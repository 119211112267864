@import "../../Import.less";
@import "_WalkthroughVariables.less";

.walkthrough-slide-info{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    .flex-center-center();
    padding: 15px;
    z-index: 3;

    &.last{
        bottom: 60px;
    }

    .slide-full-width{
        width: 100%;
        overflow-y: auto;
        overscroll-behavior: none;
    }

    .slide-title,
    .slide-body,
    .slide-media{
        margin: 0;
        margin-top: 15px;
        &:first-child{
            margin-top: 0;
        }
    }

    .slide-title{
        text-align: center;
        font-size: 22px;
        padding: 0;
        margin-top: 25px;
    }
    .slide-body{
        text-align: center;
        padding: 0;
        font-size: 17px;
    }
    .slide-media{
        width: 100%;
        .media{
            .image{
                height: 200px;
            }
        }
    }
}
.walkthrough-slide-info-continue-container{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 60px;
    line-height: 60px;
    text-align: center;
    z-index: 4;

    .btn{
        font-size: 20px;
    }
}

.walkthrough-slide-info-background{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    overflow: hidden;
    background-position: center center;
    background-size: cover;

    &.watermark{
        //opacity: .25;
        &:after{
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 2;
            background: rgba(255,255,255,0.75);
            content: '';
        }
    }
}