.wysiwyg-result {
    p {
        padding: 0;
        margin: 0 0 10px 0;

        &:last-child {
            margin-bottom: 0;
        }
    }

    img {
        width: auto;
        height: auto;
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;
        display: block;
    }

    .video-wrapper {
        position: relative;
        padding-bottom: 56.25%; /* 16:9 */
        height: 0;

        iframe, video {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    video {
        background: grey;
    }

    ol {
        margin-block-start: 1em;
        margin-block-end: 1em;
    }

    user-mention, department-mention, user-group-mention, hash-tag {
        color: var(--highlight-color);
        font-weight: bold;
    }

    table {
        border: none;
        border-collapse: collapse;
        empty-cells: show;
        max-width: 100%;
        
        td, th {
            border: 1px solid var(--general-border-color);
        }
    }
}
