
my-training, course-item {
    .course, .phase-item {
        overflow: hidden;
        margin: 10px;
        padding: 10px;
        border-radius: var(--training-border-radius);
        background-color: var(--background-highlight-color);
        cursor: pointer;
        
        @media @desktop-up {
            margin: 10px 0;
        }

        &.locked, &.expired{
            .top h3 {
                opacity: 0.4;
            }

            .image-inner, .icon {
                opacity: 0.4;
            }

            rl-progress .bar .inner {
                background: ~'rgba(var(--general-text-color-rgb), 0.4)';
            }
        }

        &.deadline {
            background-image: ~'linear-gradient(to bottom, rgba(var(--general-text-color-rgb), 0) 39%, rgba(var(--warning-color-rgb), 0.1));';

            rl-progress .bar .inner {
                background: var(--warning-color);
            }
        }

        &.new {
            background-image: ~'linear-gradient(to bottom, rgba(var(--general-text-color-rgb), 0) 39%, rgba(var(--highlight-color-rgb), 0.1));';

            .top h3 {
                color: var(--highlight-color);
            }

            .status {
                text-transform: uppercase;
            }
        }

        &.passed {
            background-image: ~'linear-gradient(to bottom, rgba(var(--general-text-color-rgb), 0) 39%, rgba(var(--confirmation-color-rgb), 0.1));';

            rl-progress .bar .inner {
                background: var(--confirmation-color);
            }

            .status {
                text-transform: var(--confirmation-color);
            }
        }

        &.not-passed {
            background-image: ~'linear-gradient(to bottom, rgba(var(--general-text-color-rgb), 0) 39%, rgba(var(--alert-color-rgb), 0.1));';

            rl-progress .bar .inner {
                background: var(--alert-color);
            }
        }

        &:first-of-type {
            margin-top: 0;
        }

        .top {
            display: flex;
            align-items: center;
            margin-bottom: 15px;

            h3 {
                margin: 0 0 10px 0;
                font-size: 15px;
                font-weight: 600;
            }
            
            .time-to-renew-badge {
                display: flex;
                align-items: center;
                justify-content: space-between;
                align-self: flex-start;
                margin-left: auto;
                padding: 6px;
                gap: 4px;
                border-radius: 7px;
                font-size: 11px;
                font-weight: 700;
                rl-icon {
                    font-size: 13px;
                }
            }

            .duration > span {
                white-space: nowrap;
                padding-right: 20px;
                font-size: 13px;

                rl-icon {
                    font-size: 13px;
                }
            }
        }

        rl-progress {
            margin-bottom: 10px;
        }

        .bottom {
            display: flex;
            justify-content: space-between;
            align-items: center;

            p {
                margin: 0;
                font-size: 13px;

                &.status {
                    font-weight: 600;
                }

                &.expired, &.deadline {
                    color: var(--general-text-inactive-color);
                }
                &.expired-since {
                    color: var(--warning-color);
                }
            }
        }
    }
}

course-item {
    .course {
        .image {
            position: relative;

            min-width: 80px;
            height: 80px;
            margin-right: 10px;

            border-radius: 50%;

            .image-inner {
                overflow: hidden;
                border-radius: 50%;

                width: 100%;
                height: 100%;

                background-size: cover;
                background-position: center center;

                text-align: center;

                rl-icon {
                    display: block;

                    font-size: 34px;
                    line-height: 80px;
                }
            }

            state-icon {
                position: absolute;
                bottom: 0;
                right: 0;
            }
        }
    }
}