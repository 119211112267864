.base-view.base-view-catalog {
    height: auto;
    background-color: var(--general-background-color);
}

catalog, catalog-category, catalog-item-details {
    .loading {
        .spinner {
            position: fixed;
            width: 50px;
            height: 50px;
            z-index: 100;
            top: 50%;
            left: 50%;
            margin-top: -25px;
            margin-left: -25px;
            border-color: var(--general-text-color);

            @media @desktop-up {
                left: calc(50% + 140px);
            }

            @media @desktop-big-up {
                left: calc(50% + 180px);
            }
        }
    }

    .breadcrumbs {
        padding-top: 20px;

        button {
            font-size: 13px;
            padding: 0;
            color: @clr-bodytext;
            opacity: 0.4;
            font-weight: 600;
            cursor: pointer;

            max-width: 100%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;

            &.last {
                opacity: 1;
            }

            &:not(:first-child):before {
                content: '\f054'; // fa-chevron-right
                padding: 0 5px;
                opacity: 0.4;

                font-size: 10px;
                font-family: 'Font Awesome 6\ Pro';
                font-weight: 400;
            }
        }
    }
}

catalog, catalog-category {
    display: block;

    @media @tablet-max {
        padding: 0 20px;
    }

    @media @desktop-up {
        margin: 0 auto;
        max-width: 60vw;
    }

    .count {
        margin: 0;
        padding: 20px 0 5px 0;
        font-size: 13px;
        font-weight: 700;
    }

    .catalog-grid {
        display: flex;
        flex-wrap: wrap;
        position: relative;
        box-sizing: border-box;
        margin: 0 -5px;

        @media @tablet-up {
            margin: 0 -10px;
        }

        &::after {
            content: '';
            flex: auto;
        }

    }

    .no-results {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;

        margin-top: 100px;

        icon {
            height: 100px;
        }

        p {
            text-align: center;
            max-width: 320px;
        }
    }
}

@import "./Items/CatalogGridItem/CatalogGridItem";
@import "./Items/CatalogItemDetails/CatalogItemDetails";
@import "./CatalogSearch/CatalogSearch";
@import "./CatalogSearch/CatalogSearchList/CatalogSearchListComponent";
