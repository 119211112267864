@import "../Import.less";

@transition-speed: .2s;

.selectlist-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: var(--popup-overlay-bg);
    z-index: @level-selectlist;
    transition-duration: @transition-speed;
    transition-property: opacity;
    transition-timing-function: ease-in-out;
    .flex-center-center();

    &.ng-enter, &.ng-leave-active {
        opacity: 0;
    }

    &.ng-enter {
        .selectlist {
            opacity: 0;
            transform: scale(1.05);
        }
    }

    &.ng-leave-active {
        .selectlist {
            opacity: 0;
            transform: scale(0.85);
        }
    }

    &.ng-enter-active {
        opacity: 1;
    }

    .selectlist-overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
    }

    .selectlist {
        display: block;
        position: relative;
        background: #fff;
        transform: scale(1);
        transition-duration: @transition-speed;
        transition-property: all;
        transition-timing-function: ease-in-out;
        z-index: 2;
        width: 90%;
        max-width: 280px;
        max-height: 400px;
        border-radius: 2px;
        box-shadow: @shadow-box-deep;
        .flex-container();
        overflow: hidden;

        .selectlist-header {
            display: block;
            min-height: 40px;
            box-sizing: border-box;
            padding: 13px 15px;
            border-bottom: 1px solid #ddd;
            flex: 0 0 auto;
            background: #f1f1f1;

            h3 {
                display: block;
                line-height: 20px;
                font-size: 18px;
                font-weight: 600;
                padding: 0;
                margin: 0;
            }
        }

        .selectlist-content {
            flex: 1 1 auto;
            overflow-x: hidden;
            overflow-y: auto;

            ul {
                display: block;
                list-style: none;
                margin: 0;
                padding: 5px 0;

                li {
                    display: block;
                    position: relative;
                    overflow: hidden;
                    line-height: 38px;
                    box-sizing: border-box;
                    padding: 0 5px 0 40px;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    transition: all ease-in-out .2s;

                    > .dot {
                        position: absolute;
                        margin: 0;
                        left: 12px;
                        top: 10px;
                        border: 2px solid #555;
                        height: 16px;
                        width: 16px;
                        text-align: center;
                        box-sizing: border-box;
                        border-radius: 50%;
                        background-image: none;
                        .flex-center-center();

                        &:before {
                            display: block;
                            height: 8px;
                            width: 8px;
                            content: '';
                            background: @color-blue;
                            transform: scale(0);
                            border-radius: 50%;
                        }
                    }

                    &.selected {
                        color: @color-blue;

                        > .dot {
                            border-color: @color-blue;

                            &:before {
                                transform: scale(1);
                            }
                        }
                    }
                }
            }
        }

        .selectlist-footer {
            flex: 0 0 auto;
            border-top: 1px solid #ddd;
            height: 38px;
            line-height: 38px;
            text-align: right;
            font-size: 0;
            padding: 0 5px;
            box-sizing: border-box;
            background: #f1f1f1;

            > button {
                background: none;
                border: none;
                display: inline-block;
                font-size: 14px;
                position: relative;
                overflow: hidden;
                height: 38px;
                line-height: 38px;
                padding: 0 15px;
                text-align: center;
                color: @color-blue;
                font-weight: 600;
                text-transform: uppercase;
            }
        }
    }
}