.signup-form {
    h2 {
        font-size: 22px;
        margin: 20px 0 0 0;
        padding: 0;
        text-align: center;
        font-weight: 600;

        &.department-name {
            font-weight: bold;
            color: #666;
        }
    }

    p {
        margin: 10px 0 0 0;
        padding: 0;
        text-align: center;
    }

    .signup-form-container {
        margin: 30px auto 20px auto;
        width: 100%;
        max-width: 320px;

        .form-element {
            margin-bottom: 20px;
        }

        label {
            font-weight: 600;
        }

        p.form-error {
            margin: 5px 0 0 0;
            padding: 0;
            text-align: left;
            color: var(--warning-color);
        }

        .submit-btn {
            border: none;
            width: 100%;
            box-sizing: border-box;
            padding: 10px 20px;
            background: #000;
            color: #fff;
            text-align: center;
            margin: 5px 0 0 0;
            font-size: 14px;
            border-radius: 4px;
            cursor: pointer;
            transition: box-shadow ease-in-out .2s;
            user-select: none;

            &:hover {
                box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
            }
        }

        input[type=text],
        input[type=tel] {
            display: block;
            width: 100%;
            min-width: 100%;
            line-height: 32px;
            padding: 0 8px;
            box-sizing: border-box;
            margin-top: 6px;
            border: 1px solid #bbb;
            border-radius: 4px;
            position: relative;
            height: auto;
            background: inherit;

            &.selector {
                cursor: pointer;
                transition: all ease-in-out .2s;
                padding-right: 40px;

                &:hover {
                    border-color: #555;
                }
            }
        }

        autocomplete {
            .visible-items {
                li {
                    p {
                        text-align: left;
                        margin: 0;
                    }
                }
            }

            .selected-items {
                margin: 10px 0 0;
                padding: 0;
                display: flex;
                flex-wrap: wrap;
                align-items: center;

                li {
                    font-size: 15px;
                    list-style: none;
                    display: flex;
                    align-items: center;
                    box-sizing: border-box;
                    height: 30px;
                    max-width: 100%;
                    padding: 0 10px;
                    margin-right: 10px;
                    margin-bottom: 10px;
                    line-height: 30px;
                    background: var(--background-highlight-color);
                    position: relative;
                    overflow: hidden;

                    p {
                        margin: 0;
                        width: 100%;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        position: relative;
                        font-size: 15px;
                    }
                }
            }

            .search-wrapper {
                input {
                    &.valid {
                        color: inherit;
                    }
                }
            }
        }

        select {
            width: 100%;
            height: 34px;
            line-height: 34px;
            padding: 0 6px;
            box-sizing: border-box;
            border: 1px solid #bbb;
            border-radius: 4px;
            position: relative;
            margin-top: 6px;
        }

        input[type=checkbox] {
            position: absolute;
            left: -99999px;
            opacity: 0;

            & + label {
                display: block;
                vertical-align: top;
                font-weight: normal;
                cursor: pointer;
                user-select: none;
                position: relative;
                padding-left: 35px;

                a {
                    color: #000;
                    text-decoration: underline;
                }

                &:before {
                    content: '';
                    display: inline-block;
                    height: 24px;
                    width: 24px;
                    line-height: 24px;
                    text-align: center;
                    background: #fff;
                    border-radius: 4px;
                    vertical-align: top;
                    box-sizing: border-box;
                    border: 1px solid #bbb;
                    color: #fff;
                    transition: all ease-in-out .2s;
                    position: absolute;
                    top: 50%;
                    left: 0;
                    margin-top: -12px;
                    font-family: 'Font Awesome\ 6 Pro';
                }

                &:hover {
                    &:before {
                        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
                    }
                }
            }

            &:checked + label {
                &:before {
                    border-color: #0095ff;
                    background: #0095ff;
                    content: '\f00c'; // fa-check
                }
            }
        }
    }
}

.signup-form-done {
    padding-top: 20px;
    padding-bottom: 30px;
    text-align: center;

    i {
        font-size: 100px;
        margin-bottom: 10px;
    }

    h2 {
        font-size: 20px;
        margin: 0 0 5px 0;
        padding: 0;
    }

    p {
        margin: 0;
        padding: 0 20px;
    }
}

.signup-terms-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: auto;
    overflow-x: hidden;
    background: rgba(0, 0, 0, 0.3);
    z-index: 2;
    padding: 20px 10px;
    box-sizing: border-box;

    .signup-terms-container {
        width: 100%;
        position: relative;
        background: #fff;
        border-radius: 5px;
        box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12);
        padding: 20px;
        box-sizing: border-box;
        max-width: 500px;
        margin: 0 auto;

        .signup-terms-headline {
            text-transform: capitalize;
            margin: 0;
        }

        .signup-terms-body {
            margin: 20px 0 10px 0;
            display: block;

            p {
                margin: 0;
            }
        }

        .signup-terms-close {
            position: absolute;
            top: 15px;
            right: 15px;
            font-size: 24px;
            cursor: pointer;
            opacity: .65;
            transition: opacity ease-in-out .1s;

            &:hover {
                opacity: 1;
            }
        }
    }
}
