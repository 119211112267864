@import "../Import.less";

@profile-viewer-header-height: 250px;

.profile-viewer-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: @level-profile-viewer;
    background: rgba(0, 0, 0, 0.5);
    display: block;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    opacity: 0;

    &.show {
        opacity: 1;
    }
}

.profile-viewer {
    position: fixed;
    top: 3px;
    right: 3px;
    bottom: 3px;
    left: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: @level-profile-viewer + 1;

    .profile-viewer-content {
        opacity: 0;
        -moz-transform: translate3d(0, 20%, 0);
        -ms-transform: translate3d(0, 20%, 0);
        -o-transform: translate3d(0, 20%, 0);
        -webkit-transform: translate3d(0, 20%, 0);
        transform: translate3d(0, 20%, 0);
        height: 100%;
        width: 100%;
        max-width: 500px;
        max-height: 700px;
        -moz-transition: all 0.3s;
        -o-transition: all 0.3s;
        -webkit-transition: all 0.3s;
        transition: all 0.3s;
        background: #fff;
        border-radius: 4px;
        overflow: hidden;
        //box-shadow: 0 8px 17px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
    }

    &.show {
        .profile-viewer-content {
            -moz-transform: translate3d(0, 0, 0);
            -ms-transform: translate3d(0, 0, 0);
            -o-transform: translate3d(0, 0, 0);
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
            opacity: 1;
        }
    }

    .profile-viewer-close {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        overflow: hidden;

        &.overlayed button {
            color: #fff;

            &:hover {
                color: #555;
            }
        }

        button {
            border: 0;
            background: none;
            color: #000;
            padding: 0;
            margin: 0;

            rl-icon {
                padding: 15px 10px 10px 10px;
            }

            &:hover {
                color: #555;
            }
        }
    }

    .profile-viewer-report-btn {
        position: absolute;
        right: 0;
        top: @profile-viewer-header-height - 40px;
        font-size: 24px;
        color: #9e2424;
        height: 40px;
        line-height: 40px;
        width: 40px;
        text-align: center;
        z-index: 2;
        cursor: pointer;

        &:hover {
            color: #e40606;
        }
    }

    .profile-viewer-profile {
        .profile-viewer-header-background {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            height: @profile-viewer-header-height;
            z-index: 1;
            background-position: center center;
            background-size: cover;
        }

        .profile-viewer-header {
            height: @profile-viewer-header-height;
            top: 0;
            left: 0;
            right: 0;
            position: absolute;
            z-index: 1;
            color: #fff;
            background: rgba(0, 0, 0, 0.26);

            .text {
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                text-align: center;
            }

            h1 {
                font-size: 20px;
                display: block;
                margin: 0 0 5px 0;
                padding: 0 20px;
                text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
            }

            .points {
                font-size: 16px;
                margin: 0 0 10px 0;
                text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);

                .coin {
                    display: inline-block;
                    height: 20px;
                    width: 20px;
                    background: url('/Content/Graphics/Coin.png') no-repeat;
                    background-size: contain;
                    background-position: center center;
                    vertical-align: middle;
                    margin-right: 5px;
                }

                span {
                    vertical-align: middle;
                    text-transform: lowercase;
                    font-weight: 600;
                }
            }
        }

        .profile-viewer-body {
            top: @profile-viewer-header-height;
            left: 0;
            right: 0;
            bottom: 0;
            position: absolute;

            .profile-viewer-contact {
                text-align: center;
                .flex-center-center();
                display: block;
                margin: 10px 0 0 0;

                .profile-viewer-contact-btn {
                    display: inline-block;
                    text-align: center;
                    margin: 0;
                    border-radius: 5px;
                    padding: 5px 10px;
                    overflow: hidden;
                    position: relative;

                    i {
                        background: #000;
                        color: #fff;
                        border-radius: 100%;
                        display: block;
                        margin: 0 auto;
                        height: 40px;
                        width: 40px;
                        padding: 0;
                        text-align: center;
                        line-height: 40px;
                        font-size: 20px;
                    }

                    span {
                        display: block;
                        margin: 5px 0 0 0;
                    }
                }
            }

            .profile-viewer-info {
                padding: 0 20px;

                > div {
                    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
                    margin-bottom: 12px;

                    &:last-child {
                        border-bottom: none;
                        margin-bottom: 0;
                    }

                    label {
                        color: rgba(0, 0, 0, 0.3);
                        text-transform: uppercase;
                    }

                    p {
                        margin: 9px 0;
                    }
                }
            }

            .profile-viewer-no-share {
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                position: absolute;
                .flex-center-center();

                p {
                    padding: 20px 25px;
                    margin: 0;
                    text-align: center;
                }
            }

            .profile-viewer-blocked {
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                position: absolute;
                .flex-center-center();

                i {
                    font-size: 50px;
                }

                h2 {
                    margin: 10px 0 0 0;
                    font-size: 16px;
                }
            }
        }
    }

    .profile-viewer-loader {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        .flex-center-center();

        .spinner {
            height: 50px;
            width: 50px;
        }
    }

    .profile-viewer-not-found {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        .flex-center-center();

        h2 {
            font-size: 20px;
            margin: 10px 0 0 0;
            padding: 0;
        }

        i {
            font-size: 80px;
            margin: 0;
            padding: 0;
        }
    }

    .profile-viewer-profile,
    .profile-viewer-not-found,
    .profile-viewer-loader {
        z-index: 1;
    }
}

.highscore-levels-enabled .profile-viewer {
    .profile-viewer-header {
        border-bottom-width: 3px;
        border-bottom-style: solid;
    }

    .highscore-level-badge {
        position: absolute;
        right: 10px;
        top: 14px;
        box-sizing: border-box;
        padding: 4px 6px 3px 6px;
        font-size: 11px;
        border-radius: 4px;
        text-transform: uppercase;
        letter-spacing: .7px;
    }
}