.base-view-contentOverview {
    background: var(--background-highlight-color);
    height: auto !important;
}

content-categories {
    display: block;
    padding: 15px 0;

    @media @tablet-max {
        padding: 15px 15px 0;
    }


    .filter {
        display: flex;
        justify-content: space-between;
        padding-bottom: 10px;
    }

    .content-category {
        padding: 15px;
        margin-bottom: 15px;

        background: var(--general-background-color);
        border-radius: var(--general-border-radius);

        header {
            display: flex;
            justify-content: space-between;
            align-items: center;

            h2 {
                margin: 0;
                font-weight: 700;
                font-size: 15px;
                line-height: 130%;
            }
        }

        .follow-actions {
            margin-left: 5px;

            button {
                max-width: 120px;
                height: 30px;

                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                font-size: 13px;
                font-weight: 500;
            }
        }

        .summary {
            margin: 10px 0 0 0;
            font-size: 15px;
            line-height: 18px;
        }

        .selected {
            font-style: italic;
            color: var(--confirmation-color);
            margin: 10px 0;

            rl-icon {
                background-color: var(--confirmation-color);
                color: var(--white-color);
            }
        }

        .new-posts-label {
            margin: 10px 0 0;
            color: var(--highlight-color);
            font-weight: 500;
            display: flex;
            align-items: center;

            .circle {
                background: var(--highlight-color);
                width: 10px;
                height: 10px;
                border-radius: 100%;
                margin-right: 5px;
            }
        }
    }
}