content-safety-warning-popup {
    action-sheet-custom .actionsheet .actionsheet-content {
        display: flex;
        flex-direction: column;
        gap: var(--spacing);

        @media @mobile-max {
            padding-bottom: calc((var(--spacing) * 2) + (var(--m-spacing) / 2));
        }
    }

    .description {
        margin: 0;
        color: var(--text-on-white-color);
    }

    .severity {
        &.lvl-1 {
            .pointer {
                left: 37.5%;
                transform: translateX(-50%);
            }

            .level b {
                color: var(--confirmation-color);
            }
        }

        &.lvl-2 {
            .pointer {
                left: 62.5%;
                transform: translateX(-50%);
            }

            .level b {
                color: var(--alert-color);
            }
        }

        &.lvl-3 {
            .pointer {
                left: 87.5%;
                transform: translateX(-50%);
            }

            .level b {
                color: var(--warning-color);
            }
        }
    }


    .gradient {
        position: relative;
        height: var(--spacing);
        border-radius: 20px;
        background: linear-gradient(90deg, rgba(var(--confirmation-color-rgb), 0.80) 0%, rgba(var(--alert-color-rgb), 0.80) 58.85%, rgba(var(--warning-color-rgb), 0.80) 100%);

        i {
            position: absolute;
            top: 0;
            bottom: 0;
            width: 2px;

            background: var(--white-color);
            box-shadow: 0 7.5px 16.25px 0 var(--shadow-color), 0 0.625px 5px 0 var(--shadow-color);

            &:nth-child(1) {
                left: calc(25% - 1px);
            }

            &:nth-child(2) {
                left: calc(50% - 1px);
            }

            &:nth-child(3) {
                left: calc(75% - 1px);
            }
        }

        .pointer {
            position: absolute;
            bottom: -4px;
        }
    }

    .level {
        margin-top: var(--m-spacing);
        color: var(--text-on-white-color);
    }

    footer {
        display: flex;
        gap: var(--spacing);

        rl-button {
            flex: 1;
        }
    }
}

