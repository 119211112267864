training-badges {
    header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 15px;

        div {
            display: flex;
            align-items: center;
        }

        .rl-headline {
            font-size: 15px;
            margin: 0 10px 0 0;
        }

        rl-icon {
            color: var(--general-text-color);
            opacity: 0.35;
        }
    }

    badges-list {
        @media @desktop-up {
            max-width: 768px;
            margin: 0 auto;
        }
    }
}
