@import "../Import.less";

@tab-header-height: 44px;
@tab-header-boxed-height: 50px;
@tab-header-boxed-link-height: @tab-header-boxed-height - 20;


.native {
    tabset {
        @media @mobile-max {
            padding-bottom: 50px;
            padding-bottom: calc(50px + env(safe-area-inset-bottom, 0px));
        }
    }
}

tabset {
    position: relative;
    display: block;

    &.tabset-scroll {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;

        .tabset-container {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            height: 100%;
            overflow: hidden;

            .tabset-content {
                position: relative;
                -webkit-box-flex: 1;
                -ms-flex: 1 auto;
                flex: 1 auto;
                display: block;
                overflow: hidden;

                .tab {
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    top: 0;
                    overflow-x: hidden;
                    overflow-y: auto;
                }
            }
        }

        .tab-header {
            -webkit-box-flex: 0;
            -ms-flex: 0 auto;
            flex: 0 auto;
        }
    }

    &.tabset-slider {
        > .tabset-container {
            > .tab-header > li {
                width: 100%;
                display: none;

                &.active {
                    display: block;
                }
            }

            > .active-tab-border {
                width: 100%;
            }
        }

        .tab-header {
            overflow: hidden;
            max-height: 44px;

            .icon-arrow {
                position: absolute;
                line-height: 44px;
                top: 0;
                font-size: 26px;
                color: #000;
                z-index: 2;
                padding: 0 5px;
                cursor: pointer;
            }

            .fa-angle-right {
                right: 5px;
            }

            .fa-angle-left {
                left: 5px;
            }
        }

        .active-tab-border {
            display: none;
        }
    }

    .tab-header {
        display: block;
        padding: 0;
        margin: 0;
        list-style: none;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;

        &:after {
            display: table;
            clear: both;
            content: '';
        }

        > li {
            height: @tab-header-height;
            display: block;
            float: left;
            margin: 0;
            padding: 0;
            overflow: hidden;

            button {
                line-height: @tab-header-height;
                text-align: center;
                padding: 0;
                margin: 0;
                display: block;
                width: 100%;
                -moz-transition: background .1s ease-in-out;
                -o-transition: background .1s ease-in-out;
                -webkit-transition: background .1s ease-in-out;
                transition: background .1s ease-in-out;
                text-transform: uppercase;
                text-decoration: none;
                color: #000;
                background: white;
                font-size: 15px;
                border-radius: 0;
                position: relative;
                overflow: hidden;
                border: none;

                .ripple-effect {
                    background-color: #999;
                }

                i {
                    font-size: 22px;
                    position: relative;
                    top: 3px;
                }

                .tab-header-image {
                    height: 24px;
                    width: 24px;
                    display: inline-block;
                    vertical-align: middle;
                    background-size: contain !important;
                    background-position: center center;
                    background-repeat: no-repeat;
                    position: relative;
                    top: -2px;
                }
            }
        }
    }

    &:not(.force-header) > .tab-count-1 {
        > .tab-header {
            display: none;
        }

        > .active-tab-border {
            display: none;
        }
    }

    > .tab-count-2 {
        > .tab-header > li {
            width: 50%;
        }

        > .active-tab-border {
            width: 50%;
        }
    }

    > .tab-count-3 {
        > .tab-header > li {
            width: 33.33333%;
        }

        > .active-tab-border {
            width: 33.33333%;
        }
    }

    > .tab-count-4 {
        > .tab-header > li {
            width: 25%;
        }

        > .active-tab-border {
            width: 25%;
        }
    }

    > .tab-count-5 {
        > .tab-header > li {
            width: 20%;
        }

        > .active-tab-border {
            width: 20%;
        }
    }

    > .tab-count-6 {
        > .tab-header > li {
            width: 16.66666%;
        }

        > .active-tab-border {
            width: 16.66666%;
        }
    }

    > .tab-count-7 {
        > .tab-header > li {
            width: 14.2%;
        }

        > .active-tab-border {
            width: 14.2%;
        }
    }

    > .tab-count-8 {
        > .tab-header > li {
            width: 12.5%;
        }

        > .active-tab-border {
            width: 12.5%;
        }
    }

    .active-tab-border {
        position: absolute;
        display: block;
        top: @tab-header-height - 3px;
        height: 3px;
        z-index: 1;
        left: 0;
        background: black;
        -moz-transition: all .3s ease-in-out;
        -o-transition: all .3s ease-in-out;
        -webkit-transition: all .3s ease-in-out;
        transition: all .3s ease-in-out;
    }

    tab {
        display: block;
    }

    .tab {
        display: block;
        padding-top: 3px;
    }
    // Show Header (Forced!)
    &.force-header {
        > .tab-count-1 {
            > .tab-header {
                > li {
                    float: none;
                    text-align: center;
                }
            }

            > .active-tab-border {
                width: 100%;
            }
        }
    }
}

/* BOXED */
/* selector is used to avoid using BOXED layout on nested tabsets */
tabset.boxed {
    > [role=tabpanel] > .tab-header {
        height: @tab-header-boxed-height;
        padding: ((@tab-header-boxed-height - @tab-header-boxed-link-height) / 2) 20px;
        box-shadow: none;

        > li {
            height: @tab-header-boxed-link-height;
            line-height: @tab-header-boxed-link-height;
            border: 1px solid @clr-bodytext;
            border-left-width: 0;
            -moz-box-sizing: border-box;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            position: relative;

            button {
                height: @tab-header-boxed-link-height - 2;
                line-height: @tab-header-boxed-link-height - 2;
                color: @clr-bodytext;
                position: relative;
                overflow: hidden;
                width: 100%;
                margin: 0;
                box-sizing: border-box;
                background: none;
            }

            &:first-child {
                border-left-width: 1px;
            }

            &.active {
                background: @clr-bodytext;
                button {
                    color: @header-text-color;
                }
            }

            .ripple-effect {
                background-color: #eee;
            }
        }
    }

    > [role=tabpanel] > .active-tab-border {
        display: none;
    }

    > .tab-count-1 {
        > .tab-header {
            > li {
                float: none;
                border-radius: 3px;
            }
        }
    }
}

/* FILL */
tabset.fill {
    /* BOXED */
    &.boxed {
        > [role=tabpanel] > .tab-header {
            background-color: @header-background-color;
            > li {
                border-color: @header-text-color;
                button {
                    color: @header-text-color;
                }
                &.active {
                    background-color: @header-text-color;
                    button {
                        color: @header-background-color;
                    }
                }
            }
        }
    }
    &:not(.boxed) {
        > [role=tabpanel] {
            > .tab-header {
                background-color: @header-background-color;
                > li {
                    button {
                        color: @header-text-color;
                    }
                    &.active button {
                        background-color: var(--general-background-color);
                        color: #000;
                    }
                }
            }
            > .active-tab-border {
                display: none;
            }

            &.tab-count-1 {
                > .tab-header > li.active button {
                    background-color: @header-background-color;
                    color: @header-text-color;
                }
            }
        }
    }
}