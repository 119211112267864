rate-popup {
    .actionsheet {
        min-width: 350px;
    }
    
    .rating-buttons {
        justify-content: flex-start;
        display: flex;
        flex-direction: column;
    }
    
    .rating-range {
        display: flex;
        justify-content: space-around;
        margin-bottom: 30px;

        --rating-item-size: 60px;

        @media @desktop-up {
            gap: 20px;
        }

        .rating-item {
            display: flex;
            align-items: center;
            justify-content: center;
            box-sizing: border-box;

            max-width: var(--rating-item-size);
            max-height: var(--rating-item-size);
            width: var(--rating-item-size);
            height: var(--rating-item-size);

            cursor: pointer;
            font-weight: bold;
            border-radius: 50%;
            opacity: 0.5;
            box-shadow: 0 4px 7px rgba(60, 72, 88, 0.1);
            background-color: var(--highlight-color);
            
            &.active,
            &:hover {
                opacity: 1;
            }
        }

        img.rating-item {
            object-fit: contain;
            box-shadow: unset !important;
            border: none !important;
            border-radius: unset !important;
            background-color: transparent !important;
        }
    }

    .rating-range[data-length='5'] span {
        width: calc((100vw - 60px) / 5);
        height: calc((100vw - 60px) / 5);
    }

    .rating-range[data-length='6'] span {
        width: calc((100vw - 60px) / 6);
        height: calc((100vw - 60px) / 6);
    }

    rl-textarea {
        margin-bottom: 15px;
    }

    footer {
        display: flex;

        rl-button:first-of-type { 
            flex: 1;
        }

        rl-button[icon="delete-alt"] {
            width: 20%;
            min-width: 50px;
            margin-left: 10px;

            button {
                min-width: auto;
                width: 100%;
            }
        }
    }
}