@import "../Import.less";

.initial-load {
    #loading-bar,
    #loading-bar-spinner {
        display: none;
    }
}

#loading-bar {
    .bar {
        background: var(--loadbar-color);
        top: 0;
    }
    .peg {
      box-shadow: var(--loadbar-color) 1px 0 6px 1px;
    }
}

#loading-bar-spinner {
    top: 13px;
    left: auto;
    right: 13px;
    display: block;
    
    > .spinner{
        border-color: var(--loadbar-color);
    }
}