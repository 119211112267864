translation-exclusions {
    .translation-exclusion-btn {
        display: flex;
        align-items: center;
        gap: 10px;
        
        height: 40px;
        padding: 5px 15px;

        cursor: pointer;
        box-sizing: border-box;
        background: rgba(var(--alert-color-rgb), 0.2);

        p {
            font-size: 13px;
            line-height: 1.3;
            margin: 0;
        }
    }
}

