my-tasks {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: 100%;
    
    tasks {
        padding: 0;
    }

    #feed-url {
        position: absolute;
        overflow: hidden;
        clip: rect(0 0 0 0);
        height: 1px;
        width: 1px;
        margin: -1px;
        padding: 0;
        border: 0;
    }

    .today-header {
        padding: var(--m-spacing);
        position: relative;
        background: var(--background-highlight-color);
        border-radius: var(--general-border-radius);
        margin: var(--m-spacing) 0;
        display: flex;
        flex: 0;
        gap: var(--spacing);
        justify-content: flex-start;
        align-items: center;
        transform: translatez(0);

        @media @desktop-up {
            margin-top: 0;
            background: transparent;

            .left {
                margin-left: 0!important;
            }

            .greetings-group {
                display: flex;
                gap: 2px;
                font-size: 1.06667rem;
            }
        }

        .left {
            width: 46px;
            height: 46px;
            margin-left: var(--s-spacing);

            .profile-image {
                width: 46px;
                height: 46px;
            }
        }

        .right {
            display: block;
        }

        h3 {
            font-weight: 400;
            font-size: 20px;

            .greetings {
                .name {
                    font-weight: 500;
                }
            }
        }

        .percentage-completed {
            display: flex;
            align-items: center;
            margin-top: 5px;

            &.all-completed {
                .label {
                    color: var(--confirmation-color);
                    font-weight: 500;
                }
            }

            .status-icon {
                background: var(--confirmation-color);
            }

            .label {
                font-size: 13px;
                line-height: 24px;
                color: ~'rgba(var(--general-text-color-rgb), .5)';
            }

            percentage-circle {
                margin-right: 5px;
            }

            .c100:after {
                background-color: var(--general-background-color);
            }
        }
    }

    .today-completed {
        background: rgba(var(--confirmation-color-rgb), 0.2);
    }

    .copy-feed {
        position: absolute;
        top: 10px;
        right: 10px;
        background: transparent;
        border: none;
        font-size: 30px;
    }

    .tear-off-calendar {
        position: relative;
        width: 120px;
        margin: 0 auto;

        .month-year {
            z-index: 3;
            position: relative;
            height: 35px;
            text-align: center;
            line-height: 35px;
            background: @clr-highlight;
            color: white;
        }

        .day {
            z-index: 3;
            position: relative;
            display: flex;
            height: 85px;
            flex-direction: column;
            justify-content: center;
            text-align: center;
            background: white;
            box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.05);

            .number {
                font-size: 40px;
                line-height: 1;
            }
        }

        .page1, .page2 {
            position: absolute;
            background: white;
            height: 100%;
            box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.05);
        }

        .page1 {
            z-index: 2;
            bottom: -5px;
            left: 2px;
            right: 2px;
        }

        .page2 {
            z-index: 1;
            bottom: -10px;
            left: 4px;
            right: 4px;
        }
    }

    .events {
        flex: 1;
        overflow: hidden;
        container-type: size;
        
        > h2 {
            margin: 0;
            font-size: 15px;
        }

        > h3 {
            margin: 0;
            padding: 20px 15px 10px 15px;
            font-weight: 600;
            font-size: 20px;
        }
    }

    .overdue-button {
        background: rgba(var(--warning-color-rgb), 0.3);
        border-radius: var(--general-border-radius);
        margin-bottom: var(--m-spacing);
        padding: 12px;
        font-size: 13px;
        color: var(--warning-color);
        font-weight: 500;
        cursor: pointer;


        .status-icon {
            min-width: 15px;
            min-height: 15px;
            background: var(--warning-color);
        }

        rl-icon {
            font-size: 10px;
            color: white;
            font-weight: 600;
        }
    }
}

sorted-tasks {
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow-y: auto;

    task {
        margin-top: var(--m-spacing);
    }

    h2 {
        margin: 0;
        padding: 10px;
        font-size: 14px;
        line-height: 19px;
        letter-spacing: 0;
    }
}
