@import "../../Import.less";

.walkthrough-slide-appaccess {
    padding: 0 20px;
    text-align: center;
    position: relative;
    z-index: 2;

    h2{
        font-size: 23px;
        margin: 0 0 15px 0;
    }

    form{
        display: block;
        margin: 30px auto 0 auto;

        label{
            display: block;
            font-weight: 600;
            width: 100%;
        }
        input[type=text]{
            display: block;
            width: 90%;
            margin: 8px auto 0 auto;
            height: 32px;
            line-height: 32px;
            border: 1px solid rgba(0,0,0,0.24);
            border-radius: 3px;
            box-sizing: border-box;
            padding: 0 10px;
            text-align: center;
        }

        .error-message{
            font-size: 13px;
            color: @error-darker-color;
            margin: 8px 0 5px 0;
            font-weight: 600;
        }
    }

    .continue-container{
        margin-top: 15px;
        text-align: center;
    }
}
.walkthrough-slide-appaccess-watermark{
    position: absolute;
    top: auto;
    bottom: 0;
    left: auto;
    right: 0;
    .flex-center-center();
    z-index: 1;
    opacity: .12;

    i{
        font-size: 300px;
        transform: rotate3d(0, 0, 1, -40deg);
        position: relative;
        bottom: -50px;
        right: -50px;
    }
    overflow: hidden;
}