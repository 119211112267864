@import "../Import.less";

.popup-sticky-bottom {
    background-color: var(--general-background-color);
    padding: var(--spacing);
    position: fixed;
    z-index: 99;
    bottom: 0;
    width: 100%;
    box-sizing: border-box;
    margin-top: auto;

    button {
        text-transform: uppercase;
    }

    @media @desktop-up {
        padding: var(--spacing);
    }
}

:root {
    --top-header-height: 55px;
    @media @desktop-up {
        --top-header-height: 60px;
    }
}

.base {
    height: 100%;
    min-height: 100%;
    position: relative;
    background: var(--general-background-color);

    @media @desktop-up {
        margin-left: var(--sidemenu-width);
    }

    &.native {
        transition: transform .35s;

        &:not(.menu-opened) {
            &:after, &:before {
                display: none;
            }
        }

        &:after, &:before {
            content: '';
            position: absolute;
            display: block;
            background: var(--sidemenu-text-color);
            opacity: 0.6;
            top: 20px;
            bottom: 20px;
            left: 0;
            right: 0;
            z-index: -1;
            transition: none;
        }

        &.menu-opened {
            transform: scale(.7) translateX(-90%);
            box-shadow: 0 3px 25px rgba(0, 0, 0, .1);

            @media @tablet-up {
                transform: scale(.8) translateX(-45%);
            }

            &:before, &:after {
                transition: right .3s;
                transition-delay: .2s;
            }

            &:before {
                right: -15px;
            }

            &:after {
                right: -30px;
                top: 40px;
                bottom: 40px;
                opacity: 0.3;
                box-shadow: none;
                filter: blur(2px);
            }
        }

        &.disable-overflow {
            .base-container {
                overflow: hidden;
            }
        }

        .base-top {
            &.disable-overflow {
                overflow: hidden;
            }

            .base-top-right-buttons {
                .module-buttons {
                    height: 100%;
                    position: relative;
                }
            }
        }

        .base-bottom {
            height: 50px;
            position: fixed;
            bottom: 0;
            width: 100%;
            border-top: none;
            z-index: 200;
            padding-bottom: env(safe-area-inset-bottom);

            ul {
                display: flex;
                justify-content: space-around;
                align-items: center;
                padding: 0;
                list-style: none;
                margin: 0;
                height: 100%;

                button {
                    position: relative;
                    padding: 1px 6px;

                    rl-icon {
                        font-size: 22px;

                        .svg-teams {
                            path {
                                fill: var(--header-text-color);
                            }
                        }
                    }

                    &.active {
                        rl-icon {
                            color: var(--highlight-color);
                        }
                    }
                }
            }

            rl-badge {
                position: absolute;
                bottom: 13px;
                right: 0;
                z-index: 10;
            }
        }

        .view-page {
            padding-bottom: 50px;
            padding-bottom: calc(50px + env(safe-area-inset-bottom, 0px));
        }
    }

    .base-container {
        height: 100%;
        min-height: 100%;
        padding-top: var(--top-header-height);
        box-sizing: border-box;
        opacity: 1;

        &.loading {
            opacity: 0.1;
            transform: scale(0.9);
        }

        transition: opacity 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94), transform 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);

        &.slow-enter {
            transition-duration: .5s;
        }

        &.ng-enter,
        &.ng-leave.ng-leave-active {
            opacity: 0;
        }

        &.ng-leave,
        &.ng-enter.ng-enter-active {
            opacity: 1;
        }
    }

    &.full-screen-page {
        .base-container {
            padding-top: 0;
        }
    }

    .base-top-background {
        height: var(--top-header-height);
        transition: background .35s ease-in-out;
        z-index: 10;
    }

    .base-top {
        z-index: 11;
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        height: var(--top-header-height);
        box-sizing: border-box;

        @media @desktop-up {
            left: var(--sidemenu-width);
        }

        @media @tablet-max {
            .desktop-button {
                display: none !important;
            }
        }

        .survey-options {
            padding: 0;
            text-align: center;
            color: var(--white-color);
            background: transparent;
            border: none;

            rl-icon {
                font-size: 20px;
            }

            @media @desktop-up {
                rl-icon {
                    font-size: 25px;
                }
            }
        }

        .base-top-inner {
            width: 100%;
            height: 100%;
            position: relative;
            display: flex;
            justify-content: space-between;
            box-sizing: border-box;

            @media @tablet-max {
                overflow: hidden;
            }
        }

        .header-popup-wrapper {
            display: inline-block;
            position: relative;

            .bookmarks-btn {
                font-size: 20px;
            }

            .chat-btn {
                font-size: 23px;
            }
        }


        .base-top-right-buttons,
        .base-top-left-buttons {
            height: 100%;
            display: flex;

            @media @desktop-up {
                width: 225px;
            }
        }

        .base-top-right-buttons {
            left: auto;
            text-align: right;
            justify-content: flex-end;
            transition: 350ms linear all;
            opacity: 1;

            @media @desktop-up {
                position: relative;
            }


            &.hidden {
                opacity: 0;
                visibility: hidden;
            }

            > .create-chat-btn {
                @media @desktop-up {
                    font-size: 24px;
                    padding-bottom: 3px;
                }
            }

            .base-top-right-buttons-inner {
                max-height: 100%;
                position: relative;
            }

            .show-department {
                rl-icon {
                    font-size: 22px;
                }
            }

            .svg-teams {
                path {
                    fill: var(--header-text-color);
                }
            }

            .header-popup-wrapper {
                .modal-content {
                    button {
                        color: unset;
                    }
                }
            }
        }

        header-search {
            display: block;
            width: 100%;
        }

        .search-field {
            @media @tablet-max {
                position: absolute;
                top: 0;
                left: 0;
                z-index: 200;
            }

            &.active {
                z-index: 202;
            }

            @media @desktop-up {
                height: 100%;
            }
        }

        .menu-toggle-btn {
            width: 45px !important;

            @media @desktop-up {
                display: none !important;
            }

            &:after {
                display: block;
                position: absolute;
                bottom: 50%;
                margin-bottom: -7px;
                height: 12px;
                left: 13px;
                right: 5px;
                border-bottom: 2px solid var(--header-text-color);
                border-top: 2px solid var(--header-text-color);
                content: '';
                width: 22px;
            }

            &:before {
                display: block;
                position: absolute;
                left: 13px;
                right: 5px;
                top: 50%;
                margin-top: -2px;
                height: 2px;
                background: var(--header-text-color);
                content: '';
                width: 22px;
            }
        }

        .menu-burger-badge {
            display: block;
            width: 16px;
            height: 16px;
            border-radius: 100%;
            position: absolute;
            top: 10px;
            right: 2px;
            z-index: 10;
            background: var(--badge-background-color);
        }

        .global-back-btn {
            width: 45px !important;
            height: 0 !important;
            overflow: hidden;
            max-width: 0;
            -moz-transition: max-width .2s ease-in-out;
            -o-transition: max-width .2s ease-in-out;
            -webkit-transition: max-width .2s ease-in-out;
            transition: max-width .2s ease-in-out;

            &.show-back {
                height: 100% !important;
                max-width: 45px;
            }
        }

        .global-cancel-btn {
            width: 45px !important;
        }

        .header-title-wrapper {
            @media @desktop-up {
                width: 100%;
                position: relative;
                display: flex;
                align-items: center;
            }

            header-search {
                @media @desktop-up {
                    position: absolute;
                }
            }
        }

        .base-top-inner {
            > .title, > .header-title-wrapper .title {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                flex: 1;
                top: 0;

                @media @tablet-max {
                    justify-content: center;
                    position: absolute;
                    left: 90px;
                    right: 90px;
                    top: 0;
                    height: var(--top-header-height);

                    > span {
                        display: block;
                        width: 100%;
                        text-align: center;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                }

                .title-wrapper {
                    position: relative;
                    max-width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    h1 {
                        display: inline-block;
                        vertical-align: middle;
                        width: auto;
                        max-width: 100%;

                        &.with-filter-button {
                            margin-left: 40px;
                        }
                    }

                    filter-button {
                        > button {
                            position: relative;
                            display: flex;
                            align-items: center;
                            width: auto;

                            rl-icon {
                                padding: 0 10px;
                                margin-left: auto;
                            }
                        }
                    }

                    .question-sign {
                        display: block;
                        position: absolute;
                        font-size: 9px;
                        font-weight: 600;
                        color: inherit;
                        right: -9px;
                        top: -2px;
                    }
                }

                h1 {
                    margin: 0;
                    text-transform: uppercase;
                }

                h1, h2 {
                    text-align: center;
                    width: 100%;
                    font-weight: 600;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }

                .title-image-container {
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 5px;
                    bottom: 5px;
                    margin: 0;
                    .flex-center-vertical();

                    @media @tablet-max {
                        -webkit-box-pack: center;
                        -ms-flex-pack: center;
                        justify-content: center;
                    }
                }

                img {
                    max-width: 100%;
                    max-height: 100%;
                    width: auto;
                    height: auto;
                }

                &.with-subtitle {
                    .subtitle {
                        margin: 0;
                        height: 20px;
                        line-height: 20px;
                        font-weight: normal;
                        opacity: .5;
                    }

                    @media @tablet-max {
                        > span &:not(.subtitle) {
                            margin-bottom: 14px;
                        }

                        .subtitle {
                            height: 18px;
                        }
                    }

                    @media @desktop-up {
                        .subtitle {
                            padding-left: 0;
                        }
                    }
                }
            }

            filter-button[place=right] {
                rl-badge {
                    z-index: 201;
                }
            }
        }

        .three-module-buttons {
            > .title, > .header-title-wrapper .title {
                @media @tablet-max {
                    margin-right: 45px;
                }
            }
        }

        .notification-btn, .bookmarks-btn, .chat-btn {
            position: relative;

            rl-badge {
                position: absolute;
                top: 10px;
                right: 8px;
            }
        }

        .profile-toggle-btn {
            .profile-image {
                width: 30px;
                height: 30px;
                vertical-align: middle;
                position: relative;
            }

            .profile-toggle-btn-image {
                width: 30px;
                height: 30px;
                display: inline-block;
                background: none;
                background-size: cover;
                background-position: center center;
                border-radius: 50%;
                overflow: hidden;
                vertical-align: middle;
                position: relative;
                top: -3px;
            }

            rl-badge {
                position: absolute;
                top: 9px;
                right: 100%;
                margin-right: -13px;
            }
        }
    }

    .base-view {
        height: 100%;
        min-height: 100%;
        position: relative;
    }
}

.header-background-color {
    background-color: var(--header-background-color);
}

.header-text-color {
    color: var(--header-text-color);
}

.header-popup-wrapper {
    position: relative;
    text-align: left;

    .modal-overlay {
        background: none;
    }

    .modal {
        position: absolute;
        top: 45px;
        right: -30px;
        left: auto;
        bottom: auto;
        width: 375px;

        &.show .modal-content {
            transform: translate3d(0, 0, 0);
        }

        .modal-content {
            overflow: hidden;
            position: relative;
            top: 15px;
            border: 1px solid var(--general-border-color);
            background: var(--general-background-color);
            border-radius: var(--general-border-radius);

            .modal-top {
                height: auto;
                padding: 15px;
                box-shadow: none;
                border-bottom: 1px solid var(--general-border-color);

                h1, h2 {
                    position: static;
                    font-size: 15px;
                    font-weight: 600;
                }

                button {
                    padding: 0;
                    font-size: 20px;
                    line-height: 15px;
                    color: unset;
                }
            }

            .modal-select-picker {
                height: 250px;
                overflow-y: auto;

                li {
                    padding: 10px 15px;
                    font-size: 13px;
                    line-height: 18px;
                    color: unset;

                    &:hover, &.selected {
                        background: var(--general-background-color);
                        color: var(--highlight-color);
                    }

                    &.selected {
                        font-weight: bold;

                        i {
                            color: var(--highlight-color);
                        }
                    }

                    &:after {
                        display: none;
                    }
                }
            }

            .modal-bottom {
                display: flex;
                justify-content: space-between;
                box-shadow: none;
                border-top: 1px solid var(--general-border-color);

                button {
                    width: auto;
                    padding: 0 15px;
                    font-size: 13px;
                    color: unset;
                }

                button:first-of-type {
                    color: var(--warning-color);
                }

                button:last-of-type {
                    color: var(--highlight-color);
                }
            }

            .modal-close {
                display: none;
            }

            button {
                color: unset;
            }
        }

    }

    .modal-body {
        max-height: 500px;
    }
}