@import "../../../Import.less";

// DESIGN: CREATIVE
.landing-page.landing-page-design-creative{
    .landing-page-chart{
        .metric-chart{
            background: #fff;
            border-radius: 6px;
            .shadow-depth-1();
            margin-bottom: 3px;
        }
        .size-2 .metric-chart{
            padding: 6px 0 2px 0;
            .metric-value{
                font-weight: 600;
            }
            .metric-chart-label{
                top: 0;
                font-size: 10px;
                font-weight: 600;
            }
        }
    }       
}