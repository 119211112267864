cancel-event-popup {
    .action-popup {
        width: 90%;
        max-width: 345px;

        .action-popup-inner {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
        }
    }

    .content {
        padding: 0 16px;
    }

    h3 {
        margin: 20px 0 15px 0;
        text-align: center;
    }

    .comment-wrapper {
        display: flex;
        flex-direction: column;

        background: var(--background-highlight-color);
        border-radius: var(--general-border-radius);

        textarea {
            box-sizing: border-box;
            height: 90px;
            width: 100%;
            padding: 10px;

            border: none;
            background: none;
            font-size: 15px;
        }

        .comment-options {
            display: flex;
            align-items: center;
            justify-content: space-between;
            box-sizing: border-box;

            height: 40px;
            padding: 0 10px;

            > span {
                font-size: 10px;
                color: ~'rgba(var(--general-text-color-rgb), 0.4)';
            }
        }
    }

    .notifications {
        height: 55px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    footer {
        rl-button {
            display: block;
            border-top: 0.5px solid var(--general-border-color);
        }
    }
}