:root {
    --calendar-accent-color: var(--highlight-color);
}

months-view {
    display: flex;
    flex-direction: column;
    background: #f7f8fa;

    .calendar-header {
        &.with-weeks {
            .titles {
                padding: 10px 10px 10px 37px;
            }
        }

        .titles {
            display: flex;
            justify-content: space-between;
            padding: 10px 15px;
            background: white;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        }

        .month-title {
            list-style: none;
            width: calc(100% / 7);
            text-align: center;
            font-size: 13px;
            font-weight: bold;
            text-transform: capitalize;

            &:nth-last-of-type(-n+2) {
                color: var(--calendar-accent-color);
            }
        }
    }

    .move-today {
        padding: 10px 10px 0 10px;

        button {
            position: relative;
            width: 40px;
            height: 40px;
            padding: 8px;
            margin: 0;
            border: none;
            border-radius: 100%;
            background: none;
            color: var(--calendar-accent-color);
            background: rgba(98, 192, 235, 0.2);

            icon {
                height: 23px;
            }
        }

        .today-date-number {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -40%);
        }
    }

    .scrolled-months {
        overflow-y: scroll;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        padding: 0 20px 0 20px;
    }

    .month {
        display: flex;
        flex-direction: column;
        padding: 15px 5px;

        h2 {
            align-self: flex-end;
            margin: 0 calc(100% / 7) 7px 0;
            font-size: 16px;
            font-weight: 500;
            color: var(--calendar-accent-color);
        }
    }

    .week {
        display: flex;

        &:not(:last-of-type) .days {
            justify-content: flex-end;
        }

        > span {
            text-align: center;
        }

        .day {
            &.weekend {
                color: ~'rgba(var(--general-text-color-rgb), .5)';
            }
        }
    }

    .week-number {
        line-height: 25px;
        opacity: .5;
        padding: 0 5px;
        font-size: 11px;
        width: 16px;
    }

    .days {
        display: flex;
        width: 100%;
    }

    .day {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: calc(100% / 7);
        font-weight: 600;

        .events {
            padding: 2px 0 5px;
            line-height: 0;
            min-height: 5px;
        }

        .day-number {
            width: 28px;
            height: 28px;
            line-height: 28px;
            text-align: center;
            cursor: pointer;
        }

        .day-dot {
            height: 4px;
            width: 4px;
            margin: 0 1px;
            display: inline-block;
            vertical-align: top;
            opacity: .6;
            background: var(--general-text-color);
            border-radius: 100%;
        }

        &.current-day {
            .day-number {
                color: var(--calendar-accent-color);
            }
        }

        &.selected {
            .day-number {
                z-index: 2;
                color: var(--text-on-highlight-color);
                background-color: var(--calendar-accent-color) !important;
                border-radius: 100%;
                box-shadow: rgba(150, 150, 150, 0.7) 0 0 14px;
            }
        }

        &.filter-start-range::after,
        &.filter-end-range::after,
        &.filter-start-range::before,
        &.filter-end-range::before,
        &.filter-range::before,
        &.filter-period::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            height: 28px;
            background-color: rgba(var(--highlight-color-rgb), 0.3);
            z-index: 0;
        }

        &.filter-period {
            position: relative;
        }

        &.filter-start-range,
        &.filter-end-range {
            position: relative;

            .day-number {
                z-index: 2;
                color: var(--text-on-highlight-color);
                background-color: rgba(var(--highlight-color-rgb), 0.8);
                border-radius: 100%;
            }
        }

        &.filter-start-range::after,
        &.filter-end-range::after {
            background-color: var(--text-on-highlight-color);
            border-radius: 100%;
            z-index: 1;
            width: 28px;
            left: auto;
            right: auto;
        }

        &.filter-start-range::before {
            left: 50%;
        }

        &.filter-end-range::before {
            right: 50%;
        }
    }
}
