swipe {
    display: block;
}

.swipe {
    position: relative;
    height: @swipe-height;
    width: @swipe-width;
    margin: 0 auto;
    padding-top: 15px;
    padding-bottom: 15px;
    text-transform: uppercase;
    transform: translate3d(0, 0, 0);

    .swipe-container {
        width: 100%;
        height: @swipe-height;
        display: block;
        border-radius: (@swipe-height/2);
        background: @checkbox-unchecked-color;
        color: #fff;
        font-weight: 200;
        position: relative;
        border: none;
        padding: 0;
        overflow: hidden;
        transform: translate3d(0, 0, 0);

        &.swiping, &.swiping:after {
            transition: all .3s ease-in-out;
        }

        span {
            height: @swipe-height;
            line-height: @swipe-height;
            text-align: center;
            width: 100%;
            display: block;
            position: relative;
            font-size: 13px;

            &.swiped {
                width: @swipe-width - @swipe-height;
                left: @swipe-dot-spacing;
            }

            &.initial {
                left: @swipe-height - @swipe-dot-spacing;
                width: @swipe-width - @swipe-height;
            }
        }

        &:after {
            display: block;
            height: @swipe-height - @swipe-dot-spacing;
            width: @swipe-height - @swipe-dot-spacing;
            border-radius: 100%;
            content: '';
            position: absolute;
            left: (@swipe-dot-spacing / 2);
            top: (@swipe-dot-spacing / 2);
            background: #fff;
        }

        &.disabled {
            opacity: 0.3;
            pointer-events: none;
        }

        &.swiped {
            background: @checkbox-checked-color;
        }

        &.swiped, &.loading {
            &:after {
                transform: translate3d((@swipe-width - @swipe-height), 0, 0);
            }
        }
    }
}