.multiple-selector {
    .multiple-selector-selection-area {
        padding: 10px 0 5px 0;

        &.disabled {
            background: rgba(0, 0, 0, 0.4);
            opacity: 0.5;
        }

        .multiple-selector-selection {
            display: inline-block;
            height: 25px;
            line-height: 25px;
            box-sizing: border-box;
            padding: 0 8px;
            font-size: 12px;
            font-style: italic;
            margin: 0 5px 5px 0;
            background: rgba(0, 0, 0, 0.06);
            position: relative;
            font-weight: 600;

            > i {
                margin-left: 5px;
            }

            &.locked {
                > i {
                    opacity: .4;
                }
            }
        }
    }
}

@import "_DepartmentSelector.less";
@import "_UserGroupSelector.less";