date-field {
    display: flex;

    input.datetime {
        border-right-width: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    input.time {
        max-width: 150px;
        border-left-width: 0;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    input.rl-input {
        position: relative;
        -webkit-appearance: none;
        color: var(--background-highlight-color);

        &:before {
            font-size: 16px;
            color: var(--general-text-color);
            content: attr(placeholder);
            position: absolute;
            top: 12px;
        }

        &:focus, &.ng-not-empty {
            color: var(--general-text-color);
        }

        &:focus:before, &.ng-not-empty:before {
            display: none;
        }
    }
}