
rich-mention-textarea {
    position: relative;
    display: block;
    background-color: var(--general-background-color);
    min-height: 253px;

    @media @desktop-up {
        min-height: 299px;
    }

    .spinner {
        position: absolute;
        z-index: 1;
        top: 50%;
        left: 50%;
        margin-left: -25px;
        margin-top: -25px;
        opacity: 0.5;
    }

    user-mention, department-mention, user-group-mention, hash-tag {
        color: var(--highlight-color);
        font-weight: bold;
    }
}

.tribute-container {
    border: 1px solid var(--general-border-color);
    border-radius: var(--general-border-radius);
    box-shadow: 0 7px 15px rgba(60, 72, 88, 0.1);
    width: 300px;

    ul {
        background: var(--general-background-color);
        margin: 0;
    }

    li {
        display: flex;
        position: relative;

        &:hover, &.highlight {
            background: var(--background-highlight-color);
        }
        
        .away-icon {
            position: absolute;
            left: 26px;
            top: 26px;
            border-radius: 50%;
            width: 16px;
            height: 16px;
            font-size: 16px;
            z-index: 1;
            backdrop-filter: blur(13px);
            color: var(--general-text-inactive-color);
        }

        img {
            display: inline-block;
            width: 36px;
            height: 36px;
            border-radius: 100%;
            margin-right: 10px;
            
            &.away {
                opacity: 0.6;
            }
        }

        .tag-label {
            display: flex;
            justify-content: center;
            align-items: center;

            min-width: 34px;
            height: 34px;
            margin-right: 10px;
            font-size: 20px;
            border-radius: 100%;
            color: var(--text-on-highlight-color);
            background: var(--highlight-color);
        }

        .right {
            display: flex;
            flex-direction: column;
            justify-content: center;
            overflow: hidden;

            > span {
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }

            .title {
                font-size: 13px;
                font-weight: 600;

                .fa-star {
                    color: var(--alert-color);
                    vertical-align: middle;
                }
            }
        }
    }
}