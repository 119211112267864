pin-code-secure-page {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;

    > header {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
        height: 40%;
        background: url('/Content/Graphics/phone.png') center bottom no-repeat;

        rl-icon {
            font-size: 60px;
            margin-bottom: 20px;
        }

        .pin-code-input-wrapper {
            position: relative;
            margin-left: -10px;
            margin-bottom: 60px;

            .pin-code-number {
                width: 40px;
                height: 40px;

                &.set:after {
                    background: var(--confirmation-color);
                }
            }
        }
    }

    section {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 1;
        width: 100%;
        padding: 30px 25px;
        box-sizing: border-box;
        background: white;

        h2 {
            margin: 0 0 10px 0;
            font-size: 20px;
            color: var(--general-text-color);
        }

        p {
            margin: 0;
            font-size: 15px;
            color: var(--general-text-color);
        }

        button {
            display: block;
            width: 100%;
            padding: 0;
            text-align: center;

            &.create {
                height: 45px;
                font-weight: 500;
                text-transform: uppercase;
                background: var(--highlight-color);
                color: var(--text-on-highlight-color);
            }

            &.skip {
                padding: 10px 0;
                font-size: 13px;
                opacity: 0.5;
                color: var(--general-text-color);
            }
        }
    }
}
