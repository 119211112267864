native-upgrade {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 99999;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    flex-direction: column;
    padding: 25px;
    background-color: var(--general-background-color);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;

    .native-upgrade-inner {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 25px 15px;
        background: var(--general-background-color);
        color: var(--general-text-color);
        box-shadow: 0 2px 15px 0 ~'rgba(var(--general-text-color-rgb), .1)';
        text-decoration: none;

        h2 {
            margin: 0;
            padding: 0;
        }

        p {
            margin: 15px 0 0 0;
            padding: 0;
        }

        .btn {
            display: block;
            margin: 15px 0 0 0;
        }
    }
}
