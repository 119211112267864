social-comments-popup {
    .loading-wrapper {
        background: ~'rgba(var(--background-highlight-color-rgb), 0.1)';
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: calc(~'100% - 55px');
        left: 0;
        top: 0;
        position: absolute;
        z-index: 10;
    }
}