department-usergroup-selector {
    display: flex;
    box-sizing: border-box;
    width: 100%;
    min-height: 50px;
    padding: 0 10px 5px 40px;
    background: var(--general-background-color);
    color: var(--general-text-color);

    .bg {
        display: block;
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 10;
        background: rgba(0, 0, 0, 0.6);

        &.animated {
            animation-duration: 0.2s;
        }
    }

    .select-category {
        position: fixed;
        z-index: 11;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        background: var(--general-background-color);

        .permission-selector-header {
            padding: 15px;

            .active {
                color: var(--highlight-color);
                padding-bottom: 6px;
                border-bottom: solid 2px var(--highlight-color);
                font-weight: 600;
            }
        }

        @media @desktop-up {
            box-shadow: 0 2px 15px rgba(0, 0, 0, .1);
            z-index: 11;
            top: 20%;
            left: calc(57vw - var(--sidemenu-width));
            width: 500px;
            height: 650px;
        }
    }

    .click-area {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;

        .selected-item {
            line-height: 30px;
            background: var(--background-highlight-color);
            overflow: hidden;
            padding: 0 8px;
            margin: 5px 10px 0 0;
        }
    }

    .selected-items {
        display: flex;
        flex-wrap: wrap;
        padding: 0 0 10px 0;
        align-items: center;

        li {
            display: flex;
            align-items: center;
            box-sizing: border-box;
            height: 30px;
            max-width: 100%;
            padding: 0 10px;
            margin-right: 10px;
            margin-bottom: 10px;
            line-height: 30px;
            background: var(--background-highlight-color);
            position: relative;
            overflow: hidden;

            &.more-elements-count {
                background: transparent;
            }

            &.default {
                padding: 0;
                margin: 0;
                background: transparent;
            }

            p {
                margin: 0;
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                position: relative;
                font-size: 15px;

                span {
                    display: none;
                }
            }

            &.with-subtitle {
                p {
                    margin-top: -10px;
                    font-size: 14px;

                    span {
                        display: block;
                        line-height: 14px;
                        position: absolute;
                        font-size: 11px;
                        top: 20px;
                        width: 100%;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        opacity: .5;
                    }
                }

                .remove-item {
                    padding-left: 10px;
                }
            }
        }

        .remove-item {
            position: relative;
            top: 1px;
            font-weight: 600;
            color: var(--warning-color);
            padding: 1px 2px 1px 8px;
            font-size: 15px;
        }
    }

    .selector-area {
        padding: 15px;
    }
}
