.native .survey-breakdown button {
    bottom: 51px;
    bottom: calc(51px + env(safe-area-inset-bottom, 0px));
}

survey-breakdown {
    display: block;
}

.survey-breakdown {
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 50px;
    z-index: 2;
    -webkit-transition: all ease-in-out .3s;
    -o-transition: all ease-in-out .3s;
    transition: all ease-in-out .3s;

    &.ng-enter,
    &.ng-leave.ng-leave-active {
        opacity: 0;
    }

    &.ng-leave,
    &.ng-enter.ng-enter-active {
        opacity: 1;
    }

    .survey-breakdown-inner {
        padding: 15px 15px 0 15px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        overflow-y: auto;
        overflow-x: hidden;
        position: relative;
        height: 100%;
        box-sizing: border-box;
    }

    h2 {
        margin: 0;
        font-size: 20px;
    }

    .breakdown-percentage {
        margin: 5px 0 0 0;
    }

    ul {
        display: block;
        list-style: none;
        padding: 0;
        margin: 20px 0 10px 0;

        li {
            display: block;
            border: 2px solid var(--confirmation-color);
            background: ~'rgba(var(--confirmation-color-rgb), 0.15)';
            box-sizing: border-box;
            padding: 10px;
            margin-bottom: 10px;

            &.wrong {
                border-color: var(--warning-color);
                background: ~'rgba(var(--warning-color-rgb), 0.15)';

                h4 {
                    color: var(--warning-color);
                }
            }

            h4 {
                margin: 0;
                color: var(--confirmation-color);
            }

            p {
                margin: 10px 0 0 0;

                &:first-child {
                    margin-top: 15px;
                }

                .answer-text {
                    font-weight: 600;
                    color: var(--confirmation-color);

                    &:first-child {
                        margin-left: 5px;
                    }

                    &.wrong-option {
                        color: var(--warning-color);
                        text-decoration: line-through;
                    }
                }
            }
        }
    }

    .submit {
        position: relative;
        bottom: 0;
        margin: 0 -15px;

        rl-button {
            position: relative;
            z-index: 2;
        }

        &.submitted:after {
            z-index: 0;
        }
    }
}