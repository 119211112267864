@import "../../Import.less";

.chart-header {
    h1, h2, h3, h4, h5, h6{
        margin: 0 0 5px 0;
        padding: 0;
        font-size: 1em;
        font-weight: @font-weight;

        .chart-help-bubble{
            display: inline-block;
            height: 12px;
            width: 12px;
            line-height: 12px;
            text-align: center;
            border-radius: 100%;
            border: 1px solid #000;
            position: relative;
            top: -2px;
            left: 5px;
            font-size: 8px;
            > i { font-size: 8px; }
        }
    }
}

