select-repeat {
    display: block;
    position: relative;
    width: 100%;

    .click-area {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 100%;

        span::first-letter {
            text-transform: uppercase;
        }
        
        &.disabled {
            opacity: 0.5;
            pointer-events: none;
        }
    }

    .repeat-selector {
        position: fixed;
        z-index: 1000;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        background: var(--background-highlight-color);
        display: flex;
        flex-direction: column;

        header {
            display: flex;
            align-items: center;

            h1 {
                width: 100%;
                text-align: center;
                margin: 0;
                font-size: 15px;
                text-transform: uppercase;
            }
        }

        .back {
            padding: 0;
            font-size: 30px;
            line-height: 1;
            border: none;
            background: none;
        }
    }
    
    .repeat-form {
        display: flex;
        flex: 1;
        flex-direction: column;
        overflow-y: auto;
        
    }

    .help-text {
        box-sizing: border-box;
        padding: 15px 10px 7px;
        font-size: 13px;
        opacity: 0.4;
    }

    .repeat-options,
    .repeat-option {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 var(--spacing);
        box-sizing: border-box;
        background: var(--general-background-color);
        border-top: 1px solid ~'rgba(var(--general-text-color-rgb), .1)';

        &.disabled {
            position: relative;
            opacity: .5;
            pointer-events: none;

            &:after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background: ~'rgba(var(--general-text-color-rgb), .2)';
            }
        }

        &.selected {
            span {
                font-weight: 500;
            }
        }

        &.week-selector {
            padding: var(--spacing);
            gap: var(--s-spacing);

            .week-option {
                display: flex;
                flex: 1 0 0;
                justify-content: center;
                font-size: 1rem;
                font-weight: 400;
                padding: var(--m-spacing) 0;
                border: 1px solid var(--general-border-color);
                border-radius: 7px;
                
                &.selected {
                    border-color: var(--highlight-color);
                    font-weight: 700;
                    color: var(--highlight-color);
                    background-color: rgba(var(--highlight-color-rgb), 0.2);
                }
            }
        }

        &.month-selector {
            flex-direction: column;

            &.repeat-option {
                padding: var(--spacing);
                gap: var(--spacing);
            }

            .monthly-option {
                cursor: pointer;
                width: 100%;
                display: flex;
                box-sizing: border-box;
                align-items: center;
                justify-content: flex-start;
                gap: 10px;
                
                label {
                    padding-left: 0;
                }

                .title {
                    flex: 1 0 auto;
                }

                &.disabled {
                    opacity: .5;
                }
            }
        }

        &.week-selector,
        &.month-selector,
        &.special {
            border-bottom: 1px solid ~'rgba(var(--general-text-color-rgb), .1)';
        }

        &.special {
            gap: 5px;

            @media @desktop-up {
                gap: 0;
            }
        }

        &.input {
            input {
                padding: 0;
                height: 48px;
                text-align: right;
            }
        }

        rl-icon {
            color: var(--highlight-color);
        }
    }

    .period-label {
        white-space: nowrap;
    }

    .repeat-options {
        justify-content: space-between;
        align-items: stretch;
        flex-direction: column;
        padding: 0;
        
        &.title {
            font-weight: 400!important;
        }

        .repeat-option:last-of-type {
            border-bottom: 1px solid ~'rgba(var(--general-text-color-rgb), .1)';
        }
    }

    .selectors-container {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        gap: var(--spacing);
        width: 100%;

        rl-select {
            width: 50%;

            span {
                line-height: unset !important;
            }

            .radio {
                display: none !important;
            }
        }
    }

    rl-select-list {
        .item {
            flex-direction: row-reverse;
            padding: 0 var(--spacing);
            opacity: 0.5;
            gap: 10px;

            &.selected {
                opacity: 1;

                .title {
                    color: var(--general-text-color) !important;
                    font-weight: 400;
                }
            }

            @media @desktop-up {
                height: 45px;

                .title {
                    font-weight: 400;
                    font-size: 1rem;
                }
            }
        }
    }
}
