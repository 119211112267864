event-status .status {
    padding: 3px 6px 3px 3.5px;

    border-radius: 3px;
    background: rgba(var(--white-color-rgb), 0.6);
    backdrop-filter: blur(24px);

    font-weight: 700;
    font-size: 10px;
    line-height: 130%;


    rl-icon.circle {
        align-self: center;

        width: 11.5px;
        height: 10.5px;

        margin-right: 4px;

        font-size: 7px;
        color: var(--white-color);

        &.attending, &.completed {
            background: var(--confirmation-color);
        }

        &.few-spots {
            background: var(--alert-color);
        }

        &.fully-booked, &.cancelled {
            background: var(--warning-color);
        }
    }
}