@import "../../../Content/Styles/Import.less";

attachment-preview, attachments-viewer {
    .popup-wrapper {
        z-index: 401;
    }

    .popup-content-inner {
        background: rgba(0, 0, 0, 1);
    }

    header-popup {
        background: #000;
        position: relative;
        z-index: 10;

        rl-icon {
            color: white;
        }

        title-slot > div {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;

            rl-icon[icon='arrow-up'] {
                transform: rotate(45deg);
            }

            rl-icon[icon='line'] {
                transform: rotate(-45deg);
                font-size: 15px;
            }

            button {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0;
                width: 30px;
                height: 30px;
            }
        }
    }
}

.attachments-viewer {
    &.show {
        opacity: 1;
    }

    ::-webkit-scrollbar {
        width: 8px; /* Width of the scrollbar */
        height: 8px; /* Height of the scrollbar */
    }

    /* Track */

    ::-webkit-scrollbar-track {
        background-color: transparent;
    }

    /* Handle */

    ::-webkit-scrollbar-thumb {
        background-color: rgba(136, 136, 136, 0.5); /* Color and opacity of the scrollbar handle */
        border-radius: 16px; /* Rounded corners of the scrollbar handle */
        opacity: 0.5; /* Opacity of the scrollbar handle */
        height: 50px; /* Height of the scrollbar handle */
    }

    /* Handle on hover */

    ::-webkit-scrollbar-thumb:hover {
        background-color: rgba(85, 85, 85, 0.5); /* Color and opacity of the scrollbar handle on hover */
        opacity: 0.7; /* Opacity of the scrollbar handle on hover */
    }

    .attachment-thumbs {
        position: absolute;
        text-align: center;
        transition: .3s opacity;
        transform: translate3d(0, 0, 0);
        z-index: 10;
        bottom: 5px;
        left: 50px;
        right: 50px;

        @media @desktop-up {
            display: flex;
            overflow-x: scroll;
            bottom: 15px;

            &.center-items {
                justify-content: center;
            }
        }

        button {
            width: 45px;
            height: 45px;
            border-radius: 0;
            border: none;
            background-size: cover !important;
            background-repeat: no-repeat;
            background-position: center !important;
            margin-right: 4px;
            margin-left: 4px;
            padding: 0;
            cursor: pointer;
            position: relative;

            @media @desktop-up {
                padding: 0 20px;
            }

            &.active {
                &:after {
                    position: absolute;
                    content: "";
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    background: var(--highlight-color) !important;
                    opacity: .3;
                }
            }

            &:after {
                position: absolute;
                background: rgba(244, 245, 246, .2);
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                content: '';
            }

            .attachment-type-styles();

            &.file-type-video,
            &.file-type-image {
                .inner {
                    visibility: hidden;
                }

                &:after {
                    background: rgba(244, 245, 246, .2);
                }

            }
        }
    }

    .empty-media {
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;

        position: absolute;
        left: 30px;
        right: 30px;
        height: 200px;
        top: 50%;
        margin-top: -100px;
        padding: 15px;

        border: 1px solid fade(#fff, 40);
        color: white;
        text-align: center;
    }

    .media-viewer-wrapper {
        &.editable {
            .media-viewer-download {
                display: none;
            }

            .media-viewer-close {
                left: 10px;
                right: auto;
            }
        }

        .media-viewer-download,
        .media-viewer-close {
            position: absolute;
            z-index: 2;
            top: 10px;
            color: #fff;
            font-size: 26px;
            background: none;
            border: none;
            padding: 0;
        }

        .media-viewer-download {
            left: 10px;
        }

        .media-viewer-close {
            right: 10px;
        }

        .footer-actions {
            position: absolute;
            width: 100%;
            height: 55px;
            bottom: 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
            box-sizing: border-box;
            background: #000;

            > button {
                width: 45px;
                padding: 0;
                margin: 0;
                color: white;
            }
        }

        .media-viewer-content {
            display: block;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 55px;
            top: 55px;
            background: #000;

            .image-zoom {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;

                .image {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    .flex-center-center();
                }
            }

            .media-view-single-content {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                box-sizing: border-box;
                .flex-center-center();

                media {
                    width: 100%;
                }
            }

            .media-view-content-files {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                box-sizing: border-box;
                padding: 10px;
                color: #fff;

                h2 {
                    display: block;
                    text-align: center;
                    margin: 0 0 10px 0;
                }

                > ul {
                    display: block;
                    padding: 0;
                    margin: 0;
                    list-style: none;

                    > li {
                        display: block;
                        padding: 5px 30px 5px 35px;
                        margin-right: 10px;
                        list-style: none;
                        position: relative;
                        border-top: 1px solid rgba(255, 255, 255, 0.3);

                        &:first-child {
                            border-top: none;
                        }

                        > a {
                            color: #fff;
                            text-decoration: none;
                        }

                        .file-icon {
                            position: absolute;
                            left: 0;
                            top: 7px;
                            font-size: 24px;
                            opacity: .8;
                        }

                        h3 {
                            display: block;
                            margin: 0;
                            padding: 0;
                            font-size: 1em;
                        }

                        span {
                            display: block;
                            margin: 0;
                            padding: 0;
                            font-size: .8em;
                            opacity: .7;
                        }

                        .download-icon {
                            position: absolute;
                            right: 0;
                            top: 10px;
                            font-size: 24px;
                            opacity: .3;
                        }
                    }
                }
            }
        }

        .spinner {
            border-color: white;
            position: absolute;
            top: 50%;
            left: 50%;
            margin-top: -25px;
            margin-left: -25px;
        }
    }

    .image-editor {
        #image-editing-container {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            left: 0;
            right: 0;
            bottom: 0;
            top: 55px;

            .custom {
                position: absolute;
                display: inline-block;
                padding: 0;
                border: none;
                background: transparent;
                resize: none;
                height: auto;
                appearance: none;
                outline: none;
                overflow: hidden;
                text-align: center;
                letter-spacing: 0;
                min-width: 1px;
                line-height: 1.3;
            }
        }

        .draw-size-picker {
            position: absolute;
            width: 100%;
            top: 55px;
            display: flex;
            gap: 15px;
            padding: 10px 0;
            justify-content: center;
            background: rgba(0, 0, 0, .4);
            color: var(--white-color);
        }

        .color-picker {
            position: absolute;
            width: 100%;
            bottom: calc(var(--sab) + 20px);
            display: flex;
            gap: 10px;
            justify-content: center;

            > div {
                display: flex;
                align-items: center;
                justify-content: center;

                box-sizing: border-box;
                width: 33px;
                height: 33px;
                border-radius: 50%;
                border: 2.5px solid white;

                rl-icon {
                    color: white;
                }

                &:first-of-type {
                    rl-icon {
                        color: black;
                    }
                }
            }
        }

        .comment-wrapper {
            position: absolute;
            transform: translateX(-50%);
            left: 50%;
            bottom: 50px;

            display: flex;
            justify-content: space-between;
            width: 90%;
            max-width: 350px;

            @media @tablet-up {
                width: 60%;
                max-width: none;
            }

            profile-image {
                filter: drop-shadow(0px 6px 15px rgba(0, 0, 0, 0.15));

                .profile-image {
                    width: 45px;
                    height: 45px;
                }
            }

            .comment {
                flex: 1;
                box-shadow: 0 7px 15px rgba(60, 72, 88, 0.1);
                border-radius: var(--general-border-radius);
                background: var(--general-background-color);

                textarea {
                    display: block;
                    width: 100%;
                    height: 100%;
                    padding: 15px;
                    max-height: 200px;
                    box-sizing: border-box;
                    background: transparent;
                    border: none;
                    resize: none;

                    &::placeholder {
                        color: var(--general-text-inactive-color);
                    }
                }

            }
        }
    }
}

// Swiper styling
.attachments-viewer {
    .media-viewer-swiper {
        &.media-view-single-content {
            .swiper-pagination {
                display: none;
            }
        }

        @media @desktop-up {
            .swiper-pagination {
                display: none;
            }
        }

        ks-swiper-container {
            display: block;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;

            .swiper-container {
                height: 100%;

                .swiper-wrapper {
                    color: #fff;
                    padding-bottom: 0;
                    box-sizing: border-box;

                    .swiper-slide {
                        .delete-media {
                            position: absolute;
                            right: -8px;
                            top: -11px;
                            padding: 5px;
                            margin: 0;
                            font-size: 18px;
                            color: var(--warning-color);
                        }
                    }

                    .media-viewer-swiper-item {
                        width: 100%;
                        height: 100%;
                        .flex-center-center();

                        media {
                            display: block;
                            width: 100%;
                        }

                        .delete-media {
                            top: -5px;
                            right: -5px;
                        }

                        .media-viewer-swiper-item-file {
                            position: relative;
                            color: #fff;
                            text-decoration: none;
                            text-align: center;
                            .flex-center-center();
                            width: ~'calc(100% - 30px)';
                            height: 500px;
                            max-height: ~'calc(100% - 80px)';

                            &.no-background {
                                .inner {
                                    background: #ddd;
                                    color: #000;
                                    padding-bottom: 20px;

                                    .file-icon {
                                        rl-icon {
                                            font-size: 60px;
                                        }
                                    }
                                }
                            }

                            .inner {
                                padding: 10px 25px;
                                border-radius: 4px;
                                height: 100%;
                                width: 100%;
                                display: flex;
                                align-items: center;
                                flex-direction: column;
                                justify-content: center;
                                box-sizing: border-box;

                                .file-icon {
                                    display: block;
                                    font-size: 24px;
                                }

                                .download {
                                    font-weight: 500;
                                }

                                .filename {
                                    display: block;
                                    position: relative;
                                    overflow: hidden;
                                    max-width: 80%;
                                    white-space: nowrap;
                                    text-overflow: ellipsis;
                                    font-size: 14px;
                                    margin-top: 10px;
                                }
                            }

                            &:after {
                                position: absolute;
                                background: rgba(244, 245, 246, .2);
                                top: 0;
                                left: 0;
                                right: 0;
                                bottom: 0;
                                pointer-events: none;
                                content: '';
                            }

                            .attachment-type-styles();
                        }
                    }

                    .swiper-zoom-container {
                        > img {
                            @media @desktop-up {
                                max-width: 80vw;
                                max-height: 80vh;
                            }
                        }
                    }

                    .swiper-zoom-wrapper {
                        width: 100%;
                        height: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        text-align: center;

                        .swiper-zoom-container {
                            position: relative;
                            margin: 15px;
                            height: auto;
                            width: auto;

                            img {
                                width: 100%;
                                max-width: 100%;
                                max-height: calc(~'100vh - 200px');
                                -o-object-fit: contain;
                                object-fit: contain;
                                border-radius: var(--general-border-radius);
                            }

                            .spinner + img + button {
                                display: none;
                            }
                        }
                    }

                    .swiper-slide-zoomed {
                        .swiper-zoom-container {
                            > img {
                                @media @tablet-max {
                                    max-width: 150%;
                                    max-height: 150%;
                                    margin: 0;
                                }
                            }

                            .delete-media {
                                display: none;
                            }
                        }

                        .editable-controls {
                            display: none;
                        }
                    }
                }
            }

            .swiper-pagination-bullet {
                background: #fff;

                &:only-child {
                    display: none;
                }

                @media @desktop-up {
                    width: 60px;
                    height: 60px;
                    border-radius: 0;
                    border: none;
                    background-size: cover;
                    background-repeat: no-repeat;
                    background-position: center;
                }
            }

            .swiper-pagination-bullet-active {
                background: var(--highlight-color);
            }
        }

        .spinner {
            position: absolute;
            border-color: #fff;
        }
    }
}

// attachment type mixin
.attachment-type-styles() {
    // Unknown
    &.file-type-unknown {
        .file-icon rl-icon, .time-left {
            color: #bfc3c8;
        }
    }

    // Video
    &.file-type-video {
        &:after {
            background: ~"rgba(var(--alert-color-rgb), .15)";
        }

        .file-icon rl-icon, .time-left {
            color: var(--alert-color);
        }
    }

    // Image
    &.file-type-image {
        &:after {
            background: rgba(244, 245, 246, .8);
        }
    }

    // Audio
    &.file-type-audio {
        &:after {
            background-color: rgba(125, 74, 141, .15);
            background-image: url('/Content/Graphics/Upload/audio.svg');
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
        }

        .file-icon rl-icon, .time-left {
            color: #7D548D;
        }
    }

    // Word
    &.file-type-doc {
        &:after {
            background: ~"rgba(var(--highlight-color-rgb), .15)";
        }

        .file-icon rl-icon, .time-left {
            color: var(--highlight-color);
        }
    }

    // Excel
    &.file-type-excel {
        &:after {
            background: ~"rgba(var(--confirmation-color-rgb), .15)";
        }

        .file-icon rl-icon, .time-left {
            color: var(--confirmation-color);
        }
    }

    // Powerpoint, PDF
    &.file-type-pdf,
    &.file-type-powerpoint {
        &:after {
            background: ~"rgba(var(--warning-color-rgb), .15)";
        }

        .file-icon rl-icon, .time-left {
            color: var(--warning-color);
        }
    }
}