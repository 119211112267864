manager-view {
    background: var(--general-background-color);
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;

    @media @desktop-up {
        width: 100%;
        margin: 0 auto;
    }

    .header {
        .filter {
            display: flex;
            align-items: center;
            background: var(--background-highlight-color);
            justify-content: space-between;
            padding: 15px 15px 90px;

            input {
                display: block;
                background: transparent;
                border: none;
                border-bottom: 1px solid ~'rgba(var(--general-text-color-rgb), .1)';
                padding: 5px 0;
                font-size: 15px;
                color: var(--general-text-color);
            }
        }

        .overall-progress {
            padding: var(--s-spacing);
            background: var(--general-background-color);
            position: relative;

            .department-name {
                margin-bottom: 10px;
                font-weight: bold;
            }

            .fraction {
                display: none;
            }
        }

        .filter-tabs {
            display: flex;
            align-items: baseline;
            padding: 10px 0 0;

            &.hide-filters {
                .btn-filter {
                    display: none;
                }
            }

            .btn-filter {
                margin-right: 15px;
                font-size: 13px;
                cursor: pointer;
                padding: 15px 0;

                &.active {
                    color: var(--highlight-color);
                    border-bottom: 1px solid var(--highlight-color);
                }

                rl-icon {
                    display: inline-flex;
                    min-width: 20px;
                    min-height: 20px;
                    max-width: 20px;
                    max-height: 20px;
                    line-height: 20px;
                    border-radius: 50%;
                    border: 1px solid ~'rgba(var(--general-text-color-rgb), .1)';
                    align-items: center;
                    color: white;
                    font-size: 16px;
                }
            }

            .overdue-icon {
                background: var(--warning-color);
            }

            .complete-icon {
                background: var(--confirmation-color);
            }
        }
    }

    tasks {
        overflow-y: auto;
        height: 100%;
        scrollbar-width: thin;
        scrollbar-color: rgba(0, 0, 0, 0.15) transparent;
        -webkit-overflow-scrolling: touch;
    }

    task {
        margin-top: 10px;
    }
}

manager-department-popup {
    .manager-department {
        display: flex;
        flex: 1 auto;
        padding: var(--m-spacing);

        @media @desktop-up {
            rl-loading-placeholder {
                margin-right: 0;
            }
        }
    }
}