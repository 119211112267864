@import "../../../Content/Styles/Variables.less";

multiple-permission-selector,
multiple-permission-selector-wrapper,
.permission-selector-popup {
    display: flex;
    flex-direction: column;

    .permission-selector-controls {
        display: flex;
        justify-content: space-between;
        background: var(--general-background-color);

        .remove {
            font-weight: 600;
            font-size: 15px;
            line-height: 25px;
            color: var(--warning-color);
            padding: 0 10px 0;
        }

        #permissions {
            position: relative;
            padding: 0;
            min-height: 50px;

            &.only-default-item {
                align-items: center;
                padding-top: 0;

                li {
                    margin-bottom: 0;
                }
            }

            &.not-multiple-permissions {
                align-items: center;
                padding-top: 0;

                &.has-items {
                    padding-top: 10px;
                    align-items: unset;
                }
            }

            .selected-items {
                margin: 0;
                padding: 0 30px 0 0;
            }

            .placeholder {
                p {
                    margin: 0;
                }
            }
        }
    }

    .popup-content-inner {
        background: var(--general-background-color);
    }

    header {
        display: flex;
        align-items: center;
        background: var(--header-background-color);
        color: var(--header-text-color);

        .save {
            padding: 0;
            font-size: 26px;
            line-height: 1;
            border: none;
            background: none;
        }
    }

    label {
        line-height: 25px;
        flex: 1 1 0;

        &.edited {
            font-weight: 600;
            color: var(--highlight-color);
        }
    }

    .selected-items {
        display: flex;
        flex-wrap: wrap;
        padding: 0 0 10px 0;
        align-items: center;

        li {
            display: flex;
            align-items: center;
            box-sizing: border-box;
            height: 30px;
            max-width: 300px;
            padding: 0 10px;
            margin: 0 10px 10px 0;
            line-height: 30px;
            background: var(--background-highlight-color);
            position: relative;
            overflow: hidden;

            &.more-elements-count {
                background: transparent;
            }

            &.default {
                padding: 0;
                margin: 0;
                background: transparent;
            }

            &.placeholder {
                background: transparent;
                padding: 0;
                margin-bottom: 0;
            }

            p {
                margin: 0;
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                position: relative;
                font-size: 15px;

                span {
                    display: none;
                }
            }

            &.with-subtitle {
                p {
                    margin-top: -10px;
                    font-size: 14px;

                    span {
                        display: block;
                        line-height: 13px;
                        position: absolute;
                        font-size: 11px;
                        top: 20px;
                        width: 100%;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        opacity: .5;
                    }
                }

                .remove-item {
                    padding-left: 10px;
                }
            }
        }

        .remove-item {
            position: relative;
            top: 1px;
            font-weight: 600;
            color: var(--warning-color);
            padding: 1px 2px 1px 8px;
            font-size: 15px;
        }
    }

    .permission-selector {
        alert-message {
            padding: 0 15px 10px 15px;
        }

        .permission-selector-header {
            display: flex;
            margin-bottom: 20px;
            overflow: hidden;
            min-height: 50px;

            button {
                height: 50px;
                flex: 1;
                color: var(--header-text-color);
                background: var(--header-background-color);
                font-weight: 500;

                &.show-validation {
                    color: var(--alert-color);
                }

                &.active {
                    font-weight: 600;

                    .btn-label {
                        border-bottom: 3px solid var(--header-text-color);
                    }
                }

                .btn-label {
                    display: inline-block;
                    min-height: 50px;
                    height: 100%;
                    line-height: 50px;
                    box-sizing: border-box;
                }
            }
        }

        article {
            padding: 0 15px;

            &.show-validation .required-label {
                color: var(--alert-color);
            }

            .required-label {
                margin: var(--m-spacing) 0;
                font-size: 13px;
            }

            .toggle-item {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 5px 0 15px;

                label {
                    padding: 0;
                    font-weight: 400;
                }
            }
        }
    }
}

multiple-permission-selector,
multiple-permission-selector-wrapper,
.permission-selector-popup {
    .permission-selector-controls {
        background: var(--general-background-color);
    }

    #permissions {
        width: 100%;
    }

    .selected-items {
        li {
            border-radius: 7px;

        }
    }
}