@import "Content/ContentCreatePopup/ContentCreatePopup";
@import "Content/ContentCommentsPopup/ContentCommentsPopup";
@import "Content/ContentItem/CountentItem";
@import "Content/ContentSocialSharing/ContentSocialSharingComponent";
@import "Content/ContentSharesPopup/ContentSharesPopupComponent";
@import "Content/Content";
@import "ContentCategories/ContentCategories";
@import "LiveTile/ContentLiveTile";

@content-footer-height: 55px;

.base-view.base-view-content, .base-view.base-view-contentItem {
    background: var(--background-highlight-color);
    height: auto;
}

content-wrapper {
    display: block;
    position: relative;

    module-search-filters {
        &.sticky {
            background-color: var(--background-highlight-color);
        }
        
        @media @tablet-max {
            padding-left: var(--spacing);
            padding-right: var(--spacing);
        }
    }

    .content-wrapper {
        padding-top: var(--m-spacing);

        @media @tablet-max {
            padding-left: var(--spacing);
            padding-right: var(--spacing);
        }
    }

    .spinner {
        position: fixed;
        width: 50px;
        height: 50px;
        border-color: var(--general-text-color);
        top: 50%;
        left: 50%;
        margin-top: -25px;
        margin-left: -25px;

        opacity: 0.4;

        @media @desktop-up {
            left: calc(50% + 140px);
        }

        @media @desktop-big-up {
            left: calc(50% + 180px);
        }
    }

    .no-content {
        position: relative;
        margin-top: 90px;
        top: unset;
        left: unset;
        right: unset;
    }

    .go-to-feed {
        display: block;
        padding-bottom: 20px;
    }
}

