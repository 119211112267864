social-wall {
    display: block;

    .show-new-posts {
        overflow: hidden;
        position: fixed;
        z-index: 10;
        top: 70px;
        left: 50%;
        transform: translateX(-50%);

        display: flex;
        align-items: center;
        padding: 5px 15px;

        color: var(--text-on-highlight-color);
        background: var(--highlight-color);
        font-weight: 500;

        border-radius: 15px;
        box-shadow: 0 2px 10px 0 ~'rgba(var(--general-text-color-rgb), 0.5)';

        @media @desktop-up {
            margin-left: 140px;
        }

        @media @desktop-big-up {
            margin-left: 180px;
        }

        rl-icon {
            padding-right: 10px;
            font-size: 15px;
        }
    }

    .hidden-post {
        padding: 15px;

        .hidden-content {
            display: flex;
            flex-wrap: wrap-reverse;
            padding-bottom: 10px;
        }

        .blocked-user-image {
            margin-right: 10px;
            width: 34px;
            height: 34px;
        }
    }
}

.social-page {
    sorting {
        padding-top: 15px;
    }

    .lazy-placeholder {
        display: block;
        margin: var(--spacing) auto;
        max-width: 600px;
        background: var(--general-background-color);
        border-radius: var(--general-border-radius);

        @media @desktop-up {
            max-width: 60vw;
        }

        rl-loading-placeholder {
            display: block;
            box-sizing: border-box;
            padding: 15px;

            .placeholder-shimmer {
                height: 19px;
            }
        }
    }
}
