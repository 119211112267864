.question-wrapper:not(.stack-below) {
    lesson-swipe-card .card-wrapper.answered {
        .card {
            margin-top: calc(var(--m-spacing) * 2 + var(--spacing));
            transition: transform 0.3s;

            @media @desktop-up {
                margin-top: calc(var(--m-spacing) * 2);
            }

            &.show-true {
                transform: rotate(9deg);
                transform-origin: bottom right;
                @media @desktop-up {
                    transform: rotate(4deg);
                }
            }

            &.show-false {
                transform: rotate(-9deg);
                transform-origin: bottom left;
                @media @desktop-up {
                    transform: rotate(-4deg);
                }
            }

            .answer-highlight {
                opacity: 1;
            }

            footer.desktop {
                display: none;
            }
        }

        &.more-cards-1 {
            .card {
                @media @tablet-max {
                    margin-top: calc(var(--m-spacing) * 2 + var(--spacing) * 2);
                    margin-bottom: var(--spacing);
                }
                @media @desktop-up {
                    margin-top: calc(var(--m-spacing) * 2 + var(--spacing));
                }
            }

            @media @desktop-up {
                footer.desktop {
                    padding: calc(var(--spacing) * 4) 0 calc(var(--spacing) * 2) 0;
                }
            }
        }

        &.more-cards-2 {
            .card {
                @media @tablet-max {
                    margin-top: calc(var(--m-spacing) * 2 + var(--spacing) * 3);
                    margin-bottom: calc(var(--spacing) * 2);
                }
                @media @desktop-up {
                    margin-top: calc(var(--m-spacing) * 2 + var(--spacing) * 2);
                }
            }

            @media @desktop-up {
                footer.desktop {
                    padding: calc(var(--spacing) * 4) 0 calc(var(--spacing) * 2) 0;
                }
            }
        }
    }
}

lesson-swipe-card {
    .card-wrapper {
        position: relative;

        &.more-cards-1 {
            &:not(.answered) {
                @media @desktop-up {
                    footer.desktop {
                        padding: calc(var(--spacing) * 4) 0 0 0;
                    }
                }
            }
        }

        &.more-cards-2 {
            &:not(.answered) {
                @media @desktop-up {
                    footer.desktop {
                        padding: calc(var(--spacing) * 4) 0 0 0;
                    }
                }
            }

            &.answered {

            }
        }

        &:not(.answered) .card {
            touch-action: none;
        }
    }

    .card {
        display: flex;
        flex-direction: column;
        position: relative;

        min-height: 100vw;

        background: var(--general-background-color);
        border-radius: var(--general-border-radius);
        box-shadow: 0 10px 10px -5px var(--shadow-color);


        @media @desktop-up {
            min-height: 88cqw;
        }

        &:not(.with-image) {
            .img-placeholder {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;

                opacity: 0.7;
                font-size: 200px;
                color: var(--background-highlight-color);
            }

            .answer-highlight {
                position: absolute;
                width: calc(100% - var(--spacing) * 2);
                height: 65px;
                text-align: center;
            }

            .content {
                position: relative;
                flex: 1;

                h3 {
                    margin: auto 0;
                    text-align: center;
                    opacity: 1;
                    transition: opacity 0.3s;
                }
            }
        }

        &.with-image {
            .image-wrapper {
                position: relative;
                height: 48vw;

                @media @desktop-up {
                    height: 53cqw;
                }

                .answer-highlight {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }

                img {
                    border-top-left-radius: var(--general-border-radius);
                    border-top-right-radius: var(--general-border-radius);
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                    object-position: center;
                    user-select: none;
                }
            }

            .content {
                flex: 1;
            }
        }

        &.correct {
            border: 1px solid var(--confirmation-color);
        }

        &.wrong {
            border: 1px solid var(--warning-color);
        }

        .answer-highlight {
            &.show-true rl-icon[icon=save] {
                display: inline-flex;
            }

            &.show-false rl-icon[icon=close] {
                display: inline-flex;
            }
        }

        .answer-highlight {
            opacity: 0;

            rl-icon {
                display: none;
                width: 65px;
                height: 65px;
                color: var(--white-color);
                font-size: 36px;
            }
        }

        .content {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            padding: var(--spacing);

            @media @desktop-up {
                padding: calc(var(--m-spacing) * 2);
            }

            h3 {
                margin: 0;
                font-size: 20px;
                font-weight: 700;
                line-height: 130%;
            }
        }
    }

    rl-icon {
        &[icon=close] {
            background: var(--warning-color);
        }

        &[icon=save] {
            background: var(--confirmation-color);
        }
    }

    footer {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        padding-top: var(--spacing);

        &.desktop {
            padding: calc(var(--spacing) * 2) 0;
        }

        .swipe-answer {
            display: flex;
            flex-direction: column;
            position: relative;

            &.true {
                align-items: flex-end;
            }

            &.false {
                align-items: flex-start;
            }

            > span {
                margin: 0 auto;
            }
        }

        rl-icon {
            margin: 0 var(--spacing) var(--s-spacing) var(--spacing);
            color: var(--white-color);
            font-size: 21px;

            @media @desktop-up {
                width: 40px;
                height: 40px;
                font-size: 28px;
            }
        }
    }

    .answer {
        display: flex;
        justify-content: space-around;
        height: calc(var(--m-spacing) * 2);
        @media @tablet-max {
            padding-top: var(--spacing);
        }

        > span {
            font-size: 13px;
        }

        .change-answer {
            display: flex;
            justify-content: center;

            button.transparent {
                height: auto;
                min-width: auto;
                padding: 0;
                font-size: 13px;
                color: var(--general-text-color);

                rl-icon {
                    font-size: 15px;
                }
            }
        }
    }
}