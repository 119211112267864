how-to-guide {
    .popup-wrapper.open {
        .popup-content {
            left: 10%;
            top: 20%;
            width: 80%;
            height: 60%;

            @media @desktop-up {
                left: 50%;
            }

            @media @tablet-only {
                left: 50%;
                width: 100%;
            }
        }
    }


    .how-to-guide {
        text-align: center;

        media {
            .image {
                img {
                    width: 100%;
                    object-fit: cover;
                }
            }
        }

        rl-rich-preview {
            margin: 15px 15px 0 15px;
        }

        button {
            color: @clr-highlight;
            margin-top: 10px;
            margin-bottom: 35px;
        }
    }
}
