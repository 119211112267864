add-event-update {
    .popup-content {
        background: var(--background-highlight-color);
    }

    form {
        position: relative;
        height: 100%;
        padding-bottom: 75px;

        header-popup {
            button {
                &[disabled], &.inactive {
                    opacity: .5;
                }
            }
        }

        language-selector {
            width: 100%;
            border-bottom: 1px solid var(--general-border-color);
        }
    }

    .event-update-content {
        position: static;
        display: flex;
        flex-direction: column;
        height: calc(100% - 55px);
        
        .languages {
            display: flex;
            
            language-selector {
                flex: 1;
                overflow: hidden;
            }
        }

        .textarea-wrapper {
            flex: 1 1 auto;
            position: relative;
            overflow-y: auto;
            display: flex;

            .event-update-description {
                height: 100%;
                padding: 10px;
                border: none;
                resize: none;
            }
        }

        .attachments-wrapper {
            padding: 10px;
            background: var(--general-background-color);

            .attachments-label {
                padding-top: 10px;
                margin-bottom: 10px;
            }
        }
    }

    .save button {
        color: var(--white-color);
        background: var(--button-background-color);
    }
    
    button {
        text-transform: uppercase;
    }
}