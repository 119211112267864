module-search-filters {
    display: flex;
    flex-direction: column;
    gap: 10px;

    padding: var(--s-spacing) 0 var(--m-spacing) 0;

    &.sticky {
        z-index: 10;
        position: sticky;
        top: 55px;

        @media @desktop-up {
            top: 60px;
        }
    }

    .filters {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
        overflow-x: auto;

        @media @desktop-up {
            overflow-x: visible;
            display: flex;
            flex-wrap: wrap;
        }

        > * {
            order: 1;
            flex-shrink: 0;

            &.active {
                order: -1;
            }
        }
    }

    scroll-shadow[direction=horizontal] {
        .gradient {
            &.last {
                background-image: linear-gradient(to right, hsla(0, 0%, 100%, 0), var(--background-highlight-color));
            }

            &.first {
                background-image: linear-gradient(to left, hsla(0, 0%, 100%, 0), var(--background-highlight-color));
            }
        }
    }

    rl-button.clear-filters button {
        display: flex;
        flex-direction: row-reverse;
        justify-content: center;
        align-items: center;

        min-width: auto;
        height: auto;
        padding: 10px;

        font-size: 13px;
        font-weight: 400;
        color: var(--warning-color);

        rl-icon {
            margin-right: 5px;
            font-size: 12px;
        }
    }
}
