@import "../Import.less";

@department-viewer-header-height: 70px;
@department-viewer-header-image-height: 200px;
.multidepartment-picker-modal {
    header-popup {
        @media @desktop-up {
            .buttons {
                display: none;
            }
        }

        .title h1 {
            text-align: left;
        }
    }

    [ng-transclude] {
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .modal-select-picker {
        display: flex;
        flex: 1;
        overflow-y: auto;

        ul {
            width: 100%;
        }
    }

    .modal-bottom {
        justify-content: space-between;
        display: flex;
        width: 100%;

        @media @iphone-x {
            padding-bottom: 21px;
        }
    }
}

.department-viewer-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: @level-department-viewer;
    background: rgba(0, 0, 0, 0.5);
    display: block;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    opacity: 0;

    &.show {
        opacity: 1;
    }
}

.department-viewer {
    position: absolute;
    top: 3px;
    right: 3px;
    bottom: 3px;
    left: 3px;
    display: block;
    z-index: @level-department-viewer + 1;

    .department-viewer-content {
        opacity: 0;
        -moz-transform: translate3d(0, 20%, 0);
        -ms-transform: translate3d(0, 20%, 0);
        -o-transform: translate3d(0, 20%, 0);
        -webkit-transform: translate3d(0, 20%, 0);
        transform: translate3d(0, 20%, 0);
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        -moz-transition: all 0.3s;
        -o-transition: all 0.3s;
        -webkit-transition: all 0.3s;
        transition: all 0.3s;
        background: #fff;
        border-radius: 4px;
        overflow: hidden;
        //box-shadow: 0 8px 17px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
    }

    &.show {
        .department-viewer-content {
            -moz-transform: translate3d(0, 0, 0);
            -ms-transform: translate3d(0, 0, 0);
            -o-transform: translate3d(0, 0, 0);
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
            opacity: 1;
        }
    }

    &.has-image {
        .department-viewer-department {
            .department-viewer-header-background {
                height: @department-viewer-header-image-height;
            }

            .department-viewer-header {
                height: @department-viewer-header-image-height;
                background: rgba(0, 0, 0, 0.26);
                color: #fff;
                .shadow-depth-0();

                .text h1,
                .info {
                    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
                }

                .text h1 {
                    overflow: auto;
                    white-space: normal;
                    margin: 0 20px 5px 20px;
                }
            }

            .department-viewer-body {
                top: @department-viewer-header-image-height;
            }
        }
    }

    .department-viewer-close {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        overflow: hidden;

        &.overlayed button {
            color: #fff;
        }

        button {
            border: 0;
            background: none;
            color: #000;
            padding: 0;
            margin: 0;

            i {
                font-size: 40px;
                padding: 4px 3px;
            }
        }
    }

    .department-viewer-department {
        .department-viewer-header-background {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            height: @department-viewer-header-height;
            z-index: 1;
            background-position: center center;
            background-size: cover;
        }

        .department-viewer-header {
            height: @department-viewer-header-height;
            top: 0;
            left: 0;
            right: 0;
            position: absolute;
            z-index: 1;
            .box-shadow-1();

            .text {
                position: absolute;
                left: 0;
                right: 0;
                bottom: 30px;
                text-align: center;
            }

            .info {
                position: absolute;
                left: 0;
                right: 0;
                bottom: 5px;
                text-align: center;
                font-size: 16px;
                line-height: 31px;

                i {
                    vertical-align: middle;
                    margin-right: 3px;
                    font-size: 22px;
                }

                span {
                    vertical-align: middle;
                    text-transform: lowercase;
                    font-weight: 600;
                    margin-right: 7px;
                }
            }

            h1 {
                font-size: 20px;
                display: block;
                position: relative;
                overflow: hidden;
                text-overflow: clip;
                white-space: nowrap;
                margin: 0 35px 5px 35px;
            }
        }

        .department-viewer-body {
            top: @department-viewer-header-height;
            left: 0;
            right: 0;
            bottom: 0;
            position: absolute;

            &.hasContact {
                .department-viewer-no-data {
                    top: 85px;
                }
            }

            .department-viewer-contact {
                text-align: center;
                height: 85px;
                .flex-center-center();
                display: block;
                margin: 10px 0 0 0;
                border-bottom: 1px solid rgba(0, 0, 0, 0.12);

                .department-viewer-contact-btn {
                    display: inline-block;
                    text-align: center;
                    margin: 0;
                    border-radius: 5px;
                    padding: 5px 10px;
                    overflow: hidden;
                    position: relative;

                    i {
                        background: #000;
                        color: #fff;
                        border-radius: 100%;
                        display: block;
                        margin: 0 auto;
                        height: 40px;
                        width: 40px;
                        padding: 0;
                        text-align: center;
                        line-height: 40px;
                        font-size: 20px;
                    }

                    span {
                        display: block;
                        margin: 5px 0 0 0;
                    }
                }
            }

            .department-viewer-info {
                padding: 10px 10px;

                table {
                    width: 100%;

                    tr {
                        td {
                            padding: 4px 5px;
                            vertical-align: top;

                            &.data-type {
                                font-weight: 600;
                            }

                            span {
                                display: block;
                            }
                        }
                    }
                }
            }

            .department-viewer-no-data {
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                position: absolute;
                .flex-center-center();

                p {
                    padding: 20px 25px;
                    margin: 0;
                    text-align: center;
                }
            }
        }
    }

    .department-viewer-loader {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        .flex-center-center();

        .spinner {
            height: 50px;
            width: 50px;
        }
    }

    .department-viewer-not-found {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        .flex-center-center();
        padding: 15px;
        text-align: center;
        box-sizing: border-box;

        h2 {
            font-size: 20px;
            margin: 10px 0 0 0;
            padding: 0;
        }

        i {
            font-size: 80px;
            margin: 0;
            padding: 0;
        }
    }

    .department-viewer-profile,
    .department-viewer-not-found,
    .department-viewer-loader {
        z-index: 1;
    }
}