want-to-rate-popup {
    .actionsheet-overlay {
        background: rgba(0, 0, 0, 0.1)!important;
    }
    h4 {
        border: 0 !important;
        margin: 0 !important;;
    }

    .buttons {
        display: flex;
        justify-content: space-between;
        gap: 10px;

        rl-button {
            flex: 1;

            button {
                width: 100%;
            }
        }
    }
}