lesson-testimonials {
    @media @desktop-up {
        .actionsheet {
            width: calc(var(--max-width) - var(--spacing) * 2);
            box-sizing: border-box;
        }
    }

    .content-wrapper {
        display: flex;
        flex-direction: column;
        gap: var(--spacing);
    }

    .stars {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: var(--m-spacing);

        h2 {
            margin: 0;
        }

        p {
            margin: 0;
        }

        &.submitted {
            padding-bottom: calc(var(--spacing) * 3);
        }
    }

    rl-textarea {
        margin-bottom: var(--spacing);

        textarea {
            width: 100%;
            padding: var(--m-spacing);
            min-height: 120px;
            box-sizing: border-box;
            border-radius: var(--general-border-radius);
            background: var(--general-background-color);
            border-color: var(--general-border-color);
            box-shadow: none;
        }

        span {
            bottom: 20px;
            right: 25px;
        }
    }

    stars-rating {
        align-self: center;

        rl-icon {
            padding: 0 10px;

            &:first-of-type {
                padding-left: 0;
            }

            &:last-of-type {
                padding-right: 0;
            }
        }
    }
}