@import "../Import.less";

.help-popup-wrapper{
    position: absolute;
    top: 10px;
    left: 10px;
    right: 10px;
    bottom: 10px;
    z-index: @level-popup;
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -ms-flex-align: center;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;

    > .help-popup{
        width: 80%;
        background: #fff;
        border-radius: 5px;
        padding: 15px;
        .shadow-depth-1();

        > .help-popup-content{
            font-size: 14px;
        }
        > .help-popup-buttons{
            margin-top: 10px;
            display: block;
            text-align: center;
        }
    }
}