participation-rate {
    .participation-rate-widget {
        padding: var(--spacing);
        border-radius: var(--general-border-radius);
        background-color: var(--general-background-color);
        box-shadow: 0 7px 15px 0 var(--shadow-color);

        h2 {
            font-size: 1rem;
            font-weight: 700;
            margin: 0 0 var(--m-spacing);
        }

        .container {
            display: flex;
            gap: calc(var(--spacing) * 2);
        }

        .chart-wrapper {
            position: relative;

            .numbers {
                position: absolute;
                z-index: 1;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);


                display: flex;
                flex-direction: column;
                align-items: center;

                .lbl {
                    font-size: 0.9rem;
                }

                .x {
                    font-size: 1.6rem;
                    font-weight: 700;
                }

                .of {
                    font-size: 0.9rem;
                    font-weight: 700;
                }
            }
        }

        .chart-container {
            width: 150px;
            height: 150px;
            flex-shrink: 0;
        }

        .legend {
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: var(--spacing);

            overflow: hidden;

            .placeholder-shimmer {
                max-width: 100%;
            }

            span {
                display: flex;
                align-items: center;
                font-size: 0.8rem;

                &.not-started:before {
                    background-color: rgba(var(--highlight-color-rgb), 0.25);
                }

                &.completed:before {
                    background-color: rgba(var(--highlight-color-rgb), 1);
                }

                &:before {
                    content: '';
                    display: block;

                    width: 9px;
                    height: 9px;
                    margin-right: var(--m-spacing);
                    margin-top: -4.5px;

                    border-radius: 50%;
                }
            }
        }
    }
}

