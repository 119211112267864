@import "../../Import.less";

.alert-wrapper{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,0.7);
    z-index: @level-actionsheet;
    transition-duration: .3s;
    transition-property: opacity;
    transition-timing-function: ease-in-out;
    .flex-center-center();

    &.ng-enter, &.ng-leave-active{
        opacity: 0;
        .alert{
            transform: scale(0);
        }
    }
    &.ng-enter-active{
        opacity: 1;
    }

    .alert-overlay{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
    }

    .alert{
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background: #fff;
        transform: scale(1);
        transition-duration: .3s;
        transition-property: transform;
        transition-timing-function: linear;
        z-index: 2;
        border-radius: 4px;
    }
}