feedback-Form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    padding: 15px;

    background: var(--background-highlight-color);
    border-radius: var(--general-border-radius);
    border: 1px solid var(--general-border-color);

    box-shadow: 0 7px 15px rgba(60, 72, 88, 0.1);

    h2 {
        margin: 0;
        font-weight: 600;
        font-size: 15px;
    }

    .description {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
        width: 100%;

        > div {
            display: flex;
            align-items: center;
        }
    }

    .profile-image {
        min-width: 24px;
        min-height: 24px;
        margin-right: 6px;
    }

    .rated-by {
        font-size: 13px;
        color: var(--general-text-inactive-color);
    }

    .comments {
        rl-icon {
            font-size: 14px;
        }
    }

    .sf-feedback-rating {
        margin-right: 10px;
    }
}