@import "../Import.less";

// fix z-index for top modal
modal:last-child {
    .modal-overlay {
        z-index: @level-modal + 1;
    }

    .modal {
        z-index: @level-modal + 2;
    }
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: @level-modal;
    background: rgba(0, 0, 0, 0.5);
    display: block;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    opacity: 0;

    &.show {
        opacity: 1;
    }
}

.modal {
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    display: block;
    z-index: @level-modal + 1;

    .modal-content {
        opacity: 0;
        -moz-transform: translate3d(0, 20%, 0);
        -ms-transform: translate3d(0, 20%, 0);
        -o-transform: translate3d(0, 20%, 0);
        -webkit-transform: translate3d(0, 20%, 0);
        transform: translate3d(0, 20%, 0);
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;

        @media @mobile-max {
            top: 0;
            bottom: 0;
        }

        @media @desktop-up {
            -moz-transform: translate3d(0, 10%, 0);
            -ms-transform: translate3d(0, 10%, 0);
            -o-transform: translate3d(0, 10%, 0);
            -webkit-transform: translate3d(0, 10%, 0);
        }

        -moz-transition: all 0.3s;
        -o-transition: all 0.3s;
        -webkit-transition: all 0.3s;
        transition: all 0.3s;
        background: #fff;
        border-radius: 0;
        overflow: hidden;
        max-width: 500px;
        margin: 0 auto;
        max-height: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;

        .modal-top {
            position: relative;
            box-sizing: border-box;
            display: flex;
            flex: 0 0 auto;
            justify-content: space-between;
            align-items: center;
            
            height: 55px;
            padding: 0;

            font-weight: 600;

            .modal-title {
                display: block;
                font-size: 18px;
                text-transform: uppercase;
                margin: 0;
                padding: 0;
                text-overflow: clip;
                overflow: hidden;
            }

            .right-buttons {
                min-width: 45px;
            }
        }

        .empty-list {
            .mdi {
                display: block;
                text-align: center;
                font-size: 70px;
            }

            p {
                display: block;
                margin: 10px 0 0 0;
                padding: 0 20px;
                text-align: center;
                font-size: 18px;
            }

            display: block;
            padding: 20px 0;
        }

        .modal-loader {
            display: block;
            text-align: center;
            padding: 10px 0;
        }
    }

    .modal-close {
        display: block;
        text-align: center;
        color: inherit;
        cursor: pointer;
        font-size: 26px;
        overflow: hidden;
        border-radius: 100%;
        border: 0;
        padding: 0;
        background: none;
        min-width: 45px;
    }

    .modal-body {
        position: relative;
        margin: 0;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        -webkit-box-flex: 1;
        flex: 1 1 auto;
        
        overflow-x: hidden !important;
        overflow-y: auto !important;

        &.padding {
            padding: 5px 10px;
        }
    }

    .modal-bottom {
        display: block;
        position: relative;
        min-height: 40px;
        transform: translate3d(0, 0, 0);
        -webkit-box-flex: 0;
        flex: 0 0 auto;
        text-align: right;

        button {
            display: inline-block;
            font-size: 14px;
            position: relative;
            overflow: hidden;
            height: 38px;
            line-height: 38px;
            padding: 0 15px;
            text-align: center;
            color: @color-blue;
            font-weight: 600;
            text-transform: uppercase;
            background: none;
            border: none;
        }
    }

    &.show {
        .modal-content {
            -moz-transform: translate3d(0, -50%, 0);
            -ms-transform: translate3d(0, -50%, 0);
            -o-transform: translate3d(0, -50%, 0);
            -webkit-transform: translate3d(0, -50%, 0);
            transform: translate3d(0, -50%, 0);
            opacity: 1;
            @media @mobile-max {
                -moz-transform: translate3d(0, 0, 0);
                -ms-transform: translate3d(0, 0, 0);
                -o-transform: translate3d(0, 0, 0);
                -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
            }
        }
    }
}

// Pickers
.modal .modal-select-picker {
    ul {
        display: block;
        padding: 0;
        margin: 0;
        list-style: none;

        > li {
            display: block;
            line-height: 36px;
            position: relative;
            overflow: hidden;
            -moz-box-sizing: border-box;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            padding-left: 10px;

            &:last-child {
                &:after {
                    display: none;
                }
            }

            i {
                position: absolute;
                top: 0;
                right: 10px;
                height: 36px;
                line-height: 36px;
                font-size: 24px;
            }

            &:after {
                display: block;
                height: 1px;
                background: rgba(0, 0, 0, 0.1);
                position: absolute;
                left: 10px;
                right: 10px;
                bottom: 0;
                content: '';
            }
        }
    }
}