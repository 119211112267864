@import "../Import.less";

@modal-page-transition-time: .15s;
@modal-page-header-height: 55px;

.modal-page-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background: var(--popup-overlay-bg);
}

.modal-page {
    position: fixed;
    padding: 5px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    box-sizing: border-box;
    z-index: @level-modal-page;
    opacity: 0;
    transition: all @modal-page-transition-time ease-in-out;
    overflow: hidden;
    .flex-center-center();

    @media @mobile-max {
        padding: 0;
    }

    &.show {
        .modal-page-inner {
            transform: translate3d(0, 0, 0);
        }

        opacity: 1;
    }

    // fix offset for children popups
    @media @tablet-up {
        .popup-wrapper {
            .popup-content {
                left: 50%;
                top: 0;
            }
        }
    }

    .modal-page-inner {
        height: 100%;
        width: 100%;
        max-width: 600px;
        padding: 0;
        overflow: hidden;
        display: block;
        position: relative;
        border-radius: 0;
        transform: translate3d(0, 20%, 0);
        transition: all @modal-page-transition-time ease-in-out;
        z-index: 2;
        box-shadow: @shadow-box-deep;
    }

    .modal-page-header {
        position: absolute;
        z-index: 2;
        width: 100%;
        left: 0;
        right: 0;
        top: 0;

        display: flex;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
        height: @modal-page-header-height;
        padding: 0;

        line-height: @modal-page-header-height;
        overflow: hidden;

        .modal-page-header-close {
            text-align: center;
            padding: 0;
            font-size: 26px;
            overflow: hidden;
            display: block;
            background: none;
            color: inherit;
            min-width: 45px;
        }

        h1 {
            box-sizing: border-box;
            line-height: @modal-page-header-height;
            text-transform: uppercase;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin: 0;
            font-weight: 600;
        }

        .right-button-wrapper {
            min-width: 45px;
        }
    }

    .modal-page-content {
        position: absolute;
        top: @modal-page-header-height;
        left: 0;
        right: 0;
        bottom: 0;
        overflow-x: hidden;
        overflow-y: auto;
        background: var(--general-background-color);
        z-index: 1;
        box-sizing: border-box;
    }

    // Submit page
    &.submit {
        .modal-page-header {
            display: flex;
            flex-direction: row;

            h2 {
                display: block;
                -webkit-box-flex: 1;
                -ms-flex: 1 1 auto;
                flex: 1 1 auto;
                position: relative;
                padding: 0;
            }

            .modal-page-header-back,
            .modal-page-header-submit {
                flex: 0 0 auto;
                height: @modal-page-header-height;
                line-height: @modal-page-header-height;
                width: 45px;
                padding: 0;
                text-align: center;
                font-size: 20px;
                overflow: hidden;
                display: block;
                position: relative;
                background: none;
                border: none;
                color: inherit;
            }

            .modal-page-header-submit {
                font-size: 20px;
                width: 50px;

                &.has-text {
                    font-size: 15px;
                    box-sizing: border-box;
                    padding: 0 15px 0 0;
                    width: auto;
                    min-width: 45px;
                    font-weight: 600;
                }
            }
        }
    }
}

@media (orientation: landscape) {
    .modal-page {
        .modal-page-inner {
            max-width: 768px;
        }
    }
}