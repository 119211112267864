service-form-live-tile {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    
    rl-badge {
        position: absolute;
        top: 5px;
        right: 5px;
    }

    .filling {
        position: absolute;
        z-index: 0;
        top: 0;
        left: 0;

        display: flex;
        box-sizing: border-box;
        flex-direction: column;
        justify-content: space-between;

        width: 100%;
        height: 100%;
        padding: 25px 10px;

        opacity: 0;

        background-size: cover;
        background-position: center;

        transition: opacity 0.3s ease-in;

        &.active {
            opacity: 1;
            z-index: 5;
        }
    }

    header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        span {
            color: white;
            text-shadow: 0px 0px 20px rgba(0, 0, 0, 1);
        }
        .icon {
            padding-right: 5px;
        }
    }

    footer {
        @media @mobile-max {
            display: none;
        }

        .title {
            display: block;
            font-size: 18px;
            padding-bottom: 5px;
        }

        span {
            color: white;
            text-shadow: 0px 0px 10px rgba(150, 150, 150, 1);
        }

        .meta span:not(:last-of-type):after {
            content: '';
            display: inline-block;
            margin: 3px 5px;
            width: 3px;
            height: 3px;

            border-radius: 50%;
            background: white;
            line-height: 15px;
        }
    }
}
