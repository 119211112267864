@import "../../Import.less";

.form-module-reportlist {
    h2 {
        font-size: 16px;
        padding: 0 10px;
        margin: 20px 0 10px 0;
        text-align: center;
    }

    .form-module-reportlist-list {
        display: block;
        list-style: none;
        padding: 0;
        margin: 0;

        > li {
            display: block;
            border-bottom: 1px solid #eee;
            padding: 10px 10px;
            position: relative;
            overflow: hidden;

            .texts {
                display: block;
                float: left;
                overflow: hidden;
                max-width: 70%;
                min-height: 40px;

                h3 {
                    margin: 0;
                    padding: 0;
                    font-size: 16px;
                }

                p {
                    margin: 2px 0 0 0;
                    padding: 0;
                    opacity: .7;
                    font-size: 13px;
                }
            }

            .actions {
                display: block;
                float: right;
                width: 20%;
                height: 40px;
                line-height: 40px;
                text-align: center;

                span {
                    opacity: .5;
                    font-size: 16px;
                }
            }

            &:after {
                display: table;
                clear: both;
                content: '';
            }

            a {
                display: inline-block;
                height: 30px;
            }

            &:last-child {
                border-bottom: none;
            }
        }
    }
}

.form-module-report{
    h2{
        font-size: 16px;
        padding: 0 10px;
        margin: 20px 0 0 0;
        text-align: center;
    }
    h3{
        font-size: 14px;
        padding: 0 10px;
        margin: 4px 0 10px 0;
        text-align: center;
        opacity: .6;
    }

    .download-btn-wrapper{
        display: block;
        padding: 5px 20px;

        a{
            border-radius: 5px;
            background: #eee;
            height: 34px;
            line-height: 34px;
            width: 100%;
            display: block;
            position: relative;
            overflow: hidden;
            text-align: center;
            color: #555;
            text-decoration: none;
        }
    }

    .report{
        display: block;
        padding: 0 10px;
        box-sizing: border-box;
        margin: 10px 0 0 0;

        .report-item{
            border-bottom: 1px solid #eee;
            margin: 10px 0;
            font-size: 13px;

            h4{
                display: block;
                font-size: 14px;
                margin: 0 0 5px 0;
                padding: 0;
            }

            &:last-child{
                border-bottom: none;
            }

            .report-textfield{
                p{
                    margin: 0 0 10px 0;
                    padding: 0;
                    color: #555;
                }
            }

            .report-checkbox{
                margin-bottom: 10px;
                span{
                    display: block;
                    color: #555;
                }
            }

            .report-rating{
                margin-bottom: 10px;
                span{
                    display: inline-block;
                    color: #555;
                }
                i{
                    font-style: normal;
                    margin: 0 3px;
                }
            }

            .report-media{
                p{
                    margin: 0 0 4px 0;
                    padding: 0;
                }
                img{
                    max-width: 100%;
                    margin-bottom: 5px;
                }
            }
        }
    }
}