desktop-message-menu {
    .desktop-message-menu {
        display: inline-flex;
        position: relative;
        justify-content: flex-end;
        padding: var(--s-spacing);
        gap: 5px;
        border-radius: var(--general-border-radius);
        background-color: var(--white-color);
        box-shadow: 8px 16px 56px rgba(10, 10, 10, 0.16);

        reactions-list {
            position: static !important;
            padding: 0 !important;
            border-radius: unset !important;
            background: unset !important;
            box-shadow: unset !important;
        }

        .button-groups {
            border-radius: var(--general-border-radius);
            background-color: var(--white-color);
            position: absolute;
            top: 45px;

            .actionsheet-button {
                cursor: pointer;
                display: flex;
                gap: 5px;
                padding: 10px;
                flex-direction: row;
                align-items: flex-start;
            }
        }

        rl-icon {
            cursor: pointer;
            
            &.red {
                color: var(--warning-color);
            }
            
            &[icon="vertical-line"] {
                color: rgba(var(--general-text-color-rgb), 0.3);
                width: 5px;
            }
        }
    }
}