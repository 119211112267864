hashtag-suggestions {
    position: absolute;
    left: 0;
    top: calc(7px + 45px + 10px);
    z-index: 10;

    display: flex;
    width: calc(100% - var(--spacing) * 2);
    min-height: 54px;
    max-height: calc(54px * 3);
    overflow: hidden;

    background: var(--general-background-color);
    border-radius: var(--general-border-radius);
    filter: drop-shadow(0px 4px 7px rgba(60, 72, 88, 0.1));

    .spinner {
        opacity: 0.5;
        margin: auto;
    }

    ul {
        width: 100%;
        overflow-y: auto;
        margin: 0;
        padding: 0;
        list-style: none;
        overscroll-behavior: contain;
    }

    li {
        display: flex;
        height: 54px;
        padding: 10px 15px;
        box-sizing: border-box;

        &:hover {
            background: var(--background-highlight-color);
        }
    }

    .name {
        display: flex;
        flex-direction: row;

        margin: 0;

        font-weight: 600;
        color: var(--general-text-color);
        font-size: 15px;

        .tag {
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .featured {
            margin-left: 5px;
            color: var(--alert-color);
        }
    }

    .tag-label {
        display: flex;
        justify-content: center;
        align-items: center;

        min-width: 34px;
        height: 34px;
        margin-right: 10px;
        font-size: 20px;
        border-radius: 100%;
        color: var(--text-on-highlight-color);
        background: var(--highlight-color);
    }

    .rl-subheadline {
        margin: 0;
    }
}