.create-update-event {

    .scroll-container {
        position: relative;
    }

    .popup-content {
        background: var(--background-highlight-color);
    }

    .assignees-container {
        padding-left: var(--m-spacing);
        display: flex;
        align-items: baseline;
        justify-content: space-between;
    }

    form {
        position: relative;
        padding-bottom: 75px;

        > header-popup {
            z-index: 1;
            position: absolute;
            background: transparent;
            top: 0;
            box-sizing: border-box;
            height: auto;
            width: 100%;
            padding: 15px 15px 0 15px;

            .buttons {
                min-width: auto;
            }

            .title {
                display: none;
            }

            .right {
                button {
                    &[disabled] {
                        opacity: 1;

                        rl-icon {
                            color: var(--general-text-color);
                        }
                    }

                    rl-icon {
                        color: var(--highlight-color);
                    }
                }
            }

            button {
                width: auto;
                height: auto;
                background: transparent;
                min-width: auto;

                rl-icon {
                    height: 30px;
                    width: 30px;
                    border-radius: 50%;
                    color: var(--general-text-color);
                }
            }

            &.on-bg {
                button {
                    rl-icon {
                        background: rgba(255, 255, 255, 0.6);
                        backdrop-filter: blur(24px);
                        filter: drop-shadow(0px 6px 15px rgba(0, 0, 0, 0.15));
                    }
                }
            }
        }

        &.disable-overflow {
            overflow-y: visible;
        }
    }
    
    .description-wrapper {
        display: flex;
        flex-direction: column;
    }

    .image-uploader {
        position: relative;
        height: 180px;

        &.have-bg {
            .title-wrapper {
                background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3) 100%);

                input {
                    color: var(--white-color);

                    &::placeholder {
                        color: ~'rgba(var(--white-color-rgb), 0.6)';
                    }
                }
            }
        }

        .media-thumbnail {
            height: 100%;

            .image {
                width: 100%;
                height: 100%;
            }
        }

        .title-wrapper {
            position: absolute;
            bottom: 0;
            display: flex;
            box-sizing: border-box;
            align-items: center;
            width: 100%;
            padding: 15px;


            input {
                height: auto;
                padding: 0 20px 0 0;
                background: transparent;
                color: var(--general-text-color);
                font-weight: 500;
                font-size: 20px;
                line-height: 25px;
                text-transform: uppercase;
            }

            rl-icon {
                height: 30px;
                width: 30px;
                border-radius: 50%;
                font-size: 16px;
                color: var(--general-text-color);
                background: rgba(255, 255, 255, 0.6);
                backdrop-filter: blur(24px);
                filter: drop-shadow(0px 6px 15px rgba(0, 0, 0, 0.15));
            }
            
            .delete-file {
                padding: 0;
            }
        }
    }

    rl-rich-textarea {
        .fr-toolbar {
            border-bottom: 0;
        }
    }

    language-selector {
        width: 100%;
        border-bottom: 1px solid var(--general-border-color);
    }

    .eventType {
        padding: 10px;
    }

    .reminder {
        .rl-input {
            background: var(--general-background-color);
            align-items: center;

            input {
                width: 80px;
                text-align: center;
                padding-right: 0;
            }

            select {
                flex: 1;
                max-width: 80px;
            }

            > span {
                display: block;
                flex: 1;
            }
        }

        .hint {
            padding: 10px;
        }
    }

    .warning {
        padding: 6px 10px;
    }

    invite-individuals {
        width: 100%;

        > button {
            padding: 0 10px 0 40px;
            height: 50px;
            background: var(--general-background-color);
        }
    }

    select-category {
        padding-left: 40px;
        background: var(--general-background-color);

        .click-area {
            height: 50px;
            background: var(--general-background-color);
            justify-content: space-between;
            color: rgba(var(--general-text-color-rgb), 0.6);
        }

        .selected-category {
            margin: 0;
        }
        
        .category-selector {
            top: 0 !important;
            left: 0 !important;
            width: 100%;
            height: 100%;
        }
    }

    .attachments-wrapper {
        padding: var(--s-spacing);
        padding-bottom: calc(2*var(--s-spacing));
        background: var(--general-background-color);

        .attachments-label {
            margin-bottom: 10px;
        }
    }

    .delete-event {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 40px;
        margin-top: 20px;
        font-size: 17px;
        color: #eb6262;
        background: #fbe0e0;
        border: none;

        icon {
            margin-right: 10px;
            height: 18px;
            width: 16px;
        }
    }

    rl-button {
        display: block;
    }
    
    .translation-btn {
        display: block;
        margin: 0 0 0 auto;
    }

    .popup-sticky-bottom {
        z-index: 9;
    }
}