.password-strength-container {
    opacity: 0;
    transition: all ease-in-out .2s;
    text-align: right;
    height: 0;

    &.active {
        margin-top: 5px;
        opacity: 1;
        height: 22px;

        span {
            font-size: 12px;
            opacity: 1;
        }

        .password-strength-bar {
            div[class^="password-strength-bar-"] {
                height: 5px;
            }
        }
    }

    span,
    &.valid span{
        opacity: 0;
        color: #ea1111;
        transition: all ease-in-out .2s;
    }

    &[data-password-strength-value="1"] {
        .password-strength-bar-1 {
            background: #ea1111;
        }
    }

    &[data-password-strength-value="2"] {
        .password-strength-bar-1,
        .password-strength-bar-2 {
            background: #ffad00;
        }
    }

    &[data-password-strength-value="3"] {
        .password-strength-bar-1,
        .password-strength-bar-2,
        .password-strength-bar-3 {
            background: #9bc158;
        }
    }

    &[data-password-strength-value="4"] {
        .password-strength-bar-1,
        .password-strength-bar-2,
        .password-strength-bar-3,
        .password-strength-bar-4 {
            background: #00b400;
        }
    }

    .password-strength-bar {
        width: 100%;
        transition: height ease-in-out .2s;
        display: flex;
        flex-direction: row;
    }

    div[class^="password-strength-bar-"] {
        background: #e8e8e8;
        transition: background ease-in-out .5s;
        height: 0;
        flex: 1 1 auto;
        margin-right: 2px;

        &:last-child {
            margin-right: 0;
        }
    }
}
