@import "../Import.less";

.btn {
    display: inline-block;
    line-height: 40px;
    height: 40px;
    padding: 0 10px;
    text-align: center;
    background: var(--button-background-color);
    color: var(--button-text-color);
    text-transform: uppercase;
    border: none;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;

    &.outline {
        background: transparent;
        border: 1px solid var(--button-background-color);
        color: var(--button-background-color);
    }
    
    &.btn-secondary {
        color: #000;
        background: #000;
    }

    &.btn-underline {
        background: none;
        color: var(--button-background-color);
        text-decoration: underline;
    }

    &.btn-big {
        display: block;
        width: 100%;
        height: 50px;
        line-height: 50px;
    }

    &.btn-warn {
        background: @error-color;
        color: #fff;
    }

    &.btn-danger,
    &.btn-default {
        border-radius: 20px;
        padding: 0 30px;
        font-weight: 600;
        text-decoration: none;
        .shadow-depth-1();
        position: relative;
        overflow: hidden;

        &[disabled] {
            background: #999;
            .shadow-depth-0();
        }
    }

    // Special colors
    &.btn-danger {
        background: @error-darker-color;
        color: #fff;
    }

    &[disabled] {
        opacity: .9;
    }

    .spinner {
        border-color: var(--button-text-color);
    }
}

.btn-md {
    display: inline-block;
    line-height: 30px;
    height: 30px;
    padding: 0 15px;
    text-align: center;
    background: var(--button-background-color);
    color: var(--button-text-color);
    text-transform: uppercase;
    border: none;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    border-radius: 4px;
    position: relative;

    &:not(.clear):not(.outline) {
        .shadow-depth-1();
    }

    &.outline {
        background: transparent;
        border: 1px solid var(--button-background-color);
        color: var(--button-text-color);
    }
    &.clear {
        background: transparent;
        color: @button-default;
    }
}

.btn-background {
    background-color: var(--button-background-color);
}

.btn-text-color {
    color: var(--button-text-color);
}

button, [role="button"], a {
    &:not([disabled]){
        @media @desktop-up {
            cursor: pointer;
        }
    }
}