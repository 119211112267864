lesson-introduction {
    &.no-introduction {
        background-image: url("/Content/Graphics/Training/training-background.svg");
        background-position: center;
        background-size: auto;
        background-repeat: no-repeat;
    }

    lesson-disclaimer {
        display: block;
        margin: calc(var(--m-spacing) * -2.5) calc(var(--spacing) * -1) calc(var(--m-spacing) * 2.5);
    }

    .lesson-media {
        width: 100%;
        margin-bottom: var(--spacing);
    }

    .label-item,
    .go-to-tests {
        display: flex;
        flex-direction: column;

        padding: var(--spacing);
        border-radius: var(--general-border-radius);
        background: var(--background-highlight-color);

        > span {
            font-size: 15px;
        }

        .bottom {
            display: flex;
            align-items: center;
            margin-top: calc(var(--m-spacing) / 2);
        }

        h3 {
            margin: 0;
            font-size: 20px;
        }

        .emoji {
            margin-right: var(--m-spacing);

            width: 30px;
            height: 30px;

            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
            background-image: url("/Content/Graphics/Training/nerd_face.png");
        }

        .arrow {
            width: 30px;
            height: 30px;
            box-sizing: border-box;

            margin-left: auto;
            padding: 3px;

            border-radius: 50%;
            background: var(--general-background-color);
            backdrop-filter: blur(12px);

            path {
                fill: var(--general-text-color);
            }
        }
    }
    
    .go-to-tests {
        cursor: pointer;
    }

    .button-wrapper {
        rl-button rl-icon {
            font-size: 15px;
        }
    }

    .labels-group {
        display: flex;
        gap: var(--spacing);
        margin: var(--spacing) 0;

        .label-item {
            width: 50%;
            font-size: 13px;
            flex-direction: row;
            gap: var(--m-spacing);
        }

        .label-icon {
            font-size: 16px;
            width: 36px;
            height: 36px;
            background-color: var(--general-background-color);
            border-radius: 50%;
        }

        .label-text {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            span:first-child {
                font-size: 15px;
                font-weight: bold;
            }
        }
    }
}