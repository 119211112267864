@import "../../Import.less";
@import "Chart/landing-page-chart.metric.less";
@import "Chart/landing-page-chart.header.less";

@chart-list-bar-height: 30px;

.landing-page-chart {

    .landing-page-tile-swiper-favorite {
        position: absolute;
        bottom: 0;
        left: 0;
        border: none;
        background: none;
        height: 26px;
        line-height: 26px;
        padding: 0;
        width: 28px;
        text-align: center;
        cursor: pointer;
        z-index: 11;
        opacity: .7;
        overflow: hidden;

        &:hover {
            opacity: 1;
        }
    }

    ks-swiper-container {
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;

        .swiper-container {
            height: 100%;

            .swiper-pagination {
                bottom: 3px;
                left: 30px;
                right: 30px;
                width: auto;

                .swiper-pagination-bullet {
                    background: none;
                    border: 1px solid #000;
                    box-sizing: border-box;

                    &.swiper-pagination-bullet-active {
                        background: #000;
                        opacity: .4;
                    }
                }
            }

            .landing-page-tile-swiper-slide {
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                top: 0;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-pack: center;
                -ms-flex-pack: center;
                justify-content: center;
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                -ms-flex-direction: column;
                flex-direction: column;
            }
        }
    }

    .landing-page-chart-content {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        justify-content: center;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
        -webkit-box-align: center;
        align-items: center;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    .chart-list {
        display: block;
        width: 100%;
        list-style: none;
        padding: (@chart-list-bar-height / 2) 0;
        margin: 0;

        li {
            display: block;
            list-style: none;
            padding: 0;
            margin: 0;

            > .chart-group-item {
                &:after {
                    display: table;
                    clear: both;
                    content: '';
                }

                .chart-list-item {
                    display: block;
                    float: left;
                    width: 100%;
                    -moz-box-sizing: border-box;
                    -webkit-box-sizing: border-box;
                    box-sizing: border-box;
                    margin-bottom: 5px;

                    &.size-2 {
                        width: 50%;
                    }

                    &.size-1 {
                        width: 33.333333%;
                    }
                }
            }
        }
    }
}

.landing-page.landing-page-design-creative{
    .landing-page-chart{
        .chart-list-item{
            .chart-item{
                position: relative;
                margin-left: 5px;
                margin-right: 5px;
            }
        }
    }
}