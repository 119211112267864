explore-view {
    display: block;
    overflow-y: auto;
    height: 100%;
    width: 100%;

    @media @desktop-up {
        height: calc(100% - 75px);
    }
    
    .lazy-wrapper {
        display: block;
        
        -webkit-overflow-scrolling: touch;
    }

    .loading-placeholder {
        width: 100%;
        padding: 15px;
        
        @media @desktop-up {
            padding: 15px 0;
        }
    }

    .events-scrolled-wrapper {
        padding: 15px;

        @media @desktop-up {
            padding: 15px 0;
        }
    }
}