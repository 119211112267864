.survey-question-list {
    display: flex;
    flex-direction: column;
    gap: var(--spacing);

    padding: var(--spacing);

    .survey-question {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: var(--m-spacing);

        overflow: hidden;
        box-sizing: border-box;

        min-height: 45px;
        padding: var(--m-spacing);
        border-radius: var(--general-border-radius);

        background: var(--background-highlight-color);

        cursor: pointer;

        rl-loading-placeholder {
            display: block;
            width: 100%;

            .placeholder-shimmer {
                height: 18px;
            }
        }

        &.unread {
            .title {
                font-weight: 600;
            }

            .comments-count {
                color: var(--highlight-color);

                .count {
                    font-weight: 600;
                }

                rl-icon {
                    position: relative;

                    &:after {
                        content: '';
                        position: absolute;
                        top: 0;
                        right: 0;
                        width: 5px;
                        height: 5px;
                        border-radius: 50%;
                        background: var(--highlight-color);
                        border: 2px solid var(--background-highlight-color);
                    }
                }
            }
        }

        .title {
            flex: 1;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            line-height: 1.3;
            font-size: 0.875rem;
        }

        .comments-count {
            display: flex;
            align-items: center;
            color: var(--general-inactive-color);

            .count {
                min-width: 15px;

                text-align: center;
                font-size: 11px;
                line-height: 1;
            }
        }
    }
}