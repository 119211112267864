content-shares-popup {

    .popup-wrapper {
        .popup-content-inner {
            padding: 0;
        }

        .content-share-viewer {
            display: flex;
            flex-direction: column;
            height: 100%;
        }

        header {
            width: 100%;
            flex: 0 0 auto;
            padding: 10px 15px 0;
            box-sizing: border-box;
            z-index: 99;
            background: white;

            ul {
                margin: 0;
                list-style: none;
                display: flex;
                align-items: center;
                padding: 0;
                border-bottom: 1px solid ~'rgba(var(--general-text-color-rgb), .1)';
                width: 100%;

                li {
                    margin: 0 15px 0 0;
                    padding: 0 5px 10px;
                    cursor: pointer;
                    font-size: 14px;
                    border-bottom: 2px solid transparent;

                    &.active {
                        border-bottom: 2px solid var(--highlight-color);
                        color: var(--highlight-color);

                        &.facebook {
                            border-bottom: 2px solid #3B5998;
                            color: #3B5998;
                        }

                        &.linkedin {
                            border-bottom: 2px solid #0077b5;
                            color: #0077b5;
                        }

                        [icon=facebook] {
                            background-color: #3B5998;
                        }

                        [icon=linkedin] {
                            background-color: #0077b5;
                        }
                    }

                    span {
                        vertical-align: middle;
                        line-height: 20px;
                    }
                }
            }

            .btn-close {
                display: none;

                @media @desktop-up {
                    display: block;
                    position: absolute;
                    right: 10px;
                    top: 10px;
                    font-size: 20px;
                    cursor: pointer;
                }
            }
        }
    }

    section {
        flex: 1 1 auto;
        padding: 15px;
        overflow-y: auto;
    }

    .popup-content {
        .spinner-wrapper {
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            color: var(--general-text-color);
        }
    }

    .user {
        display: flex;
        margin-top: 10px;
        align-items: center;

        .image-wrapper {
            position: relative;
        }

        .profile-image {
            width: 42px;
            height: 42px;
            cursor: pointer;
        }

        rl-icon {
            position: absolute;
            z-index: 11;
            bottom: 0;
            width: 20px;
            height: 20px;
            right: 0;
        }

        .title {
            margin-left: 5px;
            color: var(--text-on-white-color);

            p {
                margin: 0;
            }

            .name {
                font-size: 15px;
                font-weight: 600;
            }

            .department {
                opacity: .5;
                font-size: 13px;
            }
        }
    }


    rl-icon {
        width: 22px;
        height: 22px;
        border-radius: 50%;

        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

        font-size: 12px !important;
        color: var(--white-color);
        background-image: linear-gradient(rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0));

        &[icon=facebook] {
            background-color: #4267B2;
        }

        &[icon=linkedin] {
            background-color: #0073b1;
        }
    }
}