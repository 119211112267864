@import "ParticipantsPopup/ParticipantsPopup";

stages-popup {
    stages {
        display: block;
        padding: 0 15px;
    }
}

stages {
    .stage {
        position: relative;
        margin-top: 25px;
        padding: 25px 10px 0 10px;
        border-radius: var(--general-border-radius);

        background-color: var(--background-highlight-color);

        &.new {
            padding-bottom: 20px;
        }

        &.locked {
            opacity: 0.4;
            padding-bottom: 20px;

            .content {
                cursor: default;
            }
        }

        &.pending {
            background-image: ~'linear-gradient(to bottom, rgba(var(--highlight-color-rgb), 0.1), 39%, rgba(var(--general-text-color-rgb), 0));';
        }

        &.approved {
            background-image: ~'linear-gradient(to bottom, rgba(var(--confirmation-color-rgb), 0.1), 39%, rgba(var(--general-text-color-rgb), 0));';

            .subtitle {
                font-weight: 600;
                color: var(--confirmation-color);
            }
        }

        &.rejected {
            background-image: ~'linear-gradient(to bottom, rgba(var(--warning-color-rgb), 0.1), 39%, rgba(var(--general-text-color-rgb), 0));';

            .subtitle {
                font-weight: 600;
                color: var(--warning-color);
            }
        }

        .stage-number {
            position: absolute;
            top: ~'calc(-35px / 2)';
            left: 10px;

            display: flex;
            align-items: center;
            justify-content: center;

            width: 35px;
            height: 35px;
            border-radius: 50%;

            box-shadow: 0 3px 7px 2px ~'rgba(var(--general-text-color-rgb), .1)';
            background: var(--general-background-color);
            color: var(--general-text-color);
            font-weight: 600;
        }

        .content {
            cursor: pointer;

            .title {
                display: flex;
                flex-direction: column;

                h3 {
                    display: flex;
                    margin: 0;

                    .text {
                        overflow: hidden;
                        flex: 1;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        font-weight: 700;
                    }
                }

                rl-icon {
                    position: relative;
                    top: -11px;
                    margin-left: 10px;
                    margin-right: 5px;

                    &:not(.circle) {
                        font-size: 15px;
                    }

                    &[icon=hourglass] {
                        background: var(--highlight-color);
                        color: var(--white-color);
                    }

                    &[icon=save] {
                        background: var(--confirmation-color);
                        color: var(--white-color);
                    }

                    &[icon=close] {
                        background: var(--warning-color);
                        color: var(--white-color);
                    }
                }
            }

            p {
                margin: 4px 0 0 0;
                font-size: 13px;
                line-height: 15px;

                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
            }

            > p {
                margin: 12px 0 0 0;
            }
        }

        .spacer {
            margin-top: 19px;
            height: 1px;
        }

        footer {
            border-top: 1px solid var(--general-border-color);
            display: flex;

            rl-button {
                flex: 1;

                button {
                    display: flex;
                    align-items: center;
                    flex-direction: row-reverse;
                    justify-content: center;

                    padding: 0;

                    font-weight: normal;
                    color: var(--general-text-color);

                    rl-icon {
                        padding-right: 7px;
                    }
                }
            }
        }
    }
}