survey-finish-popup {

    .action-popup {
        width: calc(100% - var(--s-spacing) * 4);
        max-width: 345px;
    }

    .action-popup-inner {
        display: flex;
        flex-direction: column;
        align-items: center;

        gap: var(--m-spacing);
        padding: calc(var(--m-spacing) * 2) var(--spacing);

        text-align: center;
        color: var(--text-on-white-color);
    }

    h2 {
        margin: 0;
        font-size: 1.66667rem;
    }

    p {
        margin: 0;
        font-size: 0.86667rem;
    }

    .img {
        width: 46px;
        height: 46px;
        background-image: url("/Content/Graphics/Survey/clapping_big.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
    }

    rl-button {
        width: 100%;
        margin: calc(var(--s-spacing) * 2) 0;
    }
}