@import "./FillingListItem/FillingItem";

filling-list {
    display: block;
    height: 100%;
    padding: 15px;

    .no-content {
        i {
            color: var(--general-text-inactive-color);
            font-size: 4rem;
            margin-bottom: var(--spacing);
        }

        p {
            color: var(--general-text-color);
            font-size: 0.9rem;
        }
    }
}

.filling-list {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    gap: 15px;

    &.single {
        filling-item,
        filling-loading-item {
            width: 100%;
        }
    }

    &.multiple {
        filling-item,
        filling-loading-item {
            width: ~'calc((100% - 15px) / 2)';

            @media @tablet-up {
                width: ~'calc((100% - 30px) / 3)';
            }

            @media @desktop-big-up {
                width: ~'calc((100% - 45px) / 4)';
            }
        }
    }
}
