content-create-popup {
    .popup-content-inner {
        background: var(--background-highlight-color);
    }
    
    .rl-form {
        padding-bottom: 75px;
    }

    .scroll-container {
        flex: 1;
        overflow-y: auto;
        position: relative;
    }

    .post-content-header {
        padding: 20px 0 0 0;
        min-height: 205px;
        box-sizing: border-box;

        textarea {
            position: relative;
            width: 100%;
            height: 100px;
            max-height: 500px;
            font-size: 15px;
            box-sizing: border-box;
            padding: 15px;
            border: none;
            background-image: linear-gradient(to bottom, var(--general-background-color) 85%, var(--background-highlight-color));
        }

        .post-title {
            margin: 0;
            padding: 15px;
            height: 57px;
            max-height: 150px;
            display: block;
            color: var(--general-text-color);
            border: none;
            font-size: 20px;
            font-weight: 500;
            background: inherit;

            &::-webkit-input-placeholder {
                color: var(--general-text-color);
                opacity: .6;
            }
        }
    }

    .attachments-wrapper {
        padding: 10px 10px 0;

        .attachments-label {
            margin-bottom: 10px;
            font-size: 15px;
            font-weight: 500;
        }
    }
    
    .notifications {
        .rl-input.toggle {
            border: unset;
        }
        
        .notification-input:last-of-type {
            border-bottom: 1px solid var(--general-border-color);
        }
        
        .notification-input {
            border-top: 1px solid var(--general-border-color);
        }
        
        .notification-help-text {
            display: block;
            background-color: var(--general-background-color);
            padding: 0 10px var(--s-spacing);
            font-size: 14px;
            color: var(--general-text-inactive-color);
        }
    }

    .post-setting {
        &.disabled {
            position: relative;

            &:after {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;

                background: rgba(255, 255, 255, 0.8);
            }
        }

        > div {
            display: flex;
            flex-direction: column;
        }

        h2 {
            font-size: 20px;
            font-weight: 600;
            padding: 0 10px;
            margin: 0 0 10px 0;
        }


        .rl-field.language {
            label {
                display: flex;
                justify-content: space-between;
                align-items: center;
                min-height: 43px;
            }

            language-selector {
                width: 100%;
            }
        }

        .notifications,
        .settings {
            .input-wrapper {
                display: flex;
                justify-content: space-between;
                align-items: center;
                position: relative;

                height: 50px;
                box-sizing: border-box;
                background: var(--general-background-color);
                border-top: 1px solid ~'rgba(var(--general-text-color-rgb), .1)';
                border-bottom: 1px solid ~'rgba(var(--general-text-color-rgb), .1)';

                > label {
                    flex: 1;
                    padding: 0 0 0 10px;
                }

                &.disabled:after {
                    content: '';
                    position: absolute;
                    z-index: 5;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    background: rgba(0, 0, 0, 0.2);
                }

                &:first-of-type {
                    border-bottom: none;
                }
            }

            rl-toggle {
                padding-right: 10px;
            }
        }
    }

    .submit {
        position: relative;
        width: 100%;
        height: 45px;
        line-height: 45px;

        background: var(--highlight-color);
        color: var(--text-on-highlight-color);

        font-weight: 600;

        &[disabled], &.inactive {
            background: var(--general-text-color);
            opacity: .4;
            color: white;

            &:after {
                position: absolute;
                right: 10px;
                top: 0;
                color: var(--warning-color);

                font-family: "Font Awesome 6\ Pro";
                font-size: 19px;
                font-weight: 900;
                //content: "\f057";
            }
        }
    }
    
    .content-category {
        min-height: 50px;
        background: var(--general-background-color);
        gap: 10px;
        display: flex !important;
        flex-direction: row !important;
        padding-left: var(--s-spacing);
        padding-right: 0;
        align-items: center;
    }

    .required-message {
        padding: 5px 0 0 0;
        font-size: 12px;
        font-weight: 600;
        color: var(--warning-color);
    }

    select-content-category {
        padding: 10px 30px 10px 40px;
    }
}