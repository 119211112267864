@import "../Import.less";

.bookmark-viewer {
    .bookmark-viewer-loading {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        .flex-center-center();

        .spinner {
            height: 30px;
            width: 30px;
        }
    }

    .bookmark-viewer-content {
        ul {
            list-style: none;
            padding: 0;
            margin: 5px 0;
            display: block;

            > li {
                display: block;
                width: 100%;
                box-sizing: border-box;
                margin: 5px 0;
                border-top: 1px solid #e9e9e9;
                border-bottom: 1px solid #e9e9e9;
            }
        }

        .bookmark-viewer-bookmark {
            display: block;
            border-radius: 4px;
            background: #fff;
            position: relative;

            > .inner {
                display: block;
                padding: 10px 12px;
                box-sizing: border-box;
                position: relative;
                overflow: hidden;
                z-index: 1;

                h3 {
                    margin: 0;
                    font-size: 16px;
                }

                p {
                    margin: 0;
                    font-size: 13px;
                }
            }

            .options {
                border: none;
                background: none;
                display: block;
                width: 40px;
                height: 40px;
                text-align: right;
                line-height: 40px;
                position: absolute;
                top: 0;
                right: 0;
                overflow: hidden;
                z-index: 2;
                font-size: 18px;
                opacity: .6;
                box-sizing: border-box;
                padding-right: 5px;
            }
        }
    }
}
