select-category {
    display: block;
    position: relative;
    width: 100%;

    .click-area {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;
        gap: 5px;
    }

    .bg {
        display: block;
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 10;
        background: rgba(0, 0, 0, 0.6);

        &.animated {
            animation-duration: 0.2s;
        }
    }

    .selected-category {
        padding: 4px 6px;
        border-radius: var(--general-border-radius);
        font-weight: 700;
        font-size: 12px;
        line-height: 130%;
    }

    .category-selector {
        position: fixed;
        z-index: 11;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        background: var(--background-highlight-color);

        @media @desktop-up {
            box-shadow: 0 2px 15px rgba(0, 0, 0, .1);
            z-index: 11;
            top: 20%;
            left: calc(57vw - var(--sidemenu-width));
            width: 500px;
            height: 650px;
        }

        .category-scroll {
            overflow-y: scroll;
            height: calc(100% - 55px);
        }

        ul {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            box-sizing: border-box;
            justify-content: space-between;
            width: 100%;
            margin: 0;
            padding: 10px;
            margin-bottom: 50px;

            li {
                display: flex;
                align-items: center;
                justify-content: space-between;
                position: relative;
                box-sizing: border-box;
                height: 36px;
                padding: 10px;
                margin-bottom: 15px;
                list-style: none;
                border-radius: var(--general-border-radius);

                &.selected-category {
                    box-shadow: 0 4px 7px rgba(60, 72, 88, 0.1);
                }

                > span {
                    font-size: 13px;
                    line-height: 16px;
                    color: var(--white-color);
                    font-weight: bold;
                }

                rl-icon {
                    width: 16px;
                    height: 16px;
                    background: var(--white-color);
                    font-size: 11px;
                }
            }
        }
    }
}
