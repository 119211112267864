filter-by-module-component {
    position: relative;
    display: inline-block;
    
    .selected-filters {
        display: flex;
    }

    rl-toggle-button {
        margin-right: 10px;
    }

    .open-filter {
        padding: 10px;

        font-size: 13px;
        border-radius: var(--general-border-radius);
        background-color: var(--general-background-color);
        cursor: pointer;
        line-height: 20px;

        &.selected {
            background-color: var(--highlight-color);
            color: var(--text-on-highlight-color);

            rl-icon {
                color: var(--text-on-highlight-color);
            }
        }

        rl-icon {
            font-size: 12px;
            color: var(--general-text-inactive-color);
        }
    }
}

filter-by-module-popup-component {
    action-sheet-custom .actionsheet {

        @media @desktop-up {
            width: 320px;
            margin-top: 50px;
            padding: 10px;
        }

        .actionsheet-content {
            padding-top: 0;
        }
    }

    @media @desktop-up {
        rl-select-list {
            margin-bottom: 10px;
        }
    }
    
    .clear button {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: center;

        font-weight: 400;
        color: var(--warning-color);

        @media @desktop-up {
            height: 40px;
            font-size: 12px;
            rl-icon {
                font-size: 14px;
            }
        }

        rl-icon {
            margin-right: 7px;
            font-size: 14px;
        }
    }
}