@import "../../Import.less";

.todo-day{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: @level-popup;
    background: var(--popup-overlay-bg);

    .todo-day-wrapper{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        ks-swiper-container,
        ks-swiper-container .swiper-container,
        .todo-day-slide{
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            display: block;
            transition: all .3s ease-in-out;

            &.ng-enter,
            &.ng-leave.ng-leave-active{
                opacity: 0;
            }
            &.ng-leave,
            &.ng-enter.ng-enter-active{
                opacity: 1;
            }
        }

        .todo-day-slide .todo-day-slide-inner{
            position: absolute;
            left: 10px;
            right: 10px;
            bottom: 10px;
            top: 10px;
            display: block;
            transform: translate3d(0,0,0);
            background: #fff;
            border-radius: 5px;
            .shadow-depth-1();
            .flex-center-center();

            .day{
                display: block;
                text-align: center;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                height: 100px;
                line-height: 100px;
                font-size: 18px;
                font-weight: bold;
                text-transform: uppercase;
            }

            h2{
                font-weight: 600;
                font-size: 20px;
                margin: 0;
                padding: 0 10px;
            }
            p{
                margin: 20px 0 0 0;
                font-size: 14px;
                color: rgba(0,0,0,0.5);
                padding: 0 10px;
                text-align: center;
            }

            .image-container{
                display: block;
                max-width: 160px;
                position: relative;
                margin-bottom: 20px;
                .flex-center-center();
            }

            .todo-day-pagination{
                display: block;
                width: 100%;
                text-align: center;
                font-size: 0;
                position: absolute;
                left: 0;
                right: 0;
                bottom: 80px;
                
                > .dot{
                    background: #000;
                    opacity: .2;
                    display: inline-block;
                    vertical-align: middle;
                    width: 8px;
                    height: 8px;
                    margin: 0 5px;
                    border-radius: 100%;

                    &.active{
                        opacity: 1;
                    }
                }
            }

            .button-container{
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                height: 60px;
                vertical-align: middle;
                text-align: center;

                .btn{
                    margin: 0 5px;
                    &.positive{
                        background-color: @success-color;
                    }
                    &.negative{
                        background-color: @error-color;
                    }
                }
            }
        }

        .todo-day-close{
            position: absolute;
            right: 0;
            top: 0;
            z-index: 2;
            width: 40px;
            height: 40px;
            line-height: 40px;
            text-align: center;
            overflow: hidden;
            font-size: 22px;
            color: rgba(0,0,0,0.7);
            background: none;
            border: none;
        }
    }

    transition: all .3s ease-in-out;
    
    &.ng-enter,
    &.ng-leave.ng-leave-active{
        opacity: 0;
    }
    &.ng-leave,
    &.ng-enter.ng-enter-active{
        opacity: 1;
    }

    .todo-day-endpage{
        position: absolute;
        left: 10px;
        right: 10px;
        bottom: 10px;
        top: 10px;
        display: block;
        transform: translate3d(0,0,0);
        background: #fff;
        border-radius: 5px;
        .shadow-depth-1();
        .flex-center-center();
        transition: all .3s ease-in-out;
    
        &.ng-enter,
        &.ng-leave.ng-leave-active{
            opacity: 0;
        }
        &.ng-leave,
        &.ng-enter.ng-enter-active{
            opacity: 1;
        }

        .endpage-button-container{
            position: absolute;
            left: 0;
            right: 0;
            bottom: 10px;
            text-align: center;
            z-index: 2;
        }

        .todo-day-endpage-inner{
            padding: 15px 15px 80px 15px;
            .flex-center-center();

            .image-container{
                max-width: 80%;
                margin-bottom: 25px;
            }
            h2{
                display: block;
                text-align: center;
                margin: 0;
                text-transform: uppercase;
                font-size: 20px;
            }
            p{
                display: block;
                text-align: center;
                margin: 20px 0 0 0;
                font-size: 14px;
            }
        }
    }
}

