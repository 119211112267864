
permissions-info-popup {
    action-sheet-custom {
        .actionsheet{
            max-height: 80vh;
            overflow: auto;
            color: var(--text-on-white-color);
        }
    }

    p {
        margin: 0;
    }

    .section {
        margin-bottom: calc(var(--m-spacing) * 4);
    }

    .title {
        margin: 0 0 0.5rem;
        font-size: 1.5rem;
        font-weight: 600;
        line-height: 120%;
    }

    .text {
        font-size: 1rem;
        line-height: 135%;
        margin-bottom: var(--spacing);
    }

    .subtitle {
        font-size: 1rem;
        font-weight: 600;
        line-height: 120%;
        margin: calc(var(--spacing) * 2) 0 0.5rem;
    }

    .tag-mixin() {
        background-color: rgba(var(--text-on-white-color-rgb), 0.1);
        padding: var(--s-spacing);
        font-weight: 700;
        border-radius: var(--general-border-radius);
        font-size: 0.9rem;
        color: var(--text-on-white-color);
    }

    .tags {
        display: flex;
        gap: var(--s-spacing);
        margin-top: var(--spacing);

        .tag {
            .tag-mixin();
            .lock {
                font-size: 0.85rem;
                color:  rgba(var(--text-on-white-color-rgb), 0.4);
            }
        }
    }

    .demo-card {
        border-radius: var(--general-border-radius);
        border: 1px solid rgba(var(--text-on-white-color-rgb), 0.1);
        box-shadow: 0px 7px 15px 0px rgba(60, 72, 88, 0.10);
        padding: var(--m-spacing);
        position: relative;

        p {
            max-width: calc(100% - 30px); // space for trash bin icon
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            gap: var(--s-spacing);
            color: var(--general-text-inactive-color);
            font-size: 0.9rem;
        }

        .card-text span {
            .tag-mixin();
        }

        rl-icon {
            color: var(--warning-color);
            position: absolute;
            top: 0;
            right: 0;
            padding: var(--spacing) var(--m-spacing);
        }
    }
    
    .or-divider {
        width: 100%;
        display: grid;
        place-content: center;
        position: relative;

        p {
            padding: 0 var(--spacing);
            margin: var(--spacing);
            background-color: var(--white-color);
            text-transform: uppercase;
            font-size: 0.9rem;
        }

        &::before {
            position: absolute;
            content: '';
            top: 50%;
            height: 2px;
            width: 100%;
            z-index: -1;
            background-color: rgba(var(--text-on-white-color-rgb), 0.1);
        }
    }

    footer {
        rl-button {
            display: block;
        }
    }
}