profile-settings-personal-information {
    .profile-settings-personal-information-image {
        display: flex;
        margin-top: 25px;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border: none;

        .profile-image-wrapper {
            margin-top: 0;
        }
    }
    
    .profile-settings {
        .edit-block-input {
            position: relative;
        }

        .field-postfix {
            position: absolute;
            right: var(--m-spacing);
            color: var(--general-text-inactive-color);
            bottom: calc(50% - 25px);
        }
    }
}