attachment-preview {
    overflow: hidden;
    width: 100%;

    &.ng-invalid:not(.ng-pristine) {
        .preview-item-add {
            border-color: rgba(var(--warning-color-rgb), 0.7);
        }
    }

    .preview-items {
        display: flex;
        width: 100%;
        overflow-x: auto;
        padding: 0;
        -webkit-overflow-scrolling: touch;
        position: relative;

        // square template
        &.square {
            width: 100%;
            height: 190px;
            box-sizing: border-box;
            background: ~'rgba(var(--general-text-color-rgb), .1)';

            .btn-edit {
                position: absolute;
                background: ~'rgba(var(--general-text-color-rgb), .1)';
                color: white;
                right: 5px;
                top: 5px;
                border-radius: 50%;
                padding: 5px;
                pointer-events: none;
                box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);

                rl-icon {
                    font-size: 15px;
                }
            }

            .unseen-images-counter {
                position: absolute;
                height: 30px;
                width: 30px;
                font-size: 13px;
                color: white;
                border-radius: 50%;
                background: ~'rgba(var(--general-text-color-rgb), .6)';
                right: calc(~'25% - 16px');
                bottom: calc(~'25% - 16px');
                line-height: 30px;
                text-align: center;
                box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);

                svg {
                    position: absolute;
                    top: 0;
                    left: 0;
                    transition: 0.35s stroke-dashoffset;
                    transform: rotate(-90deg);
                    transform-origin: 50% 50%;
                    stroke-dasharray: 87.964, 87.964;
                    stroke-dashoffset: 87.964;

                    circle {
                        stroke: white
                    }
                }
            }

            &.square-1 {
                .preview-item:not(.preview-item-add) {
                    width: 100%;
                    height: 100%;
                    margin: 0;
                    border: none;
                    border-radius: 0;
                }
            }

            &.square-2 {
                .preview-item:not(.preview-item-add) {
                    width: calc(~'50% - 2px');
                    height: 100%;
                    margin: 0;
                    border: none;
                    border-radius: 0;

                    &:nth-of-type(1) {
                        margin-right: 2px;
                    }

                    &:nth-of-type(2) {
                        margin-left: 2px;
                    }
                }
            }

            &.square-3 {
                flex-wrap: wrap;
                flex-direction: column;

                .preview-item:not(.preview-item-add) {
                    width: calc(~'50% - 2px');
                    height: 100%;
                    margin: 0;
                    border: none;
                    border-radius: 0;

                    &:nth-of-type(1) {
                        margin-right: 2px;
                    }

                    &:nth-of-type(2) {
                        margin-left: 2px;
                        margin-bottom: 2px;
                        height: calc(~'50% - 2px');
                    }

                    &:nth-of-type(3) {
                        margin-top: 2px;
                        margin-left: 2px;
                        height: calc(~'50% - 2px');
                    }
                }
            }

            .preview-item-add {
                height: calc(~'100% - 20px');
                box-sizing: border-box;
                padding: 0;
                width: 100%;
                margin: 10px;

                .icon {
                    flex-direction: column;
                }
            }
        }
    }

    .preview-items-wrapper {
        display: flex;

        &:not(.editable) {
            .preview-item:first-of-type {
                margin-left: 0;
            }
        }

        &.editable {
            flex-direction: row-reverse;
        }
    }

    .attachment-loading-overlay {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, .2);
        z-index: 5;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;

        &.attachment-loading-overlay-waiting {
            background-color: transparent;

            &:before {
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                top: 0;
                content: '';
                animation-duration: 1.0s;
                animation-fill-mode: forwards;
                animation-iteration-count: infinite;
                animation-name: attachmentPreviewPlaceHolderShimmer;
                animation-timing-function: linear;
                background: #000;
                background: linear-gradient(to right, #000 8%, #222 18%, #000 33%);
                background-size: 1200px 104px;
                height: 100%;
                position: absolute;
                opacity: .2;
            }
        }

        .processing-item {
            height: 16px;
            width: 6px;
            background-color: var(--general-text-color);
            border-radius: 2px;
            margin: 0 2px;
            box-sizing: border-box;
            box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.25);
            opacity: 0;
            display: block;

            &:nth-child(1) {
                animation: mediaProcessAnimation 1.5s linear 0s infinite;
            }

            &:nth-child(2) {
                animation: mediaProcessAnimation 1.5s linear 0.25s infinite;
            }

            &:nth-child(3) {
                animation: mediaProcessAnimation 1.5s linear 0.5s infinite;
            }
        }
    }

    .preview-item {
        position: relative;
        height: 100px;
        width: 130px;
        min-width: 130px;
        border-radius: 5px;
        overflow: hidden;
        margin: 0 5px;
        border: 1px solid ~'rgba(var(--general-text-color-rgb), .1)';

        .elapsed-time {
            position: absolute;
            left: 50%;
            top: 50%;
            width: 50px;
            text-align: center;
            margin-left: -25px;
            margin-top: -8px;
            font-size: 11px;
            z-index: 999;
            color: var(--general-text-color);
        }

        .progress-ring-circle {
            transition: 0.35s stroke-dashoffset;
            transform: rotate(-90deg);
            transform-origin: 50% 50%;
            stroke-dasharray: 150.796, 150.796;
            stroke-dashoffset: 150.796;
            stroke: var(--general-text-color);
            fill: var(--background-highlight-color);
        }

        .icon-wrapper {
            position: absolute;
            bottom: 5px;
            left: 5px;
            max-width: calc(~'100% - 10px');
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            font-size: 12px;
            z-index: 2;
            font-weight: 500;

            rl-icon {
                color: var(--general-text-color);
            }
        }

        &:after {
            position: absolute;
            background: rgba(244, 245, 246, .2);
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            pointer-events: none;
            content: '';
        }

        &.with-preview {
            &:after {
                content: none;
            }

            .attachment-name {
                display: none;
            }

            .icon-wrapper {
                rl-icon {
                    text-shadow: 0 2px 4px rgba(0, 0, 0, .2);
                    color: white;
                }
            }
        }

        .attachment-name {
            color: var(--general-text-color);
        }

        &:not(.with-preview) {
            // Unknown
            &.file-type-unknown, &.file-type-zip {
                .progress-ring-circle {
                    stroke: #555;
                }

                .processing-item {
                    background-color: #555;
                }

                &:after {
                    background: rgba(244, 245, 246, .8);
                }

                rl-icon {
                    color: #bfc3c8;
                }
            }

            // Video
            &.file-type-video {
                .progress-ring-circle {
                    stroke: var(--alert-color);
                }

                .processing-item {
                    background-color: var(--alert-color);
                }

                &:after {
                    background: ~"rgba(var(--alert-color-rgb), .15)";
                }

                rl-icon {
                    color: var(--alert-color);
                }
            }

            // Image
            &.file-type-image {
                .progress-ring-circle {
                    stroke: #555;
                }

                .processing-item {
                    background-color: #555;
                }

                &:after {
                    background: rgba(244, 245, 246, .8);
                }

                rl-icon {
                    color: #bfc3c8;
                }
            }

            // Audio
            &.file-type-audio {
                .progress-ring-circle {
                    stroke: #7D548D;
                }

                .processing-item {
                    background-color: #7D548D;
                }

                &:after {
                    background-color: rgba(125, 74, 141, .15);
                    background-image: url('/Content/Graphics/Upload/audio.svg');
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: contain;
                }

                rl-icon {
                    color: #7D548D;
                }
            }

            // Word
            &.file-type-doc {
                .progress-ring-circle {
                    stroke: var(--highlight-color);
                }

                .processing-item {
                    background-color: var(--highlight-color);
                }

                &:after {
                    background: ~"rgba(var(--highlight-color-rgb), .15)";
                }

                rl-icon {
                    color: var(--highlight-color);
                }
            }

            // Excel
            &.file-type-excel {
                .progress-ring-circle {
                    stroke: var(--confirmation-color);
                }

                .processing-item {
                    background-color: var(--confirmation-color);
                }

                &:after {
                    background: ~"rgba(var(--confirmation-color-rgb), .15)";
                }

                rl-icon {
                    color: var(--confirmation-color);
                }
            }

            // Powerpoint, PDF
            &.file-type-pdf,
            &.file-type-powerpoint {
                .progress-ring-circle {
                    stroke: var(--warning-color);
                }

                .processing-item {
                    background-color: var(--warning-color);
                }

                &:after {
                    background: ~"rgba(var(--warning-color-rgb), .15)";
                }

                rl-icon {
                    color: var(--warning-color);
                }
            }
        }

        button {
            position: absolute;
            right: 5px;
            top: 5px;
            margin: 0;
            padding: 0;
            cursor: pointer;
            color: white;
            text-shadow: 0 2px 4px rgba(0, 0, 0, .3);
            height: 20px;
            width: 20px;
            line-height: 20px;
            text-align: center;
            font-size: 19px;

            &.edit-thumbnail-link {
                right: auto;
                left: 5px;

                rl-icon {
                    font-size: 16px;
                }
            }
        }

        .preview {
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    }

    .preview-item-add {
        border: 2px dashed ~'rgba(var(--general-text-color-rgb), .1)';
        background: none;
        box-sizing: border-box;
        margin-left: 0;

        .file-uploader-container {
            height: 100%;
            width: 100%;
        }

        .icon {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            rl-icon {
                font-size: 30px;
            }
        }
    }

    .upload {
        height: 100%;
    }

    file-uploader {
        .file-uploader-container {
            &.disabled {
                opacity: 0.4;
            }
        }
    }
}

@keyframes mediaProcessAnimation {
    0% {
        opacity: 0;
    }

    40% {
        opacity: 1;
    }

    80% {
        opacity: 0;
    }

    100% {
        opacity: 0;
    }
}

@keyframes attachmentPreviewPlaceHolderShimmer {
    0% {
        background-position: -468px 0
    }

    100% {
        background-position: 468px 0
    }
}