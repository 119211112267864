@import "CustomerField/CustomerField";
@import "NumberField/NumberField";
@import "ReferenceField/ReferenceField";

service-form-field, .service-form-field {
    display: block;
    padding: 15px;
    border: 1px solid var(--general-border-color);
    border-radius: var(--general-border-radius);

    &.red {
        border: 1px solid var(--warning-color);
        background: rgba(var(--warning-color-rgb), 0.1);
    }

    &.yellow {
        border: 1px solid var(--alert-color);
        background: rgba(var(--alert-color-rgb), 0.1);
    }

    &.green {
        border: 1px solid var(--confirmation-color);
        background: rgba(var(--confirmation-color-rgb), 0.1);
    }

    &.no-margin {
        margin: 0;
    }

    .title {
        display: flex;
        justify-content: space-between;
        align-items: baseline;

        &.has-feedback {
            margin-bottom: 5px;
        }

        .right {
            display: flex;
            align-items: center;
            position: relative;
            z-index: 31;

            .comments {
                white-space: nowrap;
            }

            > *:not(:last-child) {
                margin-right: 10px;
            }
        }

        > label {
            display: block;
            font-size: 13px;
            font-weight: 600;
            margin: 0 0 10px 0;

            &.helptext {
                color: var(--general-text-inactive-color);
                font-style: italic;
            }
        }

    }

    .field-help-text {
        font-size: 12px;
        opacity: .5;
        color: var(--general-text-color);
        line-height: 12px;
        padding-bottom: 4px;
    }

    .field-wrapper {
        display: flex;
        width: 100%;

        barcode-reader {
            .btn {
                width: 40px;
            }
        }

    }

    .input-wrapper {
        display: flex;

        > input {
            flex: 1;
        }
    }

    input[type=tel], input[type=email] {
        width: 100%;
        max-width: 100%;
    }

    input[type=number] {
        width: 150px;
    }

    input[type=time] {
        min-width: 90px;
    }

    .checkbox-label {
        display: flex;
        align-items: center;
        line-height: 24px;
        font-size: 13px;
        margin-top: 2px;
        cursor: pointer;

        .label-text {
            max-width: calc(100% - 30px);
        }

        &.disabled {
            opacity: 0.6;
            cursor: default;
        } 
        
        &.in-use {
            color: var(--general-text-inactive-color);
        }
        
        .in-use-label {
            margin-left: auto;
        }

        input {
            margin-right: 10px;
        }


        &:hover input:after {
            background: #dedede;
        }
    }

    input[type=checkbox] {
        width: 18px;
        height: 18px;
        padding: 0;
        position: relative;
        overflow: hidden;
        display: inline-block;
        vertical-align: top;

        &:after {
            display: block;
            content: '';
            height: 18px;
            width: 18px;
            box-sizing: border-box;
            border-top: 2px solid #dedede;
            background: #eee;
            border-radius: 3px;
            z-index: 1;
            position: absolute;
            left: 0;
            top: 0;
            pointer-events: none;
        }

        &:checked:before {
            display: block;
            content: "\f00c"; // fa-check
            font-family: @icon-fa;
            height: 18px;
            width: 18px;
            line-height: 18px;
            text-align: center;
            box-sizing: border-box;
            z-index: 2;
            position: absolute;
            left: 0;
            top: 0;
            pointer-events: none;
            font-size: 12px;
        }
    }

    field-datalist {
        input[type=checkbox] {
            overflow: visible;
        }
    }

    .field-media-upload {
        display: flex;
        flex-wrap: wrap;
        font-size: 0;

        media-thumbnail {
            margin: 0 8px 5px 0;
            display: inline-block;
            vertical-align: top;
            height: 80px;
            width: 80px;

            .media-thumbnail {
                border-radius: @border-radius;
                background: rgba(0, 0, 0, 0.24);

                &:after {
                    position: absolute;
                    top: 0;
                    height: 3px;
                    left: 0;
                    right: 0;
                    background: rgba(0, 0, 0, 0.24);
                    content: '';
                    border-radius: @border-radius;
                }
            }

            .image {
                height: 80px;
                width: 80px;
            }
        }
    }

    .information {
        img, video, iframe {
            max-width: 100%;
        }

        video {
            background: grey;
        }
    }

    button-group button {
        min-height: 40px;
    }

    .instant-feedback {
        margin-top: 10px;

        > p {
            font-size: 15px;
            color: var(--general-text-inactive-color);
            margin: 0 0 10px 0;
        }

        media {
            border-radius: var(--general-border-radius);
            overflow: hidden;
        }
    }
}
