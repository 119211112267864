badge-testimonials-popup {

    .action-popup {
        min-width: 300px;
        width: 80vw;
        max-width: 600px;

        .action-popup-inner {
            display: block;
            padding: 15px;
        }
    }

    .content {
        h2, p {
            margin: 0;
        }

        h2 {
            margin-bottom: 5px;
        }
    }
    
    badges-list {
        flex-direction: column;
        align-items: center;
    }
    

    .finish-lesson {
        position: relative;
        box-sizing: border-box;
        padding: 25px 15px 30px 15px;
        text-align: center;
        background: var(--general-background-color);
        width: 100%;

        &:after {
            content: '';
            position: absolute;
            left: 0;
            bottom: -10px;
            width: 100%;
            border-radius: 50%;
            background: var(--general-background-color);
            height: 20px;
        }


        .finish-icon {
            font-size: 80px;
        }
    }

    .stars {
        margin: 30px 0;
        text-align: center;

        &.submitted {
            width: 100%;
        }

        p {
            margin: 6px 0 23px 0;
        }
    }

    textarea {
        width: 100%;
        padding: 10px;
        min-height: 120px;
        margin-bottom: 10px;
        font-size: 15px;
        box-sizing: border-box;
        border-radius: 7px;
        background: var(--general-background-color);
        border: 1px solid var(--general-border-color);
    }
}