@import "../Import.less";

.feedback {
    padding: 10px;

    @media @desktop-up {
        max-width: none;
        display: flex;
        justify-content: center;
        flex-direction: column;
    }

    h2 {
        text-transform: uppercase;
        font-size: 1.05em;
        margin: 0;
        padding: 0;

        @media @desktop-up {
            max-width: 60vw;
            width: 60vw;
            margin: 0 auto 20px;
        }
    }

    form {
        display: block;
        margin: 15px 0 0 0;
        border: 1px solid #fff;
        border-color: darken(@split-color, 5%);

        @media @desktop-up {
            max-width: 60vw;
            width: 60vw;
            margin: 0 auto;
        }

        textarea {
            display: block;
            border: none;
            font-size: 15px;
            padding: 10px;
            width: 100%;
            min-height: 150px;
            -moz-box-sizing: border-box;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            max-width: 100%;
        }

        input[type=text] {
            display: block;
            width: 100%;
            padding: 0 10px;
            font-size: 15px;
            line-height: 32px;
            height: 32px;
            border: none;
            border-bottom: 1px solid #fff;
            border-color: darken(@split-color, 5%);
            -moz-box-sizing: border-box;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
        }

        .form-bottom {
            display: block;
            position: relative;
            height: 50px;
            padding: 0;
            line-height: 50px;
            margin: 0;
            background: lighten(@split-color, 5%);

            button,
            .upload-btn {
                display: inline-block;
                height: 50px;
                line-height: 50px;
                padding: 0 10px;
                margin: 0;
                border: 0;
                text-align: center;
                font-size: 22px;
                background: none;
                color: darken(@split-color, 15%);

                &[type=submit] {
                    position: absolute;
                    right: 0;
                    font-size: 1em;
                    padding: 0 15px;
                }

                i {
                    line-height: 50px;
                }
            }
        }
    }

    .image-viewer {
        margin: 10px 0 0 0;
        width: 100%;

        > div {
            display: inline-block;
            vertical-align: top;
            padding: 0 1.5%;
            overflow: hidden;
            margin: 0 0 5px 0;

            img {
                height: 60px;
            }

            .video-preview {
                background: rgba(0,0,0,0.5);
                color: #fff;
                height: 60px;
                line-height: 60px;
                width: 100%;
                text-align: center;
                font-size: 30px
            }
        }
    }
}
