@import "../Import.less";

.list{
    border-top:1px solid @split-color;

    .list-group{
        position: relative;
        width: 100%;
        margin: 0;
        padding: 0;
        border-bottom: 1px solid @split-color;
        border-spacing: 0;
        border-collapse: separate;

        thead{
            tr{
                th{
                    line-height: 30px;                    
                    margin: 0;
                    min-height: 30px;
                    font-size: 13px;
                    border-bottom: 1px solid @split-color;
                    background: lighten(@split-color, 10%);
                    text-align: left;
                    text-transform: uppercase;

                    &:last-child{
                        padding-right: 15px;
                        text-align: right;
                    }
                    &:first-child{ 
                        padding-left: 15px; 
                        text-align: left;
                    }
                }
            }
        }

        tbody{
            tr{
                padding: 0 10px;
                td{
                    padding: 8px 0;
                    position: relative;
                    &:after{
                        position: absolute;
                        height: 0;
                        bottom: 0;
                        display: block;
                        border-bottom: 1px solid @split-color;
                        content: '';
                        left: 0;
                        right: 0;
                    }

                    &.image{
                        width: 30px;
                        padding-right: 10px;
                        .profile-image{
                            height: 30px;
                            width: 30px;
                        }
                    }
                    &.faded, .faded{
                        color: darken(@split-color, 10%);
                        font-size: .95em;
                    }
                    &.small-faded, .small-faded{
                        color: darken(@split-color, 10%);
                        font-size: .85em;
                    }
                    &.smaller, .smaller{
                        font-size: .95em;
                    }
                    &.list-next{
                        width: 20px;
                        text-align: right;
                        &:before{
                            display: inline-block;
                            font-family: @icon-mdi;
                            content: "\F142";
                            font-size: 18px;
                        }
                    }
                    &:last-child{ 
                        text-align: right; 
                        padding-right: 15px;
                        &:after{ right: 15px; }
                    }
                    &:first-child{
                        padding-left: 15px;
                        text-align: left;
                        &:after{ left: 15px; }
                    }
                }
                &:last-child td:after{
                    border-bottom: none;
                }
            }
        }
    }
}