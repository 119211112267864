@import "../../Import.less";
@import "_WalkthroughVariables.less";

html[dir="rtl"] .walkthrough-control-buttons {
    button {
        transform: scale(-1);
    }
}

.walkthrough-control-buttons {
    position: absolute;
    z-index: 5;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    border: none;
    box-sizing: border-box;

    @media @desktop-up {
        top: 50%;
        bottom: auto;
        margin-top: -30px;
        justify-content: center;
    }

    button {
        padding: 10px;
        text-transform: uppercase;
        background: none;
        border: none;
        color: white;
        font-weight: bold;

        @media @desktop-up {
            font-size: 32px;
            margin: 0 30vh;
        }

        &[disabled] {
            color: #CCCCCC;
        }
    }
}

.walkthrough {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: @level-walkthrough + 1;
    box-sizing: border-box;

    .swiper-container {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
    }

    .swiper-pagination {
        bottom: 0;
        left: 0;
        right: 0;
        height: @walkthrough-pagination-height;
        line-height: @walkthrough-pagination-height;

        > .swiper-pagination-bullet:not(.swiper-pagination-bullet-active) {
            background: #fff;
            opacity: .3;
        }

        &.swiper-pagination-progress {
            height: @walkthrough-progress-height;
        }
    }

    .walkthrough-loading-container {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        z-index: 2;
        transition-duration: .3s;

        .walkthrough-slide {
            background: rgba(255, 255, 255, 0.7);
        }
    }
}

.walkthrough-overlay {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: @level-walkthrough;
    background: rgba(0, 0, 0, 0.6);
}

walkthrough,
.walkthrough,
.walkthrough-overlay,
.walkthrough-loading-container {
    transition: all ease-in-out .5s;

    &.closing,
    &.ng-enter,
    &.ng-leave.ng-leave-active {
        opacity: 0;
    }

    &.ng-leave,
    &.ng-enter.ng-enter-active {
        opacity: 1;
    }
}