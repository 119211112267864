.campaign-module {
    display: flex;
    flex-direction: column;
    justify-content: center;

    campaign-module {
        display: block;
    }
}

.campaign-list {
    list-style-type: none;
    list-style-position: inside;
    position: relative;
    margin: 0;
    padding: 15px 15px 0 15px;

    .campaign-list-item {
        display: block;
        margin-bottom: 15px;

        .campaign-list-item-inner {
            height: 90px;
            border-radius: var(--general-border-radius);
            background-color: #f5f5f5;
            background-size: cover;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: relative;
            overflow: hidden;
            -webkit-perspective: 900000px;
            perspective: 900000px;

            &.has-background {
                &:after {
                    content: '';
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    top: 0;
                    z-index: 1;
                    background: rgba(0, 0, 0, 0.15);
                }

                h3 {
                    color: #fff;
                }
            }

            h3 {
                font-weight: bold;
                font-size: 15px;
                color: #2F2F2F;
                text-align: center;
                margin: 0;
                padding: 0 15px;
                line-height: 20px;
                position: relative;
                z-index: 2;
            }

            .title-placeholder {
                width: 70%;
                height: 20px;
                display: block;
            }
        }
    }
}