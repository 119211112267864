.base-view.base-view-training.module-training-lesson-questions {
    @media @desktop-up {
        &:not(.show-answers) {
            --training-inner-background: none;
            padding-bottom: 0;
        }

        &.show-answers {
            padding-bottom: calc(var(--spacing) + (var(--m-spacing) * 5) + 1.3rem);
        }
    }
    @media @tablet-max {
        --training-background: var(--background-highlight-color);
    }
}

lesson-questions {
    rl-progress {
        position: fixed;
        z-index: 11;
        top: 55px;
        left: 0;
        right: 0;

        .bar {
            border-radius: 0;
            height: 4px !important;

            .inner {
                border-radius: 0;
                height: 4px !important;
            }
        }

        @media @desktop-up {
            left: var(--sidemenu-width);
            top: 60px;
        }
    }

    .content-wrapper {
        flex: 1;
        position: relative;
        padding-top: var(--spacing);
        container-type: inline-size;

        @media @tablet-max {
            display: flex;
            flex-direction: column;
        }

        @media @desktop-up {
            padding: calc(var(--m-spacing) * 2.5) calc(var(--spacing) * 3) var(--spacing) calc(var(--spacing) * 3);
        }

        &.timed-out {
            opacity: 0.6;
        }
    }

    @media @tablet-max {
        .next .question-wrapper {
            &.current-remove.in-stack {
                animation: slideOutLeft 0.3s;
            }

            &.current-remove:not(.in-stack) {
                animation: fadeOutLeft 0.3s;
            }

            &.current-add {
                animation: fadeInRight 0.3s;
            }
        }

        .prev .question-wrapper {
            &.current-remove {
                animation: fadeOutRight 0.3s;
            }

            &.current-add:not(.in-stack) {
                animation: fadeInLeft 0.3s;
            }

            &.current-add.in-stack {
                animation: slideInLeft 0.3s;
            }
        }
    }

    @media @desktop-up {
        .question-wrapper:not(.in-stack) {
            &.current-remove {
                animation: fadeOut 0.3s;
            }

            &.current-add {
                animation: fadeIn 0.3s;
            }
        }

        .next .question-wrapper.in-stack {
            &.current-remove {
                animation: slideOutDown 0.3s;
            }
        }

        .prev .question-wrapper.in-stack {
            &.current-add {
                animation: slideInDown 0.3s;
            }
        }
    }

    .question-wrapper {
        display: none;

        &.current-add {
            z-index: 100;
        }

        &.ng-animate {
            position: absolute;
            display: block;

            &.swipe-card {
                display: flex;
            }

            &:not(.stack-below) {
                width: calc(100% - var(--spacing) * 2);
                height: calc(100% - var(--spacing) * 2);

                @media @desktop-up {
                    top: 0;
                    height: 100%;
                    width: calc(100% - var(--spacing) * 6);
                    padding: calc(var(--m-spacing) * 2.5) 0 var(--spacing) 0;
                    box-sizing: border-box;
                }
            }
        }

        &.current {
            display: block;

            &.swipe-card {
                display: flex;
            }
        }

        &.swipe-card {
            flex-direction: column;
            justify-content: center;

            @media @tablet-max {
                margin: auto 0;
            }

            @media @desktop-up {
                height: 100%;
            }
        }

        &.stack-below {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            display: flex;
            box-sizing: border-box;

            lesson-swipe-card {
                .card.with-image h3 {
                    flex: 1;
                    white-space: nowrap;
                    opacity: 0;
                }

                .answer {
                    display: none;
                }

                @media @desktop-up {
                    footer.desktop {
                        visibility: hidden;
                    }
                }
            }

            &.stack-index-2 {
                z-index: -1;
                transform: translateY(var(--spacing));
                padding: 0 calc(2 * var(--spacing));

                @media @desktop-up {
                    padding: calc(var(--m-spacing) * 2.5) calc(4 * var(--spacing)) var(--spacing) calc(4 * var(--spacing));
                }
            }

            &.stack-index-3 {
                z-index: -2;
                transform: translateY(calc(var(--spacing) * 2));
                padding: 0 calc(3 * var(--spacing));

                @media @desktop-up {
                    padding: calc(var(--m-spacing) * 2.5) calc(5 * var(--spacing)) var(--spacing) calc(5 * var(--spacing));
                }
            }
        }
    }

    .button-wrapper {
        padding: 0 var(--spacing) calc(var(--m-spacing) * 4);
        background: none;

        @media @desktop-up {
            prev-next-buttons {
                filter: drop-shadow(0 5px 5px var(--shadow-color));
            }

            padding: 0 calc(var(--spacing) * 3) calc(var(--m-spacing) * 4);
        }

        &.answered {
            padding: var(--spacing) var(--spacing) calc(var(--m-spacing) * 5) var(--spacing);
            background: var(--general-background-color);

            @media @desktop-up {
                position: fixed;
                left: var(--sidemenu-width);
                bottom: 0;
                width: 100%;
            }

            &:after {
                content: '';
                position: absolute;
                z-index: 0;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
            }
        }

        &.correct {
            color: var(--confirmation-color);

            &:after {
                background: rgba(var(--confirmation-color-rgb), 0.2);
            }
        }

        &.wrong {
            color: var(--warning-color);

            &:after {
                background: rgba(var(--warning-color-rgb), 0.2);
            }
        }

        h3 {
            margin: 0;
            font-size: 15px;
            font-weight: 700;
            line-height: 130%;
        }

        .container {
            display: flex;
            justify-content: flex-end;
        }
    }
}