.base-view.base-view-policyOverview {
    height: auto;
    min-height: unset;

    .view-page {
        max-width: unset;
        max-inline-size: unset !important;
    }
}

policy-overview {
    .policies-list {
        display: flex;
        flex-direction: column;
        gap: var(--spacing);
        margin: var(--spacing);
        min-width: 255px;
    }

    .listed-policy {
        border-radius: var(--general-border-radius);
        background: var(--background-highlight-color);
        border: 1px solid var(--background-highlight-color);
        padding: var(--m-spacing);
        display: flex;
        gap: var(--m-spacing);

        rl-icon {
            font-size: 1.7rem;
        }
    }

    .displayed-policy {
        flex-grow: 1;
        max-width: var(--max-width);
        transition: opacity 0.3s ease;
        opacity: 1;
    }
    
    .hide-policy {
        opacity: 0;
    }

    .title {
        overflow: hidden;
        color: var(--general-text-color);
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 1rem;
        font-weight: 600;
        line-height: 130%;
        margin: 0 0 2px;
        max-width: 70vw;
    }

    .approval-date {
        .accept-date();
        margin: 0;
    }

    .desktop-layout {
        display: flex;
        gap: var(--spacing);
        padding: 0 var(--spacing) var(--spacing);
        justify-content: center;

        &.with-policy-list {
            margin-left: -255px;
        }

        .policies-list {
            .listed-policy:hover, 
            .selected {
                cursor: pointer;
                border: 1px solid var(--highlight-color);
                background-color: rgba(var(--highlight-color-rgb), 0.2);
            }

            .listed-policy {
                transition: background-color 0.3s ease, border 0.3s ease;
                
                rl-icon {
                    font-size: 1.25rem;
                }
                .title {
                    margin-bottom: 0;
                    max-width: 200px;
                }
                .approval-date {
                    display: none;
                }
            }
        }
    }

    .no-content {
        margin-top: 200px;
    }
}

@media (max-width: 1700px) {
    policy-overview .desktop-layout.with-policy-list {
        margin-left: -220px;
    }
}

@media (max-width: 1500px) {
    policy-overview .desktop-layout.with-policy-list {
        margin-left: 0;
    }
}