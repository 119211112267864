@import "../Import.less";

.text-right {
    text-align: right !important;
}

.scrolling {
    overflow-x: hidden !important;
    overflow-y: auto !important;
    -webkit-overflow-scrolling: touch !important;
}