set-reminder-popup {
    .popup-wrapper {
        .popup-content-inner {
            display: flex !important;
        }

        .popup-content {
            top: 50%;
            left: 50%;
            margin-top: -140px;
            margin-left: -172.5px;

            height: 280px;
            max-width: 345px;

            @media @desktop-up {
                margin-left: calc(140px - 172.5px);
            }
        }

        section {
            display: flex;
            flex: 1;
            padding: 15px;
            justify-content: center;
            align-items: center;

            .date-wrapper {
                position: relative;
                width: 100%;

                input {
                    width: 100%;
                    height: 50px;
                    padding: 5px 5px 5px 50px;
                    box-sizing: border-box;
                    font-size: 15px;

                    border: 1px solid var(--general-border-color);
                    color: var(--general-text-color);
                    -webkit-appearance: none;
                }

                rl-icon {
                    position: absolute;
                    height: 100%;
                    box-sizing: border-box;

                    top: 0;
                    left: 0;
                    padding: 10px;
                }
            }

        }
    }

    rl-button {
        display: block;
        padding: 0 15px 15px 15px;
    }
}