set-new-pin, enter-pin-code, reset-pin-popup .popup-content-inner {
    display: flex;
    flex-direction: column;

    height: 100%;
    width: 100%;

    padding: 15px;
    box-sizing: border-box;

    pin-code {
        height: 100%;
    }
}