content-item {
    position: relative;
    display: block;
    padding: 0;
    margin: 15px 0;
    background: var(--general-background-color);
    border-radius: var(--general-border-radius);

    .app-link-url {
        position: absolute;
        overflow: hidden;
        clip: rect(0 0 0 0);
        height: 1px;
        width: 1px;
        margin: -1px;
        padding: 0;
        border: 0;
        user-select: none;
    }

    .content-item-report-btn {
        position: absolute;
        right: 5px;
        top: 10px;
        height: 30px;
        width: 40px;
        line-height: 30px;
        text-align: center;
        cursor: pointer;
        color: #bc6565;
        font-size: 20px;
        z-index: 2;

        &:hover {
            color: #9e2424;
        }
    }

    .content-item-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px 15px 0 15px;

        &.short {
            padding: 15px 0 0 15px;

        }

        h2 {
            margin: 0;
            font-size: 18px;
            text-transform: uppercase;
            position: relative;

            word-break: break-word;
        }
    }

    .content-item-breadcrumb {
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;

        margin: 10px 15px 0 15px;
    }

    .profile-info {
        .name {
            p {
                font-weight: 500;
            }
        }

        p {
            display: inline;
            padding: 0;
            margin: 0;
            vertical-align: top;
        }
    }

    profile-image {
        padding-right: 10px;
        
        .leave-icon {
            right: 7px;
        }

        .profile-image {
            height: 34px;
            width: 34px;
        }
    }

    .bookmark-message {
        bottom: 100%;
        width: 100%;
        font-size: 12px;
        line-height: 40px;
        padding: 0 15px;
        box-sizing: border-box;
        color: transparent;
        background: rgba(0, 0, 0, .7);
        height: 0;
        visibility: hidden;
        transition: all .3s;

        &.show {
            height: 40px;
            visibility: visible;
            color: white;
        }

        .icon {
            position: absolute;
            right: 5px;
            top: 13px;
            font-size: 14px;
        }
    }
    
    .content-label {
        font-weight: 700;
        margin: var(--m-spacing) 0 0 var(--m-spacing);
        display: inline-flex;
        padding: var(--s-spacing);
        border-radius: var(--general-border-radius);
        
        &.unread {
            background-color: var(--highlight-color);
            color: var(--text-on-highlight-color);
        }
    }

    .corner {
        display: flex;
        align-items: center;

        right: 0;
        top: 0;
        margin: 0 7px;

        .pin-wrapper {
            padding: 0 7px;
            position: relative;

            rl-icon {
                color: var(--highlight-color);
                font-size: 13px;
            }
        }

        tip .tip-content {
            right: 12px;
            top: 40px;
            text-align: left;

            h3 {
                text-transform: uppercase;
                padding-bottom: 4px;

                &:after {
                    content: ':';
                }
            }

            &:after {
                position: absolute;
                right: 35px;
                left: auto;
                transform: translateX(0);
            }
        }

        .post-options {
            display: block;
            padding: 0 7px;
            text-align: center;
            color: @clr-bodytext;
            background: transparent;
            border: none;

            rl-icon {
                font-size: 20px;
            }

            @media @desktop-up {
                height: 35px;
                line-height: 35px;
                width: 35px;
            }
        }
    }

    .content-item-media {
        position: relative;
        
        &:not(:first-child) {
            margin-top: var(--s-spacing);
        }

        rl-badge {
            position: absolute;
            left: 10px;
            top: 8px;
            z-index: 6;
            padding: 0 5px;
            border-radius: 20px;
            width: auto;
        }

        &.no-image {
            height: 100px;
            border: 1px solid var(--general-background-color);
            border-top-left-radius: var(--general-border-radius);
            border-top-right-radius: var(--general-border-radius);
            background: var(--background-highlight-color);
        }


        .corner {
            z-index: 5;
            position: absolute;
            height: 40px;

            .post-options {
                color: var(--white-color);
                z-index: 4;
                text-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
            }
        }

        media-preview {
            display: flex;
            flex-direction: column;
            justify-content: center;
            overflow: hidden;

            border-top-right-radius: var(--general-border-radius);
            border-top-left-radius: var(--general-border-radius);

            .media {
                .image img {
                    @media @desktop-up {
                        max-height: 600px;
                        object-fit: contain;
                    }
                }
            }
        }

        content-social-sharing {
            .open-sharing {
                position: absolute;
                z-index: 9;
                right: 15px;
                bottom: -25px;
            }
        }
    }


    rl-rich-preview {
        margin: 15px 15px 0 15px;
        width: calc(100% - 30px);
    }

    translation-button {
        margin: 15px 0 15px 15px;
    }

    &.future-post {
        .content-item-body {
            margin-bottom: 15px;
        }
    }

    .content-item-actions {
        display: flex;
        justify-content: space-between;
        border-top: 1px solid var(--general-border-color);
        padding: 8px 0 0 0;

        > button {
            color: #000000;
            background: none;
            border: 0;
            padding: 0;
        }

        .content-item-comments {
            text-decoration: none;
            font-size: 13px;
        }

        .content-item-bookmark {
            padding: 0 5px;
            font-size: 18px;
        }

        .content-item-likes {
            height: 50px;
            line-height: 50px;
            text-align: center;
            margin-top: -15px;

            a {
                font-size: 13px;
                color: ~'rgba(var(--general-text-color-rgb), 0.4)';

                i {
                    margin-right: 5px;
                }

                &.liked {
                    color: #141823;
                }
            }
        }
    }

    .default-reaction-label {
        margin: 0;
    }

    .card-footer {
        .card-footer-actions-texts {
            button {
                span {
                    vertical-align: middle;
                }
            }

            .content-item-shares {
                rl-icon {
                    width: 22px;
                    height: 22px;
                    border-radius: 50%;

                    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

                    font-size: 12px !important;
                    color: var(--white-color);
                    background-color: #4267B2;
                    background-image: linear-gradient(rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0));
                }
            }
        }

        .content-item-like {
            .liked {
                color: var(--highlight-color);
            }

            reaction-icon {
                border: none;
                margin: 0;
            }

            .reaction-label {
                vertical-align: middle;
            }
        }

    }
}
