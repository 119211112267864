stage-feedback-popup {
    .main {
        overflow-y: auto;
        flex: 1;
        padding: 25px;

        &.approved {
            background-image: ~'linear-gradient(to bottom, rgba(var(--confirmation-color-rgb), 0.5), 15%, rgba(var(--general-text-color-rgb), 0))';


            .feedback-person rl-icon {
                background: var(--confirmation-color);
            }

            .feedback-status {
                color: var(--confirmation-color);
            }
        }

        &.rejected {
            background-image: ~'linear-gradient(to bottom, rgba(var(--warning-color-rgb), 0.5), 15%, rgba(var(--general-text-color-rgb), 0))';

            .feedback-person rl-icon {
                background: var(--warning-color);
            }

            .feedback-status {
                color: var(--warning-color);
            }
        }

        .feedback-person {
            display: flex;
            margin-bottom: 50px;

            .img-wrapper {
                position: relative;
                height: 70px;

                .img {
                    width: 70px;
                    height: 70px;
                    border-radius: 50%;
                }

                rl-icon {
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    color: var(--white-color);
                }
            }

            .text {
                display: flex;
                flex-direction: column;
                margin-left: 15px;

                span:not(:last-of-type) {
                    margin-bottom: 5px;
                }
            }
        }

        .feedback-comments {
            > h3 {
                margin: 0 0 15px 0;
                text-transform: uppercase;
                font-size: 13px;
                font-weight: 600;
            }

            .comment-item {
                display: flex;

                &:not(:last-of-type) {
                    margin-bottom: 8px;
                }

                &.approved {
                    rl-icon {
                        background: var(--confirmation-color);
                    }

                    .status {
                        color: var(--confirmation-color);
                    }
                }

                &.rejected {
                    rl-icon {
                        background: var(--warning-color);
                    }

                    .status {
                        color: var(--warning-color);
                    }
                }

                .image-wrap {
                    position: relative;
                    height: 30px;
                    margin-right: 10px;

                    .profile-image {
                        width: 30px;
                        height: 30px;
                        padding-right: 0;
                    }

                    rl-icon {
                        position: absolute;
                        bottom: 0;
                        right: 0;
                        font-size: 9px;
                        width: 13px;
                        height: 13px;
                        color: var(--white-color);
                    }
                }

                .comment-wrapper {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    
                    .translate-btn {
                        padding: 5px 0 10px 0;
                    }
                }

                .comment-content {
                    width: 100%;
                    background: var(--general-background-color);
                    padding: 10px;
                    border-radius: 15px;
                    position: relative;

                    .top {
                        display: flex;
                        justify-content: space-between;
                    }


                    h3 {
                        margin: 0;
                        font-size: 13px;
                        font-weight: 600;
                    }

                    p {
                        margin: 0;
                    }


                    &:after {
                        content: "";
                        position: absolute;
                        margin-top: -6px;
                        margin-left: -5px;
                        border-left: 5px solid transparent;
                        border-right: 5px solid transparent;
                        border-bottom: 5px solid var(--general-background-color);
                        transform: rotate(45deg);
                        left: 0;
                        top: 15px;
                    }
                }
            }
        }
    }
}   