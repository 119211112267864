select-content-category {
    display: block;
    position: relative;
    width: 100%;

    .click-area {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;
        gap: 5px;
    }

    .selected-category {
        padding: var(--s-spacing) var(--m-spacing);
        background: var(--background-highlight-color);
        border-radius: var(--general-border-radius);
        font-weight: 700;
        font-size: 12px;
        line-height: 130%;
    }
}

content-category-selector-popup {
    .category-selector {
        position: fixed;
        z-index: 11;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        background: var(--general-background-color);

        .category-scroll {
            padding: var(--spacing) var(--s-spacing);
            overflow-y: auto;
            flex: 1;
        }
    }

    .item {
        padding: 0 var(--m-spacing);
        margin-bottom: var(--s-spacing);

        @media @desktop-up {
            padding: var(--m-spacing);
        }

        &.selected {
            background: var(--background-highlight-color);
        }
    }
}
