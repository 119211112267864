attendance-overview-popup {
    right-buttons {
        position: relative;

        .tip-content {
            position: absolute;
            top: 45px;
            right: 5px;
            width: 260px;
            text-align: left;
            margin-bottom: 0;

            &:after {
                left: auto;
                right: 10px;
            }
        }
    }

    .content {
        padding: 10px 15px;

        display: flex;
        flex: 1;
        overflow-x: hidden;
        flex-direction: column;
    }

    .participants-header {
        flex-direction: column;

        .inner-participants-header {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            border-bottom: 1px solid rgba(var(--general-text-color-rgb), .1);

            button {
                max-height: 32px;
                line-height: 15px;
                padding-bottom: 10px;
                cursor: pointer;
            }
        }

        rl-search {
            width: 100%;
            margin-bottom: 15px;
        }
    }
    
    scroll-shadow {
        overflow: hidden;
        position: relative;
    }

    .tabs {
        display: flex;
        align-items: center;

        margin: 0;
        padding: 0;

        width: 100%;
        white-space: nowrap;

        list-style: none;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;

        li {
            padding: 0 0 10px;

            text-align: center;
            font-size: 14px;
            border-bottom: 2px solid transparent;
            cursor: pointer;

            &:not(:last-of-type) {
                margin-right: 10px;
            }

            &.active {
                border-bottom: 2px solid var(--highlight-color);
                color: var(--highlight-color);
            }

            rl-icon {
                margin-right: 2px;
                color: var(--white-color);
                box-shadow: 0 2px 6px 0 ~'rgba(var(--general-text-color-rgb), 0.2)';

                &[icon=save] {
                    background-color: var(--confirmation-color);
                }

                &[icon=help] {
                    background-color: var(--alert-color);

                    &.pending {
                        background-color: ~'rgba(var(--general-text-color-rgb), 0.5)';
                    }
                }

                &[icon=close] {
                    background-color: var(--warning-color);
                }
            }
        }
    }

    .user-list-wrapper {
        flex: 1;
        display: flex;
        overflow: hidden;
        flex-direction: column;
    }

    simple-user-list {
        display: block;
        height: 100%;
    }
}