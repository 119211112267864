@import "../Import.less";

.native-app-overlay {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    background: rgba(0,0,0,0.9);
    color: #fff;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: @level-modal;
    opacity: 0;
    -moz-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;

    iframe {
        display: none;
    }

    &.shown {
        opacity: 1;
    }

    .top {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;

        .close {
            border: none;
            background: none;
            cursor: pointer;
            padding: 0;

            > i {
                margin: 8px;
                font-size: 28px;
                color: #fff;
            }
        }
    }

    .content {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        text-align: center;
        -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        padding-bottom: 44px;

        .buttons {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            width: 75%;

            .store-button {
                background: #fff;
                color: #000;
                text-decoration: none;
                border-radius: 3px;
                padding: 10px 16px;
                margin: 5px 0;
                font-weight: 600;

                .container {
                    display: -webkit-inline-box;
                    display: -ms-inline-flexbox;
                    display: inline-flex;
                    align-items: center;

                    > span {
                        margin: 0 5px;
                    }

                    > i {
                        font-size: 24px;
                    }
                }
            }
        }

        h2{
            font-size: 20px;
            margin: 0 0 10px 0;
        }
        p{
            margin: 0 0 10px 0;
        }
        .spinner{
            border-color: #fff;
            margin-top: 10px;
        }
    }
}