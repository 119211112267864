report-popup {
    .actionsheet {
        z-index: 11;

        @media @desktop-up {
            min-width: 450px;
        }

        h4 {
            font-size: 1rem;

            @media @tablet-max {
                border-bottom: unset;
            }
        }

        .header-subtitle {
            @media @desktop-up {
                padding: 0;
            }
        }

        .actionsheet-content {
            margin-top: var(--s-spacing);
            padding: var(--m-spacing);

            @media @desktop-up {
                padding: 0 var(--m-spacing);
            }

            @media @tablet-max {
                border-top: 1px solid var(--general-border-color);
            }
        }
    }

    .sheet-back {
        position: absolute;
        top: 15px;
        left: 10px;

        rl-icon {
            font-size: 1.5rem;
        }
    }

    .report-content {
        display: flex;
        flex-direction: column;
    }

    .report-category {
        padding-left: var(--s-spacing);
        padding-top: var(--s-spacing);

        .title {
            font-size: 1rem;
            font-weight: 500;
        }

        .item {
            height: auto;
            margin-bottom: var(--m-spacing);

            .labels-container {
                gap: 2px;
            }
        }
    }

    .anonymous-text {
        color: rgba(var(--general-text-color-rgb), 0.4);
        margin-bottom: var(--m-spacing);
        margin-left: var(--s-spacing);
    }

    .report-category {
        &.selected {
            color: var(--highlight-color);
        }
    }

    .report-title {
        font-size: 1rem;
    }

    .next-button {
        margin-top: var(--spacing);
        border-radius: var(--general-border-radius);
        color: var(--text-on-highlight-color);
        background-color: var(--highlight-color);

        &.disabled {
            opacity: 0.5;
            pointer-events: none;
        }
    }

    .report-textarea {
        min-height: 100px;
        padding: var(--m-spacing);
        border-radius: var(--general-border-radius);
        border-color: var(--general-border-color);
        margin-left: var(--s-spacing);
        resize: none;
    }

    .anonymous-toggle {
        align-items: center;
        margin: var(--m-spacing) 0;
        display: flex;
        flex-direction: row;
        gap: var(--spacing);
        margin-left: var(--s-spacing);

        .help-text {
            color: rgba(var(--general-text-color-rgb), 0.4);
        }
    }

    rl-toggle {
        input[type=checkbox] {
            &:checked + label {
                background: var(--highlight-color);
            }
        }
    }
}