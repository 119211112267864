@survey-poll-footer-height: 36px;

.native .survey .survey-poll-container .survey-poll-footer {
    bottom: 51px;
    bottom: calc(51px + env(safe-area-inset-bottom, 0px));
}

.survey .survey-poll-container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding-bottom: 50px;
    padding-bottom: calc(50px + env(safe-area-inset-bottom, 0px));

    ks-swiper-container {
        display: inline;
    }

    .swiper-container {
        width: 100%;
        height: 100%;
        z-index: 1;
    }

    .survey-poll-inner {
        position: absolute;
        left: 0;
        right: 0;
        bottom: @survey-poll-footer-height;
        top: 0;
        overflow-x: hidden;
        overflow-y: auto;
        box-sizing: border-box;

        @media @tablet-up {
            padding: 15px ~'calc((100% - 400px) / 2)';
        }
    }

    .survey-poll-buttons {
        padding: 15px;
    }

    .survey-poll-outer {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
    }

    .survey-poll-footer {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        bottom: env(safe-area-inset-bottom, 0px);
        height: @survey-poll-footer-height;
        line-height: @survey-poll-footer-height;
        text-align: center;
        z-index: 2;
        font-weight: @font-weight-headline;
        font-size: 15px;

        a {
            position: absolute;
            left: 0;
            padding: 0 20px;
            display: block;
            height: @survey-poll-footer-height;
            line-height: @survey-poll-footer-height;
            overflow: hidden;
            bottom: 0;

            &.next {
                right: 0;
                left: auto;
            }
        }
    }

    .survey-poll-overlay {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        background: var(--popup-overlay-bg);
        z-index: 10;
    }

    .survey-poll-new-vote {
        background: var(--confirmation-color);
        color: var(--white-color);
        text-align: center;
        box-sizing: border-box;
        padding: 14px;
        font-size: 16px;
        text-transform: uppercase;
    }
}