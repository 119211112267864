@import "../../Import.less";

.metric {
    min-height: 80px;
    position: relative;
    overflow: hidden;

    .metric-top {
        display: block;

        h4, span {
            display: block;
            width: 50%;
            line-height: 24px;
            font-size: .9em;
            font-weight: @font-weight;
            -moz-box-sizing: border-box;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            float: left;
            margin: 0;
            padding: 0;
        }

        span {
            float: right;
            text-align: right;
            opacity: .5;
        }

        &:after {
            display: table;
            clear: both;
            content: '';
        }
    }

    .metric-content {
        text-align: center;
        margin-top: 5px;

        &.show-target{
            &.on-target .metric-value{
                color: @success-darker-color;
            }
            .metric-value{
                color: @error-darker-color;
            }
        }

        .metric-value {
            font-size: 40px;
            vertical-align: top;
        }

        .metric-development {
            display: inline-block;
            position: relative;
            height: 26px;
            width: 5px;
            border-radius: 5px;
            background: @success-color;
            vertical-align: top;
            margin-left: 15px;
            top: 13px;

            &.negative {
                background: @error-color;
                -moz-transform: rotate(180deg);
                -ms-transform: rotate(180deg);
                -o-transform: rotate(180deg);
                -webkit-transform: rotate(180deg);
                transform: rotate(180deg);

                &:before, &:after {
                    background: @error-color;
                }
            }

            &:before, &:after {
                display: block;
                height: 15px;
                width: 5px;
                border-radius: 5px;
                position: absolute;
                background: @success-color;
                content: '';
                top: -2px;
            }

            &:before {
                right: 4px;
                -moz-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                -o-transform: rotate(45deg);
                -webkit-transform: rotate(45deg);
                transform: rotate(45deg);
            }

            &:after {
                left: 4px;
                -moz-transform: rotate(-45deg);
                -ms-transform: rotate(-45deg);
                -o-transform: rotate(-45deg);
                -webkit-transform: rotate(-45deg);
                transform: rotate(-45deg);
            }
        }
    }

    .metric-chart-label {
        display: block;
        text-align: center;
        font-size: .85em;
        text-transform: uppercase;
        position: relative;
        top: -3px;
    }

    .metric-loader{
        position: absolute;
        top: 50%;
        left: 0; 
        right: 0;
        margin-top: -11px;
        text-align: center;
    }

    &.metric-label{
        .metric-content{
            text-align:center;
            .metric-value{
                font-size: 15px;
                
            }
        }
    }
}

// SIZE 1 (SMALL)
.size-1 .metric{
    min-height: 40px;
    .metric-top{
        display: none;
    }
    .metric-content{
        margin-top: 0;
        .metric-value{
            font-size: 18px;
        }
    }
    .metric-chart-label{
        font-size: 12px;
    }

    &.metric-label{
        .metric-content{
            .metric-value{
                font-size: 20px;
            }
        }
    }
}

// SIZE 2 (MEDIUM)
.size-2 .metric{
    min-height: 40px;
    .metric-top{
        display: none;
    }
    .metric-content{
        margin-top: 0;
        text-align:center;
        .metric-value{
            font-size: 18px;
        }
    }
    .metric-chart-label{
        font-size: 12px;
    }

    &.metric-label{
        .metric-content{
            text-align:center;
            .metric-value{
                
            }
        }
    }
    .metric-development {
        top:0px;
    }
}


/* ----------- iPad 3 and 4 ----------- */
/* Portrait and Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
    // SIZE 1 (SMALL)
    .size-1 .metric {
        .metric-content {
            .metric-value {
                font-size: 32px;
            }
        }
    }

    // SIZE 2 (MEDIUM)
    .size-2 .metric {
        .metric-content {
            .metric-value {
                font-size: 32px;
            }
        }

    }

}