@import "../Import.less";

@calendar-header-height: 50px;

calendar {
    display: block;
}

.calendar {
    display: block;
    position: relative;

    .calendar-header {
        position: relative;
        height: @calendar-header-height;
        border-bottom: 1px solid rgba(0,0,0,0.12);

        button {
            position: absolute;
            top: 0;
            bottom: 0;
            padding: 0 10px;
            height: @calendar-header-height;
            line-height: @calendar-header-height;
            width: 50px;
            overflow: hidden;
            box-sizing: border-box;
            text-align: left;
            background: transparent;
            border: none;

            &:first-child {
                left: 0;
            }

            &:last-child {
                right: 0;
                text-align: right;
            }
        }

        h2 {
            display: block;
            margin: 0;
            padding: 0;
            text-align: center;
            height: @calendar-header-height;
            line-height: @calendar-header-height;
            text-transform: capitalize;
            font-size: 18px;
        }
    }

    > table {
        thead {
            td {
                line-height: 40px;
                text-align: center;
                font-weight: bold;
                text-transform: capitalize;
                position: relative;
                overflow: hidden;

                &.weekend {
                    color: @success-color;
                }
            }
        }

        tbody {
            td {
                line-height: 40px;
                text-align: center;
                position: relative;
                overflow: hidden;
                width: 14%;

                &.out {
                    button {
                        color: rgba(0,0,0,0.4);
                    }
                }

                button {
                    position: relative;
                    padding: 0;
                    margin: 0;
                    overflow: hidden;
                    border-radius: 5px;
                    border: none;
                    background: transparent;

                    .inner {
                        vertical-align: middle;
                        font-weight: 600;
                        display: inline-block;
                        height: 30px;
                        width: 30px;
                        text-align: center;
                        line-height: 30px;
                    }

                    &.has-item {
                        .inner {
                            background: rgba(0,0,0,0.06);
                            font-size: 13px;
                            border-radius: 100%;
                        }

                        &.negative {
                            .inner {
                                background: @error-color;
                                color: #fff;
                            }
                        }

                        &.positive {
                            .inner {
                                background: @success-color;
                                color: #fff;
                            }
                        }
                    }
                }
            }
        }
    }
}

[calendar-item] {
    display: none;
}
