statistics-popup {

    section {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        height: 100%;
    }

    .filter-tabs-wrapper {
        padding: 15px;
    }

    .filter-tabs {
        display: flex;
        align-items: baseline;
        justify-content: flex-start;
        padding: 0;
        border-bottom: 1px solid ~'rgba(var(--text-on-white-color-rgb), .1)';

        .btn-filter {
            position: relative;
            font-size: 13px;
            cursor: pointer;
            padding: 5px;
            margin-right: 15px;

            &.with-icon {
                padding-left: 27px;
            }

            &.active {
                color: var(--highlight-color);
                border-bottom: 3px solid var(--highlight-color);
            }

            rl-icon {
                position: absolute;
                top: 0;
                left: 0;
                min-width: 20px;
                min-height: 20px;
                max-width: 20px;
                max-height: 20px;
                line-height: 20px;
                border-radius: 50%;
                border: 1px solid ~'rgba(var(--general-text-color-rgb), .1)';
                align-items: center;
                color: white;
                font-size: 16px;

            }
        }

        .overdue-icon {
            background: var(--warning-color);
        }

        .complete-icon {
            background: var(--confirmation-color);
        }

        .in-progress-icon {
            background: none;
            position: absolute;
            top: 0;
            left: 0;

            .c100 {
                font-size: 22px;

                &:after {
                    background-color: white;
                }
            }
        }
    }
}

