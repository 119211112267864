badge {
    position: relative;
    align-items: center;
    display: flex;
    flex-direction: column;

    text-align: center;
    box-sizing: border-box;
    word-break: break-word;

    rl-icon.circle {
        position: absolute;
        top: -1px;
        right: 8px;
        width: 27px;
        height: 27px;
        font-size: 16px;
        background-color: var(--alert-color);
        color: var(--white-color);
        &.expired {
            background-color: var(--warning-color);
        }
    }

    .image-wrap {
        width: 100%;
    }

    .image {
        position: relative;
        width: 100%;
        padding-top: 100%;
        clip-path: url(#clipPath);
        transform: translateZ(0);

        .img, .overlay {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
        }

        .overlay {
            z-index: 1;
        }

        .img {
            background-position: center center;
        }

        &.default {
            background: var(--highlight-color);

            rl-icon {
                color: var(--text-on-highlight-color);
                font-size: 30px;
            }
        }

        &.not-achieved {
            background: var(--general-background-color);

            &:after {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                background: var(--general-text-inactive-color);
            }
        }

        &.not-active {
            &:after {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                background: ~'rgba(var(--white-color-rgb), 0.6)';
            }
        }

        rl-icon {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 35px;
            color: ~'rgba(var(--general-text-color-rgb), 0.4)';
        }
    }

    svg {
        position: absolute;
        width: 0;
        height: 0;
    }

    p {
        margin: 3px 0 0 0;
        font-size: 10px;
        font-weight: 400;
        color: var(--general-text-color);

        @media @tablet-up {
            margin: 10px 0 0 0;
            font-size: 15px;
        }

        &.not-achieved {
            opacity: 0.4;
        }
    }
}
