reaction-icon {
    display: inline-block;
    vertical-align: middle;
    width: 22px;
    height: 22px;
    position: relative;
    border-radius: 50%;
    box-sizing: border-box;
    filter: drop-shadow(0px 6px 15px rgba(0, 0, 0, 0.15));

    .img-wrapper {
        border-radius: 50%;
        height: 100%;
        width: 100%;
    }

    img {
        width: 100%;
        height: 100%;
    }

    rl-icon {
        width: 100%;
        height: 100%;
        display: flex;

        .reaction-like {
            border-radius: 50%;
            font-size: 10px;
            color: white;
            background: var(--highlight-color);
            background-image: linear-gradient(rgba(255,255,255, .3), rgba(255, 255, 255, 0));
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            padding-bottom: 2px;
            box-sizing: border-box;
            box-shadow: 0 2px 4px rgba(0, 0, 0, .1);

            &:before {
                transition: font-size .2s;
            }
        }
    }

    .failed-icon {
        width: 18px;
        height: 18px;
        border-radius: 50%;
        background-color: var(--warning-color);
        color: var(--white-color);
        display: grid;

        rl-icon {
            font-size: 0.8rem;
        }
    }
}