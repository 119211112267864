feedback-inline-comments {
    display: block;
    padding-top: 15px;
    margin-top: 15px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);


    comment-item {
        padding: 0;

        &.child {
            padding-left: 65px;
        }


        profile-image > .profile-image {
            width: 30px;
            height: 30px;
        }

        &:last-of-type {
            margin-bottom: 0;
        }

        .comments-actions {
            padding: 5px 0 10px 0;
            top: auto;
            min-height: auto;
        }
    }

    rl-button {
        padding-top: 15px;

        button {
            height: auto;
            font-weight: 400;
            color: rgba(var(--general-text-color-rgb), 0.4) !important;
        }
    }
}