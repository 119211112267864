lesson-evaluation {
    .content-wrapper {
        background-size: 100% 250px;
        background-repeat: no-repeat;
    }

    .passed {
        background-image: linear-gradient(180deg, rgba(var(--confirmation-color-rgb), 0.5) 0%, var(--general-background-color) 80%);

        .title h3 {
            color: var(--confirmation-color);
        }

        .score h2 {
            color: var(--confirmation-color);
        }
    }

    .time-out,
    .not-passed {
        background-image: linear-gradient(180deg, rgba(var(--warning-color-rgb), 0.5) 0%, var(--general-background-color) 80%);

        .title h3 {
            color: var(--warning-color);
        }

        .score h2 {
            color: var(--warning-color);
        }
    }

    .time-out {
        background-image: linear-gradient(180deg, rgba(var(--alert-color-rgb), 0.5) 0%, var(--general-background-color) 80%);
    }

    .title {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 60px 0 20px 0;

        .img {
            margin-bottom: 20px;
            width: 70px;
            height: 70px;
        }

        h1 {
            margin: 0 0 var(--m-spacing) 0;
            font-size: 28px;
            font-weight: 700;
            line-height: 110%;
        }

        h3 {
            margin: 0;
            font-weight: 700;
        }
    }

    .questions-wrapper {
        padding: var(--spacing);
        border: 1px solid var(--general-border-color);
        border-radius: var(--general-border-radius);

        .score {
            text-align: center;
            padding-bottom: var(--spacing);
            margin-bottom: var(--spacing);
            border-bottom: 1px solid var(--general-border-color);

            h2 {
                margin: 0 0 5px 0;
                font-size: 40px;
                font-weight: 700;
                line-height: 100%;
            }

            h3 {
                margin: 0;
                font-weight: 700;
            }
        }

        .question {
            display: flex;
            align-items: center;
            padding: calc(var(--m-spacing) / 2) 0;
            gap: var(--m-spacing);

            .question-title {
                flex: 1;
                overflow: hidden;

                white-space: nowrap;
                text-overflow: ellipsis;
                font-size: 13px;
                line-height: 1;
            }

            &:not(:last-of-type) {
                margin-bottom: var(--spacing);
            }

            rl-icon {
                width: 24px;
                height: 24px;
                border-radius: 50%;
                font-size: 14px;

                &[icon=save] {
                    color: var(--confirmation-color);
                    background: rgba(var(--confirmation-color-rgb), 0.1);
                }

                &[icon=clock],
                &[icon=close] {
                    color: var(--warning-color);
                    background: rgba(var(--warning-color-rgb), 0.1);
                }

                &[icon=eye] {
                    cursor: pointer;
                    color: var(--general-text-color);
                    background: rgba(var(--general-text-color-rgb), 0.1);
                }
            }
        }
    }
}