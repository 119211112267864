@import "../Import.less";

@employee-evaluation-list-item-height: 30px;

.employee-evaluation {
    
    .employee-evaluation-list-view,
    .employee-evaluation-select-view {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        overflow-x: hidden;
        overflow-y: auto;
        transition: transform ease-in-out .5s;
    }

    .employee-evaluation-select-view {
        transform: translate3d(0, 0, 0);
        display: flex;
        flex-direction: column;

        p {
            text-align: center;
            margin: 20px 0 0 0;
        }

        button {
            display: block;
            position: relative;
            overflow: hidden;
            margin: 20px 10px 0 10px;
            padding: 40px 0;
            text-align: center;
            font-size: 18px;
            border-radius: 4px;
            background: rgba(0, 0, 0, 0.7);
            color: #fff;
            .shadow-depth-1();
            font-weight: 600;

            i {
                font-size: 40px;
            }

            span {
                display: block;
            }
        }
    }

    .employee-evaluation-list-view {
        transform: translate3d(100%, 0, 0);
    }

    .employee-evaluation-type-selected {
        .employee-evaluation-select-view {
            transform: translate3d(-100%, 0, 0);
        }

        .employee-evaluation-list-view {
            transform: translate3d(0, 0, 0);
        }
    }

    .employee-evaluation-employee-list,
    .employee-evaluation-supervisor-list {
        ul {
            display: block;
            list-style: none;
            padding: 0;
            margin: 0;

            li {
                padding: 12px 10px;
                border-bottom: 1px solid #eee;
                position: relative;
                height: @employee-evaluation-list-item-height;
                line-height: @employee-evaluation-list-item-height;
                display: -webkit-box;
                display: flex;
                -webkit-box-orient: horizontal;
                -webkit-box-direction: normal;
                flex-direction: row;

                &:last-child {
                    border: none;
                }

                h3 {
                    margin: 0;
                    padding: 0;
                    font-size: 14px;
                    font-weight: normal;
                    text-transform: capitalize;
                    display: inline-block;
                    vertical-align: middle;
                }

                .item-smiley {
                    display: inline-block;
                    height: 26px;
                    width: 26px;
                    margin-right: 8px;
                    vertical-align: middle;

                    img {
                        height: 100%;
                        width: 100%;
                    }
                }

                .item-left-content {
                    -webkit-box-flex: 1;
                    flex: 1 1 auto;
                }

                .item-right-content {
                    height: @employee-evaluation-list-item-height;
                    line-height: @employee-evaluation-list-item-height;
                    text-align: right;
                    -webkit-box-flex: 1;
                    flex: 1 0 auto;

                    .chkSwitch {
                        display: inline-block;
                        vertical-align: middle;
                        position: relative;
                        top: 2px;
                        margin-right: 8px;
                    }

                    a {
                        display: inline-block;
                        height: @employee-evaluation-list-item-height;
                        line-height: @employee-evaluation-list-item-height;
                        padding: 0 14px;
                        background: #efefef;
                        border-radius: 3px;
                        text-align: center;
                        font-size: 14px;
                        position: relative;
                        overflow: hidden;
                        vertical-align: middle;

                        &.transparent {
                            opacity: 0;
                        }
                    }
                }
            }

            li.list-header {
                line-height: 20px;
                height: 20px;
                background: #eee;
                border-bottom: 1px solid #ddd;
                text-transform: capitalize;

                h2 {
                    margin: 0;
                    padding: 0;
                    font-weight: 600;
                    font-size: 15px;
                }
            }
        }
    }

    .employee-evaluation-supervisor-list {
        ul {
            li {
                padding: 12px 10px 3px 10px;
                display: -webkit-box;
                display: flex;
                -webkit-box-orient: horizontal;
                -webkit-box-direction: normal;
                flex-direction: row;
                height: auto;
                line-height: normal;

                .item-left-content {
                    line-height: 20px;

                    h3, h4 {
                        padding: 0;
                        margin: 0;
                        position: relative;
                        display: block;
                        word-break: break-word;
                    }

                    h3 {
                        padding: 0;
                        margin: 5px 0;
                        font-weight: 600;
                        top: -3px;
                        line-height: 13px;
                    }

                    h4 {
                        font-weight: normal;
                        font-size: 13px;
                        top: -6px;
                    }
                }

                .item-right-content {
                    margin: 0;
                    min-width: 140px;
                    max-width: 140px;

                    .chkSwitch {
                        margin: 0;
                    }

                    .attachment-btn {
                        width: 30px;
                        padding: 0;
                        border-radius: 100%;
                        transform: rotate3d(0, 0, 1, -90deg);
                        font-size: 16px;
                        right: -3px;
                        background: none;

                        &:not(.has-attachment) {
                            color: #ddd;
                        }

                        &.hide-btn {
                            opacity: 0;
                        }
                    }
                }

                &:after {
                    display: table;
                    clear: both;
                    content: '';
                }
            }
        }
    }
}

.employee-evaluation-view {
    table {
        width: 100%;
        box-sizing: border-box;
        border-left: solid 8px transparent;
        border-right: solid 8px transparent;

        tr {
            border: none;

            td {
                padding: 13px 2px;
                border: none;
                box-sizing: border-box;
                border-bottom: 1px solid #eee;

                &:first-child {
                    width: 40%;
                    font-weight: 600;
                    overflow: hidden;
                }

                &:last-child {
                    width: 60%;
                    text-align: right;
                }

                .capitalize {
                    text-transform: capitalize;
                }

                .report-smiley {
                    width: 26px;
                    height: 26px;
                    display: inline-block;
                    vertical-align: middle;

                    img {
                        height: 100%;
                        width: 100%;
                    }
                }

                .chkSwitch {
                    display: inline-block;
                    vertical-align: middle;
                    position: relative;
                    margin-left: 8px;
                }
            }
        }
    }

    .attachment-container {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;

        a {
            display: block;
            margin: 0 10px 10px 10px;
            background: #eee;
            text-align: center;
            line-height: 36px;
            position: relative;
            overflow: hidden;
            border-radius: 4px;
        }
    }
}
