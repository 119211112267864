@import "../../Import.less";

@transition-speed: .2s;

.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: var(--popup-overlay-bg);
    z-index: @level-loader-overlay;
    transition-duration: @transition-speed;
    transition-property: opacity;
    transition-timing-function: ease-in-out;
    .flex-center-center();

    &.ng-enter, &.ng-leave-active {
        opacity: 0;
    }

    &.ng-enter-active {
        opacity: 1;
    }

    .loading-wrapper {
        .spinner {
            height: 50px;
            width: 50px;
            border-width: 4px;
        }
    }
}
