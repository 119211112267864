switch-user-popup {
    --pin-actionsheet-top: 0;

    action-sheet-custom {
        margin-left: unset;

        @media @desktop-up {
            .actionsheet-overlay {
                background: none;
            }
        }

        .pin-action-sheet {
            @media @desktop-up {
                top: var(--pin-actionsheet-top);
                padding: var(--s-spacing);
                min-width: 350px;

                & > h4 {
                    color: var(--elevated-text-inactive-color);
                    text-align: left;
                    font-size: 0.875rem;
                    padding: var(--m-spacing) var(--s-spacing);
                    margin: 0;
                }

                .actionsheet-content {
                    padding: 0;

                    .buttons {
                        display: flex;
                        flex-direction: column;
                        gap: 5px;
                    }
                }
            }
        }
    }

    .list-item-mixin() {
        display: flex;
        align-items: center;
        gap: var(--m-spacing);
        border-radius: var(--general-border-radius);
        width: 100%;
        padding: 6px;
        transition: background-color 0.3s ease;
        
        &:hover {
            background-color: rgba(var(--elevated-text-color-rgb), 0.04);
        }
    }

    .user {
        .list-item-mixin();
    
        &.selected {
            background-color: rgba(var(--elevated-text-color-rgb), 0.04);
            font-weight: 600;

            .radio {
                background: radial-gradient(circle, 
                    var(--highlight-color) 0%, 
                    var(--highlight-color) 35%, 
                    transparent 35%, 
                    transparent 60%,
                    var(--highlight-color) 60%, 
                    var(--highlight-color) 100%);
            }
        }

        .profile-image {
            width: 28px;
            height: 28px;
            padding-right: 0;
        }

        .radio {
            margin-left: auto;
            width: 17px;
            height: 17px;
            border-radius: 50%;
            background: radial-gradient(circle, 
                transparent 0%, 
                transparent 60%,
                var(--elevated-text-inactive-color) 60%, 
                var(--elevated-text-inactive-color) 100%);
        }

        .user-name {
            max-width: calc(100% - 90px);
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }

    .login-as-new-user-btn {
        font-weight: 600;
        color: var(--highlight-color);
        .list-item-mixin();

        rl-icon {
            border-radius: 50%;
            width: 28px;
            height: 28px;
            background-color: rgba(var(--highlight-color-rgb), 0.2);
            font-size: 14px;
        }
    }
}