@import "../../../Import.less";

.ec-shopgun-list-view {
    ul.ec-shopgun-list-view-ul {
        list-style: none;
        padding: 10px 0;
        margin: 0;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        li {
            width: 100%;
            height: 300px;
            display: block;
            flex: 0 0 auto;

            @media only screen and (min-width: 560px) {
                width: 50%;
            }

            @media only screen and (min-width: 900px) {
                width: 33%;
            }

            @media @desktop-big-up{
                width: 25%;    
            }
            
            button {
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 0;
                padding: 15px;
                box-sizing: border-box;
                text-align: center;
                width: 100%;
                height: 100%;
                position: relative;
                overflow: hidden;
                flex-direction: column;

                img {
                    display: inline-block;
                    max-width: 160px;
                    height: auto;
                    width: auto;
                    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
                }

                h3, h4 {
                    display: block;
                    text-align: center;
                }

                h3 {
                    font-size: 16px;
                    margin: 10px 0 0 0;
                }

                h4 {
                    font-size: 14px;
                    opacity: .7;
                    margin: 5px 0 0 0;
                }
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}
