@import "../Variables.less";

autocomplete {

    .search-wrapper {
        position: relative;

        input {
            height: 40px;
            width: 100%;
            box-sizing: border-box;
            padding: 0 40px 0 10px;
            border-radius: 0;
            line-height: 40px;
            font-size: 15px;
            border: 1px solid var(--background-highlight-color);
            background: var(--background-highlight-color);
            font-weight: 500;
            -webkit-appearance: none;

            &::placeholder {
                color: var(--general-text-inactive-color);
            }

            &.valid {
                border: 1px solid var(--highlight-color);
                color: var(--highlight-color);
            }

            &.invalid {
                border: 1px solid var(--warning-color);
                color: var(--warning-color);
            }
        }

        rl-icon {
            position: absolute;
            top: 0;
            right: 0;
            padding: 10px;
        }
    }

    ul {
        margin: 0;
        padding: 0;

        li {
            font-size: 15px;
            list-style: none;
        }
    }

    .visible-items {
        max-height: 200px;
        overflow-y: auto;
        overscroll-behavior-y: contain;
        background: var(--background-highlight-color);

        li {
            height: 40px;
            padding: 0 10px;
            overflow: hidden;
            box-sizing: border-box;
            text-overflow: ellipsis;
            white-space: nowrap;

            p {
                margin: 0;
                padding: 0;
                line-height: 40px;
            }

            span {
                display: none;
            }

            &.with-subtitle {
                p {
                    line-height: 26px;
                }

                span {
                    display: block;
                    line-height: 14px;
                    font-size: 11px;
                    opacity: .7;
                    margin-top: -4px;
                }
            }
        }
    }

    .not-found {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        box-sizing: border-box;
        padding: 20px 40px;
        background: var(--background-highlight-color);

        icon {
            width: 75px;
            height: 75px;
        }

        h2 {
            margin: 0 0 10px 0;
        }

        p {
            margin: 0;
            text-align: center;
        }
    }
}
