@import "../Fonts/FontAwesomePro/all.min.css";
@import "../Fonts/AvenirPro/stylesheet.css";
@import "../Fonts/OpenDyslexic2/stylesheet.css";
.initial-load #loading-bar,
.initial-load #loading-bar-spinner {
  display: none;
}
#loading-bar .bar {
  background: var(--loadbar-color);
  top: 0;
}
#loading-bar .peg {
  box-shadow: var(--loadbar-color) 1px 0 6px 1px;
}
#loading-bar-spinner {
  top: 13px;
  left: auto;
  right: 13px;
  display: block;
}
#loading-bar-spinner > .spinner {
  border-color: var(--loadbar-color);
}
ks-swiper-container {
  display: block;
}
.flex-container-row {
  display: -webkit-box;
  display: flex;
  flex-direction: row;
}
.no-select {
  user-select: none;
}
/* MaterialDesignIcons.com */
@font-face {
  font-family: "Material Design Icons";
  src: url("/Content/Fonts/MaterialDesignIcons/materialdesignicons-webfont.eot?v=1.9.32");
  src: url("/Content/Fonts/MaterialDesignIcons/materialdesignicons-webfont.eot?#iefix&v=1.9.32") format("embedded-opentype"), url("/Content/Fonts/MaterialDesignIcons/materialdesignicons-webfont.woff2?v=1.9.32") format("woff2"), url("/Content/Fonts/MaterialDesignIcons/materialdesignicons-webfont.woff?v=1.9.32") format("woff"), url("/Content/Fonts/MaterialDesignIcons/materialdesignicons-webfont.ttf?v=1.9.32") format("truetype"), url("/Content/Fonts/MaterialDesignIcons/materialdesignicons-webfont.svg?v=1.9.32#materialdesigniconsregular") format("svg");
  font-weight: normal;
  font-style: normal;
}
.mdi {
  display: inline-block;
  font: normal normal normal 24px/1 "Material Design Icons";
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0);
}
.mdi-access-point:before {
  content: "\F002";
}
.mdi-access-point-network:before {
  content: "\F003";
}
.mdi-account:before {
  content: "\F004";
}
.mdi-account-alert:before {
  content: "\F005";
}
.mdi-account-box:before {
  content: "\F006";
}
.mdi-account-box-outline:before {
  content: "\F007";
}
.mdi-account-card-details:before {
  content: "\F5D2";
}
.mdi-account-check:before {
  content: "\F008";
}
.mdi-account-circle:before {
  content: "\F009";
}
.mdi-account-convert:before {
  content: "\F00A";
}
.mdi-account-edit:before {
  content: "\F6BB";
}
.mdi-account-key:before {
  content: "\F00B";
}
.mdi-account-location:before {
  content: "\F00C";
}
.mdi-account-minus:before {
  content: "\F00D";
}
.mdi-account-multiple:before {
  content: "\F00E";
}
.mdi-account-multiple-minus:before {
  content: "\F5D3";
}
.mdi-account-multiple-outline:before {
  content: "\F00F";
}
.mdi-account-multiple-plus:before {
  content: "\F010";
}
.mdi-account-network:before {
  content: "\F011";
}
.mdi-account-off:before {
  content: "\F012";
}
.mdi-account-outline:before {
  content: "\F013";
}
.mdi-account-plus:before {
  content: "\F014";
}
.mdi-account-remove:before {
  content: "\F015";
}
.mdi-account-search:before {
  content: "\F016";
}
.mdi-account-settings:before {
  content: "\F630";
}
.mdi-account-settings-variant:before {
  content: "\F631";
}
.mdi-account-star:before {
  content: "\F017";
}
.mdi-account-switch:before {
  content: "\F019";
}
.mdi-adjust:before {
  content: "\F01A";
}
.mdi-air-conditioner:before {
  content: "\F01B";
}
.mdi-airballoon:before {
  content: "\F01C";
}
.mdi-airplane:before {
  content: "\F01D";
}
.mdi-airplane-landing:before {
  content: "\F5D4";
}
.mdi-airplane-off:before {
  content: "\F01E";
}
.mdi-airplane-takeoff:before {
  content: "\F5D5";
}
.mdi-airplay:before {
  content: "\F01F";
}
.mdi-alarm:before {
  content: "\F020";
}
.mdi-alarm-check:before {
  content: "\F021";
}
.mdi-alarm-multiple:before {
  content: "\F022";
}
.mdi-alarm-off:before {
  content: "\F023";
}
.mdi-alarm-plus:before {
  content: "\F024";
}
.mdi-alarm-snooze:before {
  content: "\F68D";
}
.mdi-album:before {
  content: "\F025";
}
.mdi-alert:before {
  content: "\F026";
}
.mdi-alert-box:before {
  content: "\F027";
}
.mdi-alert-circle:before {
  content: "\F028";
}
.mdi-alert-circle-outline:before {
  content: "\F5D6";
}
.mdi-alert-decagram:before {
  content: "\F6BC";
}
.mdi-alert-octagon:before {
  content: "\F029";
}
.mdi-alert-octagram:before {
  content: "\F766";
}
.mdi-alert-outline:before {
  content: "\F02A";
}
.mdi-all-inclusive:before {
  content: "\F6BD";
}
.mdi-alpha:before {
  content: "\F02B";
}
.mdi-alphabetical:before {
  content: "\F02C";
}
.mdi-altimeter:before {
  content: "\F5D7";
}
.mdi-amazon:before {
  content: "\F02D";
}
.mdi-amazon-clouddrive:before {
  content: "\F02E";
}
.mdi-ambulance:before {
  content: "\F02F";
}
.mdi-amplifier:before {
  content: "\F030";
}
.mdi-anchor:before {
  content: "\F031";
}
.mdi-android:before {
  content: "\F032";
}
.mdi-android-debug-bridge:before {
  content: "\F033";
}
.mdi-android-studio:before {
  content: "\F034";
}
.mdi-angular:before {
  content: "\F6B1";
}
.mdi-angularjs:before {
  content: "\F6BE";
}
.mdi-animation:before {
  content: "\F5D8";
}
.mdi-apple:before {
  content: "\F035";
}
.mdi-apple-finder:before {
  content: "\F036";
}
.mdi-apple-ios:before {
  content: "\F037";
}
.mdi-apple-keyboard-caps:before {
  content: "\F632";
}
.mdi-apple-keyboard-command:before {
  content: "\F633";
}
.mdi-apple-keyboard-control:before {
  content: "\F634";
}
.mdi-apple-keyboard-option:before {
  content: "\F635";
}
.mdi-apple-keyboard-shift:before {
  content: "\F636";
}
.mdi-apple-mobileme:before {
  content: "\F038";
}
.mdi-apple-safari:before {
  content: "\F039";
}
.mdi-application:before {
  content: "\F614";
}
.mdi-apps:before {
  content: "\F03B";
}
.mdi-archive:before {
  content: "\F03C";
}
.mdi-arrange-bring-forward:before {
  content: "\F03D";
}
.mdi-arrange-bring-to-front:before {
  content: "\F03E";
}
.mdi-arrange-send-backward:before {
  content: "\F03F";
}
.mdi-arrange-send-to-back:before {
  content: "\F040";
}
.mdi-arrow-all:before {
  content: "\F041";
}
.mdi-arrow-bottom-left:before {
  content: "\F042";
}
.mdi-arrow-bottom-right:before {
  content: "\F043";
}
.mdi-arrow-compress:before {
  content: "\F615";
}
.mdi-arrow-compress-all:before {
  content: "\F044";
}
.mdi-arrow-down:before {
  content: "\F045";
}
.mdi-arrow-down-bold:before {
  content: "\F72D";
}
.mdi-arrow-down-bold-box:before {
  content: "\F72E";
}
.mdi-arrow-down-bold-box-outline:before {
  content: "\F72F";
}
.mdi-arrow-down-bold-circle:before {
  content: "\F047";
}
.mdi-arrow-down-bold-circle-outline:before {
  content: "\F048";
}
.mdi-arrow-down-bold-hexagon-outline:before {
  content: "\F049";
}
.mdi-arrow-down-box:before {
  content: "\F6BF";
}
.mdi-arrow-down-drop-circle:before {
  content: "\F04A";
}
.mdi-arrow-down-drop-circle-outline:before {
  content: "\F04B";
}
.mdi-arrow-down-thick:before {
  content: "\F046";
}
.mdi-arrow-expand:before {
  content: "\F616";
}
.mdi-arrow-expand-all:before {
  content: "\F04C";
}
.mdi-arrow-left:before {
  content: "\F04D";
}
.mdi-arrow-left-bold:before {
  content: "\F730";
}
.mdi-arrow-left-bold-box:before {
  content: "\F731";
}
.mdi-arrow-left-bold-box-outline:before {
  content: "\F732";
}
.mdi-arrow-left-bold-circle:before {
  content: "\F04F";
}
.mdi-arrow-left-bold-circle-outline:before {
  content: "\F050";
}
.mdi-arrow-left-bold-hexagon-outline:before {
  content: "\F051";
}
.mdi-arrow-left-box:before {
  content: "\F6C0";
}
.mdi-arrow-left-drop-circle:before {
  content: "\F052";
}
.mdi-arrow-left-drop-circle-outline:before {
  content: "\F053";
}
.mdi-arrow-left-thick:before {
  content: "\F04E";
}
.mdi-arrow-right:before {
  content: "\F054";
}
.mdi-arrow-right-bold:before {
  content: "\F733";
}
.mdi-arrow-right-bold-box:before {
  content: "\F734";
}
.mdi-arrow-right-bold-box-outline:before {
  content: "\F735";
}
.mdi-arrow-right-bold-circle:before {
  content: "\F056";
}
.mdi-arrow-right-bold-circle-outline:before {
  content: "\F057";
}
.mdi-arrow-right-bold-hexagon-outline:before {
  content: "\F058";
}
.mdi-arrow-right-box:before {
  content: "\F6C1";
}
.mdi-arrow-right-drop-circle:before {
  content: "\F059";
}
.mdi-arrow-right-drop-circle-outline:before {
  content: "\F05A";
}
.mdi-arrow-right-thick:before {
  content: "\F055";
}
.mdi-arrow-top-left:before {
  content: "\F05B";
}
.mdi-arrow-top-right:before {
  content: "\F05C";
}
.mdi-arrow-up:before {
  content: "\F05D";
}
.mdi-arrow-up-bold:before {
  content: "\F736";
}
.mdi-arrow-up-bold-box:before {
  content: "\F737";
}
.mdi-arrow-up-bold-box-outline:before {
  content: "\F738";
}
.mdi-arrow-up-bold-circle:before {
  content: "\F05F";
}
.mdi-arrow-up-bold-circle-outline:before {
  content: "\F060";
}
.mdi-arrow-up-bold-hexagon-outline:before {
  content: "\F061";
}
.mdi-arrow-up-box:before {
  content: "\F6C2";
}
.mdi-arrow-up-drop-circle:before {
  content: "\F062";
}
.mdi-arrow-up-drop-circle-outline:before {
  content: "\F063";
}
.mdi-arrow-up-thick:before {
  content: "\F05E";
}
.mdi-assistant:before {
  content: "\F064";
}
.mdi-asterisk:before {
  content: "\F6C3";
}
.mdi-at:before {
  content: "\F065";
}
.mdi-atom:before {
  content: "\F767";
}
.mdi-attachment:before {
  content: "\F066";
}
.mdi-audiobook:before {
  content: "\F067";
}
.mdi-auto-fix:before {
  content: "\F068";
}
.mdi-auto-upload:before {
  content: "\F069";
}
.mdi-autorenew:before {
  content: "\F06A";
}
.mdi-av-timer:before {
  content: "\F06B";
}
.mdi-baby:before {
  content: "\F06C";
}
.mdi-baby-buggy:before {
  content: "\F68E";
}
.mdi-backburger:before {
  content: "\F06D";
}
.mdi-backspace:before {
  content: "\F06E";
}
.mdi-backup-restore:before {
  content: "\F06F";
}
.mdi-bandcamp:before {
  content: "\F674";
}
.mdi-bank:before {
  content: "\F070";
}
.mdi-barcode:before {
  content: "\F071";
}
.mdi-barcode-scan:before {
  content: "\F072";
}
.mdi-barley:before {
  content: "\F073";
}
.mdi-barrel:before {
  content: "\F074";
}
.mdi-basecamp:before {
  content: "\F075";
}
.mdi-basket:before {
  content: "\F076";
}
.mdi-basket-fill:before {
  content: "\F077";
}
.mdi-basket-unfill:before {
  content: "\F078";
}
.mdi-battery:before {
  content: "\F079";
}
.mdi-battery-10:before {
  content: "\F07A";
}
.mdi-battery-20:before {
  content: "\F07B";
}
.mdi-battery-30:before {
  content: "\F07C";
}
.mdi-battery-40:before {
  content: "\F07D";
}
.mdi-battery-50:before {
  content: "\F07E";
}
.mdi-battery-60:before {
  content: "\F07F";
}
.mdi-battery-70:before {
  content: "\F080";
}
.mdi-battery-80:before {
  content: "\F081";
}
.mdi-battery-90:before {
  content: "\F082";
}
.mdi-battery-alert:before {
  content: "\F083";
}
.mdi-battery-charging:before {
  content: "\F084";
}
.mdi-battery-charging-100:before {
  content: "\F085";
}
.mdi-battery-charging-20:before {
  content: "\F086";
}
.mdi-battery-charging-30:before {
  content: "\F087";
}
.mdi-battery-charging-40:before {
  content: "\F088";
}
.mdi-battery-charging-60:before {
  content: "\F089";
}
.mdi-battery-charging-80:before {
  content: "\F08A";
}
.mdi-battery-charging-90:before {
  content: "\F08B";
}
.mdi-battery-minus:before {
  content: "\F08C";
}
.mdi-battery-negative:before {
  content: "\F08D";
}
.mdi-battery-outline:before {
  content: "\F08E";
}
.mdi-battery-plus:before {
  content: "\F08F";
}
.mdi-battery-positive:before {
  content: "\F090";
}
.mdi-battery-unknown:before {
  content: "\F091";
}
.mdi-beach:before {
  content: "\F092";
}
.mdi-beaker:before {
  content: "\F68F";
}
.mdi-beats:before {
  content: "\F097";
}
.mdi-beer:before {
  content: "\F098";
}
.mdi-behance:before {
  content: "\F099";
}
.mdi-bell:before {
  content: "\F09A";
}
.mdi-bell-off:before {
  content: "\F09B";
}
.mdi-bell-outline:before {
  content: "\F09C";
}
.mdi-bell-plus:before {
  content: "\F09D";
}
.mdi-bell-ring:before {
  content: "\F09E";
}
.mdi-bell-ring-outline:before {
  content: "\F09F";
}
.mdi-bell-sleep:before {
  content: "\F0A0";
}
.mdi-beta:before {
  content: "\F0A1";
}
.mdi-bible:before {
  content: "\F0A2";
}
.mdi-bike:before {
  content: "\F0A3";
}
.mdi-bing:before {
  content: "\F0A4";
}
.mdi-binoculars:before {
  content: "\F0A5";
}
.mdi-bio:before {
  content: "\F0A6";
}
.mdi-biohazard:before {
  content: "\F0A7";
}
.mdi-bitbucket:before {
  content: "\F0A8";
}
.mdi-black-mesa:before {
  content: "\F0A9";
}
.mdi-blackberry:before {
  content: "\F0AA";
}
.mdi-blender:before {
  content: "\F0AB";
}
.mdi-blinds:before {
  content: "\F0AC";
}
.mdi-block-helper:before {
  content: "\F0AD";
}
.mdi-blogger:before {
  content: "\F0AE";
}
.mdi-bluetooth:before {
  content: "\F0AF";
}
.mdi-bluetooth-audio:before {
  content: "\F0B0";
}
.mdi-bluetooth-connect:before {
  content: "\F0B1";
}
.mdi-bluetooth-off:before {
  content: "\F0B2";
}
.mdi-bluetooth-settings:before {
  content: "\F0B3";
}
.mdi-bluetooth-transfer:before {
  content: "\F0B4";
}
.mdi-blur:before {
  content: "\F0B5";
}
.mdi-blur-linear:before {
  content: "\F0B6";
}
.mdi-blur-off:before {
  content: "\F0B7";
}
.mdi-blur-radial:before {
  content: "\F0B8";
}
.mdi-bomb:before {
  content: "\F690";
}
.mdi-bomb-off:before {
  content: "\F6C4";
}
.mdi-bone:before {
  content: "\F0B9";
}
.mdi-book:before {
  content: "\F0BA";
}
.mdi-book-minus:before {
  content: "\F5D9";
}
.mdi-book-multiple:before {
  content: "\F0BB";
}
.mdi-book-multiple-variant:before {
  content: "\F0BC";
}
.mdi-book-open:before {
  content: "\F0BD";
}
.mdi-book-open-page-variant:before {
  content: "\F5DA";
}
.mdi-book-open-variant:before {
  content: "\F0BE";
}
.mdi-book-plus:before {
  content: "\F5DB";
}
.mdi-book-variant:before {
  content: "\F0BF";
}
.mdi-bookmark:before {
  content: "\F0C0";
}
.mdi-bookmark-check:before {
  content: "\F0C1";
}
.mdi-bookmark-music:before {
  content: "\F0C2";
}
.mdi-bookmark-outline:before {
  content: "\F0C3";
}
.mdi-bookmark-plus:before {
  content: "\F0C5";
}
.mdi-bookmark-plus-outline:before {
  content: "\F0C4";
}
.mdi-bookmark-remove:before {
  content: "\F0C6";
}
.mdi-boombox:before {
  content: "\F5DC";
}
.mdi-bootstrap:before {
  content: "\F6C5";
}
.mdi-border-all:before {
  content: "\F0C7";
}
.mdi-border-bottom:before {
  content: "\F0C8";
}
.mdi-border-color:before {
  content: "\F0C9";
}
.mdi-border-horizontal:before {
  content: "\F0CA";
}
.mdi-border-inside:before {
  content: "\F0CB";
}
.mdi-border-left:before {
  content: "\F0CC";
}
.mdi-border-none:before {
  content: "\F0CD";
}
.mdi-border-outside:before {
  content: "\F0CE";
}
.mdi-border-right:before {
  content: "\F0CF";
}
.mdi-border-style:before {
  content: "\F0D0";
}
.mdi-border-top:before {
  content: "\F0D1";
}
.mdi-border-vertical:before {
  content: "\F0D2";
}
.mdi-bow-tie:before {
  content: "\F677";
}
.mdi-bowl:before {
  content: "\F617";
}
.mdi-bowling:before {
  content: "\F0D3";
}
.mdi-box:before {
  content: "\F0D4";
}
.mdi-box-cutter:before {
  content: "\F0D5";
}
.mdi-box-shadow:before {
  content: "\F637";
}
.mdi-bridge:before {
  content: "\F618";
}
.mdi-briefcase:before {
  content: "\F0D6";
}
.mdi-briefcase-check:before {
  content: "\F0D7";
}
.mdi-briefcase-download:before {
  content: "\F0D8";
}
.mdi-briefcase-upload:before {
  content: "\F0D9";
}
.mdi-brightness-1:before {
  content: "\F0DA";
}
.mdi-brightness-2:before {
  content: "\F0DB";
}
.mdi-brightness-3:before {
  content: "\F0DC";
}
.mdi-brightness-4:before {
  content: "\F0DD";
}
.mdi-brightness-5:before {
  content: "\F0DE";
}
.mdi-brightness-6:before {
  content: "\F0DF";
}
.mdi-brightness-7:before {
  content: "\F0E0";
}
.mdi-brightness-auto:before {
  content: "\F0E1";
}
.mdi-broom:before {
  content: "\F0E2";
}
.mdi-brush:before {
  content: "\F0E3";
}
.mdi-buffer:before {
  content: "\F619";
}
.mdi-bug:before {
  content: "\F0E4";
}
.mdi-bulletin-board:before {
  content: "\F0E5";
}
.mdi-bullhorn:before {
  content: "\F0E6";
}
.mdi-bullseye:before {
  content: "\F5DD";
}
.mdi-burst-mode:before {
  content: "\F5DE";
}
.mdi-bus:before {
  content: "\F0E7";
}
.mdi-cached:before {
  content: "\F0E8";
}
.mdi-cake:before {
  content: "\F0E9";
}
.mdi-cake-layered:before {
  content: "\F0EA";
}
.mdi-cake-variant:before {
  content: "\F0EB";
}
.mdi-calculator:before {
  content: "\F0EC";
}
.mdi-calendar:before {
  content: "\F0ED";
}
.mdi-calendar-blank:before {
  content: "\F0EE";
}
.mdi-calendar-check:before {
  content: "\F0EF";
}
.mdi-calendar-clock:before {
  content: "\F0F0";
}
.mdi-calendar-multiple:before {
  content: "\F0F1";
}
.mdi-calendar-multiple-check:before {
  content: "\F0F2";
}
.mdi-calendar-plus:before {
  content: "\F0F3";
}
.mdi-calendar-question:before {
  content: "\F691";
}
.mdi-calendar-range:before {
  content: "\F678";
}
.mdi-calendar-remove:before {
  content: "\F0F4";
}
.mdi-calendar-text:before {
  content: "\F0F5";
}
.mdi-calendar-today:before {
  content: "\F0F6";
}
.mdi-call-made:before {
  content: "\F0F7";
}
.mdi-call-merge:before {
  content: "\F0F8";
}
.mdi-call-missed:before {
  content: "\F0F9";
}
.mdi-call-received:before {
  content: "\F0FA";
}
.mdi-call-split:before {
  content: "\F0FB";
}
.mdi-camcorder:before {
  content: "\F0FC";
}
.mdi-camcorder-box:before {
  content: "\F0FD";
}
.mdi-camcorder-box-off:before {
  content: "\F0FE";
}
.mdi-camcorder-off:before {
  content: "\F0FF";
}
.mdi-camera:before {
  content: "\F100";
}
.mdi-camera-burst:before {
  content: "\F692";
}
.mdi-camera-enhance:before {
  content: "\F101";
}
.mdi-camera-front:before {
  content: "\F102";
}
.mdi-camera-front-variant:before {
  content: "\F103";
}
.mdi-camera-iris:before {
  content: "\F104";
}
.mdi-camera-off:before {
  content: "\F5DF";
}
.mdi-camera-party-mode:before {
  content: "\F105";
}
.mdi-camera-rear:before {
  content: "\F106";
}
.mdi-camera-rear-variant:before {
  content: "\F107";
}
.mdi-camera-switch:before {
  content: "\F108";
}
.mdi-camera-timer:before {
  content: "\F109";
}
.mdi-cancel:before {
  content: "\F739";
}
.mdi-candle:before {
  content: "\F5E2";
}
.mdi-candycane:before {
  content: "\F10A";
}
.mdi-car:before {
  content: "\F10B";
}
.mdi-car-battery:before {
  content: "\F10C";
}
.mdi-car-connected:before {
  content: "\F10D";
}
.mdi-car-wash:before {
  content: "\F10E";
}
.mdi-cards:before {
  content: "\F638";
}
.mdi-cards-outline:before {
  content: "\F639";
}
.mdi-cards-playing-outline:before {
  content: "\F63A";
}
.mdi-cards-variant:before {
  content: "\F6C6";
}
.mdi-carrot:before {
  content: "\F10F";
}
.mdi-cart:before {
  content: "\F110";
}
.mdi-cart-off:before {
  content: "\F66B";
}
.mdi-cart-outline:before {
  content: "\F111";
}
.mdi-cart-plus:before {
  content: "\F112";
}
.mdi-case-sensitive-alt:before {
  content: "\F113";
}
.mdi-cash:before {
  content: "\F114";
}
.mdi-cash-100:before {
  content: "\F115";
}
.mdi-cash-multiple:before {
  content: "\F116";
}
.mdi-cash-usd:before {
  content: "\F117";
}
.mdi-cast:before {
  content: "\F118";
}
.mdi-cast-connected:before {
  content: "\F119";
}
.mdi-cast-off:before {
  content: "\F789";
}
.mdi-castle:before {
  content: "\F11A";
}
.mdi-cat:before {
  content: "\F11B";
}
.mdi-ceiling-light:before {
  content: "\F768";
}
.mdi-cellphone:before {
  content: "\F11C";
}
.mdi-cellphone-android:before {
  content: "\F11D";
}
.mdi-cellphone-basic:before {
  content: "\F11E";
}
.mdi-cellphone-dock:before {
  content: "\F11F";
}
.mdi-cellphone-iphone:before {
  content: "\F120";
}
.mdi-cellphone-link:before {
  content: "\F121";
}
.mdi-cellphone-link-off:before {
  content: "\F122";
}
.mdi-cellphone-settings:before {
  content: "\F123";
}
.mdi-certificate:before {
  content: "\F124";
}
.mdi-chair-school:before {
  content: "\F125";
}
.mdi-chart-arc:before {
  content: "\F126";
}
.mdi-chart-areaspline:before {
  content: "\F127";
}
.mdi-chart-bar:before {
  content: "\F128";
}
.mdi-chart-bar-stacked:before {
  content: "\F769";
}
.mdi-chart-bubble:before {
  content: "\F5E3";
}
.mdi-chart-gantt:before {
  content: "\F66C";
}
.mdi-chart-histogram:before {
  content: "\F129";
}
.mdi-chart-line:before {
  content: "\F12A";
}
.mdi-chart-line-stacked:before {
  content: "\F76A";
}
.mdi-chart-pie:before {
  content: "\F12B";
}
.mdi-chart-scatterplot-hexbin:before {
  content: "\F66D";
}
.mdi-chart-timeline:before {
  content: "\F66E";
}
.mdi-check:before {
  content: "\F12C";
}
.mdi-check-all:before {
  content: "\F12D";
}
.mdi-check-circle:before {
  content: "\F5E0";
}
.mdi-check-circle-outline:before {
  content: "\F5E1";
}
.mdi-checkbox-blank:before {
  content: "\F12E";
}
.mdi-checkbox-blank-circle:before {
  content: "\F12F";
}
.mdi-checkbox-blank-circle-outline:before {
  content: "\F130";
}
.mdi-checkbox-blank-outline:before {
  content: "\F131";
}
.mdi-checkbox-marked:before {
  content: "\F132";
}
.mdi-checkbox-marked-circle:before {
  content: "\F133";
}
.mdi-checkbox-marked-circle-outline:before {
  content: "\F134";
}
.mdi-checkbox-marked-outline:before {
  content: "\F135";
}
.mdi-checkbox-multiple-blank:before {
  content: "\F136";
}
.mdi-checkbox-multiple-blank-circle:before {
  content: "\F63B";
}
.mdi-checkbox-multiple-blank-circle-outline:before {
  content: "\F63C";
}
.mdi-checkbox-multiple-blank-outline:before {
  content: "\F137";
}
.mdi-checkbox-multiple-marked:before {
  content: "\F138";
}
.mdi-checkbox-multiple-marked-circle:before {
  content: "\F63D";
}
.mdi-checkbox-multiple-marked-circle-outline:before {
  content: "\F63E";
}
.mdi-checkbox-multiple-marked-outline:before {
  content: "\F139";
}
.mdi-checkerboard:before {
  content: "\F13A";
}
.mdi-chemical-weapon:before {
  content: "\F13B";
}
.mdi-chevron-double-down:before {
  content: "\F13C";
}
.mdi-chevron-double-left:before {
  content: "\F13D";
}
.mdi-chevron-double-right:before {
  content: "\F13E";
}
.mdi-chevron-double-up:before {
  content: "\F13F";
}
.mdi-chevron-down:before {
  content: "\F140";
}
.mdi-chevron-left:before {
  content: "\F141";
}
.mdi-chevron-right:before {
  content: "\F142";
}
.mdi-chevron-up:before {
  content: "\F143";
}
.mdi-chip:before {
  content: "\F61A";
}
.mdi-church:before {
  content: "\F144";
}
.mdi-circle:before {
  content: "\F764";
}
.mdi-circle-outline:before {
  content: "\F765";
}
.mdi-cisco-webex:before {
  content: "\F145";
}
.mdi-city:before {
  content: "\F146";
}
.mdi-clipboard:before {
  content: "\F147";
}
.mdi-clipboard-account:before {
  content: "\F148";
}
.mdi-clipboard-alert:before {
  content: "\F149";
}
.mdi-clipboard-arrow-down:before {
  content: "\F14A";
}
.mdi-clipboard-arrow-left:before {
  content: "\F14B";
}
.mdi-clipboard-check:before {
  content: "\F14C";
}
.mdi-clipboard-flow:before {
  content: "\F6C7";
}
.mdi-clipboard-outline:before {
  content: "\F14D";
}
.mdi-clipboard-plus:before {
  content: "\F750";
}
.mdi-clipboard-text:before {
  content: "\F14E";
}
.mdi-clippy:before {
  content: "\F14F";
}
.mdi-clock:before {
  content: "\F150";
}
.mdi-clock-alert:before {
  content: "\F5CE";
}
.mdi-clock-end:before {
  content: "\F151";
}
.mdi-clock-fast:before {
  content: "\F152";
}
.mdi-clock-in:before {
  content: "\F153";
}
.mdi-clock-out:before {
  content: "\F154";
}
.mdi-clock-start:before {
  content: "\F155";
}
.mdi-close:before {
  content: "\F156";
}
.mdi-close-box:before {
  content: "\F157";
}
.mdi-close-box-outline:before {
  content: "\F158";
}
.mdi-close-circle:before {
  content: "\F159";
}
.mdi-close-circle-outline:before {
  content: "\F15A";
}
.mdi-close-network:before {
  content: "\F15B";
}
.mdi-close-octagon:before {
  content: "\F15C";
}
.mdi-close-octagon-outline:before {
  content: "\F15D";
}
.mdi-close-outline:before {
  content: "\F6C8";
}
.mdi-closed-caption:before {
  content: "\F15E";
}
.mdi-cloud:before {
  content: "\F15F";
}
.mdi-cloud-check:before {
  content: "\F160";
}
.mdi-cloud-circle:before {
  content: "\F161";
}
.mdi-cloud-download:before {
  content: "\F162";
}
.mdi-cloud-off-outline:before {
  content: "\F164";
}
.mdi-cloud-outline:before {
  content: "\F163";
}
.mdi-cloud-print:before {
  content: "\F165";
}
.mdi-cloud-print-outline:before {
  content: "\F166";
}
.mdi-cloud-sync:before {
  content: "\F63F";
}
.mdi-cloud-upload:before {
  content: "\F167";
}
.mdi-code-array:before {
  content: "\F168";
}
.mdi-code-braces:before {
  content: "\F169";
}
.mdi-code-brackets:before {
  content: "\F16A";
}
.mdi-code-equal:before {
  content: "\F16B";
}
.mdi-code-greater-than:before {
  content: "\F16C";
}
.mdi-code-greater-than-or-equal:before {
  content: "\F16D";
}
.mdi-code-less-than:before {
  content: "\F16E";
}
.mdi-code-less-than-or-equal:before {
  content: "\F16F";
}
.mdi-code-not-equal:before {
  content: "\F170";
}
.mdi-code-not-equal-variant:before {
  content: "\F171";
}
.mdi-code-parentheses:before {
  content: "\F172";
}
.mdi-code-string:before {
  content: "\F173";
}
.mdi-code-tags:before {
  content: "\F174";
}
.mdi-code-tags-check:before {
  content: "\F693";
}
.mdi-codepen:before {
  content: "\F175";
}
.mdi-coffee:before {
  content: "\F176";
}
.mdi-coffee-outline:before {
  content: "\F6C9";
}
.mdi-coffee-to-go:before {
  content: "\F177";
}
.mdi-coin:before {
  content: "\F178";
}
.mdi-coins:before {
  content: "\F694";
}
.mdi-collage:before {
  content: "\F640";
}
.mdi-color-helper:before {
  content: "\F179";
}
.mdi-comment:before {
  content: "\F17A";
}
.mdi-comment-account:before {
  content: "\F17B";
}
.mdi-comment-account-outline:before {
  content: "\F17C";
}
.mdi-comment-alert:before {
  content: "\F17D";
}
.mdi-comment-alert-outline:before {
  content: "\F17E";
}
.mdi-comment-check:before {
  content: "\F17F";
}
.mdi-comment-check-outline:before {
  content: "\F180";
}
.mdi-comment-multiple-outline:before {
  content: "\F181";
}
.mdi-comment-outline:before {
  content: "\F182";
}
.mdi-comment-plus-outline:before {
  content: "\F183";
}
.mdi-comment-processing:before {
  content: "\F184";
}
.mdi-comment-processing-outline:before {
  content: "\F185";
}
.mdi-comment-question-outline:before {
  content: "\F186";
}
.mdi-comment-remove-outline:before {
  content: "\F187";
}
.mdi-comment-text:before {
  content: "\F188";
}
.mdi-comment-text-outline:before {
  content: "\F189";
}
.mdi-compare:before {
  content: "\F18A";
}
.mdi-compass:before {
  content: "\F18B";
}
.mdi-compass-outline:before {
  content: "\F18C";
}
.mdi-console:before {
  content: "\F18D";
}
.mdi-contact-mail:before {
  content: "\F18E";
}
.mdi-contacts:before {
  content: "\F6CA";
}
.mdi-content-copy:before {
  content: "\F18F";
}
.mdi-content-cut:before {
  content: "\F190";
}
.mdi-content-duplicate:before {
  content: "\F191";
}
.mdi-content-paste:before {
  content: "\F192";
}
.mdi-content-save:before {
  content: "\F193";
}
.mdi-content-save-all:before {
  content: "\F194";
}
.mdi-content-save-settings:before {
  content: "\F61B";
}
.mdi-contrast:before {
  content: "\F195";
}
.mdi-contrast-box:before {
  content: "\F196";
}
.mdi-contrast-circle:before {
  content: "\F197";
}
.mdi-cookie:before {
  content: "\F198";
}
.mdi-copyright:before {
  content: "\F5E6";
}
.mdi-counter:before {
  content: "\F199";
}
.mdi-cow:before {
  content: "\F19A";
}
.mdi-creation:before {
  content: "\F1C9";
}
.mdi-credit-card:before {
  content: "\F19B";
}
.mdi-credit-card-multiple:before {
  content: "\F19C";
}
.mdi-credit-card-off:before {
  content: "\F5E4";
}
.mdi-credit-card-plus:before {
  content: "\F675";
}
.mdi-credit-card-scan:before {
  content: "\F19D";
}
.mdi-crop:before {
  content: "\F19E";
}
.mdi-crop-free:before {
  content: "\F19F";
}
.mdi-crop-landscape:before {
  content: "\F1A0";
}
.mdi-crop-portrait:before {
  content: "\F1A1";
}
.mdi-crop-rotate:before {
  content: "\F695";
}
.mdi-crop-square:before {
  content: "\F1A2";
}
.mdi-crosshairs:before {
  content: "\F1A3";
}
.mdi-crosshairs-gps:before {
  content: "\F1A4";
}
.mdi-crown:before {
  content: "\F1A5";
}
.mdi-cube:before {
  content: "\F1A6";
}
.mdi-cube-outline:before {
  content: "\F1A7";
}
.mdi-cube-send:before {
  content: "\F1A8";
}
.mdi-cube-unfolded:before {
  content: "\F1A9";
}
.mdi-cup:before {
  content: "\F1AA";
}
.mdi-cup-off:before {
  content: "\F5E5";
}
.mdi-cup-water:before {
  content: "\F1AB";
}
.mdi-currency-btc:before {
  content: "\F1AC";
}
.mdi-currency-eur:before {
  content: "\F1AD";
}
.mdi-currency-gbp:before {
  content: "\F1AE";
}
.mdi-currency-inr:before {
  content: "\F1AF";
}
.mdi-currency-ngn:before {
  content: "\F1B0";
}
.mdi-currency-rub:before {
  content: "\F1B1";
}
.mdi-currency-try:before {
  content: "\F1B2";
}
.mdi-currency-usd:before {
  content: "\F1B3";
}
.mdi-currency-usd-off:before {
  content: "\F679";
}
.mdi-cursor-default:before {
  content: "\F1B4";
}
.mdi-cursor-default-outline:before {
  content: "\F1B5";
}
.mdi-cursor-move:before {
  content: "\F1B6";
}
.mdi-cursor-pointer:before {
  content: "\F1B7";
}
.mdi-cursor-text:before {
  content: "\F5E7";
}
.mdi-database:before {
  content: "\F1B8";
}
.mdi-database-minus:before {
  content: "\F1B9";
}
.mdi-database-plus:before {
  content: "\F1BA";
}
.mdi-debug-step-into:before {
  content: "\F1BB";
}
.mdi-debug-step-out:before {
  content: "\F1BC";
}
.mdi-debug-step-over:before {
  content: "\F1BD";
}
.mdi-decagram:before {
  content: "\F76B";
}
.mdi-decagram-outline:before {
  content: "\F76C";
}
.mdi-decimal-decrease:before {
  content: "\F1BE";
}
.mdi-decimal-increase:before {
  content: "\F1BF";
}
.mdi-delete:before {
  content: "\F1C0";
}
.mdi-delete-circle:before {
  content: "\F682";
}
.mdi-delete-empty:before {
  content: "\F6CB";
}
.mdi-delete-forever:before {
  content: "\F5E8";
}
.mdi-delete-sweep:before {
  content: "\F5E9";
}
.mdi-delete-variant:before {
  content: "\F1C1";
}
.mdi-delta:before {
  content: "\F1C2";
}
.mdi-deskphone:before {
  content: "\F1C3";
}
.mdi-desktop-mac:before {
  content: "\F1C4";
}
.mdi-desktop-tower:before {
  content: "\F1C5";
}
.mdi-details:before {
  content: "\F1C6";
}
.mdi-developer-board:before {
  content: "\F696";
}
.mdi-deviantart:before {
  content: "\F1C7";
}
.mdi-dialpad:before {
  content: "\F61C";
}
.mdi-diamond:before {
  content: "\F1C8";
}
.mdi-dice-1:before {
  content: "\F1CA";
}
.mdi-dice-2:before {
  content: "\F1CB";
}
.mdi-dice-3:before {
  content: "\F1CC";
}
.mdi-dice-4:before {
  content: "\F1CD";
}
.mdi-dice-5:before {
  content: "\F1CE";
}
.mdi-dice-6:before {
  content: "\F1CF";
}
.mdi-dice-d10:before {
  content: "\F76E";
}
.mdi-dice-d20:before {
  content: "\F5EA";
}
.mdi-dice-d4:before {
  content: "\F5EB";
}
.mdi-dice-d6:before {
  content: "\F5EC";
}
.mdi-dice-d8:before {
  content: "\F5ED";
}
.mdi-dice-multiple:before {
  content: "\F76D";
}
.mdi-dictionary:before {
  content: "\F61D";
}
.mdi-directions:before {
  content: "\F1D0";
}
.mdi-directions-fork:before {
  content: "\F641";
}
.mdi-discord:before {
  content: "\F66F";
}
.mdi-disk:before {
  content: "\F5EE";
}
.mdi-disk-alert:before {
  content: "\F1D1";
}
.mdi-disqus:before {
  content: "\F1D2";
}
.mdi-disqus-outline:before {
  content: "\F1D3";
}
.mdi-division:before {
  content: "\F1D4";
}
.mdi-division-box:before {
  content: "\F1D5";
}
.mdi-dna:before {
  content: "\F683";
}
.mdi-dns:before {
  content: "\F1D6";
}
.mdi-do-not-disturb:before {
  content: "\F697";
}
.mdi-do-not-disturb-off:before {
  content: "\F698";
}
.mdi-dolby:before {
  content: "\F6B2";
}
.mdi-domain:before {
  content: "\F1D7";
}
.mdi-dots-horizontal:before {
  content: "\F1D8";
}
.mdi-dots-vertical:before {
  content: "\F1D9";
}
.mdi-douban:before {
  content: "\F699";
}
.mdi-download:before {
  content: "\F1DA";
}
.mdi-download-network:before {
  content: "\F6F3";
}
.mdi-drag:before {
  content: "\F1DB";
}
.mdi-drag-horizontal:before {
  content: "\F1DC";
}
.mdi-drag-vertical:before {
  content: "\F1DD";
}
.mdi-drawing:before {
  content: "\F1DE";
}
.mdi-drawing-box:before {
  content: "\F1DF";
}
.mdi-dribbble:before {
  content: "\F1E0";
}
.mdi-dribbble-box:before {
  content: "\F1E1";
}
.mdi-drone:before {
  content: "\F1E2";
}
.mdi-dropbox:before {
  content: "\F1E3";
}
.mdi-drupal:before {
  content: "\F1E4";
}
.mdi-duck:before {
  content: "\F1E5";
}
.mdi-dumbbell:before {
  content: "\F1E6";
}
.mdi-earth:before {
  content: "\F1E7";
}
.mdi-earth-box:before {
  content: "\F6CC";
}
.mdi-earth-box-off:before {
  content: "\F6CD";
}
.mdi-earth-off:before {
  content: "\F1E8";
}
.mdi-edge:before {
  content: "\F1E9";
}
.mdi-eject:before {
  content: "\F1EA";
}
.mdi-elevation-decline:before {
  content: "\F1EB";
}
.mdi-elevation-rise:before {
  content: "\F1EC";
}
.mdi-elevator:before {
  content: "\F1ED";
}
.mdi-email:before {
  content: "\F1EE";
}
.mdi-email-alert:before {
  content: "\F6CE";
}
.mdi-email-open:before {
  content: "\F1EF";
}
.mdi-email-open-outline:before {
  content: "\F5EF";
}
.mdi-email-outline:before {
  content: "\F1F0";
}
.mdi-email-secure:before {
  content: "\F1F1";
}
.mdi-email-variant:before {
  content: "\F5F0";
}
.mdi-emby:before {
  content: "\F6B3";
}
.mdi-emoticon:before {
  content: "\F1F2";
}
.mdi-emoticon-cool:before {
  content: "\F1F3";
}
.mdi-emoticon-dead:before {
  content: "\F69A";
}
.mdi-emoticon-devil:before {
  content: "\F1F4";
}
.mdi-emoticon-excited:before {
  content: "\F69B";
}
.mdi-emoticon-happy:before {
  content: "\F1F5";
}
.mdi-emoticon-neutral:before {
  content: "\F1F6";
}
.mdi-emoticon-poop:before {
  content: "\F1F7";
}
.mdi-emoticon-sad:before {
  content: "\F1F8";
}
.mdi-emoticon-tongue:before {
  content: "\F1F9";
}
.mdi-engine:before {
  content: "\F1FA";
}
.mdi-engine-outline:before {
  content: "\F1FB";
}
.mdi-equal:before {
  content: "\F1FC";
}
.mdi-equal-box:before {
  content: "\F1FD";
}
.mdi-eraser:before {
  content: "\F1FE";
}
.mdi-eraser-variant:before {
  content: "\F642";
}
.mdi-escalator:before {
  content: "\F1FF";
}
.mdi-ethernet:before {
  content: "\F200";
}
.mdi-ethernet-cable:before {
  content: "\F201";
}
.mdi-ethernet-cable-off:before {
  content: "\F202";
}
.mdi-etsy:before {
  content: "\F203";
}
.mdi-ev-station:before {
  content: "\F5F1";
}
.mdi-evernote:before {
  content: "\F204";
}
.mdi-exclamation:before {
  content: "\F205";
}
.mdi-exit-to-app:before {
  content: "\F206";
}
.mdi-export:before {
  content: "\F207";
}
.mdi-eye:before {
  content: "\F208";
}
.mdi-eye-off:before {
  content: "\F209";
}
.mdi-eye-off-outline:before {
  content: "\F6D0";
}
.mdi-eye-outline:before {
  content: "\F6CF";
}
.mdi-eyedropper:before {
  content: "\F20A";
}
.mdi-eyedropper-variant:before {
  content: "\F20B";
}
.mdi-face:before {
  content: "\F643";
}
.mdi-face-profile:before {
  content: "\F644";
}
.mdi-facebook:before {
  content: "\F20C";
}
.mdi-facebook-box:before {
  content: "\F20D";
}
.mdi-facebook-messenger:before {
  content: "\F20E";
}
.mdi-factory:before {
  content: "\F20F";
}
.mdi-fan:before {
  content: "\F210";
}
.mdi-fast-forward:before {
  content: "\F211";
}
.mdi-fast-forward-outline:before {
  content: "\F6D1";
}
.mdi-fax:before {
  content: "\F212";
}
.mdi-feather:before {
  content: "\F6D2";
}
.mdi-ferry:before {
  content: "\F213";
}
.mdi-file:before {
  content: "\F214";
}
.mdi-file-account:before {
  content: "\F73A";
}
.mdi-file-chart:before {
  content: "\F215";
}
.mdi-file-check:before {
  content: "\F216";
}
.mdi-file-cloud:before {
  content: "\F217";
}
.mdi-file-delimited:before {
  content: "\F218";
}
.mdi-file-document:before {
  content: "\F219";
}
.mdi-file-document-box:before {
  content: "\F21A";
}
.mdi-file-excel:before {
  content: "\F21B";
}
.mdi-file-excel-box:before {
  content: "\F21C";
}
.mdi-file-export:before {
  content: "\F21D";
}
.mdi-file-find:before {
  content: "\F21E";
}
.mdi-file-hidden:before {
  content: "\F613";
}
.mdi-file-image:before {
  content: "\F21F";
}
.mdi-file-import:before {
  content: "\F220";
}
.mdi-file-lock:before {
  content: "\F221";
}
.mdi-file-multiple:before {
  content: "\F222";
}
.mdi-file-music:before {
  content: "\F223";
}
.mdi-file-outline:before {
  content: "\F224";
}
.mdi-file-pdf:before {
  content: "\F225";
}
.mdi-file-pdf-box:before {
  content: "\F226";
}
.mdi-file-plus:before {
  content: "\F751";
}
.mdi-file-powerpoint:before {
  content: "\F227";
}
.mdi-file-powerpoint-box:before {
  content: "\F228";
}
.mdi-file-presentation-box:before {
  content: "\F229";
}
.mdi-file-restore:before {
  content: "\F670";
}
.mdi-file-send:before {
  content: "\F22A";
}
.mdi-file-tree:before {
  content: "\F645";
}
.mdi-file-video:before {
  content: "\F22B";
}
.mdi-file-word:before {
  content: "\F22C";
}
.mdi-file-word-box:before {
  content: "\F22D";
}
.mdi-file-xml:before {
  content: "\F22E";
}
.mdi-film:before {
  content: "\F22F";
}
.mdi-filmstrip:before {
  content: "\F230";
}
.mdi-filmstrip-off:before {
  content: "\F231";
}
.mdi-filter:before {
  content: "\F232";
}
.mdi-filter-outline:before {
  content: "\F233";
}
.mdi-filter-remove:before {
  content: "\F234";
}
.mdi-filter-remove-outline:before {
  content: "\F235";
}
.mdi-filter-variant:before {
  content: "\F236";
}
.mdi-find-replace:before {
  content: "\F6D3";
}
.mdi-fingerprint:before {
  content: "\F237";
}
.mdi-fire:before {
  content: "\F238";
}
.mdi-firefox:before {
  content: "\F239";
}
.mdi-fish:before {
  content: "\F23A";
}
.mdi-flag:before {
  content: "\F23B";
}
.mdi-flag-checkered:before {
  content: "\F23C";
}
.mdi-flag-outline:before {
  content: "\F23D";
}
.mdi-flag-outline-variant:before {
  content: "\F23E";
}
.mdi-flag-triangle:before {
  content: "\F23F";
}
.mdi-flag-variant:before {
  content: "\F240";
}
.mdi-flash:before {
  content: "\F241";
}
.mdi-flash-auto:before {
  content: "\F242";
}
.mdi-flash-off:before {
  content: "\F243";
}
.mdi-flash-outline:before {
  content: "\F6D4";
}
.mdi-flash-red-eye:before {
  content: "\F67A";
}
.mdi-flashlight:before {
  content: "\F244";
}
.mdi-flashlight-off:before {
  content: "\F245";
}
.mdi-flask:before {
  content: "\F093";
}
.mdi-flask-empty:before {
  content: "\F094";
}
.mdi-flask-empty-outline:before {
  content: "\F095";
}
.mdi-flask-outline:before {
  content: "\F096";
}
.mdi-flattr:before {
  content: "\F246";
}
.mdi-flip-to-back:before {
  content: "\F247";
}
.mdi-flip-to-front:before {
  content: "\F248";
}
.mdi-floppy:before {
  content: "\F249";
}
.mdi-flower:before {
  content: "\F24A";
}
.mdi-folder:before {
  content: "\F24B";
}
.mdi-folder-account:before {
  content: "\F24C";
}
.mdi-folder-download:before {
  content: "\F24D";
}
.mdi-folder-google-drive:before {
  content: "\F24E";
}
.mdi-folder-image:before {
  content: "\F24F";
}
.mdi-folder-lock:before {
  content: "\F250";
}
.mdi-folder-lock-open:before {
  content: "\F251";
}
.mdi-folder-move:before {
  content: "\F252";
}
.mdi-folder-multiple:before {
  content: "\F253";
}
.mdi-folder-multiple-image:before {
  content: "\F254";
}
.mdi-folder-multiple-outline:before {
  content: "\F255";
}
.mdi-folder-open:before {
  content: "\F76F";
}
.mdi-folder-outline:before {
  content: "\F256";
}
.mdi-folder-plus:before {
  content: "\F257";
}
.mdi-folder-remove:before {
  content: "\F258";
}
.mdi-folder-star:before {
  content: "\F69C";
}
.mdi-folder-upload:before {
  content: "\F259";
}
.mdi-font-awesome:before {
  content: "\F03A";
}
.mdi-food:before {
  content: "\F25A";
}
.mdi-food-apple:before {
  content: "\F25B";
}
.mdi-food-fork-drink:before {
  content: "\F5F2";
}
.mdi-food-off:before {
  content: "\F5F3";
}
.mdi-food-variant:before {
  content: "\F25C";
}
.mdi-football:before {
  content: "\F25D";
}
.mdi-football-australian:before {
  content: "\F25E";
}
.mdi-football-helmet:before {
  content: "\F25F";
}
.mdi-format-align-bottom:before {
  content: "\F752";
}
.mdi-format-align-center:before {
  content: "\F260";
}
.mdi-format-align-justify:before {
  content: "\F261";
}
.mdi-format-align-left:before {
  content: "\F262";
}
.mdi-format-align-middle:before {
  content: "\F753";
}
.mdi-format-align-right:before {
  content: "\F263";
}
.mdi-format-align-top:before {
  content: "\F754";
}
.mdi-format-annotation-plus:before {
  content: "\F646";
}
.mdi-format-bold:before {
  content: "\F264";
}
.mdi-format-clear:before {
  content: "\F265";
}
.mdi-format-color-fill:before {
  content: "\F266";
}
.mdi-format-color-text:before {
  content: "\F69D";
}
.mdi-format-float-center:before {
  content: "\F267";
}
.mdi-format-float-left:before {
  content: "\F268";
}
.mdi-format-float-none:before {
  content: "\F269";
}
.mdi-format-float-right:before {
  content: "\F26A";
}
.mdi-format-font:before {
  content: "\F6D5";
}
.mdi-format-header-1:before {
  content: "\F26B";
}
.mdi-format-header-2:before {
  content: "\F26C";
}
.mdi-format-header-3:before {
  content: "\F26D";
}
.mdi-format-header-4:before {
  content: "\F26E";
}
.mdi-format-header-5:before {
  content: "\F26F";
}
.mdi-format-header-6:before {
  content: "\F270";
}
.mdi-format-header-decrease:before {
  content: "\F271";
}
.mdi-format-header-equal:before {
  content: "\F272";
}
.mdi-format-header-increase:before {
  content: "\F273";
}
.mdi-format-header-pound:before {
  content: "\F274";
}
.mdi-format-horizontal-align-center:before {
  content: "\F61E";
}
.mdi-format-horizontal-align-left:before {
  content: "\F61F";
}
.mdi-format-horizontal-align-right:before {
  content: "\F620";
}
.mdi-format-indent-decrease:before {
  content: "\F275";
}
.mdi-format-indent-increase:before {
  content: "\F276";
}
.mdi-format-italic:before {
  content: "\F277";
}
.mdi-format-line-spacing:before {
  content: "\F278";
}
.mdi-format-line-style:before {
  content: "\F5C8";
}
.mdi-format-line-weight:before {
  content: "\F5C9";
}
.mdi-format-list-bulleted:before {
  content: "\F279";
}
.mdi-format-list-bulleted-type:before {
  content: "\F27A";
}
.mdi-format-list-checks:before {
  content: "\F755";
}
.mdi-format-list-numbers:before {
  content: "\F27B";
}
.mdi-format-page-break:before {
  content: "\F6D6";
}
.mdi-format-paint:before {
  content: "\F27C";
}
.mdi-format-paragraph:before {
  content: "\F27D";
}
.mdi-format-pilcrow:before {
  content: "\F6D7";
}
.mdi-format-quote-close:before {
  content: "\F27E";
}
.mdi-format-quote-open:before {
  content: "\F756";
}
.mdi-format-rotate-90:before {
  content: "\F6A9";
}
.mdi-format-section:before {
  content: "\F69E";
}
.mdi-format-size:before {
  content: "\F27F";
}
.mdi-format-strikethrough:before {
  content: "\F280";
}
.mdi-format-strikethrough-variant:before {
  content: "\F281";
}
.mdi-format-subscript:before {
  content: "\F282";
}
.mdi-format-superscript:before {
  content: "\F283";
}
.mdi-format-text:before {
  content: "\F284";
}
.mdi-format-textdirection-l-to-r:before {
  content: "\F285";
}
.mdi-format-textdirection-r-to-l:before {
  content: "\F286";
}
.mdi-format-title:before {
  content: "\F5F4";
}
.mdi-format-underline:before {
  content: "\F287";
}
.mdi-format-vertical-align-bottom:before {
  content: "\F621";
}
.mdi-format-vertical-align-center:before {
  content: "\F622";
}
.mdi-format-vertical-align-top:before {
  content: "\F623";
}
.mdi-format-wrap-inline:before {
  content: "\F288";
}
.mdi-format-wrap-square:before {
  content: "\F289";
}
.mdi-format-wrap-tight:before {
  content: "\F28A";
}
.mdi-format-wrap-top-bottom:before {
  content: "\F28B";
}
.mdi-forum:before {
  content: "\F28C";
}
.mdi-forward:before {
  content: "\F28D";
}
.mdi-foursquare:before {
  content: "\F28E";
}
.mdi-fridge:before {
  content: "\F28F";
}
.mdi-fridge-filled:before {
  content: "\F290";
}
.mdi-fridge-filled-bottom:before {
  content: "\F291";
}
.mdi-fridge-filled-top:before {
  content: "\F292";
}
.mdi-fullscreen:before {
  content: "\F293";
}
.mdi-fullscreen-exit:before {
  content: "\F294";
}
.mdi-function:before {
  content: "\F295";
}
.mdi-gamepad:before {
  content: "\F296";
}
.mdi-gamepad-variant:before {
  content: "\F297";
}
.mdi-garage:before {
  content: "\F6D8";
}
.mdi-garage-open:before {
  content: "\F6D9";
}
.mdi-gas-cylinder:before {
  content: "\F647";
}
.mdi-gas-station:before {
  content: "\F298";
}
.mdi-gate:before {
  content: "\F299";
}
.mdi-gauge:before {
  content: "\F29A";
}
.mdi-gavel:before {
  content: "\F29B";
}
.mdi-gender-female:before {
  content: "\F29C";
}
.mdi-gender-male:before {
  content: "\F29D";
}
.mdi-gender-male-female:before {
  content: "\F29E";
}
.mdi-gender-transgender:before {
  content: "\F29F";
}
.mdi-gesture-double-tap:before {
  content: "\F73B";
}
.mdi-gesture-swipe-down:before {
  content: "\F73C";
}
.mdi-gesture-swipe-left:before {
  content: "\F73D";
}
.mdi-gesture-swipe-right:before {
  content: "\F73E";
}
.mdi-gesture-swipe-up:before {
  content: "\F73F";
}
.mdi-gesture-tap:before {
  content: "\F740";
}
.mdi-gesture-two-double-tap:before {
  content: "\F741";
}
.mdi-gesture-two-tap:before {
  content: "\F742";
}
.mdi-ghost:before {
  content: "\F2A0";
}
.mdi-gift:before {
  content: "\F2A1";
}
.mdi-git:before {
  content: "\F2A2";
}
.mdi-github-box:before {
  content: "\F2A3";
}
.mdi-github-circle:before {
  content: "\F2A4";
}
.mdi-github-face:before {
  content: "\F6DA";
}
.mdi-glass-flute:before {
  content: "\F2A5";
}
.mdi-glass-mug:before {
  content: "\F2A6";
}
.mdi-glass-stange:before {
  content: "\F2A7";
}
.mdi-glass-tulip:before {
  content: "\F2A8";
}
.mdi-glassdoor:before {
  content: "\F2A9";
}
.mdi-glasses:before {
  content: "\F2AA";
}
.mdi-gmail:before {
  content: "\F2AB";
}
.mdi-gnome:before {
  content: "\F2AC";
}
.mdi-gondola:before {
  content: "\F685";
}
.mdi-google:before {
  content: "\F2AD";
}
.mdi-google-cardboard:before {
  content: "\F2AE";
}
.mdi-google-chrome:before {
  content: "\F2AF";
}
.mdi-google-circles:before {
  content: "\F2B0";
}
.mdi-google-circles-communities:before {
  content: "\F2B1";
}
.mdi-google-circles-extended:before {
  content: "\F2B2";
}
.mdi-google-circles-group:before {
  content: "\F2B3";
}
.mdi-google-controller:before {
  content: "\F2B4";
}
.mdi-google-controller-off:before {
  content: "\F2B5";
}
.mdi-google-drive:before {
  content: "\F2B6";
}
.mdi-google-earth:before {
  content: "\F2B7";
}
.mdi-google-glass:before {
  content: "\F2B8";
}
.mdi-google-keep:before {
  content: "\F6DB";
}
.mdi-google-maps:before {
  content: "\F5F5";
}
.mdi-google-nearby:before {
  content: "\F2B9";
}
.mdi-google-pages:before {
  content: "\F2BA";
}
.mdi-google-photos:before {
  content: "\F6DC";
}
.mdi-google-physical-web:before {
  content: "\F2BB";
}
.mdi-google-play:before {
  content: "\F2BC";
}
.mdi-google-plus:before {
  content: "\F2BD";
}
.mdi-google-plus-box:before {
  content: "\F2BE";
}
.mdi-google-translate:before {
  content: "\F2BF";
}
.mdi-google-wallet:before {
  content: "\F2C0";
}
.mdi-gradient:before {
  content: "\F69F";
}
.mdi-grease-pencil:before {
  content: "\F648";
}
.mdi-grid:before {
  content: "\F2C1";
}
.mdi-grid-large:before {
  content: "\F757";
}
.mdi-grid-off:before {
  content: "\F2C2";
}
.mdi-group:before {
  content: "\F2C3";
}
.mdi-guitar-acoustic:before {
  content: "\F770";
}
.mdi-guitar-electric:before {
  content: "\F2C4";
}
.mdi-guitar-pick:before {
  content: "\F2C5";
}
.mdi-guitar-pick-outline:before {
  content: "\F2C6";
}
.mdi-hackernews:before {
  content: "\F624";
}
.mdi-hamburger:before {
  content: "\F684";
}
.mdi-hand-pointing-right:before {
  content: "\F2C7";
}
.mdi-hanger:before {
  content: "\F2C8";
}
.mdi-hangouts:before {
  content: "\F2C9";
}
.mdi-harddisk:before {
  content: "\F2CA";
}
.mdi-headphones:before {
  content: "\F2CB";
}
.mdi-headphones-box:before {
  content: "\F2CC";
}
.mdi-headphones-settings:before {
  content: "\F2CD";
}
.mdi-headset:before {
  content: "\F2CE";
}
.mdi-headset-dock:before {
  content: "\F2CF";
}
.mdi-headset-off:before {
  content: "\F2D0";
}
.mdi-heart:before {
  content: "\F2D1";
}
.mdi-heart-box:before {
  content: "\F2D2";
}
.mdi-heart-box-outline:before {
  content: "\F2D3";
}
.mdi-heart-broken:before {
  content: "\F2D4";
}
.mdi-heart-half:before {
  content: "\F6DE";
}
.mdi-heart-half-full:before {
  content: "\F6DD";
}
.mdi-heart-half-outline:before {
  content: "\F6DF";
}
.mdi-heart-off:before {
  content: "\F758";
}
.mdi-heart-outline:before {
  content: "\F2D5";
}
.mdi-heart-pulse:before {
  content: "\F5F6";
}
.mdi-help:before {
  content: "\F2D6";
}
.mdi-help-box:before {
  content: "\F78A";
}
.mdi-help-circle:before {
  content: "\F2D7";
}
.mdi-help-circle-outline:before {
  content: "\F625";
}
.mdi-help-network:before {
  content: "\F6F4";
}
.mdi-hexagon:before {
  content: "\F2D8";
}
.mdi-hexagon-multiple:before {
  content: "\F6E0";
}
.mdi-hexagon-outline:before {
  content: "\F2D9";
}
.mdi-highway:before {
  content: "\F5F7";
}
.mdi-history:before {
  content: "\F2DA";
}
.mdi-hololens:before {
  content: "\F2DB";
}
.mdi-home:before {
  content: "\F2DC";
}
.mdi-home-map-marker:before {
  content: "\F5F8";
}
.mdi-home-modern:before {
  content: "\F2DD";
}
.mdi-home-outline:before {
  content: "\F6A0";
}
.mdi-home-variant:before {
  content: "\F2DE";
}
.mdi-hook:before {
  content: "\F6E1";
}
.mdi-hook-off:before {
  content: "\F6E2";
}
.mdi-hops:before {
  content: "\F2DF";
}
.mdi-hospital:before {
  content: "\F2E0";
}
.mdi-hospital-building:before {
  content: "\F2E1";
}
.mdi-hospital-marker:before {
  content: "\F2E2";
}
.mdi-hotel:before {
  content: "\F2E3";
}
.mdi-houzz:before {
  content: "\F2E4";
}
.mdi-houzz-box:before {
  content: "\F2E5";
}
.mdi-human:before {
  content: "\F2E6";
}
.mdi-human-child:before {
  content: "\F2E7";
}
.mdi-human-female:before {
  content: "\F649";
}
.mdi-human-greeting:before {
  content: "\F64A";
}
.mdi-human-handsdown:before {
  content: "\F64B";
}
.mdi-human-handsup:before {
  content: "\F64C";
}
.mdi-human-male:before {
  content: "\F64D";
}
.mdi-human-male-female:before {
  content: "\F2E8";
}
.mdi-human-pregnant:before {
  content: "\F5CF";
}
.mdi-humble-bundle:before {
  content: "\F743";
}
.mdi-image:before {
  content: "\F2E9";
}
.mdi-image-album:before {
  content: "\F2EA";
}
.mdi-image-area:before {
  content: "\F2EB";
}
.mdi-image-area-close:before {
  content: "\F2EC";
}
.mdi-image-broken:before {
  content: "\F2ED";
}
.mdi-image-broken-variant:before {
  content: "\F2EE";
}
.mdi-image-filter:before {
  content: "\F2EF";
}
.mdi-image-filter-black-white:before {
  content: "\F2F0";
}
.mdi-image-filter-center-focus:before {
  content: "\F2F1";
}
.mdi-image-filter-center-focus-weak:before {
  content: "\F2F2";
}
.mdi-image-filter-drama:before {
  content: "\F2F3";
}
.mdi-image-filter-frames:before {
  content: "\F2F4";
}
.mdi-image-filter-hdr:before {
  content: "\F2F5";
}
.mdi-image-filter-none:before {
  content: "\F2F6";
}
.mdi-image-filter-tilt-shift:before {
  content: "\F2F7";
}
.mdi-image-filter-vintage:before {
  content: "\F2F8";
}
.mdi-image-multiple:before {
  content: "\F2F9";
}
.mdi-import:before {
  content: "\F2FA";
}
.mdi-inbox:before {
  content: "\F686";
}
.mdi-inbox-arrow-down:before {
  content: "\F2FB";
}
.mdi-inbox-arrow-up:before {
  content: "\F3D1";
}
.mdi-incognito:before {
  content: "\F5F9";
}
.mdi-infinity:before {
  content: "\F6E3";
}
.mdi-information:before {
  content: "\F2FC";
}
.mdi-information-outline:before {
  content: "\F2FD";
}
.mdi-information-variant:before {
  content: "\F64E";
}
.mdi-instagram:before {
  content: "\F2FE";
}
.mdi-instapaper:before {
  content: "\F2FF";
}
.mdi-internet-explorer:before {
  content: "\F300";
}
.mdi-invert-colors:before {
  content: "\F301";
}
.mdi-itunes:before {
  content: "\F676";
}
.mdi-jeepney:before {
  content: "\F302";
}
.mdi-jira:before {
  content: "\F303";
}
.mdi-jsfiddle:before {
  content: "\F304";
}
.mdi-json:before {
  content: "\F626";
}
.mdi-keg:before {
  content: "\F305";
}
.mdi-kettle:before {
  content: "\F5FA";
}
.mdi-key:before {
  content: "\F306";
}
.mdi-key-change:before {
  content: "\F307";
}
.mdi-key-minus:before {
  content: "\F308";
}
.mdi-key-plus:before {
  content: "\F309";
}
.mdi-key-remove:before {
  content: "\F30A";
}
.mdi-key-variant:before {
  content: "\F30B";
}
.mdi-keyboard:before {
  content: "\F30C";
}
.mdi-keyboard-backspace:before {
  content: "\F30D";
}
.mdi-keyboard-caps:before {
  content: "\F30E";
}
.mdi-keyboard-close:before {
  content: "\F30F";
}
.mdi-keyboard-off:before {
  content: "\F310";
}
.mdi-keyboard-return:before {
  content: "\F311";
}
.mdi-keyboard-tab:before {
  content: "\F312";
}
.mdi-keyboard-variant:before {
  content: "\F313";
}
.mdi-kickstarter:before {
  content: "\F744";
}
.mdi-kodi:before {
  content: "\F314";
}
.mdi-label:before {
  content: "\F315";
}
.mdi-label-outline:before {
  content: "\F316";
}
.mdi-lambda:before {
  content: "\F627";
}
.mdi-lamp:before {
  content: "\F6B4";
}
.mdi-lan:before {
  content: "\F317";
}
.mdi-lan-connect:before {
  content: "\F318";
}
.mdi-lan-disconnect:before {
  content: "\F319";
}
.mdi-lan-pending:before {
  content: "\F31A";
}
.mdi-language-c:before {
  content: "\F671";
}
.mdi-language-cpp:before {
  content: "\F672";
}
.mdi-language-csharp:before {
  content: "\F31B";
}
.mdi-language-css3:before {
  content: "\F31C";
}
.mdi-language-html5:before {
  content: "\F31D";
}
.mdi-language-javascript:before {
  content: "\F31E";
}
.mdi-language-php:before {
  content: "\F31F";
}
.mdi-language-python:before {
  content: "\F320";
}
.mdi-language-python-text:before {
  content: "\F321";
}
.mdi-language-swift:before {
  content: "\F6E4";
}
.mdi-language-typescript:before {
  content: "\F6E5";
}
.mdi-laptop:before {
  content: "\F322";
}
.mdi-laptop-chromebook:before {
  content: "\F323";
}
.mdi-laptop-mac:before {
  content: "\F324";
}
.mdi-laptop-off:before {
  content: "\F6E6";
}
.mdi-laptop-windows:before {
  content: "\F325";
}
.mdi-lastfm:before {
  content: "\F326";
}
.mdi-launch:before {
  content: "\F327";
}
.mdi-layers:before {
  content: "\F328";
}
.mdi-layers-off:before {
  content: "\F329";
}
.mdi-lead-pencil:before {
  content: "\F64F";
}
.mdi-leaf:before {
  content: "\F32A";
}
.mdi-led-off:before {
  content: "\F32B";
}
.mdi-led-on:before {
  content: "\F32C";
}
.mdi-led-outline:before {
  content: "\F32D";
}
.mdi-led-variant-off:before {
  content: "\F32E";
}
.mdi-led-variant-on:before {
  content: "\F32F";
}
.mdi-led-variant-outline:before {
  content: "\F330";
}
.mdi-library:before {
  content: "\F331";
}
.mdi-library-books:before {
  content: "\F332";
}
.mdi-library-music:before {
  content: "\F333";
}
.mdi-library-plus:before {
  content: "\F334";
}
.mdi-lightbulb:before {
  content: "\F335";
}
.mdi-lightbulb-on:before {
  content: "\F6E7";
}
.mdi-lightbulb-on-outline:before {
  content: "\F6E8";
}
.mdi-lightbulb-outline:before {
  content: "\F336";
}
.mdi-link:before {
  content: "\F337";
}
.mdi-link-off:before {
  content: "\F338";
}
.mdi-link-variant:before {
  content: "\F339";
}
.mdi-link-variant-off:before {
  content: "\F33A";
}
.mdi-linkedin:before {
  content: "\F33B";
}
.mdi-linkedin-box:before {
  content: "\F33C";
}
.mdi-linux:before {
  content: "\F33D";
}
.mdi-loading:before {
  content: "\F771";
}
.mdi-lock:before {
  content: "\F33E";
}
.mdi-lock-open:before {
  content: "\F33F";
}
.mdi-lock-open-outline:before {
  content: "\F340";
}
.mdi-lock-outline:before {
  content: "\F341";
}
.mdi-lock-pattern:before {
  content: "\F6E9";
}
.mdi-lock-plus:before {
  content: "\F5FB";
}
.mdi-lock-reset:before {
  content: "\F772";
}
.mdi-login:before {
  content: "\F342";
}
.mdi-login-variant:before {
  content: "\F5FC";
}
.mdi-logout:before {
  content: "\F343";
}
.mdi-logout-variant:before {
  content: "\F5FD";
}
.mdi-looks:before {
  content: "\F344";
}
.mdi-loop:before {
  content: "\F6EA";
}
.mdi-loupe:before {
  content: "\F345";
}
.mdi-lumx:before {
  content: "\F346";
}
.mdi-magnet:before {
  content: "\F347";
}
.mdi-magnet-on:before {
  content: "\F348";
}
.mdi-magnify:before {
  content: "\F349";
}
.mdi-magnify-minus:before {
  content: "\F34A";
}
.mdi-magnify-minus-outline:before {
  content: "\F6EB";
}
.mdi-magnify-plus:before {
  content: "\F34B";
}
.mdi-magnify-plus-outline:before {
  content: "\F6EC";
}
.mdi-mail-ru:before {
  content: "\F34C";
}
.mdi-mailbox:before {
  content: "\F6ED";
}
.mdi-map:before {
  content: "\F34D";
}
.mdi-map-marker:before {
  content: "\F34E";
}
.mdi-map-marker-circle:before {
  content: "\F34F";
}
.mdi-map-marker-minus:before {
  content: "\F650";
}
.mdi-map-marker-multiple:before {
  content: "\F350";
}
.mdi-map-marker-off:before {
  content: "\F351";
}
.mdi-map-marker-plus:before {
  content: "\F651";
}
.mdi-map-marker-radius:before {
  content: "\F352";
}
.mdi-margin:before {
  content: "\F353";
}
.mdi-markdown:before {
  content: "\F354";
}
.mdi-marker:before {
  content: "\F652";
}
.mdi-marker-check:before {
  content: "\F355";
}
.mdi-martini:before {
  content: "\F356";
}
.mdi-material-ui:before {
  content: "\F357";
}
.mdi-math-compass:before {
  content: "\F358";
}
.mdi-matrix:before {
  content: "\F628";
}
.mdi-maxcdn:before {
  content: "\F359";
}
.mdi-medical-bag:before {
  content: "\F6EE";
}
.mdi-medium:before {
  content: "\F35A";
}
.mdi-memory:before {
  content: "\F35B";
}
.mdi-menu:before {
  content: "\F35C";
}
.mdi-menu-down:before {
  content: "\F35D";
}
.mdi-menu-down-outline:before {
  content: "\F6B5";
}
.mdi-menu-left:before {
  content: "\F35E";
}
.mdi-menu-right:before {
  content: "\F35F";
}
.mdi-menu-up:before {
  content: "\F360";
}
.mdi-menu-up-outline:before {
  content: "\F6B6";
}
.mdi-message:before {
  content: "\F361";
}
.mdi-message-alert:before {
  content: "\F362";
}
.mdi-message-bulleted:before {
  content: "\F6A1";
}
.mdi-message-bulleted-off:before {
  content: "\F6A2";
}
.mdi-message-draw:before {
  content: "\F363";
}
.mdi-message-image:before {
  content: "\F364";
}
.mdi-message-outline:before {
  content: "\F365";
}
.mdi-message-plus:before {
  content: "\F653";
}
.mdi-message-processing:before {
  content: "\F366";
}
.mdi-message-reply:before {
  content: "\F367";
}
.mdi-message-reply-text:before {
  content: "\F368";
}
.mdi-message-settings:before {
  content: "\F6EF";
}
.mdi-message-settings-variant:before {
  content: "\F6F0";
}
.mdi-message-text:before {
  content: "\F369";
}
.mdi-message-text-outline:before {
  content: "\F36A";
}
.mdi-message-video:before {
  content: "\F36B";
}
.mdi-meteor:before {
  content: "\F629";
}
.mdi-microphone:before {
  content: "\F36C";
}
.mdi-microphone-off:before {
  content: "\F36D";
}
.mdi-microphone-outline:before {
  content: "\F36E";
}
.mdi-microphone-settings:before {
  content: "\F36F";
}
.mdi-microphone-variant:before {
  content: "\F370";
}
.mdi-microphone-variant-off:before {
  content: "\F371";
}
.mdi-microscope:before {
  content: "\F654";
}
.mdi-microsoft:before {
  content: "\F372";
}
.mdi-minecraft:before {
  content: "\F373";
}
.mdi-minus:before {
  content: "\F374";
}
.mdi-minus-box:before {
  content: "\F375";
}
.mdi-minus-box-outline:before {
  content: "\F6F1";
}
.mdi-minus-circle:before {
  content: "\F376";
}
.mdi-minus-circle-outline:before {
  content: "\F377";
}
.mdi-minus-network:before {
  content: "\F378";
}
.mdi-mixcloud:before {
  content: "\F62A";
}
.mdi-monitor:before {
  content: "\F379";
}
.mdi-monitor-multiple:before {
  content: "\F37A";
}
.mdi-more:before {
  content: "\F37B";
}
.mdi-motorbike:before {
  content: "\F37C";
}
.mdi-mouse:before {
  content: "\F37D";
}
.mdi-mouse-off:before {
  content: "\F37E";
}
.mdi-mouse-variant:before {
  content: "\F37F";
}
.mdi-mouse-variant-off:before {
  content: "\F380";
}
.mdi-move-resize:before {
  content: "\F655";
}
.mdi-move-resize-variant:before {
  content: "\F656";
}
.mdi-movie:before {
  content: "\F381";
}
.mdi-multiplication:before {
  content: "\F382";
}
.mdi-multiplication-box:before {
  content: "\F383";
}
.mdi-music:before {
  content: "\F759";
}
.mdi-music-box:before {
  content: "\F384";
}
.mdi-music-box-outline:before {
  content: "\F385";
}
.mdi-music-circle:before {
  content: "\F386";
}
.mdi-music-note:before {
  content: "\F387";
}
.mdi-music-note-bluetooth:before {
  content: "\F5FE";
}
.mdi-music-note-bluetooth-off:before {
  content: "\F5FF";
}
.mdi-music-note-eighth:before {
  content: "\F388";
}
.mdi-music-note-half:before {
  content: "\F389";
}
.mdi-music-note-off:before {
  content: "\F38A";
}
.mdi-music-note-quarter:before {
  content: "\F38B";
}
.mdi-music-note-sixteenth:before {
  content: "\F38C";
}
.mdi-music-note-whole:before {
  content: "\F38D";
}
.mdi-music-off:before {
  content: "\F75A";
}
.mdi-nature:before {
  content: "\F38E";
}
.mdi-nature-people:before {
  content: "\F38F";
}
.mdi-navigation:before {
  content: "\F390";
}
.mdi-near-me:before {
  content: "\F5CD";
}
.mdi-needle:before {
  content: "\F391";
}
.mdi-nest-protect:before {
  content: "\F392";
}
.mdi-nest-thermostat:before {
  content: "\F393";
}
.mdi-netflix:before {
  content: "\F745";
}
.mdi-network:before {
  content: "\F6F2";
}
.mdi-new-box:before {
  content: "\F394";
}
.mdi-newspaper:before {
  content: "\F395";
}
.mdi-nfc:before {
  content: "\F396";
}
.mdi-nfc-tap:before {
  content: "\F397";
}
.mdi-nfc-variant:before {
  content: "\F398";
}
.mdi-ninja:before {
  content: "\F773";
}
.mdi-nodejs:before {
  content: "\F399";
}
.mdi-note:before {
  content: "\F39A";
}
.mdi-note-multiple:before {
  content: "\F6B7";
}
.mdi-note-multiple-outline:before {
  content: "\F6B8";
}
.mdi-note-outline:before {
  content: "\F39B";
}
.mdi-note-plus:before {
  content: "\F39C";
}
.mdi-note-plus-outline:before {
  content: "\F39D";
}
.mdi-note-text:before {
  content: "\F39E";
}
.mdi-notification-clear-all:before {
  content: "\F39F";
}
.mdi-npm:before {
  content: "\F6F6";
}
.mdi-nuke:before {
  content: "\F6A3";
}
.mdi-numeric:before {
  content: "\F3A0";
}
.mdi-numeric-0-box:before {
  content: "\F3A1";
}
.mdi-numeric-0-box-multiple-outline:before {
  content: "\F3A2";
}
.mdi-numeric-0-box-outline:before {
  content: "\F3A3";
}
.mdi-numeric-1-box:before {
  content: "\F3A4";
}
.mdi-numeric-1-box-multiple-outline:before {
  content: "\F3A5";
}
.mdi-numeric-1-box-outline:before {
  content: "\F3A6";
}
.mdi-numeric-2-box:before {
  content: "\F3A7";
}
.mdi-numeric-2-box-multiple-outline:before {
  content: "\F3A8";
}
.mdi-numeric-2-box-outline:before {
  content: "\F3A9";
}
.mdi-numeric-3-box:before {
  content: "\F3AA";
}
.mdi-numeric-3-box-multiple-outline:before {
  content: "\F3AB";
}
.mdi-numeric-3-box-outline:before {
  content: "\F3AC";
}
.mdi-numeric-4-box:before {
  content: "\F3AD";
}
.mdi-numeric-4-box-multiple-outline:before {
  content: "\F3AE";
}
.mdi-numeric-4-box-outline:before {
  content: "\F3AF";
}
.mdi-numeric-5-box:before {
  content: "\F3B0";
}
.mdi-numeric-5-box-multiple-outline:before {
  content: "\F3B1";
}
.mdi-numeric-5-box-outline:before {
  content: "\F3B2";
}
.mdi-numeric-6-box:before {
  content: "\F3B3";
}
.mdi-numeric-6-box-multiple-outline:before {
  content: "\F3B4";
}
.mdi-numeric-6-box-outline:before {
  content: "\F3B5";
}
.mdi-numeric-7-box:before {
  content: "\F3B6";
}
.mdi-numeric-7-box-multiple-outline:before {
  content: "\F3B7";
}
.mdi-numeric-7-box-outline:before {
  content: "\F3B8";
}
.mdi-numeric-8-box:before {
  content: "\F3B9";
}
.mdi-numeric-8-box-multiple-outline:before {
  content: "\F3BA";
}
.mdi-numeric-8-box-outline:before {
  content: "\F3BB";
}
.mdi-numeric-9-box:before {
  content: "\F3BC";
}
.mdi-numeric-9-box-multiple-outline:before {
  content: "\F3BD";
}
.mdi-numeric-9-box-outline:before {
  content: "\F3BE";
}
.mdi-numeric-9-plus-box:before {
  content: "\F3BF";
}
.mdi-numeric-9-plus-box-multiple-outline:before {
  content: "\F3C0";
}
.mdi-numeric-9-plus-box-outline:before {
  content: "\F3C1";
}
.mdi-nut:before {
  content: "\F6F7";
}
.mdi-nutrition:before {
  content: "\F3C2";
}
.mdi-oar:before {
  content: "\F67B";
}
.mdi-octagon:before {
  content: "\F3C3";
}
.mdi-octagon-outline:before {
  content: "\F3C4";
}
.mdi-octagram:before {
  content: "\F6F8";
}
.mdi-octagram-outline:before {
  content: "\F774";
}
.mdi-odnoklassniki:before {
  content: "\F3C5";
}
.mdi-office:before {
  content: "\F3C6";
}
.mdi-oil:before {
  content: "\F3C7";
}
.mdi-oil-temperature:before {
  content: "\F3C8";
}
.mdi-omega:before {
  content: "\F3C9";
}
.mdi-onedrive:before {
  content: "\F3CA";
}
.mdi-onenote:before {
  content: "\F746";
}
.mdi-opacity:before {
  content: "\F5CC";
}
.mdi-open-in-app:before {
  content: "\F3CB";
}
.mdi-open-in-new:before {
  content: "\F3CC";
}
.mdi-openid:before {
  content: "\F3CD";
}
.mdi-opera:before {
  content: "\F3CE";
}
.mdi-orbit:before {
  content: "\F018";
}
.mdi-ornament:before {
  content: "\F3CF";
}
.mdi-ornament-variant:before {
  content: "\F3D0";
}
.mdi-owl:before {
  content: "\F3D2";
}
.mdi-package:before {
  content: "\F3D3";
}
.mdi-package-down:before {
  content: "\F3D4";
}
.mdi-package-up:before {
  content: "\F3D5";
}
.mdi-package-variant:before {
  content: "\F3D6";
}
.mdi-package-variant-closed:before {
  content: "\F3D7";
}
.mdi-page-first:before {
  content: "\F600";
}
.mdi-page-last:before {
  content: "\F601";
}
.mdi-page-layout-body:before {
  content: "\F6F9";
}
.mdi-page-layout-footer:before {
  content: "\F6FA";
}
.mdi-page-layout-header:before {
  content: "\F6FB";
}
.mdi-page-layout-sidebar-left:before {
  content: "\F6FC";
}
.mdi-page-layout-sidebar-right:before {
  content: "\F6FD";
}
.mdi-palette:before {
  content: "\F3D8";
}
.mdi-palette-advanced:before {
  content: "\F3D9";
}
.mdi-panda:before {
  content: "\F3DA";
}
.mdi-pandora:before {
  content: "\F3DB";
}
.mdi-panorama:before {
  content: "\F3DC";
}
.mdi-panorama-fisheye:before {
  content: "\F3DD";
}
.mdi-panorama-horizontal:before {
  content: "\F3DE";
}
.mdi-panorama-vertical:before {
  content: "\F3DF";
}
.mdi-panorama-wide-angle:before {
  content: "\F3E0";
}
.mdi-paper-cut-vertical:before {
  content: "\F3E1";
}
.mdi-paperclip:before {
  content: "\F3E2";
}
.mdi-parking:before {
  content: "\F3E3";
}
.mdi-pause:before {
  content: "\F3E4";
}
.mdi-pause-circle:before {
  content: "\F3E5";
}
.mdi-pause-circle-outline:before {
  content: "\F3E6";
}
.mdi-pause-octagon:before {
  content: "\F3E7";
}
.mdi-pause-octagon-outline:before {
  content: "\F3E8";
}
.mdi-paw:before {
  content: "\F3E9";
}
.mdi-paw-off:before {
  content: "\F657";
}
.mdi-pen:before {
  content: "\F3EA";
}
.mdi-pencil:before {
  content: "\F3EB";
}
.mdi-pencil-box:before {
  content: "\F3EC";
}
.mdi-pencil-box-outline:before {
  content: "\F3ED";
}
.mdi-pencil-circle:before {
  content: "\F6FE";
}
.mdi-pencil-circle-outline:before {
  content: "\F775";
}
.mdi-pencil-lock:before {
  content: "\F3EE";
}
.mdi-pencil-off:before {
  content: "\F3EF";
}
.mdi-pentagon:before {
  content: "\F6FF";
}
.mdi-pentagon-outline:before {
  content: "\F700";
}
.mdi-percent:before {
  content: "\F3F0";
}
.mdi-periscope:before {
  content: "\F747";
}
.mdi-pharmacy:before {
  content: "\F3F1";
}
.mdi-phone:before {
  content: "\F3F2";
}
.mdi-phone-bluetooth:before {
  content: "\F3F3";
}
.mdi-phone-classic:before {
  content: "\F602";
}
.mdi-phone-forward:before {
  content: "\F3F4";
}
.mdi-phone-hangup:before {
  content: "\F3F5";
}
.mdi-phone-in-talk:before {
  content: "\F3F6";
}
.mdi-phone-incoming:before {
  content: "\F3F7";
}
.mdi-phone-locked:before {
  content: "\F3F8";
}
.mdi-phone-log:before {
  content: "\F3F9";
}
.mdi-phone-minus:before {
  content: "\F658";
}
.mdi-phone-missed:before {
  content: "\F3FA";
}
.mdi-phone-outgoing:before {
  content: "\F3FB";
}
.mdi-phone-paused:before {
  content: "\F3FC";
}
.mdi-phone-plus:before {
  content: "\F659";
}
.mdi-phone-settings:before {
  content: "\F3FD";
}
.mdi-phone-voip:before {
  content: "\F3FE";
}
.mdi-pi:before {
  content: "\F3FF";
}
.mdi-pi-box:before {
  content: "\F400";
}
.mdi-piano:before {
  content: "\F67C";
}
.mdi-pig:before {
  content: "\F401";
}
.mdi-pill:before {
  content: "\F402";
}
.mdi-pillar:before {
  content: "\F701";
}
.mdi-pin:before {
  content: "\F403";
}
.mdi-pin-off:before {
  content: "\F404";
}
.mdi-pine-tree:before {
  content: "\F405";
}
.mdi-pine-tree-box:before {
  content: "\F406";
}
.mdi-pinterest:before {
  content: "\F407";
}
.mdi-pinterest-box:before {
  content: "\F408";
}
.mdi-pistol:before {
  content: "\F702";
}
.mdi-pizza:before {
  content: "\F409";
}
.mdi-plane-shield:before {
  content: "\F6BA";
}
.mdi-play:before {
  content: "\F40A";
}
.mdi-play-box-outline:before {
  content: "\F40B";
}
.mdi-play-circle:before {
  content: "\F40C";
}
.mdi-play-circle-outline:before {
  content: "\F40D";
}
.mdi-play-pause:before {
  content: "\F40E";
}
.mdi-play-protected-content:before {
  content: "\F40F";
}
.mdi-playlist-check:before {
  content: "\F5C7";
}
.mdi-playlist-minus:before {
  content: "\F410";
}
.mdi-playlist-play:before {
  content: "\F411";
}
.mdi-playlist-plus:before {
  content: "\F412";
}
.mdi-playlist-remove:before {
  content: "\F413";
}
.mdi-playstation:before {
  content: "\F414";
}
.mdi-plex:before {
  content: "\F6B9";
}
.mdi-plus:before {
  content: "\F415";
}
.mdi-plus-box:before {
  content: "\F416";
}
.mdi-plus-box-outline:before {
  content: "\F703";
}
.mdi-plus-circle:before {
  content: "\F417";
}
.mdi-plus-circle-multiple-outline:before {
  content: "\F418";
}
.mdi-plus-circle-outline:before {
  content: "\F419";
}
.mdi-plus-network:before {
  content: "\F41A";
}
.mdi-plus-one:before {
  content: "\F41B";
}
.mdi-plus-outline:before {
  content: "\F704";
}
.mdi-pocket:before {
  content: "\F41C";
}
.mdi-pokeball:before {
  content: "\F41D";
}
.mdi-polaroid:before {
  content: "\F41E";
}
.mdi-poll:before {
  content: "\F41F";
}
.mdi-poll-box:before {
  content: "\F420";
}
.mdi-polymer:before {
  content: "\F421";
}
.mdi-pool:before {
  content: "\F606";
}
.mdi-popcorn:before {
  content: "\F422";
}
.mdi-pot:before {
  content: "\F65A";
}
.mdi-pot-mix:before {
  content: "\F65B";
}
.mdi-pound:before {
  content: "\F423";
}
.mdi-pound-box:before {
  content: "\F424";
}
.mdi-power:before {
  content: "\F425";
}
.mdi-power-plug:before {
  content: "\F6A4";
}
.mdi-power-plug-off:before {
  content: "\F6A5";
}
.mdi-power-settings:before {
  content: "\F426";
}
.mdi-power-socket:before {
  content: "\F427";
}
.mdi-prescription:before {
  content: "\F705";
}
.mdi-presentation:before {
  content: "\F428";
}
.mdi-presentation-play:before {
  content: "\F429";
}
.mdi-printer:before {
  content: "\F42A";
}
.mdi-printer-3d:before {
  content: "\F42B";
}
.mdi-printer-alert:before {
  content: "\F42C";
}
.mdi-printer-settings:before {
  content: "\F706";
}
.mdi-priority-high:before {
  content: "\F603";
}
.mdi-priority-low:before {
  content: "\F604";
}
.mdi-professional-hexagon:before {
  content: "\F42D";
}
.mdi-projector:before {
  content: "\F42E";
}
.mdi-projector-screen:before {
  content: "\F42F";
}
.mdi-publish:before {
  content: "\F6A6";
}
.mdi-pulse:before {
  content: "\F430";
}
.mdi-puzzle:before {
  content: "\F431";
}
.mdi-qqchat:before {
  content: "\F605";
}
.mdi-qrcode:before {
  content: "\F432";
}
.mdi-qrcode-scan:before {
  content: "\F433";
}
.mdi-quadcopter:before {
  content: "\F434";
}
.mdi-quality-high:before {
  content: "\F435";
}
.mdi-quicktime:before {
  content: "\F436";
}
.mdi-radar:before {
  content: "\F437";
}
.mdi-radiator:before {
  content: "\F438";
}
.mdi-radio:before {
  content: "\F439";
}
.mdi-radio-handheld:before {
  content: "\F43A";
}
.mdi-radio-tower:before {
  content: "\F43B";
}
.mdi-radioactive:before {
  content: "\F43C";
}
.mdi-radiobox-blank:before {
  content: "\F43D";
}
.mdi-radiobox-marked:before {
  content: "\F43E";
}
.mdi-raspberrypi:before {
  content: "\F43F";
}
.mdi-ray-end:before {
  content: "\F440";
}
.mdi-ray-end-arrow:before {
  content: "\F441";
}
.mdi-ray-start:before {
  content: "\F442";
}
.mdi-ray-start-arrow:before {
  content: "\F443";
}
.mdi-ray-start-end:before {
  content: "\F444";
}
.mdi-ray-vertex:before {
  content: "\F445";
}
.mdi-rdio:before {
  content: "\F446";
}
.mdi-react:before {
  content: "\F707";
}
.mdi-read:before {
  content: "\F447";
}
.mdi-readability:before {
  content: "\F448";
}
.mdi-receipt:before {
  content: "\F449";
}
.mdi-record:before {
  content: "\F44A";
}
.mdi-record-rec:before {
  content: "\F44B";
}
.mdi-recycle:before {
  content: "\F44C";
}
.mdi-reddit:before {
  content: "\F44D";
}
.mdi-redo:before {
  content: "\F44E";
}
.mdi-redo-variant:before {
  content: "\F44F";
}
.mdi-refresh:before {
  content: "\F450";
}
.mdi-regex:before {
  content: "\F451";
}
.mdi-relative-scale:before {
  content: "\F452";
}
.mdi-reload:before {
  content: "\F453";
}
.mdi-remote:before {
  content: "\F454";
}
.mdi-rename-box:before {
  content: "\F455";
}
.mdi-reorder-horizontal:before {
  content: "\F687";
}
.mdi-reorder-vertical:before {
  content: "\F688";
}
.mdi-repeat:before {
  content: "\F456";
}
.mdi-repeat-off:before {
  content: "\F457";
}
.mdi-repeat-once:before {
  content: "\F458";
}
.mdi-replay:before {
  content: "\F459";
}
.mdi-reply:before {
  content: "\F45A";
}
.mdi-reply-all:before {
  content: "\F45B";
}
.mdi-reproduction:before {
  content: "\F45C";
}
.mdi-resize-bottom-right:before {
  content: "\F45D";
}
.mdi-responsive:before {
  content: "\F45E";
}
.mdi-restart:before {
  content: "\F708";
}
.mdi-restore:before {
  content: "\F6A7";
}
.mdi-rewind:before {
  content: "\F45F";
}
.mdi-rewind-outline:before {
  content: "\F709";
}
.mdi-rhombus:before {
  content: "\F70A";
}
.mdi-rhombus-outline:before {
  content: "\F70B";
}
.mdi-ribbon:before {
  content: "\F460";
}
.mdi-road:before {
  content: "\F461";
}
.mdi-road-variant:before {
  content: "\F462";
}
.mdi-robot:before {
  content: "\F6A8";
}
.mdi-rocket:before {
  content: "\F463";
}
.mdi-roomba:before {
  content: "\F70C";
}
.mdi-rotate-3d:before {
  content: "\F464";
}
.mdi-rotate-left:before {
  content: "\F465";
}
.mdi-rotate-left-variant:before {
  content: "\F466";
}
.mdi-rotate-right:before {
  content: "\F467";
}
.mdi-rotate-right-variant:before {
  content: "\F468";
}
.mdi-rounded-corner:before {
  content: "\F607";
}
.mdi-router-wireless:before {
  content: "\F469";
}
.mdi-routes:before {
  content: "\F46A";
}
.mdi-rowing:before {
  content: "\F608";
}
.mdi-rss:before {
  content: "\F46B";
}
.mdi-rss-box:before {
  content: "\F46C";
}
.mdi-ruler:before {
  content: "\F46D";
}
.mdi-run:before {
  content: "\F70D";
}
.mdi-run-fast:before {
  content: "\F46E";
}
.mdi-sale:before {
  content: "\F46F";
}
.mdi-satellite:before {
  content: "\F470";
}
.mdi-satellite-variant:before {
  content: "\F471";
}
.mdi-saxophone:before {
  content: "\F609";
}
.mdi-scale:before {
  content: "\F472";
}
.mdi-scale-balance:before {
  content: "\F5D1";
}
.mdi-scale-bathroom:before {
  content: "\F473";
}
.mdi-scanner:before {
  content: "\F6AA";
}
.mdi-school:before {
  content: "\F474";
}
.mdi-screen-rotation:before {
  content: "\F475";
}
.mdi-screen-rotation-lock:before {
  content: "\F476";
}
.mdi-screwdriver:before {
  content: "\F477";
}
.mdi-script:before {
  content: "\F478";
}
.mdi-sd:before {
  content: "\F479";
}
.mdi-seal:before {
  content: "\F47A";
}
.mdi-search-web:before {
  content: "\F70E";
}
.mdi-seat-flat:before {
  content: "\F47B";
}
.mdi-seat-flat-angled:before {
  content: "\F47C";
}
.mdi-seat-individual-suite:before {
  content: "\F47D";
}
.mdi-seat-legroom-extra:before {
  content: "\F47E";
}
.mdi-seat-legroom-normal:before {
  content: "\F47F";
}
.mdi-seat-legroom-reduced:before {
  content: "\F480";
}
.mdi-seat-recline-extra:before {
  content: "\F481";
}
.mdi-seat-recline-normal:before {
  content: "\F482";
}
.mdi-security:before {
  content: "\F483";
}
.mdi-security-home:before {
  content: "\F689";
}
.mdi-security-network:before {
  content: "\F484";
}
.mdi-select:before {
  content: "\F485";
}
.mdi-select-all:before {
  content: "\F486";
}
.mdi-select-inverse:before {
  content: "\F487";
}
.mdi-select-off:before {
  content: "\F488";
}
.mdi-selection:before {
  content: "\F489";
}
.mdi-selection-off:before {
  content: "\F776";
}
.mdi-send:before {
  content: "\F48A";
}
.mdi-serial-port:before {
  content: "\F65C";
}
.mdi-server:before {
  content: "\F48B";
}
.mdi-server-minus:before {
  content: "\F48C";
}
.mdi-server-network:before {
  content: "\F48D";
}
.mdi-server-network-off:before {
  content: "\F48E";
}
.mdi-server-off:before {
  content: "\F48F";
}
.mdi-server-plus:before {
  content: "\F490";
}
.mdi-server-remove:before {
  content: "\F491";
}
.mdi-server-security:before {
  content: "\F492";
}
.mdi-set-all:before {
  content: "\F777";
}
.mdi-set-center:before {
  content: "\F778";
}
.mdi-set-center-right:before {
  content: "\F779";
}
.mdi-set-left:before {
  content: "\F77A";
}
.mdi-set-left-center:before {
  content: "\F77B";
}
.mdi-set-left-right:before {
  content: "\F77C";
}
.mdi-set-none:before {
  content: "\F77D";
}
.mdi-set-right:before {
  content: "\F77E";
}
.mdi-settings:before {
  content: "\F493";
}
.mdi-settings-box:before {
  content: "\F494";
}
.mdi-shape-circle-plus:before {
  content: "\F65D";
}
.mdi-shape-plus:before {
  content: "\F495";
}
.mdi-shape-polygon-plus:before {
  content: "\F65E";
}
.mdi-shape-rectangle-plus:before {
  content: "\F65F";
}
.mdi-shape-square-plus:before {
  content: "\F660";
}
.mdi-share:before {
  content: "\F496";
}
.mdi-share-variant:before {
  content: "\F497";
}
.mdi-shield:before {
  content: "\F498";
}
.mdi-shield-half-full:before {
  content: "\F77F";
}
.mdi-shield-outline:before {
  content: "\F499";
}
.mdi-shopping:before {
  content: "\F49A";
}
.mdi-shopping-music:before {
  content: "\F49B";
}
.mdi-shovel:before {
  content: "\F70F";
}
.mdi-shovel-off:before {
  content: "\F710";
}
.mdi-shredder:before {
  content: "\F49C";
}
.mdi-shuffle:before {
  content: "\F49D";
}
.mdi-shuffle-disabled:before {
  content: "\F49E";
}
.mdi-shuffle-variant:before {
  content: "\F49F";
}
.mdi-sigma:before {
  content: "\F4A0";
}
.mdi-sigma-lower:before {
  content: "\F62B";
}
.mdi-sign-caution:before {
  content: "\F4A1";
}
.mdi-sign-direction:before {
  content: "\F780";
}
.mdi-sign-text:before {
  content: "\F781";
}
.mdi-signal:before {
  content: "\F4A2";
}
.mdi-signal-2g:before {
  content: "\F711";
}
.mdi-signal-3g:before {
  content: "\F712";
}
.mdi-signal-4g:before {
  content: "\F713";
}
.mdi-signal-hspa:before {
  content: "\F714";
}
.mdi-signal-hspa-plus:before {
  content: "\F715";
}
.mdi-signal-off:before {
  content: "\F782";
}
.mdi-signal-variant:before {
  content: "\F60A";
}
.mdi-silverware:before {
  content: "\F4A3";
}
.mdi-silverware-fork:before {
  content: "\F4A4";
}
.mdi-silverware-spoon:before {
  content: "\F4A5";
}
.mdi-silverware-variant:before {
  content: "\F4A6";
}
.mdi-sim:before {
  content: "\F4A7";
}
.mdi-sim-alert:before {
  content: "\F4A8";
}
.mdi-sim-off:before {
  content: "\F4A9";
}
.mdi-sitemap:before {
  content: "\F4AA";
}
.mdi-skip-backward:before {
  content: "\F4AB";
}
.mdi-skip-forward:before {
  content: "\F4AC";
}
.mdi-skip-next:before {
  content: "\F4AD";
}
.mdi-skip-next-circle:before {
  content: "\F661";
}
.mdi-skip-next-circle-outline:before {
  content: "\F662";
}
.mdi-skip-previous:before {
  content: "\F4AE";
}
.mdi-skip-previous-circle:before {
  content: "\F663";
}
.mdi-skip-previous-circle-outline:before {
  content: "\F664";
}
.mdi-skull:before {
  content: "\F68B";
}
.mdi-skype:before {
  content: "\F4AF";
}
.mdi-skype-business:before {
  content: "\F4B0";
}
.mdi-slack:before {
  content: "\F4B1";
}
.mdi-sleep:before {
  content: "\F4B2";
}
.mdi-sleep-off:before {
  content: "\F4B3";
}
.mdi-smoking:before {
  content: "\F4B4";
}
.mdi-smoking-off:before {
  content: "\F4B5";
}
.mdi-snapchat:before {
  content: "\F4B6";
}
.mdi-snowflake:before {
  content: "\F716";
}
.mdi-snowman:before {
  content: "\F4B7";
}
.mdi-soccer:before {
  content: "\F4B8";
}
.mdi-sofa:before {
  content: "\F4B9";
}
.mdi-solid:before {
  content: "\F68C";
}
.mdi-sort:before {
  content: "\F4BA";
}
.mdi-sort-alphabetical:before {
  content: "\F4BB";
}
.mdi-sort-ascending:before {
  content: "\F4BC";
}
.mdi-sort-descending:before {
  content: "\F4BD";
}
.mdi-sort-numeric:before {
  content: "\F4BE";
}
.mdi-sort-variant:before {
  content: "\F4BF";
}
.mdi-soundcloud:before {
  content: "\F4C0";
}
.mdi-source-branch:before {
  content: "\F62C";
}
.mdi-source-commit:before {
  content: "\F717";
}
.mdi-source-commit-end:before {
  content: "\F718";
}
.mdi-source-commit-end-local:before {
  content: "\F719";
}
.mdi-source-commit-local:before {
  content: "\F71A";
}
.mdi-source-commit-next-local:before {
  content: "\F71B";
}
.mdi-source-commit-start:before {
  content: "\F71C";
}
.mdi-source-commit-start-next-local:before {
  content: "\F71D";
}
.mdi-source-fork:before {
  content: "\F4C1";
}
.mdi-source-merge:before {
  content: "\F62D";
}
.mdi-source-pull:before {
  content: "\F4C2";
}
.mdi-speaker:before {
  content: "\F4C3";
}
.mdi-speaker-off:before {
  content: "\F4C4";
}
.mdi-speaker-wireless:before {
  content: "\F71E";
}
.mdi-speedometer:before {
  content: "\F4C5";
}
.mdi-spellcheck:before {
  content: "\F4C6";
}
.mdi-spotify:before {
  content: "\F4C7";
}
.mdi-spotlight:before {
  content: "\F4C8";
}
.mdi-spotlight-beam:before {
  content: "\F4C9";
}
.mdi-spray:before {
  content: "\F665";
}
.mdi-square:before {
  content: "\F763";
}
.mdi-square-inc:before {
  content: "\F4CA";
}
.mdi-square-inc-cash:before {
  content: "\F4CB";
}
.mdi-square-outline:before {
  content: "\F762";
}
.mdi-square-root:before {
  content: "\F783";
}
.mdi-stackexchange:before {
  content: "\F60B";
}
.mdi-stackoverflow:before {
  content: "\F4CC";
}
.mdi-stadium:before {
  content: "\F71F";
}
.mdi-stairs:before {
  content: "\F4CD";
}
.mdi-star:before {
  content: "\F4CE";
}
.mdi-star-circle:before {
  content: "\F4CF";
}
.mdi-star-half:before {
  content: "\F4D0";
}
.mdi-star-off:before {
  content: "\F4D1";
}
.mdi-star-outline:before {
  content: "\F4D2";
}
.mdi-steam:before {
  content: "\F4D3";
}
.mdi-steering:before {
  content: "\F4D4";
}
.mdi-step-backward:before {
  content: "\F4D5";
}
.mdi-step-backward-2:before {
  content: "\F4D6";
}
.mdi-step-forward:before {
  content: "\F4D7";
}
.mdi-step-forward-2:before {
  content: "\F4D8";
}
.mdi-stethoscope:before {
  content: "\F4D9";
}
.mdi-sticker:before {
  content: "\F5D0";
}
.mdi-sticker-emoji:before {
  content: "\F784";
}
.mdi-stocking:before {
  content: "\F4DA";
}
.mdi-stop:before {
  content: "\F4DB";
}
.mdi-stop-circle:before {
  content: "\F666";
}
.mdi-stop-circle-outline:before {
  content: "\F667";
}
.mdi-store:before {
  content: "\F4DC";
}
.mdi-store-24-hour:before {
  content: "\F4DD";
}
.mdi-stove:before {
  content: "\F4DE";
}
.mdi-subdirectory-arrow-left:before {
  content: "\F60C";
}
.mdi-subdirectory-arrow-right:before {
  content: "\F60D";
}
.mdi-subway:before {
  content: "\F6AB";
}
.mdi-subway-variant:before {
  content: "\F4DF";
}
.mdi-summit:before {
  content: "\F785";
}
.mdi-sunglasses:before {
  content: "\F4E0";
}
.mdi-surround-sound:before {
  content: "\F5C5";
}
.mdi-svg:before {
  content: "\F720";
}
.mdi-swap-horizontal:before {
  content: "\F4E1";
}
.mdi-swap-vertical:before {
  content: "\F4E2";
}
.mdi-swim:before {
  content: "\F4E3";
}
.mdi-switch:before {
  content: "\F4E4";
}
.mdi-sword:before {
  content: "\F4E5";
}
.mdi-sword-cross:before {
  content: "\F786";
}
.mdi-sync:before {
  content: "\F4E6";
}
.mdi-sync-alert:before {
  content: "\F4E7";
}
.mdi-sync-off:before {
  content: "\F4E8";
}
.mdi-tab:before {
  content: "\F4E9";
}
.mdi-tab-plus:before {
  content: "\F75B";
}
.mdi-tab-unselected:before {
  content: "\F4EA";
}
.mdi-table:before {
  content: "\F4EB";
}
.mdi-table-column-plus-after:before {
  content: "\F4EC";
}
.mdi-table-column-plus-before:before {
  content: "\F4ED";
}
.mdi-table-column-remove:before {
  content: "\F4EE";
}
.mdi-table-column-width:before {
  content: "\F4EF";
}
.mdi-table-edit:before {
  content: "\F4F0";
}
.mdi-table-large:before {
  content: "\F4F1";
}
.mdi-table-row-height:before {
  content: "\F4F2";
}
.mdi-table-row-plus-after:before {
  content: "\F4F3";
}
.mdi-table-row-plus-before:before {
  content: "\F4F4";
}
.mdi-table-row-remove:before {
  content: "\F4F5";
}
.mdi-tablet:before {
  content: "\F4F6";
}
.mdi-tablet-android:before {
  content: "\F4F7";
}
.mdi-tablet-ipad:before {
  content: "\F4F8";
}
.mdi-taco:before {
  content: "\F761";
}
.mdi-tag:before {
  content: "\F4F9";
}
.mdi-tag-faces:before {
  content: "\F4FA";
}
.mdi-tag-heart:before {
  content: "\F68A";
}
.mdi-tag-multiple:before {
  content: "\F4FB";
}
.mdi-tag-outline:before {
  content: "\F4FC";
}
.mdi-tag-plus:before {
  content: "\F721";
}
.mdi-tag-remove:before {
  content: "\F722";
}
.mdi-tag-text-outline:before {
  content: "\F4FD";
}
.mdi-target:before {
  content: "\F4FE";
}
.mdi-taxi:before {
  content: "\F4FF";
}
.mdi-teamviewer:before {
  content: "\F500";
}
.mdi-telegram:before {
  content: "\F501";
}
.mdi-television:before {
  content: "\F502";
}
.mdi-television-guide:before {
  content: "\F503";
}
.mdi-temperature-celsius:before {
  content: "\F504";
}
.mdi-temperature-fahrenheit:before {
  content: "\F505";
}
.mdi-temperature-kelvin:before {
  content: "\F506";
}
.mdi-tennis:before {
  content: "\F507";
}
.mdi-tent:before {
  content: "\F508";
}
.mdi-terrain:before {
  content: "\F509";
}
.mdi-test-tube:before {
  content: "\F668";
}
.mdi-text-shadow:before {
  content: "\F669";
}
.mdi-text-to-speech:before {
  content: "\F50A";
}
.mdi-text-to-speech-off:before {
  content: "\F50B";
}
.mdi-textbox:before {
  content: "\F60E";
}
.mdi-texture:before {
  content: "\F50C";
}
.mdi-theater:before {
  content: "\F50D";
}
.mdi-theme-light-dark:before {
  content: "\F50E";
}
.mdi-thermometer:before {
  content: "\F50F";
}
.mdi-thermometer-lines:before {
  content: "\F510";
}
.mdi-thumb-down:before {
  content: "\F511";
}
.mdi-thumb-down-outline:before {
  content: "\F512";
}
.mdi-thumb-up:before {
  content: "\F513";
}
.mdi-thumb-up-outline:before {
  content: "\F514";
}
.mdi-thumbs-up-down:before {
  content: "\F515";
}
.mdi-ticket:before {
  content: "\F516";
}
.mdi-ticket-account:before {
  content: "\F517";
}
.mdi-ticket-confirmation:before {
  content: "\F518";
}
.mdi-ticket-percent:before {
  content: "\F723";
}
.mdi-tie:before {
  content: "\F519";
}
.mdi-tilde:before {
  content: "\F724";
}
.mdi-timelapse:before {
  content: "\F51A";
}
.mdi-timer:before {
  content: "\F51B";
}
.mdi-timer-10:before {
  content: "\F51C";
}
.mdi-timer-3:before {
  content: "\F51D";
}
.mdi-timer-off:before {
  content: "\F51E";
}
.mdi-timer-sand:before {
  content: "\F51F";
}
.mdi-timer-sand-empty:before {
  content: "\F6AC";
}
.mdi-timer-sand-full:before {
  content: "\F78B";
}
.mdi-timetable:before {
  content: "\F520";
}
.mdi-toggle-switch:before {
  content: "\F521";
}
.mdi-toggle-switch-off:before {
  content: "\F522";
}
.mdi-tooltip:before {
  content: "\F523";
}
.mdi-tooltip-edit:before {
  content: "\F524";
}
.mdi-tooltip-image:before {
  content: "\F525";
}
.mdi-tooltip-outline:before {
  content: "\F526";
}
.mdi-tooltip-outline-plus:before {
  content: "\F527";
}
.mdi-tooltip-text:before {
  content: "\F528";
}
.mdi-tooth:before {
  content: "\F529";
}
.mdi-tor:before {
  content: "\F52A";
}
.mdi-tower-beach:before {
  content: "\F680";
}
.mdi-tower-fire:before {
  content: "\F681";
}
.mdi-traffic-light:before {
  content: "\F52B";
}
.mdi-train:before {
  content: "\F52C";
}
.mdi-tram:before {
  content: "\F52D";
}
.mdi-transcribe:before {
  content: "\F52E";
}
.mdi-transcribe-close:before {
  content: "\F52F";
}
.mdi-transfer:before {
  content: "\F530";
}
.mdi-transit-transfer:before {
  content: "\F6AD";
}
.mdi-translate:before {
  content: "\F5CA";
}
.mdi-treasure-chest:before {
  content: "\F725";
}
.mdi-tree:before {
  content: "\F531";
}
.mdi-trello:before {
  content: "\F532";
}
.mdi-trending-down:before {
  content: "\F533";
}
.mdi-trending-neutral:before {
  content: "\F534";
}
.mdi-trending-up:before {
  content: "\F535";
}
.mdi-triangle:before {
  content: "\F536";
}
.mdi-triangle-outline:before {
  content: "\F537";
}
.mdi-trophy:before {
  content: "\F538";
}
.mdi-trophy-award:before {
  content: "\F539";
}
.mdi-trophy-outline:before {
  content: "\F53A";
}
.mdi-trophy-variant:before {
  content: "\F53B";
}
.mdi-trophy-variant-outline:before {
  content: "\F53C";
}
.mdi-truck:before {
  content: "\F53D";
}
.mdi-truck-delivery:before {
  content: "\F53E";
}
.mdi-truck-fast:before {
  content: "\F787";
}
.mdi-truck-trailer:before {
  content: "\F726";
}
.mdi-tshirt-crew:before {
  content: "\F53F";
}
.mdi-tshirt-v:before {
  content: "\F540";
}
.mdi-tumblr:before {
  content: "\F541";
}
.mdi-tumblr-reblog:before {
  content: "\F542";
}
.mdi-tune:before {
  content: "\F62E";
}
.mdi-tune-vertical:before {
  content: "\F66A";
}
.mdi-twitch:before {
  content: "\F543";
}
.mdi-twitter:before {
  content: "\F544";
}
.mdi-twitter-box:before {
  content: "\F545";
}
.mdi-twitter-circle:before {
  content: "\F546";
}
.mdi-twitter-retweet:before {
  content: "\F547";
}
.mdi-uber:before {
  content: "\F748";
}
.mdi-ubuntu:before {
  content: "\F548";
}
.mdi-umbraco:before {
  content: "\F549";
}
.mdi-umbrella:before {
  content: "\F54A";
}
.mdi-umbrella-outline:before {
  content: "\F54B";
}
.mdi-undo:before {
  content: "\F54C";
}
.mdi-undo-variant:before {
  content: "\F54D";
}
.mdi-unfold-less-horizontal:before {
  content: "\F54E";
}
.mdi-unfold-less-vertical:before {
  content: "\F75F";
}
.mdi-unfold-more-horizontal:before {
  content: "\F54F";
}
.mdi-unfold-more-vertical:before {
  content: "\F760";
}
.mdi-ungroup:before {
  content: "\F550";
}
.mdi-unity:before {
  content: "\F6AE";
}
.mdi-untappd:before {
  content: "\F551";
}
.mdi-update:before {
  content: "\F6AF";
}
.mdi-upload:before {
  content: "\F552";
}
.mdi-upload-network:before {
  content: "\F6F5";
}
.mdi-usb:before {
  content: "\F553";
}
.mdi-vector-arrange-above:before {
  content: "\F554";
}
.mdi-vector-arrange-below:before {
  content: "\F555";
}
.mdi-vector-circle:before {
  content: "\F556";
}
.mdi-vector-circle-variant:before {
  content: "\F557";
}
.mdi-vector-combine:before {
  content: "\F558";
}
.mdi-vector-curve:before {
  content: "\F559";
}
.mdi-vector-difference:before {
  content: "\F55A";
}
.mdi-vector-difference-ab:before {
  content: "\F55B";
}
.mdi-vector-difference-ba:before {
  content: "\F55C";
}
.mdi-vector-intersection:before {
  content: "\F55D";
}
.mdi-vector-line:before {
  content: "\F55E";
}
.mdi-vector-point:before {
  content: "\F55F";
}
.mdi-vector-polygon:before {
  content: "\F560";
}
.mdi-vector-polyline:before {
  content: "\F561";
}
.mdi-vector-radius:before {
  content: "\F749";
}
.mdi-vector-rectangle:before {
  content: "\F5C6";
}
.mdi-vector-selection:before {
  content: "\F562";
}
.mdi-vector-square:before {
  content: "\F001";
}
.mdi-vector-triangle:before {
  content: "\F563";
}
.mdi-vector-union:before {
  content: "\F564";
}
.mdi-verified:before {
  content: "\F565";
}
.mdi-vibrate:before {
  content: "\F566";
}
.mdi-video:before {
  content: "\F567";
}
.mdi-video-off:before {
  content: "\F568";
}
.mdi-video-switch:before {
  content: "\F569";
}
.mdi-view-agenda:before {
  content: "\F56A";
}
.mdi-view-array:before {
  content: "\F56B";
}
.mdi-view-carousel:before {
  content: "\F56C";
}
.mdi-view-column:before {
  content: "\F56D";
}
.mdi-view-dashboard:before {
  content: "\F56E";
}
.mdi-view-day:before {
  content: "\F56F";
}
.mdi-view-grid:before {
  content: "\F570";
}
.mdi-view-headline:before {
  content: "\F571";
}
.mdi-view-list:before {
  content: "\F572";
}
.mdi-view-module:before {
  content: "\F573";
}
.mdi-view-parallel:before {
  content: "\F727";
}
.mdi-view-quilt:before {
  content: "\F574";
}
.mdi-view-sequential:before {
  content: "\F728";
}
.mdi-view-stream:before {
  content: "\F575";
}
.mdi-view-week:before {
  content: "\F576";
}
.mdi-vimeo:before {
  content: "\F577";
}
.mdi-vine:before {
  content: "\F578";
}
.mdi-violin:before {
  content: "\F60F";
}
.mdi-visualstudio:before {
  content: "\F610";
}
.mdi-vk:before {
  content: "\F579";
}
.mdi-vk-box:before {
  content: "\F57A";
}
.mdi-vk-circle:before {
  content: "\F57B";
}
.mdi-vlc:before {
  content: "\F57C";
}
.mdi-voice:before {
  content: "\F5CB";
}
.mdi-voicemail:before {
  content: "\F57D";
}
.mdi-volume-high:before {
  content: "\F57E";
}
.mdi-volume-low:before {
  content: "\F57F";
}
.mdi-volume-medium:before {
  content: "\F580";
}
.mdi-volume-minus:before {
  content: "\F75D";
}
.mdi-volume-mute:before {
  content: "\F75E";
}
.mdi-volume-off:before {
  content: "\F581";
}
.mdi-volume-plus:before {
  content: "\F75C";
}
.mdi-vpn:before {
  content: "\F582";
}
.mdi-walk:before {
  content: "\F583";
}
.mdi-wallet:before {
  content: "\F584";
}
.mdi-wallet-giftcard:before {
  content: "\F585";
}
.mdi-wallet-membership:before {
  content: "\F586";
}
.mdi-wallet-travel:before {
  content: "\F587";
}
.mdi-wan:before {
  content: "\F588";
}
.mdi-washing-machine:before {
  content: "\F729";
}
.mdi-watch:before {
  content: "\F589";
}
.mdi-watch-export:before {
  content: "\F58A";
}
.mdi-watch-import:before {
  content: "\F58B";
}
.mdi-watch-vibrate:before {
  content: "\F6B0";
}
.mdi-water:before {
  content: "\F58C";
}
.mdi-water-off:before {
  content: "\F58D";
}
.mdi-water-percent:before {
  content: "\F58E";
}
.mdi-water-pump:before {
  content: "\F58F";
}
.mdi-watermark:before {
  content: "\F612";
}
.mdi-waves:before {
  content: "\F78C";
}
.mdi-weather-cloudy:before {
  content: "\F590";
}
.mdi-weather-fog:before {
  content: "\F591";
}
.mdi-weather-hail:before {
  content: "\F592";
}
.mdi-weather-lightning:before {
  content: "\F593";
}
.mdi-weather-lightning-rainy:before {
  content: "\F67D";
}
.mdi-weather-night:before {
  content: "\F594";
}
.mdi-weather-partlycloudy:before {
  content: "\F595";
}
.mdi-weather-pouring:before {
  content: "\F596";
}
.mdi-weather-rainy:before {
  content: "\F597";
}
.mdi-weather-snowy:before {
  content: "\F598";
}
.mdi-weather-snowy-rainy:before {
  content: "\F67E";
}
.mdi-weather-sunny:before {
  content: "\F599";
}
.mdi-weather-sunset:before {
  content: "\F59A";
}
.mdi-weather-sunset-down:before {
  content: "\F59B";
}
.mdi-weather-sunset-up:before {
  content: "\F59C";
}
.mdi-weather-windy:before {
  content: "\F59D";
}
.mdi-weather-windy-variant:before {
  content: "\F59E";
}
.mdi-web:before {
  content: "\F59F";
}
.mdi-webcam:before {
  content: "\F5A0";
}
.mdi-webhook:before {
  content: "\F62F";
}
.mdi-webpack:before {
  content: "\F72A";
}
.mdi-wechat:before {
  content: "\F611";
}
.mdi-weight:before {
  content: "\F5A1";
}
.mdi-weight-kilogram:before {
  content: "\F5A2";
}
.mdi-whatsapp:before {
  content: "\F5A3";
}
.mdi-wheelchair-accessibility:before {
  content: "\F5A4";
}
.mdi-white-balance-auto:before {
  content: "\F5A5";
}
.mdi-white-balance-incandescent:before {
  content: "\F5A6";
}
.mdi-white-balance-iridescent:before {
  content: "\F5A7";
}
.mdi-white-balance-sunny:before {
  content: "\F5A8";
}
.mdi-widgets:before {
  content: "\F72B";
}
.mdi-wifi:before {
  content: "\F5A9";
}
.mdi-wifi-off:before {
  content: "\F5AA";
}
.mdi-wii:before {
  content: "\F5AB";
}
.mdi-wiiu:before {
  content: "\F72C";
}
.mdi-wikipedia:before {
  content: "\F5AC";
}
.mdi-window-close:before {
  content: "\F5AD";
}
.mdi-window-closed:before {
  content: "\F5AE";
}
.mdi-window-maximize:before {
  content: "\F5AF";
}
.mdi-window-minimize:before {
  content: "\F5B0";
}
.mdi-window-open:before {
  content: "\F5B1";
}
.mdi-window-restore:before {
  content: "\F5B2";
}
.mdi-windows:before {
  content: "\F5B3";
}
.mdi-wordpress:before {
  content: "\F5B4";
}
.mdi-worker:before {
  content: "\F5B5";
}
.mdi-wrap:before {
  content: "\F5B6";
}
.mdi-wrench:before {
  content: "\F5B7";
}
.mdi-wunderlist:before {
  content: "\F5B8";
}
.mdi-xaml:before {
  content: "\F673";
}
.mdi-xbox:before {
  content: "\F5B9";
}
.mdi-xbox-controller:before {
  content: "\F5BA";
}
.mdi-xbox-controller-battery-alert:before {
  content: "\F74A";
}
.mdi-xbox-controller-battery-empty:before {
  content: "\F74B";
}
.mdi-xbox-controller-battery-full:before {
  content: "\F74C";
}
.mdi-xbox-controller-battery-low:before {
  content: "\F74D";
}
.mdi-xbox-controller-battery-medium:before {
  content: "\F74E";
}
.mdi-xbox-controller-battery-unknown:before {
  content: "\F74F";
}
.mdi-xbox-controller-off:before {
  content: "\F5BB";
}
.mdi-xda:before {
  content: "\F5BC";
}
.mdi-xing:before {
  content: "\F5BD";
}
.mdi-xing-box:before {
  content: "\F5BE";
}
.mdi-xing-circle:before {
  content: "\F5BF";
}
.mdi-xml:before {
  content: "\F5C0";
}
.mdi-yammer:before {
  content: "\F788";
}
.mdi-yeast:before {
  content: "\F5C1";
}
.mdi-yelp:before {
  content: "\F5C2";
}
.mdi-yin-yang:before {
  content: "\F67F";
}
.mdi-youtube-play:before {
  content: "\F5C3";
}
.mdi-zip-box:before {
  content: "\F5C4";
}
.mdi-18px.mdi-set,
.mdi-18px.mdi:before {
  font-size: 18px;
}
.mdi-24px.mdi-set,
.mdi-24px.mdi:before {
  font-size: 24px;
}
.mdi-36px.mdi-set,
.mdi-36px.mdi:before {
  font-size: 36px;
}
.mdi-48px.mdi-set,
.mdi-48px.mdi:before {
  font-size: 48px;
}
.mdi-dark:before {
  color: rgba(0, 0, 0, 0.54);
}
.mdi-dark.mdi-inactive:before {
  color: rgba(0, 0, 0, 0.26);
}
.mdi-light:before {
  color: white;
}
.mdi-light.mdi-inactive:before {
  color: rgba(255, 255, 255, 0.3);
}
.mdi-rotate-45 {
  /*
  // Not included in production
  &.mdi-flip-h:before {
      -webkit-transform: scaleX(-1) rotate(45deg);
      transform: scaleX(-1) rotate(45deg);
      filter: FlipH;
      -ms-filter: "FlipH";
  }
  &.mdi-flip-v:before {
      -webkit-transform: scaleY(-1) rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: scaleY(-1) rotate(45deg);
      filter: FlipV;
      -ms-filter: "FlipV";
  }
  */
}
.mdi-rotate-45:before {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.mdi-rotate-90 {
  /*
  // Not included in production
  &.mdi-flip-h:before {
      -webkit-transform: scaleX(-1) rotate(90deg);
      transform: scaleX(-1) rotate(90deg);
      filter: FlipH;
      -ms-filter: "FlipH";
  }
  &.mdi-flip-v:before {
      -webkit-transform: scaleY(-1) rotate(90deg);
      -ms-transform: rotate(90deg);
      transform: scaleY(-1) rotate(90deg);
      filter: FlipV;
      -ms-filter: "FlipV";
  }
  */
}
.mdi-rotate-90:before {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.mdi-rotate-135 {
  /*
  // Not included in production
  &.mdi-flip-h:before {
      -webkit-transform: scaleX(-1) rotate(135deg);
      transform: scaleX(-1) rotate(135deg);
      filter: FlipH;
      -ms-filter: "FlipH";
  }
  &.mdi-flip-v:before {
      -webkit-transform: scaleY(-1) rotate(135deg);
      -ms-transform: rotate(135deg);
      transform: scaleY(-1) rotate(135deg);
      filter: FlipV;
      -ms-filter: "FlipV";
  }
  */
}
.mdi-rotate-135:before {
  -webkit-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  transform: rotate(135deg);
}
.mdi-rotate-180 {
  /*
  // Not included in production
  &.mdi-flip-h:before {
      -webkit-transform: scaleX(-1) rotate(180deg);
      transform: scaleX(-1) rotate(180deg);
      filter: FlipH;
      -ms-filter: "FlipH";
  }
  &.mdi-flip-v:before {
      -webkit-transform: scaleY(-1) rotate(180deg);
      -ms-transform: rotate(180deg);
      transform: scaleY(-1) rotate(180deg);
      filter: FlipV;
      -ms-filter: "FlipV";
  }
  */
}
.mdi-rotate-180:before {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.mdi-rotate-225 {
  /*
  // Not included in production
  &.mdi-flip-h:before {
      -webkit-transform: scaleX(-1) rotate(225deg);
      transform: scaleX(-1) rotate(225deg);
      filter: FlipH;
      -ms-filter: "FlipH";
  }
  &.mdi-flip-v:before {
      -webkit-transform: scaleY(-1) rotate(225deg);
      -ms-transform: rotate(225deg);
      transform: scaleY(-1) rotate(225deg);
      filter: FlipV;
      -ms-filter: "FlipV";
  }
  */
}
.mdi-rotate-225:before {
  -webkit-transform: rotate(225deg);
  -ms-transform: rotate(225deg);
  transform: rotate(225deg);
}
.mdi-rotate-270 {
  /*
  // Not included in production
  &.mdi-flip-h:before {
      -webkit-transform: scaleX(-1) rotate(270deg);
      transform: scaleX(-1) rotate(270deg);
      filter: FlipH;
      -ms-filter: "FlipH";
  }
  &.mdi-flip-v:before {
      -webkit-transform: scaleY(-1) rotate(270deg);
      -ms-transform: rotate(270deg);
      transform: scaleY(-1) rotate(270deg);
      filter: FlipV;
      -ms-filter: "FlipV";
  }
  */
}
.mdi-rotate-270:before {
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}
.mdi-rotate-315 {
  /*
  // Not included in production
  &.mdi-flip-h:before {
      -webkit-transform: scaleX(-1) rotate(315deg);
      transform: scaleX(-1) rotate(315deg);
      filter: FlipH;
      -ms-filter: "FlipH";
  }
  &.mdi-flip-v:before {
      -webkit-transform: scaleY(-1) rotate(315deg);
      -ms-transform: rotate(315deg);
      transform: scaleY(-1) rotate(315deg);
      filter: FlipV;
      -ms-filter: "FlipV";
  }
  */
}
.mdi-rotate-315:before {
  -webkit-transform: rotate(315deg);
  -ms-transform: rotate(315deg);
  transform: rotate(315deg);
}
.mdi-flip-h:before {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  filter: FlipH;
  -ms-filter: "FlipH";
}
.mdi-flip-v:before {
  -webkit-transform: scaleY(-1);
  transform: scaleY(-1);
  filter: FlipV;
  -ms-filter: "FlipV";
}
.mdi-spin:before {
  -webkit-animation: mdi-spin 2s infinite linear;
  animation: mdi-spin 2s infinite linear;
}
@-webkit-keyframes mdi-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes mdi-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
/*# sourceMappingURL=materialdesignicons.css.map */
:root {
  --spacing: 15px;
  --m-spacing: 10px;
  --s-spacing: 7px;
  --popup-height-shift: 0px;
  --max-width: 600px;
  --header-background-color: #303030;
  --header-text-color: #fff;
  --header-text-color-rgb: 255, 255, 255;
  --button-background-color: #000;
  --button-text-color: #fff;
  --native-statusbar-bg-color: #303030;
  --native-statusbar-text-color: #fff;
  --popup-overlay-bg: rgba(0, 0, 0, 0.5);
  --general-text-color: #3C4858;
  --general-text-color-rgb: 60, 72, 88;
  --general-background-color: #FFFFFF;
  --general-background-color-rgb: 255, 255, 255;
  --general-border-color: rgba(var(--general-text-color-rgb), .1);
  --general-text-inactive-color: rgba(var(--general-text-color-rgb), .4);
  --shadow-color: rgba(0, 0, 0, 0.15);
  --background-highlight-color: #F5F5F5;
  --background-highlight-color-rgb: 245, 245, 245;
  --highlight-color: #5FA3F1;
  --highlight-color-rgb: 95, 163, 241;
  --text-on-highlight-color: #FFFFFF;
  --text-on-highlight-color-rgb: 255, 255, 255;
  --locked-field-color: rgba(var(--general-text-color-rgb), 0.1);
  --confirmation-color: #4FC990;
  --confirmation-color-rgb: 79, 201, 144;
  --alert-color: #FED263;
  --alert-color-rgb: 254, 210, 99;
  --warning-color: #EB6262;
  --warning-color-rgb: 235, 98, 98;
  --info-color: #5FA3F1;
  --info-color-rgb: 95, 163, 241;
  --loadbar-color: #29d;
  --sidemenu-current-text-color: #e0e0e0;
  --sidemenu-background-color: #fff;
  --sidemenu-text-color: #111;
  --desktop-background-color: #3c4858;
  --desktop-text-color: #fff;
  --navigation-text-color: #3C4858;
  --navigation-text-color-rgb: 60, 72, 88;
  --navigation-background-color: #FFFFFF;
  --navigation-background-color-rgb: 255, 255, 255;
  --black-color: #000000;
  --black-color-rgb: 0, 0, 0;
  --white-color: #fff;
  --white-color-rgb: 255, 255, 255;
  --text-on-white-color: #3C4858;
  --text-on-white-color-rgb: 60, 72, 88;
  --quiz-color: #FEB663;
  --quiz-color-rgb: 254, 182, 99;
  --service-form-color: #7D548D;
  --service-form-color-rgb: 125, 74, 141;
  --general-border-radius: 7px;
  --chat-border-radius: 15px;
  --training-border-radius: 7px;
  --training-lesson-image-height: 50%;
  --catalog-border-radius: 7px;
  --badge-background-color: #ff0404;
  --badge-text-color: #fff;
  --sab: env(safe-area-inset-bottom);
  --sat: env(safe-area-inset-top);
  --max-desktop-sheet-height: 500px;
  --max-mobile-sheet-height: 60vh;
  --elevated-text-color: var(--general-text-color);
  --elevated-text-color-rgb: var(--general-text-color-rgb);
  --elevated-text-inactive-color: rgba(var(--elevated-text-color-rgb), 0.4);
  --elevated-border-color: rgba(var(--elevated-text-color-rgb), 0.1);
  --elevated-background-color: var(--general-background-color);
  --elevated-background-color-rgb: var(--general-background-color-rgb);
  --elevated-background-highlight-color: var(--background-highlight-color);
  --elevated-background-highlight-color-rgb: var(--background-highlight-color-rgb);
}
body.no-scroll {
  overflow: hidden;
  width: 100%;
}
body.fixed {
  position: fixed;
}
body.lock-vertical-scrolling {
  overflow-y: hidden;
}
input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
select:focus,
.fr-element[contenteditable],
textarea:not(.custom) {
  font-size: 16px !important;
}
html {
  height: 100%;
  min-height: 100%;
  padding: 0;
  margin: 0;
}
body {
  height: 100%;
  min-height: 100%;
  font-family: 'Avenir Next LT Pro', Arial, sans-serif;
  font-size: 15px;
  line-height: 1.3;
  font-weight: 400;
  color: var(--general-text-color);
  background-color: var(--general-background-color);
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
}
body.dyslexia-font {
  font-family: 'OpenDyslexic' !important;
}
body.dyslexia-font p,
body.dyslexia-font a,
body.dyslexia-font input,
body.dyslexia-font textarea,
body.dyslexia-font button,
body.dyslexia-font select,
body.dyslexia-font .wysiwyg-result,
body.dyslexia-font .counter,
body.dyslexia-font span:not(.fas,.far,.fal,.fab) {
  font-family: 'OpenDyslexic' !important;
}
body.dyslexia-font h1,
body.dyslexia-font h2,
body.dyslexia-font h3,
body.dyslexia-font h4,
body.dyslexia-font h5,
body.dyslexia-font h6 {
  font-family: 'OpenDyslexic' !important;
}
body h1,
body h2,
body h3,
body h4,
body h5,
body h6 {
  font-weight: 500;
}
body.no-user-select {
  user-select: none;
}
* {
  outline: none;
}
button {
  color: inherit;
}
.main-view {
  height: 100%;
  min-height: 100%;
}
.main-view .menu-bg {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--sidemenu-background-color);
}
.main-view .menu-bg:not(.native) {
  display: none;
}
.main-view .menu-bg:not(.menu-opened) {
  display: none;
}
.main-view.is-fixed-layout {
  z-index: 2;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.loader-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: transparent;
  z-index: 99999;
  min-height: 100%;
}
.loader-overlay.loader-overlay {
  display: none;
}
.initial-load .loader-overlay.initial-loader-overlay {
  display: block;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.initial-load .loader-overlay .spinner {
  width: 50px;
  height: 50px;
  border-width: 5px;
  border-color: #fff;
  opacity: 0.5;
}
input,
textarea,
button,
select {
  font-family: inherit;
  font-weight: inherit;
}
button {
  border: none;
  background: transparent;
  font-size: 15px;
}
.bold {
  font-weight: 600;
}
.lowercase {
  text-transform: lowercase;
}
.uppercase {
  text-transform: uppercase;
}
.headline-weight {
  font-weight: 500;
}
table th {
  font-weight: 500;
}
table thead th {
  font-weight: 500;
}
.visuallyhidden {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}
grammarly-extension {
  visibility: hidden;
}
.default-background {
  background-color: var(--general-background-color);
}
.shadow-depth-1 {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}
.shadow-depth-2 {
  box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.shadow-depth-3 {
  box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.24), 0 17px 50px 0 rgba(0, 0, 0, 0.19);
}
.shadow-depth-4 {
  box-shadow: 0 16px 28px 0 rgba(0, 0, 0, 0.22), 0 25px 55px 0 rgba(0, 0, 0, 0.21);
}
.shadow-depth-5 {
  box-shadow: 0 27px 24px 0 rgba(0, 0, 0, 0.2), 0 40px 77px 0 rgba(0, 0, 0, 0.22);
}
@-moz-keyframes slideOutLeft {
  to {
    transform: translate3d(-100%, 0, 0);
  }
}
@-webkit-keyframes slideOutLeft {
  to {
    transform: translate3d(-100%, 0, 0);
  }
}
@keyframes slideOutLeft {
  to {
    transform: translate3d(-100%, 0, 0);
  }
}
@keyframes slideOutRight {
  to {
    transform: translate3d(100%, 0, 0);
  }
}
@keyframes slideInRight {
  from {
    transform: translate3d(100%, 0, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@-webkit-keyframes slideInLeft {
  from {
    transform: translate3d(-100%, 0, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes slideInLeft {
  from {
    transform: translate3d(-100%, 0, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
.form .form-item {
  display: block;
  border-bottom: 1px solid var(--general-border-color);
  padding: 10px 0 7px 0;
  position: relative;
}
.form .form-item:last-child {
  border: none;
}
.form .form-item:after {
  display: table;
  clear: both;
  content: '';
}
.form .form-item > label {
  display: block;
  color: #8e8e92;
  font-weight: 300;
  text-transform: uppercase;
  font-size: 13px;
  position: relative;
  min-height: 26px;
  line-height: normal;
  padding: 5.5px 0;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding-right: 70px;
}
.form .form-item .small-input {
  display: block;
  width: 70px;
  text-align: right;
  position: absolute;
  top: 10px;
  right: 0;
}
.form .form-item .input-upload {
  display: inline-block;
  height: 26px;
  line-height: 26px;
  padding: 0;
  margin: -26px 0 0 0;
  font-size: 22px;
  float: right;
  color: #d3d7dc;
}
.form .form-item .form-item-placeholder {
  display: block;
  height: 30px;
  width: 100%;
  border-radius: var(--general-border-radius);
}
.form .checkbox-list {
  margin: 10px 0 0 0;
}
.form .checkbox-list .checkbox-item {
  margin: 6px 0;
  position: relative;
  height: 26px;
}
.form .checkbox-list .checkbox-item > label {
  line-height: 26px;
  width: 50%;
  float: left;
}
.form .checkbox-list .checkbox-item > .small-input {
  top: 0;
}
.form .checkbox-list .checkbox-item:after {
  display: table;
  clear: both;
  content: '';
}
.form input {
  background: none;
  height: 26px;
  line-height: 26px;
  display: block;
  border: none;
  width: 100%;
  color: #111;
  font-size: 15px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.form input[disabled] {
  background: rgba(0, 0, 0, 0.4);
  opacity: 0.5;
}
.form input.with-upload {
  padding-right: 40px;
}
.form select {
  background: none;
  border: none;
  background: rgba(0, 0, 0, 0.06);
  line-height: 34px;
  height: 34px;
  color: #000;
  width: 100%;
  font-size: 13px;
  font-weight: 600;
  padding: 0 6px;
  box-sizing: border-box;
}
.form select option {
  padding-left: 0;
}
.form select[disabled] {
  background: rgba(0, 0, 0, 0.4);
  opacity: 0.5;
}
.form textarea {
  border: none;
  width: 100%;
  font-size: 15px;
  color: #111;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.form .form-item .phone-input {
  position: relative;
  clear: both;
}
.form .form-item .phone-input .phone-input-country {
  display: block;
  position: relative;
  height: 32px;
  line-height: 32px;
  width: 50px;
  z-index: 2;
  box-sizing: border-box;
  background: rgba(0, 0, 0, 0.06);
  text-align: center;
  overflow: hidden;
}
.form .form-item .phone-input .phone-input-country.disabled {
  color: rgba(0, 0, 0, 0.4);
  background: transparent;
}
.form .form-item .phone-input > input[type=text] {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  padding-left: 60px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  z-index: 1;
  height: 32px;
  line-height: 32px;
}
#toast-container {
  bottom: calc(50px + env(safe-area-inset-bottom)) !important;
  padding: var(--spacing);
  transition: transform 0.3s ease;
}
@media only screen and (min-width: 768px) {
  #toast-container {
    top: 60px !important;
    bottom: unset !important;
    right: 0;
  }
}
#toast-container > div.toast {
  border-radius: var(--general-border-radius);
  color: var(--general-text-color);
  opacity: 1;
  z-index: 0;
  padding: var(--m-spacing);
  width: calc(100vw - (2 * var(--spacing)));
  background-image: none !important;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.12);
  margin: 0;
  position: relative;
}
@media only screen and (min-width: 768px) {
  #toast-container > div.toast {
    max-width: 345px;
    margin-left: auto;
  }
}
#toast-container > div.toast::after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  backdrop-filter: blur(20px);
}
#toast-container > div.toast::before {
  position: absolute;
  font-family: 'Material Design Icons';
  color: var(--white-color);
  margin-right: var(--s-spacing);
  border-radius: 50%;
  font-size: 14px;
  display: inline-flex;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  display: grid;
  place-content: center;
  left: var(--m-spacing);
  top: 50%;
  transform: translate(0, -50%);
}
#toast-container > div.toast .toast-close-button {
  display: none;
}
@media only screen and (min-width: 768px) {
  #toast-container > div.toast .toast-close-button {
    position: absolute;
    display: block;
    color: var(--general-text-inactive-color);
    font-weight: 200;
    top: 50%;
    right: var(--m-spacing);
    transform: translate(0, -50%);
    width: 20px;
    height: 20px;
    line-height: 20px;
    font-size: 26px;
    text-shadow: none;
  }
}
#toast-container > div.toast .toast-contents {
  display: flex;
  align-items: center;
  margin-left: 30px;
}
@media only screen and (min-width: 768px) {
  #toast-container > div.toast .toast-contents {
    margin-right: 25px;
  }
}
#toast-container > div.toast .toast-title {
  line-height: 1.3rem;
}
#toast-container > div.toast .toast-message {
  font-size: 1rem;
  border: none;
  padding: 0;
}
#toast-container > div.toast .toast-buttons {
  display: flex;
  gap: var(--m-spacing);
  align-items: center;
  margin-left: auto;
}
#toast-container > div.toast .toast-buttons rl-button {
  border-radius: var(--general-border-radius);
}
#toast-container > div.toast .toast-buttons button {
  padding: 0 var(--m-spacing);
  background: inherit;
  min-width: unset;
  font-weight: 600;
  height: 1.875rem;
  color: inherit;
}
#toast-container > div.toast .toast-buttons .button-1 {
  backdrop-filter: blur(12px);
}
#toast-container > div.toast .toast-buttons .button-0 {
  color: var(--white-color);
}
#toast-container > div.toast .toast-buttons:has(.button-1) {
  flex-basis: 100%;
  justify-content: flex-end;
  margin-top: var(--m-spacing);
}
#toast-container > div.toast:has(.button-1)::before {
  top: unset;
  transform: none;
}
#toast-container > div.toast:has(.button-1) .toast-contents {
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}
@media only screen and (min-width: 768px) {
  #toast-container > div.toast:has(.button-1) .toast-contents {
    margin-right: 0;
  }
}
#toast-container > div.toast:has(.button-1) .toast-close-button {
  top: var(--s-spacing);
  right: var(--s-spacing);
  transform: none;
}
#toast-container > div.toast.toast-error {
  border: 1px solid var(--warning-color);
  background-color: var(--warning-color);
}
#toast-container > div.toast.toast-error::after {
  background-color: rgba(255, 255, 255, 0.9);
}
#toast-container > div.toast.toast-error::before {
  color: var(--white-color);
  background: var(--warning-color);
}
#toast-container > div.toast.toast-error .toast-buttons .button-1 button {
  color: var(--warning-color);
}
#toast-container > div.toast.toast-error .toast-buttons .button-0 button {
  background-color: var(--warning-color);
}
#toast-container > div.toast.toast-error::before {
  content: "\F156";
  /* xmark */
  font-size: 11px;
  line-height: 20px;
  font-weight: 800;
}
#toast-container > div.toast.toast-error .toast-buttons .button-1 button {
  background-color: rgba(var(--warning-color-rgb), 0.2);
}
#toast-container > div.toast.toast-success {
  border: 1px solid var(--confirmation-color);
  background-color: var(--confirmation-color);
}
#toast-container > div.toast.toast-success::after {
  background-color: rgba(255, 255, 255, 0.8);
}
#toast-container > div.toast.toast-success::before {
  color: var(--white-color);
  background: var(--confirmation-color);
}
#toast-container > div.toast.toast-success .toast-buttons .button-1 button {
  color: var(--confirmation-color);
}
#toast-container > div.toast.toast-success .toast-buttons .button-0 button {
  background-color: var(--confirmation-color);
}
#toast-container > div.toast.toast-success::before {
  content: "\F12c";
  /* check */
}
#toast-container > div.toast.toast-success .toast-buttons .button-1 button {
  background-color: rgba(var(--confirmation-color-rgb), 0.2);
}
#toast-container > div.toast.toast-info {
  border: 1px solid var(--elevated-text-inactive-color);
  background-color: var(--elevated-text-inactive-color);
  color: var(--elevated-text-color);
}
#toast-container > div.toast.toast-info::after {
  background-color: rgba(255, 255, 255, 0.9);
}
#toast-container > div.toast.toast-info::before {
  color: var(--white-color);
  background: var(--elevated-text-inactive-color);
}
#toast-container > div.toast.toast-info .toast-buttons .button-1 button {
  color: var(--elevated-text-inactive-color);
}
#toast-container > div.toast.toast-info .toast-buttons .button-0 button {
  background-color: var(--elevated-text-inactive-color);
}
#toast-container > div.toast.toast-info::before {
  content: "\f2fc";
  /* mdi-information*/
  color: var(--elevated-text-inactive-color);
  background: var(--elevated-background-color);
  font-size: 25px;
  line-height: 25px;
}
#toast-container > div.toast.toast-info .toast-buttons .button-1 button {
  background-color: rgba(var(--elevated-text-color-rgb), 0.1);
}
#toast-container > div.toast.toast-warning {
  border: 1px solid var(--alert-color);
  background-color: var(--alert-color);
}
#toast-container > div.toast.toast-warning::after {
  background-color: rgba(255, 255, 255, 0.8);
}
#toast-container > div.toast.toast-warning::before {
  color: var(--white-color);
  background: var(--alert-color);
}
#toast-container > div.toast.toast-warning .toast-buttons .button-1 button {
  color: var(--alert-color);
}
#toast-container > div.toast.toast-warning .toast-buttons .button-0 button {
  background-color: var(--alert-color);
}
#toast-container > div.toast.toast-warning::before {
  content: "\F028";
  /* mdi-alert-circle*/
  color: var(--alert-color);
  background: radial-gradient(closest-side, var(--general-text-color) 75%, var(--alert-color));
  font-size: 25px;
  line-height: 25px;
}
#toast-container > div.toast.toast-warning .toast-buttons .button-0 button,
#toast-container > div.toast.toast-warning .toast-buttons .button-1 button {
  color: var(--general-text-color);
}
#toast-container > div.toast.toast-warning .toast-buttons .button-1 button {
  background-color: rgba(var(--alert-color-rgb), 0.4);
}
.popup-sticky-bottom {
  background-color: var(--general-background-color);
  padding: var(--spacing);
  position: fixed;
  z-index: 99;
  bottom: 0;
  width: 100%;
  box-sizing: border-box;
  margin-top: auto;
}
.popup-sticky-bottom button {
  text-transform: uppercase;
}
@media only screen and (min-width: 1025px) {
  .popup-sticky-bottom {
    padding: var(--spacing);
  }
}
:root {
  --top-header-height: 55px;
}
@media only screen and (min-width: 1025px) {
  :root {
    --top-header-height: 60px;
  }
}
.base {
  height: 100%;
  min-height: 100%;
  position: relative;
  background: var(--general-background-color);
}
@media only screen and (min-width: 1025px) {
  .base {
    margin-left: var(--sidemenu-width);
  }
}
.base.native {
  transition: transform 0.35s;
}
.base.native:not(.menu-opened):after,
.base.native:not(.menu-opened):before {
  display: none;
}
.base.native:after,
.base.native:before {
  content: '';
  position: absolute;
  display: block;
  background: var(--sidemenu-text-color);
  opacity: 0.6;
  top: 20px;
  bottom: 20px;
  left: 0;
  right: 0;
  z-index: -1;
  transition: none;
}
.base.native.menu-opened {
  transform: scale(0.7) translateX(-90%);
  box-shadow: 0 3px 25px rgba(0, 0, 0, 0.1);
}
@media only screen and (min-width: 768px) {
  .base.native.menu-opened {
    transform: scale(0.8) translateX(-45%);
  }
}
.base.native.menu-opened:before,
.base.native.menu-opened:after {
  transition: right 0.3s;
  transition-delay: 0.2s;
}
.base.native.menu-opened:before {
  right: -15px;
}
.base.native.menu-opened:after {
  right: -30px;
  top: 40px;
  bottom: 40px;
  opacity: 0.3;
  box-shadow: none;
  filter: blur(2px);
}
.base.native.disable-overflow .base-container {
  overflow: hidden;
}
.base.native .base-top.disable-overflow {
  overflow: hidden;
}
.base.native .base-top .base-top-right-buttons .module-buttons {
  height: 100%;
  position: relative;
}
.base.native .base-bottom {
  height: 50px;
  position: fixed;
  bottom: 0;
  width: 100%;
  border-top: none;
  z-index: 200;
  padding-bottom: env(safe-area-inset-bottom);
}
.base.native .base-bottom ul {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0;
  list-style: none;
  margin: 0;
  height: 100%;
}
.base.native .base-bottom ul button {
  position: relative;
  padding: 1px 6px;
}
.base.native .base-bottom ul button rl-icon {
  font-size: 22px;
}
.base.native .base-bottom ul button rl-icon .svg-teams path {
  fill: var(--header-text-color);
}
.base.native .base-bottom ul button.active rl-icon {
  color: var(--highlight-color);
}
.base.native .base-bottom rl-badge {
  position: absolute;
  bottom: 13px;
  right: 0;
  z-index: 10;
}
.base.native .view-page {
  padding-bottom: 50px;
  padding-bottom: calc(50px + env(safe-area-inset-bottom, 0px));
}
.base .base-container {
  height: 100%;
  min-height: 100%;
  padding-top: var(--top-header-height);
  box-sizing: border-box;
  opacity: 1;
  transition: opacity 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94), transform 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.base .base-container.loading {
  opacity: 0.1;
  transform: scale(0.9);
}
.base .base-container.slow-enter {
  transition-duration: 0.5s;
}
.base .base-container.ng-enter,
.base .base-container.ng-leave.ng-leave-active {
  opacity: 0;
}
.base .base-container.ng-leave,
.base .base-container.ng-enter.ng-enter-active {
  opacity: 1;
}
.base.full-screen-page .base-container {
  padding-top: 0;
}
.base .base-top-background {
  height: var(--top-header-height);
  transition: background 0.35s ease-in-out;
  z-index: 10;
}
.base .base-top {
  z-index: 11;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  height: var(--top-header-height);
  box-sizing: border-box;
}
@media only screen and (min-width: 1025px) {
  .base .base-top {
    left: var(--sidemenu-width);
  }
}
@media only screen and (max-width: 1024px) {
  .base .base-top .desktop-button {
    display: none !important;
  }
}
.base .base-top .survey-options {
  padding: 0;
  text-align: center;
  color: var(--white-color);
  background: transparent;
  border: none;
}
.base .base-top .survey-options rl-icon {
  font-size: 20px;
}
@media only screen and (min-width: 1025px) {
  .base .base-top .survey-options rl-icon {
    font-size: 25px;
  }
}
.base .base-top .base-top-inner {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
}
@media only screen and (max-width: 1024px) {
  .base .base-top .base-top-inner {
    overflow: hidden;
  }
}
.base .base-top .header-popup-wrapper {
  display: inline-block;
  position: relative;
}
.base .base-top .header-popup-wrapper .bookmarks-btn {
  font-size: 20px;
}
.base .base-top .header-popup-wrapper .chat-btn {
  font-size: 23px;
}
.base .base-top .base-top-right-buttons,
.base .base-top .base-top-left-buttons {
  height: 100%;
  display: flex;
}
@media only screen and (min-width: 1025px) {
  .base .base-top .base-top-right-buttons,
  .base .base-top .base-top-left-buttons {
    width: 225px;
  }
}
.base .base-top .base-top-right-buttons {
  left: auto;
  text-align: right;
  justify-content: flex-end;
  transition: 350ms linear all;
  opacity: 1;
}
@media only screen and (min-width: 1025px) {
  .base .base-top .base-top-right-buttons {
    position: relative;
  }
}
.base .base-top .base-top-right-buttons.hidden {
  opacity: 0;
  visibility: hidden;
}
@media only screen and (min-width: 1025px) {
  .base .base-top .base-top-right-buttons > .create-chat-btn {
    font-size: 24px;
    padding-bottom: 3px;
  }
}
.base .base-top .base-top-right-buttons .base-top-right-buttons-inner {
  max-height: 100%;
  position: relative;
}
.base .base-top .base-top-right-buttons .show-department rl-icon {
  font-size: 22px;
}
.base .base-top .base-top-right-buttons .svg-teams path {
  fill: var(--header-text-color);
}
.base .base-top .base-top-right-buttons .header-popup-wrapper .modal-content button {
  color: unset;
}
.base .base-top header-search {
  display: block;
  width: 100%;
}
@media only screen and (max-width: 1024px) {
  .base .base-top .search-field {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 200;
  }
}
.base .base-top .search-field.active {
  z-index: 202;
}
@media only screen and (min-width: 1025px) {
  .base .base-top .search-field {
    height: 100%;
  }
}
.base .base-top .menu-toggle-btn {
  width: 45px !important;
}
@media only screen and (min-width: 1025px) {
  .base .base-top .menu-toggle-btn {
    display: none !important;
  }
}
.base .base-top .menu-toggle-btn:after {
  display: block;
  position: absolute;
  bottom: 50%;
  margin-bottom: -7px;
  height: 12px;
  left: 13px;
  right: 5px;
  border-bottom: 2px solid var(--header-text-color);
  border-top: 2px solid var(--header-text-color);
  content: '';
  width: 22px;
}
.base .base-top .menu-toggle-btn:before {
  display: block;
  position: absolute;
  left: 13px;
  right: 5px;
  top: 50%;
  margin-top: -2px;
  height: 2px;
  background: var(--header-text-color);
  content: '';
  width: 22px;
}
.base .base-top .menu-burger-badge {
  display: block;
  width: 16px;
  height: 16px;
  border-radius: 100%;
  position: absolute;
  top: 10px;
  right: 2px;
  z-index: 10;
  background: var(--badge-background-color);
}
.base .base-top .global-back-btn {
  width: 45px !important;
  height: 0 !important;
  overflow: hidden;
  max-width: 0;
  -moz-transition: max-width 0.2s ease-in-out;
  -o-transition: max-width 0.2s ease-in-out;
  -webkit-transition: max-width 0.2s ease-in-out;
  transition: max-width 0.2s ease-in-out;
}
.base .base-top .global-back-btn.show-back {
  height: 100% !important;
  max-width: 45px;
}
.base .base-top .global-cancel-btn {
  width: 45px !important;
}
@media only screen and (min-width: 1025px) {
  .base .base-top .header-title-wrapper {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
  }
}
@media only screen and (min-width: 1025px) {
  .base .base-top .header-title-wrapper header-search {
    position: absolute;
  }
}
.base .base-top .base-top-inner > .title,
.base .base-top .base-top-inner > .header-title-wrapper .title {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 1;
  top: 0;
}
@media only screen and (max-width: 1024px) {
  .base .base-top .base-top-inner > .title,
  .base .base-top .base-top-inner > .header-title-wrapper .title {
    justify-content: center;
    position: absolute;
    left: 90px;
    right: 90px;
    top: 0;
    height: var(--top-header-height);
  }
  .base .base-top .base-top-inner > .title > span,
  .base .base-top .base-top-inner > .header-title-wrapper .title > span {
    display: block;
    width: 100%;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
.base .base-top .base-top-inner > .title .title-wrapper,
.base .base-top .base-top-inner > .header-title-wrapper .title .title-wrapper {
  position: relative;
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.base .base-top .base-top-inner > .title .title-wrapper h1,
.base .base-top .base-top-inner > .header-title-wrapper .title .title-wrapper h1 {
  display: inline-block;
  vertical-align: middle;
  width: auto;
  max-width: 100%;
}
.base .base-top .base-top-inner > .title .title-wrapper h1.with-filter-button,
.base .base-top .base-top-inner > .header-title-wrapper .title .title-wrapper h1.with-filter-button {
  margin-left: 40px;
}
.base .base-top .base-top-inner > .title .title-wrapper filter-button > button,
.base .base-top .base-top-inner > .header-title-wrapper .title .title-wrapper filter-button > button {
  position: relative;
  display: flex;
  align-items: center;
  width: auto;
}
.base .base-top .base-top-inner > .title .title-wrapper filter-button > button rl-icon,
.base .base-top .base-top-inner > .header-title-wrapper .title .title-wrapper filter-button > button rl-icon {
  padding: 0 10px;
  margin-left: auto;
}
.base .base-top .base-top-inner > .title .title-wrapper .question-sign,
.base .base-top .base-top-inner > .header-title-wrapper .title .title-wrapper .question-sign {
  display: block;
  position: absolute;
  font-size: 9px;
  font-weight: 600;
  color: inherit;
  right: -9px;
  top: -2px;
}
.base .base-top .base-top-inner > .title h1,
.base .base-top .base-top-inner > .header-title-wrapper .title h1 {
  margin: 0;
  text-transform: uppercase;
}
.base .base-top .base-top-inner > .title h1,
.base .base-top .base-top-inner > .header-title-wrapper .title h1,
.base .base-top .base-top-inner > .title h2,
.base .base-top .base-top-inner > .header-title-wrapper .title h2 {
  text-align: center;
  width: 100%;
  font-weight: 600;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.base .base-top .base-top-inner > .title .title-image-container,
.base .base-top .base-top-inner > .header-title-wrapper .title .title-image-container {
  position: absolute;
  left: 0;
  right: 0;
  top: 5px;
  bottom: 5px;
  margin: 0;
  display: -webkit-box;
  display: flex;
  flex-direction: row;
  align-items: center;
}
@media only screen and (max-width: 1024px) {
  .base .base-top .base-top-inner > .title .title-image-container,
  .base .base-top .base-top-inner > .header-title-wrapper .title .title-image-container {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}
.base .base-top .base-top-inner > .title img,
.base .base-top .base-top-inner > .header-title-wrapper .title img {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
}
.base .base-top .base-top-inner > .title.with-subtitle .subtitle,
.base .base-top .base-top-inner > .header-title-wrapper .title.with-subtitle .subtitle {
  margin: 0;
  height: 20px;
  line-height: 20px;
  font-weight: normal;
  opacity: 0.5;
}
@media only screen and (max-width: 1024px) {
   > span .base .base-top .base-top-inner > .title.with-subtitle:not(.subtitle),
   > span .base .base-top .base-top-inner > .header-title-wrapper .title.with-subtitle:not(.subtitle) {
    margin-bottom: 14px;
  }
  .base .base-top .base-top-inner > .title.with-subtitle .subtitle,
  .base .base-top .base-top-inner > .header-title-wrapper .title.with-subtitle .subtitle {
    height: 18px;
  }
}
@media only screen and (min-width: 1025px) {
  .base .base-top .base-top-inner > .title.with-subtitle .subtitle,
  .base .base-top .base-top-inner > .header-title-wrapper .title.with-subtitle .subtitle {
    padding-left: 0;
  }
}
.base .base-top .base-top-inner filter-button[place=right] rl-badge {
  z-index: 201;
}
@media only screen and (max-width: 1024px) {
  .base .base-top .three-module-buttons > .title,
  .base .base-top .three-module-buttons > .header-title-wrapper .title {
    margin-right: 45px;
  }
}
.base .base-top .notification-btn,
.base .base-top .bookmarks-btn,
.base .base-top .chat-btn {
  position: relative;
}
.base .base-top .notification-btn rl-badge,
.base .base-top .bookmarks-btn rl-badge,
.base .base-top .chat-btn rl-badge {
  position: absolute;
  top: 10px;
  right: 8px;
}
.base .base-top .profile-toggle-btn .profile-image {
  width: 30px;
  height: 30px;
  vertical-align: middle;
  position: relative;
}
.base .base-top .profile-toggle-btn .profile-toggle-btn-image {
  width: 30px;
  height: 30px;
  display: inline-block;
  background: none;
  background-size: cover;
  background-position: center center;
  border-radius: 50%;
  overflow: hidden;
  vertical-align: middle;
  position: relative;
  top: -3px;
}
.base .base-top .profile-toggle-btn rl-badge {
  position: absolute;
  top: 9px;
  right: 100%;
  margin-right: -13px;
}
.base .base-view {
  height: 100%;
  min-height: 100%;
  position: relative;
}
.header-background-color {
  background-color: var(--header-background-color);
}
.header-text-color {
  color: var(--header-text-color);
}
.header-popup-wrapper {
  position: relative;
  text-align: left;
}
.header-popup-wrapper .modal-overlay {
  background: none;
}
.header-popup-wrapper .modal {
  position: absolute;
  top: 45px;
  right: -30px;
  left: auto;
  bottom: auto;
  width: 375px;
}
.header-popup-wrapper .modal.show .modal-content {
  transform: translate3d(0, 0, 0);
}
.header-popup-wrapper .modal .modal-content {
  overflow: hidden;
  position: relative;
  top: 15px;
  border: 1px solid var(--general-border-color);
  background: var(--general-background-color);
  border-radius: var(--general-border-radius);
}
.header-popup-wrapper .modal .modal-content .modal-top {
  height: auto;
  padding: 15px;
  box-shadow: none;
  border-bottom: 1px solid var(--general-border-color);
}
.header-popup-wrapper .modal .modal-content .modal-top h1,
.header-popup-wrapper .modal .modal-content .modal-top h2 {
  position: static;
  font-size: 15px;
  font-weight: 600;
}
.header-popup-wrapper .modal .modal-content .modal-top button {
  padding: 0;
  font-size: 20px;
  line-height: 15px;
  color: unset;
}
.header-popup-wrapper .modal .modal-content .modal-select-picker {
  height: 250px;
  overflow-y: auto;
}
.header-popup-wrapper .modal .modal-content .modal-select-picker li {
  padding: 10px 15px;
  font-size: 13px;
  line-height: 18px;
  color: unset;
}
.header-popup-wrapper .modal .modal-content .modal-select-picker li:hover,
.header-popup-wrapper .modal .modal-content .modal-select-picker li.selected {
  background: var(--general-background-color);
  color: var(--highlight-color);
}
.header-popup-wrapper .modal .modal-content .modal-select-picker li.selected {
  font-weight: bold;
}
.header-popup-wrapper .modal .modal-content .modal-select-picker li.selected i {
  color: var(--highlight-color);
}
.header-popup-wrapper .modal .modal-content .modal-select-picker li:after {
  display: none;
}
.header-popup-wrapper .modal .modal-content .modal-bottom {
  display: flex;
  justify-content: space-between;
  box-shadow: none;
  border-top: 1px solid var(--general-border-color);
}
.header-popup-wrapper .modal .modal-content .modal-bottom button {
  width: auto;
  padding: 0 15px;
  font-size: 13px;
  color: unset;
}
.header-popup-wrapper .modal .modal-content .modal-bottom button:first-of-type {
  color: var(--warning-color);
}
.header-popup-wrapper .modal .modal-content .modal-bottom button:last-of-type {
  color: var(--highlight-color);
}
.header-popup-wrapper .modal .modal-content .modal-close {
  display: none;
}
.header-popup-wrapper .modal .modal-content button {
  color: unset;
}
.header-popup-wrapper .modal-body {
  max-height: 500px;
}
:root {
  --sidemenu-width: 360px;
}
@media only screen and (min-width: 1025px) and (max-width: 1300px) {
  :root {
    --sidemenu-width: 280px;
  }
}
@media only screen and (min-width: 1300px) {
  :root {
    --sidemenu-width: 360px;
  }
}
@supports (-webkit-overflow-scrolling: touch) {
  .menu .menu-list .menu-list-root {
    height: calc(100% + 1px);
  }
}
.menu-overlay {
  display: none;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 350;
  background: rgba(0, 0, 0, 0.6);
}
.menu-overlay.open {
  display: block;
}
@media only screen and (min-width: 1025px) {
  .main-menu-wrapper {
    position: fixed;
    width: var(--sidemenu-width);
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
  }
}
.main-menu-wrapper.native .menu-overlay {
  background: none;
}
.main-menu-wrapper.native .menu {
  transition: transform 0.5s, visibility 0.2s, z-index 0s;
}
@media only screen and (max-width: 1024px) {
  .main-menu-wrapper.native .menu {
    width: 75%;
    left: auto;
    right: 0;
    transform: translate3d(20%, 0, 0);
    background: none;
    box-shadow: none;
    z-index: 0;
    visibility: hidden;
  }
}
.main-menu-wrapper.native .menu.open {
  transform: translate3d(0, 0, 0);
  z-index: 400;
  visibility: visible;
  transition: transform 0.3s, z-index 0.3s 0.2s, visibility 0s 0s;
}
.main-menu-wrapper.native .menu .menu-close-btn {
  display: none;
}
.main-menu-wrapper.native .menu .menu-header {
  background: none;
}
.main-menu-wrapper.native .menu .menu-list {
  padding: 15px 30px 25px;
  box-sizing: border-box;
}
.main-menu-wrapper.native .menu .menu-search {
  overflow: hidden;
  display: flex;
}
.main-menu-wrapper.native .menu .menu-search > button {
  padding: 0 34px 0 8px;
  background: transparent;
  border-radius: 0;
  color: var(--sidemenu-text-color);
  border: 1px solid var(--sidemenu-text-color);
  height: 40px;
}
.main-menu-wrapper.native .menu .menu-search > button > .icon-container {
  right: 10px;
  left: auto;
  top: 0;
  line-height: 40px;
  font-size: 22px;
}
.main-menu-wrapper.native .menu .gradient {
  content: "";
  position: absolute;
  z-index: 999;
  left: 25px;
  right: 25px;
  height: 60px;
  opacity: 0;
  box-sizing: border-box;
  pointer-events: none;
  transition: opacity 0.3s;
}
.main-menu-wrapper.native .menu .gradient.top-gradient {
  top: 15px;
}
.main-menu-wrapper.native .menu .gradient.bottom-gradient {
  bottom: 25px;
}
.main-menu-wrapper.native .menu .gradient.active {
  opacity: 1;
}
@media only screen and (min-width: 1025px) {
  .main-menu-wrapper.native .menu .gradient {
    display: none;
  }
}
@media only screen and (max-width: 1024px) {
  .main-menu-wrapper.native .menu .menu-social-footer {
    bottom: 15px;
  }
}
.main-menu-wrapper.native .menu .menu-social-footer a {
  background: transparent;
}
.menu {
  max-width: var(--sidemenu-width);
  background-color: var(--sidemenu-background-color);
  overflow: hidden;
  transition: transform 0.2s ease-in-out, max-width 0.2s ease-in-out;
}
@media only screen and (max-width: 1024px) {
  .menu {
    z-index: 351;
    will-change: transition;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 85%;
    transform: translate3d(-105%, 0, 0);
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  }
}
@media only screen and (min-width: 1025px) {
  .menu {
    position: relative;
    flex: 1;
    border-right: 1px solid #ccc;
    z-index: 20;
    height: 100%;
  }
}
.menu.open {
  transform: translate3d(0, 0, 0);
}
.menu .menu-header {
  position: relative;
  width: 100%;
  height: 90px;
  overflow: hidden;
  background-color: var(--sidemenu-background-color);
  box-sizing: border-box;
  transform: translate3d(0, 0, 0);
}
@media only screen and (min-width: 1025px) {
  .menu .menu-header {
    height: 60px;
  }
}
.menu .menu-header .menu-close-btn {
  position: absolute;
  left: 12px;
  top: 50%;
  display: block;
  width: 22px;
  height: 22px;
  padding: 0;
  margin: 0;
  cursor: pointer;
  border: none;
  background: none;
  margin-top: -12px;
  z-index: 2;
  box-sizing: border-box;
}
@media only screen and (min-width: 1025px) {
  .menu .menu-header .menu-close-btn {
    display: none;
  }
}
.menu .menu-header .menu-close-btn:after,
.menu .menu-header .menu-close-btn:before {
  position: absolute;
  display: block;
  content: '';
  width: 28px;
  height: 1px;
  background-color: var(--sidemenu-text-color);
  transform: rotate(45deg);
  left: -2px;
  opacity: 0.3;
}
.menu .menu-header .menu-close-btn:after {
  transform: rotate(-45deg);
}
.menu .menu-header .logo {
  position: absolute;
  left: 0;
  right: 0;
  top: 10px;
  bottom: 10px;
  text-align: center;
  z-index: 1;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.menu .menu-header .logo img {
  display: block;
  margin: 0 auto;
  height: auto;
  max-height: 100%;
  width: auto;
  max-width: 140px;
}
.menu .menu-list {
  position: absolute;
  top: 90px;
  width: 100%;
  padding: 0;
  margin: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
}
@media only screen and (min-width: 1025px) {
  .menu .menu-list {
    top: 60px;
  }
}
.menu .menu-list.with-social {
  bottom: 50px;
}
.menu .menu-list.no-children .menu-list-root li a rl-badge {
  right: 10px;
}
.menu .menu-list .menu-search {
  overflow: hidden;
  display: flex;
}
.menu .menu-list .menu-search > button {
  flex: 1;
  display: block;
  align-items: center;
  border-radius: 15px;
  background-color: #ccc;
  color: black;
  text-decoration: none;
  margin: 8px;
  padding: 0 10px 0 34px;
  height: 30px;
  line-height: 30px;
  cursor: pointer;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  border: none;
  text-align: left;
}
.menu .menu-list .menu-search > button > .icon-container {
  position: absolute;
  height: 30px;
  left: 12px;
  top: 0;
  line-height: 30px;
}
.menu .menu-list .menu-search > button > .icon-container > i {
  position: relative;
  top: 1px;
}
.menu .menu-list .menu-list-root-wrap {
  flex: 1;
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
  overflow-y: auto;
  overscroll-behavior: none;
}
.menu .menu-list .menu-list-root {
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
}
.menu .menu-list .menu-list-root li {
  display: block;
  list-style: none;
  box-sizing: border-box;
}
.menu .menu-list .menu-list-root li.btn-download-wrapper {
  padding: 15px 10px;
}
.menu .menu-list .menu-list-root li.open button .open-list i {
  transform: rotate(90deg);
}
.menu .menu-list .menu-list-root li.current button,
.menu .menu-list .menu-list-root li.current a {
  color: var(--sidemenu-current-text-color);
}
.menu .menu-list .menu-list-root li ul:not(.menu-list-root) {
  display: block;
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
  max-height: 0;
  transition: all 0.2s ease-in-out;
  overflow: hidden;
  border-bottom: none;
}
.menu .menu-list .menu-list-root li ul:not(.menu-list-root) li button {
  font-size: 13px;
  height: 34px;
  line-height: 34px;
  border-bottom: none;
  border-top: none;
  justify-content: left;
  align-items: center;
}
.menu .menu-list .menu-list-root li ul:not(.menu-list-root) li button .name {
  height: 34px;
  line-height: 34px;
}
.menu .menu-list .menu-list-root li ul:not(.menu-list-root) li button .name:before {
  display: inline-block;
  content: '-';
  margin-right: 7px;
}
.menu .menu-list .menu-list-root li ul:not(.menu-list-root) li a span:before {
  display: inline-block;
  content: '-';
  margin-right: 7px;
}
.menu .menu-list .menu-list-root li button,
.menu .menu-list .menu-list-root li a {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 40px;
  padding: 0 10px;
  margin: 0;
  overflow: hidden;
  box-sizing: border-box;
  line-height: 40px;
  font-size: 15px;
  color: var(--sidemenu-text-color);
  text-decoration: none;
  cursor: pointer;
  background: transparent;
  text-align: left;
}
.menu .menu-list .menu-list-root li button.btn-download,
.menu .menu-list .menu-list-root li a.btn-download {
  background: var(--highlight-color);
  color: var(--text-on-highlight-color);
  text-transform: uppercase;
  height: 40px;
  line-height: 40px;
  text-align: center;
  padding: 0 10px;
  display: block;
  border: none;
  width: 100%;
}
.menu .menu-list .menu-list-root li button .left,
.menu .menu-list .menu-list-root li a .left {
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: calc(100% - 10px);
  box-sizing: border-box;
}
.menu .menu-list .menu-list-root li button rl-badge,
.menu .menu-list .menu-list-root li a rl-badge {
  margin-left: 10px;
}
.menu .menu-list .menu-list-root li button .name,
.menu .menu-list .menu-list-root li a .name {
  position: relative;
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  box-sizing: border-box;
}
.menu .menu-list .menu-list-root li button .open-list,
.menu .menu-list .menu-list-root li a .open-list {
  display: block;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 12px;
  margin-left: 10px;
}
.menu .menu-list .menu-list-root li button .open-list i,
.menu .menu-list .menu-list-root li a .open-list i {
  transition: transform 0.2s ease-in-out;
}
.menu .menu-social-footer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  display: block;
  width: 100%;
  height: 50px;
  margin: 0;
  padding: 0;
  line-height: 50px;
  text-align: center;
  transform: translate3d(0, 0, 0);
}
.menu .menu-social-footer a {
  display: inline-block;
  position: relative;
  top: -2px;
  height: 36px;
  width: 36px;
  padding: 7px;
  font-size: 18px;
  line-height: 22px;
  text-decoration: none;
  text-align: center;
  border-radius: 50%;
  box-sizing: border-box;
  vertical-align: top;
  color: var(--sidemenu-text-color);
}
@media (max-width: 400px) {
  .menu .menu-social-footer a {
    padding-left: 0;
    padding-right: 0;
    width: 30px;
    height: 36px;
  }
}
.menu .menu-social-footer a span {
  position: relative;
  top: 1px;
}
.menu .menu-social-footer a > span[class*="fa-"] {
  font-size: 19px;
}
.menu .menu-social-footer a img {
  max-width: 100%;
  max-height: 100%;
  display: inline-block;
}
.menu .top-gradient {
  background-image: linear-gradient(to top, transparent, var(--sidemenu-background-color));
}
.menu .bottom-gradient {
  background-image: linear-gradient(to bottom, transparent, var(--sidemenu-background-color));
}
.error-page {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.25);
}
.error-page h1 {
  display: block;
  margin: 0;
  padding: 0;
  font-size: 22px;
  font-weight: 600;
  text-align: center;
  position: absolute;
  height: 55px;
  line-height: 55px;
  left: 0;
  right: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.75);
  color: #fff;
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
  transform: translate3d(0, 0, 0);
}
.error-page .button-container {
  display: block;
  position: absolute;
  bottom: 30px;
  left: 0;
  right: 0;
  text-align: center;
}
.error-page .button-container .btn {
  width: 100%;
  max-width: 260px;
}
.tab .no-content {
  position: relative;
  top: 0;
  margin: 100px 0;
}
.chkSwitch input[type=checkbox] {
  opacity: 0;
  position: absolute;
  left: -9999px;
}
.chkSwitch input[type=checkbox] + label {
  display: inline-block;
  position: relative;
  height: 26px;
  width: 50px;
  border-radius: 13px;
  margin: 0;
  background: rgba(var(--general-text-color-rgb), .2);
  transition: background 0.2s ease-in-out, opacity 0.2s ease-in-out;
}
.chkSwitch input[type=checkbox] + label:after {
  height: 22px;
  width: 22px;
  border-radius: 50%;
  display: block;
  background: #fff;
  position: absolute;
  z-index: 1;
  left: 2px;
  top: 2px;
  content: '';
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
}
.chkSwitch input[type=checkbox]:checked + label {
  background: var(--confirmation-color);
  opacity: 1;
}
.chkSwitch input[type=checkbox]:checked + label:after {
  -moz-transform: translate3d(24px, 0, 0);
  -ms-transform: translate3d(24px, 0, 0);
  -o-transform: translate3d(24px, 0, 0);
  -webkit-transform: translate3d(24px, 0, 0);
  transform: translate3d(24px, 0, 0);
}
table {
  width: 100%;
  border-spacing: 0;
}
table th,
table td {
  padding: 0;
}
table th.right-align,
table td.right-align {
  text-align: right !important;
}
table.simple th,
table.simple td {
  padding: 0 3px;
}
table.simple th.text-center,
table.simple td.text-center {
  text-align: center!important;
}
table.simple thead th {
  text-transform: uppercase;
  text-align: left;
  line-height: 30px;
}
table.simple tbody td {
  font-size: 0.9em;
  text-align: left;
  height: 45px;
  line-height: 45px;
  position: relative;
  padding: 0;
  word-break: break-word;
}
table.simple tbody td:after {
  display: table;
  clear: both;
  content: '';
}
table.simple tbody td.image {
  padding-right: 5px;
  width: 30px;
}
table.simple tbody td.image .profile-image {
  width: 30px;
  height: 30px;
  vertical-align: middle;
}
table.simple tbody td .name,
table.simple tbody td .title {
  display: block;
  vertical-align: middle;
  overflow: hidden;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  min-height: 18px;
  line-height: 18px;
  padding: 0;
  margin: 0;
}
table.simple tbody td .name {
  top: 5px;
}
table.simple tbody td .name.only {
  height: auto;
  line-height: normal;
  top: 0;
}
table.simple tbody td .title {
  font-size: 0.8em;
  opacity: 0.85;
}
table.simple tbody td .round-image {
  height: 30px;
  width: 30px;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 50%;
  display: block;
  position: relative;
}
table.lines thead th,
table.lines tbody th,
table.lines thead td,
table.lines tbody td {
  border-bottom: 1px solid #d3d7dc;
}
table.padded tbody tr th:first-child,
table.padded thead tr th:first-child,
table.padded tbody tr td:first-child,
table.padded thead tr td:first-child {
  padding-left: 10px;
}
table.padded tbody tr th:last-child,
table.padded thead tr th:last-child,
table.padded tbody tr td:last-child,
table.padded thead tr td:last-child {
  padding-right: 10px;
}
.accordion {
  display: block;
  list-style: none;
  padding: 0;
  margin: 0;
}
.accordion > li {
  display: block;
  padding: 0;
  margin: 0;
  width: 100%;
}
.accordion > li:first-child > .accordion-header {
  border-top: none;
}
.accordion > li:last-child {
  border-bottom-width: 1px;
}
.accordion > li > .accordion-header {
  display: block;
  height: 42px;
  border-top: 1px solid #000;
  border-color: #e0e0e0;
  box-sizing: border-box;
  padding: 0;
  position: relative;
  overflow: hidden;
  /*&:after {
                display: block;
                position: absolute;
                right: 10px;
                font-family: @icon-fa;
                content: "\f055"; // fa-circle-plus
                z-index: 1;
                top: 0;
                height: 35px;
                line-height: 35px;
                opacity: 0.3;
                font-size: 17px;
                -moz-transition: all .3s ease-in-out;
                -o-transition: all .3s ease-in-out;
                -webkit-transition: all .3s ease-in-out;
                transition: all .3s ease-in-out;
            }*/
}
.accordion > li > .accordion-header .accordion-title {
  font-weight: 600;
  width: 75%;
  text-align: left;
  padding-left: 13px;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.accordion > li > .accordion-header .accordion-toggle {
  width: 25%;
  text-align: right;
  padding-right: 13px;
}
.accordion > li > .accordion-header .accordion-toggle:before {
  display: inline-block;
  font-family: 'Font Awesome\ 6 Pro';
  content: '\f078';
  font-size: 14px;
  line-height: 42px;
  position: relative;
  transition: transform ease-in-out 0.2s;
}
.accordion > li > .accordion-header span {
  display: block;
  line-height: 42px;
  height: 42px;
  margin: 0;
  float: left;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
}
.accordion > li > .accordion-header:after {
  display: table;
  clear: both;
  content: '';
}
.accordion > li > .accordion-content {
  display: block;
  max-height: 0;
  overflow: hidden;
  width: 100%;
  opacity: 0;
  transition: opacity ease-in-out 0.2s;
}
.accordion > li > .accordion-content > .accordion-inner {
  padding: 5px 10px;
}
.accordion > li.active > .accordion-header {
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.accordion > li.active > .accordion-header .accordion-toggle:before {
  transform: rotate(180deg);
}
.accordion > li.active > .accordion-content {
  max-height: none;
  opacity: 1;
}
.text-right {
  text-align: right !important;
}
.scrolling {
  overflow-x: hidden !important;
  overflow-y: auto !important;
  -webkit-overflow-scrolling: touch !important;
}
.btn {
  display: inline-block;
  line-height: 40px;
  height: 40px;
  padding: 0 10px;
  text-align: center;
  background: var(--button-background-color);
  color: var(--button-text-color);
  text-transform: uppercase;
  border: none;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.btn.outline {
  background: transparent;
  border: 1px solid var(--button-background-color);
  color: var(--button-background-color);
}
.btn.btn-secondary {
  color: #000;
  background: #000;
}
.btn.btn-underline {
  background: none;
  color: var(--button-background-color);
  text-decoration: underline;
}
.btn.btn-big {
  display: block;
  width: 100%;
  height: 50px;
  line-height: 50px;
}
.btn.btn-warn {
  background: #e9806e;
  color: #fff;
}
.btn.btn-danger,
.btn.btn-default {
  border-radius: 20px;
  padding: 0 30px;
  font-weight: 600;
  text-decoration: none;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  position: relative;
  overflow: hidden;
}
.btn.btn-danger[disabled],
.btn.btn-default[disabled] {
  background: #999;
  box-shadow: none;
}
.btn.btn-danger {
  background: #c62828;
  color: #fff;
}
.btn[disabled] {
  opacity: 0.9;
}
.btn .spinner {
  border-color: var(--button-text-color);
}
.btn-md {
  display: inline-block;
  line-height: 30px;
  height: 30px;
  padding: 0 15px;
  text-align: center;
  background: var(--button-background-color);
  color: var(--button-text-color);
  text-transform: uppercase;
  border: none;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  border-radius: 4px;
  position: relative;
}
.btn-md:not(.clear):not(.outline) {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}
.btn-md.outline {
  background: transparent;
  border: 1px solid var(--button-background-color);
  color: var(--button-text-color);
}
.btn-md.clear {
  background: transparent;
  color: #3779f9;
}
.btn-background {
  background-color: var(--button-background-color);
}
.btn-text-color {
  color: var(--button-text-color);
}
@media only screen and (min-width: 1025px) {
  button:not([disabled]),
  [role="button"]:not([disabled]),
  a:not([disabled]) {
    cursor: pointer;
  }
}
.popup-overlay {
  opacity: 0;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: rgba(100, 100, 100, 0.7);
  -moz-transition: opacity 0.3s ease-in-out;
  -o-transition: opacity 0.3s ease-in-out;
  -webkit-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;
  z-index: 200;
}
.popup-overlay.show {
  opacity: 1;
}
.loading-overlay {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: rgba(100, 100, 100, 0.7);
  transition: all ease-in-out 0.5s;
  z-index: 200;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.loading-overlay .spinner {
  width: 30px;
  height: 30px;
  border-color: rgba(255, 255, 255, 0.5);
}
.loading-overlay.ng-enter,
.loading-overlay.ng-leave.ng-leave-active {
  opacity: 0;
}
.loading-overlay.ng-leave,
.loading-overlay.ng-enter.ng-enter-active {
  opacity: 1;
}
.wysiwyg-result p {
  padding: 0;
  margin: 0 0 10px 0;
}
.wysiwyg-result p:last-child {
  margin-bottom: 0;
}
.wysiwyg-result img {
  width: auto;
  height: auto;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: block;
}
.wysiwyg-result .video-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  height: 0;
}
.wysiwyg-result .video-wrapper iframe,
.wysiwyg-result .video-wrapper video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.wysiwyg-result video {
  background: grey;
}
.wysiwyg-result ol {
  margin-block-start: 1em;
  margin-block-end: 1em;
}
.wysiwyg-result user-mention,
.wysiwyg-result department-mention,
.wysiwyg-result user-group-mention,
.wysiwyg-result hash-tag {
  color: var(--highlight-color);
  font-weight: bold;
}
.wysiwyg-result table {
  border: none;
  border-collapse: collapse;
  empty-cells: show;
  max-width: 100%;
}
.wysiwyg-result table td,
.wysiwyg-result table th {
  border: 1px solid var(--general-border-color);
}
@media only screen and (max-width: 1024px) {
  .desktop-show,
  .desktop-show-inline-block,
  .desktop-show-flex {
    display: none!important;
  }
}
@media only screen and (min-width: 1025px) {
  .desktop-hide {
    display: none!important;
  }
  .desktop-show {
    display: block!important;
  }
  .desktop-show-inline-block {
    display: inline-block !important;
  }
  .desktop-show-flex {
    display: flex!important;
  }
}
reaction-icon {
  display: inline-block;
}
reaction-icon svg {
  width: 100%;
  height: 100%;
}
.reaction-label[data-type="1"] {
  color: var(--highlight-color);
}
.reaction-label[data-type="5"] {
  color: #5fa3f1;
}
.reaction-label[data-type="2"],
.reaction-label[data-type="6"] {
  color: #eb6262;
}
.reaction-label[data-type="3"],
.reaction-label[data-type="4"] {
  color: #ffcc4d;
}
.card-footer {
  padding: 0 10px;
}
@media only screen and (min-width: 1025px) {
  .card-footer {
    padding: 0 15px;
  }
}
.card-footer .card-footer-actions {
  display: flex;
  flex-direction: column;
}
.card-footer .card-footer-actions .card-footer-actions-icons,
.card-footer .card-footer-actions .card-footer-actions-texts {
  min-height: 46px;
  max-height: 46px;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
.card-footer .card-footer-actions .card-footer-actions-icons button,
.card-footer .card-footer-actions .card-footer-actions-texts button {
  min-height: 46px;
  padding: 0 7.5px;
  box-sizing: border-box;
  color: var(--general-text-color);
}
.card-footer .card-footer-actions .card-footer-actions-icons content-post-total-swipes button,
.card-footer .card-footer-actions .card-footer-actions-texts content-post-total-swipes button,
.card-footer .card-footer-actions .card-footer-actions-icons content-total-views button,
.card-footer .card-footer-actions .card-footer-actions-texts content-total-views button {
  color: var(--general-text-color);
}
.card-footer .card-footer-actions .card-footer-actions-icons {
  justify-content: space-around;
  text-align: left;
  border-top: 1px solid #EBECEE;
  position: relative;
}
.card-footer .card-footer-actions .card-footer-actions-icons .reaction-message {
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 0;
  left: 0;
  right: 0;
  background: var(--general-background-color);
  z-index: 7;
  user-select: none;
}
@media only screen and (min-width: 1025px) {
  .card-footer .card-footer-actions .card-footer-actions-icons .reaction-message {
    display: none;
  }
}
.card-footer .card-footer-actions .card-footer-actions-icons button {
  line-height: 21px;
  width: 33.33%;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-size: 13px;
  text-transform: uppercase;
}
@media only screen and (min-width: 1025px) {
  .card-footer .card-footer-actions .card-footer-actions-icons button {
    width: auto;
    padding: 0 15px;
  }
}
.card-footer .card-footer-actions .card-footer-actions-icons rl-icon {
  font-size: 17px;
  display: inline-flex;
}
.card-footer .card-footer-actions .card-footer-actions-icons reaction-button {
  width: 33.33%;
  text-align: center;
}
@media only screen and (max-width: 1024px) {
  .card-footer .card-footer-actions .card-footer-actions-icons reaction-button .btn-reaction {
    position: static;
  }
  .card-footer .card-footer-actions .card-footer-actions-icons reaction-button .reactions-list {
    left: 50%;
    transform: translateX(-50%);
  }
}
@media only screen and (min-width: 1025px) {
  .card-footer .card-footer-actions .card-footer-actions-icons reaction-button {
    width: auto;
  }
}
.card-footer .card-footer-actions .card-footer-actions-icons reaction-button button {
  width: 100%;
  color: var(--general-text-color);
  user-select: none;
}
.card-footer .card-footer-actions .card-footer-actions-texts {
  justify-content: space-around;
  text-align: right;
  align-items: normal;
}
.card-footer .card-footer-actions .card-footer-actions-texts > button {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 13px;
}
.card-footer .card-footer-actions .card-footer-actions-texts content-post-total-swipes button,
.card-footer .card-footer-actions .card-footer-actions-texts content-total-views button {
  display: flex;
  align-items: center;
  gap: 5px;
  height: 100%;
  width: 100%;
  font-size: 13px;
}
.card-footer .card-footer-actions .card-footer-actions-texts rl-icon {
  font-size: 17px;
}
.profile-navigation-overlay {
  position: fixed;
  left: 0;
  bottom: 0;
  top: 0;
  right: 0;
  z-index: 360;
  background: var(--popup-overlay-bg);
  opacity: 0;
  display: none;
}
.profile-navigation-overlay.open {
  display: block;
  opacity: 1;
}
.profile-navigation-overlay.animate {
  transition: all 0.2s linear;
}
.profile-navigation {
  position: fixed;
  width: 80%;
  max-width: 300px;
  right: 0;
  top: 0;
  bottom: 0;
  background: #f8f8f8;
  z-index: 361;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
  overflow-x: hidden;
  overflow-y: auto;
}
.profile-navigation.open {
  -webkit-transform: translateX(0%);
  transform: translateX(0%);
}
.profile-navigation.animate {
  transition: -webkit-transform 0.2s linear;
  transition: transform 0.2s linear;
  transition: transform 0.2s linear, -webkit-transform 0.2s linear;
}
.profile-navigation header {
  display: flex;
  align-items: center;
}
.profile-navigation header .close {
  margin-right: 10px;
}
.profile-navigation .profile-navigation-top {
  position: relative;
  width: 100%;
  height: 70px;
  overflow: hidden;
}
.profile-navigation .profile-navigation-top .image {
  display: block;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
  background-position: center center;
  background-size: cover;
  position: absolute;
  top: 10px;
  left: 10px;
}
.profile-navigation .profile-navigation-top .info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  left: 70px;
  top: 10px;
  height: 50px;
  right: 5px;
}
.profile-navigation .profile-navigation-top .info h2 {
  display: block;
  margin: 0;
  padding: 0;
  font-size: 17px;
  font-weight: 700;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.profile-navigation .profile-navigation-top .info .dept {
  display: block;
  margin: 0;
  padding: 0;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.profile-navigation .profile-navigation-points {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 100%;
  font-size: 13px;
  text-align: center;
  border: none;
  border-top: 1px solid #e9e9e9;
  background: transparent;
}
.profile-navigation .profile-navigation-points .coin {
  display: inline-block;
  position: relative;
  top: -1px;
  height: 14px;
  width: 14px;
  margin-right: 5px;
  background: url('/Content/Graphics/Coin.png') no-repeat center center;
  background-size: cover;
}
.profile-navigation .profile-navigation-points span {
  display: inline-block;
  line-height: 1;
}
.profile-navigation .profile-navigation-points .bold {
  font-weight: 600;
}
.profile-navigation .profile-navigation-inner > ul {
  list-style: none;
  padding: 0;
  margin: 0 0 20px 0;
  border-bottom: 1px solid #e9e9e9;
  border-top: 1px solid #e9e9e9;
  background: #fff;
}
.profile-navigation .profile-navigation-inner > ul > li > button {
  position: relative;
  display: block;
  width: 100%;
  height: 50px;
  box-sizing: border-box;
  padding: 0 0 0 50px;
  overflow: hidden;
  font-size: 15px;
  line-height: 50px;
  text-align: left;
  background: transparent;
  border: none;
}
.profile-navigation .profile-navigation-inner > ul > li > button .icon-container {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  height: 50px;
  line-height: 50px;
  text-align: center;
  font-size: 20px;
  width: 50px;
}
.profile-navigation .profile-navigation-inner > ul > li > button .icon-chat {
  font-size: 16px;
}
.profile-navigation .profile-navigation-inner > ul > li > button rl-badge {
  position: absolute;
  right: 10px;
  top: 15px;
}
.profile-navigation .profile-navigation-inner > ul > li > button.multi-department {
  line-height: 20px;
}
.profile-navigation .profile-navigation-inner > ul > li > button.multi-department > span p {
  margin: 0;
  padding: 0;
  position: relative;
  height: 20px;
  top: 10px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.profile-navigation .profile-navigation-inner > ul > li > button.multi-department > span i {
  display: block;
  font-size: 11px;
  opacity: 0.7;
  position: relative;
  top: 5px;
}
.profile-navigation .profile-navigation-inner > ul > li:last-child button:before {
  display: none;
}
[read-more] {
  position: relative;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
[read-more] + .readmore {
  background: none;
  border: none;
  width: 100%;
  position: relative;
  display: block;
  text-align: center;
  height: 30px;
  line-height: 30px;
  color: var(--general-text-color);
  font-weight: 600;
  text-transform: uppercase;
  font-size: 12px;
  margin: 20px 0 10px 0;
  padding-top: 0;
  border-top: 1px solid transparent;
  transition: all 0.3s ease-in-out;
  opacity: 0.5;
}
[read-more] + .readmore:after {
  display: block;
  width: 20px;
  height: 20px;
  line-height: 20px;
  font-family: 'Font Awesome\ 6 Pro';
  content: '\f0ab';
  /*fa-circle-arrow-down*/
  position: absolute;
  bottom: -10px;
  color: #000;
  left: 50%;
  margin-left: -10px;
  text-align: center;
}
[read-more] + .readmore.open {
  border-top: 1px solid #000;
  padding-top: 10px;
}
[read-more] + .readmore.open:after {
  content: '\f0aa';
}
[read-more] + .readmore.simple {
  border-top: none !important;
  padding-top: 0 !important;
}
[read-more] + .readmore.simple:after {
  display: none !important;
}
@media only screen and (max-width: 767px) {
  .native tabset {
    padding-bottom: 50px;
    padding-bottom: calc(50px + env(safe-area-inset-bottom, 0px));
  }
}
tabset {
  position: relative;
  display: block;
}
tabset.tabset-scroll {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}
tabset.tabset-scroll .tabset-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}
tabset.tabset-scroll .tabset-container .tabset-content {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1 auto;
  flex: 1 auto;
  display: block;
  overflow: hidden;
}
tabset.tabset-scroll .tabset-container .tabset-content .tab {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  overflow-x: hidden;
  overflow-y: auto;
}
tabset.tabset-scroll .tab-header {
  -webkit-box-flex: 0;
  -ms-flex: 0 auto;
  flex: 0 auto;
}
tabset.tabset-slider > .tabset-container > .tab-header > li {
  width: 100%;
  display: none;
}
tabset.tabset-slider > .tabset-container > .tab-header > li.active {
  display: block;
}
tabset.tabset-slider > .tabset-container > .active-tab-border {
  width: 100%;
}
tabset.tabset-slider .tab-header {
  overflow: hidden;
  max-height: 44px;
}
tabset.tabset-slider .tab-header .icon-arrow {
  position: absolute;
  line-height: 44px;
  top: 0;
  font-size: 26px;
  color: #000;
  z-index: 2;
  padding: 0 5px;
  cursor: pointer;
}
tabset.tabset-slider .tab-header .fa-angle-right {
  right: 5px;
}
tabset.tabset-slider .tab-header .fa-angle-left {
  left: 5px;
}
tabset.tabset-slider .active-tab-border {
  display: none;
}
tabset .tab-header {
  display: block;
  padding: 0;
  margin: 0;
  list-style: none;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
tabset .tab-header:after {
  display: table;
  clear: both;
  content: '';
}
tabset .tab-header > li {
  height: 44px;
  display: block;
  float: left;
  margin: 0;
  padding: 0;
  overflow: hidden;
}
tabset .tab-header > li button {
  line-height: 44px;
  text-align: center;
  padding: 0;
  margin: 0;
  display: block;
  width: 100%;
  -moz-transition: background 0.1s ease-in-out;
  -o-transition: background 0.1s ease-in-out;
  -webkit-transition: background 0.1s ease-in-out;
  transition: background 0.1s ease-in-out;
  text-transform: uppercase;
  text-decoration: none;
  color: #000;
  background: white;
  font-size: 15px;
  border-radius: 0;
  position: relative;
  overflow: hidden;
  border: none;
}
tabset .tab-header > li button .ripple-effect {
  background-color: #999;
}
tabset .tab-header > li button i {
  font-size: 22px;
  position: relative;
  top: 3px;
}
tabset .tab-header > li button .tab-header-image {
  height: 24px;
  width: 24px;
  display: inline-block;
  vertical-align: middle;
  background-size: contain !important;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
  top: -2px;
}
tabset:not(.force-header) > .tab-count-1 > .tab-header {
  display: none;
}
tabset:not(.force-header) > .tab-count-1 > .active-tab-border {
  display: none;
}
tabset > .tab-count-2 > .tab-header > li {
  width: 50%;
}
tabset > .tab-count-2 > .active-tab-border {
  width: 50%;
}
tabset > .tab-count-3 > .tab-header > li {
  width: 33.33333%;
}
tabset > .tab-count-3 > .active-tab-border {
  width: 33.33333%;
}
tabset > .tab-count-4 > .tab-header > li {
  width: 25%;
}
tabset > .tab-count-4 > .active-tab-border {
  width: 25%;
}
tabset > .tab-count-5 > .tab-header > li {
  width: 20%;
}
tabset > .tab-count-5 > .active-tab-border {
  width: 20%;
}
tabset > .tab-count-6 > .tab-header > li {
  width: 16.66666%;
}
tabset > .tab-count-6 > .active-tab-border {
  width: 16.66666%;
}
tabset > .tab-count-7 > .tab-header > li {
  width: 14.2%;
}
tabset > .tab-count-7 > .active-tab-border {
  width: 14.2%;
}
tabset > .tab-count-8 > .tab-header > li {
  width: 12.5%;
}
tabset > .tab-count-8 > .active-tab-border {
  width: 12.5%;
}
tabset .active-tab-border {
  position: absolute;
  display: block;
  top: 41px;
  height: 3px;
  z-index: 1;
  left: 0;
  background: black;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
tabset tab {
  display: block;
}
tabset .tab {
  display: block;
  padding-top: 3px;
}
tabset.force-header > .tab-count-1 > .tab-header > li {
  float: none;
  text-align: center;
}
tabset.force-header > .tab-count-1 > .active-tab-border {
  width: 100%;
}
/* BOXED */
/* selector is used to avoid using BOXED layout on nested tabsets */
tabset.boxed > [role=tabpanel] > .tab-header {
  height: 50px;
  padding: 10px 20px;
  box-shadow: none;
}
tabset.boxed > [role=tabpanel] > .tab-header > li {
  height: 30px;
  line-height: 30px;
  border: 1px solid #3c4858;
  border-left-width: 0;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
}
tabset.boxed > [role=tabpanel] > .tab-header > li button {
  height: 28px;
  line-height: 28px;
  color: #3c4858;
  position: relative;
  overflow: hidden;
  width: 100%;
  margin: 0;
  box-sizing: border-box;
  background: none;
}
tabset.boxed > [role=tabpanel] > .tab-header > li:first-child {
  border-left-width: 1px;
}
tabset.boxed > [role=tabpanel] > .tab-header > li.active {
  background: #3c4858;
}
tabset.boxed > [role=tabpanel] > .tab-header > li.active button {
  color: #fff;
}
tabset.boxed > [role=tabpanel] > .tab-header > li .ripple-effect {
  background-color: #eee;
}
tabset.boxed > [role=tabpanel] > .active-tab-border {
  display: none;
}
tabset.boxed > .tab-count-1 > .tab-header > li {
  float: none;
  border-radius: 3px;
}
/* FILL */
tabset.fill {
  /* BOXED */
}
tabset.fill.boxed > [role=tabpanel] > .tab-header {
  background-color: #303030;
}
tabset.fill.boxed > [role=tabpanel] > .tab-header > li {
  border-color: #fff;
}
tabset.fill.boxed > [role=tabpanel] > .tab-header > li button {
  color: #fff;
}
tabset.fill.boxed > [role=tabpanel] > .tab-header > li.active {
  background-color: #fff;
}
tabset.fill.boxed > [role=tabpanel] > .tab-header > li.active button {
  color: #303030;
}
tabset.fill:not(.boxed) > [role=tabpanel] > .tab-header {
  background-color: #303030;
}
tabset.fill:not(.boxed) > [role=tabpanel] > .tab-header > li button {
  color: #fff;
}
tabset.fill:not(.boxed) > [role=tabpanel] > .tab-header > li.active button {
  background-color: var(--general-background-color);
  color: #000;
}
tabset.fill:not(.boxed) > [role=tabpanel] > .active-tab-border {
  display: none;
}
tabset.fill:not(.boxed) > [role=tabpanel].tab-count-1 > .tab-header > li.active button {
  background-color: #303030;
  color: #fff;
}
.view-page {
  min-height: 100%;
  width: 100%;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  transition: top ease-in-out 0.4s;
}
@media only screen and (min-width: 1025px) {
  .view-page {
    top: 0;
    max-width: var(--max-width);
    margin: 0 auto;
  }
}
.view-page.has-news-ticker {
  top: calc(var(--top-header-height) + 40px);
}
.view-page-container {
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  transition: top ease-in-out 0.4s;
}
.view-page-container .view-page {
  top: 0;
}
@media only screen and (min-width: 1025px) {
  .view-page-container {
    top: 0;
  }
}
.view-page-container.has-news-ticker {
  top: calc(var(--top-header-height) + 40px);
}
carousel {
  display: block;
  position: relative;
}
.owl-carousel {
  height: 400px;
  text-align: center;
}
.owl-carousel .owl-stage-outer {
  z-index: 1;
  position: relative;
  height: 100%;
}
.owl-carousel .owl-item {
  z-index: 1;
}
.owl-carousel .owl-item .media .image {
  height: 400px;
  line-height: 400px;
}
.owl-carousel .owl-item .media .image > img {
  display: inline-block;
  vertical-align: middle;
}
.owl-carousel .owl-item .media .video {
  width: 100%;
  max-width: 100%;
  height: 400px;
  position: relative;
  padding-top: 35%;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.owl-carousel .owl-item .media .video > .video-wrapper {
  position: relative;
  overflow: hidden;
}
.owl-carousel .owl-item .media .video > .video-wrapper iframe,
.owl-carousel .owl-item .media .video > .video-wrapper object,
.owl-carousel .owl-item .media .video > .video-wrapper embed,
.owl-carousel .owl-item .media .video > .video-wrapper video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.owl-carousel .owl-controls {
  position: relative;
  z-index: 9;
  margin: 0 auto;
  display: inline-block;
  top: -30px;
}
.media-viewer carousel {
  position: static;
}
.media-viewer .owl-carousel {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100%;
}
.media-viewer .owl-carousel .owl-stage {
  height: 100%;
}
.media-viewer .owl-carousel .owl-stage .owl-item {
  height: 100%;
}
.media-viewer .owl-carousel .owl-stage media {
  height: 100%;
}
.media-viewer .owl-carousel .owl-stage media .media {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.media-viewer .owl-carousel .owl-stage media .media .image {
  height: auto;
  line-height: normal;
}
.list {
  border-top: 1px solid #d3d7dc;
}
.list .list-group {
  position: relative;
  width: 100%;
  margin: 0;
  padding: 0;
  border-bottom: 1px solid #d3d7dc;
  border-spacing: 0;
  border-collapse: separate;
}
.list .list-group thead tr th {
  line-height: 30px;
  margin: 0;
  min-height: 30px;
  font-size: 13px;
  border-bottom: 1px solid #d3d7dc;
  background: #eff1f3;
  text-align: left;
  text-transform: uppercase;
}
.list .list-group thead tr th:last-child {
  padding-right: 15px;
  text-align: right;
}
.list .list-group thead tr th:first-child {
  padding-left: 15px;
  text-align: left;
}
.list .list-group tbody tr {
  padding: 0 10px;
}
.list .list-group tbody tr td {
  padding: 8px 0;
  position: relative;
}
.list .list-group tbody tr td:after {
  position: absolute;
  height: 0;
  bottom: 0;
  display: block;
  border-bottom: 1px solid #d3d7dc;
  content: '';
  left: 0;
  right: 0;
}
.list .list-group tbody tr td.image {
  width: 30px;
  padding-right: 10px;
}
.list .list-group tbody tr td.image .profile-image {
  height: 30px;
  width: 30px;
}
.list .list-group tbody tr td.faded,
.list .list-group tbody tr td .faded {
  color: #b7bdc5;
  font-size: 0.95em;
}
.list .list-group tbody tr td.small-faded,
.list .list-group tbody tr td .small-faded {
  color: #b7bdc5;
  font-size: 0.85em;
}
.list .list-group tbody tr td.smaller,
.list .list-group tbody tr td .smaller {
  font-size: 0.95em;
}
.list .list-group tbody tr td.list-next {
  width: 20px;
  text-align: right;
}
.list .list-group tbody tr td.list-next:before {
  display: inline-block;
  font-family: 'Material Design Icons';
  content: "\F142";
  font-size: 18px;
}
.list .list-group tbody tr td:last-child {
  text-align: right;
  padding-right: 15px;
}
.list .list-group tbody tr td:last-child:after {
  right: 15px;
}
.list .list-group tbody tr td:first-child {
  padding-left: 15px;
  text-align: left;
}
.list .list-group tbody tr td:first-child:after {
  left: 15px;
}
.list .list-group tbody tr:last-child td:after {
  border-bottom: none;
}
profile-image {
  display: inline-block;
  padding-right: 5px;
  position: relative;
}
profile-image .leave-icon {
  border-radius: 50%;
  width: 16px;
  height: 16px;
  position: absolute;
  font-size: 16px;
  bottom: 3px;
  right: 3px;
  z-index: 1;
  backdrop-filter: blur(13px);
  color: var(--general-text-inactive-color);
}
.profile-image {
  display: inline-block;
  min-height: 10px;
  min-width: 10px;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 50%;
}
.profile-image.inactive {
  opacity: 0.6;
  position: relative;
}
.highscore-levels-enabled .profile-image.has-highscore-level {
  box-sizing: border-box;
  border-width: 2px;
  border-style: solid;
}
.profile-image.blocked::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.5);
  pointer-events: none;
  z-index: 0;
}
.profile-image.blocked .blocked-icon {
  width: 0.7rem;
  height: 0.7rem;
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: var(--warning-color);
  border-radius: 50%;
  color: var(--white-color);
  display: grid;
  place-content: center;
  z-index: 1;
  font-size: 0.4rem;
}
modal:last-child .modal-overlay {
  z-index: 411;
}
modal:last-child .modal {
  z-index: 412;
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 410;
  background: rgba(0, 0, 0, 0.5);
  display: block;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  opacity: 0;
}
.modal-overlay.show {
  opacity: 1;
}
.modal {
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  display: block;
  z-index: 411;
}
.modal .modal-content {
  opacity: 0;
  -moz-transform: translate3d(0, 20%, 0);
  -ms-transform: translate3d(0, 20%, 0);
  -o-transform: translate3d(0, 20%, 0);
  -webkit-transform: translate3d(0, 20%, 0);
  transform: translate3d(0, 20%, 0);
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  background: #fff;
  border-radius: 0;
  overflow: hidden;
  max-width: 500px;
  margin: 0 auto;
  max-height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
}
@media only screen and (max-width: 767px) {
  .modal .modal-content {
    top: 0;
    bottom: 0;
  }
}
@media only screen and (min-width: 1025px) {
  .modal .modal-content {
    -moz-transform: translate3d(0, 10%, 0);
    -ms-transform: translate3d(0, 10%, 0);
    -o-transform: translate3d(0, 10%, 0);
    -webkit-transform: translate3d(0, 10%, 0);
  }
}
.modal .modal-content .modal-top {
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  align-items: center;
  height: 55px;
  padding: 0;
  font-weight: 600;
}
.modal .modal-content .modal-top .modal-title {
  display: block;
  font-size: 18px;
  text-transform: uppercase;
  margin: 0;
  padding: 0;
  text-overflow: clip;
  overflow: hidden;
}
.modal .modal-content .modal-top .right-buttons {
  min-width: 45px;
}
.modal .modal-content .empty-list {
  display: block;
  padding: 20px 0;
}
.modal .modal-content .empty-list .mdi {
  display: block;
  text-align: center;
  font-size: 70px;
}
.modal .modal-content .empty-list p {
  display: block;
  margin: 10px 0 0 0;
  padding: 0 20px;
  text-align: center;
  font-size: 18px;
}
.modal .modal-content .modal-loader {
  display: block;
  text-align: center;
  padding: 10px 0;
}
.modal .modal-close {
  display: block;
  text-align: center;
  color: inherit;
  cursor: pointer;
  font-size: 26px;
  overflow: hidden;
  border-radius: 100%;
  border: 0;
  padding: 0;
  background: none;
  min-width: 45px;
}
.modal .modal-body {
  position: relative;
  margin: 0;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  overflow-x: hidden !important;
  overflow-y: auto !important;
}
.modal .modal-body.padding {
  padding: 5px 10px;
}
.modal .modal-bottom {
  display: block;
  position: relative;
  min-height: 40px;
  transform: translate3d(0, 0, 0);
  -webkit-box-flex: 0;
  flex: 0 0 auto;
  text-align: right;
}
.modal .modal-bottom button {
  display: inline-block;
  font-size: 14px;
  position: relative;
  overflow: hidden;
  height: 38px;
  line-height: 38px;
  padding: 0 15px;
  text-align: center;
  color: #508AE4;
  font-weight: 600;
  text-transform: uppercase;
  background: none;
  border: none;
}
.modal.show .modal-content {
  -moz-transform: translate3d(0, -50%, 0);
  -ms-transform: translate3d(0, -50%, 0);
  -o-transform: translate3d(0, -50%, 0);
  -webkit-transform: translate3d(0, -50%, 0);
  transform: translate3d(0, -50%, 0);
  opacity: 1;
}
@media only screen and (max-width: 767px) {
  .modal.show .modal-content {
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.modal .modal-select-picker ul {
  display: block;
  padding: 0;
  margin: 0;
  list-style: none;
}
.modal .modal-select-picker ul > li {
  display: block;
  line-height: 36px;
  position: relative;
  overflow: hidden;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding-left: 10px;
}
.modal .modal-select-picker ul > li:last-child:after {
  display: none;
}
.modal .modal-select-picker ul > li i {
  position: absolute;
  top: 0;
  right: 10px;
  height: 36px;
  line-height: 36px;
  font-size: 24px;
}
.modal .modal-select-picker ul > li:after {
  display: block;
  height: 1px;
  background: rgba(0, 0, 0, 0.1);
  position: absolute;
  left: 10px;
  right: 10px;
  bottom: 0;
  content: '';
}
.popup-overlay > .popup {
  height: 350px;
  width: 90%;
  position: absolute;
  top: 50%;
  left: 5%;
  right: 5%;
  margin-top: -175px;
  background: #fff;
  border-radius: 5px;
}
.popup-overlay > .popup .popup-content-wrapper {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.popup-overlay > .popup .popup-content-wrapper .popup-content {
  display: block;
  text-align: center;
  vertical-align: middle;
  width: 100%;
}
.popup-overlay > .popup .popup-content-wrapper .popup-content i {
  font-size: 100px;
  margin-bottom: 5px;
}
.popup-overlay > .popup .popup-content-wrapper .popup-content h3 {
  font-size: 18px;
  margin: 0;
  padding: 0;
}
.popup-overlay > .popup .popup-content-wrapper .popup-content p {
  display: block;
  margin: 0 auto;
  max-width: 180px;
  width: 100%;
  padding: 0;
}
.popup-overlay > .popup .popup-content-wrapper .popup-content.primary-color i {
  color: #029be5;
}
.popup-overlay > .popup .popup-close-btn {
  position: absolute;
  left: 5px;
  top: 5px;
  padding: 0;
  height: 40px;
  width: 40px;
  line-height: 40px;
  text-align: center;
  background: transparent;
  border: none;
}
.popup-overlay > .popup .popup-close-btn:before {
  display: inline-block;
  font-family: 'Material Design Icons';
  content: "\F156";
  font-size: 38px;
}
.contact-info {
  display: inline-block;
  font-size: 1em;
}
bar-chart {
  display: block;
}
.metric {
  min-height: 80px;
  position: relative;
  overflow: hidden;
}
.metric .metric-top {
  display: block;
}
.metric .metric-top h4,
.metric .metric-top span {
  display: block;
  width: 50%;
  line-height: 24px;
  font-size: 0.9em;
  font-weight: 400;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  float: left;
  margin: 0;
  padding: 0;
}
.metric .metric-top span {
  float: right;
  text-align: right;
  opacity: 0.5;
}
.metric .metric-top:after {
  display: table;
  clear: both;
  content: '';
}
.metric .metric-content {
  text-align: center;
  margin-top: 5px;
}
.metric .metric-content.show-target.on-target .metric-value {
  color: #2e7d32;
}
.metric .metric-content.show-target .metric-value {
  color: #c62828;
}
.metric .metric-content .metric-value {
  font-size: 40px;
  vertical-align: top;
}
.metric .metric-content .metric-development {
  display: inline-block;
  position: relative;
  height: 26px;
  width: 5px;
  border-radius: 5px;
  background: #59d68e;
  vertical-align: top;
  margin-left: 15px;
  top: 13px;
}
.metric .metric-content .metric-development.negative {
  background: #e9806e;
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
.metric .metric-content .metric-development.negative:before,
.metric .metric-content .metric-development.negative:after {
  background: #e9806e;
}
.metric .metric-content .metric-development:before,
.metric .metric-content .metric-development:after {
  display: block;
  height: 15px;
  width: 5px;
  border-radius: 5px;
  position: absolute;
  background: #59d68e;
  content: '';
  top: -2px;
}
.metric .metric-content .metric-development:before {
  right: 4px;
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.metric .metric-content .metric-development:after {
  left: 4px;
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.metric .metric-chart-label {
  display: block;
  text-align: center;
  font-size: 0.85em;
  text-transform: uppercase;
  position: relative;
  top: -3px;
}
.metric .metric-loader {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  margin-top: -11px;
  text-align: center;
}
.metric.metric-label .metric-content {
  text-align: center;
}
.metric.metric-label .metric-content .metric-value {
  font-size: 15px;
}
.size-1 .metric {
  min-height: 40px;
}
.size-1 .metric .metric-top {
  display: none;
}
.size-1 .metric .metric-content {
  margin-top: 0;
}
.size-1 .metric .metric-content .metric-value {
  font-size: 18px;
}
.size-1 .metric .metric-chart-label {
  font-size: 12px;
}
.size-1 .metric.metric-label .metric-content .metric-value {
  font-size: 20px;
}
.size-2 .metric {
  min-height: 40px;
}
.size-2 .metric .metric-top {
  display: none;
}
.size-2 .metric .metric-content {
  margin-top: 0;
  text-align: center;
}
.size-2 .metric .metric-content .metric-value {
  font-size: 18px;
}
.size-2 .metric .metric-chart-label {
  font-size: 12px;
}
.size-2 .metric.metric-label .metric-content {
  text-align: center;
}
.size-2 .metric .metric-development {
  top: 0px;
}
/* ----------- iPad 3 and 4 ----------- */
/* Portrait and Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
  .size-1 .metric .metric-content .metric-value {
    font-size: 32px;
  }
  .size-2 .metric .metric-content .metric-value {
    font-size: 32px;
  }
}
.line-chart {
  position: relative;
}
.line-chart > canvas {
  width: 100%;
  height: 160px;
}
.line-chart .line-chart-label {
  display: block;
  font-size: 0.85em;
  margin-top: -7px;
}
.line-chart .line-chart-loader {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  margin-top: -11px;
  text-align: center;
}
.line-chart .line-chart-missing {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  margin-top: -11px;
  text-align: center;
  opacity: 0.5;
}
.line-chart .line-chart-missing i {
  font-size: 26px;
  display: block;
  margin-bottom: 5px;
}
.line-chart .line-chart-missing span {
  display: block;
}
.chart-header h1,
.chart-header h2,
.chart-header h3,
.chart-header h4,
.chart-header h5,
.chart-header h6 {
  margin: 0 0 5px 0;
  padding: 0;
  font-size: 1em;
  font-weight: 400;
}
.chart-header h1 .chart-help-bubble,
.chart-header h2 .chart-help-bubble,
.chart-header h3 .chart-help-bubble,
.chart-header h4 .chart-help-bubble,
.chart-header h5 .chart-help-bubble,
.chart-header h6 .chart-help-bubble {
  display: inline-block;
  height: 12px;
  width: 12px;
  line-height: 12px;
  text-align: center;
  border-radius: 100%;
  border: 1px solid #000;
  position: relative;
  top: -2px;
  left: 5px;
  font-size: 8px;
}
.chart-header h1 .chart-help-bubble > i,
.chart-header h2 .chart-help-bubble > i,
.chart-header h3 .chart-help-bubble > i,
.chart-header h4 .chart-help-bubble > i,
.chart-header h5 .chart-help-bubble > i,
.chart-header h6 .chart-help-bubble > i {
  font-size: 8px;
}
.chart-link {
  text-align: center;
}
.chart-text div {
  margin: 0 0 8px 0;
  padding: 0;
  font-size: 0.8em;
  font-weight: 400;
}
.chart-datelastupdated {
  font-size: 0.7em;
  color: #aaaa97;
}
.chart-file {
  text-align: center;
}
.chart-file a.btn-md {
  text-transform: none;
  text-decoration: none;
}
.table-chart {
  font-size: 0.9em;
}
.table-chart th {
  border-bottom: 1px solid #999;
  font-weight: 500;
  padding: 3px 10px 3px 0px;
  text-align: center;
}
.table-chart th:not(:first-child) {
  border-left: 1px solid #eee;
}
.table-chart td {
  padding-top: 3px;
  padding-bottom: 3px;
  border-top: 1px solid #eee;
}
.table-chart td:not(:first-child) {
  padding-right: 10px;
  border-left: 1px solid #eee;
}
.gauge-chart .gauge-container {
  height: 200px;
  width: 100%;
  margin-top: -10px;
}
.gauge-chart .highcharts-axis-title {
  font-size: 16px;
}
.gauge-chart .gauge-center {
  text-align: center;
}
.gauge-chart .gauge-center .gauge-value {
  font-size: 25px;
  font-weight: bold;
  color: #000;
}
.gauge-chart .gauge-center .gauge-label {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.5);
}
.infinite-scroll-loader {
  text-align: center;
}
.infinite-scroll-loader > a {
  margin: 15px 0 5px 0;
}
.media-upload-progress {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  display: none;
  z-index: -1;
}
.media-upload-progress.show {
  display: block;
  z-index: 1000;
}
.media-upload-progress ul {
  display: block;
  padding: 10px 0;
  margin: 0;
}
.media-upload-progress ul > li {
  display: block;
  position: relative;
  padding: 0 10px;
  margin-bottom: 4px;
}
.media-upload-progress ul > li .filename {
  display: block;
  font-size: 12px;
  width: 100%;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding-right: 50px;
  overflow: hidden;
  -ms-text-overflow: clip;
  -o-text-overflow: clip;
  text-overflow: clip;
  text-align: left;
}
.media-upload-progress ul > li .progress {
  position: absolute;
  display: block;
  font-size: 12px;
  right: 10px;
  top: 0;
  text-align: right;
}
.media-upload-progress ul > li .progress-bar {
  display: block;
  background: #fff;
  height: 2px;
  width: 0;
  margin-top: 2px;
}
.add-to-home {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-position: center center;
  background-size: cover;
  z-index: 1100;
}
.add-to-home .add-to-home-background {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-position: center center;
  background-size: cover;
  z-index: 1;
}
.add-to-home .add-to-home-overlay {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 3;
  background: rgba(0, 0, 0, 0.85);
}
.add-to-home .add-to-home-overlay button {
  color: #fff;
  position: absolute;
  display: block;
  right: 15px;
  top: 10px;
  text-decoration: underline;
  opacity: 0.6;
  border: none;
  background: none;
}
.add-to-home .add-to-home-overlay .add-to-home-arrow {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 20px;
  text-align: center;
}
.add-to-home .add-to-home-overlay .add-to-home-arrow img {
  height: 40px;
}
.add-to-home .add-to-home-overlay .add-to-home-content {
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  margin-top: -150px;
  height: 300px;
  color: #fff;
  text-align: center;
}
.add-to-home .add-to-home-overlay .add-to-home-content .favicon {
  display: block;
  margin: 0 auto;
  width: 80px;
  height: 80px;
  background-position: center center;
  background-size: contain;
  margin-bottom: 15px;
}
.add-to-home .add-to-home-overlay .add-to-home-content .add-to-home-split {
  display: block;
  width: 140px;
  height: 1px;
  background: #fff;
  margin: 25px auto;
  opacity: 0.5;
}
.add-to-home .add-to-home-overlay .add-to-home-content h1 {
  font-size: 19px;
  margin: 0;
}
.add-to-home .add-to-home-overlay .add-to-home-content h2 {
  font-size: 15px;
  margin: 0 auto;
  max-width: 250px;
}
.add-to-home .add-to-home-overlay .add-to-home-content img {
  height: 18px;
  display: inline-block;
  margin: 0 5px;
}
html[dir="rtl"] .walkthrough-control-buttons button {
  transform: scale(-1);
}
.walkthrough-control-buttons {
  position: absolute;
  z-index: 5;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  border: none;
  box-sizing: border-box;
}
@media only screen and (min-width: 1025px) {
  .walkthrough-control-buttons {
    top: 50%;
    bottom: auto;
    margin-top: -30px;
    justify-content: center;
  }
}
.walkthrough-control-buttons button {
  padding: 10px;
  text-transform: uppercase;
  background: none;
  border: none;
  color: white;
  font-weight: bold;
}
@media only screen and (min-width: 1025px) {
  .walkthrough-control-buttons button {
    font-size: 32px;
    margin: 0 30vh;
  }
}
.walkthrough-control-buttons button[disabled] {
  color: #CCCCCC;
}
.walkthrough {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 401;
  box-sizing: border-box;
}
.walkthrough .swiper-container {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.walkthrough .swiper-pagination {
  bottom: 0;
  left: 0;
  right: 0;
  height: 36px;
  line-height: 36px;
}
.walkthrough .swiper-pagination > .swiper-pagination-bullet:not(.swiper-pagination-bullet-active) {
  background: #fff;
  opacity: 0.3;
}
.walkthrough .swiper-pagination.swiper-pagination-progress {
  height: 6px;
}
.walkthrough .walkthrough-loading-container {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 2;
  transition-duration: 0.3s;
}
.walkthrough .walkthrough-loading-container .walkthrough-slide {
  background: rgba(255, 255, 255, 0.7);
}
.walkthrough-overlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 400;
  background: rgba(0, 0, 0, 0.6);
}
walkthrough,
.walkthrough,
.walkthrough-overlay,
.walkthrough-loading-container {
  transition: all ease-in-out 0.5s;
}
walkthrough.closing,
.walkthrough.closing,
.walkthrough-overlay.closing,
.walkthrough-loading-container.closing,
walkthrough.ng-enter,
.walkthrough.ng-enter,
.walkthrough-overlay.ng-enter,
.walkthrough-loading-container.ng-enter,
walkthrough.ng-leave.ng-leave-active,
.walkthrough.ng-leave.ng-leave-active,
.walkthrough-overlay.ng-leave.ng-leave-active,
.walkthrough-loading-container.ng-leave.ng-leave-active {
  opacity: 0;
}
walkthrough.ng-leave,
.walkthrough.ng-leave,
.walkthrough-overlay.ng-leave,
.walkthrough-loading-container.ng-leave,
walkthrough.ng-enter.ng-enter-active,
.walkthrough.ng-enter.ng-enter-active,
.walkthrough-overlay.ng-enter.ng-enter-active,
.walkthrough-loading-container.ng-enter.ng-enter-active {
  opacity: 1;
}
.walkthrough-slide {
  position: absolute;
  top: 10px;
  left: 10px;
  right: 10px;
  bottom: 36px;
  background: #fff;
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
}
@media only screen and (min-width: 1025px) {
  .walkthrough-slide {
    top: 40px;
    width: 51vh;
    height: 90vh;
    margin: 0 auto;
  }
}
.walkthrough-slide:has(.walkthrough-slide-policy) {
  background-color: var(--general-background-color);
}
.walkthrough.progress .walkthrough-slide {
  bottom: 10px;
  top: 16px;
}
.walkthrough-slide-info {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 15px;
  z-index: 3;
}
.walkthrough-slide-info.last {
  bottom: 60px;
}
.walkthrough-slide-info .slide-full-width {
  width: 100%;
  overflow-y: auto;
  overscroll-behavior: none;
}
.walkthrough-slide-info .slide-title,
.walkthrough-slide-info .slide-body,
.walkthrough-slide-info .slide-media {
  margin: 0;
  margin-top: 15px;
}
.walkthrough-slide-info .slide-title:first-child,
.walkthrough-slide-info .slide-body:first-child,
.walkthrough-slide-info .slide-media:first-child {
  margin-top: 0;
}
.walkthrough-slide-info .slide-title {
  text-align: center;
  font-size: 22px;
  padding: 0;
  margin-top: 25px;
}
.walkthrough-slide-info .slide-body {
  text-align: center;
  padding: 0;
  font-size: 17px;
}
.walkthrough-slide-info .slide-media {
  width: 100%;
}
.walkthrough-slide-info .slide-media .media .image {
  height: 200px;
}
.walkthrough-slide-info-continue-container {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 60px;
  line-height: 60px;
  text-align: center;
  z-index: 4;
}
.walkthrough-slide-info-continue-container .btn {
  font-size: 20px;
}
.walkthrough-slide-info-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  overflow: hidden;
  background-position: center center;
  background-size: cover;
}
.walkthrough-slide-info-background.watermark:after {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  background: rgba(255, 255, 255, 0.75);
  content: '';
}
.walkthrough-slide-signup {
  padding: 0 20px;
  text-align: center;
  position: relative;
  z-index: 2;
}
.walkthrough-slide-signup h2 {
  font-size: 23px;
  margin: 0 0 15px 0;
}
.walkthrough-slide-signup form {
  display: block;
  margin: 30px auto 0 auto;
}
.walkthrough-slide-signup form label {
  display: block;
  font-weight: 600;
  width: 100%;
}
.walkthrough-slide-signup form input[type=text] {
  display: block;
  width: 90%;
  margin: 8px auto 0 auto;
  height: 32px;
  line-height: 32px;
  border: 1px solid rgba(0, 0, 0, 0.24);
  border-radius: 3px;
  box-sizing: border-box;
  padding: 0 10px;
  text-align: center;
}
.walkthrough-slide-signup form .error-message {
  font-size: 13px;
  color: #c62828;
  margin: 8px 0 5px 0;
  font-weight: 600;
}
.walkthrough-slide-signup .continue-container {
  margin-top: 15px;
  text-align: center;
}
.walkthrough-slide-signup-watermark {
  position: absolute;
  top: auto;
  bottom: 0;
  left: auto;
  right: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1;
  opacity: 0.12;
  overflow: hidden;
}
.walkthrough-slide-signup-watermark i {
  font-size: 300px;
  transform: rotate3d(0, 0, 1, -40deg);
  position: relative;
  bottom: -50px;
  right: -50px;
}
.walkthrough-slide-policy .policy {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 2;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  -webkit-transition: opacity ease-in-out 0.3s;
  transition: opacity ease-in-out 0.3s;
}
.walkthrough-slide-policy .policy.ng-enter,
.walkthrough-slide-policy .policy.ng-leave.ng-leave-active {
  opacity: 0;
}
.walkthrough-slide-policy .policy.ng-leave,
.walkthrough-slide-policy .policy.ng-enter.ng-enter-active {
  opacity: 1;
}
.walkthrough-slide-policy policy-view {
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.15) transparent;
}
.walkthrough-slide-policy policy-view.extra-bottom-margin .policy-view {
  margin-bottom: 70px;
}
.walkthrough-slide-policy policy-view .policy-view-container {
  max-height: unset;
}
.walkthrough-slide-policy .policy-view-bottom {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: var(--spacing) 0;
  margin: 0 var(--spacing);
  display: flex;
  gap: var(--spacing);
  width: calc(100% - (2 * var(--spacing)));
  box-sizing: border-box;
  background-color: var(--general-background-color);
  z-index: 5;
}
.walkthrough-slide-policy .policy-view-bottom .shadowed-btn {
  flex-grow: 1;
  box-shadow: 0 -20px 15px 5px var(--general-background-color);
}
.walkthrough-slide-policy .policy-view-bottom button {
  font-size: 1rem;
  line-height: 1rem;
  text-transform: uppercase;
  min-width: unset;
  width: 100%;
  word-break: break-word;
}
.walkthrough-slide-policy .policy-view-bottom .agree-btn button {
  background-color: var(--confirmation-color);
  color: var(--white-color);
}
.walkthrough-slide-policy .policy-view-bottom .disagree-btn button {
  background-color: var(--warning-color);
  color: var(--white-color);
}
.walkthrough-slide-policy tip {
  z-index: 100;
}
.walkthrough-slide-policy tip .tip-content {
  bottom: 45px;
  max-width: calc(50% - var(--spacing));
  width: unset;
  padding: var(--m-spacing);
  border-radius: var(--general-border-radius);
  box-shadow: 0px 7px 15px 0px var(--shadow-color);
  background-color: var(--general-text-color);
  color: var(--general-background-color);
}
.walkthrough-slide-policy tip .tip-content:after {
  bottom: -6px;
  border-color: var(--general-text-color) transparent transparent;
  border-width: 7px 7.5px 0 7.5px;
}
.walkthrough-slide-appaccess {
  padding: 0 20px;
  text-align: center;
  position: relative;
  z-index: 2;
}
.walkthrough-slide-appaccess h2 {
  font-size: 23px;
  margin: 0 0 15px 0;
}
.walkthrough-slide-appaccess form {
  display: block;
  margin: 30px auto 0 auto;
}
.walkthrough-slide-appaccess form label {
  display: block;
  font-weight: 600;
  width: 100%;
}
.walkthrough-slide-appaccess form input[type=text] {
  display: block;
  width: 90%;
  margin: 8px auto 0 auto;
  height: 32px;
  line-height: 32px;
  border: 1px solid rgba(0, 0, 0, 0.24);
  border-radius: 3px;
  box-sizing: border-box;
  padding: 0 10px;
  text-align: center;
}
.walkthrough-slide-appaccess form .error-message {
  font-size: 13px;
  color: #c62828;
  margin: 8px 0 5px 0;
  font-weight: 600;
}
.walkthrough-slide-appaccess .continue-container {
  margin-top: 15px;
  text-align: center;
}
.walkthrough-slide-appaccess-watermark {
  position: absolute;
  top: auto;
  bottom: 0;
  left: auto;
  right: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1;
  opacity: 0.12;
  overflow: hidden;
}
.walkthrough-slide-appaccess-watermark i {
  font-size: 300px;
  transform: rotate3d(0, 0, 1, -40deg);
  position: relative;
  bottom: -50px;
  right: -50px;
}
.walkthrough-slide-nativedownload {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  overflow-y: auto;
  overflow-x: hidden;
}
.walkthrough-slide-nativedownload .slide-full-width {
  width: 100%;
}
.walkthrough-slide-nativedownload .slide-title,
.walkthrough-slide-nativedownload .slide-body,
.walkthrough-slide-nativedownload .slide-media {
  margin: 15px 0 0 0;
}
.walkthrough-slide-nativedownload .slide-title:first-child,
.walkthrough-slide-nativedownload .slide-body:first-child,
.walkthrough-slide-nativedownload .slide-media:first-child {
  margin-top: 0;
}
.walkthrough-slide-nativedownload .slide-title,
.walkthrough-slide-nativedownload .slide-body {
  padding-left: 15px;
  padding-right: 15px;
  text-align: left;
}
.walkthrough-slide-nativedownload .slide-title:first-child,
.walkthrough-slide-nativedownload .slide-body:first-child {
  padding-top: 15px;
}
.walkthrough-slide-nativedownload .slide-title {
  font-size: 22px;
}
.walkthrough-slide-nativedownload .slide-body {
  font-size: 17px;
}
.walkthrough-slide-nativedownload .slide-media {
  width: 100%;
}
.walkthrough-slide-nativedownload .native-download-buttons {
  padding: 25px 15px 25px 15px;
  display: block;
  box-sizing: border-box;
}
.walkthrough-slide-nativedownload .native-download-buttons .btn,
.walkthrough-slide-nativedownload .native-download-buttons a {
  display: block;
  cursor: pointer;
  border: 1px solid var(--button-background-color);
  height: 40px;
  line-height: 40px;
  box-sizing: border-box;
  border-radius: 0;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
}
.walkthrough-slide-nativedownload .native-download-buttons .btn:hover,
.walkthrough-slide-nativedownload .native-download-buttons a:hover {
  opacity: 0.8;
}
.walkthrough-slide-nativedownload .native-download-buttons .btn {
  background: var(--button-background-color);
  color: var(--button-text-color);
}
.walkthrough-slide-nativedownload .native-download-buttons .btn i {
  font-size: 20px;
  margin-right: 10px;
  position: relative;
  top: 1px;
}
.walkthrough-slide-nativedownload .native-download-buttons a {
  background: none;
  color: var(--button-background-color);
}
.walkthrough-slide-nativedownload .native-download-buttons .or-divider {
  display: block;
  text-transform: uppercase;
  text-align: center;
  line-height: 40px;
  width: 100%;
}
.walkthrough-slide-nativedownload-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  overflow: hidden;
  background-position: center center;
  background-size: cover;
}
.landing-page-tile-loading {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.landing-page-tile-loading .spinner {
  width: 30px;
  height: 30px;
}
.landing-page-highscore .lph-content {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 8px;
  right: 8px;
}
.landing-page-highscore .lph-top {
  flex: 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  position: relative;
}
.landing-page-highscore .lph-top .lph-image {
  width: 45px;
  height: 45px;
  display: inline-block;
  vertical-align: middle;
  border-radius: 100%;
  background-position: center;
}
.landing-page-highscore .lph-top .lph-name {
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 55px;
  right: 0;
  bottom: 0;
}
.landing-page-highscore .lph-top .lph-name > h2 {
  margin: 0 0 2px 0;
  padding: 0;
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
}
.landing-page-highscore .lph-top .lph-name > p {
  padding: 0;
  margin: 0;
  font-size: 12px;
  text-transform: uppercase;
}
.landing-page-highscore .lph-bottom {
  height: 30px;
  line-height: 30px;
  border-top: 1px solid rgba(0, 0, 0, 0.16);
  flex: 0 1 auto;
  width: 100%;
  font-size: 13px;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
}
.landing-page-social .title-wrapper {
  display: flex;
}
.landing-page-social .title-wrapper rl-badge {
  margin-left: 10px;
}
.landing-page-social .no-posts {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
}
.landing-page-social .no-posts h3 {
  font-size: 16px;
  font-weight: normal;
  max-width: 200px;
  text-align: center;
  margin: 0 0 15px;
}
.landing-page-social .no-posts button {
  text-transform: uppercase;
  border: 2px solid var(--button-background-color);
  padding: 8px 10px;
  margin-top: 15px;
}
.landing-page-social .social-list {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  position: absolute;
  left: 10px;
  top: 10px;
  right: 10px;
  bottom: 10px;
}
.landing-page-social .social-list.list-view {
  justify-content: flex-start;
}
.landing-page-social .social-list.list-view .head {
  display: flex;
  padding: 5px 0;
  flex-shrink: 0;
  justify-content: space-between;
}
.landing-page-social .social-list.list-view .head .social-slide-read-more {
  cursor: pointer;
}
.landing-page-social .social-list.list-view .head h4 {
  margin: 10px 0;
  font-size: 22px;
}
.landing-page-social .social-list.list-view .social-list-items {
  overflow-y: auto;
}
.landing-page-social .social-list.list-view .social-list-item-wrapper {
  cursor: pointer;
  padding: 10px;
  margin-bottom: 10px;
}
.landing-page-social .social-list.list-view .social-list-item {
  max-height: 130px;
  overflow: hidden;
}
.landing-page-social .social-list.list-view .social-list-item h4 {
  margin: 0 0 10px;
}
.landing-page-social .social-list.list-view .social-slide-head {
  display: flex;
}
.landing-page-social .social-list.list-view .social-slide-content {
  padding: 10px;
  margin-top: 5px;
  background-color: #f0f0f0;
  border-radius: 10px;
  position: relative;
}
.landing-page-social .social-list.list-view .social-slide-content:after {
  content: " ";
  display: block;
  width: 0;
  border-style: solid;
  position: absolute;
  top: -12px;
  left: 25px;
  bottom: auto;
  border-width: 13px 0 0 13px;
  border-color: transparent #f0f0f0;
}
.landing-page-social .social-list.list-view .social-slide-info {
  margin-left: 5px;
}
.landing-page-social .social-list.list-view .social-slide-info h4 {
  margin: 0;
}
.landing-page-social .social-list.list-view .social-slide-info p {
  margin: 0;
  font-size: 12px;
}
.landing-page-social .social-list.list-view .profile-image {
  min-width: 34px;
  min-height: 34px;
}
.landing-page-social .social-list .headline {
  display: block;
  font-size: 16px;
  margin: 0 0 10px 0;
  padding: 0;
  -webkit-box-flex: 0;
  flex: 0 1 auto;
  text-transform: uppercase;
}
.landing-page-social .social-list ks-swiper-container {
  width: 100%;
  -webkit-box-flex: 1;
  flex: 1 auto;
  position: relative;
}
.landing-page-social .social-list ks-swiper-container .swiper-container {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.landing-page-social .social-list ks-swiper-container .swiper-button-prev,
.landing-page-social .social-list ks-swiper-container .swiper-button-next {
  opacity: 0.2;
}
.landing-page-social .social-list ks-swiper-container .swiper-button-prev.swiper-button-disabled,
.landing-page-social .social-list ks-swiper-container .swiper-button-next.swiper-button-disabled {
  display: none;
}
.landing-page-social .social-list ks-swiper-container .swiper-button-next {
  right: 0;
}
.landing-page-social .social-list ks-swiper-container .swiper-button-prev {
  left: 0;
}
.landing-page-social .social-list .social-slide .social-slide-top {
  display: flex;
  align-items: center;
}
.landing-page-social .social-list .social-slide .social-slide-top profile-image {
  padding-right: 10px;
}
.landing-page-social .social-list .social-slide .social-slide-top profile-image .profile-image {
  height: 30px;
  width: 30px;
  vertical-align: top;
}
.landing-page-social .social-list .social-slide .social-slide-top .social-slide-info h4 {
  margin: 0;
  padding: 0;
  font-size: 14px;
}
.landing-page-social .social-list .social-slide .social-slide-top .social-slide-info p {
  font-size: 12px;
  opacity: 0.7;
  margin: 0;
  padding: 0;
}
.landing-page-social .social-list .social-slide .social-slide-content {
  position: absolute;
  top: 40px;
  bottom: 30px;
  left: 0;
  right: 15px;
  overflow: hidden;
  font-size: 13px;
}
.landing-page-social .social-list .social-slide .social-slide-read-more {
  position: absolute;
  bottom: 0;
  left: 0;
  opacity: 0.8;
}
.landing-page-social .social-list .social-slide .social-slide-read-more a {
  cursor: pointer;
  text-transform: uppercase;
  font-size: 13px;
  height: 20px;
  line-height: 20px;
  border-bottom: 1px solid #000;
}
.landing-page-content .title-wrapper {
  display: flex;
}
.landing-page-content .title-wrapper rl-badge {
  margin-left: 10px;
}
.landing-page-content .no-posts {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  height: 100%;
  padding: 10px;
  text-align: center;
}
.landing-page-content .no-posts h3 {
  font-size: 16px;
  font-weight: normal;
  max-width: 200px;
  text-align: center;
  margin: 0 0 15px;
}
.landing-page-content .no-posts button {
  text-transform: uppercase;
  border: 2px solid var(--button-background-color);
  padding: 8px 10px;
  margin-top: 15px;
}
.landing-page-content .content-list {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  position: absolute;
  left: 10px;
  top: 10px;
  right: 10px;
  bottom: 10px;
}
.landing-page-content .content-list.list-view {
  justify-content: flex-start;
}
.landing-page-content .content-list.list-view .head {
  display: flex;
  padding: 5px 0;
  flex-shrink: 0;
  justify-content: space-between;
}
.landing-page-content .content-list.list-view .head h4 {
  margin: 10px 0;
  font-size: 22px;
}
.landing-page-content .content-list.list-view .content-slide-breadcrumb {
  display: flex;
  align-items: baseline;
  margin-bottom: 10px;
  font-size: 12px;
}
.landing-page-content .content-list.list-view .content-slide-breadcrumb .dot-separator {
  padding: 0 3px;
  color: grey;
}
.landing-page-content .content-list.list-view .content-list-items {
  overflow-y: auto;
}
.landing-page-content .content-list.list-view .content-list-item-wrapper {
  cursor: pointer;
  padding: 10px;
  margin-bottom: 10px;
  background: #f0f0f0;
}
.landing-page-content .content-list.list-view .content-list-item {
  max-height: 100px;
  overflow: hidden;
}
.landing-page-content .content-list.list-view .content-list-item h4 {
  margin: 0 0 10px;
}
.landing-page-content .content-list .categories {
  width: 100%;
  display: flex;
  overflow-x: auto;
  padding: 0 0 var(--spacing) 0;
  min-height: 50px;
}
.landing-page-content .content-list .categories button {
  display: flex;
  align-items: center;
  padding: var(--m-spacing);
  white-space: nowrap;
  background: var(--general-background-color);
  color: var(--general-text-color);
}
.landing-page-content .content-list .categories button:not(:last-of-type) {
  margin-right: var(--m-spacing);
}
.landing-page-content .content-list .categories button.selected {
  background: var(--highlight-color);
  color: var(--text-on-highlight-color);
}
.landing-page-content .content-list .categories button rl-badge {
  margin-left: var(--m-spacing);
}
.landing-page-content .content-list .headline {
  display: block;
  font-size: 16px;
  margin: 0 0 5px 0;
  padding: 0;
  -webkit-box-flex: 0;
  flex: 0 1 auto;
  text-transform: uppercase;
}
.landing-page-content .content-list ks-swiper-container {
  width: 100%;
  -webkit-box-flex: 1;
  flex: 1 auto;
  position: relative;
}
.landing-page-content .content-list ks-swiper-container .swiper-container {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.landing-page-content .content-list .content-label {
  font-weight: 700;
  padding: var(--s-spacing);
  border-radius: var(--general-border-radius);
  display: inline-flex;
  margin-bottom: var(--m-spacing);
}
.landing-page-content .content-list .content-label.unread {
  background-color: var(--highlight-color);
  color: var(--text-on-highlight-color);
}
.landing-page-content .content-list .content-slide .content-slide-wrapper {
  position: absolute;
  top: 0;
  bottom: 25px;
  overflow: hidden;
}
.landing-page-content .content-list .content-slide .content-slide-top {
  height: auto;
  overflow: hidden;
  position: relative;
}
.landing-page-content .content-list .content-slide .content-slide-top h4 {
  margin: 0;
  padding: 0;
  font-size: 14px;
}
.landing-page-content .content-list .content-slide .content-slide-top .content-slide-breadcrumb {
  display: block;
  line-height: 20px;
  text-transform: uppercase;
  font-size: 10px;
}
.landing-page-content .content-list .content-slide .content-slide-top .content-slide-breadcrumb > div {
  vertical-align: top;
  height: 20px;
  line-height: 20px;
  display: inline-block;
}
.landing-page-content .content-list .content-slide .content-slide-top .content-slide-breadcrumb > div span {
  color: #c2c2c2;
}
.landing-page-content .content-list .content-slide .content-slide-top .content-slide-breadcrumb > div p {
  padding: 0;
  margin: 0 10px 0 5px;
}
.landing-page-content .content-list .content-slide .content-slide-top .content-slide-breadcrumb > div i {
  color: #c2c2c2;
  font-size: 13px;
  position: relative;
  top: -1px;
}
.landing-page-content .content-list .content-slide .content-slide-top .content-slide-breadcrumb > div span,
.landing-page-content .content-list .content-slide .content-slide-top .content-slide-breadcrumb > div p,
.landing-page-content .content-list .content-slide .content-slide-top .content-slide-breadcrumb > div i {
  display: inline-block;
  vertical-align: top;
  height: 20px;
  line-height: 20px;
  font-weight: 400;
}
.landing-page-content .content-list .content-slide .content-slide-content {
  position: relative;
  top: 5px;
  padding-right: 15px;
  overflow: hidden;
  font-size: 13px;
  /*
                &:before {
                    content: '';
                    width: 100%;
                    height: 100%;    
                    position: absolute;
                    left: 0;
                    top: 0;
                    background: -webkit-linear-gradient(transparent 30px, white);
                    background: linear-gradient(transparent 30px, white);
                }*/
}
.landing-page-content .content-list .content-slide .content-slide-content p {
  margin: 0;
  padding: 0;
}
.landing-page-content .content-list .content-slide .content-slide-read-more {
  position: absolute;
  bottom: 0;
  left: 0;
  opacity: 0.8;
}
.landing-page-content .content-list .content-slide .content-slide-read-more button {
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
  text-transform: uppercase;
  font-size: 13px;
  height: 20px;
  line-height: 20px;
  border-bottom: 1px solid #000;
}
.landing-page.landing-page-design-creative .landing-page-chart .metric-chart {
  background: #fff;
  border-radius: 6px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  margin-bottom: 3px;
}
.landing-page.landing-page-design-creative .landing-page-chart .size-2 .metric-chart {
  padding: 6px 0 2px 0;
}
.landing-page.landing-page-design-creative .landing-page-chart .size-2 .metric-chart .metric-value {
  font-weight: 600;
}
.landing-page.landing-page-design-creative .landing-page-chart .size-2 .metric-chart .metric-chart-label {
  top: 0;
  font-size: 10px;
  font-weight: 600;
}
.landing-page.landing-page-design-creative .landing-page-chart .chart-header h4 {
  font-size: 14px;
  text-align: center;
  font-weight: 600;
}
.landing-page-chart .landing-page-tile-swiper-favorite {
  position: absolute;
  bottom: 0;
  left: 0;
  border: none;
  background: none;
  height: 26px;
  line-height: 26px;
  padding: 0;
  width: 28px;
  text-align: center;
  cursor: pointer;
  z-index: 11;
  opacity: 0.7;
  overflow: hidden;
}
.landing-page-chart .landing-page-tile-swiper-favorite:hover {
  opacity: 1;
}
.landing-page-chart ks-swiper-container {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.landing-page-chart ks-swiper-container .swiper-container {
  height: 100%;
}
.landing-page-chart ks-swiper-container .swiper-container .swiper-pagination {
  bottom: 3px;
  left: 30px;
  right: 30px;
  width: auto;
}
.landing-page-chart ks-swiper-container .swiper-container .swiper-pagination .swiper-pagination-bullet {
  background: none;
  border: 1px solid #000;
  box-sizing: border-box;
}
.landing-page-chart ks-swiper-container .swiper-container .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: #000;
  opacity: 0.4;
}
.landing-page-chart ks-swiper-container .swiper-container .landing-page-tile-swiper-slide {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.landing-page-chart .landing-page-chart-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.landing-page-chart .chart-list {
  display: block;
  width: 100%;
  list-style: none;
  padding: 15px 0;
  margin: 0;
}
.landing-page-chart .chart-list li {
  display: block;
  list-style: none;
  padding: 0;
  margin: 0;
}
.landing-page-chart .chart-list li > .chart-group-item:after {
  display: table;
  clear: both;
  content: '';
}
.landing-page-chart .chart-list li > .chart-group-item .chart-list-item {
  display: block;
  float: left;
  width: 100%;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin-bottom: 5px;
}
.landing-page-chart .chart-list li > .chart-group-item .chart-list-item.size-2 {
  width: 50%;
}
.landing-page-chart .chart-list li > .chart-group-item .chart-list-item.size-1 {
  width: 33.333333%;
}
.landing-page.landing-page-design-creative .landing-page-chart .chart-list-item .chart-item {
  position: relative;
  margin-left: 5px;
  margin-right: 5px;
}
.native .landing-page .spacer {
  display: block;
  height: 50px;
  height: calc(50px + env(safe-area-inset-bottom, 0px));
}
.native .landing-page-background-overlay,
.native .landing-page-background {
  bottom: 50px;
  bottom: calc(50px + env(safe-area-inset-bottom, 0px));
}
.native .scrollable-arrow {
  bottom: 70px;
  bottom: calc(70px + env(safe-area-inset-bottom, 0px));
}
@media only screen and (min-width: 1025px) {
  .native .scrollable-arrow {
    bottom: 20px;
  }
}
.scrollable-arrow {
  z-index: 10;
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  opacity: 1;
  transition: opacity 0.3s;
}
@media only screen and (min-width: 1025px) {
  .scrollable-arrow {
    left: calc(50% + 140px);
  }
}
@media only screen and (min-width: 1300px) {
  .scrollable-arrow {
    left: calc(50% + 180px);
  }
}
.scrollable-arrow.hide {
  opacity: 0;
}
@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(0);
  }
  55% {
    transform: translateY(-5px);
  }
  60% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(0);
  }
}
.scrollable-arrow .arrow {
  position: relative;
  margin: 0 auto;
  width: 30px;
  height: 3px;
  animation-name: bounce;
  animation-duration: 5s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}
.scrollable-arrow .arrow .one:before,
.scrollable-arrow .arrow .two:before,
.scrollable-arrow .arrow .one:after,
.scrollable-arrow .arrow .two:after {
  content: '';
  position: absolute;
  top: 0;
  height: 100%;
  width: 50%;
}
.scrollable-arrow .arrow .one:before,
.scrollable-arrow .arrow .two:before {
  left: 2px;
  transform: rotate(30deg);
}
.scrollable-arrow .arrow .one:after,
.scrollable-arrow .arrow .two:after {
  right: 2px;
  transform: rotate(-30deg);
}
.scrollable-arrow .arrow .one:before,
.scrollable-arrow .arrow .one:after {
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.3);
}
.scrollable-arrow .arrow .two:before,
.scrollable-arrow .arrow .two:after {
  background: white;
}
.landing-page-background-overlay {
  background: rgba(0, 0, 0, 0.4);
  position: fixed;
  left: 0;
  right: 0;
  top: 55px;
  bottom: 0;
  overflow-x: auto;
  overflow-y: hidden;
  box-sizing: border-box;
  z-index: 0;
}
@media only screen and (min-width: 1025px) {
  .landing-page-background-overlay {
    top: 60px;
    left: var(--sidemenu-width);
  }
}
.landing-page-background {
  position: fixed;
  left: 0;
  right: 0;
  top: 55px;
  bottom: 0;
  z-index: 0;
  background-color: #f2f2f2;
  background-position: center center;
}
@media only screen and (min-width: 1025px) {
  .landing-page-background {
    top: 60px;
    left: var(--sidemenu-width);
  }
}
.landing-page {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.landing-page .spacer {
  display: none;
  position: absolute;
  width: 100%;
  left: 0;
}
.landing-page-tile {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  z-index: 1;
  text-decoration: none;
}
.landing-page-tile .live-tile {
  height: 100%;
}
.landing-page-tile .landing-page-tile-background {
  position: absolute;
  left: 1px;
  top: 1px;
  right: 1px;
  bottom: 1px;
  overflow: hidden;
  background-size: cover;
  background-position: center center;
  z-index: 1;
}
.landing-page-tile .landing-page-tile-content {
  position: absolute;
  left: 1px;
  top: 1px;
  right: 1px;
  bottom: 1px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  overflow: hidden;
  z-index: 2;
  text-decoration: none;
}
.landing-page-tile .landing-page-tile-content:focus {
  outline: 2px solid -webkit-focus-ring-color;
  outline-offset: -2px;
}
.landing-page-tile .landing-page-tile-content.overlay {
  background: rgba(0, 0, 0, 0.2);
}
.landing-page-tile .landing-page-tile-content > .icon {
  display: block;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  color: #fff;
  font-size: 20px;
  margin: 0 auto 3px auto;
}
.landing-page-tile .landing-page-tile-content > .icon.icon-image {
  margin-bottom: 8px;
}
.landing-page-tile .landing-page-tile-content > .icon > i {
  vertical-align: middle;
}
.landing-page-tile .landing-page-tile-content > .icon > img {
  display: inline-block;
  height: auto;
  width: auto;
  max-height: 100%;
  max-width: 100%;
}
.landing-page-tile .landing-page-tile-content > h2,
.landing-page-tile .landing-page-tile-content > h3 {
  display: block;
  margin: 0;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  max-width: 100%;
  word-break: break-word;
  padding: 2px;
  text-align: center;
}
.landing-page-tile .landing-page-tile-content > h3 {
  font-size: 13px;
  padding: 0 8px 2px 8px;
  text-transform: none;
}
.landing-page-tile .landing-page-tile-content > rl-badge {
  position: absolute;
  top: 5px;
  right: 5px;
}
.landing-page-tile .landing-page-tile-content .landing-page-card {
  position: absolute;
  background: #fff;
  left: 6px;
  right: 6px;
  top: 6px;
  bottom: 6px;
  border-radius: 6px;
  padding: 12px;
  overflow: hidden;
}
.landing-page-tile ks-swiper-container .swiper-button-prev,
.landing-page-tile ks-swiper-container .swiper-button-next {
  opacity: 0.2;
  height: 32px;
  width: 30px;
  margin-top: -16px;
  background: none;
  font-size: 30px;
  color: #000;
}
.landing-page-tile ks-swiper-container .swiper-button-prev.swiper-button-disabled,
.landing-page-tile ks-swiper-container .swiper-button-next.swiper-button-disabled {
  display: none;
}
.landing-page-tile ks-swiper-container .swiper-button-prev:before,
.landing-page-tile ks-swiper-container .swiper-button-next:before {
  display: inline-block;
  font-family: 'Material Design Icons';
  vertical-align: top;
}
.landing-page-tile ks-swiper-container .swiper-button-prev:hover,
.landing-page-tile ks-swiper-container .swiper-button-next:hover {
  opacity: 1;
}
.landing-page-tile ks-swiper-container .swiper-button-next {
  right: -2px;
}
.landing-page-tile ks-swiper-container .swiper-button-next:before {
  content: "\F142";
}
.landing-page-tile ks-swiper-container .swiper-button-prev {
  left: -2px;
}
.landing-page-tile ks-swiper-container .swiper-button-prev:before {
  content: "\F141";
}
@media only screen and (min-width: 768px) {
  .landing-page-tile ks-swiper-container .swiper-button-prev,
  .landing-page-tile ks-swiper-container .swiper-button-next {
    font-size: 40px;
    height: 44px;
    width: 40px;
    margin-top: -22px;
  }
}
.landing-page.landing-page-design-creative .landing-page-tile .landing-page-tile-content .landing-page-card {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}
.landing-page.landing-page-design-basic .landing-page-tile {
  border: 1px solid rgba(255, 255, 255, 0.5);
}
.landing-page.landing-page-design-basic .landing-page-tile .landing-page-tile-background {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.news-ticker {
  height: 40px;
  line-height: 40px;
  background: var(--general-background-color);
  position: relative;
  z-index: 2;
  overflow: hidden;
  max-height: 5vh;
}
@media only screen and (max-width: 1024px) {
  .news-ticker {
    height: 5vh;
    line-height: 5vh;
  }
}
.news-ticker .news-ticker-holder {
  height: 40px;
  line-height: 40px;
  display: block;
  min-width: 100%;
  text-align: center;
  white-space: nowrap;
  padding-bottom: 20px;
}
@media only screen and (max-width: 1024px) {
  .news-ticker .news-ticker-holder {
    height: 5vh;
    line-height: 5vh;
  }
}
.news-ticker .news-ticker-holder .news-ticker-holder-inner {
  display: inline-block;
  vertical-align: top;
}
.news-ticker .news-ticker-holder .news-ticker-holder-inner .news-ticker-tick {
  display: inline-block;
  vertical-align: top;
  height: 40px;
  line-height: 40px;
  margin: 0 5px;
}
@media only screen and (max-width: 1024px) {
  .news-ticker .news-ticker-holder .news-ticker-holder-inner .news-ticker-tick {
    height: 5vh;
    line-height: 5vh;
  }
}
.news-ticker .news-ticker-holder:not(.scrollable) .news-ticker-holder-inner {
  text-align: center;
}
.news-ticker .news-ticker-holder.scrollable {
  overflow-x: scroll;
  overflow-y: hidden;
}
.news-ticker .news-ticker-holder.scrollable .news-ticker-holder-inner {
  padding-left: 100%;
  animation: newstick 1s linear infinite;
}
@-moz-keyframes newstick {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-100%, 0);
  }
}
@-webkit-keyframes newstick {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-100%, 0);
  }
}
@keyframes newstick {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-100%, 0);
  }
}
.help-popup-wrapper {
  position: absolute;
  top: 10px;
  left: 10px;
  right: 10px;
  bottom: 10px;
  z-index: 200;
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-align: center;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.help-popup-wrapper > .help-popup {
  width: 80%;
  background: #fff;
  border-radius: 5px;
  padding: 15px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}
.help-popup-wrapper > .help-popup > .help-popup-content {
  font-size: 14px;
}
.help-popup-wrapper > .help-popup > .help-popup-buttons {
  margin-top: 10px;
  display: block;
  text-align: center;
}
.profile-viewer-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 500;
  background: rgba(0, 0, 0, 0.5);
  display: block;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  opacity: 0;
}
.profile-viewer-overlay.show {
  opacity: 1;
}
.profile-viewer {
  position: fixed;
  top: 3px;
  right: 3px;
  bottom: 3px;
  left: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 501;
}
.profile-viewer .profile-viewer-content {
  opacity: 0;
  -moz-transform: translate3d(0, 20%, 0);
  -ms-transform: translate3d(0, 20%, 0);
  -o-transform: translate3d(0, 20%, 0);
  -webkit-transform: translate3d(0, 20%, 0);
  transform: translate3d(0, 20%, 0);
  height: 100%;
  width: 100%;
  max-width: 500px;
  max-height: 700px;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  background: #fff;
  border-radius: 4px;
  overflow: hidden;
}
.profile-viewer.show .profile-viewer-content {
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  opacity: 1;
}
.profile-viewer .profile-viewer-close {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  overflow: hidden;
}
.profile-viewer .profile-viewer-close.overlayed button {
  color: #fff;
}
.profile-viewer .profile-viewer-close.overlayed button:hover {
  color: #555;
}
.profile-viewer .profile-viewer-close button {
  border: 0;
  background: none;
  color: #000;
  padding: 0;
  margin: 0;
}
.profile-viewer .profile-viewer-close button rl-icon {
  padding: 15px 10px 10px 10px;
}
.profile-viewer .profile-viewer-close button:hover {
  color: #555;
}
.profile-viewer .profile-viewer-report-btn {
  position: absolute;
  right: 0;
  top: 210px;
  font-size: 24px;
  color: #9e2424;
  height: 40px;
  line-height: 40px;
  width: 40px;
  text-align: center;
  z-index: 2;
  cursor: pointer;
}
.profile-viewer .profile-viewer-report-btn:hover {
  color: #e40606;
}
.profile-viewer .profile-viewer-profile .profile-viewer-header-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 250px;
  z-index: 1;
  background-position: center center;
  background-size: cover;
}
.profile-viewer .profile-viewer-profile .profile-viewer-header {
  height: 250px;
  top: 0;
  left: 0;
  right: 0;
  position: absolute;
  z-index: 1;
  color: #fff;
  background: rgba(0, 0, 0, 0.26);
}
.profile-viewer .profile-viewer-profile .profile-viewer-header .text {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
}
.profile-viewer .profile-viewer-profile .profile-viewer-header h1 {
  font-size: 20px;
  display: block;
  margin: 0 0 5px 0;
  padding: 0 20px;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
}
.profile-viewer .profile-viewer-profile .profile-viewer-header .points {
  font-size: 16px;
  margin: 0 0 10px 0;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
}
.profile-viewer .profile-viewer-profile .profile-viewer-header .points .coin {
  display: inline-block;
  height: 20px;
  width: 20px;
  background: url('/Content/Graphics/Coin.png') no-repeat;
  background-size: contain;
  background-position: center center;
  vertical-align: middle;
  margin-right: 5px;
}
.profile-viewer .profile-viewer-profile .profile-viewer-header .points span {
  vertical-align: middle;
  text-transform: lowercase;
  font-weight: 600;
}
.profile-viewer .profile-viewer-profile .profile-viewer-body {
  top: 250px;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
}
.profile-viewer .profile-viewer-profile .profile-viewer-body .profile-viewer-contact {
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  display: block;
  margin: 10px 0 0 0;
}
.profile-viewer .profile-viewer-profile .profile-viewer-body .profile-viewer-contact .profile-viewer-contact-btn {
  display: inline-block;
  text-align: center;
  margin: 0;
  border-radius: 5px;
  padding: 5px 10px;
  overflow: hidden;
  position: relative;
}
.profile-viewer .profile-viewer-profile .profile-viewer-body .profile-viewer-contact .profile-viewer-contact-btn i {
  background: #000;
  color: #fff;
  border-radius: 100%;
  display: block;
  margin: 0 auto;
  height: 40px;
  width: 40px;
  padding: 0;
  text-align: center;
  line-height: 40px;
  font-size: 20px;
}
.profile-viewer .profile-viewer-profile .profile-viewer-body .profile-viewer-contact .profile-viewer-contact-btn span {
  display: block;
  margin: 5px 0 0 0;
}
.profile-viewer .profile-viewer-profile .profile-viewer-body .profile-viewer-info {
  padding: 0 20px;
}
.profile-viewer .profile-viewer-profile .profile-viewer-body .profile-viewer-info > div {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  margin-bottom: 12px;
}
.profile-viewer .profile-viewer-profile .profile-viewer-body .profile-viewer-info > div:last-child {
  border-bottom: none;
  margin-bottom: 0;
}
.profile-viewer .profile-viewer-profile .profile-viewer-body .profile-viewer-info > div label {
  color: rgba(0, 0, 0, 0.3);
  text-transform: uppercase;
}
.profile-viewer .profile-viewer-profile .profile-viewer-body .profile-viewer-info > div p {
  margin: 9px 0;
}
.profile-viewer .profile-viewer-profile .profile-viewer-body .profile-viewer-no-share {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.profile-viewer .profile-viewer-profile .profile-viewer-body .profile-viewer-no-share p {
  padding: 20px 25px;
  margin: 0;
  text-align: center;
}
.profile-viewer .profile-viewer-profile .profile-viewer-body .profile-viewer-blocked {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.profile-viewer .profile-viewer-profile .profile-viewer-body .profile-viewer-blocked i {
  font-size: 50px;
}
.profile-viewer .profile-viewer-profile .profile-viewer-body .profile-viewer-blocked h2 {
  margin: 10px 0 0 0;
  font-size: 16px;
}
.profile-viewer .profile-viewer-loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.profile-viewer .profile-viewer-loader .spinner {
  height: 50px;
  width: 50px;
}
.profile-viewer .profile-viewer-not-found {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.profile-viewer .profile-viewer-not-found h2 {
  font-size: 20px;
  margin: 10px 0 0 0;
  padding: 0;
}
.profile-viewer .profile-viewer-not-found i {
  font-size: 80px;
  margin: 0;
  padding: 0;
}
.profile-viewer .profile-viewer-profile,
.profile-viewer .profile-viewer-not-found,
.profile-viewer .profile-viewer-loader {
  z-index: 1;
}
.highscore-levels-enabled .profile-viewer .profile-viewer-header {
  border-bottom-width: 3px;
  border-bottom-style: solid;
}
.highscore-levels-enabled .profile-viewer .highscore-level-badge {
  position: absolute;
  right: 10px;
  top: 14px;
  box-sizing: border-box;
  padding: 4px 6px 3px 6px;
  font-size: 11px;
  border-radius: 4px;
  text-transform: uppercase;
  letter-spacing: 0.7px;
}
filter-bar {
  display: block;
  height: 40px;
  line-height: 40px;
  width: 100%;
  overflow-y: hidden;
  overflow-x: auto;
  padding: 0 8px;
  white-space: nowrap;
  box-sizing: border-box;
  background: #eee;
  border-bottom: 1px solid #ddd;
  position: relative;
}
filter-bar:before,
filter-bar:after {
  display: block;
  width: 10px;
  height: 39px;
  position: fixed;
  right: 0;
  content: '';
  margin-top: -41px;
}
filter-bar:before {
  right: auto;
  left: 0;
  margin-top: 0;
  background: -moz-linear-gradient(left, #eeeeee 0%, rgba(0, 0, 0, 0) 100%);
  background: -webkit-linear-gradient(left, #eeeeee 0%, rgba(0, 0, 0, 0) 100%);
  background: linear-gradient(to right, #eeeeee 0%, rgba(0, 0, 0, 0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#eeeeee', endColorstr='#00000000', GradientType=1);
}
filter-bar:after {
  background: -moz-linear-gradient(left, rgba(0, 0, 0, 0) 0%, #eeeeee 100%);
  background: -webkit-linear-gradient(left, rgba(0, 0, 0, 0) 0%, #eeeeee 100%);
  background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, #eeeeee 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#eeeeee', GradientType=1);
}
filter-bar filter-list {
  display: inline-block;
  height: 40px;
  line-height: 40px;
  vertical-align: top;
  margin: 0 10px 0 0;
  position: relative;
}
filter-bar filter-list:last-child {
  margin-right: 0;
}
filter-bar filter-list select {
  border: none;
  background: none;
  height: 34px;
  line-height: 34px;
  text-transform: capitalize;
}
filter-bar filter-list select option {
  text-transform: capitalize;
  padding: 3px 0;
}
filter-bar filter-list select option.default {
  font-weight: 600;
}
.login-view {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all ease-in-out 0.5s;
  background: rgba(0, 0, 0, 0.3);
  padding: 15px;
  box-sizing: border-box;
}
.login-view.ng-enter,
.login-view.ng-leave.ng-leave-active {
  opacity: 0;
}
.login-view.ng-leave,
.login-view.ng-enter.ng-enter-active {
  opacity: 1;
}
.login-view .login-view-logo {
  margin: 10px 0 40px 0;
}
.login-view .login-view-logo img {
  display: inline-block;
  margin: 0 auto;
  height: auto;
  max-height: 60px;
  width: auto;
  max-width: 100%;
  vertical-align: middle;
}
.login-view .login-view-box {
  width: 100%;
  display: block;
  box-sizing: border-box;
  max-width: 400px;
  text-align: center;
  height: 320px;
  position: relative;
}
.login-view .login-view-box .login-view-box-content {
  transition: opacity ease-in-out 0.5s;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  opacity: 1;
  width: 100%;
  min-height: 100px;
  border-radius: 5px;
  background: #fff;
  padding: 10px 30px;
  box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.login-view .login-view-box .login-view-box-content.reset-pass {
  opacity: 0;
  z-index: 1;
}
.login-view .login-view-box .login-view-box-content.reset-pass.flip {
  opacity: 1;
  z-index: 2;
}
.login-view .login-view-box .login-view-box-content.flip {
  opacity: 0;
  z-index: 1;
}
.login-view .login-invalid > i {
  font-size: 100px;
  color: #c62828;
  margin: 0 0 15px 0;
}
.login-view .login-invalid h2 {
  margin: 0 0 15px 0;
}
.login-view .login-invalid p {
  margin: 0 0 15px 0;
}
.login-view .login-form {
  width: 100%;
}
.login-view .login-form .input-container {
  display: block;
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  height: 40px;
  line-height: 40px;
  display: -webkit-box;
  display: flex;
  flex-direction: row;
}
.login-view .login-form .input-container label {
  width: 30px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 20px;
  color: rgba(0, 0, 0, 0.24);
  display: block;
}
.login-view .login-form .input-container input {
  display: block;
  flex: 1 1 auto;
  padding: 0 5px;
  height: 40px;
  line-height: 40px;
  border: none;
  background: none;
  margin: 0 0 0 5px;
  box-sizing: border-box;
  font-size: 14px;
}
.login-view .login-form button[type=submit] {
  margin: 30px 0 20px 0;
  display: block;
  width: 100%;
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
  position: relative;
  overflow: hidden;
}
.login-view .btn-link {
  display: block;
  width: 100%;
  text-align: center;
  margin: -10px 0 0 0;
  line-height: 30px;
  overflow: hidden;
  position: relative;
  bottom: -25px;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 13px;
  border: 0;
  padding: 0;
  background: none;
}
.login-view .loader {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.4);
  transition: all ease-in-out 0.3s;
}
.login-view .loader.ng-enter,
.login-view .loader.ng-leave.ng-leave-active {
  opacity: 0;
}
.login-view .loader.ng-leave,
.login-view .loader.ng-enter.ng-enter-active {
  opacity: 1;
}
.media-thumbnails-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.media-thumbnail-item-wrapper {
  position: relative;
}
.media-thumbnail-item-wrapper button {
  position: absolute;
  top: 0;
  left: 0;
  width: 26px;
  height: 26px;
  border: none;
  background: rgba(0, 0, 0, 0.24);
  color: #fff;
  padding: 0;
  font-size: 14px;
}
.media-thumbnail-item-wrapper .media-thumbnail {
  display: inline-block;
}
.media-thumbnail {
  max-width: 100%;
  display: block;
  position: relative;
  overflow: hidden;
}
.media-thumbnail .image {
  background-position: center center;
  background-size: cover;
  display: block;
  max-width: 100%;
}
.media-thumbnail .media-thumbnail-remove {
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;
  width: 26px;
  height: 26px;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  line-height: 26px;
  text-align: center;
  font-size: 14px;
  overflow: hidden;
}
.firework-container {
  position: absolute;
  top: var(--top-header-height);
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}
calendar {
  display: block;
}
.calendar {
  display: block;
  position: relative;
}
.calendar .calendar-header {
  position: relative;
  height: 50px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.calendar .calendar-header button {
  position: absolute;
  top: 0;
  bottom: 0;
  padding: 0 10px;
  height: 50px;
  line-height: 50px;
  width: 50px;
  overflow: hidden;
  box-sizing: border-box;
  text-align: left;
  background: transparent;
  border: none;
}
.calendar .calendar-header button:first-child {
  left: 0;
}
.calendar .calendar-header button:last-child {
  right: 0;
  text-align: right;
}
.calendar .calendar-header h2 {
  display: block;
  margin: 0;
  padding: 0;
  text-align: center;
  height: 50px;
  line-height: 50px;
  text-transform: capitalize;
  font-size: 18px;
}
.calendar > table thead td {
  line-height: 40px;
  text-align: center;
  font-weight: bold;
  text-transform: capitalize;
  position: relative;
  overflow: hidden;
}
.calendar > table thead td.weekend {
  color: #59d68e;
}
.calendar > table tbody td {
  line-height: 40px;
  text-align: center;
  position: relative;
  overflow: hidden;
  width: 14%;
}
.calendar > table tbody td.out button {
  color: rgba(0, 0, 0, 0.4);
}
.calendar > table tbody td button {
  position: relative;
  padding: 0;
  margin: 0;
  overflow: hidden;
  border-radius: 5px;
  border: none;
  background: transparent;
}
.calendar > table tbody td button .inner {
  vertical-align: middle;
  font-weight: 600;
  display: inline-block;
  height: 30px;
  width: 30px;
  text-align: center;
  line-height: 30px;
}
.calendar > table tbody td button.has-item .inner {
  background: rgba(0, 0, 0, 0.06);
  font-size: 13px;
  border-radius: 100%;
}
.calendar > table tbody td button.has-item.negative .inner {
  background: #e9806e;
  color: #fff;
}
.calendar > table tbody td button.has-item.positive .inner {
  background: #59d68e;
  color: #fff;
}
[calendar-item] {
  display: none;
}
@media only screen and (min-width: 1025px) {
  .multidepartment-picker-modal header-popup .buttons {
    display: none;
  }
}
.multidepartment-picker-modal header-popup .title h1 {
  text-align: left;
}
.multidepartment-picker-modal [ng-transclude] {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.multidepartment-picker-modal .modal-select-picker {
  display: flex;
  flex: 1;
  overflow-y: auto;
}
.multidepartment-picker-modal .modal-select-picker ul {
  width: 100%;
}
.multidepartment-picker-modal .modal-bottom {
  justify-content: space-between;
  display: flex;
  width: 100%;
}
@media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {
  .multidepartment-picker-modal .modal-bottom {
    padding-bottom: 21px;
  }
}
.department-viewer-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 490;
  background: rgba(0, 0, 0, 0.5);
  display: block;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  opacity: 0;
}
.department-viewer-overlay.show {
  opacity: 1;
}
.department-viewer {
  position: absolute;
  top: 3px;
  right: 3px;
  bottom: 3px;
  left: 3px;
  display: block;
  z-index: 491;
}
.department-viewer .department-viewer-content {
  opacity: 0;
  -moz-transform: translate3d(0, 20%, 0);
  -ms-transform: translate3d(0, 20%, 0);
  -o-transform: translate3d(0, 20%, 0);
  -webkit-transform: translate3d(0, 20%, 0);
  transform: translate3d(0, 20%, 0);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  background: #fff;
  border-radius: 4px;
  overflow: hidden;
}
.department-viewer.show .department-viewer-content {
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  opacity: 1;
}
.department-viewer.has-image .department-viewer-department .department-viewer-header-background {
  height: 200px;
}
.department-viewer.has-image .department-viewer-department .department-viewer-header {
  height: 200px;
  background: rgba(0, 0, 0, 0.26);
  color: #fff;
  box-shadow: none;
}
.department-viewer.has-image .department-viewer-department .department-viewer-header .text h1,
.department-viewer.has-image .department-viewer-department .department-viewer-header .info {
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
}
.department-viewer.has-image .department-viewer-department .department-viewer-header .text h1 {
  overflow: auto;
  white-space: normal;
  margin: 0 20px 5px 20px;
}
.department-viewer.has-image .department-viewer-department .department-viewer-body {
  top: 200px;
}
.department-viewer .department-viewer-close {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  overflow: hidden;
}
.department-viewer .department-viewer-close.overlayed button {
  color: #fff;
}
.department-viewer .department-viewer-close button {
  border: 0;
  background: none;
  color: #000;
  padding: 0;
  margin: 0;
}
.department-viewer .department-viewer-close button i {
  font-size: 40px;
  padding: 4px 3px;
}
.department-viewer .department-viewer-department .department-viewer-header-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 70px;
  z-index: 1;
  background-position: center center;
  background-size: cover;
}
.department-viewer .department-viewer-department .department-viewer-header {
  height: 70px;
  top: 0;
  left: 0;
  right: 0;
  position: absolute;
  z-index: 1;
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
}
.department-viewer .department-viewer-department .department-viewer-header .text {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 30px;
  text-align: center;
}
.department-viewer .department-viewer-department .department-viewer-header .info {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 5px;
  text-align: center;
  font-size: 16px;
  line-height: 31px;
}
.department-viewer .department-viewer-department .department-viewer-header .info i {
  vertical-align: middle;
  margin-right: 3px;
  font-size: 22px;
}
.department-viewer .department-viewer-department .department-viewer-header .info span {
  vertical-align: middle;
  text-transform: lowercase;
  font-weight: 600;
  margin-right: 7px;
}
.department-viewer .department-viewer-department .department-viewer-header h1 {
  font-size: 20px;
  display: block;
  position: relative;
  overflow: hidden;
  text-overflow: clip;
  white-space: nowrap;
  margin: 0 35px 5px 35px;
}
.department-viewer .department-viewer-department .department-viewer-body {
  top: 70px;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
}
.department-viewer .department-viewer-department .department-viewer-body.hasContact .department-viewer-no-data {
  top: 85px;
}
.department-viewer .department-viewer-department .department-viewer-body .department-viewer-contact {
  text-align: center;
  height: 85px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  display: block;
  margin: 10px 0 0 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.department-viewer .department-viewer-department .department-viewer-body .department-viewer-contact .department-viewer-contact-btn {
  display: inline-block;
  text-align: center;
  margin: 0;
  border-radius: 5px;
  padding: 5px 10px;
  overflow: hidden;
  position: relative;
}
.department-viewer .department-viewer-department .department-viewer-body .department-viewer-contact .department-viewer-contact-btn i {
  background: #000;
  color: #fff;
  border-radius: 100%;
  display: block;
  margin: 0 auto;
  height: 40px;
  width: 40px;
  padding: 0;
  text-align: center;
  line-height: 40px;
  font-size: 20px;
}
.department-viewer .department-viewer-department .department-viewer-body .department-viewer-contact .department-viewer-contact-btn span {
  display: block;
  margin: 5px 0 0 0;
}
.department-viewer .department-viewer-department .department-viewer-body .department-viewer-info {
  padding: 10px 10px;
}
.department-viewer .department-viewer-department .department-viewer-body .department-viewer-info table {
  width: 100%;
}
.department-viewer .department-viewer-department .department-viewer-body .department-viewer-info table tr td {
  padding: 4px 5px;
  vertical-align: top;
}
.department-viewer .department-viewer-department .department-viewer-body .department-viewer-info table tr td.data-type {
  font-weight: 600;
}
.department-viewer .department-viewer-department .department-viewer-body .department-viewer-info table tr td span {
  display: block;
}
.department-viewer .department-viewer-department .department-viewer-body .department-viewer-no-data {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.department-viewer .department-viewer-department .department-viewer-body .department-viewer-no-data p {
  padding: 20px 25px;
  margin: 0;
  text-align: center;
}
.department-viewer .department-viewer-loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.department-viewer .department-viewer-loader .spinner {
  height: 50px;
  width: 50px;
}
.department-viewer .department-viewer-not-found {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 15px;
  text-align: center;
  box-sizing: border-box;
}
.department-viewer .department-viewer-not-found h2 {
  font-size: 20px;
  margin: 10px 0 0 0;
  padding: 0;
}
.department-viewer .department-viewer-not-found i {
  font-size: 80px;
  margin: 0;
  padding: 0;
}
.department-viewer .department-viewer-profile,
.department-viewer .department-viewer-not-found,
.department-viewer .department-viewer-loader {
  z-index: 1;
}
.native-app-overlay {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background: rgba(0, 0, 0, 0.9);
  color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 410;
  opacity: 0;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.native-app-overlay iframe {
  display: none;
}
.native-app-overlay.shown {
  opacity: 1;
}
.native-app-overlay .top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}
.native-app-overlay .top .close {
  border: none;
  background: none;
  cursor: pointer;
  padding: 0;
}
.native-app-overlay .top .close > i {
  margin: 8px;
  font-size: 28px;
  color: #fff;
}
.native-app-overlay .content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding-bottom: 44px;
}
.native-app-overlay .content .buttons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 75%;
}
.native-app-overlay .content .buttons .store-button {
  background: #fff;
  color: #000;
  text-decoration: none;
  border-radius: 3px;
  padding: 10px 16px;
  margin: 5px 0;
  font-weight: 600;
}
.native-app-overlay .content .buttons .store-button .container {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  align-items: center;
}
.native-app-overlay .content .buttons .store-button .container > span {
  margin: 0 5px;
}
.native-app-overlay .content .buttons .store-button .container > i {
  font-size: 24px;
}
.native-app-overlay .content h2 {
  font-size: 20px;
  margin: 0 0 10px 0;
}
.native-app-overlay .content p {
  margin: 0 0 10px 0;
}
.native-app-overlay .content .spinner {
  border-color: #fff;
  margin-top: 10px;
}
.modal-page-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background: var(--popup-overlay-bg);
}
.modal-page {
  position: fixed;
  padding: 5px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  box-sizing: border-box;
  z-index: 370;
  opacity: 0;
  transition: all 0.15s ease-in-out;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
@media only screen and (max-width: 767px) {
  .modal-page {
    padding: 0;
  }
}
.modal-page.show {
  opacity: 1;
}
.modal-page.show .modal-page-inner {
  transform: translate3d(0, 0, 0);
}
@media only screen and (min-width: 768px) {
  .modal-page .popup-wrapper .popup-content {
    left: 50%;
    top: 0;
  }
}
.modal-page .modal-page-inner {
  height: 100%;
  width: 100%;
  max-width: 600px;
  padding: 0;
  overflow: hidden;
  display: block;
  position: relative;
  border-radius: 0;
  transform: translate3d(0, 20%, 0);
  transition: all 0.15s ease-in-out;
  z-index: 2;
  box-shadow: 0 16px 20px rgba(0, 0, 0, 0.4);
}
.modal-page .modal-page-header {
  position: absolute;
  z-index: 2;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  height: 55px;
  padding: 0;
  line-height: 55px;
  overflow: hidden;
}
.modal-page .modal-page-header .modal-page-header-close {
  text-align: center;
  padding: 0;
  font-size: 26px;
  overflow: hidden;
  display: block;
  background: none;
  color: inherit;
  min-width: 45px;
}
.modal-page .modal-page-header h1 {
  box-sizing: border-box;
  line-height: 55px;
  text-transform: uppercase;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  font-weight: 600;
}
.modal-page .modal-page-header .right-button-wrapper {
  min-width: 45px;
}
.modal-page .modal-page-content {
  position: absolute;
  top: 55px;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-x: hidden;
  overflow-y: auto;
  background: var(--general-background-color);
  z-index: 1;
  box-sizing: border-box;
}
.modal-page.submit .modal-page-header {
  display: flex;
  flex-direction: row;
}
.modal-page.submit .modal-page-header h2 {
  display: block;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  position: relative;
  padding: 0;
}
.modal-page.submit .modal-page-header .modal-page-header-back,
.modal-page.submit .modal-page-header .modal-page-header-submit {
  flex: 0 0 auto;
  height: 55px;
  line-height: 55px;
  width: 45px;
  padding: 0;
  text-align: center;
  font-size: 20px;
  overflow: hidden;
  display: block;
  position: relative;
  background: none;
  border: none;
  color: inherit;
}
.modal-page.submit .modal-page-header .modal-page-header-submit {
  font-size: 20px;
  width: 50px;
}
.modal-page.submit .modal-page-header .modal-page-header-submit.has-text {
  font-size: 15px;
  box-sizing: border-box;
  padding: 0 15px 0 0;
  width: auto;
  min-width: 45px;
  font-weight: 600;
}
@media (orientation: landscape) {
  .modal-page .modal-page-inner {
    max-width: 768px;
  }
}
.bookmark-viewer .bookmark-viewer-loading {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.bookmark-viewer .bookmark-viewer-loading .spinner {
  height: 30px;
  width: 30px;
}
.bookmark-viewer .bookmark-viewer-content ul {
  list-style: none;
  padding: 0;
  margin: 5px 0;
  display: block;
}
.bookmark-viewer .bookmark-viewer-content ul > li {
  display: block;
  width: 100%;
  box-sizing: border-box;
  margin: 5px 0;
  border-top: 1px solid #e9e9e9;
  border-bottom: 1px solid #e9e9e9;
}
.bookmark-viewer .bookmark-viewer-content .bookmark-viewer-bookmark {
  display: block;
  border-radius: 4px;
  background: #fff;
  position: relative;
}
.bookmark-viewer .bookmark-viewer-content .bookmark-viewer-bookmark > .inner {
  display: block;
  padding: 10px 12px;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  z-index: 1;
}
.bookmark-viewer .bookmark-viewer-content .bookmark-viewer-bookmark > .inner h3 {
  margin: 0;
  font-size: 16px;
}
.bookmark-viewer .bookmark-viewer-content .bookmark-viewer-bookmark > .inner p {
  margin: 0;
  font-size: 13px;
}
.bookmark-viewer .bookmark-viewer-content .bookmark-viewer-bookmark .options {
  border: none;
  background: none;
  display: block;
  width: 40px;
  height: 40px;
  text-align: right;
  line-height: 40px;
  position: absolute;
  top: 0;
  right: 0;
  overflow: hidden;
  z-index: 2;
  font-size: 18px;
  opacity: 0.6;
  box-sizing: border-box;
  padding-right: 5px;
}
.selectlist-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--popup-overlay-bg);
  z-index: 800;
  transition-duration: 0.2s;
  transition-property: opacity;
  transition-timing-function: ease-in-out;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.selectlist-wrapper.ng-enter,
.selectlist-wrapper.ng-leave-active {
  opacity: 0;
}
.selectlist-wrapper.ng-enter .selectlist {
  opacity: 0;
  transform: scale(1.05);
}
.selectlist-wrapper.ng-leave-active .selectlist {
  opacity: 0;
  transform: scale(0.85);
}
.selectlist-wrapper.ng-enter-active {
  opacity: 1;
}
.selectlist-wrapper .selectlist-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.selectlist-wrapper .selectlist {
  display: block;
  position: relative;
  background: #fff;
  transform: scale(1);
  transition-duration: 0.2s;
  transition-property: all;
  transition-timing-function: ease-in-out;
  z-index: 2;
  width: 90%;
  max-width: 280px;
  max-height: 400px;
  border-radius: 2px;
  box-shadow: 0 16px 20px rgba(0, 0, 0, 0.4);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.selectlist-wrapper .selectlist .selectlist-header {
  display: block;
  min-height: 40px;
  box-sizing: border-box;
  padding: 13px 15px;
  border-bottom: 1px solid #ddd;
  flex: 0 0 auto;
  background: #f1f1f1;
}
.selectlist-wrapper .selectlist .selectlist-header h3 {
  display: block;
  line-height: 20px;
  font-size: 18px;
  font-weight: 600;
  padding: 0;
  margin: 0;
}
.selectlist-wrapper .selectlist .selectlist-content {
  flex: 1 1 auto;
  overflow-x: hidden;
  overflow-y: auto;
}
.selectlist-wrapper .selectlist .selectlist-content ul {
  display: block;
  list-style: none;
  margin: 0;
  padding: 5px 0;
}
.selectlist-wrapper .selectlist .selectlist-content ul li {
  display: block;
  position: relative;
  overflow: hidden;
  line-height: 38px;
  box-sizing: border-box;
  padding: 0 5px 0 40px;
  white-space: nowrap;
  text-overflow: ellipsis;
  transition: all ease-in-out 0.2s;
}
.selectlist-wrapper .selectlist .selectlist-content ul li > .dot {
  position: absolute;
  margin: 0;
  left: 12px;
  top: 10px;
  border: 2px solid #555;
  height: 16px;
  width: 16px;
  text-align: center;
  box-sizing: border-box;
  border-radius: 50%;
  background-image: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.selectlist-wrapper .selectlist .selectlist-content ul li > .dot:before {
  display: block;
  height: 8px;
  width: 8px;
  content: '';
  background: #508AE4;
  transform: scale(0);
  border-radius: 50%;
}
.selectlist-wrapper .selectlist .selectlist-content ul li.selected {
  color: #508AE4;
}
.selectlist-wrapper .selectlist .selectlist-content ul li.selected > .dot {
  border-color: #508AE4;
}
.selectlist-wrapper .selectlist .selectlist-content ul li.selected > .dot:before {
  transform: scale(1);
}
.selectlist-wrapper .selectlist .selectlist-footer {
  flex: 0 0 auto;
  border-top: 1px solid #ddd;
  height: 38px;
  line-height: 38px;
  text-align: right;
  font-size: 0;
  padding: 0 5px;
  box-sizing: border-box;
  background: #f1f1f1;
}
.selectlist-wrapper .selectlist .selectlist-footer > button {
  background: none;
  border: none;
  display: inline-block;
  font-size: 14px;
  position: relative;
  overflow: hidden;
  height: 38px;
  line-height: 38px;
  padding: 0 15px;
  text-align: center;
  color: #508AE4;
  font-weight: 600;
  text-transform: uppercase;
}
.alert-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 900;
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: ease-in-out;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.alert-wrapper.ng-enter,
.alert-wrapper.ng-leave-active {
  opacity: 0;
}
.alert-wrapper.ng-enter .alert,
.alert-wrapper.ng-leave-active .alert {
  transform: scale(0);
}
.alert-wrapper.ng-enter-active {
  opacity: 1;
}
.alert-wrapper .alert-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.alert-wrapper .alert {
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fff;
  transform: scale(1);
  transition-duration: 0.3s;
  transition-property: transform;
  transition-timing-function: linear;
  z-index: 2;
  border-radius: 4px;
}
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--popup-overlay-bg);
  z-index: 99999;
  transition-duration: 0.2s;
  transition-property: opacity;
  transition-timing-function: ease-in-out;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.loading-overlay.ng-enter,
.loading-overlay.ng-leave-active {
  opacity: 0;
}
.loading-overlay.ng-enter-active {
  opacity: 1;
}
.loading-overlay .loading-wrapper .spinner {
  height: 50px;
  width: 50px;
  border-width: 4px;
}
.multiple-selector .multiple-selector-selection-area {
  padding: 10px 0 5px 0;
}
.multiple-selector .multiple-selector-selection-area.disabled {
  background: rgba(0, 0, 0, 0.4);
  opacity: 0.5;
}
.multiple-selector .multiple-selector-selection-area .multiple-selector-selection {
  display: inline-block;
  height: 25px;
  line-height: 25px;
  box-sizing: border-box;
  padding: 0 8px;
  font-size: 12px;
  font-style: italic;
  margin: 0 5px 5px 0;
  background: rgba(0, 0, 0, 0.06);
  position: relative;
  font-weight: 600;
}
.multiple-selector .multiple-selector-selection-area .multiple-selector-selection > i {
  margin-left: 5px;
}
.multiple-selector .multiple-selector-selection-area .multiple-selector-selection.locked > i {
  opacity: 0.4;
}
.validation-message {
  display: block;
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  font-size: 0.9rem;
  font-weight: 600;
  box-sizing: border-box;
  transition: all ease-in-out 0.2s;
  width: 100%;
}
.validation-message.visible {
  max-height: 100px;
  opacity: 1;
  margin-top: 4px;
}
.validation-message .msg {
  display: flex;
  align-items: center;
}
.validation-message .msg p {
  margin: var(--s-spacing);
}
.validation-message .msg rl-icon span {
  border-radius: 50%;
  font-size: 0.75rem;
  font-weight: 800;
  width: 1.125rem;
  height: 1.125rem;
  line-height: 1.125rem;
}
.validation-message .required-field-empty rl-icon span {
  background-color: var(--alert-color);
  color: var(--general-text-color);
}
.validation-message .error-message {
  color: var(--warning-color);
}
.validation-message .error-message rl-icon span {
  background-color: var(--warning-color);
  color: var(--white-color);
  font-size: 0.65rem;
}
.popup-wrapper.lazy-data-list-popup {
  z-index: 205;
}
.popup-wrapper.lazy-data-list-popup .loading-indicator {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.popup-wrapper.lazy-data-list-popup .loading-indicator .spinner {
  width: 50px;
  height: 50px;
}
.popup-wrapper.lazy-data-list-popup .search-empty {
  display: flex;
  height: 100%;
  padding-top: 100px;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}
.popup-wrapper.lazy-data-list-popup .search-empty [ng-transclude="create"] {
  width: 100%;
  padding: 0 25px;
  box-sizing: border-box;
}
.popup-wrapper.lazy-data-list-popup .create-data-list-item {
  box-sizing: border-box;
  height: 46px;
  font-weight: bold;
  font-size: 15px;
  width: 100%;
  background: var(--highlight-color);
  color: var(--text-on-highlight-color);
}
.popup-wrapper.lazy-data-list-popup .popup-footer {
  margin: 0;
}
.popup-wrapper.lazy-data-list-popup .popup-footer button {
  width: 50%;
}
.popup-wrapper.lazy-data-list-popup .popup-footer button.reset {
  background: #b7b7b7;
  color: #000;
}
.popup-wrapper.lazy-data-list-popup .popup-footer button.reset:hover {
  background: #a5a2a2;
}
.popup-wrapper.lazy-data-list-popup .search-block {
  display: flex;
  width: 100%;
}
.popup-wrapper.lazy-data-list-popup .search-block input[type=text] {
  display: block;
  width: 100%;
  max-width: 100%;
  margin: 0;
  padding: 8px;
  border-radius: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px solid #dedede;
  background: #eeeeee;
  font-size: 15px;
  height: 50px;
}
.popup-wrapper.lazy-data-list-popup .search-block barcode-reader .btn {
  opacity: 0.8;
  height: 50px;
  width: 50px;
  line-height: 50px;
}
.popup-wrapper.lazy-data-list-popup .search-block .search-wrapper {
  flex: 1;
}
.popup-wrapper.lazy-data-list-popup .search-wrapper {
  position: relative;
}
.popup-wrapper.lazy-data-list-popup .search-wrapper input {
  padding: 10px 35px 10px 10px;
  font-size: 15px;
}
.popup-wrapper.lazy-data-list-popup .search-wrapper input.ng-valid.ng-dirty {
  border: none;
}
.popup-wrapper.lazy-data-list-popup .search-wrapper .clear-search {
  position: absolute;
  top: 50%;
  right: 11px;
  margin: -10px 0 0 0;
  padding: 0;
  border: none;
  background: none;
}
.popup-wrapper.lazy-data-list-popup ul {
  flex: 1;
  margin: 0;
  padding: 0;
  list-style: none;
  overflow-y: auto;
}
.popup-wrapper.lazy-data-list-popup li {
  cursor: pointer;
  padding: 10px 10px;
  position: relative;
  margin: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.popup-wrapper.lazy-data-list-popup li.disabled {
  opacity: 0.4;
}
.popup-wrapper.lazy-data-list-popup li p {
  margin: 0;
}
.popup-wrapper.lazy-data-list-popup li .subtitle {
  font-size: 12px;
  color: #303030;
}
.popup-wrapper.lazy-data-list-popup li .prefix {
  padding-right: 5px;
  font-size: 18px;
}
.popup-wrapper.lazy-data-list-popup li.selected {
  background: rgba(0, 0, 0, 0.1);
}
.popup-wrapper.lazy-data-list-popup li.in-use p {
  color: var(--general-text-inactive-color);
}
.popup-wrapper.lazy-data-list-popup li .in-use-label {
  position: absolute;
  top: var(--spacing);
  right: 10px;
}
lazy-datalist {
  position: relative;
}
lazy-datalist.ng-invalid:not(.ng-pristine) .lazy-datalist-btn {
  border: 1px solid var(--warning-color) !important;
}
lazy-datalist.ng-valid.ng-dirty .lazy-datalist-btn {
  border: 1px solid var(--confirmation-color) !important;
}
lazy-datalist .lazy-datalist-selector {
  position: relative;
  width: 100%;
}
lazy-datalist .lazy-datalist-selector .lazy-datalist-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  width: 100%;
  min-height: 45px;
  box-sizing: border-box;
  padding: 10px;
  color: var(--general-text-color);
  background: var(--background-highlight-color);
  border-radius: var(--general-border-radius);
  border: 1px solid transparent;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
  cursor: pointer;
}
lazy-datalist .lazy-datalist-selector .lazy-datalist-btn:hover {
  border: 1px solid var(--general-border-color);
}
lazy-datalist .lazy-datalist-selector .lazy-datalist-btn:focus {
  border: 1px solid var(--general-text-inactive-color);
}
lazy-datalist .lazy-datalist-selector .lazy-datalist-btn > span,
lazy-datalist .lazy-datalist-selector .lazy-datalist-btn > p {
  display: block;
  flex: 1 1 auto;
  padding: 0 30px 0 8px;
  word-wrap: break-word;
  position: relative;
  min-width: 0;
  white-space: normal;
  color: var(--general-text-inactive-color);
  font-size: 13px;
  margin: 0;
}
lazy-datalist .lazy-datalist-selector .lazy-datalist-btn > span.btn-label,
lazy-datalist .lazy-datalist-selector .lazy-datalist-btn > p.btn-label {
  width: 200px;
  max-width: 45%;
  font-weight: 600;
  color: var(--general-text-color);
  flex: 1 0 auto;
  font-size: 14px;
  padding-right: 8px;
}
lazy-datalist .lazy-datalist-selector .lazy-datalist-btn > span > span,
lazy-datalist .lazy-datalist-selector .lazy-datalist-btn > p > span {
  display: block;
  width: 100%;
}
lazy-datalist .lazy-datalist-selector .lazy-datalist-btn > span .subtitle,
lazy-datalist .lazy-datalist-selector .lazy-datalist-btn > p .subtitle {
  font-size: 12px;
  color: inherit;
}
lazy-datalist .lazy-datalist-selector.has-data .lazy-datalist-btn > span,
lazy-datalist .lazy-datalist-selector.has-data .lazy-datalist-btn > p {
  color: #333;
  font-weight: 600;
}
lazy-datalist .lazy-datalist-selector.has-data .lazy-datalist-btn > span > span:not(:first-child),
lazy-datalist .lazy-datalist-selector.has-data .lazy-datalist-btn > p > span:not(:first-child) {
  font-weight: normal;
  font-size: 12px;
  margin-top: 1px;
}
lazy-datalist .inline-search {
  position: relative;
}
lazy-datalist .inline-search .inline-search-wrapper {
  width: 100%;
  display: flex;
}
lazy-datalist .inline-search .input-wrapper {
  position: relative;
  flex: 1;
}
lazy-datalist .inline-search .input-wrapper .open-menu {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 20px;
  padding: 8px;
}
lazy-datalist .inline-search .info {
  padding-top: 10px;
  display: flex;
  align-items: center;
}
lazy-datalist .inline-search .info .label {
  padding: 0 10px;
  color: #EB6262;
}
lazy-datalist .inline-search .info .icon {
  color: #EB6262;
}
lazy-datalist .inline-search .info.selected .label {
  color: #4FC990;
}
lazy-datalist .inline-search .info.selected .icon {
  color: #4FC990;
}
lazy-datalist .inline-search .info button {
  height: auto;
  line-height: 1.5;
}
lazy-datalist .inline-search .predictions {
  border: 1px solid rgba(var(--general-text-color-rgb), .1);
  position: absolute;
  z-index: 100;
  top: 40px;
  left: 0;
  width: 100%;
  max-height: 120px;
  overflow-y: auto;
  background: white;
}
lazy-datalist .inline-search .predictions ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
lazy-datalist .inline-search .predictions ul li {
  padding: 10px;
  border-bottom: 1px solid rgba(var(--general-text-color-rgb), .1);
}
lazy-datalist .inline-search .predictions ul li:last-of-type {
  border-bottom: none;
}
lazy-datalist .inline-search .predictions ul p {
  margin: 0;
}
create-data-list-item .popup-wrapper {
  z-index: 205;
}
.password-strength-container {
  opacity: 0;
  transition: all ease-in-out 0.2s;
  text-align: right;
  height: 0;
}
.password-strength-container.active {
  margin-top: 5px;
  opacity: 1;
  height: 22px;
}
.password-strength-container.active span {
  font-size: 12px;
  opacity: 1;
}
.password-strength-container.active .password-strength-bar div[class^="password-strength-bar-"] {
  height: 5px;
}
.password-strength-container span,
.password-strength-container.valid span {
  opacity: 0;
  color: #ea1111;
  transition: all ease-in-out 0.2s;
}
.password-strength-container[data-password-strength-value="1"] .password-strength-bar-1 {
  background: #ea1111;
}
.password-strength-container[data-password-strength-value="2"] .password-strength-bar-1,
.password-strength-container[data-password-strength-value="2"] .password-strength-bar-2 {
  background: #ffad00;
}
.password-strength-container[data-password-strength-value="3"] .password-strength-bar-1,
.password-strength-container[data-password-strength-value="3"] .password-strength-bar-2,
.password-strength-container[data-password-strength-value="3"] .password-strength-bar-3 {
  background: #9bc158;
}
.password-strength-container[data-password-strength-value="4"] .password-strength-bar-1,
.password-strength-container[data-password-strength-value="4"] .password-strength-bar-2,
.password-strength-container[data-password-strength-value="4"] .password-strength-bar-3,
.password-strength-container[data-password-strength-value="4"] .password-strength-bar-4 {
  background: #00b400;
}
.password-strength-container .password-strength-bar {
  width: 100%;
  transition: height ease-in-out 0.2s;
  display: flex;
  flex-direction: row;
}
.password-strength-container div[class^="password-strength-bar-"] {
  background: #e8e8e8;
  transition: background ease-in-out 0.5s;
  height: 0;
  flex: 1 1 auto;
  margin-right: 2px;
}
.password-strength-container div[class^="password-strength-bar-"]:last-child {
  margin-right: 0;
}
.ios11-upload-warning-modal .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px 10px;
  font-size: 26px;
  color: white;
}
.ios11-upload-warning-modal .modal .modal-content {
  left: 10px;
  right: 10px;
  border-radius: 0;
}
.ios11-upload-warning-modal .modal .modal-content .modal-top {
  display: none;
}
.ios11-upload-warning-modal .modal .modal-content .modal-body {
  padding: 0;
}
.ios11-upload-warning-modal .modal .modal-content .modal-body h2 {
  font-size: 20px;
  margin: 0;
}
.ios11-upload-warning-modal .ios11-upload-warning-modal-image img {
  max-width: 100%;
}
.ios11-upload-warning-modal .ios11-upload-warning-modal-content {
  padding: 25px 20px;
}
.home {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: #e9e9e9;
}
.home.has-news-ticker {
  top: 40px;
}
@media only screen and (max-width: 1024px) {
  .home.has-news-ticker {
    top: 5vh;
  }
}
.home.badge-corner .tiles .tile rl-badge {
  top: 5px;
  right: 5px;
  left: auto;
  margin: 0;
}
.home .background-overlay {
  background: rgba(0, 0, 0, 0.4);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow-x: auto;
  overflow-y: hidden;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 20px;
  z-index: 2;
}
.home .home-backgrounds {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.home .home-backgrounds > div > div {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 0;
}
.home .home-backgrounds > div > div.starter {
  opacity: 1;
}
.home .home-backgrounds > div > div.back-video {
  opacity: 1;
}
.home .home-backgrounds > div > div.back-video video {
  object-fit: cover;
}
.home .tiles {
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 3;
  overflow-y: auto;
}
.home .tiles .tile-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 25%;
  width: 100%;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  margin: 0;
  padding: 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.6);
}
.home .tiles .tile {
  display: block;
  color: #fff;
  font-size: 14px;
  overflow: hidden;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  -webkit-box-flex: 1;
  -webkit-flex: 1 0 auto;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  position: relative;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-right: 1px solid rgba(255, 255, 255, 0.6);
  height: 100%;
  width: 50%;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.home .tiles .tile .tile-inner-container {
  position: relative;
  height: 100%;
  width: 100%;
  margin: 0 auto;
}
.home .tiles .tile:last-child {
  border: none;
}
.home .tiles .tile i {
  display: block;
  font-size: 26px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -40px;
  margin-left: -25px;
  height: 50px;
  line-height: 50px;
  width: 50px;
  text-align: center;
}
.home .tiles .tile rl-badge {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -40px;
  margin-left: 4px;
}
.home .tiles .tile .text {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  margin-top: 10px;
  text-align: center;
  font-size: 14px;
  font-weight: 300;
  text-transform: uppercase;
}
.home .tiles .tile:active,
.home .tiles .tile:hover {
  background-color: rgba(220, 220, 220, 0.5);
}
.home .tiles .tile.icon-tile .text {
  display: none;
}
.home .tiles .tile.icon-tile i {
  margin-top: -25px;
}
.home .tiles .tile.icon-tile .icon-image-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  left: 20px;
  top: 20px;
  bottom: 20px;
  right: 20px;
}
.home .tiles .tile.icon-tile .icon-image-container > img {
  max-width: 100%;
  max-height: 100%;
}
.home .tiles .tile:not(.icon-tile) .icon-image-container {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -40px;
  margin-left: -25px;
  height: 50px;
  line-height: 50px;
  width: 50px;
  text-align: center;
}
.home .tiles .tile:not(.icon-tile) .icon-image-container > img {
  max-width: 100%;
  max-height: 100%;
}
.native .home .tiles {
  padding-bottom: 50px;
}
.home-landingpage {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}
.home-landingpage.has-news-ticker {
  top: 5vh;
}
@media only screen and (min-width: 1025px) {
  .home-landingpage.has-news-ticker {
    top: 40px;
  }
}
.profile-page {
  position: relative;
  height: 100%;
  background: var(--background-highlight-color);
}
.profile-page form {
  position: relative;
  background: var(--general-background-color);
}
.profile-page .container-left {
  padding-top: 10px;
  text-align: center;
}
.profile-page .container-left .profile-image-wrapper {
  margin: 0;
}
.profile-page .form-input-list {
  padding: 0 15px;
}
.profile-page .form-input-list input,
.profile-page .form-input-list textarea,
.profile-page .form-input-list select {
  color: var(--general-text-color);
}
.profile-page .form-input-list input[disabled],
.profile-page .form-input-list textarea[disabled],
.profile-page .form-input-list select[disabled] {
  opacity: 0.4;
}
.profile-page .form-input-list select {
  font-weight: 400;
  padding: 0;
  background: transparent;
}
.profile-page .form-input-list label {
  color: var(--general-text-color);
}
.profile-page .password-view-icon {
  height: 26px;
  line-height: 26px;
  font-size: 16px;
  width: 40px;
  text-align: center;
  color: #3c4858;
  opacity: 0.7;
  cursor: pointer;
  float: right;
  margin-bottom: -26px;
}
.profile-page .password-view-icon:hover {
  opacity: 1;
}
.profile-page .password-input {
  padding-right: 40px;
}
.profile-page .form-item.about {
  margin: -1px -15px 0;
  padding: 10px 15px;
  border-bottom: 20px solid var(--background-highlight-color);
  border-top: 20px solid var(--background-highlight-color);
}
.profile-page .form-item.about button {
  display: flex;
  width: 100%;
  position: relative;
  align-items: center;
  justify-content: space-between;
  padding: 0;
}
.profile-page .form-item.about button > span {
  display: flex;
  align-items: center;
}
.profile-page .form-item.about rl-icon {
  color: rgba(var(--general-text-color-rgb), .5);
}
.profile-page .form-item.about icon {
  margin-right: 10px;
  width: 26px;
  height: 20px;
}
.profile-page .form-item.about icon svg {
  stroke-width: 0;
}
.profile-page .form-item.about icon svg g {
  color: #ea6f63;
}
.profile-page .form-item.about icon svg path {
  fill: #ea6f63;
}
.profile-page .pin {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.profile-page .pin:after {
  display: none;
}
.profile-page .pin button {
  display: flex;
  align-items: center;
  padding: 0;
}
.profile-page .pin rl-icon {
  color: rgba(var(--general-text-color-rgb), .5);
}
.profile-page .pin ul {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
}
.profile-page .pin ul li {
  margin-right: 10px;
  width: 7px;
  height: 7px;
  border-radius: 100%;
  background: var(--highlight-color);
}
.profile-page .form-item textarea {
  background: none;
}
.profile-page .bar {
  border-color: #f3f3f4;
}
.profile-point-log table td {
  padding: 4px;
}
.profile-point-log table h3 {
  display: block;
  font-size: 14px;
  margin: 0;
  padding: 0;
}
.profile-point-log table span {
  display: block;
  font-size: 12px;
  color: #bbb;
}
.charts {
  background-color: #eff1f3;
}
.charts .chart-list {
  display: block;
  width: 100%;
  list-style: none;
  padding: 15px 0;
  margin: 0;
}
.charts .chart-list li {
  display: block;
  list-style: none;
  padding: 0 0 30px 0;
  margin: 0;
}
.charts .chart-list li > .chart-group-item {
  padding: 10px 15px 5px 15px;
  border-top: 1px solid #d3d7dc;
  border-bottom: 1px solid #d3d7dc;
}
.charts .chart-list li > .chart-group-item:after {
  display: table;
  clear: both;
  content: '';
}
.charts .chart-list li > .chart-group-item .chart-list-item {
  display: block;
  float: left;
  width: 100%;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin-bottom: 5px;
}
.charts .chart-list li > .chart-group-item .chart-list-item.size-2 {
  width: 50%;
}
.charts .chart-list li > .chart-group-item .chart-list-item.size-1 {
  width: 33.333333%;
}
chart {
  display: block;
}
.bar-chart .legend {
  font-size: 0.7em;
}
.bar-chart .legend ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
  display: block;
  text-align: center;
}
.bar-chart .legend ul li {
  padding: 2px;
  margin: 0 5px;
  display: inline-block;
}
.bar-chart .legend ul li span {
  line-height: 19px;
  vertical-align: middle;
}
.bar-chart .legend ul li span.colorbox {
  display: inline-block;
  height: 15px;
  width: 15px;
  border: 2px solid #fff;
  margin-right: 5px;
}
.contact .count div {
  margin: 20px 0 10px 0;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 500;
  display: inline-block;
}
@media only screen and (min-width: 768px) {
  .contact .count div {
    font-size: 16px;
  }
}
.contact .contact-tab {
  top: 70px;
}
.contact .tab {
  padding-bottom: 50px;
  padding-bottom: calc(50px + env(safe-area-inset-bottom, 0px));
}
.contact .search {
  position: relative;
  padding: 10px;
}
.contact .search input {
  width: 100%;
  box-sizing: border-box;
  height: 45px;
  padding: 10px 35px 10px 15px;
  border: 1px solid #EBECEE;
  font-size: 15px;
  line-height: 20px;
  -webkit-appearance: none;
}
.contact .search input::-webkit-search-decoration,
.contact .search input::-webkit-search-cancel-button,
.contact .search input::-webkit-search-results-button,
.contact .search input::-webkit-search-results-decoration {
  -webkit-appearance: none;
}
.contact .search input:focus {
  border: 1px solid #5FA3F1;
}
.contact .search button {
  position: absolute;
  padding: 10px;
  border: none;
  background: transparent;
}
.contact .search button.close-button {
  display: none;
}
.contact .search button.search-button {
  right: 15px;
  top: 15px;
}
.contact .contact-users {
  padding: 10px 10px 0 10px;
}
.contact .contact-departments {
  padding-top: 5px;
}
.contact .contact-departments .accordion-content {
  background: #fff;
}
.contact .contact-departments .accordion-content .accordion-inner {
  text-align: center;
}
.contact .contact-departments .contact-department-link {
  display: block;
  width: 100%;
  height: 28px;
  line-height: 28px;
  text-align: center;
  background: #555;
  color: #fff;
  margin: 4px 0 6px 0;
  font-size: 13px;
  overflow: hidden;
  position: relative;
  border-radius: 4px;
  border: none;
}
.contact .contact-departments .contact-department-link i {
  position: relative;
  top: 1px;
  margin-left: 3px;
  opacity: 0.5;
}
.contact .phone-number {
  text-decoration: none;
  color: #000;
}
.highscore {
  background: #f5f5f5;
}
.highscore .tab {
  padding: 10px 0 0 0;
}
.highscore .tab table {
  margin-top: 20px;
}
.highscore .tab table th {
  border-bottom: 1px solid #eee;
  padding-left: 5px;
}
.highscore .tab table th.ranking {
  text-align: center;
}
.highscore .tab table td {
  line-height: 20px;
  padding-left: 5px;
  border-bottom: 1px solid #eee;
}
.highscore .tab table td.ranking {
  background-color: #eee;
  width: 30px;
  text-align: center;
}
.highscore .tab table td .round-image {
  margin-left: 5px;
}
.highscore .tab table td .department {
  font-size: 10px;
  opacity: 0.8;
}
.highscore .tab table tr.highlight td {
  background-color: #000 !important;
  color: #fff !important;
}
.highscore .header {
  font-size: 24px;
  text-transform: uppercase;
  margin: 20px;
  text-align: center;
}
.highscore .spinner-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.ranking .tab {
  padding-top: 0;
}
.ranking .ranking-view {
  padding-top: 20px;
}
.ranking .ranking-view .no-content {
  position: relative;
  top: 0;
  margin: 40px 0 0 0;
}
.ranking .ranking-view .spinner-container {
  display: block;
  text-align: center;
  margin-top: 30px;
}
.ranking .ranking-view .spinner-container .spinner {
  width: 40px;
  height: 40px;
}
.ranking .ranking-view .date-last-updated {
  font-size: 13px;
  color: #333;
  text-align: center;
  padding-bottom: 5px;
}
.ranking .ranking-view table th,
.ranking .ranking-view table td {
  border-bottom: 1px solid #eee;
  padding: 0 5px;
}
.ranking .ranking-view table th.rank,
.ranking .ranking-view table td.rank {
  text-align: center;
  width: 30px;
}
.ranking .ranking-view table th.name,
.ranking .ranking-view table td.name {
  padding-left: 10px;
}
.ranking .ranking-view table th {
  vertical-align: bottom;
}
.ranking .ranking-view table td.rank {
  background-color: #eee;
}
.ranking .ranking-view table td.multiline h2 {
  padding-bottom: 0;
}
.ranking .ranking-view table td h2 {
  font-size: 1em;
  font-weight: normal;
  margin: 0;
  padding: 5px 0;
  line-height: normal;
}
.ranking .ranking-view table td h3 {
  font-size: 0.85em;
  font-weight: normal;
  opacity: 0.8;
  margin: 0;
  padding: 0 0 5px 0;
  line-height: normal;
}
.dutyplan .tab .list {
  margin-top: 20px;
}
.dutyplan .tab table td {
  line-height: 36px;
  padding-left: 15px;
}
.dutyplan .tab table td.ranking {
  background-color: #eee;
  width: 30px;
  text-align: center;
}
.dutyplan .tab table td .round-image {
  margin-left: 5px;
}
.dutyplan .tab table td.workhour {
  text-align: right;
  padding-right: 15px;
}
.dutyplan .tab table td .dateinfo {
  width: 100%;
  overflow: hidden;
}
.dutyplan .tab table td .departmentinfo {
  font-size: 0.8em;
  opacity: 0.85;
  display: block;
  line-height: 15px;
}
.dutyplan .tab table td .hours {
  width: 95px;
  overflow: hidden;
}
.dutyplan .tab table td .shifttypealias {
  font-size: 0.8em;
  opacity: 0.85;
  display: block;
  line-height: 15px;
}
.dutyplan .header {
  font-size: 24px;
  text-transform: uppercase;
  margin: 20px;
  text-align: center;
}
.dutyplan .footer {
  position: relative;
  bottom: -20px;
  font-size: 14px;
  text-align: center;
  width: 100%;
}
.dutyplan .section {
  margin-bottom: 10px;
}
.dutyplan .section .section-header {
  background-color: #eee;
  line-height: 40px;
  padding-left: 20px;
}
.dutyplan .section .section-header.inactive {
  color: #ccc;
}
.dutyplan .section table td {
  line-height: 36px;
  padding-left: 15px;
}
.dutyplan .section table td.hours {
  text-align: right;
  padding-right: 15px;
}
.dutyplan .guide {
  padding: 20px;
}
.dutyplan .goto-system {
  margin-top: 20px;
  text-align: center;
}
.dutyplan-department .header {
  font-size: 16px;
  margin: 15px;
  text-align: center;
}
.dutyplan-department .header .departmentname {
  display: block;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 4px;
}
.dutyplan-department .list .user {
  overflow: hidden;
  padding-bottom: 14px;
}
.dutyplan-department .list .details .name {
  text-align: left;
  font-weight: 600;
}
.dutyplan-department .list .details .title {
  text-align: left;
  font-size: 0.8em;
  opacity: 0.85;
}
.dutyplan-department .list .details .hours {
  text-align: left;
}
.dutyplan-department .list .details .shifttypealias {
  font-size: 0.8em;
  opacity: 0.85;
  display: block;
  line-height: 15px;
}
.shiftType-OnDuty {
  background-color: #e0f7fb;
}
.shiftType-Absence {
  background-color: #fbfbe0;
}
.instore.instore-guide h2 {
  text-transform: uppercase;
  font-size: 1.05em;
  margin: 0;
  padding: 10px;
}
.instore.instore-guide .bodytext {
  padding: 0 10px;
  margin-bottom: 10px;
}
.instore.instore-guide .media-list media {
  margin-bottom: 5px;
}
.instore.instore-upload {
  padding: 10px;
}
.instore.instore-upload h2 {
  text-transform: uppercase;
  font-size: 1.05em;
  margin: 0;
  padding: 0;
}
.instore.instore-upload form {
  display: block;
  margin: 15px 0 0 0;
  border: 1px solid #fff;
  border-color: #c5cad1;
}
.instore.instore-upload form select {
  width: 100%;
  display: block;
  line-height: 32px;
  height: 32px;
  border: none;
  border-bottom: 1px solid #fff;
  border-color: #c5cad1;
}
.instore.instore-upload form textarea {
  display: block;
  border: none;
  padding: 10px;
  width: 100%;
  min-height: 150px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  max-width: 100%;
}
.instore.instore-upload form .form-bottom {
  display: block;
  position: relative;
  height: 50px;
  padding: 0;
  line-height: 50px;
  margin: 0;
  background: #e1e4e7;
}
.instore.instore-upload form .form-bottom button,
.instore.instore-upload form .form-bottom media-upload {
  display: inline-block;
  height: 50px;
  line-height: 50px;
  padding: 0 10px;
  margin: 0;
  border: 0;
  text-align: center;
  font-size: 26px;
  background: none;
  color: #a8b0ba;
}
.instore.instore-upload form .form-bottom button[type=submit],
.instore.instore-upload form .form-bottom media-upload[type=submit] {
  position: absolute;
  right: 0;
  font-size: 1em;
  padding: 0 15px;
}
.instore.instore-upload .image-viewer {
  margin: 10px 0 0 0;
  width: 100%;
}
.instore.instore-upload .image-viewer > div {
  width: 30%;
  height: 60px;
  display: inline-block;
  vertical-align: top;
  padding: 0 1.5%;
  overflow: hidden;
  margin: 0 0 5px 0;
}
.instore.instore-upload .image-viewer > div img {
  height: 60px;
  width: 100%;
}
.base-view-iframe > .iframe {
  padding-bottom: 0px;
  height: 100%;
  overflow: visible !important;
}
@media only screen and (min-width: 1025px) {
  .base-view-iframe > .iframe {
    height: calc(100vh - 60px);
  }
}
.base-view-iframe > .iframe .iframe-container {
  -webkit-overflow-scrolling: touch;
  position: static;
  height: 100%;
  min-height: 100%;
  overflow: visible;
}
.base-view-iframe > .iframe .iframe-container iframe {
  width: 1px;
  min-width: 100%;
  height: 99%;
  border: 0;
  position: static;
}
.base-view-iframe > .iframe .iframe-launchpage {
  padding: 20px;
  text-align: center;
  display: table;
  height: 100%;
  background-position: center center;
  background-size: cover;
  position: relative;
}
.base-view-iframe > .iframe .iframe-launchpage:after {
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  background: rgba(0, 0, 0, 0.5);
  content: '';
}
.base-view-iframe > .iframe .iframe-launchpage .launchpage-content {
  display: table-cell;
  vertical-align: middle;
  z-index: 2;
  position: relative;
  color: #fff;
}
.base-view-iframe > .iframe .iframe-launchpage .launchpage-content .btn {
  margin-top: 10px;
}
.feedback {
  padding: 10px;
}
@media only screen and (min-width: 1025px) {
  .feedback {
    max-width: none;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
}
.feedback h2 {
  text-transform: uppercase;
  font-size: 1.05em;
  margin: 0;
  padding: 0;
}
@media only screen and (min-width: 1025px) {
  .feedback h2 {
    max-width: 60vw;
    width: 60vw;
    margin: 0 auto 20px;
  }
}
.feedback form {
  display: block;
  margin: 15px 0 0 0;
  border: 1px solid #fff;
  border-color: #c5cad1;
}
@media only screen and (min-width: 1025px) {
  .feedback form {
    max-width: 60vw;
    width: 60vw;
    margin: 0 auto;
  }
}
.feedback form textarea {
  display: block;
  border: none;
  font-size: 15px;
  padding: 10px;
  width: 100%;
  min-height: 150px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  max-width: 100%;
}
.feedback form input[type=text] {
  display: block;
  width: 100%;
  padding: 0 10px;
  font-size: 15px;
  line-height: 32px;
  height: 32px;
  border: none;
  border-bottom: 1px solid #fff;
  border-color: #c5cad1;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.feedback form .form-bottom {
  display: block;
  position: relative;
  height: 50px;
  padding: 0;
  line-height: 50px;
  margin: 0;
  background: #e1e4e7;
}
.feedback form .form-bottom button,
.feedback form .form-bottom .upload-btn {
  display: inline-block;
  height: 50px;
  line-height: 50px;
  padding: 0 10px;
  margin: 0;
  border: 0;
  text-align: center;
  font-size: 22px;
  background: none;
  color: #a8b0ba;
}
.feedback form .form-bottom button[type=submit],
.feedback form .form-bottom .upload-btn[type=submit] {
  position: absolute;
  right: 0;
  font-size: 1em;
  padding: 0 15px;
}
.feedback form .form-bottom button i,
.feedback form .form-bottom .upload-btn i {
  line-height: 50px;
}
.feedback .image-viewer {
  margin: 10px 0 0 0;
  width: 100%;
}
.feedback .image-viewer > div {
  display: inline-block;
  vertical-align: top;
  padding: 0 1.5%;
  overflow: hidden;
  margin: 0 0 5px 0;
}
.feedback .image-viewer > div img {
  height: 60px;
}
.feedback .image-viewer > div .video-preview {
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  height: 60px;
  line-height: 60px;
  width: 100%;
  text-align: center;
  font-size: 30px;
}
.workmood .mood {
  padding: 20px 10px;
}
.workmood .mood h3 {
  font-size: 15px;
  margin: 0;
  padding: 0 20px;
  text-align: center;
}
.workmood .mood .emotions {
  margin: 20px auto 0 auto;
  text-align: center;
}
.workmood .mood .emotions .emotion {
  display: inline-block;
  vertical-align: top;
  margin: 0 0.5%;
  -moz-transition: all ease-in-out 0.5s;
  -o-transition: all ease-in-out 0.5s;
  -webkit-transition: all ease-in-out 0.5s;
  transition: all ease-in-out 0.5s;
  overflow: hidden;
}
.workmood .mood .emotions .emotion.hidden {
  max-width: 0 !important;
}
.workmood .mood .emotions .emotion img {
  width: 100%;
  height: auto;
}
.workmood .mood .emotions .emotion.selected {
  width: 150px !important;
  max-width: 150px !important;
}
.workmood .mood .emotions.count-4 .emotion {
  width: 23%;
  max-width: 23%;
}
.workmood .mood .emotions.count-5 .emotion {
  width: 17%;
  max-width: 17%;
}
.workmood .mood-splitter {
  display: block;
  width: 100%;
  height: 20px;
  background: #eff1f3;
  border-top: 1px solid #d3d7dc;
  border-bottom: 1px solid #d3d7dc;
}
.workmood .mood-stats {
  padding: 20px 10px;
}
.workmood .mood-stats h3 {
  font-size: 15px;
  margin: 0;
  padding: 0;
  text-transform: uppercase;
}
.workmood .mood-stats canvas {
  margin-top: 15px;
}
.workmood .mood-stats .workmood-chart {
  margin-top: 25px;
  position: relative;
  overflow: hidden;
  margin-left: -10px;
  margin-right: -10px;
}
.member-card {
  padding: 10px;
}
.member-card .tc .btn {
  width: 100%;
  margin-bottom: 10px;
  cursor: default;
}
.member-card .cardcontainer {
  position: relative;
}
.member-card .cardcontainer .cardimage {
  position: relative;
}
.member-card .cardcontainer .cardimage img {
  width: 100%;
}
.member-card .cardcontainer .cardtext {
  position: absolute;
  color: #fff;
  text-align: center;
  width: 100%;
  top: 0px;
  left: 0px;
}
.member-card .cardcontainer .cardtext .text-xl {
  font-size: 2.5em;
}
.member-card .cardcontainer .cardtext .text-sm {
  font-size: 0.7em;
}
.member-card .cardcontainer .cardtext .margin-lg {
  margin-bottom: 20px;
}
.member-card .cardcontainer .cardtext .margin-md {
  margin-bottom: 10px;
}
.member-card .cardcontainer .cardtext .margin-sm {
  margin-bottom: 5px;
}
.member-card .cardcontainer .cardtext barcode {
  display: flex;
  justify-content: center;
  margin: 10px 0;
}
.member-card .cardcontainer .cardtext barcode img {
  max-width: 100%;
}
.member-card .cardinfo .showTC {
  margin-top: 10px;
  text-align: center;
  display: block;
  color: gray;
}
.form-module-reportlist h2 {
  font-size: 16px;
  padding: 0 10px;
  margin: 20px 0 10px 0;
  text-align: center;
}
.form-module-reportlist .form-module-reportlist-list {
  display: block;
  list-style: none;
  padding: 0;
  margin: 0;
}
.form-module-reportlist .form-module-reportlist-list > li {
  display: block;
  border-bottom: 1px solid #eee;
  padding: 10px 10px;
  position: relative;
  overflow: hidden;
}
.form-module-reportlist .form-module-reportlist-list > li .texts {
  display: block;
  float: left;
  overflow: hidden;
  max-width: 70%;
  min-height: 40px;
}
.form-module-reportlist .form-module-reportlist-list > li .texts h3 {
  margin: 0;
  padding: 0;
  font-size: 16px;
}
.form-module-reportlist .form-module-reportlist-list > li .texts p {
  margin: 2px 0 0 0;
  padding: 0;
  opacity: 0.7;
  font-size: 13px;
}
.form-module-reportlist .form-module-reportlist-list > li .actions {
  display: block;
  float: right;
  width: 20%;
  height: 40px;
  line-height: 40px;
  text-align: center;
}
.form-module-reportlist .form-module-reportlist-list > li .actions span {
  opacity: 0.5;
  font-size: 16px;
}
.form-module-reportlist .form-module-reportlist-list > li:after {
  display: table;
  clear: both;
  content: '';
}
.form-module-reportlist .form-module-reportlist-list > li a {
  display: inline-block;
  height: 30px;
}
.form-module-reportlist .form-module-reportlist-list > li:last-child {
  border-bottom: none;
}
.form-module-report h2 {
  font-size: 16px;
  padding: 0 10px;
  margin: 20px 0 0 0;
  text-align: center;
}
.form-module-report h3 {
  font-size: 14px;
  padding: 0 10px;
  margin: 4px 0 10px 0;
  text-align: center;
  opacity: 0.6;
}
.form-module-report .download-btn-wrapper {
  display: block;
  padding: 5px 20px;
}
.form-module-report .download-btn-wrapper a {
  border-radius: 5px;
  background: #eee;
  height: 34px;
  line-height: 34px;
  width: 100%;
  display: block;
  position: relative;
  overflow: hidden;
  text-align: center;
  color: #555;
  text-decoration: none;
}
.form-module-report .report {
  display: block;
  padding: 0 10px;
  box-sizing: border-box;
  margin: 10px 0 0 0;
}
.form-module-report .report .report-item {
  border-bottom: 1px solid #eee;
  margin: 10px 0;
  font-size: 13px;
}
.form-module-report .report .report-item h4 {
  display: block;
  font-size: 14px;
  margin: 0 0 5px 0;
  padding: 0;
}
.form-module-report .report .report-item:last-child {
  border-bottom: none;
}
.form-module-report .report .report-item .report-textfield p {
  margin: 0 0 10px 0;
  padding: 0;
  color: #555;
}
.form-module-report .report .report-item .report-checkbox {
  margin-bottom: 10px;
}
.form-module-report .report .report-item .report-checkbox span {
  display: block;
  color: #555;
}
.form-module-report .report .report-item .report-rating {
  margin-bottom: 10px;
}
.form-module-report .report .report-item .report-rating span {
  display: inline-block;
  color: #555;
}
.form-module-report .report .report-item .report-rating i {
  font-style: normal;
  margin: 0 3px;
}
.form-module-report .report .report-item .report-media p {
  margin: 0 0 4px 0;
  padding: 0;
}
.form-module-report .report .report-item .report-media img {
  max-width: 100%;
  margin-bottom: 5px;
}
.native .form-module .form-module-content {
  bottom: 91px;
  bottom: calc(91px + env(safe-area-inset-bottom, 0px));
}
.native .form-module .form-module-footer {
  bottom: 51px;
  bottom: calc(51px + env(safe-area-inset-bottom, 0px));
}
.form-module .form-module-nav {
  display: block;
  position: absolute;
  top: 0;
  left: 5px;
  right: 5px;
  height: 52px;
  text-align: center;
  font-size: 0;
  z-index: 2;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
}
.form-module .form-module-nav .form-module-nav-item {
  display: inline-block;
  height: 50px;
  text-align: center;
  width: 16%;
  min-width: 26px;
  border-bottom: 2px solid transparent;
}
.form-module .form-module-nav .form-module-nav-item .step-status {
  display: inline-block;
  height: 26px;
  width: 26px;
  line-height: 26px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 100%;
  margin-top: 15px;
  vertical-align: top;
  text-align: center;
  color: transparent;
}
.form-module .form-module-nav .form-module-nav-item.active {
  border-color: #000;
}
.form-module .form-module-nav .form-module-nav-item.answered .step-status {
  background-color: #029be5;
  border-color: #029be5;
  color: #fff;
  font-size: 20px;
}
.form-module .form-module-nav .form-module-nav-item.answered .step-status > i {
  position: relative;
  top: 1px;
}
.form-module .form-module-footer {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  bottom: env(safe-area-inset-bottom, 0px);
  height: 40px;
  line-height: 40px;
  z-index: 2;
}
.form-module .form-module-footer > button {
  display: inline-block;
  height: 40px;
  line-height: 40px;
  position: absolute;
  top: 0;
  left: 0;
  padding: 0 10px;
  border: none;
  background: none;
  font-size: 15px;
}
.form-module .form-module-footer .next-btn {
  right: 0;
  left: auto;
}
.form-module .form-module-footer > p {
  display: block;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 0.9em;
  margin: 0;
  padding: 0;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.4);
}
.form-module .form-module-footer > p > span {
  margin-left: 3px;
  font-weight: normal;
}
.form-module .form-module-content {
  position: absolute;
  left: 0;
  right: 0;
  top: 52px;
  bottom: 40px;
  bottom: calc(40px + env(safe-area-inset-bottom, 0px));
  z-index: 1;
  overflow-x: hidden;
}
.form-module .form-module-step {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  bottom: 0;
  padding: 20px 10px 20px 10px;
  box-sizing: border-box;
  transition: all ease-in-out 0.5s, opacity linear 1ms;
}
.form-module .form-module-step.inactive {
  opacity: 0;
}
.form-module .form-module-step.prev {
  left: -100%;
}
.form-module .form-module-step.next {
  left: 100%;
}
.form-module .form-module-step .form-module-question {
  display: block;
}
.form-module .form-module-step .form-module-question:not(:last-of-type) {
  margin-bottom: 15px;
}
.form-module .form-module-step .form-module-question .form-module-question-media {
  margin-bottom: 10px;
}
.form-module .form-module-step .form-module-question .form-module-single-question {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-line-pack: center;
  align-content: center;
  position: absolute;
  top: 0;
  left: 20px;
  right: 20px;
  bottom: 0;
}
.form-module .form-module-step .form-module-question .form-module-single-question > div {
  display: block;
  width: 100%;
}
.form-module .form-module-step .form-module-question h3 {
  margin: 0 0 10px 0;
  font-size: 1rem;
}
.form-module .form-module-step .form-module-question h3.text-center {
  text-align: center;
}
.form-module .form-module-question-checkbox table {
  margin: 10px 0 20px 0;
}
.form-module .form-module-question-checkbox table tr td {
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}
.form-module .form-module-question-checkbox table tr td:first-child {
  width: 40px;
}
.form-module .form-module-question-checkbox table tr span {
  color: rgba(0, 0, 0, 0.3);
  margin-left: 15px;
  font-size: 1rem;
}
.form-module .form-module-question-checkbox table tr.picked .checkbox-circle {
  border-color: #029be5;
  background-color: #029be5;
  color: #fff;
}
.form-module .form-module-question-checkbox table tr.picked span {
  color: #000;
}
.form-module .form-module-question-checkbox table tr:last-child td {
  border-bottom-color: transparent;
}
.form-module .form-module-question-checkbox .checkbox-circle {
  display: block;
  height: 36px;
  width: 36px;
  line-height: 36px;
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 100%;
  background-color: transparent;
  margin: 13px 0;
  color: transparent;
  font-size: 30px;
}
.form-module .form-module-question-textfield textarea {
  border: none;
  display: block;
  width: 100%;
  box-sizing: border-box;
  background: rgba(0, 0, 0, 0.06);
  border-radius: 3px;
  font-size: 1rem;
  padding: 8px;
  margin-bottom: 30px;
}
.form-module .form-module-question-textfield input[type=text] {
  border: none;
  display: block;
  width: 100%;
  box-sizing: border-box;
  background: rgba(0, 0, 0, 0.06);
  border-radius: 3px;
  font-size: 1rem;
  padding: 8px;
  margin-bottom: 30px;
}
.form-module .form-module-question-rating {
  text-align: center;
  margin-top: 30px;
  padding-bottom: 20px;
  overflow: hidden;
  margin-bottom: 30px;
}
.form-module .form-module-question-rating button {
  display: inline-block;
  border: none;
  height: 50px;
  width: 50px;
  line-height: 50px;
  vertical-align: top;
  background-color: transparent;
  padding: 0;
  position: relative;
  overflow: visible;
}
.form-module .form-module-question-rating button i {
  font-size: 50px;
  color: rgba(0, 0, 0, 0.12);
}
.form-module .form-module-question-rating button > span {
  opacity: 0;
}
.form-module .form-module-question-rating button.activated i {
  color: #fec61a;
}
.form-module .form-module-question-rating button.picked > span {
  opacity: 1;
  line-height: 20px;
  width: 100px;
  position: absolute;
  top: 100%;
  left: -25px;
  display: block;
  text-align: center;
  font-weight: 500;
}
.form-module .form-module-question-media table {
  margin: 10px 0 20px 0;
}
.form-module .form-module-question-media table tr td {
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  padding: 14px 0;
}
.form-module .form-module-question-media table tr td.td-image {
  width: 50px;
}
.form-module .form-module-question-media table tr td.td-image .image-container {
  display: block;
  width: 40px;
  height: 40px;
  overflow: hidden;
  border-radius: 100%;
  box-sizing: border-box;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.form-module .form-module-question-media table tr td.td-desc p {
  display: block;
  margin: 0;
  padding: 0;
  font-size: 0.8rem;
  color: rgba(0, 0, 0, 0.5);
}
.form-module .form-module-question-media table tr td.td-upload {
  text-align: right;
}
.form-module .form-module-question-media table tr td.td-upload media-upload {
  display: inline-block;
  height: 30px;
  line-height: 30px;
  background: rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  padding: 0 15px;
  text-align: center;
}
.form-module .form-module-question-media table tr td.td-upload media-upload span {
  color: rgba(0, 0, 0, 0.5);
}
.form-module .form-module-question-media table tr:last-child td {
  border-bottom-color: transparent;
}
.christmas-calendar {
  background: #cc3333;
  padding-bottom: 0;
  overflow: hidden;
}
.christmas-calendar.advent .tile-container .tile {
  width: 43%;
}
.christmas-calendar.advent .tile-container .tile .tile-content .tile-body .number {
  font-size: 40px;
}
.christmas-calendar.advent .tile-container .tile .tile-content .tile-body.no-icon .icon-container {
  display: none;
}
.christmas-calendar.advent .tile-container .tile .tile-content .tile-open-body {
  font-size: 40px;
}
.christmas-calendar.normal-25-days .tile-container .tile:last-child {
  width: 91%;
}
.christmas-calendar.normal-25-days .tile-container .tile:last-child .tile-content {
  padding-bottom: 20.88%;
}
.christmas-calendar .short-intro {
  background-color: #fff;
  padding: 6px;
  text-align: center;
  color: #000;
  position: relative;
  z-index: 3;
}
.christmas-calendar .tile-container {
  padding-top: 10px;
  padding-left: 2.5%;
  position: relative;
  z-index: 3;
}
.christmas-calendar .tile-container .tile {
  display: inline-block;
  width: 19%;
  margin: 2% 2.5%;
  vertical-align: top;
}
.christmas-calendar .tile-container .tile .tile-content {
  width: 100%;
  padding-bottom: 100%;
  position: relative;
  perspective: 100px;
  border: 2px solid #fff;
  border-radius: 4px;
  color: #fff;
}
.christmas-calendar .tile-container .tile .tile-content .tile-body {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  transition: transform 0.5s;
  transform-style: preserve-3d;
  transform-origin: left center;
}
.christmas-calendar .tile-container .tile .tile-content .tile-body.open {
  background: #cc3333;
  transform: rotateY(85deg);
}
.christmas-calendar .tile-container .tile .tile-content .tile-body.no-icon .number {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  font-size: 24px;
}
.christmas-calendar .tile-container .tile .tile-content .tile-body.no-icon .icon-container {
  display: none;
}
.christmas-calendar .tile-container .tile .tile-content .tile-body .number {
  font-size: 13px;
  font-weight: 600;
  position: absolute;
  top: 3px;
  left: 5px;
  z-index: 2;
}
.christmas-calendar .tile-container .tile .tile-content .tile-body .icon-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.christmas-calendar .tile-container .tile .tile-content .tile-body .icon-container .icon {
  width: 55%;
  height: 55%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.christmas-calendar .tile-container .tile .tile-content .tile-body .icon-container .icon > img {
  display: block;
  max-height: 100%;
  max-width: 100%;
}
.christmas-calendar .tile-container .tile .tile-content .tile-open-body {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #000;
  font-size: 20px;
  font-weight: 600;
  background: #fff;
}
.christmas-calendar .tile-container .tile .tile-content .inactive-tile {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.3);
  z-index: 3;
}
.christmas-calendar .backgrounds {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.christmas-calendar .backgrounds > div {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  opacity: 0;
  background-position: center center;
  background-size: cover;
  transition: opacity ease-in-out 1s;
}
.christmas-calendar .backgrounds > div.active {
  z-index: 2;
  opacity: 1;
}
.christmas-calendar .background-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  background: rgba(0, 0, 0, 0.3);
}
.christmas-calendar-day {
  color: #fff;
}
.christmas-calendar-day .christmas-calendar-day-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  overflow: hidden;
}
.christmas-calendar-day .christmas-calendar-day-container .day-tile {
  position: relative;
  width: 100%;
  height: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-bottom-color: #fff;
  overflow: hidden;
  padding: 20px;
}
.christmas-calendar-day .christmas-calendar-day-container .day-tile h2 {
  margin: 0 0 5px 0;
  padding: 0;
  font-size: 26px;
}
.christmas-calendar-day .christmas-calendar-day-container .day-tile p {
  font-size: 18px;
  margin: 0;
  padding: 0;
  text-align: center;
}
.christmas-calendar-day .christmas-calendar-day-container .day-tile:last-child {
  border-bottom: none;
}
.employee-evaluation .employee-evaluation-list-view,
.employee-evaluation .employee-evaluation-select-view {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  overflow-x: hidden;
  overflow-y: auto;
  transition: transform ease-in-out 0.5s;
}
.employee-evaluation .employee-evaluation-select-view {
  transform: translate3d(0, 0, 0);
  display: flex;
  flex-direction: column;
}
.employee-evaluation .employee-evaluation-select-view p {
  text-align: center;
  margin: 20px 0 0 0;
}
.employee-evaluation .employee-evaluation-select-view button {
  display: block;
  position: relative;
  overflow: hidden;
  margin: 20px 10px 0 10px;
  padding: 40px 0;
  text-align: center;
  font-size: 18px;
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  font-weight: 600;
}
.employee-evaluation .employee-evaluation-select-view button i {
  font-size: 40px;
}
.employee-evaluation .employee-evaluation-select-view button span {
  display: block;
}
.employee-evaluation .employee-evaluation-list-view {
  transform: translate3d(100%, 0, 0);
}
.employee-evaluation .employee-evaluation-type-selected .employee-evaluation-select-view {
  transform: translate3d(-100%, 0, 0);
}
.employee-evaluation .employee-evaluation-type-selected .employee-evaluation-list-view {
  transform: translate3d(0, 0, 0);
}
.employee-evaluation .employee-evaluation-employee-list ul,
.employee-evaluation .employee-evaluation-supervisor-list ul {
  display: block;
  list-style: none;
  padding: 0;
  margin: 0;
}
.employee-evaluation .employee-evaluation-employee-list ul li,
.employee-evaluation .employee-evaluation-supervisor-list ul li {
  padding: 12px 10px;
  border-bottom: 1px solid #eee;
  position: relative;
  height: 30px;
  line-height: 30px;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
}
.employee-evaluation .employee-evaluation-employee-list ul li:last-child,
.employee-evaluation .employee-evaluation-supervisor-list ul li:last-child {
  border: none;
}
.employee-evaluation .employee-evaluation-employee-list ul li h3,
.employee-evaluation .employee-evaluation-supervisor-list ul li h3 {
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-weight: normal;
  text-transform: capitalize;
  display: inline-block;
  vertical-align: middle;
}
.employee-evaluation .employee-evaluation-employee-list ul li .item-smiley,
.employee-evaluation .employee-evaluation-supervisor-list ul li .item-smiley {
  display: inline-block;
  height: 26px;
  width: 26px;
  margin-right: 8px;
  vertical-align: middle;
}
.employee-evaluation .employee-evaluation-employee-list ul li .item-smiley img,
.employee-evaluation .employee-evaluation-supervisor-list ul li .item-smiley img {
  height: 100%;
  width: 100%;
}
.employee-evaluation .employee-evaluation-employee-list ul li .item-left-content,
.employee-evaluation .employee-evaluation-supervisor-list ul li .item-left-content {
  -webkit-box-flex: 1;
  flex: 1 1 auto;
}
.employee-evaluation .employee-evaluation-employee-list ul li .item-right-content,
.employee-evaluation .employee-evaluation-supervisor-list ul li .item-right-content {
  height: 30px;
  line-height: 30px;
  text-align: right;
  -webkit-box-flex: 1;
  flex: 1 0 auto;
}
.employee-evaluation .employee-evaluation-employee-list ul li .item-right-content .chkSwitch,
.employee-evaluation .employee-evaluation-supervisor-list ul li .item-right-content .chkSwitch {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  top: 2px;
  margin-right: 8px;
}
.employee-evaluation .employee-evaluation-employee-list ul li .item-right-content a,
.employee-evaluation .employee-evaluation-supervisor-list ul li .item-right-content a {
  display: inline-block;
  height: 30px;
  line-height: 30px;
  padding: 0 14px;
  background: #efefef;
  border-radius: 3px;
  text-align: center;
  font-size: 14px;
  position: relative;
  overflow: hidden;
  vertical-align: middle;
}
.employee-evaluation .employee-evaluation-employee-list ul li .item-right-content a.transparent,
.employee-evaluation .employee-evaluation-supervisor-list ul li .item-right-content a.transparent {
  opacity: 0;
}
.employee-evaluation .employee-evaluation-employee-list ul li.list-header,
.employee-evaluation .employee-evaluation-supervisor-list ul li.list-header {
  line-height: 20px;
  height: 20px;
  background: #eee;
  border-bottom: 1px solid #ddd;
  text-transform: capitalize;
}
.employee-evaluation .employee-evaluation-employee-list ul li.list-header h2,
.employee-evaluation .employee-evaluation-supervisor-list ul li.list-header h2 {
  margin: 0;
  padding: 0;
  font-weight: 600;
  font-size: 15px;
}
.employee-evaluation .employee-evaluation-supervisor-list ul li {
  padding: 12px 10px 3px 10px;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  height: auto;
  line-height: normal;
}
.employee-evaluation .employee-evaluation-supervisor-list ul li .item-left-content {
  line-height: 20px;
}
.employee-evaluation .employee-evaluation-supervisor-list ul li .item-left-content h3,
.employee-evaluation .employee-evaluation-supervisor-list ul li .item-left-content h4 {
  padding: 0;
  margin: 0;
  position: relative;
  display: block;
  word-break: break-word;
}
.employee-evaluation .employee-evaluation-supervisor-list ul li .item-left-content h3 {
  padding: 0;
  margin: 5px 0;
  font-weight: 600;
  top: -3px;
  line-height: 13px;
}
.employee-evaluation .employee-evaluation-supervisor-list ul li .item-left-content h4 {
  font-weight: normal;
  font-size: 13px;
  top: -6px;
}
.employee-evaluation .employee-evaluation-supervisor-list ul li .item-right-content {
  margin: 0;
  min-width: 140px;
  max-width: 140px;
}
.employee-evaluation .employee-evaluation-supervisor-list ul li .item-right-content .chkSwitch {
  margin: 0;
}
.employee-evaluation .employee-evaluation-supervisor-list ul li .item-right-content .attachment-btn {
  width: 30px;
  padding: 0;
  border-radius: 100%;
  transform: rotate3d(0, 0, 1, -90deg);
  font-size: 16px;
  right: -3px;
  background: none;
}
.employee-evaluation .employee-evaluation-supervisor-list ul li .item-right-content .attachment-btn:not(.has-attachment) {
  color: #ddd;
}
.employee-evaluation .employee-evaluation-supervisor-list ul li .item-right-content .attachment-btn.hide-btn {
  opacity: 0;
}
.employee-evaluation .employee-evaluation-supervisor-list ul li:after {
  display: table;
  clear: both;
  content: '';
}
.employee-evaluation-view table {
  width: 100%;
  box-sizing: border-box;
  border-left: solid 8px transparent;
  border-right: solid 8px transparent;
}
.employee-evaluation-view table tr {
  border: none;
}
.employee-evaluation-view table tr td {
  padding: 13px 2px;
  border: none;
  box-sizing: border-box;
  border-bottom: 1px solid #eee;
}
.employee-evaluation-view table tr td:first-child {
  width: 40%;
  font-weight: 600;
  overflow: hidden;
}
.employee-evaluation-view table tr td:last-child {
  width: 60%;
  text-align: right;
}
.employee-evaluation-view table tr td .capitalize {
  text-transform: capitalize;
}
.employee-evaluation-view table tr td .report-smiley {
  width: 26px;
  height: 26px;
  display: inline-block;
  vertical-align: middle;
}
.employee-evaluation-view table tr td .report-smiley img {
  height: 100%;
  width: 100%;
}
.employee-evaluation-view table tr td .chkSwitch {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  margin-left: 8px;
}
.employee-evaluation-view .attachment-container {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}
.employee-evaluation-view .attachment-container a {
  display: block;
  margin: 0 10px 10px 10px;
  background: #eee;
  text-align: center;
  line-height: 36px;
  position: relative;
  overflow: hidden;
  border-radius: 4px;
}
.todo-module .todo-module-inner {
  padding: 10px;
  position: relative;
  z-index: 3;
}
.todo-module .todo-module-inner .calendar {
  margin-bottom: 20px;
}
.todo-module .todo-module-inner .links button {
  display: block;
  height: 46px;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.18);
  position: relative;
  overflow: hidden;
  line-height: 46px;
  text-transform: uppercase;
  font-weight: 600;
  padding: 0 5px;
  margin: 0;
  box-sizing: border-box;
  background: transparent;
  width: 100%;
  text-align: left;
}
.todo-module .todo-module-inner .links button:before {
  position: absolute;
  height: 46px;
  line-height: 46px;
  content: "\f054";
  font-family: 'Font Awesome\ 6 Pro';
  right: 5px;
}
.todo-module .todo-module-inner .links button:first-child {
  border-top: 1px solid rgba(0, 0, 0, 0.18);
}
.todo-module .todo-module-inner .todo-module-footer-image {
  margin: 20px 0 0 0;
}
.todo-day {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 200;
  background: var(--popup-overlay-bg);
  transition: all 0.3s ease-in-out;
}
.todo-day .todo-day-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.todo-day .todo-day-wrapper ks-swiper-container,
.todo-day .todo-day-wrapper ks-swiper-container .swiper-container,
.todo-day .todo-day-wrapper .todo-day-slide {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: block;
  transition: all 0.3s ease-in-out;
}
.todo-day .todo-day-wrapper ks-swiper-container.ng-enter,
.todo-day .todo-day-wrapper ks-swiper-container .swiper-container.ng-enter,
.todo-day .todo-day-wrapper .todo-day-slide.ng-enter,
.todo-day .todo-day-wrapper ks-swiper-container.ng-leave.ng-leave-active,
.todo-day .todo-day-wrapper ks-swiper-container .swiper-container.ng-leave.ng-leave-active,
.todo-day .todo-day-wrapper .todo-day-slide.ng-leave.ng-leave-active {
  opacity: 0;
}
.todo-day .todo-day-wrapper ks-swiper-container.ng-leave,
.todo-day .todo-day-wrapper ks-swiper-container .swiper-container.ng-leave,
.todo-day .todo-day-wrapper .todo-day-slide.ng-leave,
.todo-day .todo-day-wrapper ks-swiper-container.ng-enter.ng-enter-active,
.todo-day .todo-day-wrapper ks-swiper-container .swiper-container.ng-enter.ng-enter-active,
.todo-day .todo-day-wrapper .todo-day-slide.ng-enter.ng-enter-active {
  opacity: 1;
}
.todo-day .todo-day-wrapper .todo-day-slide .todo-day-slide-inner {
  position: absolute;
  left: 10px;
  right: 10px;
  bottom: 10px;
  top: 10px;
  display: block;
  transform: translate3d(0, 0, 0);
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.todo-day .todo-day-wrapper .todo-day-slide .todo-day-slide-inner .day {
  display: block;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100px;
  line-height: 100px;
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
}
.todo-day .todo-day-wrapper .todo-day-slide .todo-day-slide-inner h2 {
  font-weight: 600;
  font-size: 20px;
  margin: 0;
  padding: 0 10px;
}
.todo-day .todo-day-wrapper .todo-day-slide .todo-day-slide-inner p {
  margin: 20px 0 0 0;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.5);
  padding: 0 10px;
  text-align: center;
}
.todo-day .todo-day-wrapper .todo-day-slide .todo-day-slide-inner .image-container {
  display: block;
  max-width: 160px;
  position: relative;
  margin-bottom: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.todo-day .todo-day-wrapper .todo-day-slide .todo-day-slide-inner .todo-day-pagination {
  display: block;
  width: 100%;
  text-align: center;
  font-size: 0;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 80px;
}
.todo-day .todo-day-wrapper .todo-day-slide .todo-day-slide-inner .todo-day-pagination > .dot {
  background: #000;
  opacity: 0.2;
  display: inline-block;
  vertical-align: middle;
  width: 8px;
  height: 8px;
  margin: 0 5px;
  border-radius: 100%;
}
.todo-day .todo-day-wrapper .todo-day-slide .todo-day-slide-inner .todo-day-pagination > .dot.active {
  opacity: 1;
}
.todo-day .todo-day-wrapper .todo-day-slide .todo-day-slide-inner .button-container {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 60px;
  vertical-align: middle;
  text-align: center;
}
.todo-day .todo-day-wrapper .todo-day-slide .todo-day-slide-inner .button-container .btn {
  margin: 0 5px;
}
.todo-day .todo-day-wrapper .todo-day-slide .todo-day-slide-inner .button-container .btn.positive {
  background-color: #59d68e;
}
.todo-day .todo-day-wrapper .todo-day-slide .todo-day-slide-inner .button-container .btn.negative {
  background-color: #e9806e;
}
.todo-day .todo-day-wrapper .todo-day-close {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  overflow: hidden;
  font-size: 22px;
  color: rgba(0, 0, 0, 0.7);
  background: none;
  border: none;
}
.todo-day.ng-enter,
.todo-day.ng-leave.ng-leave-active {
  opacity: 0;
}
.todo-day.ng-leave,
.todo-day.ng-enter.ng-enter-active {
  opacity: 1;
}
.todo-day .todo-day-endpage {
  position: absolute;
  left: 10px;
  right: 10px;
  bottom: 10px;
  top: 10px;
  display: block;
  transform: translate3d(0, 0, 0);
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;
}
.todo-day .todo-day-endpage.ng-enter,
.todo-day .todo-day-endpage.ng-leave.ng-leave-active {
  opacity: 0;
}
.todo-day .todo-day-endpage.ng-leave,
.todo-day .todo-day-endpage.ng-enter.ng-enter-active {
  opacity: 1;
}
.todo-day .todo-day-endpage .endpage-button-container {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 10px;
  text-align: center;
  z-index: 2;
}
.todo-day .todo-day-endpage .todo-day-endpage-inner {
  padding: 15px 15px 80px 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.todo-day .todo-day-endpage .todo-day-endpage-inner .image-container {
  max-width: 80%;
  margin-bottom: 25px;
}
.todo-day .todo-day-endpage .todo-day-endpage-inner h2 {
  display: block;
  text-align: center;
  margin: 0;
  text-transform: uppercase;
  font-size: 20px;
}
.todo-day .todo-day-endpage .todo-day-endpage-inner p {
  display: block;
  text-align: center;
  margin: 20px 0 0 0;
  font-size: 14px;
}
.todo-module-highscore .highscore-tabs {
  margin-top: 0;
}
.todo-module-highscore .highscore-tabs .highscore-loader {
  display: block;
  height: 200px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.todo-module-highscore .highscore-tabs .highscore-loader .spinner {
  width: 40px;
  height: 40px;
  border-width: 4px;
}
.todo-module-highscore .highscore-tabs .tab .no-content {
  margin: 50px 0;
}
.todo-module-highscore .highscore-tabs .tab table {
  width: 100%;
  margin: 10px 0;
}
.todo-module-highscore .highscore-tabs .tab table tr {
  position: relative;
  overflow: hidden;
}
.todo-module-highscore .highscore-tabs .tab table tr th,
.todo-module-highscore .highscore-tabs .tab table tr td {
  padding: 4px 6px;
  text-align: left;
  position: relative;
}
.todo-module-highscore .highscore-tabs .tab table tr th.rank,
.todo-module-highscore .highscore-tabs .tab table tr td.rank {
  width: 25px;
  text-align: center;
}
.todo-module-highscore .highscore-tabs .tab table tr th.score,
.todo-module-highscore .highscore-tabs .tab table tr td.score {
  width: 50px;
  text-align: center;
}
.todo-module-highscore .highscore-tabs .tab table tr th a,
.todo-module-highscore .highscore-tabs .tab table tr td a {
  display: block;
  position: relative;
  width: 100%;
  overflow: hidden;
}
.todo-module-highscore .highscore-tabs .tab table tr th .multiline-text,
.todo-module-highscore .highscore-tabs .tab table tr td .multiline-text {
  display: block;
  position: absolute;
  left: 44px;
  right: 0;
  top: 8px;
}
.todo-module-highscore .highscore-tabs .tab table tr th .multiline-text p,
.todo-module-highscore .highscore-tabs .tab table tr td .multiline-text p,
.todo-module-highscore .highscore-tabs .tab table tr th .multiline-text span,
.todo-module-highscore .highscore-tabs .tab table tr td .multiline-text span {
  display: block;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0;
}
.todo-module-highscore .highscore-tabs .tab table tr th .multiline-text p,
.todo-module-highscore .highscore-tabs .tab table tr td .multiline-text p {
  font-size: 14px;
}
.todo-module-highscore .highscore-tabs .tab table tr th .multiline-text span,
.todo-module-highscore .highscore-tabs .tab table tr td .multiline-text span {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
  margin-top: -2px;
}
.todo-module-highscore .highscore-tabs .tab table tr th .single-line-text,
.todo-module-highscore .highscore-tabs .tab table tr td .single-line-text {
  display: block;
  position: relative;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
}
.todo-module-highscore .highscore-tabs .tab table tr th .profile-image,
.todo-module-highscore .highscore-tabs .tab table tr td .profile-image {
  width: 30px;
  height: 30px;
}
.todo-module-highscore .highscore-tabs .tab table.department-table tbody tr td {
  padding: 8px 6px;
}
.learningbank-module {
  display: flex;
  height: 100%;
  background-color: #fff;
}
.learningbank-module .spinner {
  margin-top: var(--spacing);
  height: 40px;
  width: 40px;
}
.learningbank-module .learningbank-module-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  font-size: 1rem;
  line-height: 130%;
  margin: 0 auto;
  color: #323251;
  min-width: 100%;
}
.learningbank-module .learningbank-module-inner svg {
  height: 40px;
}
.learningbank-module .learningbank-module-inner .error-msg {
  max-width: 310px;
}
.learningbank-module .learningbank-iframe-container {
  display: flex;
  flex: 1;
}
@media only screen and (min-width: 1025px) {
  .learningbank-module .learningbank-iframe-container {
    max-height: unset;
  }
}
.learningbank-module .learningbank-iframe-container iframe {
  width: 100%;
}
.learningbank-module .module-error {
  transition-delay: 0.5s;
}
.base .base-view-learningbank {
  background: var(--background-highlight-color);
}
.base .base-view-learningbank.base-view {
  height: auto;
}
.learning-bank-overview {
  display: block;
  padding: var(--spacing) var(--spacing) calc(60px + env(safe-area-inset-bottom, 0px));
}
.learning-bank-overview .learningbank-list-item {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: var(--m-spacing);
  background: var(--general-background-color);
  border-radius: var(--general-border-radius);
}
.learning-bank-overview .learningbank-list-item:not(:last-of-type) {
  margin-bottom: var(--spacing);
}
.learning-bank-overview .learningbank-list-item:hover {
  cursor: pointer;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
}
.learning-bank-overview .image {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 53px;
  height: 53px;
  margin-right: var(--spacing);
  border-radius: var(--general-border-radius);
}
.learning-bank-overview .image rl-icon {
  font-size: 25px;
}
.learning-bank-overview .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: var(--general-border-radius);
}
.learning-bank-overview .image:not(.placeholder-shimmer) {
  background: var(--general-border-color);
}
.learning-bank-overview .right {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-self: center;
}
.learning-bank-overview .right h2 {
  font-size: 0.95rem;
  margin: 0 0 5px 0;
  font-weight: 600;
  word-break: break-word;
}
.skillstown-module {
  background-color: #D7F6F3;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.skillstown-module .spinner {
  height: 40px;
  width: 40px;
}
.skillstown-module .skillstown-module-inner {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 100%;
  position: relative;
  z-index: 2;
  min-height: 200px;
  box-sizing: border-box;
  padding: 0 15px;
  text-align: center;
}
.skillstown-module .skillstown-module-inner svg {
  margin-bottom: 25px;
}
.skillstown-module:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  opacity: 0.1;
}
files-preview {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 10px;
  overflow-x: auto;
}
files-preview .file {
  position: relative;
  box-sizing: border-box;
  width: 150px;
  height: 80px;
  margin-right: 10px;
  padding: 10px 5px;
  border-left: 6px solid;
  background-color: rgba(0, 0, 0, 0.1);
  background-size: cover;
}
files-preview .file.image {
  border-color: #f3a560;
  background-color: rgba(243, 165, 96, 0.3);
  color: white;
}
files-preview .file.image:before {
  content: '';
  display: block;
  position: absolute;
  background: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}
files-preview .file.video {
  border-color: #824ed2;
  background-color: rgba(130, 78, 210, 0.3);
}
files-preview .file.pdf {
  border-color: #cb0606;
  background-color: rgba(203, 6, 6, 0.3);
}
files-preview .file.spreadsheet {
  border-color: #1a7343;
  background-color: rgba(26, 115, 67, 0.3);
}
files-preview .file.document {
  border-color: #2c5898;
  background-color: rgba(44, 88, 152, 0.3);
}
files-preview .file.presentation {
  border-color: #cc482e;
  background-color: rgba(204, 72, 46, 0.3);
}
files-preview .file .content {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  height: 100%;
}
files-preview .file .delete-file {
  position: absolute;
  top: 5px;
  right: 5px;
}
files-preview .file span,
files-preview .file a {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 600;
}
files-preview .file span.file-name,
files-preview .file a.file-name {
  padding-bottom: 5px;
}
files-preview .file span.file-type,
files-preview .file a.file-type {
  font-size: 13px;
  width: calc(100% - 15px);
}
files-preview .file a {
  color: #62c0eb;
}
files-preview .file:after {
  content: '';
  display: block;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 20px 20px;
  border-color: transparent transparent #ffffff transparent;
}
autocomplete .search-wrapper {
  position: relative;
}
autocomplete .search-wrapper input {
  height: 40px;
  width: 100%;
  box-sizing: border-box;
  padding: 0 40px 0 10px;
  border-radius: 0;
  line-height: 40px;
  font-size: 15px;
  border: 1px solid var(--background-highlight-color);
  background: var(--background-highlight-color);
  font-weight: 500;
  -webkit-appearance: none;
}
autocomplete .search-wrapper input::placeholder {
  color: var(--general-text-inactive-color);
}
autocomplete .search-wrapper input.valid {
  border: 1px solid var(--highlight-color);
  color: var(--highlight-color);
}
autocomplete .search-wrapper input.invalid {
  border: 1px solid var(--warning-color);
  color: var(--warning-color);
}
autocomplete .search-wrapper rl-icon {
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
}
autocomplete ul {
  margin: 0;
  padding: 0;
}
autocomplete ul li {
  font-size: 15px;
  list-style: none;
}
autocomplete .visible-items {
  max-height: 200px;
  overflow-y: auto;
  overscroll-behavior-y: contain;
  background: var(--background-highlight-color);
}
autocomplete .visible-items li {
  height: 40px;
  padding: 0 10px;
  overflow: hidden;
  box-sizing: border-box;
  text-overflow: ellipsis;
  white-space: nowrap;
}
autocomplete .visible-items li p {
  margin: 0;
  padding: 0;
  line-height: 40px;
}
autocomplete .visible-items li span {
  display: none;
}
autocomplete .visible-items li.with-subtitle p {
  line-height: 26px;
}
autocomplete .visible-items li.with-subtitle span {
  display: block;
  line-height: 14px;
  font-size: 11px;
  opacity: 0.7;
  margin-top: -4px;
}
autocomplete .not-found {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  padding: 20px 40px;
  background: var(--background-highlight-color);
}
autocomplete .not-found icon {
  width: 75px;
  height: 75px;
}
autocomplete .not-found h2 {
  margin: 0 0 10px 0;
}
autocomplete .not-found p {
  margin: 0;
  text-align: center;
}
alert-message {
  display: block;
}
alert-message .alert-text {
  margin: 0;
  padding: 10px 20px;
  color: #3c4858;
  font-weight: 600;
}
alert-message .alert-text.alert {
  border: 1px solid #e1b400;
  background: #FFDA48;
}
alert-message .alert-text.warning {
  border: 1px solid #cd1a1a;
  background: #EB6262;
}
alert-message .alert-text.confirm {
  border: 1px solid #2a885c;
  background: #4FC990;
}
.ui-mention-container {
  position: relative;
}
.ui-mention-container textarea,
.ui-mention-container input {
  background: none;
  position: relative;
  z-index: 2;
  box-sizing: border-box;
}
.ui-mention-container textarea[disabled],
.ui-mention-container input[disabled] {
  opacity: 0.5;
}
.ui-mention-container textarea {
  min-height: 100px;
}
.ui-mention-container input {
  min-height: 40px;
}
.ui-mention-container .ui-mention-highlight {
  display: none;
  padding: 10px;
  white-space: pre-wrap;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  color: rgba(0, 0, 0, 0);
  z-index: 1;
}
.ui-mention-container .ui-mention-highlight.short {
  margin: 2px 0 0 0;
  padding: 0 5px;
  white-space: nowrap;
  overflow: hidden;
}
.ui-mention-container.short .mention-dropdown {
  position: absolute;
  bottom: 47px;
  left: -15px;
  right: -40px;
  max-height: 150px;
  width: auto;
  overflow-y: auto;
}
.mention-dropdown {
  position: fixed;
  z-index: 10;
  left: 0;
  width: 100%;
  max-height: 150px;
  overflow-y: auto;
  margin: 0;
  padding: 0;
  background: var(--general-background-color);
  box-shadow: 0 2px 15px 0 rgba(60, 72, 88, 0.1);
}
.mention-dropdown profile-image .leave-icon {
  bottom: 0;
}
@media only screen and (max-width: 1024px) {
  .mention-dropdown::-webkit-scrollbar {
    -webkit-appearance: none;
  }
  .mention-dropdown::-webkit-scrollbar:vertical {
    width: 11px;
  }
  .mention-dropdown::-webkit-scrollbar:horizontal {
    height: 11px;
  }
  .mention-dropdown::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid var(--general-text-color);
    /* should match background, can't be transparent */
    background-color: rgba(0, 0, 0, 0.5);
  }
}
.mention-dropdown li {
  position: relative;
  display: flex;
  align-items: center;
  padding: 7px 15px;
}
.mention-dropdown li:first-of-type {
  padding-top: 15px;
}
.mention-dropdown li:last-of-type {
  padding-bottom: 15px;
}
.mention-dropdown li p {
  margin: 0;
  line-height: 1;
}
.mention-dropdown li:hover,
.mention-dropdown li.active {
  background: rgba(var(--general-text-color-rgb), 0.1);
}
.mention-dropdown .completed {
  display: none;
  padding: 5px;
  margin-left: auto;
  border-radius: 100%;
  background: #5FA3F1;
  color: white;
  font-size: 10px;
}
.mention-dropdown .content {
  overflow: hidden;
}
.mention-dropdown .name {
  display: flex;
  flex-direction: row;
  margin: 0;
  font-weight: 600;
  padding-bottom: 5px;
  color: var(--general-text-color);
  font-size: 15px;
}
.mention-dropdown .name .tag {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.mention-dropdown .name .featured {
  margin-left: 5px;
  color: var(--alert-color);
}
.mention-dropdown .profile-image {
  width: 34px;
  min-width: 34px;
  height: 34px;
  margin-right: 10px;
}
.mention-dropdown .tag-label {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 34px;
  height: 34px;
  margin-right: 10px;
  font-size: 20px;
  border-radius: 100%;
  color: var(--text-on-highlight-color);
  background: var(--highlight-color);
}
.ec-shopgun-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  z-index: 2;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  padding: 15px;
  box-sizing: border-box;
}
.ec-shopgun-loader .spinner {
  flex: 0 0 auto;
}
.ec-shopgun-loader span {
  flex: 0 0 auto;
  display: block;
  margin-top: 15px;
  font-weight: 600;
}
.ec-shopgun-load-error {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 15px;
  box-sizing: border-box;
  position: relative;
  z-index: 2;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}
.ec-shopgun-load-error span {
  flex: 0 0 auto;
  display: block;
  margin-top: 15px;
  font-weight: 600;
}
.ec-shopgun-list-view ul.ec-shopgun-list-view-ul {
  list-style: none;
  padding: 10px 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.ec-shopgun-list-view ul.ec-shopgun-list-view-ul li {
  width: 100%;
  height: 300px;
  display: block;
  flex: 0 0 auto;
}
@media only screen and (min-width: 560px) {
  .ec-shopgun-list-view ul.ec-shopgun-list-view-ul li {
    width: 50%;
  }
}
@media only screen and (min-width: 900px) {
  .ec-shopgun-list-view ul.ec-shopgun-list-view-ul li {
    width: 33%;
  }
}
@media only screen and (min-width: 1300px) {
  .ec-shopgun-list-view ul.ec-shopgun-list-view-ul li {
    width: 25%;
  }
}
.ec-shopgun-list-view ul.ec-shopgun-list-view-ul li button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 15px;
  box-sizing: border-box;
  text-align: center;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  flex-direction: column;
}
.ec-shopgun-list-view ul.ec-shopgun-list-view-ul li button img {
  display: inline-block;
  max-width: 160px;
  height: auto;
  width: auto;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.ec-shopgun-list-view ul.ec-shopgun-list-view-ul li button h3,
.ec-shopgun-list-view ul.ec-shopgun-list-view-ul li button h4 {
  display: block;
  text-align: center;
}
.ec-shopgun-list-view ul.ec-shopgun-list-view-ul li button h3 {
  font-size: 16px;
  margin: 10px 0 0 0;
}
.ec-shopgun-list-view ul.ec-shopgun-list-view-ul li button h4 {
  font-size: 14px;
  opacity: 0.7;
  margin: 5px 0 0 0;
}
.ec-shopgun-list-view ul.ec-shopgun-list-view-ul li:last-child {
  margin-bottom: 0;
}
.ec-shopgun-page-view ul.ec-shopgun-page-view-ul {
  list-style: none;
  padding: 10px 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.ec-shopgun-page-view ul.ec-shopgun-page-view-ul li {
  width: 50%;
  height: 220px;
  display: block;
  flex: 0 0 auto;
}
@media only screen and (min-width: 1025px) {
  .ec-shopgun-page-view ul.ec-shopgun-page-view-ul li {
    width: 33%;
  }
}
@media only screen and (min-width: 1300px) {
  .ec-shopgun-page-view ul.ec-shopgun-page-view-ul li {
    width: 25%;
  }
}
.ec-shopgun-page-view ul.ec-shopgun-page-view-ul li button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 15px;
  box-sizing: border-box;
  text-align: center;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}
.ec-shopgun-page-view ul.ec-shopgun-page-view-ul li button img {
  display: inline-block;
  max-width: 100%;
  max-height: 100%;
  height: auto;
  width: auto;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.ec-shopgun-catalog-view .shopgun-catalog-container {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 1;
  opacity: 0;
}
.ec-shopgun-catalog-view .shopgun-catalog-container.is-viewing {
  opacity: 1;
}
.embedded-content-module {
  position: relative;
}
power-bi {
  display: flex;
  height: 100%;
  overflow: hidden;
  flex-direction: column;
}
power-bi .powerbi-frame {
  height: 100%;
  width: 100%;
}
power-bi .powerbi-frame iframe {
  border: none;
}
@media only screen and (min-width: 1025px) {
  power-bi.view-page {
    height: calc(100vh - 60px);
    max-width: 100%;
  }
}
power-bi tabset {
  padding-bottom: 0 !important;
}
power-bi tabset .tabset-content {
  display: none !important;
}
.power-bi-report-error {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
}
.power-bi-report-error i {
  font-size: 50px;
  margin-bottom: 10px;
}
.power-bi-report-error span {
  text-align: center;
}
.power-bi-report-print {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
}
how-to-guide .popup-wrapper.open .popup-content {
  left: 10%;
  top: 20%;
  width: 80%;
  height: 60%;
}
@media only screen and (min-width: 1025px) {
  how-to-guide .popup-wrapper.open .popup-content {
    left: 50%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  how-to-guide .popup-wrapper.open .popup-content {
    left: 50%;
    width: 100%;
  }
}
how-to-guide .how-to-guide {
  text-align: center;
}
how-to-guide .how-to-guide media .image img {
  width: 100%;
  object-fit: cover;
}
how-to-guide .how-to-guide rl-rich-preview {
  margin: 15px 15px 0 15px;
}
how-to-guide .how-to-guide button {
  color: #5FA3F1;
  margin-top: 10px;
  margin-bottom: 35px;
}
.search-field {
  width: 100%;
  height: 100%;
  text-align: left;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  transform: translateX(calc(100% - 50px));
}
.search-field.ng-animate {
  animation-duration: 0.5s;
  animation-name: bounce-close;
  animation-fill-mode: forwards;
  transition: transform 0.3s;
}
.search-field.ng-leave {
  display: none;
}
.search-field button {
  width: 45px;
  padding: 0;
}
.search-field.active {
  animation-duration: 0.5s;
  animation-name: bounce-open;
  animation-fill-mode: forwards;
}
.search-field.active input {
  opacity: 1;
}
.search-field input {
  font-size: 15px;
  padding: 0;
  background: none;
  border: none;
  width: 75%;
  opacity: 0;
}
@media only screen and (min-width: 1025px) {
  .search-field input {
    width: 100%;
  }
}
.search-field input::-webkit-input-placeholder {
  color: rgba(var(--header-text-color-rgb), 0.4);
}
.search-field input::-moz-placeholder {
  color: rgba(var(--header-text-color-rgb), 0.4);
}
.search-field input:-moz-placeholder {
  color: rgba(var(--header-text-color-rgb), 0.4);
}
.search-field input:-ms-input-placeholder {
  color: rgba(var(--header-text-color-rgb), 0.4);
}
@keyframes bounce-open {
  0% {
    transform: translateX(calc(100% - 50px));
  }
  30% {
    transform: translateX(-2px);
  }
  70%,
  100% {
    transform: translateX(0);
  }
  45% {
    transform: translateX(6px);
  }
  75% {
    transform: translateX(1px);
  }
}
@keyframes bounce-close {
  0% {
    transform: translateX(0);
  }
  30% {
    transform: translateX(calc(100% - 48px));
  }
  70%,
  100% {
    transform: translateX(calc(100% - 50px));
  }
  45% {
    transform: translateX(calc(100% - 56px));
  }
  75% {
    transform: translateX(calc(100% - 51px));
  }
}
.rl-header h1 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 17px;
  text-transform: uppercase;
  margin: 0;
}
.rl-header h2 {
  font-size: 12px;
  margin: 0;
}
.rl-header > a {
  line-height: 55px;
  position: relative;
  height: 100%;
  width: 45px;
  padding: 0;
  color: var(--header-text-color);
  text-align: center;
}
.rl-header > a rl-icon {
  font-size: 20px;
  color: var(--header-text-color);
}
.rl-header > a[disabled] rl-icon {
  opacity: 0.4;
}
.rl-header > a .fa-ellipsis-v {
  font-size: 28px;
}
.rl-header > button {
  position: relative;
  height: 100%;
  width: 45px;
  padding: 0;
  color: var(--header-text-color);
  text-align: center;
}
.rl-header > button rl-icon {
  font-size: 20px;
  color: var(--header-text-color);
}
.rl-header > button[disabled] rl-icon {
  opacity: 0.4;
}
.rl-header > button .fa-ellipsis-v {
  font-size: 28px;
}
.rl-header .base-top-right-buttons > button,
.rl-header .base-top-left-buttons > button {
  flex: 0 0 auto;
}
.rl-header .base-top-right-buttons > button,
.rl-header .base-top-left-buttons > button,
.rl-header .header-popup-wrapper > button,
.rl-header .app-header-dropdown-wrapper > button,
.rl-header filter-button > button,
.rl-header .left-buttons > button,
.rl-header .right-buttons > button {
  position: relative;
  height: 100%;
  width: 45px;
  padding: 0;
  color: var(--header-text-color);
  text-align: center;
}
.rl-header .base-top-right-buttons > button rl-icon,
.rl-header .base-top-left-buttons > button rl-icon,
.rl-header .header-popup-wrapper > button rl-icon,
.rl-header .app-header-dropdown-wrapper > button rl-icon,
.rl-header filter-button > button rl-icon,
.rl-header .left-buttons > button rl-icon,
.rl-header .right-buttons > button rl-icon {
  font-size: 20px;
  color: var(--header-text-color);
}
.rl-header .base-top-right-buttons > button[disabled] rl-icon,
.rl-header .base-top-left-buttons > button[disabled] rl-icon,
.rl-header .header-popup-wrapper > button[disabled] rl-icon,
.rl-header .app-header-dropdown-wrapper > button[disabled] rl-icon,
.rl-header filter-button > button[disabled] rl-icon,
.rl-header .left-buttons > button[disabled] rl-icon,
.rl-header .right-buttons > button[disabled] rl-icon {
  opacity: 0.4;
}
.rl-header .base-top-right-buttons > button .fa-ellipsis-v,
.rl-header .base-top-left-buttons > button .fa-ellipsis-v,
.rl-header .header-popup-wrapper > button .fa-ellipsis-v,
.rl-header .app-header-dropdown-wrapper > button .fa-ellipsis-v,
.rl-header filter-button > button .fa-ellipsis-v,
.rl-header .left-buttons > button .fa-ellipsis-v,
.rl-header .right-buttons > button .fa-ellipsis-v {
  font-size: 28px;
}
attachment-preview {
  overflow: hidden;
  width: 100%;
}
attachment-preview.ng-invalid:not(.ng-pristine) .preview-item-add {
  border-color: rgba(var(--warning-color-rgb), 0.7);
}
attachment-preview .preview-items {
  display: flex;
  width: 100%;
  overflow-x: auto;
  padding: 0;
  -webkit-overflow-scrolling: touch;
  position: relative;
}
attachment-preview .preview-items.square {
  width: 100%;
  height: 190px;
  box-sizing: border-box;
  background: rgba(var(--general-text-color-rgb), .1);
}
attachment-preview .preview-items.square .btn-edit {
  position: absolute;
  background: rgba(var(--general-text-color-rgb), .1);
  color: white;
  right: 5px;
  top: 5px;
  border-radius: 50%;
  padding: 5px;
  pointer-events: none;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
}
attachment-preview .preview-items.square .btn-edit rl-icon {
  font-size: 15px;
}
attachment-preview .preview-items.square .unseen-images-counter {
  position: absolute;
  height: 30px;
  width: 30px;
  font-size: 13px;
  color: white;
  border-radius: 50%;
  background: rgba(var(--general-text-color-rgb), .6);
  right: calc(25% - 16px);
  bottom: calc(25% - 16px);
  line-height: 30px;
  text-align: center;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
}
attachment-preview .preview-items.square .unseen-images-counter svg {
  position: absolute;
  top: 0;
  left: 0;
  transition: 0.35s stroke-dashoffset;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
  stroke-dasharray: 87.964, 87.964;
  stroke-dashoffset: 87.964;
}
attachment-preview .preview-items.square .unseen-images-counter svg circle {
  stroke: white;
}
attachment-preview .preview-items.square.square-1 .preview-item:not(.preview-item-add) {
  width: 100%;
  height: 100%;
  margin: 0;
  border: none;
  border-radius: 0;
}
attachment-preview .preview-items.square.square-2 .preview-item:not(.preview-item-add) {
  width: calc(50% - 2px);
  height: 100%;
  margin: 0;
  border: none;
  border-radius: 0;
}
attachment-preview .preview-items.square.square-2 .preview-item:not(.preview-item-add):nth-of-type(1) {
  margin-right: 2px;
}
attachment-preview .preview-items.square.square-2 .preview-item:not(.preview-item-add):nth-of-type(2) {
  margin-left: 2px;
}
attachment-preview .preview-items.square.square-3 {
  flex-wrap: wrap;
  flex-direction: column;
}
attachment-preview .preview-items.square.square-3 .preview-item:not(.preview-item-add) {
  width: calc(50% - 2px);
  height: 100%;
  margin: 0;
  border: none;
  border-radius: 0;
}
attachment-preview .preview-items.square.square-3 .preview-item:not(.preview-item-add):nth-of-type(1) {
  margin-right: 2px;
}
attachment-preview .preview-items.square.square-3 .preview-item:not(.preview-item-add):nth-of-type(2) {
  margin-left: 2px;
  margin-bottom: 2px;
  height: calc(50% - 2px);
}
attachment-preview .preview-items.square.square-3 .preview-item:not(.preview-item-add):nth-of-type(3) {
  margin-top: 2px;
  margin-left: 2px;
  height: calc(50% - 2px);
}
attachment-preview .preview-items.square .preview-item-add {
  height: calc(100% - 20px);
  box-sizing: border-box;
  padding: 0;
  width: 100%;
  margin: 10px;
}
attachment-preview .preview-items.square .preview-item-add .icon {
  flex-direction: column;
}
attachment-preview .preview-items-wrapper {
  display: flex;
}
attachment-preview .preview-items-wrapper:not(.editable) .preview-item:first-of-type {
  margin-left: 0;
}
attachment-preview .preview-items-wrapper.editable {
  flex-direction: row-reverse;
}
attachment-preview .attachment-loading-overlay {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}
attachment-preview .attachment-loading-overlay.attachment-loading-overlay-waiting {
  background-color: transparent;
}
attachment-preview .attachment-loading-overlay.attachment-loading-overlay-waiting:before {
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  content: '';
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: attachmentPreviewPlaceHolderShimmer;
  animation-timing-function: linear;
  background: #000;
  background: linear-gradient(to right, #000 8%, #222 18%, #000 33%);
  background-size: 1200px 104px;
  height: 100%;
  position: absolute;
  opacity: 0.2;
}
attachment-preview .attachment-loading-overlay .processing-item {
  height: 16px;
  width: 6px;
  background-color: var(--general-text-color);
  border-radius: 2px;
  margin: 0 2px;
  box-sizing: border-box;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.25);
  opacity: 0;
  display: block;
}
attachment-preview .attachment-loading-overlay .processing-item:nth-child(1) {
  animation: mediaProcessAnimation 1.5s linear 0s infinite;
}
attachment-preview .attachment-loading-overlay .processing-item:nth-child(2) {
  animation: mediaProcessAnimation 1.5s linear 0.25s infinite;
}
attachment-preview .attachment-loading-overlay .processing-item:nth-child(3) {
  animation: mediaProcessAnimation 1.5s linear 0.5s infinite;
}
attachment-preview .preview-item {
  position: relative;
  height: 100px;
  width: 130px;
  min-width: 130px;
  border-radius: 5px;
  overflow: hidden;
  margin: 0 5px;
  border: 1px solid rgba(var(--general-text-color-rgb), .1);
}
attachment-preview .preview-item .elapsed-time {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 50px;
  text-align: center;
  margin-left: -25px;
  margin-top: -8px;
  font-size: 11px;
  z-index: 999;
  color: var(--general-text-color);
}
attachment-preview .preview-item .progress-ring-circle {
  transition: 0.35s stroke-dashoffset;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
  stroke-dasharray: 150.796, 150.796;
  stroke-dashoffset: 150.796;
  stroke: var(--general-text-color);
  fill: var(--background-highlight-color);
}
attachment-preview .preview-item .icon-wrapper {
  position: absolute;
  bottom: 5px;
  left: 5px;
  max-width: calc(100% - 10px);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 12px;
  z-index: 2;
  font-weight: 500;
}
attachment-preview .preview-item .icon-wrapper rl-icon {
  color: var(--general-text-color);
}
attachment-preview .preview-item:after {
  position: absolute;
  background: rgba(244, 245, 246, 0.2);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  content: '';
}
attachment-preview .preview-item.with-preview:after {
  content: none;
}
attachment-preview .preview-item.with-preview .attachment-name {
  display: none;
}
attachment-preview .preview-item.with-preview .icon-wrapper rl-icon {
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  color: white;
}
attachment-preview .preview-item .attachment-name {
  color: var(--general-text-color);
}
attachment-preview .preview-item:not(.with-preview).file-type-unknown .progress-ring-circle,
attachment-preview .preview-item:not(.with-preview).file-type-zip .progress-ring-circle {
  stroke: #555;
}
attachment-preview .preview-item:not(.with-preview).file-type-unknown .processing-item,
attachment-preview .preview-item:not(.with-preview).file-type-zip .processing-item {
  background-color: #555;
}
attachment-preview .preview-item:not(.with-preview).file-type-unknown:after,
attachment-preview .preview-item:not(.with-preview).file-type-zip:after {
  background: rgba(244, 245, 246, 0.8);
}
attachment-preview .preview-item:not(.with-preview).file-type-unknown rl-icon,
attachment-preview .preview-item:not(.with-preview).file-type-zip rl-icon {
  color: #bfc3c8;
}
attachment-preview .preview-item:not(.with-preview).file-type-video .progress-ring-circle {
  stroke: var(--alert-color);
}
attachment-preview .preview-item:not(.with-preview).file-type-video .processing-item {
  background-color: var(--alert-color);
}
attachment-preview .preview-item:not(.with-preview).file-type-video:after {
  background: rgba(var(--alert-color-rgb), .15);
}
attachment-preview .preview-item:not(.with-preview).file-type-video rl-icon {
  color: var(--alert-color);
}
attachment-preview .preview-item:not(.with-preview).file-type-image .progress-ring-circle {
  stroke: #555;
}
attachment-preview .preview-item:not(.with-preview).file-type-image .processing-item {
  background-color: #555;
}
attachment-preview .preview-item:not(.with-preview).file-type-image:after {
  background: rgba(244, 245, 246, 0.8);
}
attachment-preview .preview-item:not(.with-preview).file-type-image rl-icon {
  color: #bfc3c8;
}
attachment-preview .preview-item:not(.with-preview).file-type-audio .progress-ring-circle {
  stroke: #7D548D;
}
attachment-preview .preview-item:not(.with-preview).file-type-audio .processing-item {
  background-color: #7D548D;
}
attachment-preview .preview-item:not(.with-preview).file-type-audio:after {
  background-color: rgba(125, 74, 141, 0.15);
  background-image: url('/Content/Graphics/Upload/audio.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
attachment-preview .preview-item:not(.with-preview).file-type-audio rl-icon {
  color: #7D548D;
}
attachment-preview .preview-item:not(.with-preview).file-type-doc .progress-ring-circle {
  stroke: var(--highlight-color);
}
attachment-preview .preview-item:not(.with-preview).file-type-doc .processing-item {
  background-color: var(--highlight-color);
}
attachment-preview .preview-item:not(.with-preview).file-type-doc:after {
  background: rgba(var(--highlight-color-rgb), .15);
}
attachment-preview .preview-item:not(.with-preview).file-type-doc rl-icon {
  color: var(--highlight-color);
}
attachment-preview .preview-item:not(.with-preview).file-type-excel .progress-ring-circle {
  stroke: var(--confirmation-color);
}
attachment-preview .preview-item:not(.with-preview).file-type-excel .processing-item {
  background-color: var(--confirmation-color);
}
attachment-preview .preview-item:not(.with-preview).file-type-excel:after {
  background: rgba(var(--confirmation-color-rgb), .15);
}
attachment-preview .preview-item:not(.with-preview).file-type-excel rl-icon {
  color: var(--confirmation-color);
}
attachment-preview .preview-item:not(.with-preview).file-type-pdf .progress-ring-circle,
attachment-preview .preview-item:not(.with-preview).file-type-powerpoint .progress-ring-circle {
  stroke: var(--warning-color);
}
attachment-preview .preview-item:not(.with-preview).file-type-pdf .processing-item,
attachment-preview .preview-item:not(.with-preview).file-type-powerpoint .processing-item {
  background-color: var(--warning-color);
}
attachment-preview .preview-item:not(.with-preview).file-type-pdf:after,
attachment-preview .preview-item:not(.with-preview).file-type-powerpoint:after {
  background: rgba(var(--warning-color-rgb), .15);
}
attachment-preview .preview-item:not(.with-preview).file-type-pdf rl-icon,
attachment-preview .preview-item:not(.with-preview).file-type-powerpoint rl-icon {
  color: var(--warning-color);
}
attachment-preview .preview-item button {
  position: absolute;
  right: 5px;
  top: 5px;
  margin: 0;
  padding: 0;
  cursor: pointer;
  color: white;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  height: 20px;
  width: 20px;
  line-height: 20px;
  text-align: center;
  font-size: 19px;
}
attachment-preview .preview-item button.edit-thumbnail-link {
  right: auto;
  left: 5px;
}
attachment-preview .preview-item button.edit-thumbnail-link rl-icon {
  font-size: 16px;
}
attachment-preview .preview-item .preview {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
attachment-preview .preview-item-add {
  border: 2px dashed rgba(var(--general-text-color-rgb), .1);
  background: none;
  box-sizing: border-box;
  margin-left: 0;
}
attachment-preview .preview-item-add .file-uploader-container {
  height: 100%;
  width: 100%;
}
attachment-preview .preview-item-add .icon {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
attachment-preview .preview-item-add .icon rl-icon {
  font-size: 30px;
}
attachment-preview .upload {
  height: 100%;
}
attachment-preview file-uploader .file-uploader-container.disabled {
  opacity: 0.4;
}
@keyframes mediaProcessAnimation {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes attachmentPreviewPlaceHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
inline-selector {
  display: block;
  padding: 10px 0;
}
inline-selector label {
  font-size: 14px;
  font-weight: 500;
  padding: 0 8px;
}
inline-selector ul {
  margin: 0;
  padding: 8px 8px 5px;
  list-style: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow-x: auto;
  width: calc(100% - 24px);
}
inline-selector ul li {
  border-radius: 17px;
  padding: 3px;
  min-width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  background: rgba(var(--general-text-color-rgb), .1);
  color: var(--general-text-color);
  cursor: pointer;
  margin-right: 8px;
  flex-shrink: 0;
}
inline-selector ul li.selected {
  color: var(--text-on-highlight-color);
  background: var(--highlight-color);
}
.signup-body {
  overflow: auto;
  min-height: initial;
  background-position: center center;
}
.signup-body .loader-overlay {
  background: rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.signup-body .loader-overlay .initial-loader {
  opacity: 1;
}
.signup-body .loader-overlay .initial-loader .spinner {
  height: 50px;
  width: 50px;
}
.signup-body .signup-view {
  display: none;
}
.signup-body .signup-view.signup-view-ready {
  display: block;
}
.signup-body .signup-view.signup-view-ready .signup-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  padding: 10px;
  box-sizing: border-box;
  overflow-y: hidden;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.signup-body .signup-view.signup-view-ready .signup-container {
  background: #fff;
  border-radius: 4px;
  width: 100%;
  max-width: 400px;
  padding: 10px;
  box-sizing: border-box;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  transition: height ease-in-out 0.2s;
}
.signup-body .failed .signup-container {
  padding-top: 30px !important;
  padding-bottom: 30px !important;
  text-align: center;
}
.signup-body .failed i {
  font-size: 50px;
  margin-bottom: 10px;
}
.signup-body .failed h1 {
  font-size: 24px;
  margin: 0;
  text-align: center;
}
.signup-body .signup-form h2 {
  font-size: 22px;
  margin: 20px 0 0 0;
  padding: 0;
  text-align: center;
  font-weight: 600;
}
.signup-body .signup-form h2.department-name {
  font-weight: bold;
  color: #666;
}
.signup-body .signup-form p {
  margin: 10px 0 0 0;
  padding: 0;
  text-align: center;
}
.signup-body .signup-form .signup-form-container {
  margin: 30px auto 20px auto;
  width: 100%;
  max-width: 320px;
}
.signup-body .signup-form .signup-form-container .form-element {
  margin-bottom: 20px;
}
.signup-body .signup-form .signup-form-container label {
  font-weight: 600;
}
.signup-body .signup-form .signup-form-container p.form-error {
  margin: 5px 0 0 0;
  padding: 0;
  text-align: left;
  color: var(--warning-color);
}
.signup-body .signup-form .signup-form-container .submit-btn {
  border: none;
  width: 100%;
  box-sizing: border-box;
  padding: 10px 20px;
  background: #000;
  color: #fff;
  text-align: center;
  margin: 5px 0 0 0;
  font-size: 14px;
  border-radius: 4px;
  cursor: pointer;
  transition: box-shadow ease-in-out 0.2s;
  user-select: none;
}
.signup-body .signup-form .signup-form-container .submit-btn:hover {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}
.signup-body .signup-form .signup-form-container input[type=text],
.signup-body .signup-form .signup-form-container input[type=tel] {
  display: block;
  width: 100%;
  min-width: 100%;
  line-height: 32px;
  padding: 0 8px;
  box-sizing: border-box;
  margin-top: 6px;
  border: 1px solid #bbb;
  border-radius: 4px;
  position: relative;
  height: auto;
  background: inherit;
}
.signup-body .signup-form .signup-form-container input[type=text].selector,
.signup-body .signup-form .signup-form-container input[type=tel].selector {
  cursor: pointer;
  transition: all ease-in-out 0.2s;
  padding-right: 40px;
}
.signup-body .signup-form .signup-form-container input[type=text].selector:hover,
.signup-body .signup-form .signup-form-container input[type=tel].selector:hover {
  border-color: #555;
}
.signup-body .signup-form .signup-form-container autocomplete .visible-items li p {
  text-align: left;
  margin: 0;
}
.signup-body .signup-form .signup-form-container autocomplete .selected-items {
  margin: 10px 0 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.signup-body .signup-form .signup-form-container autocomplete .selected-items li {
  font-size: 15px;
  list-style: none;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  height: 30px;
  max-width: 100%;
  padding: 0 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  line-height: 30px;
  background: var(--background-highlight-color);
  position: relative;
  overflow: hidden;
}
.signup-body .signup-form .signup-form-container autocomplete .selected-items li p {
  margin: 0;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: relative;
  font-size: 15px;
}
.signup-body .signup-form .signup-form-container autocomplete .search-wrapper input.valid {
  color: inherit;
}
.signup-body .signup-form .signup-form-container select {
  width: 100%;
  height: 34px;
  line-height: 34px;
  padding: 0 6px;
  box-sizing: border-box;
  border: 1px solid #bbb;
  border-radius: 4px;
  position: relative;
  margin-top: 6px;
}
.signup-body .signup-form .signup-form-container input[type=checkbox] {
  position: absolute;
  left: -99999px;
  opacity: 0;
}
.signup-body .signup-form .signup-form-container input[type=checkbox] + label {
  display: block;
  vertical-align: top;
  font-weight: normal;
  cursor: pointer;
  user-select: none;
  position: relative;
  padding-left: 35px;
}
.signup-body .signup-form .signup-form-container input[type=checkbox] + label a {
  color: #000;
  text-decoration: underline;
}
.signup-body .signup-form .signup-form-container input[type=checkbox] + label:before {
  content: '';
  display: inline-block;
  height: 24px;
  width: 24px;
  line-height: 24px;
  text-align: center;
  background: #fff;
  border-radius: 4px;
  vertical-align: top;
  box-sizing: border-box;
  border: 1px solid #bbb;
  color: #fff;
  transition: all ease-in-out 0.2s;
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -12px;
  font-family: 'Font Awesome\ 6 Pro';
}
.signup-body .signup-form .signup-form-container input[type=checkbox] + label:hover:before {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}
.signup-body .signup-form .signup-form-container input[type=checkbox]:checked + label:before {
  border-color: #0095ff;
  background: #0095ff;
  content: '\f00c';
}
.signup-body .signup-form-done {
  padding-top: 20px;
  padding-bottom: 30px;
  text-align: center;
}
.signup-body .signup-form-done i {
  font-size: 100px;
  margin-bottom: 10px;
}
.signup-body .signup-form-done h2 {
  font-size: 20px;
  margin: 0 0 5px 0;
  padding: 0;
}
.signup-body .signup-form-done p {
  margin: 0;
  padding: 0 20px;
}
.signup-body .signup-terms-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  overflow-x: hidden;
  background: rgba(0, 0, 0, 0.3);
  z-index: 2;
  padding: 20px 10px;
  box-sizing: border-box;
}
.signup-body .signup-terms-overlay .signup-terms-container {
  width: 100%;
  position: relative;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  padding: 20px;
  box-sizing: border-box;
  max-width: 500px;
  margin: 0 auto;
}
.signup-body .signup-terms-overlay .signup-terms-container .signup-terms-headline {
  text-transform: capitalize;
  margin: 0;
}
.signup-body .signup-terms-overlay .signup-terms-container .signup-terms-body {
  margin: 20px 0 10px 0;
  display: block;
}
.signup-body .signup-terms-overlay .signup-terms-container .signup-terms-body p {
  margin: 0;
}
.signup-body .signup-terms-overlay .signup-terms-container .signup-terms-close {
  position: absolute;
  top: 15px;
  right: 15px;
  font-size: 24px;
  cursor: pointer;
  opacity: 0.65;
  transition: opacity ease-in-out 0.1s;
}
.signup-body .signup-terms-overlay .signup-terms-container .signup-terms-close:hover {
  opacity: 1;
}
.signup-body .signup-404 {
  padding: 20px 10px 30px 10px;
  text-align: center;
}
.signup-body .signup-404 h2 {
  font-size: 22px;
  margin: 0;
  padding: 0;
}
.signup-body .signup-404 i {
  font-size: 100px;
  margin-bottom: 15px;
  color: var(--warning-color);
}
@media only screen and (max-width: 500px) {
  .signup-body .signup-view.signup-view-ready .signup-wrapper {
    position: static;
  }
}
:root {
  --startpage-background-image: '';
}
.show-intro .start-page {
  transform: translate3d(-100%, 0, 0);
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}
.start-page {
  background-color: var(--header-background-color);
  background-image: var(--startpage-background-image);
  background-position: center center;
  background-size: cover;
  color: var(--header-text-color);
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  font-size: 16px;
  height: 100%;
  min-height: 100%;
  box-sizing: border-box;
  padding: 35px 25px;
  transform: translate3d(0, 0, 0);
  z-index: 2;
  transition: transform ease-in-out 0.2s;
}
.start-page .start-page-logo {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  padding: 5vh 25px 0 25px;
  box-sizing: border-box;
}
.start-page .start-page-logo img {
  width: 100%;
  max-width: 80vw;
  display: inline-block;
}
.start-page .start-page-footer {
  width: 100%;
}
.start-page .start-page-footer .start-page-texts {
  text-align: center;
  margin-bottom: 15px;
}
.start-page .start-page-footer .start-page-texts h2 {
  font-size: 15px;
  font-weight: bold;
  width: 100%;
}
.start-page .start-page-footer .start-page-texts p {
  font-size: 15px;
  margin: 0;
  padding: 0;
}
.start-page .start-page-footer .start-page-buttons {
  text-align: center;
}
.start-page .start-page-footer .start-page-button-seperator {
  font-size: 13px;
  padding: 15px 0;
  display: block;
  text-transform: uppercase;
}
.start-page .start-page-footer .start-page-button {
  display: inline-block;
  width: 100%;
  max-width: 80vw;
  box-sizing: border-box;
  height: 46px;
  line-height: 46px;
  color: var(--button-text-color);
  background: var(--button-background-color);
  border: 0;
  box-shadow: none;
  text-transform: uppercase;
  font-weight: 600;
  border-radius: 5px;
  font-size: 15px;
  text-decoration: none;
  cursor: pointer;
  padding: 0 15px;
}
.start-page .start-page-footer .start-page-button span:not(.spinner) {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
}
.start-page .start-page-footer .start-page-button .spinner {
  border-color: var(--button-text-color);
  position: relative;
  top: -2px;
}
.start-page .start-page-footer .start-page-button.start-page-button-outlined {
  background: none;
  border: 1px solid var(--header-text-color);
  color: var(--header-text-color);
}
.start-page .start-page-footer .start-page-button.start-page-button-outlined .spinner {
  border-color: var(--header-text-color);
}
.intro-site-wrapper .intro-site-global-loading {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: var(--header-background-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 10;
}
.intro-site-wrapper .intro-site-global-loading .spinner {
  border-color: var(--header-text-color);
  width: 100px;
  height: 100px;
}
.intro-site-main {
  height: 100%;
  min-height: 100%;
  display: block;
}
.show-intro:not(.state-change) .intro-site-container {
  position: relative;
}
.intro-site-container {
  display: flex;
  height: 100%;
  min-height: 100%;
  flex-direction: column;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
}
.intro-site-container .intro-site-header {
  height: 20px;
  overflow: hidden;
  width: 100%;
  flex: 0 1 auto;
  background: var(--header-background-color);
}
.intro-site-container .intro-site-header a {
  display: inline-block;
  min-width: 100px;
  box-sizing: border-box;
  font-size: 13px;
  color: var(--header-text-color);
  text-decoration: none;
  cursor: pointer;
  height: 20px;
  line-height: 20px;
  padding: 0 10px;
}
.intro-site-container .intro-site-header a span {
  display: inline-block;
  margin-left: 5px;
}
.intro-site-container iframe {
  flex: 1 1 auto;
  border: 0;
}
.close-button {
  position: relative;
  display: block;
  width: 22px;
  height: 22px;
  padding: 0;
  margin: 0;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
  border: none;
  background: none;
}
.close-button:after,
.close-button:before {
  position: absolute;
  display: block;
  content: '';
  width: 28px;
  height: 1px;
  background: var(--sidemenu-text-color);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  left: -2px;
  opacity: 0.3;
}
.close-button:after {
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
autocomplete .selected-items {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0 10px 0;
  align-items: center;
}
autocomplete .selected-items li {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  height: 30px;
  max-width: 100%;
  padding: 0 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  line-height: 30px;
  background: var(--background-highlight-color);
  position: relative;
  overflow: hidden;
}
autocomplete .selected-items li p {
  margin: 0;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: relative;
  font-size: 15px;
}
autocomplete .selected-items li .remove-item {
  position: relative;
  top: 1px;
  font-weight: 600;
  color: var(--warning-color);
  padding: 1px 2px 1px 8px;
  font-size: 15px;
}
autocomplete .selected-items li [icon="lock"] {
  opacity: 0.4;
  padding-left: 8px;
  font-size: 13px;
}
.base-view-user-management {
  height: auto !important;
}
.base-view-user-management icon-plus button {
  background-color: var(--button-background-color);
}
.user-management {
  --footer-height: 50px;
  --mobile-header-height: 55px;
  --desktop-header-height: 60px;
  display: flex;
  justify-content: space-between;
}
@media only screen and (min-width: 1025px) {
  .user-management {
    max-width: unset;
  }
}
.user-management .placeholder-shimmer {
  height: 65px;
}
.user-management .filters-section {
  flex: 2 1 40%;
  display: flex;
  flex-direction: column;
  max-width: min(40%, 450px);
  border: 1px solid var(--general-border-color);
  border-radius: var(--general-border-radius);
  margin: var(--spacing);
}
.user-management .filters-section .subheader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: var(--spacing);
}
.user-management .filters-section .subheader h2 {
  font-weight: 700;
  font-size: 1rem;
  margin: 0;
}
.user-management .filters-section .subheader button {
  padding: 0;
}
.user-management .filters-section .content {
  max-height: calc(100vh - 220px);
  overflow: auto;
}
.user-management .main-section {
  display: flex;
  flex-direction: column;
  max-width: var(--max-width);
  margin: 0 auto;
  flex: 1 1 60%;
  max-height: calc(100vh - var(--mobile-header-height) - var(--footer-height));
  overflow-y: hidden;
}
@media only screen and (min-width: 1025px) {
  .user-management .main-section {
    max-height: calc(100vh - var(--desktop-header-height));
  }
}
.user-management .main-section .fixed-top {
  position: sticky;
  z-index: 1;
  top: 0;
  width: 100%;
  background: var(--general-background-color);
  box-sizing: border-box;
  padding: 0 var(--spacing);
}
@media only screen and (min-width: 1025px) {
  .user-management .main-section .fixed-top .top {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    gap: var(--spacing);
    padding: var(--spacing) 0 0;
  }
}
.user-management .main-section .fixed-top .top .user-management-search {
  display: flex;
  padding: var(--spacing) 0 0;
}
.user-management .main-section .fixed-top .top .user-management-search rl-search {
  flex: 1;
}
@media only screen and (min-width: 1025px) {
  .user-management .main-section .fixed-top .top .user-management-search {
    flex: 1;
    padding: 0;
  }
}
.user-management .main-section .fixed-top .top .user-management-search .info-wrapper {
  position: relative;
  margin-left: var(--spacing);
}
.user-management .main-section .fixed-top .top .user-management-search .info-wrapper rl-icon {
  height: 100%;
}
.user-management .main-section .fixed-top .top .filters-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  gap: var(--s-spacing);
  padding: var(--m-spacing);
  background-color: transparent;
  border: 1px solid var(--general-border-color);
  border-radius: var(--general-border-radius);
  height: 45px;
  box-sizing: border-box;
}
.user-management .main-section .fixed-top .top .filters-wrapper:hover {
  cursor: pointer;
  background-color: rgba(var(--general-text-color-rgb), 0.03);
}
.user-management .main-section .fixed-top .top .filters-wrapper p {
  margin: 0;
}
.user-management .main-section .fixed-top .top .filters-wrapper .filters-count {
  display: none;
}
.user-management .main-section .fixed-top .top .filters-wrapper.active {
  font-weight: 700;
  color: var(--highlight-color);
  background-color: rgba(var(--highlight-color-rgb), 0.1);
  border: 1px solid transparent;
}
.user-management .main-section .fixed-top .top .filters-wrapper.active .filters-count {
  display: inline;
}
.user-management .main-section .fixed-top .top .filters-wrapper.active:hover {
  background-color: rgba(var(--highlight-color-rgb), 0.15);
}
.user-management .main-section .fixed-top .top .user-management-create-link {
  box-sizing: border-box;
  text-align: center;
  width: auto;
  padding: 0;
}
.user-management .main-section .fixed-top .rl-tabs .rl-tab {
  min-width: 50px;
}
.user-management .main-section .fixed-top .rl-tabs rl-badge {
  filter: drop-shadow(0px 3px 10px rgba(0, 0, 0, 0.15));
  font-weight: bold;
}
.user-management .main-section .fixed-top .rl-tabs:has(> *:only-child) {
  display: none;
}
.user-management .main-section .user-management-scroll-container {
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  padding: 50px var(--spacing);
  margin-top: -50px;
}
.user-management .main-section .user-management-scroll-container:has(.no-content) {
  height: 100vh;
}
.user-management .main-section .empty-states-container {
  position: relative;
  flex: 1;
}
.user-management .main-section .empty-states-container .empty-state {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  gap: var(--s-spacing);
}
.user-management .main-section .empty-states-container .empty-state.empty-search {
  transition-delay: 0.3s;
}
.user-management .main-section .empty-states-container .create-user-mobile-btn {
  margin-top: var(--s-spacing);
}
.user-management .main-section .no-content {
  position: static;
  margin-top: var(--spacing);
  min-width: max-content;
  height: unset;
}
.user-management .main-section .no-content span {
  line-height: 2rem;
}
.user-management .main-section .no-content i {
  margin: var(--s-spacing) auto;
  font-size: 64px;
  color: var(--general-text-inactive-color);
}
.user-management .main-section .no-content p {
  max-width: 255px;
}
.user-management .module-pre-loader {
  display: flex;
  flex-direction: column;
  gap: var(--spacing);
  padding: 0 var(--spacing);
  margin: var(--spacing) 0;
}
.user-management .module-pre-loader .placeholder-top-line rl-loading-placeholder {
  display: flex;
  gap: var(--m-spacing);
  height: 45px;
}
.user-management .module-pre-loader .placeholder-top-line rl-loading-placeholder .placeholder-shimmer {
  border-radius: var(--general-border-radius);
  height: 45px;
}
.user-management .module-pre-loader .placeholder-top-line rl-loading-placeholder > *:first-child {
  flex-basis: 65%;
}
.user-management .module-pre-loader .placeholder-top-line rl-loading-placeholder > *:nth-child(2) {
  flex-basis: 15%;
}
.user-management .module-pre-loader .placeholder-top-line rl-loading-placeholder > *:last-child {
  flex-basis: 20%;
  background-color: var(--general-text-inactive-color);
}
.user-management .module-pre-loader .placeholder-top-line rl-loading-placeholder > *:only-child {
  flex-basis: 100%;
  background-color: inherit;
}
.user-management .module-pre-loader .placeholder-user {
  display: flex;
  gap: var(--s-spacing);
}
.user-management .module-pre-loader .placeholder-image-container {
  position: relative;
}
.user-management .module-pre-loader .placeholder-image-container .placeholder-avatar {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: block;
}
.user-management .module-pre-loader .placeholder-image-container .placeholder-status {
  position: absolute;
  bottom: -2px;
  right: 0;
  background-color: var(--general-text-inactive-color);
  border-radius: 50%;
  height: 18px;
  width: 18px;
  backdrop-filter: blur(15px);
}
.user-management .module-pre-loader .placeholder-user-info {
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
}
.user-management .module-pre-loader .placeholder-user-info .placeholder-name .placeholder-shimmer {
  height: 21px;
  border-radius: 1000px;
}
.user-management .module-pre-loader .placeholder-user-info .placeholder-department .placeholder-shimmer {
  height: 12px;
  border-radius: 1000px;
}
.user-management-update {
  padding: 20px 20px 10px 20px;
}
.user-management-update .user-management-update-popup-content {
  overflow-y: auto;
}
.user-management-update .image-container {
  width: 100%;
  height: 100px;
  text-align: center;
  position: relative;
  margin-top: 20px;
}
.user-management-update .image-container .image-box {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -50px;
  margin-left: -50px;
  height: 100px;
  width: 100px;
  border-radius: 50%;
}
.user-management-update .image-container .image-box .profile-image {
  height: 100px;
  width: 100px;
  border-width: 3px;
}
.user-management-update .image-container .image-box .disabled .profile-image {
  opacity: 0.5;
}
.user-management-update .image-container .disabled-indicator {
  position: absolute;
  bottom: 0;
  right: 50%;
  transform: translate(50%, 0);
  padding: 0 var(--s-spacing);
  color: var(--white-color);
  background-color: var(--warning-color);
  font-size: 0.675rem;
  border-radius: var(--general-border-radius);
  font-weight: 600;
  line-height: 1.7rem;
  display: flex;
  align-items: center;
  gap: 4px;
}
.user-management-update .image-container .disabled-indicator rl-icon {
  font-size: 0.75rem;
}
.user-management-update .popup-sticky-bottom .pending-user-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: var(--spacing);
}
.user-management-update .popup-sticky-bottom .pending-user-actions rl-button {
  flex: 1 0 47%;
  max-width: 100%;
  transition: max-width 0.3s ease;
}
.user-management-update .popup-sticky-bottom .pending-user-actions rl-button button {
  display: flex;
  justify-content: center;
  align-items: center;
}
.user-management-update .popup-sticky-bottom .pending-user-actions .approve button {
  background-color: var(--confirmation-color);
  color: var(--white-color);
}
.user-management-update .popup-sticky-bottom .pending-user-actions .approve.with-spinner rl-icon {
  animation: rotation 2s infinite linear;
}
.user-management-update .popup-sticky-bottom .pending-user-actions .decline button {
  background-color: var(--warning-color);
  color: var(--white-color);
}
.user-management-update .popup-sticky-bottom .pending-user-actions .decline.hidden {
  max-width: 0;
  overflow: hidden;
}
.user-management-create .selector-postfix,
.user-management-update .selector-postfix {
  position: absolute;
  bottom: 10px;
  right: 10px;
  color: var(--general-text-inactive-color);
}
.user-management-create .divider-line,
.user-management-update .divider-line {
  content: '';
  width: 100%;
  height: 1px;
  background-color: var(--general-border-color);
  margin: var(--m-spacing) 0;
}
.user-management-create .form-item:last-child,
.user-management-update .form-item:last-child {
  padding-bottom: 75px;
}
.user-management-create .toggles .form-item:first-child .toggle-field,
.user-management-update .toggles .form-item:first-child .toggle-field {
  border-radius: var(--general-border-radius) var(--general-border-radius) 0 0;
}
.user-management-create .toggles .form-item:last-child .toggle-field,
.user-management-update .toggles .form-item:last-child .toggle-field {
  border-radius: 0 0 var(--general-border-radius) var(--general-border-radius);
}
.user-management-create .pre-loader .placeholder-avatar,
.user-management-update .pre-loader .placeholder-avatar {
  margin: 20px auto 0;
  height: 100px;
  width: 100px;
  border-radius: 50%;
}
.user-management-create .pre-loader .divider-line,
.user-management-update .pre-loader .divider-line {
  margin: var(--spacing) 0;
}
.user-management-create .pre-loader .placeholder-form,
.user-management-update .pre-loader .placeholder-form {
  padding: var(--spacing) var(--spacing) 0;
  display: flex;
  flex-direction: column;
  gap: var(--spacing);
}
.user-management-create .pre-loader .placeholder-form-item,
.user-management-update .pre-loader .placeholder-form-item {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.user-management-create .pre-loader .placeholder-label .placeholder-shimmer,
.user-management-update .pre-loader .placeholder-label .placeholder-shimmer {
  border-radius: 1000px;
  height: 20px;
  margin-left: var(--m-spacing);
}
.user-management-create .pre-loader .placeholder-input .placeholder-shimmer,
.user-management-update .pre-loader .placeholder-input .placeholder-shimmer {
  height: 45px;
  border-radius: var(--general-border-radius);
}
.user-management-create .processing-overlay,
.user-management-update .processing-overlay {
  background-color: rgba(var(--general-background-color-rgb), 0.8);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.user-management-create header-popup,
.user-management-update header-popup {
  position: sticky;
  z-index: 3;
  top: 0;
  width: 100%;
  box-sizing: border-box;
}
.user-management-create .form,
.user-management-update .form {
  padding: 15px;
  overflow-y: auto;
}
.user-management-create .form .form-item > label,
.user-management-update .form .form-item > label {
  padding: 5.5px 0;
}
.user-management-create .form-item,
.user-management-update .form-item {
  position: relative;
}
.user-management-create autocomplete .select-area,
.user-management-update autocomplete .select-area,
.user-management-create autocomplete .search-wrapper input,
.user-management-update autocomplete .search-wrapper input {
  border-radius: var(--general-border-radius);
  background: var(--background-highlight-color);
  overflow: hidden;
  font-weight: 400;
}
.user-management-create autocomplete .select-area.disabled,
.user-management-update autocomplete .select-area.disabled,
.user-management-create autocomplete .search-wrapper input.disabled,
.user-management-update autocomplete .search-wrapper input.disabled {
  background: var(--locked-field-color);
  border: none;
  color: var(--general-text-inactive-color);
  opacity: 1;
}
.user-management-create .selected-items,
.user-management-update .selected-items {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0 10px 0;
  align-items: center;
}
.user-management-create .selected-items li,
.user-management-update .selected-items li {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  height: 30px;
  max-width: 100%;
  padding: 0 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  line-height: 30px;
  border-radius: var(--general-border-radius);
  background: var(--background-highlight-color);
  position: relative;
  overflow: hidden;
}
.user-management-create .selected-items li p,
.user-management-update .selected-items li p {
  margin: 0;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: relative;
  font-size: 15px;
}
.user-management-create .selected-items li .remove-item,
.user-management-update .selected-items li .remove-item {
  position: relative;
  top: 1px;
  font-weight: 600;
  color: var(--button-background-color);
  padding: 1px 2px 1px 8px;
  font-size: 15px;
}
.user-management-create .selected-items li [icon="lock"],
.user-management-update .selected-items li [icon="lock"] {
  opacity: 0.4;
  padding-left: 8px;
  font-size: 13px;
}
.user-management-create .action-container,
.user-management-update .action-container {
  margin: 0 var(--spacing);
  display: flex;
  flex-direction: column;
  gap: var(--spacing);
  padding-bottom: 75px;
}
.user-management-create .action-container rl-button button,
.user-management-update .action-container rl-button button {
  font-size: 1rem;
  font-weight: 400;
  text-align: left;
  background-color: var(--background-highlight-color);
  display: flex;
  align-items: center;
  gap: 4px;
}
.user-management-create .action-container rl-button.warning,
.user-management-update .action-container rl-button.warning {
  color: var(--warning-color);
}
.user-management-create .save button,
.user-management-update .save button {
  color: var(--white-color);
  background: var(--button-background-color);
}
.user-management-create .save.with-spinner rl-icon,
.user-management-update .save.with-spinner rl-icon {
  animation: rotation 2s infinite linear;
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
.user-management-create .form {
  padding-bottom: 90px;
}
activity-widget {
  padding: var(--spacing) var(--m-spacing);
  margin: var(--spacing) 0;
  background-color: var(--background-highlight-color);
  border-radius: var(--general-border-radius);
}
activity-widget .general-widget .title {
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--s-spacing);
  font-weight: 700;
  font-size: 1rem;
  line-height: 135%;
}
activity-widget .general-widget .stats-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: var(--spacing) 0 var(--s-spacing);
  padding: 0 var(--spacing);
}
activity-widget .general-widget .stat {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 var(--s-spacing);
  flex-basis: 30%;
  word-break: break-word;
  max-width: 100px;
}
activity-widget .general-widget .stat p {
  font-size: 1.65rem;
  font-weight: 700;
  line-height: 130%;
  margin: 0;
  text-align: center;
}
activity-widget .general-widget .stat span {
  font-size: 0.85rem;
  font-weight: 400;
  color: var(--general-text-inactive-color);
  text-align: center;
}
activity-widget .specific-widget {
  display: flex;
  align-items: center;
  gap: var(--s-spacing);
}
activity-widget .specific-widget .activity-indicator {
  width: 32px;
  height: 32px;
  display: grid;
  place-content: center;
}
activity-widget .specific-widget .stats-wrapper {
  flex-grow: 1;
}
activity-widget .specific-widget .stats-wrapper p {
  text-overflow: ellipsis;
  overflow: hidden;
  text-wrap: nowrap;
  margin: 0;
  max-width: calc(100% - 20px);
}
activity-widget .specific-widget .percent {
  font-size: 1rem;
  font-weight: 700;
  line-height: 150%;
}
activity-widget .specific-widget .count {
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 135%;
  color: var(--general-text-inactive-color);
}
activity-widget .specific-widget .info {
  width: 22px;
  height: 22px;
  display: grid;
  place-content: center;
  color: var(--general-text-inactive-color);
  position: relative;
}
activity-widget .specific-widget tip {
  z-index: 100;
}
activity-widget .specific-widget tip .tip-content {
  right: -8px;
  bottom: 0;
  max-width: 165px;
  padding: var(--m-spacing);
  border-radius: var(--general-border-radius);
  box-shadow: 0px 7px 15px 0px var(--shadow-color);
  background-color: var(--white-color);
  color: var(--text-on-white-color);
}
activity-widget .specific-widget tip .tip-content:after {
  right: 5px;
  bottom: -7px;
  border-color: var(--white-color) transparent transparent;
  left: unset;
  border-width: 7px 7.5px 0 7.5px;
}
user-management-filters {
  flex: 1;
  display: flex;
  flex-direction: column;
}
user-management-filters .content {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 15px;
}
user-management-filters .content rl-select.activity .options {
  max-height: 80vh;
}
user-management-filters .content rl-select.activity .options .option.selected > span {
  color: var(--general-text-color);
}
user-management-filters .content .rl-label {
  text-transform: lowercase;
  font-size: 0.75rem;
}
user-management-filters .content .rl-label::first-letter {
  text-transform: capitalize;
}
user-management-filters .content usergroup-selector,
user-management-filters .content department-selector {
  min-height: 45px;
  display: block;
  background: var(--background-highlight-color);
  border-radius: var(--general-border-radius);
}
user-management-filters .content usergroup-selector autocomplete,
user-management-filters .content department-selector autocomplete {
  display: flex;
  flex-direction: column-reverse;
}
user-management-filters .content usergroup-selector .selected-items,
user-management-filters .content department-selector .selected-items {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 10px;
}
user-management-filters .content usergroup-selector .selected-items li,
user-management-filters .content department-selector .selected-items li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 7px 10px;
  font-size: 13px;
  backdrop-filter: blur(24px);
  background: rgba(var(--white-color-rgb), 0.7);
  border-radius: 100px;
}
user-management-filters .content usergroup-selector .selected-items li button,
user-management-filters .content department-selector .selected-items li button {
  padding: 0;
  margin-left: 10px;
  opacity: 0.7;
}
user-management-filters .content usergroup-selector .selected-items li button rl-icon,
user-management-filters .content department-selector .selected-items li button rl-icon {
  font-size: 15px;
  color: var(--warning-color);
}
user-management-filters .content usergroup-selector .selected-items li p,
user-management-filters .content department-selector .selected-items li p {
  margin: 0;
}
user-management-filters .content usergroup-selector input,
user-management-filters .content department-selector input {
  font-weight: normal;
  height: 45px;
  border: none;
  background: transparent;
}
user-management-filters .content usergroup-selector [icon="search"],
user-management-filters .content department-selector [icon="search"] {
  height: 100%;
  box-sizing: border-box;
}
user-management-filters .content .rl-field-wrapper.recent {
  box-sizing: border-box;
  min-height: 46px;
  margin-top: 15px;
  padding: 10px;
  border-radius: var(--general-border-radius);
  background: var(--background-highlight-color);
}
user-management-filters .content .divider {
  width: 100%;
  height: 1px;
  background-color: var(--general-border-color);
  margin: var(--m-spacing) 0;
}
user-management-filters footer {
  margin: auto 0 0 auto;
  padding: var(--spacing);
  display: flex;
}
user-management-filters footer rl-button button.small {
  font-size: 0.75rem;
  font-weight: 700;
  height: 37px;
  min-width: 91px;
  line-height: 1rem;
}
user-management-filters footer rl-button[icon=ban] button {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  gap: 5px;
  color: var(--warning-color);
  padding: 0 var(--spacing);
}
user-management-filters footer rl-button[icon=ban] rl-icon {
  font-size: 0.9rem;
}
activity-indicator {
  display: flex;
}
activity-indicator i {
  position: relative;
  width: 18px;
  height: 18px;
  border-radius: 50%;
}
activity-indicator i::before {
  font-size: 14px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: rgba(var(--white-color-rgb), 0.4);
  backdrop-filter: blur(4px);
  overflow: hidden;
  border-radius: 50%;
}
activity-indicator .all {
  background: conic-gradient(rgba(var(--alert-color-rgb), 0.95) 0%, rgba(var(--warning-color-rgb), 0.95) 5%, rgba(var(--alert-color-rgb), 0.95) 35%, rgba(var(--confirmation-color-rgb), 0.95) 55%, rgba(var(--alert-color-rgb), 0.95) 85%, rgba(var(--alert-color-rgb), 0.95) 100%);
}
activity-indicator .today {
  background: var(--confirmation-color);
}
activity-indicator .week {
  background-color: color-mix(in srgb, var(--confirmation-color) 50%, var(--alert-color));
}
activity-indicator .month {
  background: var(--alert-color);
}
activity-indicator .no-activity-in-month,
activity-indicator .had-activity {
  background: var(--warning-color);
}
activity-indicator .no-activity-in-week {
  background-color: color-mix(in srgb, var(--warning-color) 50%, var(--alert-color));
}
activity-indicator .no-activity {
  background: var(--general-text-inactive-color);
}
activity-indicator .pending {
  width: 14px;
  height: 14px;
  background: var(--general-text-inactive-color);
}
activity-indicator .pending::before {
  font-size: 8px;
  color: var(--white-color);
  width: 14px;
  height: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(5px);
}
activity-indicator .disabled {
  background: var(--warning-color);
  width: 17px;
  height: 17px;
}
activity-indicator .disabled::before {
  font-size: 10px;
  line-height: 15px;
  color: var(--white-color);
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(5px);
}
user-management-user-list .user {
  margin-bottom: 15px;
}
user-management-user-list .user.pending {
  margin: 15px 0 0 0;
}
user-management-user-list .user .divider {
  padding: 10px 0 15px 10px;
  color: var(--general-text-color);
  text-transform: uppercase;
  font-size: 11px;
  line-height: 14px;
}
user-management-user-list .user .item {
  display: flex;
}
user-management-user-list .user .item:not(:last-of-type) {
  margin-bottom: 15px;
}
user-management-user-list .user .item .img-wrapper {
  position: relative;
  margin-right: 10px;
}
user-management-user-list .user .item .img-wrapper.no-activity profile-image,
user-management-user-list .user .item .img-wrapper.disabled profile-image {
  opacity: 0.5;
}
user-management-user-list .user .item .img-wrapper profile-image {
  padding-right: 0;
}
user-management-user-list .user .item .img-wrapper profile-image .profile-image {
  width: 36px;
  height: 36px;
}
user-management-user-list .user .item .img-wrapper activity-indicator {
  position: absolute;
  bottom: 0;
  right: 0;
}
user-management-user-list .user .item .right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-basis: 100%;
  max-width: min(510px, 100%);
  container-type: inline-size;
}
user-management-user-list .user .item .right .name {
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
  display: inline-block;
  max-width: min(510px, 100%);
}
user-management-user-list .user .item .right .title-wrapper {
  width: 100%;
  max-width: min(510px, 100%);
  font-size: 0;
  display: flex;
}
user-management-user-list .user .item .right .title-wrapper .department {
  flex: 1;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
  display: inline-block;
}
user-management-user-list .user .item .right .title-wrapper > span:nth-child(2):before {
  content: '·';
  font-weight: bold;
  padding: 0 5px;
}
user-management-user-list .user .item .right .title-wrapper span {
  color: rgba(var(--general-text-color-rgb), 0.4);
  font-size: 0.8rem;
}
user-management-user-list .user .item .right.disabled {
  color: var(--general-text-inactive-color);
}
.user-management-confirm-popup.confirmation-popup .content {
  padding: var(--s-spacing) var(--spacing);
  margin: var(--s-spacing) 0;
}
.user-management-confirm-popup.confirmation-popup h1 {
  color: var(--elevated-text-color);
  font-size: 1rem;
  padding-bottom: var(--s-spacing);
}
.user-management-confirm-popup.confirmation-popup p {
  font-size: 0.9rem;
  color: var(--elevated-text-color);
}
.user-management-confirm-popup.confirmation-popup footer {
  border: none;
  padding: 0 var(--spacing) var(--spacing);
  gap: var(--spacing);
}
.user-management-confirm-popup.confirmation-popup footer rl-button {
  border-radius: var(--general-border-radius);
  min-width: unset;
  flex: 1 0 47%;
}
.user-management-confirm-popup.confirmation-popup footer rl-button button {
  min-width: unset;
  width: 100%;
  text-transform: uppercase;
}
.user-management-confirm-popup.confirmation-popup footer rl-button button span {
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.user-management-confirm-popup.confirmation-popup footer rl-button.no button {
  opacity: 1;
  color: var(--elevated-text-inactive-color);
  background-color: var(--elevated-border-color);
}
.user-management-confirm-popup.confirmation-popup footer rl-button.yes button {
  background-color: var(--warning-color);
  color: var(--white-color);
}
.user-management-confirm-popup.confirmation-popup footer rl-button.yes:only-child button {
  background-color: var(--button-background-color);
  color: var(--button-text-color);
}
stars-rating {
  position: relative;
  display: inline-block;
}
stars-rating rl-icon {
  font-size: 40px;
}
stars-rating .empty {
  white-space: nowrap;
}
stars-rating .empty rl-icon {
  color: rgba(var(--general-text-color-rgb), 0.2);
}
stars-rating .full {
  width: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  white-space: nowrap;
}
stars-rating .full rl-icon {
  color: var(--alert-color);
}
content-total-swipes-popup .scrolling,
read-by-popup .scrolling {
  height: 100%;
}
content-total-swipes-popup .read-registration-no-views,
read-by-popup .read-registration-no-views {
  padding-top: 50%;
}
content-total-swipes-popup .read-registration-no-views rl-icon,
read-by-popup .read-registration-no-views rl-icon {
  display: block;
  text-align: center;
  font-size: 70px;
}
content-total-swipes-popup .read-registration-no-views p,
read-by-popup .read-registration-no-views p {
  display: block;
  margin: 10px 0 0 0;
  padding: 0 20px;
  text-align: center;
  font-size: 18px;
}
content-total-swipes-popup .tabs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none;
  border-bottom: 1px solid rgba(var(--general-text-color-rgb), .1);
  width: 100%;
}
content-total-swipes-popup .tabs li {
  flex: 1 auto;
  padding: 0 0 10px;
  text-align: center;
  font-size: 14px;
  border-bottom: 2px solid transparent;
  cursor: pointer;
}
content-total-swipes-popup .tabs li:last-of-type {
  margin: 0;
}
content-total-swipes-popup .tabs li.active {
  border-bottom: 2px solid var(--highlight-color);
  color: var(--highlight-color);
}
content-total-swipes-popup .tabs li rl-icon {
  margin-right: 5px;
  color: var(--white-color);
}
content-total-swipes-popup .tabs li rl-icon.read-by {
  background: var(--confirmation-color);
}
content-total-swipes-popup .tabs li rl-icon.not-read-by {
  background: var(--warning-color);
}
content-total-swipes-popup .tabs li span {
  vertical-align: middle;
  line-height: 20px;
}
content-total-swipes-popup section {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
}
content-total-swipes-popup simple-user-list {
  height: 100%;
}
icon-plus button {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  width: 50px;
  height: 50px;
  text-align: center;
  background: var(--highlight-color);
  color: var(--white-color);
  box-shadow: 0 5px 7px 0 var(--general-border-color);
  border-radius: 50%;
  z-index: 99;
}
icon-plus button.right-bottom {
  right: 10px;
  bottom: 60px;
  bottom: calc(60px + env(safe-area-inset-bottom, 0px));
}
barcode-reader {
  display: flex;
}
barcode-reader rl-icon {
  color: var(--general-text-color);
}
barcode-reader .camera {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
}
barcode-reader .camera video {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: white;
}
barcode-reader .area {
  position: absolute;
  z-index: 100;
  border: 2px solid red;
  top: 35%;
  bottom: 35%;
  left: 15%;
  right: 15%;
}
barcode-reader .close-camera {
  z-index: 100;
  position: absolute;
  padding: 0;
  top: 15px;
  right: 15px;
  font-size: 25px;
}
barcode-reader #interactive.viewport > canvas,
barcode-reader #interactive.viewport > video {
  max-width: 100%;
  width: 100%;
}
barcode-reader canvas.drawing,
barcode-reader canvas.drawingBuffer {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  max-width: 100%;
}
bookmarks-dropdown .app-header-dropdown {
  width: 400px;
}
bookmarks-dropdown .app-header-dropdown .app-header-dropdown-header app-header-dropdown-header {
  gap: var(--s-spacing);
  border-bottom: none;
}
bookmarks-dropdown .app-header-dropdown .app-header-dropdown-header h2 {
  text-transform: none;
}
bookmarks-dropdown .app-header-dropdown .app-header-dropdown-header .count {
  margin-right: auto;
  font-weight: 400;
  color: var(--general-text-inactive-color);
}
bookmarks-dropdown .app-header-dropdown .app-header-dropdown-body {
  min-height: 282px;
  max-height: calc(100vh - 165px);
}
bookmarks-dropdown .app-header-dropdown .app-header-dropdown-footer app-header-dropdown-footer {
  border: none;
}
bookmarks-dropdown .app-header-dropdown .app-header-dropdown-footer app-header-dropdown-footer > button:only-child:hover {
  background-color: transparent;
  color: rgba(var(--highlight-color-rgb), 0.85);
}
bookmarks-dropdown .app-header-dropdown .app-header-dropdown-footer .see-more {
  text-transform: none;
}
bookmarks-dropdown .no-content {
  margin-top: -70px;
}
bookmarks-dropdown .filter-match-no-bookmarks .no-content {
  position: static;
  margin: 62px auto 82px;
  height: unset;
}
bookmarks-list {
  display: flex;
  flex-direction: column;
}
bookmarks-list .bookmarks-list,
bookmarks-list .bookmarks-list-loading {
  display: grid;
  grid-template-columns: repeat(2, minmax(150px, 190px));
  padding: var(--spacing);
  gap: var(--spacing);
  margin: 0 auto;
  overflow: hidden;
}
bookmarks-list:has(.bookmarks-list) .bookmarks-list-loading {
  z-index: 4;
  margin-top: -190px;
}
@media only screen and (max-width: 767px) and (orientation: landscape) {
  bookmarks-list:not([is-dropdown="true"]) .bookmarks-list,
  bookmarks-list:not([is-dropdown="true"]) .bookmarks-list-loading {
    margin-left: 0;
  }
}
@media only screen and (min-width: 768px) {
  bookmarks-list:not([is-dropdown="true"]) .bookmarks-list,
  bookmarks-list:not([is-dropdown="true"]) .bookmarks-list-loading {
    grid-template-columns: repeat(3, 190px);
  }
}
@media only screen and (min-width: 1025px) {
  bookmarks-list:not([is-dropdown="true"]) .bookmarks-list,
  bookmarks-list:not([is-dropdown="true"]) .bookmarks-list-loading {
    padding: var(--spacing) 0;
  }
}
@media only screen and (min-width: 768px) {
  bookmarks-list:not([is-dropdown="true"]) bookmark-filters {
    width: var(--max-width);
    margin: var(--m-spacing) auto 0;
  }
}
bookmarks-list .bookmark-item,
bookmarks-list .bookmarks-list-loading-item {
  width: 100%;
  position: relative;
  border: 1px solid transparent;
  border-radius: var(--general-border-radius);
}
bookmarks-list .bookmark-item:last-child,
bookmarks-list .bookmarks-list-loading-item:last-child {
  margin-right: auto;
}
bookmarks-list .bookmark-item:hover,
bookmarks-list .bookmarks-list-loading-item:hover {
  border: 1px solid var(--general-border-color);
}
bookmarks-list .bookmark-item.deleted .image,
bookmarks-list .bookmarks-list-loading-item.deleted .image,
bookmarks-list .bookmark-item.deleted .module-indicator,
bookmarks-list .bookmarks-list-loading-item.deleted .module-indicator,
bookmarks-list .bookmark-item.deleted .inner,
bookmarks-list .bookmarks-list-loading-item.deleted .inner,
bookmarks-list .bookmark-item.deleted button,
bookmarks-list .bookmarks-list-loading-item.deleted button {
  display: none;
}
bookmarks-list .bookmark-item:has(.icon-on-thumbnail) .options,
bookmarks-list .bookmarks-list-loading-item:has(.icon-on-thumbnail) .options {
  color: var(--white-color);
  text-shadow: 0px 4px 7px rgba(60, 72, 88, 0.1);
}
bookmarks-list .bookmarks-list-loading-item {
  background-color: var(--general-background-color);
}
bookmarks-list .module-indicator {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}
bookmarks-list .module-indicator .module-icon {
  position: absolute;
  top: var(--s-spacing);
  left: 0;
  right: 0;
  z-index: 2;
  font-size: 0.75rem;
}
bookmarks-list .module-indicator .bookmark-icon {
  font-size: 2.2rem;
  color: var(--general-background-color);
}
bookmarks-list .module-indicator .bookmark-icon-duplicate {
  top: -5px;
  position: absolute;
  left: 0;
}
bookmarks-list .image,
bookmarks-list .bookmarks-list-loading-item-image {
  height: 84px;
  border-radius: var(--general-border-radius) var(--general-border-radius) 0 0;
  background-color: rgba(var(--general-text-color-rgb), 0.2);
  display: grid;
  place-content: center;
}
bookmarks-list .image rl-icon,
bookmarks-list .bookmarks-list-loading-item-image rl-icon {
  font-size: 2.5rem;
  color: rgba(var(--general-text-color-rgb), 0.6);
}
bookmarks-list .image rl-icon.icon-on-thumbnail,
bookmarks-list .bookmarks-list-loading-item-image rl-icon.icon-on-thumbnail {
  width: 49px;
  height: 49px;
  text-shadow: 0px 17.182px 36.818px rgba(60, 72, 88, 0.1);
  color: var(--white-color);
}
bookmarks-list .image rl-icon.icon-on-thumbnail::before,
bookmarks-list .bookmarks-list-loading-item-image rl-icon.icon-on-thumbnail::before {
  color: var(--white-color);
}
bookmarks-list .image rl-icon.icon-on-thumbnail::after,
bookmarks-list .bookmarks-list-loading-item-image rl-icon.icon-on-thumbnail::after {
  color: var(--text-on-white-color);
}
bookmarks-list .file-icon-with-text {
  position: relative;
}
bookmarks-list .file-icon-with-text::before,
bookmarks-list .file-icon-with-text::after {
  content: attr(data-label);
  position: absolute;
  bottom: 8px;
  font-size: 9px;
  font-weight: 700;
}
bookmarks-list .file-icon-with-text::before {
  color: var(--general-background-color);
}
bookmarks-list .file-icon-with-text::after {
  color: rgba(var(--general-text-color-rgb), 0.2);
}
bookmarks-list .inner,
bookmarks-list .bookmarks-list-loading-item-text {
  border-radius: 0 0 var(--general-border-radius) var(--general-border-radius);
  padding: var(--m-spacing);
  background-color: var(--background-highlight-color);
  display: flex;
  flex-direction: column;
  gap: 4px;
  height: 56px;
}
bookmarks-list .row {
  display: flex;
}
bookmarks-list .row .title {
  font-size: 0.8rem;
  font-weight: 700;
  line-height: 135%;
  word-break: break-word;
  word-break: auto-phrase;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
bookmarks-list .row .module,
bookmarks-list .row .date {
  color: var(--general-text-inactive-color);
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 135%;
}
bookmarks-list .row .module {
  word-break: break-word;
  word-break: auto-phrase;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
bookmarks-list .row .module::after {
  content: '·';
  width: 10px;
  display: inline-block;
  text-align: center;
}
bookmarks-list .row .date {
  flex-shrink: 0;
}
bookmarks-list .options {
  position: absolute;
  top: 5px;
  right: 0;
  color: var(--white-color);
}
bookmarks-list .options.no-media {
  color: var(--general-text-inactive-color);
}
bookmarks-list .options rl-icon {
  font-size: 1.2rem;
}
bookmarks-list .bookmarks-list-loading-item-text {
  background-color: var(--background-highlight-color);
}
bookmarks-list .bookmarks-list-loading-item-text-headline {
  height: 0.9rem;
  width: 60%;
  border-radius: var(--general-border-radius);
}
bookmarks-list .bookmarks-list-loading-item-text-headline:first-child {
  width: 100%;
}
bookmarks-list .bookmarks-list-loading-item-text-subheadline {
  margin-top: 4px;
  height: 0.8rem;
  width: 80%;
  border-radius: var(--general-border-radius);
}
no-content.no-bookmarks {
  display: block;
}
no-content.no-bookmarks i {
  color: var(--general-text-inactive-color);
  font-size: 4rem;
  margin-bottom: var(--m-spacing);
}
no-content.no-bookmarks span {
  line-height: 150%;
}
no-content.no-bookmarks p {
  font-size: 0.8rem;
  line-height: 135%;
  max-width: 170px;
}
no-content .no-content {
  margin-top: -70px;
}
@media only screen and (min-width: 768px) {
  no-content .no-content {
    margin-top: -100px;
  }
}
bookmark-filters {
  display: flex;
  gap: var(--m-spacing);
  margin: var(--m-spacing) var(--spacing) 0;
}
bookmark-filters button {
  font-size: 0.8rem;
  height: 2.5rem;
  min-width: 45px;
  padding: 0 var(--m-spacing);
  border-radius: var(--general-border-radius);
  border: 1px solid var(--general-border-color);
  background-color: var(--background-highlight-color);
}
bookmark-filters button.active {
  background-color: var(--highlight-color);
  color: var(--text-on-highlight-color);
  border: 1px solid var(--highlight-color);
}
department-swap-selector no-content {
  display: block;
}
department-swap-selector no-content .no-content {
  display: block;
  height: 200px;
  margin: 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
department-swap-selector no-content .no-content i {
  font-size: 40px;
  margin: 0 0 20px 0;
}
department-swap-selector no-content .no-content p {
  font-size: 13px;
}
department-swap-selector .department-swap-placeholder {
  height: 30px;
  margin: 15px;
  border-radius: var(--general-border-radius);
}
department-swap-selector .department-swap-placeholder:not(:first-of-type) {
  margin-top: 25px;
}
@media only screen and (min-width: 1025px) {
  department-swap-selector .department-swap-placeholder {
    height: 20px;
  }
  department-swap-selector .department-swap-placeholder:not(:first-of-type) {
    margin-top: 20px;
  }
}
department-swap-selector rl-select-list .item {
  padding: 0 15px;
  box-sizing: border-box;
}
@media only screen and (min-width: 1025px) {
  department-swap-selector rl-select-list .item:hover {
    background: rgba(var(--background-highlight-color-rgb), 0.5);
  }
}
@media only screen and (min-width: 1025px) {
  department-swap-selector rl-select-list {
    row-gap: 0;
  }
  department-swap-selector rl-select-list .item {
    height: 36px;
  }
}
department-swap-popup popup-wrapper popup-footer button {
  width: 100%;
}
department-swap-popup .popup-wrapper .popup-content-inner {
  padding: 0;
}
swipe {
  display: block;
}
.swipe {
  position: relative;
  height: 34px;
  width: 200px;
  margin: 0 auto;
  padding-top: 15px;
  padding-bottom: 15px;
  text-transform: uppercase;
  transform: translate3d(0, 0, 0);
}
.swipe .swipe-container {
  width: 100%;
  height: 34px;
  display: block;
  border-radius: 17px;
  background: #8f9091;
  color: #fff;
  font-weight: 200;
  position: relative;
  border: none;
  padding: 0;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
}
.swipe .swipe-container.swiping,
.swipe .swipe-container.swiping:after {
  transition: all 0.3s ease-in-out;
}
.swipe .swipe-container span {
  height: 34px;
  line-height: 34px;
  text-align: center;
  width: 100%;
  display: block;
  position: relative;
  font-size: 13px;
}
.swipe .swipe-container span.swiped {
  width: 166px;
  left: 4px;
}
.swipe .swipe-container span.initial {
  left: 30px;
  width: 166px;
}
.swipe .swipe-container:after {
  display: block;
  height: 30px;
  width: 30px;
  border-radius: 100%;
  content: '';
  position: absolute;
  left: 2px;
  top: 2px;
  background: #fff;
}
.swipe .swipe-container.disabled {
  opacity: 0.3;
  pointer-events: none;
}
.swipe .swipe-container.swiped {
  background: #3fc59d;
}
.swipe .swipe-container.swiped:after,
.swipe .swipe-container.loading:after {
  transform: translate3d(166px, 0, 0);
}
content-create-popup .popup-content-inner {
  background: var(--background-highlight-color);
}
content-create-popup .rl-form {
  padding-bottom: 75px;
}
content-create-popup .scroll-container {
  flex: 1;
  overflow-y: auto;
  position: relative;
}
content-create-popup .post-content-header {
  padding: 20px 0 0 0;
  min-height: 205px;
  box-sizing: border-box;
}
content-create-popup .post-content-header textarea {
  position: relative;
  width: 100%;
  height: 100px;
  max-height: 500px;
  font-size: 15px;
  box-sizing: border-box;
  padding: 15px;
  border: none;
  background-image: linear-gradient(to bottom, var(--general-background-color) 85%, var(--background-highlight-color));
}
content-create-popup .post-content-header .post-title {
  margin: 0;
  padding: 15px;
  height: 57px;
  max-height: 150px;
  display: block;
  color: var(--general-text-color);
  border: none;
  font-size: 20px;
  font-weight: 500;
  background: inherit;
}
content-create-popup .post-content-header .post-title::-webkit-input-placeholder {
  color: var(--general-text-color);
  opacity: 0.6;
}
content-create-popup .attachments-wrapper {
  padding: 10px 10px 0;
}
content-create-popup .attachments-wrapper .attachments-label {
  margin-bottom: 10px;
  font-size: 15px;
  font-weight: 500;
}
content-create-popup .notifications .rl-input.toggle {
  border: unset;
}
content-create-popup .notifications .notification-input:last-of-type {
  border-bottom: 1px solid var(--general-border-color);
}
content-create-popup .notifications .notification-input {
  border-top: 1px solid var(--general-border-color);
}
content-create-popup .notifications .notification-help-text {
  display: block;
  background-color: var(--general-background-color);
  padding: 0 10px var(--s-spacing);
  font-size: 14px;
  color: var(--general-text-inactive-color);
}
content-create-popup .post-setting.disabled {
  position: relative;
}
content-create-popup .post-setting.disabled:after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.8);
}
content-create-popup .post-setting > div {
  display: flex;
  flex-direction: column;
}
content-create-popup .post-setting h2 {
  font-size: 20px;
  font-weight: 600;
  padding: 0 10px;
  margin: 0 0 10px 0;
}
content-create-popup .post-setting .rl-field.language label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 43px;
}
content-create-popup .post-setting .rl-field.language language-selector {
  width: 100%;
}
content-create-popup .post-setting .notifications .input-wrapper,
content-create-popup .post-setting .settings .input-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  height: 50px;
  box-sizing: border-box;
  background: var(--general-background-color);
  border-top: 1px solid rgba(var(--general-text-color-rgb), .1);
  border-bottom: 1px solid rgba(var(--general-text-color-rgb), .1);
}
content-create-popup .post-setting .notifications .input-wrapper > label,
content-create-popup .post-setting .settings .input-wrapper > label {
  flex: 1;
  padding: 0 0 0 10px;
}
content-create-popup .post-setting .notifications .input-wrapper.disabled:after,
content-create-popup .post-setting .settings .input-wrapper.disabled:after {
  content: '';
  position: absolute;
  z-index: 5;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.2);
}
content-create-popup .post-setting .notifications .input-wrapper:first-of-type,
content-create-popup .post-setting .settings .input-wrapper:first-of-type {
  border-bottom: none;
}
content-create-popup .post-setting .notifications rl-toggle,
content-create-popup .post-setting .settings rl-toggle {
  padding-right: 10px;
}
content-create-popup .submit {
  position: relative;
  width: 100%;
  height: 45px;
  line-height: 45px;
  background: var(--highlight-color);
  color: var(--text-on-highlight-color);
  font-weight: 600;
}
content-create-popup .submit[disabled],
content-create-popup .submit.inactive {
  background: var(--general-text-color);
  opacity: 0.4;
  color: white;
}
content-create-popup .submit[disabled]:after,
content-create-popup .submit.inactive:after {
  position: absolute;
  right: 10px;
  top: 0;
  color: var(--warning-color);
  font-family: "Font Awesome 6\ Pro";
  font-size: 19px;
  font-weight: 900;
}
content-create-popup .content-category {
  min-height: 50px;
  background: var(--general-background-color);
  gap: 10px;
  display: flex !important;
  flex-direction: row !important;
  padding-left: var(--s-spacing);
  padding-right: 0;
  align-items: center;
}
content-create-popup .required-message {
  padding: 5px 0 0 0;
  font-size: 12px;
  font-weight: 600;
  color: var(--warning-color);
}
content-create-popup select-content-category {
  padding: 10px 30px 10px 40px;
}
content-comments-popup .loading-wrapper {
  background: rgba(var(--background-highlight-color-rgb), 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: calc(100% - 55px);
  left: 0;
  top: 0;
  position: absolute;
  z-index: 10;
}
content-comments-popup .content-comments {
  padding: 0 10px 5px;
  display: flex;
  width: 100%;
  flex-direction: column;
  height: calc(100% - 55px);
  box-sizing: border-box;
  min-height: 40px;
}
content-comments-popup .content-comments .empty-list {
  flex: 1 1 auto;
  text-align: center;
  padding: 40px 0;
}
content-comments-popup .content-comments .empty-list rl-icon {
  font-size: 40px;
}
content-comments-popup .content-comments .content-comments-list {
  position: relative;
  padding: 0;
  margin: 0;
  flex: 1 1 auto;
  overflow-y: auto;
}
content-comments-popup .content-comments .content-comments-list > li {
  display: block;
  position: relative;
  padding: 15px 0 10px 40px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
content-comments-popup .content-comments .content-comments-list > li:last-child {
  border-bottom: none;
}
content-comments-popup .content-comments .content-comments-list > li .content-comments-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 10px;
  padding-top: 5px;
}
content-comments-popup .content-comments .content-comments-list > li .content-comments-actions .action-buttons button {
  padding: 0;
}
content-comments-popup .content-comments .content-comments-list > li .content-comments-actions .action-buttons .content-comment-like.liked {
  color: var(--highlight-color);
}
content-comments-popup .content-comments .content-comments-list > li .content-comments-actions .action-buttons .content-comment-like.liked rl-icon {
  color: var(--highlight-color);
}
content-comments-popup .content-comments .content-comments-list > li profile-image {
  position: absolute;
  top: 20px;
  left: 0;
}
content-comments-popup .content-comments .content-comments-list > li profile-image > .profile-image {
  height: 30px;
  width: 30px;
}
content-comments-popup .content-comments .content-comments-list > li h3 {
  font-size: 1em;
  margin: 0;
  padding: 0 25px 0 0;
}
content-comments-popup .content-comments .content-comments-list > li p {
  display: block;
  padding: 0;
  margin: 3px 0;
}
content-comments-popup .content-comments .content-comments-list > li .subtitle {
  display: block;
  margin-top: 5px;
  font-size: 0.9em;
  opacity: 0.7;
}
content-comments-popup .content-comments .content-comments-list > li .comment-delete {
  position: absolute;
  right: 10px;
  top: 20px;
  background: none;
  border: 0;
  padding: 0;
  font-size: inherit;
}
content-comments-popup .content-comments .content-comments-list > li .comment-report {
  position: absolute;
  right: 0;
  bottom: 17px;
  height: 20px;
  width: 34px;
  display: block;
  text-align: center;
  color: #bc6565;
  border: 0;
  background: transparent;
  font-size: 15px;
}
content-comments-popup .content-comments .content-comments-list > li .comment-report:hover {
  color: #9e2424;
}
content-comments-popup .content-comments-footer {
  flex: 0 0 auto;
}
content-comments-popup .content-comments-footer form {
  display: block;
  min-height: 40px;
  line-height: 40px;
  position: relative;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0 65px 0 5px;
}
content-comments-popup .content-comments-footer form input[type=text],
content-comments-popup .content-comments-footer form textarea {
  display: block;
  width: 100%;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 8px 0 0 0;
  height: 30px;
  line-height: 30px;
  padding: 0 5px;
  background: none;
  border: 1px solid #fff;
  border-color: #e1e4e7;
  border-radius: 4px;
}
content-comments-popup .content-comments-footer form textarea {
  padding: 5px;
  line-height: initial;
  max-height: 100px;
}
content-comments-popup .content-comments-footer form button {
  position: absolute;
  top: 0;
  right: 5px;
  height: 30px;
  line-height: 30px;
  width: 55px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: none;
  color: #b7bdc5;
  border: none;
  text-align: center;
  padding: 0;
  display: inline-block;
}
content-comments-popup .content-comments-footer form .comment-posting {
  text-align: center;
  display: block;
  height: 30px;
  line-height: 30px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
}
content-item {
  position: relative;
  display: block;
  padding: 0;
  margin: 15px 0;
  background: var(--general-background-color);
  border-radius: var(--general-border-radius);
}
content-item .app-link-url {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  user-select: none;
}
content-item .content-item-report-btn {
  position: absolute;
  right: 5px;
  top: 10px;
  height: 30px;
  width: 40px;
  line-height: 30px;
  text-align: center;
  cursor: pointer;
  color: #bc6565;
  font-size: 20px;
  z-index: 2;
}
content-item .content-item-report-btn:hover {
  color: #9e2424;
}
content-item .content-item-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 15px 0 15px;
}
content-item .content-item-title.short {
  padding: 15px 0 0 15px;
}
content-item .content-item-title h2 {
  margin: 0;
  font-size: 18px;
  text-transform: uppercase;
  position: relative;
  word-break: break-word;
}
content-item .content-item-breadcrumb {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  margin: 10px 15px 0 15px;
}
content-item .profile-info .name p {
  font-weight: 500;
}
content-item .profile-info p {
  display: inline;
  padding: 0;
  margin: 0;
  vertical-align: top;
}
content-item profile-image {
  padding-right: 10px;
}
content-item profile-image .leave-icon {
  right: 7px;
}
content-item profile-image .profile-image {
  height: 34px;
  width: 34px;
}
content-item .bookmark-message {
  bottom: 100%;
  width: 100%;
  font-size: 12px;
  line-height: 40px;
  padding: 0 15px;
  box-sizing: border-box;
  color: transparent;
  background: rgba(0, 0, 0, 0.7);
  height: 0;
  visibility: hidden;
  transition: all 0.3s;
}
content-item .bookmark-message.show {
  height: 40px;
  visibility: visible;
  color: white;
}
content-item .bookmark-message .icon {
  position: absolute;
  right: 5px;
  top: 13px;
  font-size: 14px;
}
content-item .content-label {
  font-weight: 700;
  margin: var(--m-spacing) 0 0 var(--m-spacing);
  display: inline-flex;
  padding: var(--s-spacing);
  border-radius: var(--general-border-radius);
}
content-item .content-label.unread {
  background-color: var(--highlight-color);
  color: var(--text-on-highlight-color);
}
content-item .corner {
  display: flex;
  align-items: center;
  right: 0;
  top: 0;
  margin: 0 7px;
}
content-item .corner .pin-wrapper {
  padding: 0 7px;
  position: relative;
}
content-item .corner .pin-wrapper rl-icon {
  color: var(--highlight-color);
  font-size: 13px;
}
content-item .corner tip .tip-content {
  right: 12px;
  top: 40px;
  text-align: left;
}
content-item .corner tip .tip-content h3 {
  text-transform: uppercase;
  padding-bottom: 4px;
}
content-item .corner tip .tip-content h3:after {
  content: ':';
}
content-item .corner tip .tip-content:after {
  position: absolute;
  right: 35px;
  left: auto;
  transform: translateX(0);
}
content-item .corner .post-options {
  display: block;
  padding: 0 7px;
  text-align: center;
  color: #3c4858;
  background: transparent;
  border: none;
}
content-item .corner .post-options rl-icon {
  font-size: 20px;
}
@media only screen and (min-width: 1025px) {
  content-item .corner .post-options {
    height: 35px;
    line-height: 35px;
    width: 35px;
  }
}
content-item .content-item-media {
  position: relative;
}
content-item .content-item-media:not(:first-child) {
  margin-top: var(--s-spacing);
}
content-item .content-item-media rl-badge {
  position: absolute;
  left: 10px;
  top: 8px;
  z-index: 6;
  padding: 0 5px;
  border-radius: 20px;
  width: auto;
}
content-item .content-item-media.no-image {
  height: 100px;
  border: 1px solid var(--general-background-color);
  border-top-left-radius: var(--general-border-radius);
  border-top-right-radius: var(--general-border-radius);
  background: var(--background-highlight-color);
}
content-item .content-item-media .corner {
  z-index: 5;
  position: absolute;
  height: 40px;
}
content-item .content-item-media .corner .post-options {
  color: var(--white-color);
  z-index: 4;
  text-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
}
content-item .content-item-media media-preview {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  border-top-right-radius: var(--general-border-radius);
  border-top-left-radius: var(--general-border-radius);
}
@media only screen and (min-width: 1025px) {
  content-item .content-item-media media-preview .media .image img {
    max-height: 600px;
    object-fit: contain;
  }
}
content-item .content-item-media content-social-sharing .open-sharing {
  position: absolute;
  z-index: 9;
  right: 15px;
  bottom: -25px;
}
content-item rl-rich-preview {
  margin: 15px 15px 0 15px;
  width: calc(100% - 30px);
}
content-item translation-button {
  margin: 15px 0 15px 15px;
}
content-item.future-post .content-item-body {
  margin-bottom: 15px;
}
content-item .content-item-actions {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid var(--general-border-color);
  padding: 8px 0 0 0;
}
content-item .content-item-actions > button {
  color: #000000;
  background: none;
  border: 0;
  padding: 0;
}
content-item .content-item-actions .content-item-comments {
  text-decoration: none;
  font-size: 13px;
}
content-item .content-item-actions .content-item-bookmark {
  padding: 0 5px;
  font-size: 18px;
}
content-item .content-item-actions .content-item-likes {
  height: 50px;
  line-height: 50px;
  text-align: center;
  margin-top: -15px;
}
content-item .content-item-actions .content-item-likes a {
  font-size: 13px;
  color: rgba(var(--general-text-color-rgb), 0.4);
}
content-item .content-item-actions .content-item-likes a i {
  margin-right: 5px;
}
content-item .content-item-actions .content-item-likes a.liked {
  color: #141823;
}
content-item .default-reaction-label {
  margin: 0;
}
content-item .card-footer .card-footer-actions-texts button span {
  vertical-align: middle;
}
content-item .card-footer .card-footer-actions-texts .content-item-shares rl-icon {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-size: 12px !important;
  color: var(--white-color);
  background-color: #4267B2;
  background-image: linear-gradient(rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0));
}
content-item .card-footer .content-item-like .liked {
  color: var(--highlight-color);
}
content-item .card-footer .content-item-like reaction-icon {
  border: none;
  margin: 0;
}
content-item .card-footer .content-item-like .reaction-label {
  vertical-align: middle;
}
content-social-sharing .open-sharing {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  background: var(--highlight-color);
  color: var(--white-color);
  box-shadow: 0 5px 7px 0 var(--general-border-color);
}
content-social-sharing .sharing-dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 201;
  display: flex;
  justify-content: center;
  align-items: center;
}
content-social-sharing .sharing-dialog .bg {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  animation-duration: 0.3s;
  background: var(--popup-overlay-bg);
}
content-social-sharing .sharing-dialog .sharing-dialog-popup {
  z-index: 2;
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 90%;
  max-width: 360px;
  padding: 20px 15px 15px 15px;
  text-align: center;
  background: var(--white-color);
  animation-duration: 0.4s;
}
content-social-sharing .sharing-dialog .sharing-dialog-popup > span {
  color: var(--text-on-white-color);
}
content-social-sharing .sharing-dialog .sharing-dialog-popup p {
  margin: 0;
  padding-bottom: 10px;
  text-align: left;
  font-size: 13px;
  color: var(--text-on-white-color);
}
content-social-sharing .sharing-dialog .sharing-dialog-popup .platforms {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 20px 0 7px 0;
}
content-social-sharing .sharing-dialog .sharing-dialog-popup .platforms > button {
  position: relative;
  padding: 0;
  height: 50px;
  text-align: center;
}
content-social-sharing .sharing-dialog .sharing-dialog-popup .platforms > button:first-of-type {
  margin-bottom: 10px;
}
content-social-sharing .sharing-dialog .sharing-dialog-popup .platforms > button.linkedin {
  background: #0073b1;
}
content-social-sharing .sharing-dialog .sharing-dialog-popup .platforms > button.facebook {
  background: #4267B2;
}
content-social-sharing .sharing-dialog .sharing-dialog-popup .platforms > button[disabled] {
  opacity: 0.6;
}
content-social-sharing .sharing-dialog .sharing-dialog-popup .platforms > button rl-icon {
  position: absolute;
  top: 0;
  left: 0;
  padding: 10px;
  border-radius: 5px;
  font-size: 30px;
  color: var(--white-color);
}
content-social-sharing .sharing-dialog .sharing-dialog-popup .platforms > button > span {
  flex: 1;
  font-weight: 500;
  color: var(--white-color);
}
content-social-sharing .sharing-dialog .sharing-dialog-popup rl-button button {
  color: var(--text-on-white-color);
  opacity: 1;
}
content-social-sharing .linkedin-popup .popup-content-inner {
  padding: 15px;
  background: var(--background-highlight-color);
}
content-social-sharing .linkedin-popup .popup-content-inner .content {
  padding: 10px;
  margin-bottom: 20px;
  box-shadow: 0 6px 7px 0 var(--general-border-color);
  background: var(--general-background-color);
}
content-social-sharing .linkedin-popup .popup-content-inner .content .profile {
  display: flex;
  margin-bottom: 10px;
}
content-social-sharing .linkedin-popup .popup-content-inner .content .profile profile-image {
  padding-right: 10px;
}
content-social-sharing .linkedin-popup .popup-content-inner .content .profile .profile-image {
  width: 35px;
  height: 35px;
}
content-social-sharing .linkedin-popup .popup-content-inner .content .profile h2 {
  margin: 0;
  padding: 0;
  font-weight: 500;
  text-transform: none;
}
content-social-sharing .linkedin-popup .popup-content-inner .content .profile p {
  margin: 0;
}
content-social-sharing .linkedin-popup .popup-content-inner .content textarea {
  min-height: 100px;
  width: 100%;
  padding: 0;
  background: transparent;
  border: none;
}
content-social-sharing .linkedin-popup .popup-content-inner .content textarea::placeholder {
  color: rgba(var(--general-text-color-rgb), 0.6);
}
content-social-sharing .linkedin-popup .popup-content-inner .share-content {
  border: 1px dashed var(--general-border-color);
  background: var(--background-highlight-color);
  padding-bottom: 20px;
}
content-social-sharing .linkedin-popup .popup-content-inner .share-content h2 {
  margin: 0;
  padding: 15px 10px 7px 10px;
  text-transform: none;
  word-wrap: break-word;
}
content-social-sharing .linkedin-popup .popup-content-inner .share-content p {
  margin: 0;
  padding: 0 10px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  font-size: 13px;
}
content-social-sharing .linkedin-popup .popup-content-inner .share-content .image {
  height: 170px;
  background-position: center;
  background-size: cover;
}
content-social-sharing .linkedin-popup .popup-content-inner .share-linkedin {
  margin-top: auto;
  position: sticky;
  bottom: 0;
}
content-social-sharing .modal-loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
content-shares-popup .popup-wrapper .popup-content-inner {
  padding: 0;
}
content-shares-popup .popup-wrapper .content-share-viewer {
  display: flex;
  flex-direction: column;
  height: 100%;
}
content-shares-popup .popup-wrapper header {
  width: 100%;
  flex: 0 0 auto;
  padding: 10px 15px 0;
  box-sizing: border-box;
  z-index: 99;
  background: white;
}
content-shares-popup .popup-wrapper header ul {
  margin: 0;
  list-style: none;
  display: flex;
  align-items: center;
  padding: 0;
  border-bottom: 1px solid rgba(var(--general-text-color-rgb), .1);
  width: 100%;
}
content-shares-popup .popup-wrapper header ul li {
  margin: 0 15px 0 0;
  padding: 0 5px 10px;
  cursor: pointer;
  font-size: 14px;
  border-bottom: 2px solid transparent;
}
content-shares-popup .popup-wrapper header ul li.active {
  border-bottom: 2px solid var(--highlight-color);
  color: var(--highlight-color);
}
content-shares-popup .popup-wrapper header ul li.active.facebook {
  border-bottom: 2px solid #3B5998;
  color: #3B5998;
}
content-shares-popup .popup-wrapper header ul li.active.linkedin {
  border-bottom: 2px solid #0077b5;
  color: #0077b5;
}
content-shares-popup .popup-wrapper header ul li.active [icon=facebook] {
  background-color: #3B5998;
}
content-shares-popup .popup-wrapper header ul li.active [icon=linkedin] {
  background-color: #0077b5;
}
content-shares-popup .popup-wrapper header ul li span {
  vertical-align: middle;
  line-height: 20px;
}
content-shares-popup .popup-wrapper header .btn-close {
  display: none;
}
@media only screen and (min-width: 1025px) {
  content-shares-popup .popup-wrapper header .btn-close {
    display: block;
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 20px;
    cursor: pointer;
  }
}
content-shares-popup section {
  flex: 1 1 auto;
  padding: 15px;
  overflow-y: auto;
}
content-shares-popup .popup-content .spinner-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: var(--general-text-color);
}
content-shares-popup .user {
  display: flex;
  margin-top: 10px;
  align-items: center;
}
content-shares-popup .user .image-wrapper {
  position: relative;
}
content-shares-popup .user .profile-image {
  width: 42px;
  height: 42px;
  cursor: pointer;
}
content-shares-popup .user rl-icon {
  position: absolute;
  z-index: 11;
  bottom: 0;
  width: 20px;
  height: 20px;
  right: 0;
}
content-shares-popup .user .title {
  margin-left: 5px;
  color: var(--text-on-white-color);
}
content-shares-popup .user .title p {
  margin: 0;
}
content-shares-popup .user .title .name {
  font-size: 15px;
  font-weight: 600;
}
content-shares-popup .user .title .department {
  opacity: 0.5;
  font-size: 13px;
}
content-shares-popup rl-icon {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-size: 12px !important;
  color: var(--white-color);
  background-image: linear-gradient(rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0));
}
content-shares-popup rl-icon[icon=facebook] {
  background-color: #4267B2;
}
content-shares-popup rl-icon[icon=linkedin] {
  background-color: #0073b1;
}
contents {
  display: block;
  padding-bottom: 10px;
}
contents .content-create-link {
  display: block;
  margin-bottom: 15px;
}
contents .content-create-link button {
  font-weight: 400;
}
@media only screen and (min-width: 1025px) {
  contents .content-create-link {
    max-width: 60vw;
  }
}
contents .content-item-placeholder {
  display: block;
  margin: var(--spacing) 0;
  background: var(--general-background-color);
  border-radius: var(--general-border-radius);
}
contents .content-item-placeholder rl-loading-placeholder {
  display: block;
  box-sizing: border-box;
  padding: 15px;
}
contents .content-item-placeholder rl-loading-placeholder .placeholder-shimmer {
  height: 19px;
}
contents .contents .content .media-container {
  position: relative;
}
contents .contents .content .media-container h2 {
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  box-sizing: border-box;
  color: #fff;
  padding: 0 15px 20px 15px;
  margin: 0;
  text-transform: uppercase;
  font-size: 1.1em;
}
contents .contents .content .container {
  display: block;
  padding: 15px;
  margin: 0;
}
contents .contents .content .container h2 {
  font-size: 1.1em;
  text-transform: uppercase;
  margin: 0 0 10px 0;
}
contents .contents .content .breadcrumb {
  display: flex;
  flex-wrap: wrap;
  line-height: 20px;
  text-transform: uppercase;
  font-size: 11px;
  margin: 0 0 10px 0;
}
contents .contents .content .breadcrumb button {
  display: flex;
  padding: 0;
}
contents .contents .content .breadcrumb > div,
contents .contents .content .breadcrumb content-post-total-swipes,
contents .contents .content .breadcrumb content-total-views {
  vertical-align: top;
  height: 20px;
  line-height: 20px;
  display: inline-block;
}
contents .contents .content .breadcrumb > div span,
contents .contents .content .breadcrumb content-post-total-swipes span,
contents .contents .content .breadcrumb content-total-views span {
  color: #c2c2c2;
}
contents .contents .content .breadcrumb > div p,
contents .contents .content .breadcrumb content-post-total-swipes p,
contents .contents .content .breadcrumb content-total-views p {
  padding: 0;
  margin: 0 10px 0 5px;
}
contents .contents .content .breadcrumb > div i,
contents .contents .content .breadcrumb content-post-total-swipes i,
contents .contents .content .breadcrumb content-total-views i {
  color: #c2c2c2;
  font-size: 12px;
  position: relative;
  top: -1px;
}
contents .contents .content .breadcrumb > div span,
contents .contents .content .breadcrumb content-post-total-swipes span,
contents .contents .content .breadcrumb content-total-views span,
contents .contents .content .breadcrumb > div p,
contents .contents .content .breadcrumb content-post-total-swipes p,
contents .contents .content .breadcrumb content-total-views p,
contents .contents .content .breadcrumb > div i,
contents .contents .content .breadcrumb content-post-total-swipes i,
contents .contents .content .breadcrumb content-total-views i {
  display: inline-block;
  vertical-align: top;
  height: 20px;
  line-height: 20px;
  font-weight: 400;
  font-size: 11px;
}
contents .contents .content .breadcrumb rl-badge {
  padding: 0 5px;
  margin-left: auto;
  border-radius: 20px;
}
contents .contents .content .body-text {
  overflow: hidden;
}
contents .contents .content .body-text p {
  padding: 0;
  margin: 0;
}
contents .contents .content .body-text.readmore .swipe {
  padding-bottom: 0;
}
.base-view-contentOverview {
  background: var(--background-highlight-color);
  height: auto !important;
}
content-categories {
  display: block;
  padding: 15px 0;
}
@media only screen and (max-width: 1024px) {
  content-categories {
    padding: 15px 15px 0;
  }
}
content-categories .filter {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
}
content-categories .content-category {
  padding: 15px;
  margin-bottom: 15px;
  background: var(--general-background-color);
  border-radius: var(--general-border-radius);
}
content-categories .content-category header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
content-categories .content-category header h2 {
  margin: 0;
  font-weight: 700;
  font-size: 15px;
  line-height: 130%;
}
content-categories .content-category .follow-actions {
  margin-left: 5px;
}
content-categories .content-category .follow-actions button {
  max-width: 120px;
  height: 30px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 13px;
  font-weight: 500;
}
content-categories .content-category .summary {
  margin: 10px 0 0 0;
  font-size: 15px;
  line-height: 18px;
}
content-categories .content-category .selected {
  font-style: italic;
  color: var(--confirmation-color);
  margin: 10px 0;
}
content-categories .content-category .selected rl-icon {
  background-color: var(--confirmation-color);
  color: var(--white-color);
}
content-categories .content-category .new-posts-label {
  margin: 10px 0 0;
  color: var(--highlight-color);
  font-weight: 500;
  display: flex;
  align-items: center;
}
content-categories .content-category .new-posts-label .circle {
  background: var(--highlight-color);
  width: 10px;
  height: 10px;
  border-radius: 100%;
  margin-right: 5px;
}
content-live-tile {
  display: flex;
  flex-direction: column;
  height: 100%;
  background: var(--background-highlight-color);
}
content-live-tile > header {
  display: flex;
  justify-content: space-between;
  padding: 15px;
}
content-live-tile > header h2 {
  display: flex;
  align-items: center;
  margin: 0;
  text-transform: uppercase;
  font-size: 15px;
}
content-live-tile > header h2 rl-badge {
  margin-left: 5px;
}
content-live-tile .content-scroll-container {
  padding: 0 15px 15px 15px;
  box-sizing: border-box;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}
content-live-tile .categories {
  width: 100%;
  display: flex;
  overflow-x: auto;
}
content-live-tile .categories button {
  display: flex;
  align-items: center;
  padding: 10px;
  white-space: nowrap;
  background: var(--general-background-color);
  color: var(--general-text-color);
}
content-live-tile .categories button:not(:last-of-type) {
  margin-right: 20px;
}
content-live-tile .categories button.selected {
  background: var(--highlight-color);
  color: var(--text-on-highlight-color);
}
content-live-tile .categories button rl-badge {
  margin-left: 10px;
}
@media only screen and (min-width: 1025px) {
  content-live-tile .content-list {
    margin: 0 auto;
    max-width: 60vw;
  }
}
content-live-tile content-item:first-of-type {
  margin-top: 0;
}
content-live-tile .no-content {
  position: static;
  margin-top: 0;
}
.base-view.base-view-content,
.base-view.base-view-contentItem {
  background: var(--background-highlight-color);
  height: auto;
}
content-wrapper {
  display: block;
  position: relative;
}
content-wrapper module-search-filters.sticky {
  background-color: var(--background-highlight-color);
}
@media only screen and (max-width: 1024px) {
  content-wrapper module-search-filters {
    padding-left: var(--spacing);
    padding-right: var(--spacing);
  }
}
content-wrapper .content-wrapper {
  padding-top: var(--m-spacing);
}
@media only screen and (max-width: 1024px) {
  content-wrapper .content-wrapper {
    padding-left: var(--spacing);
    padding-right: var(--spacing);
  }
}
content-wrapper .spinner {
  position: fixed;
  width: 50px;
  height: 50px;
  border-color: var(--general-text-color);
  top: 50%;
  left: 50%;
  margin-top: -25px;
  margin-left: -25px;
  opacity: 0.4;
}
@media only screen and (min-width: 1025px) {
  content-wrapper .spinner {
    left: calc(50% + 140px);
  }
}
@media only screen and (min-width: 1300px) {
  content-wrapper .spinner {
    left: calc(50% + 180px);
  }
}
content-wrapper .no-content {
  position: relative;
  margin-top: 90px;
  top: unset;
  left: unset;
  right: unset;
}
content-wrapper .go-to-feed {
  display: block;
  padding-bottom: 20px;
}
popup-wrapper:not(.keyboard-open) comments .comments-footer {
  padding-bottom: env(safe-area-inset-bottom, 0);
}
comments .post-comments {
  padding: 0;
  display: flex;
  width: 100%;
  flex-direction: column;
  height: calc(100% - 55px);
  box-sizing: border-box;
  background: var(--background-highlight-color);
}
@media only screen and (min-width: 1025px) {
  comments .post-comments reaction-button .reactions-list {
    margin-left: -65px;
  }
}
comments .post-comments reaction-button .btn-reaction .reaction-icon-container reaction-icon {
  display: none;
}
comments .post-comments reaction-button .btn-reaction button rl-icon {
  display: none;
}
comments .post-comments .comments-list {
  position: relative;
  margin: 0;
  flex: 1 1 auto;
  overflow-y: auto;
  padding: 15px 0;
}
comments .post-comments .comments-list ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
comments .post-comments .comments-list ul ul li comment-item {
  padding-left: 65px;
}
comments .post-comments .comments-list ul ul li comment-item profile-image > .profile-image {
  width: 30px;
  height: 30px;
}
comments .post-comments .comments-list ul ul li comment-item profile-image .leave-icon {
  width: 13px;
  height: 13px;
  font-size: 13px;
}
comments .post-comments .comments-list ul ul li reaction-button .reactions-list {
  left: -35px;
}
@media only screen and (min-width: 1025px) {
  comments .post-comments .comments-list ul ul li reaction-button .reactions-list {
    left: 0;
  }
}
comments .post-comments .comments-list .expand-thread-link {
  padding-left: 105px;
  margin-top: -15px;
  margin-bottom: 20px;
}
comments .post-comments .comments-list li.faded > comment-item {
  opacity: 0.5;
}
comments .post-comments .comments-list li.faded-active .comments-actions .action-buttons {
  opacity: 0.5;
}
comments .post-comments .uploads {
  box-sizing: border-box;
  padding: 2.5px;
  max-height: 135px;
  min-height: 67px;
  overflow-y: auto;
}
comments .post-comments .uploads .media-thumbnail {
  display: block;
  float: left;
  padding: 2.5px;
  width: 25%;
  box-sizing: border-box;
  position: relative;
}
comments .post-comments .uploads .media-thumbnail img {
  width: 100%;
  vertical-align: top;
}
comments .post-comments .uploads .media-thumbnail:before {
  display: block;
  position: absolute;
  top: 5px;
  right: 5px;
  content: "\F156";
  font-family: 'Material Design Icons';
  color: #fff;
  text-shadow: -1px -1px 0 rgba(0, 0, 0, 0.5), 1px -1px 0 rgba(0, 0, 0, 0.5), -1px 1px 0 rgba(0, 0, 0, 0.5), 1px 1px 0 rgba(0, 0, 0, 0.5);
}
comments .post-comments .uploads:after {
  display: table;
  clear: both;
  content: '';
}
comments .post-comments .empty-list {
  flex: 1 1 auto;
  text-align: center;
  padding: 40px 0;
}
comments .post-comments .empty-list rl-icon {
  font-size: 40px;
}
comments .post-comments .comments-footer {
  flex: 0 0 auto;
  background: var(--general-background-color);
  color: var(--general-text-color);
  border-top: 1px solid var(--background-highlight-color);
}
comments .post-comments .comments-footer .replying-to-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px 5px 15px;
  border-top: 1px solid var(--general-border-color);
  border-bottom: 1px solid var(--general-border-color);
}
comments .post-comments .comments-footer .replying-to-label rl-icon {
  cursor: pointer;
}
comments .post-comments .comments-footer form {
  display: block;
  min-height: 40px;
  line-height: 40px;
  position: relative;
  box-sizing: border-box;
  padding: 10px 40px 10px 15px;
}
comments .post-comments .comments-footer .input-wrapper {
  position: relative;
  background: transparent;
}
comments .post-comments .comments-footer .upload {
  position: absolute;
  right: 10px;
  top: 0;
  line-height: 40px;
  z-index: 2;
  opacity: 0.6;
  color: var(--general-text-color);
}
comments .post-comments .comments-footer input[type=text] {
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 40px;
  margin: 2px 0 0 0;
  padding: 0 5px;
  font-size: 15px;
  line-height: 40px;
  background: none;
  border: none;
}
comments .post-comments .comments-footer textarea {
  display: block;
  box-sizing: border-box;
  width: 100%;
  min-height: 36px;
  height: 36px;
  max-height: 150px;
  margin: 0;
  padding: 8px 35px 8px 5px;
  resize: none;
  overflow-y: auto;
  font-size: 15px;
  line-height: 20px;
  background: none;
  border: none;
}
comments .post-comments .comments-footer button {
  position: absolute;
  box-sizing: border-box;
  top: 10px;
  padding: 0;
  right: 0;
  width: 40px;
  height: 40px;
  line-height: 40px;
  background: none;
  border: none;
  text-align: center;
  color: var(--general-text-color);
}
comments .post-comments .comments-footer button rl-icon {
  color: var(--highlight-color);
}
comments .post-comments .comments-footer button.inactive {
  opacity: 0.6;
}
comments .post-comments .comments-footer button.inactive rl-icon span {
  font-weight: 300;
  color: var(--general-text-color);
}
comment-item {
  display: flex;
  align-items: flex-start;
  padding: 0 15px;
  margin-bottom: 10px;
}
comment-item.with-bottom-bar {
  margin-bottom: 0;
}
comment-item:first-of-type .reaction-icon-wrapper .reaction-label {
  margin-bottom: 17px;
}
comment-item profile-image {
  padding-right: 10px;
}
comment-item profile-image .leave-icon {
  bottom: 5px;
  right: 8px;
}
comment-item profile-image > .profile-image {
  height: 45px;
  width: 45px;
  border: none;
}
comment-item profile-image > .profile-image.has-highscore-level {
  border: none;
}
comment-item .comment {
  flex: 1;
}
comment-item .comment-content {
  background: var(--general-background-color);
  padding: 10px;
  border-radius: 15px;
  position: relative;
}
comment-item .comment-content:after {
  content: "";
  position: absolute;
  margin-top: -6px;
  margin-left: -5px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid var(--general-background-color);
  transform: rotate(45deg);
  left: 0;
  top: 15px;
}
comment-item h3 {
  display: block;
  margin: 0;
  box-sizing: border-box;
  padding-right: 30px;
  font-size: 13px;
  font-weight: 600;
}
comment-item .comment-media {
  padding: 10px 0 5px;
}
comment-item .comment-media media-preview {
  width: 100%;
  height: 200px;
}
comment-item .comment-media media-preview .image img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}
comment-item p {
  display: block;
  margin: 0;
  padding: 5px 0 0;
  font-size: 15px;
  word-break: break-word;
}
comment-item p > span {
  font-weight: 600;
  color: var(--highlight-color);
}
comment-item > span {
  display: block;
  margin: 0;
  padding: 0 30px 0 0;
  font-size: 0.8em;
  color: #c5cad1;
}
comment-item .confirm {
  position: absolute;
  right: 0;
  top: 10px;
  height: 20px;
  width: 30px;
  display: block;
  text-align: center;
  color: #c5cad1;
  border: 0;
  background: transparent;
  font-size: 15px;
}
comment-item .confirm:hover {
  color: #000;
}
comment-item .report {
  position: absolute;
  right: 0;
  bottom: 4px;
  height: 20px;
  width: 30px;
  display: block;
  text-align: center;
  color: #bc6565;
  border: 0;
  background: transparent;
  font-size: 14px;
}
comment-item .report:hover {
  color: #9e2424;
}
comment-item .comments-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  top: -7px;
  position: relative;
}
comment-item .comments-actions .action-buttons {
  display: flex;
  align-items: center;
}
comment-item .comments-actions .action-buttons > *:not(:first-child) {
  margin-left: 10px;
}
comment-item .comments-actions .action-buttons button {
  padding: 0;
  font-size: 13px;
  font-weight: 600;
  color: var(--general-text-color);
  min-height: 46px;
}
comment-item .comments-actions .action-buttons .comment-like.liked {
  color: var(--highlight-color);
}
comment-item .comments-actions .action-buttons .comment-like.liked rl-icon {
  color: var(--highlight-color);
}
comment-item .comments-actions .reactions-labels {
  padding: 0;
}
comment-item .comments-actions .reactions-labels.extended {
  background: var(--general-background-color);
  border-radius: 15px;
  box-shadow: 0 2px 4px 0 rgba(60, 72, 88, 0.1);
}
comment-item .comments-actions .reactions-labels button {
  font-size: 13px;
  color: var(--general-text-color);
  padding: 2px 5px 2px 2px;
  display: flex;
  align-items: center;
}
comment-item .comments-actions .reactions-labels reactions-summary reaction-icon {
  width: 18px;
  height: 18px;
  box-sizing: content-box;
  border: 2px solid var(--general-background-color);
}
comment-item .blocked-user-image {
  margin-right: 10px;
  width: 45px;
  height: 45px;
  border: none;
}
comment-item .blocked-user-image.small {
  width: 30px;
  height: 30px;
}
comment-item mention-textarea profile-image .leave-icon {
  bottom: 0;
}
user-profile-popup .popup-content-inner {
  background: var(--background-highlight-color);
}
user-profile-popup profile-overview,
user-profile-popup .profile-overview-loading-container {
  flex: 1;
  overflow-y: auto;
}
.profile-overview-page {
  background: var(--background-highlight-color);
  min-height: 100%;
}
.profile-overview-page profile-overview {
  display: flex;
  flex-direction: column;
  height: auto;
}
profile-overview,
.profile-overview-loading-container {
  display: block;
  position: relative;
  height: calc(100% - 55px);
  background: var(--background-highlight-color);
}
profile-overview .sub-header .highscore-level-badge,
.profile-overview-loading-container .sub-header .highscore-level-badge {
  box-sizing: border-box;
  padding: 4px 6px 3px 6px;
  font-size: 11px;
  border-radius: 4px;
  text-transform: uppercase;
  letter-spacing: 0.7px;
}
profile-overview .block badges-list,
.profile-overview-loading-container .block badges-list {
  padding: 0 10px;
}
profile-overview .user,
.profile-overview-loading-container .user {
  padding: 15px;
  text-align: center;
}
profile-overview .user .info h2,
.profile-overview-loading-container .user .info h2 {
  margin: 0;
  min-height: 26px;
}
profile-overview .user .info > p,
.profile-overview-loading-container .user .info > p {
  margin: 0;
  min-height: 19.5px;
}
profile-overview .user .info > p.bio,
.profile-overview-loading-container .user .info > p.bio {
  padding-top: 15px;
}
profile-overview .user .info .text-loading,
.profile-overview-loading-container .user .info .text-loading {
  width: 100%;
  max-width: 200px;
  height: 18px;
  border-radius: var(--general-border-radius);
  margin: 0 auto 5px auto;
}
profile-overview .user .info .leave-information,
.profile-overview-loading-container .user .info .leave-information {
  border-radius: var(--general-border-radius);
  gap: var(--s-spacing);
  background-color: var(--background-highlight-color);
  display: flex;
  padding: var(--m-spacing);
  margin: var(--m-spacing) 0;
  align-items: flex-start;
}
profile-overview .user .info .leave-information .leave-text,
.profile-overview-loading-container .user .info .leave-information .leave-text {
  display: flex;
  flex-direction: column;
  gap: 3px;
  text-align: start;
}
profile-overview .user .info .leave-information .read-more,
.profile-overview-loading-container .user .info .leave-information .read-more {
  font-size: 0.875rem;
  color: var(--general-text-inactive-color);
  text-transform: lowercase;
  cursor: pointer;
}
profile-overview .user .info .leave-information .leave-type,
.profile-overview-loading-container .user .info .leave-information .leave-type {
  font-weight: 600;
}
profile-overview .user .info .leave-information .leave-type,
.profile-overview-loading-container .user .info .leave-information .leave-type,
profile-overview .user .info .leave-information .leave-message,
.profile-overview-loading-container .user .info .leave-information .leave-message {
  font-size: 0.875rem;
}
profile-overview .user .info .leave-information .leave-message,
.profile-overview-loading-container .user .info .leave-information .leave-message {
  transition: height 0.2s ease-out;
  position: relative;
}
profile-overview .user .kpi,
.profile-overview-loading-container .user .kpi {
  display: flex;
  margin-top: 25px;
}
profile-overview .user .kpi .kpi-item,
.profile-overview-loading-container .user .kpi .kpi-item {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
}
profile-overview .user .kpi .kpi-item rl-icon,
.profile-overview-loading-container .user .kpi .kpi-item rl-icon {
  box-shadow: 0 2px 10px 0 var(--general-border-color);
  box-sizing: border-box;
  border-radius: 100%;
  font-size: 20px;
  width: 40px;
  height: 40px;
  margin-bottom: 7px;
  color: white;
}
profile-overview .user .kpi .kpi-item p,
.profile-overview-loading-container .user .kpi .kpi-item p {
  min-height: 19.5px;
  margin: 0;
}
profile-overview .user .kpi .points:not([disabled]),
.profile-overview-loading-container .user .kpi .points:not([disabled]) {
  cursor: pointer;
}
profile-overview .user .kpi .points rl-icon,
.profile-overview-loading-container .user .kpi .points rl-icon {
  background: var(--alert-color);
}
profile-overview .user .kpi .highscore-rank:not([disabled]),
.profile-overview-loading-container .user .kpi .highscore-rank:not([disabled]) {
  cursor: pointer;
}
profile-overview .user .kpi .highscore-rank rl-icon,
.profile-overview-loading-container .user .kpi .highscore-rank rl-icon {
  background: var(--warning-color);
}
profile-overview .user .kpi .highscore-rank rl-icon span,
.profile-overview-loading-container .user .kpi .highscore-rank rl-icon span {
  margin-top: 1px;
}
profile-overview .user .kpi .swipes rl-icon,
.profile-overview-loading-container .user .kpi .swipes rl-icon {
  background: var(--confirmation-color);
}
profile-overview .user .kpi .likes rl-icon,
.profile-overview-loading-container .user .kpi .likes rl-icon {
  background: var(--highlight-color);
}
profile-overview .user .kpi.kpi-loading-container,
.profile-overview-loading-container .user .kpi.kpi-loading-container {
  flex-direction: row;
  justify-content: space-around;
}
profile-overview .user .kpi.kpi-loading-container .kpi-loading,
.profile-overview-loading-container .user .kpi.kpi-loading-container .kpi-loading {
  height: 80px;
  width: 80px;
  border-radius: var(--general-border-radius);
}
profile-overview .custom-fields .user-custom-field,
.profile-overview-loading-container .custom-fields .user-custom-field {
  margin: 10px 0;
  font-size: 14px;
}
profile-overview .custom-fields .user-custom-field td,
.profile-overview-loading-container .custom-fields .user-custom-field td {
  width: 50%;
  padding: 7px;
}
profile-overview .custom-fields .user-custom-field .field-title,
.profile-overview-loading-container .custom-fields .user-custom-field .field-title {
  text-align: right;
  font-weight: 800;
}
profile-overview .badges training-badges,
.profile-overview-loading-container .badges training-badges {
  box-sizing: border-box;
  cursor: pointer;
  margin-top: 10px;
  padding: 0 10px;
}
profile-overview .badges training-badges .training-badge p,
.profile-overview-loading-container .badges training-badges .training-badge p {
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-spacing: initial;
  font-size: 12px;
}
profile-overview .badges .no-badges,
.profile-overview-loading-container .badges .no-badges {
  display: flex;
  align-items: center;
  padding: 20px 15px;
}
profile-overview .badges .no-badges rl-icon,
.profile-overview-loading-container .badges .no-badges rl-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 60px;
  min-height: 60px;
  margin-right: 15px;
  font-size: 30px;
  border-radius: 100%;
  background: rgba(var(--general-text-color-rgb), 0.1);
}
profile-overview .badges .no-badges .description h2,
.profile-overview-loading-container .badges .no-badges .description h2 {
  margin: 0;
  padding-bottom: 5px;
  font-size: 15px;
  color: var(--general-text-color);
}
profile-overview .badges .no-badges .description p,
.profile-overview-loading-container .badges .no-badges .description p {
  margin: 0;
  font-size: 13px;
  color: var(--general-text-color);
}
profile-overview .user-logout rl-icon[icon="logout"],
.profile-overview-loading-container .user-logout rl-icon[icon="logout"] {
  color: var(--highlight-color);
}
profile-overview .block.user,
.profile-overview-loading-container .block.user {
  margin-top: 80px;
  overflow: visible;
}
.profile-page .profile-image-wrapper,
.profile-overview-loading-container .profile-image-wrapper,
profile-overview .profile-image-wrapper,
profile-settings-personal-information .profile-image-wrapper {
  position: relative;
  display: inline-block;
  margin-top: -70px;
  padding: 0 0 5px 0;
}
.profile-page .profile-image-wrapper .edit-user-image,
.profile-overview-loading-container .profile-image-wrapper .edit-user-image,
profile-overview .profile-image-wrapper .edit-user-image,
profile-settings-personal-information .profile-image-wrapper .edit-user-image,
.profile-page .profile-image-wrapper media-upload,
.profile-overview-loading-container .profile-image-wrapper media-upload,
profile-overview .profile-image-wrapper media-upload,
profile-settings-personal-information .profile-image-wrapper media-upload {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
  width: 31px;
  height: 31px;
  padding: 0;
  background: var(--white-color);
  color: var(--text-on-white-color);
  border-radius: 100%;
  box-shadow: 0 2px 10px 0 var(--general-border-color);
  z-index: 1;
}
.profile-page .profile-image-wrapper .edit-user-image rl-icon,
.profile-overview-loading-container .profile-image-wrapper .edit-user-image rl-icon,
profile-overview .profile-image-wrapper .edit-user-image rl-icon,
profile-settings-personal-information .profile-image-wrapper .edit-user-image rl-icon,
.profile-page .profile-image-wrapper media-upload rl-icon,
.profile-overview-loading-container .profile-image-wrapper media-upload rl-icon,
profile-overview .profile-image-wrapper media-upload rl-icon,
profile-settings-personal-information .profile-image-wrapper media-upload rl-icon {
  font-size: 15px;
}
.profile-page .profile-image-wrapper profile-image,
.profile-overview-loading-container .profile-image-wrapper profile-image,
profile-overview .profile-image-wrapper profile-image,
profile-settings-personal-information .profile-image-wrapper profile-image {
  padding: 0;
  width: 105px;
  height: 105px;
  border-radius: 50%;
  background-color: var(--background-highlight-color);
  box-shadow: 0 2px 10px 0 var(--general-border-color);
}
.profile-page .profile-image-wrapper profile-image .leave-icon,
.profile-overview-loading-container .profile-image-wrapper profile-image .leave-icon,
profile-overview .profile-image-wrapper profile-image .leave-icon,
profile-settings-personal-information .profile-image-wrapper profile-image .leave-icon {
  width: 34px;
  height: 34px;
  font-size: 34px;
  bottom: 0;
  right: 0;
}
.profile-page .profile-image-wrapper profile-image .profile-image,
.profile-overview-loading-container .profile-image-wrapper profile-image .profile-image,
profile-overview .profile-image-wrapper profile-image .profile-image,
profile-settings-personal-information .profile-image-wrapper profile-image .profile-image {
  min-height: 105px;
  min-width: 105px;
}
.base-view-blockedUsers {
  background-color: var(--background-highlight-color);
}
blocked-users {
  display: block;
  padding: 10px 15px;
}
blocked-users .blocked-users-wrapper {
  background-color: var(--general-background-color);
  border-radius: var(--general-border-radius);
}
@media only screen and (min-width: 1025px) {
  blocked-users .blocked-users-wrapper {
    max-width: 60vw;
    margin: 0 auto;
  }
}
blocked-users .user {
  display: flex;
  align-items: center;
  padding: 10px;
}
blocked-users .user .profile-image {
  height: 36px;
  width: 36px;
  margin-right: 10px;
}
blocked-users .user .details {
  display: flex;
  flex-direction: column;
  margin-right: 10px;
}
blocked-users .user button {
  margin-left: auto;
  padding: 4px 6px;
  border-radius: var(--general-border-radius);
  background-color: var(--highlight-color);
  color: var(--text-on-highlight-color);
}
.no-content .description {
  font-weight: normal;
}
profile-desktop-dropdown-menu .app-header-dropdown {
  right: 0;
}
profile-desktop-dropdown-menu .app-header-dropdown .app-header-dropdown-content {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
profile-desktop-dropdown-menu .profile-desktop-dropdown-menu-header {
  box-sizing: border-box;
  padding: 15px 15px 5px 15px;
}
profile-desktop-dropdown-menu .profile-desktop-dropdown-menu-header:not(:first-of-type) {
  padding-top: 20px;
}
profile-desktop-dropdown-menu .profile-desktop-dropdown-menu-header h3 {
  margin: 0;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 700;
  color: var(--general-text-color);
}
profile-desktop-dropdown-menu ul {
  display: block;
  list-style: none;
  padding: 0;
  margin: 0;
}
profile-desktop-dropdown-menu ul li {
  display: block;
  width: 100%;
}
profile-desktop-dropdown-menu ul li button {
  display: flex;
  width: 100%;
  padding: 15px;
  margin: 0;
  color: var(--general-text-color);
  justify-content: flex-start;
  align-items: center;
  min-height: 49px;
  cursor: pointer;
}
profile-desktop-dropdown-menu ul li button span {
  line-height: 1rem;
}
profile-desktop-dropdown-menu ul li button rl-icon:first-child {
  margin-right: 10px;
}
profile-desktop-dropdown-menu ul li button rl-icon:last-child {
  margin-left: auto;
}
profile-desktop-dropdown-menu ul li button rl-icon[icon="back"],
profile-desktop-dropdown-menu ul li button rl-icon[icon="next"] {
  font-size: 16px;
}
profile-desktop-dropdown-menu ul li button.user-logout rl-icon {
  color: var(--highlight-color);
}
profile-desktop-dropdown-menu ul li button:hover {
  background: var(--background-highlight-color);
}
.profile-settings-menu {
  display: block;
  list-style: none;
  padding: 0;
  margin: 0;
}
.profile-settings-menu > li {
  list-style: none;
  display: block;
  width: 100%;
}
.profile-settings-menu > li button {
  display: flex;
  width: 100%;
  padding: 15px;
  margin: 0;
  color: var(--general-text-color);
  justify-content: flex-start;
  align-items: center;
  min-height: 49px;
  cursor: pointer;
}
.profile-settings-menu > li button .text {
  line-height: 1rem;
  text-align: left;
  position: relative;
  word-wrap: break-word;
}
.profile-settings-menu > li button .text-container {
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 0 0 -5px 0;
}
.profile-settings-menu > li button .text-container .subtext {
  font-size: 12px;
  margin-top: 1px;
  color: var(--general-text-inactive-color);
  word-wrap: break-word;
  line-height: 1rem;
  position: relative;
  text-align: left;
}
.profile-settings-menu > li button .text-container .subtext:hover {
  cursor: text;
}
.profile-settings-menu > li button rl-icon:first-child {
  margin-right: 10px;
}
.profile-settings-menu > li button rl-icon:last-child {
  margin-left: auto;
}
.profile-settings-menu > li button rl-icon[icon="back"],
.profile-settings-menu > li button rl-icon[icon="next"] {
  font-size: 16px;
}
.profile-settings-menu > li button:hover {
  background: rgba(var(--background-highlight-color-rgb), 0.5);
}
.profile-settings-page {
  background: var(--background-highlight-color);
  min-height: 100%;
}
.profile-settings-page profile-settings {
  display: flex;
  flex-direction: column;
  height: auto;
}
.profile-settings-edit-page profile-settings-accessibility,
.profile-settings-edit-page profile-settings-language,
.profile-settings-edit-page profile-settings-celebrations,
.profile-settings-edit-page profile-settings-communication,
.profile-settings-edit-page profile-settings-shared-information,
.profile-settings-edit-page profile-settings-personal-information,
.profile-settings-edit-page profile-settings-security,
.profile-settings-edit-page profile-settings-security-password {
  display: flex;
  flex-direction: column;
  height: auto;
}
.profile-settings-edit-page.floating-footer {
  padding-bottom: 125px;
}
.profile-settings-edit-page .profile-settings-edit-page-footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80px;
  padding: 0 15px;
  box-sizing: border-box;
}
.profile-settings-edit-page .profile-settings-edit-page-footer.floating-footer {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 49px;
  background: var(--general-background-color);
  box-shadow: 0px 10px 45px 35px var(--general-background-color);
}
.profile-settings-edit-page .profile-settings-edit-page-footer rl-button button {
  min-width: 240px;
}
@media only screen and (min-width: 1025px) {
  .profile-settings-edit-page .profile-settings-edit-page-footer.floating-footer {
    bottom: 0;
    left: var(--sidemenu-width);
  }
}
.profile-settings,
profile-overview {
  margin: 0 auto;
  width: 100%;
  max-width: var(--max-width);
}
.profile-settings .block,
profile-overview .block {
  margin: var(--spacing) var(--spacing) 0 var(--spacing);
  position: relative;
  overflow: hidden;
  border: 1px solid var(--general-border-color);
  border-radius: var(--general-border-radius);
  background: var(--general-background-color);
}
.profile-settings .block:first-of-type:not(.user),
profile-overview .block:first-of-type:not(.user) {
  margin-top: 20px;
}
.profile-settings .block:last-of-type,
profile-overview .block:last-of-type {
  margin-bottom: 20px;
}
.profile-settings .block header,
profile-overview .block header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-height: auto;
  padding: 15px;
  border-bottom: 1px solid var(--general-border-color);
}
.profile-settings .block header h2,
profile-overview .block header h2 {
  margin: 0;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 700;
  color: var(--general-text-color);
}
.profile-settings .block header button,
profile-overview .block header button {
  padding: 0;
  font-size: 13px;
  color: var(--highlight-color);
  font-weight: 700;
}
.profile-settings .block .user-profile-loading,
profile-overview .block .user-profile-loading {
  border-radius: var(--general-border-radius);
  height: 25px;
  margin: 12.5px 15px;
}
.profile-settings .block .user-profile-loading:not(:first-of-type),
profile-overview .block .user-profile-loading:not(:first-of-type) {
  margin-top: 20px;
}
.profile-settings .info-block,
profile-overview .info-block {
  margin: 0 10px;
  padding: 20px 10px;
  position: initial;
  overflow: initial;
  border-bottom: 1px solid var(--general-border-color);
}
.profile-settings .info-block p,
profile-overview .info-block p {
  margin: 0 0 10px 0;
}
.profile-settings .info-block p:last-of-type,
profile-overview .info-block p:last-of-type {
  margin-bottom: 0;
}
.profile-settings .edit-block,
profile-overview .edit-block {
  margin: 0 10px;
  padding: 20px 10px;
  position: initial;
  overflow: initial;
  border-bottom: 1px solid var(--general-border-color);
}
.profile-settings .edit-block:first-of-type,
profile-overview .edit-block:first-of-type {
  margin-top: 20px;
}
.profile-settings .edit-block:last-of-type,
profile-overview .edit-block:last-of-type {
  margin-bottom: 30px;
}
.profile-settings .edit-block:last-of-type + .profile-settings-edit-page-footer,
profile-overview .edit-block:last-of-type + .profile-settings-edit-page-footer {
  margin-top: -20px;
}
.profile-settings .edit-block .user-profile-loading,
profile-overview .edit-block .user-profile-loading {
  border-radius: var(--general-border-radius);
  height: 40px;
  margin: -5px 0px;
}
.profile-settings .edit-block .user-profile-loading.user-profile-loading-textarea,
profile-overview .edit-block .user-profile-loading.user-profile-loading-textarea {
  height: 120px;
}
.profile-settings .edit-block .user-profile-loading-image,
profile-overview .edit-block .user-profile-loading-image {
  height: 105px;
  width: 105px;
  border-radius: 100%;
  margin-left: auto;
  margin-right: auto;
}
.profile-settings .edit-block.edit-block-toggle,
profile-overview .edit-block.edit-block-toggle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.profile-settings .edit-block.edit-block-toggle > label,
profile-overview .edit-block.edit-block-toggle > label {
  display: block;
  font-weight: 500;
}
.profile-settings .edit-block.edit-block-toggle.edit-block-toggle-disabled > label,
profile-overview .edit-block.edit-block-toggle.edit-block-toggle-disabled > label {
  color: var(--general-text-inactive-color);
}
.profile-settings .edit-block.edit-block-link,
profile-overview .edit-block.edit-block-link {
  display: block;
  padding: 10px 0;
}
.profile-settings .edit-block.edit-block-link button,
profile-overview .edit-block.edit-block-link button {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  cursor: pointer;
  margin: 0;
  padding: 10px;
}
.profile-settings .edit-block.edit-block-link button:hover,
profile-overview .edit-block.edit-block-link button:hover {
  background: rgba(var(--background-highlight-color-rgb), 0.5);
  border-radius: var(--general-border-radius);
}
.profile-settings .edit-block.edit-block-link button .link-icon,
profile-overview .edit-block.edit-block-link button .link-icon {
  align-items: center;
  background: var(--background-highlight-color);
  border-radius: 100%;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  height: 40px;
  justify-content: center;
  width: 40px;
}
.profile-settings .edit-block.edit-block-link button .link-icon.link-icon-pin-code rl-icon,
profile-overview .edit-block.edit-block-link button .link-icon.link-icon-pin-code rl-icon {
  font-size: 17px;
}
.profile-settings .edit-block.edit-block-link button .link-icon.link-icon-pin-code .pin-container,
profile-overview .edit-block.edit-block-link button .link-icon.link-icon-pin-code .pin-container {
  display: flex;
  flex-direction: row;
  margin-top: 3px;
  font-size: 6px;
  justify-content: space-between;
  width: 22px;
}
.profile-settings .edit-block.edit-block-link button .text,
profile-overview .edit-block.edit-block-link button .text {
  display: block;
  font-weight: 500;
  margin-left: 15px;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
}
.profile-settings .edit-block.edit-block-link button .next-text,
profile-overview .edit-block.edit-block-link button .next-text {
  display: block;
  margin: 0 0 0 auto;
  padding: 0;
  text-align: right;
}
.profile-settings .edit-block.edit-block-link button .next-text.inactive,
profile-overview .edit-block.edit-block-link button .next-text.inactive {
  color: rgba(var(--general-text-color-rgb),.5);
}
.profile-settings .edit-block.edit-block-link button .next-text.active,
profile-overview .edit-block.edit-block-link button .next-text.active {
  font-weight: 500;
  color: var(--highlight-color);
}
.profile-settings .edit-block.edit-block-link button .link-next-pin-code,
profile-overview .edit-block.edit-block-link button .link-next-pin-code {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 20px;
  list-style: none;
  margin: 0 0 0 auto;
  padding: 0;
}
.profile-settings .edit-block.edit-block-link button .link-next-pin-code li,
profile-overview .edit-block.edit-block-link button .link-next-pin-code li {
  display: block;
  height: 8px;
  width: 8px;
  border-radius: 100%;
  background: var(--highlight-color);
  margin: 0 4px;
  padding: 0;
  list-style: none;
}
.profile-settings .edit-block.edit-block-link button .next-text + rl-icon[icon="next"],
profile-overview .edit-block.edit-block-link button .next-text + rl-icon[icon="next"],
.profile-settings .edit-block.edit-block-link button .link-next-pin-code + rl-icon[icon="next"],
profile-overview .edit-block.edit-block-link button .link-next-pin-code + rl-icon[icon="next"] {
  margin-left: 10px;
}
.profile-settings .edit-block.edit-block-link button rl-icon[icon="next"],
profile-overview .edit-block.edit-block-link button rl-icon[icon="next"] {
  margin-left: auto;
}
.profile-settings .edit-block.edit-block-input > label,
profile-overview .edit-block.edit-block-input > label {
  display: block;
  padding: 5px 0;
  font-size: 14px;
  color: var(--general-text-inactive-color);
}
.profile-settings .edit-block.edit-block-input > rl-select .select-area.disabled,
profile-overview .edit-block.edit-block-input > rl-select .select-area.disabled {
  cursor: progress;
}
.profile-settings .edit-block.edit-block-input > rl-text-input,
profile-overview .edit-block.edit-block-input > rl-text-input,
.profile-settings .edit-block.edit-block-input > rl-select,
profile-overview .edit-block.edit-block-input > rl-select,
.profile-settings .edit-block.edit-block-input > rl-textarea,
profile-overview .edit-block.edit-block-input > rl-textarea,
.profile-settings .edit-block.edit-block-input > rl-phone-input,
profile-overview .edit-block.edit-block-input > rl-phone-input {
  margin: 0 -10px;
  width: calc(100% + 20px);
}
.profile-settings .edit-block.edit-block-input > rl-text-input textarea:disabled,
profile-overview .edit-block.edit-block-input > rl-text-input textarea:disabled,
.profile-settings .edit-block.edit-block-input > rl-select textarea:disabled,
profile-overview .edit-block.edit-block-input > rl-select textarea:disabled,
.profile-settings .edit-block.edit-block-input > rl-textarea textarea:disabled,
profile-overview .edit-block.edit-block-input > rl-textarea textarea:disabled,
.profile-settings .edit-block.edit-block-input > rl-phone-input textarea:disabled,
profile-overview .edit-block.edit-block-input > rl-phone-input textarea:disabled,
.profile-settings .edit-block.edit-block-input > rl-text-input input:disabled,
profile-overview .edit-block.edit-block-input > rl-text-input input:disabled,
.profile-settings .edit-block.edit-block-input > rl-select input:disabled,
profile-overview .edit-block.edit-block-input > rl-select input:disabled,
.profile-settings .edit-block.edit-block-input > rl-textarea input:disabled,
profile-overview .edit-block.edit-block-input > rl-textarea input:disabled,
.profile-settings .edit-block.edit-block-input > rl-phone-input input:disabled,
profile-overview .edit-block.edit-block-input > rl-phone-input input:disabled {
  opacity: 1;
  background-color: var(--locked-field-color);
  color: var(--general-text-inactive-color);
  cursor: not-allowed;
}
profile-settings-personal-information .profile-settings-personal-information-image {
  display: flex;
  margin-top: 25px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: none;
}
profile-settings-personal-information .profile-settings-personal-information-image .profile-image-wrapper {
  margin-top: 0;
}
profile-settings-personal-information .profile-settings .edit-block-input {
  position: relative;
}
profile-settings-personal-information .profile-settings .field-postfix {
  position: absolute;
  right: var(--m-spacing);
  color: var(--general-text-inactive-color);
  bottom: calc(50% - 25px);
}
leave-registration {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}
leave-registration .leave-registration {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
}
leave-registration .rl-tabs {
  margin: var(--s-spacing);
  overflow-x: auto;
  overflow-y: hidden;
  min-height: 40px;
}
leave-registration .rl-tabs .rl-tab {
  cursor: pointer;
}
leave-registration .empty-state {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--s-spacing);
}
leave-registration .empty-state rl-icon {
  font-size: 4rem;
  color: rgba(var(--general-text-color-rgb), 0.4);
}
leave-registration .empty-state .leave-title {
  font-size: 1rem;
  font-weight: 600;
}
leave-registration .empty-state .leave-text {
  font-size: 0.875rem;
}
leave-registration h2 {
  font-size: 0.875rem;
  font-weight: 600;
}
leave-registration .leaves-list {
  padding: 0 var(--spacing) var(--spacing);
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  scrollbar-color: rgba(0, 0, 0, 0.15) transparent;
  scrollbar-width: thin;
}
leave-registration .leaves-list rl-loading-placeholder .placeholder-shimmer {
  height: 60px;
}
leave-registration .leaves-list rl-loading-placeholder .placeholder-shimmer:first-child {
  margin-top: var(--spacing);
}
leave-registration .leaves-list .leave-item {
  display: flex;
  padding: var(--m-spacing);
  gap: var(--m-spacing);
  margin-bottom: var(--m-spacing);
  border-radius: var(--general-border-radius);
  background-color: var(--background-highlight-color);
}
leave-registration .leaves-list .leave-info {
  display: flex;
  flex-direction: column;
}
leave-registration .leaves-list .leave-info span:first-child {
  font-weight: 600;
  font-size: 1rem;
}
leave-registration .leaves-list .leave-info span:last-child {
  font-size: 0.875rem;
}
leave-registration .leaves-list .current {
  color: var(--highlight-color);
}
leave-registration .leaves-list .past {
  color: rgba(var(--general-text-color-rgb), 0.5);
}
leave-registration .leaves-list .duration-label {
  font-size: 0.875rem;
  margin-left: auto;
  color: rgba(var(--general-text-color-rgb), 0.5);
}
leave-registration icon-plus button.right-bottom {
  transform: translate3d(0, 0, 0) !important;
}
@media only screen and (min-width: 1025px) {
  leave-registration icon-plus button.right-bottom {
    transform: none !important;
    right: calc((100cqw - var(--max-width)) / 2 - 10px);
  }
}
.base-view-leaveRegistration {
  overflow: hidden;
  container-type: inline-size;
}
leave-registration-popup-component .content {
  display: flex;
  flex-direction: column;
  gap: var(--spacing);
  padding: var(--spacing);
}
leave-registration-popup-component rl-icon[icon="delete"] {
  color: var(--warning-color);
}
leave-registration-popup-component .block {
  display: flex;
  flex-direction: column;
}
leave-registration-popup-component .block .loader-dropdown,
leave-registration-popup-component .block .loader-time,
leave-registration-popup-component .block .loader-textarea {
  margin-bottom: calc(2*(var(--spacing)));
}
leave-registration-popup-component .block .loader-textarea .placeholder-shimmer {
  height: 150px;
}
leave-registration-popup-component .block h2 {
  font-size: 0.875rem;
  font-weight: 600;
  padding-left: var(--m-spacing);
}
leave-registration-popup-component .block ul {
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
}
leave-registration-popup-component .block ul rl-toggle {
  margin-left: auto;
}
leave-registration-popup-component .block li {
  padding: var(--m-spacing);
  gap: var(--s-spacing);
  list-style: none;
  display: flex;
  align-items: center;
}
leave-registration-popup-component .block li.comments-section {
  padding: 0;
}
leave-registration-popup-component .block li.disable {
  opacity: 0.7;
  pointer-events: none;
}
leave-registration-popup-component .block li.readonly {
  opacity: 0.7;
}
leave-registration-popup-component .block .leave-details {
  border-radius: var(--general-border-radius);
  background-color: var(--background-highlight-color);
}
leave-registration-popup-component .block .leave-details.disable {
  opacity: 0.7;
  pointer-events: none;
}
leave-registration-popup-component .block .leave-details.highlight {
  border: 1px solid var(--alert-color);
}
leave-registration-popup-component .block .leave-details.dropdown {
  display: flex;
  padding: var(--m-spacing);
  justify-content: flex-start;
  align-items: center;
  gap: var(--s-spacing);
}
leave-registration-popup-component .block .leave-details.dropdown rl-icon[icon='triangle-down'] {
  margin-left: auto;
}
leave-registration-popup-component .block .leave-details .date {
  width: 100%;
}
leave-registration-popup-component .block .leave-details .date .time {
  display: flex;
  width: 100%;
}
leave-registration-popup-component .block input[type='date'],
leave-registration-popup-component .block input[type='datetime-local'] {
  flex: auto;
  background-color: unset;
  border: unset;
  color: var(--general-text-color);
  font-size: 1rem !important;
}
leave-registration-popup-component rl-textarea textarea {
  padding: var(--m-spacing);
}
leave-registration-popup-component footer {
  width: calc(100% - 2 * (var(--spacing)));
  position: absolute;
  bottom: 0;
  margin: var(--spacing);
  padding-bottom: env(safe-area-inset-bottom);
}
leave-registration-popup-component footer button {
  width: 100%;
  font-size: 1rem;
  text-transform: uppercase;
}
@media only screen and (min-width: 1025px) {
  absence-dropdown-component .actionsheet {
    min-width: 350px;
    padding: var(--spacing);
  }
}
absence-dropdown-component action-sheet-custom .actionsheet .actionsheet-content {
  padding-top: 0;
}
@media only screen and (min-width: 1025px) {
  absence-dropdown-component action-sheet-custom .actionsheet .actionsheet-content {
    padding-top: var(--spacing);
  }
}
absence-dropdown-component rl-select-list .item {
  justify-content: flex-start;
}
absence-dropdown-component rl-select-list .item .labels-container {
  margin-right: auto;
}
about-relesys {
  display: block;
  max-width: 600px;
  margin: 0 auto;
}
about-relesys .header-image {
  height: 210px;
  background-image: url("/Content/Graphics/about-relesys-header-v2.png");
  background-position: top center;
  background-size: cover;
}
about-relesys section {
  padding: 20px 15px;
}
about-relesys h1 {
  padding-bottom: 15px;
  margin: 0;
  font-size: 20px;
  font-weight: 600;
  color: var(--general-text-color);
}
about-relesys p {
  margin: 0 0 20px 0;
  line-height: 1.4;
  font-size: 14px;
  color: var(--general-text-color);
}
about-relesys a {
  text-decoration: none;
  font-weight: 600;
  color: var(--highlight-color);
}
about-relesys .logo {
  height: 50px;
  width: 100%;
  max-width: 190px;
}
about-relesys .logo svg {
  stroke-width: 0;
}
about-relesys .logo svg path {
  fill: var(--general-text-color);
}
about-relesys .logo svg path[fill="#EA6F63"] {
  fill: #ea6f63;
}
points-log filter-button {
  justify-content: flex-end;
}
points-log .profile-point-log {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
}
points-log .profile-point-log header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100px;
  background: #F7F8FA;
  border-bottom: 1px solid #EBECEE;
}
points-log .profile-point-log header h2 {
  margin: 0;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 22px;
  color: #3c4858;
}
points-log .profile-point-log header p {
  margin: 0;
  font-size: 15px;
  color: #3c4858;
}
points-log .profile-point-log article {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  border-bottom: 1px solid #EBECEE;
}
points-log .profile-point-log article .left {
  display: flex;
}
points-log .profile-point-log article rl-icon {
  box-sizing: border-box;
  margin-right: 10px;
  padding: 6px;
  width: 38px;
  min-width: 38px;
  height: 38px;
  font-size: 20px;
  border-radius: 50%;
  background: #EBECEE;
}
points-log .profile-point-log article .description h3 {
  margin: 0;
  padding-bottom: 5px;
  font-weight: 600;
  font-size: 15px;
  color: #3c4858;
}
points-log .profile-point-log article .description span {
  font-size: 13px;
  color: #8893A3;
}
points-log .profile-point-log article .points {
  font-weight: 600;
  font-size: 15px;
  min-width: 40px;
  text-align: right;
  padding: 0 5px;
}
points-log .modal-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
points-log .modal-loader .spinner {
  width: 50px;
  height: 50px;
}
points-log .empty-list {
  display: block;
  padding: 20px 0;
}
points-log .empty-list .mdi {
  display: block;
  text-align: center;
  font-size: 70px;
}
points-log .empty-list p {
  display: block;
  margin: 10px 0 0 0;
  padding: 0 20px;
  text-align: center;
  font-size: 18px;
}
policy-view .loading-container,
policy-overview .loading-container {
  height: 80vh;
  width: 100%;
  top: 0;
  left: 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
policy-view .loading-container .spinner,
policy-overview .loading-container .spinner {
  height: 40px;
  width: 40px;
}
.base-view.base-view-policyOverview {
  height: auto;
  min-height: unset;
}
.base-view.base-view-policyOverview .view-page {
  max-width: unset;
  max-inline-size: unset !important;
}
policy-overview .policies-list {
  display: flex;
  flex-direction: column;
  gap: var(--spacing);
  margin: var(--spacing);
  min-width: 255px;
}
policy-overview .listed-policy {
  border-radius: var(--general-border-radius);
  background: var(--background-highlight-color);
  border: 1px solid var(--background-highlight-color);
  padding: var(--m-spacing);
  display: flex;
  gap: var(--m-spacing);
}
policy-overview .listed-policy rl-icon {
  font-size: 1.7rem;
}
policy-overview .displayed-policy {
  flex-grow: 1;
  max-width: var(--max-width);
  transition: opacity 0.3s ease;
  opacity: 1;
}
policy-overview .hide-policy {
  opacity: 0;
}
policy-overview .title {
  overflow: hidden;
  color: var(--general-text-color);
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 1rem;
  font-weight: 600;
  line-height: 130%;
  margin: 0 0 2px;
  max-width: 70vw;
}
policy-overview .approval-date {
  color: var(--confirmation-color);
  display: flex;
  gap: 5px;
  margin: 0;
}
policy-overview .approval-date i {
  font-size: 1rem;
}
policy-overview .approval-date span {
  font-weight: 600;
  font-size: 0.85rem;
}
policy-overview .desktop-layout {
  display: flex;
  gap: var(--spacing);
  padding: 0 var(--spacing) var(--spacing);
  justify-content: center;
}
policy-overview .desktop-layout.with-policy-list {
  margin-left: -255px;
}
policy-overview .desktop-layout .policies-list .listed-policy:hover,
policy-overview .desktop-layout .policies-list .selected {
  cursor: pointer;
  border: 1px solid var(--highlight-color);
  background-color: rgba(var(--highlight-color-rgb), 0.2);
}
policy-overview .desktop-layout .policies-list .listed-policy {
  transition: background-color 0.3s ease, border 0.3s ease;
}
policy-overview .desktop-layout .policies-list .listed-policy rl-icon {
  font-size: 1.25rem;
}
policy-overview .desktop-layout .policies-list .listed-policy .title {
  margin-bottom: 0;
  max-width: 200px;
}
policy-overview .desktop-layout .policies-list .listed-policy .approval-date {
  display: none;
}
policy-overview .no-content {
  margin-top: 200px;
}
@media (max-width: 1700px) {
  policy-overview .desktop-layout.with-policy-list {
    margin-left: -220px;
  }
}
@media (max-width: 1500px) {
  policy-overview .desktop-layout.with-policy-list {
    margin-left: 0;
  }
}
.base-view.base-view-policyView {
  height: auto;
  min-height: unset;
}
policy-view .policy-view-container {
  position: relative;
  max-height: calc(100vh - 55px - 50px - env(safe-area-inset-bottom));
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.15) transparent;
}
policy-view .policy-view-container.isDesktop {
  margin-top: var(--spacing);
  background-color: var(--background-highlight-color);
  border-radius: var(--general-border-radius);
}
policy-view .policy-counter {
  position: absolute;
  top: 0;
  right: var(--spacing);
  color: var(--general-text-inactive-color);
}
policy-view .policy-view-top {
  height: 120px;
  width: 100%;
  padding: var(--m-spacing) var(--spacing) 0 var(--spacing);
  position: relative;
  background-repeat: repeat-x;
  flex: 0 0 auto;
  box-sizing: border-box;
}
policy-view .policy-view-top .policy-view-top-inner {
  display: block;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 2;
  text-align: right;
}
policy-view .policy-view-top .policy-view-top-inner svg.policy-shield-element {
  height: 90px;
  position: absolute;
  left: 0;
  bottom: 10px;
  animation: policy-view-shield-animation 4s ease-in-out infinite;
  z-index: 3;
}
policy-view .policy-view-top .policy-view-top-inner svg.policy-cloud-background {
  height: 100%;
}
policy-view .policy-view-content {
  padding: var(--spacing);
}
policy-view .policy-view-content .accept-date {
  color: var(--confirmation-color);
  display: flex;
  gap: 5px;
}
policy-view .policy-view-content .accept-date i {
  font-size: 1rem;
}
policy-view .policy-view-content .accept-date span {
  font-weight: 600;
  font-size: 0.85rem;
}
policy-view .policy-view-content h2 {
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 120%;
  margin: var(--m-spacing) 0 var(--spacing);
}
policy-view .revoke-btn-container {
  position: sticky;
  bottom: -1px;
  display: flex;
  justify-content: flex-end;
}
policy-view .revoke-btn-container button {
  font-size: 1rem;
  line-height: 1rem;
  text-transform: uppercase;
}
policy-view .revoke-btn-container.isDesktop {
  padding: var(--spacing);
  background: linear-gradient(180deg, transparent 0%, var(--background-highlight-color) 69.5%);
}
policy-view .revoke-btn-container.isDesktop button {
  background-color: var(--button-background-color);
}
policy-view .revoke-btn-container.isMobile {
  padding: 0 var(--spacing) var(--spacing);
  box-shadow: 0px -10px 35px 15px var(--general-background-color);
  background-color: var(--general-background-color);
}
policy-view .revoke-btn-container.isMobile rl-button {
  flex-basis: 100%;
}
policy-view .revoke-btn-container.isMobile rl-button button {
  background: var(--warning-color);
  width: 100%;
}
policy-view .policy-view-loading {
  height: 100%;
  min-height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
policy-view .policy-view-loading .spinner {
  height: 40px;
  width: 40px;
}
policy-view .policy-view-revoke-wrapper {
  position: fixed;
  z-index: 9999999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
policy-view .policy-view-revoke-wrapper .spinner {
  height: 50px;
  width: 50px;
  border-color: #fff;
}
.revoke-consent-confirmation-popup rl-button.yes button {
  color: var(--button-background-color);
}
.revoke-consent-confirmation-popup button {
  text-transform: uppercase;
}
@keyframes policy-view-shield-animation {
  0% {
    transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
  }
  50% {
    transform: translate3d(0, -6px, 0) scale3d(1.05, 1.05, 1.05);
  }
  100% {
    transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
  }
}
.filter-button-wrapper {
  min-width: 32px;
}
filter-button {
  display: inline-flex;
  height: 100%;
}
filter-button.filter-btn-title > button rl-icon {
  font-size: 16px;
}
filter-button.filter-btn-title > button rl-icon[icon=info] {
  font-size: 19px;
}
filter-button.filter-btn-title > button:before,
filter-button.filter-btn-title > button:after {
  content: none;
}
filter-button.filter-btn-title ul {
  box-shadow: 0 2px 10px 0 rgba(60, 72, 88, 0.05);
  backdrop-filter: blur(11px);
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 5px;
  padding: 5px;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  margin: 0;
  top: 52px;
  right: auto;
}
@media only screen and (min-width: 1025px) {
  filter-button.filter-btn-title ul {
    position: absolute;
  }
}
filter-button.filter-btn-title ul li {
  color: var(--text-on-white-color);
}
filter-button.filter-btn-title ul li.caption {
  display: none;
}
filter-button.filter-btn-title ul li.selected {
  font-weight: 600;
}
filter-button.filter-btn-title ul li.selected:after {
  font-size: 16px;
  padding: 3px;
  margin-top: -12px;
  font-weight: 600;
  color: var(--white-color);
  background: var(--highlight-color);
}
filter-button.filter-btn-title ul li .item-title rl-badge {
  opacity: 1;
  display: inline-block;
  right: auto;
  top: -2px;
  margin-left: 5px;
}
filter-button .overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 40;
  display: none;
}
filter-button button:before,
filter-button button:after {
  content: "";
  opacity: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #EBECEE;
  width: 0;
  height: 0;
  position: absolute;
  bottom: -7px;
  z-index: 200;
  left: 50%;
  margin-left: -6px;
  transition: all linear 0.2s;
}
filter-button button:after {
  border-bottom: 6px solid white;
  bottom: -9px;
}
filter-button button.hidden {
  display: none !important;
}
filter-button button.active {
  color: var(--highlight-color) !important;
}
filter-button button.active.with-items:before,
filter-button button.active.with-items:after {
  opacity: 1;
}
filter-button button.active rl-icon[icon=filters-active] {
  background: var(--highlight-color);
  color: var(--text-on-highlight-color);
}
filter-button button rl-icon[icon=filters],
filter-button button rl-icon[icon=filters-active] {
  width: 24px;
  height: 24px;
  border-radius: 5px;
  font-size: 1rem;
}
filter-button button rl-badge {
  position: absolute;
  top: 50%;
  margin-top: -18px;
  right: 0;
  bottom: auto;
  left: auto;
}
filter-button ul {
  position: absolute;
  z-index: 50;
  right: 10px;
  top: 62px;
  margin: 0;
  padding: 0;
  min-width: 200px;
  opacity: 0;
  background: var(--white-color);
  list-style: none;
  font-size: 14px;
  text-align: left;
  box-shadow: 0 2px 20px 0 rgba(60, 72, 88, 0.1);
  transition: all linear 0.2s;
}
filter-button ul.active {
  color: var(--highlight-color);
  opacity: 1;
}
filter-button ul.active + .overlay {
  display: block;
}
filter-button ul li {
  padding: 10px 40px 10px 15px;
  color: var(--text-on-white-color);
  position: relative;
}
filter-button ul li.caption {
  text-transform: uppercase;
  font-weight: 600;
  border-bottom: 1px solid #EBECEE;
}
filter-button ul li.selected:after {
  position: absolute;
  width: 18px;
  height: 18px;
  right: 10px;
  background: var(--highlight-color);
  content: '\f00c';
  font-weight: 200;
  font-family: "Font Awesome\ 6 Pro";
  top: 50%;
  margin-top: -9px;
  text-align: center;
  border-radius: 50%;
  color: white;
  font-size: 10px;
  line-height: 18px;
}
filter-button ul li .item-title {
  position: relative;
}
filter-button ul li .item-title rl-badge {
  opacity: 1;
  position: absolute;
  right: -14px;
  top: -4px;
  text-align: center;
  bottom: auto;
  left: auto;
}
.app-header-dropdown-wrapper {
  position: relative;
}
.app-header-dropdown-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 420;
  background: transparent;
  display: block;
  transition: all 0.1s;
  opacity: 0;
}
.app-header-dropdown-overlay.show {
  opacity: 1;
}
.app-header-dropdown {
  position: absolute;
  top: 55px;
  left: auto;
  right: -20px;
  bottom: auto;
  width: 375px;
  display: block;
  z-index: 421;
  background: none;
  text-align: left;
}
.app-header-dropdown.show .app-header-dropdown-content {
  transform: translate3d(0, 0, 0);
}
.app-header-dropdown .app-header-dropdown-content {
  overflow: hidden;
  position: relative;
  transform: translate3d(0, 5%, 0);
  transition: all 0.1s;
  border-radius: var(--general-border-radius);
  background: var(--general-background-color);
  box-shadow: 0px 7px 15px 0px rgba(60, 72, 88, 0.1);
}
.app-header-dropdown .app-header-dropdown-body {
  display: block;
  max-height: 600px;
  margin: 0;
  box-sizing: border-box;
  flex: 1 1 auto;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
}
.app-header-dropdown .app-header-dropdown-header {
  display: block;
}
.app-header-dropdown .app-header-dropdown-header app-header-dropdown-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  flex: 0 0 auto;
  border-bottom: 1px solid var(--general-border-color);
  box-sizing: border-box;
  font-weight: 600;
  position: relative;
  padding: 15px;
  min-height: 50px;
}
.app-header-dropdown .app-header-dropdown-header app-header-dropdown-header.no-justify {
  justify-content: unset;
}
.app-header-dropdown .app-header-dropdown-header app-header-dropdown-header.no-justify rl-icon + h2 {
  margin-left: 5px;
}
.app-header-dropdown .app-header-dropdown-header app-header-dropdown-header.clickable {
  cursor: pointer;
}
.app-header-dropdown .app-header-dropdown-header app-header-dropdown-header.clickable:hover {
  background: var(--background-highlight-color);
}
.app-header-dropdown .app-header-dropdown-header app-header-dropdown-header rl-icon[icon="back"],
.app-header-dropdown .app-header-dropdown-header app-header-dropdown-header rl-icon[icon="next"] {
  font-size: 16px;
}
.app-header-dropdown .app-header-dropdown-header h2 {
  display: block;
  font-size: 15px;
  font-weight: 600;
  position: static;
  text-overflow: clip;
  text-transform: uppercase;
  margin: 0;
  padding: 0;
  line-height: 1rem;
}
.app-header-dropdown .app-header-dropdown-footer {
  display: block;
}
.app-header-dropdown .app-header-dropdown-footer app-header-dropdown-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  flex: 0 0 auto;
  border-top: 1px solid var(--general-border-color);
}
.app-header-dropdown .app-header-dropdown-footer app-header-dropdown-footer > button {
  padding: 15px;
  text-transform: uppercase;
  color: var(--highlight-color);
  font-weight: 600;
  font-size: 13px;
  cursor: pointer;
}
.app-header-dropdown .app-header-dropdown-footer app-header-dropdown-footer > button:only-child {
  width: 100%;
  flex: 1 0 auto;
}
.app-header-dropdown .app-header-dropdown-footer app-header-dropdown-footer > button:only-child:hover {
  background: var(--background-highlight-color);
}
users-list {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
  gap: var(--spacing);
}
users-list .selected-users-wrapper {
  margin: 0;
  overflow: hidden;
  flex: initial;
}
users-list rl-search {
  margin: var(--spacing) var(--spacing) 0;
}
users-list .show-history-toggle {
  display: flex;
  align-items: center;
  gap: var(--m-spacing);
  margin: 0 var(--spacing);
  color: var(--general-text-color);
}
users-list .show-history-toggle:hover,
users-list .show-history-toggle label:hover {
  cursor: pointer;
}
users-list .checkbox {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 auto;
  width: 1.15rem;
  height: 1.15rem;
  border: 1.5px solid var(--general-text-inactive-color);
  border-radius: 2px;
  box-sizing: border-box;
}
users-list .checkbox rl-icon {
  display: none;
  color: var(--white-color);
}
users-list .checkbox.selected {
  background: var(--highlight-color);
  border-color: var(--highlight-color);
}
users-list .checkbox.selected rl-icon {
  display: inline-flex;
  font-size: 0.8rem;
}
users-list .selected-users {
  display: flex;
  flex-wrap: nowrap;
  gap: 2px;
  margin: 0;
  padding: var(--s-spacing) var(--spacing);
  list-style: none;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
users-list .selected-users .remove-user {
  position: absolute;
  top: 0;
  right: 0;
  width: 1.35rem;
  height: 1.35rem;
  padding: 0;
  z-index: 1;
  color: var(--text-on-white-color);
  background: var(--general-background-color);
  border-radius: 50%;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
  display: grid;
  place-content: center;
}
users-list .selected-users .remove-user rl-icon {
  font-size: 1rem;
}
users-list .selected-users li {
  width: 86px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--s-spacing);
  flex: 0 0 auto;
}
users-list .selected-users .profile-and-remove-btn-wrapper {
  position: relative;
}
users-list .selected-users .profile-and-remove-btn-wrapper profile-image .leave-icon {
  width: 1.35rem;
  height: 1.35rem;
  font-size: 1.35rem;
  bottom: 0;
  right: 0;
}
users-list .selected-users profile-image {
  padding-right: 0;
}
users-list .selected-users profile-image .profile-image {
  width: 3.5rem;
  height: 3.5rem;
  vertical-align: top;
}
users-list .selected-users .name {
  max-width: 86px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 0.7rem;
  color: var(--general-text-color);
}
users-list .user-list-wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: auto;
  background: var(--general-background-color);
  padding: 0 var(--spacing);
}
users-list .divider {
  margin: 0 var(--m-spacing) 5px;
  font-size: 0.75rem;
  text-transform: uppercase;
}
users-list .user-with-menu-container {
  display: flex;
  margin-bottom: 5px;
}
users-list .menu-icon {
  transform: rotate(90deg);
}
users-list .menu-icon:hover {
  cursor: pointer;
}
users-list .user {
  flex: 1 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 44px;
}
users-list .multiple .user:hover {
  cursor: pointer;
}
users-list .user-info {
  display: flex;
  align-items: flex-start;
}
users-list .user-info .profile-image-wrapper {
  position: relative;
  margin-right: var(--s-spacing);
}
users-list .user-info .profile-image-wrapper .status {
  position: absolute;
  bottom: -3px;
  right: 8px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  color: var(--white-color);
  box-shadow: 0 2px 4px 0 rgba(var(--general-text-color-rgb), 0.1);
  font-size: 10px;
}
users-list .user-info .profile-image-wrapper .status.completed {
  background: var(--confirmation-color);
}
users-list .user-info .blocked-icon {
  width: 0.9rem;
  height: 0.9rem;
  position: absolute;
  right: 0;
  bottom: 0;
  background-color: var(--warning-color);
  border-radius: 50%;
  color: var(--white-color);
  display: grid;
  place-content: center;
}
users-list .user-info .blocked-icon [icon="ban"] {
  font-size: 0.5rem;
}
users-list .user-info profile-image {
  display: block;
  background-color: var(--general-background-color);
  border-radius: 50%;
  padding-right: 0;
}
users-list .user-info profile-image .leave-icon {
  bottom: 0;
  right: 0;
}
users-list .user-info profile-image .profile-image {
  width: 2.25rem;
  height: 2.25rem;
  vertical-align: top;
}
users-list .user-info profile-image.blocked .profile-image {
  opacity: 0.5;
}
users-list .user-info .profile-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
users-list .user-info .profile-info .name {
  display: flex;
  align-items: center;
  font-size: 1rem;
  font-weight: 700;
  color: var(--general-text-color);
}
users-list .user-info .profile-info .name span {
  display: inline-block;
  word-break: break-all;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100vw - 160px);
}
users-list .user-info .profile-info .name [icon="crown"] {
  margin-left: var(--s-spacing);
  font-size: 0.65rem;
  color: var(--alert-color);
}
users-list .user-info .profile-info .name.blocked {
  color: var(--general-text-inactive-color);
}
users-list .user-info .profile-info .name.blocked [icon="crown"] {
  color: var(--general-text-inactive-color);
}
users-list .user-info .profile-info .department,
users-list .user-info .profile-info .date-created {
  font-size: 0.8rem;
  color: var(--general-text-inactive-color);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100vw - 160px);
  word-break: break-all;
  display: inline-block;
}
users-list .user-info .profile-info .blocked-label {
  font-size: 0.8rem;
  font-weight: bold;
  color: var(--warning-color);
}
users-list .spinner-wrapper {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}
users-list .spinner-wrapper .spinner {
  width: 50px;
  height: 50px;
}
users-list .no-users-found {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
}
users-list .no-users-found icon {
  width: 75px;
  height: 75px;
}
users-list .no-users-found h2 {
  margin: var(--s-spacing) 0;
  font-size: 1.15rem;
  font-weight: bold;
  color: #3c4858;
}
.base-view-taskmanagement {
  background: var(--general-background-color);
}
task-management {
  height: 100%;
  display: flex;
  flex-direction: column;
}
task-management rl-loading-placeholder {
  margin-right: var(--m-spacing);
}
task-management rl-loading-placeholder .placeholder-shimmer {
  height: 90px;
}
@media only screen and (min-width: 1025px) {
  task-management rl-loading-placeholder .placeholder-shimmer {
    margin: var(--spacing) 0 0;
  }
}
task-management .calendar-shimmer {
  margin: var(--spacing) 0;
}
task-management .calendar-shimmer .placeholder-shimmer {
  height: 250px;
}
@media only screen and (min-width: 1025px) {
  task-management {
    max-width: unset !important;
    padding-bottom: 0;
  }
  task-management rl-loading-placeholder {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  task-management rl-loading-placeholder .placeholder-shimmer {
    height: 90px;
  }
  task-management .tasks-filters-desktop {
    padding: 0 !important;
    border-left: 1px solid var(--general-border-color);
    width: 350px;
    height: 100%;
    overflow: hidden;
    flex-shrink: 0;
    position: relative;
  }
  task-management .tabs-date-search-header {
    margin: var(--m-spacing);
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    gap: var(--m-spacing);
  }
  task-management .tabs-date-search-header .selected-date {
    font-size: 1.4rem;
    text-transform: capitalize;
    font-weight: 700;
  }
  task-management .tabs-date-search-header rl-button button {
    background: var(--button-background-color);
    color: var(--button-text-color);
    min-width: 130px;
    text-transform: uppercase;
  }
  task-management .no-tasks-found-container {
    position: absolute;
    top: 40%;
    left: calc(50% - var(--sidemenu-width));
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
task-management .no-tasks-found-container {
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}
task-management .no-tasks-found-container rl-icon {
  font-size: 5rem;
}
task-management .no-tasks-found-container h2 {
  line-height: 1rem;
  font-weight: 700;
}
task-management .no-tasks-found-container .text {
  font-size: 0.86667rem;
  line-height: 135%;
  max-width: 300px;
  text-align: center;
}
task-management .status-icon {
  display: inline-flex;
  min-width: 26px;
  min-height: 26px;
  line-height: 26px;
  border-radius: 50%;
  border: 1px solid rgba(var(--general-text-color-rgb), 0.1);
  margin-right: var(--m-spacing);
  justify-content: center;
  align-items: center;
  color: white;
  box-sizing: border-box;
}
task-management .status-icon rl-icon {
  font-size: 16px;
}
task-management .tasks-view-button {
  font-size: 1.5rem;
  gap: var(--spacing);
  padding: var(--m-spacing) 0 0 var(--m-spacing);
}
task-management .tasks-view-button.active {
  color: var(--highlight-color);
}
task-management .processing-spinner-overlay {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 99;
}
task-management .processing-spinner-overlay .spinner {
  width: 50px;
  height: 50px;
}
task-management .date-label {
  margin: 0;
  padding: 20px 15px 10px 15px;
  font-size: 20px;
  font-weight: 600;
}
task-management .rl-tabs {
  justify-content: space-around;
}
task-management .rl-tabs .rl-tab {
  cursor: pointer;
  flex: 1;
}
task-management .rl-tabs .rl-tab:hover {
  background: rgba(var(--general-background-color-rgb), 0.3);
}
@media only screen and (min-width: 1025px) {
  task-management .rl-tabs {
    justify-content: flex-start;
    gap: var(--spacing);
    padding-left: var(--spacing);
  }
  task-management .rl-tabs .rl-tab {
    flex: unset;
  }
}
task-management .task-management-content {
  display: flex;
  flex: 1;
  overflow: hidden;
  flex-direction: column;
}
task-management .task-management-content .tab-content-desktop {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex: 1;
}
@media only screen and (min-width: 1025px) {
  task-management .task-management-content {
    flex-direction: row;
  }
  task-management .task-management-content .tab-content-desktop {
    width: 100%;
    margin-left: var(--m-spacing);
  }
}
task-management .task-management-wrapper {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
task-management .task-management-wrapper .tabs-subtitle {
  display: flex;
  gap: var(--m-spacing);
  padding-top: var(--m-spacing);
}
task-management .task-management-view-type {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex: 1;
  padding: var(--s-spacing);
}
@media only screen and (min-width: 1025px) {
  task-management .task-management-view-type {
    padding: 0 var(--s-spacing) 0 0;
  }
  task-management .task-management-view-type.view-type-manager {
    padding: 0;
  }
}
task-management .task-management-view-type .filtered-tasks {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex: 1;
}
task-management .task-management-view-type months-view,
task-management .tasks-filters-desktop months-view {
  height: 300px;
}
task-management .task-management-view-type months-view .scrolled-months,
task-management .tasks-filters-desktop months-view .scrolled-months {
  border-radius: 0 0 var(--general-border-radius) var(--general-border-radius);
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.15) transparent;
  margin-top: 0;
  border-bottom: 0;
  padding: 0 !important;
}
task-management .task-management-view-type months-view tasks,
task-management .tasks-filters-desktop months-view tasks {
  margin-bottom: var(--m-spacing);
}
task-management .task-management-view-type .spinner-container .spinner-wrapper,
task-management .tasks-filters-desktop .spinner-container .spinner-wrapper {
  text-align: center;
  width: 100%;
  margin-top: 50px;
}
@media only screen and (min-width: 1025px) {
  task-management .task-management-view-type .more-tasks-spinner,
  task-management .tasks-filters-desktop .more-tasks-spinner {
    padding-bottom: 55px;
  }
}
task-management .task-management-view-type .more-tasks-spinner .spinner-wrapper,
task-management .tasks-filters-desktop .more-tasks-spinner .spinner-wrapper {
  text-align: center;
  width: 100%;
  margin-top: var(--spacing);
}
task-management .filter-tasks-header {
  display: flex;
  justify-content: flex-end;
}
task-management .filter-tasks-header rl-button button {
  font-size: 14px;
  font-weight: 400;
  margin: 17px 10px 0 0;
  color: grey;
}
task-management .filter-tasks-header rl-icon {
  font-size: 14px;
  color: grey;
}
task-management .view-type-overdue {
  margin-top: var(--s-spacing);
}
task-management .view-type-overdue .no-content {
  text-align: center;
}
task-management .view-type-overdue .no-content i {
  display: inline-block;
}
task-management .no-results-wrapper {
  height: 100%;
  width: 100%;
  text-align: center;
}
task-management .no-results-wrapper .no-results-container {
  position: relative;
  text-align: center;
  top: calc(50% - 80px);
  padding: 0 80px 0 80px;
}
task-management .no-results-wrapper .no-results-container rl-icon {
  font-size: 36px;
}
task-management .no-results-wrapper .no-results-container button {
  text-transform: uppercase;
  font-weight: 400;
}
task-management .no-results-wrapper .no-results-item {
  display: block;
  margin: 0 0 var(--m-spacing) 0;
}
task-management .my-tasks-item {
  width: 50%;
  height: 100%;
  overflow-y: auto;
}
task-management .overlooking {
  margin: var(--m-spacing);
}
@media only screen and (min-width: 1025px) {
  task-management .overlooking {
    margin-top: 0;
  }
}
task-management .mobile-calendar-container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex: 1;
}
task-management months-view {
  border-bottom: 0;
  border-radius: var(--general-border-radius) var(--general-border-radius) 0 0;
  margin-bottom: var(--m-spacing);
  background: transparent;
}
@media only screen and (min-width: 1025px) {
  task-management months-view {
    margin-bottom: 0;
  }
}
task-management months-view .calendar-header .month-title:nth-last-of-type(-n+2) {
  color: rgba(var(--general-text-color-rgb), 0.5);
}
task-management months-view .calendar-header .titles {
  background: var(--background-highlight-color);
  color: var(--general-text-color);
}
task-management months-view .day.current-day.selected .day-number {
  background: var(--highlight-color);
  color: var(--text-on-highlight-color);
  box-shadow: rgba(150, 150, 150, 0.8) 0 0 14px;
}
task-management months-view .day .day-dot {
  opacity: 0.3;
}
task-management months-view .day.current-day .day-number {
  color: var(--black-color);
  background: var(--background-highlight-color);
  border-radius: 100%;
}
tasks-by-day {
  padding-bottom: 50px;
}
tasks {
  container-type: size;
}
tasks .tasks-scrolled-wrapper {
  width: 100%;
  height: 100cqh;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.15) transparent;
  -webkit-overflow-scrolling: touch;
  box-sizing: border-box;
}
@media only screen and (min-width: 1025px) {
  tasks .tasks-scrolled-wrapper {
    align-content: flex-start;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}
task {
  display: block;
  padding: 10px 15px;
  box-sizing: border-box;
  cursor: pointer;
  background: var(--background-highlight-color);
  border-radius: var(--general-border-radius);
  margin-bottom: var(--m-spacing);
}
@media only screen and (min-width: 1025px) {
  task {
    flex-basis: 49%;
    min-height: 100px;
  }
}
task .task-icons-container {
  display: inline;
  position: absolute;
  right: 0;
}
task .task {
  height: 100%;
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: row;
  justify-content: center;
  box-sizing: border-box;
  background-size: cover;
}
task .task .label {
  position: relative;
  z-index: 2;
  align-self: flex-start;
  padding: 5px;
  font-size: 1.06667rem;
  font-weight: 700;
}
task .task.completed .content,
task .task.overdue .content {
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}
task .task .completed-icon rl-icon {
  opacity: 0;
}
task .task.completed .content h2 {
  color: rgba(var(--general-text-color-rgb), .5);
}
task .task.completed .content .task-title {
  text-decoration: line-through;
  opacity: 0.5;
}
task .task.completed .status-icon {
  background: var(--confirmation-color);
}
task .task.completed .completed-icon {
  display: flex;
  background: #4FC990;
}
task .task.completed .overdue-icon {
  display: none;
}
task .task.completed h2 {
  color: #4FC990;
}
task .task.completed .task-title {
  text-decoration: line-through;
}
task .task.completed .text p {
  font-weight: 500;
  color: var(--confirmation-color);
}
task .task.completed .text .rl-subheadline {
  color: var(--general-text-color);
}
task .task.overdue .completed-icon {
  display: none;
}
task .task.overdue .status-icon {
  background: var(--warning-color);
}
task .task.due-soon .status-icon {
  background: var(--alert-color);
}
task .task.due-soon .text p {
  font-weight: 500;
  color: var(--alert-color);
}
task .task.empty .status-icon {
  display: none;
}
task .task.empty .text rl-icon {
  display: none;
}
task .task .completed-icon,
task .task .overdue-icon {
  display: none;
  margin-right: 15px;
  min-width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
}
task .task .completed-icon .icon,
task .task .overdue-icon .icon {
  color: white;
  font-size: 20px;
}
task .task .content {
  flex: 1;
  z-index: 2;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  overflow: hidden;
}
task .task .text {
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
task .task .text .top {
  display: flex;
  justify-content: space-between;
}
task .task .text .top .label {
  box-sizing: border-box;
  overflow: hidden;
  white-space: nowrap;
  max-width: 120px;
  text-overflow: ellipsis;
  padding: 5px;
  font-size: 13px;
}
task .task .text .top .task-title {
  font-weight: 700;
  font-size: 1.06667rem;
  word-break: break-word;
}
task .task .text h2 {
  margin: 3px 0 2px;
  padding: 0;
  font-size: 15px;
  font-weight: 600;
}
task .task .text h2 rl-icon {
  font-size: 12px;
  opacity: 0.4;
  color: var(--general-text-color);
}
task .task .text p {
  font-size: 13px;
  padding: 0;
  margin: 0;
  font-weight: 500;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
task .task .text .deadline {
  font-size: 0.86667rem;
  font-weight: 400;
  white-space: normal;
}
task .task .text .complitions {
  font-weight: 400;
  font-size: 13px;
  color: var(--general-text-color);
}
task .task .categories {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
task .task .categories .category {
  width: 30px;
  height: 5px;
  margin-top: 10px;
  margin-right: 5px;
  border-radius: 5px;
}
task .task .footer,
task .task .bottom {
  display: flex;
  justify-content: space-between;
}
task .task .footer .assignees,
task .task .bottom .assignees {
  display: flex;
  margin-left: auto;
}
task .task .footer .assignees span,
task .task .bottom .assignees span {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  color: var(--text-on-white-color);
  background: var(--white-color);
  box-sizing: border-box;
  box-shadow: 0 6px 7px 0 rgba(var(--general-text-color-rgb), .1);
  border: 1px solid var(--white-color);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
task .task .footer .assignees span:not(:last-of-type),
task .task .bottom .assignees span:not(:last-of-type) {
  margin-right: -10px;
}
task .task .footer {
  align-items: flex-end;
  margin-top: auto;
}
tasks {
  flex: 1;
}
tasks .no-tasks {
  display: block;
  width: 100%;
  padding: 10px 15px;
  background: var(--background-highlight-color);
  border-radius: var(--general-border-radius);
  box-sizing: border-box;
  cursor: pointer;
}
tasks .no-tasks p {
  font-size: 15px;
  margin: 0;
  font-weight: 700;
  word-break: break-word;
  color: var(--general-text-color);
}
tasks .no-tasks-desktop {
  position: absolute;
  top: 40%;
  transform: translateX(-50%);
  left: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
tasks .no-tasks-desktop rl-icon {
  font-size: 5rem;
}
tasks .no-tasks-desktop h2 {
  line-height: 1rem;
  font-weight: 700;
}
tasks .no-tasks-desktop .info-message {
  text-align: center;
  font-size: 0.86667rem;
}
my-tasks {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
}
my-tasks tasks {
  padding: 0;
}
my-tasks #feed-url {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}
my-tasks .today-header {
  padding: var(--m-spacing);
  position: relative;
  background: var(--background-highlight-color);
  border-radius: var(--general-border-radius);
  margin: var(--m-spacing) 0;
  display: flex;
  flex: 0;
  gap: var(--spacing);
  justify-content: flex-start;
  align-items: center;
  transform: translatez(0);
}
@media only screen and (min-width: 1025px) {
  my-tasks .today-header {
    margin-top: 0;
    background: transparent;
  }
  my-tasks .today-header .left {
    margin-left: 0!important;
  }
  my-tasks .today-header .greetings-group {
    display: flex;
    gap: 2px;
    font-size: 1.06667rem;
  }
}
my-tasks .today-header .left {
  width: 46px;
  height: 46px;
  margin-left: var(--s-spacing);
}
my-tasks .today-header .left .profile-image {
  width: 46px;
  height: 46px;
}
my-tasks .today-header .right {
  display: block;
}
my-tasks .today-header h3 {
  font-weight: 400;
  font-size: 20px;
}
my-tasks .today-header h3 .greetings .name {
  font-weight: 500;
}
my-tasks .today-header .percentage-completed {
  display: flex;
  align-items: center;
  margin-top: 5px;
}
my-tasks .today-header .percentage-completed.all-completed .label {
  color: var(--confirmation-color);
  font-weight: 500;
}
my-tasks .today-header .percentage-completed .status-icon {
  background: var(--confirmation-color);
}
my-tasks .today-header .percentage-completed .label {
  font-size: 13px;
  line-height: 24px;
  color: rgba(var(--general-text-color-rgb), .5);
}
my-tasks .today-header .percentage-completed percentage-circle {
  margin-right: 5px;
}
my-tasks .today-header .percentage-completed .c100:after {
  background-color: var(--general-background-color);
}
my-tasks .today-completed {
  background: rgba(var(--confirmation-color-rgb), 0.2);
}
my-tasks .copy-feed {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 30px;
}
my-tasks .tear-off-calendar {
  position: relative;
  width: 120px;
  margin: 0 auto;
}
my-tasks .tear-off-calendar .month-year {
  z-index: 3;
  position: relative;
  height: 35px;
  text-align: center;
  line-height: 35px;
  background: #5FA3F1;
  color: white;
}
my-tasks .tear-off-calendar .day {
  z-index: 3;
  position: relative;
  display: flex;
  height: 85px;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  background: white;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.05);
}
my-tasks .tear-off-calendar .day .number {
  font-size: 40px;
  line-height: 1;
}
my-tasks .tear-off-calendar .page1,
my-tasks .tear-off-calendar .page2 {
  position: absolute;
  background: white;
  height: 100%;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.05);
}
my-tasks .tear-off-calendar .page1 {
  z-index: 2;
  bottom: -5px;
  left: 2px;
  right: 2px;
}
my-tasks .tear-off-calendar .page2 {
  z-index: 1;
  bottom: -10px;
  left: 4px;
  right: 4px;
}
my-tasks .events {
  flex: 1;
  overflow: hidden;
  container-type: size;
}
my-tasks .events > h2 {
  margin: 0;
  font-size: 15px;
}
my-tasks .events > h3 {
  margin: 0;
  padding: 20px 15px 10px 15px;
  font-weight: 600;
  font-size: 20px;
}
my-tasks .overdue-button {
  background: rgba(var(--warning-color-rgb), 0.3);
  border-radius: var(--general-border-radius);
  margin-bottom: var(--m-spacing);
  padding: 12px;
  font-size: 13px;
  color: var(--warning-color);
  font-weight: 500;
  cursor: pointer;
}
my-tasks .overdue-button .status-icon {
  min-width: 15px;
  min-height: 15px;
  background: var(--warning-color);
}
my-tasks .overdue-button rl-icon {
  font-size: 10px;
  color: white;
  font-weight: 600;
}
sorted-tasks {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: auto;
}
sorted-tasks task {
  margin-top: var(--m-spacing);
}
sorted-tasks h2 {
  margin: 0;
  padding: 10px;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0;
}
search-tasks {
  height: 100%;
  display: flex;
  flex-direction: column;
}
search-tasks .no-results-item {
  font-size: 5rem;
  margin-bottom: var(--s-spacing);
}
@media only screen and (min-width: 1025px) {
  search-tasks {
    padding: var(--spacing) var(--s-spacing) 0;
  }
}
@media only screen and (max-width: 1024px) {
  search-tasks {
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }
}
search-tasks .spinner-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}
search-tasks .spinner-overlay .spinner {
  width: 50px;
  height: 50px;
}
search-tasks .search-tasks-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: var(--spacing);
}
@media only screen and (min-width: 1025px) {
  search-tasks .search-tasks-container {
    overflow-y: auto;
  }
  search-tasks .search-tasks-container rl-loading-placeholder {
    margin-right: 0;
  }
}
search-tasks .search-tasks-container tasks {
  flex: 1;
}
search-tasks .no-events-found {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
search-tasks .no-events-found h2 {
  margin: 0 0 10px 0;
}
search-tasks .no-events-found p {
  margin: 0;
}
task-details {
  display: flex;
  min-height: 100%;
  flex-direction: column;
  background: var(--general-background-color);
}
task-details > header {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 15px;
  min-height: 220px !important;
  box-sizing: border-box;
  background: rgba(var(--general-text-color-rgb), .06);
}
task-details > header .status {
  opacity: 0.5;
}
task-details > header .status-icon {
  display: none;
  min-width: 22px;
  min-height: 22px;
  line-height: 22px;
  box-sizing: border-box;
  border-radius: 50%;
  border: 1px solid rgba(var(--general-text-color-rgb), .1);
  margin-right: 10px;
  justify-content: center;
  align-items: center;
  color: white;
}
task-details > header .status-icon rl-icon {
  font-size: 14px;
}
task-details > header.in-progress .status-icon {
  display: none;
}
task-details > header.completed {
  background: rgba(var(--confirmation-color-rgb), .2);
}
task-details > header.completed .status {
  opacity: 1;
}
task-details > header.completed h1 span {
  opacity: 0.5;
}
task-details > header.completed h1 .title {
  text-decoration: line-through;
}
task-details > header.completed .status {
  color: var(--confirmation-color);
}
task-details > header.completed .status .status-icon {
  display: inline-flex;
  background: var(--confirmation-color);
}
task-details > header.completed .status .status-icon rl-icon:not([icon="save"]) {
  display: none;
}
task-details > header.overdue {
  background: rgba(var(--warning-color-rgb), .2);
}
task-details > header.overdue .status {
  color: var(--warning-color);
}
task-details > header.overdue .status .status-icon {
  display: inline-flex;
  background: var(--warning-color);
}
task-details > header.overdue .status .status-icon rl-icon:not([icon="close"]) {
  display: none;
}
task-details > header.due-soon {
  background: rgba(var(--alert-color-rgb), .2);
}
task-details > header.due-soon .status {
  color: var(--alert-color);
}
task-details > header.due-soon .status .status-icon {
  display: inline-flex;
  background: var(--alert-color);
}
task-details > header.due-soon .status .status-icon rl-icon:not([icon="exclamation"]) {
  display: none;
}
task-details > header .top-buttons {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
task-details > header .info {
  width: 100%;
}
task-details > header .info .status {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 13px;
  font-weight: bold;
}
task-details > header .info .status-placeholder {
  display: inline-block;
  height: 22px;
  width: 140px;
  border-radius: 4px;
}
task-details > header .info .bottom {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
task-details > header .info .title-placeholder {
  display: inline-block;
  height: 24px;
  width: 170px;
  border-radius: 4px;
}
task-details > header .info .assignees {
  display: flex;
}
task-details > header .info .assignees:disabled {
  opacity: 0.5;
}
task-details > header .info .assignees span {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  color: var(--text-on-white-color);
  background: var(--white-color);
  box-sizing: border-box;
  box-shadow: 0 6px 7px 0 rgba(var(--general-text-color-rgb), .1);
  border: 1px solid var(--white-color);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
task-details > header .info .assignees span:not(:last-of-type) {
  margin-right: -10px;
}
task-details > header .info .assignees span rl-icon {
  color: var(--text-on-white-color);
  font-size: 19px;
  margin: 0;
}
task-details > header button {
  padding: 0;
  width: auto;
  color: var(--general-text-color);
}
task-details > header button rl-icon {
  font-size: 26px;
  margin-left: -5px;
}
task-details > header h1 {
  margin: 0;
  padding: 10px 10px 0 0;
  width: 100%;
  font-size: initial;
  word-break: break-word;
}
task-details > header h1 span {
  font-size: 20px;
}
task-details > header h1 rl-icon {
  opacity: 0.5;
  margin-left: 10px;
}
task-details > header .header-details {
  margin: 10px 0;
  color: var(--general-text-color);
}
task-details > header .details-dot-separator {
  vertical-align: middle;
  font-size: 2px;
  padding: 0 5px;
}
task-details .statistics {
  padding: 15px;
}
task-details .statistics .statistics-columns {
  padding: 15px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  border: 1px solid rgba(var(--general-text-color-rgb), 0.1);
  border-radius: 10px;
  cursor: pointer;
}
task-details .statistics .statistics-columns .statistics-column {
  width: 50%;
  text-align: center;
}
task-details .statistics .statistics-columns h4 {
  margin: 0;
}
task-details .statistics .statistics-columns .rl-headline-placeholder {
  width: 40px;
  position: relative;
  top: 3px;
}
task-details .statistics .statistics-columns .status-text {
  margin-bottom: 5px;
}
task-details .statistics .statistics-columns .status-text .status-text-placeholder {
  height: 16px;
  width: 80px;
  display: inline-block;
  border-radius: 4px;
  vertical-align: middle;
}
task-details .statistics .statistics-columns .status-icon {
  display: inline-flex;
  background: var(--confirmation-color);
  width: 22px;
  min-width: 22px;
  height: 22px;
  min-height: 22px;
  line-height: 22px;
  box-sizing: border-box;
  border-radius: 50%;
  border: 1px solid rgba(var(--general-text-color-rgb), 0.1);
  justify-content: center;
  align-items: center;
  color: white;
}
task-details .statistics .statistics-columns .status-icon.overdue {
  background: var(--warning-color);
}
task-details .statistics .statistics-columns .status-icon.in-progress {
  background: none;
}
task-details .statistics .statistics-columns .status-icon.in-progress .c100 {
  font-size: 22px;
}
task-details .statistics .statistics-columns .status-icon.in-progress .c100:after {
  background-color: white;
}
task-details .statistics .statistics-columns .status-icon rl-icon {
  font-size: 14px;
}
task-details .statistics .statistics-columns .status-icon-placeholder {
  height: 22px;
  width: 22px;
  min-height: 22px;
  line-height: 22px;
  border-radius: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
task-details .details {
  padding: 20px 15px 10px 15px;
  flex: 1;
  display: flex;
  flex-direction: column;
  background: var(--general-background-color);
}
task-details .details .details-row-placeholder {
  display: block;
  height: 30px;
  min-width: 10px;
  border-radius: 4px;
  margin-bottom: 10px;
}
task-details .details rl-rich-preview {
  position: relative;
}
task-details .details rl-rich-preview .content.loading {
  width: 100%;
}
task-details .details translation-button {
  margin-bottom: 30px;
}
task-details .title-expandable {
  display: flex;
  align-items: center;
}
task-details .title-expandable .title-content {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
task-details .title-expandable .title-content .empty-comments {
  opacity: 0.5;
}
task-details .title-expandable .title-content rl-icon {
  color: var(--general-text-color);
  opacity: 0.5;
  font-size: inherit;
}
task-details .detail-item {
  display: flex;
  padding: 10px 0;
  align-items: center;
  width: 100%;
}
task-details .detail-item > rl-icon {
  margin-right: 15px;
}
task-details .lock-by-text > span {
  padding-right: 15px;
}
task-details .categories rl-icon {
  margin-top: 2.5px;
  margin-bottom: auto;
}
task-details .categories ul {
  display: flex;
  flex-wrap: wrap;
  margin: -10px 0 0 0;
  padding: 0;
}
task-details .categories ul li {
  padding: 0 10px;
  height: 24px;
  line-height: 24px;
  list-style: none;
  margin-top: 10px;
  font-size: 13px;
  font-weight: 500;
  border-radius: var(--general-border-radius);
}
task-details .categories ul li:not(:last-of-type) {
  margin-right: 10px;
}
task-details .add-subtask {
  margin: 10px 0;
  height: 50px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  border: 1px solid rgba(var(--general-text-color-rgb), 0.1);
  border-radius: 10px;
}
task-details .add-subtask[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}
task-details .add-subtask rl-icon {
  margin: 0 10px;
  color: var(--general-text-color);
}
task-details .add-subtask rl-icon.info-icon {
  margin-left: auto;
}
task-details .sorting-actions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0 15px;
}
task-details .sorting-actions .save-sorting {
  flex: 1;
}
task-details .sorting-actions .save-sorting button {
  width: 100%;
  background-color: var(--highlight-color);
  color: var(--button-text-color);
  text-transform: uppercase;
}
task-details .sorting-actions .save-sorting button.transparent.secondary {
  color: var(--button-text-color);
}
task-details .sorting-actions .cancel-sorting {
  flex: 1;
}
task-details .sorting-actions .cancel-sorting button {
  width: 100%;
  color: var(--warning-color);
  text-transform: uppercase;
}
task-details .cant-complete {
  justify-content: center;
}
task-details .swipe span {
  text-transform: uppercase;
}
task-details .details-bottom {
  margin-top: auto;
}
task-details .download-event-wrapper {
  text-align: center;
}
task-details .download-event-wrapper .download-event {
  text-decoration: none;
}
task-details .download-event-wrapper .fa {
  padding-right: 10px;
}
completions-popup .user ng-transclude rl-icon {
  color: rgba(var(--general-text-color-rgb), 0.6);
  font-size: 25px;
}
completions-popup .user ng-transclude rl-icon:active {
  color: rgba(var(--general-text-color-rgb), 1);
}
create-update-task-popup .popup-content-inner {
  background: var(--background-highlight-color);
}
create-update-task-popup form {
  padding-bottom: 75px;
}
create-update-task-popup .scroll-container {
  flex: 1;
  overflow-y: auto;
  position: relative;
  display: flex;
  flex-direction: column;
}
create-update-task-popup .scroll-container.disable-overflow {
  overflow-y: visible !important;
}
create-update-task-popup .task-header .language-selector-wrapper label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 43px;
  box-sizing: border-box;
}
create-update-task-popup .task-header .language-selector-wrapper label button {
  display: flex;
}
create-update-task-popup .task-header .language-selector-wrapper language-selector {
  width: 100%;
}
create-update-task-popup .task-header .language-selector-wrapper .selected-title {
  display: none;
}
create-update-task-popup .task-header .input-title {
  margin: 0;
  padding: 15px;
  height: 57px;
  display: block;
  color: var(--general-text-color);
  border: none;
  font-size: 20px;
  font-weight: 500;
  background: inherit;
}
create-update-task-popup .task-header .input-title::-webkit-input-placeholder {
  color: var(--general-text-color);
  opacity: 0.6;
}
create-update-task-popup .task-header textarea {
  position: relative;
  width: 100%;
  height: 120px;
  box-sizing: border-box;
  padding: 15px;
  border: none;
  background-image: linear-gradient(to bottom, var(--general-background-color) 85%, var(--background-highlight-color));
}
create-update-task-popup .task-header textarea::-webkit-input-placeholder {
  color: var(--general-text-color);
}
create-update-task-popup .task-options {
  flex: 1;
}
create-update-task-popup select-category,
create-update-task-popup select-repeat {
  display: flex;
  box-sizing: border-box;
  width: 100%;
  min-height: 50px;
  padding: 10px 30px 10px 40px;
  background: var(--general-background-color);
  color: var(--general-text-color);
}
create-update-task-popup select-category .category-selector,
create-update-task-popup select-repeat .category-selector {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
create-update-task-popup select-repeat {
  padding-left: 10px;
}
create-update-task-popup .assignees {
  display: flex;
  align-items: center;
  width: 100%;
  height: 50px;
  padding-left: 40px;
  background: var(--general-background-color);
}
create-update-task-popup .assignees .users {
  display: flex;
  margin-right: 10px;
}
create-update-task-popup .assignees .users span {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  color: var(--text-on-white-color);
  background: var(--white-color);
  box-sizing: border-box;
  box-shadow: 0 6px 7px 0 rgba(var(--general-text-color-rgb), .1);
  border: 1px solid var(--white-color);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
create-update-task-popup .assignees .users span:not(:last-of-type) {
  margin-right: -10px;
}
create-update-task-popup .attachments-wrapper {
  padding: 15px 10px;
}
create-update-task-popup .attachments-wrapper .attachments-label {
  padding-bottom: 10px;
}
create-update-task-popup footer .required-message {
  padding: 5px 0 0 0;
  font-size: 12px;
  font-weight: 600;
  color: var(--warning-color);
}
create-update-task-popup .popup-sticky-bottom {
  z-index: 9;
}
task-assignees {
  display: flex;
  flex-direction: column;
}
task-assignees .assignees-container {
  align-items: baseline;
  justify-content: space-between;
  flex-direction: row !important;
}
task-assignees .assignees {
  display: flex;
  align-items: center;
  width: 100%;
  height: 50px;
  padding-left: 40px;
  background: var(--general-background-color);
}
task-assignees .assignees .users {
  display: flex;
  margin-right: 10px;
}
task-assignees .assignees .users span {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  color: var(--text-on-white-color);
  background: var(--white-color);
  box-sizing: border-box;
  box-shadow: 0 6px 7px 0 rgba(var(--general-text-color-rgb), .1);
  border: 1px solid var(--white-color);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
task-assignees .assignees .users span:not(:last-of-type) {
  margin-right: -10px;
}
statistics-popup section {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
}
statistics-popup .filter-tabs-wrapper {
  padding: 15px;
}
statistics-popup .filter-tabs {
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
  padding: 0;
  border-bottom: 1px solid rgba(var(--text-on-white-color-rgb), .1);
}
statistics-popup .filter-tabs .btn-filter {
  position: relative;
  font-size: 13px;
  cursor: pointer;
  padding: 5px;
  margin-right: 15px;
}
statistics-popup .filter-tabs .btn-filter.with-icon {
  padding-left: 27px;
}
statistics-popup .filter-tabs .btn-filter.active {
  color: var(--highlight-color);
  border-bottom: 3px solid var(--highlight-color);
}
statistics-popup .filter-tabs .btn-filter rl-icon {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 20px;
  min-height: 20px;
  max-width: 20px;
  max-height: 20px;
  line-height: 20px;
  border-radius: 50%;
  border: 1px solid rgba(var(--general-text-color-rgb), .1);
  align-items: center;
  color: white;
  font-size: 16px;
}
statistics-popup .filter-tabs .overdue-icon {
  background: var(--warning-color);
}
statistics-popup .filter-tabs .complete-icon {
  background: var(--confirmation-color);
}
statistics-popup .filter-tabs .in-progress-icon {
  background: none;
  position: absolute;
  top: 0;
  left: 0;
}
statistics-popup .filter-tabs .in-progress-icon .c100 {
  font-size: 22px;
}
statistics-popup .filter-tabs .in-progress-icon .c100:after {
  background-color: white;
}
assignees-popup users-list {
  height: calc(100% - 55px);
}
comments-popup .content {
  display: flex;
  flex-direction: column;
  height: calc(100% - 55px);
}
comments-popup .content section {
  flex: 1 1 auto;
  max-height: calc(100% - 40px);
  overflow-y: auto;
}
comments-popup .content section .empty-list {
  padding: 30px 0;
  text-align: center;
}
comments-popup .content section .empty-list rl-icon {
  font-size: 60px;
  margin-bottom: 20px;
}
comments-popup .content section .empty-list .empty-text {
  font-size: 16px;
}
comments-popup .content section .comments-list ul {
  display: block;
  position: relative;
  padding: 0;
  margin: 0;
  list-style: none;
}
comments-popup .content section .comments-list ul li {
  position: relative;
  padding: 10px 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
comments-popup .content section .comments-list ul li .profile-image {
  min-width: 40px;
  min-height: 40px;
}
comments-popup .content section .comments-list ul li .comment-content {
  width: 100%;
  padding: 0 10px;
  color: var(--general-text-color);
}
comments-popup .content section .comments-list ul li .comment-content h3,
comments-popup .content section .comments-list ul li .comment-content p {
  padding: 0;
  margin: 0;
}
comments-popup .content section .comments-list ul li .comment-content p {
  margin-top: 2px;
  word-break: break-word;
}
comments-popup .content section .comments-list ul li .comment-content .subtitle {
  font-size: 13px;
  opacity: 0.5;
  margin-top: 3px;
}
comments-popup .content section .comments-list ul li .comment-delete {
  padding: 0;
}
comments-popup .content footer {
  min-height: 40px;
  display: block;
  width: 100%;
  flex: 0 0 auto;
}
comments-popup .content footer .form-inline {
  display: flex;
  padding: 5px 15px;
}
comments-popup .content footer .form-inline textarea {
  flex: 1 1 auto;
  min-width: 70%;
  height: 30px;
  box-sizing: border-box;
  padding: 5px;
  line-height: initial;
  border: 1px solid rgba(var(--general-text-color-rgb), .1);
  border-radius: 4px;
  margin: 0;
}
comments-popup .content footer .form-inline button {
  flex: 0 0 auto;
  color: var(--general-text-color);
  padding: 0;
  margin-left: 15px;
}
comments-popup .content footer .comment-posting {
  text-align: center;
}
comments-popup .content footer .comment-posting .spinner {
  width: 20px;
  height: 20px;
  top: 8px;
}
manager-view {
  background: var(--general-background-color);
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
}
@media only screen and (min-width: 1025px) {
  manager-view {
    width: 100%;
    margin: 0 auto;
  }
}
manager-view .header .filter {
  display: flex;
  align-items: center;
  background: var(--background-highlight-color);
  justify-content: space-between;
  padding: 15px 15px 90px;
}
manager-view .header .filter input {
  display: block;
  background: transparent;
  border: none;
  border-bottom: 1px solid rgba(var(--general-text-color-rgb), .1);
  padding: 5px 0;
  font-size: 15px;
  color: var(--general-text-color);
}
manager-view .header .overall-progress {
  padding: var(--s-spacing);
  background: var(--general-background-color);
  position: relative;
}
manager-view .header .overall-progress .department-name {
  margin-bottom: 10px;
  font-weight: bold;
}
manager-view .header .overall-progress .fraction {
  display: none;
}
manager-view .header .filter-tabs {
  display: flex;
  align-items: baseline;
  padding: 10px 0 0;
}
manager-view .header .filter-tabs.hide-filters .btn-filter {
  display: none;
}
manager-view .header .filter-tabs .btn-filter {
  margin-right: 15px;
  font-size: 13px;
  cursor: pointer;
  padding: 15px 0;
}
manager-view .header .filter-tabs .btn-filter.active {
  color: var(--highlight-color);
  border-bottom: 1px solid var(--highlight-color);
}
manager-view .header .filter-tabs .btn-filter rl-icon {
  display: inline-flex;
  min-width: 20px;
  min-height: 20px;
  max-width: 20px;
  max-height: 20px;
  line-height: 20px;
  border-radius: 50%;
  border: 1px solid rgba(var(--general-text-color-rgb), .1);
  align-items: center;
  color: white;
  font-size: 16px;
}
manager-view .header .filter-tabs .overdue-icon {
  background: var(--warning-color);
}
manager-view .header .filter-tabs .complete-icon {
  background: var(--confirmation-color);
}
manager-view tasks {
  overflow-y: auto;
  height: 100%;
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.15) transparent;
  -webkit-overflow-scrolling: touch;
}
manager-view task {
  margin-top: 10px;
}
manager-department-popup .manager-department {
  display: flex;
  flex: 1 auto;
  padding: var(--m-spacing);
}
@media only screen and (min-width: 1025px) {
  manager-department-popup .manager-department rl-loading-placeholder {
    margin-right: 0;
  }
}
.subtaskitemsyles {
  display: flex;
  box-sizing: border-box;
  justify-content: flex-start;
  align-items: center;
  min-height: 50px;
  padding: 10px 10px;
  background: var(--background-highlight-color);
  border-radius: var(--general-border-radius);
  line-height: 16px;
  font-weight: 400;
}
create-subtask-only-title .add-subtask {
  padding: 15px;
  flex: 1;
  overflow-y: auto;
}
create-subtask-only-title .add-subtask .add-subtask-field {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
}
create-subtask-only-title .add-subtask .add-subtask-field .remove-subtask-btn {
  position: absolute;
  right: 10px;
  color: var(--warning-color);
}
create-subtask-only-title .add-subtask .list-of-subtasks {
  margin: 0;
  padding: 10px 0;
  list-style-type: none;
}
create-subtask-only-title .add-subtask .new-subtask-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}
create-subtask-only-title .add-subtask .new-subtask-item.sortable-chosen {
  pointer-events: none;
}
create-subtask-only-title .add-subtask .new-subtask-item .subtask-item-content {
  box-sizing: border-box;
  justify-content: flex-start;
  min-height: 50px;
  padding: 10px 10px;
  background: var(--background-highlight-color);
  border-radius: var(--general-border-radius);
  line-height: 16px;
  font-weight: 400;
  min-width: calc(100% - 40px);
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
create-subtask-only-title .add-subtask .new-subtask-item .subtask-item-content .title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
create-subtask-only-title .add-subtask .new-subtask-item .subtask-item-content .handle {
  cursor: move;
}
create-subtask-only-title .add-subtask .new-subtask-item .remove-subtask-from-list {
  cursor: pointer;
  color: var(--warning-color);
}
create-subtask-only-title .add-subtask .input-subtask {
  background: var(--background-highlight-color);
  border: 1px solid rgba(var(--general-text-color-rgb), 0.1);
  border-radius: 10px;
  padding-right: 45px;
}
create-subtask-only-title .info {
  padding: 6px 10px;
}
subtasks-list {
  margin-top: 15px;
}
subtasks-list .subtasks-list {
  margin: 10px 0;
  padding: 0 0 10px 0;
  list-style-type: none;
}
subtasks-list .subtasks-list .subtask {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 50px;
  margin-top: 10px;
  padding: 0 10px;
  box-sizing: border-box;
  background: var(--background-highlight-color);
  border-radius: 10px;
  font-weight: 400;
  line-height: 16px;
}
subtasks-list .subtasks-list .subtask.sortable-chosen {
  pointer-events: none;
}
subtasks-list .subtasks-list .subtask .status-icon {
  display: inline-flex;
  width: 30px;
  min-width: 30px;
  height: 30px;
  min-height: 30px;
  line-height: 30px;
  box-sizing: border-box;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  color: var(--white-color);
}
subtasks-list .subtasks-list .subtask .status-complete {
  background: var(--confirmation-color);
}
subtasks-list .subtasks-list .subtask .status-incomplete {
  border: 1px solid var(--general-border-color);
  background-color: var(--white-color);
}
subtasks-list .subtasks-list .subtask .title {
  width: 100%;
  margin-left: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
subtasks-list .subtasks-list .subtask .title .complete {
  color: var(--general-text-inactive-color);
  text-decoration: line-through;
}
subtasks-list .subtasks-list .subtask .extra-info {
  margin-left: auto;
  display: flex;
}
subtasks-list .subtasks-list .subtask .extra-info rl-icon {
  color: var(--general-text-inactive-color);
}
subtasks-list .subtasks-list .subtask .handle {
  margin-left: auto;
  touch-action: none;
  padding: 10px;
}
subtasks-counter span {
  color: var(--general-text-inactive-color);
  font-size: 11px;
}
subtask-details {
  display: flex;
  min-height: 100%;
  flex-direction: column;
  background: var(--general-background-color);
}
subtask-details > header {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 15px;
  min-height: 220px !important;
  box-sizing: border-box;
  background: var(--background-highlight-color);
}
subtask-details > header .status {
  opacity: 0.5;
}
subtask-details > header .status-icon {
  display: none;
  min-width: 22px;
  min-height: 22px;
  line-height: 22px;
  box-sizing: border-box;
  border-radius: 50%;
  border: 1px solid var(--general-border-color);
  margin-right: 10px;
  justify-content: center;
  align-items: center;
  color: white;
}
subtask-details > header .status-icon rl-icon {
  font-size: 14px;
}
subtask-details > header.in-progress .status-icon {
  display: none;
}
subtask-details > header.completed {
  background: rgba(var(--confirmation-color-rgb), 0.2);
}
subtask-details > header.completed .status {
  opacity: 1;
}
subtask-details > header.completed h1 span {
  opacity: 0.5;
}
subtask-details > header.completed h1 .title {
  text-decoration: line-through;
}
subtask-details > header.completed .status {
  color: var(--confirmation-color);
}
subtask-details > header.completed .status .status-icon {
  display: inline-flex;
  background: var(--confirmation-color);
}
subtask-details > header.completed .status .status-icon rl-icon:not([icon="save"]) {
  display: none;
}
subtask-details > header.overdue {
  background: rgba(var(--warning-color-rgb), 0.2);
}
subtask-details > header.overdue .status {
  color: var(--warning-color);
}
subtask-details > header.overdue .status .status-icon {
  display: inline-flex;
  background: var(--warning-color);
}
subtask-details > header.overdue .status .status-icon rl-icon:not([icon="close"]) {
  display: none;
}
subtask-details > header.due-soon {
  background: rgba(var(--alert-color-rgb), 0.2);
}
subtask-details > header.due-soon .status {
  color: var(--alert-color);
}
subtask-details > header.due-soon .status .status-icon {
  display: inline-flex;
  background: var(--alert-color);
}
subtask-details > header.due-soon .status .status-icon rl-icon:not([icon="exclamation"]) {
  display: none;
}
subtask-details > header .top-buttons {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
subtask-details > header .info {
  width: 100%;
}
subtask-details > header .info .status {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 13px;
  font-weight: bold;
}
subtask-details > header .info .status-placeholder {
  display: inline-block;
  height: 22px;
  width: 140px;
  border-radius: 4px;
}
subtask-details > header .info .bottom {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
subtask-details > header .info .title-placeholder {
  display: inline-block;
  height: 24px;
  width: 170px;
  border-radius: 4px;
}
subtask-details > header .info .assignees {
  display: flex;
}
subtask-details > header .info .assignees:disabled {
  opacity: 0.5;
}
subtask-details > header .info .assignees span {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  color: var(--text-on-white-color);
  background: var(--white-color);
  box-sizing: border-box;
  box-shadow: 0 6px 7px 0 rgba(var(--general-text-color-rgb), 0.1);
  border: 1px solid var(--white-color);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
subtask-details > header .info .assignees span:not(:last-of-type) {
  margin-right: -10px;
}
subtask-details > header .info .assignees span rl-icon {
  color: var(--text-on-white-color);
  font-size: 19px;
  margin: 0;
}
subtask-details > header button {
  padding: 0;
  width: auto;
  color: var(--general-text-color);
}
subtask-details > header button rl-icon {
  font-size: 26px;
  margin-left: -5px;
}
subtask-details > header h1 {
  margin: 0;
  padding: 10px 10px 0 0;
  width: 100%;
  font-size: initial;
  word-break: break-word;
}
subtask-details > header h1 span {
  font-size: 20px;
}
subtask-details > header h1 rl-icon {
  opacity: 0.5;
  margin-left: 10px;
}
subtask-details .statistics {
  padding: 15px;
}
subtask-details .statistics .statistics-columns {
  padding: 15px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  border: 1px solid var(--general-border-color);
  border-radius: 10px;
  cursor: pointer;
}
subtask-details .statistics .statistics-columns .statistics-column {
  width: 50%;
  text-align: center;
}
subtask-details .statistics .statistics-columns h4 {
  margin: 0;
}
subtask-details .statistics .statistics-columns .rl-headline-placeholder {
  width: 40px;
  position: relative;
  top: 3px;
}
subtask-details .statistics .statistics-columns .status-text {
  margin-bottom: 5px;
}
subtask-details .statistics .statistics-columns .status-text .status-text-placeholder {
  height: 16px;
  width: 80px;
  display: inline-block;
  border-radius: 4px;
  vertical-align: middle;
}
subtask-details .statistics .statistics-columns .status-icon {
  display: inline-flex;
  background: var(--confirmation-color);
  width: 22px;
  min-width: 22px;
  height: 22px;
  min-height: 22px;
  line-height: 22px;
  box-sizing: border-box;
  border-radius: 50%;
  border: 1px solid var(--general-border-color);
  justify-content: center;
  align-items: center;
  color: white;
}
subtask-details .statistics .statistics-columns .status-icon.overdue {
  background: var(--warning-color);
}
subtask-details .statistics .statistics-columns .status-icon.in-progress {
  background: none;
}
subtask-details .statistics .statistics-columns .status-icon.in-progress .c100 {
  font-size: 22px;
}
subtask-details .statistics .statistics-columns .status-icon.in-progress .c100:after {
  background-color: white;
}
subtask-details .statistics .statistics-columns .status-icon rl-icon {
  font-size: 14px;
}
subtask-details .statistics .statistics-columns .status-icon-placeholder {
  height: 22px;
  width: 22px;
  min-height: 22px;
  line-height: 22px;
  border-radius: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
subtask-details .details {
  padding: 20px 15px 10px 15px;
  flex: 1;
  display: flex;
  flex-direction: column;
  background: var(--general-background-color);
}
subtask-details .details .details-row-placeholder {
  display: block;
  height: 30px;
  min-width: 10px;
  border-radius: 4px;
  margin-bottom: 10px;
}
subtask-details .details rl-rich-preview {
  position: relative;
}
subtask-details .details rl-rich-preview .content.loading {
  width: 100%;
}
subtask-details .details translation-button {
  margin-bottom: 30px;
}
subtask-details .title-expandable {
  display: flex;
  align-items: center;
}
subtask-details .title-expandable .title-content {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
subtask-details .title-expandable .title-content .empty-comments {
  opacity: 0.5;
}
subtask-details .title-expandable .title-content rl-icon {
  color: var(--general-text-color);
  opacity: 0.5;
  font-size: inherit;
}
subtask-details .detail-item {
  display: flex;
  padding: 10px 0;
  align-items: center;
  width: 100%;
}
subtask-details .detail-item > rl-icon {
  margin-right: 15px;
}
subtask-details .categories rl-icon {
  margin-top: 2.5px;
  margin-bottom: auto;
}
subtask-details .categories ul {
  display: flex;
  flex-wrap: wrap;
  margin: -10px 0 0 0;
  padding: 0;
}
subtask-details .categories ul li {
  padding: 0 10px;
  height: 24px;
  line-height: 24px;
  color: var(--white-color);
  list-style: none;
  margin-top: 10px;
  font-size: 13px;
  font-weight: 500;
}
subtask-details .categories ul li:not(:last-of-type) {
  margin-right: 10px;
}
subtask-details .add-subtask {
  margin: 10px 0;
  height: 50px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  border: 1px solid var(--general-border-color);
  border-radius: 10px;
}
subtask-details .add-subtask rl-icon {
  margin: 0 10px;
  color: var(--general-text-color);
}
subtask-details .cant-complete {
  justify-content: center;
}
subtask-details .swipe span {
  text-transform: uppercase;
}
subtask-details .details-bottom {
  margin-top: auto;
}
subtask-details .download-event-wrapper {
  text-align: center;
}
subtask-details .download-event-wrapper .download-event {
  text-decoration: none;
}
subtask-details .download-event-wrapper .fa {
  padding-right: 10px;
}
completions-popup .user ng-transclude rl-icon {
  color: rgba(var(--general-text-color-rgb), 0.6);
  font-size: 25px;
}
completions-popup .user ng-transclude rl-icon:active {
  color: rgba(var(--general-text-color-rgb), 1);
}
update-subtask-popup .scroll-container {
  flex: 1;
  overflow-y: auto;
  position: relative;
}
update-subtask-popup .subtask-header {
  padding: 0 15px;
}
update-subtask-popup .subtask-header .language-selector-wrapper {
  margin-left: -15px;
  margin-right: -15px;
}
update-subtask-popup .subtask-header .language-selector-wrapper .rl-label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
  min-height: 43px;
}
update-subtask-popup .subtask-header .language-selector-wrapper language-selector {
  background-color: var(--background-highlight-color);
}
update-subtask-popup .subtask-header .language-selector-wrapper language-selector .selected-languages {
  margin: 0 15px;
}
update-subtask-popup .subtask-header .language-selector-wrapper language-selector .selected-title {
  display: none;
}
update-subtask-popup form {
  padding-bottom: 75px;
}
update-subtask-popup .subtask-options {
  padding: 0 15px 15px;
}
update-subtask-popup .save button {
  text-transform: uppercase;
  color: var(--white-color);
  background: var(--button-background-color);
}
assignees-info-popup action-sheet-custom .actionsheet {
  max-height: 80vh;
  overflow: auto;
  color: var(--elevated-text-color);
  padding-top: 0;
}
assignees-info-popup p {
  margin: 0;
}
assignees-info-popup .section:last-child {
  margin-bottom: calc(var(--m-spacing) * 5);
}
assignees-info-popup .title {
  font-size: 1.3rem;
  font-weight: 600;
  line-height: 120%;
  margin-top: var(--spacing);
  margin-bottom: var(--s-spacing);
}
assignees-info-popup .text {
  font-size: 1rem;
  line-height: 130%;
  margin-bottom: var(--spacing);
}
assignees-info-popup .lock {
  font-size: 10.4px;
  width: 10.4px;
}
assignees-info-popup .demo-card {
  position: relative;
  display: block;
  padding: var(--m-spacing);
  box-sizing: border-box;
  cursor: pointer;
  background: var(--background-highlight-color);
  border-radius: var(--general-border-radius);
  margin-bottom: calc(4*var(--m-spacing));
}
@media only screen and (min-width: 1025px) {
  assignees-info-popup .demo-card {
    flex-basis: 49%;
    min-height: 100px;
  }
}
assignees-info-popup .demo-card .assignees {
  width: 61px;
  height: 61px;
  border: 4px solid red;
  border-radius: 50%;
  position: absolute;
  right: 10px;
  top: 75%;
  transform: translateY(-50%);
  background-color: var(--white-color);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  box-shadow: 0px 7px 25px 0px rgba(0, 0, 0, 0.25);
}
assignees-info-popup .demo-card .assignees span {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  color: var(--text-on-white-color);
  background: var(--white-color);
  box-sizing: border-box;
  box-shadow: 0 6px 7px 0 rgba(var(--general-text-color-rgb), .1);
  border: 1px solid var(--background-highlight-color);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
assignees-info-popup .demo-card .assignees span:not(:last-of-type) {
  margin-right: -5px;
}
assignees-info-popup .demo-card .assignees span:last-of-type img {
  border-radius: 1440px;
  border: 3px solid var(--background-highlight-color);
}
assignees-info-popup .demo-card .task-icons-container {
  display: inline;
  position: absolute;
  right: 0;
}
assignees-info-popup .demo-card .task {
  height: 100%;
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: row;
  justify-content: center;
  box-sizing: border-box;
  background-size: cover;
}
assignees-info-popup .demo-card .task .label {
  position: relative;
  z-index: 2;
  align-self: flex-start;
  padding: 5px;
  font-size: 1.06667rem;
  font-weight: 700;
}
assignees-info-popup .demo-card .task .content {
  flex: 1;
  z-index: 2;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  overflow: hidden;
}
assignees-info-popup .demo-card .task .task-text {
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-left: var(--m-spacing);
}
assignees-info-popup .demo-card .task .task-text .top {
  display: flex;
  justify-content: space-between;
}
assignees-info-popup .demo-card .task .task-text .top .label {
  box-sizing: border-box;
  overflow: hidden;
  white-space: nowrap;
  max-width: 120px;
  text-overflow: ellipsis;
  padding: 5px;
  font-size: 13px;
}
assignees-info-popup .demo-card .task .task-text .top .task-title {
  font-weight: 700;
  font-size: 1.06667rem;
  word-break: break-word;
  line-height: 21px;
}
assignees-info-popup .demo-card .task .task-text h2 {
  margin: 0 0 2px;
  padding: 0;
  font-size: 15px;
  font-weight: 600;
}
assignees-info-popup .demo-card .task .task-text h2 rl-icon {
  font-size: 12px;
  opacity: 0.4;
  color: var(--general-text-color);
}
assignees-info-popup .demo-card .task .task-text p {
  font-size: 13px;
  padding: 0;
  margin: 0;
  font-weight: 500;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
assignees-info-popup .demo-card .task .task-text .deadline {
  font-size: 0.86667rem;
  font-weight: 400;
  white-space: normal;
  opacity: 0.4;
}
assignees-info-popup .demo-card .task .categories {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
assignees-info-popup .demo-card .task .categories .category {
  width: 30px;
  height: 5px;
  margin-top: 10px;
  margin-right: 5px;
  border-radius: 5px;
}
assignees-info-popup .demo-card .task .categories .red {
  background-color: var(--warning-color);
}
assignees-info-popup .demo-card .task .categories .yellow {
  background-color: var(--alert-color);
}
assignees-info-popup .demo-card .task .categories .green {
  background-color: var(--confirmation-color);
}
assignees-info-popup .demo-card .task .footer,
assignees-info-popup .demo-card .task .top,
assignees-info-popup .demo-card .task .bottom {
  display: flex;
  justify-content: space-between;
}
assignees-info-popup .demo-card .task .footer {
  align-items: flex-end;
  margin-top: auto;
}
thumbnail-selector .rl-header > button[disabled] rl-icon {
  opacity: 0.5;
}
@media only screen and (min-width: 1025px) {
  thumbnail-selector .popup-content.clean.thumbnail-popup {
    left: 50% !important;
  }
}
thumbnail-selector .popup-content-inner {
  display: flex !important;
}
thumbnail-selector .popup-content-inner .title {
  margin: 10px;
  color: white;
  text-align: center;
}
.thumbnail-selector {
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.thumbnail-selector .video-container {
  position: relative;
  max-width: 310px;
  margin: 0 auto;
  font-size: 0;
  height: calc(100% - 55px);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
@media only screen and (min-width: 1025px) {
  .thumbnail-selector .video-container {
    max-width: 420px;
  }
}
.thumbnail-selector .video-container video {
  width: 100%;
  max-height: 100%;
}
.thumbnail-selector .video-container .spinner-wrapper {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.1);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.thumbnail-selector .video-container .spinner-wrapper .spinner {
  border: solid 2px #fff;
}
.thumbnail-selector .video-container .timeline {
  width: 100%;
  height: 10px;
  cursor: pointer;
  background: var(--background-highlight-color);
  position: relative;
}
.thumbnail-selector .video-container .timeline .timeline-drag {
  width: 16px;
  height: 16px;
  left: 0;
  top: -3px;
  background-color: var(--highlight-color);
  position: absolute;
  z-index: 2;
  transform-origin: 0 0;
  border-radius: 50%;
  margin-left: -8px;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.3);
}
.thumbnail-selector .video-container .timeline .timeline-progress {
  display: block;
  width: 100%;
  height: 10px;
  transform: scaleX(0);
  transform-origin: 0 0;
  position: relative;
  z-index: 1;
  background: var(--highlight-color);
}
.rect-auto,
.c100.p51 .slice,
.c100.p52 .slice,
.c100.p53 .slice,
.c100.p54 .slice,
.c100.p55 .slice,
.c100.p56 .slice,
.c100.p57 .slice,
.c100.p58 .slice,
.c100.p59 .slice,
.c100.p60 .slice,
.c100.p61 .slice,
.c100.p62 .slice,
.c100.p63 .slice,
.c100.p64 .slice,
.c100.p65 .slice,
.c100.p66 .slice,
.c100.p67 .slice,
.c100.p68 .slice,
.c100.p69 .slice,
.c100.p70 .slice,
.c100.p71 .slice,
.c100.p72 .slice,
.c100.p73 .slice,
.c100.p74 .slice,
.c100.p75 .slice,
.c100.p76 .slice,
.c100.p77 .slice,
.c100.p78 .slice,
.c100.p79 .slice,
.c100.p80 .slice,
.c100.p81 .slice,
.c100.p82 .slice,
.c100.p83 .slice,
.c100.p84 .slice,
.c100.p85 .slice,
.c100.p86 .slice,
.c100.p87 .slice,
.c100.p88 .slice,
.c100.p89 .slice,
.c100.p90 .slice,
.c100.p91 .slice,
.c100.p92 .slice,
.c100.p93 .slice,
.c100.p94 .slice,
.c100.p95 .slice,
.c100.p96 .slice,
.c100.p97 .slice,
.c100.p98 .slice,
.c100.p99 .slice,
.c100.p100 .slice {
  clip: rect(auto, auto, auto, auto);
}
.pie,
.c100 .bar,
.c100.p51 .fill,
.c100.p52 .fill,
.c100.p53 .fill,
.c100.p54 .fill,
.c100.p55 .fill,
.c100.p56 .fill,
.c100.p57 .fill,
.c100.p58 .fill,
.c100.p59 .fill,
.c100.p60 .fill,
.c100.p61 .fill,
.c100.p62 .fill,
.c100.p63 .fill,
.c100.p64 .fill,
.c100.p65 .fill,
.c100.p66 .fill,
.c100.p67 .fill,
.c100.p68 .fill,
.c100.p69 .fill,
.c100.p70 .fill,
.c100.p71 .fill,
.c100.p72 .fill,
.c100.p73 .fill,
.c100.p74 .fill,
.c100.p75 .fill,
.c100.p76 .fill,
.c100.p77 .fill,
.c100.p78 .fill,
.c100.p79 .fill,
.c100.p80 .fill,
.c100.p81 .fill,
.c100.p82 .fill,
.c100.p83 .fill,
.c100.p84 .fill,
.c100.p85 .fill,
.c100.p86 .fill,
.c100.p87 .fill,
.c100.p88 .fill,
.c100.p89 .fill,
.c100.p90 .fill,
.c100.p91 .fill,
.c100.p92 .fill,
.c100.p93 .fill,
.c100.p94 .fill,
.c100.p95 .fill,
.c100.p96 .fill,
.c100.p97 .fill,
.c100.p98 .fill,
.c100.p99 .fill,
.c100.p100 .fill {
  position: absolute;
  border: 0.14em solid var(--highlight-color);
  width: 0.72em;
  height: 0.72em;
  clip: rect(0em, 0.5em, 1em, 0em);
  border-radius: 50%;
  transform: rotate(0deg);
}
.pie-fill,
.c100.p51 .bar:after,
.c100.p51 .fill,
.c100.p52 .bar:after,
.c100.p52 .fill,
.c100.p53 .bar:after,
.c100.p53 .fill,
.c100.p54 .bar:after,
.c100.p54 .fill,
.c100.p55 .bar:after,
.c100.p55 .fill,
.c100.p56 .bar:after,
.c100.p56 .fill,
.c100.p57 .bar:after,
.c100.p57 .fill,
.c100.p58 .bar:after,
.c100.p58 .fill,
.c100.p59 .bar:after,
.c100.p59 .fill,
.c100.p60 .bar:after,
.c100.p60 .fill,
.c100.p61 .bar:after,
.c100.p61 .fill,
.c100.p62 .bar:after,
.c100.p62 .fill,
.c100.p63 .bar:after,
.c100.p63 .fill,
.c100.p64 .bar:after,
.c100.p64 .fill,
.c100.p65 .bar:after,
.c100.p65 .fill,
.c100.p66 .bar:after,
.c100.p66 .fill,
.c100.p67 .bar:after,
.c100.p67 .fill,
.c100.p68 .bar:after,
.c100.p68 .fill,
.c100.p69 .bar:after,
.c100.p69 .fill,
.c100.p70 .bar:after,
.c100.p70 .fill,
.c100.p71 .bar:after,
.c100.p71 .fill,
.c100.p72 .bar:after,
.c100.p72 .fill,
.c100.p73 .bar:after,
.c100.p73 .fill,
.c100.p74 .bar:after,
.c100.p74 .fill,
.c100.p75 .bar:after,
.c100.p75 .fill,
.c100.p76 .bar:after,
.c100.p76 .fill,
.c100.p77 .bar:after,
.c100.p77 .fill,
.c100.p78 .bar:after,
.c100.p78 .fill,
.c100.p79 .bar:after,
.c100.p79 .fill,
.c100.p80 .bar:after,
.c100.p80 .fill,
.c100.p81 .bar:after,
.c100.p81 .fill,
.c100.p82 .bar:after,
.c100.p82 .fill,
.c100.p83 .bar:after,
.c100.p83 .fill,
.c100.p84 .bar:after,
.c100.p84 .fill,
.c100.p85 .bar:after,
.c100.p85 .fill,
.c100.p86 .bar:after,
.c100.p86 .fill,
.c100.p87 .bar:after,
.c100.p87 .fill,
.c100.p88 .bar:after,
.c100.p88 .fill,
.c100.p89 .bar:after,
.c100.p89 .fill,
.c100.p90 .bar:after,
.c100.p90 .fill,
.c100.p91 .bar:after,
.c100.p91 .fill,
.c100.p92 .bar:after,
.c100.p92 .fill,
.c100.p93 .bar:after,
.c100.p93 .fill,
.c100.p94 .bar:after,
.c100.p94 .fill,
.c100.p95 .bar:after,
.c100.p95 .fill,
.c100.p96 .bar:after,
.c100.p96 .fill,
.c100.p97 .bar:after,
.c100.p97 .fill,
.c100.p98 .bar:after,
.c100.p98 .fill,
.c100.p99 .bar:after,
.c100.p99 .fill,
.c100.p100 .bar:after,
.c100.p100 .fill {
  transform: rotate(180deg);
}
.c100 {
  position: relative;
  font-size: 120px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  float: left;
  background-color: rgba(var(--general-text-color-rgb), .1);
}
.c100 *,
.c100 *:before,
.c100 *:after {
  box-sizing: content-box;
}
.c100.center {
  float: none;
  margin: 0 auto;
}
.c100.big {
  font-size: 240px;
}
.c100.small {
  font-size: 24px;
}
.c100 > span {
  position: absolute;
  width: 100%;
  z-index: 1;
  left: 0;
  top: 0;
  width: 5em;
  line-height: 5em;
  font-size: 0.2em;
  color: rgba(var(--general-text-color-rgb), .1);
  display: block;
  text-align: center;
  white-space: nowrap;
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
}
.c100:after {
  position: absolute;
  top: 0.14em;
  left: 0.14em;
  display: block;
  content: " ";
  border-radius: 50%;
  background-color: var(--background-highlight-color);
  width: 0.72em;
  height: 0.72em;
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease-in;
}
.c100 .slice {
  position: absolute;
  width: 1em;
  height: 1em;
  clip: rect(0em, 1em, 1em, 0.5em);
}
.c100.p1 .bar {
  transform: rotate(3.6deg);
}
.c100.p2 .bar {
  transform: rotate(7.2deg);
}
.c100.p3 .bar {
  transform: rotate(10.8deg);
}
.c100.p4 .bar {
  transform: rotate(14.4deg);
}
.c100.p5 .bar {
  transform: rotate(18deg);
}
.c100.p6 .bar {
  transform: rotate(21.6deg);
}
.c100.p7 .bar {
  transform: rotate(25.2deg);
}
.c100.p8 .bar {
  transform: rotate(28.8deg);
}
.c100.p9 .bar {
  transform: rotate(32.4deg);
}
.c100.p10 .bar {
  transform: rotate(36deg);
}
.c100.p11 .bar {
  transform: rotate(39.6deg);
}
.c100.p12 .bar {
  transform: rotate(43.2deg);
}
.c100.p13 .bar {
  transform: rotate(46.800000000000004deg);
}
.c100.p14 .bar {
  transform: rotate(50.4deg);
}
.c100.p15 .bar {
  transform: rotate(54deg);
}
.c100.p16 .bar {
  transform: rotate(57.6deg);
}
.c100.p17 .bar {
  transform: rotate(61.2deg);
}
.c100.p18 .bar {
  transform: rotate(64.8deg);
}
.c100.p19 .bar {
  transform: rotate(68.4deg);
}
.c100.p20 .bar {
  transform: rotate(72deg);
}
.c100.p21 .bar {
  transform: rotate(75.60000000000001deg);
}
.c100.p22 .bar {
  transform: rotate(79.2deg);
}
.c100.p23 .bar {
  transform: rotate(82.8deg);
}
.c100.p24 .bar {
  transform: rotate(86.4deg);
}
.c100.p25 .bar {
  transform: rotate(90deg);
}
.c100.p26 .bar {
  transform: rotate(93.60000000000001deg);
}
.c100.p27 .bar {
  transform: rotate(97.2deg);
}
.c100.p28 .bar {
  transform: rotate(100.8deg);
}
.c100.p29 .bar {
  transform: rotate(104.4deg);
}
.c100.p30 .bar {
  transform: rotate(108deg);
}
.c100.p31 .bar {
  transform: rotate(111.60000000000001deg);
}
.c100.p32 .bar {
  transform: rotate(115.2deg);
}
.c100.p33 .bar {
  transform: rotate(118.8deg);
}
.c100.p34 .bar {
  transform: rotate(122.4deg);
}
.c100.p35 .bar {
  transform: rotate(126deg);
}
.c100.p36 .bar {
  transform: rotate(129.6deg);
}
.c100.p37 .bar {
  transform: rotate(133.20000000000002deg);
}
.c100.p38 .bar {
  transform: rotate(136.8deg);
}
.c100.p39 .bar {
  transform: rotate(140.4deg);
}
.c100.p40 .bar {
  transform: rotate(144deg);
}
.c100.p41 .bar {
  transform: rotate(147.6deg);
}
.c100.p42 .bar {
  transform: rotate(151.20000000000002deg);
}
.c100.p43 .bar {
  transform: rotate(154.8deg);
}
.c100.p44 .bar {
  transform: rotate(158.4deg);
}
.c100.p45 .bar {
  transform: rotate(162deg);
}
.c100.p46 .bar {
  transform: rotate(165.6deg);
}
.c100.p47 .bar {
  transform: rotate(169.20000000000002deg);
}
.c100.p48 .bar {
  transform: rotate(172.8deg);
}
.c100.p49 .bar {
  transform: rotate(176.4deg);
}
.c100.p50 .bar {
  transform: rotate(180deg);
}
.c100.p51 .bar {
  transform: rotate(183.6deg);
}
.c100.p52 .bar {
  transform: rotate(187.20000000000002deg);
}
.c100.p53 .bar {
  transform: rotate(190.8deg);
}
.c100.p54 .bar {
  transform: rotate(194.4deg);
}
.c100.p55 .bar {
  transform: rotate(198deg);
}
.c100.p56 .bar {
  transform: rotate(201.6deg);
}
.c100.p57 .bar {
  transform: rotate(205.20000000000002deg);
}
.c100.p58 .bar {
  transform: rotate(208.8deg);
}
.c100.p59 .bar {
  transform: rotate(212.4deg);
}
.c100.p60 .bar {
  transform: rotate(216deg);
}
.c100.p61 .bar {
  transform: rotate(219.6deg);
}
.c100.p62 .bar {
  transform: rotate(223.20000000000002deg);
}
.c100.p63 .bar {
  transform: rotate(226.8deg);
}
.c100.p64 .bar {
  transform: rotate(230.4deg);
}
.c100.p65 .bar {
  transform: rotate(234deg);
}
.c100.p66 .bar {
  transform: rotate(237.6deg);
}
.c100.p67 .bar {
  transform: rotate(241.20000000000002deg);
}
.c100.p68 .bar {
  transform: rotate(244.8deg);
}
.c100.p69 .bar {
  transform: rotate(248.4deg);
}
.c100.p70 .bar {
  transform: rotate(252deg);
}
.c100.p71 .bar {
  transform: rotate(255.6deg);
}
.c100.p72 .bar {
  transform: rotate(259.2deg);
}
.c100.p73 .bar {
  transform: rotate(262.8deg);
}
.c100.p74 .bar {
  transform: rotate(266.40000000000003deg);
}
.c100.p75 .bar {
  transform: rotate(270deg);
}
.c100.p76 .bar {
  transform: rotate(273.6deg);
}
.c100.p77 .bar {
  transform: rotate(277.2deg);
}
.c100.p78 .bar {
  transform: rotate(280.8deg);
}
.c100.p79 .bar {
  transform: rotate(284.40000000000003deg);
}
.c100.p80 .bar {
  transform: rotate(288deg);
}
.c100.p81 .bar {
  transform: rotate(291.6deg);
}
.c100.p82 .bar {
  transform: rotate(295.2deg);
}
.c100.p83 .bar {
  transform: rotate(298.8deg);
}
.c100.p84 .bar {
  transform: rotate(302.40000000000003deg);
}
.c100.p85 .bar {
  transform: rotate(306deg);
}
.c100.p86 .bar {
  transform: rotate(309.6deg);
}
.c100.p87 .bar {
  transform: rotate(313.2deg);
}
.c100.p88 .bar {
  transform: rotate(316.8deg);
}
.c100.p89 .bar {
  transform: rotate(320.40000000000003deg);
}
.c100.p90 .bar {
  transform: rotate(324deg);
}
.c100.p91 .bar {
  transform: rotate(327.6deg);
}
.c100.p92 .bar {
  transform: rotate(331.2deg);
}
.c100.p93 .bar {
  transform: rotate(334.8deg);
}
.c100.p94 .bar {
  transform: rotate(338.40000000000003deg);
}
.c100.p95 .bar {
  transform: rotate(342deg);
}
.c100.p96 .bar {
  transform: rotate(345.6deg);
}
.c100.p97 .bar {
  transform: rotate(349.2deg);
}
.c100.p98 .bar {
  transform: rotate(352.8deg);
}
.c100.p99 .bar {
  transform: rotate(356.40000000000003deg);
}
.c100.p100 .bar {
  transform: rotate(360deg);
}
multiple-permission-selector-wrapper {
  display: flex;
  flex-direction: column;
}
multiple-permission-selector-wrapper .permissions-label-wrapper {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--general-border-color);
}
multiple-permission-selector-wrapper .permission-selector-label {
  flex: 1;
  font-size: 15px;
  font-weight: 500;
  padding: 15px 10px 7px;
}
multiple-permission-selector-wrapper .remove {
  font-weight: 600;
  font-size: 15px;
  line-height: 25px;
  color: var(--warning-color);
  padding: 15px 10px 7px;
}
multiple-permission-selector-wrapper .permissions-set {
  display: flex;
  flex-direction: column;
}
#permissions.not-multiple-permissions:after {
  border: solid var(--general-text-color);
  border-width: 0 2px 2px 0;
  content: "";
  display: block;
  height: 10px;
  margin-top: -5px;
  opacity: 0.5;
  position: absolute;
  right: 12px;
  top: 50%;
  transform: rotate(-45deg);
  width: 10px;
}
multiple-permission-selector:not(:last-child) {
  border-bottom: 1px solid rgba(var(--general-text-color-rgb), 0.1);
}
multiple-permission-selector,
multiple-permission-selector-wrapper,
.permission-selector-popup {
  display: flex;
  flex-direction: column;
}
multiple-permission-selector .permission-selector-controls,
multiple-permission-selector-wrapper .permission-selector-controls,
.permission-selector-popup .permission-selector-controls {
  display: flex;
  justify-content: space-between;
  background: var(--general-background-color);
}
multiple-permission-selector .permission-selector-controls .remove,
multiple-permission-selector-wrapper .permission-selector-controls .remove,
.permission-selector-popup .permission-selector-controls .remove {
  font-weight: 600;
  font-size: 15px;
  line-height: 25px;
  color: var(--warning-color);
  padding: 0 10px 0;
}
multiple-permission-selector .permission-selector-controls #permissions,
multiple-permission-selector-wrapper .permission-selector-controls #permissions,
.permission-selector-popup .permission-selector-controls #permissions {
  position: relative;
  padding: 0;
  min-height: 50px;
}
multiple-permission-selector .permission-selector-controls #permissions.only-default-item,
multiple-permission-selector-wrapper .permission-selector-controls #permissions.only-default-item,
.permission-selector-popup .permission-selector-controls #permissions.only-default-item {
  align-items: center;
  padding-top: 0;
}
multiple-permission-selector .permission-selector-controls #permissions.only-default-item li,
multiple-permission-selector-wrapper .permission-selector-controls #permissions.only-default-item li,
.permission-selector-popup .permission-selector-controls #permissions.only-default-item li {
  margin-bottom: 0;
}
multiple-permission-selector .permission-selector-controls #permissions.not-multiple-permissions,
multiple-permission-selector-wrapper .permission-selector-controls #permissions.not-multiple-permissions,
.permission-selector-popup .permission-selector-controls #permissions.not-multiple-permissions {
  align-items: center;
  padding-top: 0;
}
multiple-permission-selector .permission-selector-controls #permissions.not-multiple-permissions.has-items,
multiple-permission-selector-wrapper .permission-selector-controls #permissions.not-multiple-permissions.has-items,
.permission-selector-popup .permission-selector-controls #permissions.not-multiple-permissions.has-items {
  padding-top: 10px;
  align-items: unset;
}
multiple-permission-selector .permission-selector-controls #permissions .selected-items,
multiple-permission-selector-wrapper .permission-selector-controls #permissions .selected-items,
.permission-selector-popup .permission-selector-controls #permissions .selected-items {
  margin: 0;
  padding: 0 30px 0 0;
}
multiple-permission-selector .permission-selector-controls #permissions .placeholder p,
multiple-permission-selector-wrapper .permission-selector-controls #permissions .placeholder p,
.permission-selector-popup .permission-selector-controls #permissions .placeholder p {
  margin: 0;
}
multiple-permission-selector .popup-content-inner,
multiple-permission-selector-wrapper .popup-content-inner,
.permission-selector-popup .popup-content-inner {
  background: var(--general-background-color);
}
multiple-permission-selector header,
multiple-permission-selector-wrapper header,
.permission-selector-popup header {
  display: flex;
  align-items: center;
  background: var(--header-background-color);
  color: var(--header-text-color);
}
multiple-permission-selector header .save,
multiple-permission-selector-wrapper header .save,
.permission-selector-popup header .save {
  padding: 0;
  font-size: 26px;
  line-height: 1;
  border: none;
  background: none;
}
multiple-permission-selector label,
multiple-permission-selector-wrapper label,
.permission-selector-popup label {
  line-height: 25px;
  flex: 1 1 0;
}
multiple-permission-selector label.edited,
multiple-permission-selector-wrapper label.edited,
.permission-selector-popup label.edited {
  font-weight: 600;
  color: var(--highlight-color);
}
multiple-permission-selector .selected-items,
multiple-permission-selector-wrapper .selected-items,
.permission-selector-popup .selected-items {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0 10px 0;
  align-items: center;
}
multiple-permission-selector .selected-items li,
multiple-permission-selector-wrapper .selected-items li,
.permission-selector-popup .selected-items li {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  height: 30px;
  max-width: 300px;
  padding: 0 10px;
  margin: 0 10px 10px 0;
  line-height: 30px;
  background: var(--background-highlight-color);
  position: relative;
  overflow: hidden;
}
multiple-permission-selector .selected-items li.more-elements-count,
multiple-permission-selector-wrapper .selected-items li.more-elements-count,
.permission-selector-popup .selected-items li.more-elements-count {
  background: transparent;
}
multiple-permission-selector .selected-items li.default,
multiple-permission-selector-wrapper .selected-items li.default,
.permission-selector-popup .selected-items li.default {
  padding: 0;
  margin: 0;
  background: transparent;
}
multiple-permission-selector .selected-items li.placeholder,
multiple-permission-selector-wrapper .selected-items li.placeholder,
.permission-selector-popup .selected-items li.placeholder {
  background: transparent;
  padding: 0;
  margin-bottom: 0;
}
multiple-permission-selector .selected-items li p,
multiple-permission-selector-wrapper .selected-items li p,
.permission-selector-popup .selected-items li p {
  margin: 0;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: relative;
  font-size: 15px;
}
multiple-permission-selector .selected-items li p span,
multiple-permission-selector-wrapper .selected-items li p span,
.permission-selector-popup .selected-items li p span {
  display: none;
}
multiple-permission-selector .selected-items li.with-subtitle p,
multiple-permission-selector-wrapper .selected-items li.with-subtitle p,
.permission-selector-popup .selected-items li.with-subtitle p {
  margin-top: -10px;
  font-size: 14px;
}
multiple-permission-selector .selected-items li.with-subtitle p span,
multiple-permission-selector-wrapper .selected-items li.with-subtitle p span,
.permission-selector-popup .selected-items li.with-subtitle p span {
  display: block;
  line-height: 13px;
  position: absolute;
  font-size: 11px;
  top: 20px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  opacity: 0.5;
}
multiple-permission-selector .selected-items li.with-subtitle .remove-item,
multiple-permission-selector-wrapper .selected-items li.with-subtitle .remove-item,
.permission-selector-popup .selected-items li.with-subtitle .remove-item {
  padding-left: 10px;
}
multiple-permission-selector .selected-items .remove-item,
multiple-permission-selector-wrapper .selected-items .remove-item,
.permission-selector-popup .selected-items .remove-item {
  position: relative;
  top: 1px;
  font-weight: 600;
  color: var(--warning-color);
  padding: 1px 2px 1px 8px;
  font-size: 15px;
}
multiple-permission-selector .permission-selector alert-message,
multiple-permission-selector-wrapper .permission-selector alert-message,
.permission-selector-popup .permission-selector alert-message {
  padding: 0 15px 10px 15px;
}
multiple-permission-selector .permission-selector .permission-selector-header,
multiple-permission-selector-wrapper .permission-selector .permission-selector-header,
.permission-selector-popup .permission-selector .permission-selector-header {
  display: flex;
  margin-bottom: 20px;
  overflow: hidden;
  min-height: 50px;
}
multiple-permission-selector .permission-selector .permission-selector-header button,
multiple-permission-selector-wrapper .permission-selector .permission-selector-header button,
.permission-selector-popup .permission-selector .permission-selector-header button {
  height: 50px;
  flex: 1;
  color: var(--header-text-color);
  background: var(--header-background-color);
  font-weight: 500;
}
multiple-permission-selector .permission-selector .permission-selector-header button.show-validation,
multiple-permission-selector-wrapper .permission-selector .permission-selector-header button.show-validation,
.permission-selector-popup .permission-selector .permission-selector-header button.show-validation {
  color: var(--alert-color);
}
multiple-permission-selector .permission-selector .permission-selector-header button.active,
multiple-permission-selector-wrapper .permission-selector .permission-selector-header button.active,
.permission-selector-popup .permission-selector .permission-selector-header button.active {
  font-weight: 600;
}
multiple-permission-selector .permission-selector .permission-selector-header button.active .btn-label,
multiple-permission-selector-wrapper .permission-selector .permission-selector-header button.active .btn-label,
.permission-selector-popup .permission-selector .permission-selector-header button.active .btn-label {
  border-bottom: 3px solid var(--header-text-color);
}
multiple-permission-selector .permission-selector .permission-selector-header button .btn-label,
multiple-permission-selector-wrapper .permission-selector .permission-selector-header button .btn-label,
.permission-selector-popup .permission-selector .permission-selector-header button .btn-label {
  display: inline-block;
  min-height: 50px;
  height: 100%;
  line-height: 50px;
  box-sizing: border-box;
}
multiple-permission-selector .permission-selector article,
multiple-permission-selector-wrapper .permission-selector article,
.permission-selector-popup .permission-selector article {
  padding: 0 15px;
}
multiple-permission-selector .permission-selector article.show-validation .required-label,
multiple-permission-selector-wrapper .permission-selector article.show-validation .required-label,
.permission-selector-popup .permission-selector article.show-validation .required-label {
  color: var(--alert-color);
}
multiple-permission-selector .permission-selector article .required-label,
multiple-permission-selector-wrapper .permission-selector article .required-label,
.permission-selector-popup .permission-selector article .required-label {
  margin: var(--m-spacing) 0;
  font-size: 13px;
}
multiple-permission-selector .permission-selector article .toggle-item,
multiple-permission-selector-wrapper .permission-selector article .toggle-item,
.permission-selector-popup .permission-selector article .toggle-item {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0 15px;
}
multiple-permission-selector .permission-selector article .toggle-item label,
multiple-permission-selector-wrapper .permission-selector article .toggle-item label,
.permission-selector-popup .permission-selector article .toggle-item label {
  padding: 0;
  font-weight: 400;
}
multiple-permission-selector .permission-selector-controls,
multiple-permission-selector-wrapper .permission-selector-controls,
.permission-selector-popup .permission-selector-controls {
  background: var(--general-background-color);
}
multiple-permission-selector #permissions,
multiple-permission-selector-wrapper #permissions,
.permission-selector-popup #permissions {
  width: 100%;
}
multiple-permission-selector .selected-items li,
multiple-permission-selector-wrapper .selected-items li,
.permission-selector-popup .selected-items li {
  border-radius: 7px;
}
permissions-info-popup action-sheet-custom .actionsheet {
  max-height: 80vh;
  overflow: auto;
  color: var(--text-on-white-color);
}
permissions-info-popup p {
  margin: 0;
}
permissions-info-popup .section {
  margin-bottom: calc(var(--m-spacing) * 4);
}
permissions-info-popup .title {
  margin: 0 0 0.5rem;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 120%;
}
permissions-info-popup .text {
  font-size: 1rem;
  line-height: 135%;
  margin-bottom: var(--spacing);
}
permissions-info-popup .subtitle {
  font-size: 1rem;
  font-weight: 600;
  line-height: 120%;
  margin: calc(var(--spacing) * 2) 0 0.5rem;
}
permissions-info-popup .tags {
  display: flex;
  gap: var(--s-spacing);
  margin-top: var(--spacing);
}
permissions-info-popup .tags .tag {
  background-color: rgba(var(--text-on-white-color-rgb), 0.1);
  padding: var(--s-spacing);
  font-weight: 700;
  border-radius: var(--general-border-radius);
  font-size: 0.9rem;
  color: var(--text-on-white-color);
}
permissions-info-popup .tags .tag .lock {
  font-size: 0.85rem;
  color: rgba(var(--text-on-white-color-rgb), 0.4);
}
permissions-info-popup .demo-card {
  border-radius: var(--general-border-radius);
  border: 1px solid rgba(var(--text-on-white-color-rgb), 0.1);
  box-shadow: 0px 7px 15px 0px rgba(60, 72, 88, 0.1);
  padding: var(--m-spacing);
  position: relative;
}
permissions-info-popup .demo-card p {
  max-width: calc(100% - 30px);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: var(--s-spacing);
  color: var(--general-text-inactive-color);
  font-size: 0.9rem;
}
permissions-info-popup .demo-card .card-text span {
  background-color: rgba(var(--text-on-white-color-rgb), 0.1);
  padding: var(--s-spacing);
  font-weight: 700;
  border-radius: var(--general-border-radius);
  font-size: 0.9rem;
  color: var(--text-on-white-color);
}
permissions-info-popup .demo-card rl-icon {
  color: var(--warning-color);
  position: absolute;
  top: 0;
  right: 0;
  padding: var(--spacing) var(--m-spacing);
}
permissions-info-popup .or-divider {
  width: 100%;
  display: grid;
  place-content: center;
  position: relative;
}
permissions-info-popup .or-divider p {
  padding: 0 var(--spacing);
  margin: var(--spacing);
  background-color: var(--white-color);
  text-transform: uppercase;
  font-size: 0.9rem;
}
permissions-info-popup .or-divider::before {
  position: absolute;
  content: '';
  top: 50%;
  height: 2px;
  width: 100%;
  z-index: -1;
  background-color: rgba(var(--text-on-white-color-rgb), 0.1);
}
permissions-info-popup footer rl-button {
  display: block;
}
@keyframes animOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes animBlink {
  80% {
    opacity: 1;
  }
  90% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes animPinPulse {
  0% {
    opacity: 1;
  }
  30% {
    opacity: 0.25;
    transform: scale(0.8);
  }
  60% {
    opacity: 1;
  }
}
pin-code .pin-code-input-wrapper,
pin-code-welcome-page .pin-code-input-wrapper,
pin-code-secure-page .pin-code-input-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
pin-code .pin-code-number,
pin-code-welcome-page .pin-code-number,
pin-code-secure-page .pin-code-number {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 55px;
  height: 55px;
  border-radius: 100%;
  background: rgba(var(--general-text-color-rgb), .1);
}
pin-code .pin-code-number:not(:last-of-type),
pin-code-welcome-page .pin-code-number:not(:last-of-type),
pin-code-secure-page .pin-code-number:not(:last-of-type) {
  margin-right: 10px;
}
pin-code .pin-code-number:after,
pin-code-welcome-page .pin-code-number:after,
pin-code-secure-page .pin-code-number:after {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border-radius: 100%;
}
pin-code .pin-code-number.set,
pin-code-welcome-page .pin-code-number.set,
pin-code-secure-page .pin-code-number.set {
  background: none;
}
pin-code .pin-code-number.set:after,
pin-code-welcome-page .pin-code-number.set:after,
pin-code-secure-page .pin-code-number.set:after {
  width: 45%;
  height: 45%;
  background: var(--highlight-color);
  animation: animOpacity 0.3s ease;
}
pin-code .pin-code-number.active,
pin-code-welcome-page .pin-code-number.active,
pin-code-secure-page .pin-code-number.active {
  background: var(--general-background-color);
}
pin-code .pin-code-number.active:after,
pin-code-welcome-page .pin-code-number.active:after,
pin-code-secure-page .pin-code-number.active:after {
  border: 2px solid var(--highlight-color);
  animation: animBlink 2s ease infinite;
}
pin-code {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
pin-code .pin-code-input {
  width: 250px;
}
pin-code .pin-code-input.error .pin-code-number.set:after {
  background: var(--warning-color);
}
pin-code .pin-code-input.success .pin-code-number.set:after {
  background: var(--confirmation-color);
  animation: animPinPulse 1.5s ease infinite;
}
pin-code .tip {
  margin: 15px 0 0 0;
  font-size: 15px;
  text-align: center;
}
pin-code .keyboard {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  box-shadow: 0 2px 15px 0 rgba(var(--general-text-color-rgb), .1);
  box-sizing: border-box;
}
pin-code .key {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-basis: 33.33333333%;
  height: 55px;
  font-size: 18px;
  font-weight: 500;
  background: var(--general-background-color);
  border-right: 1px solid rgba(var(--general-text-color-rgb), .1);
  border-bottom: 1px solid rgba(var(--general-text-color-rgb), .1);
  box-sizing: border-box;
  cursor: pointer;
  user-select: none;
}
pin-code .key:active {
  background: rgba(var(--general-text-color-rgb), .1);
  transition: background-color 0.3s ease;
}
pin-code .key:nth-child(3n) {
  border-right: none;
}
pin-code .key:nth-child(n + 10) {
  border-bottom: none;
}
pin-code .key.ok {
  color: var(--highlight-color);
}
set-new-pin,
enter-pin-code,
reset-pin-popup .popup-content-inner {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 15px;
  box-sizing: border-box;
}
set-new-pin pin-code,
enter-pin-code pin-code,
reset-pin-popup .popup-content-inner pin-code {
  height: 100%;
}
pin-code-hint {
  position: absolute;
  top: 0;
  right: 0;
}
pin-code-hint rl-icon {
  font-size: 25px;
  padding: 0;
}
pin-code-hint button {
  cursor: pointer;
  padding: 0;
}
pin-code-hint .tip {
  top: 45px;
  right: 250px;
  position: relative;
}
pin-code-hint .tip .tip-content {
  background-color: var(--general-text-color);
  color: var(--white-color);
  width: 235px;
  padding: var(--m-spacing);
}
pin-code-hint .tip .tip-content::after {
  border-color: transparent transparent var(--general-text-color) transparent;
  border-width: 0 7.5px 7px 7.5px;
  left: unset;
  right: 1px;
}
pin-code-hint .tip .pin-tip-content {
  text-align: left;
}
pin-code-hint .tip .pin-tip-content h2 {
  font-size: 1rem;
  margin: 0;
  font-weight: 600;
  line-height: 150%;
}
pin-code-hint .tip .pin-tip-content p {
  font-size: 0.875rem;
  line-height: 130%;
}
enter-pin-code .buttons .forgot > button > span {
  color: var(--general-text-color);
  font-size: 13px;
  opacity: 0.5;
}
reset-pin-popup .popup-content-inner {
  position: relative;
}
reset-pin-popup pin-code-hint .open {
  position: absolute;
  z-index: 10;
  top: 15px;
  right: 15px;
}
reset-pin-popup pin-code {
  height: auto !important;
  flex: 1;
}
pin-code-welcome-page {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
}
pin-code-welcome-page > header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 40%;
  background: url('/Content/Graphics/phone.png') center bottom no-repeat;
}
pin-code-welcome-page > header rl-icon {
  font-size: 25px;
  margin-bottom: 30px;
}
pin-code-welcome-page > header .pin-code-input-wrapper {
  position: relative;
  margin-left: -10px;
  margin-bottom: 60px;
}
pin-code-welcome-page > header .pin-code-input-wrapper .pin-code-number {
  width: 40px;
  height: 40px;
}
pin-code-welcome-page > header .pin-code-input-wrapper .pin-code-number.active {
  background: none;
}
pin-code-welcome-page > header .pin-code-input-wrapper .pin-code-number.active:after {
  animation: none;
}
pin-code-welcome-page section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  width: 100%;
  padding: 30px 25px;
  box-sizing: border-box;
  background: white;
}
pin-code-welcome-page section h2 {
  margin: 0 0 10px 0;
  font-size: 20px;
  color: var(--general-text-color);
}
pin-code-welcome-page section p {
  margin: 0;
  font-size: 15px;
  color: var(--general-text-color);
}
pin-code-welcome-page section p.text-center {
  text-align: center;
}
pin-code-welcome-page section button {
  display: block;
  width: 100%;
  padding: 0;
  text-align: center;
}
pin-code-welcome-page section button.create {
  height: 45px;
  font-weight: 500;
  text-transform: uppercase;
  background: var(--highlight-color);
  color: var(--text-on-highlight-color);
}
pin-code-welcome-page section button.skip {
  padding: 10px 0;
  font-size: 13px;
  opacity: 0.5;
  color: var(--general-text-color);
}
pin-code-secure-page {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
}
pin-code-secure-page > header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 40%;
  background: url('/Content/Graphics/phone.png') center bottom no-repeat;
}
pin-code-secure-page > header rl-icon {
  font-size: 60px;
  margin-bottom: 20px;
}
pin-code-secure-page > header .pin-code-input-wrapper {
  position: relative;
  margin-left: -10px;
  margin-bottom: 60px;
}
pin-code-secure-page > header .pin-code-input-wrapper .pin-code-number {
  width: 40px;
  height: 40px;
}
pin-code-secure-page > header .pin-code-input-wrapper .pin-code-number.set:after {
  background: var(--confirmation-color);
}
pin-code-secure-page section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  width: 100%;
  padding: 30px 25px;
  box-sizing: border-box;
  background: white;
}
pin-code-secure-page section h2 {
  margin: 0 0 10px 0;
  font-size: 20px;
  color: var(--general-text-color);
}
pin-code-secure-page section p {
  margin: 0;
  font-size: 15px;
  color: var(--general-text-color);
}
pin-code-secure-page section button {
  display: block;
  width: 100%;
  padding: 0;
  text-align: center;
}
pin-code-secure-page section button.create {
  height: 45px;
  font-weight: 500;
  text-transform: uppercase;
  background: var(--highlight-color);
  color: var(--text-on-highlight-color);
}
pin-code-secure-page section button.skip {
  padding: 10px 0;
  font-size: 13px;
  opacity: 0.5;
  color: var(--general-text-color);
}
pin-profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  height: 40%;
  padding-bottom: 40px;
  box-sizing: border-box;
}
pin-profile .pin-image {
  border-radius: 50%;
  box-shadow: 0 6px 7px 0 rgba(var(--general-text-color-rgb), 0.1);
  height: 90px;
  width: 90px;
  padding: 0;
  margin-bottom: 15px;
  background-position: center;
}
pin-profile .chevron-btn {
  display: flex;
  align-items: center;
  gap: 5px;
  transition: background-color 0.3s ease;
  padding-left: var(--m-spacing);
  border-radius: var(--general-border-radius);
}
pin-profile .chevron-btn:hover {
  cursor: pointer;
  background-color: rgba(var(--general-text-color-rgb), 0.06);
}
pin-profile .chevron-btn rl-icon {
  color: var(--general-text-inactive-color);
  font-size: 0.85rem;
}
pin-profile h2 {
  margin: 0;
  font-weight: 600;
  font-size: 1.3rem;
  color: var(--general-text-color);
  line-height: 120%;
}
pin-code-biometric-setup h2 {
  margin: 0;
  text-align: center;
}
pin-code-biometric-setup p {
  margin: 10px 0 25px 0;
  text-align: center;
}
pin-code-biometric-setup .icon-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
pin-code-biometric-setup .enable > button > span {
  text-transform: uppercase;
}
pin-code-biometric-setup .close > button > span {
  text-transform: uppercase;
}
pin-code-biometric-setup .skip > button > span {
  color: var(--general-text-color);
  font-size: 13px;
  opacity: 0.5;
}
create-pin-biometric-popup .popup-content-inner {
  position: relative;
}
create-pin-biometric-popup pin-code-hint .open {
  position: absolute;
  z-index: 10;
  top: 15px;
  right: 15px;
}
create-pin-biometric-popup pin-code {
  height: auto !important;
  flex: 1;
}
switch-user-popup {
  --pin-actionsheet-top: 0;
}
switch-user-popup action-sheet-custom {
  margin-left: unset;
}
@media only screen and (min-width: 1025px) {
  switch-user-popup action-sheet-custom .actionsheet-overlay {
    background: none;
  }
}
@media only screen and (min-width: 1025px) {
  switch-user-popup action-sheet-custom .pin-action-sheet {
    top: var(--pin-actionsheet-top);
    padding: var(--s-spacing);
    min-width: 350px;
  }
  switch-user-popup action-sheet-custom .pin-action-sheet > h4 {
    color: var(--elevated-text-inactive-color);
    text-align: left;
    font-size: 0.875rem;
    padding: var(--m-spacing) var(--s-spacing);
    margin: 0;
  }
  switch-user-popup action-sheet-custom .pin-action-sheet .actionsheet-content {
    padding: 0;
  }
  switch-user-popup action-sheet-custom .pin-action-sheet .actionsheet-content .buttons {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
}
switch-user-popup .user {
  display: flex;
  align-items: center;
  gap: var(--m-spacing);
  border-radius: var(--general-border-radius);
  width: 100%;
  padding: 6px;
  transition: background-color 0.3s ease;
}
switch-user-popup .user:hover {
  background-color: rgba(var(--elevated-text-color-rgb), 0.04);
}
switch-user-popup .user.selected {
  background-color: rgba(var(--elevated-text-color-rgb), 0.04);
  font-weight: 600;
}
switch-user-popup .user.selected .radio {
  background: radial-gradient(circle, var(--highlight-color) 0%, var(--highlight-color) 35%, transparent 35%, transparent 60%, var(--highlight-color) 60%, var(--highlight-color) 100%);
}
switch-user-popup .user .profile-image {
  width: 28px;
  height: 28px;
  padding-right: 0;
}
switch-user-popup .user .radio {
  margin-left: auto;
  width: 17px;
  height: 17px;
  border-radius: 50%;
  background: radial-gradient(circle, transparent 0%, transparent 60%, var(--elevated-text-inactive-color) 60%, var(--elevated-text-inactive-color) 100%);
}
switch-user-popup .user .user-name {
  max-width: calc(100% - 90px);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
switch-user-popup .login-as-new-user-btn {
  font-weight: 600;
  color: var(--highlight-color);
  display: flex;
  align-items: center;
  gap: var(--m-spacing);
  border-radius: var(--general-border-radius);
  width: 100%;
  padding: 6px;
  transition: background-color 0.3s ease;
}
switch-user-popup .login-as-new-user-btn:hover {
  background-color: rgba(var(--elevated-text-color-rgb), 0.04);
}
switch-user-popup .login-as-new-user-btn rl-icon {
  border-radius: 50%;
  width: 28px;
  height: 28px;
  background-color: rgba(var(--highlight-color-rgb), 0.2);
  font-size: 14px;
}
pin-code-page {
  display: block;
  background: var(--background-highlight-color);
  position: fixed;
  z-index: 500;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
pin-code-page pin-code-hint .open {
  position: absolute;
  top: 15px;
  right: 15px;
}
pin-code-page .wrapper {
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
@media only screen and (min-width: 768px) {
  pin-code-page .wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
    padding: 50px;
    max-width: 600px;
  }
  pin-code-page .wrapper > * {
    height: 85%;
  }
}
pin-code-page pin-code {
  flex: 1;
  width: 100%;
}
@media only screen and (min-width: 1025px) {
  pin-code-page action-sheet-custom {
    margin-left: 0;
  }
}
attachment-preview .popup-wrapper,
attachments-viewer .popup-wrapper {
  z-index: 401;
}
attachment-preview .popup-content-inner,
attachments-viewer .popup-content-inner {
  background: #000000;
}
attachment-preview header-popup,
attachments-viewer header-popup {
  background: #000;
  position: relative;
  z-index: 10;
}
attachment-preview header-popup rl-icon,
attachments-viewer header-popup rl-icon {
  color: white;
}
attachment-preview header-popup title-slot > div,
attachments-viewer header-popup title-slot > div {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
attachment-preview header-popup title-slot > div rl-icon[icon='arrow-up'],
attachments-viewer header-popup title-slot > div rl-icon[icon='arrow-up'] {
  transform: rotate(45deg);
}
attachment-preview header-popup title-slot > div rl-icon[icon='line'],
attachments-viewer header-popup title-slot > div rl-icon[icon='line'] {
  transform: rotate(-45deg);
  font-size: 15px;
}
attachment-preview header-popup title-slot > div button,
attachments-viewer header-popup title-slot > div button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  width: 30px;
  height: 30px;
}
.attachments-viewer {
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.attachments-viewer.show {
  opacity: 1;
}
.attachments-viewer ::-webkit-scrollbar {
  width: 8px;
  /* Width of the scrollbar */
  height: 8px;
  /* Height of the scrollbar */
}
.attachments-viewer ::-webkit-scrollbar-track {
  background-color: transparent;
}
.attachments-viewer ::-webkit-scrollbar-thumb {
  background-color: rgba(136, 136, 136, 0.5);
  /* Color and opacity of the scrollbar handle */
  border-radius: 16px;
  /* Rounded corners of the scrollbar handle */
  opacity: 0.5;
  /* Opacity of the scrollbar handle */
  height: 50px;
  /* Height of the scrollbar handle */
}
.attachments-viewer ::-webkit-scrollbar-thumb:hover {
  background-color: rgba(85, 85, 85, 0.5);
  /* Color and opacity of the scrollbar handle on hover */
  opacity: 0.7;
  /* Opacity of the scrollbar handle on hover */
}
.attachments-viewer .attachment-thumbs {
  position: absolute;
  text-align: center;
  transition: 0.3s opacity;
  transform: translate3d(0, 0, 0);
  z-index: 10;
  bottom: 5px;
  left: 50px;
  right: 50px;
}
@media only screen and (min-width: 1025px) {
  .attachments-viewer .attachment-thumbs {
    display: flex;
    overflow-x: scroll;
    bottom: 15px;
  }
  .attachments-viewer .attachment-thumbs.center-items {
    justify-content: center;
  }
}
.attachments-viewer .attachment-thumbs button {
  width: 45px;
  height: 45px;
  border-radius: 0;
  border: none;
  background-size: cover !important;
  background-repeat: no-repeat;
  background-position: center !important;
  margin-right: 4px;
  margin-left: 4px;
  padding: 0;
  cursor: pointer;
  position: relative;
}
@media only screen and (min-width: 1025px) {
  .attachments-viewer .attachment-thumbs button {
    padding: 0 20px;
  }
}
.attachments-viewer .attachment-thumbs button.active:after {
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: var(--highlight-color) !important;
  opacity: 0.3;
}
.attachments-viewer .attachment-thumbs button:after {
  position: absolute;
  background: rgba(244, 245, 246, 0.2);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: '';
}
.attachments-viewer .attachment-thumbs button.file-type-unknown .file-icon rl-icon,
.attachments-viewer .attachment-thumbs button.file-type-unknown .time-left {
  color: #bfc3c8;
}
.attachments-viewer .attachment-thumbs button.file-type-video:after {
  background: rgba(var(--alert-color-rgb), .15);
}
.attachments-viewer .attachment-thumbs button.file-type-video .file-icon rl-icon,
.attachments-viewer .attachment-thumbs button.file-type-video .time-left {
  color: var(--alert-color);
}
.attachments-viewer .attachment-thumbs button.file-type-image:after {
  background: rgba(244, 245, 246, 0.8);
}
.attachments-viewer .attachment-thumbs button.file-type-audio:after {
  background-color: rgba(125, 74, 141, 0.15);
  background-image: url('/Content/Graphics/Upload/audio.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.attachments-viewer .attachment-thumbs button.file-type-audio .file-icon rl-icon,
.attachments-viewer .attachment-thumbs button.file-type-audio .time-left {
  color: #7D548D;
}
.attachments-viewer .attachment-thumbs button.file-type-doc:after {
  background: rgba(var(--highlight-color-rgb), .15);
}
.attachments-viewer .attachment-thumbs button.file-type-doc .file-icon rl-icon,
.attachments-viewer .attachment-thumbs button.file-type-doc .time-left {
  color: var(--highlight-color);
}
.attachments-viewer .attachment-thumbs button.file-type-excel:after {
  background: rgba(var(--confirmation-color-rgb), .15);
}
.attachments-viewer .attachment-thumbs button.file-type-excel .file-icon rl-icon,
.attachments-viewer .attachment-thumbs button.file-type-excel .time-left {
  color: var(--confirmation-color);
}
.attachments-viewer .attachment-thumbs button.file-type-pdf:after,
.attachments-viewer .attachment-thumbs button.file-type-powerpoint:after {
  background: rgba(var(--warning-color-rgb), .15);
}
.attachments-viewer .attachment-thumbs button.file-type-pdf .file-icon rl-icon,
.attachments-viewer .attachment-thumbs button.file-type-powerpoint .file-icon rl-icon,
.attachments-viewer .attachment-thumbs button.file-type-pdf .time-left,
.attachments-viewer .attachment-thumbs button.file-type-powerpoint .time-left {
  color: var(--warning-color);
}
.attachments-viewer .attachment-thumbs button.file-type-video .inner,
.attachments-viewer .attachment-thumbs button.file-type-image .inner {
  visibility: hidden;
}
.attachments-viewer .attachment-thumbs button.file-type-video:after,
.attachments-viewer .attachment-thumbs button.file-type-image:after {
  background: rgba(244, 245, 246, 0.2);
}
.attachments-viewer .empty-media {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  position: absolute;
  left: 30px;
  right: 30px;
  height: 200px;
  top: 50%;
  margin-top: -100px;
  padding: 15px;
  border: 1px solid rgba(255, 255, 255, 0.4);
  color: white;
  text-align: center;
}
.attachments-viewer .media-viewer-wrapper.editable .media-viewer-download {
  display: none;
}
.attachments-viewer .media-viewer-wrapper.editable .media-viewer-close {
  left: 10px;
  right: auto;
}
.attachments-viewer .media-viewer-wrapper .media-viewer-download,
.attachments-viewer .media-viewer-wrapper .media-viewer-close {
  position: absolute;
  z-index: 2;
  top: 10px;
  color: #fff;
  font-size: 26px;
  background: none;
  border: none;
  padding: 0;
}
.attachments-viewer .media-viewer-wrapper .media-viewer-download {
  left: 10px;
}
.attachments-viewer .media-viewer-wrapper .media-viewer-close {
  right: 10px;
}
.attachments-viewer .media-viewer-wrapper .footer-actions {
  position: absolute;
  width: 100%;
  height: 55px;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  background: #000;
}
.attachments-viewer .media-viewer-wrapper .footer-actions > button {
  width: 45px;
  padding: 0;
  margin: 0;
  color: white;
}
.attachments-viewer .media-viewer-wrapper .media-viewer-content {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 55px;
  top: 55px;
  background: #000;
}
.attachments-viewer .media-viewer-wrapper .media-viewer-content .image-zoom {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.attachments-viewer .media-viewer-wrapper .media-viewer-content .image-zoom .image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.attachments-viewer .media-viewer-wrapper .media-viewer-content .media-view-single-content {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.attachments-viewer .media-viewer-wrapper .media-viewer-content .media-view-single-content media {
  width: 100%;
}
.attachments-viewer .media-viewer-wrapper .media-viewer-content .media-view-content-files {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  box-sizing: border-box;
  padding: 10px;
  color: #fff;
}
.attachments-viewer .media-viewer-wrapper .media-viewer-content .media-view-content-files h2 {
  display: block;
  text-align: center;
  margin: 0 0 10px 0;
}
.attachments-viewer .media-viewer-wrapper .media-viewer-content .media-view-content-files > ul {
  display: block;
  padding: 0;
  margin: 0;
  list-style: none;
}
.attachments-viewer .media-viewer-wrapper .media-viewer-content .media-view-content-files > ul > li {
  display: block;
  padding: 5px 30px 5px 35px;
  margin-right: 10px;
  list-style: none;
  position: relative;
  border-top: 1px solid rgba(255, 255, 255, 0.3);
}
.attachments-viewer .media-viewer-wrapper .media-viewer-content .media-view-content-files > ul > li:first-child {
  border-top: none;
}
.attachments-viewer .media-viewer-wrapper .media-viewer-content .media-view-content-files > ul > li > a {
  color: #fff;
  text-decoration: none;
}
.attachments-viewer .media-viewer-wrapper .media-viewer-content .media-view-content-files > ul > li .file-icon {
  position: absolute;
  left: 0;
  top: 7px;
  font-size: 24px;
  opacity: 0.8;
}
.attachments-viewer .media-viewer-wrapper .media-viewer-content .media-view-content-files > ul > li h3 {
  display: block;
  margin: 0;
  padding: 0;
  font-size: 1em;
}
.attachments-viewer .media-viewer-wrapper .media-viewer-content .media-view-content-files > ul > li span {
  display: block;
  margin: 0;
  padding: 0;
  font-size: 0.8em;
  opacity: 0.7;
}
.attachments-viewer .media-viewer-wrapper .media-viewer-content .media-view-content-files > ul > li .download-icon {
  position: absolute;
  right: 0;
  top: 10px;
  font-size: 24px;
  opacity: 0.3;
}
.attachments-viewer .media-viewer-wrapper .spinner {
  border-color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -25px;
  margin-left: -25px;
}
.attachments-viewer .image-editor #image-editing-container {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  right: 0;
  bottom: 0;
  top: 55px;
}
.attachments-viewer .image-editor #image-editing-container .custom {
  position: absolute;
  display: inline-block;
  padding: 0;
  border: none;
  background: transparent;
  resize: none;
  height: auto;
  appearance: none;
  outline: none;
  overflow: hidden;
  text-align: center;
  letter-spacing: 0;
  min-width: 1px;
  line-height: 1.3;
}
.attachments-viewer .image-editor .draw-size-picker {
  position: absolute;
  width: 100%;
  top: 55px;
  display: flex;
  gap: 15px;
  padding: 10px 0;
  justify-content: center;
  background: rgba(0, 0, 0, 0.4);
  color: var(--white-color);
}
.attachments-viewer .image-editor .color-picker {
  position: absolute;
  width: 100%;
  bottom: calc(var(--sab) + 20px);
  display: flex;
  gap: 10px;
  justify-content: center;
}
.attachments-viewer .image-editor .color-picker > div {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 33px;
  height: 33px;
  border-radius: 50%;
  border: 2.5px solid white;
}
.attachments-viewer .image-editor .color-picker > div rl-icon {
  color: white;
}
.attachments-viewer .image-editor .color-picker > div:first-of-type rl-icon {
  color: black;
}
.attachments-viewer .image-editor .comment-wrapper {
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
  bottom: 50px;
  display: flex;
  justify-content: space-between;
  width: 90%;
  max-width: 350px;
}
@media only screen and (min-width: 768px) {
  .attachments-viewer .image-editor .comment-wrapper {
    width: 60%;
    max-width: none;
  }
}
.attachments-viewer .image-editor .comment-wrapper profile-image {
  filter: drop-shadow(0px 6px 15px rgba(0, 0, 0, 0.15));
}
.attachments-viewer .image-editor .comment-wrapper profile-image .profile-image {
  width: 45px;
  height: 45px;
}
.attachments-viewer .image-editor .comment-wrapper .comment {
  flex: 1;
  box-shadow: 0 7px 15px rgba(60, 72, 88, 0.1);
  border-radius: var(--general-border-radius);
  background: var(--general-background-color);
}
.attachments-viewer .image-editor .comment-wrapper .comment textarea {
  display: block;
  width: 100%;
  height: 100%;
  padding: 15px;
  max-height: 200px;
  box-sizing: border-box;
  background: transparent;
  border: none;
  resize: none;
}
.attachments-viewer .image-editor .comment-wrapper .comment textarea::placeholder {
  color: var(--general-text-inactive-color);
}
.attachments-viewer .media-viewer-swiper.media-view-single-content .swiper-pagination {
  display: none;
}
@media only screen and (min-width: 1025px) {
  .attachments-viewer .media-viewer-swiper .swiper-pagination {
    display: none;
  }
}
.attachments-viewer .media-viewer-swiper ks-swiper-container {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.attachments-viewer .media-viewer-swiper ks-swiper-container .swiper-container {
  height: 100%;
}
.attachments-viewer .media-viewer-swiper ks-swiper-container .swiper-container .swiper-wrapper {
  color: #fff;
  padding-bottom: 0;
  box-sizing: border-box;
}
.attachments-viewer .media-viewer-swiper ks-swiper-container .swiper-container .swiper-wrapper .swiper-slide .delete-media {
  position: absolute;
  right: -8px;
  top: -11px;
  padding: 5px;
  margin: 0;
  font-size: 18px;
  color: var(--warning-color);
}
.attachments-viewer .media-viewer-swiper ks-swiper-container .swiper-container .swiper-wrapper .media-viewer-swiper-item {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.attachments-viewer .media-viewer-swiper ks-swiper-container .swiper-container .swiper-wrapper .media-viewer-swiper-item media {
  display: block;
  width: 100%;
}
.attachments-viewer .media-viewer-swiper ks-swiper-container .swiper-container .swiper-wrapper .media-viewer-swiper-item .delete-media {
  top: -5px;
  right: -5px;
}
.attachments-viewer .media-viewer-swiper ks-swiper-container .swiper-container .swiper-wrapper .media-viewer-swiper-item .media-viewer-swiper-item-file {
  position: relative;
  color: #fff;
  text-decoration: none;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: calc(100% - 30px);
  height: 500px;
  max-height: calc(100% - 80px);
}
.attachments-viewer .media-viewer-swiper ks-swiper-container .swiper-container .swiper-wrapper .media-viewer-swiper-item .media-viewer-swiper-item-file.no-background .inner {
  background: #ddd;
  color: #000;
  padding-bottom: 20px;
}
.attachments-viewer .media-viewer-swiper ks-swiper-container .swiper-container .swiper-wrapper .media-viewer-swiper-item .media-viewer-swiper-item-file.no-background .inner .file-icon rl-icon {
  font-size: 60px;
}
.attachments-viewer .media-viewer-swiper ks-swiper-container .swiper-container .swiper-wrapper .media-viewer-swiper-item .media-viewer-swiper-item-file .inner {
  padding: 10px 25px;
  border-radius: 4px;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
}
.attachments-viewer .media-viewer-swiper ks-swiper-container .swiper-container .swiper-wrapper .media-viewer-swiper-item .media-viewer-swiper-item-file .inner .file-icon {
  display: block;
  font-size: 24px;
}
.attachments-viewer .media-viewer-swiper ks-swiper-container .swiper-container .swiper-wrapper .media-viewer-swiper-item .media-viewer-swiper-item-file .inner .download {
  font-weight: 500;
}
.attachments-viewer .media-viewer-swiper ks-swiper-container .swiper-container .swiper-wrapper .media-viewer-swiper-item .media-viewer-swiper-item-file .inner .filename {
  display: block;
  position: relative;
  overflow: hidden;
  max-width: 80%;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 14px;
  margin-top: 10px;
}
.attachments-viewer .media-viewer-swiper ks-swiper-container .swiper-container .swiper-wrapper .media-viewer-swiper-item .media-viewer-swiper-item-file:after {
  position: absolute;
  background: rgba(244, 245, 246, 0.2);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  content: '';
}
.attachments-viewer .media-viewer-swiper ks-swiper-container .swiper-container .swiper-wrapper .media-viewer-swiper-item .media-viewer-swiper-item-file.file-type-unknown .file-icon rl-icon,
.attachments-viewer .media-viewer-swiper ks-swiper-container .swiper-container .swiper-wrapper .media-viewer-swiper-item .media-viewer-swiper-item-file.file-type-unknown .time-left {
  color: #bfc3c8;
}
.attachments-viewer .media-viewer-swiper ks-swiper-container .swiper-container .swiper-wrapper .media-viewer-swiper-item .media-viewer-swiper-item-file.file-type-video:after {
  background: rgba(var(--alert-color-rgb), .15);
}
.attachments-viewer .media-viewer-swiper ks-swiper-container .swiper-container .swiper-wrapper .media-viewer-swiper-item .media-viewer-swiper-item-file.file-type-video .file-icon rl-icon,
.attachments-viewer .media-viewer-swiper ks-swiper-container .swiper-container .swiper-wrapper .media-viewer-swiper-item .media-viewer-swiper-item-file.file-type-video .time-left {
  color: var(--alert-color);
}
.attachments-viewer .media-viewer-swiper ks-swiper-container .swiper-container .swiper-wrapper .media-viewer-swiper-item .media-viewer-swiper-item-file.file-type-image:after {
  background: rgba(244, 245, 246, 0.8);
}
.attachments-viewer .media-viewer-swiper ks-swiper-container .swiper-container .swiper-wrapper .media-viewer-swiper-item .media-viewer-swiper-item-file.file-type-audio:after {
  background-color: rgba(125, 74, 141, 0.15);
  background-image: url('/Content/Graphics/Upload/audio.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.attachments-viewer .media-viewer-swiper ks-swiper-container .swiper-container .swiper-wrapper .media-viewer-swiper-item .media-viewer-swiper-item-file.file-type-audio .file-icon rl-icon,
.attachments-viewer .media-viewer-swiper ks-swiper-container .swiper-container .swiper-wrapper .media-viewer-swiper-item .media-viewer-swiper-item-file.file-type-audio .time-left {
  color: #7D548D;
}
.attachments-viewer .media-viewer-swiper ks-swiper-container .swiper-container .swiper-wrapper .media-viewer-swiper-item .media-viewer-swiper-item-file.file-type-doc:after {
  background: rgba(var(--highlight-color-rgb), .15);
}
.attachments-viewer .media-viewer-swiper ks-swiper-container .swiper-container .swiper-wrapper .media-viewer-swiper-item .media-viewer-swiper-item-file.file-type-doc .file-icon rl-icon,
.attachments-viewer .media-viewer-swiper ks-swiper-container .swiper-container .swiper-wrapper .media-viewer-swiper-item .media-viewer-swiper-item-file.file-type-doc .time-left {
  color: var(--highlight-color);
}
.attachments-viewer .media-viewer-swiper ks-swiper-container .swiper-container .swiper-wrapper .media-viewer-swiper-item .media-viewer-swiper-item-file.file-type-excel:after {
  background: rgba(var(--confirmation-color-rgb), .15);
}
.attachments-viewer .media-viewer-swiper ks-swiper-container .swiper-container .swiper-wrapper .media-viewer-swiper-item .media-viewer-swiper-item-file.file-type-excel .file-icon rl-icon,
.attachments-viewer .media-viewer-swiper ks-swiper-container .swiper-container .swiper-wrapper .media-viewer-swiper-item .media-viewer-swiper-item-file.file-type-excel .time-left {
  color: var(--confirmation-color);
}
.attachments-viewer .media-viewer-swiper ks-swiper-container .swiper-container .swiper-wrapper .media-viewer-swiper-item .media-viewer-swiper-item-file.file-type-pdf:after,
.attachments-viewer .media-viewer-swiper ks-swiper-container .swiper-container .swiper-wrapper .media-viewer-swiper-item .media-viewer-swiper-item-file.file-type-powerpoint:after {
  background: rgba(var(--warning-color-rgb), .15);
}
.attachments-viewer .media-viewer-swiper ks-swiper-container .swiper-container .swiper-wrapper .media-viewer-swiper-item .media-viewer-swiper-item-file.file-type-pdf .file-icon rl-icon,
.attachments-viewer .media-viewer-swiper ks-swiper-container .swiper-container .swiper-wrapper .media-viewer-swiper-item .media-viewer-swiper-item-file.file-type-powerpoint .file-icon rl-icon,
.attachments-viewer .media-viewer-swiper ks-swiper-container .swiper-container .swiper-wrapper .media-viewer-swiper-item .media-viewer-swiper-item-file.file-type-pdf .time-left,
.attachments-viewer .media-viewer-swiper ks-swiper-container .swiper-container .swiper-wrapper .media-viewer-swiper-item .media-viewer-swiper-item-file.file-type-powerpoint .time-left {
  color: var(--warning-color);
}
@media only screen and (min-width: 1025px) {
  .attachments-viewer .media-viewer-swiper ks-swiper-container .swiper-container .swiper-wrapper .swiper-zoom-container > img {
    max-width: 80vw;
    max-height: 80vh;
  }
}
.attachments-viewer .media-viewer-swiper ks-swiper-container .swiper-container .swiper-wrapper .swiper-zoom-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.attachments-viewer .media-viewer-swiper ks-swiper-container .swiper-container .swiper-wrapper .swiper-zoom-wrapper .swiper-zoom-container {
  position: relative;
  margin: 15px;
  height: auto;
  width: auto;
}
.attachments-viewer .media-viewer-swiper ks-swiper-container .swiper-container .swiper-wrapper .swiper-zoom-wrapper .swiper-zoom-container img {
  width: 100%;
  max-width: 100%;
  max-height: calc(100vh - 200px);
  -o-object-fit: contain;
  object-fit: contain;
  border-radius: var(--general-border-radius);
}
.attachments-viewer .media-viewer-swiper ks-swiper-container .swiper-container .swiper-wrapper .swiper-zoom-wrapper .swiper-zoom-container .spinner + img + button {
  display: none;
}
@media only screen and (max-width: 1024px) {
  .attachments-viewer .media-viewer-swiper ks-swiper-container .swiper-container .swiper-wrapper .swiper-slide-zoomed .swiper-zoom-container > img {
    max-width: 150%;
    max-height: 150%;
    margin: 0;
  }
}
.attachments-viewer .media-viewer-swiper ks-swiper-container .swiper-container .swiper-wrapper .swiper-slide-zoomed .swiper-zoom-container .delete-media {
  display: none;
}
.attachments-viewer .media-viewer-swiper ks-swiper-container .swiper-container .swiper-wrapper .swiper-slide-zoomed .editable-controls {
  display: none;
}
.attachments-viewer .media-viewer-swiper ks-swiper-container .swiper-pagination-bullet {
  background: #fff;
}
.attachments-viewer .media-viewer-swiper ks-swiper-container .swiper-pagination-bullet:only-child {
  display: none;
}
@media only screen and (min-width: 1025px) {
  .attachments-viewer .media-viewer-swiper ks-swiper-container .swiper-pagination-bullet {
    width: 60px;
    height: 60px;
    border-radius: 0;
    border: none;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
}
.attachments-viewer .media-viewer-swiper ks-swiper-container .swiper-pagination-bullet-active {
  background: var(--highlight-color);
}
.attachments-viewer .media-viewer-swiper .spinner {
  position: absolute;
  border-color: #fff;
}
.scormcloud-course-list {
  padding: 10px;
}
.scormcloud-course-item {
  box-sizing: border-box;
  width: 100%;
  max-width: 500px;
  overflow: hidden;
  margin: 10px auto;
  padding: 10px;
  border-radius: var(--training-border-radius);
  background-color: var(--background-highlight-color);
  cursor: pointer;
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(39%, rgba(var(--general-text-color-rgb), 0)), to(rgba(var(--confirmation-color-rgb), 0.1)));;
  background-image: -webkit-linear-gradient(top, rgba(var(--general-text-color-rgb), 0) 39%, rgba(var(--confirmation-color-rgb), 0.1));;
  background-image: -o-linear-gradient(top, rgba(var(--general-text-color-rgb), 0) 39%, rgba(var(--confirmation-color-rgb), 0.1));;
  background-image: linear-gradient(to bottom, rgba(var(--general-text-color-rgb), 0) 39%, rgba(var(--confirmation-color-rgb), 0.1));;
}
.scormcloud-course-item:first-of-type {
  margin-top: 0;
}
.scormcloud-course-item.locked .top h3 {
  opacity: 0.4;
}
.scormcloud-course-item.locked .image-inner {
  opacity: 0.4;
}
.scormcloud-course-item.passed {
  background-image: linear-gradient(to bottom, rgba(var(--general-text-color-rgb), 0) 39%, rgba(var(--confirmation-color-rgb), 0.1));;
}
.scormcloud-course-item.passed .status {
  text-transform: var(--confirmation-color);
}
.scormcloud-course-item.completed {
  background-image: linear-gradient(to bottom, rgba(var(--general-text-color-rgb), 0) 39%, rgba(var(--alert-color-rgb), 0.1));;
}
.scormcloud-course-item .activity,
.scormcloud-course-item .redo {
  color: rgba(var(--general-text-color-rgb), 0.4);
}
.scormcloud-course-item .top {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.scormcloud-course-item .top h3 {
  margin: 0;
  font-size: 15px;
  font-weight: 600;
}
.scormcloud-course-item .top .duration > span {
  white-space: nowrap;
  padding-right: 20px;
  font-size: 13px;
}
.scormcloud-course-item .top .duration > span rl-icon {
  font-size: 13px;
}
.scormcloud-course-item .top .image {
  position: relative;
  min-width: 80px;
  height: 80px;
  margin-right: 10px;
  border-radius: 50%;
  background-color: var(--general-background-color);
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.25);
}
.scormcloud-course-item .top .image .image-inner {
  overflow: hidden;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center center;
  text-align: center;
  background-color: var(--general-text-color);
  color: rgba(var(--general-background-color-rgb), 0.4);
}
.scormcloud-course-item .top .image .image-inner rl-icon {
  display: block;
  font-size: 34px;
  line-height: 80px;
}
.scormcloud-course-item .top .image state-icon {
  position: absolute;
  bottom: 0;
  right: 0;
}
.scormcloud-course-item .status {
  margin: 0;
  font-size: 13px;
  font-weight: 600;
}
native-upgrade {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  flex-direction: column;
  padding: 25px;
  background-color: var(--general-background-color);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
native-upgrade .native-upgrade-inner {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 25px 15px;
  background: var(--general-background-color);
  color: var(--general-text-color);
  box-shadow: 0 2px 15px 0 rgba(var(--general-text-color-rgb), .1);
  text-decoration: none;
}
native-upgrade .native-upgrade-inner h2 {
  margin: 0;
  padding: 0;
}
native-upgrade .native-upgrade-inner p {
  margin: 15px 0 0 0;
  padding: 0;
}
native-upgrade .native-upgrade-inner .btn {
  display: block;
  margin: 15px 0 0 0;
}
simple-user-list ul {
  overflow-y: auto;
  height: 100%;
  padding: 0;
  margin: 0;
}
simple-user-list ul li {
  display: flex;
  padding: 5px 0;
}
simple-user-list ul .img-wrapper {
  position: relative;
  margin-right: 5px;
}
simple-user-list ul .img-wrapper .blocked-user-icon {
  width: 34px;
  height: 34px;
  margin-right: 6px;
}
simple-user-list ul .img-wrapper rl-icon {
  position: absolute;
  bottom: 0;
  right: 0;
  color: var(--white-color);
  box-shadow: 0 2px 6px 0 rgba(var(--general-text-color-rgb), 0.2);
}
simple-user-list ul .img-wrapper rl-icon[icon=save] {
  background-color: var(--confirmation-color);
}
simple-user-list ul .img-wrapper rl-icon[icon=help] {
  background-color: var(--alert-color);
}
simple-user-list ul .img-wrapper rl-icon[icon=help].pending {
  background-color: rgba(var(--general-text-color-rgb), 0.5);
  backdrop-filter: blur(10px);
}
simple-user-list ul .img-wrapper rl-icon[icon=close] {
  background-color: var(--warning-color);
}
simple-user-list ul .select-user {
  flex: 1;
  text-align: end;
  padding-right: 10px;
}
simple-user-list ul .select-user-checkbox {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
simple-user-list ul .select-user-checkbox + label {
  display: inline-flex;
  align-items: center;
  user-select: none;
}
simple-user-list ul .select-user-checkbox + label::before {
  content: '';
  display: inline-block;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  flex-grow: 0;
  border: 1px solid var(--general-border-color);
  border-radius: 50%;
  margin-right: 50%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
  background-color: var(--general-background-color);
  cursor: pointer;
}
simple-user-list ul .select-user-checkbox:checked + label::before {
  border-color: var(--highlight-color);
  background-color: var(--highlight-color);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}
simple-user-list ul .select-user-checkbox:disabled + label::before {
  opacity: 0.5;
}
simple-user-list ul .profile-image {
  width: 34px;
  height: 34px;
  padding: 0;
}
simple-user-list ul .user-data {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
simple-user-list ul .user-data div {
  display: flex;
}
simple-user-list ul .user-data h3,
simple-user-list ul .user-data p {
  margin: 0;
}
simple-user-list ul .user-data h3 {
  font-size: 15px;
  text-overflow: ellipsis;
  overflow: hidden;
}
simple-user-list ul .user-data p {
  margin-right: 5px;
  font-size: 13px;
  opacity: 0.7;
}
scroll-shadow {
  flex: 1;
  position: relative;
}
scroll-shadow .gradient {
  content: "";
  position: absolute;
  box-sizing: border-box;
  z-index: 99;
  pointer-events: none;
}
scroll-shadow[direction="vertical"] .gradient {
  height: 40px;
  width: 100%;
  left: 0;
}
scroll-shadow[direction="vertical"] .gradient.first {
  top: 0;
  background-image: linear-gradient(to top, rgba(255, 255, 255, 0), var(--general-background-color));
}
scroll-shadow[direction="vertical"] .gradient.last {
  bottom: 0;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), var(--general-background-color));
}
scroll-shadow[direction="horizontal"] .gradient {
  height: 100%;
  width: 40px;
  top: 0;
}
scroll-shadow[direction="horizontal"] .gradient.first {
  left: 0;
  background-image: linear-gradient(to left, rgba(255, 255, 255, 0), var(--general-background-color));
}
scroll-shadow[direction="horizontal"] .gradient.last {
  right: 0;
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0), var(--general-background-color));
}
set-reminder-popup .popup-wrapper .popup-content-inner {
  display: flex !important;
}
set-reminder-popup .popup-wrapper .popup-content {
  top: 50%;
  left: 50%;
  margin-top: -140px;
  margin-left: -172.5px;
  height: 280px;
  max-width: 345px;
}
@media only screen and (min-width: 1025px) {
  set-reminder-popup .popup-wrapper .popup-content {
    margin-left: calc(140px - 172.5px);
  }
}
set-reminder-popup .popup-wrapper section {
  display: flex;
  flex: 1;
  padding: 15px;
  justify-content: center;
  align-items: center;
}
set-reminder-popup .popup-wrapper section .date-wrapper {
  position: relative;
  width: 100%;
}
set-reminder-popup .popup-wrapper section .date-wrapper input {
  width: 100%;
  height: 50px;
  padding: 5px 5px 5px 50px;
  box-sizing: border-box;
  font-size: 15px;
  border: 1px solid var(--general-border-color);
  color: var(--general-text-color);
  -webkit-appearance: none;
}
set-reminder-popup .popup-wrapper section .date-wrapper rl-icon {
  position: absolute;
  height: 100%;
  box-sizing: border-box;
  top: 0;
  left: 0;
  padding: 10px;
}
set-reminder-popup rl-button {
  display: block;
  padding: 0 15px 15px 15px;
}
reminders {
  display: block;
  padding-top: 15px;
}
reminders .day {
  padding-bottom: 20px;
}
reminders .day h3 {
  margin: 0 15px;
}
reminders .reminder-wrapper {
  display: flex;
  padding: 15px 0;
}
@media only screen and (min-width: 1025px) {
  reminders .reminder-wrapper {
    align-items: center;
  }
}
reminders .reminder {
  display: flex;
  flex: 1;
  align-items: center;
}
reminders .reminder-image-container {
  position: relative;
  height: 56px;
  width: 56px;
  margin: 0 15px;
}
reminders .reminder-image-container .avatar svg {
  stroke-width: 0;
  border-radius: 50%;
}
reminders .reminder-image-container .avatar path {
  color: #8893a3;
}
reminders .reminder-image-container .media-thumbnail,
reminders .reminder-image-container .image {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
reminders .reminder-image-container rl-icon {
  position: absolute;
  right: -2px;
  bottom: -6px;
  width: 27px;
  height: 27px;
  font-size: 14px;
  color: var(--white-color);
}
reminders .reminder-image-container rl-icon[icon='content'] {
  background: var(--highlight-color);
}
reminders .reminder-data {
  display: flex;
  flex: 1;
  flex-direction: column;
}
reminders .reminder-data p {
  margin: 0;
  word-break: break-word;
}
reminders .reminder-data p:first-of-type {
  margin-bottom: 5px;
}
reminders .options {
  padding: 0 15px;
}
reminders .options rl-icon {
  color: var(--general-text-color);
}
date-field {
  display: flex;
}
date-field input.datetime {
  border-right-width: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
date-field input.time {
  max-width: 150px;
  border-left-width: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
date-field input.rl-input {
  position: relative;
  -webkit-appearance: none;
  color: var(--background-highlight-color);
}
date-field input.rl-input:before {
  font-size: 16px;
  color: var(--general-text-color);
  content: attr(placeholder);
  position: absolute;
  top: 12px;
}
date-field input.rl-input:focus,
date-field input.rl-input.ng-not-empty {
  color: var(--general-text-color);
}
date-field input.rl-input:focus:before,
date-field input.rl-input.ng-not-empty:before {
  display: none;
}
language-selector,
language-selector-popup .selected-languages-wrapper {
  display: flex;
  align-items: center;
}
language-selector scroll-shadow,
language-selector-popup .selected-languages-wrapper scroll-shadow {
  flex: 1;
  overflow: hidden;
}
language-selector .selected-languages,
language-selector-popup .selected-languages-wrapper .selected-languages {
  display: flex;
  overflow-y: auto;
  padding: 10px 0;
  margin: 0 10px;
}
language-selector .selected-languages ul,
language-selector-popup .selected-languages-wrapper .selected-languages ul {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}
language-selector .selected-languages ul li:not(:first-of-type),
language-selector-popup .selected-languages-wrapper .selected-languages ul li:not(:first-of-type) {
  margin-left: 15px;
}
language-selector .language-image,
language-selector-popup .language-image {
  width: 30px;
  height: 30px;
}
language-selector {
  background: var(--general-background-color);
}
language-selector .selected-title {
  padding-left: 10px;
  color: var(--general-text-color);
}
language-selector .selected-languages li:not(.active) {
  opacity: 0.4;
}
language-selector rl-icon.circle {
  margin-left: 15px;
  min-width: 30px;
  width: 30px;
  height: 30px;
  background: var(--general-background-color);
  box-shadow: 0 2px rgba(0, 0, 0, 0.05);
}
language-selector-popup .popup-wrapper .popup-content-inner {
  display: block;
}
.translation-btn[disabled] rl-icon {
  color: rgba(var(--general-text-color-rgb), 0.5);
}
.translation-btn rl-icon {
  color: var(--highlight-color);
}
language-selector-popup rl-search {
  margin-bottom: 10px;
}
language-selector-popup .selected-languages-wrapper {
  margin: 0 -15px;
  padding-left: 15px;
}
language-selector-popup .selected-languages ul li {
  position: relative;
}
language-selector-popup .selected-languages ul li rl-icon {
  position: absolute;
  top: -8px;
  right: -8px;
  background: rgba(var(--background-highlight-color-rgb), 0.6);
  backdrop-filter: blur(27.1828px);
}
language-selector-popup .language-list {
  margin: 0 -15px;
}
language-selector-popup .language-list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  padding: 0 15px;
  border-bottom: 1px solid rgba(var(--general-text-color-rgb), 0.1);
}
language-selector-popup .language-list-item:first-of-type {
  border-top: 1px solid rgba(var(--general-text-color-rgb), 0.1);
}
language-selector-popup .language-list-item.user-language rl-icon {
  opacity: 0.5;
  background: var(--general-text-color);
}
language-selector-popup .language-list-item .left {
  display: flex;
  align-items: center;
}
language-selector-popup .language-list-item .language-image {
  margin-right: 10px;
}
language-selector-popup .language-list-item rl-icon {
  width: 17px;
  height: 17px;
  font-size: 9px;
  background: var(--highlight-color);
  color: var(--white-color);
}
simple-language-selector button {
  display: flex;
  align-items: center;
  padding: 0;
}
simple-language-selector button .language-image {
  width: 24px;
  height: 24px;
  filter: drop-shadow(0px 6px 15px rgba(0, 0, 0, 0.15));
}
simple-language-selector button rl-icon {
  font-size: 16px;
  margin-left: 3px;
  color: rgba(var(--general-text-color-rgb), 0.5);
}
my-events {
  display: flex;
  flex-direction: column;
  height: 100%;
}
@media only screen and (max-width: 1024px) {
  my-events {
    padding: 0 15px;
  }
}
@media only screen and (min-width: 1025px) {
  my-events {
    height: calc(100% - 75px);
    overflow-y: auto;
  }
}
my-events .no-future-events {
  display: flex;
  flex-direction: column;
  height: 100%;
}
my-events h4 {
  font-weight: 700;
  margin: 25px 0 10px 0;
}
my-events .lazy-wrapper {
  flex: 1;
  display: block;
  overflow: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
my-events .copy-feed {
  color: var(--general-text-inactive-color);
  font-size: 15px;
}
my-events #feed-url {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}
my-events > .today-only {
  height: 100%;
  display: flex;
  flex-direction: column;
}
@media only screen and (max-width: 1024px) {
  my-events .today-events:not(.today-only) {
    overflow: visible;
    overflow-x: auto;
  }
  my-events .today-events:not(.today-only) .events-scrolled-wrapper {
    flex-wrap: nowrap;
  }
  my-events .today-events.today-only {
    height: 100%;
    padding-bottom: 15px;
  }
}
my-events .future {
  display: flex;
  flex-direction: column;
  padding-bottom: 15px;
}
@media only screen and (max-width: 1024px) {
  my-events .future {
    overflow: hidden;
  }
}
calendar-view {
  height: 100%;
  display: flex;
  flex-direction: column;
}
@media only screen and (min-width: 1025px) {
  calendar-view {
    height: calc(100% - 75px);
  }
}
calendar-view months-view {
  height: 310px;
}
calendar-view .events-scrolled-wrapper {
  padding: 15px;
}
@media only screen and (min-width: 1025px) {
  calendar-view .events-scrolled-wrapper {
    padding: 15px 0;
  }
}
explore-view {
  display: block;
  overflow-y: auto;
  height: 100%;
  width: 100%;
}
@media only screen and (min-width: 1025px) {
  explore-view {
    height: calc(100% - 75px);
  }
}
explore-view .lazy-wrapper {
  display: block;
  -webkit-overflow-scrolling: touch;
}
explore-view .loading-placeholder {
  width: 100%;
  padding: 15px;
}
@media only screen and (min-width: 1025px) {
  explore-view .loading-placeholder {
    padding: 15px 0;
  }
}
explore-view .events-scrolled-wrapper {
  padding: 15px;
}
@media only screen and (min-width: 1025px) {
  explore-view .events-scrolled-wrapper {
    padding: 15px 0;
  }
}
search-events {
  height: 100%;
  display: flex;
  flex-direction: column;
}
@media only screen and (min-width: 1025px) {
  search-events {
    height: calc(100% - 75px);
  }
}
search-events header {
  position: relative;
  padding: 10px 0;
}
search-events .search-events-container {
  display: block;
  overflow-y: auto;
}
search-events .search-events-container events {
  padding: 15px;
}
@media only screen and (min-width: 1025px) {
  search-events .search-events-container events {
    padding: 15px 0;
  }
}
search-panel {
  display: flex;
  padding: 15px 0;
}
search-panel rl-search {
  flex: 1;
}
search-panel .filter-button {
  position: relative;
}
search-panel .filter-button rl-badge {
  position: absolute;
  top: 0;
  right: 0;
}
.create-update-event .scroll-container {
  position: relative;
}
.create-update-event .popup-content {
  background: var(--background-highlight-color);
}
.create-update-event .assignees-container {
  padding-left: var(--m-spacing);
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}
.create-update-event form {
  position: relative;
  padding-bottom: 75px;
}
.create-update-event form > header-popup {
  z-index: 1;
  position: absolute;
  background: transparent;
  top: 0;
  box-sizing: border-box;
  height: auto;
  width: 100%;
  padding: 15px 15px 0 15px;
}
.create-update-event form > header-popup .buttons {
  min-width: auto;
}
.create-update-event form > header-popup .title {
  display: none;
}
.create-update-event form > header-popup .right button[disabled] {
  opacity: 1;
}
.create-update-event form > header-popup .right button[disabled] rl-icon {
  color: var(--general-text-color);
}
.create-update-event form > header-popup .right button rl-icon {
  color: var(--highlight-color);
}
.create-update-event form > header-popup button {
  width: auto;
  height: auto;
  background: transparent;
  min-width: auto;
}
.create-update-event form > header-popup button rl-icon {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  color: var(--general-text-color);
}
.create-update-event form > header-popup.on-bg button rl-icon {
  background: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(24px);
  filter: drop-shadow(0px 6px 15px rgba(0, 0, 0, 0.15));
}
.create-update-event form.disable-overflow {
  overflow-y: visible;
}
.create-update-event .description-wrapper {
  display: flex;
  flex-direction: column;
}
.create-update-event .image-uploader {
  position: relative;
  height: 180px;
}
.create-update-event .image-uploader.have-bg .title-wrapper {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3) 100%);
}
.create-update-event .image-uploader.have-bg .title-wrapper input {
  color: var(--white-color);
}
.create-update-event .image-uploader.have-bg .title-wrapper input::placeholder {
  color: rgba(var(--white-color-rgb), 0.6);
}
.create-update-event .image-uploader .media-thumbnail {
  height: 100%;
}
.create-update-event .image-uploader .media-thumbnail .image {
  width: 100%;
  height: 100%;
}
.create-update-event .image-uploader .title-wrapper {
  position: absolute;
  bottom: 0;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  width: 100%;
  padding: 15px;
}
.create-update-event .image-uploader .title-wrapper input {
  height: auto;
  padding: 0 20px 0 0;
  background: transparent;
  color: var(--general-text-color);
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
  text-transform: uppercase;
}
.create-update-event .image-uploader .title-wrapper rl-icon {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  font-size: 16px;
  color: var(--general-text-color);
  background: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(24px);
  filter: drop-shadow(0px 6px 15px rgba(0, 0, 0, 0.15));
}
.create-update-event .image-uploader .title-wrapper .delete-file {
  padding: 0;
}
.create-update-event rl-rich-textarea .fr-toolbar {
  border-bottom: 0;
}
.create-update-event language-selector {
  width: 100%;
  border-bottom: 1px solid var(--general-border-color);
}
.create-update-event .eventType {
  padding: 10px;
}
.create-update-event .reminder .rl-input {
  background: var(--general-background-color);
  align-items: center;
}
.create-update-event .reminder .rl-input input {
  width: 80px;
  text-align: center;
  padding-right: 0;
}
.create-update-event .reminder .rl-input select {
  flex: 1;
  max-width: 80px;
}
.create-update-event .reminder .rl-input > span {
  display: block;
  flex: 1;
}
.create-update-event .reminder .hint {
  padding: 10px;
}
.create-update-event .warning {
  padding: 6px 10px;
}
.create-update-event invite-individuals {
  width: 100%;
}
.create-update-event invite-individuals > button {
  padding: 0 10px 0 40px;
  height: 50px;
  background: var(--general-background-color);
}
.create-update-event select-category {
  padding-left: 40px;
  background: var(--general-background-color);
}
.create-update-event select-category .click-area {
  height: 50px;
  background: var(--general-background-color);
  justify-content: space-between;
  color: rgba(var(--general-text-color-rgb), 0.6);
}
.create-update-event select-category .selected-category {
  margin: 0;
}
.create-update-event select-category .category-selector {
  top: 0 !important;
  left: 0 !important;
  width: 100%;
  height: 100%;
}
.create-update-event .attachments-wrapper {
  padding: var(--s-spacing);
  padding-bottom: calc(2*var(--s-spacing));
  background: var(--general-background-color);
}
.create-update-event .attachments-wrapper .attachments-label {
  margin-bottom: 10px;
}
.create-update-event .delete-event {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
  margin-top: 20px;
  font-size: 17px;
  color: #eb6262;
  background: #fbe0e0;
  border: none;
}
.create-update-event .delete-event icon {
  margin-right: 10px;
  height: 18px;
  width: 16px;
}
.create-update-event rl-button {
  display: block;
}
.create-update-event .translation-btn {
  display: block;
  margin: 0 0 0 auto;
}
.create-update-event .popup-sticky-bottom {
  z-index: 9;
}
invite-individuals {
  display: block;
}
invite-individuals .open-btn {
  display: flex;
  align-items: center;
  width: 100%;
  text-align: left;
  padding: 10px 0;
  margin: 0;
  border: none;
  -webkit-appearance: none;
  position: relative;
  overflow: hidden;
}
invite-individuals .open-btn:active {
  background: rgba(var(--background-highlight-color-rgb), 0.1);
}
invite-individuals .open-btn .no-invites {
  font-size: 15px;
  line-height: 20px;
}
invite-individuals .open-btn .invites {
  display: flex;
  margin-right: 10px;
}
invite-individuals .open-btn .invites .user-img {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  color: var(--text-on-white-color);
  background: var(--white-color);
  box-sizing: border-box;
  border: 1px solid var(--general-background-color);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
invite-individuals .open-btn .invites .user-img:not(:last-of-type) {
  margin-right: -10px;
}
invite-individuals .open-btn .length {
  font-size: 13px;
  line-height: 16px;
  color: rgba(var(--general-text-color-rgb), 0.4);
}
.invite-individuals-popup .main {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
}
filter-popup .content {
  padding: 15px;
  flex: 1;
}
filter-popup .input-wrapper {
  position: relative;
}
filter-popup .input-wrapper > rl-icon {
  position: absolute;
  z-index: 1;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
}
filter-popup .input-wrapper input {
  padding: 10px 10px 10px 40px;
}
filter-popup .input-wrapper input[type=date] {
  display: block;
  text-align: left;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
}
filter-popup .input-wrapper input[type=date]::-webkit-date-and-time-value {
  text-align: left;
}
filter-popup select-category .click-area {
  position: relative;
  width: 100%;
  min-height: 45px;
  box-sizing: border-box;
  padding: 10px 40px 10px 40px;
  border-radius: var(--general-border-radius);
  border: 1px solid transparent;
  font-size: 15px;
  line-height: 1.3;
  color: var(--general-text-color);
  background: var(--background-highlight-color);
  background-clip: padding-box;
}
filter-popup select-category .click-area > span {
  color: var(--general-text-color);
  opacity: 0.4;
}
filter-popup select-category .click-area:hover {
  border: 1px solid var(--general-border-color);
}
filter-popup select-category .click-area:after {
  content: '';
  display: block;
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px 5px 0 5px;
  border-color: var(--general-text-inactive-color) transparent transparent transparent;
}
filter-popup select-category .category-selector {
  top: 0 !important;
  left: 0 !important;
  width: 100%;
  height: 100%;
}
filter-popup .other .rl-field-wrapper label {
  display: flex;
  align-items: center;
}
filter-popup .other .rl-field-wrapper label rl-icon {
  margin-right: 10px;
}
filter-popup footer {
  margin-top: auto;
  padding: 15px;
  display: flex;
}
filter-popup footer rl-button {
  flex: 1;
}
filter-popup footer rl-button:first-of-type {
  margin-right: 15px;
}
filter-popup footer rl-button[icon=sliders] button {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
}
filter-popup footer rl-button[icon=sliders] button rl-icon {
  margin-right: 10px;
}
events {
  flex: 1;
  display: block;
  overflow: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
events .events-scrolled-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  align-items: flex-start;
}
event {
  display: block;
  box-sizing: border-box;
  overflow: hidden;
  cursor: pointer;
  border-radius: var(--general-border-radius);
}
@media only screen and (max-width: 767px) {
  event:not(.big) {
    width: calc((100% - 15px) / 2);
  }
}
@media only screen and (min-width: 768px) {
  event:not(.big) {
    width: calc((100% - 30px) / 3);
  }
}
@media only screen and (max-width: 1024px) {
  event.big {
    min-width: 280px;
  }
}
@media only screen and (min-width: 1025px) {
  event.big {
    width: calc((100% - 30px) / 3);
  }
}
event.big .event .background {
  height: 155px;
}
event.big .event .background event-status {
  left: 10px;
  bottom: 10px;
}
event.big .event .content {
  padding: 10px;
}
event.big .event .content .period {
  opacity: 0.4;
}
event .event {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  background: var(--background-highlight-color);
  color: var(--general-text-color);
  /*        .label {
                    position: relative;
                    z-index: 2;
                    align-self: flex-start;
                    font-size: 13px;
                }*/
}
event .event.cancelled .background {
  background-color: rgba(var(--general-text-color-rgb), 0.1);
}
event .event.cancelled .background .image {
  opacity: 0.4;
}
event .event.cancelled .content h2,
event .event.cancelled .content p {
  opacity: 0.4;
}
event .event .background {
  position: relative;
  height: 95px;
  background-color: rgba(var(--general-text-color-rgb), 0.2);
}
event .event .background .image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-position: center;
  background-size: cover;
}
event .event .background .no-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0.1;
  font-size: 58px;
}
@media only screen and (min-width: 768px) {
  event .event .background {
    height: 115px;
  }
}
event .event .background .interested {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 21px;
  color: var(--white-color);
  filter: drop-shadow(0px 6px 15px rgba(0, 0, 0, 0.15));
}
event .event .background .interested.bg {
  color: rgba(var(--white-color-rgb), 0.4);
}
event .event .background .interested.active {
  color: var(--alert-color);
}
event .event .background event-status {
  position: absolute;
  left: 7px;
  bottom: 7px;
}
event .event .background .date {
  position: absolute;
  right: 10px;
  bottom: -25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 50px;
  height: 50px;
  border-radius: var(--general-border-radius);
  filter: drop-shadow(0px 6px 15px rgba(0, 0, 0, 0.15));
  background-color: var(--highlight-color);
}
event .event .background .date:after {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
  opacity: 0.4;
}
event .event .background .date p {
  margin: 0;
  color: var(--text-on-highlight-color);
}
event .event .background .date .day {
  font-weight: 700;
  font-size: 20px;
}
event .event .background .date .month {
  font-weight: 400;
  font-size: 11px;
  text-transform: uppercase;
}
event .event .content {
  display: flex;
  flex-direction: column;
  height: 75px;
  padding: 7px;
  box-sizing: border-box;
}
event .event .content > :not(:last-child) {
  margin-bottom: 4px;
}
@media only screen and (min-width: 1025px) {
  event .event .content {
    height: 95px;
    padding: 10px;
  }
  event .event .content > :not(:last-child) {
    margin-bottom: 7px;
  }
}
event .event .content h2 {
  margin: 0;
  display: flex;
}
event .event .content h2 > span {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 15px;
  line-height: 130%;
  font-weight: 600;
}
@media only screen and (min-width: 1025px) {
  event .event .content h2 > span {
    font-size: 17px;
  }
}
event .event .content h2 rl-icon {
  margin-left: 5px;
  font-size: 15px;
  opacity: 0.5;
}
event .event .content p {
  overflow: hidden;
  margin: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 13px;
  line-height: 130%;
}
@media only screen and (min-width: 1025px) {
  event .event .content p {
    font-size: 15px;
  }
}
event .event .content p.location {
  color: rgba(var(--general-text-color-rgb), 0.4);
}
no-events {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
no-events rl-icon {
  width: 98px;
  height: 98px;
  font-size: 33px;
  border-radius: 50%;
  background-color: var(--background-highlight-color);
}
event-status .status {
  padding: 3px 6px 3px 3.5px;
  border-radius: 3px;
  background: rgba(var(--white-color-rgb), 0.6);
  backdrop-filter: blur(24px);
  font-weight: 700;
  font-size: 10px;
  line-height: 130%;
}
event-status .status rl-icon.circle {
  align-self: center;
  width: 11.5px;
  height: 10.5px;
  margin-right: 4px;
  font-size: 7px;
  color: var(--white-color);
}
event-status .status rl-icon.circle.attending,
event-status .status rl-icon.circle.completed {
  background: var(--confirmation-color);
}
event-status .status rl-icon.circle.few-spots {
  background: var(--alert-color);
}
event-status .status rl-icon.circle.fully-booked,
event-status .status rl-icon.circle.cancelled {
  background: var(--warning-color);
}
event-details .popup-content {
  display: flex;
  flex-direction: column;
}
event-details header {
  z-index: 10;
  position: sticky;
  top: -55px;
  width: 100%;
  height: 200px;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--background-highlight-color);
}
event-details header.image {
  background-color: white !important;
}
event-details header.image.cancelled {
  background-color: rgba(var(--general-text-color-rgb), 0.1);
}
event-details header.image.cancelled .image {
  opacity: 0.4;
}
event-details header .image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-position: center;
}
event-details header header-popup {
  position: sticky;
  top: 0;
  background: transparent;
  box-sizing: border-box;
  height: auto;
  width: 100%;
  padding: 15px 15px 0 15px;
}
event-details header header-popup .buttons {
  min-width: auto;
}
event-details header header-popup .buttons button {
  width: auto;
  height: auto;
  background: transparent;
  min-width: auto;
}
event-details header header-popup .buttons button rl-icon {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  color: var(--general-text-color);
}
event-details header header-popup.on-bg button rl-icon {
  background: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(24px);
  filter: drop-shadow(0px 6px 15px rgba(0, 0, 0, 0.15));
}
event-details header .inner-header {
  display: flex;
  padding: 0 15px 15px 15px;
}
event-details header .inner-header .interested {
  position: absolute;
  right: 10px;
  bottom: -22px;
  border-radius: 50%;
}
event-details header .inner-header .interested button {
  width: 44px;
  height: 44px;
  min-width: 44px;
  background-color: var(--white-color);
  filter: drop-shadow(0px 6px 15px rgba(0, 0, 0, 0.15));
}
event-details header .inner-header .interested button:active,
event-details header .inner-header .interested button:hover {
  opacity: 1;
}
event-details header .inner-header .interested button rl-icon {
  font-size: 20px;
  color: var(--general-text-inactive-color);
}
event-details header .inner-header .interested.active button rl-icon {
  color: var(--alert-color);
}
event-details .event-body {
  display: flex;
  flex-direction: column;
  flex: 1;
}
event-details .event-content {
  padding: 22px 10px 20px 10px;
  flex: 1;
}
event-details .event-content h1 {
  position: relative;
  z-index: 1;
  display: flex;
  width: calc(100% - 50px);
  margin: 0;
  text-transform: uppercase;
  color: var(--general-text-color);
}
event-details .event-content h1.cancelled {
  text-decoration-line: line-through;
  opacity: 0.4;
}
event-details .event-content h1 > span {
  font-size: 20px;
  word-break: break-word;
}
event-details .event-content h1 rl-icon {
  margin-left: 5px;
  opacity: 0.5;
  font-size: 15px;
}
event-details translation-button .spinner {
  width: 10px;
  height: 10px;
  margin-left: 5px;
}
event-details .btn-create-update {
  margin-bottom: 15px;
  display: block;
}
event-details .field {
  display: flex;
  align-items: center;
  margin: 15px 0;
}
event-details .field > rl-icon:first-of-type,
event-details .field .icon-wrapper {
  margin-right: 10px;
}
event-details .period {
  margin-top: 25px;
  align-items: flex-start;
}
event-details .period > div {
  display: flex;
  align-items: center;
}
event-details .period > div p {
  text-transform: capitalize;
  margin: 0;
}
event-details .period > div p:first-of-type {
  color: rgba(var(--general-text-color-rgb), 0.4);
  font-size: 13px;
  line-height: 130%;
}
event-details .period > div p:last-of-type {
  margin-top: 5px;
  font-size: 15px;
  line-height: 19.5px;
}
event-details .period [icon="next"] {
  margin: 0 20px;
}
event-details .spots .icon-wrapper {
  position: relative;
}
event-details .spots rl-icon.circle {
  position: absolute;
  bottom: 3px;
  right: -2px;
  width: 10px;
  height: 10px;
  font-size: 6px;
  color: var(--white-color);
}
event-details .spots [icon="ticket"] {
  color: rgba(var(--general-text-color-rgb, 0.4));
}
event-details .spots [icon="exclamation"] {
  background: var(--warning-color);
}
event-details .spots [icon="save"] {
  background: var(--confirmation-color);
}
event-details .spots.attending [icon="ticket"],
event-details .spots.attending > span {
  color: var(--confirmation-color) !important;
}
event-details .spots.exclamation [icon="ticket"],
event-details .spots.exclamation > span {
  color: var(--warning-color) !important;
}
event-details .signups-wrapper {
  display: flex;
  align-items: center;
}
event-details .signups-wrapper [icon="triangle-right"] {
  margin-left: auto;
  font-size: 15px;
  color: var(--general-text-inactive-color);
}
event-details .signups-wrapper .not-answered {
  color: rgba(var(--general-text-color-rgb), 0.4);
}
event-details .signups-wrapper .signups {
  display: flex;
  align-items: center;
}
event-details .signups-wrapper .signups:disabled {
  opacity: 0.5;
}
event-details .signups-wrapper .signups div {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  color: var(--text-on-white-color);
  background: var(--white-color);
  box-sizing: border-box;
  border: 1px solid var(--white-color);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
event-details .signups-wrapper .signups div:not(:last-of-type) {
  margin-right: -10px;
}
event-details .signups-wrapper .signups span {
  padding-left: 10px;
  font-size: 13px;
  color: rgba(var(--general-text-color-rgb), 0.4);
}
event-details .category .label {
  padding: 6px;
  border-radius: var(--general-border-radius);
  font-weight: 700;
  font-size: 13px;
  line-height: 130%;
}
event-details .attachments-wrapper {
  height: 135px;
  margin-bottom: 25px;
}
event-details .attachments-wrapper .attachments-label {
  margin-bottom: 10px;
}
event-details .cancel-reason {
  margin-top: 25px;
  padding: 15px;
  border-radius: var(--general-border-radius);
  background-color: rgba(var(--warning-color-rgb), 0.2);
}
event-details .cancel-reason h4 {
  margin: 0 0 10px 0;
  color: var(--warning-color);
}
event-details .cancel-reason p {
  margin: 0;
}
event-details .summary {
  margin-top: 25px;
}
event-details .summary rl-rich-preview {
  overflow: hidden;
}
event-details footer {
  position: sticky;
  z-index: 10;
  bottom: 0;
  margin-top: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 15px;
  padding: 15px 15px calc(15px + env(safe-area-inset-bottom, 20px)) 15px;
  box-sizing: border-box;
  background: var(--background-highlight-color);
}
event-details footer .swipe {
  width: 300px;
  padding: 0;
}
event-details footer .swipe .swipe-container span {
  left: auto !important;
  width: 100% !important;
}
event-details footer .swipe .swipe-container.swiped {
  background: var(--calendar-accent-color);
}
event-details footer .swipe .swipe-container.swiped:after,
event-details footer .swipe .swipe-container.loading:after {
  transform: translate3d(266px, 0, 0);
}
event-details footer .attending {
  display: flex;
  flex-direction: column;
}
@media only screen and (min-width: 1025px) {
  event-details footer .attending {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  event-details footer .attending.no-button {
    justify-content: center;
  }
}
event-details footer .attending .select-attending-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
@media only screen and (min-width: 1025px) {
  event-details footer .attending .select-attending-wrapper {
    flex: 1;
    max-width: 300px;
    justify-content: space-between;
  }
}
event-details footer .attending .select-attending-wrapper.selected > button:not(.active) rl-icon {
  background: var(--general-text-inactive-color);
  margin: 0;
}
event-details footer .attending .select-attending-wrapper.selected > button:not(.active) > span {
  display: none;
}
event-details footer .attending .select-attending-wrapper button {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  color: var(--general-text-color);
}
event-details footer .attending .select-attending-wrapper button[disabled] {
  cursor: default;
}
event-details footer .attending .select-attending-wrapper button[disabled] rl-icon {
  background: var(--general-text-inactive-color);
}
event-details footer .attending .select-attending-wrapper button[disabled]:not(.active) rl-icon {
  margin: 0;
}
event-details footer .attending .select-attending-wrapper button[disabled]:not(.active) > span {
  display: none;
}
event-details footer .attending .select-attending-wrapper button rl-icon {
  margin-bottom: 8px;
  color: var(--white-color);
}
event-details footer .attending .select-attending-wrapper button > span {
  font-size: 13px;
  line-height: 1.3;
}
event-details footer .attending .select-attending-wrapper button.active.in-progress rl-icon {
  background: var(--general-text-inactive-color);
}
event-details footer .attending .select-attending-wrapper button.active rl-icon {
  filter: drop-shadow(0px 6px 15px rgba(0, 0, 0, 0.15));
}
event-details footer .attending .select-attending-wrapper button.active > span {
  font-weight: bold;
}
event-details footer .attending .select-attending-wrapper [icon=save] {
  background: var(--confirmation-color);
}
event-details footer .attending .select-attending-wrapper [icon=help] {
  background: var(--alert-color);
}
event-details footer .attending .select-attending-wrapper [icon=close] {
  background: var(--warning-color);
}
event-details footer .attending .show-continue-form.sign-up-enabled {
  margin-left: 15px;
}
@media "767px" {
  event-details footer .attending .show-continue-form.sign-up-enabled {
    margin-left: 0;
  }
}
@media only screen and (max-width: 1024px) {
  event-details footer .attending .show-continue-form {
    margin-top: 15px;
  }
}
@media only screen and (min-width: 1025px) {
  event-details footer .attending .show-continue-form {
    min-width: 250px;
  }
}
event-details footer .attending .show-continue-form button {
  display: flex;
  align-items: center;
  text-transform: uppercase;
}
event-details footer .attending .show-continue-form button > * {
  margin-left: auto;
}
event-details footer .attending .show-continue-form rl-icon {
  opacity: 0.6;
}
event-details footer .expired {
  margin-top: 15px;
  text-align: center;
}
@media only screen and (min-width: 1025px) {
  event-details footer .expired:not(.no-button) {
    text-align: left;
  }
}
event-details footer .sign-up {
  display: flex;
}
event-details footer .sign-up .spots {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  box-sizing: border-box;
  width: 100px;
  text-align: center;
}
event-details footer .sign-up .spots > span {
  font-size: 13px;
  line-height: 17px;
  color: rgba(var(--general-text-color-rgb, 0.4));
}
event-details footer .sign-up rl-button {
  flex: 1;
}
event-details footer .sign-up rl-button:not(:first-child) {
  margin-left: 10px;
}
event-details footer .sign-up rl-button.signed-up button > * {
  margin-left: auto;
}
event-details footer .sign-up rl-button button {
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
}
@media only screen and (max-width: 767px) {
  event-details footer .sign-up rl-button button {
    min-width: 120px;
  }
}
event-details footer .sign-up rl-button button rl-icon {
  opacity: 0.5;
}
event-details rl-loading-placeholder {
  padding: 22px 10px 20px 10px;
}
event-details .deadline {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 22px 10px 20px 10px;
}
event-details .deadline .deadline-icon {
  margin-right: 10px;
  color: var(--general-text-color);
}
add-event-update .popup-content {
  background: var(--background-highlight-color);
}
add-event-update form {
  position: relative;
  height: 100%;
  padding-bottom: 75px;
}
add-event-update form header-popup button[disabled],
add-event-update form header-popup button.inactive {
  opacity: 0.5;
}
add-event-update form language-selector {
  width: 100%;
  border-bottom: 1px solid var(--general-border-color);
}
add-event-update .event-update-content {
  position: static;
  display: flex;
  flex-direction: column;
  height: calc(100% - 55px);
}
add-event-update .event-update-content .languages {
  display: flex;
}
add-event-update .event-update-content .languages language-selector {
  flex: 1;
  overflow: hidden;
}
add-event-update .event-update-content .textarea-wrapper {
  flex: 1 1 auto;
  position: relative;
  overflow-y: auto;
  display: flex;
}
add-event-update .event-update-content .textarea-wrapper .event-update-description {
  height: 100%;
  padding: 10px;
  border: none;
  resize: none;
}
add-event-update .event-update-content .attachments-wrapper {
  padding: 10px;
  background: var(--general-background-color);
}
add-event-update .event-update-content .attachments-wrapper .attachments-label {
  padding-top: 10px;
  margin-bottom: 10px;
}
add-event-update .save button {
  color: var(--white-color);
  background: var(--button-background-color);
}
add-event-update button {
  text-transform: uppercase;
}
event-update {
  display: block;
  border-radius: var(--general-border-radius);
  background-color: rgba(var(--highlight-color-rgb), 0.2);
  padding: 15px;
  margin: 15px 10px;
}
event-update .event-update-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
event-update .event-update-top h4 {
  line-height: 130%;
  color: var(--highlight-color);
  margin: 0;
}
event-update .event-update-top .right {
  display: flex;
  align-items: center;
}
event-update .event-update-top .date {
  opacity: 0.4;
  font-size: 13px;
  line-height: 130%;
}
event-update .event-update-top .post-options {
  margin-left: 10px;
  padding: 0;
  text-align: center;
  color: var(--general-text-color);
  background: transparent;
  border: none;
}
event-update .event-update-text {
  display: block;
  padding: 5px 0 15px 0;
  margin: 0;
  overflow: hidden;
}
event-update .bold-button {
  font-weight: bold !important;
  font-size: 1rem;
}
event-update .event-update-image {
  margin: 5px -15px 0px -15px;
  position: relative;
}
event-update .event-update-image media-preview {
  min-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
event-update .event-update-image .embed-video {
  width: 100%;
  max-width: 100%;
}
event-update .event-update-image .embed-video > .embed-video-wrapper {
  position: relative;
  padding-bottom: 46%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
}
event-update .event-update-image .embed-video > .embed-video-wrapper iframe,
event-update .event-update-image .embed-video > .embed-video-wrapper object,
event-update .event-update-image .embed-video > .embed-video-wrapper embed,
event-update .event-update-image .embed-video > .embed-video-wrapper video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
@media only screen and (min-width: 1025px) {
  event-update .event-update-image .media .image img {
    max-height: 600px;
    object-fit: contain;
  }
}
cancel-event-popup .action-popup {
  width: 90%;
  max-width: 345px;
}
cancel-event-popup .action-popup .action-popup-inner {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
cancel-event-popup .content {
  padding: 0 16px;
}
cancel-event-popup h3 {
  margin: 20px 0 15px 0;
  text-align: center;
}
cancel-event-popup .comment-wrapper {
  display: flex;
  flex-direction: column;
  background: var(--background-highlight-color);
  border-radius: var(--general-border-radius);
}
cancel-event-popup .comment-wrapper textarea {
  box-sizing: border-box;
  height: 90px;
  width: 100%;
  padding: 10px;
  border: none;
  background: none;
  font-size: 15px;
}
cancel-event-popup .comment-wrapper .comment-options {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  height: 40px;
  padding: 0 10px;
}
cancel-event-popup .comment-wrapper .comment-options > span {
  font-size: 10px;
  color: rgba(var(--general-text-color-rgb), 0.4);
}
cancel-event-popup .notifications {
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
cancel-event-popup footer rl-button {
  display: block;
  border-top: 0.5px solid var(--general-border-color);
}
cancel-recurring-event-popup .action-popup {
  width: 90%;
  max-width: 270px;
}
cancel-recurring-event-popup .action-popup .action-popup-inner {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
cancel-recurring-event-popup .action-popup .content {
  padding: 16px 20px;
  text-align: center;
}
cancel-recurring-event-popup .action-popup .content h3 {
  margin: 0;
}
cancel-recurring-event-popup .action-popup .content p {
  margin: 0;
}
cancel-recurring-event-popup .action-popup rl-button {
  display: block;
  border-top: 0.5px solid var(--general-border-color);
}
attendance-overview-popup right-buttons {
  position: relative;
}
attendance-overview-popup right-buttons .tip-content {
  position: absolute;
  top: 45px;
  right: 5px;
  width: 260px;
  text-align: left;
  margin-bottom: 0;
}
attendance-overview-popup right-buttons .tip-content:after {
  left: auto;
  right: 10px;
}
attendance-overview-popup .content {
  padding: 10px 15px;
  display: flex;
  flex: 1;
  overflow-x: hidden;
  flex-direction: column;
}
attendance-overview-popup .participants-header {
  flex-direction: column;
}
attendance-overview-popup .participants-header .inner-participants-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid rgba(var(--general-text-color-rgb), 0.1);
}
attendance-overview-popup .participants-header .inner-participants-header button {
  max-height: 32px;
  line-height: 15px;
  padding-bottom: 10px;
  cursor: pointer;
}
attendance-overview-popup .participants-header rl-search {
  width: 100%;
  margin-bottom: 15px;
}
attendance-overview-popup scroll-shadow {
  overflow: hidden;
  position: relative;
}
attendance-overview-popup .tabs {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  width: 100%;
  white-space: nowrap;
  list-style: none;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
attendance-overview-popup .tabs li {
  padding: 0 0 10px;
  text-align: center;
  font-size: 14px;
  border-bottom: 2px solid transparent;
  cursor: pointer;
}
attendance-overview-popup .tabs li:not(:last-of-type) {
  margin-right: 10px;
}
attendance-overview-popup .tabs li.active {
  border-bottom: 2px solid var(--highlight-color);
  color: var(--highlight-color);
}
attendance-overview-popup .tabs li rl-icon {
  margin-right: 2px;
  color: var(--white-color);
  box-shadow: 0 2px 6px 0 rgba(var(--general-text-color-rgb), 0.2);
}
attendance-overview-popup .tabs li rl-icon[icon=save] {
  background-color: var(--confirmation-color);
}
attendance-overview-popup .tabs li rl-icon[icon=help] {
  background-color: var(--alert-color);
}
attendance-overview-popup .tabs li rl-icon[icon=help].pending {
  background-color: rgba(var(--general-text-color-rgb), 0.5);
}
attendance-overview-popup .tabs li rl-icon[icon=close] {
  background-color: var(--warning-color);
}
attendance-overview-popup .user-list-wrapper {
  flex: 1;
  display: flex;
  overflow: hidden;
  flex-direction: column;
}
attendance-overview-popup simple-user-list {
  display: block;
  height: 100%;
}
.base-view-calendar,
.base-view-eventPopup {
  background: var(--general-background-color);
}
.calendar-module {
  padding: 0;
  height: 100%;
}
scroll-calendar {
  display: flex;
  height: 100%;
  flex-direction: column;
}
scroll-calendar .search-field {
  position: absolute;
  top: -55px;
  height: 55px;
  z-index: 100;
  transform: translateX(100%) !important;
}
scroll-calendar .calendar-wrapper {
  flex: 1;
  overflow: hidden;
}
scroll-calendar .loading-placeholder {
  display: flex;
  gap: 15px;
}
scroll-calendar .loading-placeholder.big .shimmer {
  height: 230px;
  min-width: 280px;
  width: 280px;
}
scroll-calendar .loading-placeholder:not(.big) {
  flex-wrap: wrap;
}
scroll-calendar .loading-placeholder:not(.big) .shimmer {
  height: 170px;
  width: calc((100% - 15px) / 2);
}
@media only screen and (min-width: 768px) {
  scroll-calendar .loading-placeholder:not(.big) .shimmer {
    width: calc((100% - 30px) / 3);
    height: 190px;
  }
}
@media only screen and (min-width: 1025px) {
  scroll-calendar .loading-placeholder:not(.big) .shimmer {
    height: 210px;
  }
}
scroll-calendar .loading-placeholder .shimmer {
  border-radius: var(--general-border-radius);
}
scroll-calendar > footer {
  display: flex;
  height: 55px;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
scroll-calendar > footer button {
  width: 20%;
  padding: 0;
  overflow: hidden;
  border: none;
  background: none;
  color: black;
  text-align: center;
}
scroll-calendar > footer button:hover,
scroll-calendar > footer button.active {
  color: var(--calendar-accent-color);
}
scroll-calendar > footer button icon {
  height: 25px;
  width: 30px;
}
scroll-calendar > footer button span {
  display: block;
  font-size: 10px;
}
scroll-calendar > footer .add-event {
  display: flex;
  justify-content: center;
}
scroll-calendar > footer .add-event div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background: var(--calendar-accent-color);
}
scroll-calendar > footer .add-event icon {
  width: 20px;
  height: 20px;
  color: white;
}
scroll-calendar > footer .search g,
scroll-calendar > footer .search circle {
  fill: transparent;
}
scroll-calendar icon-plus button {
  background: var(--calendar-accent-color);
}
:root {
  --calendar-accent-color: var(--highlight-color);
}
months-view {
  display: flex;
  flex-direction: column;
  background: #f7f8fa;
}
months-view .calendar-header.with-weeks .titles {
  padding: 10px 10px 10px 37px;
}
months-view .calendar-header .titles {
  display: flex;
  justify-content: space-between;
  padding: 10px 15px;
  background: white;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
months-view .calendar-header .month-title {
  list-style: none;
  width: calc(100% / 7);
  text-align: center;
  font-size: 13px;
  font-weight: bold;
  text-transform: capitalize;
}
months-view .calendar-header .month-title:nth-last-of-type(-n+2) {
  color: var(--calendar-accent-color);
}
months-view .move-today {
  padding: 10px 10px 0 10px;
}
months-view .move-today button {
  position: relative;
  width: 40px;
  height: 40px;
  padding: 8px;
  margin: 0;
  border: none;
  border-radius: 100%;
  background: none;
  color: var(--calendar-accent-color);
  background: rgba(98, 192, 235, 0.2);
}
months-view .move-today button icon {
  height: 23px;
}
months-view .move-today .today-date-number {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -40%);
}
months-view .scrolled-months {
  overflow-y: scroll;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 0 20px 0 20px;
}
months-view .month {
  display: flex;
  flex-direction: column;
  padding: 15px 5px;
}
months-view .month h2 {
  align-self: flex-end;
  margin: 0 calc(100% / 7) 7px 0;
  font-size: 16px;
  font-weight: 500;
  color: var(--calendar-accent-color);
}
months-view .week {
  display: flex;
}
months-view .week:not(:last-of-type) .days {
  justify-content: flex-end;
}
months-view .week > span {
  text-align: center;
}
months-view .week .day.weekend {
  color: rgba(var(--general-text-color-rgb), .5);
}
months-view .week-number {
  line-height: 25px;
  opacity: 0.5;
  padding: 0 5px;
  font-size: 11px;
  width: 16px;
}
months-view .days {
  display: flex;
  width: 100%;
}
months-view .day {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: calc(100% / 7);
  font-weight: 600;
}
months-view .day .events {
  padding: 2px 0 5px;
  line-height: 0;
  min-height: 5px;
}
months-view .day .day-number {
  width: 28px;
  height: 28px;
  line-height: 28px;
  text-align: center;
  cursor: pointer;
}
months-view .day .day-dot {
  height: 4px;
  width: 4px;
  margin: 0 1px;
  display: inline-block;
  vertical-align: top;
  opacity: 0.6;
  background: var(--general-text-color);
  border-radius: 100%;
}
months-view .day.current-day .day-number {
  color: var(--calendar-accent-color);
}
months-view .day.selected .day-number {
  z-index: 2;
  color: var(--text-on-highlight-color);
  background-color: var(--calendar-accent-color) !important;
  border-radius: 100%;
  box-shadow: rgba(150, 150, 150, 0.7) 0 0 14px;
}
months-view .day.filter-start-range::after,
months-view .day.filter-end-range::after,
months-view .day.filter-start-range::before,
months-view .day.filter-end-range::before,
months-view .day.filter-range::before,
months-view .day.filter-period::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 28px;
  background-color: rgba(var(--highlight-color-rgb), 0.3);
  z-index: 0;
}
months-view .day.filter-period {
  position: relative;
}
months-view .day.filter-start-range,
months-view .day.filter-end-range {
  position: relative;
}
months-view .day.filter-start-range .day-number,
months-view .day.filter-end-range .day-number {
  z-index: 2;
  color: var(--text-on-highlight-color);
  background-color: rgba(var(--highlight-color-rgb), 0.8);
  border-radius: 100%;
}
months-view .day.filter-start-range::after,
months-view .day.filter-end-range::after {
  background-color: var(--text-on-highlight-color);
  border-radius: 100%;
  z-index: 1;
  width: 28px;
  left: auto;
  right: auto;
}
months-view .day.filter-start-range::before {
  left: 50%;
}
months-view .day.filter-end-range::before {
  right: 50%;
}
file-preview {
  display: block;
}
file-preview .background {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
}
file-preview .background.no-preview {
  background: var(--background-highlight-color);
}
file-preview .background:not(.image):after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.1);
}
file-preview.small .file-icon {
  font-size: 35px;
}
file-preview.small .file-icon[icon="play"] {
  width: 50px;
  height: 50px;
  font-size: 25px;
}
file-preview.small .download-icon {
  right: 5px;
  width: 16px;
  height: 16px;
  font-size: 10px;
}
file-preview.small .text {
  margin-top: 5px;
  font-size: 10px;
}
file-preview .file {
  z-index: 2;
  position: relative;
}
file-preview .file icon {
  position: absolute;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--background-highlight-color);
  filter: brightness(90%);
}
file-preview .file icon ng-include {
  display: block;
  font-size: 0;
}
file-preview .file-icon {
  font-size: 78px;
  color: var(--white-color);
}
file-preview .file-icon[icon="play"] {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  font-size: 39px;
  color: var(--general-text-color);
  background: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(24px);
}
file-preview .file-icon[icon="play"] span {
  margin-left: 5px;
  opacity: 0.7;
}
file-preview .download-icon {
  position: absolute;
  right: 15px;
  bottom: -3px;
  font-size: 17px;
  background: var(--highlight-color);
  color: var(--text-on-highlight-color);
  filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.25));
}
file-preview .text {
  z-index: 2;
  position: relative;
  margin-top: 10px;
  display: block;
  max-width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 4px 7px;
  border-radius: 100px;
  color: var(--white-color);
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px);
}
file-preview .text.count {
  text-transform: lowercase;
}
media-preview {
  --media-preview-item-height: 200px;
  display: block;
  position: relative;
}
media-preview .media-preview-file {
  height: var(--media-preview-item-height);
  text-align: center;
  position: relative;
  text-decoration: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
media-preview media.no-play {
  pointer-events: none;
}
media-preview .multiple .media-preview-list {
  position: relative;
}
media-preview .multiple .media-preview-list:after {
  display: table;
  clear: both;
  content: '';
}
media-preview .multiple .media-preview-list .media-preview-list-col {
  display: block;
  width: 50%;
  float: left;
  height: var(--media-preview-item-height);
  overflow: hidden;
  box-sizing: border-box;
  padding-right: 3px;
}
media-preview .multiple .media-preview-list .media-preview-list-col:last-child {
  padding-right: 0;
}
media-preview .multiple .media-preview-list .media-preview-list-col .media-preview-list-item {
  display: block;
  height: var(--media-preview-item-height);
  width: 100%;
  position: relative;
  text-align: center;
}
media-preview .multiple .media-preview-list .media-preview-list-col .media-preview-list-item.small {
  height: calc(var(--media-preview-item-height) / 2);
}
media-preview .multiple .media-preview-list .media-preview-list-col .media-preview-list-item.small icon {
  width: 40%;
}
media-preview .multiple .media-preview-list .media-preview-list-col .media-preview-list-item.small:last-child {
  top: 3px;
  height: calc(var(--media-preview-item-height) / 2 - 3px);
}
media-preview .multiple .media-preview-list .media-preview-list-col .media-preview-list-item .media-preview-zoom {
  position: absolute;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: var(--white-color);
  background: rgba(0, 0, 0, 0.25);
}
media-preview .multiple .media-preview-list .media-preview-list-col .media-preview-list-item .media-preview-zoom rl-icon {
  font-size: 30px;
}
media-preview file-preview {
  width: 100%;
  height: 100%;
}
user-portraits {
  display: flex;
}
user-portraits div {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  color: var(--text-on-white-color);
  background: var(--white-color);
  box-sizing: border-box;
  box-shadow: 0 6px 7px 0 rgba(var(--general-text-color-rgb), .1);
  border: 1px solid var(--white-color);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
user-portraits div:not(:last-of-type) {
  margin-right: -10px;
}
.campaign-module {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.campaign-module campaign-module {
  display: block;
}
.campaign-list {
  list-style-type: none;
  list-style-position: inside;
  position: relative;
  margin: 0;
  padding: 15px 15px 0 15px;
}
.campaign-list .campaign-list-item {
  display: block;
  margin-bottom: 15px;
}
.campaign-list .campaign-list-item .campaign-list-item-inner {
  height: 90px;
  border-radius: var(--general-border-radius);
  background-color: #f5f5f5;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  -webkit-perspective: 900000px;
  perspective: 900000px;
}
.campaign-list .campaign-list-item .campaign-list-item-inner.has-background:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 1;
  background: rgba(0, 0, 0, 0.15);
}
.campaign-list .campaign-list-item .campaign-list-item-inner.has-background h3 {
  color: #fff;
}
.campaign-list .campaign-list-item .campaign-list-item-inner h3 {
  font-weight: bold;
  font-size: 15px;
  color: #2F2F2F;
  text-align: center;
  margin: 0;
  padding: 0 15px;
  line-height: 20px;
  position: relative;
  z-index: 2;
}
.campaign-list .campaign-list-item .campaign-list-item-inner .title-placeholder {
  width: 70%;
  height: 20px;
  display: block;
}
.campaign-itemlist {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 15px 15px 0 15px;
}
.campaign-itemlist .campaign-itemlist-item {
  width: 25%;
  height: 75px;
  box-sizing: border-box;
  padding-bottom: 15px;
  position: relative;
}
.campaign-itemlist .campaign-itemlist-item .campaign-itemlist-item-inner {
  display: block;
  height: 100%;
  width: 100%;
  border-radius: var(--general-border-radius);
  position: relative;
  transition: all 0.5s ease-in-out;
  -webkit-perspective: 900000px;
  perspective: 900000px;
}
.campaign-itemlist .campaign-itemlist-item .campaign-itemlist-item-inner.overdue .campaign-itemlist-item-inner-background {
  opacity: 0.25;
}
.campaign-itemlist .campaign-itemlist-item .campaign-itemlist-item-inner.open:not(.overdue) {
  background-color: #CECECE;
}
.campaign-itemlist .campaign-itemlist-item .campaign-itemlist-item-inner.open:not(.overdue) .campaign-itemlist-item-inner-door {
  background-color: #EBEBEB;
  -webkit-transform: skewY(3deg) scaleX(0.95);
  transform: skewY(3deg) scaleX(0.95);
}
@media all and (min-width: 400px) and (max-width: 749px) {
  .campaign-itemlist .campaign-itemlist-item .campaign-itemlist-item-inner.open:not(.overdue) .campaign-itemlist-item-inner-door {
    -webkit-transform: skewY(1.5deg) scaleX(0.97);
    transform: skewY(1.5deg) scaleX(0.97);
  }
}
@media all and (min-width: 750px) and (max-width: 1024px) {
  .campaign-itemlist .campaign-itemlist-item .campaign-itemlist-item-inner.open:not(.overdue) .campaign-itemlist-item-inner-door {
    -webkit-transform: skewY(1deg) scaleX(0.98);
    transform: skewY(1deg) scaleX(0.98);
  }
}
@media all and (min-width: 1025px) and (max-width: 1449px) {
  .campaign-itemlist .campaign-itemlist-item .campaign-itemlist-item-inner.open:not(.overdue) .campaign-itemlist-item-inner-door {
    -webkit-transform: skewY(1.5deg) scaleX(0.97);
    transform: skewY(1.5deg) scaleX(0.97);
  }
}
@media all and (min-width: 1450px) {
  .campaign-itemlist .campaign-itemlist-item .campaign-itemlist-item-inner.open:not(.overdue) .campaign-itemlist-item-inner-door {
    -webkit-transform: skewY(1deg) scaleX(0.98);
    transform: skewY(1deg) scaleX(0.98);
  }
}
.campaign-itemlist .campaign-itemlist-item .campaign-itemlist-item-inner .campaign-itemlist-item-inner-door {
  background-color: #EBEBEB;
  border-radius: var(--general-border-radius);
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  padding: 10px;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  transform-origin: left;
  transition: all 0.5s ease-in-out;
}
.campaign-itemlist .campaign-itemlist-item .campaign-itemlist-item-inner h3 {
  font-size: 15px;
  font-weight: bold;
  margin: 0;
  padding: 0;
  color: var(--general-text-color);
  position: relative;
  z-index: 2;
}
.campaign-itemlist .campaign-itemlist-item .campaign-itemlist-item-inner h4 {
  text-transform: uppercase;
  color: var(--general-text-color);
  font-size: 11px;
  font-weight: normal;
  margin: 0;
  padding: 0;
  opacity: 0.75;
  position: relative;
  z-index: 2;
}
.campaign-itemlist .campaign-itemlist-item .campaign-itemlist-item-inner rl-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 30px;
  width: 30px;
  line-height: 30px;
  text-align: center;
  margin-top: -15px;
  margin-left: -15px;
  font-size: 26px;
  opacity: 0.15;
  z-index: 2;
}
.campaign-itemlist .campaign-itemlist-item .campaign-itemlist-item-inner .title-placeholder {
  display: block;
  height: 15px;
  width: 70%;
}
.campaign-itemlist .campaign-itemlist-item .campaign-itemlist-item-inner .sort-placeholder {
  display: block;
  height: 15px;
  width: 40%;
}
.campaign-itemlist .campaign-itemlist-item .campaign-itemlist-item-inner-background {
  background-position: center center;
  background-size: cover;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 1;
}
.campaign-itemlist.campaign-view-biggrid .campaign-itemlist-item {
  width: 50%;
  height: 165px;
}
.campaign-itemlist.campaign-view-biggrid .campaign-itemlist-item:nth-child(odd) {
  padding-right: 7.5px;
}
.campaign-itemlist.campaign-view-biggrid .campaign-itemlist-item:nth-child(even) {
  padding-left: 7.5px;
}
.campaign-itemlist.campaign-view-biggrid .campaign-itemlist-item:nth-child(4n-2) {
  height: 215px;
}
.campaign-itemlist.campaign-view-biggrid .campaign-itemlist-item:nth-child(4n-2):last-child {
  height: 165px;
}
.campaign-itemlist.campaign-view-biggrid .campaign-itemlist-item:nth-child(4n-1) {
  height: 215px;
  top: -50px;
  margin-bottom: -50px;
}
.campaign-itemlist.campaign-view-biggrid .campaign-itemlist-item .campaign-itemlist-item-inner.locked-with-image {
  position: relative;
}
.campaign-itemlist.campaign-view-biggrid .campaign-itemlist-item .campaign-itemlist-item-inner.locked-with-image:after {
  content: '';
  z-index: 1;
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.2);
}
.campaign-itemlist.campaign-view-biggrid .campaign-itemlist-item .campaign-itemlist-item-inner.locked-with-image rl-icon {
  color: var(--general-background-color);
  opacity: 1;
}
.campaign-itemlist.campaign-view-biggrid .campaign-itemlist-item .campaign-itemlist-item-inner rl-icon {
  height: 60px;
  width: 60px;
  line-height: 60px;
  text-align: center;
  margin-top: -30px;
  margin-left: -30px;
  font-size: 50px;
}
.campaign-introduction {
  padding: 15px;
}
.campaign-introduction translation-button {
  margin-bottom: 10px;
}
campaign-item header-popup {
  background: none;
  margin-bottom: -55px;
  z-index: 1;
  position: relative;
}
campaign-item header-popup .buttons button {
  width: 55px;
}
campaign-item header-popup .buttons button rl-icon {
  color: #000;
  background: rgba(200, 200, 200, 0.7);
  height: 30px;
  width: 30px;
  border-radius: 100%;
  text-align: center;
  line-height: 30px;
  font-size: 15px;
  box-sizing: border-box;
  padding-left: 1px;
  padding-top: 1px;
}
.campaign-item {
  min-height: 100%;
  background: #fff;
}
.campaign-item .campaign-item-header {
  height: 210px;
  width: 100%;
  background: #EBEBEB;
  display: flex;
  flex-direction: column-reverse;
  padding: 20px;
  box-sizing: border-box;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  background-position: center center;
  background-size: cover;
}
.campaign-item .campaign-item-header h2 {
  margin: 0;
  padding: 0;
  color: var(--general-text-color);
  font-weight: bold;
  font-size: 28px;
}
.campaign-item .campaign-item-header h3 {
  margin: 0 0 -5px 0;
  padding: 0;
  color: var(--general-text-color);
  font-weight: normal;
  font-size: 13px;
  text-transform: uppercase;
  opacity: 0.75;
}
.campaign-item .campaign-item-cover {
  margin: 15px 15px -10px 15px;
  border-radius: var(--general-border-radius);
  overflow: hidden;
}
.campaign-item .campaign-item-content {
  box-sizing: border-box;
  padding: 25px 15px 15px 15px;
}
.campaign-item .campaign-item-content.has-footer {
  padding-bottom: 85px;
}
.campaign-item .campaign-item-content p:first-child {
  margin-top: 0;
}
.campaign-item .campaign-item-content translation-button {
  margin-top: -10px;
  margin-bottom: 10px;
}
.campaign-item .campaign-item-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  box-sizing: border-box;
  padding: 10px 15px 15px 15px;
  background: #fff;
}
add-users .add-users-content {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
}
add-users header-popup .title h1 {
  font-weight: 700;
}
add-users header-popup .title h2 {
  color: rgba(var(--header-text-color-rgb), 0.4);
  font-weight: 400;
}
chat-members .chat-members-content {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
}
chat-members header-popup .title h1 {
  font-weight: 700;
}
chat-members header-popup .title h2 {
  color: rgba(var(--header-text-color-rgb), 0.4);
  font-weight: 400;
}
channels-list {
  position: relative;
  display: block;
}
channels-list .tab-header {
  padding: 10px 15px;
}
channels-list .channel-wrapper {
  position: relative;
  overflow: hidden;
}
channels-list .channel-wrapper:first-of-type .channel {
  border-top: 1px solid var(--general-border-color);
}
channels-list .channel-wrapper .channel-options {
  position: absolute;
  z-index: 10;
  top: 0;
  right: 0;
  bottom: 0;
  left: 100%;
  width: 100%;
  background: var(--general-background-color);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  box-shadow: -3px 0 8px 0 rgba(60, 72, 88, 0.1);
  border-top: 1px solid var(--general-border-color);
  border-bottom: 1px solid var(--general-border-color);
  transition: transform 0.3s;
}
channels-list .channel-wrapper .channel-options button {
  height: 100%;
  padding: 15px;
  color: var(--general-text-color);
  font-weight: 600;
}
channels-list .channel-wrapper .channel-options button rl-icon {
  font-size: 17px;
}
channels-list .channel-wrapper .channel-options .delete {
  color: var(--warning-color);
}
channels-list .channel {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  padding: 15px;
  overflow: hidden;
  transition: transform 0.3s;
}
channels-list .channel.has-new-messages {
  background: var(--background-highlight-color);
}
channels-list .channel rl-icon {
  height: 45px;
  width: 20px;
  margin-left: 15px;
  opacity: 0.5;
}
channels-list .channel rl-badge {
  height: 7px;
  width: 7px;
  min-width: 7px;
  position: absolute;
  top: 34px;
  left: 4px;
  z-index: 10;
}
channels-list .channel profile-image .leave-icon {
  width: 16px;
  height: 16px;
  bottom: 0;
  right: 0;
  margin-left: unset;
  opacity: 1;
}
channels-list .channel-content {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-left: 10px;
  word-break: break-all;
}
channels-list .channel-content:hover {
  cursor: pointer;
}
channels-list .channel-content .top {
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
channels-list .channel-content .top .title {
  margin: 0;
  font-size: 15px;
  font-weight: bold;
  word-break: break-word;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 70vw;
}
channels-list .channel-content .top .time {
  margin: 0;
  opacity: 0.5;
  font-size: 12px;
  word-break: break-word;
}
channels-list .channel-content .bottom {
  display: flex;
  align-items: baseline;
}
channels-list .channel-content .bottom .author {
  font-weight: bold;
  font-size: 12px;
  flex-shrink: 0;
  color: var(--general-text-inactive-color);
}
channels-list .channel-content .bottom .message {
  margin: 0;
  opacity: 0.5;
  padding-left: 5px;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 70vw;
}
@media only screen and (min-width: 1025px) {
  channels-list .channel-content .bottom .message {
    max-width: calc(100vw - 600px);
  }
}
channels-list .channel-content .bottom .message:first-child {
  padding-left: 0;
}
channels-list .channel-content .bottom .mute-icon {
  font-size: 10px;
  max-width: 10px;
  height: 10px;
  display: flex;
  margin-left: auto;
}
channels-list .icon-remove {
  margin: 0 10px;
  color: red;
}
channels-list .empty-channels-list {
  top: 30vh;
}
chat-settings .popup-wrapper .popup-content {
  background: var(--background-highlight-color);
}
chat-settings .scroll-container {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  padding: var(--spacing);
}
chat-settings .scroll-container .profile-image-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: none;
}
chat-settings .scroll-container .profile-image-section .profile-image-wrapper {
  position: relative;
  display: inline-block;
  padding: 0 0 5px 0;
}
chat-settings .scroll-container .profile-image-section .profile-image-wrapper .channel-image {
  background: var(--background-highlight-color);
  width: 105px;
  height: 105px;
  min-width: 105px;
  border-radius: 50%;
  padding: 0;
}
chat-settings .scroll-container .profile-image-section .profile-image-wrapper .channel-image .profile-image {
  width: 100%;
  height: 100%;
}
chat-settings .scroll-container .profile-image-section .profile-image-wrapper .group-image {
  position: relative;
  border-radius: 50%;
  z-index: 5;
  overflow: hidden;
  width: 105px;
  height: 105px;
  min-width: 105px;
  background-color: var(--background-highlight-color);
}
chat-settings .scroll-container .profile-image-section .profile-image-wrapper .group-image profile-image {
  padding: 0;
}
chat-settings .scroll-container .profile-image-section .profile-image-wrapper .group-image .channel-image:nth-child(2),
chat-settings .scroll-container .profile-image-section .profile-image-wrapper .group-image .channel-image:nth-child(3) {
  position: absolute;
  min-width: 0;
  top: 0;
  width: 50%;
  left: 50%;
  border-left-width: 0.4px;
  border-left-style: solid;
}
chat-settings .scroll-container .profile-image-section .profile-image-wrapper .group-image .channel-image:nth-child(2) .profile-image,
chat-settings .scroll-container .profile-image-section .profile-image-wrapper .group-image .channel-image:nth-child(3) .profile-image {
  border-radius: 0;
}
chat-settings .scroll-container .profile-image-section .profile-image-wrapper .group-image.group-image-2 .channel-image:nth-child(2) {
  border-radius: 0 50% 50% 0;
}
chat-settings .scroll-container .profile-image-section .profile-image-wrapper .group-image.group-image-3 .channel-image:nth-child(2) {
  height: 50%;
  border-radius: 0 50% 0 0;
}
chat-settings .scroll-container .profile-image-section .profile-image-wrapper .group-image.group-image-3 .channel-image:nth-child(3) {
  height: 50%;
  top: 50%;
  border-radius: 0 0 50% 0;
  border-top-width: 0.4px;
  border-top-style: solid;
}
chat-settings .scroll-container .profile-image-section .profile-image-wrapper .edit-group-image,
chat-settings .scroll-container .profile-image-section .profile-image-wrapper media-upload {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
  width: 31px;
  height: 31px;
  padding: 0;
  z-index: 6;
  background: var(--white-color);
  color: var(--text-on-white-color);
  border-radius: 100%;
  box-shadow: 0 2px 10px 0 var(--general-border-color);
}
chat-settings .scroll-container .profile-image-section .profile-image-wrapper .edit-group-image rl-icon,
chat-settings .scroll-container .profile-image-section .profile-image-wrapper media-upload rl-icon {
  font-size: 15px;
}
chat-settings .scroll-container .profile-image-section .profile-image-wrapper profile-image {
  padding: 0;
  width: 105px;
  height: 105px;
  border-radius: 50%;
  background-color: var(--background-highlight-color);
  box-shadow: 0 2px 10px 0 var(--general-border-color);
}
chat-settings .scroll-container .profile-image-section .profile-image-wrapper profile-image .profile-image {
  height: 105px;
  width: 105px;
}
chat-settings .setting-label,
chat-settings .setting-item {
  background: var(--general-background-color);
  padding: var(--m-spacing);
  margin: 0 0 var(--spacing);
  position: relative;
  border-radius: var(--general-border-radius);
}
chat-settings .setting-label .users-count,
chat-settings .setting-item .users-count {
  display: flex;
  justify-content: space-between;
}
chat-settings .setting-label .users-count button,
chat-settings .setting-item .users-count button {
  color: var(--highlight-color);
  font-weight: 600;
}
chat-settings .setting-label input,
chat-settings .setting-item input {
  width: 100%;
  border: none;
  box-sizing: border-box;
}
chat-settings .setting-label .leave-icon,
chat-settings .setting-item .leave-icon,
chat-settings .setting-label .users-icon,
chat-settings .setting-item .users-icon {
  width: 1.25rem;
  height: 1.25rem;
  margin-right: var(--m-spacing);
  font-size: 1.1rem;
}
chat-settings .setting-label .arrow-icon,
chat-settings .setting-item .arrow-icon {
  font-size: 1rem;
  transform: rotate(-90deg);
  color: var(--general-text-inactive-color);
  margin-left: auto;
}
chat-settings .setting-label .btn-leave,
chat-settings .setting-item .btn-leave,
chat-settings .setting-label .btn-members,
chat-settings .setting-item .btn-members {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0;
  font-weight: 400;
  font-size: 1rem;
}
chat-settings .setting-label .btn-leave,
chat-settings .setting-item .btn-leave {
  color: var(--warning-color);
}
chat-settings .setting-label .chat-title,
chat-settings .setting-item .chat-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: var(--s-spacing);
}
chat-settings .setting-label .chat-title input,
chat-settings .setting-item .chat-title input {
  font-size: 1.15rem;
  color: var(--general-text-color);
  font-weight: 400;
  text-overflow: ellipsis;
  padding-left: 0;
  background: inherit;
}
chat-settings .setting-label .chat-title input:disabled,
chat-settings .setting-item .chat-title input:disabled {
  background: inherit;
}
chat-settings .setting-label .chat-title rl-icon,
chat-settings .setting-item .chat-title rl-icon,
chat-settings .setting-label .chat-title .spinner,
chat-settings .setting-item .chat-title .spinner {
  z-index: 10;
  color: var(--general-text-inactive-color);
  font-size: 1rem;
  cursor: pointer;
}
chat-settings .setting-label .chat-title rl-icon[icon=save],
chat-settings .setting-item .chat-title rl-icon[icon=save],
chat-settings .setting-label .chat-title .spinner[icon=save],
chat-settings .setting-item .chat-title .spinner[icon=save] {
  font-size: 1.25rem;
  opacity: 1;
  color: var(--highlight-color);
}
chat-settings .setting-label .chat-title .spinner,
chat-settings .setting-item .chat-title .spinner {
  width: 0.9rem;
  height: 0.9rem;
  display: none;
}
chat-settings .setting-label .chat-title.fetching .spinner,
chat-settings .setting-item .chat-title.fetching .spinner {
  display: block;
}
chat-settings .setting-label .chat-notifications,
chat-settings .setting-item .chat-notifications {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
chat-settings .setting-label .chat-notifications label,
chat-settings .setting-item .chat-notifications label {
  display: flex;
  align-items: center;
  gap: var(--m-spacing);
  font-size: 1rem;
  flex-grow: 1;
}
chat-settings .setting-label .chat-notifications label:hover,
chat-settings .setting-item .chat-notifications label:hover {
  cursor: pointer;
}
chat-settings .setting-label {
  margin: 0;
  padding: 0 0 var(--s-spacing) var(--m-spacing);
  font-size: 0.75rem;
  color: var(--general-text-inactive-color);
  background: var(--background-highlight-color);
}
chat-settings .members-label {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
chat-settings .btn-add button {
  font-size: 0.8rem;
  font-weight: bold;
  height: 30px;
  padding: 8.5px 10px;
}
chat-settings .setting-footer {
  font-size: 0.8rem;
  color: var(--general-text-inactive-color);
}
chat-settings .setting-footer {
  line-height: 1.15rem;
}
chat-settings .setting-footer span {
  display: block;
}
conversation .popup-wrapper .popup-content {
  background-color: var(--background-highlight-color);
}
conversation header-popup,
conversation footer {
  display: flex;
  height: 55px;
  justify-content: space-between;
  align-items: center;
  top: 0;
  width: 100%;
  box-sizing: border-box;
  z-index: 99;
}
conversation header-popup .title-slot {
  padding: 0;
}
conversation header-popup title-slot {
  width: 100%;
  display: flex;
  overflow: hidden;
  gap: var(--s-spacing);
}
conversation header-popup profile-image.channel-image {
  width: 40px;
  height: 40px;
}
conversation header-popup profile-image.channel-image:nth-child(2),
conversation header-popup profile-image.channel-image:nth-child(3) {
  border-color: var(--header-background-color);
}
conversation header-popup profile-image .leave-icon {
  bottom: 0;
  right: 0;
  color: rgba(var(--header-text-color-rgb), 0.4);
}
conversation header-popup .channel-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
conversation header-popup .channel-name:hover {
  cursor: pointer;
}
conversation header-popup .channel-name .channel-title {
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: bold;
  font-size: 1rem;
}
conversation header-popup .channel-name .info {
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 0.8rem;
  color: rgba(var(--header-text-color-rgb), 0.4);
}
conversation header-popup .left-buttons,
conversation header-popup .right-buttons {
  min-width: 45px;
}
conversation .messages-list-wrapper {
  flex: 1;
  overflow-y: auto;
  flex-direction: column;
  display: flex;
  overscroll-behavior-y: none;
}
conversation .messages-list-wrapper:has(.message.highlighted) {
  overflow: hidden;
}
conversation .messages-loading {
  position: absolute;
  z-index: 100;
  top: 55px;
  right: 0;
  left: 0;
  bottom: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
}
conversation .messages-loading .spinner {
  width: 50px;
  height: 50px;
}
conversation .shimmer-container {
  position: absolute;
  z-index: 100;
  top: 55px;
  right: 0;
  left: 0;
  bottom: 55px;
  justify-content: flex-end;
}
conversation .shimmer-container .lazy-placeholder {
  min-width: 60%;
  margin-right: auto;
}
conversation .shimmer-container .lazy-placeholder:nth-child(2n),
conversation .shimmer-container .lazy-placeholder:first-child {
  margin-left: auto;
  margin-right: 0;
}
conversation .shimmer-container .lazy-placeholder:nth-child(2n) .placeholder-shimmer,
conversation .shimmer-container .lazy-placeholder:first-child .placeholder-shimmer {
  margin-left: auto;
}
conversation .messages-list {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex: auto;
  opacity: 0;
  padding: var(--spacing) var(--m-spacing);
  box-sizing: border-box;
  transition: opacity 0.3s;
}
conversation .messages-list.show {
  opacity: 1;
}
conversation .messages-list .message-wrapper {
  position: relative;
}
conversation .messages-list .message-wrapper.highlight:after {
  content: '';
  position: absolute;
  z-index: -1;
  top: -2px;
  right: -15px;
  bottom: 2px;
  left: -15px;
  display: block;
  transition: background-color 0.2s linear;
  background-color: rgba(var(--highlight-color-rgb), 0.1);
}
conversation .messages-list .message-wrapper:not(.deleted) + .deleted {
  margin-top: 5px;
}
conversation .messages-empty {
  top: 40%;
  z-index: -1;
}
conversation .scroll-to-bottom {
  position: absolute;
  z-index: 2;
  bottom: calc(env(safe-area-inset-bottom, 0) + 70px);
  left: 50%;
  transform: translateX(-50%);
}
conversation .scroll-to-bottom button {
  width: 36px;
  min-width: 36px;
  height: 36px;
  filter: drop-shadow(0px 6px 15px rgba(0, 0, 0, 0.15));
}
conversation .scroll-to-bottom button span {
  width: 15px;
  min-width: 15px;
}
conversation .replied-content {
  overflow: hidden;
  background-color: var(--general-background-color);
  border-radius: var(--general-border-radius);
}
conversation .replied-content .title {
  padding: 15px 10px;
  font-weight: 700;
  font-size: 15px;
  line-height: 1.3;
}
conversation popup-wrapper:not(.keyboard-open) footer {
  padding-bottom: env(safe-area-inset-bottom, 0);
}
conversation .typing-wrapper {
  display: flex;
  align-items: center;
  padding: 0 var(--m-spacing);
  background-color: var(--background-highlight-color);
  margin-top: calc(var(--spacing) / -2);
}
conversation .typing-wrapper .channel-image {
  width: 30px;
  height: 30px;
  min-width: 30px;
}
conversation .typing-wrapper .profile-images-typing {
  display: flex;
  align-items: center;
}
conversation .typing-wrapper .profile-images-typing .profile-image-typing {
  width: 40px;
  height: 40px;
  background-size: cover;
  background-position: center;
  border-radius: 50%;
  margin-left: -10px;
  box-sizing: border-box;
  border: 2px solid var(--background-highlight-color);
}
conversation .typing-wrapper .profile-images-typing-wrapper:not(:first-child) {
  margin-left: -5px;
}
conversation .typing-wrapper .message {
  display: flex;
  padding: 5px;
  margin-bottom: 5px;
  text-align: left;
  align-items: center;
}
conversation .typing-wrapper .typing-label {
  font-size: 0.73333rem;
  max-width: calc(100vw - 170px);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
conversation .typing-wrapper .comma {
  margin-right: 3px;
}
conversation .typing-wrapper .is-typing {
  margin-left: 4px;
  font-size: 0.73333rem;
}
conversation .typing-wrapper .message-body-wrapper {
  position: relative;
  padding-left: 7px;
  width: 40px;
  display: inline-block;
  border-radius: var(--general-border-radius);
}
conversation .typing-wrapper .message-body-wrapper .message-body {
  min-height: 18px;
  white-space: normal;
  text-align: left;
}
conversation .typing-wrapper .message-body-wrapper .message-body .typing-indicator {
  position: absolute;
  left: 50%;
  margin-left: -18px;
  top: 50%;
  margin-top: -14px;
}
conversation .typing-wrapper .message-body-wrapper .message-body .typing-indicator span {
  animation-name: blink;
  animation-duration: 1.4s;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  font-size: 40px;
  font-weight: bold;
  line-height: 8px;
}
conversation .typing-wrapper .message-body-wrapper .message-body .typing-indicator span:nth-child(2) {
  animation-delay: 0.2s;
}
conversation .typing-wrapper .message-body-wrapper .message-body .typing-indicator span:nth-child(3) {
  animation-delay: 0.4s;
}
conversation .leave-text {
  border-radius: var(--general-border-radius);
  border: 1px solid var(--general-border-color);
  padding: var(--m-spacing);
  margin: 0 var(--spacing) var(--spacing);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  align-items: center;
  background-color: var(--background-highlight-color);
}
conversation .leave-text:hover {
  background-color: var(--elevated-background-highlight-color);
}
conversation .leave-text.hide-message {
  display: none;
}
conversation .leave-text .message-box {
  display: flex;
  flex-direction: column;
  flex: auto;
}
conversation .leave-text .message-box rl-icon[icon="xmark"] {
  font-size: 19px;
  margin: 0 10px;
}
conversation .leave-text .read-more {
  color: var(--general-text-inactive-color);
  text-transform: lowercase;
}
conversation footer {
  position: sticky;
  bottom: 0;
  height: auto;
  margin-top: auto;
  flex-direction: column;
  align-items: stretch;
  background-color: var(--general-background-color);
}
conversation footer .editing,
conversation footer .replying-message {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: var(--m-spacing) var(--m-spacing) 0;
  padding: 10px;
  font-size: 13px;
  border: 1px solid var(--general-border-color);
  border-radius: var(--general-border-radius);
}
conversation footer .replying-message [icon=reply] {
  padding: 0 10px;
}
conversation footer .replying-message .message {
  display: flex;
  flex: 1;
  align-items: center;
  overflow: hidden;
}
conversation footer .replying-message .right {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
}
conversation footer .replying-message .text {
  overflow: hidden;
  margin-right: 15px;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: var(--general-text-inactive-color);
}
conversation footer .replying-message .author {
  margin-bottom: 5px;
}
conversation footer .replying-message file-preview {
  position: relative;
  overflow: hidden;
  margin-right: 10px;
  border-radius: 5px;
  width: 35px;
  height: 35px;
}
conversation footer .replying-message file-preview .file-icon {
  font-size: 25px;
}
conversation footer .uploads-container {
  display: flex;
  flex-direction: column;
  padding-top: var(--spacing);
  gap: var(--s-spacing);
  border-bottom: solid 1px var(--general-border-color);
}
conversation footer .uploads-label {
  font-size: 0.65rem;
  text-transform: uppercase;
  margin: 0;
  font-weight: 400;
  padding-left: var(--spacing);
}
conversation footer scroll-shadow {
  overflow: hidden;
  flex: 1 0 100%;
}
conversation footer .uploads {
  width: 100%;
  box-sizing: border-box;
  background: inherit;
  display: flex;
  gap: var(--m-spacing);
  overflow-y: hidden;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  padding: 0 var(--spacing) var(--m-spacing);
}
conversation footer .uploads .media-thumbnail {
  display: block;
  float: left;
  min-width: 77px;
  height: 77px;
  box-sizing: border-box;
  position: relative;
  border-radius: var(--general-border-radius);
}
conversation footer .uploads .media-thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
conversation footer .uploads .media-thumbnail:before {
  display: block;
  position: absolute;
  top: 5px;
  right: 5px;
  content: "\F156";
  font-family: 'Material Design Icons';
  color: var(--white-color);
  text-shadow: -1px -1px 0 rgba(0, 0, 0, 0.5), 1px -1px 0 rgba(0, 0, 0, 0.5), -1px 1px 0 rgba(0, 0, 0, 0.5), 1px 1px 0 rgba(0, 0, 0, 0.5);
}
conversation footer .uploads:after {
  display: table;
  clear: both;
  content: '';
}
conversation footer [icon="close"] {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  backdrop-filter: blur(24px);
  background: rgba(0, 0, 0, 0.1);
  font-size: 12px;
}
conversation footer .form {
  display: flex;
  width: 100%;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
  padding-right: var(--s-spacing);
}
conversation footer .form .text-input {
  width: 100%;
  position: relative;
}
conversation footer .form mention-textarea {
  border: none;
  padding: 8px 28px 4px 10px;
  line-height: 25px;
  display: block;
  max-height: 150px;
  resize: none;
  color: var(--general-text-color);
  overscroll-behavior-y: contain;
}
conversation footer .form mention-textarea .ui-mention-container .mention-dropdown {
  bottom: 44px;
  left: -19px;
  right: -72px;
}
conversation footer .form mention-textarea .ui-mention-container textarea {
  resize: none;
  background: none;
  border: none;
  box-sizing: border-box;
  display: block;
  font-size: 15px;
  height: 36px;
  line-height: 20px;
  margin: 0;
  max-height: 150px;
  min-height: 36px;
  overflow-y: auto;
  padding: 8px 35px 8px 5px;
  width: 100%;
  color: var(--general-text-color);
}
conversation footer .form mention-textarea::-webkit-input-placeholder {
  color: rgba(var(--general-text-color-rgb), 0.7);
}
conversation footer .form mention-textarea::-webkit-resizer {
  display: none;
}
@media only screen and (min-width: 1025px) {
  conversation footer .form mention-textarea {
    scrollbar-width: thin;
  }
  conversation footer .form mention-textarea::-webkit-scrollbar {
    width: 5px;
  }
  conversation footer .form mention-textarea::-webkit-scrollbar-thumb {
    background: var(--background-highlight-color);
  }
  conversation footer .form mention-textarea::-webkit-scrollbar-track {
    background: var(--background-highlight-color);
  }
}
conversation footer .form .btn-upload {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 4px;
  top: 0;
  bottom: 0;
  width: 30px;
}
conversation footer .form .btn-upload input {
  opacity: 0;
  height: 100%;
  box-sizing: border-box;
  padding: 0;
  width: 40px;
  line-height: 36px;
}
conversation footer .form button {
  padding: 0 5px;
  text-align: center;
  color: var(--highlight-color);
}
conversation footer .form button[disabled] {
  opacity: 0.4;
  color: var(--general-text-color);
}
conversation footer .form button [icon=spinner] {
  animation: fa-spin 2s linear infinite;
}
conversation footer .form button.sending {
  opacity: 0.4;
}
conversation footer .form button.sending [icon=send] {
  display: none;
}
conversation footer .form button:not(.sending) [icon=spinner] {
  display: none;
}
conversation .blocked-title {
  display: flex;
  align-items: center;
  padding-left: 10px;
}
@keyframes blink {
  0% {
    opacity: 0.2;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}
.message-wrapper:last-of-type desktop-message-menu .button-groups {
  top: unset;
  bottom: 45px;
}
.message-wrapper:last-of-type action-sheet,
.message-wrapper:nth-last-child(2) action-sheet {
  display: block;
}
message-item .rl-animation-height {
  transition: 0.15s linear transform, 0.15s linear height, 0.15s linear opacity;
}
message-item .meta-bottom {
  gap: 5px;
}
message-item .meta-bottom .message-views,
message-item .meta-bottom .time,
message-item .meta-bottom .message-status {
  height: 0;
  max-width: 0;
  overflow: hidden;
  opacity: 0;
  transform: scaleY(0);
  transform-origin: top;
}
message-item .meta-bottom .message-views.meta-animation,
message-item .meta-bottom .time.meta-animation,
message-item .meta-bottom .message-status.meta-animation {
  transform: scaleY(1);
  height: 1.5rem;
  max-width: 100%;
  opacity: 1;
  overflow: unset;
}
message-item .meta-bottom .message-views {
  display: flex;
  justify-content: flex-start;
  margin: 0 3px;
}
message-item .meta-bottom .message-views .message-view {
  margin: 0 0 0 calc(-1 * var(--s-spacing));
  text-align: center;
}
message-item .meta-bottom .message-views .message-view-badge {
  width: 1.2rem;
  height: 1.2rem;
  line-height: 1.2rem;
  border-radius: 50%;
  background-color: var(--background-highlight-color);
  color: var(--general-text-color);
  font-size: 0.55rem;
  font-weight: 700;
  border: 3px solid var(--background-highlight-color);
  position: relative;
  z-index: 0;
}
message-item .meta-bottom .message-views .message-view-badge::before {
  background-color: var(--general-border-color);
  z-index: -1;
  content: '';
  display: block;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  position: absolute;
}
message-item .meta-bottom .message-views profile-image {
  width: 1.2rem;
  height: 1.2rem;
  position: static;
  background: var(--background-highlight-color);
  border-radius: 50%;
  padding: 0;
  overflow: hidden;
  border: 3px solid var(--background-highlight-color);
}
message-item .meta-bottom .message-views profile-image .profile-image {
  width: 100%;
  height: 100%;
}
message-item .message-status {
  font-size: 0.87rem;
  display: flex;
  align-items: center;
  color: var(--general-text-inactive-color);
}
message-item .message-status.failed {
  color: var(--warning-color);
}
message-item .message-status rl-icon[icon="edit"] {
  width: 1rem;
  font-size: 0.5rem;
  padding: 0 var(--s-spacing);
}
message-item .message-status .animated-dots {
  display: flex;
}
message-item .message-status .animated-dots .single-dot {
  animation: blink 1.5s infinite;
}
message-item .message-status .animated-dots .single-dot:nth-child(2) {
  animation-delay: 0.3s;
}
message-item .message-status .animated-dots .single-dot:nth-child(3) {
  animation-delay: 0.6s;
}
@keyframes blink {
  0%,
  20%,
  50%,
  80%,
  100% {
    opacity: 1;
  }
  40% {
    opacity: 0;
  }
  60% {
    opacity: 0;
  }
}
message-item .time {
  overflow: hidden;
}
@media only screen and (min-width: 1025px) {
  message-item .time {
    display: flex;
  }
}
message-item.self .meta-bottom {
  font-size: 0.87rem;
  color: var(--general-text-inactive-color);
  display: flex;
  justify-content: flex-end;
}
message-item.self .meta-bottom .message-views {
  padding-left: 0;
}
message-item.self .time {
  align-items: center;
  flex-direction: row;
}
message-item.self .failed-indicator {
  position: absolute;
  left: -1.6rem;
  top: calc(50% - 0.7rem);
  font-size: 1rem;
  width: 1.4rem;
  height: 1.4rem;
  border-radius: 50%;
  background-color: var(--warning-color);
  color: var(--white-color);
  display: grid;
}
message-item.self .failed-indicator rl-icon {
  font-size: 0.8rem;
}
message-item.self.deleted .deleted-message {
  text-align: right;
  margin-left: auto;
}
message-item.self .message-content-wrapper {
  align-items: flex-end;
}
message-item.self .message-actionsheet-wrapper {
  align-items: flex-start;
}
message-item.self .message-reactions-wrapper {
  margin-left: auto;
}
message-item.self .meta {
  text-align: right;
  padding-left: 0;
  justify-content: flex-end;
}
message-item.self reactions-list {
  right: 0;
}
message-item.self .message {
  text-align: right;
  padding-left: 0;
}
message-item.self .message .avatar {
  right: 0;
  left: auto;
}
message-item.self .message .replying-message {
  background: var(--general-border-color);
}
message-item.self .message .message-body-wrapper {
  background: var(--highlight-color);
  margin-left: auto;
}
message-item.self .message .message-body-wrapper.first-in-row:after {
  border-bottom: 7px solid var(--highlight-color);
  transform: rotate(-45deg);
  left: auto;
  right: -8px;
}
message-item.self .message .message-body {
  color: var(--white-color);
  white-space: pre-wrap;
}
message-item.self .message.reactions-overlay reactions-list {
  right: -5px;
}
message-item.self .message desktop-message-menu {
  right: 0;
}
message-item.self .message-views {
  display: flex;
  justify-content: flex-end;
}
message-item.self action-sheet.attached .actionsheet {
  right: 0;
}
message-item.deleted .deleted-message {
  text-align: left;
}
message-item:not(.self) .time {
  align-items: center;
}
message-item:not(.self) .time:not(:first-child)::before {
  content: '\b7';
  display: block;
  font-size: 1.2rem;
  margin: 0 var(--s-spacing);
}
message-item:not(.self) action-sheet.attached .actionsheet {
  right: auto;
}
message-item:not(.self) .meta-bottom {
  font-size: 0.87rem;
  color: var(--general-text-inactive-color);
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
}
message-item:not(.self) .message.dialog reactions-list,
message-item:not(.self) .message.dialog action-sheet.attached .actionsheet {
  left: 0 !important;
}
message-item:not(.self) .message.dialog.reactions-overlay reactions-list {
  left: calc(var(--s-spacing) * -1) !important;
}
message-item:not(.self) .deleted-message {
  margin-right: auto;
}
message-item:not(.self) .message-actionsheet-wrapper {
  align-items: flex-end;
}
message-item:not(.self) .message-reactions-wrapper {
  margin-right: auto;
}
message-item .date {
  font-size: 13px;
  text-align: center;
}
message-item .meta {
  margin-top: 5px;
  text-align: left;
  position: relative;
  padding-left: 35px;
  font-size: 0.87rem;
  color: var(--general-text-inactive-color);
  font-weight: 400;
  display: flex;
  align-items: center;
  line-height: 1.2rem;
}
message-item .meta.dialog {
  padding-left: 0;
}
message-item .meta .message-status:not(:first-child) .text::before {
  content: '\b7';
  display: inline;
  font-size: 1.2rem;
  margin: 0 var(--s-spacing);
}
message-item .meta .message-status rl-icon {
  display: none;
}
message-item .meta .message-status.mobile .text {
  display: none;
}
message-item .meta .message-status.mobile rl-icon {
  display: block;
}
message-item .message-reactions-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
message-item .message-content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
message-item .message.hover desktop-message-menu {
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.1s linear, transform 0.2s linear;
}
message-item .message {
  --move-dist: 0;
  position: relative;
  top: 0;
  text-align: left;
  padding-left: 35px;
  padding-right: 0;
  transition: top 0.3s ease;
}
message-item .message.dialog {
  padding-left: 0;
}
message-item .message.highlighted .message-body-wrapper,
message-item .message.highlighted .replying-message,
message-item .message.highlighted reactions-summary {
  position: relative;
  z-index: 7;
}
message-item .message.highlighted > reactions-list,
message-item .message.highlighted action-sheet .actionsheet {
  z-index: 9;
}
message-item .message.highlighted.mobile {
  z-index: 200;
  top: var(--move-dist);
}
message-item .message.highlighted.mobile .message-body-wrapper,
message-item .message.highlighted.mobile .replying-message,
message-item .message.highlighted.mobile reactions-summary {
  z-index: 101;
}
message-item .message.highlighted.mobile > reactions-list,
message-item .message.highlighted.mobile action-sheet .actionsheet {
  z-index: 102;
}
message-item .message.highlighted desktop-message-menu {
  display: block;
}
message-item .message.highlighted .meta-bottom {
  opacity: 0;
}
message-item .message.reactions-overlay reactions-list {
  bottom: 40vh;
  top: unset;
  z-index: 102;
  transition: top 0.5s ease;
}
@media only screen and (max-width: 767px) and (orientation: landscape) {
  message-item .message.reactions-overlay reactions-list {
    bottom: 70vh;
  }
}
message-item .message desktop-message-menu {
  visibility: hidden;
  opacity: 0;
  transform: translateY(10px);
  position: absolute;
  top: -30px;
  z-index: 10;
}
message-item .message reactions-list {
  position: absolute;
  top: -60px;
  padding: var(--m-spacing);
  max-height: 55px;
  display: flex;
  gap: var(--m-spacing);
  border-radius: 25px;
  background: var(--general-background-color);
  box-shadow: 0px 12px 25px 0px var(--shadow-color);
}
message-item .message reactions-list .reaction-icon-wrapper {
  width: 35px;
  height: 35px;
  margin: 0;
}
@media only screen and (min-width: 1025px) {
  message-item .message reactions-list .reaction-icon-wrapper {
    width: 24px;
    height: 24px;
  }
}
message-item .message reactions-list .reaction-icon-wrapper .reaction-label {
  margin-bottom: 12px;
}
message-item .message reactions-list .reaction-icon-wrapper .reaction-like {
  font-size: 13px;
  padding-bottom: 4px;
}
message-item .message .avatar {
  border-radius: 50%;
  background: var(--background-highlight-color);
  width: 30px;
  height: 30px;
  position: absolute;
  top: 0;
  left: 0;
  padding: 0;
}
message-item .message .avatar .leave-icon {
  bottom: -3px;
  right: -3px;
}
message-item .message .avatar .profile-image {
  width: 100%;
  height: 100%;
}
message-item .message .replying-message,
message-item .message .message-body-wrapper {
  position: relative;
  padding: var(--m-spacing);
  display: inline-block;
  border-radius: var(--chat-border-radius);
  background: var(--general-background-color);
  word-break: break-word;
  box-sizing: border-box;
}
message-item .message .replying-message {
  display: flex;
  max-width: max(70%, 250px);
  overflow: hidden;
  padding-bottom: 15px;
  margin-bottom: -10px;
  margin-top: 5px;
  background-color: var(--general-background-color);
  cursor: pointer;
  box-sizing: border-box;
  font-size: 14px;
}
message-item .message .replying-message:after {
  content: '';
  z-index: -1;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--background-highlight-color);
}
@media only screen and (min-width: 1025px) {
  message-item .message .replying-message:hover {
    filter: brightness(0.95);
  }
}
message-item .message .replying-message > rl-icon {
  font-size: 1.2rem;
  margin-right: 10px;
  opacity: 0.7;
}
message-item .message .replying-message file-preview {
  position: relative;
  overflow: hidden;
  margin-right: 10px;
  border-radius: 5px;
  width: 35px;
  height: 35px;
}
message-item .message .replying-message file-preview .file-icon {
  font-size: 25px;
}
message-item .message .replying-message .right {
  display: flex;
  flex: 1;
  flex-direction: column;
  text-align: left;
  justify-content: center;
  overflow: hidden;
}
message-item .message .replying-message .right .text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  opacity: 0.7;
}
message-item .message .message-media {
  display: flex;
  width: 220px;
}
message-item .message .message-media media-preview {
  width: 100%;
  height: 200px;
}
message-item .message .message-media media-preview .media-preview-list-item,
message-item .message .message-media media-preview .image {
  border-radius: 10px;
  overflow: hidden;
}
message-item .message .message-media media-preview .media {
  position: static;
}
message-item .message .message-media media-preview .media-image-container .image {
  height: 200px;
}
message-item .message .message-media media-preview .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
message-item .message .message-media media-preview .video-play-thumb {
  padding: 0;
  height: 200px;
}
message-item .message .message-media media-preview .media .video > .video-wrapper {
  height: 200px;
  padding-top: 0;
  padding-bottom: 0;
}
message-item .message .message-actionsheet-wrapper {
  display: flex;
  flex-direction: column;
  max-width: max(70%, 250px);
}
message-item .message .message-actionsheet-wrapper action-sheet {
  position: unset;
  margin-right: auto;
}
message-item .message .message-actionsheet-wrapper action-sheet .actionsheet {
  position: unset;
}
message-item .message action-sheet.attached .actionsheet {
  top: auto;
  bottom: auto;
  width: 187px;
  padding: var(--s-spacing) 0;
  margin-top: 5px;
  border: 1px solid var(--general-border-color);
  box-shadow: 0px 7px 15px 0px var(--shadow-color);
}
message-item .message action-sheet.attached .actionsheet .actionsheet-button:has(.red) .text-wrapper {
  color: var(--warning-color);
}
message-item .message action-sheet.attached .actionsheet .text {
  font-size: 0.8rem;
}
message-item .message action-sheet.attached .actionsheet rl-icon {
  margin-right: var(--s-spacing);
}
message-item reactions-summary {
  position: relative;
  margin: -7px 0 0 3px;
  padding: 3px 5px;
  background-color: var(--white-color);
  box-shadow: 0px 4px 7px 0px var(--shadow-color);
  border-radius: 100px;
  border: 1px solid var(--general-border-color);
  cursor: pointer;
}
message-item reactions-summary .count {
  color: var(--text-on-white-color);
  font-size: 0.8rem;
}
message-item reactions-summary .icons.count-3 {
  margin-left: -4px;
}
message-item reactions-summary .icons.count-2 {
  margin-left: -2px;
}
message-item reactions-summary reaction-icon {
  border: none;
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
}
message-item reactions-summary reaction-icon:nth-child(1) {
  z-index: 6;
}
message-item reactions-summary reaction-icon:nth-child(2) {
  z-index: 5;
  left: 2px;
}
message-item reactions-summary reaction-icon:nth-child(3) {
  z-index: 4;
  left: 4px;
}
message-item reactions-summary reaction-icon rl-icon {
  position: absolute;
}
message-item .message-body-wrapper {
  margin: 3px 0;
}
message-item .message-body-wrapper.blocked-message {
  color: var(--general-text-inactive-color);
  border-color: var(--general-text-inactive-color);
  background-color: var(--general-background-color);
}
message-item .message-body-wrapper.first-in-row {
  margin-top: 0;
}
message-item .message-body-wrapper.with-media {
  width: 100%;
}
message-item .message-body {
  white-space: pre-wrap;
  text-align: left;
}
message-item .message-body .post-tag,
message-item .message-body .tag-label {
  font-weight: 700;
}
message-item .deleted-message {
  display: block;
  padding: var(--m-spacing);
  border-radius: var(--chat-border-radius);
  border: 1px solid var(--general-border-color);
  background-color: var(--general-background-color);
  font-size: 1rem;
  color: var(--general-text-inactive-color);
  width: max-content;
  margin: 3px 0;
  max-width: max(70%, 250px);
}
message-item .deleted-message.first-in-row {
  margin-top: 0;
}
message-item .overlay {
  position: fixed;
  z-index: 8;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
}
message-item .overlay.mobile {
  z-index: 100;
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(20px);
}
desktop-message-menu .desktop-message-menu {
  display: inline-flex;
  position: relative;
  justify-content: flex-end;
  padding: var(--s-spacing);
  gap: 5px;
  border-radius: var(--general-border-radius);
  background-color: var(--white-color);
  box-shadow: 8px 16px 56px rgba(10, 10, 10, 0.16);
}
desktop-message-menu .desktop-message-menu reactions-list {
  position: static !important;
  padding: 0 !important;
  border-radius: unset !important;
  background: unset !important;
  box-shadow: unset !important;
}
desktop-message-menu .desktop-message-menu .button-groups {
  border-radius: var(--general-border-radius);
  background-color: var(--white-color);
  position: absolute;
  top: 45px;
}
desktop-message-menu .desktop-message-menu .button-groups .actionsheet-button {
  cursor: pointer;
  display: flex;
  gap: 5px;
  padding: 10px;
  flex-direction: row;
  align-items: flex-start;
}
desktop-message-menu .desktop-message-menu rl-icon {
  cursor: pointer;
}
desktop-message-menu .desktop-message-menu rl-icon.red {
  color: var(--warning-color);
}
desktop-message-menu .desktop-message-menu rl-icon[icon="vertical-line"] {
  color: rgba(var(--general-text-color-rgb), 0.3);
  width: 5px;
}
message-viewers .message-viewers {
  min-height: 100%;
}
message-viewers .message-viewers .popup-content {
  background: var(--general-background-color);
}
message-viewers .message-viewers .viewers-list {
  flex: 1;
  padding: 0;
  margin: 0;
  overflow-y: auto;
  list-style: none;
}
message-viewers .message-viewers .viewers-list li {
  padding: 10px 15px;
  margin: 0;
  display: flex;
  justify-content: left;
  align-items: center;
}
message-viewers .message-viewers .viewers-list li .profile-image {
  width: 36px;
  height: 36px;
  min-width: 36px;
}
message-viewers .message-viewers .viewers-list li .info {
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  margin-left: 10px;
}
message-viewers .message-viewers .viewers-list li .left {
  display: flex;
  flex-direction: column;
  max-width: calc(100vw - 200px);
}
message-viewers .message-viewers .viewers-list li .name,
message-viewers .message-viewers .viewers-list li .department {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
message-viewers .message-viewers .viewers-list li .name {
  font-size: 15px;
  font-weight: 500;
  color: var(--general-text-color);
}
message-viewers .message-viewers .viewers-list li .department {
  font-size: 13px;
  opacity: 0.5;
}
message-viewers .message-viewers .viewers-list li .date {
  font-size: 12px;
  text-align: right;
  min-width: 100px;
  white-space: nowrap;
}
create-chat .create-chat-content {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
}
create-chat header-popup .title h1 {
  font-weight: 700;
}
create-chat header-popup .title h2 {
  color: rgba(var(--header-text-color-rgb), 0.4);
  font-weight: 400;
}
.base-view-chat {
  height: auto !important;
  position: relative;
  background: var(--general-background-color);
}
chat {
  position: relative;
  display: block;
  padding-bottom: 50px;
}
chat .lazy-placeholder {
  padding: 15px;
  display: flex;
  align-items: center;
}
@media only screen and (min-width: 1025px) {
  chat .lazy-placeholder {
    max-width: 60vw;
  }
}
chat .lazy-placeholder .profile-image-lazy {
  width: 40px;
  height: 40px;
  min-width: 40px;
  background-color: rgba(var(--general-text-color-rgb), 0.15);
  border-radius: 50%;
  content: "";
}
chat .lazy-placeholder rl-loading-placeholder {
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  width: 80vw;
}
chat .lazy-placeholder rl-loading-placeholder .placeholder-shimmer {
  height: 16px;
}
chat .channel-image {
  border-color: var(--general-background-color);
}
chat .channel-image,
conversation .channel-image {
  background: var(--background-highlight-color);
  width: 40px;
  height: 40px;
  min-width: 40px;
  border-radius: 50%;
  padding: 0;
}
chat .channel-image .profile-image,
conversation .channel-image .profile-image {
  width: 100%;
  height: 100%;
}
chat .group-image,
conversation .group-image {
  position: relative;
  border-radius: 50%;
  z-index: 5;
  overflow: hidden;
  width: 40px;
  height: 40px;
  min-width: 40px;
  background-color: var(--background-highlight-color);
}
chat .group-image profile-image,
conversation .group-image profile-image {
  padding: 0;
}
chat .group-image .channel-image:nth-child(2),
conversation .group-image .channel-image:nth-child(2),
chat .group-image .channel-image:nth-child(3),
conversation .group-image .channel-image:nth-child(3) {
  position: absolute;
  min-width: 0;
  top: 0;
  width: 50%;
  left: 50%;
  border-left-width: 0.4px;
  border-left-style: solid;
}
chat .group-image .channel-image:nth-child(2) .profile-image,
conversation .group-image .channel-image:nth-child(2) .profile-image,
chat .group-image .channel-image:nth-child(3) .profile-image,
conversation .group-image .channel-image:nth-child(3) .profile-image {
  border-radius: 0;
}
chat .group-image.group-image-2 .channel-image:nth-child(2),
conversation .group-image.group-image-2 .channel-image:nth-child(2) {
  border-radius: 0 50% 50% 0;
}
chat .group-image.group-image-3 .channel-image:nth-child(2),
conversation .group-image.group-image-3 .channel-image:nth-child(2) {
  height: 50%;
  border-radius: 0 50% 0 0;
}
chat .group-image.group-image-3 .channel-image:nth-child(3),
conversation .group-image.group-image-3 .channel-image:nth-child(3) {
  height: 50%;
  top: 50%;
  border-radius: 0 0 50% 0;
  border-top-width: 0.4px;
  border-top-style: solid;
}
chat .empty-screen,
conversation .empty-screen {
  position: absolute;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
chat .empty-screen rl-icon,
conversation .empty-screen rl-icon {
  font-size: 4rem;
  color: var(--general-text-inactive-color);
}
chat .empty-screen .subtitle,
conversation .empty-screen .subtitle,
chat .empty-screen .title,
conversation .empty-screen .title {
  padding: 0 var(--spacing);
  line-height: 135%;
  max-width: 252px;
  text-align: center;
  margin: 0;
  font-size: 0.8rem;
}
chat .empty-screen .title,
conversation .empty-screen .title {
  font-size: 1rem;
  font-weight: bold;
  margin-top: var(--m-spacing);
}
chat .empty-screen rl-button,
conversation .empty-screen rl-button {
  margin-top: var(--spacing);
  width: 100%;
  max-width: 252px;
}
.chat-leave-confirm {
  z-index: 500;
}
.chat-leave-confirm .yes {
  font-size: 15px;
  color: var(--highlight-color);
}
.chat-leave-confirm .no {
  font-size: 15px;
  color: var(--warning-color);
  font-weight: normal;
}
.channels-list-action-sheet .actionsheet-header {
  padding: 0 0 var(--s-spacing) 0 !important;
}
.failed-chat-connect {
  top: 30vh;
}
report-popup .actionsheet {
  z-index: 11;
}
@media only screen and (min-width: 1025px) {
  report-popup .actionsheet {
    min-width: 450px;
  }
}
report-popup .actionsheet h4 {
  font-size: 1rem;
}
@media only screen and (max-width: 1024px) {
  report-popup .actionsheet h4 {
    border-bottom: unset;
  }
}
@media only screen and (min-width: 1025px) {
  report-popup .actionsheet .header-subtitle {
    padding: 0;
  }
}
report-popup .actionsheet .actionsheet-content {
  margin-top: var(--s-spacing);
  padding: var(--m-spacing);
}
@media only screen and (min-width: 1025px) {
  report-popup .actionsheet .actionsheet-content {
    padding: 0 var(--m-spacing);
  }
}
@media only screen and (max-width: 1024px) {
  report-popup .actionsheet .actionsheet-content {
    border-top: 1px solid var(--general-border-color);
  }
}
report-popup .sheet-back {
  position: absolute;
  top: 15px;
  left: 10px;
}
report-popup .sheet-back rl-icon {
  font-size: 1.5rem;
}
report-popup .report-content {
  display: flex;
  flex-direction: column;
}
report-popup .report-category {
  padding-left: var(--s-spacing);
  padding-top: var(--s-spacing);
}
report-popup .report-category .title {
  font-size: 1rem;
  font-weight: 500;
}
report-popup .report-category .item {
  height: auto;
  margin-bottom: var(--m-spacing);
}
report-popup .report-category .item .labels-container {
  gap: 2px;
}
report-popup .anonymous-text {
  color: rgba(var(--general-text-color-rgb), 0.4);
  margin-bottom: var(--m-spacing);
  margin-left: var(--s-spacing);
}
report-popup .report-category.selected {
  color: var(--highlight-color);
}
report-popup .report-title {
  font-size: 1rem;
}
report-popup .next-button {
  margin-top: var(--spacing);
  border-radius: var(--general-border-radius);
  color: var(--text-on-highlight-color);
  background-color: var(--highlight-color);
}
report-popup .next-button.disabled {
  opacity: 0.5;
  pointer-events: none;
}
report-popup .report-textarea {
  min-height: 100px;
  padding: var(--m-spacing);
  border-radius: var(--general-border-radius);
  border-color: var(--general-border-color);
  margin-left: var(--s-spacing);
  resize: none;
}
report-popup .anonymous-toggle {
  align-items: center;
  margin: var(--m-spacing) 0;
  display: flex;
  flex-direction: row;
  gap: var(--spacing);
  margin-left: var(--s-spacing);
}
report-popup .anonymous-toggle .help-text {
  color: rgba(var(--general-text-color-rgb), 0.4);
}
report-popup rl-toggle input[type=checkbox]:checked + label {
  background: var(--highlight-color);
}
.service-form-page {
  min-height: 100%;
  background-color: var(--background-highlight-color);
}
.native service-form > footer {
  padding-bottom: 51px;
  padding-bottom: calc(51px + env(safe-area-inset-bottom, 0px));
}
service-form > footer {
  position: fixed;
  z-index: 2;
  padding: 0;
  padding-bottom: env(safe-area-inset-bottom, 0px);
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  height: 42px;
  height: calc(42px + env(safe-area-inset-bottom, 0px));
  box-sizing: border-box;
  background: #fff;
  -webkit-box-shadow: 0 -1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
  box-shadow: 0 -1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
}
@media only screen and (min-width: 1025px) {
  service-form > footer {
    left: var(--sidemenu-width);
  }
}
service-form > footer button {
  display: -webkit-box;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 42px;
  width: 100px;
  max-width: 100px;
  padding: 0;
  margin: 0;
  border: none;
  background: none;
  cursor: pointer;
  text-align: center;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
}
service-form > footer button:hover {
  background: rgba(0, 0, 0, 0.12);
}
service-form > footer button .fa {
  display: block;
  font-size: 22px;
  margin-top: 3px;
}
service-form > footer button span {
  display: block;
  font-size: 11px;
  margin-top: 2px;
  font-weight: 600;
}
service-form > footer button.create {
  height: 50px;
  width: 50px;
  position: relative;
  overflow: hidden;
  background: #000;
  border-radius: 50px;
  margin: 0 5px;
  bottom: 8px;
  color: #fff;
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
}
service-form > footer button.create .mdi {
  height: 34px;
  line-height: 34px;
  font-size: 26px;
}
service-form > footer button.create:hover {
  background: #444;
}
service-form > footer filling-list-display {
  display: block;
  width: 100px;
}
service-form .service-form-content {
  height: 100%;
  box-sizing: border-box;
  padding-bottom: 42px;
}
@media only screen and (min-width: 1025px) {
  service-form .service-form-content {
    margin: 0 auto;
    max-width: 60vw;
  }
}
.sf-feedback-rating {
  display: block;
  box-sizing: border-box;
  width: 30px;
  min-width: 30px;
  height: 30px;
  min-height: 30px;
  line-height: 30px;
  color: var(--white-color);
  background: var(--highlight-color);
  font-weight: bold;
  text-align: center;
  border-radius: 50%;
  box-shadow: 0 4px 7px rgba(60, 72, 88, 0.1);
}
img.sf-feedback-rating {
  object-fit: contain;
  background-color: transparent;
  border-radius: unset;
  box-shadow: none;
  border-color: transparent;
}
create-data-list-item .popup-wrapper .popup-content-inner {
  padding: 10px 15px 75px 10px;
}
create-data-list-item .service-form-field {
  margin-bottom: 15px;
}
create-data-list-item footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding-bottom: 30px;
  padding-bottom: calc(30px + env(safe-area-inset-bottom, 0px));
}
create-data-list-item footer .right {
  display: flex;
  margin-left: auto;
  margin-right: 15px;
  filter: drop-shadow(0 6px 15px rgba(0, 0, 0, 0.15));
}
create-data-list-item footer .right button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  height: 46px;
  padding: 0 25px;
  font-weight: bold;
  background: var(--highlight-color);
  color: var(--text-on-highlight-color);
  border-radius: var(--general-border-radius);
  border: 1px solid var(--white-color);
}
create-filling {
  display: flex;
  flex: 1;
}
create-filling .popup-wrapper {
  z-index: 205;
}
create-filling .main,
update-filling .main {
  position: relative;
  padding: 15px 15px 0 15px;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}
create-filling .step,
update-filling .step {
  position: absolute;
  left: 15px;
  width: calc(100% - 30px);
  padding-bottom: 76px;
  padding-bottom: calc(76px + env(safe-area-inset-bottom, 0px));
}
create-filling section.next .step.ng-hide-remove,
update-filling section.next .step.ng-hide-remove {
  animation: fadeInRight 0.3s;
}
create-filling section.next .step.ng-hide-add,
update-filling section.next .step.ng-hide-add {
  animation: fadeOutLeft 0.3s;
}
create-filling section.prev .step.ng-hide-remove,
update-filling section.prev .step.ng-hide-remove {
  animation: fadeInLeft 0.3s;
}
create-filling section.prev .step.ng-hide-add,
update-filling section.prev .step.ng-hide-add {
  animation: fadeOutRight 0.3s;
}
create-filling .form-group:first-of-type h3,
update-filling .form-group:first-of-type h3 {
  margin-top: 0;
}
create-filling .form-group > h3,
update-filling .form-group > h3 {
  font-size: 21px;
  font-weight: bold;
  margin-top: 65px;
  margin-bottom: 15px;
}
create-filling .form-group > h3 p,
update-filling .form-group > h3 p {
  margin: 0;
}
create-filling .guide,
update-filling .guide {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: 100%;
}
create-filling .guide .outro,
update-filling .guide .outro {
  text-align: left;
  justify-content: normal;
}
create-filling .guide img,
update-filling .guide img,
create-filling .guide video,
update-filling .guide video,
create-filling .guide iframe,
update-filling .guide iframe {
  max-width: 100%;
}
create-filling .guide video,
update-filling .guide video {
  background: grey;
}
create-filling .guide button,
update-filling .guide button {
  min-width: 100px;
}
create-filling .guide .linked-forms,
update-filling .guide .linked-forms {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  margin: 30px -10px 15px -10px;
}
create-filling .guide .linked-forms:before,
update-filling .guide .linked-forms:before {
  content: '';
  display: block;
  width: 150%;
  margin: 0 -50px;
  background: #ededed;
  height: 1px;
  position: relative;
  top: -15px;
}
create-filling .guide .linked-forms .form-item,
update-filling .guide .linked-forms .form-item {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  flex-direction: row-reverse;
  width: 100%;
  margin: 10px 0;
  padding: 10px 25px;
  background: var(--highlight-color);
  color: var(--text-on-highlight-color);
  font-size: 18px;
  cursor: pointer;
}
create-filling .guide .linked-forms .form-item h2,
update-filling .guide .linked-forms .form-item h2 {
  margin: 0;
  padding: 0;
  font-size: 16px;
}
create-filling .guide .linked-forms .form-item p,
update-filling .guide .linked-forms .form-item p {
  margin: 0;
  padding-bottom: 5px;
}
create-filling .guide .linked-forms .form-item img,
update-filling .guide .linked-forms .form-item img {
  max-height: 30px;
  margin: 0 5px;
}
create-filling footer,
update-filling footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding-bottom: 30px;
  padding-bottom: calc(30px + env(safe-area-inset-bottom, 0px));
}
create-filling footer .right,
update-filling footer .right {
  display: flex;
  margin-left: auto;
  margin-right: 15px;
}
create-filling footer .loop,
update-filling footer .loop {
  height: 40px;
  line-height: 40px;
  margin: 0 0 0 15px;
  filter: drop-shadow(0 6px 15px rgba(0, 0, 0, 0.15));
  color: var(--general-text-color);
  background: var(--background-highlight-color);
  border-radius: var(--general-border-radius);
  border: 1px solid var(--white-color);
  padding: 0 10px;
}
create-filling footer .loop label,
update-filling footer .loop label {
  font-size: 15px;
}
create-filling footer .loop input,
update-filling footer .loop input {
  margin: 5px;
}
create-filling footer.single,
update-filling footer.single {
  justify-content: center;
}
create-filling footer span,
update-filling footer span {
  padding-top: 5px;
  font-size: 15px;
}
create-filling footer button,
update-filling footer button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 46px;
  height: 46px;
  padding: 0;
  color: var(--general-text-color);
  background: var(--background-highlight-color);
  border-radius: var(--general-border-radius);
  border: 1px solid var(--white-color);
}
create-filling footer button.submit,
update-filling footer button.submit {
  width: auto;
  padding: 0 25px;
  font-weight: bold;
  background: var(--highlight-color);
  color: var(--text-on-highlight-color);
}
create-filling footer button.btn-close,
update-filling footer button.btn-close {
  width: auto;
  padding: 0 25px;
  font-weight: bold;
}
create-filling footer.no-steps button.submit,
update-filling footer.no-steps button.submit {
  border-top-left-radius: var(--general-border-radius);
  border-bottom-left-radius: var(--general-border-radius);
}
.service-form-field-wrapper {
  position: relative;
  padding-bottom: 15px;
}
.service-form-field-wrapper.disabled:after {
  content: '';
  position: absolute;
  z-index: 30;
  top: 0;
  right: -15px;
  bottom: 0;
  left: -15px;
  background: rgba(var(--general-text-color-rgb), 0.1);
}
.service-form-field-wrapper.disabled .participant {
  padding-top: 10px;
}
.service-form-field-wrapper .participant {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 5px;
}
.service-form-field-wrapper .participant .left {
  display: flex;
  align-items: center;
}
.service-form-field-wrapper .participant profile-image {
  line-height: 0;
}
.service-form-field-wrapper .participant profile-image .profile-image {
  min-width: 26px;
  min-height: 26px;
}
.service-form-field-wrapper .border {
  display: none;
}
filling-item,
filling-loading-item {
  box-sizing: border-box;
}
filling-item .media-preview :not(.multiple) .media .image,
filling-loading-item .media-preview :not(.multiple) .media .image {
  height: 130px;
  justify-content: center;
  display: flex;
  align-items: center;
  background: rgba(0, 0, 0, 0.6);
}
@media only screen and (max-width: 767px) {
  filling-item .media-preview .multiple .media-preview-list .media-preview-list-col,
  filling-loading-item .media-preview .multiple .media-preview-list .media-preview-list-col {
    height: 130px;
  }
  filling-item .media-preview .multiple .media-preview-list .media-preview-list-col .media-preview-list-item,
  filling-loading-item .media-preview .multiple .media-preview-list .media-preview-list-col .media-preview-list-item {
    height: 130px;
    line-height: 130px;
  }
  filling-item .media-preview .multiple .media-preview-list .media-preview-list-col .media-preview-list-item.small,
  filling-loading-item .media-preview .multiple .media-preview-list .media-preview-list-col .media-preview-list-item.small {
    height: 65px;
    line-height: 65px;
  }
}
@media only screen and (min-width: 768px) {
  filling-item .media-preview :not(.multiple) .media .image,
  filling-loading-item .media-preview :not(.multiple) .media .image {
    height: 200px;
  }
}
filling-item .filling-item-container,
filling-loading-item .filling-item-container {
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  border-radius: var(--general-border-radius);
  background: var(--general-background-color);
}
filling-item .filling-item-container.draft .field-media:before,
filling-loading-item .filling-item-container.draft .field-media:before {
  content: '';
  display: block;
  position: absolute;
  background: rgba(0, 0, 0, 0.1);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
filling-item .content,
filling-loading-item .content {
  flex: 1 1 auto;
  box-sizing: border-box;
  padding: 0 7px 10px 7px;
  cursor: pointer;
  word-break: break-word;
}
filling-item .content > div:first-of-type h2,
filling-loading-item .content > div:first-of-type h2 {
  margin-top: 5px;
}
filling-item .icons-group,
filling-loading-item .icons-group {
  z-index: 1;
  display: flex;
  gap: 10px;
}
filling-item .badges-container,
filling-loading-item .badges-container {
  display: flex;
  position: absolute;
  right: 5px;
  top: 5px;
  flex-direction: column;
  align-items: flex-end;
}
filling-item .badges-container.left,
filling-loading-item .badges-container.left {
  display: flex;
  gap: var(--s-spacing);
  flex-direction: row;
  align-items: center;
  right: unset;
  top: unset;
  left: 5px;
  bottom: 5px;
}
filling-item .badges-container .comments-icon,
filling-loading-item .badges-container .comments-icon {
  background-color: var(--warning-color);
  color: var(--text-on-highlight-color);
  padding: 5px;
  border-radius: 7px;
  font-size: 13px;
}
filling-item .badges-container .label,
filling-loading-item .badges-container .label {
  z-index: 2;
  padding: 6px 8px;
  margin-bottom: 4px;
  border-radius: var(--general-border-radius);
  backdrop-filter: blur(24px);
  font-size: 13px;
  font-weight: bold;
  color: var(--white-color);
}
filling-item .badges-container .label.draft,
filling-loading-item .badges-container .label.draft {
  background: rgba(0, 0, 0, 0.6);
}
filling-item .badges-container .label.new,
filling-loading-item .badges-container .label.new {
  color: var(--white-color);
}
filling-item footer,
filling-loading-item footer {
  display: flex;
  flex-direction: column;
  cursor: pointer;
}
filling-item footer .basic-data,
filling-loading-item footer .basic-data {
  display: flex;
  flex-direction: column;
  padding: 10px;
}
filling-item footer .basic-data p,
filling-loading-item footer .basic-data p {
  margin: 0 0 5px 0;
  padding: 0;
  display: block;
  font-size: 13px;
  color: var(--general-text-inactive-color);
}
@media only screen and (min-width: 768px) {
  filling-item footer .basic-data p,
  filling-loading-item footer .basic-data p {
    display: inline-block;
  }
}
filling-item footer .basic-data p span,
filling-loading-item footer .basic-data p span {
  margin-left: 3px;
}
filling-item footer .basic-data rl-icon,
filling-loading-item footer .basic-data rl-icon {
  font-size: 13px;
  color: var(--general-text-inactive-color);
}
filling-item footer .basic-data rl-loading-placeholder .placeholder-shimmer,
filling-loading-item footer .basic-data rl-loading-placeholder .placeholder-shimmer {
  height: 15px;
}
filling-item footer .buttons,
filling-loading-item footer .buttons {
  display: flex;
  flex-direction: row;
  border-top: 1px solid var(--general-border-color);
}
filling-item footer .buttons reaction,
filling-loading-item footer .buttons reaction {
  display: flex;
  flex: 1;
}
filling-item footer .buttons button,
filling-loading-item footer .buttons button {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: 0;
  height: 40px;
  box-sizing: border-box;
  border: none;
  background: none;
  cursor: pointer;
}
filling-item footer .buttons button rl-icon,
filling-loading-item footer .buttons button rl-icon {
  font-size: 14px;
}
filling-item footer .buttons button > span,
filling-loading-item footer .buttons button > span {
  font-size: 13px;
  margin-left: 6px;
}
filling-item .service-form-filling-field,
filling-loading-item .service-form-filling-field {
  word-break: break-word;
  word-break: auto-phrase;
}
filling-item .service-form-filling-field:not(:last-of-type),
filling-loading-item .service-form-filling-field:not(:last-of-type) {
  margin-bottom: 8px;
}
filling-item .service-form-filling-field h2,
filling-loading-item .service-form-filling-field h2 {
  font-size: 14px;
  padding: 0;
  margin: 0 0 4px 0;
}
filling-item .service-form-filling-field p,
filling-loading-item .service-form-filling-field p {
  font-size: 12px;
  margin: 0;
}
filling-item .service-form-filling-field p.italic,
filling-loading-item .service-form-filling-field p.italic {
  font-style: italic;
}
filling-item .service-form-filling-field .field-tags,
filling-loading-item .service-form-filling-field .field-tags {
  display: flex;
  overflow-y: auto;
  white-space: nowrap;
  gap: 7px;
}
filling-item .service-form-filling-field .field-tags div,
filling-loading-item .service-form-filling-field .field-tags div {
  display: inline-block;
  vertical-align: top;
  padding: 5px 7px;
  box-sizing: border-box;
  align-items: center;
  font-weight: bold;
  border-radius: var(--general-border-radius);
  font-size: 13px;
  background: var(--background-highlight-color);
  color: var(--general-text-color);
}
filling-item .service-form-filling-field .field-media,
filling-loading-item .service-form-filling-field .field-media {
  position: relative;
  margin-left: -7px;
  margin-right: -7px;
  margin-bottom: 5px;
}
filling-item .service-form-filling-field .field-media .img-placeholder,
filling-loading-item .service-form-filling-field .field-media .img-placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;
  background: rgba(0, 0, 0, 0.1);
}
filling-item .service-form-filling-field .field-media .img-placeholder rl-icon,
filling-loading-item .service-form-filling-field .field-media .img-placeholder rl-icon {
  color: var(--general-text-inactive-color);
  font-size: 63px;
}
filling-item .service-form-filling-field .field-media media-preview,
filling-loading-item .service-form-filling-field .field-media media-preview {
  display: block;
  --media-preview-item-height: 150px;
}
filling-item .service-form-filling-field .field-media media-preview img,
filling-loading-item .service-form-filling-field .field-media media-preview img {
  height: 150px;
  object-fit: cover;
}
filling-item .service-form-filling-field .field-media media-preview:before,
filling-loading-item .service-form-filling-field .field-media media-preview:before {
  position: absolute;
  content: '';
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 5;
}
filling-item .service-form-filling-field .field-media .performance-range-badge,
filling-loading-item .service-form-filling-field .field-media .performance-range-badge {
  padding: 6px 8px;
  min-width: 20px;
  border-radius: var(--general-border-radius);
  background-color: var(--warning-color);
  color: var(--text-on-highlight-color);
  text-align: center;
  font-size: 13px;
  font-weight: bold;
}
filling-item .service-form-filling-field .field-media .sf-feedback-rating,
filling-loading-item .service-form-filling-field .field-media .sf-feedback-rating {
  margin-bottom: 4px;
}
filling-list {
  display: block;
  height: 100%;
  padding: 15px;
}
filling-list .no-content i {
  color: var(--general-text-inactive-color);
  font-size: 4rem;
  margin-bottom: var(--spacing);
}
filling-list .no-content p {
  color: var(--general-text-color);
  font-size: 0.9rem;
}
.filling-list {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}
.filling-list.single filling-item,
.filling-list.single filling-loading-item {
  width: 100%;
}
.filling-list.multiple filling-item,
.filling-list.multiple filling-loading-item {
  width: calc((100% - 15px) / 2);
}
@media only screen and (min-width: 768px) {
  .filling-list.multiple filling-item,
  .filling-list.multiple filling-loading-item {
    width: calc((100% - 30px) / 3);
  }
}
@media only screen and (min-width: 1300px) {
  .filling-list.multiple filling-item,
  .filling-list.multiple filling-loading-item {
    width: calc((100% - 45px) / 4);
  }
}
score-report > .header {
  padding: 10px;
  box-shadow: 0 2px 7px 0 rgba(var(--general-text-color-rgb), 0.1);
}
score-report > .header h2 {
  margin: 0;
}
score-report > .header rl-progress .percentage {
  font-size: 20px;
}
score-report > .header .top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}
score-report > .header .bottom rl-progress {
  margin-bottom: 30px;
}
score-report > .header .bottom .performance-badge {
  margin-top: 13px;
}
score-report > .header .performance-badge {
  display: inline-block;
  box-sizing: border-box;
  height: 30px;
  min-width: 30px;
  line-height: 30px;
  padding: 0 10px;
  border-radius: 50px;
  text-align: center;
  text-transform: uppercase;
  color: var(--white-color);
}
score-report > .header .performance-range {
  margin-top: 30px;
}
score-report > .header .performance-range > h2 {
  margin: 0 0 10px 0;
}
score-report rl-button {
  text-transform: uppercase;
  display: block;
}
score-report rl-button button {
  display: block;
  margin: 0 auto;
  color: var(--general-text-color);
}
score-report .categories {
  margin-top: 25px;
}
score-report .categories .count {
  display: inline-block;
  margin-bottom: 10px;
}
score-report .category {
  padding: 10px;
  box-shadow: 0 2px 7px 0 rgba(var(--general-text-color-rgb), 0.1);
}
score-report .category:not(:last-of-type) {
  margin-bottom: 15px;
}
score-report .category h3 {
  margin: 0 0 15px 0;
}
filling-content .filling-content:not(.show-feedback-only) > div:not(:first-of-type) {
  margin-top: 65px;
}
filling-content .filling-content.show-feedback-only .service-form-filling-group:not(:last-of-type) {
  margin-bottom: 15px;
}
filling-content .service-form-filling-group > h2 {
  font-size: 21px;
  font-weight: bold;
  padding-bottom: 10px;
  margin-top: 0;
  margin-bottom: 15px;
  border-bottom: 1px solid var(--general-border-color);
}
filling-content .service-form-filling-group > h2 p {
  margin: 0;
}
filling-content .service-form-filling-field {
  word-break: break-word;
  word-break: auto-phrase;
  padding: 10px;
  border-radius: var(--general-border-radius);
  background: var(--background-highlight-color);
}
filling-content .service-form-filling-field:not(:last-of-type) {
  margin-bottom: 15px;
}
filling-content .service-form-filling-field media-preview {
  overflow: hidden;
  border-radius: var(--general-border-radius);
}
filling-content .service-form-filling-field .multiline {
  word-break: break-word;
}
filling-content .service-form-filling-field > .title {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  min-height: 20px;
  padding-bottom: 10px;
  box-sizing: border-box;
}
filling-content .service-form-filling-field > .title.bottom-space {
  margin-bottom: 10px;
}
filling-content .service-form-filling-field > .title h3 {
  font-weight: bold;
  font-size: 15px;
  line-height: 1.3;
  margin: 0 10px 0 0;
}
filling-content .service-form-filling-field > .title .right {
  display: flex;
  align-items: center;
}
filling-content .service-form-filling-field > .title .right .comments {
  white-space: nowrap;
}
filling-content .service-form-filling-field > .title .right > *:not(:last-child) {
  margin-right: 10px;
}
filling-content .service-form-filling-field > .title rl-button[icon=edit] rl-icon {
  font-size: 15px;
}
filling-content .service-form-filling-field > .title rl-button button {
  min-width: 30px;
  width: 30px;
  height: 30px;
  background: var(--highlight-color);
  filter: drop-shadow(0px 6px 15px rgba(0, 0, 0, 0.15));
}
filling-content .service-form-filling-field > .title rl-button button rl-icon {
  font-size: 15px;
  color: var(--text-on-highlight-color);
}
filling-content .service-form-filling-field .field-help-text {
  display: block;
  position: relative;
  z-index: 2;
  font-size: 12px;
  opacity: 0.5;
  color: var(--general-text-color);
  line-height: 12px;
  margin: 5px 0 10px 0;
}
filling-content .service-form-filling-field p {
  margin: 0;
  font-size: 15px;
  line-height: 1.3;
  color: var(--general-text-inactive-color);
}
filling-content .service-form-filling-field .field-tags {
  position: relative;
  z-index: 2;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 7px;
}
filling-content .service-form-filling-field .field-tags div {
  display: inline-block;
  vertical-align: top;
  padding: 5px 7px;
  box-sizing: border-box;
  align-items: center;
  font-weight: bold;
  border-radius: var(--general-border-radius);
  font-size: 13px;
  background: var(--general-background-color);
  color: var(--general-text-color);
}
filling-content .feedback-buttons-wrapper rl-button {
  display: block;
  margin-top: 15px;
}
filling-content .feedback-buttons-wrapper rl-button + rl-button button {
  background-color: var(--general-background-color);
  border: 1px solid var(--highlight-color);
  color: var(--highlight-color);
}
filling-content footer {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  padding: 25px 15px 15px;
  background: var(--background-highlight-color);
  border: 1px solid var(--general-border-color);
  border-radius: var(--general-border-radius);
}
filling-content footer [icon="info"] {
  position: absolute;
  top: -13px;
  left: 13px;
  width: 26px;
  border-radius: 50%;
  font-size: 26px;
  background: var(--white-color);
  color: var(--general-text-inactive-color);
}
filling-content footer p {
  margin: 0;
  padding: 0;
  display: inline-block;
  color: var(--general-text-inactive-color);
}
filling-content footer p:not(:last-of-type) {
  padding-bottom: 5px;
}
filling-content footer p span {
  margin-left: 3px;
}
filling-content footer rl-icon {
  font-size: 13px;
}
filling-content footer .customer button {
  padding: 0;
}
stage-content-popup .main {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
}
stage-content-popup .main translation-button {
  padding: 15px 15px 0 15px;
  margin: 0;
}
stage-content-popup .status {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  cursor: pointer;
}
stage-content-popup .status.approved {
  color: var(--confirmation-color);
  background: rgba(var(--confirmation-color-rgb), 0.2);
}
stage-content-popup .status.approved rl-icon {
  background: var(--confirmation-color);
  color: var(--white-color);
}
stage-content-popup .status.rejected {
  color: var(--warning-color);
  background: rgba(var(--warning-color-rgb), 0.2);
}
stage-content-popup .status.rejected rl-icon {
  background: var(--warning-color);
  color: var(--white-color);
}
stage-content-popup .status .user-wrapper {
  position: relative;
  margin-right: 15px;
  height: 25px;
}
stage-content-popup .status .user-wrapper .img {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  overflow: hidden;
}
stage-content-popup .status .user-wrapper rl-icon {
  position: absolute;
  right: -5px;
  bottom: -1px;
  width: 12px;
  height: 12px;
  font-size: 8px;
}
stage-content-popup .status .text {
  font-size: 13px;
  font-weight: 600;
}
stage-content-popup filling-content {
  display: block;
  padding: 15px;
}
stage-content-popup .approval-section {
  margin-top: auto;
  padding: 25px 15px 50px 15px;
  background: var(--background-highlight-color);
  text-align: center;
}
stage-content-popup .approval-section h3 {
  margin: 0 0 32px 0;
}
stage-content-popup .approval-section approve-slider {
  margin: 0 auto;
}
prompt-popup .stage-review.approve rl-icon {
  box-shadow: 0 5px 7px rgba(var(--confirmation-color-rgb, 0.3));
}
prompt-popup .stage-review.approve rl-icon,
prompt-popup .stage-review.approve rl-button button {
  background: var(--confirmation-color);
}
prompt-popup .stage-review.reject rl-icon {
  box-shadow: 0 5px 7px rgba(var(--warning-color-rgb, 0.3));
}
prompt-popup .stage-review.reject rl-icon,
prompt-popup .stage-review.reject rl-button button:not([disabled]) {
  background: var(--warning-color);
}
stage-feedback-popup .main {
  overflow-y: auto;
  flex: 1;
  padding: 25px;
}
stage-feedback-popup .main.approved {
  background-image: linear-gradient(to bottom, rgba(var(--confirmation-color-rgb), 0.5), 15%, rgba(var(--general-text-color-rgb), 0));
}
stage-feedback-popup .main.approved .feedback-person rl-icon {
  background: var(--confirmation-color);
}
stage-feedback-popup .main.approved .feedback-status {
  color: var(--confirmation-color);
}
stage-feedback-popup .main.rejected {
  background-image: linear-gradient(to bottom, rgba(var(--warning-color-rgb), 0.5), 15%, rgba(var(--general-text-color-rgb), 0));
}
stage-feedback-popup .main.rejected .feedback-person rl-icon {
  background: var(--warning-color);
}
stage-feedback-popup .main.rejected .feedback-status {
  color: var(--warning-color);
}
stage-feedback-popup .main .feedback-person {
  display: flex;
  margin-bottom: 50px;
}
stage-feedback-popup .main .feedback-person .img-wrapper {
  position: relative;
  height: 70px;
}
stage-feedback-popup .main .feedback-person .img-wrapper .img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
}
stage-feedback-popup .main .feedback-person .img-wrapper rl-icon {
  position: absolute;
  bottom: 0;
  right: 0;
  color: var(--white-color);
}
stage-feedback-popup .main .feedback-person .text {
  display: flex;
  flex-direction: column;
  margin-left: 15px;
}
stage-feedback-popup .main .feedback-person .text span:not(:last-of-type) {
  margin-bottom: 5px;
}
stage-feedback-popup .main .feedback-comments > h3 {
  margin: 0 0 15px 0;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 600;
}
stage-feedback-popup .main .feedback-comments .comment-item {
  display: flex;
}
stage-feedback-popup .main .feedback-comments .comment-item:not(:last-of-type) {
  margin-bottom: 8px;
}
stage-feedback-popup .main .feedback-comments .comment-item.approved rl-icon {
  background: var(--confirmation-color);
}
stage-feedback-popup .main .feedback-comments .comment-item.approved .status {
  color: var(--confirmation-color);
}
stage-feedback-popup .main .feedback-comments .comment-item.rejected rl-icon {
  background: var(--warning-color);
}
stage-feedback-popup .main .feedback-comments .comment-item.rejected .status {
  color: var(--warning-color);
}
stage-feedback-popup .main .feedback-comments .comment-item .image-wrap {
  position: relative;
  height: 30px;
  margin-right: 10px;
}
stage-feedback-popup .main .feedback-comments .comment-item .image-wrap .profile-image {
  width: 30px;
  height: 30px;
  padding-right: 0;
}
stage-feedback-popup .main .feedback-comments .comment-item .image-wrap rl-icon {
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 9px;
  width: 13px;
  height: 13px;
  color: var(--white-color);
}
stage-feedback-popup .main .feedback-comments .comment-item .comment-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}
stage-feedback-popup .main .feedback-comments .comment-item .comment-wrapper .translate-btn {
  padding: 5px 0 10px 0;
}
stage-feedback-popup .main .feedback-comments .comment-item .comment-content {
  width: 100%;
  background: var(--general-background-color);
  padding: 10px;
  border-radius: 15px;
  position: relative;
}
stage-feedback-popup .main .feedback-comments .comment-item .comment-content .top {
  display: flex;
  justify-content: space-between;
}
stage-feedback-popup .main .feedback-comments .comment-item .comment-content h3 {
  margin: 0;
  font-size: 13px;
  font-weight: 600;
}
stage-feedback-popup .main .feedback-comments .comment-item .comment-content p {
  margin: 0;
}
stage-feedback-popup .main .feedback-comments .comment-item .comment-content:after {
  content: "";
  position: absolute;
  margin-top: -6px;
  margin-left: -5px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid var(--general-background-color);
  transform: rotate(45deg);
  left: 0;
  top: 15px;
}
want-to-rate-popup .actionsheet-overlay {
  background: rgba(0, 0, 0, 0.1) !important;
}
want-to-rate-popup h4 {
  border: 0 !important;
  margin: 0 !important;
}
want-to-rate-popup .buttons {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}
want-to-rate-popup .buttons rl-button {
  flex: 1;
}
want-to-rate-popup .buttons rl-button button {
  width: 100%;
}
rate-popup .actionsheet {
  min-width: 350px;
}
rate-popup .rating-buttons {
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
}
rate-popup .rating-range {
  display: flex;
  justify-content: space-around;
  margin-bottom: 30px;
  --rating-item-size: 60px;
}
@media only screen and (min-width: 1025px) {
  rate-popup .rating-range {
    gap: 20px;
  }
}
rate-popup .rating-range .rating-item {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  max-width: var(--rating-item-size);
  max-height: var(--rating-item-size);
  width: var(--rating-item-size);
  height: var(--rating-item-size);
  cursor: pointer;
  font-weight: bold;
  border-radius: 50%;
  opacity: 0.5;
  box-shadow: 0 4px 7px rgba(60, 72, 88, 0.1);
  background-color: var(--highlight-color);
}
rate-popup .rating-range .rating-item.active,
rate-popup .rating-range .rating-item:hover {
  opacity: 1;
}
rate-popup .rating-range img.rating-item {
  object-fit: contain;
  box-shadow: unset !important;
  border: none !important;
  border-radius: unset !important;
  background-color: transparent !important;
}
rate-popup .rating-range[data-length='5'] span {
  width: calc((100vw - 60px) / 5);
  height: calc((100vw - 60px) / 5);
}
rate-popup .rating-range[data-length='6'] span {
  width: calc((100vw - 60px) / 6);
  height: calc((100vw - 60px) / 6);
}
rate-popup rl-textarea {
  margin-bottom: 15px;
}
rate-popup footer {
  display: flex;
}
rate-popup footer rl-button:first-of-type {
  flex: 1;
}
rate-popup footer rl-button[icon="delete-alt"] {
  width: 20%;
  min-width: 50px;
  margin-left: 10px;
}
rate-popup footer rl-button[icon="delete-alt"] button {
  min-width: auto;
  width: 100%;
}
feedback-comments-popup .form-feedback {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  width: 100%;
  padding: 0 15px;
  box-sizing: border-box;
  box-shadow: 0 7px 15px rgba(60, 72, 88, 0.1);
}
feedback-comments-popup .form-feedback .profile-image {
  width: 24px;
  height: 24px;
  min-width: 24px;
  margin: 0 6px;
}
feedback-comments-popup .form-feedback .rl-subheadline {
  margin-right: 10px;
}
feedback-comments-popup comments.has-feedback .post-comments {
  height: calc(100% - 60px - 55px);
}
filling-preview-popup header-popup title-slot {
  display: flex;
  width: 100%;
  justify-content: center;
}
filling-preview-popup header-popup title-slot .wrap {
  overflow: hidden;
}
filling-preview-popup .label {
  padding: 5px 10px;
  text-align: center;
  color: var(--text-on-highlight-color);
  text-transform: uppercase;
  font-weight: normal;
}
filling-preview-popup .label.draft {
  background: var(--highlight-color);
}
filling-preview-popup .label.new {
  background: var(--confirmation-color);
}
filling-preview-popup .popup-wrapper .popup-content .modal-loader {
  align-items: flex-start;
  border-top: 55px solid var(--header-background-color);
}
filling-preview-popup .popup-wrapper .popup-content .modal-loader.inner {
  border: none;
}
filling-preview-popup .popup-wrapper .popup-content .modal-loader .shimmer-container {
  padding: var(--m-spacing) var(--spacing);
}
filling-preview-popup .popup-wrapper .popup-content .modal-loader .lazy-placeholder {
  background-color: var(--background-highlight-color);
  padding: 10px 0;
}
filling-preview-popup filling-preview {
  flex: 1;
  overflow-y: auto;
  padding: 10px 15px;
}
filling-preview-popup filling-content {
  display: block;
  margin-top: 30px;
}
filling-preview-popup .feedback-buttons-wrapper {
  margin-top: 15px;
}
filling-preview-popup .continue-draft {
  padding: 15px;
}
filling-preview-popup .tabs,
stage-content-popup .tabs {
  width: 100%;
  display: flex;
}
filling-preview-popup .tabs div,
stage-content-popup .tabs div {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  height: 40px;
  font-size: 13px;
  line-height: 130%;
  color: rgba(var(--general-text-color-rgb), 0.4);
  border-bottom: 1px solid var(--general-border-color);
}
filling-preview-popup .tabs div.active,
stage-content-popup .tabs div.active {
  font-weight: bold;
  color: var(--highlight-color);
  border-bottom: 3px solid var(--highlight-color);
}
.filling-full-view .filling-actions-footer {
  display: flex;
  flex-direction: column;
  padding-top: 15px;
}
@media only screen and (min-width: 1025px) {
  .filling-full-view .filling-actions-footer {
    flex-direction: row;
    justify-content: space-between;
  }
}
.filling-full-view .filling-actions-footer .filling-actions-info {
  text-align: center;
}
@media only screen and (max-width: 1024px) {
  .filling-full-view .filling-actions-footer .filling-actions-info {
    border-bottom: 1px solid var(--general-border-color);
  }
}
@media only screen and (min-width: 1025px) {
  .filling-full-view .filling-actions-footer .filling-actions-info {
    text-align: left;
  }
  .filling-full-view .filling-actions-footer .filling-actions-info button {
    height: 50px;
  }
}
.filling-full-view .filling-actions-footer .filling-actions {
  text-align: center;
}
.filling-full-view .filling-actions-footer .filling-actions button {
  height: 50px;
  color: var(--text-on-white-color);
}
@media only screen and (min-width: 1025px) {
  .filling-full-view .filling-actions-footer .filling-actions {
    text-align: right;
  }
}
.filling-full-view filling-actions {
  display: flex;
}
.filling-full-view filling-actions button {
  width: 50%;
  height: 36px;
  margin: 10px 0;
  line-height: 36px;
  cursor: pointer;
  vertical-align: top;
  color: #fff;
  position: relative;
  overflow: hidden;
  border: none;
  text-transform: uppercase;
}
.filling-full-view filling-actions button:not(:disabled):hover:after {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  content: '';
  background: rgba(255, 255, 255, 0.1);
  cursor: pointer;
}
.filling-full-view filling-actions button[disabled] {
  opacity: 0.5;
}
.filling-full-view filling-actions button.delete {
  background: #ED4248;
}
.filling-full-view filling-actions button.edit {
  background: #999;
}
.service-form-filling-page filling-preview {
  display: block;
  padding: 15px;
  -webkit-overflow-scrolling: touch;
}
filling-preview translation-button,
stage-content-popup translation-button {
  margin-bottom: 15px;
}
filling-preview translation-button button,
stage-content-popup translation-button button {
  width: 100%;
  height: 50px;
  padding: 10px;
  box-sizing: border-box;
  align-items: center;
  border-radius: var(--general-border-radius);
  background: rgba(var(--highlight-color-rgb), 0.1);
  border: 1px solid rgba(var(--highlight-color-rgb), 0.2);
}
feedback-inline-comments {
  display: block;
  padding-top: 15px;
  margin-top: 15px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
feedback-inline-comments comment-item {
  padding: 0;
}
feedback-inline-comments comment-item.child {
  padding-left: 65px;
}
feedback-inline-comments comment-item profile-image > .profile-image {
  width: 30px;
  height: 30px;
}
feedback-inline-comments comment-item:last-of-type {
  margin-bottom: 0;
}
feedback-inline-comments comment-item .comments-actions {
  padding: 5px 0 10px 0;
  top: auto;
  min-height: auto;
}
feedback-inline-comments rl-button {
  padding-top: 15px;
}
feedback-inline-comments rl-button button {
  height: auto;
  font-weight: 400;
  color: rgba(var(--general-text-color-rgb), 0.4) !important;
}
fillings-overview .popup-content-inner.popup-content-inner {
  justify-content: space-between;
  padding: 0;
}
fillings-overview .overview-wrapper {
  text-align: center;
}
fillings-overview .create {
  box-sizing: border-box;
  height: 46px;
  font-weight: bold;
  font-size: 15px;
  width: 90%;
  margin-top: 20px;
}
fillings-overview .footer {
  text-align: center;
}
fillings-overview .footer .finish {
  width: 50%;
  height: 46px;
}
@media only screen and (max-width: 767px) {
  fillings-overview .footer .finish {
    width: 100%;
  }
}
@media only screen and (min-width: 768px) {
  fillings-overview .footer .finish {
    margin-bottom: 10px;
  }
}
fillings-overview-content {
  display: block;
  overflow-x: auto;
}
fillings-overview-content table {
  border-collapse: collapse;
  border-bottom: 1px solid #EBECEE;
  margin-top: 10px;
}
fillings-overview-content table thead {
  border-bottom: 1px solid #EBECEE;
}
fillings-overview-content table th {
  padding: 5px 4px;
  white-space: nowrap;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  font-size: 13px;
  font-weight: bold;
}
fillings-overview-content table th.title {
  padding: 10px 0;
  font-size: 15px;
  font-weight: 600;
}
fillings-overview-content table th:first-child {
  padding-left: 8px;
}
fillings-overview-content table tbody tr:nth-child(even) {
  background: #F7F8FA;
}
fillings-overview-content table tbody td {
  text-align: left;
  padding: 10px 4px;
  font-size: 14px;
}
fillings-overview-content table tbody td:first-child {
  padding-left: 8px;
}
fillings-overview-content table tbody td .field-tags div {
  vertical-align: top;
  margin: 0 5px 5px 0;
  box-sizing: border-box;
  font-size: 13px;
}
fillings-overview-content table tbody td p {
  margin: 0;
}
fillings-overview-content table .delete {
  color: #EB6262;
  font-size: 13px;
}
filter-fillings customer-field {
  display: block;
}
filter-fillings form {
  display: flex;
  flex-direction: column;
  padding: 0;
  height: 100%;
}
filter-fillings form .service-form-field {
  margin: 0;
  border: none;
}
filter-fillings form .service-form-field > div {
  width: 100%;
}
filter-fillings form .service-form-field .service-form-slider {
  padding: 10px 0;
  text-align: center;
}
filter-fillings form .service-form-field .service-form-slider label {
  font-weight: 500;
  color: var(--general-text-color);
  font-size: 14px;
  display: block;
  text-align: left;
  padding: 0 8px;
}
filter-fillings form .service-form-field:not(:last-of-type) {
  margin: 0;
}
filter-fillings form .service-form-field:not(:first-of-type) {
  margin-top: -1px;
}
filter-fillings form .service-form-field .service-form-field-selector {
  display: block;
}
filter-fillings form .service-form-field .service-form-field-selector:not(:first-of-type) {
  margin-top: -1px;
}
filter-fillings form .service-form-field .service-form-field-selector > label {
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  position: relative;
}
filter-fillings form .service-form-field .service-form-field-selector > label > span:first-child {
  width: 200px;
  max-width: 45%;
  font-weight: 600;
  color: var(--general-text-color);
  flex: 1 0 auto;
  display: block;
  padding: 0 8px;
  word-wrap: break-word;
  position: relative;
  min-width: 0;
  white-space: normal;
  font-size: 14px;
}
filter-fillings form .service-form-field .service-form-field-selector > label > span:last-child:not(.chkSwitch) {
  padding: 0 8px;
  flex: 1 1 auto;
}
filter-fillings form .service-form-field .service-form-field-selector > label input[type=date] {
  padding: 0;
  height: 30px;
  line-height: 30px;
  background: none;
  border: 0;
}
filter-fillings form .service-form-field .service-form-field-selector > label .chkSwitch {
  text-align: right;
  width: 100%;
  margin-right: 8px;
}
filter-fillings form .service-form-field .service-form-field-selector > label .ripple-effect {
  position: absolute;
}
filter-fillings .save {
  line-height: normal;
}
filter-fillings .popup-wrapper .popup-content-inner {
  padding: 0 0 10px 0;
}
filter-fillings .popup-wrapper .popup-footer {
  margin: 0;
}
filter-fillings .popup-wrapper .popup-footer button {
  width: 50%;
}
filter-fillings .popup-wrapper .popup-footer button.reset {
  background: #b7b7b7;
  color: #000;
}
filter-fillings .popup-wrapper .popup-footer button.reset:hover {
  background: #a5a2a2;
}
filter-fillings .popup-wrapper .popup-content .modal-loader {
  align-items: flex-start;
}
filter-fillings .popup-wrapper .popup-content .modal-loader rl-loading-placeholder {
  padding: 0;
}
filter-fillings .popup-wrapper .popup-content .modal-loader rl-loading-placeholder .placeholder-shimmer {
  height: 45px;
  background-color: var(--background-highlight-color);
}
filter-fillings .rzslider {
  width: calc(100% - 20px);
  margin: 20px 0 30px 0;
}
filter-fillings .rzslider .rz-bar {
  background: rgba(var(--highlight-color-rgb), .15);
}
filter-fillings .rzslider .rz-pointer {
  background-color: var(--highlight-color);
  width: 24px;
  height: 24px;
  top: -10px;
}
filter-fillings .rzslider .rz-pointer:after {
  content: none;
}
filter-fillings .rzslider .rz-bubble {
  font-size: 14px;
  font-weight: 500;
  bottom: auto;
  top: 16px;
}
service-form-live-tile {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
service-form-live-tile rl-badge {
  position: absolute;
  top: 5px;
  right: 5px;
}
service-form-live-tile .filling {
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: 25px 10px;
  opacity: 0;
  background-size: cover;
  background-position: center;
  transition: opacity 0.3s ease-in;
}
service-form-live-tile .filling.active {
  opacity: 1;
  z-index: 5;
}
service-form-live-tile header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
service-form-live-tile header span {
  color: white;
  text-shadow: 0px 0px 20px #000000;
}
service-form-live-tile header .icon {
  padding-right: 5px;
}
@media only screen and (max-width: 767px) {
  service-form-live-tile footer {
    display: none;
  }
}
service-form-live-tile footer .title {
  display: block;
  font-size: 18px;
  padding-bottom: 5px;
}
service-form-live-tile footer span {
  color: white;
  text-shadow: 0px 0px 10px #969696;
}
service-form-live-tile footer .meta span:not(:last-of-type):after {
  content: '';
  display: inline-block;
  margin: 3px 5px;
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background: white;
  line-height: 15px;
}
map-popup #map {
  position: absolute;
  top: 55px;
  right: 0;
  bottom: 0;
  left: 0;
}
customer-field {
  display: flex;
}
customer-field lazy-datalist {
  flex: 1;
}
customer-field lazy-datalist .lazy-datalist {
  height: 100%;
}
customer-field lazy-datalist .lazy-datalist .lazy-datalist-btn {
  height: auto;
  line-height: 1;
  padding: 12px;
}
customer-field lazy-datalist .lazy-datalist .lazy-datalist-btn p.title {
  padding-bottom: 5px;
}
customer-field lazy-datalist .lazy-datalist .lazy-datalist-btn p.subtitle {
  padding-bottom: 3px;
}
customer-field lazy-datalist .lazy-datalist .lazy-datalist-btn p:last-of-type {
  padding-bottom: 0;
}
number-field {
  position: relative;
  display: flex;
  justify-content: left;
  align-items: center;
}
number-field input.ng-valid.ng-dirty + .controls {
  border-color: var(--confirmation-color);
}
number-field input.ng-invalid.ng-dirty + .controls {
  border-color: var(--warning-color);
}
number-field input:hover + .controls {
  border-color: var(--general-border-color);
}
number-field input:focus + .controls {
  border-color: var(--general-text-inactive-color);
}
number-field input[type=number] {
  text-align: right;
  appearance: none;
}
number-field input[type=number].simple {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right-width: 0 !important;
}
number-field .controls {
  display: flex;
  border: 1px solid transparent;
  border-left-width: 0;
  border-top-right-radius: var(--general-border-radius);
  border-bottom-right-radius: var(--general-border-radius);
  overflow: hidden;
}
number-field .controls .suffix {
  height: 43px;
  box-sizing: border-box;
  padding: 10px;
  font-size: 15px;
  line-height: 23px;
  font-weight: 500;
  color: rgba(var(--general-text-color-rgb), 0.6);
  background: var(--background-highlight-color);
}
number-field .controls button {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  height: 43px;
  padding: 0 5px;
  cursor: pointer;
  color: var(--text-color);
  background: var(--background-highlight-color);
}
number-field .controls button rl-icon {
  color: rgba(var(--general-text-color-rgb), 0.6);
  font-size: 15px;
}
reference-field {
  display: flex;
  align-items: flex-start;
  margin-bottom: 5px;
}
reference-field .reference {
  margin: 0 0 0 10px;
  font-weight: bold;
}
reference-field label {
  display: block;
  margin: 0;
  padding: 0;
  line-height: 24px;
  font-size: 13px;
  font-weight: 600;
}
reference-field p {
  padding: 0;
  margin: 0;
  height: 24px;
  line-height: 24px;
}
reference-field .field-tags div {
  display: flex;
  flex-direction: column;
  background: #fff;
  vertical-align: top;
  margin: 0;
  padding: 2px 0 0 0;
  font-size: 14px;
  box-sizing: border-box;
}
reference-field .field-tags div .subtitles {
  font-weight: normal;
  font-size: 12px;
}
reference-field .field-tags div span {
  display: block;
}
service-form-field,
.service-form-field {
  display: block;
  padding: 15px;
  border: 1px solid var(--general-border-color);
  border-radius: var(--general-border-radius);
}
service-form-field.red,
.service-form-field.red {
  border: 1px solid var(--warning-color);
  background: rgba(var(--warning-color-rgb), 0.1);
}
service-form-field.yellow,
.service-form-field.yellow {
  border: 1px solid var(--alert-color);
  background: rgba(var(--alert-color-rgb), 0.1);
}
service-form-field.green,
.service-form-field.green {
  border: 1px solid var(--confirmation-color);
  background: rgba(var(--confirmation-color-rgb), 0.1);
}
service-form-field.no-margin,
.service-form-field.no-margin {
  margin: 0;
}
service-form-field .title,
.service-form-field .title {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
service-form-field .title.has-feedback,
.service-form-field .title.has-feedback {
  margin-bottom: 5px;
}
service-form-field .title .right,
.service-form-field .title .right {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 31;
}
service-form-field .title .right .comments,
.service-form-field .title .right .comments {
  white-space: nowrap;
}
service-form-field .title .right > *:not(:last-child),
.service-form-field .title .right > *:not(:last-child) {
  margin-right: 10px;
}
service-form-field .title > label,
.service-form-field .title > label {
  display: block;
  font-size: 13px;
  font-weight: 600;
  margin: 0 0 10px 0;
}
service-form-field .title > label.helptext,
.service-form-field .title > label.helptext {
  color: var(--general-text-inactive-color);
  font-style: italic;
}
service-form-field .field-help-text,
.service-form-field .field-help-text {
  font-size: 12px;
  opacity: 0.5;
  color: var(--general-text-color);
  line-height: 12px;
  padding-bottom: 4px;
}
service-form-field .field-wrapper,
.service-form-field .field-wrapper {
  display: flex;
  width: 100%;
}
service-form-field .field-wrapper barcode-reader .btn,
.service-form-field .field-wrapper barcode-reader .btn {
  width: 40px;
}
service-form-field .input-wrapper,
.service-form-field .input-wrapper {
  display: flex;
}
service-form-field .input-wrapper > input,
.service-form-field .input-wrapper > input {
  flex: 1;
}
service-form-field input[type=tel],
.service-form-field input[type=tel],
service-form-field input[type=email],
.service-form-field input[type=email] {
  width: 100%;
  max-width: 100%;
}
service-form-field input[type=number],
.service-form-field input[type=number] {
  width: 150px;
}
service-form-field input[type=time],
.service-form-field input[type=time] {
  min-width: 90px;
}
service-form-field .checkbox-label,
.service-form-field .checkbox-label {
  display: flex;
  align-items: center;
  line-height: 24px;
  font-size: 13px;
  margin-top: 2px;
  cursor: pointer;
}
service-form-field .checkbox-label .label-text,
.service-form-field .checkbox-label .label-text {
  max-width: calc(100% - 30px);
}
service-form-field .checkbox-label.disabled,
.service-form-field .checkbox-label.disabled {
  opacity: 0.6;
  cursor: default;
}
service-form-field .checkbox-label.in-use,
.service-form-field .checkbox-label.in-use {
  color: var(--general-text-inactive-color);
}
service-form-field .checkbox-label .in-use-label,
.service-form-field .checkbox-label .in-use-label {
  margin-left: auto;
}
service-form-field .checkbox-label input,
.service-form-field .checkbox-label input {
  margin-right: 10px;
}
service-form-field .checkbox-label:hover input:after,
.service-form-field .checkbox-label:hover input:after {
  background: #dedede;
}
service-form-field input[type=checkbox],
.service-form-field input[type=checkbox] {
  width: 18px;
  height: 18px;
  padding: 0;
  position: relative;
  overflow: hidden;
  display: inline-block;
  vertical-align: top;
}
service-form-field input[type=checkbox]:after,
.service-form-field input[type=checkbox]:after {
  display: block;
  content: '';
  height: 18px;
  width: 18px;
  box-sizing: border-box;
  border-top: 2px solid #dedede;
  background: #eee;
  border-radius: 3px;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
}
service-form-field input[type=checkbox]:checked:before,
.service-form-field input[type=checkbox]:checked:before {
  display: block;
  content: "\f00c";
  font-family: 'Font Awesome\ 6 Pro';
  height: 18px;
  width: 18px;
  line-height: 18px;
  text-align: center;
  box-sizing: border-box;
  z-index: 2;
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
  font-size: 12px;
}
service-form-field field-datalist input[type=checkbox],
.service-form-field field-datalist input[type=checkbox] {
  overflow: visible;
}
service-form-field .field-media-upload,
.service-form-field .field-media-upload {
  display: flex;
  flex-wrap: wrap;
  font-size: 0;
}
service-form-field .field-media-upload media-thumbnail,
.service-form-field .field-media-upload media-thumbnail {
  margin: 0 8px 5px 0;
  display: inline-block;
  vertical-align: top;
  height: 80px;
  width: 80px;
}
service-form-field .field-media-upload media-thumbnail .media-thumbnail,
.service-form-field .field-media-upload media-thumbnail .media-thumbnail {
  border-radius: 3px;
  background: rgba(0, 0, 0, 0.24);
}
service-form-field .field-media-upload media-thumbnail .media-thumbnail:after,
.service-form-field .field-media-upload media-thumbnail .media-thumbnail:after {
  position: absolute;
  top: 0;
  height: 3px;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.24);
  content: '';
  border-radius: 3px;
}
service-form-field .field-media-upload media-thumbnail .image,
.service-form-field .field-media-upload media-thumbnail .image {
  height: 80px;
  width: 80px;
}
service-form-field .information img,
.service-form-field .information img,
service-form-field .information video,
.service-form-field .information video,
service-form-field .information iframe,
.service-form-field .information iframe {
  max-width: 100%;
}
service-form-field .information video,
.service-form-field .information video {
  background: grey;
}
service-form-field button-group button,
.service-form-field button-group button {
  min-height: 40px;
}
service-form-field .instant-feedback,
.service-form-field .instant-feedback {
  margin-top: 10px;
}
service-form-field .instant-feedback > p,
.service-form-field .instant-feedback > p {
  font-size: 15px;
  color: var(--general-text-inactive-color);
  margin: 0 0 10px 0;
}
service-form-field .instant-feedback media,
.service-form-field .instant-feedback media {
  border-radius: var(--general-border-radius);
  overflow: hidden;
}
participants-popup users-list {
  overflow-y: auto;
}
stages-popup stages {
  display: block;
  padding: 0 15px;
}
stages .stage {
  position: relative;
  margin-top: 25px;
  padding: 25px 10px 0 10px;
  border-radius: var(--general-border-radius);
  background-color: var(--background-highlight-color);
}
stages .stage.new {
  padding-bottom: 20px;
}
stages .stage.locked {
  opacity: 0.4;
  padding-bottom: 20px;
}
stages .stage.locked .content {
  cursor: default;
}
stages .stage.pending {
  background-image: linear-gradient(to bottom, rgba(var(--highlight-color-rgb), 0.1), 39%, rgba(var(--general-text-color-rgb), 0));;
}
stages .stage.approved {
  background-image: linear-gradient(to bottom, rgba(var(--confirmation-color-rgb), 0.1), 39%, rgba(var(--general-text-color-rgb), 0));;
}
stages .stage.approved .subtitle {
  font-weight: 600;
  color: var(--confirmation-color);
}
stages .stage.rejected {
  background-image: linear-gradient(to bottom, rgba(var(--warning-color-rgb), 0.1), 39%, rgba(var(--general-text-color-rgb), 0));;
}
stages .stage.rejected .subtitle {
  font-weight: 600;
  color: var(--warning-color);
}
stages .stage .stage-number {
  position: absolute;
  top: calc(-35px / 2);
  left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  box-shadow: 0 3px 7px 2px rgba(var(--general-text-color-rgb), .1);
  background: var(--general-background-color);
  color: var(--general-text-color);
  font-weight: 600;
}
stages .stage .content {
  cursor: pointer;
}
stages .stage .content .title {
  display: flex;
  flex-direction: column;
}
stages .stage .content .title h3 {
  display: flex;
  margin: 0;
}
stages .stage .content .title h3 .text {
  overflow: hidden;
  flex: 1;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: 700;
}
stages .stage .content .title rl-icon {
  position: relative;
  top: -11px;
  margin-left: 10px;
  margin-right: 5px;
}
stages .stage .content .title rl-icon:not(.circle) {
  font-size: 15px;
}
stages .stage .content .title rl-icon[icon=hourglass] {
  background: var(--highlight-color);
  color: var(--white-color);
}
stages .stage .content .title rl-icon[icon=save] {
  background: var(--confirmation-color);
  color: var(--white-color);
}
stages .stage .content .title rl-icon[icon=close] {
  background: var(--warning-color);
  color: var(--white-color);
}
stages .stage .content p {
  margin: 4px 0 0 0;
  font-size: 13px;
  line-height: 15px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
stages .stage .content > p {
  margin: 12px 0 0 0;
}
stages .stage .spacer {
  margin-top: 19px;
  height: 1px;
}
stages .stage footer {
  border-top: 1px solid var(--general-border-color);
  display: flex;
}
stages .stage footer rl-button {
  flex: 1;
}
stages .stage footer rl-button button {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: center;
  padding: 0;
  font-weight: normal;
  color: var(--general-text-color);
}
stages .stage footer rl-button button rl-icon {
  padding-right: 7px;
}
feedback-Form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 15px;
  background: var(--background-highlight-color);
  border-radius: var(--general-border-radius);
  border: 1px solid var(--general-border-color);
  box-shadow: 0 7px 15px rgba(60, 72, 88, 0.1);
}
feedback-Form h2 {
  margin: 0;
  font-weight: 600;
  font-size: 15px;
}
feedback-Form .description {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  width: 100%;
}
feedback-Form .description > div {
  display: flex;
  align-items: center;
}
feedback-Form .profile-image {
  min-width: 24px;
  min-height: 24px;
  margin-right: 6px;
}
feedback-Form .rated-by {
  font-size: 13px;
  color: var(--general-text-inactive-color);
}
feedback-Form .comments rl-icon {
  font-size: 14px;
}
feedback-Form .sf-feedback-rating {
  margin-right: 10px;
}
.base .base-view-serviceFormOverview {
  background: var(--background-highlight-color);
}
.base .base-view-serviceFormOverview.base-view {
  height: auto;
}
forms-overview {
  display: block;
  padding: var(--spacing) var(--spacing) calc(60px + env(safe-area-inset-bottom, 0px));
}
forms-overview article {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: var(--m-spacing);
  background: var(--general-background-color);
  border-radius: var(--general-border-radius);
}
forms-overview article:not(:last-of-type) {
  margin-bottom: var(--spacing);
}
forms-overview .image {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 53px;
  height: 53px;
  margin-right: var(--spacing);
  border-radius: var(--general-border-radius);
}
forms-overview .image rl-icon {
  font-size: 25px;
}
forms-overview .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: var(--general-border-radius);
}
forms-overview .image rl-badge {
  position: absolute;
  top: -10px;
  right: -10px;
}
forms-overview .image:not(.placeholder-shimmer) {
  background: var(--general-border-color);
}
forms-overview .right {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-self: center;
}
forms-overview .right h2 {
  font-size: 0.95rem;
  margin: 0 0 5px 0;
  font-weight: 600;
  word-break: break-all;
}
forms-overview .right p {
  margin: 0;
  font-size: 0.8rem;
  word-break: break-all;
  color: var(--general-text-inactive-color);
}
forms-overview .empty-overview {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: var(--spacing);
  max-width: 246px;
  margin: 155px auto;
}
forms-overview .empty-overview .label {
  font-size: 0.9rem;
  font-weight: 700;
  line-height: 130%;
  text-align: center;
  margin: 0;
  padding: 0 var(--s-spacing);
}
forms-overview .empty-overview svg {
  fill: var(--general-text-inactive-color);
  width: 110px;
  height: 110px;
}
filter-tasks-popup,
filter-tasks {
  padding: var(--m-spacing);
}
filter-tasks-popup .popup-wrapper .popup-content-inner,
filter-tasks .popup-wrapper .popup-content-inner {
  background: var(--background-highlight-color);
  padding: 0;
}
filter-tasks-popup rl-loading-placeholder,
filter-tasks rl-loading-placeholder {
  padding: var(--m-spacing);
}
filter-tasks-popup rl-loading-placeholder .placeholder-shimmer,
filter-tasks rl-loading-placeholder .placeholder-shimmer {
  height: 40px;
}
filter-tasks-popup header button[disabled],
filter-tasks header button[disabled] {
  opacity: 0.5;
}
filter-tasks-popup .scroll-container,
filter-tasks .scroll-container {
  flex: 1;
  overflow-y: hidden;
}
filter-tasks-popup .scroll-container.disable-overflow,
filter-tasks .scroll-container.disable-overflow {
  overflow-y: visible;
}
filter-tasks-popup .task-date-filter,
filter-tasks .task-date-filter,
filter-tasks-popup select-category,
filter-tasks select-category,
filter-tasks-popup department-usergroup-selector,
filter-tasks department-usergroup-selector {
  align-items: center;
  min-height: 50px;
  border-radius: var(--general-border-radius);
  background: var(--background-highlight-color) !important;
}
filter-tasks-popup department-usergroup-selector,
filter-tasks department-usergroup-selector {
  line-height: 50px;
}
filter-tasks-popup .rl-form .rl-field label,
filter-tasks .rl-form .rl-field label {
  font-size: 0.8rem;
  color: var(--general-text-inactive-color);
}
filter-tasks-popup .rl-form .rl-input,
filter-tasks .rl-form .rl-input {
  border: none;
  background: transparent !important;
}
filter-tasks-popup .rl-form .rl-input input,
filter-tasks .rl-form .rl-input input {
  background: transparent;
}
filter-tasks-popup .rl-form .rl-input label,
filter-tasks .rl-form .rl-input label {
  font-size: 1.06667rem;
}
filter-tasks-popup .rl-form .rl-input .toggle,
filter-tasks .rl-form .rl-input .toggle {
  background-color: transparent;
}
filter-tasks-popup footer,
filter-tasks footer {
  text-align: center;
  min-height: 90px;
  border: 0;
  background: var(--background-highlight-color);
}
filter-tasks-popup footer button,
filter-tasks footer button {
  border-radius: 9px;
}
filter-tasks-popup footer .filter-reset-all,
filter-tasks footer .filter-reset-all {
  margin-top: 10px;
}
filter-tasks-popup footer .filter-reset-all rl-icon,
filter-tasks footer .filter-reset-all rl-icon {
  font-size: 14px;
  color: grey;
}
filter-tasks-popup footer .filter-reset-all button,
filter-tasks footer .filter-reset-all button {
  text-transform: none;
  color: grey;
  font-weight: 400;
  padding: 0 5px;
}
filter-tasks-popup rl-button button.full-width,
filter-tasks rl-button button.full-width {
  width: 90%;
}
filter-tasks-popup rl-button button.standard,
filter-tasks rl-button button.standard {
  text-align: center;
  border-top: 0;
}
filter-tasks-popup .task-date-filter,
filter-tasks .task-date-filter {
  display: flex;
}
filter-tasks-popup .task-date-filter .date,
filter-tasks .task-date-filter .date {
  width: 50%;
}
filter-tasks-popup .task-date-filter .date .rl-input,
filter-tasks .task-date-filter .date .rl-input {
  display: block;
  background: var(--general-background-color);
}
filter-tasks-popup .task-date-filter .date .rl-input .prefix,
filter-tasks .task-date-filter .date .rl-input .prefix {
  height: 30px;
  padding-left: 10px;
  font-size: 14px;
}
filter-tasks-popup .task-date-filter .date .rl-input input,
filter-tasks .task-date-filter .date .rl-input input {
  padding: 0 10px 0 10px;
  margin-bottom: 0;
  height: 30px;
}
filter-tasks-popup .task-date-filter .date input,
filter-tasks .task-date-filter .date input {
  padding: 0 10px 0 10px;
  margin-bottom: 0;
}
filter-tasks-popup .task-date-filter .input-title,
filter-tasks .task-date-filter .input-title {
  margin: 0;
  padding: 15px;
  height: 57px;
  display: block;
  color: var(--general-text-color);
  border: none;
  font-size: 20px;
  font-weight: 500;
  background: inherit;
}
filter-tasks-popup .task-date-filter .input-title::-webkit-input-placeholder,
filter-tasks .task-date-filter .input-title::-webkit-input-placeholder {
  color: var(--general-text-color);
  opacity: 0.6;
}
filter-tasks-popup .task-date-filter textarea,
filter-tasks .task-date-filter textarea {
  position: relative;
  width: 100%;
  height: 120px;
  box-sizing: border-box;
  padding: 15px;
  border: none;
  background-image: linear-gradient(to bottom, var(--general-background-color) 85%, var(--background-highlight-color));
}
filter-tasks-popup .task-date-filter textarea::-webkit-input-placeholder,
filter-tasks .task-date-filter textarea::-webkit-input-placeholder {
  color: var(--general-text-color);
}
filter-tasks-popup select-category,
filter-tasks select-category {
  display: flex;
  box-sizing: border-box;
  width: 100%;
  min-height: 50px;
  padding: 0 10px 0 40px;
  background: var(--general-background-color);
  color: var(----general-text-color);
}
filter-tasks-popup select-category .click-area,
filter-tasks select-category .click-area {
  flex: 1;
}
filter-tasks-popup select-repeat,
filter-tasks select-repeat {
  padding-left: 10px;
}
filter-tasks-popup .permission-selector-controls label,
filter-tasks .permission-selector-controls label {
  display: none;
}
filter-tasks-popup .toggle-tasks .rl-field,
filter-tasks .toggle-tasks .rl-field {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
  padding-right: 10px;
}
@media only screen and (min-width: 1025px) {
  filter-tasks-popup .buttons-footer,
  filter-tasks .buttons-footer {
    display: flex;
    flex-direction: row;
    gap: var(--m-spacing);
    justify-content: flex-end;
    align-items: baseline;
  }
  filter-tasks-popup footer,
  filter-tasks footer {
    margin-top: var(--m-spacing);
    background: transparent;
  }
  filter-tasks-popup footer .submit-button button,
  filter-tasks footer .submit-button button,
  filter-tasks-popup footer .reset-button button,
  filter-tasks footer .reset-button button {
    font-size: 0.86667rem;
    font-weight: 700;
    text-transform: uppercase;
  }
  filter-tasks-popup footer .filter-reset-all button,
  filter-tasks footer .filter-reset-all button,
  filter-tasks-popup footer .reset-button button,
  filter-tasks footer .reset-button button,
  filter-tasks-popup footer .filter-reset-all rl-icon,
  filter-tasks footer .filter-reset-all rl-icon,
  filter-tasks-popup footer .reset-button rl-icon,
  filter-tasks footer .reset-button rl-icon {
    color: var(--button-background-color);
  }
  filter-tasks-popup footer .submit-button button,
  filter-tasks footer .submit-button button {
    min-width: 90px;
    color: var(--button-text-color);
    background: var(--button-background-color);
  }
}
filter-tasks {
  overflow: auto;
  scrollbar-width: thin;
  height: 100%;
  display: flex;
  flex-direction: column;
  scrollbar-color: rgba(0, 0, 0, 0.15) transparent;
  box-sizing: border-box;
}
filter-tasks .filters-container {
  display: flex;
  flex-direction: column;
  flex: 1;
}
filter-tasks .scroll-container {
  flex: 1;
}
filter-tasks .buttons-footer {
  align-items: flex-end;
}
filter-tasks .buttons-footer .filter-reset-all {
  margin-top: 0;
  margin-bottom: 10px;
}
department-usergroup-selector {
  display: flex;
  box-sizing: border-box;
  width: 100%;
  min-height: 50px;
  padding: 0 10px 5px 40px;
  background: var(--general-background-color);
  color: var(--general-text-color);
}
department-usergroup-selector .bg {
  display: block;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 10;
  background: rgba(0, 0, 0, 0.6);
}
department-usergroup-selector .bg.animated {
  animation-duration: 0.2s;
}
department-usergroup-selector .select-category {
  position: fixed;
  z-index: 11;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background: var(--general-background-color);
}
department-usergroup-selector .select-category .permission-selector-header {
  padding: 15px;
}
department-usergroup-selector .select-category .permission-selector-header .active {
  color: var(--highlight-color);
  padding-bottom: 6px;
  border-bottom: solid 2px var(--highlight-color);
  font-weight: 600;
}
@media only screen and (min-width: 1025px) {
  department-usergroup-selector .select-category {
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
    z-index: 11;
    top: 20%;
    left: calc(57vw - var(--sidemenu-width));
    width: 500px;
    height: 650px;
  }
}
department-usergroup-selector .click-area {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
department-usergroup-selector .click-area .selected-item {
  line-height: 30px;
  background: var(--background-highlight-color);
  overflow: hidden;
  padding: 0 8px;
  margin: 5px 10px 0 0;
}
department-usergroup-selector .selected-items {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0 10px 0;
  align-items: center;
}
department-usergroup-selector .selected-items li {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  height: 30px;
  max-width: 100%;
  padding: 0 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  line-height: 30px;
  background: var(--background-highlight-color);
  position: relative;
  overflow: hidden;
}
department-usergroup-selector .selected-items li.more-elements-count {
  background: transparent;
}
department-usergroup-selector .selected-items li.default {
  padding: 0;
  margin: 0;
  background: transparent;
}
department-usergroup-selector .selected-items li p {
  margin: 0;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: relative;
  font-size: 15px;
}
department-usergroup-selector .selected-items li p span {
  display: none;
}
department-usergroup-selector .selected-items li.with-subtitle p {
  margin-top: -10px;
  font-size: 14px;
}
department-usergroup-selector .selected-items li.with-subtitle p span {
  display: block;
  line-height: 14px;
  position: absolute;
  font-size: 11px;
  top: 20px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  opacity: 0.5;
}
department-usergroup-selector .selected-items li.with-subtitle .remove-item {
  padding-left: 10px;
}
department-usergroup-selector .selected-items .remove-item {
  position: relative;
  top: 1px;
  font-weight: 600;
  color: var(--warning-color);
  padding: 1px 2px 1px 8px;
  font-size: 15px;
}
department-usergroup-selector .selector-area {
  padding: 15px;
}
contact-item .contact-item-component {
  display: flex;
  justify-content: space-between;
  margin: 4px 0;
  cursor: pointer;
}
@media only screen and (min-width: 1025px) {
  contact-item .contact-item-component {
    padding: 0 20px 0 0;
  }
}
contact-item .contact-item-component .left {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 44px;
  overflow: hidden;
}
contact-item .contact-name-title {
  margin-left: 5px;
  width: 100%;
  overflow: hidden;
}
contact-item .contact-name-title .title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: rgba(var(--general-text-color-rgb), 0.5);
}
contact-item .contact-name-title .name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
contact-item .contact-name-title .rl-small-text {
  color: rgba(var(--general-text-color-rgb), 0.5);
}
contact-item .contact-buttons-container {
  display: inline-flex;
  margin: 5px 0 0 0;
}
contact-item .contact-buttons-container .contact-button {
  height: 30px;
  width: 30px;
  margin-left: 10px;
  border-radius: 100%;
}
contact-item .contact-buttons-container .contact-button rl-icon {
  font-size: 14px;
  margin-top: 8px;
  margin-left: 6px;
}
contact-item .contact-buttons-container .contact-button rl-icon[icon=phone],
contact-item .contact-buttons-container .contact-button rl-icon[icon=comment],
contact-item .contact-buttons-container .contact-button rl-icon[icon=comment] {
  color: var(--confirmation-color);
}
contact-item .contact-buttons-container .contact-button rl-icon[icon=email] {
  color: var(--highlight-color);
}
contact-item .contact-buttons-container .contact-button:not(.mail) {
  background: rgba(var(--confirmation-color-rgb), 0.2);
}
contact-item .contact-buttons-container .contact-button.mail {
  background: rgba(var(--highlight-color-rgb), 0.2);
}
contact-item .contact-buttons-container .contact-button.disabled {
  background: rgba(var(--general-text-color-rgb), 0.07);
}
contact-item .contact-buttons-container .contact-button.disabled rl-icon {
  color: rgba(var(--general-text-color-rgb), 0.3);
}
contact-item profile-image .profile-image {
  min-height: 36px;
  min-width: 36px;
}
.base-view-contactmodule {
  overflow-y: hidden;
}
contact-module .loading {
  position: absolute;
  z-index: 100;
  top: 0;
  right: 0;
  left: 0;
  height: calc(100vh - 105px);
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
}
contact-module .loading .spinner {
  width: 50px;
  height: 50px;
}
contact-module .contact-module-search {
  padding: 15px;
}
contact-module .tab-container {
  padding: 0 15px;
  margin-bottom: 15px;
}
contact-module .rl-tab {
  cursor: pointer;
}
contact-module .contact-list-container {
  padding: 0 15px 60px 15px;
}
contact-module .search .contact-list-container {
  padding: 0 15px 10px 15px;
}
contact-module .search contact-list .contact-list-component {
  max-height: calc(100vh - 130px);
}
@media only screen and (min-width: 1025px) {
  contact-module .search contact-list .contact-list-component {
    height: calc(100vh - 180px);
  }
}
contact-module .contact-list-my-department {
  border-radius: 7px;
  background: var(--background-highlight-color);
  padding: 0 10px;
}
contact-module .departments {
  position: relative;
  overflow-y: auto;
  height: calc(100vh - 170px);
}
@media only screen and (min-width: 1025px) {
  contact-module .departments {
    height: calc(100vh - 220px);
  }
}
contact-module .user-search {
  margin-top: 15px;
}
contact-module .department-item {
  margin: 10px 0;
  cursor: pointer;
}
contact-list .contact-divider {
  margin: 10px 0 10px -7px;
}
contact-list .rl-label-inactive {
  margin: 10px 0 10px 18px;
  font-size: 12px;
  font-weight: bold;
}
contact-list .alphabet-list {
  width: 9px;
  position: fixed;
  right: 0;
  font-weight: bold;
  margin-right: 5px;
  text-align: center;
  font-size: 10px;
  top: 25%;
}
contact-list .alphabet-list a {
  color: var(--general-text-color);
  text-decoration: none;
  font-size: 10px;
}
contact-list .contact-list-component {
  position: relative;
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: calc(100vh - 170px);
}
@media only screen and (min-width: 1025px) {
  contact-list .contact-list-component {
    max-height: calc(100vh - 220px);
  }
}
department-popup .actionSheet-Click {
  display: flex;
}
department-popup .popup-content {
  transform: translate(0, 0);
}
department-popup .loading {
  position: absolute;
  z-index: 100;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
}
department-popup .loading .spinner {
  width: 50px;
  height: 50px;
}
department-popup header-popup {
  z-index: 3;
  position: absolute;
  background: transparent;
  left: 0;
  top: 0;
  box-sizing: border-box;
  height: auto;
  width: 100%;
  padding: 15px 15px 0 15px;
}
department-popup header-popup .buttons {
  min-width: auto;
}
department-popup header-popup .buttons button {
  width: auto;
  height: auto;
  background: transparent;
  min-width: auto;
}
department-popup header-popup .buttons button rl-icon {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  color: var(--general-text-color);
  background: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(24px);
  filter: drop-shadow(0px 6px 15px rgba(0, 0, 0, 0.15));
}
department-popup .department-popup-container {
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: calc(100% - 50px);
}
department-popup .department-container {
  margin-top: 240px;
}
department-popup .popup-wrapper .popup-content.clean.flex .popup-content-inner:not(.scroll-disabled),
department-popup .popup-wrapper .popup-content-inner:not(.scroll-disabled) {
  overflow-y: hidden;
}
department-popup .department-image {
  background-position: center;
  border-radius: 100%;
  width: 120px;
  height: 120px;
  display: block;
  margin: -100px auto 0 auto;
  transition-duration: 0.2s;
  -webkit-transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
}
department-popup .department-image.unset {
  background: var(--general-background-color);
  margin: 0px auto 0 auto;
}
department-popup .department-image.scrolled {
  margin: -100px auto 0 auto;
}
department-popup .department-header-background {
  width: 100%;
  height: 156px;
}
department-popup .header {
  position: fixed;
  text-align: center;
  top: 0;
  z-index: 2;
  width: 100%;
  padding-bottom: 10px;
  background-color: var(--general-background-color);
}
department-popup .header .rl-headline {
  margin-bottom: 0;
}
department-popup .header .unset {
  text-align: center;
}
department-popup .header .unset rl-icon {
  font-size: 40px;
  margin-top: 40px;
  color: rgba(var(--general-text-color-rgb), 0.5);
  transition-duration: 0.2s;
}
department-popup .header.unset {
  background: var(--background-highlight-color);
  border-radius: 0 0 30px 30px;
  padding: 40px 0 10px 0;
}
department-popup .header.shrink .department-header-background {
  background: none;
}
department-popup .header.shrink .department-image {
  width: 45px;
  height: 45px;
  -webkit-transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
}
department-popup .header.shrink .rl-headline {
  margin-top: 10px;
}
department-popup .header.shrink.unset {
  box-shadow: rgba(17, 12, 46, 0.1) 0 10px 50px 0;
}
department-popup .header.shrink.unset rl-icon {
  font-size: 20px;
  margin-top: 15px;
}
department-popup .header .rl-subheadline {
  opacity: 0.8;
}
department-popup .content-wrapper {
  padding: 15px 15px 0px 15px;
}
department-popup .content-wrapper:last-child {
  padding: 15px 15px 15px 15px;
}
department-popup .content {
  background: var(--background-highlight-color);
  border-radius: 7px;
  padding: 10px;
}
department-popup .content.information {
  padding: 10px;
}
department-popup .department-information-item {
  display: flex;
  margin: 8px 0;
}
department-popup .department-information-item .rl-small-text {
  margin: 10px 0 0 10px;
}
department-popup .department-information-item .address {
  display: grid;
}
department-popup .department-information-item .address .rl-small-text {
  margin: 9px 0 0 10px;
}
department-popup .department-information-item .address .rl-small-text.double {
  margin: 2px 0 0 10px;
}
department-popup .department-information-item .address .rl-small-text.city {
  margin: 0 0 0 10px;
}
department-popup .department-information-item .manager {
  display: grid;
}
department-popup .department-information-item .manager .rl-small-text {
  margin: 9px 0 0 10px;
}
department-popup .department-information-item .manager .rl-small-text.double {
  margin: 2px 0 0 10px;
}
department-popup .department-information-item .manager .rl-small-text.name {
  margin: 0 0 0 10px;
}
department-popup .department-information-button {
  height: 35px;
  width: 35px;
  border-radius: 100%;
}
department-popup .department-information-button rl-icon {
  font-size: 16px;
  margin-top: 9px;
  margin-left: 7px;
}
department-popup .department-information-button rl-icon span {
  font-weight: 600;
}
department-popup .department-information-button rl-icon span.fa-phone {
  color: var(--confirmation-color);
}
department-popup .department-information-button rl-icon span.fa-envelope {
  color: var(--highlight-color);
}
department-popup .department-information-button rl-icon span.fa-map-marker-alt {
  color: var(--general-text-color);
}
department-popup .department-information-button rl-icon span.fa-building {
  color: var(--general-text-color);
}
department-popup .department-information-button.phone {
  background: rgba(var(--confirmation-color-rgb), 0.2);
}
department-popup .department-information-button.mail {
  background: rgba(var(--highlight-color-rgb), 0.2);
}
department-popup .department-information-button.location {
  background: rgba(var(--general-text-color-rgb), 0.2);
}
department-popup .department-information-button.building {
  background: rgba(var(--general-text-color-rgb), 0.15);
}
department-popup contact-list .contact-list-component {
  overflow-y: hidden;
  max-height: unset;
}
department-popup .department-contacts-wrapper {
  margin-top: 15px;
}
department-popup .department-contacts {
  margin-top: 4px;
}
department-popup .rl-label-inactive {
  font-size: 11px;
  font-weight: 700;
}
department-popup .department-no-content {
  text-align: center;
}
department-popup .no-content-container {
  width: 70%;
  margin: 0 auto;
}
department-hierarchy button {
  justify-content: center;
  align-items: center;
  padding: 0;
  width: 20px;
  height: 20px;
  text-align: center;
  background: rgba(var(--general-text-color-rgb), 0.1);
  color: rgba(var(--general-text-color-rgb), 0.5);
  border-radius: 4px;
}
department-hierarchy button rl-icon {
  margin-top: -10px;
  font-size: 11px;
}
department-hierarchy .department-hierarchy-component {
  margin-top: 10px;
}
department-hierarchy .department {
  display: inline-flex;
  width: 100%;
  box-sizing: border-box;
  height: 41px;
  padding: 2px 0;
  cursor: pointer;
}
department-hierarchy .department.no-alias-height {
  height: 28px;
}
department-hierarchy .left {
  margin-right: 10px;
}
department-hierarchy .left.no-children {
  margin-right: 15px;
}
department-hierarchy .left.no-children rl-icon {
  font-size: 8px;
  margin-left: 5px;
  color: rgba(var(--general-text-color-rgb), 0.1);
}
department-hierarchy .right {
  display: grid;
  width: 100%;
  align-items: center;
}
department-hierarchy .right span {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
department-hierarchy .right.no-alias {
  align-items: unset;
}
department-hierarchy .open {
  background: var(--highlight-color);
  color: var(--text-on-highlight-color);
}
department-hierarchy .open rl-icon {
  transform: rotate(90deg);
}
department-hierarchy .rl-subheadline {
  font-size: 12px;
}
reactions-viewer .popup-wrapper .popup-content-inner {
  padding: 0;
}
reactions-viewer .popup-wrapper .reaction-viewer-content {
  display: flex;
  flex-direction: column;
  height: 100%;
}
reactions-viewer .popup-wrapper header {
  flex: 0 0 auto;
  width: 100%;
  box-sizing: border-box;
  z-index: 99;
  background: white;
}
reactions-viewer .popup-wrapper header ul {
  width: 100%;
  margin: 0;
  list-style: none;
  display: flex;
  align-items: center;
  padding: 20px 25px 0 15px;
  box-sizing: border-box;
  border-bottom: 1px solid rgba(var(--text-on-white-color-rgb), .1);
}
reactions-viewer .popup-wrapper header ul li {
  margin: 0 15px 0 0;
  padding: 0 0 10px;
  cursor: pointer;
  font-size: 14px;
  border-bottom: 2px solid transparent;
  color: var(--text-on-white-color);
}
reactions-viewer .popup-wrapper header ul li.active {
  border-bottom: 2px solid var(--text-on-white-color);
}
reactions-viewer .popup-wrapper header ul li.active[data-reaction-type="1"],
reactions-viewer .popup-wrapper header ul li.active[data-reaction-type="5"] {
  color: var(--highlight-color);
  border-color: var(--highlight-color);
}
reactions-viewer .popup-wrapper header ul li.active[data-reaction-type="2"],
reactions-viewer .popup-wrapper header ul li.active[data-reaction-type="6"] {
  color: var(--alert-color);
  border-color: var(--alert-color);
}
reactions-viewer .popup-wrapper header ul li.active[data-reaction-type="3"],
reactions-viewer .popup-wrapper header ul li.active[data-reaction-type="4"] {
  color: var(--warning-color);
  border-color: var(--warning-color);
}
reactions-viewer .popup-wrapper header ul li reaction-icon {
  width: 19px;
  height: 19px;
  vertical-align: middle;
}
reactions-viewer .popup-wrapper header ul li span {
  vertical-align: middle;
  line-height: 20px;
}
reactions-viewer .popup-wrapper header .btn-close {
  display: none;
}
@media only screen and (min-width: 1025px) {
  reactions-viewer .popup-wrapper header .btn-close {
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    padding: 15px;
    font-size: 20px;
    cursor: pointer;
  }
}
reactions-viewer section {
  flex: 1 1 auto;
  padding: 15px;
  overflow-y: auto;
}
reactions-viewer .reactions-list {
  margin: 0;
  list-style: none;
  padding: 0;
}
reactions-viewer .reactions-list li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}
reactions-viewer .reactions-list li .left {
  display: flex;
  align-items: center;
}
reactions-viewer .reactions-list li .image-wrapper {
  position: relative;
}
reactions-viewer .reactions-list li .profile-image {
  width: 42px;
  height: 42px;
  cursor: pointer;
}
reactions-viewer .reactions-list li .blocked-name {
  font-weight: 600;
  margin-left: 10px;
}
reactions-viewer .reactions-list li reaction-icon {
  position: absolute;
  z-index: 11;
  bottom: 0;
  width: 20px;
  height: 20px;
  right: 0;
}
reactions-viewer .reactions-list li .title {
  margin-left: 5px;
  color: var(--text-on-white-color);
}
reactions-viewer .reactions-list li .title p {
  margin: 0;
}
reactions-viewer .reactions-list li .title .name {
  font-size: 15px;
  font-weight: 600;
}
reactions-viewer .reactions-list li .title .department {
  opacity: 0.5;
  font-size: 13px;
}
reactions-viewer .reactions-list li button {
  min-width: auto;
  opacity: 1 !important;
}
reactions-viewer .popup-content {
  border-radius: var(--general-border-radius);
}
reactions-viewer .popup-content .spinner-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: var(--general-text-color);
}
reaction-icon {
  display: inline-block;
  vertical-align: middle;
  width: 22px;
  height: 22px;
  position: relative;
  border-radius: 50%;
  box-sizing: border-box;
  filter: drop-shadow(0px 6px 15px rgba(0, 0, 0, 0.15));
}
reaction-icon .img-wrapper {
  border-radius: 50%;
  height: 100%;
  width: 100%;
}
reaction-icon img {
  width: 100%;
  height: 100%;
}
reaction-icon rl-icon {
  width: 100%;
  height: 100%;
  display: flex;
}
reaction-icon rl-icon .reaction-like {
  border-radius: 50%;
  font-size: 10px;
  color: white;
  background: var(--highlight-color);
  background-image: linear-gradient(rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0));
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 2px;
  box-sizing: border-box;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
reaction-icon rl-icon .reaction-like:before {
  transition: font-size 0.2s;
}
reaction-icon .failed-icon {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: var(--warning-color);
  color: var(--white-color);
  display: grid;
}
reaction-icon .failed-icon rl-icon {
  font-size: 0.8rem;
}
reaction-button .btn-reaction {
  display: inline-block;
  position: relative;
}
reaction-button .btn-reaction .reaction-icon-container {
  display: flex;
  align-items: center;
  line-height: 18px;
}
reaction-button .btn-reaction .reaction-icon-container reaction-icon {
  border: 0;
  margin-right: 4px;
  display: flex;
  align-items: center;
  margin-bottom: 4px;
}
reaction-button .btn-reaction .reaction-icon-container reaction-icon rl-icon .reaction-like {
  font-size: 12px;
  padding-bottom: 1px;
}
reaction-button .btn-reaction button {
  min-height: 46px;
}
reaction-button .reactions-list {
  position: absolute;
  z-index: 8;
  bottom: 100%;
  left: 0;
  margin-bottom: -3px;
  opacity: 0;
  transition: visibility 0s 0.3s, opacity 0.3s;
  visibility: hidden;
}
@media only screen and (min-width: 1025px) {
  reaction-button .reactions-list {
    left: 50%;
    right: auto;
    margin-left: -120px;
  }
  reaction-button .reactions-list:after {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    right: -20px;
    bottom: -20px;
    left: -20px;
  }
}
reaction-button .reactions-list.active {
  visibility: visible;
  opacity: 1;
  transition: visibility 0s 0s, opacity 0.3s;
}
reaction-button .reactions-list.active .reactions-overlay {
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  position: fixed;
}
@media only screen and (min-width: 1025px) {
  reaction-button .reactions-list.active .reactions-overlay {
    display: none;
  }
}
@keyframes slideUp {
  0% {
    transform: translateY(70px);
  }
  100% {
    transform: translateY(0);
  }
}
reactions-list {
  display: flex;
  box-sizing: border-box;
  padding: 5px 5px 2px;
  max-height: 46px;
  line-height: 22px;
  font-size: 22px;
  border-radius: 25px;
  text-align: center;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0 5px 10px 0 rgba(60, 72, 88, 0.1);
}
reactions-list .reaction-icon-wrapper {
  position: relative;
  width: 36px;
  height: 36px;
  margin: 0 5px;
}
reactions-list .reaction-icon-wrapper reaction-icon {
  transition: transform 0.2s ease;
  transform-origin: center bottom;
}
reactions-list .reaction-icon-wrapper.active,
reactions-list .reaction-icon-wrapper:hover {
  z-index: 10;
}
reactions-list .reaction-icon-wrapper.active .reaction-label,
reactions-list .reaction-icon-wrapper:hover .reaction-label {
  visibility: visible;
  opacity: 1;
}
reactions-list .reaction-icon-wrapper.active reaction-icon,
reactions-list .reaction-icon-wrapper:hover reaction-icon {
  transform: scale(1.4);
}
reactions-list .reaction-icon-wrapper .reaction-label {
  position: absolute;
  visibility: hidden;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  transition: all 0.1s;
  box-sizing: border-box;
  width: auto;
  margin-bottom: 23px;
  padding: 0 5px;
  font-size: 12px;
  border-radius: 15px;
  background: rgba(0, 0, 0, 0.6);
  opacity: 0;
  color: white;
  text-align: center;
  white-space: nowrap;
}
reactions-list reaction-icon {
  position: absolute;
  bottom: 0;
  left: 0;
  transition: width 0.2s, height 0.2s;
  margin: 0;
  width: 100%;
  height: 100%;
  border-radius: 0;
  border: none;
  cursor: pointer;
}
reactions-list reaction-icon .reaction-like {
  font-size: 18px;
  padding-bottom: 6px;
}
reactions-list span {
  margin: 0;
  padding: 3px;
  cursor: pointer;
}
reactions-summary {
  display: inline-flex;
  align-items: center;
}
reactions-summary .icons {
  position: relative;
  display: inline-flex;
  flex-direction: row-reverse;
  align-items: center;
  margin: 0;
  padding: 0;
}
reactions-summary .icons.count-3 {
  margin-left: -10px;
}
reactions-summary .icons.count-2 {
  margin-left: -5px;
}
reactions-summary reaction-icon {
  border: 2px solid white;
}
reactions-summary reaction-icon:nth-child(1) {
  z-index: 4;
}
reactions-summary reaction-icon:nth-child(2) {
  z-index: 5;
  left: 5px;
}
reactions-summary reaction-icon:nth-child(3) {
  z-index: 6;
  left: 10px;
}
reactions-summary .count {
  margin-left: 5px;
}
media {
  display: block;
}
media.pdf-viewer {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.media {
  position: relative;
}
.media .image {
  font-size: 0;
  display: block;
  text-align: center;
  position: relative;
}
.media .image.loading {
  display: block;
}
.media .image.image-gradient:after {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  display: block;
  content: '';
  height: 66px;
  background: url('/Content/Graphics/Gradient.png') repeat-x;
}
.media .image img {
  width: auto;
  max-width: 100%;
  height: auto;
  margin: 0 auto;
  display: inline-block;
  max-height: 100%;
}
.media .image-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: none;
}
.media .image-spinner .spinner {
  margin: 28px 0;
  opacity: 0.25;
}
.media .image[afkl-lazy-image-loaded] + .image-spinner {
  display: none;
}
.media .video {
  width: 100%;
  max-width: 100%;
}
.media .video > .video-wrapper {
  position: relative;
  padding-bottom: 46%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
}
.media .video > .video-wrapper iframe,
.media .video > .video-wrapper object,
.media .video > .video-wrapper embed,
.media .video > .video-wrapper video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.media .video > .video-play-thumb {
  position: relative;
  overflow: hidden;
  padding-top: 30px;
  padding-bottom: 46%;
  background-size: cover;
  background-position: center center;
}
.media .video > .video-play-thumb > rl-icon {
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 80px;
  width: 80px;
  border-radius: 50%;
  font-size: 40px;
  color: var(--general-text-color);
  background: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(24px);
}
.media .video > .video-play-thumb > rl-icon span {
  margin-left: 5px;
}
.media .video > .video-play-thumb:before {
  content: '';
  display: block;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
}
.media .video .video-newwindow {
  padding: 5px 10px;
  text-align: right;
  color: gray;
}
.media .video .video-newwindow a {
  color: gray;
}
.media .file-link > a {
  display: block;
  padding: 0 10px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  line-height: 50px;
  height: 50px;
  color: #000;
  text-decoration: none;
  position: relative;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  background: #eff1f3;
}
.media .file-link > a i {
  font-size: 40px;
  height: 48px;
  line-height: 48px;
}
.media .file-link > a span {
  display: block;
  position: absolute;
  left: 50px;
  right: 50px;
  top: 4px;
  bottom: 0;
  text-align: center;
  height: 30px;
  line-height: 30px;
  overflow: hidden;
}
.media .file-link > a span:after {
  display: block;
}
.media .file-link > a span.small {
  top: 19px;
  font-size: 0.8em;
  opacity: 0.6;
}
.media .audio {
  width: 100%;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.media .audio span {
  margin-bottom: 5px;
  font-size: 0.8em;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  max-width: 100%;
  text-align: center;
}
module-search-filters {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: var(--s-spacing) 0 var(--m-spacing) 0;
}
module-search-filters.sticky {
  z-index: 10;
  position: sticky;
  top: 55px;
}
@media only screen and (min-width: 1025px) {
  module-search-filters.sticky {
    top: 60px;
  }
}
module-search-filters .filters {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  overflow-x: auto;
}
@media only screen and (min-width: 1025px) {
  module-search-filters .filters {
    overflow-x: visible;
    display: flex;
    flex-wrap: wrap;
  }
}
module-search-filters .filters > * {
  order: 1;
  flex-shrink: 0;
}
module-search-filters .filters > *.active {
  order: -1;
}
module-search-filters scroll-shadow[direction=horizontal] .gradient.last {
  background-image: linear-gradient(to right, hsla(0, 0%, 100%, 0), var(--background-highlight-color));
}
module-search-filters scroll-shadow[direction=horizontal] .gradient.first {
  background-image: linear-gradient(to left, hsla(0, 0%, 100%, 0), var(--background-highlight-color));
}
module-search-filters rl-button.clear-filters button {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  min-width: auto;
  height: auto;
  padding: 10px;
  font-size: 13px;
  font-weight: 400;
  color: var(--warning-color);
}
module-search-filters rl-button.clear-filters button rl-icon {
  margin-right: 5px;
  font-size: 12px;
}
search-results {
  display: block;
  width: 100%;
}
search-results sorting {
  padding: 10px 0 5px;
  z-index: 9;
  font-size: 13px;
  position: sticky;
  top: 167px;
  background: var(--background-highlight-color);
}
@media only screen and (min-width: 1025px) {
  search-results sorting {
    top: 172px;
  }
}
search-results .search-results-title {
  margin-top: 0;
  font-size: 13px;
  font-weight: 700;
  line-height: 1.3;
  margin-bottom: 0;
}
search-results .search-results .results-wrapper {
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}
search-results .search-results .results-wrapper.hidden {
  opacity: 0;
}
search-results rl-search-result-item {
  margin: 10px 0;
}
search-results rl-search-result-item:last-of-type {
  margin-bottom: 0;
}
sorting {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
sorting rl-icon .far {
  font-weight: 400;
}
content-safety-warning-popup action-sheet-custom .actionsheet .actionsheet-content {
  display: flex;
  flex-direction: column;
  gap: var(--spacing);
}
@media only screen and (max-width: 767px) {
  content-safety-warning-popup action-sheet-custom .actionsheet .actionsheet-content {
    padding-bottom: calc((var(--spacing) * 2) + (var(--m-spacing) / 2));
  }
}
content-safety-warning-popup .description {
  margin: 0;
  color: var(--text-on-white-color);
}
content-safety-warning-popup .severity.lvl-1 .pointer {
  left: 37.5%;
  transform: translateX(-50%);
}
content-safety-warning-popup .severity.lvl-1 .level b {
  color: var(--confirmation-color);
}
content-safety-warning-popup .severity.lvl-2 .pointer {
  left: 62.5%;
  transform: translateX(-50%);
}
content-safety-warning-popup .severity.lvl-2 .level b {
  color: var(--alert-color);
}
content-safety-warning-popup .severity.lvl-3 .pointer {
  left: 87.5%;
  transform: translateX(-50%);
}
content-safety-warning-popup .severity.lvl-3 .level b {
  color: var(--warning-color);
}
content-safety-warning-popup .gradient {
  position: relative;
  height: var(--spacing);
  border-radius: 20px;
  background: linear-gradient(90deg, rgba(var(--confirmation-color-rgb), 0.8) 0%, rgba(var(--alert-color-rgb), 0.8) 58.85%, rgba(var(--warning-color-rgb), 0.8) 100%);
}
content-safety-warning-popup .gradient i {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 2px;
  background: var(--white-color);
  box-shadow: 0 7.5px 16.25px 0 var(--shadow-color), 0 0.625px 5px 0 var(--shadow-color);
}
content-safety-warning-popup .gradient i:nth-child(1) {
  left: calc(25% - 1px);
}
content-safety-warning-popup .gradient i:nth-child(2) {
  left: calc(50% - 1px);
}
content-safety-warning-popup .gradient i:nth-child(3) {
  left: calc(75% - 1px);
}
content-safety-warning-popup .gradient .pointer {
  position: absolute;
  bottom: -4px;
}
content-safety-warning-popup .level {
  margin-top: var(--m-spacing);
  color: var(--text-on-white-color);
}
content-safety-warning-popup footer {
  display: flex;
  gap: var(--spacing);
}
content-safety-warning-popup footer rl-button {
  flex: 1;
}
button-group {
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  border-radius: var(--general-border-radius);
}
button-group.ng-invalid:not(.ng-pristine) {
  border: 1px solid var(--warning-color);
}
button-group.series button {
  width: 50%;
}
button-group.series button:not(:nth-last-of-type(-n+2)) {
  margin-bottom: 1px;
}
button-group.series button:nth-child(2n) {
  margin-right: 0;
}
button-group:not(.series) button {
  flex: 1;
}
button-group button {
  display: inline-block;
  min-height: 34px;
  padding: 5px 10px;
  box-sizing: border-box;
  font-size: 16px;
  background: rgba(var(--general-text-color-rgb), 0.07);
  cursor: pointer;
}
button-group button.active {
  background: rgba(var(--general-text-color-rgb), 0.15);
  font-weight: 600;
}
button-group button.in-use {
  color: var(--general-text-inactive-color);
}
button-group button:hover {
  background: rgba(var(--general-text-color-rgb), 0.15);
}
button-group button:not(:last-of-type) {
  border-right: 1px solid var(--white-color);
}
button-group button i {
  margin-right: 3px;
}
select-category {
  display: block;
  position: relative;
  width: 100%;
}
select-category .click-area {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  gap: 5px;
}
select-category .bg {
  display: block;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 10;
  background: rgba(0, 0, 0, 0.6);
}
select-category .bg.animated {
  animation-duration: 0.2s;
}
select-category .selected-category {
  padding: 4px 6px;
  border-radius: var(--general-border-radius);
  font-weight: 700;
  font-size: 12px;
  line-height: 130%;
}
select-category .category-selector {
  position: fixed;
  z-index: 11;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background: var(--background-highlight-color);
}
@media only screen and (min-width: 1025px) {
  select-category .category-selector {
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
    z-index: 11;
    top: 20%;
    left: calc(57vw - var(--sidemenu-width));
    width: 500px;
    height: 650px;
  }
}
select-category .category-selector .category-scroll {
  overflow-y: scroll;
  height: calc(100% - 55px);
}
select-category .category-selector ul {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  box-sizing: border-box;
  justify-content: space-between;
  width: 100%;
  margin: 0;
  padding: 10px;
  margin-bottom: 50px;
}
select-category .category-selector ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  box-sizing: border-box;
  height: 36px;
  padding: 10px;
  margin-bottom: 15px;
  list-style: none;
  border-radius: var(--general-border-radius);
}
select-category .category-selector ul li.selected-category {
  box-shadow: 0 4px 7px rgba(60, 72, 88, 0.1);
}
select-category .category-selector ul li > span {
  font-size: 13px;
  line-height: 16px;
  color: var(--white-color);
  font-weight: bold;
}
select-category .category-selector ul li rl-icon {
  width: 16px;
  height: 16px;
  background: var(--white-color);
  font-size: 11px;
}
select-content-category {
  display: block;
  position: relative;
  width: 100%;
}
select-content-category .click-area {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  gap: 5px;
}
select-content-category .selected-category {
  padding: var(--s-spacing) var(--m-spacing);
  background: var(--background-highlight-color);
  border-radius: var(--general-border-radius);
  font-weight: 700;
  font-size: 12px;
  line-height: 130%;
}
content-category-selector-popup .category-selector {
  position: fixed;
  z-index: 11;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  background: var(--general-background-color);
}
content-category-selector-popup .category-selector .category-scroll {
  padding: var(--spacing) var(--s-spacing);
  overflow-y: auto;
  flex: 1;
}
content-category-selector-popup .item {
  padding: 0 var(--m-spacing);
  margin-bottom: var(--s-spacing);
}
@media only screen and (min-width: 1025px) {
  content-category-selector-popup .item {
    padding: var(--m-spacing);
  }
}
content-category-selector-popup .item.selected {
  background: var(--background-highlight-color);
}
select-repeat {
  display: block;
  position: relative;
  width: 100%;
}
select-repeat .click-area {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
}
select-repeat .click-area span::first-letter {
  text-transform: uppercase;
}
select-repeat .click-area.disabled {
  opacity: 0.5;
  pointer-events: none;
}
select-repeat .repeat-selector {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background: var(--background-highlight-color);
  display: flex;
  flex-direction: column;
}
select-repeat .repeat-selector header {
  display: flex;
  align-items: center;
}
select-repeat .repeat-selector header h1 {
  width: 100%;
  text-align: center;
  margin: 0;
  font-size: 15px;
  text-transform: uppercase;
}
select-repeat .repeat-selector .back {
  padding: 0;
  font-size: 30px;
  line-height: 1;
  border: none;
  background: none;
}
select-repeat .repeat-form {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: auto;
}
select-repeat .help-text {
  box-sizing: border-box;
  padding: 15px 10px 7px;
  font-size: 13px;
  opacity: 0.4;
}
select-repeat .repeat-options,
select-repeat .repeat-option {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 var(--spacing);
  box-sizing: border-box;
  background: var(--general-background-color);
  border-top: 1px solid rgba(var(--general-text-color-rgb), .1);
}
select-repeat .repeat-options.disabled,
select-repeat .repeat-option.disabled {
  position: relative;
  opacity: 0.5;
  pointer-events: none;
}
select-repeat .repeat-options.disabled:after,
select-repeat .repeat-option.disabled:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(var(--general-text-color-rgb), .2);
}
select-repeat .repeat-options.selected span,
select-repeat .repeat-option.selected span {
  font-weight: 500;
}
select-repeat .repeat-options.week-selector,
select-repeat .repeat-option.week-selector {
  padding: var(--spacing);
  gap: var(--s-spacing);
}
select-repeat .repeat-options.week-selector .week-option,
select-repeat .repeat-option.week-selector .week-option {
  display: flex;
  flex: 1 0 0;
  justify-content: center;
  font-size: 1rem;
  font-weight: 400;
  padding: var(--m-spacing) 0;
  border: 1px solid var(--general-border-color);
  border-radius: 7px;
}
select-repeat .repeat-options.week-selector .week-option.selected,
select-repeat .repeat-option.week-selector .week-option.selected {
  border-color: var(--highlight-color);
  font-weight: 700;
  color: var(--highlight-color);
  background-color: rgba(var(--highlight-color-rgb), 0.2);
}
select-repeat .repeat-options.month-selector,
select-repeat .repeat-option.month-selector {
  flex-direction: column;
}
select-repeat .repeat-options.month-selector.repeat-option,
select-repeat .repeat-option.month-selector.repeat-option {
  padding: var(--spacing);
  gap: var(--spacing);
}
select-repeat .repeat-options.month-selector .monthly-option,
select-repeat .repeat-option.month-selector .monthly-option {
  cursor: pointer;
  width: 100%;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}
select-repeat .repeat-options.month-selector .monthly-option label,
select-repeat .repeat-option.month-selector .monthly-option label {
  padding-left: 0;
}
select-repeat .repeat-options.month-selector .monthly-option .title,
select-repeat .repeat-option.month-selector .monthly-option .title {
  flex: 1 0 auto;
}
select-repeat .repeat-options.month-selector .monthly-option.disabled,
select-repeat .repeat-option.month-selector .monthly-option.disabled {
  opacity: 0.5;
}
select-repeat .repeat-options.week-selector,
select-repeat .repeat-option.week-selector,
select-repeat .repeat-options.month-selector,
select-repeat .repeat-option.month-selector,
select-repeat .repeat-options.special,
select-repeat .repeat-option.special {
  border-bottom: 1px solid rgba(var(--general-text-color-rgb), .1);
}
select-repeat .repeat-options.special,
select-repeat .repeat-option.special {
  gap: 5px;
}
@media only screen and (min-width: 1025px) {
  select-repeat .repeat-options.special,
  select-repeat .repeat-option.special {
    gap: 0;
  }
}
select-repeat .repeat-options.input input,
select-repeat .repeat-option.input input {
  padding: 0;
  height: 48px;
  text-align: right;
}
select-repeat .repeat-options rl-icon,
select-repeat .repeat-option rl-icon {
  color: var(--highlight-color);
}
select-repeat .period-label {
  white-space: nowrap;
}
select-repeat .repeat-options {
  justify-content: space-between;
  align-items: stretch;
  flex-direction: column;
  padding: 0;
}
select-repeat .repeat-options.title {
  font-weight: 400!important;
}
select-repeat .repeat-options .repeat-option:last-of-type {
  border-bottom: 1px solid rgba(var(--general-text-color-rgb), .1);
}
select-repeat .selectors-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  gap: var(--spacing);
  width: 100%;
}
select-repeat .selectors-container rl-select {
  width: 50%;
}
select-repeat .selectors-container rl-select span {
  line-height: unset !important;
}
select-repeat .selectors-container rl-select .radio {
  display: none !important;
}
select-repeat rl-select-list .item {
  flex-direction: row-reverse;
  padding: 0 var(--spacing);
  opacity: 0.5;
  gap: 10px;
}
select-repeat rl-select-list .item.selected {
  opacity: 1;
}
select-repeat rl-select-list .item.selected .title {
  color: var(--general-text-color) !important;
  font-weight: 400;
}
@media only screen and (min-width: 1025px) {
  select-repeat rl-select-list .item {
    height: 45px;
  }
  select-repeat rl-select-list .item .title {
    font-weight: 400;
    font-size: 1rem;
  }
}
manager-overview-table {
  display: flex;
  overflow: hidden;
  flex: 1;
  flex-direction: column;
}
manager-overview-table .scroll-container {
  overflow-y: auto;
  scrollbar-width: thin;
  height: 100%;
  -webkit-overflow-scrolling: touch;
}
manager-overview-table header {
  display: flex;
  overflow: hidden;
  padding: 10px;
  box-sizing: border-box;
  border-bottom: 1px solid rgba(var(--general-text-color-rgb), .1);
}
manager-overview-table header div {
  display: flex;
  font-weight: bold;
}
@media only screen and (min-width: 768px) {
  manager-overview-table header div .title.mobile {
    display: none;
  }
}
manager-overview-table header div .title.desktop {
  overflow: hidden;
  text-overflow: ellipsis;
}
@media only screen and (max-width: 767px) {
  manager-overview-table header div .title.desktop {
    display: none;
  }
}
manager-overview-table header div .sort {
  position: relative;
  top: 2px;
  padding-left: 5px;
  color: #EBECEE;
}
manager-overview-table header div .sort.active {
  color: #3c4858;
}
manager-overview-table header div .sort.reverse {
  transform: scale(1, -1);
}
manager-overview-table .cell {
  display: flex;
  width: 30%;
  align-items: center;
  overflow: hidden;
  text-align: left;
}
manager-overview-table .cell .expand-button {
  min-width: 19px;
  width: 19px;
}
manager-overview-table .cell .expand-button.empty .fal:before {
  content: "";
}
manager-overview-table .cell.DepartmentName {
  width: 70%;
  z-index: 1;
}
manager-overview-table .cell.Progress {
  align-items: flex-start;
  justify-content: center;
  font-size: 14px;
}
manager-overview-table .cell.Progress percentage-circle .c100.small {
  font-size: 19px;
  margin-left: 5px;
}
manager-overview-table .cell.Progress percentage-circle .c100.small:after {
  background-color: var(--general-background-color);
}
manager-overview-table .cell:not(:last-of-type) {
  padding-right: 10px;
}
manager-overview-table .table-content {
  display: flex;
  flex: 1;
  overflow-y: auto;
  flex-direction: column;
}
manager-overview-table .department {
  flex-direction: column;
  border-bottom: 1px solid #EBECEE;
  position: relative;
}
manager-overview-table .department.opened:before {
  content: '';
  display: block;
  position: absolute;
  z-index: 0;
  top: 23px;
  left: 22px;
  bottom: 23px;
  width: 1px;
  background: #3c4858;
}
manager-overview-table .department.opened > .inner-row .name {
  font-weight: bold;
}
manager-overview-table .department:not(.opened) .departments {
  display: none;
}
manager-overview-table .department .department {
  border-bottom: none;
}
manager-overview-table .department .department.last:before {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  left: 23px;
  height: 1px;
  width: 7px;
  background: #3c4858;
}
manager-overview-table .inner-row {
  display: flex;
  width: 100%;
  padding: 15px 10px 15px 15px;
  box-sizing: border-box;
  background: transparent;
}
manager-overview-table .inner-row .fal {
  line-height: 13px;
  background: white;
}
show-more-less {
  font-size: 0;
}
show-more-less .sml-text {
  display: inline;
  word-break: break-word;
  word-break: auto-phrase;
}
show-more-less .sml-expand {
  display: inline;
  font-size: 13px;
  opacity: 0.7;
  padding: 0 0 0 5px;
}
content-library .search-wrapper {
  position: relative;
}
content-library .search-wrapper.sticky {
  z-index: 30;
  position: sticky;
  top: 55px;
  background: var(--background-highlight-color);
}
@media only screen and (min-width: 1025px) {
  content-library .search-wrapper.sticky {
    top: 60px;
  }
}
@media only screen and (max-width: 1024px) {
  content-library .search-wrapper module-search-filters {
    padding-left: var(--m-spacing);
    padding-right: var(--m-spacing);
  }
}
content-library .search-filters-wrapper action-sheet-custom .actionsheet {
  z-index: 11;
}
content-library .content-wrapper {
  padding: var(--m-spacing);
}
content-library .content-wrapper.unset-padding {
  padding-top: 0;
}
content-library .spinner {
  position: absolute;
  top: 50%;
  left: 50%;
}
content-library .content-item-placeholder {
  display: block;
  margin: var(--spacing) 0;
  background: var(--general-background-color);
  border-radius: var(--general-border-radius);
}
content-library .content-item-placeholder rl-loading-placeholder {
  display: block;
  box-sizing: border-box;
  padding: 15px;
}
content-library .content-item-placeholder rl-loading-placeholder .placeholder-shimmer {
  height: 19px;
}
content-library-list .app-link-url {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  user-select: none;
}
content-library-list .head {
  position: relative;
}
content-library-list .head:last-of-type {
  margin-bottom: 0;
}
content-library-list .head:first-of-type {
  margin-top: 0;
}
content-library-list .head .left,
content-library-list .head .right {
  display: flex;
  align-items: center;
  padding: 15px 15px 0 15px;
}
content-library-list .head .right {
  justify-content: flex-end;
}
content-library-list .head .content-library-options {
  padding: 0;
  text-align: center;
  color: var(--white-color);
  background: transparent;
  border: none;
  z-index: 1;
}
content-library-list .head .content-library-options rl-icon {
  font-size: 20px;
}
content-library-list .no-content {
  left: unset;
  margin-top: 90px;
  position: relative;
  right: unset;
  top: unset;
}
content-library-list .separator,
content-library-item .separator {
  padding: 0 3px;
}
content-library-list .card-item-progress,
content-library-item .card-item-progress {
  height: 4px;
  position: relative;
  background: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(24px);
}
content-library-list .card-item-progress .fill-progress,
content-library-item .card-item-progress .fill-progress {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  background: var(--highlight-color);
  content: " ";
  height: 4px;
}
content-library-list .head,
content-library-item .head {
  height: 230px;
  z-index: 10;
  margin: calc(var(--spacing) * 2) 0;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.6);
  color: var(--white-color);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
content-library-list .head.fixed-top,
content-library-item .head.fixed-top {
  height: auto;
  background-color: var(--header-background-color);
  color: var(--header-text-color);
}
content-library-list .head.fixed-top .caption,
content-library-item .head.fixed-top .caption {
  display: none;
}
content-library-list .head.fixed-top .card-content-fixed,
content-library-item .head.fixed-top .card-content-fixed {
  display: block;
}
content-library-list .head:not(.fixed-top) .caption,
content-library-item .head:not(.fixed-top) .caption {
  display: block;
}
content-library-list .head:not(.fixed-top) .card-content-fixed,
content-library-item .head:not(.fixed-top) .card-content-fixed {
  display: none;
}
@media only screen and (min-width: 1025px) {
  content-library-list .head,
  content-library-item .head {
    height: 330px;
  }
}
content-library-list .head .caption,
content-library-item .head .caption {
  position: absolute;
  width: 100%;
  right: 0;
  left: 0;
  bottom: 0;
}
content-library-list .head .card-head-top,
content-library-item .head .card-head-top,
content-library-list .head .card-head-bottom,
content-library-item .head .card-head-bottom {
  padding: 3vw;
}
content-library-list .head .card-head-top,
content-library-item .head .card-head-top {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
content-library-list .head .card-head-top h3,
content-library-item .head .card-head-top h3 {
  margin-bottom: 0;
  text-transform: uppercase;
  text-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
}
content-library-list .head .card-content-fixed,
content-library-item .head .card-content-fixed {
  padding: 3vw;
  background: black;
}
content-library-list .head .card-content-fixed h3,
content-library-item .head .card-content-fixed h3 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0 25px 5px 0;
}
content-library-list .head .card-content-fixed .content-description,
content-library-item .head .card-content-fixed .content-description {
  margin: 8px 0 2px;
  white-space: nowrap;
  font-size: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: flex;
  align-items: baseline;
}
content-library-list .head .card-content-fixed .content-description .content-title-link,
content-library-item .head .card-content-fixed .content-description .content-title-link {
  max-width: calc(100% - 80px);
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 5px;
}
content-library-list .head .card-content-fixed .content-description .page-icon,
content-library-item .head .card-content-fixed .content-description .page-icon {
  font-size: 16px;
}
content-library-list .head .card-content-fixed .content-description span,
content-library-item .head .card-content-fixed .content-description span {
  padding: 0 3px;
}
content-library-list .head .card-item-breadcrumb,
content-library-item .head .card-item-breadcrumb {
  margin-top: 10px;
  font-size: 12px;
  align-items: baseline;
  text-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
}
content-library-list .head .card-item-breadcrumb > div,
content-library-item .head .card-item-breadcrumb > div {
  display: inline-block;
  vertical-align: middle;
}
content-library-list .head .card-item-breadcrumb > div:last-of-type .separator,
content-library-item .head .card-item-breadcrumb > div:last-of-type .separator {
  display: none;
}
content-library-list .head .card-head-bottom,
content-library-item .head .card-head-bottom {
  background: rgba(0, 0, 0, 0.6);
  position: relative;
  overflow: hidden;
}
content-library-list .head .card-head-bottom .card-head-actions,
content-library-item .head .card-head-bottom .card-head-actions {
  display: flex;
  justify-content: space-between;
}
content-library-list .head .card-head-bottom .card-head-actions button,
content-library-item .head .card-head-bottom .card-head-actions button {
  padding: 0 5px;
}
content-library-list .head .card-head-bottom .card-head-actions button span,
content-library-item .head .card-head-bottom .card-head-actions button span {
  font-size: 17px;
}
content-library-list .head .card-head-bottom .card-head-actions button.completed span,
content-library-item .head .card-head-bottom .card-head-actions button.completed span {
  font-size: 12px;
  padding-right: 2px;
}
content-library-list .head .card-head-bottom .card-footer-button,
content-library-item .head .card-head-bottom .card-footer-button {
  font-size: 10px;
  text-align: center;
}
content-library-list .head .card-head-bottom .card-footer-button button,
content-library-item .head .card-head-bottom .card-footer-button button {
  background-color: var(--highlight-color);
  border-radius: 10px;
  padding: 5px 16px;
  color: var(--text-on-highlight-color);
  font-size: 12px;
  text-transform: uppercase;
  margin: 0 3px 5px;
  font-weight: 600;
}
content-library-list .head .card-head-bottom .card-footer-button .read-progress,
content-library-item .head .card-head-bottom .card-footer-button .read-progress {
  color: var(--white-color);
}
content-library-list .head .card-head-bottom .content-item-comments-label,
content-library-item .head .card-head-bottom .content-item-comments-label {
  font-size: 12px;
  margin-top: 5px;
  margin-left: 5px;
}
content-library-list .head .card-head-bottom button,
content-library-item .head .card-head-bottom button {
  color: var(--white-color);
}
content-library-item {
  display: block;
  padding-bottom: 50px;
  background-color: white;
  -webkit-text-size-adjust: 100%;
}
@media only screen and (min-width: 1025px) {
  content-library-item {
    max-width: 60vw;
    margin: 15px auto;
    padding-bottom: 0;
  }
}
content-library-item translation-button {
  padding-top: var(--spacing);
  padding-left: 3vw;
}
content-library-item .go-to-feed {
  display: block;
  padding: 10px;
}
@media only screen and (min-width: 1025px) {
  content-library-item .go-to-feed {
    padding: 10px 0;
  }
}
content-library-item .head {
  position: sticky;
  top: 55px;
  left: 0;
  width: 100%;
  margin: 0;
  z-index: 10;
  transition: min-height 0.2s linear;
}
@media only screen and (min-width: 1025px) {
  content-library-item .head {
    top: 60px;
  }
}
@media only screen and (max-width: 1024px) {
  content-library-item .head {
    min-height: 230px;
    height: auto;
  }
}
content-library-item .head.fixed-top {
  min-height: 50px;
}
@media only screen and (min-width: 1025px) {
  content-library-item .head.fixed-top {
    height: auto;
  }
}
content-library-item .head.fixed-top .caption {
  display: none;
}
content-library-item .head:not(.fixed-top) .small-caption {
  display: none;
}
content-library-item .head .btn-close {
  position: absolute;
  right: 10px;
  top: 10px;
  margin: 0;
  padding: 0;
  border-radius: 50%;
  background: rgba(var(--header-text-color-rgb), 0.15);
  color: var(--header-text-color);
  width: 23px;
  height: 23px;
  text-align: center;
  z-index: 15;
  font-size: 16px;
  line-height: 16px;
}
content-library-item .head .btn-close rl-icon {
  font-size: 15px;
}
content-library-item .head .small-caption {
  padding: 2vw;
}
content-library-item .head .small-caption h3 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0 25px 5px 0;
}
content-library-item .head .small-caption .content-description {
  margin: 8px 0 2px;
  white-space: nowrap;
  font-size: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: flex;
  align-items: baseline;
}
content-library-item .head .small-caption .content-description .content-title-link {
  max-width: calc(100% - 80px);
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 5px;
}
content-library-item .head .small-caption .content-description .page-icon {
  font-size: 16px;
}
content-library-item .head .small-caption .content-description > span {
  padding: 0 3px;
}
content-library-item .head .small-caption .table-of-contents {
  margin: 0;
  font-size: 14px;
}
content-library-item .head .small-caption .table-of-contents.gradient .content-list ul {
  margin-bottom: 20px;
}
content-library-item .head .small-caption .table-of-contents.gradient:after {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), var(--header-background-color) 100%);
  content: "";
  position: absolute;
  bottom: 5px;
  width: 100%;
  height: 40px;
  left: 0;
  opacity: 1;
  z-index: 90;
  pointer-events: none;
}
content-library-item .head .small-caption .table-of-contents .content-list {
  position: relative;
  max-height: 50vh;
  overflow: auto;
}
@media only screen and (min-width: 768px) {
  content-library-item .head .small-caption .table-of-contents .content-list {
    max-height: 65vh;
  }
}
content-library-item .head .small-caption .table-of-contents .content-list .toc-progress {
  position: absolute;
  top: 13px;
  left: 7px;
  width: 2px;
  overflow: hidden;
  background-color: rgba(var(--header-text-color-rgb), 0.5);
  backdrop-filter: blur(4px);
}
content-library-item .head .small-caption .table-of-contents .content-list .toc-progress .fill-progress {
  position: absolute;
  top: 0;
  bottom: 0;
  height: 0;
  left: 0;
  width: 4px;
  background-color: var(--highlight-color);
}
content-library-item .head .small-caption .table-of-contents .content-list ul {
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
}
content-library-item .head .small-caption .table-of-contents .content-list ul li {
  margin: 0;
  text-transform: uppercase;
  font-size: 14px;
  padding: 12px 2px 12px 25px;
  position: relative;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: rgba(var(--header-text-color-rgb), 0.7);
}
content-library-item .head .small-caption .table-of-contents .content-list ul li:after {
  position: absolute;
  left: 2px;
  top: 50%;
  margin-top: -6px;
  content: "";
  width: 12px;
  height: 12px;
  background-color: rgba(var(--header-text-color-rgb), 0.5);
  backdrop-filter: blur(4px);
  border-radius: 50%;
  z-index: 10;
}
content-library-item .head .small-caption .table-of-contents .content-list ul li.completed {
  color: var(--header-text-color-rgb);
}
content-library-item .head .small-caption .table-of-contents .content-list ul li.completed:after {
  font-family: "Font Awesome\ 6 Pro";
  text-align: center;
  content: "\f00c";
  background-color: var(--highlight-color);
  color: var(--text-on-highlight-color);
  width: 17px;
  height: 17px;
  left: 0;
  margin-top: -8px;
  font-size: 10px;
  line-height: 16px;
}
content-library-item .head .small-caption .table-of-contents .content-list ul li span {
  padding: 1px;
}
content-library-item .toc-overlay {
  display: block;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  content: " ";
  background: rgba(0, 0, 0, 0.7);
  z-index: -1;
  opacity: 0.2;
}
content-library-item .card-item-progress {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
}
content-library-item .card-item-progress .fill-progress {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  background: var(--highlight-color);
  content: " ";
  height: 4px;
}
content-library-item .card-sticky-bottom {
  display: flex;
  flex-direction: column;
}
content-library-item .card-sticky-bottom .top,
content-library-item .card-sticky-bottom .bottom {
  display: flex;
  align-items: center;
  justify-content: center;
}
content-library-item .card-sticky-bottom .top {
  border-bottom: 1px solid rgba(var(--general-text-color-rgb), 0.1);
}
content-library-item .card-sticky-bottom .top button rl-icon {
  font-size: 15px;
}
content-library-item .card-sticky-bottom .bottom button rl-icon {
  vertical-align: bottom;
}
content-library-item .card-sticky-bottom .bottom button > span {
  text-transform: uppercase;
  font-size: 13px;
}
content-library-item .card-sticky-bottom button {
  padding: 0 10px;
  height: 50px;
}
content-library-item .card-sticky-bottom button rl-icon {
  margin-right: 6px;
}
content-library-item .card-item-body {
  padding-top: 20px;
}
content-library-item .card-item-body article {
  position: relative;
  margin-top: 40px;
  border-top: 3px solid var(--general-text-inactive-color);
}
content-library-item .card-item-body article:first-of-type {
  margin-top: 10px;
}
content-library-item .card-item-body article.single {
  margin-top: 20px;
  border-top: none;
}
content-library-item .card-item-body article:last-of-type {
  margin-bottom: 40px;
}
content-library-item .card-item-body article.completed {
  border-top: 3px solid var(--highlight-color);
}
content-library-item .card-item-body article.completed .index {
  background: var(--highlight-color);
  color: var(--text-on-highlight-color);
}
content-library-item .card-item-body article.completed h1 {
  background: rgba(var(--highlight-color-rgb), 0.2);
}
content-library-item .card-item-body article a {
  color: var(--highlight-color);
}
content-library-item .card-item-body .index {
  position: absolute;
  top: -28px;
  left: 50%;
  margin-left: -25px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  color: var(--general-background-color);
  background: var(--general-text-inactive-color);
  text-align: center;
  line-height: 50px;
  font-size: 17px;
}
content-library-item .card-item-body rl-rich-preview.article-content {
  padding: 0 3vw;
}
content-library-item .card-item-body rl-rich-preview.article-content h1 {
  margin: 0 -3vw 20px;
  padding: 45px 3vw 30px;
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  color: var(--general-text-color);
  background: var(--background-highlight-color);
  word-break: break-word;
}
content-library-item .card-item-body img {
  width: auto;
  height: auto;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: block;
}
content-library-item .card-item-body .video-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  height: 0;
}
content-library-item .card-item-body .video-wrapper iframe,
content-library-item .card-item-body .video-wrapper video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
content-library-item .card-item-body video {
  background: grey;
}
content-library-item footer {
  padding: 20px 3vw;
  text-align: center;
  background: var(--background-highlight-color);
}
content-library-item footer.with-margin {
  margin-bottom: 53px;
}
content-library-item footer .card-item-breadcrumb {
  align-items: center;
  justify-content: center;
  font-size: 12px;
}
content-library-item footer .card-item-breadcrumb > div {
  display: inline-block;
}
content-library-item footer .card-item-breadcrumb > div:last-of-type .separator {
  display: none;
}
content-library-item .next-content-link {
  background: var(--highlight-color);
  color: var(--white-color);
  padding: 3vw;
}
content-library-item .next-content-link h3 {
  font-weight: bold;
  text-transform: uppercase;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
content-library-item .next-content-link h4 {
  margin: 0 0 5px;
}
.notification-viewer .no-content {
  text-align: center;
}
@media only screen and (min-width: 1025px) {
  .notification-viewer .no-content .icon-wrapper {
    padding-top: 30px;
  }
}
.notification-viewer .no-content icon {
  margin-bottom: 25px;
  margin-top: 15px;
  width: 36px;
}
.notification-viewer .no-content icon svg {
  stroke-width: 0;
}
.notification-viewer .no-content icon.icon-stop {
  position: absolute;
  width: 76px;
  margin-left: -20px;
  margin-top: -5px;
  color: #ea6f63;
}
.notification-viewer .no-content button {
  padding: 15px;
  border: 1px solid #5fa3f1;
  color: #5fa3f1;
  margin-top: 15px;
}
.notification-viewer .notification-viewer-loading {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.notification-viewer .notification-viewer-loading .spinner {
  height: 30px;
  width: 30px;
}
.notification-viewer .notification-viewer-content ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: block;
}
.notification-viewer .notification-viewer-content ul > li {
  display: block;
  width: 100%;
  box-sizing: border-box;
  margin: 0;
}
.notification-viewer .notification-viewer-content ul > li.list-title {
  text-transform: uppercase;
  background: #fff;
  padding: 10px 15px;
  font-weight: 600;
  border-top: 1px solid #e9e9e9;
  border-bottom: 1px solid #e9e9e9;
}
.notification-viewer .notification-viewer-content ul > li.list-title:first-of-type {
  border-top: none;
}
.notification-viewer .notification-viewer-content ul > li.unread {
  border-color: #c0e9f3;
}
.notification-viewer .notification-viewer-content ul > li.unread .notification-viewer-notification {
  background: rgba(95, 163, 241, 0.1);
}
.notification-viewer .notification-viewer-content .notification-viewer-notification {
  display: block;
  background: #fff;
  position: relative;
  padding-top: 5px;
}
.notification-viewer .notification-viewer-content .notification-viewer-notification > .inner {
  display: block;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  z-index: 1;
  padding-bottom: 10px;
}
.notification-viewer .notification-viewer-content .notification-viewer-notification > .inner > .notification-image-container {
  height: 56px;
  width: 56px;
  position: absolute;
  top: 6px;
  left: 12px;
  border-radius: 50%;
  color: #fff;
}
.notification-viewer .notification-viewer-content .notification-viewer-notification > .inner > .notification-image-container .media-thumbnail {
  height: 56px;
  width: 56px;
  border-radius: 50%;
  background: #303030;
}
.notification-viewer .notification-viewer-content .notification-viewer-notification > .inner > .notification-image-container .media-thumbnail .image {
  height: 100%;
  width: 100%;
}
.notification-viewer .notification-viewer-content .notification-viewer-notification > .inner > .notification-image-container.no-thumbnail {
  background: #303030;
  text-align: center;
  line-height: 56px;
  font-size: 24px;
}
.notification-viewer .notification-viewer-content .notification-viewer-notification > .inner > .notification-image-container.no-thumbnail .avatar {
  position: absolute;
  top: 0;
  left: 0;
  color: #3c4858;
  width: 100%;
  height: 100%;
}
.notification-viewer .notification-viewer-content .notification-viewer-notification > .inner > .notification-image-container.no-thumbnail .avatar svg {
  box-sizing: border-box;
  border-radius: 50%;
  stroke-width: 0;
}
.notification-viewer .notification-viewer-content .notification-viewer-notification > .inner > .notification-image-container.no-thumbnail .avatar svg path {
  fill: #8893a3;
}
.notification-viewer .notification-viewer-content .notification-viewer-notification > .inner > .notification-image-container .icon {
  position: absolute;
  line-height: 14px;
  right: -2px;
  bottom: -6px;
  top: auto;
  background: #3c4858;
  border-radius: 50%;
  width: 27px;
  height: 27px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
}
.notification-viewer .notification-viewer-content .notification-viewer-notification > .inner > .notification-image-container .icon rl-icon {
  font-size: 14px;
}
.notification-viewer .notification-viewer-content .notification-viewer-notification > .inner > .notification-image-container .icon.socialPost {
  background: #eb6262;
}
.notification-viewer .notification-viewer-content .notification-viewer-notification > .inner > .notification-image-container .icon.contentItem {
  background: #5fa3f1;
}
.notification-viewer .notification-viewer-content .notification-viewer-notification > .inner > .notification-image-container .icon.serviceFormFilling {
  background: #3c4858;
}
.notification-viewer .notification-viewer-content .notification-viewer-notification > .inner > .notification-image-container .icon.isReaction {
  background: none;
  box-shadow: none;
}
.notification-viewer .notification-viewer-content .notification-viewer-notification > .inner > .notification-image-container .icon icon {
  width: 14px;
  height: 14px;
}
.notification-viewer .notification-viewer-content .notification-viewer-notification > .inner > .notification-image-container .icon icon svg {
  stroke-width: 0;
}
.notification-viewer .notification-viewer-content .notification-viewer-notification > .inner > .notification-data {
  padding: 15px 15px 15px 80px;
  word-break: break-word;
}
.notification-viewer .notification-viewer-content .notification-viewer-notification > .inner > .notification-data h3 {
  margin: 0;
  font-size: 15px;
}
.notification-viewer .notification-viewer-content .notification-viewer-notification > .inner > .notification-data p {
  margin: 0 36px 0 0;
  font-size: 14px;
}
.notification-viewer .notification-viewer-content .notification-viewer-notification > .inner > .notification-data .notification-data-time {
  font-size: 11px;
  opacity: 0.7;
  text-align: right;
  white-space: nowrap;
}
.notification-viewer .notification-viewer-content .notification-viewer-notification > .inner > .notification-data .notification-module-name {
  width: 70%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  opacity: 0.6;
}
.notification-viewer .notification-viewer-content .notification-viewer-notification > .inner .notification-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  margin-top: 7px;
}
.notification-viewer .notification-viewer-content .notification-viewer-notification > .inner reaction-icon {
  width: 27px;
  height: 27px;
}
.notification-viewer .notification-viewer-content .notification-viewer-notification > .inner reaction-icon rl-icon .reaction-like {
  font-size: 14px;
}
.notification-viewer .notification-viewer-content .notification-viewer-notification .options {
  display: block;
  text-align: center;
  position: absolute;
  top: 10px;
  right: 12px;
  overflow: hidden;
  z-index: 2;
  font-size: 24px;
  height: 30px;
  line-height: 30px;
  opacity: 0.6;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  border: 0;
  background: none;
}
.notification-viewer .notification-viewer-content .notification-viewer-notification .options rl-icon {
  font-size: 20px;
}
.notification-viewer .notification-viewer-content .notification-viewer-notification .options svg {
  stroke-width: 0;
}
.notification-viewer .notification-viewer-content .notification-viewer-notification.placeholder > .inner > .notification-data p {
  display: block;
  width: 100%;
  height: 20px;
  border-radius: 4px;
}
.notification-viewer .notification-viewer-content .notification-viewer-notification.placeholder > .inner > .notification-data .notification-module-name {
  display: block;
  width: 160px;
  max-width: 40%;
  height: 18px;
  border-radius: 4px;
}
notifications-settings .form h4,
notifications-setting-page .form h4,
module-notifications-settings .form h4 {
  background: #F7F8FA;
  color: unset;
  padding: 15px 20px;
  position: relative;
  margin: 0;
  border-bottom: 1px solid #ebecee;
}
notifications-settings .form h4 rl-icon,
notifications-setting-page .form h4 rl-icon,
module-notifications-settings .form h4 rl-icon,
notifications-settings .form h4 img,
notifications-setting-page .form h4 img,
module-notifications-settings .form h4 img {
  position: absolute;
  top: 50%;
  margin-top: -13px;
  left: 20px;
  width: 14px;
  height: 14px;
  padding: 6px;
  border-radius: 50%;
  color: white;
  object-fit: contain;
}
notifications-settings .form h4 rl-icon,
notifications-setting-page .form h4 rl-icon,
module-notifications-settings .form h4 rl-icon {
  width: 27px;
  height: 27px;
  font-size: 14px;
  padding: 0;
}
notifications-settings .form h4.app-title img,
notifications-setting-page .form h4.app-title img,
module-notifications-settings .form h4.app-title img {
  padding: 0;
  width: 28px;
  height: 28px;
  border: 1px solid #EBECEE;
}
notifications-settings .form h4.content-title rl-icon,
notifications-setting-page .form h4.content-title rl-icon,
module-notifications-settings .form h4.content-title rl-icon {
  background: #5fa3f1;
  color: white;
}
notifications-settings .form h4.social-title rl-icon,
notifications-setting-page .form h4.social-title rl-icon,
module-notifications-settings .form h4.social-title rl-icon {
  background: #eb6262;
  color: white;
}
notifications-settings .form h4.app-title rl-icon,
notifications-setting-page .form h4.app-title rl-icon,
module-notifications-settings .form h4.app-title rl-icon {
  background: black;
  color: #ea6f63;
}
notifications-settings .form-input-list,
notifications-setting-page .form-input-list,
module-notifications-settings .form-input-list {
  width: 100%;
  box-sizing: border-box;
  background: none;
  padding: 0;
  background: white;
}
notifications-settings .form-input-list.disabled,
notifications-setting-page .form-input-list.disabled,
module-notifications-settings .form-input-list.disabled {
  position: relative;
}
notifications-settings .form-input-list.disabled:after,
notifications-setting-page .form-input-list.disabled:after,
module-notifications-settings .form-input-list.disabled:after {
  content: '';
  position: absolute;
  z-index: 5;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.2);
}
notifications-settings .form-input-list .form-item-wrapper,
notifications-setting-page .form-input-list .form-item-wrapper,
module-notifications-settings .form-input-list .form-item-wrapper {
  padding: 0 20px;
  border-bottom: 1px solid #ebecee;
}
notifications-settings .form-input-list .form-item-wrapper.disabled,
notifications-setting-page .form-input-list .form-item-wrapper.disabled,
module-notifications-settings .form-input-list .form-item-wrapper.disabled {
  position: relative;
}
notifications-settings .form-input-list .form-item-wrapper.disabled:after,
notifications-setting-page .form-input-list .form-item-wrapper.disabled:after,
module-notifications-settings .form-input-list .form-item-wrapper.disabled:after {
  content: '';
  position: absolute;
  z-index: 5;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.2);
}
notifications-settings .form-input-list .form-item,
notifications-setting-page .form-input-list .form-item,
module-notifications-settings .form-input-list .form-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
notifications-settings .form-input-list .form-item.changed > label,
notifications-setting-page .form-input-list .form-item.changed > label,
module-notifications-settings .form-input-list .form-item.changed > label,
notifications-settings .form-input-list .form-item.changed .status,
notifications-setting-page .form-input-list .form-item.changed .status,
module-notifications-settings .form-input-list .form-item.changed .status {
  font-weight: 600;
}
notifications-settings .form-input-list .form-item > div,
notifications-setting-page .form-input-list .form-item > div,
module-notifications-settings .form-input-list .form-item > div {
  display: flex;
  justify-content: right;
}
notifications-settings .form-input-list .form-item > label,
notifications-setting-page .form-input-list .form-item > label,
module-notifications-settings .form-input-list .form-item > label {
  flex: 1;
  padding-right: 10px;
  min-height: auto;
  opacity: 0.8;
  color: unset;
  text-transform: none;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
notifications-settings .form-input-list .form-item .status,
notifications-setting-page .form-input-list .form-item .status,
module-notifications-settings .form-input-list .form-item .status {
  padding-top: 1px;
  padding-right: 10px;
}
notifications-settings .form-input-list .form-item .small-input,
notifications-setting-page .form-input-list .form-item .small-input,
module-notifications-settings .form-input-list .form-item .small-input {
  position: static;
  width: auto;
}
notifications-settings .form-input-list .form-item .small-input input:disabled + label,
notifications-setting-page .form-input-list .form-item .small-input input:disabled + label,
module-notifications-settings .form-input-list .form-item .small-input input:disabled + label {
  background: darkgray;
}
notifications-settings .module-settings.native .form-item > label {
  width: 50%;
}
notifications-settings .module-settings.native .form-item > div:not(.form-item-placeholder) {
  width: 50%;
  justify-content: space-between;
}
notifications-settings-popup notifications-settings-list,
notifications-settings-popup module-group-notifications-settings {
  display: block;
  height: calc(100% - 55px);
}
notifications-settings-popup .scroll-container {
  height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
module-notifications-settings-popup .popup-wrapper .popup-content-inner {
  padding: 0;
}
notifications-dropdown no-content {
  display: block;
}
notifications-dropdown no-content .no-content {
  display: block;
  height: 200px;
  margin: 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
notifications-dropdown no-content .no-content i {
  font-size: 40px;
  margin: 0 0 20px 0;
}
notifications-dropdown no-content .no-content p {
  font-size: 13px;
}
rich-mention-textarea {
  position: relative;
  display: block;
  background-color: var(--general-background-color);
  min-height: 253px;
}
@media only screen and (min-width: 1025px) {
  rich-mention-textarea {
    min-height: 299px;
  }
}
rich-mention-textarea .spinner {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  margin-left: -25px;
  margin-top: -25px;
  opacity: 0.5;
}
rich-mention-textarea user-mention,
rich-mention-textarea department-mention,
rich-mention-textarea user-group-mention,
rich-mention-textarea hash-tag {
  color: var(--highlight-color);
  font-weight: bold;
}
.tribute-container {
  border: 1px solid var(--general-border-color);
  border-radius: var(--general-border-radius);
  box-shadow: 0 7px 15px rgba(60, 72, 88, 0.1);
  width: 300px;
}
.tribute-container ul {
  background: var(--general-background-color);
  margin: 0;
}
.tribute-container li {
  display: flex;
  position: relative;
}
.tribute-container li:hover,
.tribute-container li.highlight {
  background: var(--background-highlight-color);
}
.tribute-container li .away-icon {
  position: absolute;
  left: 26px;
  top: 26px;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  font-size: 16px;
  z-index: 1;
  backdrop-filter: blur(13px);
  color: var(--general-text-inactive-color);
}
.tribute-container li img {
  display: inline-block;
  width: 36px;
  height: 36px;
  border-radius: 100%;
  margin-right: 10px;
}
.tribute-container li img.away {
  opacity: 0.6;
}
.tribute-container li .tag-label {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 34px;
  height: 34px;
  margin-right: 10px;
  font-size: 20px;
  border-radius: 100%;
  color: var(--text-on-highlight-color);
  background: var(--highlight-color);
}
.tribute-container li .right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
}
.tribute-container li .right > span {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.tribute-container li .right .title {
  font-size: 13px;
  font-weight: 600;
}
.tribute-container li .right .title .fa-star {
  color: var(--alert-color);
  vertical-align: middle;
}
.popup-wrapper {
  position: fixed;
  z-index: 201;
  display: none;
}
.popup-wrapper.open {
  display: block;
}
.popup-wrapper.open .popup-content {
  animation: showPopup 0.3s ease;
}
.popup-wrapper.template-half {
  display: block;
}
.popup-wrapper.template-half.ng-hide-add,
.popup-wrapper.template-half.ng-hide-remove {
  transition: all 0.3s;
}
.popup-wrapper.template-half.ng-hide-add .modal-loader {
  display: none;
}
.popup-wrapper.template-half.ng-hide-add.ng-hide-add-active {
  opacity: 0.4;
}
.popup-wrapper.template-half.ng-hide-add.ng-hide-add-active .popup-content {
  animation: hidePopup 0.3s ease;
}
.popup-wrapper.template-half.ng-hide-add.ng-hide-add-active .modal-loader {
  display: none;
}
.popup-wrapper.template-half.ng-hide-remove.ng-hide-remove-active .popup-content {
  animation: showPopup 0.3s ease;
}
.popup-wrapper.template-half header {
  min-height: auto;
}
.popup-wrapper .popup-wrapper-bg {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
}
@keyframes showPopup {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0%);
  }
}
@keyframes hidePopup {
  0% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(100%);
  }
}
.popup-wrapper .popup-content {
  position: fixed;
  z-index: 15;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  max-width: 768px;
  height: 100%;
  padding: 0;
  transform: translate(0, 0);
  background: var(--general-background-color);
}
@media only screen and (min-width: 768px) {
  .popup-wrapper .popup-content {
    left: 50%;
    margin-left: calc(-768px / 2);
  }
}
@media only screen and (max-width: 1024px) {
  .popup-wrapper .popup-content {
    height: calc(100% - var(--popup-height-shift));
  }
}
@media only screen and (min-width: 1025px) {
  .popup-wrapper .popup-content {
    height: auto;
    top: 5%;
    bottom: 5%;
    left: calc((var(--sidemenu-width) / 2) + 50%);
  }
  .popup-wrapper .popup-content .popup-content {
    top: 0;
    bottom: 0;
  }
}
.popup-wrapper .popup-content .modal-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.popup-wrapper .popup-content .modal-loader .spinner {
  width: 50px;
  height: 50px;
}
.popup-wrapper .popup-content .modal-loader .spinner.ng-animate {
  display: none;
}
.popup-wrapper .popup-content .shimmer-container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing);
  padding: var(--spacing);
  flex: 1 1 100%;
  overflow: hidden;
}
.popup-wrapper .popup-content .lazy-placeholder {
  background: var(--general-background-color);
  border-radius: var(--general-border-radius);
}
.popup-wrapper .popup-content .lazy-placeholder rl-loading-placeholder {
  display: block;
  box-sizing: border-box;
  padding: 15px;
}
.popup-wrapper .popup-content .lazy-placeholder rl-loading-placeholder .placeholder-shimmer {
  height: 19px;
}
.popup-wrapper .popup-content .close {
  border: none;
  background: transparent;
  padding: 0;
  margin: 0;
  color: inherit;
  cursor: pointer;
}
.popup-wrapper .popup-content .close i {
  font-size: 26px;
}
.popup-wrapper .scroll-container {
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.15) transparent;
  overscroll-behavior: none;
  -webkit-overflow-scrolling: touch;
}
.popup-wrapper .popup-content.clean:not(.flex) {
  display: block;
}
.popup-wrapper .popup-content.clean:not(.flex) .popup-content-inner {
  display: block;
  flex: initial;
  height: 100%;
}
.popup-wrapper .popup-content.clean .popup-content-inner {
  padding: 0;
}
.popup-wrapper .popup-content.half {
  width: 100%;
  max-height: 50vh;
  bottom: 0;
  top: auto;
}
@media only screen and (min-width: 1025px) {
  .popup-wrapper .popup-content.half {
    top: 25%;
    bottom: 25%;
  }
}
.popup-wrapper .popup-content.half .divider {
  width: 42px;
  display: block;
  border-radius: 7px;
  background: #ebecee;
  margin: 0 auto;
  position: relative;
  top: -10px;
  height: 5px;
}
@media only screen and (min-width: 1025px) {
  .popup-wrapper .popup-content.half .divider {
    display: none;
  }
}
.popup-wrapper .popup-content.clean.flex .popup-content-inner,
.popup-wrapper .popup-content-inner {
  display: flex;
  flex: 1;
  overflow-x: hidden;
  flex-direction: column;
}
.popup-wrapper .popup-content.clean.flex .popup-content-inner:not(.scroll-disabled),
.popup-wrapper .popup-content-inner:not(.scroll-disabled) {
  overflow-y: auto;
}
.popup-wrapper .popup-content-inner {
  padding: 10px 15px;
}
.popup-wrapper .popup-footer {
  flex: 0 1 auto;
  min-height: 40px;
  box-sizing: border-box;
  text-align: right;
  font-size: 0;
}
.popup-wrapper .popup-footer button {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  padding: 0 15px;
  cursor: pointer;
}
.popup-wrapper .popup-footer button[disabled] {
  color: #a7a7a7;
  opacity: 0.8;
  cursor: not-allowed;
}
icon {
  display: inline-block;
}
icon svg {
  width: 100%;
  height: 100%;
}
icon svg g,
icon svg path,
icon svg rect,
icon svg circle {
  fill: currentColor;
  stroke: currentColor;
}
translation-exclusions .translation-exclusion-btn {
  display: flex;
  align-items: center;
  gap: 10px;
  height: 40px;
  padding: 5px 15px;
  cursor: pointer;
  box-sizing: border-box;
  background: rgba(var(--alert-color-rgb), 0.2);
}
translation-exclusions .translation-exclusion-btn p {
  font-size: 13px;
  line-height: 1.3;
  margin: 0;
}
.base-view.base-view-search {
  background-color: var(--background-highlight-color);
  height: auto;
}
search-page {
  display: block;
  position: relative;
}
search-page .sticky-wrapper {
  z-index: 5;
  position: sticky;
  top: 55px;
  background-color: var(--background-highlight-color);
}
@media only screen and (min-width: 1025px) {
  search-page .sticky-wrapper {
    top: 60px;
  }
}
search-page .sticky-wrapper module-search-filters {
  padding: 7px 15px 10px 15px;
}
search-page .sticky-wrapper:after {
  display: block;
  content: '';
  height: 1px;
  margin-top: auto;
  width: 100%;
  background-color: var(--background-highlight-color);
}
search-page .search-results {
  display: block;
  padding-bottom: 15px;
}
search-page .search-results .results-wrapper {
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}
search-page .search-results .results-wrapper.hidden {
  opacity: 0;
}
search-page .search {
  padding: 7px 15px 0;
}
search-page .results-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px 10px;
}
search-page .results-header h2 {
  margin: 0;
  font-size: 13px;
  font-weight: 700;
}
@media only screen and (min-width: 1025px) {
  search-page .results-header h2 {
    font-size: 15px;
  }
}
search-page .help-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 90px auto 0;
  max-width: 250px;
  text-align: center;
}
search-page .help-text rl-icon {
  position: relative;
  font-size: 56px;
  margin-bottom: 20px;
  color: var(--general-text-inactive-color);
}
search-page .help-text h4 {
  margin: 0;
  font-size: 15px;
  font-weight: 700;
}
search-page .help-text p {
  margin: 0;
  font-size: 13px;
}
@media only screen and (min-width: 1025px) {
  search-page .help-text {
    max-width: 320px;
  }
  search-page .help-text h4 {
    margin-bottom: 10px;
  }
  search-page .help-text rl-icon {
    margin-bottom: 25px;
  }
}
search-page .spinner {
  position: fixed;
  width: 50px;
  height: 50px;
  border-color: var(--general-text-color);
  top: 50%;
  left: 50%;
  margin-top: -25px;
  margin-left: -25px;
  opacity: 0.4;
}
@media only screen and (min-width: 1025px) {
  search-page .spinner {
    left: calc(50% + 140px);
  }
}
@media only screen and (min-width: 1300px) {
  search-page .spinner {
    left: calc(50% + 180px);
  }
}
search-page .no-content {
  position: relative;
  margin: 90px auto 0;
}
filter-by-module-component {
  position: relative;
  display: inline-block;
}
filter-by-module-component .selected-filters {
  display: flex;
}
filter-by-module-component rl-toggle-button {
  margin-right: 10px;
}
filter-by-module-component .open-filter {
  padding: 10px;
  font-size: 13px;
  border-radius: var(--general-border-radius);
  background-color: var(--general-background-color);
  cursor: pointer;
  line-height: 20px;
}
filter-by-module-component .open-filter.selected {
  background-color: var(--highlight-color);
  color: var(--text-on-highlight-color);
}
filter-by-module-component .open-filter.selected rl-icon {
  color: var(--text-on-highlight-color);
}
filter-by-module-component .open-filter rl-icon {
  font-size: 12px;
  color: var(--general-text-inactive-color);
}
@media only screen and (min-width: 1025px) {
  filter-by-module-popup-component action-sheet-custom .actionsheet {
    width: 320px;
    margin-top: 50px;
    padding: 10px;
  }
}
filter-by-module-popup-component action-sheet-custom .actionsheet .actionsheet-content {
  padding-top: 0;
}
@media only screen and (min-width: 1025px) {
  filter-by-module-popup-component rl-select-list {
    margin-bottom: 10px;
  }
}
filter-by-module-popup-component .clear button {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  color: var(--warning-color);
}
@media only screen and (min-width: 1025px) {
  filter-by-module-popup-component .clear button {
    height: 40px;
    font-size: 12px;
  }
  filter-by-module-popup-component .clear button rl-icon {
    font-size: 14px;
  }
}
filter-by-module-popup-component .clear button rl-icon {
  margin-right: 7px;
  font-size: 14px;
}
.base-view.base-view-catalog {
  height: auto;
  background-color: var(--general-background-color);
}
catalog .loading .spinner,
catalog-category .loading .spinner,
catalog-item-details .loading .spinner {
  position: fixed;
  width: 50px;
  height: 50px;
  z-index: 100;
  top: 50%;
  left: 50%;
  margin-top: -25px;
  margin-left: -25px;
  border-color: var(--general-text-color);
}
@media only screen and (min-width: 1025px) {
  catalog .loading .spinner,
  catalog-category .loading .spinner,
  catalog-item-details .loading .spinner {
    left: calc(50% + 140px);
  }
}
@media only screen and (min-width: 1300px) {
  catalog .loading .spinner,
  catalog-category .loading .spinner,
  catalog-item-details .loading .spinner {
    left: calc(50% + 180px);
  }
}
catalog .breadcrumbs,
catalog-category .breadcrumbs,
catalog-item-details .breadcrumbs {
  padding-top: 20px;
}
catalog .breadcrumbs button,
catalog-category .breadcrumbs button,
catalog-item-details .breadcrumbs button {
  font-size: 13px;
  padding: 0;
  color: #3c4858;
  opacity: 0.4;
  font-weight: 600;
  cursor: pointer;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
catalog .breadcrumbs button.last,
catalog-category .breadcrumbs button.last,
catalog-item-details .breadcrumbs button.last {
  opacity: 1;
}
catalog .breadcrumbs button:not(:first-child):before,
catalog-category .breadcrumbs button:not(:first-child):before,
catalog-item-details .breadcrumbs button:not(:first-child):before {
  content: '\f054';
  padding: 0 5px;
  opacity: 0.4;
  font-size: 10px;
  font-family: 'Font Awesome 6\ Pro';
  font-weight: 400;
}
catalog,
catalog-category {
  display: block;
}
@media only screen and (max-width: 1024px) {
  catalog,
  catalog-category {
    padding: 0 20px;
  }
}
@media only screen and (min-width: 1025px) {
  catalog,
  catalog-category {
    margin: 0 auto;
    max-width: 60vw;
  }
}
catalog .count,
catalog-category .count {
  margin: 0;
  padding: 20px 0 5px 0;
  font-size: 13px;
  font-weight: 700;
}
catalog .catalog-grid,
catalog-category .catalog-grid {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  box-sizing: border-box;
  margin: 0 -5px;
}
@media only screen and (min-width: 768px) {
  catalog .catalog-grid,
  catalog-category .catalog-grid {
    margin: 0 -10px;
  }
}
catalog .catalog-grid::after,
catalog-category .catalog-grid::after {
  content: '';
  flex: auto;
}
catalog .no-results,
catalog-category .no-results {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 100px;
}
catalog .no-results icon,
catalog-category .no-results icon {
  height: 100px;
}
catalog .no-results p,
catalog-category .no-results p {
  text-align: center;
  max-width: 320px;
}
catalog-grid-item {
  margin: 0 5px 10px 5px;
  box-sizing: border-box;
  cursor: pointer;
  flex-basis: calc(100% / 2 - 10px);
}
@media only screen and (min-width: 768px) {
  catalog-grid-item {
    flex-basis: calc(100% / 3 - 20px);
    margin: 0 10px 15px 10px;
  }
}
@media only screen and (min-width: 1025px) {
  catalog-grid-item {
    flex-basis: calc(100% / 4 - 20px);
  }
}
@media only screen and (min-width: 1300px) {
  catalog-grid-item {
    flex-basis: calc(100% / 5 - 20px);
  }
}
catalog-grid-item .wrapper {
  position: relative;
  padding-bottom: 65%;
  border: 1px solid var(--general-border-color);
  border-radius: var(--catalog-border-radius);
  overflow: hidden;
}
catalog-grid-item .wrapper file-preview {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
catalog-grid-item .wrapper file-preview .image {
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
catalog-grid-item .wrapper file-preview rl-icon:not([icon=play]) {
  font-size: 4vw;
}
@media only screen and (max-width: 1024px) {
  catalog-grid-item .wrapper file-preview rl-icon:not([icon=play]) {
    font-size: 10vw;
  }
}
@media only screen and (max-width: 767px) {
  catalog-grid-item .wrapper file-preview rl-icon:not([icon=play]) {
    font-size: 15vw;
  }
}
catalog-grid-item .wrapper > rl-icon {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  font-size: 50px;
  color: var(--general-text-inactive-color);
}
catalog-grid-item h2 {
  margin: 0;
  padding: 5px 0 0 0;
  font-size: 13px;
  color: #3c4858;
  overflow: hidden;
  display: -webkit-box;
  word-break: break-word;
  word-break: auto-phrase;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
catalog-grid-item p {
  margin: 0;
  font-size: 14px;
  color: #8893A3;
}
catalog-item-details {
  display: flex;
  flex-direction: column;
}
@media only screen and (max-width: 767px) {
  catalog-item-details {
    padding: 0 10px;
  }
}
@media only screen and (max-width: 1024px) {
  catalog-item-details {
    padding: 0 20px;
    min-height: calc(100vh - 110px);
  }
}
catalog-item-details .breadcrumbs {
  margin-bottom: 20px;
}
catalog-item-details .gallery {
  margin-top: 10px;
}
catalog-item-details .gallery .wrap {
  border: 1px solid rgba(60, 72, 88, 0.1);
  margin-bottom: 10px;
}
catalog-item-details .gallery .img {
  position: relative;
  width: 100%;
  height: 200px;
}
@media only screen and (min-width: 768px) {
  catalog-item-details .gallery .img {
    height: 300px;
  }
}
@media only screen and (min-width: 1025px) {
  catalog-item-details .gallery .img {
    height: 500px;
  }
}
catalog-item-details .gallery .img img,
catalog-item-details .gallery .img .video-play-thumb,
catalog-item-details .gallery .img .media-preview-file {
  height: 200px;
  padding: 0;
}
@media only screen and (min-width: 768px) {
  catalog-item-details .gallery .img img,
  catalog-item-details .gallery .img .video-play-thumb,
  catalog-item-details .gallery .img .media-preview-file {
    height: 300px;
  }
}
@media only screen and (min-width: 1025px) {
  catalog-item-details .gallery .img img,
  catalog-item-details .gallery .img .video-play-thumb,
  catalog-item-details .gallery .img .media-preview-file {
    height: 500px;
  }
}
catalog-item-details .gallery .img img {
  max-height: initial;
}
catalog-item-details .gallery .img.animate {
  animation: imageChange 0.5s ease;
}
catalog-item-details .gallery .img .image-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -12px;
  margin-left: -12px;
}
catalog-item-details .gallery .img .image-spinner .spinner {
  margin: 0;
}
catalog-item-details .gallery .previews {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0 0 10px 0;
}
catalog-item-details .gallery .previews li {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 40px;
  height: 40px;
  margin-bottom: 10px;
  border: 1px solid rgba(60, 72, 88, 0.1);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  list-style: none;
}
@media only screen and (min-width: 768px) {
  catalog-item-details .gallery .previews li {
    width: 80px;
    height: 80px;
  }
}
@media only screen and (min-width: 1025px) {
  catalog-item-details .gallery .previews li {
    width: 100px;
    height: 100px;
  }
}
catalog-item-details .gallery .previews li.active {
  border: 1px solid var(--highlight-color);
}
catalog-item-details .gallery .previews li:not(:last-of-type) {
  margin-right: 10px;
}
catalog-item-details .gallery .previews li:not(.active) {
  opacity: 0.5;
}
catalog-item-details .gallery .previews li file-preview {
  position: relative;
  width: 100%;
  height: 100%;
}
catalog-item-details .gallery .previews li file-preview rl-icon {
  font-size: 40px;
}
catalog-item-details .gallery .previews li file-preview rl-icon[icon="play"] {
  width: 40px;
  height: 40px;
  font-size: 20px;
}
catalog-item-details .variants h2 {
  margin: 0 0 10px 0;
  font-size: 16px;
}
catalog-item-details .variants ul {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin: 0 0 10px 0;
  padding: 0;
}
catalog-item-details .variants ul li {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 7px 10px;
  background: #f8f8f8;
  list-style: none;
  cursor: pointer;
}
catalog-item-details .variants ul li.media {
  padding: 0;
}
catalog-item-details .variants ul li.media p {
  width: 40px;
  height: 40px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
catalog-item-details .variants ul li.selected {
  border: 1px solid var(--highlight-color);
  cursor: default;
}
catalog-item-details .variants ul li.disabled {
  opacity: 0.5;
  cursor: default;
}
catalog-item-details .variants ul li p {
  margin: 0;
}
catalog-item-details .variants ul li:first-child {
  margin-left: 0;
}
catalog-item-details .variants ul li:last-child {
  margin-right: 0;
}
catalog-item-details .variants ul li .color {
  width: 25px;
  height: 25px;
}
catalog-item-details .content {
  margin-top: 35px;
}
catalog-item-details .content header {
  margin-bottom: 13px;
}
catalog-item-details .content .description img {
  width: auto;
  height: auto;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: block;
}
catalog-item-details .content .description .video-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  height: 0;
}
catalog-item-details .content .description .video-wrapper iframe,
catalog-item-details .content .description .video-wrapper video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
catalog-item-details .content .description video {
  background: grey;
}
catalog-item-details .content .title {
  margin: 0;
  font-size: 20px;
  color: #494949;
}
catalog-item-details .content .subtitle {
  margin: 0;
  padding-top: 5px;
  font-size: 13px;
  line-height: 16px;
}
catalog-item-details .categories {
  margin-bottom: 13px;
}
catalog-item-details .categories h2 {
  font-size: 16px;
  margin: 0 0 10px 0;
}
catalog-item-details .categories .categoryLink {
  cursor: pointer;
  display: inline-block;
  padding: 0 10px 0 0;
  margin-bottom: 5px;
  font-weight: 600;
  color: #5FA3F1;
  text-decoration: underline;
}
catalog-item-details .categories .categoryLink:last-of-type {
  padding-right: 0;
}
catalog-item-details .descripttion {
  font-size: 15px;
  line-height: 16px;
}
catalog-item-details .descripttion p {
  margin: 10px 0;
}
catalog-item-details barcode img {
  display: block;
  margin: 0 auto;
}
catalog-item-details .custom-fields {
  margin-top: 35px;
}
catalog-item-details .custom-fields h2 {
  margin: 0 0 10px 0;
  font-size: 15px;
}
catalog-item-details .custom-fields table {
  border-collapse: collapse;
}
catalog-item-details .custom-fields table tr:nth-child(even) {
  background: #f8f8f8;
}
catalog-item-details .custom-fields table td {
  padding: 6px 8px;
  border: 1px solid #ececec;
}
catalog-item-details .custom-fields table td .number {
  text-align: right;
}
catalog-item-details .custom-fields table td .color {
  width: 50px;
  height: 50px;
}
catalog-item-details .custom-fields table p {
  margin: 0;
}
catalog-item-details .buttons-wrapper {
  display: flex;
  justify-content: flex-end;
  position: sticky;
  margin-top: auto;
  padding-top: 40px;
  margin-bottom: 20px;
  bottom: calc(70px + env(safe-area-inset-bottom, 0px));
}
@media only screen and (min-width: 1025px) {
  catalog-item-details .buttons-wrapper {
    bottom: 40px;
    margin-top: 40px;
    margin-bottom: 40px;
    padding-top: 0;
  }
}
catalog-item-details .buttons-wrapper .buttons {
  display: flex;
  background: var(--white-color);
  filter: drop-shadow(0 6px 15px rgba(0, 0, 0, 0.15));
  border-radius: var(--general-border-radius);
}
catalog-item-details .buttons-wrapper .buttons button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 46px;
  height: 46px;
  padding: 0;
  color: var(--general-text-color);
  background: var(--background-highlight-color);
  border-radius: var(--general-border-radius);
  border: 1px solid var(--white-color);
}
catalog-item-details .buttons-wrapper .buttons button:not([disabled]) {
  cursor: pointer;
}
catalog-item-details .buttons-wrapper .buttons button[disabled] {
  color: var(--general-text-inactive-color);
}
catalog-item-details .buttons-wrapper .buttons button.prev {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
catalog-item-details .buttons-wrapper .buttons button.next {
  position: relative;
  left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
catalog-search module-search-filters {
  padding: 10px 0 0;
}
catalog-search .search-header.sticky {
  z-index: 10;
  position: sticky;
  top: 55px;
  background: var(--background-highlight-color);
}
@media only screen and (min-width: 1025px) {
  catalog-search .search-header.sticky {
    top: 60px;
  }
}
catalog-search .search {
  display: flex;
  padding-top: 15px;
}
catalog-search .search rl-search {
  flex: 1;
}
catalog-search .results-wrapper {
  opacity: 1;
}
catalog-search .results-wrapper.hidden {
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}
catalog-search-list-component rl-search-result-item {
  margin: 0 0 15px;
}
catalog-search-list-component .search-list {
  display: flex;
  flex-direction: column;
  position: relative;
  box-sizing: border-box;
}
catalog-search-list-component .search-item {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  padding: 5px;
  border-radius: 7px;
  background-color: var(--background-highlight-color);
}
catalog-search-list-component .info {
  display: flex;
  position: relative;
  flex-direction: row;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--general-border-color);
}
catalog-search-list-component .wrapper {
  flex: 1;
  margin-right: 10px;
  min-height: 70px;
  min-width: 70px;
  position: inherit;
}
catalog-search-list-component .item-details {
  flex: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 5px;
}
catalog-search-list-component .breadcrumbs {
  padding-top: 0;
  margin-bottom: 10px;
}
.base-view.base-view-socialOverview {
  background: var(--background-highlight-color);
  height: auto;
}
social-groups {
  display: block;
  padding: 15px 10px;
}
social-groups .social-group-placeholder {
  margin-bottom: 10px;
  padding: 15px 15px 15px 20px;
  border-radius: var(--general-border-radius);
  background: var(--general-background-color);
}
social-group {
  display: block;
  position: relative;
  margin-bottom: 10px;
}
social-group .social-group {
  padding: 15px 15px 15px 20px;
  border-left: 7px solid;
  border-radius: var(--general-border-radius);
  background: var(--general-background-color);
}
social-group .social-group .follow-actions {
  margin-left: 5px;
}
social-group .social-group .follow-actions button {
  max-width: 120px;
  height: 30px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 13px;
  font-weight: 500;
}
social-group .social-group header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
social-group .social-group header h2 {
  margin: 0;
  font-weight: 700;
  font-size: 15px;
  line-height: 130%;
}
social-group .social-group .selected {
  font-style: italic;
  color: var(--confirmation-color);
  margin: 10px 0;
}
social-group .social-group .selected rl-icon {
  background-color: var(--confirmation-color);
  color: var(--white-color);
}
social-group .social-group .summary {
  margin: 0 10px 0 0;
  font-size: 13px;
  line-height: 130%;
}
social-group .social-group .new-posts-label {
  margin: 10px 0 0;
  color: var(--highlight-color);
  font-weight: 500;
  display: flex;
  align-items: center;
}
social-group .social-group .new-posts-label .circle {
  background: var(--highlight-color);
  width: 10px;
  height: 10px;
  border-radius: 100%;
  margin-right: 5px;
}
social-live-tile {
  display: block;
  background: var(--background-highlight-color);
  height: 100%;
}
social-live-tile > header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 15px;
}
social-live-tile > header h2 {
  display: flex;
  flex-direction: row;
  text-transform: uppercase;
  font-size: 15px;
  margin: 0;
}
social-live-tile > header h2 rl-badge {
  margin: 0 0 0 5px;
}
social-live-tile > header button {
  padding: 0;
}
social-live-tile social-wall {
  display: block;
  height: calc(100% - 50px);
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  padding: 0 15px;
}
social-live-tile .social-page {
  height: 100%;
  padding: 0;
}
@media only screen and (min-width: 1025px) {
  social-live-tile .social-page sorting {
    margin: 0 auto;
    max-width: 60vw;
  }
}
social-live-tile .social-post-item:first-of-type {
  margin-top: 0;
}
social-wall {
  display: block;
}
social-wall .show-new-posts {
  overflow: hidden;
  position: fixed;
  z-index: 10;
  top: 70px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  padding: 5px 15px;
  color: var(--text-on-highlight-color);
  background: var(--highlight-color);
  font-weight: 500;
  border-radius: 15px;
  box-shadow: 0 2px 10px 0 rgba(var(--general-text-color-rgb), 0.5);
}
@media only screen and (min-width: 1025px) {
  social-wall .show-new-posts {
    margin-left: 140px;
  }
}
@media only screen and (min-width: 1300px) {
  social-wall .show-new-posts {
    margin-left: 180px;
  }
}
social-wall .show-new-posts rl-icon {
  padding-right: 10px;
  font-size: 15px;
}
social-wall .hidden-post {
  padding: 15px;
}
social-wall .hidden-post .hidden-content {
  display: flex;
  flex-wrap: wrap-reverse;
  padding-bottom: 10px;
}
social-wall .hidden-post .blocked-user-image {
  margin-right: 10px;
  width: 34px;
  height: 34px;
}
.social-page sorting {
  padding-top: 15px;
}
.social-page .lazy-placeholder {
  display: block;
  margin: var(--spacing) auto;
  max-width: 600px;
  background: var(--general-background-color);
  border-radius: var(--general-border-radius);
}
@media only screen and (min-width: 1025px) {
  .social-page .lazy-placeholder {
    max-width: 60vw;
  }
}
.social-page .lazy-placeholder rl-loading-placeholder {
  display: block;
  box-sizing: border-box;
  padding: 15px;
}
.social-page .lazy-placeholder rl-loading-placeholder .placeholder-shimmer {
  height: 19px;
}
social-post-create .social-post-create-inner {
  padding-bottom: 75px;
}
social-post-create .scroll-container {
  position: relative;
}
social-post-create .social-post-create-options {
  display: block;
  padding: 0;
  margin: 0;
  list-style: none;
  flex: 0 0 auto;
}
social-post-create .social-post-create-options > li {
  display: block;
  width: 100%;
  border-bottom: 1px solid var(--general-border-color);
}
social-post-create .social-post-create-options > li.disabled > .social-group-select {
  background: rgba(0, 0, 0, 0.1);
}
social-post-create .social-post-create-options > li.disabled > .social-group-select .icon,
social-post-create .social-post-create-options > li.disabled > .social-group-select span {
  opacity: 0.5;
}
social-post-create .social-post-create-options > li.disabled > .social-group-select .ripple-effect {
  display: none;
}
social-post-create .social-post-create-options > li > .upload-btn,
social-post-create .social-post-create-options > li > .social-group-select {
  display: block;
  height: 40px;
  line-height: 40px;
  position: relative;
  overflow: hidden;
}
social-post-create .social-post-create-options > li > .upload-btn .icon,
social-post-create .social-post-create-options > li > .social-group-select .icon {
  height: 40px;
  line-height: 40px;
  width: 50px;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  font-size: 20px;
  display: block;
  text-align: center;
}
social-post-create .social-post-create-options > li > .upload-btn .icon rl-icon,
social-post-create .social-post-create-options > li > .social-group-select .icon rl-icon {
  height: 40px;
}
social-post-create .social-post-create-options > li > .upload-btn .icon.green,
social-post-create .social-post-create-options > li > .social-group-select .icon.green {
  color: #6aab1f;
}
social-post-create .social-post-create-options > li > .upload-btn .label,
social-post-create .social-post-create-options > li > .social-group-select .label {
  position: absolute;
  box-sizing: border-box;
  padding: 0 10px;
  height: 40px;
  line-height: 40px;
  display: block;
  left: 50px;
  top: 0;
  bottom: 0;
  right: 0;
}
social-post-create .social-post-create-options > li > .upload-btn .label > b,
social-post-create .social-post-create-options > li > .social-group-select .label > b {
  font-weight: 600;
}
social-post-create .social-post-create-options > li > .upload-btn .label > i,
social-post-create .social-post-create-options > li > .social-group-select .label > i {
  font-size: 13px;
  margin-left: 5px;
}
social-post-create .social-post-create-options .language-selector-wrapper {
  display: flex;
  justify-content: space-between;
  background-color: var(--general-background-color);
}
social-post-create .social-post-create-options .language-selector-wrapper language-selector {
  flex: 1;
  overflow: hidden;
}
social-post-create .social-post-create {
  overflow-y: auto;
  height: calc(100% - 55px);
  background: white;
}
social-post-create .social-post-create .save button {
  width: 100%;
  color: var(--white-color);
  background: var(--button-background-color);
  text-transform: uppercase;
}
social-post-create .social-post-create .social-post-create-inner {
  position: static;
  display: flex;
  flex-direction: column;
  height: 100%;
}
social-post-create .social-post-create .social-post-create-area {
  flex: 1;
}
social-post-create .social-post-create rl-rich-textarea {
  border-top: none;
}
social-post-create .social-post-create social-poll {
  margin: 10px;
}
social-post-create .social-post-create .social-post-create-media {
  display: flex;
  flex-wrap: wrap;
  flex: 0 0 auto;
  width: 100%;
  min-height: 10px;
  max-height: 40%;
  padding: 10px 0 0 0;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  border-bottom: 1px solid #ddd;
  box-sizing: border-box;
  font-size: 0;
}
social-post-create .social-post-create .social-post-create-media .preview-container {
  width: 46%;
  height: 80px;
  margin: 0 2% 10px 2%;
  display: inline-block;
  position: relative;
}
social-post-create .social-post-create .social-post-create-media .preview-container .media-thumbnail .image {
  height: 80px;
}
social-post-create .social-post-create .social-post-create-media .preview-container .preview-container-close {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 2;
  font-size: 19px;
  text-align: right;
  color: #fff;
  box-sizing: border-box;
  padding: 4px 5px 0 0;
}
social-post-create .social-post-create .attachments-wrapper {
  padding-top: 10px;
  margin-top: auto;
}
social-post-create .social-post-create .attachments-wrapper attachment-preview {
  box-sizing: border-box;
  display: block;
  padding: 10px;
}
social-post-create .social-post-create .attachments-label {
  padding: 0 10px;
  color: var(--general-text-color);
}
social-post-create .social-post-create .social-post-create-loader {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 16;
  background: rgba(0, 0, 0, 0.5);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
social-post-create .social-post-create .social-post-create-loader .spinner {
  border-color: #fff;
  height: 40px;
  width: 40px;
}
social-post-create .social-post-create .social-post-create-loader.ng-enter,
social-post-create .social-post-create .social-post-create-loader.ng-leave.ng-leave-active {
  opacity: 0;
}
social-post-create .social-post-create .social-post-create-loader.ng-leave,
social-post-create .social-post-create .social-post-create-loader.ng-enter.ng-enter-active {
  opacity: 1;
}
.base-view.base-view-socialHashtags {
  background: var(--background-highlight-color);
  height: auto;
}
social-hashtags {
  display: block;
  padding-top: 15px;
}
@media only screen and (max-width: 1024px) {
  social-hashtags {
    padding-left: 15px;
    padding-right: 15px;
  }
}
social-hashtags .hashtag {
  display: flex;
  align-items: center;
  background: var(--general-background-color);
  padding: 10px 15px;
  box-sizing: border-box;
  border-radius: var(--general-border-radius);
}
@media only screen and (min-width: 1025px) {
  social-hashtags .hashtag {
    max-width: 60vw;
  }
}
social-hashtags .hashtag .content {
  overflow: hidden;
}
social-hashtags .hashtag .name {
  display: flex;
  flex-direction: row;
  margin: 0;
  font-weight: 600;
  padding-bottom: 5px;
  color: var(--general-text-color);
  font-size: 15px;
}
social-hashtags .hashtag .name .tag {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
social-hashtags .hashtag .name .featured {
  margin-left: 5px;
  color: var(--alert-color);
}
social-hashtags .hashtag .tag-label {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 34px;
  height: 34px;
  margin-right: 10px;
  font-size: 20px;
  border-radius: 100%;
  color: var(--text-on-highlight-color);
  background: var(--highlight-color);
}
social-hashtags .hashtag .tag-label .content {
  width: 100%;
  overflow: hidden;
}
social-hashtags .hashtag .subTitle {
  margin: 0;
}
.base-view-socialPost {
  background: var(--background-highlight-color);
}
.base-view-socialPost .social-page {
  padding-left: var(--spacing);
  padding-right: var(--spacing);
  padding-top: var(--spacing);
}
social-post {
  display: block;
  position: relative;
}
social-post social-poll {
  margin-bottom: 30px;
}
.post-hashtag {
  cursor: pointer;
}
.post-tag {
  cursor: pointer;
}
.tag-label {
  cursor: unset;
}
.post-tag-user-token {
  visibility: hidden;
  display: none;
}
.post-tag-department-token {
  visibility: hidden;
  display: none;
}
.post-tag-user-group-token {
  visibility: hidden;
  display: none;
}
.social-post-item-encoding-missing {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 2;
  background: rgba(var(--color-white-rgb), 0.95);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 20px;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  max-width: 500px;
  margin: 0 auto;
}
@media only screen and (min-width: 1025px) {
  .social-post-item-encoding-missing {
    max-width: 60vw;
  }
}
.social-post-item {
  position: relative;
  display: block;
  width: 100%;
  box-sizing: border-box;
  background: var(--general-background-color);
  border-radius: var(--general-border-radius);
}
.social-post-item.is-hashtag {
  padding-bottom: 15px;
}
@media only screen and (min-width: 1025px) {
  .social-post-item {
    max-width: 60vw;
  }
}
.social-post-item .app-link-url {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  user-select: none;
}
.social-post-item .post-inner {
  padding-top: 15px;
}
.social-post-item .post-inner > * {
  padding-right: 15px;
  padding-left: 15px;
}
.social-post-item .post-inner .post-footer {
  padding-right: 10px;
  padding-left: 10px;
}
.social-post-item .open-profile {
  cursor: pointer;
}
.social-post-item translation-button {
  margin: 15px 0;
}
.social-post-item .stamp {
  width: 80px;
  height: 80px;
  margin-left: auto;
  margin-bottom: 10px;
  background-position: center center !important;
  background-size: contain !important;
  background-repeat: no-repeat;
}
.social-post-item rl-rich-preview {
  position: relative;
  margin: 15px 0 10px 0;
}
.social-post-item rl-rich-preview .read-more {
  margin-bottom: 0;
}
.social-post-item .post-content-translation {
  margin: 15px 0;
  box-sizing: border-box;
  border-left: 2px solid rgba(0, 0, 0, 0.25);
}
.social-post-item .post-content-translation .post-content {
  margin-left: 15px;
}
.social-post-item .post-content-translation .post-translation-toggle-container {
  margin-bottom: 0;
  margin-left: 15px;
}
.social-post-item .post-translation-toggle-container {
  margin: 15px 0;
}
.social-post-item .post-translation-toggle-container .post-translation-toggle {
  display: block;
  cursor: pointer;
  color: #508AE4;
  padding: 0;
}
.social-post-item .post-image {
  margin: 5px -15px 5px -15px;
  position: relative;
}
.social-post-item .post-image media-preview {
  min-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.social-post-item .post-image .stamp {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 10;
  margin: 0;
}
.social-post-item .post-image .embed-video {
  width: 100%;
  max-width: 100%;
}
.social-post-item .post-image .embed-video > .embed-video-wrapper {
  position: relative;
  padding-bottom: 46%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
}
.social-post-item .post-image .embed-video > .embed-video-wrapper iframe,
.social-post-item .post-image .embed-video > .embed-video-wrapper object,
.social-post-item .post-image .embed-video > .embed-video-wrapper embed,
.social-post-item .post-image .embed-video > .embed-video-wrapper video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
@media only screen and (min-width: 1025px) {
  .social-post-item .post-image .media .image img {
    max-height: 600px;
    object-fit: contain;
  }
}
.social-post-item .post-info {
  height: 1.2rem;
  line-height: 1.2rem;
  font-size: 0.8em;
  text-transform: uppercase;
  text-align: center;
  color: rgba(var(--general-text-color-rgb), 0.4);
  padding: var(--s-spacing) 0 var(--m-spacing);
  border-bottom: 1px solid var(--general-border-color);
}
.social-post-item .post-info span {
  display: inline-block;
  vertical-align: top;
  margin-right: 11px;
  position: relative;
  font-weight: 600;
}
.social-post-item .post-info .reaction-like {
  margin: 0;
  display: flex;
  font-weight: 600;
}
.social-post-item .post-info .default-reaction-label {
  margin: 0;
}
.social-post-item .post-bottom {
  height: 50px;
  line-height: 50px;
  text-align: center;
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-around;
}
.social-post-item .post-bottom .reaction-message {
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 0;
  left: 0;
  right: 0;
  background: var(--general-background-color);
  z-index: 7;
  user-select: none;
}
@media only screen and (min-width: 1025px) {
  .social-post-item .post-bottom .reaction-message {
    display: none;
  }
}
.social-post-item .post-bottom rl-icon {
  font-size: 17px;
}
.social-post-item .post-bottom reaction-icon rl-icon {
  display: flex;
}
.social-post-item .post-bottom reaction-button {
  flex: 0 0 33.33%;
}
.social-post-item .post-bottom reaction-button .btn-reaction {
  min-width: 90px;
}
@media only screen and (max-width: 1024px) {
  .social-post-item .post-bottom reaction-button .btn-reaction {
    position: static;
  }
}
@media only screen and (max-width: 1024px) {
  .social-post-item .post-bottom reaction-button .reactions-list {
    left: 50%;
    transform: translateX(-50%);
  }
}
.social-post-item .post-bottom button {
  word-break: break-word;
  flex: 0 0 33.33%;
  position: relative;
  display: inline-block;
  margin: 0;
  padding: 0;
  vertical-align: top;
  font-size: 13px;
  line-height: 21px;
  text-transform: uppercase;
  color: rgba(var(--general-text-color-rgb), 0.4);
  background: transparent;
  border: none;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
.social-post-item .post-bottom button reaction-icon {
  line-height: 18px;
  margin-right: 2px;
}
.social-post-item .post-bottom button reaction-icon span {
  margin: 0;
}
.social-post-item .post-bottom button > span {
  margin-right: 7px;
}
.social-post-item .post-bottom button.liked {
  color: var(--highlight-color);
}
.social-post-item .bookmark-message {
  width: 100%;
  font-size: 12px;
  line-height: 40px;
  padding: 0 15px;
  box-sizing: border-box;
  color: transparent;
  background: rgba(0, 0, 0, 0.7);
  height: 0;
  visibility: hidden;
  transition: all 0.3s;
  position: relative;
}
.social-post-item .bookmark-message.show {
  color: white;
  height: 40px;
  visibility: visible;
}
.social-post-item .bookmark-message .icon {
  position: absolute;
  right: 5px;
  top: 13px;
  font-size: 14px;
}
.social-post-item .post-comments-loading {
  display: block;
  height: 40px;
  line-height: 40px;
  text-align: center;
}
.social-post-item .post-comments-loading .spinner {
  border-color: var(--general-text-color);
}
.social-post-item .post-top {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  min-height: 40px;
}
.social-post-item .post-top .left,
.social-post-item .post-top .right {
  display: flex;
  align-items: center;
}
.social-post-item .post-top .right {
  position: relative;
  right: -2px;
}
.social-post-item .post-top .post-options {
  padding: 0;
  text-align: center;
  color: var(--general-text-color);
  background: transparent;
  border: none;
}
.social-post-item .post-top .post-options rl-icon {
  font-size: 20px;
}
@media only screen and (min-width: 1025px) {
  .social-post-item .post-top .post-options rl-icon {
    font-size: 25px;
  }
}
.social-post-item .post-top .pin-wrapper {
  position: relative;
  padding-right: 10px;
}
.social-post-item .post-top .pin-wrapper rl-icon {
  color: var(--highlight-color);
  font-size: 13px;
}
.social-post-item .post-top tip .tip-content {
  right: -7px;
  top: 30px;
  text-align: left;
  z-index: 10;
}
.social-post-item .post-top tip .tip-content h3 {
  text-transform: uppercase;
  padding-bottom: 4px;
}
.social-post-item .post-top tip .tip-content h3:after {
  content: ':';
}
.social-post-item .post-top tip .tip-content:after {
  right: 35px;
  left: auto;
}
.social-post-item .post-top profile-image {
  padding-right: 10px;
}
.social-post-item .post-top profile-image .leave-icon {
  right: 7px;
}
.social-post-item .post-top profile-image .profile-image {
  height: 34px;
  width: 34px;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
.social-post-item .post-top .profile-info {
  display: block;
  box-sizing: border-box;
}
.social-post-item .post-top .profile-info h3 {
  margin: 0;
  font-size: 15px;
  display: block;
  color: var(--general-text-color);
}
.social-post-item .post-top .profile-info .divider {
  padding: 0 2px;
}
.social-post-item .post-top .profile-info .department span:not(:last-of-type)::after {
  content: ',';
}
social-poll {
  position: relative;
  display: block;
  min-height: 40px;
}
social-poll .add-poll {
  position: absolute;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 40px;
  padding: 10px;
  color: var(--highlight-color);
  background: rgba(var(--highlight-color-rgb), 0.1);
  border: 1px solid rgba(var(--highlight-color-rgb), 0.1);
  box-sizing: border-box;
  border-radius: var(--general-border-radius);
}
social-poll .add-poll .add-poll-title {
  margin-top: 2px;
  margin-left: 5px;
  font-weight: bold;
  font-size: 13px;
  line-height: 16px;
}
social-poll .add-poll [icon=add] {
  margin-left: auto;
  font-size: 15px;
}
social-poll .settings {
  padding-bottom: 10px;
}
social-poll .settings > div {
  display: flex;
  align-items: center;
  height: 45px;
}
social-poll .settings > div rl-icon {
  margin-right: 10px;
  font-size: 17px;
}
social-poll .settings > div rl-toggle {
  margin-left: auto;
}
social-poll .poll header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
social-poll .poll header div {
  flex: 1;
  display: flex;
}
social-poll .poll header div > span {
  margin-left: 5px;
}
social-poll .poll header button {
  padding: 0;
}
social-poll .poll header rl-icon {
  width: 19px;
  vertical-align: top;
}
social-poll .poll header rl-icon[icon=settings] {
  opacity: 0.5;
}
social-poll .poll header .header-title {
  font-size: 15px;
  font-weight: bold;
}
social-poll .poll header input {
  flex: 1;
  height: 19px;
  margin-left: 5px;
  padding: 0;
  font-weight: bold;
  font-size: 15px;
  line-height: 19px;
  border: none;
}
social-poll .poll header input::placeholder {
  color: rgba(var(--general-text-color-rgb), 0.4);
}
social-poll .poll .poll-options .option {
  position: relative;
}
social-poll .poll .poll-options .option:not(:last-of-type) {
  margin-bottom: 10px;
}
social-poll .poll .poll-options .option input,
social-poll .poll .poll-options .option .text {
  width: 100%;
  min-height: 40px;
  padding: 10px 40px 10px 10px;
  font-size: 13px;
  line-height: 18px;
  border: 1px solid rgba(var(--general-text-color-rgb), 0.15);
  border-radius: var(--general-border-radius);
  box-sizing: border-box;
  -webkit-appearance: none;
}
social-poll .poll .poll-options .option .text {
  position: relative;
  text-align: left;
  -webkit-appearance: none;
  word-wrap: break-word;
}
social-poll .poll .poll-options .option .text.voted {
  color: var(--highlight-color);
  font-weight: bold;
  border-left-width: 3px;
  border-right-width: 3px;
  border-color: var(--highlight-color);
}
social-poll .poll .poll-options .option .text.clickable {
  cursor: pointer;
}
social-poll .poll .poll-options .option .text[disabled] {
  -webkit-tap-highlight-color: transparent;
}
social-poll .poll .poll-options .option .text .percent {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: rgba(var(--highlight-color-rgb), 0.1);
}
social-poll .poll .poll-options .option input::placeholder {
  color: rgba(var(--general-text-color-rgb), 0.4);
}
social-poll .poll .poll-options .option .option-votes {
  display: flex;
  margin-top: 5px;
  align-items: center;
}
social-poll .poll .poll-options .option .option-votes user-portraits {
  margin-right: 5px;
}
social-poll .poll .poll-options .option .option-votes user-portraits div {
  width: 20px;
  height: 20px;
}
social-poll .poll .poll-options .option .option-votes user-portraits div:not(:last-of-type) {
  margin-right: -5px;
}
social-poll .poll .poll-options .option .option-votes span {
  text-transform: lowercase;
  opacity: 0.4;
  font-size: 13px;
  line-height: 16px;
}
social-poll .poll .poll-options .option .option-button {
  position: absolute;
  right: 0;
  top: 0;
  padding: 0;
  height: 40px;
  width: 40px;
}
social-poll .poll .poll-options .option .option-button rl-icon {
  height: 40px;
  font-size: 15px;
  opacity: 0.4;
}
social-poll .poll footer {
  display: flex;
  justify-content: space-between;
  margin: 10px 0 0 0;
}
social-poll .poll footer .deadline {
  flex: 1;
  display: flex;
  align-items: center;
  padding-right: 10px;
  font-size: 13px;
  line-height: 16px;
}
social-poll .poll footer .deadline .label {
  margin-right: 5px;
}
social-poll .poll footer .deadline input {
  flex: 1;
  vertical-align: middle;
  height: 27px;
  padding: 0 10px;
  font-size: 13px;
  line-height: 16px;
  border-radius: var(--general-border-radius);
  border: none;
  background: var(--background-highlight-color);
  -webkit-appearance: none;
}
social-poll .poll footer .deadline .never {
  display: flex;
  align-items: center;
  height: 27px;
  padding: 0 10px;
  border-radius: var(--general-border-radius);
  background: var(--background-highlight-color);
}
social-poll .poll footer .deadline .never > span {
  margin: 0 5px;
  font-size: 13px;
  line-height: 16px;
  font-weight: 700;
}
social-poll .poll footer .deadline .never [icon=clock] {
  font-size: 12px;
}
social-poll .poll footer .deadline .never [icon=arrow-bottom] {
  margin-top: 2px;
  font-size: 8px;
}
social-poll .poll footer .remove-poll {
  height: 27px;
  padding: 0 10px;
  font-weight: bold;
  font-size: 13px;
  line-height: 16px;
  color: var(--warning-color);
  background: rgba(var(--warning-color-rgb), 0.1);
  border-radius: var(--general-border-radius);
}
social-poll .poll footer .deadline {
  font-size: 13px;
  line-height: 16px;
}
social-poll .poll footer .deadline .time-left {
  text-transform: lowercase;
  padding-left: 5px;
  font-weight: bold;
}
social-poll .poll footer .votes {
  color: var(--general-text-color);
  text-transform: lowercase;
  opacity: 0.4;
  font-size: 13px;
  line-height: 16px;
}
voters-popup .popup-content-inner > section {
  padding: 0 15px;
  height: calc(100% - 55px);
}
voters-popup .popup-content-inner > section simple-user-list {
  display: block;
  height: 100%;
}
social-comments-popup .loading-wrapper {
  background: rgba(var(--background-highlight-color-rgb), 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: calc(100% - 55px);
  left: 0;
  top: 0;
  position: absolute;
  z-index: 10;
}
hashtag-suggestions {
  position: absolute;
  left: 0;
  top: calc(7px + 45px + 10px);
  z-index: 10;
  display: flex;
  width: calc(100% - var(--spacing) * 2);
  min-height: 54px;
  max-height: calc(54px * 3);
  overflow: hidden;
  background: var(--general-background-color);
  border-radius: var(--general-border-radius);
  filter: drop-shadow(0px 4px 7px rgba(60, 72, 88, 0.1));
}
hashtag-suggestions .spinner {
  opacity: 0.5;
  margin: auto;
}
hashtag-suggestions ul {
  width: 100%;
  overflow-y: auto;
  margin: 0;
  padding: 0;
  list-style: none;
  overscroll-behavior: contain;
}
hashtag-suggestions li {
  display: flex;
  height: 54px;
  padding: 10px 15px;
  box-sizing: border-box;
}
hashtag-suggestions li:hover {
  background: var(--background-highlight-color);
}
hashtag-suggestions .name {
  display: flex;
  flex-direction: row;
  margin: 0;
  font-weight: 600;
  color: var(--general-text-color);
  font-size: 15px;
}
hashtag-suggestions .name .tag {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
hashtag-suggestions .name .featured {
  margin-left: 5px;
  color: var(--alert-color);
}
hashtag-suggestions .tag-label {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 34px;
  height: 34px;
  margin-right: 10px;
  font-size: 20px;
  border-radius: 100%;
  color: var(--text-on-highlight-color);
  background: var(--highlight-color);
}
hashtag-suggestions .rl-subheadline {
  margin: 0;
}
.base-view.base-view-social {
  background: var(--background-highlight-color);
  height: auto;
}
.menu-opened social .search-wrapper.sticky {
  top: auto !important;
}
social {
  display: block;
}
social .search-wrapper {
  position: relative;
}
social .search-wrapper.sticky {
  z-index: 10;
  position: sticky;
  top: 55px;
  background: var(--background-highlight-color);
}
@media only screen and (min-width: 1025px) {
  social .search-wrapper.sticky {
    top: 60px;
  }
}
@media only screen and (max-width: 1024px) {
  social .search-wrapper module-search-filters {
    padding-left: var(--spacing);
    padding-right: var(--spacing);
  }
}
social .social-content {
  padding-top: var(--m-spacing);
}
social .social-content.unset-padding {
  padding-top: 0;
}
@media only screen and (max-width: 1024px) {
  social .social-content {
    padding-left: var(--spacing);
    padding-right: var(--spacing);
  }
}
@media only screen and (min-width: 1025px) {
  social .social-content {
    padding-bottom: var(--spacing);
  }
}
social .social-content users-list {
  margin-top: 10px;
  height: calc(100vh - 216px);
}
@media only screen and (min-width: 1025px) {
  social .social-content users-list {
    height: calc(100vh - 242px);
  }
}
social .social-content users-list .user-list-wrapper {
  background: transparent;
}
social .social-content users-list .divider {
  padding: 15px 0 0 10px;
  font-weight: 400;
  font-size: 11px;
  line-height: 130%;
  color: var(--general-text-inactive-color);
}
social .social-content users-list .user {
  padding: var(--s-spacing) 0;
}
social .social-content users-list .spinner-wrapper {
  opacity: 0.4;
}
social .social-content > .spinner {
  z-index: 100;
  position: fixed;
  top: 50%;
  left: 50%;
  width: 50px;
  height: 50px;
  margin-left: -25px;
  margin-top: -25px;
  border-color: var(--general-text-color);
}
@media only screen and (min-width: 1025px) {
  social .social-content > .spinner {
    left: calc(50% + 140px);
  }
}
@media only screen and (min-width: 1300px) {
  social .social-content > .spinner {
    left: calc(50% + 180px);
  }
}
:root {
  --training-background: var(--general-background-color);
  --training-inner-background: var(--general-background-color);
}
.base-view.base-view-training {
  height: auto;
  background-color: var(--training-background);
}
training-course-page,
training-module-page,
training-voluntary-category,
training-voluntary-page {
  display: block;
}
training-course-page .training-grid,
training-module-page .training-grid,
training-voluntary-category .training-grid,
training-voluntary-page .training-grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 15px 5px;
}
@media only screen and (min-width: 1025px) {
  training-course-page .training-grid,
  training-module-page .training-grid,
  training-voluntary-category .training-grid,
  training-voluntary-page .training-grid {
    padding: 15px 0;
    margin: 0 -5px;
  }
}
training-course-page .training-item,
training-module-page .training-item,
training-voluntary-category .training-item,
training-voluntary-page .training-item {
  position: relative;
  width: 50%;
  cursor: pointer;
  box-sizing: border-box;
}
@media only screen and (min-width: 768px) {
  training-course-page .training-item,
  training-module-page .training-item,
  training-voluntary-category .training-item,
  training-voluntary-page .training-item {
    width: calc(100% / 3);
  }
}
training-course-page .training-item.locked .info h3,
training-module-page .training-item.locked .info h3,
training-voluntary-category .training-item.locked .info h3,
training-voluntary-page .training-item.locked .info h3,
training-course-page .training-item.expired .info h3,
training-module-page .training-item.expired .info h3,
training-voluntary-category .training-item.expired .info h3,
training-voluntary-page .training-item.expired .info h3 {
  opacity: 0.4;
}
training-course-page .training-item.locked .image-inner,
training-module-page .training-item.locked .image-inner,
training-voluntary-category .training-item.locked .image-inner,
training-voluntary-page .training-item.locked .image-inner,
training-course-page .training-item.expired .image-inner,
training-module-page .training-item.expired .image-inner,
training-voluntary-category .training-item.expired .image-inner,
training-voluntary-page .training-item.expired .image-inner {
  opacity: 0.4;
}
training-course-page .training-item.locked rl-progress .bar .inner,
training-module-page .training-item.locked rl-progress .bar .inner,
training-voluntary-category .training-item.locked rl-progress .bar .inner,
training-voluntary-page .training-item.locked rl-progress .bar .inner,
training-course-page .training-item.expired rl-progress .bar .inner,
training-module-page .training-item.expired rl-progress .bar .inner,
training-voluntary-category .training-item.expired rl-progress .bar .inner,
training-voluntary-page .training-item.expired rl-progress .bar .inner {
  background: rgba(var(--general-text-color-rgb), 0.4);
}
training-course-page .training-item.deadline .info,
training-module-page .training-item.deadline .info,
training-voluntary-category .training-item.deadline .info,
training-voluntary-page .training-item.deadline .info {
  background-image: linear-gradient(to bottom, rgba(var(--general-text-color-rgb), 0) 39%, rgba(var(--warning-color-rgb), 0.1));
}
training-course-page .training-item.deadline rl-progress .bar .inner,
training-module-page .training-item.deadline rl-progress .bar .inner,
training-voluntary-category .training-item.deadline rl-progress .bar .inner,
training-voluntary-page .training-item.deadline rl-progress .bar .inner {
  background: var(--warning-color);
}
training-course-page .training-item.new .info,
training-module-page .training-item.new .info,
training-voluntary-category .training-item.new .info,
training-voluntary-page .training-item.new .info {
  background-image: linear-gradient(to bottom, rgba(var(--general-text-color-rgb), 0) 39%, rgba(var(--highlight-color-rgb), 0.1));
}
training-course-page .training-item.new .info h3,
training-module-page .training-item.new .info h3,
training-voluntary-category .training-item.new .info h3,
training-voluntary-page .training-item.new .info h3 {
  color: var(--highlight-color);
}
training-course-page .training-item.new .status,
training-module-page .training-item.new .status,
training-voluntary-category .training-item.new .status,
training-voluntary-page .training-item.new .status {
  text-transform: uppercase;
}
training-course-page .training-item.passed .info,
training-module-page .training-item.passed .info,
training-voluntary-category .training-item.passed .info,
training-voluntary-page .training-item.passed .info {
  background-image: linear-gradient(to bottom, rgba(var(--general-text-color-rgb), 0) 39%, rgba(var(--confirmation-color-rgb), 0.1));
}
training-course-page .training-item.passed rl-progress .bar .inner,
training-module-page .training-item.passed rl-progress .bar .inner,
training-voluntary-category .training-item.passed rl-progress .bar .inner,
training-voluntary-page .training-item.passed rl-progress .bar .inner {
  background: var(--confirmation-color);
}
training-course-page .training-item.not-passed .info,
training-module-page .training-item.not-passed .info,
training-voluntary-category .training-item.not-passed .info,
training-voluntary-page .training-item.not-passed .info {
  background-image: linear-gradient(to bottom, rgba(var(--general-text-color-rgb), 0) 39%, rgba(var(--alert-color-rgb), 0.1));
}
training-course-page .training-item.not-passed rl-progress .bar .inner,
training-module-page .training-item.not-passed rl-progress .bar .inner,
training-voluntary-category .training-item.not-passed rl-progress .bar .inner,
training-voluntary-page .training-item.not-passed rl-progress .bar .inner {
  background: var(--alert-color);
}
training-course-page .training-item .image-inner,
training-module-page .training-item .image-inner,
training-voluntary-category .training-item .image-inner,
training-voluntary-page .training-item .image-inner {
  overflow: hidden;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center center;
  text-align: center;
}
training-course-page .training-item .image-inner rl-icon,
training-module-page .training-item .image-inner rl-icon,
training-voluntary-category .training-item .image-inner rl-icon,
training-voluntary-page .training-item .image-inner rl-icon {
  display: block;
  color: var(--general-background-color);
  opacity: 0.3;
  font-size: 34px;
  line-height: 80px;
}
training-course-page .training-item .info,
training-module-page .training-item .info,
training-voluntary-category .training-item .info,
training-voluntary-page .training-item .info {
  background-color: var(--background-highlight-color);
}
training-course-page .training-item .info h3,
training-module-page .training-item .info h3,
training-voluntary-category .training-item .info h3,
training-voluntary-page .training-item .info h3 {
  overflow: hidden;
  width: 100%;
  margin: 0;
  padding: 0 10px;
  font-size: 15px;
  font-weight: 600;
  box-sizing: border-box;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
}
training-course-page .training-item .info .duration,
training-module-page .training-item .info .duration,
training-voluntary-category .training-item .info .duration,
training-voluntary-page .training-item .info .duration,
training-course-page .training-item .info .redo,
training-module-page .training-item .info .redo,
training-voluntary-category .training-item .info .redo,
training-voluntary-page .training-item .info .redo {
  padding: 0;
  margin: 5px 0 0 0;
  text-align: center;
  font-size: 13px;
}
training-course-page .training-item .info .duration rl-icon,
training-module-page .training-item .info .duration rl-icon,
training-voluntary-category .training-item .info .duration rl-icon,
training-voluntary-page .training-item .info .duration rl-icon,
training-course-page .training-item .info .redo rl-icon,
training-module-page .training-item .info .redo rl-icon,
training-voluntary-category .training-item .info .redo rl-icon,
training-voluntary-page .training-item .info .redo rl-icon {
  font-size: 15px;
}
training-course-page .training-item .info .status,
training-module-page .training-item .info .status,
training-voluntary-category .training-item .info .status,
training-voluntary-page .training-item .info .status {
  margin: 5px 0 0 0;
  text-align: center;
  font-size: 13px;
  font-weight: 600;
}
training-course-page .training-item .info .expired,
training-module-page .training-item .info .expired,
training-voluntary-category .training-item .info .expired,
training-voluntary-page .training-item .info .expired,
training-course-page .training-item .info .deadline,
training-module-page .training-item .info .deadline,
training-voluntary-category .training-item .info .deadline,
training-voluntary-page .training-item .info .deadline {
  margin: 5px 0 0 0;
  color: var(--general-text-inactive-color);
  text-align: center;
  font-size: 13px;
}
training {
  display: block;
}
training rl-translation-information {
  display: block;
  padding: var(--spacing);
}
@media only screen and (min-width: 1025px) {
  training rl-translation-information {
    padding: var(--spacing) 0;
  }
}
.training-item .image {
  background-color: var(--general-background-color);
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.25);
}
.training-item .image .image-inner {
  background-color: var(--general-text-color);
  color: rgba(var(--general-background-color-rgb), 0.4);
}
.training-item .title {
  color: var(--general-text-color);
}
.training-item .duration,
.training-item .redo {
  color: rgba(var(--general-text-color-rgb), 0.4);
}
.training-item .status {
  color: var(--general-text-color);
}
.training-item rl-progress .bar .inner {
  background-color: var(--general-text-color);
}
training-phase-page {
  display: block;
}
@media only screen and (min-width: 1025px) {
  training-phase-page course-item .course {
    margin: 10px 0;
  }
}
badge-popup .action-popup {
  width: calc(100% - var(--spacing) * 2);
  max-width: calc(var(--max-width) - var(--spacing) * 2);
}
@media only screen and (min-width: 1025px) {
  badge-popup .action-popup {
    margin-left: calc(var(--sidemenu-width) / 2);
  }
}
badge-popup .badge-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: calc(var(--m-spacing) * 2) var(--spacing);
  border-bottom: 1px solid var(--general-border-color);
}
badge-popup badge {
  margin-bottom: var(--m-spacing);
}
badge-popup badge .image-wrap {
  width: 100px;
}
badge-popup badge p {
  font-size: 15px;
  margin: var(--s-spacing) 0 0 0;
  font-weight: 400;
}
badges-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 15px -9px 0 -9px;
}
@media only screen and (min-width: 768px) {
  badges-list {
    margin: 30px -15px 0 -15px;
  }
}
badges-list badge {
  width: calc(100% / 4 - 18px);
  margin: 0 9px 15px 9px;
}
@media only screen and (min-width: 768px) {
  badges-list badge {
    width: calc(100% / 5 - 40px);
    margin: 0 20px 15px 20px;
  }
}
badges-list svg {
  position: absolute;
  width: 0;
  height: 0;
}
badge {
  position: relative;
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;
  box-sizing: border-box;
  word-break: break-word;
}
badge rl-icon.circle {
  position: absolute;
  top: -1px;
  right: 8px;
  width: 27px;
  height: 27px;
  font-size: 16px;
  background-color: var(--alert-color);
  color: var(--white-color);
}
badge rl-icon.circle.expired {
  background-color: var(--warning-color);
}
badge .image-wrap {
  width: 100%;
}
badge .image {
  position: relative;
  width: 100%;
  padding-top: 100%;
  clip-path: url(#clipPath);
  transform: translateZ(0);
}
badge .image .img,
badge .image .overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
badge .image .overlay {
  z-index: 1;
}
badge .image .img {
  background-position: center center;
}
badge .image.default {
  background: var(--highlight-color);
}
badge .image.default rl-icon {
  color: var(--text-on-highlight-color);
  font-size: 30px;
}
badge .image.not-achieved {
  background: var(--general-background-color);
}
badge .image.not-achieved:after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: var(--general-text-inactive-color);
}
badge .image.not-active:after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(var(--white-color-rgb), 0.6);
}
badge .image rl-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 35px;
  color: rgba(var(--general-text-color-rgb), 0.4);
}
badge svg {
  position: absolute;
  width: 0;
  height: 0;
}
badge p {
  margin: 3px 0 0 0;
  font-size: 10px;
  font-weight: 400;
  color: var(--general-text-color);
}
@media only screen and (min-width: 768px) {
  badge p {
    margin: 10px 0 0 0;
    font-size: 15px;
  }
}
badge p.not-achieved {
  opacity: 0.4;
}
training-introduction {
  display: block;
  padding: 0 10px;
}
@media only screen and (min-width: 1025px) {
  training-introduction {
    padding: 0;
  }
}
training-introduction .introduction {
  position: relative;
  display: block;
  border-radius: var(--training-border-radius);
  background: var(--background-highlight-color);
}
training-introduction .introduction .introduction-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 65px;
  padding: 0 10px;
}
training-introduction .introduction .introduction-title {
  overflow: hidden;
  padding-right: 10px;
}
training-introduction .introduction .introduction-title h2,
training-introduction .introduction .introduction-title h3 {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: 0;
}
training-introduction .introduction .introduction-toggle {
  display: flex;
  height: 25px;
  width: 25px;
  border-radius: 15px;
  justify-content: center;
  transition: all ease-in-out 0.2s;
  background: rgba(var(--general-text-color-rgb), 0.4);
  color: var(--general-background-color);
  backdrop-filter: blur(10px);
  box-shadow: 0 5px 7px 0 rgba(var(--general-text-color-rgb), 0.1);
}
training-introduction .introduction media {
  overflow: hidden;
  margin: 10px 10px 0 10px;
  border-radius: var(--general-border-radius);
  box-shadow: 0 5px 7px 0 rgba(var(--general-text-color-rgb), 0.1);
}
training-introduction .introduction .introduction-inner {
  padding: 20px 10px;
}
training-learn-more-component {
  display: flex;
  flex-direction: column;
}
training-learn-more-component .top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  height: 101px;
  margin: 0 35px 0 20px;
}
training-learn-more-component .top .men {
  position: relative;
  bottom: -10px;
}
training-learn-more-component .bottom {
  border-radius: var(--general-border-radius);
  padding: 25px 15px 15px;
  background-image: linear-gradient(to bottom, rgba(var(--general-text-color-rgb), 0) 39%, rgba(var(--highlight-color), 0.1));
  background-color: var(--background-highlight-color);
}
training-learn-more-component .bottom h2 {
  padding-bottom: 5px;
}
training-learn-more-component .bottom h2,
training-learn-more-component .bottom p {
  margin: 0;
}
training-learn-more-component .bottom rl-button {
  display: block;
  margin-top: 15px;
}
training-scores {
  display: flex;
  padding: 10px;
}
@media only screen and (min-width: 1025px) {
  training-scores {
    padding: 10px 0;
  }
}
training-scores > div {
  position: relative;
  width: calc(50% - 5px);
  box-sizing: border-box;
  padding: 20px 10px 10px 10px;
  border-radius: var(--training-border-radius);
  text-align: center;
  background: var(--background-highlight-color);
}
training-scores > div:first-of-type {
  margin-right: 10px;
}
training-scores > div h2 {
  margin: 0;
  font-size: 20px;
  line-height: 25px;
  letter-spacing: 0;
}
training-scores > div .rl-subheadline {
  margin: 5px 0 10px 0;
  font-size: 10px;
  line-height: 10px;
}
training-scores > div rl-progress .bar .inner {
  background: var(--general-text-color);
}
training-scores > div rl-progress.green .bar .inner {
  background: var(--confirmation-color);
}
training-scores > div rl-progress.yellow .bar .inner {
  background: var(--alert-color);
}
training-scores > div rl-progress.red .bar .inner {
  background: var(--warning-color);
}
training-scores tip .tip-content {
  z-index: 155;
  top: -30px;
  right: 5px;
  width: 260px;
  padding: 10px;
  text-align: left;
}
training-scores tip .tip-content:after {
  left: 65%;
  bottom: -4px;
}
@media only screen and (min-width: 768px) {
  training-scores tip .tip-content {
    right: 25%;
    margin-right: -130px;
  }
  training-scores tip .tip-content:after {
    left: 50%;
  }
}
state-icon {
  border-radius: 50%;
}
state-icon rl-icon {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
state-icon rl-icon.new {
  background: var(--highlight-color);
  color: var(--white-color);
  font-size: 17px;
}
state-icon rl-icon.new span {
  position: relative;
  top: -1px;
}
state-icon rl-icon.deadline {
  background: var(--warning-color);
  color: var(--white-color);
  font-size: 18px;
}
state-icon rl-icon.not-passed {
  background: var(--alert-color);
  color: var(--white-color);
  font-size: 18px;
}
state-icon rl-icon.passed {
  background: var(--confirmation-color);
  color: var(--white-color);
}
state-icon rl-icon.locked {
  background: rgba(var(--general-text-color-rgb), 0.4);
  color: var(--general-background-color);
  backdrop-filter: blur(10px);
  font-size: 14px;
}
state-icon rl-icon.expired {
  color: var(--white-color);
  background-color: var(--general-text-color);
  font-size: 16px;
}
badge-testimonials-popup .action-popup {
  min-width: 300px;
  width: 80vw;
  max-width: 600px;
}
badge-testimonials-popup .action-popup .action-popup-inner {
  display: block;
  padding: 15px;
}
badge-testimonials-popup .content h2,
badge-testimonials-popup .content p {
  margin: 0;
}
badge-testimonials-popup .content h2 {
  margin-bottom: 5px;
}
badge-testimonials-popup badges-list {
  flex-direction: column;
  align-items: center;
}
badge-testimonials-popup .finish-lesson {
  position: relative;
  box-sizing: border-box;
  padding: 25px 15px 30px 15px;
  text-align: center;
  background: var(--general-background-color);
  width: 100%;
}
badge-testimonials-popup .finish-lesson:after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -10px;
  width: 100%;
  border-radius: 50%;
  background: var(--general-background-color);
  height: 20px;
}
badge-testimonials-popup .finish-lesson .finish-icon {
  font-size: 80px;
}
badge-testimonials-popup .stars {
  margin: 30px 0;
  text-align: center;
}
badge-testimonials-popup .stars.submitted {
  width: 100%;
}
badge-testimonials-popup .stars p {
  margin: 6px 0 23px 0;
}
badge-testimonials-popup textarea {
  width: 100%;
  padding: 10px;
  min-height: 120px;
  margin-bottom: 10px;
  font-size: 15px;
  box-sizing: border-box;
  border-radius: 7px;
  background: var(--general-background-color);
  border: 1px solid var(--general-border-color);
}
timer {
  display: flex;
  justify-content: center;
  padding: var(--spacing) 0 var(--s-spacing);
}
timer .timer-container {
  display: flex;
  align-items: center;
  gap: calc(var(--m-spacing) / 2);
  padding: var(--m-spacing);
  border: 1px solid var(--general-border-color);
  border-radius: var(--general-border-radius);
  color: var(--warning-color);
  background-color: var(--general-background-color);
}
timer .timer-container .time-icon {
  padding: 0 3px;
  font-size: 14px;
}
timer .timer-container .time-value {
  min-width: 60px;
  font-size: 13px;
  font-weight: 700;
  line-height: 130%;
}
timer .high-alert {
  color: var(--text-on-highlight-color);
  background-color: var(--warning-color);
}
.validity-badge-background {
  position: relative;
  width: auto;
  padding: var(--s-spacing);
}
.validity-badge-background::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.2;
  z-index: -1;
  border-radius: var(--general-border-radius);
}
.validity-badge-background.bg-not-obtained::before {
  background-color: rgba(var(--general-text-color-rgb), 0.8);
}
.validity-badge-background.bg-obtained::before {
  background: rgba(var(--confirmation-color-rgb), 0.8);
}
.validity-badge-background.bg-expired::before {
  background: rgba(var(--warning-color-rgb), 0.8);
}
.validity-badge-background.bg-valid-until::before {
  background: rgba(var(--confirmation-color-rgb), 0.8);
}
.validity-badge-background.bg-deactivated::before {
  background: rgba(var(--alert-color-rgb), 0.8);
}
.validity-badge-background .validity-icon-and-text {
  display: flex;
  gap: calc(var(--m-spacing) * 0.5);
  justify-content: center;
  align-items: center;
}
.validity-badge-background .validity-icon-and-text .validity-badge-icon-bg-obtained {
  color: var(--confirmation-color);
}
.validity-badge-background .validity-icon-and-text .validity-badge-icon-bg-expired {
  color: var(--warning-color);
}
.validity-badge-background .validity-icon-and-text .validity-badge-icon-bg-valid-until {
  color: var(--confirmation-color);
}
.validity-badge-background .validity-icon-and-text .validity-badge-icon-bg-not-obtained {
  color: var(--general-text-color);
}
.validity-badge-background .validity-icon-and-text .validity-badge-icon-bg-deactivated {
  color: var(--alert-color);
}
.validity-badge-text {
  opacity: 1;
}
.validity-badge-text p {
  color: var(--elevated-text-color);
  margin: 0;
  padding: 0;
  font-size: 0.8125rem;
  font-style: normal;
  font-weight: 700;
  line-height: 135%;
}
badge-validity-popup .action-popup {
  width: calc(100% - var(--spacing) * 2);
  max-width: calc(var(--max-width) - var(--spacing) * 2);
}
@media only screen and (min-width: 1025px) {
  badge-validity-popup .action-popup {
    margin-left: calc(var(--sidemenu-width) / 2);
  }
}
badge-validity-popup .action-popup badge {
  margin-bottom: var(--m-spacing);
}
badge-validity-popup .action-popup badge .image-wrap {
  width: 100px;
}
badge-validity-popup .badge-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: calc(var(--m-spacing) * 2) var(--spacing);
  border-bottom: 1px solid var(--general-border-color);
}
badge-validity-popup .badge-wrapper p {
  font-size: 1.375rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  width: max-content;
  max-width: 280px;
  color: var(--elevated-text-color);
}
badge-validity-popup .badge-wrapper .go-to-training-btn-container {
  width: 250px;
  margin-top: var(--spacing);
}
badge-validity-popup .badge-wrapper .validity-badge-text p {
  font-size: 15px;
}
my-progress .point-container {
  display: block;
  text-align: center;
  border-bottom: 1px solid rgba(var(--general-text-color-rgb), .1);
}
my-progress .point-container h2 {
  display: block;
  margin: 0;
  padding-top: 15px;
  font-size: 36px;
  font-weight: bold;
}
my-progress .point-container h3 {
  display: block;
  margin: 5px 0 30px 0;
  font-size: 15px;
  color: rgba(var(--general-text-color-rgb), .5);
  text-transform: uppercase;
}
my-progress training-badges {
  display: block;
  padding: 15px;
}
training-badges header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 15px;
}
training-badges header div {
  display: flex;
  align-items: center;
}
training-badges header .rl-headline {
  font-size: 15px;
  margin: 0 10px 0 0;
}
training-badges header rl-icon {
  color: var(--general-text-color);
  opacity: 0.35;
}
@media only screen and (min-width: 1025px) {
  training-badges badges-list {
    max-width: 768px;
    margin: 0 auto;
  }
}
lesson-page {
  display: block;
  box-sizing: border-box;
  padding: 0 15px 50px;
}
@media only screen and (min-width: 1025px) {
  lesson-page {
    padding: 0 15px;
  }
}
lesson-page rl-loading-placeholder .placeholder-shimmer:not(:last-of-type) {
  margin-bottom: 15px;
}
lesson-page .lesson {
  box-sizing: border-box;
  padding-top: 20px;
  min-height: calc(100dvh - 55px - 105px - 50px);
}
@media only screen and (min-width: 1025px) {
  lesson-page .lesson {
    min-height: calc(100dvh - 60px - 105px);
  }
}
lesson-page .finish-screen {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex: 1;
}
lesson-page .finish-screen h2,
lesson-page .finish-screen p {
  margin: 0;
}
lesson-page .finish-screen h2 {
  margin-bottom: 5px;
}
lesson-page .finish-screen .finish-lesson {
  position: relative;
  padding: 25px 15px 30px 15px;
  margin: -20px -15px 0;
  text-align: center;
  background: var(--general-background-color);
}
lesson-page .finish-screen .finish-lesson:not(.no-testimonials) {
  width: 100%;
}
lesson-page .finish-screen .finish-lesson.no-testimonials {
  margin: auto;
  background: transparent;
}
lesson-page .finish-screen .finish-lesson.no-testimonials:after {
  display: none;
}
lesson-page .finish-screen .finish-lesson:after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -10px;
  width: 100%;
  border-radius: 50%;
  background: var(--general-background-color);
  height: 20px;
}
lesson-page .finish-screen .finish-lesson .finish-icon {
  font-size: 80px;
}
lesson-page .finish-screen .stars {
  margin: 30px 0;
  text-align: center;
}
lesson-page .finish-screen .stars.submitted {
  margin: auto;
  width: 100%;
}
lesson-page .finish-screen .stars p {
  margin: 6px 0 23px 0;
}
lesson-page .new-badge-screen {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
lesson-page .new-badge-screen .header {
  padding: 20px 0;
  text-align: center;
}
lesson-page .new-badge-screen h2 {
  margin: 0 0 7px 0;
}
lesson-page .new-badge-screen p {
  margin: 0;
}
lesson-page .new-badge-screen badges-list {
  width: 100%;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
}
lesson-page .new-badge-screen badges-list badge {
  width: 50%;
  margin: 0 0 20px 0;
}
lesson-page .new-badge-screen badges-list badge p {
  font-size: 20px;
  margin-top: 10px;
}
@media only screen and (min-width: 768px) {
  lesson-page .new-badge-screen badges-list badge {
    width: 30%;
  }
}
lesson-page .continue {
  position: sticky;
  bottom: 50px;
  margin: 0 -15px 0;
  padding: 20px 15px;
  padding-bottom: calc(20px + env(safe-area-inset-bottom, 0px));
  background: linear-gradient(to top, var(--background-highlight-color) 50%, rgba(var(--background-highlight-color-rgb), 0));
}
@media only screen and (min-width: 1025px) {
  lesson-page .continue {
    bottom: 0;
  }
}
lesson-page .continue:not(.answered) {
  padding-top: 40px;
}
lesson-page .continue rl-button {
  border-radius: var(--general-border-radius);
  display: block;
  background: var(--general-background-color);
}
lesson-page .continue rl-button.expired {
  background: transparent;
}
lesson-page .continue.answered {
  background: var(--background-highlight-color);
  margin-top: 15px;
}
lesson-page .continue.answered:not(.no-score) {
  box-shadow: 0 2px 7px 0 rgba(60, 72, 88, 0.1);
}
lesson-page .continue.answered.passed:before,
lesson-page .continue.answered.not-passed:before {
  content: '';
  position: absolute;
  z-index: -1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
lesson-page .continue.answered.passed:before {
  background: rgba(var(--confirmation-color-rgb), 0.2);
}
lesson-page .continue.answered.passed .complete-feedback {
  color: var(--confirmation-color);
}
lesson-page .continue.answered.passed rl-button button {
  background: var(--confirmation-color);
}
lesson-page .continue.answered.not-passed:before {
  background: rgba(var(--warning-color-rgb), 0.2);
}
lesson-page .continue.answered.not-passed .complete-feedback {
  color: var(--warning-color);
}
lesson-page .continue.answered.not-passed rl-button button {
  background: var(--warning-color);
}
lesson-page .continue .complete-feedback {
  margin: 15px 0;
  font-weight: 600;
}
lesson-content-component {
  display: block;
}
lesson-content-component .disclaimer {
  display: flex;
  align-items: center;
  margin: -20px -15px 30px;
  padding: 20px 15px;
  background: rgba(var(--alert-color-rgb), 0.2);
  color: var(--general-text-color);
}
lesson-content-component .disclaimer.lesson-done-success {
  background: rgba(var(--confirmation-color-rgb), 0.2);
}
lesson-content-component .disclaimer.expired {
  background: rgba(var(--general-text-color-rgb), 0.2);
}
lesson-content-component .disclaimer p {
  margin: 0;
  padding: 0;
  text-align: left;
  font-size: 13px;
}
lesson-content-component .disclaimer rl-icon {
  flex-shrink: 0;
  width: 25px;
  height: 25px;
  margin-right: 15px;
  font-size: 15px;
  border-radius: 50%;
  color: var(--white-color);
}
lesson-content-component .disclaimer rl-icon[icon=save] {
  background: var(--confirmation-color);
}
lesson-content-component .disclaimer rl-icon[icon=exclamation] {
  background: var(--alert-color);
}
lesson-content-component .disclaimer rl-icon[icon=lock] {
  background: rgba(var(--general-text-color-rgb), 0.4);
}
lesson-content-component .disclaimer rl-icon[icon=hourglass-end] {
  font-size: 13px;
  background: var(--general-text-color);
}
lesson-content-component .lesson-media {
  width: 100%;
  margin-bottom: 15px;
}
lesson-content-component .introduction {
  margin-bottom: 25px;
}
lesson-content-component .introduction *:first-child {
  margin-top: 0;
}
lesson-content-component .introduction *:last-child {
  margin-bottom: 0;
}
lesson-content-component .introduction img,
lesson-content-component .introduction video,
lesson-content-component .introduction iframe {
  width: 100%;
}
lesson-content-component .question:not(:last-of-type) {
  margin-bottom: 25px;
}
lesson-content-component .question .title h2 {
  margin: 0;
  font-size: 20px;
  font-weight: 600;
  color: var(--general-text-color);
}
lesson-content-component .question .title p {
  margin: 0;
  font-size: 13px;
  color: var(--general-text-color);
}
lesson-content-component .question .title media {
  margin-bottom: 10px;
}
lesson-content-component .question .question-feedback {
  margin-top: 10px;
}
lesson-content-component .question .question-feedback media {
  overflow: hidden;
  margin-bottom: 10px;
  border-radius: var(--general-border-radius);
}
lesson-content-component .question .question-feedback p {
  margin: 0;
  font-size: 13px;
  color: var(--general-text-color);
}
lesson-content-component .answers {
  margin-top: 20px;
}
lesson-content-component .answers > span {
  display: block;
  font-size: 13px;
  font-weight: 500;
  padding-bottom: 5px;
}
lesson-content-component .answer {
  padding: 15px;
  border: 1px solid rgba(var(--general-text-color-rgb), 0.1);
  background-color: var(--general-background-color);
}
lesson-content-component .answer.selected {
  border-left: 5px solid var(--highlight-color);
  border-color: var(--highlight-color);
  box-shadow: 0 2px 7px 0 rgba(var(--general-text-color-rgb), 0.1);
  padding-left: 10px;
}
lesson-content-component .answer.selected rl-icon {
  width: 19px;
  height: 19px;
  margin-right: 5px;
  font-size: 11px;
  border-radius: 50%;
  color: var(--white-color);
}
lesson-content-component .answer.selected.wrong {
  background-image: linear-gradient(to bottom, rgba(var(--warning-color-rgb), 0.1) 39%, rgba(var(--general-text-color-rgb), 0));
  border-left: 5px solid var(--warning-color);
  border-color: var(--warning-color);
}
lesson-content-component .answer.selected.wrong rl-icon {
  background: var(--warning-color);
}
lesson-content-component .answer.selected.correct {
  background-image: linear-gradient(to bottom, rgba(var(--confirmation-color-rgb), 0.1) 39%, rgba(var(--general-text-color-rgb), 0));
  border-left: 5px solid var(--confirmation-color);
  border-color: var(--confirmation-color);
}
lesson-content-component .answer.selected.correct rl-icon {
  background: var(--confirmation-color);
}
lesson-content-component .answer.selected > h3 {
  font-weight: 600;
}
lesson-content-component .answer:not(:last-of-type) {
  margin-bottom: 15px;
}
lesson-content-component .answer > h3 {
  margin: 0;
  font-size: 15px;
  color: var(--general-text-color);
}
lesson-content-component .answer.disabled {
  background-color: var(--background-highlight-color);
}
lesson-content-component .answer .answer-feedback {
  padding-top: 15px;
}
lesson-content-component .answer .answer-feedback media {
  overflow: hidden;
  margin-bottom: 10px;
  border-radius: var(--general-border-radius);
}
lesson-content-component .answer .answer-feedback p {
  margin: 0;
  font-size: 13px;
  color: var(--general-text-color);
}
@media only screen and (min-width: 1025px) {
  .base-view.base-view-training.base-view-training-lesson.lesson-page-v2 {
    height: 100%;
    padding: calc(var(--m-spacing) * 2) 0 calc(var(--m-spacing) * 4) 0;
    box-sizing: border-box;
    --training-background: var(--background-highlight-color);
  }
}
.base-view.base-view-training.base-view-training-lesson.lesson-page-v2.show-answers lesson-page-v2 {
  background: none;
}
lesson-page-v2 {
  display: block;
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  lesson-page-v2 {
    margin: 0 auto;
    max-width: var(--max-width);
  }
}
@media only screen and (min-width: 1025px) {
  lesson-page-v2 {
    height: 100%;
    overflow-y: auto;
    background: var(--training-inner-background);
  }
  lesson-page-v2.view-page {
    max-width: calc(var(--max-width) + var(--spacing) * 6);
  }
}
lesson-page-v2 rl-loading-placeholder {
  display: block;
  padding: calc(var(--m-spacing) * 2.5) var(--spacing) var(--spacing) var(--spacing);
}
lesson-page-v2 .lesson-inner-page {
  display: flex;
  flex-direction: column;
  transition: opacity 0.3s;
  opacity: 1;
}
lesson-page-v2 .lesson-inner-page.hidden {
  overflow: hidden;
  opacity: 0;
  height: 0;
}
@media only screen and (min-width: 1025px) {
  lesson-page-v2 .lesson-inner-page {
    height: 100%;
  }
}
@media only screen and (max-width: 1024px) {
  lesson-page-v2 .lesson-inner-page {
    min-height: calc(100dvh - 50px - 55px - env(safe-area-inset-bottom));
  }
}
lesson-page-v2 .content-wrapper {
  box-sizing: border-box;
  padding: calc(var(--m-spacing) * 2.5) var(--spacing) var(--spacing) var(--spacing);
}
lesson-page-v2 .button-wrapper {
  z-index: 5;
  position: sticky;
  bottom: calc(env(safe-area-inset-bottom) + 50px);
  margin-top: auto;
  padding: calc(var(--m-spacing) * 6) var(--spacing) var(--m-spacing) var(--spacing);
  box-sizing: border-box;
  background: linear-gradient(0deg, var(--general-background-color) 0%, rgba(255, 255, 255, 0) 106.14%);
}
@media only screen and (min-width: 1025px) {
  lesson-page-v2 .button-wrapper {
    bottom: 0;
    padding: calc(var(--m-spacing) * 6) var(--spacing) calc(var(--m-spacing) * 2) var(--spacing);
  }
}
lesson-page-v2 .button-wrapper rl-button {
  display: block;
  background: var(--general-background-color);
  border-radius: var(--general-border-radius);
}
@media only screen and (min-width: 1025px) {
  .base-view.base-view-training.module-training-lesson-questions:not(.show-answers) {
    --training-inner-background: none;
    padding-bottom: 0;
  }
  .base-view.base-view-training.module-training-lesson-questions.show-answers {
    padding-bottom: calc(var(--spacing) + (var(--m-spacing) * 5) + 1.3rem);
  }
}
@media only screen and (max-width: 1024px) {
  .base-view.base-view-training.module-training-lesson-questions {
    --training-background: var(--background-highlight-color);
  }
}
lesson-questions rl-progress {
  position: fixed;
  z-index: 11;
  top: 55px;
  left: 0;
  right: 0;
}
lesson-questions rl-progress .bar {
  border-radius: 0;
  height: 4px !important;
}
lesson-questions rl-progress .bar .inner {
  border-radius: 0;
  height: 4px !important;
}
@media only screen and (min-width: 1025px) {
  lesson-questions rl-progress {
    left: var(--sidemenu-width);
    top: 60px;
  }
}
lesson-questions .content-wrapper {
  flex: 1;
  position: relative;
  padding-top: var(--spacing);
  container-type: inline-size;
}
@media only screen and (max-width: 1024px) {
  lesson-questions .content-wrapper {
    display: flex;
    flex-direction: column;
  }
}
@media only screen and (min-width: 1025px) {
  lesson-questions .content-wrapper {
    padding: calc(var(--m-spacing) * 2.5) calc(var(--spacing) * 3) var(--spacing) calc(var(--spacing) * 3);
  }
}
lesson-questions .content-wrapper.timed-out {
  opacity: 0.6;
}
@media only screen and (max-width: 1024px) {
  lesson-questions .next .question-wrapper.current-remove.in-stack {
    animation: slideOutLeft 0.3s;
  }
  lesson-questions .next .question-wrapper.current-remove:not(.in-stack) {
    animation: fadeOutLeft 0.3s;
  }
  lesson-questions .next .question-wrapper.current-add {
    animation: fadeInRight 0.3s;
  }
  lesson-questions .prev .question-wrapper.current-remove {
    animation: fadeOutRight 0.3s;
  }
  lesson-questions .prev .question-wrapper.current-add:not(.in-stack) {
    animation: fadeInLeft 0.3s;
  }
  lesson-questions .prev .question-wrapper.current-add.in-stack {
    animation: slideInLeft 0.3s;
  }
}
@media only screen and (min-width: 1025px) {
  lesson-questions .question-wrapper:not(.in-stack).current-remove {
    animation: fadeOut 0.3s;
  }
  lesson-questions .question-wrapper:not(.in-stack).current-add {
    animation: fadeIn 0.3s;
  }
  lesson-questions .next .question-wrapper.in-stack.current-remove {
    animation: slideOutDown 0.3s;
  }
  lesson-questions .prev .question-wrapper.in-stack.current-add {
    animation: slideInDown 0.3s;
  }
}
lesson-questions .question-wrapper {
  display: none;
}
lesson-questions .question-wrapper.current-add {
  z-index: 100;
}
lesson-questions .question-wrapper.ng-animate {
  position: absolute;
  display: block;
}
lesson-questions .question-wrapper.ng-animate.swipe-card {
  display: flex;
}
lesson-questions .question-wrapper.ng-animate:not(.stack-below) {
  width: calc(100% - var(--spacing) * 2);
  height: calc(100% - var(--spacing) * 2);
}
@media only screen and (min-width: 1025px) {
  lesson-questions .question-wrapper.ng-animate:not(.stack-below) {
    top: 0;
    height: 100%;
    width: calc(100% - var(--spacing) * 6);
    padding: calc(var(--m-spacing) * 2.5) 0 var(--spacing) 0;
    box-sizing: border-box;
  }
}
lesson-questions .question-wrapper.current {
  display: block;
}
lesson-questions .question-wrapper.current.swipe-card {
  display: flex;
}
lesson-questions .question-wrapper.swipe-card {
  flex-direction: column;
  justify-content: center;
}
@media only screen and (max-width: 1024px) {
  lesson-questions .question-wrapper.swipe-card {
    margin: auto 0;
  }
}
@media only screen and (min-width: 1025px) {
  lesson-questions .question-wrapper.swipe-card {
    height: 100%;
  }
}
lesson-questions .question-wrapper.stack-below {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  box-sizing: border-box;
}
lesson-questions .question-wrapper.stack-below lesson-swipe-card .card.with-image h3 {
  flex: 1;
  white-space: nowrap;
  opacity: 0;
}
lesson-questions .question-wrapper.stack-below lesson-swipe-card .answer {
  display: none;
}
@media only screen and (min-width: 1025px) {
  lesson-questions .question-wrapper.stack-below lesson-swipe-card footer.desktop {
    visibility: hidden;
  }
}
lesson-questions .question-wrapper.stack-below.stack-index-2 {
  z-index: -1;
  transform: translateY(var(--spacing));
  padding: 0 calc(2 * var(--spacing));
}
@media only screen and (min-width: 1025px) {
  lesson-questions .question-wrapper.stack-below.stack-index-2 {
    padding: calc(var(--m-spacing) * 2.5) calc(4 * var(--spacing)) var(--spacing) calc(4 * var(--spacing));
  }
}
lesson-questions .question-wrapper.stack-below.stack-index-3 {
  z-index: -2;
  transform: translateY(calc(var(--spacing) * 2));
  padding: 0 calc(3 * var(--spacing));
}
@media only screen and (min-width: 1025px) {
  lesson-questions .question-wrapper.stack-below.stack-index-3 {
    padding: calc(var(--m-spacing) * 2.5) calc(5 * var(--spacing)) var(--spacing) calc(5 * var(--spacing));
  }
}
lesson-questions .button-wrapper {
  padding: 0 var(--spacing) calc(var(--m-spacing) * 4);
  background: none;
}
@media only screen and (min-width: 1025px) {
  lesson-questions .button-wrapper {
    padding: 0 calc(var(--spacing) * 3) calc(var(--m-spacing) * 4);
  }
  lesson-questions .button-wrapper prev-next-buttons {
    filter: drop-shadow(0 5px 5px var(--shadow-color));
  }
}
lesson-questions .button-wrapper.answered {
  padding: var(--spacing) var(--spacing) calc(var(--m-spacing) * 5) var(--spacing);
  background: var(--general-background-color);
}
@media only screen and (min-width: 1025px) {
  lesson-questions .button-wrapper.answered {
    position: fixed;
    left: var(--sidemenu-width);
    bottom: 0;
    width: 100%;
  }
}
lesson-questions .button-wrapper.answered:after {
  content: '';
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
lesson-questions .button-wrapper.correct {
  color: var(--confirmation-color);
}
lesson-questions .button-wrapper.correct:after {
  background: rgba(var(--confirmation-color-rgb), 0.2);
}
lesson-questions .button-wrapper.wrong {
  color: var(--warning-color);
}
lesson-questions .button-wrapper.wrong:after {
  background: rgba(var(--warning-color-rgb), 0.2);
}
lesson-questions .button-wrapper h3 {
  margin: 0;
  font-size: 15px;
  font-weight: 700;
  line-height: 130%;
}
lesson-questions .button-wrapper .container {
  display: flex;
  justify-content: flex-end;
}
lesson-question .title h2 {
  margin: 0 0 var(--spacing) 0;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  color: var(--general-text-color);
}
lesson-question .title p {
  margin: 0;
  font-size: 13px;
  color: var(--general-text-color);
}
lesson-question .title media {
  overflow: hidden;
  margin-bottom: var(--spacing);
  border-radius: var(--general-border-radius);
}
lesson-question .question-feedback {
  margin-top: var(--spacing);
}
lesson-question .question-feedback media {
  overflow: hidden;
  margin-top: var(--spacing);
  border-radius: var(--general-border-radius);
}
lesson-question .question-feedback p {
  margin: 0;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  color: var(--general-text-color);
}
lesson-question .answers {
  margin-top: 20px;
}
lesson-question .answers > span {
  display: block;
  font-size: 13px;
  font-weight: 500;
  padding-bottom: 5px;
}
lesson-question .answer {
  padding: var(--m-spacing);
  border-radius: var(--general-border-radius);
  background-color: var(--general-background-color);
  border: 1px solid rgba(var(--general-text-color-rgb), 0.1);
}
lesson-question .answer .answer-wrapper {
  display: flex;
  align-items: flex-start;
  gap: var(--s-spacing);
}
lesson-question .answer rl-icon {
  color: var(--general-text-inactive-color);
}
lesson-question .answer rl-icon[icon=circle],
lesson-question .answer rl-icon[icon=dot-circle] {
  font-size: 17px;
}
lesson-question .answer h3 {
  margin: 0;
  font-size: 15px;
  font-weight: 400;
  line-height: 17px;
  word-break: break-word;
  color: var(--general-text-color);
}
lesson-question .answer.answered {
  cursor: default;
}
lesson-question .answer.selected {
  border-color: var(--highlight-color);
}
lesson-question .answer.selected rl-icon {
  color: var(--highlight-color);
}
lesson-question .answer.selected.wrong {
  border-color: var(--warning-color);
}
lesson-question .answer.selected.wrong rl-icon {
  color: var(--warning-color);
}
lesson-question .answer.selected.correct {
  border-color: var(--confirmation-color);
}
lesson-question .answer.selected.correct rl-icon {
  color: var(--confirmation-color);
}
lesson-question .answer.disabled {
  background-color: var(--background-highlight-color);
}
lesson-question .answer:not(:last-of-type) {
  margin-bottom: 15px;
}
lesson-question .answer .answer-feedback {
  padding-top: var(--s-spacing);
}
lesson-question .answer .answer-feedback p {
  margin: 0;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  color: var(--general-text-color);
}
lesson-question .answer .answer-feedback media {
  overflow: hidden;
  margin-top: var(--s-spacing);
  border-radius: var(--general-border-radius);
}
.question-wrapper:not(.stack-below) lesson-swipe-card .card-wrapper.answered .card {
  margin-top: calc(var(--m-spacing) * 2 + var(--spacing));
  transition: transform 0.3s;
}
@media only screen and (min-width: 1025px) {
  .question-wrapper:not(.stack-below) lesson-swipe-card .card-wrapper.answered .card {
    margin-top: calc(var(--m-spacing) * 2);
  }
}
.question-wrapper:not(.stack-below) lesson-swipe-card .card-wrapper.answered .card.show-true {
  transform: rotate(9deg);
  transform-origin: bottom right;
}
@media only screen and (min-width: 1025px) {
  .question-wrapper:not(.stack-below) lesson-swipe-card .card-wrapper.answered .card.show-true {
    transform: rotate(4deg);
  }
}
.question-wrapper:not(.stack-below) lesson-swipe-card .card-wrapper.answered .card.show-false {
  transform: rotate(-9deg);
  transform-origin: bottom left;
}
@media only screen and (min-width: 1025px) {
  .question-wrapper:not(.stack-below) lesson-swipe-card .card-wrapper.answered .card.show-false {
    transform: rotate(-4deg);
  }
}
.question-wrapper:not(.stack-below) lesson-swipe-card .card-wrapper.answered .card .answer-highlight {
  opacity: 1;
}
.question-wrapper:not(.stack-below) lesson-swipe-card .card-wrapper.answered .card footer.desktop {
  display: none;
}
@media only screen and (max-width: 1024px) {
  .question-wrapper:not(.stack-below) lesson-swipe-card .card-wrapper.answered.more-cards-1 .card {
    margin-top: calc(var(--m-spacing) * 2 + var(--spacing) * 2);
    margin-bottom: var(--spacing);
  }
}
@media only screen and (min-width: 1025px) {
  .question-wrapper:not(.stack-below) lesson-swipe-card .card-wrapper.answered.more-cards-1 .card {
    margin-top: calc(var(--m-spacing) * 2 + var(--spacing));
  }
}
@media only screen and (min-width: 1025px) {
  .question-wrapper:not(.stack-below) lesson-swipe-card .card-wrapper.answered.more-cards-1 footer.desktop {
    padding: calc(var(--spacing) * 4) 0 calc(var(--spacing) * 2) 0;
  }
}
@media only screen and (max-width: 1024px) {
  .question-wrapper:not(.stack-below) lesson-swipe-card .card-wrapper.answered.more-cards-2 .card {
    margin-top: calc(var(--m-spacing) * 2 + var(--spacing) * 3);
    margin-bottom: calc(var(--spacing) * 2);
  }
}
@media only screen and (min-width: 1025px) {
  .question-wrapper:not(.stack-below) lesson-swipe-card .card-wrapper.answered.more-cards-2 .card {
    margin-top: calc(var(--m-spacing) * 2 + var(--spacing) * 2);
  }
}
@media only screen and (min-width: 1025px) {
  .question-wrapper:not(.stack-below) lesson-swipe-card .card-wrapper.answered.more-cards-2 footer.desktop {
    padding: calc(var(--spacing) * 4) 0 calc(var(--spacing) * 2) 0;
  }
}
lesson-swipe-card .card-wrapper {
  position: relative;
}
@media only screen and (min-width: 1025px) {
  lesson-swipe-card .card-wrapper.more-cards-1:not(.answered) footer.desktop {
    padding: calc(var(--spacing) * 4) 0 0 0;
  }
}
@media only screen and (min-width: 1025px) {
  lesson-swipe-card .card-wrapper.more-cards-2:not(.answered) footer.desktop {
    padding: calc(var(--spacing) * 4) 0 0 0;
  }
}
lesson-swipe-card .card-wrapper:not(.answered) .card {
  touch-action: none;
}
lesson-swipe-card .card {
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 100vw;
  background: var(--general-background-color);
  border-radius: var(--general-border-radius);
  box-shadow: 0 10px 10px -5px var(--shadow-color);
}
@media only screen and (min-width: 1025px) {
  lesson-swipe-card .card {
    min-height: 88cqw;
  }
}
lesson-swipe-card .card:not(.with-image) .img-placeholder {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.7;
  font-size: 200px;
  color: var(--background-highlight-color);
}
lesson-swipe-card .card:not(.with-image) .answer-highlight {
  position: absolute;
  width: calc(100% - var(--spacing) * 2);
  height: 65px;
  text-align: center;
}
lesson-swipe-card .card:not(.with-image) .content {
  position: relative;
  flex: 1;
}
lesson-swipe-card .card:not(.with-image) .content h3 {
  margin: auto 0;
  text-align: center;
  opacity: 1;
  transition: opacity 0.3s;
}
lesson-swipe-card .card.with-image .image-wrapper {
  position: relative;
  height: 48vw;
}
@media only screen and (min-width: 1025px) {
  lesson-swipe-card .card.with-image .image-wrapper {
    height: 53cqw;
  }
}
lesson-swipe-card .card.with-image .image-wrapper .answer-highlight {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
lesson-swipe-card .card.with-image .image-wrapper img {
  border-top-left-radius: var(--general-border-radius);
  border-top-right-radius: var(--general-border-radius);
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
  user-select: none;
}
lesson-swipe-card .card.with-image .content {
  flex: 1;
}
lesson-swipe-card .card.correct {
  border: 1px solid var(--confirmation-color);
}
lesson-swipe-card .card.wrong {
  border: 1px solid var(--warning-color);
}
lesson-swipe-card .card .answer-highlight.show-true rl-icon[icon=save] {
  display: inline-flex;
}
lesson-swipe-card .card .answer-highlight.show-false rl-icon[icon=close] {
  display: inline-flex;
}
lesson-swipe-card .card .answer-highlight {
  opacity: 0;
}
lesson-swipe-card .card .answer-highlight rl-icon {
  display: none;
  width: 65px;
  height: 65px;
  color: var(--white-color);
  font-size: 36px;
}
lesson-swipe-card .card .content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: var(--spacing);
}
@media only screen and (min-width: 1025px) {
  lesson-swipe-card .card .content {
    padding: calc(var(--m-spacing) * 2);
  }
}
lesson-swipe-card .card .content h3 {
  margin: 0;
  font-size: 20px;
  font-weight: 700;
  line-height: 130%;
}
lesson-swipe-card rl-icon[icon=close] {
  background: var(--warning-color);
}
lesson-swipe-card rl-icon[icon=save] {
  background: var(--confirmation-color);
}
lesson-swipe-card footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: var(--spacing);
}
lesson-swipe-card footer.desktop {
  padding: calc(var(--spacing) * 2) 0;
}
lesson-swipe-card footer .swipe-answer {
  display: flex;
  flex-direction: column;
  position: relative;
}
lesson-swipe-card footer .swipe-answer.true {
  align-items: flex-end;
}
lesson-swipe-card footer .swipe-answer.false {
  align-items: flex-start;
}
lesson-swipe-card footer .swipe-answer > span {
  margin: 0 auto;
}
lesson-swipe-card footer rl-icon {
  margin: 0 var(--spacing) var(--s-spacing) var(--spacing);
  color: var(--white-color);
  font-size: 21px;
}
@media only screen and (min-width: 1025px) {
  lesson-swipe-card footer rl-icon {
    width: 40px;
    height: 40px;
    font-size: 28px;
  }
}
lesson-swipe-card .answer {
  display: flex;
  justify-content: space-around;
  height: calc(var(--m-spacing) * 2);
}
@media only screen and (max-width: 1024px) {
  lesson-swipe-card .answer {
    padding-top: var(--spacing);
  }
}
lesson-swipe-card .answer > span {
  font-size: 13px;
}
lesson-swipe-card .answer .change-answer {
  display: flex;
  justify-content: center;
}
lesson-swipe-card .answer .change-answer button.transparent {
  height: auto;
  min-width: auto;
  padding: 0;
  font-size: 13px;
  color: var(--general-text-color);
}
lesson-swipe-card .answer .change-answer button.transparent rl-icon {
  font-size: 15px;
}
lesson-image-question .title h2 {
  margin: 0 0 var(--spacing) 0;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  color: var(--general-text-color);
}
lesson-image-question .title p {
  margin: 0;
  font-size: 13px;
  color: var(--general-text-color);
}
lesson-image-question .title media {
  overflow: hidden;
  margin-bottom: var(--spacing);
  border-radius: var(--general-border-radius);
}
lesson-image-question .answers {
  position: relative;
  margin-bottom: var(--spacing);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}
lesson-image-question .answer {
  position: relative;
  width: 47%;
  margin-bottom: var(--spacing);
}
lesson-image-question .answer img {
  object-fit: cover;
  border: 1px solid transparent;
  border-radius: var(--general-border-radius);
  width: 47cqw;
  height: 47cqw;
}
lesson-image-question .answer.selected img {
  border-color: var(--highlight-color);
}
lesson-image-question .answer.selected .answer-icon {
  width: 30px;
  height: 30px;
  background-color: var(--white-color);
  border-radius: 50%;
  color: var(--highlight-color);
}
lesson-image-question .answer.selected .answer-icon.multiple {
  width: 26px;
  height: 26px;
  font-size: 32px;
}
lesson-image-question .answer.answered.selected rl-icon {
  --fa-primary-color: var(--white-color);
  --fa-secondary-opacity: 1;
}
lesson-image-question .answer.answered.selected.correct img {
  border-color: var(--confirmation-color);
}
lesson-image-question .answer.answered.selected.correct rl-icon {
  --fa-secondary-color: var(--confirmation-color);
}
lesson-image-question .answer.answered.selected.wrong img {
  border-color: var(--warning-color);
}
lesson-image-question .answer.answered.selected.wrong rl-icon {
  --fa-secondary-color: var(--warning-color);
}
lesson-image-question .answer.disabled img {
  background-color: var(--background-highlight-color);
}
lesson-image-question .answer-icon {
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 30px;
  color: var(--general-background-color);
}
lesson-image-question .answer-icon span {
  width: 30px;
  height: 30px;
}
lesson-image-question .answer-icon.multiple {
  width: 26px;
  height: 26px;
}
lesson-image-question .answer-title {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
}
lesson-evaluation .content-wrapper {
  background-size: 100% 250px;
  background-repeat: no-repeat;
}
lesson-evaluation .passed {
  background-image: linear-gradient(180deg, rgba(var(--confirmation-color-rgb), 0.5) 0%, var(--general-background-color) 80%);
}
lesson-evaluation .passed .title h3 {
  color: var(--confirmation-color);
}
lesson-evaluation .passed .score h2 {
  color: var(--confirmation-color);
}
lesson-evaluation .time-out,
lesson-evaluation .not-passed {
  background-image: linear-gradient(180deg, rgba(var(--warning-color-rgb), 0.5) 0%, var(--general-background-color) 80%);
}
lesson-evaluation .time-out .title h3,
lesson-evaluation .not-passed .title h3 {
  color: var(--warning-color);
}
lesson-evaluation .time-out .score h2,
lesson-evaluation .not-passed .score h2 {
  color: var(--warning-color);
}
lesson-evaluation .time-out {
  background-image: linear-gradient(180deg, rgba(var(--alert-color-rgb), 0.5) 0%, var(--general-background-color) 80%);
}
lesson-evaluation .title {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 0 20px 0;
}
lesson-evaluation .title .img {
  margin-bottom: 20px;
  width: 70px;
  height: 70px;
}
lesson-evaluation .title h1 {
  margin: 0 0 var(--m-spacing) 0;
  font-size: 28px;
  font-weight: 700;
  line-height: 110%;
}
lesson-evaluation .title h3 {
  margin: 0;
  font-weight: 700;
}
lesson-evaluation .questions-wrapper {
  padding: var(--spacing);
  border: 1px solid var(--general-border-color);
  border-radius: var(--general-border-radius);
}
lesson-evaluation .questions-wrapper .score {
  text-align: center;
  padding-bottom: var(--spacing);
  margin-bottom: var(--spacing);
  border-bottom: 1px solid var(--general-border-color);
}
lesson-evaluation .questions-wrapper .score h2 {
  margin: 0 0 5px 0;
  font-size: 40px;
  font-weight: 700;
  line-height: 100%;
}
lesson-evaluation .questions-wrapper .score h3 {
  margin: 0;
  font-weight: 700;
}
lesson-evaluation .questions-wrapper .question {
  display: flex;
  align-items: center;
  padding: calc(var(--m-spacing) / 2) 0;
  gap: var(--m-spacing);
}
lesson-evaluation .questions-wrapper .question .question-title {
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 13px;
  line-height: 1;
}
lesson-evaluation .questions-wrapper .question:not(:last-of-type) {
  margin-bottom: var(--spacing);
}
lesson-evaluation .questions-wrapper .question rl-icon {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  font-size: 14px;
}
lesson-evaluation .questions-wrapper .question rl-icon[icon=save] {
  color: var(--confirmation-color);
  background: rgba(var(--confirmation-color-rgb), 0.1);
}
lesson-evaluation .questions-wrapper .question rl-icon[icon=clock],
lesson-evaluation .questions-wrapper .question rl-icon[icon=close] {
  color: var(--warning-color);
  background: rgba(var(--warning-color-rgb), 0.1);
}
lesson-evaluation .questions-wrapper .question rl-icon[icon=eye] {
  cursor: pointer;
  color: var(--general-text-color);
  background: rgba(var(--general-text-color-rgb), 0.1);
}
lesson-disclaimer .disclaimer {
  display: flex;
  align-items: center;
  gap: var(--spacing);
  padding: var(--spacing);
  background: rgba(var(--alert-color-rgb), 0.2);
  color: var(--general-text-color);
}
lesson-disclaimer .disclaimer.lesson-done-success {
  background: rgba(var(--confirmation-color-rgb), 0.2);
}
lesson-disclaimer .disclaimer.expired {
  background: rgba(var(--general-text-color-rgb), 0.2);
}
lesson-disclaimer .disclaimer p {
  margin: 0;
  padding: 0;
  text-align: left;
  font-size: 13px;
}
lesson-disclaimer .disclaimer rl-icon {
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  font-size: 12px;
  border-radius: 50%;
  color: var(--white-color);
}
lesson-disclaimer .disclaimer rl-icon[icon=save] {
  background: var(--confirmation-color);
}
lesson-disclaimer .disclaimer rl-icon[icon=exclamation] {
  background: var(--alert-color);
}
lesson-disclaimer .disclaimer rl-icon[icon=lock] {
  background: rgba(var(--general-text-color-rgb), 0.4);
}
lesson-disclaimer .disclaimer rl-icon[icon=hourglass-end] {
  font-size: 13px;
  background: var(--general-text-color);
}
@media only screen and (min-width: 1025px) {
  lesson-testimonials .actionsheet {
    width: calc(var(--max-width) - var(--spacing) * 2);
    box-sizing: border-box;
  }
}
lesson-testimonials .content-wrapper {
  display: flex;
  flex-direction: column;
  gap: var(--spacing);
}
lesson-testimonials .stars {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--m-spacing);
}
lesson-testimonials .stars h2 {
  margin: 0;
}
lesson-testimonials .stars p {
  margin: 0;
}
lesson-testimonials .stars.submitted {
  padding-bottom: calc(var(--spacing) * 3);
}
lesson-testimonials rl-textarea {
  margin-bottom: var(--spacing);
}
lesson-testimonials rl-textarea textarea {
  width: 100%;
  padding: var(--m-spacing);
  min-height: 120px;
  box-sizing: border-box;
  border-radius: var(--general-border-radius);
  background: var(--general-background-color);
  border-color: var(--general-border-color);
  box-shadow: none;
}
lesson-testimonials rl-textarea span {
  bottom: 20px;
  right: 25px;
}
lesson-testimonials stars-rating {
  align-self: center;
}
lesson-testimonials stars-rating rl-icon {
  padding: 0 10px;
}
lesson-testimonials stars-rating rl-icon:first-of-type {
  padding-left: 0;
}
lesson-testimonials stars-rating rl-icon:last-of-type {
  padding-right: 0;
}
lesson-introduction.no-introduction {
  background-image: url("/Content/Graphics/Training/training-background.svg");
  background-position: center;
  background-size: auto;
  background-repeat: no-repeat;
}
lesson-introduction lesson-disclaimer {
  display: block;
  margin: calc(var(--m-spacing) * -2.5) calc(var(--spacing) * -1) calc(var(--m-spacing) * 2.5);
}
lesson-introduction .lesson-media {
  width: 100%;
  margin-bottom: var(--spacing);
}
lesson-introduction .label-item,
lesson-introduction .go-to-tests {
  display: flex;
  flex-direction: column;
  padding: var(--spacing);
  border-radius: var(--general-border-radius);
  background: var(--background-highlight-color);
}
lesson-introduction .label-item > span,
lesson-introduction .go-to-tests > span {
  font-size: 15px;
}
lesson-introduction .label-item .bottom,
lesson-introduction .go-to-tests .bottom {
  display: flex;
  align-items: center;
  margin-top: calc(var(--m-spacing) / 2);
}
lesson-introduction .label-item h3,
lesson-introduction .go-to-tests h3 {
  margin: 0;
  font-size: 20px;
}
lesson-introduction .label-item .emoji,
lesson-introduction .go-to-tests .emoji {
  margin-right: var(--m-spacing);
  width: 30px;
  height: 30px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/Content/Graphics/Training/nerd_face.png");
}
lesson-introduction .label-item .arrow,
lesson-introduction .go-to-tests .arrow {
  width: 30px;
  height: 30px;
  box-sizing: border-box;
  margin-left: auto;
  padding: 3px;
  border-radius: 50%;
  background: var(--general-background-color);
  backdrop-filter: blur(12px);
}
lesson-introduction .label-item .arrow path,
lesson-introduction .go-to-tests .arrow path {
  fill: var(--general-text-color);
}
lesson-introduction .go-to-tests {
  cursor: pointer;
}
lesson-introduction .button-wrapper rl-button rl-icon {
  font-size: 15px;
}
lesson-introduction .labels-group {
  display: flex;
  gap: var(--spacing);
  margin: var(--spacing) 0;
}
lesson-introduction .labels-group .label-item {
  width: 50%;
  font-size: 13px;
  flex-direction: row;
  gap: var(--m-spacing);
}
lesson-introduction .labels-group .label-icon {
  font-size: 16px;
  width: 36px;
  height: 36px;
  background-color: var(--general-background-color);
  border-radius: 50%;
}
lesson-introduction .labels-group .label-text {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
lesson-introduction .labels-group .label-text span:first-child {
  font-size: 15px;
  font-weight: bold;
}
training-lesson-item {
  display: flex;
  flex-direction: column;
  padding: 0 5px 10px 5px;
}
training-lesson-item .image {
  position: relative;
  overflow: hidden;
  height: auto;
  border-top-left-radius: var(--training-border-radius);
  border-top-right-radius: var(--training-border-radius);
  box-shadow: none;
}
training-lesson-item.training-item .image {
  box-shadow: none;
}
training-lesson-item.training-item .image .image-inner {
  height: 0;
  padding-bottom: var(--training-lesson-image-height);
  position: relative;
}
training-lesson-item.training-item .image .image-inner rl-icon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  line-height: initial;
}
training-lesson-item state-icon {
  position: absolute;
  top: 0;
  right: 0;
  margin: 10px;
}
training-lesson-item .info {
  flex: 1;
  padding: 10px 0;
  border-bottom-left-radius: var(--training-border-radius);
  border-bottom-right-radius: var(--training-border-radius);
}
training-lesson-item .info .duration-redo {
  display: flex;
  justify-content: center;
}
training-lesson-item .info .duration-redo .redo {
  margin-left: 15px;
  text-transform: lowercase;
}
my-training > section {
  padding: 20px 0;
}
my-training rl-loading-placeholder {
  display: block;
  margin: 10px;
  padding: 10px;
  background-color: var(--background-highlight-color);
  border-radius: var(--training-border-radius);
}
my-training rl-loading-placeholder:first-of-type {
  margin-top: 0;
}
@media only screen and (min-width: 1025px) {
  my-training rl-loading-placeholder {
    margin: 10px 0;
  }
}
my-training rl-loading-placeholder .placeholder-shimmer {
  height: 19.5px;
}
my-training rl-loading-placeholder .placeholder-shimmer:not(:last-of-type) {
  margin-bottom: 17px;
}
my-training .phase {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 15px;
}
my-training .phase:before {
  content: '';
  position: absolute;
  top: 19px;
  width: 100%;
  height: 1px;
  background: radial-gradient(circle, rgba(0, 0, 0, 0) 0%, rgba(var(--general-text-color-rgb), 0.1) 50%, rgba(0, 0, 0, 0) 100%);
}
my-training .phase .phase-number {
  position: absolute;
  top: 12px;
  font-size: 13px;
  font-weight: 600;
  color: var(--text-on-white-color);
}
my-training .phase rl-icon {
  position: relative;
  font-size: 34px;
  color: var(--alert-color);
}
my-training .phase h2 {
  text-transform: uppercase;
  padding-top: 7px;
  margin: 0 10px;
  text-align: center;
  line-height: 1.5;
  font-size: 15px;
  font-weight: 600;
}
my-training .phase-item .top .icon-wrapper {
  position: relative;
  margin-right: 10px;
}
my-training .phase-item .top .icon-wrapper .icon {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: var(--general-text-color);
}
my-training .phase-item .top .icon-wrapper .icon [icon="crown"] {
  font-size: 34px;
  color: rgba(var(--general-background-color-rgb), 0.4);
}
my-training .phase-item .top .icon-wrapper state-icon {
  display: flex;
  position: absolute;
  bottom: 0;
  right: 0;
}
my-training .phase-item .top .icon-wrapper state-icon rl-icon.new span {
  top: 0;
}
my-training .phase-item .top h3 {
  margin: 0;
}
my-training training-learn-more-component {
  margin: 10px;
}
@media only screen and (min-width: 1025px) {
  my-training training-learn-more-component {
    margin: 10px 0;
  }
}
my-training .title {
  display: flex;
  flex-direction: row;
}
my-training .title .title-phase {
  margin-right: 5px !important;
}
my-training .course,
course-item .course,
my-training .phase-item,
course-item .phase-item {
  overflow: hidden;
  margin: 10px;
  padding: 10px;
  border-radius: var(--training-border-radius);
  background-color: var(--background-highlight-color);
  cursor: pointer;
}
@media only screen and (min-width: 1025px) {
  my-training .course,
  course-item .course,
  my-training .phase-item,
  course-item .phase-item {
    margin: 10px 0;
  }
}
my-training .course.locked .top h3,
course-item .course.locked .top h3,
my-training .phase-item.locked .top h3,
course-item .phase-item.locked .top h3,
my-training .course.expired .top h3,
course-item .course.expired .top h3,
my-training .phase-item.expired .top h3,
course-item .phase-item.expired .top h3 {
  opacity: 0.4;
}
my-training .course.locked .image-inner,
course-item .course.locked .image-inner,
my-training .phase-item.locked .image-inner,
course-item .phase-item.locked .image-inner,
my-training .course.expired .image-inner,
course-item .course.expired .image-inner,
my-training .phase-item.expired .image-inner,
course-item .phase-item.expired .image-inner,
my-training .course.locked .icon,
course-item .course.locked .icon,
my-training .phase-item.locked .icon,
course-item .phase-item.locked .icon,
my-training .course.expired .icon,
course-item .course.expired .icon,
my-training .phase-item.expired .icon,
course-item .phase-item.expired .icon {
  opacity: 0.4;
}
my-training .course.locked rl-progress .bar .inner,
course-item .course.locked rl-progress .bar .inner,
my-training .phase-item.locked rl-progress .bar .inner,
course-item .phase-item.locked rl-progress .bar .inner,
my-training .course.expired rl-progress .bar .inner,
course-item .course.expired rl-progress .bar .inner,
my-training .phase-item.expired rl-progress .bar .inner,
course-item .phase-item.expired rl-progress .bar .inner {
  background: rgba(var(--general-text-color-rgb), 0.4);
}
my-training .course.deadline,
course-item .course.deadline,
my-training .phase-item.deadline,
course-item .phase-item.deadline {
  background-image: linear-gradient(to bottom, rgba(var(--general-text-color-rgb), 0) 39%, rgba(var(--warning-color-rgb), 0.1));;
}
my-training .course.deadline rl-progress .bar .inner,
course-item .course.deadline rl-progress .bar .inner,
my-training .phase-item.deadline rl-progress .bar .inner,
course-item .phase-item.deadline rl-progress .bar .inner {
  background: var(--warning-color);
}
my-training .course.new,
course-item .course.new,
my-training .phase-item.new,
course-item .phase-item.new {
  background-image: linear-gradient(to bottom, rgba(var(--general-text-color-rgb), 0) 39%, rgba(var(--highlight-color-rgb), 0.1));;
}
my-training .course.new .top h3,
course-item .course.new .top h3,
my-training .phase-item.new .top h3,
course-item .phase-item.new .top h3 {
  color: var(--highlight-color);
}
my-training .course.new .status,
course-item .course.new .status,
my-training .phase-item.new .status,
course-item .phase-item.new .status {
  text-transform: uppercase;
}
my-training .course.passed,
course-item .course.passed,
my-training .phase-item.passed,
course-item .phase-item.passed {
  background-image: linear-gradient(to bottom, rgba(var(--general-text-color-rgb), 0) 39%, rgba(var(--confirmation-color-rgb), 0.1));;
}
my-training .course.passed rl-progress .bar .inner,
course-item .course.passed rl-progress .bar .inner,
my-training .phase-item.passed rl-progress .bar .inner,
course-item .phase-item.passed rl-progress .bar .inner {
  background: var(--confirmation-color);
}
my-training .course.passed .status,
course-item .course.passed .status,
my-training .phase-item.passed .status,
course-item .phase-item.passed .status {
  text-transform: var(--confirmation-color);
}
my-training .course.not-passed,
course-item .course.not-passed,
my-training .phase-item.not-passed,
course-item .phase-item.not-passed {
  background-image: linear-gradient(to bottom, rgba(var(--general-text-color-rgb), 0) 39%, rgba(var(--alert-color-rgb), 0.1));;
}
my-training .course.not-passed rl-progress .bar .inner,
course-item .course.not-passed rl-progress .bar .inner,
my-training .phase-item.not-passed rl-progress .bar .inner,
course-item .phase-item.not-passed rl-progress .bar .inner {
  background: var(--alert-color);
}
my-training .course:first-of-type,
course-item .course:first-of-type,
my-training .phase-item:first-of-type,
course-item .phase-item:first-of-type {
  margin-top: 0;
}
my-training .course .top,
course-item .course .top,
my-training .phase-item .top,
course-item .phase-item .top {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
my-training .course .top h3,
course-item .course .top h3,
my-training .phase-item .top h3,
course-item .phase-item .top h3 {
  margin: 0 0 10px 0;
  font-size: 15px;
  font-weight: 600;
}
my-training .course .top .time-to-renew-badge,
course-item .course .top .time-to-renew-badge,
my-training .phase-item .top .time-to-renew-badge,
course-item .phase-item .top .time-to-renew-badge {
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-self: flex-start;
  margin-left: auto;
  padding: 6px;
  gap: 4px;
  border-radius: 7px;
  font-size: 11px;
  font-weight: 700;
}
my-training .course .top .time-to-renew-badge rl-icon,
course-item .course .top .time-to-renew-badge rl-icon,
my-training .phase-item .top .time-to-renew-badge rl-icon,
course-item .phase-item .top .time-to-renew-badge rl-icon {
  font-size: 13px;
}
my-training .course .top .duration > span,
course-item .course .top .duration > span,
my-training .phase-item .top .duration > span,
course-item .phase-item .top .duration > span {
  white-space: nowrap;
  padding-right: 20px;
  font-size: 13px;
}
my-training .course .top .duration > span rl-icon,
course-item .course .top .duration > span rl-icon,
my-training .phase-item .top .duration > span rl-icon,
course-item .phase-item .top .duration > span rl-icon {
  font-size: 13px;
}
my-training .course rl-progress,
course-item .course rl-progress,
my-training .phase-item rl-progress,
course-item .phase-item rl-progress {
  margin-bottom: 10px;
}
my-training .course .bottom,
course-item .course .bottom,
my-training .phase-item .bottom,
course-item .phase-item .bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
my-training .course .bottom p,
course-item .course .bottom p,
my-training .phase-item .bottom p,
course-item .phase-item .bottom p {
  margin: 0;
  font-size: 13px;
}
my-training .course .bottom p.status,
course-item .course .bottom p.status,
my-training .phase-item .bottom p.status,
course-item .phase-item .bottom p.status {
  font-weight: 600;
}
my-training .course .bottom p.expired,
course-item .course .bottom p.expired,
my-training .phase-item .bottom p.expired,
course-item .phase-item .bottom p.expired,
my-training .course .bottom p.deadline,
course-item .course .bottom p.deadline,
my-training .phase-item .bottom p.deadline,
course-item .phase-item .bottom p.deadline {
  color: var(--general-text-inactive-color);
}
my-training .course .bottom p.expired-since,
course-item .course .bottom p.expired-since,
my-training .phase-item .bottom p.expired-since,
course-item .phase-item .bottom p.expired-since {
  color: var(--warning-color);
}
course-item .course .image {
  position: relative;
  min-width: 80px;
  height: 80px;
  margin-right: 10px;
  border-radius: 50%;
}
course-item .course .image .image-inner {
  overflow: hidden;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center center;
  text-align: center;
}
course-item .course .image .image-inner rl-icon {
  display: block;
  font-size: 34px;
  line-height: 80px;
}
course-item .course .image state-icon {
  position: absolute;
  bottom: 0;
  right: 0;
}
my-training-v2 {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - var(--top-header-height));
  transition: opacity 0.3s ease-in;
}
my-training-v2 .tabs-wrapper .rl-tabs {
  margin: 0;
  padding-left: 0;
}
@media only screen and (max-width: 1024px) {
  my-training-v2 .tabs-wrapper {
    margin: 0 var(--spacing);
  }
}
my-training-v2 > section {
  display: flex;
  flex-direction: column;
  gap: var(--spacing);
  padding: calc(var(--spacing) * 2) 0;
}
my-training-v2 > section course-item .course {
  margin: 0;
}
@media only screen and (max-width: 1024px) {
  my-training-v2 > section {
    margin: 0 var(--spacing);
  }
}
my-training-v2 rl-loading-placeholder {
  display: block;
  padding: var(--m-spacing);
  background-color: var(--background-highlight-color);
  border-radius: var(--training-border-radius);
}
my-training-v2 rl-loading-placeholder .placeholder-shimmer {
  height: 19.5px;
}
my-training-v2 rl-loading-placeholder .placeholder-shimmer:not(:last-of-type) {
  margin-bottom: 17px;
}
my-training-v2 .learning-path {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: var(--spacing);
}
my-training-v2 .learning-path:before {
  content: '';
  position: absolute;
  top: 19px;
  width: 100%;
  height: 1px;
  background: radial-gradient(circle, rgba(0, 0, 0, 0) 0%, rgba(var(--general-text-color-rgb), 0.1) 50%, rgba(0, 0, 0, 0) 100%);
}
my-training-v2 .learning-path .phase-number {
  position: absolute;
  top: 12px;
  font-size: 13px;
  font-weight: 600;
  color: var(--text-on-white-color);
}
my-training-v2 .learning-path .learning-path-icon-number {
  position: relative;
}
my-training-v2 .learning-path .learning-path-icon-number rl-icon {
  position: relative;
  font-size: 34px;
  color: var(--background-highlight-color);
}
my-training-v2 .learning-path .learning-path-icon-number .learning-path-number {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
my-training-v2 .learning-path h2 {
  padding-top: 7px;
  margin: 0 var(--m-spacing);
  text-align: center;
  line-height: 1.5;
  font-size: 16px;
  font-weight: 700;
}
my-training-v2 .courses-list {
  display: flex;
  flex-direction: column;
  gap: var(--spacing);
}
my-training-v2 training-learn-more-component {
  margin-top: auto;
  padding-bottom: var(--spacing);
}
@media only screen and (max-width: 1024px) {
  my-training-v2 training-learn-more-component {
    margin: 0 var(--spacing);
  }
}
my-training-v2 no-content {
  margin: auto;
}
my-training-v2 no-content .no-content {
  position: static;
  margin: 0;
  height: unset;
}
learning-path-page {
  display: block;
}
learning-path-item {
  display: block;
  overflow: hidden;
  padding: var(--m-spacing);
  border-radius: var(--training-border-radius);
  background-color: var(--background-highlight-color);
}
learning-path-item .learning-path-item.expired .top h3 {
  opacity: 0.4;
}
learning-path-item .learning-path-item.expired .image-inner {
  opacity: 0.4;
}
learning-path-item .learning-path-item.expired rl-progress .bar .inner {
  background: rgba(var(--general-text-color-rgb), 0.4);
}
learning-path-item:not(.locked) {
  cursor: pointer;
}
learning-path-item .top .icon-wrapper {
  position: relative;
  margin-right: 10px;
}
learning-path-item .top .icon-wrapper .icon {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: var(--general-text-color);
}
learning-path-item .top .icon-wrapper .icon [icon="circle"] {
  font-size: 34px;
  color: rgba(var(--general-background-color-rgb), 0.4);
}
learning-path-item .top .icon-wrapper state-icon {
  display: flex;
  position: absolute;
  bottom: 0;
  right: 0;
}
learning-path-item .top .icon-wrapper state-icon rl-icon.new span {
  top: 0;
}
learning-path-item .top h3 {
  margin: 0;
}
learning-path-item .top {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
learning-path-item .top .icon-wrapper {
  position: relative;
  margin-right: 10px;
}
learning-path-item .top .icon-wrapper .icon {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: var(--general-text-color);
}
learning-path-item .top .icon-wrapper .icon [icon="folder-tree"] {
  font-size: 34px;
  color: rgba(var(--general-background-color-rgb), 0.4);
}
learning-path-item .top .icon-wrapper state-icon {
  display: flex;
  position: absolute;
  bottom: 0;
  right: 0;
}
learning-path-item .top .icon-wrapper state-icon rl-icon.new span {
  top: 0;
}
learning-path-item .top h3 {
  margin: 0 0 6px 0;
  font-size: 16px;
  font-weight: 600;
}
learning-path-item .top .title-and-duration .duration {
  font-size: 13px;
}
learning-path-item .top .title-and-duration .duration span rl-icon {
  font-size: 13px;
}
learning-path-item rl-progress {
  margin-bottom: 10px;
}
learning-path-item .locked .info h3,
learning-path-item .expired .info h3 {
  opacity: 0.4;
}
learning-path-item .locked .image-inner,
learning-path-item .expired .image-inner {
  opacity: 0.4;
}
learning-path-item .locked rl-progress .bar .inner,
learning-path-item .expired rl-progress .bar .inner {
  background: rgba(var(--general-text-color-rgb), 0.4);
}
learning-path-item .deadline .info {
  background-image: linear-gradient(to bottom, rgba(var(--general-text-color-rgb), 0) 39%, rgba(var(--warning-color-rgb), 0.1));
}
learning-path-item .deadline rl-progress .bar .inner {
  background: var(--warning-color);
}
learning-path-item .new .info {
  background-image: linear-gradient(to bottom, rgba(var(--general-text-color-rgb), 0) 39%, rgba(var(--highlight-color-rgb), 0.1));
}
learning-path-item .new .info h3 {
  color: var(--highlight-color);
}
learning-path-item .new .status {
  text-transform: uppercase;
}
learning-path-item .passed .info {
  background-image: linear-gradient(to bottom, rgba(var(--general-text-color-rgb), 0) 39%, rgba(var(--confirmation-color-rgb), 0.1));
}
learning-path-item .passed rl-progress .bar .inner {
  background: var(--confirmation-color);
}
learning-path-item .not-passed .info {
  background-image: linear-gradient(to bottom, rgba(var(--general-text-color-rgb), 0) 39%, rgba(var(--alert-color-rgb), 0.1));
}
learning-path-item .not-passed rl-progress .bar .inner {
  background: var(--alert-color);
}
learning-path-item .bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
learning-path-item .bottom p {
  margin: 0;
  font-size: 13px;
}
learning-path-item .bottom p.status {
  font-weight: 600;
}
learning-path-item .bottom p.expired,
learning-path-item .bottom p.deadline {
  color: var(--general-text-inactive-color);
}
training-module-item {
  padding: 40px 5px 10px;
}
training-module-item .image {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 80px;
  height: 80px;
  border-radius: 100%;
}
training-module-item .image state-icon {
  position: absolute;
  bottom: 0;
  right: 0;
}
training-module-item .image .image-inner {
  border-radius: 100%;
}
training-module-item .info {
  height: calc(100% - 60px);
  padding: 50px 0 10px 0;
  border-radius: var(--training-border-radius);
}
training-module-item .info rl-progress {
  padding: 0 10px;
  margin-top: 12px;
}
training-voluntary-category .bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 220px;
}
training-voluntary-category header {
  position: relative;
  display: flex;
  justify-content: center;
  padding-top: 25px;
}
training-voluntary-category header .image {
  width: 87px;
  height: 87px;
  border-radius: 50%;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.25);
}
training-voluntary-category section {
  position: relative;
  padding-top: 25px;
}
training-voluntary-category section:not(:last-of-type) {
  margin-bottom: 25px;
}
training-voluntary-category section > h2 {
  margin: 0 0 10px 10px;
  color: var(--general-text-color);
  font-size: 15px;
  line-height: 20px;
  font-weight: 600;
}
training-voluntary-category .related-categories > div {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin: 0 10px 10px 10px;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
@media only screen and (min-width: 1025px) {
  training-voluntary-category .related-categories > div {
    flex-wrap: wrap;
    margin: 0 5px;
  }
}
training-voluntary-category .related-categories > div training-voluntary-category-item {
  width: 40%;
  height: 80px;
  flex-shrink: 0;
}
@media only screen and (max-width: 1024px) {
  training-voluntary-category .related-categories > div training-voluntary-category-item:not(:last-of-type) {
    margin-right: 10px;
  }
}
@media only screen and (min-width: 768px) {
  training-voluntary-category .related-categories > div training-voluntary-category-item {
    width: 25%;
  }
}
@media only screen and (min-width: 1025px) {
  training-voluntary-category .related-categories > div training-voluntary-category-item {
    margin: 0 5px 10px 5px;
  }
}
training-voluntary-category .related-categories > div training-voluntary-category-item .image {
  width: 75px;
  height: 75px;
  bottom: -30px;
  right: -15px;
}
training-voluntary-category-item {
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  height: 100px;
  padding: 10px;
  cursor: pointer;
  border-radius: var(--general-border-radius);
}
training-voluntary-category-item .left {
  width: 100%;
}
training-voluntary-category-item h3 {
  overflow: hidden;
  width: 100%;
  margin: 0 0 5px 0;
  line-height: 20px;
  white-space: nowrap;
  text-overflow: ellipsis;
}
training-voluntary-category-item p {
  margin: 0;
}
training-voluntary-category-item rl-icon {
  font-size: 15px;
}
training-voluntary-category-item .image {
  position: absolute;
  margin-top: auto;
  right: -15px;
  bottom: -15px;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.25);
}
@media only screen and (min-width: 1025px) {
  training-voluntary-page .training-grid,
  training-voluntary-category .training-grid {
    margin: 0;
  }
}
training-voluntary-page .training-categories-grid,
training-voluntary-category .training-categories-grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0 5px;
  box-sizing: border-box;
}
training-voluntary-page .training-categories-grid training-voluntary-category-item,
training-voluntary-category .training-categories-grid training-voluntary-category-item {
  margin: 0 5px 10px 5px;
  width: calc(50% - 10px);
}
@media only screen and (min-width: 768px) {
  training-voluntary-page .training-categories-grid training-voluntary-category-item,
  training-voluntary-category .training-categories-grid training-voluntary-category-item {
    width: calc(33.333% - 10px);
  }
}
@media only screen and (min-width: 1025px) {
  training-voluntary-page .training-categories-grid training-voluntary-category-item,
  training-voluntary-category .training-categories-grid training-voluntary-category-item {
    width: calc(25% - 10px);
  }
}
training-voluntary-page {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 55px - 50px);
}
@media only screen and (min-width: 1025px) {
  training-voluntary-page {
    height: calc(100vh - 60px);
  }
}
training-voluntary-page rl-search {
  margin: 10px;
}
training-voluntary-page ul {
  display: flex;
  margin: 15px 10px 25px 10px;
  padding: 0;
}
training-voluntary-page li {
  list-style: none;
  opacity: 0.4;
}
training-voluntary-page li:first-of-type {
  margin-right: 30px;
}
training-voluntary-page li.active {
  position: relative;
  opacity: 1;
  font-weight: 600;
}
training-voluntary-page li.active:after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -5px;
  width: 100%;
  height: 3px;
  background: var(--general-text-color);
}
training-voluntary-page > section {
  position: relative;
  flex: 1;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior: none;
}
training-voluntary-page .training-categories-grid {
  position: absolute;
  min-height: 100%;
  width: 100%;
  align-content: flex-start;
}
training-voluntary-page .lesson {
  padding: 0 5px 10px 5px;
}
training-voluntary-page .training-modules-lessons header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}
training-voluntary-page .training-modules-lessons header h3 {
  font-size: 15px;
  margin: 0;
}
training-voluntary-page .training-modules-lessons header rl-button button {
  font-size: 13px;
  font-weight: normal;
  min-width: auto;
  height: auto;
}
training-filters-popup .sorting {
  display: flex;
  flex: 1;
  flex-direction: column;
  background: var(--background-highlight-color);
}
training-filters-popup .sorting .input-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
}
training-filters-popup .sorting .input-wrapper:after {
  content: '';
  display: block;
  position: absolute;
  right: 15px;
  bottom: 20px;
  width: 8px;
  height: 8px;
  border-right: 1px solid rgba(var(--general-text-color-rgb), 0.35);
  border-bottom: 1px solid rgba(var(--general-text-color-rgb), 0.35);
  transform: rotate(-45deg);
}
training-filters-popup .sorting .input-wrapper label {
  padding: 15px 10px 10px 10px;
  font-size: 15px;
  font-weight: 600;
  color: var(--general-text-color);
}
training-filters-popup .sorting .input-wrapper rl-icon {
  position: absolute;
  font-size: 17px;
  bottom: 17px;
  left: 10px;
}
training-filters-popup .sorting .input-wrapper select {
  padding: 0 30px 0 40px;
  height: 50px;
  color: rgba(var(--general-text-color-rgb), 0.5);
  border: 1px solid rgba(var(--general-text-color-rgb), 0.1);
  font-size: 15px;
  background: var(--general-background-color);
  -webkit-appearance: none !important;
}
training-filters-popup .sorting rl-button {
  margin: auto 15px 15px 15px;
}
training-filters-popup .sorting rl-button button {
  text-transform: uppercase;
}
overview-table .table-content {
  display: flex;
  flex: 1;
  overflow-y: auto;
  flex-direction: column;
}
overview-table .course {
  flex-direction: column;
  border-bottom: 1px solid #EBECEE;
  position: relative;
}
overview-table .course.opened:before {
  content: '';
  display: block;
  position: absolute;
  z-index: -1;
  top: 23px;
  left: 22px;
  bottom: 23px;
  width: 1px;
  background: #3c4858;
}
overview-table .course.opened > .inner-row .name {
  font-weight: bold;
}
overview-table .course:not(.opened) .modules {
  display: none;
}
overview-table .course .name {
  display: flex;
  align-items: flex-start;
}
overview-table .course .name .redo {
  background: var(--general-border-color);
  padding: 3px 5px;
  margin-left: 5px;
}
overview-table .course .name .redo rl-icon {
  font-size: 15px;
}
overview-table .module:not(.opened) .lessons {
  display: none;
}
overview-table .lesson {
  position: relative;
}
overview-table .lesson:before {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  left: 23px;
  height: 1px;
  width: 7px;
  background: #3c4858;
}
overview-table .lesson .name {
  padding-left: 19px;
}
overview-table .inner-row {
  display: flex;
  width: 100%;
  padding: 15px 10px 15px 15px;
  box-sizing: border-box;
  background: transparent;
}
overview-table .inner-row .fal {
  line-height: 13px;
  background: white;
  padding-left: 1px;
  padding-right: 5px;
}
training-overview .actions {
  background: var(--header-background-color);
  color: var(--header-text-color);
  border-bottom: none;
}
training-overview .actions h1 {
  text-align: center;
  margin: 0 0 0 -38px !important;
}
training-overview .actions .action-close {
  color: var(--header-text-color);
}
training-overview .training-overview-header {
  padding: 15px;
  background: var(--general-background-color);
}
training-overview .training-overview-header .header-profile {
  display: flex;
  margin-bottom: 15px;
}
training-overview .training-overview-header .header-profile profile-image {
  padding-right: 10px;
}
training-overview .training-overview-header .header-profile profile-image .profile-image {
  width: 50px;
  height: 50px;
}
training-overview .training-overview-header .header-profile .profile-info {
  display: flex;
  flex-direction: column;
}
training-overview .training-overview-header .header-profile .profile-info .name {
  font-weight: 600;
  font-size: 18px;
  color: #3c4858;
}
training-overview .training-overview-header .header-profile .profile-info .department {
  font-size: 15px;
  color: var(--general-text-color);
  opacity: 0.5;
}
training-overview .training-overview-header .results {
  display: flex;
}
training-overview .training-overview-header .results .completion,
training-overview .training-overview-header .results .performance {
  position: relative;
  flex-basis: 50%;
  height: 36px;
  margin-right: 15px;
  text-align: center;
  line-height: 36px;
  font-weight: 600;
}
training-overview .training-overview-header .results .completion .training-progress-completion,
training-overview .training-overview-header .results .performance .training-progress-completion {
  display: flex;
  justify-content: center;
  align-content: center;
}
training-overview .training-overview-header .results .completion .training-progress-completion > div:nth-child(1),
training-overview .training-overview-header .results .performance .training-progress-completion > div:nth-child(1) {
  margin-left: 5px;
}
training-overview .training-overview-header .results .completion span,
training-overview .training-overview-header .results .performance span {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
}
training-overview .training-overview-header .results .completion {
  color: #5FA3F1;
  border: 1px solid #5FA3F1;
}
training-overview .training-overview-header .results .completion span {
  background: rgba(95, 163, 241, 0.2);
}
training-overview .training-overview-header .results .performance {
  color: #4FC990;
  border: 1px solid #4FC990;
}
training-overview .training-overview-header .results .performance span {
  background: rgba(79, 201, 144, 0.2);
}
training-overview overview-table {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
}
users-table .row {
  display: flex;
  padding: 10px;
  box-sizing: border-box;
  border-bottom: 1px solid rgba(var(--general-text-color-rgb), .1);
  word-break: break-word;
}
users-table .row.disabled {
  opacity: 0.5;
}
users-table .row:first-of-type {
  border-top: none;
}
users-table .row .UserName {
  display: flex;
}
users-table .row .UserName .profile-image {
  height: 34px;
  width: 34px;
}
users-table .row .UserName .name {
  display: block;
}
users-table .row .UserName .department {
  font-size: 13px;
  color: #8893A3;
}
training-material-selector button {
  width: 100%;
  padding: 0 30px 0 40px;
  height: 50px;
  text-align: left;
  color: rgba(var(--general-text-color-rgb), 0.5);
  border: 1px solid rgba(var(--general-text-color-rgb), 0.1);
  background: var(--general-background-color);
  -webkit-appearance: none !important;
}
.training-material-selector-popup .main {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: calc(100% - 55px);
  box-sizing: border-box;
  padding: 10px;
}
.training-material-selector-popup .main rl-search {
  margin: 15px 0 10px 0;
  min-height: 45px;
}
.training-material-selector-popup .main .scroll {
  overflow-y: auto;
  margin: 0 -10px;
  flex: 1;
  overscroll-behavior: none;
}
.training-material-selector-popup .main .material-list {
  flex: 1;
  min-height: 100px;
}
.training-material-selector-popup .main .material-list > div {
  position: relative;
  display: flex;
  align-items: center;
  height: 50px;
  padding: 0 10px;
  box-sizing: border-box;
}
.training-material-selector-popup .main .material-list > div.selected {
  background: var(--background-highlight-color);
}
.training-material-selector-popup .main .material-list > div.selected .text span:first-child {
  font-weight: 700;
}
.training-material-selector-popup .main .material-list > div .img {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 38px;
  min-height: 38px;
  border-radius: 100%;
  background: rgba(var(--general-text-color-rgb), 0.1);
  background-position: center;
}
.training-material-selector-popup .main .material-list > div .img rl-icon {
  color: var(--general-text-color);
  opacity: 0.4;
}
.training-material-selector-popup .main .material-list > div .text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 8px;
  overflow: hidden;
}
.training-material-selector-popup .main .material-list > div .text > span {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.training-material-selector-popup .main .material-list > div [icon="save"] {
  position: absolute;
  right: 10px;
  bottom: 15px;
  background: var(--confirmation-color);
  color: var(--white-color);
}
training-manager-tab {
  display: block;
  padding-top: 10px;
}
training-manager-tab .performance {
  background: var(--background-highlight-color);
}
training-manager-tab .performance > header {
  padding: 20px 25px;
  text-align: center;
  background: var(--general-background-color);
}
training-manager-tab .performance > header h2 {
  margin: 0;
  padding-bottom: 15px;
  text-transform: uppercase;
  font-size: 15px;
  line-height: 1;
}
training-manager-tab .performance > header h2 span {
  padding-left: 5px;
  font-size: 13px;
}
training-manager-tab .performance .results {
  display: flex;
  justify-content: space-between;
}
training-manager-tab .performance .result {
  display: flex;
  flex-direction: column;
}
training-manager-tab .performance .result h3 {
  margin: 0;
  padding-bottom: 5px;
  font-size: 28px;
}
training-manager-tab .low {
  color: var(--warning-color);
}
training-manager-tab .medium {
  color: var(--alert-color);
}
training-manager-tab .high {
  color: var(--confirmation-color);
}
training-manager-tab .spinner {
  width: 50px;
  height: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -25px;
  margin-top: -25px;
}
training-manager-tab .filter-button {
  position: fixed;
  right: 10px;
  bottom: 60px;
}
@media only screen and (max-width: 1024px) {
  training-manager-tab .filter-button {
    display: none;
  }
}
users-table header,
overview-table header {
  display: flex;
  overflow: hidden;
  padding: 10px;
  box-sizing: border-box;
  border-bottom: 1px solid rgba(var(--general-text-color-rgb), .1);
}
users-table header div,
overview-table header div {
  display: flex;
  font-weight: bold;
}
@media only screen and (min-width: 768px) {
  users-table header div .title.mobile,
  overview-table header div .title.mobile {
    display: none;
  }
}
users-table header div .title.desktop,
overview-table header div .title.desktop {
  overflow: hidden;
  text-overflow: ellipsis;
}
@media only screen and (max-width: 767px) {
  users-table header div .title.desktop,
  overview-table header div .title.desktop {
    display: none;
  }
}
users-table header div .sort,
overview-table header div .sort {
  position: relative;
  top: 2px;
  padding-left: 5px;
  color: #EBECEE;
}
users-table header div .sort.active,
overview-table header div .sort.active {
  color: #3c4858;
}
users-table header div .sort.reverse,
overview-table header div .sort.reverse {
  transform: scale(1, -1);
}
users-table .cell,
overview-table .cell {
  display: flex;
  width: 20%;
  align-items: center;
  overflow: hidden;
  text-align: left;
}
users-table .cell.UserName,
overview-table .cell.UserName,
users-table .cell.Name,
overview-table .cell.Name {
  width: 60%;
}
users-table .cell:not(:last-of-type),
overview-table .cell:not(:last-of-type) {
  padding-right: 10px;
}
.scorm {
  position: relative;
  padding-bottom: 0;
  height: 100%;
  overflow: visible !important;
}
@media only screen and (min-width: 1025px) {
  .scorm {
    height: calc(100vh - 60px);
  }
}
.scorm .iframe-container {
  -webkit-overflow-scrolling: touch;
  position: relative;
  height: 100%;
  min-height: 100%;
  overflow: visible;
}
.scorm .iframe-container icon {
  position: absolute;
  top: 10px;
  right: 10px;
  color: var(--general-background-color);
  background-color: var(--general-text-color);
  border-radius: 50%;
  width: 30px;
  height: 30px;
  padding: 6px;
}
@media screen and (orientation: landscape) {
  .scorm .iframe-container icon.landscape {
    display: none;
  }
}
.scorm .iframe-container icon.portrait {
  transform: scaleY(-1) rotate(90deg);
}
@media screen and (orientation: portrait) {
  .scorm .iframe-container icon.portrait {
    display: none;
  }
}
.scorm .iframe-container iframe {
  width: 1px;
  min-width: 100%;
  height: 99%;
  border: 0;
  position: static;
}
.scorm .spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: calc(-75px / 2);
  margin-left: calc(-75px / 2);
  width: 75px;
  height: 75px;
}
.base-view-survey {
  background: var(--general-background-color);
}
.base-view-survey.base-view-survey-manager-tab {
  height: auto;
  background: var(--background-highlight-color);
}
.native .survey .next-btn-wrapper,
.native .survey .prev-btn-wrapper,
.native .survey .counter {
  padding-bottom: calc(0px + env(safe-area-inset-bottom, 0px));
}
.survey {
  display: block;
}
.survey .app-link-url {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  user-select: none;
}
.survey .survey-container.paged {
  position: absolute;
  z-index: 1;
  opacity: 1;
  left: 0;
  right: 0;
  top: 0;
  bottom: 50px;
  transition: all ease-in-out 0.3s;
}
@media only screen and (max-width: 1024px) {
  .survey .survey-container.paged.with-tabs {
    top: 40px;
  }
}
@media only screen and (min-width: 1025px) {
  .survey .survey-container.paged {
    bottom: 0;
  }
}
.survey .survey-container.paged.ng-enter,
.survey .survey-container.paged.ng-leave.ng-leave-active {
  opacity: 0;
}
.survey .survey-container.paged.ng-leave,
.survey .survey-container.paged.ng-enter.ng-enter-active {
  opacity: 1;
}
.survey .survey-container.paged .questions {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 40px;
  width: 100%;
  overflow: hidden;
}
.survey .survey-container.paged survey-question {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  transform: translate3d(100%, 0, 0);
  z-index: 1;
  overflow: auto;
}
.survey .survey-container.paged survey-question.current {
  transform: translate3d(0, 0, 0);
  transition: transform 0.5s ease-in-out;
}
.survey .survey-container.paged survey-question.leave {
  transform: translate(-100%);
  transition: transform 0.5s ease-in-out;
}
.survey .survey-container:not(.paged) survey-question:not(:last-of-type) {
  margin-bottom: 15px;
}
@media only screen and (min-width: 768px) {
  .survey .survey-container survey-question {
    padding: 15px calc((100% - 400px) / 2);
  }
}
.survey .counter {
  display: block;
  height: 40px;
  line-height: 40px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  padding-bottom: calc(0px + env(safe-area-inset-bottom, 0px));
}
.survey .questions .breakdown {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -40px;
  top: 0;
  transform: translate(100%);
  z-index: 2;
}
.survey .questions .breakdown.show {
  transition: all 0.5s ease-in-out;
  transform: translate(0);
}
.survey .questions .breakdown button {
  height: 40px;
  line-height: 40px;
  width: 120px;
  color: var(--button-text-color);
  background: none;
  background: var(--button-background-color);
  border: 0;
  display: block;
  margin: 30px auto 30px auto;
  padding: 0;
  text-transform: uppercase;
}
.survey .submit {
  z-index: 5;
  position: sticky;
  bottom: 50px;
  padding: 55px 15px calc(20px + env(safe-area-inset-bottom, 0px)) 15px;
  background: linear-gradient(to top, var(--general-background-color) 50%, rgba(255, 255, 255, 0));
}
.survey .submit.submitted {
  background: var(--general-background-color);
  padding: 20px 15px;
}
.survey .submit.submitted:after {
  content: '';
  position: absolute;
  z-index: -1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(var(--confirmation-color-rgb), 0.2);
}
.survey .submit.submitted button {
  background: var(--confirmation-color);
}
@media only screen and (min-width: 768px) {
  .survey .submit {
    padding: 55px calc((100% - 400px) / 2) 20px;
  }
}
@media only screen and (min-width: 1025px) {
  .survey .submit {
    position: static;
  }
}
.survey .submit > span {
  display: block;
  padding-bottom: 12px;
  font-size: 16px;
  font-weight: 600;
  color: var(--confirmation-color);
}
.survey .next-btn-wrapper,
.survey .prev-btn-wrapper {
  z-index: 2;
  display: block;
  text-align: right;
  position: absolute;
  right: 10px;
  bottom: 5px;
  padding-bottom: calc(0px + env(safe-area-inset-bottom, 0px));
}
.survey .next-btn-wrapper .nav-btn,
.survey .prev-btn-wrapper .nav-btn {
  display: inline-block;
  padding: 0 10px;
  height: 30px;
  line-height: 30px;
  font-size: 1.1em;
  background: transparent;
  border: none;
}
.survey .prev-btn-wrapper {
  text-align: left;
  left: 10px;
  right: auto;
}
survey-question {
  display: block;
  padding: 15px;
}
survey-question header {
  display: flex;
  flex-direction: column;
}
survey-question header .question-number {
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 50%;
  margin-bottom: 15px;
  background: var(--background-highlight-color);
}
survey-question header media {
  margin-bottom: 30px;
}
survey-question header .title {
  margin-bottom: 15px;
}
@media only screen and (min-width: 1025px) {
  survey-question header .title {
    margin-bottom: 40px;
  }
}
survey-question header .title h2 {
  margin: 0 0 5px 0;
  word-break: break-word;
}
survey-question .predefined-question .option {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 15px;
  border: 1px solid var(--general-border-color);
}
survey-question .predefined-question .option.disabled span {
  opacity: 0.6;
}
survey-question .predefined-question .option.picked {
  border: 1px solid var(--highlight-color);
  border-left-width: 3px;
}
survey-question .predefined-question .option.picked span {
  color: var(--highlight-color);
  font-weight: 600;
}
survey-question .predefined-question .option:not(:last-of-type) {
  margin-bottom: 10px;
}
survey-question .predefined-question .option:not(.picked) {
  padding-left: 17px;
}
survey-question .predefined-question .option span {
  font-size: 13px;
  transition: font-weight 0.2s;
}
survey-question .predefined-question .option .poll-progress {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 0;
  background: rgba(var(--highlight-color-rgb), 0.15);
  transition: all ease-in-out 0.5s;
}
survey-question .scale-question {
  text-align: center;
}
survey-question .scale-question ul {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  justify-content: space-around;
  flex-wrap: wrap;
}
survey-question .scale-question ul li {
  list-style: none;
  text-align: center;
  width: 20%;
  outline: none;
}
survey-question .scale-question ul li i {
  display: block;
  font-size: 45px;
  color: var(--background-highlight-color);
}
survey-question .scale-question ul li.marked i {
  color: var(--alert-color);
}
survey-question .scale-question ul li .option-text {
  overflow-wrap: break-word;
}
survey-question .text-question textarea {
  width: 100%;
  height: 180px;
  padding: 10px;
  box-sizing: border-box;
  background: rgba(var(--background-highlight-color-rgb), 0.6);
  color: var(--general-text-color);
  border: none;
  resize: none;
}
survey-question .text-question .next-btn-wrapper {
  margin-top: 20px;
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
survey-question .info-question media {
  margin-bottom: 10px;
}
survey-question .info-question .info-question-body {
  margin: 0;
  font-size: 16px;
}
survey-question .enps-question .rzslider {
  margin-top: 25px;
}
survey-question .enps-question .rzslider .rz-bar {
  border-radius: 100px;
  height: 10px;
}
survey-question .enps-question .rzslider .rz-bar:not(.rz-selection) {
  background-color: rgba(var(--general-text-color-rgb), 0.1);
}
survey-question .enps-question .rzslider .rz-bar.rz-selection {
  background-color: var(--warning-color);
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 100%);
}
survey-question .enps-question .rzslider .rz-pointer {
  top: -8px;
  width: 26px;
  height: 26px;
  background: var(--white-color);
  filter: drop-shadow(0px 2px 7px rgba(0, 0, 0, 0.15));
}
survey-question .enps-question .rzslider .rz-pointer:after {
  top: 8px;
  left: 8px;
  width: 10px;
  height: 10px;
  background: var(--warning-color);
}
survey-question .enps-question .rzslider .rz-ticks {
  top: 0;
}
survey-question .enps-question .rzslider .rz-tick {
  top: 28px;
  width: 1px;
  height: 10px;
  border-radius: 5px;
  background: rgba(var(--general-text-color-rgb), 0.15);
}
survey-question .enps-question .rzslider .rz-tick.rz-selected {
  width: 1.5px;
  background: var(--warning-color);
}
survey-question .enps-question .current span {
  font-size: 13px;
  line-height: 16px;
  color: rgba(var(--general-text-color-rgb), 0.4);
}
survey-question .enps-question .labels {
  width: 100%;
  position: relative;
  margin-top: 40px;
}
survey-question .enps-question .labels .first {
  left: 0;
}
survey-question .enps-question .labels .last {
  right: 0;
}
survey-question .enps-question .labels span {
  position: absolute;
  font-size: 13px;
  line-height: 16px;
  color: rgba(var(--general-text-color-rgb), 0.4);
}
.native .survey-breakdown button {
  bottom: 51px;
  bottom: calc(51px + env(safe-area-inset-bottom, 0px));
}
survey-breakdown {
  display: block;
}
.survey-breakdown {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 50px;
  z-index: 2;
  -webkit-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
  transition: all ease-in-out 0.3s;
}
.survey-breakdown.ng-enter,
.survey-breakdown.ng-leave.ng-leave-active {
  opacity: 0;
}
.survey-breakdown.ng-leave,
.survey-breakdown.ng-enter.ng-enter-active {
  opacity: 1;
}
.survey-breakdown .survey-breakdown-inner {
  padding: 15px 15px 0 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  height: 100%;
  box-sizing: border-box;
}
.survey-breakdown h2 {
  margin: 0;
  font-size: 20px;
}
.survey-breakdown .breakdown-percentage {
  margin: 5px 0 0 0;
}
.survey-breakdown ul {
  display: block;
  list-style: none;
  padding: 0;
  margin: 20px 0 10px 0;
}
.survey-breakdown ul li {
  display: block;
  border: 2px solid var(--confirmation-color);
  background: rgba(var(--confirmation-color-rgb), 0.15);
  box-sizing: border-box;
  padding: 10px;
  margin-bottom: 10px;
}
.survey-breakdown ul li.wrong {
  border-color: var(--warning-color);
  background: rgba(var(--warning-color-rgb), 0.15);
}
.survey-breakdown ul li.wrong h4 {
  color: var(--warning-color);
}
.survey-breakdown ul li h4 {
  margin: 0;
  color: var(--confirmation-color);
}
.survey-breakdown ul li p {
  margin: 10px 0 0 0;
}
.survey-breakdown ul li p:first-child {
  margin-top: 15px;
}
.survey-breakdown ul li p .answer-text {
  font-weight: 600;
  color: var(--confirmation-color);
}
.survey-breakdown ul li p .answer-text:first-child {
  margin-left: 5px;
}
.survey-breakdown ul li p .answer-text.wrong-option {
  color: var(--warning-color);
  text-decoration: line-through;
}
.survey-breakdown .submit {
  position: relative;
  bottom: 0;
  margin: 0 -15px;
}
.survey-breakdown .submit rl-button {
  position: relative;
  z-index: 2;
}
.survey-breakdown .submit.submitted:after {
  z-index: 0;
}
.native .survey .survey-poll-container .survey-poll-footer {
  bottom: 51px;
  bottom: calc(51px + env(safe-area-inset-bottom, 0px));
}
.survey .survey-poll-container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding-bottom: 50px;
  padding-bottom: calc(50px + env(safe-area-inset-bottom, 0px));
}
.survey .survey-poll-container ks-swiper-container {
  display: inline;
}
.survey .survey-poll-container .swiper-container {
  width: 100%;
  height: 100%;
  z-index: 1;
}
.survey .survey-poll-container .survey-poll-inner {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 36px;
  top: 0;
  overflow-x: hidden;
  overflow-y: auto;
  box-sizing: border-box;
}
@media only screen and (min-width: 768px) {
  .survey .survey-poll-container .survey-poll-inner {
    padding: 15px calc((100% - 400px) / 2);
  }
}
.survey .survey-poll-container .survey-poll-buttons {
  padding: 15px;
}
.survey .survey-poll-container .survey-poll-outer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}
.survey .survey-poll-container .survey-poll-footer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  bottom: env(safe-area-inset-bottom, 0px);
  height: 36px;
  line-height: 36px;
  text-align: center;
  z-index: 2;
  font-weight: 500;
  font-size: 15px;
}
.survey .survey-poll-container .survey-poll-footer a {
  position: absolute;
  left: 0;
  padding: 0 20px;
  display: block;
  height: 36px;
  line-height: 36px;
  overflow: hidden;
  bottom: 0;
}
.survey .survey-poll-container .survey-poll-footer a.next {
  right: 0;
  left: auto;
}
.survey .survey-poll-container .survey-poll-overlay {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: var(--popup-overlay-bg);
  z-index: 10;
}
.survey .survey-poll-container .survey-poll-new-vote {
  background: var(--confirmation-color);
  color: var(--white-color);
  text-align: center;
  box-sizing: border-box;
  padding: 14px;
  font-size: 16px;
  text-transform: uppercase;
}
survey-finish-popup .action-popup {
  width: calc(100% - var(--s-spacing) * 4);
  max-width: 345px;
}
survey-finish-popup .action-popup-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--m-spacing);
  padding: calc(var(--m-spacing) * 2) var(--spacing);
  text-align: center;
  color: var(--text-on-white-color);
}
survey-finish-popup h2 {
  margin: 0;
  font-size: 1.66667rem;
}
survey-finish-popup p {
  margin: 0;
  font-size: 0.86667rem;
}
survey-finish-popup .img {
  width: 46px;
  height: 46px;
  background-image: url("/Content/Graphics/Survey/clapping_big.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
survey-finish-popup rl-button {
  width: 100%;
  margin: calc(var(--s-spacing) * 2) 0;
}
.colorize.negative {
  background: var(--warning-color);
}
.colorize.neutral {
  background: var(--alert-color);
}
.colorize.positive {
  background: var(--confirmation-color);
}
question-statistic-popup {
  display: block;
  padding: var(--spacing);
}
question-statistic-popup .question-statistic-wrapper {
  display: flex;
  flex-direction: column;
  gap: var(--spacing);
  padding: var(--spacing);
  background: var(--general-background-color);
  border-radius: var(--general-border-radius);
  box-shadow: 0 7px 15px 0 var(--shadow-color);
}
question-statistic-popup h3 {
  margin: 0;
  font-size: 1.06667rem;
  font-weight: 700;
}
question-statistic-popup .text {
  margin: 0;
  font-size: 1.06667rem;
}
question-statistic-popup .groups-list-header {
  display: flex;
  justify-content: space-between;
  padding-bottom: var(--m-spacing);
  margin-bottom: var(--m-spacing);
  border-bottom: 1px solid var(--general-border-color);
}
question-statistic-popup .groups-list-header span {
  font-size: 0.86667rem;
  font-weight: 700;
}
question-statistic-popup .groups-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
question-statistic-popup .groups-list .group-item {
  display: flex;
  justify-content: space-between;
  gap: var(--m-spacing);
  align-items: center;
}
question-statistic-popup .groups-list .group-item .group {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 0.86667rem;
}
question-statistic-popup .groups-list .group-item .score {
  flex-shrink: 0;
  width: 50px;
  height: 24px;
  border-radius: 3px;
  text-align: center;
  line-height: 24px;
  font-size: 1.06667rem;
  font-weight: 700;
}
question-statistic-popup .groups-list .group-item .score.negative {
  background: var(--warning-color);
}
question-statistic-popup .groups-list .group-item .score.neutral {
  background: var(--alert-color);
}
question-statistic-popup .groups-list .group-item .score.positive {
  background: var(--confirmation-color);
}
question-statistic-popup .groups-list .group-item .score.negative {
  background: var(--warning-color);
  color: var(--general-background-color);
}
question-statistic-popup .groups-list .group-item .score.neutral {
  background: var(--alert-color);
  color: var(--general-text-color);
}
question-statistic-popup .groups-list .group-item .score.positive {
  background: var(--confirmation-color);
  color: var(--general-background-color);
}
survey-manager-tab {
  display: flex;
  flex-direction: column;
  gap: var(--spacing);
  padding: var(--spacing);
}
survey-manager-tab .filters-button-wrapper {
  display: flex;
  justify-content: space-between;
}
survey-manager-tab .filters-button-wrapper rl-icon {
  cursor: pointer;
  width: 24px;
  height: 24px;
  font-size: 1rem;
  border-radius: 5px;
}
survey-manager-tab .filters-button-wrapper rl-icon[icon="filters-active"] {
  background: var(--highlight-color);
  color: var(--text-on-highlight-color);
}
survey-manager-tab .direct-reports-widget .satisfaction:not(.shimmer) {
  cursor: pointer;
}
survey-manager-tab .my-departments {
  display: flex;
  flex-direction: column;
  gap: var(--spacing);
}
.colorize.negative {
  background: var(--warning-color);
  color: var(--general-background-color);
}
.colorize.neutral {
  background: var(--alert-color);
  color: var(--general-text-color);
}
.colorize.positive {
  background: var(--confirmation-color);
  color: var(--general-background-color);
}
store-manager-vew header-slot {
  display: flex;
  gap: var(--m-spacing);
  align-items: center;
}
store-manager-vew header-slot .img {
  width: 34px;
  height: 34px;
  filter: drop-shadow(0 7px 5px var(--shadow-color));
}
store-manager-vew header-slot h4 {
  margin: 0;
  font-size: 1.06667rem;
  font-weight: 400;
}
store-manager-vew header-slot .score {
  margin-left: auto;
  margin-right: var(--m-spacing);
  padding: calc(var(--m-spacing) / 3) var(--s-spacing);
  box-shadow: 0 7px 15px 0 var(--shadow-color);
  font-size: 0.86667rem;
  font-weight: 700;
}
store-manager-vew header-slot .score.negative {
  background: var(--warning-color);
}
store-manager-vew header-slot .score.neutral {
  background: var(--alert-color);
}
store-manager-vew header-slot .score.positive {
  background: var(--confirmation-color);
}
store-manager-vew header-slot .score.negative {
  background: var(--warning-color);
  color: var(--general-background-color);
}
store-manager-vew header-slot .score.neutral {
  background: var(--alert-color);
  color: var(--general-text-color);
}
store-manager-vew header-slot .score.positive {
  background: var(--confirmation-color);
  color: var(--general-background-color);
}
store-manager-vew .questions-without-drivers {
  padding: var(--m-spacing) var(--spacing);
  border-radius: var(--general-border-radius);
  box-shadow: 0 7px 15px 0 var(--shadow-color);
  background: var(--general-background-color);
}
store-manager-vew .questions-header {
  display: flex;
  justify-content: space-between;
  padding-bottom: var(--m-spacing);
  margin-bottom: var(--m-spacing);
  border-bottom: 1px solid var(--general-border-color);
}
store-manager-vew .questions-header span {
  font-size: 0.86667rem;
  font-weight: 700;
}
store-manager-vew .questions-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
store-manager-vew .questions-list .question-item {
  display: flex;
  gap: var(--m-spacing);
  justify-content: space-between;
  align-items: center;
}
store-manager-vew .questions-list .question-item .question {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 0.86667rem;
}
store-manager-vew .questions-list .question-item .right {
  display: flex;
  gap: var(--m-spacing);
}
store-manager-vew .questions-list .question-item .score {
  flex-shrink: 0;
  width: 50px;
  height: 24px;
  border-radius: 3px;
  text-align: center;
  line-height: 24px;
  font-size: 1.06667rem;
  font-weight: 700;
}
store-manager-vew .questions-list .question-item .score.negative {
  background: var(--warning-color);
}
store-manager-vew .questions-list .question-item .score.neutral {
  background: var(--alert-color);
}
store-manager-vew .questions-list .question-item .score.positive {
  background: var(--confirmation-color);
}
store-manager-vew .questions-list .question-item .score.negative {
  background: var(--warning-color);
  color: var(--general-background-color);
}
store-manager-vew .questions-list .question-item .score.neutral {
  background: var(--alert-color);
  color: var(--general-text-color);
}
store-manager-vew .questions-list .question-item .score.positive {
  background: var(--confirmation-color);
  color: var(--general-background-color);
}
store-manager-vew .questions-list .question-item rl-icon {
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  padding: 0 var(--s-spacing);
  font-size: 14px;
  cursor: pointer;
  border-radius: 2px;
  background: var(--general-border-color);
}
multiple-department-view-component {
  display: block;
  background: var(--general-background-color);
  overflow: hidden;
  border-radius: var(--general-border-radius);
  box-shadow: 0 7px 15px 0 var(--shadow-color);
}
multiple-department-view-component header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--m-spacing);
  border-bottom: 1px solid var(--general-border-color);
}
multiple-department-view-component header span {
  font-size: 0.73333rem;
  font-weight: 700;
  line-height: 130%;
}
multiple-department-view-component header .sorting {
  cursor: pointer;
}
multiple-department-view-component header .sorting rl-icon {
  color: var(--general-text-inactive-color);
}
multiple-department-view-component header .sorting .descending {
  transform: rotate(180deg);
}
multiple-department-view-component .body {
  display: flex;
  flex-direction: column;
}
multiple-department-view-component department-row:not(:last-of-type) {
  border-bottom: 1px solid var(--general-border-color);
}
multiple-department-view-component department-row .tree-icon {
  color: var(--general-text-inactive-color);
  margin-right: calc(var(--spacing) / 2);
}
multiple-department-view-component department-row .right {
  margin-left: auto;
  display: flex;
}
multiple-department-view-component department-row .right rl-icon {
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  padding: 0 var(--s-spacing);
  margin-right: calc(var(--spacing) / 2);
  font-size: 14px;
  cursor: pointer;
  border-radius: 2px;
  background: var(--general-border-color);
}
multiple-department-view-component department-row .score {
  width: 50px;
  box-sizing: border-box;
  line-height: 24px;
  text-align: center;
  border-radius: 1.5px;
  font-size: 0.86667rem;
  font-weight: 700;
}
multiple-department-view-component department-row .score.negative {
  background: var(--warning-color);
  color: var(--general-background-color);
}
multiple-department-view-component department-row .score.neutral {
  background: var(--alert-color);
  color: var(--general-text-color);
}
multiple-department-view-component department-row .score.positive {
  background: var(--confirmation-color);
  color: var(--general-background-color);
}
multiple-department-view-component department-row:nth-child(even) > .row-content {
  background: var(--background-highlight-color);
}
multiple-department-view-component .row-content {
  display: flex;
  align-items: center;
  padding: var(--m-spacing);
  padding-left: calc(var(--m-spacing) + var(--s-spacing) * 2 * var(--level));
}
multiple-department-view-component .row-content.clickable {
  cursor: pointer;
}
multiple-department-view-component .multiple-department-loader-container .body .row-content:nth-child(even) {
  background: var(--background-highlight-color);
}
multiple-department-view-component .multiple-department-loader-container .body .row-content:nth-child(even) .placeholder-shimmer {
  background-image: linear-gradient(to right, var(--general-background-color) 8%, var(--background-highlight-color) 18%, var(--general-background-color) 33%);
}
multiple-department-view-component .multiple-department-loader-container .body .row-content:not(:last-of-type) {
  border-bottom: 1px solid var(--general-border-color);
}
survey-filters-popup .popup-footer popup-footer {
  display: flex;
  justify-content: space-between;
  gap: var(--spacing);
  padding: var(--spacing);
}
survey-filters-popup .popup-footer popup-footer rl-button {
  flex: 1;
}
participation-rate .participation-rate-widget {
  padding: var(--spacing);
  border-radius: var(--general-border-radius);
  background-color: var(--general-background-color);
  box-shadow: 0 7px 15px 0 var(--shadow-color);
}
participation-rate .participation-rate-widget h2 {
  font-size: 1rem;
  font-weight: 700;
  margin: 0 0 var(--m-spacing);
}
participation-rate .participation-rate-widget .container {
  display: flex;
  gap: calc(var(--spacing) * 2);
}
participation-rate .participation-rate-widget .chart-wrapper {
  position: relative;
}
participation-rate .participation-rate-widget .chart-wrapper .numbers {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
}
participation-rate .participation-rate-widget .chart-wrapper .numbers .lbl {
  font-size: 0.9rem;
}
participation-rate .participation-rate-widget .chart-wrapper .numbers .x {
  font-size: 1.6rem;
  font-weight: 700;
}
participation-rate .participation-rate-widget .chart-wrapper .numbers .of {
  font-size: 0.9rem;
  font-weight: 700;
}
participation-rate .participation-rate-widget .chart-container {
  width: 150px;
  height: 150px;
  flex-shrink: 0;
}
participation-rate .participation-rate-widget .legend {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: var(--spacing);
  overflow: hidden;
}
participation-rate .participation-rate-widget .legend .placeholder-shimmer {
  max-width: 100%;
}
participation-rate .participation-rate-widget .legend span {
  display: flex;
  align-items: center;
  font-size: 0.8rem;
}
participation-rate .participation-rate-widget .legend span.not-started:before {
  background-color: rgba(var(--highlight-color-rgb), 0.25);
}
participation-rate .participation-rate-widget .legend span.completed:before {
  background-color: rgba(var(--highlight-color-rgb), 1);
}
participation-rate .participation-rate-widget .legend span:before {
  content: '';
  display: block;
  width: 9px;
  height: 9px;
  margin-right: var(--m-spacing);
  margin-top: -4.5px;
  border-radius: 50%;
}
overall-satisfaction .satisfaction {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: var(--spacing);
  padding: var(--spacing);
  background-color: var(--general-background-color);
  border-radius: var(--general-border-radius);
  box-shadow: 0 7px 15px 0 var(--shadow-color);
}
overall-satisfaction .satisfaction h2 {
  margin: 0;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.3;
}
overall-satisfaction .satisfaction .body {
  display: flex;
  align-items: center;
  gap: var(--m-spacing);
}
overall-satisfaction .satisfaction .body .img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  box-shadow: 0 7px 15px 0 var(--shadow-color);
  background-size: 34px !important;
  background-repeat: no-repeat;
}
overall-satisfaction .satisfaction .body .right {
  display: flex;
  flex-direction: column;
  gap: calc(var(--s-spacing) / 2);
}
overall-satisfaction .satisfaction .body .score {
  font-size: 1.3rem;
  font-weight: 700;
  line-height: 1;
}
overall-satisfaction .satisfaction rl-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: var(--s-spacing);
  width: 24px;
  height: 24px;
  padding: 0 var(--s-spacing);
  font-size: 14px;
  border-radius: 2px;
  background: var(--general-border-color);
}
overall-satisfaction .satisfaction.negative .img {
  background-color: rgba(var(--warning-color-rgb), 0.2);
}
overall-satisfaction .satisfaction.negative .score {
  color: var(--warning-color);
}
overall-satisfaction .satisfaction.neutral .img {
  background-color: rgba(var(--alert-color-rgb), 0.2);
}
overall-satisfaction .satisfaction.neutral .score {
  color: var(--alert-color);
}
overall-satisfaction .satisfaction.positive .img {
  background-color: rgba(var(--confirmation-color-rgb), 0.2);
}
overall-satisfaction .satisfaction.positive .score {
  color: var(--confirmation-color);
}
overall-satisfaction .satisfaction.na .img {
  background-color: var(--general-border-color);
}
no-data-available {
  display: flex;
  flex-direction: column;
  position: relative;
  gap: var(--m-spacing);
  padding: var(--spacing);
  background: var(--general-background-color);
  border-radius: var(--general-border-radius);
  box-shadow: 0 7px 15px 0 var(--shadow-color);
}
no-data-available .placeholder {
  height: var(--s-spacing);
  border-radius: 100px;
  background: var(--background-highlight-color);
}
no-data-available .icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: calc(var(--m-spacing) / 2);
}
no-data-available .icon rl-icon {
  color: var(--alert-color);
  font-size: 32px;
}
no-data-available .icon > span {
  font-size: 0.86667rem;
  font-weight: 700;
}
survey-data-collection-status {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: var(--spacing);
  gap: var(--m-spacing);
  background: var(--general-background-color);
  border-radius: var(--general-border-radius);
  box-shadow: 0 7px 15px 0 var(--shadow-color);
}
survey-data-collection-status .container {
  display: flex;
  flex-direction: row;
  align-items: center;
}
survey-data-collection-status .container h2 {
  font-size: 1rem;
  font-weight: 700;
  margin: 0;
  width: 70%;
}
survey-data-collection-status .container h3 {
  font-size: 0.9rem;
  font-weight: 700;
  margin: 0;
  width: 30%;
  text-align: right;
}
survey-data-collection-status .container h3.in-progress {
  color: var(--alert-color);
}
survey-data-collection-status .container h3.completed {
  color: var(--confirmation-color);
}
survey-data-collection-status .status-description {
  font-size: 0.9rem;
}
survey-data-collection-status .status-description .status-description-enddate {
  margin: 0;
}
survey-data-collection-status .status-description .status-description-enddate-detailed {
  margin: 0;
  font-size: 0.7rem;
  opacity: 0.5;
}
.survey-question-list {
  display: flex;
  flex-direction: column;
  gap: var(--spacing);
  padding: var(--spacing);
}
.survey-question-list .survey-question {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--m-spacing);
  overflow: hidden;
  box-sizing: border-box;
  min-height: 45px;
  padding: var(--m-spacing);
  border-radius: var(--general-border-radius);
  background: var(--background-highlight-color);
  cursor: pointer;
}
.survey-question-list .survey-question rl-loading-placeholder {
  display: block;
  width: 100%;
}
.survey-question-list .survey-question rl-loading-placeholder .placeholder-shimmer {
  height: 18px;
}
.survey-question-list .survey-question.unread .title {
  font-weight: 600;
}
.survey-question-list .survey-question.unread .comments-count {
  color: var(--highlight-color);
}
.survey-question-list .survey-question.unread .comments-count .count {
  font-weight: 600;
}
.survey-question-list .survey-question.unread .comments-count rl-icon {
  position: relative;
}
.survey-question-list .survey-question.unread .comments-count rl-icon:after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: var(--highlight-color);
  border: 2px solid var(--background-highlight-color);
}
.survey-question-list .survey-question .title {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 1.3;
  font-size: 0.875rem;
}
.survey-question-list .survey-question .comments-count {
  display: flex;
  align-items: center;
  color: var(--general-inactive-color);
}
.survey-question-list .survey-question .comments-count .count {
  min-width: 15px;
  text-align: center;
  font-size: 11px;
  line-height: 1;
}
question-comments-component {
  display: block;
}
question-comments-component .survey-comments-page {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 55px - 50px);
}
@media only screen and (min-width: 768px) {
  question-comments-component .survey-comments-page {
    min-height: calc(100vh - 60px);
  }
}
question-comments-component .survey-comments-page .sentiment-tabs-container {
  position: sticky;
  z-index: 2;
  width: 100%;
  top: 55px;
  padding: 1px 0;
  box-sizing: border-box;
  overflow-x: auto;
  background: var(--general-background-color);
}
@media only screen and (min-width: 768px) {
  question-comments-component .survey-comments-page .sentiment-tabs-container {
    top: 60px;
  }
}
question-comments-component .survey-comments-page .sentiment-tabs-container .rl-tab {
  display: flex;
  gap: calc(var(--m-spacing) / 2);
  height: auto;
  padding: var(--spacing) var(--s-spacing) var(--m-spacing) var(--s-spacing);
  text-wrap: nowrap;
}
question-comments-component .survey-comments-page .sentiment-tabs-container .rl-tab.active {
  border-bottom: 2px solid var(--highlight-color);
}
question-comments-component .survey-comments-page .sentiment-tabs-container .rl-tab img.sentimental-emoji {
  width: 20px;
  height: 20px;
}
question-comments-component .survey-comments-page .survey-questions-list {
  padding: var(--spacing) var(--spacing) 0 var(--spacing);
}
question-comments-component .survey-comments-page .survey-question-container {
  margin: var(--m-spacing) 0;
}
question-comments-component .survey-comments-page .survey-question-container .title {
  font-size: 16px;
  font-weight: 600;
}
question-comments-component .survey-comments-page .survey-question-container .placeholder-shimmer {
  height: 16px;
  margin-bottom: var(--s-spacing);
}
question-comments-component .survey-comments-page .comments {
  margin-top: var(--spacing);
}
question-comments-component .survey-comments-page .comments .comment-card-container .loader-comment-header {
  margin-bottom: var(--m-spacing);
}
question-comments-component .survey-comments-page .comments .comment-card-container .loader-comment-header .placeholder-shimmer {
  height: 55px;
  margin-bottom: var(--s-spacing);
}
question-comments-component .survey-comments-page .comments .comment-card-container .placeholder-shimmer {
  height: 16px;
  margin-bottom: var(--s-spacing);
}
question-comments-component .survey-comments-page .arrow-buttons {
  position: sticky;
  display: flex;
  margin-top: auto;
  padding: calc(var(--m-spacing) * 3) var(--spacing) calc(var(--m-spacing) * 2) var(--spacing);
  bottom: 50px;
  background-image: linear-gradient(0deg, var(--general-background-color) 54%, rgba(var(--general-background-color-rgb), 0.5) 75%, transparent 100%);
}
@media only screen and (min-width: 768px) {
  question-comments-component .survey-comments-page .arrow-buttons {
    bottom: 0;
  }
}
question-comments-component .survey-comments-page .arrow-buttons .question-counter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
  color: var(--general-text-inactive-color);
  font-size: 0.6875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  /* 0.6875rem */
}
question-comments-component .survey-comments-page .arrow-buttons prev-next-buttons {
  margin-left: auto;
  filter: drop-shadow(0px 4px 8px var(--shadow-color));
}
comment-card-component:last-of-type .comment-card-container {
  margin-bottom: 0;
}
.comment-card-container {
  border-radius: 8px;
  margin-bottom: var(--spacing);
  padding: var(--spacing);
  border: 1px solid var(--general-border-color);
  background-clip: padding-box;
}
.comment-card-container.negative {
  background-image: linear-gradient(180deg, rgba(var(--warning-color-rgb), 0.3) 0%, var(--general-background-color) 38%);
}
.comment-card-container.positive {
  background-image: linear-gradient(180deg, rgba(var(--confirmation-color-rgb), 0.3) 0%, var(--general-background-color) 38%);
}
.comment-card-container.mixed {
  background-image: linear-gradient(180deg, rgba(var(--alert-color-rgb), 0.3) 0%, var(--general-background-color) 38%);
}
.comment-card-container .loader-comment-header {
  height: 55px;
}
.comment-card-container .comment-card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--m-spacing);
}
.comment-card-container .comment-card-header .comment-poster-info {
  display: flex;
  align-items: center;
  gap: calc(var(--m-spacing) / 2);
}
.comment-card-container .comment-card-header .comment-poster-info .profile-pic .profile-image {
  width: 35px;
  height: 35px;
}
.comment-card-container .comment-card-header .comment-poster-info .comment-is-new {
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: var(--highlight-color);
}
.comment-card-container .comment-card-header .comment-poster-info .comment-post-details {
  line-height: 1.56rem;
}
.comment-card-container .comment-card-header .comment-poster-info .comment-post-details .comment-post-date {
  color: var(--general-text-inactive-color);
}
.comment-card-container .comment-card-header .sentimental-emoji-icon .sentimental-emoji {
  width: 34px;
  height: 34px;
}
.comment-card-container .divider {
  height: 1px;
  background-color: var(--general-border-color);
}
.comment-card-container .comment-card-body {
  margin-top: var(--m-spacing);
}
.survey-module-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.survey-module-list survey-list {
  display: block;
}
.survey-list {
  list-style-type: none;
  list-style-position: inside;
  position: relative;
  margin: 0;
  padding: var(--spacing) var(--spacing) 0 var(--spacing);
}
.survey-list .survey-list-item {
  display: block;
  margin-bottom: var(--spacing);
}
.survey-list .survey-list-item .survey-list-item-inner {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  height: 90px;
  border-radius: var(--general-border-radius);
  background-color: var(--background-highlight-color);
  background-size: cover;
}
.survey-list .survey-list-item .survey-list-item-inner h3 {
  font-weight: bold;
  font-size: 15px;
  color: var(--general-text-color);
  margin: 0;
  padding: 0 var(--spacing);
  position: relative;
  z-index: 2;
}
.survey-list .survey-list-item .survey-list-item-inner .title-placeholder {
  width: 70%;
  height: 20px;
  display: block;
}
.survey-list .survey-list-item .survey-list-item-inner rl-badge {
  position: absolute;
  right: 0;
  margin-right: var(--m-spacing);
}
