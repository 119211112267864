chat-members {
    .chat-members-content {
        display: flex;
        flex-direction: column;
        flex: 1;
        overflow: hidden;
    }

    header-popup {
        .title {
            h1 {
                font-weight: 700;
            }

            h2 {
                color: rgba(var(--header-text-color-rgb), 0.4);
                font-weight: 400;
            }
        }
    }
}