survey-manager-tab {
    display: flex;
    flex-direction: column;
    gap: var(--spacing);
    padding: var(--spacing);

    .filters-button-wrapper {
        display: flex;
        justify-content: space-between;

        rl-icon {
            cursor: pointer;
            width: 24px;
            height: 24px;
            font-size: 1rem;
            border-radius: 5px;

            &[icon="filters-active"] {
                background: var(--highlight-color);
                color: var(--text-on-highlight-color);
            }
        }
    }

    .direct-reports-widget {
        .satisfaction:not(.shimmer) {
            cursor: pointer;
        }
    }


    .my-departments {
        display: flex;
        flex-direction: column;
        gap: var(--spacing);
    }
}