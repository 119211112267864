question-comments-component {
    display: block;

    .survey-comments-page {
        display: flex;
        flex-direction: column;
        min-height: calc(100vh - 55px - 50px);

        @media @tablet-up {
            min-height: calc(100vh - 60px);
        }

        .sentiment-tabs-container {
            position: sticky;
            z-index: 2;
            width: 100%;
            top: 55px;
            padding: 1px 0;
            box-sizing: border-box;
            overflow-x: auto;

            background: var(--general-background-color);

            @media @tablet-up {
                top: 60px;
            }

            .rl-tab {
                display: flex;
                gap: calc(var(--m-spacing) / 2);
                height: auto;
                padding: var(--spacing) var(--s-spacing) var(--m-spacing) var(--s-spacing);
                text-wrap: nowrap;

                &.active {
                    border-bottom: 2px solid var(--highlight-color);
                }

                img.sentimental-emoji {
                    width: 20px;
                    height: 20px;
                }
            }
        }

        .survey-questions-list {
            padding: var(--spacing) var(--spacing) 0 var(--spacing);
        }

        .survey-question-container {
            margin: var(--m-spacing) 0;

            .title {
                font-size: 16px;
                font-weight: 600;
            }

            .placeholder-shimmer {
                height: 16px;
                margin-bottom: var(--s-spacing);
            }
        }

        .comments {
            margin-top: var(--spacing);

            .comment-card-container {
                .loader-comment-header {
                    margin-bottom: var(--m-spacing);

                    .placeholder-shimmer {
                        height: 55px;
                        margin-bottom: var(--s-spacing);
                    }
                }

                .placeholder-shimmer {
                    height: 16px;
                    margin-bottom: var(--s-spacing);
                }
            }
        }

        .arrow-buttons {
            position: sticky;
            display: flex;
            margin-top: auto;
            padding: calc(var(--m-spacing) * 3) var(--spacing) calc(var(--m-spacing) * 2) var(--spacing);
            bottom: 50px;

            background-image: linear-gradient(0deg, var(--general-background-color) 54%, rgba(var(--general-background-color-rgb), 0.5) 75%, transparent 100%);

            @media @tablet-up {
                bottom: 0;
            }

            .question-counter {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translateX(-50%);

                color: var(--general-text-inactive-color);
                font-size: 0.6875rem;
                font-style: normal;
                font-weight: 400;
                line-height: 1; /* 0.6875rem */
            }

            prev-next-buttons {
                margin-left: auto;
                filter: drop-shadow(0px 4px 8px var(--shadow-color));
            }
        }
    }
}

