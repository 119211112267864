@import "../../Import.less";

.todo-module {

    .todo-module-inner {
        padding: 10px;
        position: relative;
        z-index: 3;

        .calendar {
            margin-bottom: 20px;
        }

        .links {
            button {
                display: block;
                height: 46px;
                border: none;
                border-bottom: 1px solid rgba(0,0,0,0.18);
                position: relative;
                overflow: hidden;
                line-height: 46px;
                text-transform: uppercase;
                font-weight: 600;
                padding: 0 5px;
                margin: 0;
                box-sizing: border-box;
                background: transparent;
                width: 100%;
                text-align: left;

                &:before {
                    position: absolute;
                    height: 46px;
                    line-height: 46px;
                    content: "\f054"; // fa-chevron-right
                    font-family: @icon-fa;
                    right: 5px;
                }

                &:first-child {
                    border-top: 1px solid rgba(0,0,0,0.18);
                }
            }
        }

        .todo-module-footer-image {
            margin: 20px 0 0 0;
        }
    }
}
