@import "../Import.less";

.home {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-color: @home-background-color;

    &.has-news-ticker {
        top: @news-ticker-height;

        @media @tablet-max {
            top: @news-ticker-height-mobile;
        }
    }

    &.badge-corner .tiles .tile rl-badge {
        top: 5px;
        right: 5px;
        left: auto;
        margin: 0;
    }

    .background-overlay {
        background: rgba(0, 0, 0, 0.4);
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        overflow-x: auto;
        overflow-y: hidden;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        padding: 20px;
        z-index: 2;
    }

    .home-backgrounds {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;

        > div > div {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background-position: center center;
            background-size: cover;
            background-repeat: no-repeat;
            opacity: 0;

            &.starter {
                opacity: 1;
            }

            &.back-video {
                opacity: 1;

                video {
                    object-fit: cover;
                }
            }
        }
    }

    .tiles {
        width: 100%;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 3;
        overflow-y: auto;

        .tile-row {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            height: 25%;
            width: 100%;
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
            -webkit-flex-direction: row;
            -ms-flex-direction: row;
            flex-direction: row;
            margin: 0;
            padding: 0;
            border-bottom: 1px solid @home-tile-border-color;
        }

        .tile {
            display: block;
            color: #fff;
            font-size: 14px;
            overflow: hidden;
            text-align: center;
            cursor: pointer;
            text-decoration: none;
            -webkit-box-flex: 1;
            -webkit-flex: 1 0 auto;
            -ms-flex: 1 0 auto;
            flex: 1 0 auto;
            position: relative;
            -moz-box-sizing: border-box;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            border-right: 1px solid @home-tile-border-color;
            height: 100%;
            width: 50%;
            background-position: center center;
            background-size: cover;
            background-repeat: no-repeat;

            .tile-inner-container {
                position: relative;
                height: 100%;
                width: 100%;
                margin: 0 auto;
            }

            &:last-child {
                border: none;
            }

            i {
                display: block;
                font-size: 26px;
                position: absolute;
                top: 50%;
                left: 50%;
                margin-top: -40px;
                margin-left: -25px;
                height: 50px;
                line-height: 50px;
                width: 50px;
                text-align: center;
                //opacity: .7;
            }

            rl-badge {
                position: absolute;
                left: 50%;
                top: 50%;
                margin-top: -40px;
                margin-left: 4px;
            }

            .text {
                position: absolute;
                left: 0;
                right: 0;
                top: 50%;
                margin-top: 10px;
                text-align: center;
                font-size: 14px;
                font-weight: 300;
                text-transform: uppercase;
            }

            &:active,
            &:hover {
                background-color: rgba(220, 220, 220, 0.5);
            }

            &.icon-tile {
                .text {
                    display: none;
                }

                i {
                    margin-top: -25px;
                }

                .icon-image-container {
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    position: absolute;
                    -webkit-box-align: center;
                    align-items: center;
                    -webkit-box-pack: center;
                    justify-content: center;
                    left: 20px;
                    top: 20px;
                    bottom: 20px;
                    right: 20px;

                    > img {
                        max-width: 100%;
                        max-height: 100%;
                        //opacity: .7;
                    }
                }
            }

            &:not(.icon-tile) {
                .icon-image-container {
                    display: block;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    margin-top: -40px;
                    margin-left: -25px;
                    height: 50px;
                    line-height: 50px;
                    width: 50px;
                    text-align: center;

                    > img {
                        max-width: 100%;
                        max-height: 100%;
                        //opacity: .7;
                    }
                }
            }
        }
    }
}

.native {
    .home .tiles {
        padding-bottom: 50px;
    }
}

.home-landingpage {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;

    &.has-news-ticker {
        top: @news-ticker-height-mobile;

        @media @desktop-up {
            top: @news-ticker-height;
        }
    }
}
