training-overview {
    .actions {
        background: var(--header-background-color);
        color: var(--header-text-color);
        border-bottom: none;

        h1 {
            text-align: center;
            margin: 0 0 0 -38px !important;
        }

        .action-close {
            color: var(--header-text-color);
        }
    }

    .training-overview-header {
        padding: 15px;
        background: var(--general-background-color);

        .header-profile {
            display: flex;
            margin-bottom: 15px;

            profile-image {
                padding-right: 10px;

                .profile-image {
                    width: 50px;
                    height: 50px;
                }
            }

            .profile-info {
                display: flex;
                flex-direction: column;

                .name {
                    font-weight: 600;
                    font-size: 18px;
                    color: @clr-bodytext;
                }

                .department {
                    font-size: 15px;
                    color: var(--general-text-color);
                    opacity: 0.5;
                }
            }
        }

        .results {
            display: flex;

            .completion, .performance {
                position: relative;
                flex-basis: 50%;
                height: 36px;
                margin-right: 15px;
                text-align: center;
                line-height: 36px;
                font-weight: 600;

                .training-progress-completion {
                    display: flex;
                    justify-content: center;
                    align-content: center;

                    > div {
                        &:nth-child(1){
                            margin-left:5px;
                        }
                    }
                }

                span {
                    position: absolute;
                    left: 0;
                    top: 0;
                    height: 100%;
                }
            }

            .completion {
                color: @clr-highlight;
                border: 1px solid @clr-highlight;

                span {
                    background: fade(@clr-highlight, 20);
                }
            }

            .performance {
                color: @clr-confirmation;
                border: 1px solid @clr-confirmation;

                span {
                    background: fade(@clr-confirmation, 20);
                }
            }
        }
    }

    overview-table {
        display: flex;
        flex-direction: column;
        flex: 1;
        overflow: hidden;
    }
}
