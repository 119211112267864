about-relesys {
    display: block;
    max-width: 600px;
    margin: 0 auto;

    .header-image {
        height: 210px;
        background-image: url("/Content/Graphics/about-relesys-header-v2.png");
        background-position: top center;
        background-size: cover;
    }

    section {
        padding: 20px 15px;
    }

    h1 {
        padding-bottom: 15px;
        margin: 0;
        font-size: 20px;
        font-weight: 600;
        color: var(--general-text-color);
    }

    p {
        margin: 0 0 20px 0;
        line-height: 1.4;
        font-size: 14px;
        color: var(--general-text-color);
    }

    a {
        text-decoration: none;
        font-weight: 600;
        color: var(--highlight-color);
    }

    .logo {
        height: 50px;
        width: 100%;
        max-width: 190px;

        svg {
            stroke-width: 0;

            path {
                fill: var(--general-text-color);
            }

            path[fill="#EA6F63"] {
                fill: rgb(234, 111, 99);
            }
        }
    }
}
