my-progress {
    .point-container {
        display: block;
        text-align: center;
        border-bottom: ~'1px solid rgba(var(--general-text-color-rgb), .1)';

        h2 {
            display: block;
            margin: 0;
            padding-top: 15px;
            font-size: 36px;
            font-weight: bold;
        }

        h3 {
            display: block;
            margin: 5px 0 30px 0;
            font-size: 15px;
            color: ~'rgba(var(--general-text-color-rgb), .5)';
            text-transform: uppercase;
        }
    }

    training-badges {
        display: block;
        padding: 15px;
    }
}
