state-icon {
    border-radius: 50%;

    rl-icon {
        width: 30px;
        height: 30px;

        border-radius: 50%;

        &.new {
            background: var(--highlight-color);
            color: var(--white-color);
            font-size: 17px;

            span {
                position: relative;
                top: -1px;
            }
        }

        &.deadline {
            background: var(--warning-color);
            color: var(--white-color);
            font-size: 18px;
        }
        
        &.not-passed {
            background: var(--alert-color);
            color: var(--white-color);
            font-size: 18px;
        }

        &.passed {
            background: var(--confirmation-color);
            color: var(--white-color);
        }

        &.locked {
            background: ~'rgba(var(--general-text-color-rgb), 0.4)';
            color: var(--general-background-color);
            backdrop-filter: blur(10px);
            font-size: 14px;

        }

        &.expired {
            color: var(--white-color);
            background-color: var(--general-text-color);
            font-size: 16px;
        }
    }
}
