overall-satisfaction {
    .satisfaction {
        position: relative;
        
        display: flex;
        flex-direction: column;
        gap: var(--spacing);
        padding: var(--spacing);

        background-color: var(--general-background-color);
        border-radius: var(--general-border-radius);
        box-shadow: 0 7px 15px 0 var(--shadow-color);

        h2 {
            margin: 0;
            font-size: 1rem;
            font-weight: 700;
            line-height: 1.3;
        }

        .body {
            display: flex;
            align-items: center;
            gap: var(--m-spacing);

            .img {
                width: 50px;
                height: 50px;
                border-radius: 50%;
                box-shadow: 0 7px 15px 0 var(--shadow-color);

                background-size: 34px !important;
                background-repeat: no-repeat;
            }

            .right {
                display: flex;
                flex-direction: column;
                gap: calc(var(--s-spacing) / 2);
            }

            .score {
                font-size: 1.3rem;
                font-weight: 700;
                line-height: 1;
            }
        }

        rl-icon {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: var(--s-spacing);

            width: 24px;
            height: 24px;
            padding: 0 var(--s-spacing);
            font-size: 14px;
            border-radius: 2px;
            background: var(--general-border-color);
        }

        &.negative {
            .img {
                background-color: rgba(var(--warning-color-rgb), 0.2);
            }

            .score {
                color: var(--warning-color);
            }
        }

        &.neutral {
            .img {
                background-color: rgba(var(--alert-color-rgb), 0.2);
            }

            .score {
                color: var(--alert-color);
            }
        }

        &.positive {
            .img {
                background-color: rgba(var(--confirmation-color-rgb), 0.2);
            }

            .score {
                color: var(--confirmation-color);
            }
        }

        &.na {
            .img {
                background-color: var(--general-border-color);
            }
        }
    }
}
