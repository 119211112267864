message-viewers {
    .message-viewers {
        min-height: 100%;

        .popup-content {
            background: var(--general-background-color);
        }

        .viewers-list {
            flex: 1;
            padding: 0;
            margin: 0;
            overflow-y: auto;
            list-style: none;

            li {
                padding: 10px 15px;
                margin: 0;
                display: flex;
                justify-content: left;
                align-items: center;

                .profile-image {
                    width: 36px;
                    height: 36px;
                    min-width: 36px;
                }

                .info {
                    display: flex;
                    flex: 1;
                    justify-content: space-between;
                    align-items: center;
                    
                    margin-left: 10px;
                }

                .left {
                    display: flex;
                    flex-direction: column;
                    max-width: calc(100vw - 200px);
                }

                .name, 
                .department {
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
                .name {
                    font-size: 15px;
                    font-weight: 500;
                    color: var(--general-text-color);
                }

                .department {
                    font-size: 13px;
                    opacity: 0.5;
                }

                .date {
                    font-size: 12px;
                    text-align: right;
                    min-width: 100px;
                    white-space: nowrap;
                }
            }
        }
    }
}

