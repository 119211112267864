@import "../Import.less";

@login-box-icon-size: 100px;
@login-box-icon-size-half: (@login-box-icon-size / 2);

.login-view {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    .flex-center-center();
    transition: all ease-in-out .5s;
    box-sizing: border-box;
    background: rgba(0, 0, 0, .3);
    padding: 15px;
    box-sizing: border-box;

    &.ng-enter,
    &.ng-leave.ng-leave-active {
        opacity: 0;
    }

    &.ng-leave,
    &.ng-enter.ng-enter-active {
        opacity: 1;
    }

    .login-view-logo {
        margin: 10px 0 40px 0;

        img {
            display: inline-block;
            margin: 0 auto;
            height: auto;
            max-height: 60px;
            width: auto;
            max-width: 100%;
            vertical-align: middle;
        }
    }

    .login-view-box {
        width: 100%;
        display: block;
        box-sizing: border-box;
        max-width: 400px;
        text-align: center;
        height: 320px;
        position: relative;

        .login-view-box-content {
            transition: opacity ease-in-out .5s;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 2;
            opacity: 1;
            width: 100%;
            min-height: 100px;
            border-radius: 5px;
            background: #fff;
            padding: 10px 30px;
            box-sizing: border-box;
            .flex-center-center();

            &.reset-pass {
                opacity: 0;
                z-index: 1;

                &.flip {
                    opacity: 1;
                    z-index: 2;
                }
            }

            &.flip {
                opacity: 0;
                z-index: 1;
            }
        }
    }

    .login-invalid {
        > i {
            font-size: 100px;
            color: @error-darker-color;
            margin: 0 0 15px 0;
        }

        h2 {
            margin: 0 0 15px 0;
        }

        p {
            margin: 0 0 15px 0;
        }
    }

    .login-form {
        width: 100%;

        .input-container {
            display: block;
            width: 100%;
            border-bottom: 1px solid rgba(0, 0, 0, .12);
            height: 40px;
            line-height: 40px;
            .flex-container-row();

            label {
                width: 30px;
                height: 40px;
                line-height: 40px;
                text-align: center;
                font-size: 20px;
                color: rgba(0, 0, 0, .24);
                display: block;
            }

            input {
                display: block;
                flex: 1 1 auto;
                padding: 0 5px;
                height: 40px;
                line-height: 40px;
                border: none;
                background: none;
                margin: 0 0 0 5px;
                box-sizing: border-box;
                font-size: 14px;
            }
        }

        button[type=submit] {
            margin: 30px 0 20px 0;
            display: block;
            width: 100%;
            .box-shadow-1();
            position: relative;
            overflow: hidden;
        }
    }

    .btn-link {
        display: block;
        width: 100%;
        text-align: center;
        margin: -10px 0 0 0;
        line-height: 30px;
        overflow: hidden;
        position: relative;
        bottom: -25px;
        font-weight: 600;
        text-transform: uppercase;
        font-size: 13px;
        border: 0;
        padding: 0;
        background: none;
    }

    .loader {
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        .flex-center-center();
        background: rgba(255, 255, 255, 0.4);
        transition: all ease-in-out .3s;

        &.ng-enter,
        &.ng-leave.ng-leave-active {
            opacity: 0;
        }

        &.ng-leave,
        &.ng-enter.ng-enter-active {
            opacity: 1;
        }
    }
}