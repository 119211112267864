files-preview {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 10px;
    overflow-x: auto;

    .file {
        position: relative;

        box-sizing: border-box;
        width: 150px;
        height: 80px;

        margin-right: 10px;
        padding: 10px 5px;

        border-left: 6px solid;
        background-color: rgba(0, 0, 0, 0.1);
        background-size: cover;

        &.image {
            border-color: rgb(243, 165, 96);
            background-color: rgba(243, 165, 96, 0.3);

            color: white;

            &:before {
                content: '';
                display: block;
                position: absolute;
                background: rgba(0, 0, 0, 0.6);
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
            }
        }
        &.video {
            border-color: rgb(130, 78, 210);
            background-color: rgba(130, 78, 210, 0.3);
        }
        &.pdf {
            border-color: rgb(203, 6, 6);
            background-color: rgba(203, 6, 6, 0.3);
        }
        &.spreadsheet {
            border-color: rgb(26, 115, 67);
            background-color: rgba(26, 115, 67, 0.3);
        }
        &.document {
            border-color: rgb(44, 88, 152);
            background-color: rgba(44, 88, 152, 0.3);
        }
        &.presentation {
            border-color: rgb(204, 72, 46);
            background-color: rgba(204, 72, 46, 0.3);
        }

        .content {
            position: relative;
            z-index: 2;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: flex-start;
            height: 100%;
        }

        .delete-file {
            position: absolute;
            top: 5px;
            right: 5px;
        }

        span, a {
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-weight: 600;

            &.file-name {
                padding-bottom: 5px;
            }

            &.file-type {
                font-size: 13px;
                width: calc(~'100% - 15px');
            }
        }

        a {
            color: #62c0eb;
        }

        &:after {
            content: '';
            display: block;
            position: absolute;
            bottom: 0;
            right: 0;

            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 0 20px 20px;
            border-color: transparent transparent #ffffff transparent;
        }
    }
}