@font-face {
    font-family: 'OpenDyslexic';
    src: url('/Content/Fonts/OpenDyslexic2/OpenDyslexic-BoldItalic.woff2') format('woff2'),
        url('/Content/Fonts/OpenDyslexic2/OpenDyslexic-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'OpenDyslexic';
    src: url('/Content/Fonts/OpenDyslexic2/OpenDyslexic-Italic.woff2') format('woff2'),
        url('/Content/Fonts/OpenDyslexic2/OpenDyslexic-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'OpenDyslexic';
    src: url('/Content/Fonts/OpenDyslexic2/OpenDyslexic-Regular.woff2') format('woff2'),
        url('/Content/Fonts/OpenDyslexic2/OpenDyslexic-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'OpenDyslexic';
    src: url('/Content/Fonts/OpenDyslexic2/OpenDyslexic-Bold.woff2') format('woff2'),
        url('/Content/Fonts/OpenDyslexic2/OpenDyslexic-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

