catalog-search-list-component {
    rl-search-result-item {
        margin: 0 0 15px;
    }
    
    .search-list {
        display: flex;
        flex-direction: column;
        position: relative;
        box-sizing: border-box;
    }
    
    .search-item {
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
        padding: 5px;
        border-radius: 7px;
        background-color: var(--background-highlight-color);
    }
    
    .info {
        display: flex;
        position: relative;
        flex-direction: row;
        padding-bottom: 10px;
        border-bottom: 1px solid var(--general-border-color);
    }
    
    .wrapper {
        flex: 1;
        margin-right: 10px;
        min-height: 70px;
        min-width: 70px;
        position: inherit;
    }
    
    .item-details {
        flex: 2;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 5px;  
    }
    
    .breadcrumbs {
        padding-top: 0;
        margin-bottom: 10px;
    }
}