.landing-page-content {
    .title-wrapper {
        display: flex;

        rl-badge {
            margin-left: 10px;
        }
    }

    .no-posts {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        justify-content: center;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
        -webkit-box-align: center;
        align-items: center;
        height: 100%;
        padding: 10px;
        text-align: center;

        h3 {
            font-size: 16px;
            font-weight: normal;
            max-width: 200px;
            text-align: center;
            margin: 0 0 15px;
        }

        button {
            text-transform: uppercase;
            border: 2px solid var(--button-background-color);
            padding: 8px 10px;
            margin-top: 15px;
        }
    }

    .content-list {
        display: -webkit-box;
        display: flex;
        -webkit-box-pack: center;
        justify-content: center;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
        position: absolute;
        left: 10px;
        top: 10px;
        right: 10px;
        bottom: 10px;

        &.list-view {
            justify-content: flex-start;

            .head {
                display: flex;
                padding: 5px 0;
                flex-shrink: 0;
                justify-content: space-between;

                h4 {
                    margin: 10px 0;
                    font-size: 22px;
                }
            }

            .content-slide-breadcrumb {
                display: flex;
                align-items: baseline;
                margin-bottom: 10px;
                font-size: 12px;

                .dot-separator {
                    padding: 0 3px;
                    color: grey;
                }
            }

            .content-list-items {
                overflow-y: auto;
            }

            .content-list-item-wrapper {
                cursor: pointer;
                padding: 10px;
                margin-bottom: 10px;
                background: @color-background-light;
            }

            .content-list-item {
                max-height: 100px;
                overflow: hidden;

                h4 {
                    margin: 0 0 10px;
                }
            }
        }

        .categories {
            width: 100%;
            display: flex;
            overflow-x: auto;
            padding: 0 0 var(--spacing) 0;
            min-height: 50px;
            
            button {
                display: flex;
                align-items: center;
                padding: var(--m-spacing);
                white-space: nowrap;
                background: var(--general-background-color);
                color: var(--general-text-color);

                &:not(:last-of-type) {
                    margin-right: var(--m-spacing);
                }

                &.selected {
                    background: var(--highlight-color);
                    color: var(--text-on-highlight-color);
                }

                rl-badge {
                    margin-left: var(--m-spacing);
                }
            }
        }
        
        .headline {
            display: block;
            font-size: 16px;
            margin: 0 0 5px 0;
            padding: 0;
            -webkit-box-flex: 0;
            flex: 0 1 auto;
            text-transform: uppercase;
        }

        ks-swiper-container {
            width: 100%;
            -webkit-box-flex: 1;
            flex: 1 auto;
            position: relative;

            .swiper-container {
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
            }
        }
        
        .content-label {
            font-weight: 700;
            padding: var(--s-spacing);
            border-radius: var(--general-border-radius);
            display: inline-flex;
            margin-bottom: var(--m-spacing);

            &.unread {
                background-color: var(--highlight-color);
                color: var(--text-on-highlight-color);
            }
        }

        .content-slide {
            .content-slide-wrapper {
                position: absolute;
                top: 0;
                bottom: 25px;
                overflow: hidden;
            }

            .content-slide-top {
                height: auto;
                overflow: hidden;
                position: relative;

                h4 {
                    margin: 0;
                    padding: 0;
                    font-size: 14px;
                }

                .content-slide-breadcrumb {
                    display: block;
                    line-height: 20px;
                    text-transform: uppercase;
                    font-size: 10px;

                    > div {
                        vertical-align: top;
                        height: 20px;
                        line-height: 20px;
                        display: inline-block;

                        span {
                            color: #c2c2c2;
                        }

                        p {
                            padding: 0;
                            margin: 0 10px 0 5px;
                        }

                        i {
                            color: #c2c2c2;
                            font-size: 13px;
                            position: relative;
                            top: -1px;
                        }

                        span, p, i {
                            display: inline-block;
                            vertical-align: top;
                            height: 20px;
                            line-height: 20px;
                            font-weight: 400;
                        }
                    }
                }
            }

            .content-slide-content {
                position: relative;
                top: 5px;
                padding-right: 15px;
                overflow: hidden;
                font-size: 13px;

                p {
                    margin: 0;
                    padding: 0;
                }

                /*
                &:before {
                    content: '';
                    width: 100%;
                    height: 100%;    
                    position: absolute;
                    left: 0;
                    top: 0;
                    background: -webkit-linear-gradient(transparent 30px, white);
                    background: linear-gradient(transparent 30px, white);
                }*/
            }

            .content-slide-read-more {
                position: absolute;
                bottom: 0;
                left: 0;
                opacity: .8;

                button {
                    cursor: pointer;
                    background: none;
                    border: none;
                    padding: 0;
                    text-transform: uppercase;
                    font-size: 13px;
                    height: 20px;
                    line-height: 20px;
                    border-bottom: 1px solid #000;
                }
            }
        }
    }
}
