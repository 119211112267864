assignees-info-popup {
    action-sheet-custom {
        .actionsheet {
            max-height: 80vh;
            overflow: auto;
            color: var(--elevated-text-color);
            padding-top: 0;
        }
    }

    p {
        margin: 0;
    }

    .section:last-child{
        margin-bottom: calc(var(--m-spacing) * 5);
    }
    

    .title {
        font-size: 1.3rem;
        font-weight: 600;
        line-height: 120%;
        margin-top: var(--spacing);
        margin-bottom: var(--s-spacing);
    }

    .text {
        font-size: 1rem;
        line-height: 130%;
        margin-bottom: var(--spacing);
    }
    
    .lock {
        font-size: 10.4px;
        width: 10.4px;
    }

    .demo-card {
        position: relative;
            display: block;
            padding: var(--m-spacing);
            box-sizing: border-box;
            cursor: pointer;
            background: var(--background-highlight-color);
            border-radius: var(--general-border-radius);
            margin-bottom: calc(4*var(--m-spacing));

            @media @desktop-up {
                flex-basis: 49%;
                min-height: 100px;
            }

        .assignees {
            width: 61px;
            height: 61px;
            border: 4px solid red;
            border-radius: 50%;
            position: absolute;
            right: 10px;
            top: 75%;
            transform: translateY(-50%);
            background-color: var(--white-color);
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;
            box-shadow: 0px 7px 25px 0px rgba(0, 0, 0, 0.25);

            span {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 25px;
                height: 25px;
                border-radius: 50%;
                color: var(--text-on-white-color);
                background: var(--white-color);
                box-sizing: border-box;
                box-shadow: 0 6px 7px 0 ~'rgba(var(--general-text-color-rgb), .1)';
                border: 1px solid var(--background-highlight-color);
                background-position: center center;
                background-size: cover;
                background-repeat: no-repeat;

                &:not(:last-of-type) {
                    margin-right: -5px;
                }
                
                &:last-of-type img {
                    border-radius: 1440px;
                    border: 3px solid var(--background-highlight-color);
                }
            }
        }

            .task-icons-container {
                display: inline;
                position: absolute;
                right: 0;
            }

            .task {
                height: 100%;
                position: relative;
                z-index: 2;
                display: flex;
                flex-direction: row;
                justify-content: center;
                box-sizing: border-box;
                background-size: cover;

                .label {
                    position: relative;
                    z-index: 2;
                    align-self: flex-start;
                    padding: 5px;
                    font-size: 1.06667rem;
                    font-weight: 700;
                }

                .content {
                    flex: 1;
                    z-index: 2;
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: flex-start;
                    overflow: hidden;
                }

                .task-text {
                    overflow: hidden;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    margin-left: var(--m-spacing);

                    .top {
                        display: flex;
                        justify-content: space-between;

                        .label {
                            box-sizing: border-box;
                            overflow: hidden;
                            white-space: nowrap;
                            max-width: 120px;
                            text-overflow: ellipsis;
                            padding: 5px;
                            font-size: 13px;
                        }

                        .task-title {
                            font-weight: 700;
                            font-size: 1.06667rem;
                            word-break: break-word;
                            line-height: 21px;
                        }
                    }

                    h2 {
                        margin: 0 0 2px;
                        padding: 0;
                        font-size: 15px;
                        font-weight: 600;

                        rl-icon {
                            font-size: 12px;
                            opacity: .4;
                            color: var(--general-text-color);
                        }
                    }

                    p {
                        font-size: 13px;
                        padding: 0;
                        margin: 0;
                        font-weight: 500;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }

                    .deadline {
                        font-size: 0.86667rem;
                        font-weight: 400;
                        white-space: normal;
                        opacity: 0.4;
                    }
                }

                .categories {
                    height: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;

                    .category {
                        width: 30px;
                        height: 5px;
                        margin-top: 10px;
                        margin-right: 5px;
                        border-radius: 5px;
                    }
                        
                    .red {
                        background-color: var(--warning-color);
                    }
                        
                    .yellow {
                        background-color: var(--alert-color);
                    }
                    
                    .green {
                        background-color: var(--confirmation-color);
                    }
                }

                .footer, .top,
                .bottom {
                    display: flex;
                    justify-content: space-between;
                }

                .footer {
                    align-items: flex-end;
                    margin-top: auto;
                }
            }
        }
    }