users-table {
    .row {
        display: flex;
        padding: 10px;
        box-sizing: border-box;
        border-bottom: ~'1px solid rgba(var(--general-text-color-rgb), .1)';
        word-break: break-word;
        
        &.disabled {
            opacity: 0.5;
        }

        &:first-of-type {
            border-top: none;
        }

        .UserName {
            display: flex;

            .profile-image {
                height: 34px;
                width: 34px;
            }

            .name {
                display: block;
            }

            .department {
                font-size: 13px;
                color: @clr-subheadline-onbg;
            }
        }
    }
}
