@import "../Import.less";

.workmood {
    .mood {
        padding: 20px 10px;

        h3 {
            font-size: @font-size;
            margin: 0;
            padding: 0 20px;
            text-align: center;
        }

        .emotions {
            margin: 20px auto 0 auto;
            text-align: center;

            .emotion {
                display: inline-block;
                vertical-align: top;
                margin: 0 .5%;
                -moz-transition: all ease-in-out .5s;
                -o-transition: all ease-in-out .5s;
                -webkit-transition: all ease-in-out .5s;
                transition: all ease-in-out .5s;
                overflow: hidden;

                &.hidden{
                    max-width: 0 !important;
                }

                img {
                    width: 100%;
                    height: auto;
                }

                &.selected{
                    width: 150px !important;
                    max-width: 150px !important;
                }
            }

            &.count-4 .emotion{
                width: 23%;
                max-width: 23%;
            }
            &.count-5 .emotion{
                width: 17%;
                max-width: 17%;
            }
        }
    }

    .mood-splitter {
        display: block;
        width: 100%;
        height: 20px;
        background: lighten(@split-color, 10%);
        border-top: 1px solid @split-color;
        border-bottom: 1px solid @split-color;
    }

    .mood-stats {
        padding: 20px 10px;

        h3 {
            font-size: @font-size;
            margin: 0;
            padding: 0;
            text-transform: uppercase;
        }

        canvas{
            margin-top: 15px;
        }

        .workmood-chart{
            margin-top: 25px;
            position: relative;
            overflow: hidden;
            margin-left: -10px;
            margin-right: -10px;
        }
    }
}