comment-card-component:last-of-type {
    .comment-card-container {
        margin-bottom: 0;
    }
}

.comment-card-container {
    border-radius: 8px;
    margin-bottom: var(--spacing);
    padding: var(--spacing);
    border: 1px solid var(--general-border-color);
    background-clip: padding-box;

    &.negative {
        background-image: linear-gradient(180deg, rgba(var(--warning-color-rgb), 0.3) 0%, var(--general-background-color) 38%);
    }

    &.positive {
        background-image: linear-gradient(180deg, rgba(var(--confirmation-color-rgb), 0.3) 0%, var(--general-background-color) 38%);
    }

    &.mixed {
        background-image: linear-gradient(180deg, rgba(var(--alert-color-rgb), 0.3) 0%, var(--general-background-color) 38%);
    }

    .loader-comment-header {
        height: 55px;
    }

    .comment-card-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: var(--m-spacing);


        .comment-poster-info {
            display: flex;
            align-items: center;
            gap: calc(var(--m-spacing) / 2);

            .profile-pic {
                .profile-image {
                    width: 35px;
                    height: 35px;
                }
            }

            .comment-is-new {
                width: 7px;
                height: 7px;
                border-radius: 50%;
                background: var(--highlight-color);
            }

            .comment-post-details {
                line-height: 1.56rem;

                .comment-post-date {
                    color: var(--general-text-inactive-color);
                }
            }
        }

        .sentimental-emoji-icon {

            .sentimental-emoji {
                width: 34px;
                height: 34px;
            }
        }
    }

    .divider {
        height: 1px;
        background-color: var(--general-border-color);
    }

    .comment-card-body {
        margin-top: var(--m-spacing);
    }
}