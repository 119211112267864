.base-view-blockedUsers {
    background-color: var(--background-highlight-color);
}

blocked-users {
    display: block;
    padding: 10px 15px;

    .blocked-users-wrapper {
        background-color: var(--general-background-color);
        border-radius: var(--general-border-radius);

        @media @desktop-up {
            max-width: 60vw;
            margin: 0 auto;
        }
    }

    .user {
        display: flex;
        align-items: center;
        padding: 10px;

        .profile-image {
            height: 36px;
            width: 36px;
            margin-right: 10px;
        }

        .details {
            display: flex;
            flex-direction: column;
            margin-right: 10px;
        }

        button {
            margin-left: auto;
            padding: 4px 6px;
            border-radius: var(--general-border-radius);
            background-color: var(--highlight-color);
            color: var(--text-on-highlight-color);
        }
    }
}

.no-content {
    .description {
        font-weight: normal;
    }
}