.base-view.base-view-training.base-view-training-lesson.lesson-page-v2 {
    @media @desktop-up {
        height: 100%;
        padding: calc(var(--m-spacing) * 2) 0 calc(var(--m-spacing) * 4) 0;
        box-sizing: border-box;

        --training-background: var(--background-highlight-color);
    }

    &.show-answers {
        lesson-page-v2 {
            background: none;
        }
    }
}

lesson-page-v2 {
    display: block;

    @media @tablet-only {
        margin: 0 auto;
        max-width: var(--max-width);
    }

    @media @desktop-up {
        height: 100%;
        overflow-y: auto;
        background: var(--training-inner-background);

        &.view-page {
            max-width: calc(var(--max-width) + var(--spacing) * 6);
        }
    }

    rl-loading-placeholder {
        display: block;
        padding: calc(var(--m-spacing) * 2.5) var(--spacing) var(--spacing) var(--spacing);
    }

    .lesson-inner-page {
        display: flex;
        flex-direction: column;
        transition: opacity 0.3s;
        opacity: 1;

        &.hidden {
            overflow: hidden;
            opacity: 0;
            height: 0;
        }

        @media @desktop-up {
            height: 100%;
        }

        @media @tablet-max {
            min-height: calc(100dvh - 50px - 55px - env(safe-area-inset-bottom));
        }
    }

    .content-wrapper {
        box-sizing: border-box;
        padding: calc(var(--m-spacing) * 2.5) var(--spacing) var(--spacing) var(--spacing);
    }

    .button-wrapper {
        z-index: 5;
        position: sticky;
        bottom: calc(env(safe-area-inset-bottom) + 50px);

        margin-top: auto;
        padding: calc(var(--m-spacing) * 6) var(--spacing) var(--m-spacing) var(--spacing);
        box-sizing: border-box;

        background: linear-gradient(0deg, var(--general-background-color) 0%, rgba(255, 255, 255, 0.00) 106.14%);

        @media @desktop-up {
            bottom: 0;
            padding: calc(var(--m-spacing) * 6) var(--spacing) calc(var(--m-spacing) * 2) var(--spacing);
        }

        rl-button {
            display: block;
            background: var(--general-background-color);
            border-radius: var(--general-border-radius);
        }
    }
}

@import "LessonQuestions/LessonQuestions";
@import "LessonQuestions/LessonQuestion/LessonQuestion";
@import "LessonQuestions/LessonSwipeCard/LessonSwipeCard";
@import "LessonQuestions/LessonImageQuestion/LessonImageQuestion";
@import "LessonEvaluation/LessonEvaluation";
@import "LessonDisclaimer/LessonDisclaimer";
@import "LessonTestimonials/LessonTestimonials";
@import "LessonIntroduction/LessonIntroduction";
