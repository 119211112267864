activity-indicator {
    display: flex;

    i {
        position: relative;
        width: 18px;
        height: 18px;
        border-radius: 50%;
    }
    
    i::before {
        font-size: 14px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: rgba(var(--white-color-rgb), 0.4);
        backdrop-filter: blur(4px);
        overflow: hidden;
        border-radius: 50%;
    }

    .all {
        background: conic-gradient(
            rgba(var(--alert-color-rgb), 0.95) 0%,
            rgba(var(--warning-color-rgb), 0.95) 5%,       
            rgba(var(--alert-color-rgb), 0.95) 35%,
            rgba(var(--confirmation-color-rgb), 0.95) 55%,
            rgba(var(--alert-color-rgb), 0.95) 85%,
            rgba(var(--alert-color-rgb), 0.95) 100%
        );
    }

    .today {
        background: var(--confirmation-color);
    }

    .week {
        background-color: color-mix(in srgb, var(--confirmation-color) 50%, var(--alert-color));
    }

    .month {
        background: var(--alert-color);
    }

    .no-activity-in-month,
    .had-activity {
        background: var(--warning-color);
    }

    .no-activity-in-week {
        background-color: color-mix(in srgb, var(--warning-color) 50%, var(--alert-color));
    }

    .no-activity {
        background: var(--general-text-inactive-color);
    }

    .pending {
        width: 14px;
        height: 14px;
        background: var(--general-text-inactive-color);

        &::before {
            font-size: 8px;
            color: var(--white-color);
            width: 14px;
            height: 14px;
            display: flex;
            justify-content: center;
            align-items: center;
            backdrop-filter: blur(5px);
        }
    }

    .disabled {
        background: var(--warning-color);
        width: 17px;
        height: 17px;

        &::before {
            font-size: 10px;
            line-height: 15px;
            color: var(--white-color);
            display: flex;
            justify-content: center;
            align-items: center;
            backdrop-filter: blur(5px);
        }
    }
}