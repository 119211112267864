@import "../../Import.less";

.form-module-question-checkbox {
    table {
        margin: 10px 0 20px 0;

        tr {
            td {
                border-bottom: 1px solid rgba(0, 0, 0, 0.06);
            }

            td:first-child {
                width: 40px;
            }

            span {
                color: rgba(0, 0, 0, 0.3);
                margin-left: 15px;
                font-size: 1rem;
            }

            &.picked {
                .checkbox-circle {
                    border-color: @color-primary;
                    background-color: @color-primary;
                    color: @color-primary-accent;
                }

                span {
                    color: #000;
                }
            }

            &:last-child td {
                border-bottom-color: transparent;
            }
        }
    }

    .checkbox-circle {
        display: block;
        height: 36px;
        width: 36px;
        line-height: 36px;
        text-align: center;
        border: 1px solid rgba(0, 0, 0, 0.12);
        border-radius: 100%;
        background-color: transparent;
        margin: 13px 0;
        color: transparent;
        font-size: 30px;
    }
}