@import "../Variables.less";

power-bi {
    display: flex;
    height: 100%;
    overflow: hidden;
    flex-direction: column;

    .powerbi-frame {
        height: 100%;
        width: 100%;

        iframe {
            border: none;
        }
    }

    &.view-page {
        @media @desktop-up {
            height: calc(100vh - 60px);
            max-width: 100%;
        }
    }

    tabset {
        padding-bottom: 0 !important;
        .tabset-content{
            display: none !important;
        }
    }
}


.power-bi-report-error {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;

    i{
        font-size: 50px;
        margin-bottom: 10px;
    }

    span{
        text-align: center;
    }
}

.power-bi-report-print {
    display: flex;
    justify-content: flex-end;
    padding: 10px;
}