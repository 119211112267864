campaign-item {
    header-popup {
        background: none;
        margin-bottom: -55px;
        z-index: 1;
        position: relative;

        .buttons button {
            width: 55px;

            rl-icon {
                color: #000;
                background: rgba(200, 200, 200, 0.7);
                height: 30px;
                width: 30px;
                border-radius: 100%;
                text-align: center;
                line-height: 30px;
                font-size: 15px;
                box-sizing: border-box;
                padding-left: 1px;
                padding-top: 1px;
            }
        }
    }
}

.campaign-item {
    min-height: 100%;
    background: #fff;

    .campaign-item-header {
        height: 210px;
        width: 100%;
        background: #EBEBEB;
        display: flex;
        flex-direction: column-reverse;
        padding: 20px;
        box-sizing: border-box;
        border-bottom-left-radius: 30px;
        border-bottom-right-radius: 30px;
        background-position: center center;
        background-size: cover;

        h2 {
            margin: 0;
            padding: 0;
            color: var(--general-text-color);
            font-weight: bold;
            font-size: 28px;
        }

        h3 {
            margin: 0 0 -5px 0;
            padding: 0;
            color: var(--general-text-color);
            font-weight: normal;
            font-size: 13px;
            text-transform: uppercase;
            opacity: .75;
        }
    }

    .campaign-item-cover {
        margin: 15px 15px -10px 15px;
        border-radius: var(--general-border-radius);
        overflow: hidden;
    }

    .campaign-item-content {
        box-sizing: border-box;
        padding: 25px 15px 15px 15px;

        &.has-footer {
            padding-bottom: 85px;
        }

        p:first-child {
            margin-top: 0;
        }

        translation-button {
            margin-top: -10px;
            margin-bottom: 10px;
        }
    }

    .campaign-item-footer {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        box-sizing: border-box;
        padding: 10px 15px 15px 15px;
        background: #fff;
    }
}