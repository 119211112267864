thumbnail-selector {
    .rl-header {
        > button[disabled] {
            rl-icon {
                opacity: .5;
            }
        }
    }

    .popup-content.clean.thumbnail-popup {
        @media @desktop-up {
            left: 50% !important;
        }
    }

    .popup-content-inner {
        display: flex !important;

        .title {
            margin: 10px;
            color: white;
            text-align: center;
        }
    }
}

.thumbnail-selector {
    text-align: center;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    .video-container {
        position: relative;
        max-width: 310px;
        margin: 0 auto;
        font-size: 0;
        height: calc(100% - 55px);
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        @media @desktop-up {
            max-width: 420px;
        }

        video {
            width: 100%;
            max-height: 100%;
        }

        .spinner-wrapper {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            background: rgba(0,0,0, .1);
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;

            .spinner {
                border: solid 2px #fff;
            }
        }

        .timeline {
            width: 100%;
            height: 10px;
            cursor: pointer;
            background: var(--background-highlight-color);
            position: relative;

            .timeline-drag {
                width: 16px;
                height: 16px;
                left: 0;
                top: -3px;
                background-color: var(--highlight-color);
                position: absolute;
                z-index: 2;
                transform-origin: 0 0;
                border-radius: 50%;
                margin-left: -8px;
                box-shadow: 0 1px 10px rgba(0, 0, 0, 0.3);
            }

            .timeline-progress {
                display: block;
                width: 100%;
                height: 10px;
                transform: scaleX(0);
                transform-origin: 0 0;
                position: relative;
                z-index: 1;
                background: var(--highlight-color);
            }
        }
    }


}