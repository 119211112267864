create-data-list-item {
    .popup-wrapper .popup-content-inner {
        padding: 10px 15px 75px 10px;

    }

    .service-form-field {
        margin-bottom: 15px;
    }

    footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding-bottom: 30px;
        padding-bottom: calc(30px + env(safe-area-inset-bottom, 0px));

        .right {
            display: flex;
            margin-left: auto;
            margin-right: 15px;
            filter: drop-shadow(0 6px 15px rgba(0, 0, 0, 0.15));

            button {
                display: flex;
                justify-content: center;
                align-items: center;
                width: auto;
                height: 46px;

                padding: 0 25px;

                font-weight: bold;
                background: var(--highlight-color);
                color: var(--text-on-highlight-color);

                border-radius: var(--general-border-radius);
                border: 1px solid var(--white-color);
            }
        }
    }
}