events {
    flex: 1;
    display: block;
    overflow: hidden;

    overflow-y: auto;
    -webkit-overflow-scrolling: touch;

    .events-scrolled-wrapper {
        display: flex;
        flex-wrap: wrap;
        gap: 15px;
        align-items: flex-start;
    }
}

event {
    display: block;

    &:not(.big) {
        @media @mobile-max {
            width: calc((100% - 15px) / 2);
        }

        @media @tablet-up {
            width: calc((100% - 30px) / 3);
        }
    }

    &.big {
        @media @tablet-max {
            min-width: 280px;
        }
        
        @media @desktop-up {
            width: calc((100% - 30px) / 3);
        }

        .event {
            .background {
                height: 155px;

                event-status {
                    left: 10px;
                    bottom: 10px;
                }
            }

            .content {
                padding: 10px;

                .period {
                    opacity: 0.4;
                }
            }
        }
    }

    box-sizing: border-box;
    overflow: hidden;
    cursor: pointer;
    border-radius: var(--general-border-radius);

    .event {
        position: relative;
        z-index: 2;

        display: flex;
        flex-direction: column;

        background: var(--background-highlight-color);
        color: var(--general-text-color);

        &.cancelled {
            .background {
                background-color: rgba(var(--general-text-color-rgb), 0.1);

                .image {
                    opacity: 0.4;
                }
            }

            .content {
                h2, p {
                    opacity: 0.4;
                }
            }
        }

        .background {
            position: relative;
            height: 95px;
            background-color: rgba(var(--general-text-color-rgb), 0.2);

            .image {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;

                background-position: center;
                background-size: cover;
            }

            .no-image {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);

                opacity: 0.1;
                font-size: 58px;
            }

            @media @tablet-up {
                height: 115px;
            }

            .interested {
                position: absolute;
                top: 10px;
                right: 10px;

                font-size: 21px;

                color: var(--white-color);
                filter: drop-shadow(0px 6px 15px rgba(0, 0, 0, 0.15));

                &.bg {
                    color: rgba(var(--white-color-rgb), 0.4);
                }

                &.active {
                    color: var(--alert-color);
                }
            }

            event-status {
                position: absolute;
                left: 7px;
                bottom: 7px;
            }

            .date {
                position: absolute;
                right: 10px;
                bottom: -25px;

                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                overflow: hidden;
                width: 50px;
                height: 50px;
                border-radius: var(--general-border-radius);
                filter: drop-shadow(0px 6px 15px rgba(0, 0, 0, 0.15));
                background-color: var(--highlight-color);

                &:after {
                    content: '';
                    display: block;
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    background: linear-gradient(135deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
                    opacity: 0.4;
                }

                p {
                    margin: 0;
                    color: var(--text-on-highlight-color);
                }

                .day {
                    font-weight: 700;
                    font-size: 20px;
                }

                .month {
                    font-weight: 400;
                    font-size: 11px;
                    text-transform: uppercase;
                }

            }
        }

        .content {
            display: flex;
            flex-direction: column;

            height: 75px;
            padding: 7px;
            box-sizing: border-box;

            > :not(:last-child) {
                margin-bottom: 4px;
            }

            @media @desktop-up {
                height: 95px;
                padding: 10px;

                > :not(:last-child) {
                    margin-bottom: 7px;
                }
            }

            h2 {
                margin: 0;
                display: flex;

                > span {
                    overflow: hidden;

                    white-space: nowrap;
                    text-overflow: ellipsis;

                    font-size: 15px;
                    line-height: 130%;
                    font-weight: 600;

                    @media @desktop-up {
                        font-size: 17px;
                    }
                }

                rl-icon {
                    margin-left: 5px;
                    font-size: 15px;
                    opacity: 0.5;
                }
            }

            p {
                overflow: hidden;
                margin: 0;

                white-space: nowrap;
                text-overflow: ellipsis;

                font-size: 13px;
                line-height: 130%;

                @media @desktop-up {
                    font-size: 15px;
                }

                &.location {
                    color: rgba(var(--general-text-color-rgb), 0.4);
                }
            }
        }

        /*        .label {
                    position: relative;
                    z-index: 2;
                    align-self: flex-start;
                    font-size: 13px;
                }*/
    }
}
