@import "../Import.less";

.base-view-iframe > .iframe {
    padding-bottom: 0px;
    height: 100%;
    overflow: visible !important;

    @media @desktop-up {
        height: calc(100vh - 60px);
    }

    .iframe-container {
        -webkit-overflow-scrolling: touch;
        position: static;
        height: 100%;
        min-height: 100%;
        overflow: visible;

        iframe {
            width: 1px;
            min-width: 100%;
            height: 99%;
            border: 0;
            position: static;
        }
    }

    .iframe-launchpage {
        padding: 20px;
        text-align: center;
        display: table;
        height: 100%;
        background-position: center center;
        background-size: cover;
        position: relative;

        &:after {
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 1;
            background: rgba(0, 0, 0, 0.5);
            content: '';
        }

        .launchpage-content {
            display: table-cell;
            vertical-align: middle;
            z-index: 2;
            position: relative;
            color: #fff;

            .btn {
                margin-top: 10px;
            }
        }
    }
}
