@import "FiltersPopup/TainingFiltersPopup";
@import "OverviewTable/OverviewTable";
@import "TrainingOverview/TrainingOverview";
@import "UsersTable/UsersOverviewTables";
@import "TrainingMaterialSelector/TrainingMaterialSelector";

training-manager-tab {
    display: block;
    padding-top: 10px;

    .performance {
        background: var(--background-highlight-color);

        > header {
            padding: 20px 25px;
            text-align: center;
            background: var(--general-background-color);

            h2 {
                margin: 0;
                padding-bottom: 15px;
                text-transform: uppercase;
                font-size: 15px;
                line-height: 1;

                span {
                    padding-left: 5px;
                    font-size: 13px;
                }
            }
        }

        .results {
            display: flex;
            justify-content: space-between;
        }

        .result {
            display: flex;
            flex-direction: column;

            h3 {
                margin: 0;
                padding-bottom: 5px;
                font-size: 28px;
            }
        }
    }

    .low {
        color: var(--warning-color);
    }

    .medium {
        color: var(--alert-color);
    }

    .high {
        color: var(--confirmation-color);
    }

    .spinner {
        width: 50px;
        height: 50px;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -25px;
        margin-top: -25px;
    }

    .filter-button {
        @media @tablet-max {
            display: none;
        }

        position: fixed;
        right: 10px;
        bottom: 60px;
    }
}

users-table, overview-table {
    header {
        display: flex;
        overflow: hidden;
        padding: 10px;
        box-sizing: border-box;
        border-bottom: ~'1px solid rgba(var(--general-text-color-rgb), .1)';

        div {
            display: flex;
            font-weight: bold;

            .title {
                &.mobile {
                    @media @tablet-up {
                        display: none;
                    }
                }

                &.desktop {
                    overflow: hidden;
                    text-overflow: ellipsis;

                    @media @mobile-max {
                        display: none;
                    }
                }
            }

            .sort {
                position: relative;
                top: 2px;
                padding-left: 5px;
                color: @clr-borders;

                &.active {
                    color: @clr-bodytext;
                }

                &.reverse {
                    transform: scale(1, -1);
                }
            }
        }
    }

    .cell {
        display: flex;
        width: 20%;
        align-items: center;
        overflow: hidden;
        text-align: left;

        &.UserName, &.Name {
            width: 60%;
        }

        &:not(:last-of-type) {
            padding-right: 10px;
        }
    }
}


