department-swap-selector {
    no-content {
        display: block;

        .no-content {
            display: block;
            height: 200px;
            margin: 0;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            i {
                font-size: 40px;
                margin: 0 0 20px 0;
            }

            p {
                font-size: 13px;
            }
        }
    }

    .department-swap-placeholder {
        height: 30px;
        margin: 15px;
        border-radius: var(--general-border-radius);

        &:not(:first-of-type) {
            margin-top: 25px;
        }

        @media @desktop-up {
            height: 20px;

            &:not(:first-of-type) {
                margin-top: 20px;
            }
        }
    }

    rl-select-list {
        .item {
            padding: 0 15px;
            box-sizing: border-box;

            @media @desktop-up {
                &:hover {
                    background: ~'rgba(var(--background-highlight-color-rgb), 0.5)';
                }
            }
        }

        @media @desktop-up {
            row-gap: 0;

            .item {
                height: 36px;
            }
        }
    }
}