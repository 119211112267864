.ec-shopgun-loader {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    z-index: 2;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    padding: 15px;
    box-sizing: border-box;

    .spinner {
        flex: 0 0 auto;
    }

    span {
        flex: 0 0 auto;
        display: block;
        margin-top: 15px;
        font-weight: 600;
        font-weight: 600;
    }
}

.ec-shopgun-load-error {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 15px;
    box-sizing: border-box;
    position: relative;
    z-index: 2;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;

    span {
        flex: 0 0 auto;
        display: block;
        margin-top: 15px;
        font-weight: 600;
    }
}