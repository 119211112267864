@import "../Import.less";

.ranking {
    .tab{
        padding-top: 0;
    }

    .ranking-view{
        padding-top: 20px;

        .no-content{
            position: relative;
            top: 0;
            margin: 40px 0 0 0;
        }
        .spinner-container{
            display: block;
            text-align: center;
            margin-top: 30px;

            .spinner{
                width: 40px;
                height: 40px;
            }
        }
        .date-last-updated {
            font-size: 13px;
            color: #333;
            text-align: center;
            padding-bottom: 5px;
        }

        table{
            th, td{
                border-bottom: 1px solid #eee;
                padding: 0 5px;
                &.rank{
                    text-align: center;
                    width: 30px;
                }
                &.name{
                    padding-left: 10px;
                }
            }
            th{
                vertical-align: bottom;
            }
            td {
                &.rank {
                    background-color: #eee;
                }
                &.multiline h2{
                    padding-bottom: 0;
                }

                h2{
                    font-size: 1em;
                    font-weight: normal;
                    margin: 0;
                    padding: 5px 0;
                    line-height: normal;
                }
                h3{
                    font-size: .85em;
                    font-weight: normal;
                    opacity: .8;
                    margin: 0;
                    padding: 0 0 5px 0;
                    line-height: normal;
                }
            }
        }
    }
}