survey-question {
    display: block;
    padding: 15px;

    header {
        display: flex;
        flex-direction: column;

        .question-number {
            width: 40px;
            height: 40px;
            line-height: 40px;
            text-align: center;
            border-radius: 50%;
            margin-bottom: 15px;
            background: var(--background-highlight-color);
        }

        media {
            margin-bottom: 30px;
        }

        .title {
            margin-bottom: 15px;
            @media @desktop-up {
                margin-bottom: 40px;
            }

            h2 {
                margin: 0 0 5px 0;
                word-break: break-word;
            }
        }
    }

    .predefined-question {
        .option {
            position: relative;
            display: flex;
            justify-content: space-between;
            padding: 15px;
            border: 1px solid var(--general-border-color);

            &.disabled {
                span {
                    opacity: 0.6;
                }
            }

            &.picked {
                border: 1px solid var(--highlight-color);
                border-left-width: 3px;

                span {
                    color: var(--highlight-color);
                    font-weight: 600;
                }
            }

            &:not(:last-of-type) {
                margin-bottom: 10px;
            }

            &:not(.picked) {
                padding-left: 17px;
            }

            span {
                font-size: 13px;
                transition: font-weight 0.2s;
            }

            .poll-progress {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 0;
                background: ~'rgba(var(--highlight-color-rgb), 0.15)';
                transition: all ease-in-out 0.5s;
            }
        }
    }

    .scale-question {
        text-align: center;

        ul {
            margin: 0;
            padding: 0;
            display: flex;
            list-style: none;
            justify-content: space-around;
            flex-wrap: wrap;

            li {
                list-style: none;
                text-align: center;
                width: 20%;
                outline: none;

                i {
                    display: block;
                    font-size: 45px;
                    color: var(--background-highlight-color);
                }

                &.marked i {
                    color: var(--alert-color);
                }

                .option-text {
                    overflow-wrap: break-word;
                }
            }
        }
    }

    .text-question {
        textarea {
            width: 100%;
            height: 180px;
            padding: 10px;
            box-sizing: border-box;
            background: rgba(var(--background-highlight-color-rgb), 0.6);
            color: var(--general-text-color);
            border: none;
            resize: none;
        }

        .next-btn-wrapper {
            margin-top: 20px;
            position: relative;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
        }
    }

    .info-question {
        media {
            margin-bottom: 10px;
        }

        .info-question-body {
            margin: 0;
            font-size: 16px;
        }
    }

    .enps-question {
        .rzslider {
            margin-top: 25px;

            .rz-bar {
                border-radius: 100px;
                height: 10px;

                &:not(.rz-selection) {
                    background-color: ~'rgba(var(--general-text-color-rgb), 0.1)';
                }

                &.rz-selection {
                    background-color: var(--warning-color);
                    background-image: ~'linear-gradient(90deg, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 100%)';
                }
            }

            .rz-pointer {
                top: -8px;
                width: 26px;
                height: 26px;
                background: var(--white-color);
                filter: drop-shadow(0px 2px 7px rgba(0, 0, 0, 0.15));

                &:after {
                    top: 8px;
                    left: 8px;
                    width: 10px;
                    height: 10px;
                    background: var(--warning-color);
                }
            }

            .rz-ticks {
                top: 0;
            }

            .rz-tick {
                top: 28px;

                width: 1px;
                height: 10px;
                border-radius: 5px;
                background: ~'rgba(var(--general-text-color-rgb), 0.15)';

                &.rz-selected {
                    width: 1.5px;
                    background: var(--warning-color);
                }
            }
        }

        .current {
            span {
                font-size: 13px;
                line-height: 16px;
                color: ~'rgba(var(--general-text-color-rgb), 0.4)';
            }
        }

        .labels {
            width: 100%;
            position: relative;
            margin-top: 40px;

            .first {
                left: 0;
            }

            .last {
                right: 0;
            }

            span {
                position: absolute;
                font-size: 13px;
                line-height: 16px;
                color: ~'rgba(var(--general-text-color-rgb), 0.4)';
            }
        }
    }
}
