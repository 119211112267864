@import "../Import.less";

.view-page {
    min-height: 100%;
    width: 100%;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    transition: top ease-in-out .4s;

    @media @desktop-up {
        top: 0;
        max-width: var(--max-width);
        margin: 0 auto;
    }

    &.has-news-ticker {
        top: calc(var(--top-header-height) + @news-ticker-height);
    }
}

.view-page-container {
    width: 100%;
    box-sizing: border-box;
    overflow: hidden;
    transition: top ease-in-out .4s;

    .view-page {
        top: 0;
    }

    @media @desktop-up {
        top: 0;
    }

    &.has-news-ticker {
        top: calc(var(--top-header-height) + @news-ticker-height);
    }
}