lesson-content-component {
    display: block;

    .disclaimer {
        display: flex;
        align-items: center;
        margin: -20px -15px 30px;
        padding: 20px 15px;
        background: rgba(var(--alert-color-rgb), 0.2);
        color: var(--general-text-color);

        &.lesson-done-success {
            background: rgba(var(--confirmation-color-rgb), 0.2);
        }

        &.expired {
            background: rgba(var(--general-text-color-rgb), 0.2);
        }

        p {
            margin: 0;
            padding: 0;
            text-align: left;
            font-size: 13px;
        }

        rl-icon {
            flex-shrink: 0;
            width: 25px;
            height: 25px;
            margin-right: 15px;
            font-size: 15px;
            border-radius: 50%;
            color: var(--white-color);

            &[icon=save] {
                background: var(--confirmation-color);
            }

            &[icon=exclamation] {
                background: var(--alert-color);
            }

            &[icon=lock] {
                background: rgba(var(--general-text-color-rgb), 0.4);
            }

            &[icon=hourglass-end] {
                font-size: 13px;
                background: var(--general-text-color);
            }
        }
    }

    .lesson-media {
        width: 100%;
        margin-bottom: 15px;
    }

    .introduction {
        margin-bottom: 25px;

        *:first-child {
            margin-top: 0;
        }

        *:last-child {
            margin-bottom: 0;
        }

        img, video, iframe {
            width: 100%;
        }
    }

    .question {
        &:not(:last-of-type) {
            margin-bottom: 25px;
        }

        .title {
            h2 {
                margin: 0;
                font-size: 20px;
                font-weight: 600;
                color: var(--general-text-color);
            }

            p {
                margin: 0;
                font-size: 13px;
                color: var(--general-text-color);
            }

            media {
                margin-bottom: 10px;
            }
        }

        .question-feedback {
            margin-top: 10px;

            media {
                overflow: hidden;
                margin-bottom: 10px;
                border-radius: var(--general-border-radius);
            }

            p {
                margin: 0;
                font-size: 13px;
                color: var(--general-text-color);
            }
        }
    }

    .answers {
        margin-top: 20px;

        > span {
            display: block;
            font-size: 13px;
            font-weight: 500;
            padding-bottom: 5px;
        }
    }

    .answer {
        &.selected {
            border-left: 5px solid var(--highlight-color);
            border-color: var(--highlight-color);
            box-shadow: 0 2px 7px 0 rgba(var(--general-text-color-rgb), .1);
            padding-left: 10px;

            rl-icon {
                width: 19px;
                height: 19px;
                margin-right: 5px;
                font-size: 11px;
                border-radius: 50%;
                color: var(--white-color);
            }

            &.wrong {
                background-image: linear-gradient(to bottom, rgba(var(--warning-color-rgb), 0.1) 39%, rgba(var(--general-text-color-rgb), 0));
                border-left: 5px solid var(--warning-color);
                border-color: var(--warning-color);

                rl-icon {
                    background: var(--warning-color);
                }
            }

            &.correct {
                background-image: linear-gradient(to bottom, rgba(var(--confirmation-color-rgb), 0.1) 39%, rgba(var(--general-text-color-rgb), 0));
                border-left: 5px solid var(--confirmation-color);
                border-color: var(--confirmation-color);

                rl-icon {
                    background: var(--confirmation-color);
                }
            }

            > h3 {
                font-weight: 600;
            }
        }

        &:not(:last-of-type) {
            margin-bottom: 15px;
        }

        padding: 15px;
        border: 1px solid rgba(var(--general-text-color-rgb), .1);
        background-color: var(--general-background-color);

        > h3 {
            margin: 0;
            font-size: 15px;
            color: var(--general-text-color);
        }

        &.disabled {
            background-color: var(--background-highlight-color);
        }

        .answer-feedback {
            padding-top: 15px;

            media {
                overflow: hidden;
                margin-bottom: 10px;
                border-radius: var(--general-border-radius);
            }

            p {
                margin: 0;
                font-size: 13px;
                color: var(--general-text-color);
            }
        }
    }
}