badges-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    margin: 15px -9px 0 -9px;

    @media @tablet-up {
        margin: 30px -15px 0 -15px;
    }

    badge {
        width: ~'calc(100% / 4 - 18px)';
        margin: 0 9px 15px 9px;

        @media @tablet-up {
            width: ~'calc(100% / 5 - 40px)';
            margin: 0 20px 15px 20px;
        }
    }

    svg {
        position: absolute;
        width: 0;
        height: 0;
    }
}