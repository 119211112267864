sorting {
    display: flex;
    justify-content: space-between;
    align-items: center;

    rl-icon {
        .far {
            font-weight: 400;
        }
    }
}