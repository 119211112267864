@sevent-wipe-height: 34px;
@event-swipe-width: 300px;

event-details {
    .popup-content {
        display: flex;
        flex-direction: column;
    }

    header {
        z-index: 10;
        position: sticky;
        top: -55px;
        width: 100%;
        height: 200px;
        min-height: 200px;

        display: flex;
        flex-direction: column;
        justify-content: space-between;

        background-color: var(--background-highlight-color);

        &.image {
            background-color: white !important;

            &.cancelled {
                background-color: rgba(var(--general-text-color-rgb), 0.1);

                .image {
                    opacity: 0.4;
                }
            }
        }

        .image {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-position: center;
        }

        header-popup {
            position: sticky;
            top: 0;
            background: transparent;

            box-sizing: border-box;
            height: auto;
            width: 100%;
            padding: 15px 15px 0 15px;

            .buttons {
                min-width: auto;

                button {
                    width: auto;
                    height: auto;
                    background: transparent;
                    min-width: auto;

                    rl-icon {
                        height: 30px;
                        width: 30px;
                        border-radius: 50%;

                        color: var(--general-text-color);
                    }
                }
            }

            &.on-bg {
                button {
                    rl-icon {
                        background: rgba(255, 255, 255, 0.6);
                        backdrop-filter: blur(24px);
                        filter: drop-shadow(0px 6px 15px rgba(0, 0, 0, 0.15));
                    }
                }
            }
        }

        .inner-header {
            display: flex;
            padding: 0 15px 15px 15px;

            .interested {
                position: absolute;
                right: 10px;
                bottom: -22px;
                border-radius: 50%;

                button {
                    width: 44px;
                    height: 44px;
                    min-width: 44px;

                    background-color: var(--white-color);
                    filter: drop-shadow(0px 6px 15px rgba(0, 0, 0, 0.15));

                    &:active, &:hover {
                        opacity: 1;
                    }

                    rl-icon {
                        font-size: 20px;
                        color: var(--general-text-inactive-color);
                    }
                }

                &.active {
                    button rl-icon {
                        color: var(--alert-color);
                    }
                }
            }
        }
    }

    .event-body {
        display: flex;
        flex-direction: column;
        flex: 1;
    }

    .event-content {
        padding: 22px 10px 20px 10px;
        flex: 1;

        h1 {
            position: relative;
            z-index: 1;

            display: flex;
            width: calc(100% - 50px);
            margin: 0;

            text-transform: uppercase;
            color: var(--general-text-color);

            &.cancelled {
                text-decoration-line: line-through;
                opacity: 0.4;
            }

            > span {
                font-size: 20px;
                word-break: break-word;
            }

            rl-icon {
                margin-left: 5px;
                opacity: 0.5;
                font-size: 15px;
            }
        }
    }

    translation-button {
        .spinner {
            width: 10px;
            height: 10px;
            margin-left: 5px;
        }
    }

    .btn-create-update {
        margin-bottom: 15px;
        display: block;
    }

    .field {
        display: flex;
        align-items: center;
        margin: 15px 0;

        > rl-icon:first-of-type, .icon-wrapper {
            margin-right: 10px;
        }
    }

    .period {
        margin-top: 25px;
        align-items: flex-start;

        > div {
            display: flex;
            align-items: center;


            p {
                text-transform: capitalize;
                margin: 0;

                &:first-of-type {
                    color: rgba(var(--general-text-color-rgb), 0.4);
                    font-size: 13px;
                    line-height: 130%;
                }

                &:last-of-type {
                    margin-top: 5px;
                    font-size: 15px;
                    line-height: 19.5px;
                }
            }
        }

        [icon="next"] {
            margin: 0 20px;
        }
    }

    .spots {
        .icon-wrapper {
            position: relative;
        }

        rl-icon {
            &.circle {
                position: absolute;
                bottom: 3px;
                right: -2px;

                width: 10px;
                height: 10px;
                font-size: 6px;
                color: var(--white-color);
            }
        }

        [icon="ticket"] {
            color: ~'rgba(var(--general-text-color-rgb, 0.4))';
        }

        [icon="exclamation"] {
            background: var(--warning-color);
        }


        [icon="save"] {
            background: var(--confirmation-color);
        }

        &.attending {
            [icon="ticket"], > span {
                color: var(--confirmation-color) !important;
            }
        }

        &.exclamation {
            [icon="ticket"], > span {
                color: var(--warning-color) !important;;
            }
        }
    }

    .signups-wrapper {
        display: flex;
        align-items: center;

        [icon="triangle-right"] {
            margin-left: auto;
            font-size: 15px;
            color: var(--general-text-inactive-color);
        }

        .not-answered {
            color: rgba(var(--general-text-color-rgb), 0.4);
        }

        .signups {
            display: flex;
            align-items: center;

            &:disabled {
                opacity: .5;
            }

            div {
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;

                width: 30px;
                height: 30px;

                border-radius: 50%;
                color: var(--text-on-white-color);
                background: var(--white-color);
                box-sizing: border-box;
                border: 1px solid var(--white-color);

                background-position: center center;
                background-size: cover;
                background-repeat: no-repeat;

                &:not(:last-of-type) {
                    margin-right: -10px;
                }
            }

            span {
                padding-left: 10px;
                font-size: 13px;
                color: ~'rgba(var(--general-text-color-rgb), 0.4)';
            }
        }
    }

    .category {
        .label {
            padding: 6px;
            border-radius: var(--general-border-radius);

            font-weight: 700;
            font-size: 13px;
            line-height: 130%;
        }
    }

    .attachments-wrapper {
        height: 135px;
        margin-bottom: 25px;

        .attachments-label {
            margin-bottom: 10px;
        }
    }

    .cancel-reason {
        margin-top: 25px;
        padding: 15px;

        border-radius: var(--general-border-radius);
        background-color: rgba(var(--warning-color-rgb), 0.2);

        h4 {
            margin: 0 0 10px 0;
            color: var(--warning-color);
        }

        p {
            margin: 0;
        }
    }

    .summary {
        margin-top: 25px;

        rl-rich-preview {
            overflow: hidden;
        }
    }

    footer {
        position: sticky;
        z-index: 10;
        bottom: 0;
        margin-top: auto;

        display: flex;
        flex-direction: column;
        justify-content: center;

        width: 100%;

        padding: 15px;
        padding: 15px 15px calc(15px + env(safe-area-inset-bottom, 20px)) 15px;
        box-sizing: border-box;
        background: var(--background-highlight-color);

        .swipe {
            width: @event-swipe-width;
            padding: 0;

            .swipe-container {
                span {
                    left: auto !important;
                    width: 100% !important;
                }

                &.swiped {
                    background: var(--calendar-accent-color);
                }

                &.swiped, &.loading {
                    &:after {
                        transform: translate3d((@event-swipe-width - @sevent-wipe-height), 0, 0);
                    }
                }
            }
        }

        .attending {
            display: flex;
            flex-direction: column;

            @media @desktop-up {
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                &.no-button {
                    justify-content: center;
                }
            }

            .select-attending-wrapper {
                display: flex;
                align-items: center;
                justify-content: space-around;

                @media @desktop-up {
                    flex: 1;
                    max-width: 300px;
                    justify-content: space-between;
                }

                &.selected > button:not(.active) {
                    rl-icon {
                        background: var(--general-text-inactive-color);
                        margin: 0;
                    }

                    > span {
                        display: none;
                    }
                }

                button {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    padding: 0;
                    color: var(--general-text-color);

                    &[disabled] {
                        cursor: default;

                        rl-icon {
                            background: var(--general-text-inactive-color);
                        }

                        &:not(.active) {
                            rl-icon {
                                margin: 0;
                            }

                            > span {
                                display: none;
                            }
                        }
                    }

                    rl-icon {
                        margin-bottom: 8px;
                        color: var(--white-color);
                    }

                    > span {
                        font-size: 13px;
                        line-height: 1.3;
                    }

                    &.active {
                        &.in-progress {
                            rl-icon {
                                background: var(--general-text-inactive-color);
                            }
                        }

                        rl-icon {
                            filter: drop-shadow(0px 6px 15px rgba(0, 0, 0, 0.15));
                        }

                        > span {
                            font-weight: bold;
                        }
                    }
                }

                [icon=save] {
                    background: var(--confirmation-color);
                }

                [icon=help] {
                    background: var(--alert-color);
                }

                [icon=close] {
                    background: var(--warning-color);
                }
            }

            .show-continue-form {
                &.sign-up-enabled {
                    margin-left: 15px;

                    @media @mobile {
                        margin-left: 0;
                    }
                }

                @media @tablet-max {
                    margin-top: 15px;
                }

                @media @desktop-up {
                    min-width: 250px;
                }

                button {
                    display: flex;
                    align-items: center;
                    text-transform: uppercase;

                    > * {
                        margin-left: auto;
                    }
                }

                rl-icon {
                    opacity: 0.6;
                }
            }
        }

        .expired {
            margin-top: 15px;
            text-align: center;

            @media @desktop-up {
                &:not(.no-button) {
                    text-align: left;
                }
            }
        }

        .sign-up {
            display: flex;

            .spots {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                padding: 0 10px;
                box-sizing: border-box;
                width: 100px;
                text-align: center;

                > span {
                    font-size: 13px;
                    line-height: 17px;
                    color: ~'rgba(var(--general-text-color-rgb, 0.4))';
                }
            }

            rl-button {
                flex: 1;

                &:not(:first-child) {
                    margin-left: 10px;
                }

                &.signed-up {
                    button {
                        > * {
                            margin-left: auto;
                        }
                    }
                }

                button {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-transform: uppercase;

                    @media @mobile-max {
                        min-width: 120px;
                    }

                    rl-icon {
                        opacity: 0.5;
                    }
                }
            }
        }
    }

    rl-loading-placeholder {
        padding: 22px 10px 20px 10px;
    }

    .deadline {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 22px 10px 20px 10px;

        .deadline-icon {
            margin-right: 10px;
            color: var(--general-text-color);
        }
    }
}

