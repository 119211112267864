@import "../Variables";

inline-selector {
    display: block;
    padding: 10px 0;

    label {
        font-size: 14px;
        font-weight: 500;
        padding: 0 8px;
    }

    ul {
        margin: 0;
        padding: 8px 8px 5px;
        list-style: none;
        display: flex;
        flex-direction: row;
        align-items: center;
        overflow-x: auto;
        width: calc(~'100% - 24px');

        li {
            border-radius: 17px;
            padding: 3px;
            min-width: 30px;
            height: 30px;
            line-height: 30px;
            text-align: center;
            background: ~'rgba(var(--general-text-color-rgb), .1)';
            color: var(--general-text-color);
            cursor: pointer;
            margin-right: 8px;
            flex-shrink: 0;

            &.selected {
                color: var(--text-on-highlight-color);
                background: var(--highlight-color);
            }
        }
    }
}