training-voluntary-category {
    .bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 220px;
    }

    header {
        position: relative;
        display: flex;
        justify-content: center;
        padding-top: 25px;

        .image {
            width: 87px;
            height: 87px;
            border-radius: 50%;
            box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.25);
        }
    }

    section {
        position: relative;
        padding-top: 25px;

        &:not(:last-of-type) {
            margin-bottom: 25px;
        }

        > h2 {
            margin: 0 0 10px 10px;
            color: var(--general-text-color);
            font-size: 15px;
            line-height: 20px;
            font-weight: 600;
        }
    }

    .related-categories > div {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;

        margin: 0 10px 10px 10px;

        overflow-x: auto;
        -webkit-overflow-scrolling: touch;

        @media @desktop-up {
            flex-wrap: wrap;
            margin: 0 5px;
        }

        training-voluntary-category-item {
            width: 40%;
            height: 80px;
            flex-shrink: 0;

           @media @tablet-max {
               &:not(:last-of-type) {
                   margin-right: 10px;
               }
           }

            @media @tablet-up {
                width: 25%;
            }
            @media @desktop-up {
                margin: 0 5px 10px 5px;
            }


            .image {
                width: 75px;
                height: 75px;
                bottom: -30px;
                right: -15px;
            }
        }
    }
}