@keyframes animOpacity {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes animBlink {
    80% {
        opacity: 1;
    }
    90% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes animPinPulse {
    0% {
        opacity: 1;
    }
    30% {
        opacity: 0.25;
        transform: scale(0.8);
    }
    60% {
        opacity: 1;
    }
}

pin-code, pin-code-welcome-page, pin-code-secure-page {
    .pin-code-input-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .pin-code-number {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 55px;
        height: 55px;

        border-radius: 100%;
        background: ~'rgba(var(--general-text-color-rgb), .1)';

        &:not(:last-of-type) {
            margin-right: 10px;
        }

        &:after {
            content: '';
            display: block;
            width: 100%;
            height: 100%;

            box-sizing: border-box;
            border-radius: 100%;
        }

        &.set {
            background: none;

            &:after {
                width: 45%;
                height: 45%;

                background: var(--highlight-color);
                animation: animOpacity 0.3s ease;
            }
        }

        &.active {
            background: var(--general-background-color);

            &:after {
                border: 2px solid var(--highlight-color);
                animation: animBlink 2s ease infinite;
            }
        }
    }
}

pin-code {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    .pin-code-input {
        width: 250px;

        &.error {
            .pin-code-number {
                &.set:after {
                    background: var(--warning-color);
                }
            }
        }

        &.success {
            .pin-code-number {
                &.set:after {
                    background: var(--confirmation-color);
                    animation: animPinPulse 1.5s ease infinite;
                }
            }
        }
    }

    .tip {
        margin: 15px 0 0 0;
        font-size: 15px;
        text-align: center;
    }

    .keyboard {
        display: flex;
        flex-wrap: wrap;

        width: 100%;
        box-shadow: 0 2px 15px 0 ~'rgba(var(--general-text-color-rgb), .1)';
        box-sizing: border-box;
    }

    .key {
        display: flex;
        align-items: center;
        justify-content: center;

        flex-basis: (100/3%);
        height: 55px;

        font-size: 18px;
        font-weight: 500;

        background: var(--general-background-color);
        border-right: 1px solid ~'rgba(var(--general-text-color-rgb), .1)';
        border-bottom: 1px solid ~'rgba(var(--general-text-color-rgb), .1)';
        box-sizing: border-box;
        cursor: pointer;
        user-select: none;

        &:active {
            background: ~'rgba(var(--general-text-color-rgb), .1)';
            transition: background-color 0.3s ease;
        }

        &:nth-child(3n) {
            border-right: none;
        }

        &:nth-child(n + 10) {
            border-bottom: none;
        }

        &.ok {
            color: var(--highlight-color);
        }
    }
}
