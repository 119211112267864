create-filling {
    display: flex;
    flex: 1;

    .popup-wrapper {
        z-index: 205;
    }
}

create-filling, update-filling {
    .main {
        position: relative;
        padding: 15px 15px 0 15px;
        overflow-y: auto;
        overflow-x: hidden;
        height: 100%;
    }

    .step {
        position: absolute;
        left: 15px;
        width: calc(100% - 30px);
        padding-bottom: 76px;
        padding-bottom: calc(76px + env(safe-area-inset-bottom, 0px));
    }

    section.next .step {
        &.ng-hide-remove {
            animation: fadeInRight 0.3s;
        }

        &.ng-hide-add {
            animation: fadeOutLeft 0.3s;
        }
    }

    section.prev .step {
        &.ng-hide-remove {
            animation: fadeInLeft 0.3s;
        }

        &.ng-hide-add {
            animation: fadeOutRight 0.3s;
        }
    }

    .form-group {
        &:first-of-type {
            h3 {
                margin-top: 0;
            }
        }

        > h3 {
            font-size: 21px;
            font-weight: bold;
            margin-top: 65px;
            margin-bottom: 15px;

            p {
                margin: 0;
            }
        }
    }

    .guide {
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        min-height: 100%;

        .outro {
            text-align: left;
            justify-content: normal;
        }

        img, video, iframe {
            max-width: 100%;
        }

        video {
            background: grey;
        }

        button {
            min-width: 100px;
        }

        .linked-forms {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            width: 100%;
            margin: 30px -10px 15px -10px;

            &:before {
                content: '';
                display: block;
                width: 150%;
                margin: 0 -50px;
                background: #ededed;
                height: 1px;
                position: relative;
                top: -15px;
            }

            .form-item {
                display: flex;
                align-items: center;
                justify-content: center;
                box-sizing: border-box;
                flex-direction: row-reverse;

                width: 100%;
                margin: 10px 0;
                padding: 10px 25px;
                background: var(--highlight-color);
                color: var(--text-on-highlight-color);
                font-size: 18px;

                cursor: pointer;

                h2 {
                    margin: 0;
                    padding: 0;
                    font-size: 16px;
                }

                p {
                    margin: 0;
                    padding-bottom: 5px;
                }

                img {
                    max-height: 30px;
                    margin: 0 5px;
                }
            }
        }
    }

    footer {
        display: flex;
        justify-content: space-between;
        align-items: center;

        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;

        padding-bottom: 30px;
        padding-bottom: calc(30px + env(safe-area-inset-bottom, 0px));


        .right {
            display: flex;
            margin-left: auto;
            margin-right: 15px;
        }

        .loop {
            height: 40px;
            line-height: 40px;
            margin: 0 0 0 15px;
            filter: drop-shadow(0 6px 15px rgba(0, 0, 0, 0.15));
            color: var(--general-text-color);
            background: var(--background-highlight-color);
            border-radius: var(--general-border-radius);
            border: 1px solid var(--white-color);
            padding: 0 10px;

            label {
                font-size: 15px;
            }

            input {
                margin: 5px;
            }
        }

        &.single {
            justify-content: center;
        }

        span {
            padding-top: 5px;
            font-size: 15px;
        }

        button {
            display: flex;
            justify-content: center;
            align-items: center;

            width: 46px;
            height: 46px;
            padding: 0;

            color: var(--general-text-color);
            background: var(--background-highlight-color);
            border-radius: var(--general-border-radius);
            border: 1px solid var(--white-color);

            &.submit {
                width: auto;
                padding: 0 25px;
                font-weight: bold;
                background: var(--highlight-color);
                color: var(--text-on-highlight-color);
            }

            &.btn-close {
                width: auto;
                padding: 0 25px;
                font-weight: bold;
            }
        }

        &.no-steps {
            button.submit {
                border-top-left-radius: var(--general-border-radius);
                border-bottom-left-radius: var(--general-border-radius);
            }
        }
    }
}

.service-form-field-wrapper {
    position: relative;
    padding-bottom: 15px;

    &.disabled {
        &:after {
            content: '';
            position: absolute;
            z-index: 30;

            top: 0;
            right: -15px;
            bottom: 0;
            left: -15px;

            background: ~'rgba(var(--general-text-color-rgb), 0.1)';
        }

        .participant {
            padding-top: 10px;
        }
    }

    .participant {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 5px;

        .left {
            display: flex;
            align-items: center;
        }

        profile-image {
            line-height: 0;

            .profile-image {
                min-width: 26px;
                min-height: 26px;
            }
        }
    }

    .border {
        display: none;
    }
}