@import "../../Import.less";

.form-module-question-media{
    table{
        margin: 10px 0 20px 0;
        tr{
            td{
                border-bottom: 1px solid rgba(0,0,0,0.06);
                padding: 14px 0;

                &.td-image{
                    width: 50px;
                    .image-container{
                        display: block;
                        width: 40px;
                        height: 40px;
                        overflow: hidden;
                        border-radius: 100%;
                        box-sizing: border-box;
                        border: 1px solid rgba(0,0,0,0.12);
                    }
                }
                &.td-desc{
                    p{
                        display: block;
                        margin: 0;
                        padding: 0;
                        font-size: .8rem;
                        color: rgba(0,0,0,0.5);
                    }
                }
                &.td-upload{
                    text-align: right;
                    media-upload{
                        display: inline-block;
                        height: 30px;
                        line-height: 30px;
                        background: rgba(0,0,0,0.06);
                        border-radius: 5px;
                        padding: 0 15px;
                        text-align: center;

                        span{
                            color: rgba(0,0,0,0.5);
                        }
                    }
                }
            }

            &:last-child td{
                border-bottom-color: transparent;
            }
        }
    }
}