my-training {
    > section {
        padding: 20px 0;
    }

    rl-loading-placeholder {
        display: block;

        margin: 10px;
        padding: 10px;

        background-color: var(--background-highlight-color);
        border-radius: var(--training-border-radius);

        &:first-of-type {
            margin-top: 0;
        }

        @media @desktop-up {
            margin: 10px 0;
        }

        .placeholder-shimmer {
            height: 19.5px;

            &:not(:last-of-type) {
                margin-bottom: 17px;
            }
        }
    }

    .phase {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 15px;

        &:before {
            content: '';

            position: absolute;
            top: 19px;

            width: 100%;
            height: 1px;
            background: radial-gradient(circle, rgba(0, 0, 0, 0) 0%, rgba(var(--general-text-color-rgb), 0.1) 50%, rgba(0, 0, 0, 0) 100%);
        }

        .phase-number {
            position: absolute;
            top: 12px;
            font-size: 13px;
            font-weight: 600;
            color: var(--text-on-white-color);
        }

        rl-icon {
            position: relative;
            font-size: 34px;
            color: var(--alert-color);
        }

        h2 {
            text-transform: uppercase;
            padding-top: 7px;

            margin: 0 10px;

            text-align: center;
            line-height: 1.5;
            font-size: 15px;
            font-weight: 600;
        }
    }

    .phase-item {
        .top {
            .icon-wrapper {
                position: relative;
                margin-right: 10px;

                .icon {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    position: relative;
                    width: 80px;
                    height: 80px;

                    border-radius: 50%;

                    background-color: var(--general-text-color);

                    [icon="crown"] {
                        font-size: 34px;
                        color: rgba(var(--general-background-color-rgb), 0.4);
                    }
                }

                state-icon {
                    display: flex;
                    position: absolute;
                    bottom: 0;
                    right: 0;

                    rl-icon {
                        &.new {
                            span {
                                top: 0;
                            }
                        }
                    }
                }
            }

            h3 {
                margin: 0;
            }
        }
    }

    training-learn-more-component {
        margin: 10px;

        @media @desktop-up {
            margin: 10px 0;
        }
    }

    .title {
        display: flex;
        flex-direction: row;

        .title-phase {
            margin-right: 5px !important;
        }
    }
}