no-events {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    rl-icon {
        width: 98px;
        height: 98px;

        font-size: 33px;
        border-radius: 50%;
        background-color: var(--background-highlight-color);
    }
}