chat-settings {
    .popup-wrapper .popup-content {
        background: var(--background-highlight-color);
    }

    .scroll-container {
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        padding: var(--spacing);

        .profile-image-section {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            border: none;

            .profile-image-wrapper {
                position: relative;
                display: inline-block;
                padding: 0 0 5px 0;

                .channel-image {
                    background: var(--background-highlight-color);
                    width: 105px;
                    height: 105px;
                    min-width: 105px;
                    border-radius: 50%;
                    padding: 0;

                    .profile-image {
                        width: 100%;
                        height: 100%;
                    }
                }

                .group-image {
                    position: relative;
                    border-radius: 50%;
                    z-index: 5;
                    overflow: hidden;
                    width: 105px;
                    height: 105px;
                    min-width: 105px;
                    background-color: var(--background-highlight-color);

                    profile-image {
                        padding: 0;
                    }

                    .channel-image {
                        &:nth-child(2),
                        &:nth-child(3) {
                            position: absolute;
                            min-width: 0;
                            top: 0;
                            width: 50%;
                            left: 50%;
                            border-left-width: 0.4px;
                            border-left-style: solid;

                            .profile-image {
                                border-radius: 0;
                            }
                        }
                    }

                    &.group-image-2 {
                        .channel-image {
                            &:nth-child(2) {
                                border-radius: 0 50% 50% 0;
                            }
                        }
                    }

                    &.group-image-3 {
                        .channel-image {
                            &:nth-child(2) {
                                height: 50%;
                                border-radius: 0 50% 0 0;
                            }

                            &:nth-child(3) {
                                height: 50%;
                                top: 50%;
                                border-radius: 0 0 50% 0;
                                border-top-width: 0.4px;
                                border-top-style: solid;
                            }
                        }
                    }
                }

                .edit-group-image,
                media-upload {
                    position: absolute;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    top: 0;
                    right: 0;
                    width: 31px;
                    height: 31px;
                    padding: 0;
                    z-index: 6;
                    background: var(--white-color);
                    color: var(--text-on-white-color);
                    border-radius: 100%;
                    box-shadow: 0 2px 10px 0 var(--general-border-color);

                    rl-icon {
                        font-size: 15px;
                    }
                }

                profile-image {
                    padding: 0;
                    width: 105px;
                    height: 105px;
                    border-radius: 50%;
                    background-color: var(--background-highlight-color);
                    box-shadow: 0 2px 10px 0 var(--general-border-color);

                    .profile-image {
                        height: 105px;
                        width: 105px;
                    }
                }
            }
        }
    }

    .setting-label,
    .setting-item {
        background: var(--general-background-color);
        padding: var(--m-spacing);
        margin: 0 0 var(--spacing);
        position: relative;
        border-radius: var(--general-border-radius);

        .users-count {
            display: flex;
            justify-content: space-between;

            button {
                color: var(--highlight-color);
                font-weight: 600;
            }
        }

        input {
            width: 100%;
            border: none;
            box-sizing: border-box;
        }

        .leave-icon,
        .users-icon {
            width: 1.25rem;
            height: 1.25rem;
            margin-right: var(--m-spacing);
            font-size: 1.1rem;
        }

        .arrow-icon {
            font-size: 1rem;
            transform: rotate(-90deg);
            color: var(--general-text-inactive-color);
            margin-left: auto;
        }

        .btn-leave,
        .btn-members {
            display: flex;
            align-items: center;
            width: 100%;
            padding: 0;
            font-weight: 400;
            font-size: 1rem;
        }

        .btn-leave {
            color: var(--warning-color);
        }

        .chat-title {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: var(--s-spacing);

            input {
                font-size: 1.15rem;
                color: var(--general-text-color);
                font-weight: 400;
                text-overflow: ellipsis;
                padding-left: 0;
                background: inherit;

                &:disabled {
                    background: inherit;
                }
            }

            rl-icon, .spinner {
                z-index: 10;
                color: var(--general-text-inactive-color);
                font-size: 1rem;
                cursor: pointer;

                &[icon=save] {
                    font-size: 1.25rem;
                    opacity: 1;
                    color: var(--highlight-color);
                }
            }

            .spinner {
                width: 0.9rem;
                height: 0.9rem;
                display: none;
            }

            &.fetching {
                .spinner {
                    display: block;
                }
            }
        }

        .chat-notifications {
            display: flex;
            justify-content: space-between;
            align-items: center;

            label {
                display: flex;
                align-items: center;
                gap: var(--m-spacing);
                font-size: 1rem;
                flex-grow: 1;

                &:hover {
                    cursor: pointer;
                }
            }
        }
    }

    .setting-label {
        margin: 0;
        padding: 0 0 var(--s-spacing) var(--m-spacing);
        font-size: 0.75rem;
        color: var(--general-text-inactive-color);
        background: var(--background-highlight-color);
    }

    .members-label {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .btn-add {
        button {
            font-size: 0.8rem;
            font-weight: bold;
            height: 30px;
            padding: 8.5px 10px;
        }
    }

    .setting-footer {
        font-size: 0.8rem;
        color: var(--general-text-inactive-color);
    }

    .setting-footer {
        line-height: 1.15rem;

        span {
            display: block;
        }
    }
}
