@import "../Import.less";

[read-more] {
    position: relative;
    -moz-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;

    & + .readmore {
        background: none;
        border: none;
        width: 100%;
        position: relative;
        display: block;
        text-align: center;
        height: 30px;
        line-height: 30px;
        color: var(--general-text-color);
        font-weight: 600;
        text-transform: uppercase;
        font-size: 12px;
        margin: 20px 0 10px 0;
        padding-top: 0;
        border-top: 1px solid transparent;
        transition: all .3s ease-in-out;
        opacity: 0.5;

        &:after {
            display: block;
            width: 20px;
            height: 20px;
            line-height: 20px;
            font-family: @icon-fa;
            content: '\f0ab'; /*fa-circle-arrow-down*/
            position: absolute;
            bottom: -10px;
            color: #000;
            left: 50%;
            margin-left: -10px;
            text-align: center;
        }

        &.open {
            border-top: 1px solid #000;
            padding-top: 10px;

            &:after {
                content: '\f0aa'; //fa-arrow-circle-up
            }
        }

        &.simple {
            border-top: none !important;
            padding-top: 0 !important;

            &:after {
                display: none !important;
            }
        }
    }
}
