@import "../../../Content/Styles/Import.less";
@import "PinCode/PinCode";
@import "SetNewPin/SetNewPin";
@import "PinCodeHint/PinCodeHint";
@import "EnterPinCode/EnterPinCode";
@import "ResetPinPopup/ResetPinPopup";
@import "PinCodeWelcomePage/PinCodeWelcomePage";
@import "PinCodeSecurePage/PinCodeSecurePage.less";
@import "PinProfile/PinProfile";
@import "PinCodeBiometricSetup/PinCodeBiometricSetup";
@import "CreatePinBiometricPopup/CreatePinBiometricPopup";
@import "SwitchUserPopup/SwitchUserPopup.less";

pin-code-page {
    display: block;
    background: var(--background-highlight-color);

    position: fixed;
    z-index: 500;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    pin-code-hint {
        .open {
            position: absolute;
            top: 15px;
            right: 15px;
        }
    }

    .wrapper {
        box-sizing: border-box;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;

        @media @tablet-up {
            display: flex;
            flex-direction: column;
            justify-content: center;

            margin: 0 auto;
            padding: 50px;
            max-width: 600px;
            > * {
                height: 85%;
            }
        }
    }
}

pin-code-page {
    pin-code {
        flex: 1;
        width: 100%;
    }

    action-sheet-custom {
        @media @desktop-up {
            margin-left: 0;
        }
    }
}