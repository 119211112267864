training-introduction {
    display: block;
    padding: 0 10px;

    @media @desktop-up {
        padding: 0;
    }

    .introduction {
        position: relative;
        display: block;
        border-radius: var(--training-border-radius);
        background: var(--background-highlight-color);

        .introduction-top {
            display: flex;
            justify-content: space-between;
            align-items: center;

            height: 65px;
            padding: 0 10px;
        }

        .introduction-title {
            overflow: hidden;
            padding-right: 10px;

            h2, h3 {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                margin: 0;
            }
        }

        .introduction-toggle {
            display: flex;
            height: 25px;
            width: 25px;
            border-radius: 15px;
            justify-content: center;

            transition: all ease-in-out .2s;
            background: ~'rgba(var(--general-text-color-rgb), 0.4)';
            color: var(--general-background-color);
            backdrop-filter: blur(10px);
            box-shadow: 0 5px 7px 0 ~'rgba(var(--general-text-color-rgb), 0.1)';
        }

        media {
            overflow: hidden;
            margin: 10px 10px 0 10px;

            border-radius: var(--general-border-radius);
            box-shadow: 0 5px 7px 0 ~'rgba(var(--general-text-color-rgb), 0.1)';
        }

        .introduction-inner {
            padding: 20px 10px;
        }
    }
}