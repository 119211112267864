@import "../Import.less";

@table-line-height: 45px;

table {
    width: 100%;
    border-spacing: 0;
    th, td{ 
        padding: 0; 
        &.right-align{
            text-align: right !important;
        }
    }

    &.simple {
        th, td{
            padding: 0 3px;
            &.text-center {
                text-align:center!important;
            }
        }
        thead {
            th {
                text-transform: uppercase;
                text-align: left;
                line-height: 30px;
            }
        }

        tbody {
            td {
                font-size: 0.9em;
                text-align: left;
                height: @table-line-height;
                line-height: @table-line-height;
                position: relative;
                padding: 0;
                word-break: break-word;

                &:after {
                    display: table;
                    clear: both;
                    content: '';
                }
                &.image{
                    padding-right: 5px;
                    width: 30px;

                    .profile-image{
                        width: 30px;
                        height: 30px;
                        vertical-align: middle;
                    }
                }

                .name,
                .title {
                    display: block;
                    vertical-align: middle;
                    overflow: hidden;
                    position: relative;
                    top: 0;
                    left: 0;
                    right: 0;
                    min-height: 18px;
                    line-height: 18px;
                    padding: 0;
                    margin: 0;
                }
                .name{
                    top: 5px;
                    &.only{
                        height: auto;
                        line-height: normal;
                        top: 0;
                    }
                }
                .title{
                    font-size: 0.8em;
                    opacity: 0.85;
                }

                .round-image {
                    height: 30px;
                    width: 30px;
                    background-position: center center;
                    background-size: cover;
                    background-repeat: no-repeat;
                    border-radius: 50%;
                    display: block;
                    position: relative;
                }
            }
        }
    }

    &.lines{
        thead, tbody{
            th, td{
                border-bottom: 1px solid @split-color;
            }
        }
    }

    &.padded{
        tbody, thead{
            tr th, tr td{
                &:first-child{ padding-left: 10px; }
                &:last-child{ padding-right: 10px; }
            }
        }
    }
}