@import "../Import.less";

.news-ticker {
    height: @news-ticker-height;
    line-height: @news-ticker-height;
    background: var(--general-background-color);
    position: relative;
    z-index: 2;
    overflow: hidden;
    max-height: @news-ticker-height-mobile;


    @media @tablet-max {
        height: @news-ticker-height-mobile;
        line-height: @news-ticker-height-mobile;
    }

    .news-ticker-holder {
        height: @news-ticker-height;
        line-height: @news-ticker-height;
        display: block;
        min-width: 100%;
        text-align: center;
        white-space: nowrap;
        padding-bottom: 20px;

        @media @tablet-max {
            height: @news-ticker-height-mobile;
            line-height: @news-ticker-height-mobile;
        }

        .news-ticker-holder-inner {
            display: inline-block;
            vertical-align: top;

            .news-ticker-tick {
                display: inline-block;
                vertical-align: top;
                height: @news-ticker-height;
                line-height: @news-ticker-height;
                margin: 0 5px;

                @media @tablet-max {
                    height: @news-ticker-height-mobile;
                    line-height: @news-ticker-height-mobile;
                }
            }
        }

        &:not(.scrollable) {
            .news-ticker-holder-inner {
                text-align: center;
            }
        }

        &.scrollable {
            overflow-x: scroll;
            overflow-y: hidden;

            .news-ticker-holder-inner {
                padding-left: 100%;
                animation: newstick 1s linear infinite;
            }
        }
    }
}

@-moz-keyframes newstick {
    0% {
        transform: translate(0, 0);
    }

    100% {
        transform: translate(-100%, 0);
    }
}

@-webkit-keyframes newstick {
    0% {
        transform: translate(0, 0);
    }

    100% {
        transform: translate(-100%, 0);
    }
}

@keyframes newstick {
    0% {
        transform: translate(0, 0);
    }

    100% {
        transform: translate(-100%, 0);
    }
}
