// VARS
@circle-width: 0.14em;
@circle-width-hover: 0.07em;

// colors default
@primary-circle-color: var(--highlight-color);
@secondary-circle-color: ~'rgba(var(--general-text-color-rgb), .1)';
@circle-bg-color: var(--background-highlight-color);


// CIRCLE
// classes 2 extend
.rect-auto {
    clip: rect(auto, auto, auto, auto);
}

.pie {
    position: absolute;
    border: @circle-width solid @primary-circle-color;
    width: 1 - (2 * @circle-width);
    height: 1 - (2 * @circle-width);
    clip: rect(0em, 0.5em, 1em, 0em);
    border-radius: 50%;
    transform: rotate(0deg);
}

.pie-fill {
    transform: rotate(180deg);
}

// main
.c100 {

    *, *:before, *:after {
        box-sizing: content-box;
    }

    position: relative;
    font-size: 120px;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    float: left;
    background-color: @secondary-circle-color;

    // center circle to its parent
    &.center{
        float: none;
        margin: 0 auto;
    }

    // bigger size
    &.big{
        font-size: 240px;
    }

    // smaller size
    &.small{
        font-size: 24px;
    }

    // centered value inside circle
    > span {
        position: absolute;
        width: 100%;
        z-index: 1;
        left: 0;
        top: 0;
        width: 5em;
        line-height: 5em;
        font-size: 0.2em;
        color: @secondary-circle-color;
        display: block;
        text-align: center;
        white-space: nowrap;
        transition-property: all;
        transition-duration: 0.2s;
        transition-timing-function: ease-out;
    }

    // background inside the circle
    &:after{
        position: absolute;
        top: @circle-width;
        left: @circle-width;
        display: block;
        content: " ";
        border-radius: 50%;
        background-color: @circle-bg-color;
        width: 1 - (2 * @circle-width);
        height: 1 - (2 * @circle-width);
        transition-property: all;
        transition-duration: 0.2s;
        transition-timing-function: ease-in;
    }

    // the slice (mask)
    .slice {
        position: absolute;
        width: 1em;
        height: 1em;
        clip: rect(0em, 1em, 1em, 0.5em);
    }

    // circle to show the status
    .bar {
        &:extend(.pie);
    }

    // loop to create all needed elements automatically
    .generate-elements(@n, @i: 1) when (@i =< @n) {
        &.p@{i} {
            & .slice {
                &:extend(.rect-auto);
            }
            & .bar:after{
                &:extend(.pie-fill);
            }
            & .fill{
                &:extend(.pie);
                &:extend(.pie-fill);
            }
        }
        .generate-elements(@n, (@i + 1));
    }
    // loop to rotate all 100 circles
    .generate-rotation(@n, @i: 1) when (@i =< @n) {
        &.p@{i} .bar {
            @degs: (360/100*@i);
            transform: rotate(~"@{degs}deg");
        }
        .generate-rotation(@n, (@i + 1));
    }

    .generate-elements(100, 51);
    .generate-rotation(100, 1);
}