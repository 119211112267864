cancel-recurring-event-popup {
    .action-popup {
        width: 90%;
        max-width: 270px;

        .action-popup-inner {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
        }

        .content {
            padding: 16px 20px;
            text-align: center;

            h3 {
                margin: 0;
            }

            p {
                margin: 0;
            }
        }

        rl-button {
            display: block;
            border-top: 0.5px solid var(--general-border-color);
        }
    }
}