@import "../Import.less";

.highscore {
    background: #f5f5f5;

    .tab {
        padding: 10px 0 0 0;

        table {
            margin-top: 20px;

            th {
                &.ranking {
                    text-align: center;
                }

                border-bottom: 1px solid #eee;
                padding-left: 5px;
            }

            td {
                line-height: 20px;

                &.ranking {
                    background-color: #eee;
                    width: 30px;
                    text-align: center;
                }

                padding-left: 5px;
                border-bottom: 1px solid #eee;

                .round-image {
                    margin-left: 5px;
                }

                .department {
                    font-size: 10px;
                    opacity: 0.8;
                }
            }

            tr {
                &.highlight {
                    td {
                        background-color: #000 !important;
                        color: #fff !important;
                    }
                }
            }
        }
    }

    .header {
        font-size: 24px;
        text-transform: uppercase;
        margin: 20px;
        text-align: center;
    }

    .spinner-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}