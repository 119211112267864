bookmark-filters {
    display: flex;
    gap: var(--m-spacing);
    margin: var(--m-spacing) var(--spacing) 0;

    button {
        font-size: 0.8rem;
        height: 2.5rem;
        min-width: 45px;
        padding: 0 var(--m-spacing);
        border-radius: var(--general-border-radius);
        border: 1px solid var(--general-border-color);
        background-color: var(--background-highlight-color);

        &.active {
            background-color: var(--highlight-color);
            color: var(--text-on-highlight-color);
            border: 1px solid var(--highlight-color);
        }
    }
}