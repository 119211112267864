search-results {
    display: block;
    width: 100%;

    sorting {
        padding: 10px 0 5px;
        z-index: 9;
        font-size: 13px;
        position: sticky;
        top: 167px;
        background: var(--background-highlight-color);

        @media @desktop-up {
            top: 172px;
        }
    }

    .search-results-title {
        margin-top: 0;
        font-size: 13px;
        font-weight: 700;
        line-height: 1.3;
        margin-bottom: 0;
    }

    .search-results {
        .results-wrapper {
            opacity: 1;
            transition: opacity 0.3s ease-in-out;

            &.hidden {
                opacity: 0;
            }
        }
    }

    rl-search-result-item {
        margin: 10px 0;

        &:last-of-type {
            margin-bottom: 0;
        }
    }
}