@import "../Import.less";

.media-thumbnails-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.media-thumbnail-item-wrapper {
    position: relative;

    button {
        position: absolute;
        top: 0;
        left: 0;
        width: 26px;
        height: 26px;
        border: none;
        background: rgba(0,0,0,0.24);
        color: #fff;
        padding: 0;
        font-size: 14px;
    }

    .media-thumbnail {
        display: inline-block;
    }
}

.media-thumbnail {
    max-width: 100%;
    display: block;
    position: relative;
    overflow: hidden;

    .image {
        background-position: center center;
        background-size: cover;
        display: block;
        max-width: 100%;
    }

    .media-thumbnail-remove{
        position: absolute;
        z-index: 2;
        top: 0;
        right: 0;
        width: 26px;
        height: 26px;
        background: rgba(0,0,0,0.5);
        color: #fff;
        line-height: 26px;
        text-align: center;
        font-size: 14px;
        overflow: hidden;
    }
}