content-comments-popup {
    .loading-wrapper {
        background: ~'rgba(var(--background-highlight-color-rgb), 0.2)';
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: calc(~'100% - 55px');
        left: 0;
        top: 0;
        position: absolute;
        z-index: 10;
    }

    .content-comments {
        padding: 0 10px 5px;
        display: flex;
        width: 100%;
        flex-direction: column;
        height: calc(100% - 55px);
        box-sizing: border-box;
        min-height: 40px;

        .empty-list {
            flex: 1 1 auto;
            text-align: center;
            padding: 40px 0;

            rl-icon {
                font-size: 40px;
            }
        }

        .content-comments-list {
            position: relative;
            padding: 0;
            margin: 0;
            flex: 1 1 auto;
            overflow-y: auto;

            > li {
                display: block;
                position: relative;
                padding: 15px 0 10px 40px;
                border-bottom: 1px solid rgba(0, 0, 0, 0.1);

                &:last-child {
                    border-bottom: none;
                }

                .content-comments-actions {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding-right: 10px;
                    padding-top: 5px;

                    .action-buttons {
                        button {
                            padding: 0;
                        }

                        .content-comment-like {
                            &.liked {
                                color: var(--highlight-color);

                                rl-icon {
                                    color: var(--highlight-color);
                                }
                            }
                        }
                    }
                }

                profile-image {
                    position: absolute;
                    top: 20px;
                    left: 0;

                    > .profile-image {
                        height: 30px;
                        width: 30px;
                    }
                }

                h3 {
                    font-size: 1em;
                    margin: 0;
                    padding: 0 25px 0 0;
                }

                p {
                    display: block;
                    padding: 0;
                    margin: 3px 0;
                }

                .subtitle {
                    display: block;
                    margin-top: 5px;
                    font-size: .9em;
                    opacity: .7;
                }

                .comment-delete {
                    position: absolute;
                    right: 10px;
                    top: 20px;
                    background: none;
                    border: 0;
                    padding: 0;
                    font-size: inherit;
                }

                .comment-report {
                    position: absolute;
                    right: 0;
                    bottom: 17px;
                    height: 20px;
                    width: 34px;
                    display: block;
                    text-align: center;
                    color: #bc6565;
                    border: 0;
                    background: transparent;
                    font-size: 15px;

                    &:hover {
                        color: #9e2424;
                    }
                }
            }
        }
    }

    .content-comments-footer {
        flex: 0 0 auto;

        form {
            display: block;
            min-height: 40px;
            line-height: 40px;
            position: relative;
            -moz-box-sizing: border-box;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            padding: 0 65px 0 5px;

            input[type=text], textarea {
                display: block;
                width: 100%;
                -moz-box-sizing: border-box;
                -webkit-box-sizing: border-box;
                box-sizing: border-box;
                margin: 8px 0 0 0;
                height: 30px;
                line-height: 30px;
                padding: 0 5px;
                background: none;
                border: 1px solid #fff;
                border-color: lighten(@split-color, 5%);
                border-radius: 4px;
            }

            textarea {
                padding: 5px;
                line-height: initial;
                max-height: 100px;
            }

            button {
                position: absolute;
                top: 0;
                right: 5px;
                height: 30px;
                line-height: 30px;
                width: 55px;
                -moz-box-sizing: border-box;
                -webkit-box-sizing: border-box;
                box-sizing: border-box;
                background: none;
                color: darken(@split-color, 10%);
                border: none;
                text-align: center;
                padding: 0;
                display: inline-block;
            }

            .comment-posting {
                text-align: center;
                display: block;
                height: 30px;
                line-height: 30px;
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
            }
        }
    }
}