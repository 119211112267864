stage-content-popup {
    .main {
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow-y: auto;

        translation-button {
            padding: 15px 15px 0 15px;
            margin: 0;
        }
    }

    .status {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px 0;
        cursor: pointer;

        &.approved {
            color: var(--confirmation-color);
            background: ~'rgba(var(--confirmation-color-rgb), 0.2)';

            rl-icon {
                background: var(--confirmation-color);
                color: var(--white-color);
            }
        }

        &.rejected {
            color: var(--warning-color);
            background: ~'rgba(var(--warning-color-rgb), 0.2)';

            rl-icon {
                background: var(--warning-color);
                color: var(--white-color);
            }
        }

        .user-wrapper {
            position: relative;
            margin-right: 15px;
            height: 25px;

            .img {
                width: 25px;
                height: 25px;
                border-radius: 50%;
                overflow: hidden;
            }

            rl-icon {
                position: absolute;
                right: -5px;
                bottom: -1px;
                width: 12px;
                height: 12px;
                font-size: 8px;
            }
        }

        .text {
            font-size: 13px;
            font-weight: 600;
        }
    }

    filling-content {
        display: block;
        padding: 15px;
    }

    .approval-section {
        margin-top: auto;
        padding: 25px 15px 50px 15px;
        background: var(--background-highlight-color);

        text-align: center;

        h3 {
            margin: 0 0 32px 0;
        }

        approve-slider {
            margin: 0 auto;
        }
    }
}

prompt-popup {
    .stage-review {
        &.approve {
            rl-icon {
                box-shadow: 0 5px 7px ~'rgba(var(--confirmation-color-rgb, 0.3))';
            }

            rl-icon, rl-button button {
                background: var(--confirmation-color);
            }
        }

        &.reject {
            rl-icon {
                box-shadow: 0 5px 7px ~'rgba(var(--warning-color-rgb, 0.3))';
            }

            rl-icon, rl-button button:not([disabled]) {
                background: var(--warning-color);
            }
        }
    }

}
