badge-validity-popup {
    .action-popup {
        width: calc(100% - var(--spacing) * 2);
        max-width: calc(var(--max-width) - var(--spacing) * 2);

        @media @desktop-up {
            margin-left: calc(var(--sidemenu-width) / 2);
        }

        badge {
            margin-bottom: var(--m-spacing);

            .image-wrap {
                width: 100px;
            }
        }
    }

    .badge-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;

        padding: calc(var(--m-spacing) * 2) var(--spacing);
        border-bottom: 1px solid var(--general-border-color);

        p {
            font-size: 1.375rem;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            width: max-content;
            max-width: 280px;
            color: var(--elevated-text-color);
        }
        .go-to-training-btn-container {
            width: 250px;
            margin-top: var(--spacing);
        }

        .validity-badge-text {
            p {
                font-size: @font-size;
            }
        }
    }
}


