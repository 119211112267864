@import "../Import.less";

.popup-overlay {
    opacity: 0;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background: rgba(100,100,100,0.7);
    -moz-transition: opacity .3s ease-in-out;
    -o-transition: opacity .3s ease-in-out;
    -webkit-transition: opacity .3s ease-in-out;
    transition: opacity .3s ease-in-out;
    z-index: @level-popup;

    &.show{
        opacity: 1;
    }
}

.loading-overlay{
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background: rgba(100,100,100,0.7);
    transition:all ease-in-out .5s;
    z-index: @level-popup;
    .flex-center-center();

    .spinner{
        width: 30px;
        height: 30px;
        border-color: rgba(255,255,255, 0.5);
    }

    &.ng-enter,
    &.ng-leave.ng-leave-active{
        opacity: 0;
    }
    &.ng-leave,
    &.ng-enter.ng-enter-active{
        opacity: 1;
    }
}