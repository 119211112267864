survey-data-collection-status {
    display: flex;
    flex-direction: column;
    position: relative;

    padding: var(--spacing);
    gap: var(--m-spacing);

    background: var(--general-background-color);
    border-radius: var(--general-border-radius);
    box-shadow: 0 7px 15px 0 var(--shadow-color);

    .container {
        display: flex;
        flex-direction: row;
        align-items: center;

        h2 {
            font-size: 1rem;
            font-weight: 700;
            margin: 0;
            width: 70%;
        }
    
        h3 {
            font-size: 0.9rem;
            font-weight: 700;
            margin: 0;
            width: 30%;
            text-align: right;

            &.in-progress {
                color: var(--alert-color);
            }

            &.completed {
                color: var(--confirmation-color);
            }
        }
    }
    
    .status-description {
        font-size: .9rem;

        .status-description-enddate {
            margin: 0;
        }

        .status-description-enddate-detailed {
            margin: 0;
            font-size: 0.7rem;
            opacity: 0.5;
        }
    }
}