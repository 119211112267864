content-live-tile {
    display: flex;
    flex-direction: column;
    height: 100%;
    background: var(--background-highlight-color);

    > header {
        display: flex;
        justify-content: space-between;
        padding: 15px;

        h2 {
            display: flex;
            align-items: center;

            margin: 0;
            text-transform: uppercase;
            font-size: 15px;

            rl-badge {
                margin-left: 5px;
            }
        }
    }

    .content-scroll-container {
        padding: 0 15px 15px 15px;
        box-sizing: border-box;

        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
    }

    .categories {
        width: 100%;
        display: flex;
        overflow-x: auto;

        button {
            display: flex;
            align-items: center;
            padding: 10px;

            white-space: nowrap;
            background: var(--general-background-color);
            color: var(--general-text-color);

            &:not(:last-of-type) {
                margin-right: 20px;
            }

            &.selected {
                background: var(--highlight-color);
                color: var(--text-on-highlight-color);
            }

            rl-badge {
                margin-left: 10px;
            }
        }
    }

    .content-list {
        @media @desktop-up {
            margin: 0 auto;
            max-width: 60vw;
        }
    }

    content-item:first-of-type {
        margin-top: 0;
    }

    .no-content {
        position: static;
        margin-top: 0;
    }
}