.app-header-dropdown-wrapper {
    position: relative;
}

.app-header-dropdown-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 420;
    background: transparent;
    display: block;
    transition: all 0.1s;
    opacity: 0;

    &.show {
        opacity: 1;
    }
}

.app-header-dropdown {
    position: absolute;
    top: 55px;
    left: auto;
    right: -20px;
    bottom: auto;
    width: 375px;
    display: block;
    z-index: 421;
    background: none;
    text-align: left;

    &.show .app-header-dropdown-content {
        transform: translate3d(0, 0, 0);
    }

    .app-header-dropdown-content {
        overflow: hidden;
        position: relative;
        transform: translate3d(0, 5%, 0);
        transition: all 0.1s;
        border-radius: var(--general-border-radius);
        background: var(--general-background-color);
        box-shadow: 0px 7px 15px 0px rgba(60, 72, 88, 0.1);
    }

    .app-header-dropdown-body {
        display: block;
        max-height: 600px;
        margin: 0;
        box-sizing: border-box;
        flex: 1 1 auto;
        overflow-x: hidden;
        overflow-y: auto;
        position: relative;
    }

    .app-header-dropdown-header {
        display: block;
        
        app-header-dropdown-header{
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-direction: row;
            flex: 0 0 auto;
            border-bottom: 1px solid var(--general-border-color);
            box-sizing: border-box;
            font-weight: 600;
            position: relative;
            padding: 15px;
            min-height: 50px;

            &.no-justify {
                justify-content: unset;

                rl-icon + h2 {
                    margin-left: 5px;
                }
            }

            &.clickable {
                cursor: pointer;

                &:hover {
                    background: var(--background-highlight-color);
                }
            }

            rl-icon[icon="back"], rl-icon[icon="next"] {
                font-size: 16px;
            }
        }
        
        h2 {
            display: block;
            font-size: 15px;
            font-weight: 600;
            position: static;
            text-overflow: clip;
            text-transform: uppercase;
            margin: 0;
            padding: 0;
            line-height: 1rem;
        }
    }

    .app-header-dropdown-footer {
        display: block;

        app-header-dropdown-footer{
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-direction: row;
            flex: 0 0 auto;
            border-top: 1px solid var(--general-border-color);

            > button {
                padding: 15px;
                text-transform: uppercase;
                color: var(--highlight-color);
                font-weight: 600;
                font-size: 13px;
                cursor: pointer;
                
                &:only-child{
                    width: 100%;
                    flex: 1 0 auto;

                    &:hover{
                        background: var(--background-highlight-color);
                    }
                }
            }
        }
    }
}

