@import "../../../Import.less";

.ec-shopgun-page-view {
    ul.ec-shopgun-page-view-ul {
        list-style: none;
        padding: 10px 0;
        margin: 0;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        li {
            width: 50%;
            height: 220px;
            display: block;
            flex: 0 0 auto;

            @media @desktop-up {
                width: 33%;
            }

            @media @desktop-big-up {
                width: 25%;
            }

            button {
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 0;
                padding: 15px;
                box-sizing: border-box;
                text-align: center;
                width: 100%;
                height: 100%;
                position: relative;
                overflow: hidden;

                img {
                    display: inline-block;
                    max-width: 100%;
                    max-height: 100%;
                    height: auto;
                    width: auto;
                    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
                }
            }
        }
    }
}
