.profile-overview-page {
    background: var(--background-highlight-color);
    min-height: 100%;

    profile-overview {
        display: flex;
        flex-direction: column;
        height: auto;
    }
}

profile-overview,
.profile-overview-loading-container {
    display: block;
    position: relative;
    height: calc(100% - 55px);
    background: var(--background-highlight-color);

    .sub-header .highscore-level-badge {
        box-sizing: border-box;
        padding: 4px 6px 3px 6px;
        font-size: 11px;
        border-radius: 4px;
        text-transform: uppercase;
        letter-spacing: .7px;
    }

    .block badges-list {
        padding: 0 10px;
    }

    .user {
        padding: 15px;
        text-align: center;

        .info {
            h2 {
                margin: 0;
                min-height: 26px;
            }

            > p {
                margin: 0;
                min-height: 19.5px;

                &.bio {
                    padding-top: 15px;
                }
            }

            .text-loading {
                width: 100%;
                max-width: 200px;
                height: 18px;
                border-radius: var(--general-border-radius);
                margin: 0 auto 5px auto;
            }

            .leave-information {
                border-radius: var(--general-border-radius);
                gap: var(--s-spacing);
                background-color: var(--background-highlight-color);
                display: flex;
                padding: var(--m-spacing);
                margin: var(--m-spacing) 0;
                align-items: flex-start;

                .leave-text {
                    display: flex;
                    flex-direction: column;
                    gap: 3px;
                    text-align: start;
                }

                .read-more {
                    font-size: 0.875rem;
                    color: var(--general-text-inactive-color);
                    text-transform: lowercase;
                    cursor: pointer;
                }

                .leave-type {
                    font-weight: 600;
                }

                .leave-type,
                .leave-message {
                    font-size: 0.875rem;
                }

                .leave-message {
                    transition: height .2s ease-out;
                    position: relative;
                }
            }
        }

        .kpi {
            display: flex;
            margin-top: 25px;

            .kpi-item {
                display: flex;
                flex: 1;
                flex-direction: column;
                align-items: center;

                rl-icon {
                    box-shadow: 0 2px 10px 0 var(--general-border-color);
                    box-sizing: border-box;
                    border-radius: 100%;
                    font-size: 20px;
                    width: 40px;
                    height: 40px;
                    margin-bottom: 7px;
                    color: white;
                }

                p {
                    min-height: 19.5px;
                    margin: 0;
                }
            }

            .points {
                &:not([disabled]) {
                    cursor: pointer;
                }

                rl-icon {
                    background: var(--alert-color);
                }
            }

            .highscore-rank {
                &:not([disabled]) {
                    cursor: pointer;
                }

                rl-icon {
                    background: var(--warning-color);

                    span {
                        margin-top: 1px;
                    }
                }
            }

            .swipes rl-icon {
                background: var(--confirmation-color);
            }

            .likes rl-icon {
                background: var(--highlight-color);
            }

            &.kpi-loading-container {
                flex-direction: row;
                justify-content: space-around;

                .kpi-loading {
                    height: 80px;
                    width: 80px;
                    border-radius: var(--general-border-radius);
                }
            }
        }
    }

    .custom-fields {
        .user-custom-field {
            margin: 10px 0;
            font-size: 14px;

            td {
                width: 50%;
                padding: 7px;
            }

            .field-title {
                text-align: right;
                font-weight: 800;
            }
        }
    }

    .badges {
        training-badges {
            box-sizing: border-box;
            cursor: pointer;
            margin-top: 10px;
            padding: 0 10px;

            .training-badge {
                p {
                    display: block;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    word-spacing: initial;
                    font-size: 12px;
                }
            }
        }

        .no-badges {
            display: flex;
            align-items: center;
            padding: 20px 15px;

            rl-icon {
                display: flex;
                align-items: center;
                justify-content: center;
                min-width: 60px;
                min-height: 60px;
                margin-right: 15px;
                font-size: 30px;
                border-radius: 100%;
                background: ~'rgba(var(--general-text-color-rgb), 0.1)';
            }

            .description {
                h2 {
                    margin: 0;
                    padding-bottom: 5px;
                    font-size: 15px;
                    color: var(--general-text-color);
                }

                p {
                    margin: 0;
                    font-size: 13px;
                    color: var(--general-text-color);
                }
            }
        }
    }

    .user-logout {
        rl-icon[icon="logout"] {
            color: var(--highlight-color);
        }
    }
}

profile-overview,
.profile-overview-loading-container {
    .block.user {
        margin-top: 80px;
        overflow: visible;
    }
}

.profile-page,
.profile-overview-loading-container,
profile-overview,
profile-settings-personal-information {
    .profile-image-wrapper {
        position: relative;
        display: inline-block;
        margin-top: -70px;
        padding: 0 0 5px 0;

        .edit-user-image,
        media-upload {
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            top: 0;
            right: 0;
            width: 31px;
            height: 31px;
            padding: 0;
            background: var(--white-color);
            color: var(--text-on-white-color);
            border-radius: 100%;
            box-shadow: 0 2px 10px 0 var(--general-border-color);
            z-index: 1;

            rl-icon {
                font-size: 15px;
            }
        }

        profile-image {
            padding: 0;
            width: 105px;
            height: 105px;
            border-radius: 50%;
            background-color: var(--background-highlight-color);
            box-shadow: 0 2px 10px 0 var(--general-border-color);

            .leave-icon {
                width: 34px;
                height: 34px;
                font-size: 34px;
                bottom: 0;
                right: 0;
            }

            .profile-image {
                min-height: 105px;
                min-width: 105px;          
            }
        }
    }
}