activity-widget {
    padding: var(--spacing) var(--m-spacing);
    margin: var(--spacing) 0;
    background-color: var(--background-highlight-color);
    border-radius: var(--general-border-radius);

    .general-widget {

        .title {
            margin: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: var(--s-spacing);
            font-weight: 700;
            font-size: 1rem;
            line-height: 135%;
        }

        .stats-wrapper {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            margin: var(--spacing) 0 var(--s-spacing);
            padding: 0 var(--spacing);
        }

        .stat {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 0 var(--s-spacing);
            flex-basis: 30%;
            word-break: break-word;
            max-width: 100px;

            p {
                font-size: 1.65rem;
                font-weight: 700;
                line-height: 130%;
                margin: 0;
                text-align: center;
            }

            span {
                font-size: 0.85rem;
                font-weight: 400;
                color: var(--general-text-inactive-color);
                text-align: center;
            }
        }
    }

    .specific-widget {
        display: flex;
        align-items: center;
        gap: var(--s-spacing);

        .activity-indicator {
            width: 32px;
            height: 32px;
            display: grid;
            place-content: center;
        }

        .stats-wrapper {
            flex-grow: 1;

            p {
                text-overflow: ellipsis;
                overflow: hidden;
                text-wrap: nowrap;
                margin: 0;
                max-width: calc(100% - 20px);
            }
        }

        .percent {
            font-size: 1rem;
            font-weight: 700;
            line-height: 150%;
        }

        .count {
            font-size: 0.8rem;
            font-weight: 400;
            line-height: 135%;
            color: var(--general-text-inactive-color)
        }

        .info {
            width: 22px;
            height: 22px;
            display: grid;
            place-content: center;
            color: var(--general-text-inactive-color);
            position: relative;
        }

        tip { 
            z-index: 100;

            .tip-content {
                right: -8px;
                bottom: 0;
                max-width: 165px;
                padding: var(--m-spacing);
                border-radius: var(--general-border-radius);
                box-shadow: 0px 7px 15px 0px var(--shadow-color);
                background-color: var(--white-color);
                color: var(--text-on-white-color);

                &:after {
                    right: 5px;
                    bottom: -7px;
                    border-color: var(--white-color) transparent transparent;
                    left: unset;
                    border-width: 7px 7.5px 0 7.5px;
                }
            }
        }

    }
}