@import "Import.less";

body {
    &.no-scroll {
        overflow: hidden;
        width: 100%;
    }

    &.fixed {
        position: fixed;
    }

    &.lock-vertical-scrolling {
        overflow-y: hidden;
    }
}

//disable zoom on  
input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
select:focus,
.fr-element[contenteditable],
textarea:not(.custom) {
    font-size: 16px !important;
}

html {
    height: 100%;
    min-height: 100%;
    padding: 0;
    margin: 0;
}

body {
    height: 100%;
    min-height: 100%;
    font-family: @font-family;
    font-size: @font-size;
    line-height: 1.3;
    font-weight: @font-weight;
    color: var(--general-text-color);
    background-color: var(--general-background-color);
    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;
    overflow-x: hidden;
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: transparent;

    &.dyslexia-font {
        font-family: @dyslexia-font-family !important;

        p, a, input, textarea, button, select, .wysiwyg-result, .counter, span:not(.fas,.far,.fal,.fab) {
            font-family: @dyslexia-font-family !important;
        }

        h1, h2, h3, h4, h5, h6 {
            font-family: @dyslexia-font-family !important;
        }
    }

    h1, h2, h3, h4, h5, h6 {
        font-weight: @font-weight-headline;
    }

    &.no-user-select {
        user-select: none;
    }
}

* {
    outline: none;
}

button {
    color: inherit;
}

.main-view {
    height: 100%;
    min-height: 100%;

    .menu-bg {
        &:not(.native) {
            display: none;
        }

        &:not(.menu-opened) {
            display: none;
        }

        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        background: var(--sidemenu-background-color);
    }

    &.is-fixed-layout {
        z-index: 2;
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
    }
}

.loader-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: transparent;
    z-index: @level-loader-overlay;
    min-height: 100%;

    &.loader-overlay {
        display: none;
    }
}

.initial-load .loader-overlay {
    &.initial-loader-overlay {
        display: block;
        .flex-center-center();
    }

    .spinner {
        width: 50px;
        height: 50px;
        border-width: 5px;
        border-color: #fff;
        opacity: .5;
    }
}

input, textarea, button, select {
    font-family: inherit;
    font-weight: inherit;
}

button {
    border: none;
    background: transparent;
    font-size: @font-size;
}

.bold {
    font-weight: 600;
}

.lowercase {
    text-transform: lowercase;
}

.uppercase {
    text-transform: uppercase;
}

.headline-weight {
    font-weight: @font-weight-headline;
}

table th {
    font-weight: @font-weight-headline;
}

table thead th {
    font-weight: @font-weight-headline;
}

.visuallyhidden {
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
}

grammarly-extension {
    visibility: hidden;
}

.default-background {
    background-color: var(--general-background-color);
} 