@border-radius: 3px;
@service-form-filling-text-color: #3b3c40;

@import 'ScoreReport/ScoreReport';
@import 'FillingContent/FillingContent';
@import 'StageContentPopup/StageContentPopup';
@import 'StageFeedbackPopup/StageFeedbackPopup';
@import 'WantToRatePopup/WantToRatePopupComponent';
@import 'RatePopup/RatePopup';
@import 'FeedbackCommentsPopup/FeedbackCommentsPopup';

filling-preview-popup {
    header-popup {
        title-slot {
            display: flex;
            width: 100%;
            justify-content: center;

            .wrap {
                overflow: hidden;
            }
        }
    }

    .label {
        padding: 5px 10px;

        text-align: center;

        color: var(--text-on-highlight-color);
        text-transform: uppercase;
        font-weight: normal;

        &.draft {
            background: var(--highlight-color);
        }

        &.new {
            background: var(--confirmation-color);
        }
    }

    .popup-wrapper {
        .popup-content {
            .modal-loader {
                align-items: flex-start;
                border-top: 55px solid var(--header-background-color);

                &.inner {
                    border: none;
                }

                .shimmer-container {
                    padding: var(--m-spacing) var(--spacing);
                }
        
                .lazy-placeholder {
                    background-color: var(--background-highlight-color);
                    padding: 10px 0;
                }
            }
        }
    }

    filling-preview {
        flex: 1;
        overflow-y: auto;
        padding: 10px 15px;
    }

    filling-content {
        display: block;
        margin-top: 30px;
    }
    
    .feedback-buttons-wrapper {
        margin-top: 15px;
    }

    .continue-draft {
        padding: 15px;
    }
}

filling-preview-popup, stage-content-popup {
    .tabs {
        width: 100%;
        display: flex;

        div {
            display: flex;
            flex: 1;
            align-items: center;
            justify-content: center;
            box-sizing: border-box;
            height: 40px;

            font-size: 13px;
            line-height: 130%;
            color: rgba(var(--general-text-color-rgb), 0.4);
            border-bottom: 1px solid var(--general-border-color);

            &.active {
                font-weight: bold;
                color: var(--highlight-color);
                border-bottom: 3px solid var(--highlight-color);
            }
        }
    }
}

.filling-full-view {

    .filling-actions-footer {
        display: flex;
        flex-direction: column;
        padding-top: 15px;

        @media @desktop-up {
            flex-direction: row;
            justify-content: space-between;
        }

        .filling-actions-info {
            text-align: center;

            @media @tablet-max {
                border-bottom: 1px solid var(--general-border-color);
            }

            @media @desktop-up {
                text-align: left;
                button {
                    height: 50px;
                }
            }
        }

        .filling-actions {
            text-align: center;

            button {
                height: 50px;
                color: var(--text-on-white-color);
            }

            @media @desktop-up {
                text-align: right;
            }
        }
    }

    filling-actions {
        display: flex;

        button {
            width: 50%;
            height: 36px;
            margin: 10px 0;
            line-height: 36px;
            cursor: pointer;
            vertical-align: top;
            color: #fff;
            position: relative;
            overflow: hidden;
            border: none;
            text-transform: uppercase;

            &:not(:disabled):hover:after {
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                content: '';
                background: rgba(255, 255, 255, 0.1);
                cursor: pointer;
            }

            &[disabled] {
                opacity: .5;
            }

            &.delete {
                background: @color-red;
            }

            &.edit {
                background: #999;
            }
        }
    }
}

.service-form-filling-page {
    filling-preview {
        display: block;
        padding: 15px;

        -webkit-overflow-scrolling: touch;
    }
}

filling-preview, stage-content-popup {
    translation-button {
        margin-bottom: 15px;

        button {
            width: 100%;
            height: 50px;
            padding: 10px;
            box-sizing: border-box;
            align-items: center;

            border-radius: var(--general-border-radius);
            background: rgba(var(--highlight-color-rgb), 0.1);
            border: 1px solid rgba(var(--highlight-color-rgb), 0.2);
        }
    }
}


@import "./FillingContent/FillingContent";
@import "./ScoreReport/ScoreReport";
@import "./StageContentPopup/StageContentPopup";
@import "./StageFeedbackPopup/StageFeedbackPopup";
@import "./FeedbackInlineComments/FeedbackInlineComments";