reaction-icon {
    display: inline-block;

    svg {
        width: 100%;
        height: 100%;
    }
}

.reaction-label {
    &[data-type="1"] {
        color: var(--highlight-color);
    }

    &[data-type="5"] {
        color: #5fa3f1;
    }

    &[data-type="2"],
    &[data-type="6"] {
        color: #eb6262;
    }

    &[data-type="3"],
    &[data-type="4"] {
        color: #ffcc4d;
    }
}