lesson-disclaimer {
    .disclaimer {
        display: flex;
        align-items: center;
        gap: var(--spacing);
        padding: var(--spacing);
        background: rgba(var(--alert-color-rgb), 0.2);
        color: var(--general-text-color);

        &.lesson-done-success {
            background: rgba(var(--confirmation-color-rgb), 0.2);
        }

        &.expired {
            background: rgba(var(--general-text-color-rgb), 0.2);
        }

        p {
            margin: 0;
            padding: 0;
            text-align: left;
            font-size: 13px;
        }

        rl-icon {
            flex-shrink: 0;
            width: 20px;
            height: 20px;
            font-size: 12px;
            border-radius: 50%;
            color: var(--white-color);

            &[icon=save] {
                background: var(--confirmation-color);
            }

            &[icon=exclamation] {
                background: var(--alert-color);
            }

            &[icon=lock] {
                background: rgba(var(--general-text-color-rgb), 0.4);
            }

            &[icon=hourglass-end] {
                font-size: 13px;
                background: var(--general-text-color);
            }
        }
    }
}
