.profile-settings-menu {
    display: block;
    list-style: none;
    padding: 0;
    margin: 0;

    >li {
        list-style: none;
        display: block;
        width: 100%;

        button {
            display: flex;
            width: 100%;
            padding: 15px;
            margin: 0;
            color: var(--general-text-color);
            justify-content: flex-start;
            align-items: center;
            min-height: 49px;
            cursor: pointer;

            .text {
                line-height: 1rem;
                text-align: left;
                position: relative;
                word-wrap: break-word;
            }

            .text-container {
                display: flex;
                flex-direction: column;
                position: relative;
                margin: 0 0 -5px 0;

                .subtext {
                    font-size: 12px;
                    margin-top: 1px;
                    color: var(--general-text-inactive-color);
                    word-wrap: break-word;
                    line-height: 1rem;
                    position: relative;
                    text-align: left;

                    &:hover {
                        cursor: text;
                    }
                }
            }

            rl-icon:first-child {
                margin-right: 10px;
            }

            rl-icon:last-child {
                margin-left: auto;
            }

            rl-icon[icon="back"],
            rl-icon[icon="next"] {
                font-size: 16px;
            }

            &:hover {
                background: ~'rgba(var(--background-highlight-color-rgb), 0.5)';
            }
        }
    }
}

.profile-settings-page {
    background: var(--background-highlight-color);
    min-height: 100%;

    profile-settings {
        display: flex;
        flex-direction: column;
        height: auto;
    }
}

.profile-settings-edit-page {

    profile-settings-accessibility,
    profile-settings-language,
    profile-settings-celebrations,
    profile-settings-communication,
    profile-settings-shared-information,
    profile-settings-personal-information,
    profile-settings-security,
    profile-settings-security-password {
        display: flex;
        flex-direction: column;
        height: auto;
    }

    &.floating-footer {
        padding-bottom: 125px;
    }

    .profile-settings-edit-page-footer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 80px;
        padding: 0 15px;
        box-sizing: border-box;

        &.floating-footer {
            position: fixed;
            left: 0;
            right: 0;
            bottom: 49px;
            background: var(--general-background-color);
            box-shadow: 0px 10px 45px 35px var(--general-background-color);
        }

        rl-button button {
            min-width: 240px;
        }
    }

    @media @desktop-up {
        .profile-settings-edit-page-footer.floating-footer {
            bottom: 0;
            left: var(--sidemenu-width);
        }
    }
}

.profile-settings,
profile-overview {
    margin: 0 auto;
    width: 100%;
    max-width: var(--max-width);

    .block {
        margin: var(--spacing) var(--spacing) 0 var(--spacing);
        position: relative;
        overflow: hidden;

        &:first-of-type:not(.user) {
            margin-top: 20px;
        }

        &:last-of-type {
            margin-bottom: 20px;
        }

        border: 1px solid var(--general-border-color);
        border-radius: var(--general-border-radius);
        background: var(--general-background-color);

        header {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            min-height: auto;
            padding: 15px;
            border-bottom: 1px solid var(--general-border-color);

            h2 {
                margin: 0;
                font-size: 13px;
                text-transform: uppercase;
                font-weight: 700;
                color: var(--general-text-color);
            }

            button {
                padding: 0;
                font-size: 13px;
                color: var(--highlight-color);
                font-weight: 700;
            }
        }

        .user-profile-loading {
            border-radius: var(--general-border-radius);
            height: 25px;
            margin: 12.5px 15px;

            &:not(:first-of-type) {
                margin-top: 20px;
            }
        }
    }

    .info-block {
        margin: 0 10px;
        padding: 20px 10px;
        position: initial;
        overflow: initial;
        border-bottom: 1px solid var(--general-border-color);

        p {
            margin: 0 0 10px 0;

            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }

    .edit-block {
        margin: 0 10px;
        padding: 20px 10px;
        position: initial;
        overflow: initial;
        border-bottom: 1px solid var(--general-border-color);

        &:first-of-type {
            margin-top: 20px;
        }

        &:last-of-type {
            margin-bottom: 30px;

            +.profile-settings-edit-page-footer {
                margin-top: -20px;
            }
        }

        .user-profile-loading {
            border-radius: var(--general-border-radius);
            height: 40px;
            margin: -5px 0px;

            &.user-profile-loading-textarea {
                height: 120px;
            }
        }

        .user-profile-loading-image {
            height: 105px;
            width: 105px;
            border-radius: 100%;
            margin-left: auto;
            margin-right: auto;
        }

        &.edit-block-toggle {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            >label {
                display: block;
                font-weight: 500;
            }

            &.edit-block-toggle-disabled>label {
                color: var(--general-text-inactive-color);
            }
        }

        &.edit-block-link {
            display: block;
            padding: 10px 0;

            button {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                width: 100%;
                cursor: pointer;
                margin: 0;
                padding: 10px;

                &:hover {
                    background: ~'rgba(var(--background-highlight-color-rgb), 0.5)';
                    border-radius: var(--general-border-radius);
                }

                .link-icon {
                    align-items: center;
                    background: var(--background-highlight-color);
                    border-radius: 100%;
                    display: flex;
                    flex: 0 0 auto;
                    flex-direction: column;
                    height: 40px;
                    justify-content: center;
                    width: 40px;

                    &.link-icon-pin-code {
                        rl-icon {
                            font-size: 17px;
                        }

                        .pin-container {
                            display: flex;
                            flex-direction: row;
                            margin-top: 3px;
                            font-size: 6px;
                            justify-content: space-between;
                            width: 22px;
                        }
                    }
                }

                .text {
                    display: block;
                    font-weight: 500;
                    margin-left: 15px;
                    overflow: hidden;
                    text-align: left;
                    text-overflow: ellipsis;
                }

                .next-text {
                    display: block;
                    margin: 0 0 0 auto;
                    padding: 0;
                    text-align: right;

                    &.inactive {
                        color: ~'rgba(var(--general-text-color-rgb),.5)';
                    }

                    &.active {
                        font-weight: 500;
                        color: var(--highlight-color);
                    }
                }

                .link-next-pin-code {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    height: 20px;
                    list-style: none;
                    margin: 0 0 0 auto;
                    padding: 0;

                    li {
                        display: block;
                        height: 8px;
                        width: 8px;
                        border-radius: 100%;
                        background: var(--highlight-color);
                        margin: 0 4px;
                        padding: 0;
                        list-style: none;
                    }
                }

                .next-text,
                .link-next-pin-code {
                    &+rl-icon[icon="next"] {
                        margin-left: 10px;
                    }
                }

                rl-icon[icon="next"] {
                    margin-left: auto;
                }
            }
        }

        &.edit-block-input {
            >label {
                display: block;
                padding: 5px 0;
                font-size: 14px;
                color: var(--general-text-inactive-color);
            }

            >rl-select {
                .select-area.disabled {
                    cursor: progress;
                }
            }

            >rl-text-input,
            >rl-select,
            >rl-textarea,
            >rl-phone-input {
                margin: 0 -10px;
                width: calc(100% + 20px);
                
                textarea:disabled,
                input:disabled {
                    //overrides user-agent styles for ios devices
                    opacity: 1;
                    background-color: var(--locked-field-color);
                    color: var(--general-text-inactive-color);
                    cursor: not-allowed;
                }
            }
        }
    }
}