
// notification-viewer {
//     .notification-viewer {
//         background: @clr-background;
//     }
// }

.notification-viewer {
    .no-content {
        text-align: center;

        .icon-wrapper {
            @media @desktop-up {
                padding-top: 30px;
            }
        }

        icon {
            margin-bottom: 25px;
            margin-top: 15px;
            width: 36px;

            svg {
                stroke-width: 0;
            }

            &.icon-stop {
                position: absolute;
                width: 76px;
                margin-left: -20px;
                margin-top: -5px;
                color: #ea6f63;
            }
        }

        button {
            padding: 15px;
            border: 1px solid #5fa3f1;
            color: #5fa3f1;
            margin-top: 15px;
        }
    }

    .notification-viewer-loading {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        .flex-center-center();

        .spinner {
            height: 30px;
            width: 30px;
        }
    }

    .notification-viewer-content {
        ul {
            list-style: none;
            padding: 0;
            margin: 0;
            display: block;

            > li {
                display: block;
                width: 100%;
                box-sizing: border-box;
                margin: 0;

                &.list-title {
                    text-transform: uppercase;
                    background: #fff;
                    padding: 10px 15px;
                    font-weight: 600;
                    border-top: 1px solid @color-background-light-border-color;
                    border-bottom: 1px solid @color-background-light-border-color;

                    &:first-of-type {
                        border-top: none;
                    }
                }

                &.unread {
                    border-color: #c0e9f3;

                    .notification-viewer-notification {
                        background: rgba(95, 163, 241, 0.1);
                    }
                }
            }
        }

        .notification-viewer-notification {
            display: block;
            background: #fff;
            position: relative;
            padding-top: 5px;

            > .inner {
                display: block;
                box-sizing: border-box;
                position: relative;
                overflow: hidden;
                z-index: 1;
                padding-bottom: 10px;

                > .notification-image-container {
                    height: 56px;
                    width: 56px;
                    position: absolute;
                    top: 6px;
                    left: 12px;
                    border-radius: 50%;
                    color: #fff;

                    .media-thumbnail {
                        height: 56px;
                        width: 56px;
                        border-radius: 50%;
                        background: #303030;

                        .image {
                            height: 100%;
                            width: 100%;
                        }
                    }

                    &.no-thumbnail {
                        background: #303030;
                        text-align: center;
                        line-height: 56px;
                        font-size: 24px;

                        .avatar {
                            position: absolute;
                            top: 0;
                            left: 0;
                            color: #3c4858;
                            width: 100%;
                            height: 100%;

                            svg {
                                box-sizing: border-box;
                                border-radius: 50%;
                                stroke-width: 0;

                                path {
                                    fill: #8893a3;
                                }
                            }
                        }
                    }

                    .icon {
                        position: absolute;
                        line-height: 14px;
                        right: -2px;
                        bottom: -6px;
                        top: auto;
                        background: #3c4858;
                        border-radius: 50%;
                        width: 27px;
                        height: 27px;
                        text-align: center;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        box-shadow: 0 2px 10px rgba(0, 0, 0, .15);

                        rl-icon {
                            font-size: 14px;
                        }

                        &.socialPost {
                            background: #eb6262;
                        }

                        &.contentItem {
                            background: #5fa3f1;
                        }

                        &.serviceFormFilling {
                            background: #3c4858;
                        }

                        &.isReaction {
                            background: none;
                            box-shadow: none;
                        }

                        icon {
                            width: 14px;
                            height: 14px;

                            svg {
                                stroke-width: 0;
                            }
                        }
                    }
                }

                > .notification-data {
                    padding: 15px 15px 15px 80px;
                    word-break: break-word;

                    h3 {
                        margin: 0;
                        font-size: 15px;
                    }

                    p {
                        margin: 0 36px 0 0;
                        font-size: 14px;
                    }

                    .notification-data-time {
                        font-size: 11px;
                        opacity: .7;
                        text-align: right;
                        white-space: nowrap;
                    }

                    .notification-module-name {
                        width: 70%;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        opacity: .6;
                    }
                }

                .notification-footer {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    font-size: 12px;
                    margin-top: 7px;
                }

                reaction-icon {
                    width: 27px;
                    height: 27px;

                    rl-icon .reaction-like {
                        font-size: 14px;
                    }
                }
            }

            .options {
                display: block;
                text-align: center;
                position: absolute;

                top: 10px;
                right: 12px;
                overflow: hidden;
                z-index: 2;


                font-size: 24px;
                height: 30px;
                line-height: 30px;
                opacity: .6;
                box-sizing: border-box;
                padding: 0;
                margin: 0;
                border: 0;
                background: none;

                rl-icon {
                    font-size: 20px;
                }

                svg {
                    stroke-width: 0;
                }
            }

            &.placeholder{
                > .inner{
                    > .notification-data{
                        p{
                            display: block;
                            width: 100%;
                            height: 20px;
                            border-radius: 4px;
                        }

                        .notification-module-name{
                            display: block;
                            width: 160px;
                            max-width: 40%;
                            height: 18px;
                            border-radius: 4px;
                        }
                    }
                }
            }
        }
    }
}


@import "NotificationsSettings/NotificationsSettings";