my-training-v2 {
    display: flex;
    flex-direction: column;

    min-height: calc(100vh - var(--top-header-height));

    transition: opacity 0.3s ease-in;

    .tabs-wrapper {
        .rl-tabs {
            margin: 0;
            padding-left: 0;
        }

        @media @tablet-max {
            margin: 0 var(--spacing);
        }
    }

    > section {
        display: flex;
        flex-direction: column;
        gap: var(--spacing);
        padding: calc(var(--spacing) * 2) 0;

        course-item .course {
            margin: 0;
        }

        @media @tablet-max {
            margin: 0 var(--spacing);
        }
    }

    rl-loading-placeholder {
        display: block;
        padding: var(--m-spacing);

        background-color: var(--background-highlight-color);
        border-radius: var(--training-border-radius);

        .placeholder-shimmer {
            height: 19.5px;

            &:not(:last-of-type) {
                margin-bottom: 17px;
            }
        }
    }

    .learning-path {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: var(--spacing);

        &:before {
            content: '';
            position: absolute;
            top: 19px;
            width: 100%;
            height: 1px;
            background: radial-gradient(circle, rgba(0, 0, 0, 0) 0%, rgba(var(--general-text-color-rgb), 0.1) 50%, rgba(0, 0, 0, 0) 100%);
        }

        .phase-number {
            position: absolute;
            top: 12px;
            font-size: 13px;
            font-weight: 600;
            color: var(--text-on-white-color);
        }

        .learning-path-icon-number {
            position: relative;

            rl-icon {
                position: relative;
                font-size: 34px;
                color: var(--background-highlight-color);
            }

            .learning-path-number {
                display: block;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }

        h2 {
            padding-top: 7px;
            margin: 0 var(--m-spacing);
            text-align: center;
            line-height: 1.5;
            font-size: 16px;
            font-weight: 700;
        }
    }

    .courses-list {
        display: flex;
        flex-direction: column;
        gap: var(--spacing);
    }

    training-learn-more-component {
        margin-top: auto;
        padding-bottom: var(--spacing);

        @media @tablet-max {
            margin: 0 var(--spacing);
        }
    }

    no-content {
        margin: auto;

        .no-content {
            position: static;
            margin: 0;
            height: unset;
        }
    }
}

learning-path-page {
    display: block;
}