catalog-grid-item {
    margin: 0 5px 10px 5px;
    box-sizing: border-box;
    cursor: pointer;

    flex-basis: calc(~'100% / 2 - 10px');

    @media @tablet-up {
        flex-basis: calc(~'100% / 3 - 20px');
        margin: 0 10px 15px 10px;
    }
    @media @desktop-up {
        flex-basis: calc(~'100% / 4 - 20px');
    }
    @media @desktop-big-up {
        flex-basis: calc(~'100% / 5 - 20px');
    }

    .wrapper {
        position: relative;
        padding-bottom: 65%;
        border: 1px solid var(--general-border-color);
        border-radius: var(--catalog-border-radius);
        overflow: hidden;

        file-preview {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;

            .image {
                background-position: center;
                background-size: contain;
                background-repeat: no-repeat;
            }

            rl-icon:not([icon=play]) {
                font-size: 4vw;
                @media @tablet-max {
                    font-size: 10vw;
                }
                @media @mobile-max {
                    font-size: 15vw;
                }
            }

            rl-icon[icon=play] {
                @media @tablet-max {

                }
            }
        }

        > rl-icon {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;

            font-size: 50px;
            color: var(--general-text-inactive-color);
        }
    }

    h2 {
        margin: 0;
        padding: 5px 0 0 0;
        font-size: 13px;
        color: @clr-bodytext;

        overflow: hidden;
        display: -webkit-box;
        word-break: break-word;
        word-break: auto-phrase;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    p {
        margin: 0;
        font-size: 14px;
        color: @clr-subheadline-onbg;
    }
}

