autocomplete {
    .selected-items {
        display: flex;
        flex-wrap: wrap;
        padding: 0 0 10px 0;
        align-items: center;

        li {
            display: flex;
            align-items: center;
            box-sizing: border-box;
            height: 30px;
            max-width: 100%;
            padding: 0 10px;
            margin-right: 10px;
            margin-bottom: 10px;
            line-height: 30px;
            background: var(--background-highlight-color);
            position: relative;
            overflow: hidden;

            p {
                margin: 0;
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                position: relative;
                font-size: 15px;
            }

            .remove-item {
                position: relative;
                top: 1px;
                font-weight: 600;
                color: var(--warning-color);
                padding: 1px 2px 1px 8px;
                font-size: 15px;
            }

            [icon="lock"] {
                opacity: 0.4;
                padding-left: 8px;
                font-size: 13px;
            }
        }
    }
}
