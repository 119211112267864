icon {
    display: inline-block;
    svg {
        width: 100%;
        height: 100%;

        g, path, rect, circle {
            fill: currentColor;
            stroke: currentColor;
        }
    }
}
