.message-wrapper:last-of-type {
    desktop-message-menu {
        .button-groups {
            top: unset;
            bottom: 45px;
        }
    }
}

.message-wrapper:last-of-type,
.message-wrapper:nth-last-child(2) {
    action-sheet {
        display: block;
    }
}

message-item {
    .rl-animation-height {
        transition: 0.15s linear transform, 0.15s linear height, 0.15s linear opacity;

    }

    .meta-bottom {
        gap: 5px;

        .message-views, .time, .message-status {
            height: 0;
            max-width: 0;
            overflow: hidden;
            opacity: 0;
            transform: scaleY(0);
            transform-origin: top;

            &.meta-animation {
                transform: scaleY(1);
                height: 1.5rem;
                max-width: 100%;
                opacity: 1;
                overflow: unset;
            }
        }

        .message-views {
            display: flex;
            justify-content: flex-start;
            margin: 0 3px;

            .message-view {
                margin: 0 0 0 calc(-1 * var(--s-spacing));
                text-align: center;
            }

            .message-view-badge {
                width: 1.2rem;
                height: 1.2rem;
                line-height: 1.2rem;
                border-radius: 50%;
                background-color: var(--background-highlight-color);
                color: var(--general-text-color);
                font-size: 0.55rem;
                font-weight: 700;
                border: 3px solid var(--background-highlight-color);
                position: relative;
                z-index: 0;

                &::before {
                    background-color: var(--general-border-color);
                    z-index: -1;
                    content: '';
                    display: block;
                    border-radius: 50%;
                    width: 100%;
                    height: 100%;
                    position: absolute;
                }
            }

            profile-image {
                width: 1.2rem;
                height: 1.2rem;
                position: static;
                background: var(--background-highlight-color);
                border-radius: 50%;
                padding: 0;
                overflow: hidden;
                border: 3px solid var(--background-highlight-color);

                .profile-image {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }

    .message-status {
        font-size: 0.87rem;
        display: flex;
        align-items: center;
        color: var(--general-text-inactive-color);

        &.failed {
            color: var(--warning-color);
        }

        rl-icon[icon="edit"] {
            width: 1rem;
            font-size: 0.5rem;
            padding: 0 var(--s-spacing);
        }

        .animated-dots {
            display: flex;

            .single-dot {
                animation: blink 1.5s infinite;
            }

            .single-dot:nth-child(2) {
                animation-delay: 0.3s;
            }

            .single-dot:nth-child(3) {
                animation-delay: 0.6s;
            }

            @keyframes blink {
                0%, 20%, 50%, 80%, 100% {
                    opacity: 1;
                }
                40% {
                    opacity: 0;
                }
                60% {
                    opacity: 0;
                }
            }
        }
    }

    .time {
        overflow: hidden;

        @media @desktop-up {
            display: flex;
        }
    }

    &.self {
        .meta-bottom {
            font-size: 0.87rem;
            color: var(--general-text-inactive-color);
            display: flex;
            justify-content: flex-end;

            .message-views {
                padding-left: 0;
            }
        }

        .time {
            align-items: center;
            flex-direction: row;
        }

        .failed-indicator {
            position: absolute;
            left: -1.6rem;
            top: calc(50% - 0.7rem);
            font-size: 1rem;
            width: 1.4rem;
            height: 1.4rem;
            border-radius: 50%;
            background-color: var(--warning-color);
            color: var(--white-color);
            display: grid;

            rl-icon {
                font-size: 0.8rem;
            }
        }

        &.deleted {
            .deleted-message {
                text-align: right;
                margin-left: auto;
            }
        }

        .message-content-wrapper {
            align-items: flex-end;
        }

        .message-actionsheet-wrapper {
            align-items: flex-start;
        }

        .message-reactions-wrapper {
            margin-left: auto;
        }

        .meta {
            text-align: right;
            padding-left: 0;
            justify-content: flex-end;
        }

        reactions-list {
            right: 0;
        }

        .message {
            text-align: right;
            padding-left: 0;

            .avatar {
                right: 0;
                left: auto;
            }

            .replying-message {
                background: var(--general-border-color);
            }

            .message-body-wrapper {
                background: var(--highlight-color);
                margin-left: auto;

                &.first-in-row:after {
                    border-bottom: 7px solid var(--highlight-color);
                    transform: rotate(-45deg);
                    left: auto;
                    right: -8px;
                }
            }

            .message-body {
                color: var(--white-color);
                white-space: pre-wrap;
            }

            &.reactions-overlay {
                reactions-list {
                    right: -5px;
                }
            }

            desktop-message-menu {
                right: 0;
            }
        }

        .message-views {
            display: flex;
            justify-content: flex-end;
        }

        action-sheet.attached .actionsheet {
            right: 0;
        }
    }

    &.deleted {
        .deleted-message {
            text-align: left;
        }
    }

    &:not(.self) {
        .time {
            align-items: center;

            &:not(:first-child)::before {
                content: '\b7';
                display: block;
                font-size: 1.2rem;
                margin: 0 var(--s-spacing);
            }
        }

        action-sheet.attached .actionsheet {
            right: auto;
        }

        .meta-bottom {
            font-size: 0.87rem;
            color: var(--general-text-inactive-color);
            position: relative;
            display: flex;
            flex-direction: row-reverse;
            justify-content: flex-end;
        }

        .message {
            &.dialog {
                reactions-list,
                action-sheet.attached .actionsheet {
                    left: 0 !important;
                }

                &.reactions-overlay {
                    reactions-list {
                        left: calc(var(--s-spacing) * -1) !important;
                    }
                }
            }
        }

        .deleted-message {
            margin-right: auto;;
        }

        .message-actionsheet-wrapper {
            align-items: flex-end;
        }

        .message-reactions-wrapper {
            margin-right: auto;
        }
    }

    .date {
        font-size: 13px;
        text-align: center;
    }

    .meta {
        margin-top: 5px;
        text-align: left;
        position: relative;
        padding-left: 35px;
        font-size: 0.87rem;
        color: var(--general-text-inactive-color);
        font-weight: 400;
        display: flex;
        align-items: center;
        line-height: 1.2rem;

        &.dialog {
            padding-left: 0;
        }

        .message-status:not(:first-child) .text {
            &::before {
                content: '\b7';
                display: inline;
                font-size: 1.2rem;
                margin: 0 var(--s-spacing);
            }
        }

        .message-status {
            rl-icon {
                display: none;
            }

            &.mobile {
                .text {
                    display: none;
                }

                rl-icon {
                    display: block;
                }
            }
        }
    }

    .message-reactions-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .message-content-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .message.hover {
        desktop-message-menu {
            visibility: visible;
            opacity: 1;
            transform: translateY(0);
            transition: opacity .1s linear, transform .2s linear;
        }
    }

    .message {
        --move-dist: 0;

        position: relative;
        top: 0;
        text-align: left;
        padding-left: 35px;
        padding-right: 0;
        transition: top 0.3s ease;

        &.dialog {
            padding-left: 0;
        }

        &.highlighted {
            .message-body-wrapper, .replying-message, reactions-summary {
                position: relative;
                z-index: 7;
            }

            > reactions-list, action-sheet .actionsheet {
                z-index: 9;
            }

            &.mobile {
                z-index: 200;
                top: var(--move-dist);

                .message-body-wrapper, .replying-message, reactions-summary {
                    z-index: 101;
                }

                > reactions-list, action-sheet .actionsheet {
                    z-index: 102;
                }
            }

            desktop-message-menu {
                display: block;
            }

            .meta-bottom {
                opacity: 0;
            }
        }

        &.reactions-overlay {
            reactions-list {
                bottom: 40vh;
                top: unset;
                z-index: 102;
                transition: top 0.5s ease;

                @media @mobile-max and (orientation: landscape) {
                    bottom: 70vh;
                }
            }
        }

        desktop-message-menu {
            visibility: hidden;
            opacity: 0;
            transform: translateY(10px);
            position: absolute;
            top: -30px;
            z-index: 10;
        }

        reactions-list {
            position: absolute;
            top: -60px;
            padding: var(--m-spacing);
            max-height: 55px;
            display: flex;
            gap: var(--m-spacing);

            border-radius: 25px;
            background: var(--general-background-color);
            box-shadow: 0px 12px 25px 0px var(--shadow-color);

            .reaction-icon-wrapper {
                width: 35px;
                height: 35px;
                margin: 0;

                @media @desktop-up {
                    width: 24px;
                    height: 24px;
                }

                .reaction-label {
                    margin-bottom: 12px;
                }

                .reaction-like {
                    font-size: 13px;
                    padding-bottom: 4px;
                }
            }
        }

        .avatar {
            border-radius: 50%;
            background: var(--background-highlight-color);
            width: 30px;
            height: 30px;
            position: absolute;
            top: 0;
            left: 0;
            padding: 0;

            .leave-icon {
                bottom: -3px;
                right: -3px;
            }

            .profile-image {
                width: 100%;
                height: 100%;
            }
        }

        .replying-message, .message-body-wrapper {
            position: relative;
            padding: var(--m-spacing);
            display: inline-block;
            border-radius: var(--chat-border-radius);
            background: var(--general-background-color);
            word-break: break-word;
            box-sizing: border-box;
        }

        .replying-message {
            display: flex;
            max-width: max(70%, 250px);
            overflow: hidden;
            padding-bottom: 15px;
            margin-bottom: -10px;
            margin-top: 5px;
            background-color: var(--general-background-color);
            cursor: pointer;
            box-sizing: border-box;
            font-size: 14px;

            &:after {
                content: '';
                z-index: -1;
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: var(--background-highlight-color);
            }

            @media @desktop-up {
                &:hover {
                    filter: brightness(0.95);
                }
            }

            > rl-icon {
                font-size: 1.2rem;
                margin-right: 10px;
                opacity: 0.7;
            }

            file-preview {
                position: relative;
                overflow: hidden;
                margin-right: 10px;
                border-radius: 5px;
                width: 35px;
                height: 35px;

                .file-icon {
                    font-size: 25px;
                }
            }

            .right {
                display: flex;
                flex: 1;
                flex-direction: column;
                text-align: left;
                justify-content: center;
                overflow: hidden;

                .text {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    opacity: 0.7;
                }
            }
        }

        .message-media {
            display: flex;
            width: 220px;

            media-preview {
                width: 100%;
                height: 200px;

                .media-preview-list-item, .image {
                    border-radius: 10px;
                    overflow: hidden;
                }

                .media {
                    position: static;
                }

                .media-image-container .image {
                    height: 200px;
                }

                .image {
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }

                .video-play-thumb {
                    padding: 0;
                    height: 200px;
                }

                .media .video > .video-wrapper {
                    height: 200px;
                    padding-top: 0;
                    padding-bottom: 0;
                }
            }
        }

        .message-actionsheet-wrapper {
            display: flex;
            flex-direction: column;
            max-width: max(70%, 250px);

            action-sheet {
                position: unset;
                margin-right: auto;

                .actionsheet {
                    position: unset;
                }
            }
        }

        action-sheet.attached .actionsheet {
            top: auto;
            bottom: auto;
            width: 187px;
            padding: var(--s-spacing) 0;
            margin-top: 5px;
            border: 1px solid var(--general-border-color);
            box-shadow: 0px 7px 15px 0px var(--shadow-color);

            .actionsheet-button:has(.red) .text-wrapper {
                color: var(--warning-color);
            }

            .text {
                font-size: 0.8rem;
            }

            rl-icon {
                margin-right: var(--s-spacing);
            }
        }
    }

    reactions-summary {
        position: relative;
        margin: -7px 0 0 3px;
        padding: 3px 5px;
        background-color: var(--white-color);
        box-shadow: 0px 4px 7px 0px var(--shadow-color);
        border-radius: 100px;
        border: 1px solid var(--general-border-color);
        cursor: pointer;

        .count {
            color: var(--text-on-white-color);
            font-size: 0.8rem;
        }

        .icons {
            &.count-3 {
                margin-left: -4px;
            }

            &.count-2 {
                margin-left: -2px;
            }
        }

        reaction-icon {
            border: none;
            width: 18px;
            height: 18px;
            display: flex;
            justify-content: center;
            align-items: center;

            &:nth-child(1) {
                z-index: 6;
            }

            &:nth-child(2) {
                z-index: 5;
                left: 2px;
            }

            &:nth-child(3) {
                z-index: 4;
                left: 4px;
            }

            rl-icon {
                position: absolute
            }
        }
    }

    .message-body-wrapper {
        margin: 3px 0;

        &.blocked-message {
            color: var(--general-text-inactive-color);
            border-color: var(--general-text-inactive-color);
            background-color: var(--general-background-color);
        }

        &.first-in-row {
            margin-top: 0;
        }

        &.with-media {
            width: 100%;
        }
    }

    .message-body {
        white-space: pre-wrap;
        text-align: left;

        .post-tag, .tag-label {
            font-weight: 700;
        }
    }

    .deleted-message {
        display: block;
        padding: var(--m-spacing);
        border-radius: var(--chat-border-radius);
        border: 1px solid var(--general-border-color);
        background-color: var(--general-background-color);
        font-size: 1rem;
        color: var(--general-text-inactive-color);
        width: max-content;
        margin: 3px 0;
        max-width: max(70%, 250px);

        &.first-in-row {
            margin-top: 0;
        }
    }

    .overlay {
        position: fixed;
        z-index: 8;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.4);

        &.mobile {
            z-index: 100;
            background: rgba(0, 0, 0, 0.6);
            backdrop-filter: blur(20px);
        }
    }
}

@import "./DesktopMessageMenu/DesktopMessageMenu";