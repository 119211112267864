badge-popup {
    .action-popup {
        width: calc(100% - var(--spacing) * 2);
        max-width: calc(var(--max-width) - var(--spacing) * 2);

        @media @desktop-up {
            margin-left: calc(var(--sidemenu-width) / 2);
        }
    }

    .badge-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;

        padding: calc(var(--m-spacing) * 2) var(--spacing);
        border-bottom: 1px solid var(--general-border-color);
    }

    badge {
        margin-bottom: var(--m-spacing);

        .image-wrap {
            width: 100px;
        }

        p {
            font-size: 15px;
            margin: var(--s-spacing) 0 0 0;
            font-weight: 400;
        }
    }
}  