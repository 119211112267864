event-update {
    display: block;
    border-radius: var(--general-border-radius);
    background-color: rgba(var(--highlight-color-rgb), 0.2);
    padding: 15px;
    margin: 15px 10px;

    .event-update-top {
        display: flex;
        justify-content: space-between;
        align-items: center;


        h4 {
            line-height: 130%;
            color: var(--highlight-color);
            margin: 0;
        }

        .right {
            display: flex;
            align-items: center;
        }

        .date {
            opacity: 0.4;
            font-size: 13px;
            line-height: 130%;
        }

        .post-options {
            margin-left: 10px;
            padding: 0;
            text-align: center;
            color: var(--general-text-color);
            background: transparent;
            border: none;
        }
    }

    .event-update-text {
        display: block;
        padding: 5px 0 15px 0;
        margin: 0;
        overflow: hidden;
    }

    .bold-button {
        font-weight: bold !important;
        font-size: 1rem;
    }

    .event-update-image {
        margin: 5px -15px 0px -15px;
        position: relative;

        media-preview {
            min-height: 200px;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        .embed-video {
            width: 100%;
            max-width: 100%;

            > .embed-video-wrapper {
                position: relative;
                padding-bottom: 46%;
                padding-top: 30px;
                height: 0;
                overflow: hidden;

                iframe, object, embed, video {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }
            }
        }

        @media @desktop-up {
            .media {
                .image {
                    img {
                        max-height: 600px;
                        object-fit: contain;
                    }
                }
            }
        }
    }
}
