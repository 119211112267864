update-subtask-popup {
    .scroll-container {
        flex: 1;
        overflow-y: auto;
        position: relative;
    }

    .subtask-header {
        padding: 0 15px;

        .language-selector-wrapper {
            margin-left: -15px;
            margin-right: -15px;

            .rl-label {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding-left: 15px;
                padding-right: 15px;
                min-height: 43px;
            }


            language-selector {
                background-color: var(--background-highlight-color);

                .selected-languages {
                    margin: 0 15px;
                }

                .selected-title {
                    display: none;
                }
            }
        }
    }
    
    form {
        padding-bottom: 75px;
    }

    .subtask-options {
        padding: 0 15px 15px;
    }

    .save button {
        text-transform: uppercase;
        color: var(--white-color);
        background: var(--button-background-color);
    }
}