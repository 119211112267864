feedback-comments-popup {
    .form-feedback {
        position: relative;
        z-index: 1;
        
        display: flex;
        align-items: center;
        justify-content: center;

        height: 60px;
        width: 100%;
        padding: 0 15px;

        box-sizing: border-box;
        box-shadow: 0 7px 15px rgba(60, 72, 88, 0.1);

        .profile-image {
            width: 24px;
            height: 24px;
            min-width: 24px;
            margin: 0 6px;
        }

        .rl-subheadline {
            margin-right: 10px;
        }
    }

    comments.has-feedback .post-comments {
        height: calc(100% - 60px - 55px);
    }
}