language-selector, language-selector-popup .selected-languages-wrapper {
    display: flex;
    align-items: center;

    scroll-shadow {
        flex: 1;
        overflow: hidden;
    }

    .selected-languages {
        display: flex;
        overflow-y: auto;
        padding: 10px 0;
        margin: 0 10px;

        ul {
            display: flex;
            list-style: none;
            padding: 0;
            margin: 0;

            li:not(:first-of-type) {
                margin-left: 15px;
            }
        }
    }
}

language-selector, language-selector-popup {
    .language-image {
        width: 30px;
        height: 30px;
    }
}

language-selector {
    background: var(--general-background-color);

    .selected-title {
        padding-left: 10px;
        color: var(--general-text-color);
    }

    .selected-languages {
        li:not(.active) {
            opacity: 0.4;
        }
    }

    rl-icon.circle {
        margin-left: 15px;
        min-width: 30px;
        width: 30px;
        height: 30px;
        background: var(--general-background-color);
        box-shadow: 0 2px rgba(0, 0, 0, 0.05);
    }
}

language-selector-popup {
    .popup-wrapper .popup-content-inner {
        display: block;
    }
}

.translation-btn {
    &[disabled] {
        rl-icon {
            color: ~'rgba(var(--general-text-color-rgb), 0.5)';
        }
    }

    rl-icon {
        color: var(--highlight-color);
    }
}

@import "LanguageSelectorPopup/LanguageSelectorPopup";
@import "SimpleLanguageSelector/SimpleLanguageSelector";