pin-profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;

    height: 40%;
    padding-bottom: 40px;
    box-sizing: border-box;
    
    .pin-image {
        border-radius: 50%;
        box-shadow: 0 6px 7px 0 rgba(var(--general-text-color-rgb), .1);
        height: 90px;
        width: 90px;
        padding: 0;
        margin-bottom: 15px;
        background-position: center;
    }

    .chevron-btn {
        display: flex;
        align-items: center;
        gap: 5px;
        transition: background-color 0.3s ease;
        padding-left: var(--m-spacing);
        border-radius: var(--general-border-radius);

        &:hover {
            cursor: pointer;
            background-color: rgba(var(--general-text-color-rgb), 0.06);
        }

        rl-icon {
            color: var(--general-text-inactive-color);
            font-size: 0.85rem;
        }
    }

    h2 {
        margin: 0;
        font-weight: 600;
        font-size: 1.3rem;
        color: var(--general-text-color);
        line-height: 120%;
    }
}