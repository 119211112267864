.base-view-contactmodule {
    overflow-y: hidden;
}

contact-module {
    .loading {
        position: absolute;
        z-index: 100;
        top: 0;
        right: 0;
        left: 0;
        height: calc(~'100vh - 105px');
        display: flex;
        justify-content: center;
        align-items: center;
        background: white;

        .spinner {
            width: 50px;
            height: 50px;
        }
    }

    .contact-module-search {
        padding: 15px;
    }

    .tab-container {
        padding: 0 15px;
        margin-bottom: 15px;
    }

    .rl-tab {
        cursor: pointer;
    }

    .contact-list-container {
        padding: 0 15px 60px 15px;
    }

    .search {
        .contact-list-container {
            padding: 0 15px 10px 15px;
        }

        contact-list .contact-list-component {
            max-height: calc(100vh - 130px);

            @media @desktop-up {
                height: calc(100vh - 180px);
            }
        }
    }

    .contact-list-my-department {
        border-radius: 7px;
        background: var(--background-highlight-color);
        padding: 0 10px;
    }

    .departments {
        position: relative;
        overflow-y: auto;
        height: calc(100vh - 170px);

        @media @desktop-up {
            height: calc(100vh - 220px);
        }
    }

    .user-search {
        margin-top: 15px;
    }

    .department-item {
        margin: 10px 0;
        cursor: pointer;
    }
}
