//@import "MediaViewer/MediaViewer";
@import "FilePreview/FilePreviewComponent";


// Preview
media-preview {
    --media-preview-item-height: 200px;

    display: block;
    position: relative;

    .media-preview-file {
        height: var(--media-preview-item-height);
        text-align: center;
        position: relative;
        text-decoration: none;
        .flex-center-center();
    }
    
    media {
        &.no-play {
            pointer-events: none;
        }
    }
    
    .multiple {
        .media-preview-list {
            position: relative;

            &:after {
                display: table;
                clear: both;
                content: '';
            }

            .media-preview-list-col {
                display: block;
                width: 50%;
                float: left;
                height: var(--media-preview-item-height);
                overflow: hidden;
                box-sizing: border-box;
                padding-right: 3px;

                &:last-child {
                    padding-right: 0
                }

                .media-preview-list-item {
                    display: block;
                    height: var(--media-preview-item-height);
                    width: 100%;
                    position: relative;
                    text-align: center;

                    &.small {
                        height: calc(var(--media-preview-item-height) / 2);

                        icon {
                            width: 40%;
                        }

                        &:last-child {
                            top: 3px;
                            height: calc(var(--media-preview-item-height) / 2 - 3px);
                        }
                    }

                    .media-preview-zoom {
                        position: absolute;
                        z-index: 2;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        color: var(--white-color);
                        background: rgba(0, 0, 0, 0.25);

                        rl-icon {
                            font-size: 30px;
                        }
                    }
                }
            }
        }
    }
    
    file-preview {
        width: 100%;
        height: 100%;
    }
}
