pin-code-biometric-setup{
    h2 {
        margin: 0;
        text-align: center;
    }

    p {
        margin: 10px 0 25px 0;
        text-align: center;
    }

    .icon-container{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .enable > button > span {
        text-transform: uppercase;
    }

    .close > button > span {
        text-transform: uppercase;
    }

    .skip > button > span {
        color: var(--general-text-color);
        font-size: 13px;
        opacity: 0.5;
    }
}