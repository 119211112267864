@import "../Import.less";

.profile-page {
    position: relative;
    height: 100%;
    background: var(--background-highlight-color);

    form {
        position: relative;
        background: var(--general-background-color);
    }

    .container-left {
        padding-top: 10px;
        text-align: center;

        .profile-image-wrapper {
            margin: 0;
        }
    }


    .form-input-list {
        padding: 0 15px;

        input, textarea, select {
            color: var(--general-text-color);

            &[disabled] {
                opacity: 0.4;
            }
        }

        select {
            font-weight: 400;
            padding: 0;
            background: transparent;
        }

        label {
            color: var(--general-text-color);
        }
    }

    .password-view-icon {
        height: 26px;
        line-height: 26px;
        font-size: 16px;
        width: 40px;
        text-align: center;
        color: @clr-bodytext;
        opacity: .7;
        cursor: pointer;
        float: right;
        margin-bottom: -26px;

        &:hover {
            opacity: 1;
        }
    }

    .password-input {
        padding-right: 40px;
    }

    .form-item.about {
        margin: -1px -15px 0;
        padding: 10px 15px;
        border-bottom: 20px solid var(--background-highlight-color);
        border-top: 20px solid var(--background-highlight-color);

        button {
            display: flex;
            width: 100%;
            position: relative;
            align-items: center;
            justify-content: space-between;
            padding: 0;

            > span {
                display: flex;
                align-items: center;
            }
        }

        rl-icon {
            color: ~'rgba(var(--general-text-color-rgb), .5)';
        }

        icon {
            margin-right: 10px;
            width: 26px;
            height: 20px;

            svg {
                stroke-width: 0;

                g {
                    color: rgb(234, 111, 99);
                }

                path {
                    fill: rgb(234, 111, 99);
                }
            }
        }
    }

    .pin {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &:after {
            display: none;
        }

        button {
            display: flex;
            align-items: center;
            padding: 0;
        }

        rl-icon {
            color: ~'rgba(var(--general-text-color-rgb), .5)';
        }

        ul {
            display: flex;
            margin: 0;
            padding: 0;
            list-style: none;

            li {
                margin-right: 10px;
                width: 7px;
                height: 7px;
                border-radius: 100%;
                background: var(--highlight-color);
            }
        }
    }

    .form-item {
        textarea {
            background: none;
        }
    }


    .bar {
        border-color: #f3f3f4;
    }
}

// Profile Point Log
.profile-point-log {
    table {
        td {
            padding: 4px;
        }

        h3 {
            display: block;
            font-size: 14px;
            margin: 0;
            padding: 0;
        }

        span {
            display: block;
            font-size: 12px;
            color: #bbb;
        }
    }
}