subtasks-list {
    margin-top: 15px;
}

subtasks-list .subtasks-list {
    margin: 10px 0;
    padding: 0 0 10px 0;
    list-style-type: none;

    .subtask {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        width: 100%;
        height: 50px;
        margin-top: 10px;
        padding: 0 10px;
        box-sizing: border-box;

        background: var(--background-highlight-color);
        border-radius: 10px;
        font-weight: 400;
        line-height: 16px;

        &.sortable-chosen {
            pointer-events: none;
        }

        .status-icon {
            display: inline-flex;
            width: 30px;
            min-width: 30px;
            height: 30px;
            min-height: 30px;
            line-height: 30px;
            box-sizing: border-box;
            border-radius: 50%;
            justify-content: center;
            align-items: center;
            color: var(--white-color);
        }

        .status-complete {
            background: var(--confirmation-color);
        }

        .status-incomplete {
            border: 1px solid var(--general-border-color);
            background-color: var(--white-color);
        }

        .title {
            width: 100%;
            margin-left: 10px;
            
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            .complete {
                color: var(--general-text-inactive-color);
                text-decoration: line-through;
            }
        }

        .extra-info {
            margin-left: auto;
            display: flex;

            rl-icon {
                color: var(--general-text-inactive-color);
            }
        }

        .handle {
            margin-left: auto;
            touch-action: none;
            padding: 10px;
        }
    }
}