profile-desktop-dropdown-menu{
    .app-header-dropdown {
        right: 0;

        .app-header-dropdown-content {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
    }

    .profile-desktop-dropdown-menu-header {
        box-sizing: border-box;
        padding: 15px 15px 5px 15px;

        &:not(:first-of-type) {
            padding-top: 20px;
        }

        h3 {
            margin: 0;
            font-size: 14px;
            text-transform: uppercase;
            font-weight: 700;
            color: var(--general-text-color);
        }
    }

    ul {
        display: block;
        list-style: none;
        padding: 0;
        margin: 0;

        li {
            display: block;
            width: 100%;

            button {
                display: flex;
                width: 100%;
                padding: 15px;
                margin: 0;
                color: var(--general-text-color);
                justify-content: flex-start;
                align-items: center;
                min-height: 49px;
                cursor: pointer;

                span {
                    line-height: 1rem;
                }

                rl-icon:first-child {
                    margin-right: 10px;
                }

                rl-icon:last-child {
                    margin-left: auto;
                }

                rl-icon[icon="back"], rl-icon[icon="next"] {
                    font-size: 16px;
                }

                &.user-logout rl-icon {
                    color: var(--highlight-color);
                }

                &:hover {
                    background: var(--background-highlight-color);
                }
            }
        }
    }
}