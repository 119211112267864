training-material-selector {
    button {
        width: 100%;
        padding: 0 30px 0 40px;
        height: 50px;

        text-align: left;
        color: ~'rgba(var(--general-text-color-rgb), 0.5)';
        border: 1px solid ~'rgba(var(--general-text-color-rgb), 0.1)';
        background: var(--general-background-color);
        -webkit-appearance: none !important;
    }
}

.training-material-selector-popup {
    .main {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        height: calc(100% - 55px);
        box-sizing: border-box;
        padding: 10px;

        rl-search {
            margin: 15px 0 10px 0;
            min-height: 45px;
        }

        .scroll {
            overflow-y: auto;
            margin: 0 -10px;
            flex: 1;
            overscroll-behavior: none;
        }

        .material-list {
            flex: 1;
            min-height: 100px;

            > div {
                position: relative;
                display: flex;
                align-items: center;
                height: 50px;
                padding: 0 10px;
                box-sizing: border-box;

                &.selected {
                    background: var(--background-highlight-color);

                    .text {
                        span:first-child {
                            font-weight: 700;
                        }
                    }
                }

                .img {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    min-width: 38px;
                    min-height: 38px;
                    border-radius: 100%;
                    background: ~'rgba(var(--general-text-color-rgb), 0.1)';
                    background-position: center;

                    rl-icon {
                        color: var(--general-text-color);
                        opacity: 0.4;
                    }
                }

                .text {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    margin-left: 8px;
                    overflow: hidden;
                    > span {
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                    }
                }

                [icon="save"] {
                    position: absolute;
                    right: 10px;
                    bottom: 15px;
                    background: var(--confirmation-color);
                    color: var(--white-color);
                }
            }
        }
    }
}