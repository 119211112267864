.base-view.base-view-socialHashtags {
    background: var(--background-highlight-color);
    height: auto;
}

social-hashtags {
    display: block;
    padding-top: 15px;

    @media @tablet-max {
        padding-left: 15px;
        padding-right: 15px;
    }

    .hashtag {
        display: flex;
        align-items: center;
        background: var(--general-background-color);

        padding: 10px 15px;
        box-sizing: border-box;
        border-radius: var(--general-border-radius);

        @media @desktop-up {
            max-width: 60vw;
        }

        .content {
            overflow: hidden;
        }

        .name {
            display: flex;
            flex-direction: row;

            margin: 0;

            font-weight: 600;
            padding-bottom: 5px;
            color: var(--general-text-color);
            font-size: 15px;

            .tag {
                width: 100%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .featured {
                margin-left: 5px;
                color: var(--alert-color);
            }
        }

        .tag-label {
            display: flex;
            justify-content: center;
            align-items: center;

            min-width: 34px;
            height: 34px;
            margin-right: 10px;
            font-size: 20px;
            border-radius: 100%;
            color: var(--text-on-highlight-color);
            background: var(--highlight-color);

            .content {
                width: 100%;
                overflow: hidden;
            }
        }

        .subTitle {
            margin: 0;
        }
    }
}