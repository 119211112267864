.signup-body {
    overflow: auto;
    min-height: initial;
    background-position: center center;

    .loader-overlay {
        background: rgba(0, 0, 0, 0.15);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .initial-loader {
            opacity: 1;

            .spinner {
                height: 50px;
                width: 50px;
            }
        }
    }

    .signup-view {
        display: none;

        &.signup-view-ready {
            display: block;

            .signup-wrapper {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                z-index: 1;
                padding: 10px;
                box-sizing: border-box;
                overflow-y: hidden;
                overflow-x: auto;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
            }

            .signup-container {
                background: #fff;
                border-radius: 4px;
                width: 100%;
                max-width: 400px;
                padding: 10px;
                box-sizing: border-box;
                box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
                transition: height ease-in-out .2s;
            }
        }
    }

    .failed {
        .signup-container {
            padding-top: 30px !important;
            padding-bottom: 30px !important;
            text-align: center;
        }

        i {
            font-size: 50px;
            margin-bottom: 10px;
        }

        h1 {
            font-size: 24px;
            margin: 0;
            text-align: center;
        }
    }

    @import "SignUpForm.less";
    @import "SignUp404.less";
}

@media only screen and (max-width: 500px) {
    .signup-body {
        .signup-view {
            &.signup-view-ready {
                .signup-wrapper {
                    position: static;
                }
            }
        }
    }
}