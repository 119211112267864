department-swap-popup {
    popup-wrapper {
        popup-footer button {
            width: 100%;
        }
    }

    .popup-wrapper .popup-content-inner {
        padding: 0;
    }
}