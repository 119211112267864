training-learn-more-component {
    display: flex;
    flex-direction: column;

    .top {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;

        height: 101px;
        margin: 0 35px 0 20px;

        .men {
            position: relative;
            bottom: -10px;
        }
    }

    .bottom {
        border-radius: var(--general-border-radius);
        padding: 25px 15px 15px;
        background-image: linear-gradient(to bottom, rgba(var(--general-text-color-rgb), 0) 39%, rgba(var(--highlight-color), 0.1));
        background-color: var(--background-highlight-color);

        h2 {
            padding-bottom: 5px;
        }

        h2, p {
            margin: 0;
        }

        rl-button {
            display: block;
            margin-top: 15px;
        }
    }
}