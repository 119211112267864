.overflow-ellipsis(@lines) {
    word-break: break-word;
    word-break: auto-phrase;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: @lines;
    -webkit-box-orient: vertical;
  }

bookmarks-list {
    display: flex;
    flex-direction: column;

    .bookmarks-list,
    .bookmarks-list-loading {
        display: grid;
        grid-template-columns: repeat(2, minmax(150px, 190px));
        padding: var(--spacing);
        gap: var(--spacing);
        margin: 0 auto;
        overflow: hidden;
    }

    // overlap one line of loading bookmarks on top
    &:has(.bookmarks-list) {
        .bookmarks-list-loading {
            z-index: 4;
            margin-top: -190px;
        }
    }

    &:not([is-dropdown="true"]) {

        .bookmarks-list,
        .bookmarks-list-loading {

            @media @mobile-max and (orientation: landscape) {
                margin-left: 0;
            }

            @media @tablet-up {
                grid-template-columns: repeat(3, 190px);
            }

            @media @desktop-up {
                padding: var(--spacing) 0;
            }
        }

        bookmark-filters {
            @media @tablet-up {
                width: var(--max-width);
                margin: var(--m-spacing) auto 0;
            }
        }
    }

    .bookmark-item,
    .bookmarks-list-loading-item {
        width: 100%;
        position: relative;
        border: 1px solid transparent;
        border-radius: var(--general-border-radius);        

        &:last-child {
            margin-right: auto;
        }

        &:hover {
            border: 1px solid var(--general-border-color);
        }

        &.deleted {
            .image, .module-indicator, .inner, button {
                display: none;
            }
        }
        
        &:has(.icon-on-thumbnail) {
            .options {
                color: var(--white-color);
                text-shadow: 0px 4px 7px rgba(60, 72, 88, 0.10);
            }
        }
    }

    .bookmarks-list-loading-item {
        background-color: var(--general-background-color);
    }

    .module-indicator {
        position: absolute;
        top: 0;
        left: 0;
        overflow: hidden;

        .module-icon {
            position: absolute;
            top: var(--s-spacing);
            left: 0;
            right: 0;
            z-index: 2;
            font-size: 0.75rem;
        }

        .bookmark-icon {
            font-size: 2.2rem;
            color: var(--general-background-color);
        }

        .bookmark-icon-duplicate {
            top: -5px;
            position: absolute;
            left: 0;
        }
    }

    .image,
    .bookmarks-list-loading-item-image {
        height: 84px;
        border-radius: var(--general-border-radius)  var(--general-border-radius) 0 0;
        background-color: rgba(var(--general-text-color-rgb), 0.2);
        display: grid;
        place-content: center;

        rl-icon {
            font-size: 2.5rem;
            color: rgba(var(--general-text-color-rgb), 0.6);

            &.icon-on-thumbnail {
                width: 49px;
                height: 49px;
                text-shadow: 0px 17.182px 36.818px rgba(60, 72, 88, 0.10);
                color: var(--white-color);
        
                &::before {
                    color: var(--white-color);
                }
            
                &::after {
                    color: var(--text-on-white-color)
                }
            }
        }
    }

    .file-icon-with-text {
        position: relative;
        
        &::before,
        &::after {
            content: attr(data-label);;
            position: absolute;
            bottom: 8px;
            font-size: 9px;
            font-weight: 700;
        }
    
        &::before {
            color: var(--general-background-color);
        }
    
        &::after {
            color: rgba(var(--general-text-color-rgb), 0.2);
        }
    }
    
    .inner,
    .bookmarks-list-loading-item-text {
        border-radius: 0 0 var(--general-border-radius)  var(--general-border-radius);
        padding: var(--m-spacing);
        background-color: var(--background-highlight-color);
        display: flex;
        flex-direction: column;
        gap: 4px;
        height: 56px;
    }

    .row {
        display: flex;

        .title {
            font-size: 0.8rem;
            font-weight: 700;
            line-height: 135%;
            .overflow-ellipsis(2);
        }
        
        .module, .date {
            color: var(--general-text-inactive-color);
            font-size: 0.8rem;
            font-weight: 400;
            line-height: 135%;
        }

        .module {
            .overflow-ellipsis(1);

            &::after {
                content: '·';
                width: 10px;
                display: inline-block;
                text-align: center;
            }
        }

        .date {
            flex-shrink: 0;
        }
    }

    .options {
        position: absolute;
        top: 5px;
        right: 0;
        color: var(--white-color);

        &.no-media {
            color: var(--general-text-inactive-color);
        }

        rl-icon {
            font-size: 1.2rem;
        }
    }

    .bookmarks-list-loading-item-text {
        background-color: var(--background-highlight-color);
    }

    .bookmarks-list-loading-item-text-headline {
        height: 0.9rem;
        width: 60%;
        border-radius: var(--general-border-radius);

        &:first-child {
            width: 100%;
        }
    }

    .bookmarks-list-loading-item-text-subheadline {
        margin-top: 4px;
        height: 0.8rem;
        width: 80%;
        border-radius: var(--general-border-radius);
    }
}

no-content {
    &.no-bookmarks {
        display: block;

        i {
            color: var(--general-text-inactive-color);
            font-size: 4rem;
            margin-bottom: var(--m-spacing);
        }
    
        span {
            line-height: 150%;
        }
    
        p {
            font-size: 0.8rem;
            line-height: 135%;
            max-width: 170px;
        }
    }
    
    .no-content {
        margin-top: -70px;
    }

    @media @tablet-up {
        .no-content {
            margin-top: -100px;
        }
    }
}
