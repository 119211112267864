@import "../Import";

.close-button {
    position: relative;
    display: block;
    width: 22px;
    height: 22px;
    padding: 0;
    margin: 0;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    
    cursor: pointer;
    border: none;
    background: none;


    &:after,
    &:before {
        position: absolute;
        display: block;
        content: '';
        width: 28px;
        height: 1px;
        background: var(--sidemenu-text-color);
        -moz-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        left: -2px;
        opacity: .3;
    }

    &:after {
        -moz-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
    }
}
