@import "../Import.less";

.dutyplan {

    .tab {
        .list {
            margin-top: 20px;
        }

        table {

            td {
                line-height: 36px;

                &.ranking {
                    background-color: #eee;
                    width: 30px;
                    text-align: center;
                }

                padding-left: 15px;
                //border-bottom:1px solid #eee;
                .round-image {
                    margin-left: 5px;
                }

                &.workhour {
                    text-align: right;
                    padding-right: 15px;
                }

                .dateinfo {
                    width: 100%;
                    overflow: hidden;
                }

                .departmentinfo {
                    font-size: 0.8em;
                    opacity: 0.85;
                    display: block;
                    line-height: 15px;
                }

                .hours {
                    width: 95px;
                    overflow: hidden;
                }

                .shifttypealias {
                    font-size: 0.8em;
                    opacity: 0.85;
                    display: block;
                    line-height: 15px;
                }
            }
        }
    }

    .header {
        font-size: 24px;
        text-transform: uppercase;
        margin: 20px;
        text-align: center;

        
    }

    .footer {
        position: relative;
        bottom: -20px;
        font-size: 14px;
        text-align: center;
        width: 100%;
    }

    .section {
        .section-header {
            background-color: #eee;
            line-height: 40px;
            padding-left: 20px;

            &.inactive {
                color: #ccc;
            }
        }

        table {

            td {
                line-height: 36px;
                padding-left: 15px;

                &.hours {
                    text-align: right;
                    padding-right: 15px;
                }
            }
        }

        margin-bottom: 10px;
    }

    .guide {
        padding: 20px;
    }

    .goto-system {
        margin-top: 20px;
        text-align: center;
    }
}

.dutyplan-department {
    .header {
        font-size: 16px;
        margin: 15px;
        text-align: center;

        .departmentname {
            display: block;
            font-size: 20px;
            font-weight:600;
            margin-bottom:4px;
        }
    }

    .list {
        .user {
            overflow: hidden;
            padding-bottom: 14px;
        }

        .details {
            .name {
                text-align: left;
                font-weight: 600;
            }

            .title {
                text-align: left;
                font-size: 0.8em;
                opacity: 0.85;
            }

            .hours {
                text-align: left;
            }

            .shifttypealias {
                font-size: 0.8em;
                opacity: 0.85;
                display: block;
                line-height: 15px;
            }
        }
    }
}

.shiftType-OnDuty {
    background-color:#e0f7fb;
}

.shiftType-Absence {
    background-color:#fbfbe0;
}