.intro-site-wrapper {
    .intro-site-global-loading {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        background: var(--header-background-color);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        z-index: 10;

        .spinner {
            border-color: var(--header-text-color);
            width: 100px;
            height: 100px;
        }
    }
}

.intro-site-main {
    height: 100%;
    min-height: 100%;
    display: block;
}

.show-intro {
    &:not(.state-change){
        .intro-site-container{
            position: relative;
        }
    }
}

.intro-site-container {
    display: flex;
    height: 100%;
    min-height: 100%;
    flex-direction: column;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;

    .intro-site-header {
        height: 20px;
        overflow: hidden;
        width: 100%;
        flex: 0 1 auto;
        background: var(--header-background-color);

        a {
            display: inline-block;
            min-width: 100px;
            box-sizing: border-box;
            font-size: 13px;
            color: var(--header-text-color);
            text-decoration: none;
            cursor: pointer;
            height: 20px;
            line-height: 20px;
            padding: 0 10px;

            span {
                display: inline-block;
                margin-left: 5px;
            }
        }
    }

    iframe {
        flex: 1 1 auto;
        border: 0;
    }
}