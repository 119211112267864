barcode-reader {
    display: flex;

    rl-icon {
        color: var(--general-text-color);
    }

    .camera {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        z-index: 1000;

        video {
            position: fixed;
            left: 0;
            top: 0;
            bottom: 0;
            right: 0;
            width: 100%;
            height: 100%;
            background: white;
        }
    }

    .area {
        position: absolute;
        z-index: 100;
        border: 2px solid red;
        top: 35%;
        bottom: 35%;
        left: 15%;
        right: 15%;
    }

    .close-camera {
        z-index: 100;
        position: absolute;
        padding: 0;
        top: 15px;
        right: 15px;
        font-size: 25px;
    }

    #interactive.viewport > canvas, #interactive.viewport > video {
        max-width: 100%;
        width: 100%;
    }

    canvas.drawing, canvas.drawingBuffer {
        position: fixed;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        height: 100%;
        max-width: 100%;
    }
}