training-scores {
    display: flex;
    padding: 10px;

    @media @desktop-up {
        padding: 10px 0;
    }

    > div {
        position: relative;
        width: ~'calc(50% - 5px)';
        box-sizing: border-box;

        padding: 20px 10px 10px 10px;
        border-radius: var(--training-border-radius);

        text-align: center;
        background: var(--background-highlight-color);
        
        &:first-of-type {
            margin-right: 10px;
        }

        h2 {
            margin: 0;
            font-size: 20px;
            line-height: 25px;
            letter-spacing: 0;
        }

        .rl-subheadline {
            margin: 5px 0 10px 0;
            font-size: 10px;
            line-height: 10px;
        }

        rl-progress {
            .bar .inner {
                background: var(--general-text-color);
            }

            &.green .bar .inner {
                background: var(--confirmation-color);
            }

            &.yellow .bar .inner {
                background: var(--alert-color);
            }

            &.red .bar .inner {
                background: var(--warning-color);
            }
        }
    }

    tip .tip-content {
        z-index: 155;

        top: -30px;
        right: 5px;

        width: 260px;
        padding: 10px;

        text-align: left;

        &:after {
            left: 65%;
            bottom: -4px;
        }

        @media @tablet-up {
            right: 25%;
            margin-right: -130px;
            &:after {
                left: 50%;
            }
        }
    }
}