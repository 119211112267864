conversation {
    .popup-wrapper .popup-content {
        background-color: var(--background-highlight-color)
    }

    header-popup, footer {
        display: flex;
        height: 55px;
        justify-content: space-between;
        align-items: center;
        top: 0;
        width: 100%;
        box-sizing: border-box;
        z-index: 99;
    }

    header-popup {
        .title-slot {
            padding: 0;
        }

        title-slot {
            width: 100%;
            display: flex;
            overflow: hidden;
            gap: var(--s-spacing);
        }

        profile-image.channel-image {
            width: 40px;
            height: 40px;

            &:nth-child(2),
            &:nth-child(3) {
                border-color: var(--header-background-color);
            }
        }
        
        profile-image {
            .leave-icon {
                bottom: 0;
                right: 0;
                color: rgba(var(--header-text-color-rgb), .4);
            }
        }

        .channel-name {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: flex;
            flex-direction: column;
            justify-content: center;

            &:hover {
                cursor: pointer;
            }

            .channel-title {
                text-overflow: ellipsis;
                overflow: hidden;
                font-weight: bold;
                font-size: 1rem;
            }

            .info {
                text-overflow: ellipsis;
                overflow: hidden;
                font-size: 0.8rem;
                color: rgba(var(--header-text-color-rgb), 0.4);
            }
        }

        .left-buttons, .right-buttons {
            min-width: 45px;
        }
    }

    .messages-list-wrapper {
        flex: 1;
        overflow-y: auto;
        flex-direction: column;
        display: flex;
        overscroll-behavior-y: none;

        &:has(.message.highlighted) {
            overflow: hidden;
        }
    }

    .messages-loading {
        position: absolute;
        z-index: 100;
        top: 55px;
        right: 0;
        left: 0;
        bottom: 55px;
        display: flex;
        justify-content: center;
        align-items: center;

        .spinner {
            width: 50px;
            height: 50px;
        }
    }
    
    .shimmer-container {
        position: absolute;
        z-index: 100;
        top: 55px;
        right: 0;
        left: 0;
        bottom: 55px;
        justify-content: flex-end;
        
        .lazy-placeholder {
            min-width: 60%;
            margin-right: auto;

            &:nth-child(2n),
            &:first-child {
                margin-left: auto;
                margin-right: 0;

                .placeholder-shimmer {
                    margin-left: auto;
                }
            }
        }
    }

    .messages-list {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        flex: auto;
        opacity: 0;
        padding: var(--spacing) var(--m-spacing);
        box-sizing: border-box;
        transition: opacity .3s;

        &.show {
            opacity: 1;
        }

        .message-wrapper {
            position: relative;

            &.highlight:after {
                content: '';
                position: absolute;
                z-index: -1;

                top: -2px;
                right: -15px;
                bottom: 2px;
                left: -15px;

                display: block;
                transition: background-color 0.2s linear;
                background-color: rgba(var(--highlight-color-rgb), 0.1);
            }
        }

        .message-wrapper:not(.deleted) + .deleted {
            margin-top: 5px;
        }
    }

    .messages-empty {
        top: 40%;
        z-index: -1;
    }

    .scroll-to-bottom {
        position: absolute;
        z-index: 2;
        bottom: calc(env(safe-area-inset-bottom, 0) + 70px);
        left: 50%;
        transform: translateX(-50%);

        button {
            width: 36px;
            min-width: 36px;
            height: 36px;
            filter: drop-shadow(0px 6px 15px rgba(0, 0, 0, 0.15));

            span {
                width: 15px;
                min-width: 15px;
            }
        }
    }

    .replied-content {
        overflow: hidden;
        background-color: var(--general-background-color);
        border-radius: var(--general-border-radius);

        .title {
            padding: 15px 10px;
            font-weight: 700;
            font-size: 15px;
            line-height: 1.3;
        }
    }

    popup-wrapper:not(.keyboard-open) {
        footer {
            padding-bottom: env(safe-area-inset-bottom, 0);
        }
    }

    .typing-wrapper {
        display: flex;
        align-items: center;
        padding: 0 var(--m-spacing);
        background-color: var(--background-highlight-color);
        margin-top: ~"calc(var(--spacing) / -2)";

        .channel-image {
            width: 30px;
            height: 30px;
            min-width: 30px;
        }

        .profile-images-typing {
            display: flex;
            align-items: center;

            .profile-image-typing {
                width: 40px;
                height: 40px;
                background-size: cover;
                background-position: center;
                border-radius: 50%;
                margin-left: -10px;
                box-sizing: border-box;
                border: 2px solid var(--background-highlight-color);
            }
        }

        .profile-images-typing-wrapper:not(:first-child) {
            margin-left: -5px;
        }

        .message {
            display: flex;
            padding: 5px;
            margin-bottom: 5px;
            text-align: left;
            align-items: center;
        }

        .typing-label {
            font-size: 0.73333rem;
            max-width: calc(100vw - 170px);
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        .comma {
            margin-right: 3px;
        }

        .is-typing {
            margin-left: 4px;
            font-size: 0.73333rem;
        }

        .message-body-wrapper {
            position: relative;
            padding-left: 7px;
            width: 40px;
            display: inline-block;
            border-radius: var(--general-border-radius);

            .message-body {
                min-height: 18px;
                white-space: normal;
                text-align: left;

                .typing-indicator {
                    position: absolute;
                    left: 50%;
                    margin-left: -18px;
                    top: 50%;
                    margin-top: -14px;

                    span {
                        animation-name: blink;
                        animation-duration: 1.4s;
                        animation-iteration-count: infinite;
                        animation-fill-mode: both;
                        font-size: 40px;
                        font-weight: bold;
                        line-height: 8px;

                        &:nth-child(2) {
                            animation-delay: .2s;
                        }

                        &:nth-child(3) {
                            animation-delay: .4s;
                        }
                    }
                }
            }
        }
    }

    .leave-text {
        border-radius: var(--general-border-radius);
        border: 1px solid var(--general-border-color);
        padding: var(--m-spacing);
        margin: 0 var(--spacing) var(--spacing);
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        cursor: pointer;
        align-items: center;
        background-color: var(--background-highlight-color);
        
        &:hover {
            background-color: var(--elevated-background-highlight-color);
        }

        &.hide-message {
            display: none;
        }

        .message-box {
            display: flex;
            flex-direction: column;
            flex: auto;

            rl-icon[icon="xmark"] {
                font-size: 19px;
                margin: 0 10px;
            }
        }
        
        .read-more {
            color: var(--general-text-inactive-color);
            text-transform: lowercase;
        }
    }

    footer {
        position: sticky;
        bottom: 0;
        height: auto;
        margin-top: auto;
        flex-direction: column;
        align-items: stretch;
        background-color: var(--general-background-color);

        .editing, .replying-message {
            display: flex;
            justify-content: space-between;
            align-items: center;

            margin: var(--m-spacing) var(--m-spacing) 0;
            padding: 10px;
            font-size: 13px;

            border: 1px solid var(--general-border-color);
            border-radius: var(--general-border-radius);
        }

        .replying-message {

            [icon=reply] {
                padding: 0 10px;
            }

            .message {
                display: flex;
                flex: 1;
                align-items: center;

                overflow: hidden;
            }

            .right {
                display: flex;
                flex: 1;
                flex-direction: column;
                overflow: hidden;
            }

            .text {
                overflow: hidden;
                margin-right: 15px;
                white-space: nowrap;
                text-overflow: ellipsis;
                color: var(--general-text-inactive-color);
            }

            .author {
                margin-bottom: 5px;
            }

            file-preview {
                position: relative;
                overflow: hidden;
                margin-right: 10px;
                border-radius: 5px;
                width: 35px;
                height: 35px;

                .file-icon {
                    font-size: 25px;
                }
            }
        }

        .uploads-container {
            display: flex;
            flex-direction: column;
            padding-top: var(--spacing);
            gap: var(--s-spacing);
            border-bottom: solid 1px var(--general-border-color);
        }

        .uploads-label {
            font-size: 0.65rem;
            text-transform: uppercase;
            margin: 0;
            font-weight: 400;
            padding-left: var(--spacing);
        }

        scroll-shadow {
            overflow: hidden;
            flex: 1 0 100%;
        }

        .uploads {
            width: 100%;
            box-sizing: border-box;
            background: inherit;
            display: flex;
            gap: var(--m-spacing);
            overflow-y: hidden;
            overflow-x: auto;
            -webkit-overflow-scrolling: touch;
            padding: 0 var(--spacing) var(--m-spacing);

            .media-thumbnail {
                display: block;
                float: left;
                min-width: 77px;
                height: 77px;
                box-sizing: border-box;
                position: relative;
                border-radius: var(--general-border-radius);

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center;
                }

                &:before {
                    display: block;
                    position: absolute;
                    top: 5px;
                    right: 5px;
                    content: "\F156";
                    font-family: @icon-mdi;
                    color: var(--white-color);
                    text-shadow: -1px -1px 0 rgba(0, 0, 0, 0.5), 1px -1px 0 rgba(0, 0, 0, 0.5), -1px 1px 0 rgba(0, 0, 0, 0.5), 1px 1px 0 rgba(0, 0, 0, 0.5);
                }
            }

            &:after {
                display: table;
                clear: both;
                content: '';
            }
        }

        [icon="close"] {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            backdrop-filter: blur(24px);
            background: rgba(0, 0, 0, 0.1);
            font-size: 12px;
        }

        .form {
            display: flex;
            width: 100%;
            box-sizing: border-box;
            align-items: center;
            justify-content: space-between;
            padding-right: var(--s-spacing);

            .text-input {
                width: 100%;
                position: relative;
            }

            mention-textarea {
                border: none;
                padding: 8px 28px 4px 10px;
                line-height: 25px;
                display: block;
                max-height: 150px;
                resize: none;
                color: var(--general-text-color);
                overscroll-behavior-y: contain;

                .ui-mention-container {
                    .mention-dropdown {
                        bottom: 44px;
                        left: -19px;
                        right: -72px;
                    }

                    textarea {
                        resize: none;
                        background: none;
                        border: none;
                        box-sizing: border-box;
                        display: block;
                        font-size: 15px;
                        height: 36px;
                        line-height: 20px;
                        margin: 0;
                        max-height: 150px;
                        min-height: 36px;
                        overflow-y: auto;
                        padding: 8px 35px 8px 5px;
                        width: 100%;
                        color: var(--general-text-color);
                    }
                }

                &::-webkit-input-placeholder {
                    color: rgba(var(--general-text-color-rgb), 0.7);
                }

                &::-webkit-resizer {
                    display: none;
                }

                @media @desktop-up {
                    &::-webkit-scrollbar {
                        width: 5px;
                    }

                    &::-webkit-scrollbar-thumb {
                        background: var(--background-highlight-color);
                    }

                    &::-webkit-scrollbar-track {
                        background: var(--background-highlight-color);
                    }

                    & {
                        scrollbar-width: thin;
                    }
                }

            }

            .btn-upload {
                position: absolute;
                display: flex;
                align-items: center;
                justify-content: center;
                right: 4px;
                top: 0;
                bottom: 0;
                width: 30px;

                input {
                    opacity: 0;
                    height: 100%;
                    box-sizing: border-box;
                    padding: 0;
                    width: 40px;
                    line-height: 36px;
                }
            }

            button {
                padding: 0 5px;
                text-align: center;
                color: var(--highlight-color);

                &[disabled] {
                    opacity: 0.4;
                    color: var(--general-text-color);
                }

                [icon=spinner] {
                    animation: fa-spin 2s linear infinite;
                }

                &.sending {
                    opacity: 0.4;

                    [icon=send] {
                        display: none;
                    }
                }

                &:not(.sending) {
                    [icon=spinner] {
                        display: none;
                    }
                }
            }
        }
    }

    .blocked-title {
        display: flex;
        align-items: center;
        padding-left: 10px;
    }
}

@keyframes blink {

    0% {
        opacity: .2;
    }

    20% {
        opacity: 1;
    }

    100% {
        opacity: .2;
    }
}

@import "./MessageItem/MessageItem";