@import "../Import.less";

.validation-message {
    display: block;
    max-height: 0;
    overflow: hidden;
    opacity: 0;
    font-size: 0.9rem;
    font-weight: 600;
    box-sizing: border-box;
    transition: all ease-in-out .2s;
    width: 100%;

    &.visible {
        max-height: 100px;
        opacity: 1;
        margin-top: 4px;
    }

    .msg {
        display: flex;
        align-items: center;

        p {
            margin: var(--s-spacing);
        }

        rl-icon {
            span {
                border-radius: 50%;
                font-size: 0.75rem;
                font-weight: 800;
                width: 1.125rem;
                height: 1.125rem;
                line-height: 1.125rem;
            }
        }
    }

    .required-field-empty {
        rl-icon span {
            background-color: var(--alert-color);
            color: var(--general-text-color);
        }
    }

    .error-message {
        color: var(--warning-color);

        rl-icon span {
            background-color: var(--warning-color);
            color: var(--white-color);
            font-size: 0.65rem;
        }
    }
}