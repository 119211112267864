@import "../Variables";

.ui-mention-container {
    position: relative;

    textarea, input {
        background: none;
        position: relative;
        z-index: 2;
        box-sizing: border-box; // Prevent scrollbar for autogrow

        &[disabled] {
            opacity: 0.5;
        }
    }

    textarea {
        min-height: 100px;
    }

    input {
        min-height: 40px;
    }

    .ui-mention-highlight {
        display: none;
        padding: 10px;
        white-space: pre-wrap;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: white;
        color: rgba(0, 0, 0, 0);
        z-index: 1;

        &.short {
            margin: 2px 0 0 0;
            padding: 0 5px;
            white-space: nowrap;
            overflow: hidden;
        }
    }

    &.short {
        .mention-dropdown {
            position: absolute;
            bottom: 47px;
            left: -15px;
            right: -40px;

            max-height: 150px;
            width: auto;
            overflow-y: auto;
        }
    }
}

.mention-dropdown {
    position: fixed;
    z-index: 10;
    left: 0;

    width: 100%;
    max-height: 150px;
    overflow-y: auto;
    margin: 0;
    padding: 0;
    background: var(--general-background-color);
    box-shadow: 0 2px 15px 0 rgba(60, 72, 88, 0.1);
    
    profile-image {
        .leave-icon {
            bottom: 0;
        }
    }

    @media @tablet-max {
        &::-webkit-scrollbar {
            -webkit-appearance: none;
        }

        &::-webkit-scrollbar:vertical {
            width: 11px;
        }

        &::-webkit-scrollbar:horizontal {
            height: 11px;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 8px;
            border: 2px solid var(--general-text-color); /* should match background, can't be transparent */
            background-color: rgba(0, 0, 0, .5);
        }
    }

    li {
        position: relative;
        display: flex;
        align-items: center;
        padding: 7px 15px;

        &:first-of-type {
            padding-top: 15px;
        }

        &:last-of-type {
            padding-bottom: 15px;
        }

        p {
            margin: 0;
            line-height: 1;
        }

        &:hover, &.active {
            background: ~'rgba(var(--general-text-color-rgb), 0.1)';
        }
    }

    .completed {
        display: none;
        padding: 5px;
        margin-left: auto;

        border-radius: 100%;
        background: @clr-highlight;
        color: white;
        font-size: 10px;
    }

    .content {
        overflow: hidden;
    }

    .name {
        display: flex;
        flex-direction: row;

        margin: 0;

        font-weight: 600;
        padding-bottom: 5px;
        color: var(--general-text-color);
        font-size: 15px;

        .tag {
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .featured {
            margin-left: 5px;
            color: var(--alert-color);
        }
    }

    .profile-image {
        width: 34px;
        min-width: 34px;
        height: 34px;
        margin-right: 10px;
    }

    .tag-label {
        display: flex;
        justify-content: center;
        align-items: center;

        min-width: 34px;
        height: 34px;
        margin-right: 10px;
        font-size: 20px;
        border-radius: 100%;
        color: var(--text-on-highlight-color);
        background: var(--highlight-color);
    }
}
