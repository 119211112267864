customer-field {
    display: flex;

    lazy-datalist {
        flex: 1;

        .lazy-datalist {
            height: 100%;

            .lazy-datalist-btn {
                height: auto;
                line-height: 1;
                padding: 12px;

                p {
                    &.title {
                        padding-bottom: 5px
                    }

                    &.subtitle {
                        padding-bottom: 3px;
                    }

                    &:last-of-type {
                        padding-bottom: 0;
                    }
                }
            }
        }
    }
}
