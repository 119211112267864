@import "../Import.less";

:root {
    --sidemenu-width: 360px;
}

@media @desktop-up-to-desktop-big {
    :root {
        --sidemenu-width: 280px;
    }
}

@media @desktop-big-up {
    :root {
        --sidemenu-width: 360px;
    }
}


@menu-social-footer-height: 50px;
@menu-header-height: 90px;
@menu-header-height-desktop: 60px;

@supports (-webkit-overflow-scrolling: touch) {
    .menu .menu-list .menu-list-root {
        height: calc(~'100% + 1px');
    }
}

.menu-overlay {
    display: none;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: @level-menu;
    background: rgba(0, 0, 0, 0.6);

    &.open {
        display: block;
    }
}

.main-menu-wrapper {
    @media @desktop-up {
        position: fixed;
        width: var(--sidemenu-width);
        height: 100%;
        top: 0;
        left: 0;
        z-index: 1;
    }
    // Fix base menu styles for native template
    &.native {
        .menu-overlay {
            background: none;
        }

        .menu {
            // adding delay for visibility to prevent any actions with menu after it disappears
            transition: transform .5s, visibility .2s, z-index 0s;

            @media @tablet-max {
                width: 75%;
                left: auto;
                right: 0;
                transform: translate3d(20%, 0, 0);
                background: none;
                box-shadow: none;
                z-index: 0;
                visibility: hidden;
            }

            &.open {
                transform: translate3d(0, 0, 0);
                z-index: 400;
                visibility: visible;
                // adding delay for z-index property when menu appears
                transition: transform .3s, z-index .3s .2s, visibility 0s 0s;
            }

            .menu-close-btn {
                display: none;
            }

            .menu-header {
                background: none;
            }

            .menu-list {
                padding: 15px 30px 25px;
                box-sizing: border-box;
            }

            .menu-search {
                overflow: hidden;
                display: flex;

                > button {
                    padding: 0 34px 0 8px;
                    background: transparent;
                    border-radius: 0;
                    color: var(--sidemenu-text-color);
                    border: 1px solid var(--sidemenu-text-color);
                    height: 40px;

                    > .icon-container {
                        right: 10px;
                        left: auto;
                        top: 0;
                        line-height: 40px;
                        font-size: 22px;
                    }
                }
            }

            .gradient {
                content: "";
                position: absolute;
                z-index: 999;
                left: 25px;
                right: 25px;
                height: 60px;

                opacity: 0;
                box-sizing: border-box;
                pointer-events: none;
                transition: opacity .3s;

                &.top-gradient {
                    top: 15px;
                }

                &.bottom-gradient {
                    bottom: 25px;
                }

                &.active {
                    opacity: 1;
                }

                @media @desktop-up {
                    display: none;
                }
            }

            .menu-social-footer {
                @media @tablet-max {
                    bottom: 15px;
                }

                a {
                    background: transparent;
                }
            }
        }
    }
}

.menu {
    max-width: var(--sidemenu-width);
    background-color: var(--sidemenu-background-color);
    overflow: hidden;
    transition: transform .2s ease-in-out, max-width .2s ease-in-out;

    @media @tablet-max {
        z-index: @level-menu + 1;
        will-change: transition;
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        width: 85%;
        transform: translate3d(-105%, 0, 0);
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    }

    @media @desktop-up {
        position: relative;
        flex: 1;
        border-right: 1px solid #ccc;
        z-index: 20;
        height: 100%;
    }

    &.open {
        transform: translate3d(0, 0, 0);
    }

    .menu-header {
        position: relative;
        width: 100%;
        height: @menu-header-height;
        overflow: hidden;
        background-color: var(--sidemenu-background-color);
        box-sizing: border-box;
        transform: translate3d(0, 0, 0);

        @media @desktop-up {
            height: @menu-header-height-desktop;
        }

        .menu-close-btn {
            position: absolute;
            left: 12px;
            top: 50%;
            display: block;
            width: 22px;
            height: 22px;
            padding: 0;
            margin: 0;
            cursor: pointer;
            border: none;
            background: none;
            margin-top: -12px;
            z-index: 2;
            box-sizing: border-box;

            @media @desktop-up {
                display: none;
            }

            &:after,
            &:before {
                position: absolute;
                display: block;
                content: '';
                width: 28px;
                height: 1px;
                background-color: var(--sidemenu-text-color);
                transform: rotate(45deg);
                left: -2px;
                opacity: .3;
            }

            &:after {
                transform: rotate(-45deg);
            }
        }

        .logo {
            position: absolute;
            left: 0;
            right: 0;
            top: 10px;
            bottom: 10px;
            text-align: center;
            z-index: 1;
            display: flex;
            align-items: center;

            cursor: pointer;

            img {
                display: block;
                margin: 0 auto;
                height: auto;
                max-height: 100%;
                width: auto;
                max-width: 140px;
            }
        }
    }

    .menu-list {
        position: absolute;
        top: @menu-header-height;
        width: 100%;
        padding: 0;
        margin: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        flex-direction: column;

        @media @desktop-up {
            top: @menu-header-height-desktop;
        }

        &.with-social {
            bottom: @menu-social-footer-height;
        }

        &.no-children {
            .menu-list-root li a rl-badge {
                right: 10px;
            }
        }

        .menu-search {
            overflow: hidden;
            display: flex;

            > button {
                flex: 1;
                display: block;
                align-items: center;
                border-radius: 15px;
                background-color: #ccc;
                color: black;
                text-decoration: none;
                margin: 8px;
                padding: 0 10px 0 34px;
                height: 30px;
                line-height: 30px;
                cursor: pointer;
                box-sizing: border-box;
                position: relative;
                overflow: hidden;
                border: none;
                text-align: left;

                > .icon-container {
                    position: absolute;
                    height: 30px;
                    left: 12px;
                    top: 0;
                    line-height: 30px;

                    > i {
                        position: relative;
                        top: 1px;
                    }
                }
            }
        }

        .menu-list-root-wrap {
            flex: 1;
            -webkit-overflow-scrolling: touch;
            overflow-x: hidden;
            overflow-y: auto;
            overscroll-behavior: none;
        }

        .menu-list-root {
            display: block;
            width: 100%;
            padding: 0;
            margin: 0;
            list-style: none;

            li {
                display: block;
                list-style: none;
                box-sizing: border-box;

                &.btn-download-wrapper {
                    padding: 15px 10px;
                }

                &.open {
                    button .open-list i {
                        transform: rotate(90deg);
                    }
                }

                &.current {
                    button, a {
                        color: var(--sidemenu-current-text-color);
                    }
                }

                ul:not(.menu-list-root) {
                    display: block;
                    list-style: none;
                    padding: 0;
                    margin: 0;
                    width: 100%;
                    max-height: 0;
                    transition: all .2s ease-in-out;
                    overflow: hidden;
                    border-bottom: none;

                    li {
                        button {
                            font-size: 13px;
                            height: 34px;
                            line-height: 34px;
                            border-bottom: none;
                            border-top: none;
                            justify-content: left;
                            align-items: center;

                            .name {
                                height: 34px;
                                line-height: 34px;

                                &:before {
                                    display: inline-block;
                                    content: '-';
                                    margin-right: 7px;
                                }
                            }
                        }

                        a {
                            span:before {
                                display: inline-block;
                                content: '-';
                                margin-right: 7px;
                            }
                        }
                    }
                }

                button, a {
                    position: relative;

                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    width: 100%;
                    height: 40px;
                    padding: 0 10px;
                    margin: 0;
                    overflow: hidden;

                    box-sizing: border-box;
                    line-height: 40px;
                    font-size: 15px;
                    color: var(--sidemenu-text-color);
                    text-decoration: none;
                    cursor: pointer;
                    background: transparent;
                    text-align: left;

                    &.btn-download {
                        background: var(--highlight-color);
                        color: var(--text-on-highlight-color);
                        text-transform: uppercase;
                        height: 40px;
                        line-height: 40px;
                        text-align: center;
                        padding: 0 10px;
                        display: block;
                        border: none;
                        width: 100%;
                    }

                    .left {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        max-width: calc(~'100% - 10px');
                        box-sizing: border-box;
                    }

                    rl-badge {
                        margin-left: 10px;
                    }

                    .name {
                        position: relative;
                        overflow: hidden;
                        max-width: 100%;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        box-sizing: border-box;
                    }

                    .open-list {
                        display: block;
                        height: 40px;
                        line-height: 40px;
                        text-align: center;
                        font-size: 12px;
                        margin-left: 10px;

                        i {
                            transition: transform .2s ease-in-out;
                        }
                    }
                }
            }
        }
    }

    .menu-social-footer {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;

        display: block;
        width: 100%;
        height: @menu-social-footer-height;
        margin: 0;
        padding: 0;

        line-height: @menu-social-footer-height;
        text-align: center;
        transform: translate3d(0, 0, 0);

        a {
            display: inline-block;
            position: relative;

            top: -2px;
            height: 36px;
            width: 36px;
            padding: 7px;

            font-size: 18px;
            line-height: 22px;

            text-decoration: none;
            text-align: center;
            border-radius: 50%;
            box-sizing: border-box;
            vertical-align: top;
            color: var(--sidemenu-text-color);

            @media (max-width: 400px) {
                padding-left: 0;
                padding-right: 0;
                width: 30px;
                height: 36px;
            }

            span {
                position: relative;
                top: 1px;
            }

            > span[class*="fa-"] {
                font-size: 19px;
            }

            img {
                max-width: 100%;
                max-height: 100%;
                display: inline-block;
            }
        }
    }
}

.menu .top-gradient {
    background-image: linear-gradient(to top, transparent, var(--sidemenu-background-color))
}

.menu .bottom-gradient {
    background-image: linear-gradient(to bottom, transparent, var(--sidemenu-background-color))
}

