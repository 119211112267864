contact-item {
    .contact-item-component {
        @media @desktop-up {
            padding: 0 20px 0 0;
        }

        display: flex;
        justify-content: space-between;
        margin: 4px 0;
        cursor: pointer;

        .left {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100%;
            height: 44px;
            overflow: hidden;
        }
    }

    .contact-name-title {
        margin-left: 5px;
        width: 100%;
        overflow: hidden;

        .title {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            color: rgba(var(--general-text-color-rgb), 0.5);
        }

        .name {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .rl-small-text {
            color: rgba(var(--general-text-color-rgb), 0.5);
        }
    }

    .contact-buttons-container {
        display: inline-flex;
        margin: 5px 0 0 0;

        .contact-button {
            height: 30px;
            width: 30px;
            margin-left: 10px;
            border-radius: 100%;

            rl-icon {
                font-size: 14px;
                margin-top: 8px;
                margin-left: 6px;

                &[icon=phone], &[icon=comment], &[icon=comment] {
                    color: var(--confirmation-color);
                }

                &[icon=email] {
                    color: var(--highlight-color);
                }
            }

            &:not(.mail) {
                background: rgba(var(--confirmation-color-rgb), 0.2);
            }

            &.mail {
                background: rgba(var(--highlight-color-rgb), 0.2);
            }

            &.disabled {
                background: rgba(var(--general-text-color-rgb), 0.07);

                rl-icon {
                    color: rgba(var(--general-text-color-rgb), 0.3);
                }
            }
        }
    }

    profile-image {
        .profile-image {
            min-height: 36px;
            min-width: 36px;
        }
    }
}
