.search-field {
    width: 100%;
    height: 100%;
    text-align: left;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;

    transform: translateX(calc(~'100% - 50px'));

    &.ng-animate {
        animation-duration: .5s;
        animation-name: bounce-close;
        animation-fill-mode: forwards;
        transition: transform .3s;
    }

    &.ng-leave {
        display: none;
    }

    button {
        width: 45px;
        padding: 0;
    }

    &.active {
        animation-duration: .5s;
        animation-name: bounce-open;
        animation-fill-mode: forwards;

        input {
            opacity: 1;
        }
    }

    input {
        font-size: 15px;
        padding: 0;
        background: none;
        border: none;
        width: 75%;
        opacity: 0;
        @media @desktop-up {
            width: 100%;
        }

        &::-webkit-input-placeholder {
            color: ~'rgba(var(--header-text-color-rgb), 0.4)';
        }

        &::-moz-placeholder {
            color: ~'rgba(var(--header-text-color-rgb), 0.4)';
        }

        &:-moz-placeholder {
            color: ~'rgba(var(--header-text-color-rgb), 0.4)';
        }

        &:-ms-input-placeholder {
            color: ~'rgba(var(--header-text-color-rgb), 0.4)';
        }
    }
}

@keyframes bounce-open {
    0% {
        transform: translateX(calc(~'100% - 50px'))
    }
    30% {
        transform: translateX(-2px)
    }
    70%, 100% {
        transform: translateX(0)
    }
    45% {
        transform: translateX(6px)
    }
    75% {
        transform: translateX(1px)
    }
}

@keyframes bounce-close {
    0% {
        transform: translateX(0)
    }
    30% {
        transform: translateX(calc(~'100% - 48px'))
    }
    70%, 100% {
        transform: translateX(calc(~'100% - 50px'))
    }
    45% {
        transform: translateX(calc(~'100% - 56px'))
    }
    75% {
        transform: translateX(calc(~'100% - 51px'))
    }
}