contact-list {
    .contact-divider {
        margin: 10px 0 10px -7px;
    }

    .rl-label-inactive {
        margin: 10px 0 10px 18px;
        font-size: 12px;
        font-weight: bold;
    }

    .alphabet-list {
        width: 9px;
        position: fixed;
        right: 0;
        font-weight: bold;
        margin-right: 5px;
        text-align: center;
        font-size: 10px;
        top: 25%;

        a {
            color: var(--general-text-color);
            text-decoration: none;
            font-size: 10px;
        }
    }

    .contact-list-component {
        position: relative;
        overflow-y: scroll;
        overflow-x: hidden;
        max-height: calc(100vh - 170px);

        @media @desktop-up {
            max-height: calc(100vh - 220px);
        }
    }
}
