.base-view.base-view-policyView {
    height: auto;
    min-height: unset;
}
policy-view {

    .policy-view-container {
        position: relative;
        max-height: calc(100vh - 55px - 50px - env(safe-area-inset-bottom));
        overflow-y: auto;
        overflow-x: hidden;
        scrollbar-width: thin;
        scrollbar-color: rgba(0, 0, 0, 0.15) transparent;

        &.isDesktop {
            margin-top: var(--spacing);
            background-color: var(--background-highlight-color);
            border-radius: var(--general-border-radius);
        }
    }

    .policy-counter {
        position: absolute;
        top: 0;
        right: var(--spacing);
        color: var(--general-text-inactive-color);
    }
    
    .policy-view-top {
        height: 120px;
        width: 100%;
        padding: var(--m-spacing) var(--spacing) 0 var(--spacing);
        box-sizing: border-box;
        position: relative;
        background-repeat: repeat-x;
        flex: 0 0 auto;
        box-sizing: border-box;

        .policy-view-top-inner {
            display: block;
            margin: 0 auto;
            width: 100%;
            height: 100%;
            position: relative;
            z-index: 2;
            text-align: right;

            svg.policy-shield-element {
                height: 90px;
                position: absolute;
                left: 0;
                bottom: 10px;
                animation: policy-view-shield-animation 4.0s ease-in-out infinite;
                z-index: 3;
            }

            svg.policy-cloud-background {
                height: 100%;
            }
        }
    }

    .policy-view-content {
        padding: var(--spacing);

        .accept-date {
            .accept-date();
        }

        h2 {
            font-size: 1.4rem;
            font-weight: 600;
            line-height: 120%;
            margin: var(--m-spacing) 0 var(--spacing);
        }
    }

    .revoke-btn-container {
        position: sticky;
        bottom: -1px;
        display: flex;
        justify-content: flex-end;

        button {
            font-size: 1rem;
            line-height: 1rem;
            text-transform: uppercase;
        }

        &.isDesktop {
            padding: var(--spacing);
            background: linear-gradient(180deg, transparent 0%, var(--background-highlight-color) 69.5%);

            button {
                background-color: var(--button-background-color);
            }
        }

        &.isMobile {
            padding: 0 var(--spacing) var(--spacing);
            box-shadow: 0px -10px 35px 15px var(--general-background-color);
            background-color: var(--general-background-color);

            rl-button {
                flex-basis: 100%;
                button {
                    background: var(--warning-color);
                    width: 100%;
                }
            }
        }
    }

    .policy-view-loading {
        height: 100%;
        min-height: 100%;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .spinner {
            height: 40px;
            width: 40px;
        }
    }

    .policy-view-revoke-wrapper {
        position: fixed;
        z-index: 9999999;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0,0,0,0.8);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .spinner {
            height: 50px;
            width: 50px;
            border-color: #fff;
        }
    }
}

.revoke-consent-confirmation-popup {
    rl-button.yes button{
        color: var(--button-background-color);
    }

    button {
        text-transform: uppercase;
    }
}

@keyframes policy-view-shield-animation {
    0% {
        transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
    }

    50% {
        transform: translate3d(0, -6px, 0) scale3d(1.05, 1.05, 1.05);
    }

    100% {
        transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
    }
}