@import "../Import.less";

@profile-navigation-top-height: 70px;
@profile-navigation-border-color: #e9e9e9;
@profile-navigation-background: #f8f8f8;
@profile-navigation-item-background: #fff;

@transition-speed: .15s;

.profile-navigation-overlay {
    position: fixed;
    left: 0;
    bottom: 0;
    top: 0;
    right: 0;
    z-index: @level-profile-navigation;
    background: var(--popup-overlay-bg);
    opacity: 0;
    display: none;

    &.open {
        display: block;
        opacity: 1;
    }

    &.animate {
        transition: all @transition-speed linear;
    }
}

.profile-navigation {
    position: fixed;
    width: 80%;
    max-width: 300px;
    right: 0;
    top: 0;
    bottom: 0;
    background: @profile-navigation-background;
    z-index: @level-profile-navigation + 1;
    box-shadow: @shadow-box;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    overflow-x: hidden;
    overflow-y: auto;

    &.open {
        -webkit-transform: translateX(0%);
        transform: translateX(0%);
    }

    &.animate {
        transition: -webkit-transform @transition-speed linear;
        transition: transform @transition-speed linear;
        transition: transform @transition-speed linear, -webkit-transform @transition-speed linear;
    }

    header {
        display: flex;
        align-items: center;

        .close {
            margin-right: 10px;
        }
    }

    .profile-navigation-top {
        position: relative;
        width: 100%;
        height: @profile-navigation-top-height;
        overflow: hidden;

        .image {
            display: block;
            width: 50px;
            height: 50px;
            border-radius: 50%;
            overflow: hidden;
            background-position: center center;
            background-size: cover;
            position: absolute;
            top: ((@profile-navigation-top-height - 50) / 2);
            left: ((@profile-navigation-top-height - 50) / 2);
        }

        .info {
            display: flex;
            flex-direction: column;
            justify-content: center;

            position: absolute;
            left: 70px;
            top: ((@profile-navigation-top-height - 50) / 2);
            height: 50px;
            right: 5px;

            h2 {
                display: block;
                margin: 0;
                padding: 0;
                font-size: 17px;
                font-weight: 700;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .dept {
                display: block;
                margin: 0;
                padding: 0;
                font-size: 14px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }

    .profile-navigation-points {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 30px;
        width: 100%;
        font-size: 13px;
        text-align: center;
        border: none;
        border-top: 1px solid @profile-navigation-border-color;
        background: transparent;

        .coin {
            display: inline-block;
            position: relative;
            top: -1px;
            height: 14px;
            width: 14px;
            margin-right: 5px;
            background: url('/Content/Graphics/Coin.png') no-repeat center center;
            background-size: cover;
        }

        span {
            display: inline-block;
            line-height: 1;
        }
        .bold {
            font-weight: 600;
        }
    }

    .profile-navigation-inner {
        > ul {
            list-style: none;
            padding: 0;
            margin: 0 0 20px 0;
            border-bottom: 1px solid @profile-navigation-border-color;
            border-top: 1px solid @profile-navigation-border-color;
            background: @profile-navigation-item-background;

            > li {
                > button {
                    position: relative;
                    display: block;
                    width: 100%;
                    height: 50px;
                    box-sizing: border-box;
                    padding: 0 0 0 50px;
                    overflow: hidden;
                    font-size: 15px;
                    line-height: 50px;
                    text-align: left;
                    background: transparent;
                    border: none;

                    .icon-container {
                        position: absolute;
                        left: 0;
                        top: 0;
                        bottom: 0;
                        height: 50px;
                        line-height: 50px;
                        text-align: center;
                        font-size: 20px;
                        width: 50px;
                    }

                    .icon-chat {
                        font-size: 16px;
                    }

                    rl-badge {
                        position: absolute;
                        right: 10px;
                        top: 15px;
                    }

                    &.multi-department {
                        line-height: 20px;

                        > span {
                            p {
                                margin: 0;
                                padding: 0;
                                position: relative;
                                height: 20px;
                                top: 10px;
                                overflow: hidden;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                            }

                            i {
                                display: block;
                                font-size: 11px;
                                opacity: .7;
                                position: relative;
                                top: 5px;
                            }
                        }
                    }
                }

                &:last-child button:before {
                    display: none;
                }
            }
        }
    }
}