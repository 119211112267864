.landing-page-highscore {
    .lph-content {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 8px;
        right: 8px;
    }

    .lph-top {
        flex: 1 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        position: relative;

        .lph-image {
            width: 45px;
            height: 45px;
            display: inline-block;
            vertical-align: middle;
            border-radius: 100%;
            background-position: center;
        }

        .lph-name {
            display: flex;
            justify-content: center;
            flex-direction: column;
            position: absolute;
            top: 0;
            left: 55px;
            right: 0;
            bottom: 0;

            > h2 {
                margin: 0 0 2px 0;
                padding: 0;
                font-size: 16px;
                font-weight: 600;
                line-height: 16px;
            }

            > p {
                padding: 0;
                margin: 0;
                font-size: 12px;
                text-transform: uppercase;
            }
        }
    }

    .lph-bottom {
        height: 30px;
        line-height: 30px;
        border-top: 1px solid rgba(0,0,0,0.16);
        flex: 0 1 auto;
        width: 100%;
        font-size: 13px;
        font-weight: 600;
        text-transform: uppercase;
        text-align: center;
    }
}

.landing-page-design-creative{
    .landing-page-highscore{

    }
}