@import "../../Import.less";
@import "_WalkthroughVariables.less";

.walkthrough-slide{
    position: absolute;
    top: @walkthrough-padding;
    left: @walkthrough-padding;
    right: @walkthrough-padding;
    bottom: @walkthrough-pagination-height;
    background: #fff;
    border-radius: @walkthrough-slide-border-radius;
    .flex-center-center();
    overflow: hidden;
    .box-shadow-1();

    @media @desktop-up {
        top: @walkthrough-padding-desktop;
        width: 51vh;
        height: 90vh;
        margin: 0 auto;
    }

    &:has(.walkthrough-slide-policy) {
        background-color: var(--general-background-color);
    }
}

.walkthrough.progress .walkthrough-slide{
    bottom: @walkthrough-padding;
    top: @walkthrough-progress-height + @walkthrough-padding;
}