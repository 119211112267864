.scormcloud-course-item {
    box-sizing: border-box;
    width: 100%;
    max-width: 500px;
    overflow: hidden;
    margin: 10px auto;
    padding: 10px;
    border-radius: var(--training-border-radius);
    background-color: var(--background-highlight-color);
    cursor: pointer;
    background-image: ~'-webkit-gradient(linear, left top, left bottom, color-stop(39%, rgba(var(--general-text-color-rgb), 0)), to(rgba(var(--confirmation-color-rgb), 0.1)));';
    background-image: ~'-webkit-linear-gradient(top, rgba(var(--general-text-color-rgb), 0) 39%, rgba(var(--confirmation-color-rgb), 0.1));';
    background-image: ~'-o-linear-gradient(top, rgba(var(--general-text-color-rgb), 0) 39%, rgba(var(--confirmation-color-rgb), 0.1));';
    background-image: ~'linear-gradient(to bottom, rgba(var(--general-text-color-rgb), 0) 39%, rgba(var(--confirmation-color-rgb), 0.1));';

    &:first-of-type{
        margin-top: 0;
    }

    &.locked {
        .top h3 {
            opacity: 0.4;
        }

        .image-inner {
            opacity: 0.4;
        }
    }

    &.passed {
        background-image: ~'linear-gradient(to bottom, rgba(var(--general-text-color-rgb), 0) 39%, rgba(var(--confirmation-color-rgb), 0.1));';

        .status {
            text-transform: var(--confirmation-color);
        }
    }

    &.completed {
        background-image: ~'linear-gradient(to bottom, rgba(var(--general-text-color-rgb), 0) 39%, rgba(var(--alert-color-rgb), 0.1));';
    }

    .activity, .redo {
        color: ~'rgba(var(--general-text-color-rgb), 0.4)';
    }

    .top {
        display: flex;
        align-items: center;
        margin-bottom: 15px;

        h3 {
            margin: 0;
            font-size: 15px;
            font-weight: 600;
        }

        .duration > span {
            white-space: nowrap;
            padding-right: 20px;
            font-size: 13px;

            rl-icon {
                font-size: 13px;
            }
        }

        .image {
            position: relative;
            min-width: 80px;
            height: 80px;
            margin-right: 10px;
            border-radius: 50%;
            background-color: var(--general-background-color);
            box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.25);

            .image-inner {
                overflow: hidden;
                border-radius: 50%;
                width: 100%;
                height: 100%;
                background-size: cover;
                background-position: center center;
                text-align: center;
                background-color: var(--general-text-color);
                color: ~'rgba(var(--general-background-color-rgb), 0.4)';

                rl-icon {
                    display: block;
                    font-size: 34px;
                    line-height: 80px;
                }
            }

            state-icon {
                position: absolute;
                bottom: 0;
                right: 0;
            }
        }
    }

    .status {
        margin: 0;
        font-size: 13px;
        font-weight: 600;
    }
}
