lesson-page {
    display: block;
    box-sizing: border-box;

    padding: 0 15px 50px;

    @media @desktop-up {
        padding: 0 15px;
    }

    rl-loading-placeholder .placeholder-shimmer:not(:last-of-type) {
        margin-bottom: 15px;
    }

    .lesson {
        box-sizing: border-box;
        padding-top: 20px;
        min-height: calc(100dvh - 55px - 105px - 50px);

        @media @desktop-up {
            min-height: calc(100dvh - 60px - 105px);
        }
    }

    .finish-screen {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        flex: 1;

        h2, p {
            margin: 0;
        }

        h2 {
            margin-bottom: 5px;
        }

        .finish-lesson {
            position: relative;
            padding: 25px 15px 30px 15px;
            margin: -20px -15px 0;
            text-align: center;
            background: var(--general-background-color);

            &:not(.no-testimonials) {
                width: 100%;
            }

            &.no-testimonials {
                margin: auto;
                background: transparent;

                &:after {
                    display: none;
                }
            }


            &:after {
                content: '';
                position: absolute;
                left: 0;
                bottom: -10px;
                width: 100%;
                border-radius: 50%;
                background: var(--general-background-color);
                height: 20px;
            }


            .finish-icon {
                font-size: 80px;
            }
        }

        .stars {
            margin: 30px 0;
            text-align: center;

            &.submitted {
                margin: auto;
                width: 100%;
            }

            p {
                margin: 6px 0 23px 0;
            }
        }
    }

    .new-badge-screen {
        display: flex;
        flex: 1;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .header {
            padding: 20px 0;
            text-align: center;
        }

        h2 {
            margin: 0 0 7px 0;
        }

        p {
            margin: 0;
        }

        badges-list {
            width: 100%;
            justify-content: flex-start;
            flex-direction: column;
            align-items: center;

            badge {
                width: 50%;
                margin: 0 0 20px 0;

                p {
                    font-size: 20px;
                    margin-top: 10px;
                }

                @media @tablet-up {
                    width: 30%;
                }
            }
        }
    }

    .continue {
        position: sticky;
        bottom: 50px;
        margin: 0 -15px 0;
        padding: 20px 15px;
        padding-bottom: calc(20px + env(safe-area-inset-bottom, 0px));

        @media @desktop-up {
            bottom: 0;
        }

        &:not(.answered) {
            padding-top: 40px;
        }

        background: linear-gradient(to top, var(--background-highlight-color) 50%, rgba(var(--background-highlight-color-rgb), 0));

        rl-button {
            border-radius: var(--general-border-radius);
            display: block;
            background: var(--general-background-color);

            &.expired {
                background: transparent;
            }
        }

        &.answered {
            background: var(--background-highlight-color);
            margin-top: 15px;

            &:not(.no-score) {
                box-shadow: 0 2px 7px 0 rgba(60, 72, 88, 0.1);
            }

            &.passed, &.not-passed {
                &:before {
                    content: '';
                    position: absolute;
                    z-index: -1;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                }
            }

            &.passed {
                &:before {
                    background: rgba(var(--confirmation-color-rgb), 0.2);
                }

                .complete-feedback {
                    color: var(--confirmation-color);
                }

                rl-button button {
                    background: var(--confirmation-color);
                }
            }

            &.not-passed {
                &:before {
                    background: rgba(var(--warning-color-rgb), 0.2);
                }

                .complete-feedback {
                    color: var(--warning-color);
                }

                rl-button button {
                    background: var(--warning-color);
                }
            }
        }

        .complete-feedback {
            margin: 15px 0;
            font-weight: 600;
        }
    }
}


@import "LessonContent/LessonContent";