search-events {
    height: 100%;
    display: flex;
    flex-direction: column;

    @media @desktop-up {
        height: calc(100% - 75px);
    }

    header {
        position: relative;
        padding: 10px 0;
    }

    .search-events-container {
        display: block;
        overflow-y: auto;

        events {
            padding: 15px;

            @media @desktop-up {
                padding: 15px 0;
            }
        }
    }
}