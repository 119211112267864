my-events {
    display: flex;
    flex-direction: column;

    height: 100%;

    @media @tablet-max {
        padding: 0 15px;
    }
    @media @desktop-up {
        height: calc(100% - 75px);
        overflow-y: auto;
    }

    .no-future-events {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    h4 {
        font-weight: 700;
        margin: 25px 0 10px 0;
    }

    .lazy-wrapper {
        flex: 1;
        display: block;
        overflow: hidden;

        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
    }

    .copy-feed {
        color: var(--general-text-inactive-color);
        font-size: 15px;
    }

    #feed-url {
        position: absolute;
        overflow: hidden;
        clip: rect(0 0 0 0);
        height: 1px;
        width: 1px;
        margin: -1px;
        padding: 0;
        border: 0;
    }


    > .today-only {
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .today-events {
        @media @tablet-max {
            &:not(.today-only) {
                overflow: visible;
                overflow-x: auto;

                .events-scrolled-wrapper {
                    flex-wrap: nowrap;
                }
            }
            &.today-only {
                height: 100%;
                padding-bottom: 15px;
            }
        }
    }

    .future {
        display: flex;
        flex-direction: column;
        padding-bottom: 15px;

        @media @tablet-max {
            overflow: hidden;
        }

    }
}