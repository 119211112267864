@import "../../Import.less";
@import "landing-page-tile-loading.directive.less";
@import "landing-page-highscore.directive.less";
@import "landing-page-social.directive.less";
@import "landing-page-content.directive.less";
@import "landing-page-chart.directive.less";

@transition-speed: .2s;

.native {
    .landing-page .spacer {
        display: block;
        height: 50px;
        height: calc(50px + env(safe-area-inset-bottom, 0px));
    }

    .landing-page-background-overlay, .landing-page-background {
        bottom: 50px;
        bottom: calc(50px + env(safe-area-inset-bottom, 0px));
    }

    .scrollable-arrow {
        bottom: 70px;
        bottom: calc(70px + env(safe-area-inset-bottom, 0px));

        @media @desktop-up {
            bottom: 20px;
        }
    }
}

.scrollable-arrow {
    z-index: 10;
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    opacity: 1;
    transition: opacity 0.3s;

    @media @desktop-up {
        left: calc(50% + 140px);
    }

    @media @desktop-big-up {
        left: calc(50% + 180px);
    }

    &.hide {
        opacity: 0;
    }

    @keyframes bounce {
        0% {
            transform: translateY(0);
        }
        50% {
            transform: translateY(0);
        }
        55% {
            transform: translateY(-5px);
        }
        60% {
            transform: translateY(0);
        }
        100% {
            transform: translateY(0);
        }
    }

    .arrow {
        position: relative;
        margin: 0 auto;
        width: 30px;
        height: 3px;

        animation-name: bounce;
        animation-duration: 5s;
        animation-timing-function: ease-in-out;
        animation-iteration-count: infinite;

        .one, .two {
            &:before, &:after {
                content: '';
                position: absolute;
                top: 0;
                height: 100%;
                width: 50%;
            }

            &:before {
                left: 2px;
                transform: rotate(30deg);
            }

            &:after {
                right: 2px;
                transform: rotate(-30deg);
            }
        }

        .one {
            &:before, &:after {
                box-shadow: 0 1px 10px rgba(0, 0, 0, 0.3);
            }
        }

        .two {
            &:before, &:after {
                background: white;
            }
        }
    }
}



.landing-page-background-overlay {
    background: rgba(0, 0, 0, 0.4);
    position: fixed;
    left: 0;
    right: 0;
    top: 55px;
    bottom: 0;
    overflow-x: auto;
    overflow-y: hidden;
    box-sizing: border-box;
    z-index: 0;

    @media @desktop-up {
        top: 60px;
        left: var(--sidemenu-width);
    }

}

.landing-page-background {
    position: fixed;
    left: 0;
    right: 0;
    top: 55px;
    bottom: 0;
    z-index: 0;
    background-color: #f2f2f2;
    background-position: center center;

    @media @desktop-up {
        top: 60px;
        left:var(--sidemenu-width);
    }
}

.landing-page {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    .spacer {
        display: none;

        position: absolute;
        width: 100%;
        left: 0;
    }
}

.landing-page-tile {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    z-index: 1;
    text-decoration: none;

    .live-tile {
        height: 100%;
    }

    .landing-page-tile-background {
        position: absolute;
        left: 1px;
        top: 1px;
        right: 1px;
        bottom: 1px;
        overflow: hidden;
        background-size: cover;
        background-position: center center;
        z-index: 1;
    }

    .landing-page-tile-content {
        position: absolute;
        left: 1px;
        top: 1px;
        right: 1px;
        bottom: 1px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        overflow: hidden;
        z-index: 2;
        text-decoration: none;

        &:focus {
            outline: 2px solid -webkit-focus-ring-color;
            outline-offset: -2px;
        }

        &.overlay {
            background: rgba(0, 0, 0, 0.2);
        }

        > .icon {
            display: block;
            width: 30px;
            height: 30px;
            line-height: 30px;
            text-align: center;
            color: #fff;
            font-size: 20px;
            margin: 0 auto 3px auto;

            &.icon-image {
                margin-bottom: 8px;
            }

            > i {
                vertical-align: middle;
            }

            > img {
                display: inline-block;
                height: auto;
                width: auto;
                max-height: 100%;
                max-width: 100%;
            }
        }

        > h2,
        > h3 {
            display: block;
            margin: 0;
            text-transform: uppercase;
            font-size: 14px;
            font-weight: bold;
            color: #fff;
            max-width: 100%;
            word-break: break-word;
            padding: 2px;
            text-align: center;
        }

        > h3 {
            font-size: 13px;
            padding: 0 8px 2px 8px;
            text-transform: none;
        }

        > rl-badge {
            position: absolute;
            top: 5px;
            right: 5px;
        }

        .landing-page-card {
            position: absolute;
            background: #fff;
            left: 6px;
            right: 6px;
            top: 6px;
            bottom: 6px;
            border-radius: 6px;
            padding: 12px;
            overflow: hidden;
        }
    }

    ks-swiper-container {
        .swiper-button-prev,
        .swiper-button-next {
            opacity: .2;
            height: 32px;
            width: 30px;
            margin-top: -16px;
            background: none;
            font-size: 30px;
            color: #000;

            &.swiper-button-disabled {
                display: none;
            }

            &:before {
                display: inline-block;
                font-family: @icon-mdi;
                vertical-align: top;
            }

            &:hover {
                opacity: 1;
            }
        }

        .swiper-button-next {
            right: -2px;

            &:before {
                content: "\F142";
            }
        }

        .swiper-button-prev {
            left: -2px;

            &:before {
                content: "\F141";
            }
        }

        @media @tablet-up {
            .swiper-button-prev,
            .swiper-button-next {
                font-size: 40px;
                height: 44px;
                width: 40px;
                margin-top: -22px;
            }
        }
    }
}

// DESIGN: CREATIVE
.landing-page.landing-page-design-creative {
    .landing-page-tile {
        .landing-page-tile-content {
            .landing-page-card {
                .shadow-depth-1();
            }
        }
    }
}

// DESIGN: BASIC
.landing-page.landing-page-design-basic {
    .landing-page-tile {
        border: 1px solid rgba(255, 255, 255, 0.5);

        .landing-page-tile-background {
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
        }
    }
}
