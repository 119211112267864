popup-wrapper:not(.keyboard-open) {
    comments .comments-footer {
        padding-bottom: env(safe-area-inset-bottom, 0);
    }
}
comments {
    .post-comments {
        padding: 0;
        display: flex;
        width: 100%;
        flex-direction: column;
        height: calc(~'100% - 55px');
        box-sizing: border-box;
        background: var(--background-highlight-color);

        reaction-button {
            .reactions-list {
                @media @desktop-up {
                    margin-left: -65px;
                }
            }

            .btn-reaction {
                .reaction-icon-container {
                    reaction-icon {
                        display: none;
                    }
                }

                button {
                    rl-icon {
                        display: none;
                    }
                }
            }
        }

        .comments-list {
            position: relative;
            margin: 0;
            flex: 1 1 auto;
            overflow-y: auto;
            padding: 15px 0;

            ul {
                margin: 0;
                padding: 0;
                list-style: none;

                ul {
                    li {
                        comment-item {
                            padding-left: 65px;

                            profile-image > .profile-image {
                                width: 30px;
                                height: 30px;
                            }
                            
                            profile-image {
                                .leave-icon {
                                    width: 13px;
                                    height: 13px;
                                    font-size: 13px;
                                }
                            }
                        }

                        reaction-button .reactions-list {
                            left: -35px;

                            @media @desktop-up {
                                left: 0;
                            }
                        }
                    }
                }
            }

            .expand-thread-link {
                padding-left: 105px;
                margin-top: -15px;
                margin-bottom: 20px;
            }

            li {
                &.faded > comment-item {
                    opacity: .5;
                }

                &.faded-active {
                    .comments-actions .action-buttons {
                        opacity: .5;
                    }
                }
            }
        }

        .uploads {
            box-sizing: border-box;
            padding: 2.5px;
            max-height: 135px;
            min-height: 67px;
            overflow-y: auto;

            .media-thumbnail {
                display: block;
                float: left;
                padding: 2.5px;
                width: 25%;
                box-sizing: border-box;
                position: relative;

                img {
                    width: 100%;
                    vertical-align: top;
                }

                &:before {
                    display: block;
                    position: absolute;
                    top: 5px;
                    right: 5px;
                    content: "\F156";
                    font-family: @icon-mdi;
                    color: #fff;
                    text-shadow: -1px -1px 0 rgba(0, 0, 0, 0.5), 1px -1px 0 rgba(0, 0, 0, 0.5), -1px 1px 0 rgba(0, 0, 0, 0.5), 1px 1px 0 rgba(0, 0, 0, 0.5);
                }
            }

            &:after {
                display: table;
                clear: both;
                content: '';
            }
        }

        .empty-list {
            flex: 1 1 auto;
            text-align: center;
            padding: 40px 0;

            rl-icon {
                font-size: 40px;
            }
        }

        .comments-footer {
            flex: 0 0 auto;
            background: var(--general-background-color);
            color: var(--general-text-color);
            border-top: 1px solid var(--background-highlight-color);

            .replying-to-label {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 5px 10px 5px 15px;
                border-top: 1px solid var(--general-border-color);
                border-bottom: 1px solid var(--general-border-color);

                rl-icon {
                    cursor: pointer;
                }
            }

            form {
                display: block;
                min-height: 40px;
                line-height: 40px;
                position: relative;
                box-sizing: border-box;
                padding: 10px 40px 10px 15px;
            }

            .input-wrapper {
                position: relative;
                background: transparent;
            }

            .upload {
                position: absolute;
                right: 10px;
                top: 0;
                line-height: 40px;
                z-index: 2;
                opacity: .6;
                color: var(--general-text-color);
            }

            input[type=text] {
                display: block;
                box-sizing: border-box;
                width: 100%;
                height: 40px;
                margin: 2px 0 0 0;
                padding: 0 5px;
                font-size: 15px;
                line-height: 40px;
                background: none;
                border: none;
            }

            textarea {
                display: block;
                box-sizing: border-box;
                width: 100%;
                min-height: 36px;
                height: 36px;
                max-height: 150px;
                margin: 0;
                padding: 8px 35px 8px 5px;
                resize: none;
                overflow-y: auto;

                font-size: 15px;
                line-height: 20px;
                background: none;
                border: none;
            }

            button {
                position: absolute;
                box-sizing: border-box;
                top: 10px;
                padding: 0;
                right: 0;
                width: 40px;
                height: 40px;
                line-height: 40px;
                background: none;
                border: none;
                text-align: center;
                color: var(--general-text-color);

                rl-icon {
                    color: var(--highlight-color);
                }

                &.inactive {
                    opacity: .6;

                    rl-icon {
                        span {
                            font-weight: 300;
                            color: var(--general-text-color);
                        }
                    }
                }
            }
        }
    }
}

@import "./CommentItem/CommentItem";
