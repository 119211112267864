@import "../Import.less";

.contact {
    .count div {
        margin: 20px 0 10px 0;
        font-size: 13px;
        text-transform: uppercase;
        font-weight: 500;
        display: inline-block;

        @media @tablet-up {
            font-size: 16px;
        }
    }

    .contact-tab {
        top: 70px;
    }
    
    .tab {
        padding-bottom: 50px;
        padding-bottom: calc(50px + env(safe-area-inset-bottom, 0px));
    }

    .search {
        position: relative;
        padding: 10px;

        input {
            width: 100%;
            box-sizing: border-box;
            height: 45px;
            padding: 10px 35px 10px 15px;
            border: 1px solid @clr-borders;

            font-size: 15px;
            line-height: 20px;

            &::-webkit-search-decoration,
            &::-webkit-search-cancel-button,
            &::-webkit-search-results-button,
            &::-webkit-search-results-decoration {
                -webkit-appearance: none;
            }

            -webkit-appearance: none;

            &:focus {
                border: 1px solid @clr-highlight;
            }
        }

        button {
            position: absolute;
            padding: 10px;
            border: none;
            background: transparent;

            &.close-button {
                display: none;
            }

            &.search-button {
                right: 15px;
                top: 15px;
            }
        }
    }

    .contact-users {
        padding: 10px 10px 0 10px;
    }

    .contact-departments {
        padding-top: 5px;

        .accordion-content {
            background: #fff;

            .accordion-inner {
                text-align: center;
            }
        }

        .contact-department-link {
            display: block;
            width: 100%;
            height: 28px;
            line-height: 28px;
            text-align: center;
            background: #555;
            color: #fff;
            margin: 4px 0 6px 0;
            font-size: 13px;
            overflow: hidden;
            position: relative;
            border-radius: 4px;
            border: none;

            i {
                position: relative;
                top: 1px;
                margin-left: 3px;
                opacity: 0.5;
            }
        }
    }

    .phone-number {
        text-decoration: none;
        color: #000;
    }
}