.survey-module-list {
    display: flex;
    flex-direction: column;
    justify-content: center;

    survey-list {
        display: block;
    }
}

.survey-list {
    list-style-type: none;
    list-style-position: inside;
    position: relative;
    margin: 0;
    padding: var(--spacing) var(--spacing) 0 var(--spacing);

    .survey-list-item {
        display: block;
        margin-bottom: var(--spacing);

        .survey-list-item-inner {
            display: flex;
            position: relative;
            flex-direction: column;
            justify-content: center;
            height: 90px;

            border-radius: var(--general-border-radius);
            background-color: var(--background-highlight-color);
            background-size: cover;

            h3 {
                font-weight: bold;
                font-size: 15px;
                color: var(--general-text-color);
                margin: 0;
                padding: 0 var(--spacing);
                position: relative;
                z-index: 2;
            }

            .title-placeholder {
                width: 70%;
                height: 20px;
                display: block;
            }

            rl-badge {
                position: absolute;
                right: 0;
                margin-right: var(--m-spacing);
            }
        }
    }
}