contents {
    display: block;
    padding-bottom: 10px;

    .content-create-link {
        display: block;
        margin-bottom: 15px;

        button {
            font-weight: 400;
        }

        @media @desktop-up {
            max-width: 60vw;
        }
    }

    .content-item-placeholder {
        display: block;
        margin: var(--spacing) 0;
        background: var(--general-background-color);
        border-radius: var(--general-border-radius);

        rl-loading-placeholder {
            display: block;
            box-sizing: border-box;
            padding: 15px;

            .placeholder-shimmer {
                height: 19px;
            }
        }
    }

    .contents {
        .content {
            .media-container {
                position: relative;

                h2 {
                    display: block;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    box-sizing: border-box;
                    color: #fff;
                    padding: 0 15px 20px 15px;
                    margin: 0;
                    text-transform: uppercase;
                    font-size: 1.1em;
                }
            }

            .container {
                display: block;
                padding: 15px;
                margin: 0;

                h2 {
                    font-size: 1.1em;
                    text-transform: uppercase;
                    margin: 0 0 10px 0;
                }
            }

            .breadcrumb {
                display: flex;
                flex-wrap: wrap;
                line-height: 20px;
                text-transform: uppercase;
                font-size: 11px;
                margin: 0 0 10px 0;

                button {
                    display: flex;
                    padding: 0;
                }

                > div, content-post-total-swipes, content-total-views {
                    vertical-align: top;
                    height: 20px;
                    line-height: 20px;
                    display: inline-block;

                    span {
                        color: #c2c2c2;
                    }

                    p {
                        padding: 0;
                        margin: 0 10px 0 5px;
                    }

                    i {
                        color: #c2c2c2;
                        font-size: 12px;
                        position: relative;
                        top: -1px;
                    }

                    span, p, i {
                        display: inline-block;
                        vertical-align: top;
                        height: 20px;
                        line-height: 20px;
                        font-weight: 400;
                        font-size: 11px;
                    }
                }


                rl-badge {
                    padding: 0 5px;
                    margin-left: auto;
                    border-radius: 20px;
                }
            }

            .body-text {
                overflow: hidden;

                p {
                    padding: 0;
                    margin: 0;
                }

                &.readmore {
                    .swipe {
                        padding-bottom: 0;
                    }
                }
            }
        }
    }
}
