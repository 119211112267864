@import "../../Import.less";

@filter-bar-height: 40px;

filter-bar{
    display: block;
    height: @filter-bar-height;
    line-height: @filter-bar-height;
    width: 100%;
    overflow-y: hidden;
    overflow-x: auto;
    padding: 0 8px;
    white-space: nowrap;
    box-sizing: border-box;

    background: #eee;
    border-bottom: 1px solid #ddd;

    position: relative;

    &:before,
    &:after{
        display: block;
        width: 10px;
        height: @filter-bar-height - 1px;
        position: fixed;
        right: 0;
        content: '';
        margin-top: -@filter-bar-height - 1px;
    }

    &:before{
        right: auto;
        left: 0;
        margin-top: 0;
        background: -moz-linear-gradient(left, rgba(238,238,238,1) 0%, rgba(0,0,0,0) 100%);
        background: -webkit-linear-gradient(left, rgba(238,238,238,1) 0%,rgba(0,0,0,0) 100%);
        background: linear-gradient(to right, rgba(238,238,238,1) 0%,rgba(0,0,0,0) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#eeeeee', endColorstr='#00000000',GradientType=1 );
    }
    &:after{
        background: -moz-linear-gradient(left, rgba(0,0,0,0) 0%, rgba(238,238,238,1) 100%);
        background: -webkit-linear-gradient(left, rgba(0,0,0,0) 0%,rgba(238,238,238,1) 100%);
        background: linear-gradient(to right, rgba(0,0,0,0) 0%,rgba(238,238,238,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#eeeeee',GradientType=1 );
    }
    
    filter-list{
        display: inline-block;
        height: @filter-bar-height;
        line-height: @filter-bar-height;
        vertical-align: top;
        margin: 0 10px 0 0;
        position: relative;

        &:last-child{
            margin-right: 0;
        }

        select{
            border: none;
            background: none;
            height: @filter-bar-height - 6px;
            line-height: @filter-bar-height - 6px;
            text-transform: capitalize;

            option{
                text-transform: capitalize;
                padding: 3px 0;
                &.default{
                    font-weight: 600;
                }
            }
        }
    }
}