content-total-swipes-popup, read-by-popup {
    .scrolling {
        height: 100%;
    }

    .read-registration-no-views {
        padding-top: 50%;

        rl-icon {
            display: block;
            text-align: center;
            font-size: 70px;
        }

        p {
            display: block;
            margin: 10px 0 0 0;
            padding: 0 20px;
            text-align: center;
            font-size: 18px;
        }
    }

}

content-total-swipes-popup {
    .tabs {
        display: flex;
        justify-content: space-between;
        align-items: center;

        margin: 0;
        padding: 0;

        list-style: none;
        border-bottom: 1px solid ~'rgba(var(--general-text-color-rgb), .1)';
        width: 100%;

        li {
            flex: 1 auto;
            padding: 0 0 10px;

            text-align: center;
            font-size: 14px;
            border-bottom: 2px solid transparent;
            cursor: pointer;

            &:last-of-type {
                margin: 0;
            }

            &.active {
                border-bottom: 2px solid var(--highlight-color);
                color: var(--highlight-color);
            }

            rl-icon {
                margin-right: 5px;
                color: var(--white-color);

                &.read-by {
                    background: var(--confirmation-color);
                }

                &.not-read-by {
                    background: var(--warning-color);
                }
            }

            span {
                vertical-align: middle;
                line-height: 20px;
            }
        }
    }

    section {
        display: flex;
        flex-direction: column;
        flex: 1;
        overflow-y: auto;
    }

    simple-user-list {
        height: 100%;
    }
}


