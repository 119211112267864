@import "../Import.less";

.instore {

    &.instore-guide{
        h2 {
            text-transform: uppercase;
            font-size: 1.05em;
            margin: 0;
            padding: 10px;
        }
        .bodytext{
            padding: 0 10px;
            margin-bottom: 10px;
        }
        .media-list{
            media{
                margin-bottom: 5px;
            }
        }
    }

    &.instore-upload {
        padding: 10px;

        h2 {
            text-transform: uppercase;
            font-size: 1.05em;
            margin: 0;
            padding: 0;
        }

        form {
            display: block;
            margin: 15px 0 0 0;
            border: 1px solid #fff;
            border-color: darken(@split-color, 5%);

            select {
                width: 100%;
                display: block;
                line-height: 32px;
                height: 32px;
                border: none;
                border-bottom: 1px solid #fff;
                border-color: darken(@split-color, 5%);
            }

            textarea {
                display: block;
                border: none;
                padding: 10px;
                width: 100%;
                min-height: 150px;
                -moz-box-sizing: border-box;
                -webkit-box-sizing: border-box;
                box-sizing: border-box;
                max-width: 100%;
            }

            .form-bottom {
                display: block;
                position: relative;
                height: 50px;
                padding: 0;
                line-height: 50px;
                margin: 0;
                background: lighten(@split-color, 5%);

                button, media-upload {
                    display: inline-block;
                    height: 50px;
                    line-height: 50px;
                    padding: 0 10px;
                    margin: 0;
                    border: 0;
                    text-align: center;
                    font-size: 26px;
                    background: none;
                    color: darken(@split-color, 15%);

                    &[type=submit] {
                        position: absolute;
                        right: 0;
                        font-size: 1em;
                        padding: 0 15px;
                    }
                }
            }
        }

        .image-viewer{
            margin: 10px 0 0 0;
            width: 100%;

            > div{
                width: 30%;
                height: 60px;
                display: inline-block;
                vertical-align: top;
                padding: 0 1.5%;
                overflow: hidden;
                margin: 0 0 5px 0;

                img{
                    height: 60px;
                    width: 100%;
                }
            }
        }
    }
}