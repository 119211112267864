@import "../../Import.less";

.todo-module-highscore{
    .highscore-tabs{
        margin-top: 0;

        .highscore-loader{
            display: block;
            height: 200px;
            .flex-center-center();
            .spinner{
                width: 40px;
                height: 40px;
                border-width: 4px;
            }
        }

        .tab{
            .no-content{
                margin: 50px 0;
            }

            table{
                width: 100%;
                margin: 10px 0;

                tr{
                    position: relative;
                    overflow: hidden;

                    th, td{
                        padding: 4px 6px;
                        text-align: left;
                        position: relative;

                        &.rank{
                            width: 25px;
                            text-align: center;
                        }
                        
                        &.score{
                            width: 50px;
                            text-align: center;
                        }

                        a{
                            display: block;
                            position: relative;
                            width: 100%;
                            overflow: hidden;
                        }

                        .multiline-text{
                            display: block;
                            position: absolute;
                            left: 44px;
                            right: 0;
                            top: 8px;

                            p, span{
                                display: block;
                                position: relative;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                margin: 0;
                            }
                            p{
                                font-size: 14px;
                            }
                            span{
                                font-size: 12px;
                                color: rgba(0,0,0,0.6);
                                margin-top: -2px;
                            }
                        }

                        .single-line-text{
                            display: block;
                            position: relative;
                            width: 100%;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            font-size: 14px;
                        }
                        
                        .profile-image{
                            width: 30px;
                            height: 30px;
                        }
                    }
                }

                &.department-table{
                    tbody{
                        tr{
                            td{
                                padding: 8px 6px;
                            }
                        }
                    }
                }
            }
        }
    }
}