@import "../Import.less";
@carousel-height: 400px;

carousel{
    display: block;
    position: relative;
}
.owl-carousel {
    height: @carousel-height;
    text-align: center;

    .owl-stage-outer {
        z-index: 1;
        position: relative;
        height: 100%;
    }

    .owl-item {
        z-index: 1;

        .media .image {
            height: @carousel-height;
            line-height: @carousel-height;

            > img {
                display: inline-block;
                vertical-align: middle;
            }
        }

        .media {
            .video {
                width: 100%;
                max-width: 100%;
                height: @carousel-height;
                position: relative;
                padding-top: 35%;
                -moz-box-sizing: border-box;
                -webkit-box-sizing: border-box;
                box-sizing: border-box;

                > .video-wrapper {
                    position: relative;
                    overflow: hidden;

                    iframe, object, embed, video {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
    }

    .owl-controls {
        position: relative;
        z-index: 9;
        margin: 0 auto;
        display: inline-block;
        top: -30px;
    }
}

// Media Viewer Changes
.media-viewer{
    carousel{
        position: static;
    }
    .owl-carousel {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        height: 100%;
        .owl-stage{
            height: 100%;
            .owl-item{
                height: 100%;
            }
            media{
                height: 100%;
                .media{
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;

                    .image{
                        height: auto;
                        line-height: normal;
                    }
                }
            }
        }
    }
}