create-update-task-popup {
    .popup-content-inner {
        background: var(--background-highlight-color);
    }
    
    form {
        padding-bottom: 75px;
    }

    .scroll-container {
        flex: 1;
        overflow-y: auto;
        position: relative;
        display: flex;
        flex-direction: column;

        &.disable-overflow {
            overflow-y: visible !important;
        }
    }

    .task-header {
        .language-selector-wrapper {
            label {
                display: flex;
                align-items: center;
                justify-content: space-between;
                min-height: 43px;
                box-sizing: border-box;

                button {
                    display: flex;
                }
            }

            language-selector {
                width: 100%;
            }

            .selected-title {
                display: none;
            }
        }

        .input-title {
            margin: 0;
            padding: 15px;
            height: 57px;
            display: block;

            color: var(--general-text-color);
            border: none;
            font-size: 20px;
            font-weight: 500;
            background: inherit;

            &::-webkit-input-placeholder {
                color: var(--general-text-color);
                opacity: .6;
            }
        }

        textarea {
            position: relative;
            width: 100%;
            height: 120px;

            box-sizing: border-box;
            padding: 15px;

            border: none;
            background-image: linear-gradient(to bottom, var(--general-background-color) 85%, var(--background-highlight-color));

            &::-webkit-input-placeholder {
                color: var(--general-text-color);
            }
        }
    }

    .task-options {
        flex: 1;
    }

    select-category, select-repeat {
        display: flex;
        box-sizing: border-box;

        width: 100%;
        min-height: 50px;
        padding: 10px 30px 10px 40px;

        background: var(--general-background-color);
        color: var(--general-text-color);

        .category-selector {
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    select-repeat {
        padding-left: 10px;
    }

    .assignees {
        display: flex;
        align-items: center;

        width: 100%;
        height: 50px;
        padding-left: 40px;

        background: var(--general-background-color);

        .users {
            display: flex;
            margin-right: 10px;

            span {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 30px;
                height: 30px;
                border-radius: 50%;
                color: var(--text-on-white-color);
                background: var(--white-color);
                box-sizing: border-box;
                box-shadow: 0 6px 7px 0 ~'rgba(var(--general-text-color-rgb), .1)';
                border: 1px solid var(--white-color);
                background-position: center center;
                background-size: cover;
                background-repeat: no-repeat;

                &:not(:last-of-type) {
                    margin-right: -10px;
                }
            }
        }
    }

    .attachments-wrapper {
        padding: 15px 10px;

        .attachments-label {
            padding-bottom: 10px;
        }
    }

    footer {
        .required-message {
            padding: 5px 0 0 0;
            font-size: 12px;
            font-weight: 600;
            color: var(--warning-color);
        }
    }

    .popup-sticky-bottom {
        z-index: 9;
    }
}