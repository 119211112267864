@training-list-item-height: 50px;
@training-list-item-padding: 10px;
@training-list-item-icon-size: 40px;

:root {
    --training-background: var(--general-background-color);
    --training-inner-background: var(--general-background-color);
}

.base-view.base-view-training {
    height: auto;
    background-color: var(--training-background);
}

training-course-page, training-module-page, training-voluntary-category, training-voluntary-page {
    display: block;

    .training-grid {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding: 15px 5px;

        @media @desktop-up {
            padding: 15px 0;
            margin: 0 -5px;
        }
    }

    .training-item {
        position: relative;
        width: 50%;
        cursor: pointer;
        box-sizing: border-box;

        @media @tablet-up {
            width: calc(100% / 3);
        }

        &.locked, &.expired {
            .info h3 {
                opacity: 0.4;
            }

            .image-inner {
                opacity: 0.4;
            }

            rl-progress .bar .inner {
                background: rgba(var(--general-text-color-rgb), 0.4);
            }
        }

        &.deadline {
            .info {
                background-image: linear-gradient(to bottom, rgba(var(--general-text-color-rgb), 0) 39%, rgba(var(--warning-color-rgb), 0.1));
            }

            rl-progress .bar .inner {
                background: var(--warning-color);
            }
        }

        &.new {
            .info {
                background-image: linear-gradient(to bottom, rgba(var(--general-text-color-rgb), 0) 39%, rgba(var(--highlight-color-rgb), 0.1));

                h3 {
                    color: var(--highlight-color);
                }
            }

            .status {
                text-transform: uppercase;
            }
        }

        &.passed {
            .info {
                background-image: linear-gradient(to bottom, rgba(var(--general-text-color-rgb), 0) 39%, rgba(var(--confirmation-color-rgb), 0.1));
            }

            rl-progress .bar .inner {
                background: var(--confirmation-color);
            }
        }

        &.not-passed {
            .info {
                background-image: linear-gradient(to bottom, rgba(var(--general-text-color-rgb), 0) 39%, rgba(var(--alert-color-rgb), 0.1));
            }

            rl-progress .bar .inner {
                background: var(--alert-color);
            }
        }

        .image-inner {
            overflow: hidden;
            width: 100%;
            height: 100%;
            background-size: cover;
            background-position: center center;
            text-align: center;

            rl-icon {
                display: block;
                color: var(--general-background-color);
                opacity: 0.3;
                font-size: 34px;
                line-height: 80px;
            }
        }

        .info {
            background-color: var(--background-highlight-color);

            h3 {
                overflow: hidden;
                width: 100%;
                margin: 0;
                padding: 0 10px;
                font-size: 15px;
                font-weight: 600;
                box-sizing: border-box;
                text-align: center;
                white-space: nowrap;
                text-overflow: ellipsis;
            }

            .duration, .redo {
                padding: 0;
                margin: 5px 0 0 0;
                text-align: center;
                font-size: 13px;

                rl-icon {
                    font-size: 15px;
                }
            }

            .status {
                margin: 5px 0 0 0;
                text-align: center;
                font-size: 13px;
                font-weight: 600;
            }

            .expired, .deadline {
                margin: 5px 0 0 0;
                color: var(--general-text-inactive-color);
                text-align: center;
                font-size: 13px;
            }
        }
    }
}


training {
    display: block;

    rl-translation-information {
        display: block;
        padding: var(--spacing);

        @media @desktop-up {
            padding: var(--spacing) 0;
        }
    }
}

.training-item {
    .image {
        background-color: var(--general-background-color);
        box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.25);

        .image-inner {
            background-color: var(--general-text-color);
            color: rgba(var(--general-background-color-rgb), 0.4);
        }
    }

    .title {
        color: var(--general-text-color);
    }

    .duration, .redo {
        color: rgba(var(--general-text-color-rgb), 0.4);
    }

    .status {
        color: var(--general-text-color);
    }

    rl-progress .bar .inner {
        background-color: var(--general-text-color);
    }
}

training-phase-page {
    display: block;

    @media @desktop-up {
        course-item {
            .course {
                margin: 10px 0;
            }
        }
    }
}

@import "Common/BadgePopup/BadgePopupComponent";
@import "Common/BadgesList/BadgesListComponent";
@import "Common/BadgesList/Badge/BadgeComponent";
@import "Common/Introduction/TrainingIntroduction";
@import "Common/LearnMore/TrainingLearnMoreComponent";
@import "Common/Scores/TrainingScoresComponent";
@import "Common/StateIcon/StateIcon";
@import "Common/BadgeTestimonialsPopup/BadgeTestimonialsPopup";
@import "Common/Timer/TimerComponent";
@import "Common/ValidityBadge/ValidityBadgeComponent";
@import "Common/BadgeValidityPopup/BadgeValidityPopupComponent";

@import "MyProgress/MyProgress";
@import "MyProgress/Badges/TrainingBadgesComponent";

@import "LessonPage/LessonPage";
@import "LessonPage/LessonPageV2";

@import "ModulePage/LessonItem/TrainingLessonItem";

@import "MyTraining/MyTraining";
@import "MyTraining/CourseItem/CourseItem";
@import "MyTrainingV2/MyTrainingV2";
@import "MyTrainingV2/LearningPathItem/LearningPathItem";

@import "CoursePage/ModuleItem/TrainingModuleItem";
@import "Voluntary/VoluntaryCategoryPage/TrainingVoluntaryCategoryPage";
@import "Voluntary/VoluntaryCategoryItem/TrainingVoluntaryCategoryItem";
@import "Voluntary/VoluntaryPage/TrainingVoluntaryPage";

@import "ManagerTab/TrainingManagerTab";

@import "ScormCloudCoursePage/ScormCloudCoursePage";




