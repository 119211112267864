.filter-button-wrapper {
    min-width: 32px;
}

filter-button {
    display: inline-flex;
    height: 100%;

    &.filter-btn-title {
        > button {

            rl-icon {
                font-size: 16px;

                &[icon=info] {
                    font-size: 19px;
                }
            }

            &:before, &:after {
                content: none;
            }
        }

        ul {
            box-shadow: 0 2px 10px 0 rgba(60, 72, 88, 0.05);
            backdrop-filter: blur(11px);
            background-color: rgba(255, 255, 255, 0.6);
            border-radius: 5px;
            padding: 5px;
            position: fixed;
            left: 50%;
            transform: translateX(-50%);
            margin: 0;
            top: 52px;
            right: auto;

            @media @desktop-up {
                position: absolute;
            }

            li {
                color: var(--text-on-white-color);

                &.caption {
                    display: none;
                }

                &.selected {
                    font-weight: 600;

                    &:after {
                        font-size: 16px;
                        padding: 3px;
                        margin-top: -12px;

                        font-weight: 600;
                        color: var(--white-color);
                        background: var(--highlight-color);
                    }
                }

                .item-title {
                    rl-badge {
                        opacity: 1;
                        display: inline-block;
                        right: auto;
                        top: -2px;
                        margin-left: 5px;
                    }
                }
            }
        }
    }

    .overlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 40;
        display: none;
    }

    button {
        &:before, &:after {
            content: "";
            opacity: 0;
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            border-bottom: 6px solid @clr-borders;
            width: 0;
            height: 0;
            position: absolute;
            bottom: -7px;
            z-index: 200;
            left: 50%;
            margin-left: -6px;
            transition: all linear 0.2s;
        }

        &:after {
            border-bottom: 6px solid white;
            bottom: -9px;
        }

        &.hidden {
            display: none !important;
        }

        &.active {
            color: var(--highlight-color) !important;

            &.with-items:before,
            &.with-items:after {
                opacity: 1;
            }

            rl-icon[icon=filters-active] {
                background: var(--highlight-color);
                color: var(--text-on-highlight-color);
            }
        }

        rl-icon[icon=filters],
        rl-icon[icon=filters-active] {
            width: 24px;
            height: 24px;
            border-radius: 5px;
            font-size: 1rem;
        }

        rl-badge {
            position: absolute;
            top: 50%;
            margin-top: -18px;
            right: 0;
            bottom: auto;
            left: auto;
        }
    }

    ul {
        position: absolute;
        z-index: 50;
        right: 10px;
        top: 62px;

        margin: 0;
        padding: 0;
        min-width: 200px;

        opacity: 0;
        background: var(--white-color);
        list-style: none;
        font-size: 14px;
        text-align: left;
        box-shadow: 0 2px 20px 0 rgba(60, 72, 88, 0.1);
        transition: all linear 0.2s;

        &.active {
            color: var(--highlight-color);
            opacity: 1;

            & + .overlay {
                display: block;
            }
        }

        li {
            padding: 10px 40px 10px 15px;
            color: var(--text-on-white-color);
            position: relative;

            &.caption {
                text-transform: uppercase;
                font-weight: 600;
                border-bottom: 1px solid @clr-borders;
            }

            &.selected {
                &:after {
                    position: absolute;
                    width: 18px;
                    height: 18px;
                    right: 10px;
                    background: var(--highlight-color);
                    content: '\f00c'; // fa-check
                    font-weight: 200;
                    font-family: "Font Awesome\ 6 Pro";
                    top: 50%;
                    margin-top: -9px;
                    text-align: center;
                    border-radius: 50%;
                    color: white;
                    font-size: 10px;
                    line-height: 18px;
                }
            }

            .item-title {
                position: relative;

                rl-badge {
                    opacity: 1;
                    position: absolute;
                    right: -14px;
                    top: -4px;
                    text-align: center;
                    bottom: auto;
                    left: auto;
                }
            }

        }
    }
}
