no-data-available {
    display: flex;
    flex-direction: column;
    position: relative;

    gap: var(--m-spacing);
    padding: var(--spacing);

    background: var(--general-background-color);
    border-radius: var(--general-border-radius);
    box-shadow: 0 7px 15px 0 var(--shadow-color);

    .placeholder {
        height: var(--s-spacing);
        border-radius: 100px;
        background: var(--background-highlight-color);
    }

    .icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: calc(var(--m-spacing) / 2);

        rl-icon {
            color: var(--alert-color);
            font-size: 32px;
        }

        > span {
            font-size: 0.86667rem;
            font-weight: 700;
        }
    }
}