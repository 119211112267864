@survey-bottom-height: 40px;
@survey-mark-height: 40px;

.base-view-survey {
    background: var(--general-background-color);

    &.base-view-survey-manager-tab {
        height: auto;
        background: var(--background-highlight-color);
    }
}

.native .survey {
    .next-btn-wrapper,
    .prev-btn-wrapper,
    .counter {
        padding-bottom: calc(0px + env(safe-area-inset-bottom, 0px));
    }
}

.survey {
    display: block;

    .app-link-url {
        position: absolute;
        overflow: hidden;
        clip: rect(0 0 0 0);
        height: 1px;
        width: 1px;
        margin: -1px;
        padding: 0;
        border: 0;
        user-select: none;
    }

    .survey-container {
        &.paged {
            position: absolute;
            z-index: 1;
            opacity: 1;
            left: 0;
            right: 0;
            top: 0;
            bottom: 50px;
            transition: all ease-in-out .3s;

            @media @tablet-max {
                &.with-tabs {
                    top: 40px;
                }
            }

            @media @desktop-up {
                bottom: 0;
            }

            &.ng-enter,
            &.ng-leave.ng-leave-active {
                opacity: 0;
            }

            &.ng-leave,
            &.ng-enter.ng-enter-active {
                opacity: 1;
            }

            .questions {
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: @survey-bottom-height;
                width: 100%;
                overflow: hidden;
            }

            survey-question {
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                transform: translate3d(100%, 0, 0);
                z-index: 1;

                overflow: auto;

                &.current {
                    transform: translate3d(0, 0, 0);
                    transition: transform .5s ease-in-out;
                }

                &.leave {
                    transform: translate(-100%);
                    transition: transform .5s ease-in-out;
                }
            }
        }

        &:not(.paged) {
            survey-question {
                &:not(:last-of-type) {
                    margin-bottom: 15px;
                }
            }
        }

        survey-question {
            @media @tablet-up {
                padding: 15px calc((100% - 400px) / 2);
            }
        }
    }

    .counter {
        display: block;
        height: @survey-bottom-height;
        line-height: @survey-bottom-height;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        text-align: center;
        padding-bottom: calc(0px + env(safe-area-inset-bottom, 0px));
    }

    .questions {
        .breakdown {
            position: absolute;
            left: 0;
            right: 0;
            bottom: -@survey-bottom-height;
            top: 0;
            transform: translate(100%);
            z-index: 2;

            &.show {
                transition: all .5s ease-in-out;
                transform: translate(0);
            }

            button {
                height: 40px;
                line-height: 40px;
                width: 120px;
                color: var(--button-text-color);
                background: none;
                background: var(--button-background-color);
                border: 0;
                display: block;
                margin: 30px auto 30px auto;
                padding: 0;
                text-transform: uppercase;
            }
        }
    }

    .submit {
        z-index: 5;
        position: sticky;
        bottom: 50px;

        padding: 55px 15px calc(20px + env(safe-area-inset-bottom, 0px)) 15px;
        background: linear-gradient(to top, var(--general-background-color) 50%, rgba(255, 255, 255, 0));

        &.submitted {
            background: ~'var(--general-background-color)';
            padding: 20px 15px;

            &:after {
                content: '';
                position: absolute;
                z-index: -1;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                background: rgba(var(--confirmation-color-rgb), 0.2);
            }

            button {
                background: var(--confirmation-color);
            }
        }

        @media @tablet-up {
            padding: 55px calc((100% - 400px) / 2) 20px;
        }

        @media @desktop-up {
            position: static;
        }

        > span {
            display: block;
            padding-bottom: 12px;
            font-size: 16px;
            font-weight: 600;
            color: var(--confirmation-color);
        }
    }

    .next-btn-wrapper,
    .prev-btn-wrapper {
        z-index: 2;
        display: block;
        text-align: right;
        position: absolute;
        right: 10px;
        bottom: 5px;
        padding-bottom: calc(0px + env(safe-area-inset-bottom, 0px));

        .nav-btn {
            display: inline-block;
            padding: 0 10px;
            height: 30px;
            line-height: 30px;
            font-size: 1.1em;
            background: transparent;
            border: none;
        }
    }

    .prev-btn-wrapper {
        text-align: left;
        left: 10px;
        right: auto;
    }
}


@import "Question/SurveyQuestion";
@import "Breakdown/SurveyBreakdown";
@import "Poll/SurveyPoll";

@import "SurveyPage/FinishPopup/SurveyFinishPopup";

@import "SurveyPage/ManagerTab/QuestionStatisticPopup/QuestionStatisticPopup";
@import "SurveyPage/ManagerTab/SurveyManagerTab";
@import "SurveyPage/ManagerTab/StoreManagerVew/StoreManagerVew";
@import "SurveyPage/ManagerTab/MultipleDepartmentView/MultipleDepartmentView";
@import "SurveyPage/ManagerTab/FiltersPopup/SurveyFiltersPopup";

@import "SurveyPage/ManagerTab/Common/ParticipationRate/ParticipationRate";
@import "SurveyPage/ManagerTab/Common/OverallSatisfaction/OverallSatisfaction";
@import "SurveyPage/ManagerTab/Common/NoDataAvailable/NoDataAvailable";
@import "SurveyPage/ManagerTab/Common/SurveyDataCollectionStatus/SurveyDataCollectionStatus";

@import "SurveyPage/CommentsTab/SurveyCommentsTab";
@import "SurveyPage/CommentsTab/QuestionCommentsPage/QuestionCommentsComponent";
@import "SurveyPage/CommentsTab/QuestionCommentsPage/QuestionCommentCardItem/CommentCardComponent";
