pin-code-hint {
    position: absolute;
    top: 0;
    right: 0;

    rl-icon {
        font-size: 25px;
        padding: 0;
    }

    button {
        cursor: pointer;
        padding: 0;
    }

    .tip {    
        top: 45px;
        right: 250px;
        position: relative;

        .tip-content {
            background-color: var(--general-text-color);
            color: var(--white-color);
            width: 235px;
            padding: var(--m-spacing);

            &::after {
                border-color:  transparent transparent var(--general-text-color) transparent;
                border-width: 0 7.5px 7px 7.5px;
                left: unset;
                right: 1px;
            }
        }

        .pin-tip-content {
            text-align: left;

            h2 {
                font-size: 1rem;
                margin: 0;
                font-weight: 600;
                line-height: 150%;
            }

            p {
                font-size: 0.875rem;
                line-height: 130%;
            }
        }
    }
}