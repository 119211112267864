number-field {
    position: relative;
    display: flex;
    justify-content: left;
    align-items: center;


    input.ng-valid.ng-dirty + .controls {
        border-color: var(--confirmation-color);
    }

    input.ng-invalid.ng-dirty + .controls {
        border-color: var(--warning-color);
    }

    input:hover + .controls {
        border-color: var(--general-border-color);
    }

    input:focus + .controls {
        border-color: var(--general-text-inactive-color);
    }

    input[type=number] {
        text-align: right;
        appearance: none;

        &.simple {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border-right-width: 0 !important;
        }
    }

    .controls {
        display: flex;
        border: 1px solid transparent;
        border-left-width: 0;
        border-top-right-radius: var(--general-border-radius);
        border-bottom-right-radius: var(--general-border-radius);
        overflow: hidden;

        .suffix {
            height: 43px;
            box-sizing: border-box;
            padding: 10px;

            font-size: 15px;
            line-height: 23px;
            font-weight: 500;

            color: rgba(var(--general-text-color-rgb), 0.6);
            background: var(--background-highlight-color);
        }

        button {
            position: relative;
            display: inline-block;
            box-sizing: border-box;
            height: 43px;
            padding: 0 5px;

            cursor: pointer;

            color: var(--text-color);
            background: var(--background-highlight-color);

            rl-icon {
                color: rgba(var(--general-text-color-rgb), 0.6);
                font-size: 15px;
            }
        }
    }
}