department-popup {
    .actionSheet-Click {
        display: flex;
    }

    .popup-content {
        transform: translate(0, 0);
    }

    .loading {
        position: absolute;
        z-index: 100;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background: white;

        .spinner {
            width: 50px;
            height: 50px;
        }
    }

    header-popup {
        z-index: 3;
        position: absolute;
        background: transparent;
        left: 0;
        top: 0;
        box-sizing: border-box;
        height: auto;
        width: 100%;
        padding: 15px 15px 0 15px;

        .buttons {
            min-width: auto;

            button {
                width: auto;
                height: auto;
                background: transparent;
                min-width: auto;

                rl-icon {
                    height: 30px;
                    width: 30px;
                    border-radius: 50%;
                    color: var(--general-text-color);
                    background: rgba(255, 255, 255, 0.6);
                    backdrop-filter: blur(24px);
                    filter: drop-shadow(0px 6px 15px rgba(0, 0, 0, 0.15));
                }
            }
        }
    }

    .department-popup-container {
        overflow-y: scroll;
        overflow-x: hidden;
        max-height: calc(100% - 50px);
    }

    .department-container {
        margin-top: 240px;
    }

    .popup-wrapper .popup-content.clean.flex .popup-content-inner:not(.scroll-disabled), .popup-wrapper .popup-content-inner:not(.scroll-disabled) {
        overflow-y: hidden;
    }

    .department-image {
        background-position: center;
        border-radius: 100%;
        width: 120px;
        height: 120px;
        display: block;
        margin: -100px auto 0 auto;
        transition-duration: 0.2s;
        -webkit-transform-style: preserve-3d;
        -webkit-backface-visibility: hidden;

        &.unset {
            background: var(--general-background-color);
            margin: 0px auto 0 auto;
        }

        &.scrolled {
            margin: -100px auto 0 auto;
        }
    }

    .department-header-background {
        width: 100%;
        height: 156px;
    }

    .header {
        position: fixed;
        text-align: center;
        top: 0;
        z-index: 2;
        width: 100%;
        padding-bottom: 10px;
        background-color: var(--general-background-color);

        .rl-headline {
            margin-bottom: 0;
        }

        .unset {
            text-align: center;

            rl-icon {
                font-size: 40px;
                margin-top: 40px;
                color: rgba(var(--general-text-color-rgb), 0.5);
                transition-duration: 0.2s;
            }
        }

        &.unset {
            background: var(--background-highlight-color);
            border-radius: 0 0 30px 30px;
            padding: 40px 0 10px 0;
        }

        &.shrink {
            .department-header-background {
                background: none;
            }

            .department-image {
                width: 45px;
                height: 45px;
                -webkit-transform-style: preserve-3d;
                -webkit-backface-visibility: hidden;
            }

            .rl-headline {
                margin-top: 10px;
            }

            &.unset {
                box-shadow: rgb(17 12 46 / 10%) 0 10px 50px 0;

                rl-icon {
                    font-size: 20px;
                    margin-top: 15px;
                }
            }
        }

        .rl-subheadline {
            opacity: 0.8;
        }
    }

    .content-wrapper {
        padding: 15px 15px 0px 15px;
    }

    .content-wrapper:last-child {
        padding: 15px 15px 15px 15px;
    }

    .content {
        background: var(--background-highlight-color);
        border-radius: 7px;
        padding: 10px;

        &.information {
            padding: 10px;
        }
    }

    .department-information-item {
        display: flex;
        margin: 8px 0;

        .rl-small-text {
            margin: 10px 0 0 10px;
        }

        .address {
            display: grid;

            .rl-small-text {
                margin: 9px 0 0 10px;

                &.double {
                    margin: 2px 0 0 10px;
                }

                &.city {
                    margin: 0 0 0 10px;
                }
            }
        }

        .manager {
            display: grid;

            .rl-small-text {
                margin: 9px 0 0 10px;

                &.double {
                    margin: 2px 0 0 10px;
                }

                &.name {
                    margin: 0 0 0 10px;
                }
            }
        }
    }

    .department-information-button {
        height: 35px;
        width: 35px;
        border-radius: 100%;

        rl-icon {
            font-size: 16px;
            margin-top: 9px;
            margin-left: 7px;

            span {
                font-weight: 600;

                &.fa-phone {
                    color: var(--confirmation-color);
                }

                &.fa-envelope {
                    color: var(--highlight-color);
                }

                &.fa-map-marker-alt {
                    color: var(--general-text-color);
                }

                &.fa-building {
                    color: var(--general-text-color);
                }
            }
        }

        &.phone {
            background: rgba(var(--confirmation-color-rgb), 0.2);
        }

        &.mail {
            background: rgba(var(--highlight-color-rgb), 0.2);
        }

        &.location {
            background: rgba(var(--general-text-color-rgb), 0.2);
        }

        &.building {
            background: rgba(var(--general-text-color-rgb), 0.15);
        }
    }

    contact-list .contact-list-component {
        overflow-y: hidden;
        max-height: unset;
    }

    .department-contacts-wrapper {
        margin-top: 15px;
    }

    .department-contacts {
        margin-top: 4px;
    }

    .rl-label-inactive {
        font-size: 11px;
        font-weight: 700;
    }

    .department-no-content {
        text-align: center;
    }

    .no-content-container {
        width: 70%;
        margin: 0 auto;
    }
}
