training-filters-popup {
    .sorting {
        display: flex;
        flex: 1;
        flex-direction: column;
        background: var(--background-highlight-color);

        .input-wrapper {
            position: relative;
            display: flex;
            flex-direction: column;

            &:after {
                content: '';
                display: block;
                position: absolute;
                right: 15px;
                bottom: 20px;
                width: 8px;
                height: 8px;
                border-right: 1px solid ~'rgba(var(--general-text-color-rgb), 0.35)';
                border-bottom: 1px solid ~'rgba(var(--general-text-color-rgb), 0.35)';
                transform: rotate(-45deg);
            }

            label {
                padding: 15px 10px 10px 10px;
                font-size: 15px;
                font-weight: 600;
                color: var(--general-text-color);
            }

            rl-icon {
                position: absolute;
                font-size: 17px;
                bottom: 17px;
                left: 10px;
            }


            select {
                padding: 0 30px 0 40px;
                height: 50px;
                color: ~'rgba(var(--general-text-color-rgb), 0.5)';
                border: 1px solid ~'rgba(var(--general-text-color-rgb), 0.1)';

                font-size: 15px;
                background: var(--general-background-color);
                -webkit-appearance: none !important;
            }
        }

        rl-button {
            margin: auto 15px 15px 15px;

            button {
                text-transform: uppercase;
            }
        }
    }
}