tasks {
    flex: 1;
    
    .no-tasks {
        display: block;
        width: 100%;
        padding: 10px 15px;

        background: var(--background-highlight-color);
        border-radius: var(--general-border-radius);
        box-sizing: border-box;
        cursor: pointer;

        p {
            font-size: 15px;
            margin: 0;
            font-weight: 700;
            word-break: break-word;
            color: var(--general-text-color);
        }
    }
    
    .no-tasks-desktop {
        position: absolute;
        top: 40%;
        transform: translateX(-50%);
        left: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        
        rl-icon {
            font-size: 5rem;
        }

        h2 {
            line-height: 1rem;
            font-weight: 700;
        }
        
        .info-message {
            text-align: center;
            font-size: 0.86667rem;
        }
    }
}