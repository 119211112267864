filter-fillings {
    customer-field {
        display: block;
    }

    form {
        display: flex;
        flex-direction: column;
        padding: 0;
        height: 100%;

        .service-form-field {
            margin: 0;
            border: none;

            > div {
                width: 100%;
            }

            .service-form-slider {
                padding: 10px 0;
                text-align: center;

                label {
                    font-weight: 500;
                    color: var(--general-text-color);
                    font-size: 14px;
                    display: block;
                    text-align: left;
                    padding: 0 8px;
                }
            }

            &:not(:last-of-type) {
                margin: 0;
            }

            &:not(:first-of-type) {
                margin-top: -1px;
            }

            .service-form-field-selector {
                display: block;

                &:not(:first-of-type) {
                    margin-top: -1px;
                }

                > label {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    overflow: hidden;
                    position: relative;

                    > span:first-child {
                        width: 200px;
                        max-width: 45%;
                        font-weight: 600;
                        color: var(--general-text-color);
                        flex: 1 0 auto;
                        display: block;
                        padding: 0 8px;
                        word-wrap: break-word;
                        position: relative;
                        min-width: 0;
                        white-space: normal;
                        font-size: 14px;
                    }

                    > span:last-child:not(.chkSwitch) {
                        padding: 0 8px;
                        flex: 1 1 auto;
                    }

                    input[type=date] {
                        padding: 0;
                        height: 30px;
                        line-height: 30px;
                        background: none;
                        border: 0;
                    }

                    .chkSwitch {
                        text-align: right;
                        width: 100%;
                        margin-right: 8px;
                    }

                    .ripple-effect {
                        position: absolute;
                    }
                }
            }
        }
    }

    .save {
        line-height: normal;
    }

    .popup-wrapper {
        .popup-content-inner {
            padding: 0 0 10px 0;
        }

        .popup-footer {
            margin: 0;

            button {
                width: 50%;

                &.submit {
                }

                &.reset {
                    background: #b7b7b7;
                    color: #000;

                    &:hover {
                        background: #a5a2a2
                    }
                }
            }
        }

        .popup-content {
            .modal-loader {
                align-items: flex-start;
    
                rl-loading-placeholder {
                    padding: 0;
                
                    .placeholder-shimmer {
                        height: 45px;
                        background-color: var(--background-highlight-color);
                    }
                }
            }
        }
    }

    .rzslider {
        width: calc(~'100% - 20px');
        margin: 20px 0 30px 0;

        .rz-bar {
            background: ~"rgba(var(--highlight-color-rgb), .15)";
        }

        .rz-pointer {
            background-color: var(--highlight-color);
            width: 24px;
            height: 24px;
            top: -10px;

            &:after {
                content: none;
            }
        }

        .rz-bubble {
            font-size: 14px;
            font-weight: 500;
            bottom: auto;
            top: 16px;
        }
    }
}
