user-management-filters {
    flex: 1;
    display: flex;
    flex-direction: column;

    .content {
        flex: 1;
        display: flex;
        flex-direction: column;

        overflow-y: auto;

        padding: 15px;
        
        rl-select.activity {
            .options {
                max-height: 80vh;
                .option.selected > span {
                    color: var(--general-text-color);
                }
            }
        }

        .rl-label {
            text-transform: lowercase;
            font-size: 0.75rem;

            &::first-letter {
                text-transform: capitalize;
            }
        }

        usergroup-selector, department-selector {
            min-height: 45px;
            display: block;
            background: var(--background-highlight-color);
            border-radius: var(--general-border-radius);

            autocomplete {
                display: flex;
                flex-direction: column-reverse;
            }

            .selected-items {
                display: flex;
                flex-wrap: wrap;
                gap: 10px;
                padding: 10px;

                li {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    padding: 7px 10px;
                    font-size: 13px;

                    backdrop-filter: blur(24px);
                    background: rgba(var(--white-color-rgb), 0.7);
                    border-radius: 100px;

                    button {
                        padding: 0;
                        margin-left: 10px;

                        opacity: 0.7;

                        rl-icon {
                            font-size: 15px;
                            color: var(--warning-color);
                        }
                    }

                    p {
                        margin: 0;
                    }
                }
            }

            input {
                font-weight: normal;
                height: 45px;
                border: none;
                background: transparent;
            }

            [icon="search"] {
                height: 100%;
                box-sizing: border-box;
            }
        }

        .rl-field-wrapper.recent {
            box-sizing: border-box;
            min-height: 46px;
            margin-top: 15px;
            padding: 10px;
            border-radius: var(--general-border-radius);
            background: var(--background-highlight-color);
        }

        .divider {
            width: 100%;
            height: 1px;
            background-color: var(--general-border-color);
            margin: var(--m-spacing) 0;
        }
    }

    footer {
        margin: auto 0 0 auto;
        padding: var(--spacing);
        display: flex;

        rl-button {

            button.small {
                font-size: 0.75rem;
                font-weight: 700;
                height: 37px;
                min-width: 91px;
                line-height: 1rem;
            }

            &[icon=ban ] {
                button {
                    display: flex;
                    flex-direction: row-reverse;
                    align-items: center;
                    gap: 5px;
                    color: var(--warning-color);
                    padding: 0 var(--spacing);
                }

                rl-icon {
                    font-size: 0.9rem;
                }
            }
        }
    }
}