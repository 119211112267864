lesson-question {
    .title {
        h2 {
            margin: 0 0 var(--spacing) 0;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 130%;
            color: var(--general-text-color);
        }

        p {
            margin: 0;
            font-size: 13px;
            color: var(--general-text-color);
        }

        media {
            overflow: hidden;
            margin-bottom: var(--spacing);
            border-radius: var(--general-border-radius);
        }
    }

    .question-feedback {
        margin-top: var(--spacing);

        media {
            overflow: hidden;
            margin-top: var(--spacing);
            border-radius: var(--general-border-radius);
        }

        p {
            margin: 0;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 130%;
            color: var(--general-text-color);
        }
    }

    .answers {
        margin-top: 20px;

        > span {
            display: block;
            font-size: 13px;
            font-weight: 500;
            padding-bottom: 5px;
        }
    }

    .answer {
        padding: var(--m-spacing);
        border-radius: var(--general-border-radius);
        background-color: var(--general-background-color);
        border: 1px solid rgba(var(--general-text-color-rgb), .1);

        .answer-wrapper {
            display: flex;
            align-items: flex-start;
            gap: var(--s-spacing);
        }

        rl-icon {
            color: var(--general-text-inactive-color);

            &[icon=circle], &[icon=dot-circle] {
                font-size: 17px;
            }
        }

        h3 {
            margin: 0;

            font-size: 15px;
            font-weight: 400;
            line-height: 17px;
            word-break: break-word;
            color: var(--general-text-color);
        }

        &.answered {
            cursor: default;
        }

        &.selected {
            border-color: var(--highlight-color);

            rl-icon {
                color: var(--highlight-color);
            }

            &.wrong {
                border-color: var(--warning-color);

                rl-icon {
                    color: var(--warning-color);
                }
            }

            &.correct {
                border-color: var(--confirmation-color);

                rl-icon {
                    color: var(--confirmation-color);
                }
            }
        }

        &.disabled {
            background-color: var(--background-highlight-color);
        }

        &:not(:last-of-type) {
            margin-bottom: 15px;
        }

        .answer-feedback {
            padding-top: var(--s-spacing);

            p {
                margin: 0;
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: 130%;
                color: var(--general-text-color);
            }

            media {
                overflow: hidden;
                margin-top: var(--s-spacing);
                border-radius: var(--general-border-radius);
            }
        }
    }
}
