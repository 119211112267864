@import "../Import.less";
.media-upload-progress {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    background: rgba(0,0,0,0.5);
    color: #fff;
    display: none;
    z-index: -1;

    &.show {
        display: block;
        z-index: @level-upload-progress;
    }

    ul {
        display: block;
        padding: 10px 0;
        margin: 0;

        > li {
            display: block;
            position: relative;
            padding: 0 10px;
            margin-bottom: 4px;

            .filename {
                display: block;
                font-size: 12px;
                width: 100%;
                -moz-box-sizing: border-box;
                -webkit-box-sizing: border-box;
                box-sizing: border-box;
                padding-right: 50px;
                overflow: hidden;
                -ms-text-overflow: clip;
                -o-text-overflow: clip;
                text-overflow: clip;
                text-align: left;
            }

            .progress {
                position: absolute;
                display: block;
                font-size: 12px;
                right: 10px;
                top: 0;
                text-align: right;
            }

            .progress-bar {
                display: block;
                background: #fff;
                height: 2px;
                width: 0;
                margin-top: 2px;
            }
        }
    }
}