@import "../../../Content/Styles/Import.less";

.base-view-taskmanagement {
    background: var(--general-background-color);
}

task-management {
    height: 100%;
    display: flex;
    flex-direction: column;

    rl-loading-placeholder {
        margin-right: var(--m-spacing);

        .placeholder-shimmer {
            height: 90px;
            @media @desktop-up {
                margin: var(--spacing) 0 0;
            }
        }
    }
    
    .calendar-shimmer {
        margin: var(--spacing) 0;

        .placeholder-shimmer {
            height: 250px;
        }
    }

    @media @desktop-up {
        max-width: unset !important;
        padding-bottom: 0;

        rl-loading-placeholder {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            .placeholder-shimmer {
                height: 90px;
            }
        }

        .tasks-filters-desktop {
            padding: 0 !important;
            border-left: 1px solid var(--general-border-color);
            width: 350px;
            height: 100%;
            overflow: hidden;
            flex-shrink: 0;
            position: relative;
        }

        .tabs-date-search-header {
            margin: var(--m-spacing);
            display: flex;
            align-items: baseline;
            justify-content: space-between;
            gap: var(--m-spacing);

            .selected-date {
                font-size: 1.4rem;
                text-transform: capitalize;
                font-weight: 700;
            }

            rl-button button {
                background: var(--button-background-color);
                color: var(--button-text-color);
                min-width: 130px;
                text-transform: uppercase;
            }
        }

        .no-tasks-found-container {
            position: absolute;
            top: 40%;
            left: calc(50% - var(--sidemenu-width));
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }

    .no-tasks-found-container {
        margin: auto;
        display: flex;
        flex-direction: column;
        align-items: center;

        rl-icon {
            font-size: 5rem;
        }

        h2 {
            line-height: 1rem;
            font-weight: 700;
        }

        .text {
            font-size: 0.86667rem;
            line-height: 135%;
            max-width: 300px;
            text-align: center;
        }
    }


    .status-icon {
        display: inline-flex;
        min-width: 26px;
        min-height: 26px;
        line-height: 26px;
        border-radius: 50%;
        border: 1px solid rgba(var(--general-text-color-rgb), .1);
        margin-right: var(--m-spacing);
        justify-content: center;
        align-items: center;
        color: white;
        box-sizing: border-box;

        rl-icon {
            font-size: 16px;
        }
    }

    .tasks-view-button {
        font-size: 1.5rem;
        gap: var(--spacing);
        padding: var(--m-spacing) 0 0 var(--m-spacing);

        &.active {
            color: var(--highlight-color);
        }
    }

    .processing-spinner-overlay {
        position: absolute;
        left: 50%;
        top: 50%;
        z-index: 99;

        .spinner {
            width: 50px;
            height: 50px;
        }
    }

    .date-label {
        margin: 0;
        padding: 20px 15px 10px 15px;
        font-size: 20px;
        font-weight: 600;
    }

    .rl-tabs {
        justify-content: space-around;

        .rl-tab {
            cursor: pointer;
            flex: 1;

            &:hover {
                background: rgba(var(--general-background-color-rgb), 0.3);
            }
        }

        @media @desktop-up {
            justify-content: flex-start;
            gap: var(--spacing);
            padding-left: var(--spacing);

            .rl-tab {
                flex: unset;
            }
        }

    }

    .task-management-content {
        display: flex;
        flex: 1;
        overflow: hidden;
        flex-direction: column;

        .tab-content-desktop {
            display: flex;
            flex-direction: column;
            overflow: hidden;
            flex: 1;
        }

        @media @desktop-up {
            flex-direction: row;

            .tab-content-desktop {
                width: 100%;
                margin-left: var(--m-spacing);
            }
        }
    }

    .task-management-wrapper {
        flex: 1;
        overflow: hidden;
        display: flex;
        flex-direction: column;

        .tabs-subtitle {
            display: flex;
            gap: var(--m-spacing);
            padding-top: var(--m-spacing);
        }
    }

    .task-management-view-type {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        flex: 1;
        padding: var(--s-spacing);

        @media @desktop-up {
            padding: 0 var(--s-spacing) 0 0;
            
            &.view-type-manager {
                padding: 0;
            }
        }

        .filtered-tasks {
            display: flex;
            flex-direction: column;
            overflow: hidden;
            flex: 1;
        }
    }

    .task-management-view-type,
    .tasks-filters-desktop {
        months-view {
            height: 300px;

            .scrolled-months {
                border-radius: 0 0 var(--general-border-radius) var(--general-border-radius);
                scrollbar-width: thin;
                scrollbar-color: rgba(0, 0, 0, 0.15) transparent;
                margin-top: 0;
                border-bottom: 0;
                padding: 0 !important;
            }

            tasks {
                margin-bottom: var(--m-spacing);
            }
        }

        .spinner-container {
            .spinner-wrapper {
                text-align: center;
                width: 100%;
                margin-top: 50px;
            }
        }

        .more-tasks-spinner {
            @media @desktop-up {
                padding-bottom: 55px;
            }

            .spinner-wrapper {
                text-align: center;
                width: 100%;
                margin-top: var(--spacing);
            }
        }
    }

    .filter-tasks-header {
        display: flex;
        justify-content: flex-end;

        rl-button button {
            font-size: 14px;
            font-weight: 400;
            margin: 17px 10px 0 0;
            color: grey;
        }

        rl-icon {
            font-size: 14px;
            color: grey;
        }
    }

    .view-type-overdue {
        margin-top: var(--s-spacing);

        .no-content {
            text-align: center;

            i {
                display: inline-block;
            }
        }
    }

    .no-results-wrapper {
        height: 100%;
        width: 100%;
        text-align: center;

        .no-results-container {
            position: relative;
            text-align: center;
            top: calc(50% - 80px);
            padding: 0 80px 0 80px;

            rl-icon {
                font-size: 36px;
            }

            button {
                text-transform: uppercase;
                font-weight: 400;
            }
        }

        .no-results-item {
            display: block;
            margin: 0 0 var(--m-spacing) 0;
        }
    }

    .my-tasks-item {
        width: 50%;
        height: 100%;
        overflow-y: auto;
    }

    .overlooking {
        margin: var(--m-spacing);

        @media @desktop-up {
            margin-top: 0;
        }
    }

    .mobile-calendar-container {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        flex: 1;
    }
}

task-management months-view {
    border-bottom: 0;
    border-radius: var(--general-border-radius) var(--general-border-radius) 0 0;
    margin-bottom: var(--m-spacing);
    background: transparent;

    @media @desktop-up {
        margin-bottom: 0;
    }

    .calendar-header {
        .month-title:nth-last-of-type(-n+2) {
            color: rgba(var(--general-text-color-rgb), .5);
        }

        .titles {
            background: var(--background-highlight-color);
            color: var(--general-text-color);
        }
    }

    .day {
        &.current-day.selected .day-number {
            background: var(--highlight-color);
            color: var(--text-on-highlight-color);
            box-shadow: rgb(150 150 150 / 80%) 0 0 14px;
        }

        .day-dot {
            opacity: .3;
        }
    }

    .day.current-day .day-number {
        color: var(--black-color);
        background: var(--background-highlight-color);
        border-radius: 100%;
    }
}

tasks-by-day {
    padding-bottom: 50px;
}

tasks {
    container-type: size;

    .tasks-scrolled-wrapper {
        width: 100%;
        height: 100cqh;
        overflow-y: auto;
        scrollbar-width: thin;
        scrollbar-color: rgba(0, 0, 0, 0.15) transparent;
        -webkit-overflow-scrolling: touch;
        box-sizing: border-box;

        @media @desktop-up {
            align-content: flex-start;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }
    }
}

@import "Task/Task";
@import "Tasks/Tasks";
@import "MyTasks/MyTasks";
@import "SearchTasks/SearchTasks";
@import "TaskDetails/TaskDetails";
@import "CreateUpdateTask/CreateUpdateTaskComponent";
@import "TaskAssignees/TaskAssigneesComponent";
@import "TaskDetails/StatisticsPopup/StatisticsPopup";
@import "TaskDetails/AssigneesPopup/AssigneesPopup";
@import "TaskDetails/CommentsPopup/TaskManagementCommentsPopup";
@import "ManagerView/ManagerViewComponent";
@import "Subtasks/CreateSubtaskOnlyTitle/CreateSubtaskOnlyTitleComponent";
@import "Subtasks/SubtasksList/SubtasksListComponent";
@import "Subtasks/SubtasksCounter/SubtasksCounterComponent";
@import "Subtasks/SubtaskDetails/SubtaskDetails";
@import "Subtasks/UpdateSubtaskPopup/UpdateSubtaskPopup";