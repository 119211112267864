@import "../Import.less";

.error-page {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.25);

    h1 {
        display: block;
        margin: 0;
        padding: 0;
        font-size: 22px;
        font-weight: 600;
        text-align: center;
        position: absolute;
        height: 55px;
        line-height: 55px;
        left: 0;
        right: 0;
        top: 0;
        background: rgba(0, 0, 0, 0.75);
        color: #fff;
        .box-shadow-1();
        transform: translate3d(0, 0, 0);
    }

    .button-container {
        display: block;
        position: absolute;
        bottom: 30px;
        left: 0;
        right: 0;
        text-align: center;

        .btn {
            width: 100%;
            max-width: 260px;
        }
    }
}

.tab .no-content {
    position: relative;
    top: 0;
    margin: 100px 0;
}