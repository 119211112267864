@import "../Import.less";

.add-to-home {
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-position: center center;
    background-size: cover;
    z-index: @level-add-to-home;

    .add-to-home-background {
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-position: center center;
        background-size: cover;
        z-index: 1;
    }

    .add-to-home-overlay {
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 3;
        background: rgba(0,0,0,0.85);

        button {
            color: #fff;
            position: absolute;
            display: block;
            right: 15px;
            top: 10px;
            text-decoration: underline;
            opacity: .6;
            border: none;
            background: none;
        }

        .add-to-home-arrow {
            display: block;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 20px;
            text-align: center;

            img {
                height: 40px;
            }
        }

        .add-to-home-content {
            display: block;
            position: absolute;
            top: 50%;
            left: 0;
            right: 0;
            margin-top: -150px;
            height: 300px;
            color: #fff;
            text-align: center;

            .favicon {
                display: block;
                margin: 0 auto;
                width: 80px;
                height: 80px;
                background-position: center center;
                background-size: contain;
                margin-bottom: 15px;
            }

            .add-to-home-split {
                display: block;
                width: 140px;
                height: 1px;
                background: #fff;
                margin: 25px auto;
                opacity: .5;
            }

            h1 {
                font-size: @font-size + 4;
                margin: 0;
            }

            h2 {
                font-size: @font-size;
                margin: 0 auto;
                max-width: 250px;
            }

            img {
                height: 18px;
                display: inline-block;
                margin: 0 5px;
            }
        }
    }
}
