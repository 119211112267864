reference-field {
    display: flex;
    align-items: flex-start;
    margin-bottom: 5px;

    .reference {
        margin: 0 0 0 10px;
        font-weight: bold;
    }

    label {
        display: block;

        margin: 0;
        padding: 0;

        line-height: 24px;
        font-size: 13px;
        font-weight: 600;
    }

    p {
        padding: 0;
        margin: 0;
        height: 24px;
        line-height: 24px;
    }

    .field-tags div {
        display: flex;
        flex-direction: column;
        background: #fff;
        vertical-align: top;
        margin: 0;
        padding: 2px 0 0 0;
        font-size: 14px;
        box-sizing: border-box;
        
        .subtitles {
            font-weight: normal;
            font-size: 12px;
        }

        span {
            display: block;
        }
    }
}