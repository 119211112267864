.rl-header {
    h1 {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 17px;
        text-transform: uppercase;
        margin: 0;
    }

    h2 {
        font-size: 12px;
        margin: 0;
    }

    > a {
        line-height: 55px;
        .button;
    }

    > button {
        .button;
    }

    .base-top-right-buttons, .base-top-left-buttons{
        > button {
            flex: 0 0 auto;
        }
    }

    .base-top-right-buttons, .base-top-left-buttons,
    .header-popup-wrapper,
    .app-header-dropdown-wrapper,
    filter-button,
    .left-buttons, .right-buttons {
        > button {
            .button;
        }
    }

    .button() {
        position: relative;

        height: 100%;
        width: 45px;
        padding: 0;

        color: var(--header-text-color);
        text-align: center;

        rl-icon {
            font-size: 20px;
            color: var(--header-text-color);
        }

        &[disabled] {
            rl-icon {
                opacity: 0.4;
            }
        }

        .fa-ellipsis-v {
            font-size: 28px;
        }
    }

}