social-live-tile {
    display: block;
    background: var(--background-highlight-color);
    height: 100%;

    > header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        padding: 15px;

        h2 {
            display: flex;
            flex-direction: row;
            text-transform: uppercase;
            font-size: 15px;

            margin: 0;


            rl-badge {
                margin: 0 0 0 5px;
            }
        }

        button {
            padding: 0;
        }
    }

    social-wall {
        display: block;
        height: ~'calc(100% - 50px)';

        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;

        padding: 0 15px;

    }

    .social-page {
        height: 100%;
        padding: 0;

        sorting {
            @media @desktop-up {
                margin: 0 auto;
                max-width: 60vw;
            }
        }
    }

    .social-post-item:first-of-type {
        margin-top: 0;
    }
}