invite-individuals {
    display: block;

    .open-btn {
        display: flex;
        align-items: center;

        width: 100%;
        text-align: left;
        padding: 10px 0;

        margin: 0;
        border: none;
        -webkit-appearance: none;

        position: relative;
        overflow: hidden;

        &:active {
            background: ~'rgba(var(--background-highlight-color-rgb), 0.1)';
        }

        .no-invites {
            font-size: 15px;
            line-height: 20px;
        }

        .invites {
            display: flex;
            margin-right: 10px;

            .user-img {
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;

                width: 30px;
                height: 30px;

                border-radius: 50%;
                color: var(--text-on-white-color);
                background: var(--white-color);
                box-sizing: border-box;
                border: 1px solid var(--general-background-color);

                background-position: center center;
                background-size: cover;
                background-repeat: no-repeat;

                &:not(:last-of-type) {
                    margin-right: -10px;
                }
            }
        }

        .length {
            font-size: 13px;
            line-height: 16px;
            color: ~'rgba(var(--general-text-color-rgb), 0.4)';
        }
    }
}

.invite-individuals-popup {
    .main {
        display: flex;
        flex: 1;
        flex-direction: column;
        overflow: hidden;
    }
}

