training-voluntary-category-item {
    position: relative;
    overflow: hidden;

    box-sizing: border-box;
    height: 100px;
    padding: 10px;

    cursor: pointer;

    border-radius: var(--general-border-radius);

    .left {
        width: 100%;
    }

    h3 {
        overflow: hidden;
        width: 100%;
        margin: 0 0 5px 0;
        line-height: 20px;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    p {
        margin: 0;
    }

    rl-icon {
        font-size: 15px;
    }

    .image {
        position: absolute;
        margin-top: auto;

        right: -15px;
        bottom: -15px;

        width: 80px;
        height: 80px;

        border-radius: 50%;
        box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.25);
    }
}