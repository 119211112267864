training-module-item {
    padding: 40px 5px 10px;

    .image {
        position: absolute;
        z-index: 2;
        top: 0;
        left: 50%;
        transform: translateX(-50%);

        width: 80px;
        height: 80px;

        border-radius: 100%;

        state-icon {
            position: absolute;
            bottom: 0;
            right: 0;
        }

        .image-inner {
            border-radius: 100%;
        }
    }

    .info {
        height: calc(100% - 60px);
        padding: 50px 0 10px 0;
        border-radius: var(--training-border-radius);

        rl-progress {
            padding: 0 10px;
            margin-top: 12px;
        }
    }
}