filter-tasks-popup, filter-tasks {
    .popup-wrapper .popup-content-inner {
        background: var(--background-highlight-color);
        padding: 0;
    }

    rl-loading-placeholder {
        padding: var(--m-spacing);

        .placeholder-shimmer {
            height: 40px;
        }
    }

    header {
        button[disabled] {
            opacity: .5;
        }
    }

    .scroll-container {
        flex: 1;
        overflow-y: hidden;


        &.disable-overflow {
            overflow-y: visible;
        }
    }

    padding: var(--m-spacing);

    .task-date-filter, select-category, department-usergroup-selector {
        align-items: center;
        min-height: 50px;
        border-radius: var(--general-border-radius);
        background: var(--background-highlight-color) !important;
    }

    department-usergroup-selector {
        line-height: 50px;
    }

    .rl-form {
        .rl-field {
            label {
                font-size: 0.8rem;
                color: var(--general-text-inactive-color);
            }
        }

        .rl-input {
            border: none;
            background: transparent !important;

            input {
                background: transparent;
            }

            label {
                font-size: 1.06667rem;
            }

            .toggle {
                background-color: transparent;
            }
        }
    }

    footer {
        text-align: center;
        min-height: 90px;
        border: 0;
        background: var(--background-highlight-color);

        button {
            border-radius: 9px;
        }

        .filter-reset-all {
            margin-top: 10px;

            rl-icon {
                font-size: 14px;
                color: grey;
            }

            button {
                text-transform: none;
                color: grey;
                font-weight: 400;
                padding: 0 5px;
            }
        }
    }

    rl-button button.full-width {
        width: 90%;
    }

    rl-button button.standard {
        text-align: center;
        border-top: 0;
    }

    .task-date-filter {
        display: flex;

        .date {
            width: 50%;

            .rl-input {
                display: block;
                background: var(--general-background-color);

                .prefix {
                    height: 30px;
                    padding-left: 10px;
                    font-size: 14px;
                }

                input {
                    padding: 0 10px 0 10px;
                    margin-bottom: 0;
                    height: 30px;
                }
            }

            input {
                padding: 0 10px 0 10px;
                margin-bottom: 0;
            }
        }

        .input-title {
            margin: 0;
            padding: 15px;
            height: 57px;
            display: block;
            color: var(--general-text-color);
            border: none;
            font-size: 20px;
            font-weight: 500;
            background: inherit;

            &::-webkit-input-placeholder {
                color: var(--general-text-color);
                opacity: .6;
            }
        }

        textarea {
            position: relative;
            width: 100%;
            height: 120px;
            box-sizing: border-box;
            padding: 15px;
            border: none;
            background-image: linear-gradient(to bottom, var(--general-background-color) 85%, var(--background-highlight-color));

            &::-webkit-input-placeholder {
                color: var(--general-text-color);
            }
        }
    }

    select-category {
        display: flex;
        box-sizing: border-box;
        width: 100%;
        min-height: 50px;
        padding: 0 10px 0 40px;
        background: var(--general-background-color);
        color: var(----general-text-color);

        .click-area {
            flex: 1;
        }
    }

    select-repeat {
        padding-left: 10px;
    }

    .permission-selector-controls label {
        display: none;
    }

    .toggle-tasks {
        .rl-field {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            margin-top: 15px;
            padding-right: 10px;
        }
    }

    @media @desktop-up {
        .buttons-footer {
            display: flex;
            flex-direction: row;
            gap: var(--m-spacing);
            justify-content: flex-end;
            align-items: baseline;
        }

        footer {
            margin-top: var(--m-spacing);
            background: transparent;

            .submit-button,
            .reset-button {
                button {
                    font-size: 0.86667rem;
                    font-weight: 700;
                    text-transform: uppercase;
                }
            }

            .filter-reset-all,
            .reset-button {
                button,
                rl-icon {
                    color: var(--button-background-color);
                }
            }

            .submit-button {
                button {
                    min-width: 90px;
                    color: var(--button-text-color);
                    background: var(--button-background-color);
                }
            }
        }
    }
}

filter-tasks {
    overflow: auto;
    scrollbar-width: thin;
    height: 100%;
    display: flex;
    flex-direction: column;
    scrollbar-color: rgba(0, 0, 0, 0.15) transparent;
    box-sizing: border-box;

    .filters-container {
        display: flex;
        flex-direction: column;
        flex: 1;
    }

    .scroll-container {
        flex: 1;
    }

    .buttons-footer {
        align-items: flex-end;

        .filter-reset-all {
            margin-top: 0;
            margin-bottom: 10px;
        }
    }
}
