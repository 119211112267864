training-lesson-item {
    display: flex;
    flex-direction: column;
    padding: 0 5px 10px 5px;

    .image {
        position: relative;
        overflow: hidden;
        height: auto;
        border-top-left-radius: var(--training-border-radius);
        border-top-right-radius: var(--training-border-radius);
        box-shadow: none;
    }

    &.training-item .image {
        box-shadow: none;

        .image-inner {
            height: 0;
            padding-bottom: var(--training-lesson-image-height);
            position: relative;

            rl-icon {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                line-height: initial;
            }
        }
    }

    state-icon {
        position: absolute;
        top: 0;
        right: 0;
        margin: 10px;
    }

    .info {
        flex: 1;
        padding: 10px 0;
        border-bottom-left-radius: var(--training-border-radius);
        border-bottom-right-radius: var(--training-border-radius);

        .duration-redo {
            display: flex;
            justify-content: center;

            .redo {
                margin-left: 15px;
                text-transform: lowercase;
            }
        }
    }
}
