@import "../../Import.less";

@form-module-nav-height: 52px;
@form-module-footer-height: 40px;

@import "FormModuleReports.less";

.native {
    .form-module {
        .form-module-content {
            bottom: 91px;
            bottom: calc(91px + env(safe-area-inset-bottom, 0px));
        }

        .form-module-footer {
            bottom: 51px;
            bottom: calc(51px + env(safe-area-inset-bottom, 0px));
        }
    }
}

.form-module {
    // NAVIGATION
    .form-module-nav {
        display: block;
        position: absolute;
        top: 0;
        left: 5px;
        right: 5px;
        height: @form-module-nav-height;
        text-align: center;
        font-size: 0;
        z-index: 2;
        overflow-x: auto;
        overflow-y: hidden;
        white-space: nowrap;

        .form-module-nav-item {
            display: inline-block;
            height: @form-module-nav-height - 2;
            text-align: center;
            width: 16%;
            min-width: 26px;
            border-bottom: 2px solid transparent;

            .step-status {
                display: inline-block;
                height: 26px;
                width: 26px;
                line-height: 26px;
                border: 1px solid rgba(0, 0, 0, 0.12);
                border-radius: 100%;
                margin-top: 15px;
                vertical-align: top;
                text-align: center;
                color: transparent;
            }

            &.active {
                border-color: #000;
            }

            &.answered {
                .step-status {
                    background-color: @color-primary;
                    border-color: @color-primary;
                    color: @color-primary-accent;
                    font-size: 20px;

                    > i {
                        position: relative;
                        top: 1px;
                    }
                }
            }
        }
    }
    // FOOTER
    .form-module-footer {
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        bottom: env(safe-area-inset-bottom, 0px);
        height: @form-module-footer-height;
        line-height: @form-module-footer-height;
        z-index: 2;

        > button {
            display: inline-block;
            height: @form-module-footer-height;
            line-height: @form-module-footer-height;
            position: absolute;
            top: 0;
            left: 0;
            padding: 0 10px;
            border: none;
            background: none;
            font-size: 15px;
        }

        .next-btn {
            right: 0;
            left: auto;
        }

        > p {
            display: block;
            height: @form-module-footer-height;
            line-height: @form-module-footer-height;
            text-align: center;
            font-size: 0.9em;
            margin: 0;
            padding: 0;
            font-weight: bold;
            color: rgba(0, 0, 0, 0.4);

            > span {
                margin-left: 3px;
                font-weight: normal;
            }
        }
    }
    // CONTENT
    .form-module-content {
        position: absolute;
        left: 0;
        right: 0;
        top: @form-module-nav-height;
        bottom: @form-module-footer-height;
        bottom: calc(@form-module-footer-height + env(safe-area-inset-bottom, 0px));
        z-index: 1;
        overflow-x: hidden;
    }

    .form-module-step {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        bottom: 0;
        padding: 20px 10px 20px 10px;
        box-sizing: border-box;
        transition: all ease-in-out .5s, opacity linear 1ms;

        &.inactive {
            opacity: 0;
        }
        &.prev {
            left: -100%;
        }

        &.next {
            left: 100%;
        }

        .form-module-question {
            display: block;

            &:not(:last-of-type) {
                margin-bottom: 15px;
            }

            .form-module-question-media {
                margin-bottom: 10px;
            }

            .form-module-single-question {
                -ms-flex-wrap: wrap;
                flex-wrap: wrap;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                -ms-flex-direction: row;
                flex-direction: row;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                -ms-flex-line-pack: center;
                align-content: center;
                position: absolute;
                top: 0;
                left: 20px;
                right: 20px;
                bottom: 0;

                > div {
                    display: block;
                    width: 100%;
                }
            }

            h3 {
                margin: 0 0 10px 0;
                font-size: 1rem;

                &.text-center {
                    text-align: center;
                }
            }
        }
    }
    // Imports
    @import "FormModuleQuestionCheckbox.less";
    @import "FormModuleQuestionTextfield.less";
    @import "FormModuleQuestionRating.less";
    @import "FormModuleQuestionSelector.less";
    @import "FormModuleQuestionMedia.less";
    @import "FormModuleQuestionInformation.less";
}