simple-user-list {
    ul {
        overflow-y: auto;
        height: 100%;
        padding: 0;
        margin: 0;

        li {
            display: flex;
            padding: 5px 0;
        }

        .img-wrapper {
            position: relative;
            margin-right: 5px;
            
            .blocked-user-icon {
                width: 34px;
                height: 34px;
                margin-right: 6px;
            }

            rl-icon {
                position: absolute;
                bottom: 0;
                right: 0;
                color: var(--white-color);
                box-shadow: 0 2px 6px 0 ~'rgba(var(--general-text-color-rgb), 0.2)';

                &[icon=save] {
                    background-color: var(--confirmation-color);
                }

                &[icon=help] {
                    background-color: var(--alert-color);

                    &.pending {
                        background-color: ~'rgba(var(--general-text-color-rgb), 0.5)';
                        backdrop-filter: blur(10px);
                    }
                }

                &[icon=close] {
                    background-color: var(--warning-color);
                }
            }
        }

        .select-user {
            flex: 1;
            text-align: end;
            padding-right: 10px;
        }

        .select-user-checkbox {
            position: absolute;
            z-index: -1;
            opacity: 0;
        }

        .select-user-checkbox+label {
            display: inline-flex;
            align-items: center;
            user-select: none;
        }

        .select-user-checkbox+label::before {
            content: '';
            display: inline-block;
            width: 20px;
            height: 20px;
            flex-shrink: 0;
            flex-grow: 0;
            border: 1px solid var(--general-border-color);
            border-radius: 50%;
            margin-right: 50%;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 50% 50%;
            background-color: var(--general-background-color);
            cursor: pointer;
        }

        .select-user-checkbox:checked+label::before {
            border-color: var(--highlight-color);
            background-color: var(--highlight-color);
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
        }

        .select-user-checkbox:disabled+label::before {
            opacity: 0.5;
        }

        .profile-image {
            width: 34px;
            height: 34px;
            padding: 0;
        }

        .user-data {
            display: flex;
            flex-direction: column;
            overflow: hidden;

            div {
                display: flex;
            }

            h3,
            p {
                margin: 0;
            }

            h3 {
                font-size: 15px;
                text-overflow: ellipsis;
                overflow: hidden;
            }

            p {
                margin-right: 5px;
                font-size: 13px;
                opacity: 0.7;
            }
        }
    }

}