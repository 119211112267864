.chkSwitch {
    input[type=checkbox] {
        opacity: 0;
        position: absolute;
        left: -9999px;

        & + label {
            display: inline-block;
            position: relative;
            height: 26px;
            width: 50px;
            border-radius: 13px;
            margin: 0;
            background: ~'rgba(var(--general-text-color-rgb), .2)';
            transition: background .2s ease-in-out, opacity .2s ease-in-out;

            &:after {
                height: 22px;
                width: 22px;
                border-radius: 50%;
                display: block;
                background: #fff;
                position: absolute;
                z-index: 1;
                left: 2px;
                top: 2px;
                content: '';
                -moz-box-sizing: border-box;
                -webkit-box-sizing: border-box;
                box-sizing: border-box;
                -moz-transition: all .2s ease-in-out;
                -o-transition: all .2s ease-in-out;
                -webkit-transition: all .2s ease-in-out;
                transition: all .2s ease-in-out;
                box-shadow: 0 1px 4px rgba(0,0,0,.3);
            }
        }

        &:checked + label {
            background: var(--confirmation-color);
            opacity: 1;

            &:after {
                -moz-transform: translate3d(24px, 0, 0);
                -ms-transform: translate3d(24px, 0, 0);
                -o-transform: translate3d(24px, 0, 0);
                -webkit-transform: translate3d(24px, 0, 0);
                transform: translate3d(24px, 0, 0);
            }
        }
    }
}
