comment-item {
    display: flex;
    align-items: flex-start;
    padding: 0 15px;
    margin-bottom: 10px;

    &.with-bottom-bar {
        margin-bottom: 0;
    }

    &:first-of-type {
        .reaction-icon-wrapper {
            .reaction-label {
                margin-bottom: 17px;
            }
        }
    }

    profile-image {
        padding-right: 10px;
        
        .leave-icon {
            bottom: 5px;
            right: 8px;
        }

        > .profile-image {
            height: 45px;
            width: 45px;
            border: none;

            &.has-highscore-level {
                border: none;
            }
        }
    }

    .comment {
        flex: 1;
    }

    .comment-content {
        background: var(--general-background-color);
        padding: 10px;
        border-radius: 15px;
        position: relative;

        &:after {
            content: "";
            position: absolute;
            margin-top: -6px;
            margin-left: -5px;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-bottom: 5px solid var(--general-background-color);
            transform: rotate(45deg);
            left: 0;
            top: 15px;
        }
    }

    h3 {
        display: block;
        margin: 0;
        box-sizing: border-box;
        padding-right: 30px;
        font-size: 13px;
        font-weight: 600;
    }

    .comment-media {
        padding: 10px 0 5px;

        media-preview {
            width: 100%;
            height: 200px;

            .image img {
                width: 100%;
                height: 200px;
                object-fit: cover;
            }
        }
    }

    p {
        display: block;
        margin: 0;
        padding: 5px 0 0;
        font-size: 15px;
        word-break: break-word;

        > span {
            font-weight: 600;
            color: var(--highlight-color);
        }
    }

    > span {
        display: block;
        margin: 0;
        padding: 0 30px 0 0;
        font-size: .8em;
        color: darken(@split-color, 5%);
    }

    .confirm {
        position: absolute;
        right: 0;
        top: 10px;
        height: 20px;
        width: 30px;
        display: block;
        text-align: center;
        color: darken(@split-color, 5%);
        border: 0;
        background: transparent;
        font-size: 15px;

        &:hover {
            color: #000;
        }
    }

    .report {
        position: absolute;
        right: 0;
        bottom: 4px;
        height: 20px;
        width: 30px;
        display: block;
        text-align: center;
        color: #bc6565;
        border: 0;
        background: transparent;
        font-size: 14px;

        &:hover {
            color: #9e2424;
        }
    }

    .comments-actions {
        display: flex;
        align-items: center;
        justify-content: space-between;
        top: -7px;
        position: relative;

        .action-buttons {
            display: flex;
            align-items: center;

            > *:not(:first-child) {
                margin-left: 10px;
            }

            button {
                padding: 0;
                font-size: 13px;
                font-weight: 600;
                color: var(--general-text-color);
                min-height: 46px;
            }

            .comment-like {
                &.liked {
                    color: var(--highlight-color);

                    rl-icon {
                        color: var(--highlight-color);
                    }
                }
            }
        }

        .reactions-labels {
            padding: 0;

            &.extended {
                background: var(--general-background-color);
                border-radius: 15px;
                box-shadow: 0 2px 4px 0 rgba(60, 72, 88, 0.1);
            }

            button {
                font-size: 13px;
                color: var(--general-text-color);
                padding: 2px 5px 2px 2px;
                display: flex;
                align-items: center;
            }

            reactions-summary {
                reaction-icon {
                    width: 18px;
                    height: 18px;
                    box-sizing: content-box;
                    border: 2px solid var(--general-background-color);
                }
            }
        }
    }

    .blocked-user-image {
        margin-right: 10px;
        width: 45px;
        height: 45px;
        border: none;

        &.small {
            width: 30px;
            height: 30px;
        }
    }
    
    mention-textarea {
        profile-image {
            .leave-icon {
                bottom: 0;
            }
        }
    }
}