notifications-settings, notifications-setting-page, module-notifications-settings {
    .form {
        h4 {
            background: @clr-background;
            color: unset;
            padding: 15px 20px;
            position: relative;
            margin: 0;
            border-bottom: 1px solid #ebecee;

            rl-icon, img {
                position: absolute;
                top: 50%;
                margin-top: -13px;
                left: 20px;
                width: 14px;
                height: 14px;
                padding: 6px;
                border-radius: 50%;
                color: white;
                object-fit: contain;
            }

            rl-icon {
                width: 27px;
                height: 27px;
                font-size: 14px;
                padding: 0;
            }

            &.app-title {
                img {
                    padding: 0;
                    width: 28px;
                    height: 28px;
                    border: 1px solid @clr-borders;
                }
            }

            &.content-title {
                rl-icon {
                    background: #5fa3f1;
                    color: white;
                }
            }

            &.social-title {
                rl-icon {
                    background: #eb6262;
                    color: white;
                }
            }

            &.app-title {
                rl-icon {
                    background: black;
                    color: #ea6f63;
                }
            }
        }
    }

    .form-input-list {
        width: 100%;
        box-sizing: border-box;
        background: none;
        padding: 0;
        background: white;

        &.disabled {
            position: relative;

            &:after {
                content: '';
                position: absolute;
                z-index: 5;

                top: 0;
                right: 0;
                bottom: 0;
                left: 0;

                background: rgba(0, 0, 0, 0.2);
            }
        }

        .form-item-wrapper {
            padding: 0 20px;
            border-bottom: 1px solid #ebecee;

            &.disabled {
                position: relative;

                &:after {
                    content: '';
                    position: absolute;
                    z-index: 5;

                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;

                    background: rgba(0, 0, 0, 0.2);
                }
            }
        }

        .form-item {
            display: flex;
            align-items: center;
            justify-content: space-between;

            &.changed {
                > label, .status {
                    font-weight: 600;
                }
            }

            > div {
                display: flex;
                justify-content: right;
            }

            > label {
                flex: 1;
                padding-right: 10px;
                min-height: auto;

                opacity: .8;
                color: unset;

                text-transform: none;
                font-size: 14px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .status {
                padding-top: 1px;
                padding-right: 10px;
            }

            .small-input {
                position: static;
                width: auto;

                input {
                    &:disabled + label {
                        background: darkgray;
                    }
                }
            }
        }
    }
}

notifications-settings {
    .module-settings.native {
        .form-item {
            > label {
                width: 50%;
            }

            > div:not(.form-item-placeholder) {
                width: 50%;
                justify-content: space-between;
            }
        }
    }
}


notifications-settings-popup {
    notifications-settings-list, module-group-notifications-settings {
        display: block;
        height: calc(100% - 55px);
    }

    .scroll-container {
        height: 100%;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
    }
}

module-notifications-settings-popup {
    .popup-wrapper .popup-content-inner {
        padding: 0;
    }
}