@speed: .25s;

@-moz-keyframes slideOutLeft {
    to {
        transform: translate3d(-100%, 0, 0);
    }
}

@-webkit-keyframes slideOutLeft {
    to {
        transform: translate3d(-100%, 0, 0);
    }
}

@keyframes slideOutLeft {
    to {
        transform: translate3d(-100%, 0, 0);
    }
}

@keyframes slideOutRight {
    to {
        transform: translate3d(100%, 0, 0);
    }
}

@keyframes slideInRight {
    from {
        transform: translate3d(100%, 0, 0);
    }
    to {
        transform: translate3d(0, 0, 0);
    }
}

@-webkit-keyframes slideInLeft {
    from {
        transform: translate3d(-100%, 0, 0);
    }
    to {
        transform: translate3d(0, 0, 0);
    }
}

@keyframes slideInLeft {
    from {
        transform: translate3d(-100%, 0, 0);
    }
    to {
        transform: translate3d(0, 0, 0);
    }
}