reactions-summary {
    display: inline-flex;
    align-items: center;

    .icons {
        position: relative;
        display: inline-flex;
        flex-direction: row-reverse;
        align-items: center;
        margin: 0;
        padding: 0;

        &.count-3 {
            margin-left: -10px;
        }

        &.count-2 {
            margin-left: -5px;
        }
    }

    reaction-icon {
        border: 2px solid white;

        &:nth-child(1) {
            z-index: 4;
        }

        &:nth-child(2) {
            z-index: 5;
            left: 5px;
        }

        &:nth-child(3) {
            z-index: 6;
            left: 10px;
        }
    }

    .count {
        margin-left: 5px;
    }
}