.base-view.base-view-socialOverview {
    background: var(--background-highlight-color);
    height: auto;
}

social-groups {
    display: block;
    padding: 15px 10px;

    .social-group-placeholder {
        margin-bottom: 10px;
        padding: 15px 15px 15px 20px;

        border-radius: var(--general-border-radius);
        background: var(--general-background-color);
    }
}

social-group {
    display: block;
    position: relative;
    margin-bottom: 10px;

    .social-group {
        padding: 15px 15px 15px 20px;

        border-left: 7px solid;
        border-radius: var(--general-border-radius);
        background: var(--general-background-color);


        .follow-actions {
            margin-left: 5px;

            button {
                max-width: 120px;
                height: 30px;

                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                font-size: 13px;
                font-weight: 500;
            }
        }

        header {
            display: flex;
            justify-content: space-between;
            align-items: center;

            h2 {
                margin: 0;

                font-weight: 700;
                font-size: 15px;
                line-height: 130%;
            }
        }

        .selected {
            font-style: italic;
            color: var(--confirmation-color);
            margin: 10px 0;

            rl-icon {
                background-color: var(--confirmation-color);
                color: var(--white-color);
            }
        }

        .summary {
            margin: 0 10px 0 0;
            font-size: 13px;
            line-height: 130%;
        }

        .new-posts-label {
            margin: 10px 0 0;
            color: var(--highlight-color);
            font-weight: 500;
            display: flex;
            align-items: center;

            .circle {
                background: var(--highlight-color);
                width: 10px;
                height: 10px;
                border-radius: 100%;
                margin-right: 5px;
            }
        }
    }
}
