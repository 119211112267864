leave-registration {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    
    .leave-registration {
        display: flex;
        flex-direction: column;
        flex: 1;
        overflow: hidden;
    }
    
    .rl-tabs {
        margin: var(--s-spacing);
        overflow-x: auto;
        overflow-y: hidden;
        min-height: 40px;
        
        .rl-tab {
            cursor: pointer;
        }
    }
    
    .empty-state {
        display: flex;
        flex: 1;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: var(--s-spacing);

        rl-icon {
            font-size: 4rem;
            color: rgba(var(--general-text-color-rgb), .4);
        }

        .leave-title {
            font-size: 1rem;
            font-weight: 600;
        }

        .leave-text {
            font-size: 0.875rem;
        }
    }

    h2 {
        font-size: 0.875rem;
        font-weight: 600;
    }
    
    .leaves-list {
        padding: 0 var(--spacing) var(--spacing);
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        scrollbar-color: rgba(0, 0, 0, .15) transparent;
        scrollbar-width: thin;
        
        rl-loading-placeholder {
            .placeholder-shimmer {
                &:first-child {
                    margin-top: var(--spacing);
                }
                
                height: 60px;
            }
        }
        
        .leave-item {
            display: flex;
            padding: var(--m-spacing);
            gap: var(--m-spacing);
            margin-bottom: var(--m-spacing);
            border-radius: var(--general-border-radius);
            background-color: var(--background-highlight-color);
        }
        
        .leave-info {
            display: flex;
            flex-direction: column;
            
            span:first-child {
                font-weight: 600;
                font-size: 1rem;
            }
            
            span:last-child {
                font-size: 0.875rem;
            }
        }

        .current {
            color: var(--highlight-color);
        }
        
        .past {
            color: rgba(var(--general-text-color-rgb), 0.5);
        }
        
        .duration-label {
            font-size: 0.875rem;
            margin-left: auto;
            color: rgba(var(--general-text-color-rgb), 0.5);
        }
    }

    icon-plus button.right-bottom {
        transform: translate3d(0, 0, 0) !important;
        
        @media @desktop-up {
            transform: none !important;
            right: calc((100cqw - var(--max-width)) / 2 - 10px);
        }
    } 
}

.base-view-leaveRegistration {
    overflow: hidden;
    container-type: inline-size;    
}

leave-registration-popup-component {    
    .content {
        display: flex;
        flex-direction: column;
        gap: var(--spacing);
        padding: var(--spacing);
    }
    
    rl-icon[icon="delete"] {
        color: var(--warning-color);
    }

    .block {
        display: flex;
        flex-direction: column;
        
        .loader-dropdown,
        .loader-time,
        .loader-textarea {
            margin-bottom: calc(2*(var(--spacing)));
        }
            
        .loader-textarea {
            .placeholder-shimmer {
                height: 150px;
            }
        }

        h2 {
            font-size: 0.875rem;
            font-weight: 600;
            padding-left: var(--m-spacing);
        }
        
        ul {
            padding-left: 0;
            margin-top: 0;
            margin-bottom: 0;
            
            rl-toggle {
                margin-left: auto;
            }
        }

        li {
            padding: var(--m-spacing);
            gap: var(--s-spacing);
            list-style: none;
            display: flex;
            align-items: center;
            
            &.comments-section {
                padding: 0;
            }
            
            &.disable {
                opacity: 0.7;
                pointer-events: none;
            }
            
            &.readonly {
                opacity: 0.7;
            }
        }

        .leave-details {
            border-radius: var(--general-border-radius);
            background-color: var(--background-highlight-color);

            &.disable {
                opacity: 0.7;
                pointer-events: none;
            }
            
            &.highlight {
                border: 1px solid var(--alert-color);
            }
            
            &.dropdown {
                display: flex;
                padding: var(--m-spacing);
                justify-content: flex-start;
                align-items: center;
                gap: var(--s-spacing);
                
                rl-icon[icon='triangle-down'] {
                    margin-left: auto;
                }
            }
            
            .date {
                width: 100%;
                
                .time {
                    display: flex;
                    width: 100%;
                }
            }
        }
        
        input[type='date'],
        input[type='datetime-local'] {
            flex: auto;
            background-color: unset;
            border: unset;
            color: var(--general-text-color);
            font-size: 1rem !important;
        }
    }
    
    rl-textarea {
        textarea {
            padding: var(--m-spacing);
        }
    }

    footer {
        width: calc(100% - 2 * (var(--spacing)));
        position: absolute;
        bottom: 0;
        margin: var(--spacing);
        padding-bottom: env(safe-area-inset-bottom);

        button {
            width: 100%;
            font-size: 1rem;
            text-transform: uppercase;
        }
    }
}

absence-dropdown-component {
    @media @desktop-up {
        .actionsheet {
            min-width: 350px;
            padding: var(--spacing);
        }
    }

    action-sheet-custom .actionsheet .actionsheet-content {
        padding-top: 0;
        
        @media @desktop-up {
            padding-top: var(--spacing);
        }
    }
    
    rl-select-list .item {
        justify-content: flex-start;
        
        .labels-container {
            margin-right: auto;
        }
    }
}