@import "../../../Import.less";

// DESIGN: CREATIVE
.landing-page.landing-page-design-creative{
    .landing-page-chart{
        .chart-header h4{
            font-size: 14px;
            text-align: center;
            font-weight: 600;
        }
    }       
}