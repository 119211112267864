language-selector-popup {
    rl-search {
        margin-bottom: 10px;
    }

    .selected-languages-wrapper {
        margin: 0 -15px;
        padding-left: 15px;
    }

    .selected-languages ul li {
        position: relative;

        rl-icon {
            position: absolute;
            top: -8px;
            right: -8px;
            background: ~'rgba(var(--background-highlight-color-rgb), 0.6)';
            backdrop-filter: blur(27.1828px);
        }
    }

    .language-list {
        margin: 0 -15px;

    }

    .language-list-item {
        display: flex;
        align-items: center;
        justify-content: space-between;

        height: 50px;
        padding: 0 15px;

        border-bottom: 1px solid ~'rgba(var(--general-text-color-rgb), 0.1)';

        &:first-of-type {
            border-top: 1px solid ~'rgba(var(--general-text-color-rgb), 0.1)';
        }

        &.user-language {
            rl-icon {
                opacity: 0.5;
                background: var(--general-text-color);
            }
        }

        .left {
            display: flex;
            align-items: center;
        }

        .language-image {
            margin-right: 10px;
        }

        rl-icon {
            width: 17px;
            height: 17px;
            font-size: 9px;

            background: var(--highlight-color);
            color: var(--white-color);
        }
    }
}