.signup-404 {
    padding: 20px 10px 30px 10px;
    text-align: center;

    h2 {
        font-size: 22px;
        margin: 0;
        padding: 0;
    }

    i {
        font-size: 100px;
        margin-bottom: 15px;
        color: var(--warning-color);
    }
}