button-group {
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    border-radius: var(--general-border-radius);

    &.ng-invalid:not(.ng-pristine) {
        border: 1px solid var(--warning-color);
    }

    &.series {
        button {
            width: 50%;

            &:not(:nth-last-of-type(-n+2)) {
                margin-bottom: 1px;
            }

            &:nth-child(2n) {
                margin-right: 0;
            }
        }
    }

    &:not(.series) {
        button {
            flex: 1;
        }
    }

    button {
        display: inline-block;

        min-height: 34px;
        padding: 5px 10px;

        box-sizing: border-box;
        font-size: 16px;
        background: rgba(var(--general-text-color-rgb), 0.07);
        cursor: pointer;

        &.active {
            background: rgba(var(--general-text-color-rgb), 0.15);
            font-weight: 600;
        }
        
        &.in-use {
            color: var(--general-text-inactive-color);
        }

        &:hover {
            background: rgba(var(--general-text-color-rgb), 0.15);
        }

        &:not(:last-of-type) {
            border-right: 1px solid var(--white-color);
        }

        i {
            margin-right: 3px;
        }
    }
}
