search-tasks {
    height: 100%;
    display: flex;
    flex-direction: column;

    .no-results-item {
        font-size: 5rem;
        margin-bottom: var(--s-spacing);
    }
    
    @media @desktop-up {
        padding: var(--spacing) var(--s-spacing) 0;
    }

    @media @tablet-max {
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
    }

    .spinner-overlay {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;

        .spinner {
            width: 50px;
            height: 50px;
        }
    }

    .search-tasks-container {
        display: flex;
        flex-direction: column;
        flex: 1;
        margin-top: var(--spacing);

        @media @desktop-up {
            overflow-y: auto;
            
            rl-loading-placeholder {
                margin-right: 0;
            }
        }
        
        tasks {
            flex: 1;
        }
    }

    .no-events-found {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;

        h2 {
            margin: 0 0 10px 0;
        }

        p {
            margin: 0;
        }
    }
}