@import "../Variables";

alert-message {
    display: block;

    .alert-text {
        margin: 0;
        padding: 10px 20px;

        color: @clr-bodytext;
        font-weight: 600;

        &.alert {
            border: 1px solid darken(@clr-alert, 20%);
            background: @clr-alert;
        }
        &.warning {
            border: 1px solid darken(@clr-warning, 20%);
            background: @clr-warning;
        }
        &.confirm {
            border: 1px solid darken(@clr-confirmation, 20%);
            background: @clr-confirmation;
        }
    }
}