@import 'MyEvents/MyEvents';
@import 'CalendarView/CalendarView';
@import 'ExploreView/ExploreView';

@import 'Search/SearchEvents/SearchEvents';
@import 'Search/SearchPanel/SearchPanel';

@import 'CreateUpdateEvent/CreateUpdateEvent';
@import 'CreateUpdateEvent/InviteIndividuals/InviteIndividuals';
@import 'FilterPopup/FilterPopup';

@import 'Event/Event';

@import 'NoEvents/NoEvents';
@import 'EventStatus/EventStatus';

@import 'EventDetails/EventDetails';
@import 'EventDetails/AddEventUpdate/AddEventUpdate';
@import 'EventDetails/EventUpdate/EventUpdate';
@import 'EventDetails/CancelEventPopup/CancelEventPopup';
@import 'EventDetails/CancelEventPopup/CancelRecurringEventPopup';
@import 'EventDetails/AttendanceOverviewPopup/AttendanceOverviewPopupComponent';


.base-view-calendar, .base-view-eventPopup {
    background: var(--general-background-color);
}

.calendar-module {
    padding: 0;
    height: 100%;
}

scroll-calendar {
    display: flex;
    height: 100%;
    flex-direction: column;

    .search-field {
        position: absolute;
        top: -55px;
        height: 55px;
        z-index: 100;
        transform: translateX(100%) !important;
    }

    .calendar-wrapper {
        flex: 1;
        overflow: hidden;
    }

    .loading-placeholder {
        display: flex;
        gap: 15px;

        &.big {
            .shimmer {
                height: 230px;
                min-width: 280px;
                width: 280px;
            }
        }

        &:not(.big) {
            flex-wrap: wrap;

            .shimmer {
                height: 170px;
                width: calc((100% - 15px) / 2);

                @media @tablet-up {
                    width: calc((100% - 30px) / 3);
                    height: 190px;
                }
                @media @desktop-up {
                    height: 210px;
                }
            }
        }

        .shimmer {
            border-radius: var(--general-border-radius);
        }
    }

    > footer {
        display: flex;
        height: 55px;
        box-sizing: border-box;
        align-items: center;
        justify-content: space-between;
        border-top: 1px solid rgba(0, 0, 0, 0.1);

        button {
            width: 20%;
            padding: 0;
            overflow: hidden;
            border: none;
            background: none;
            color: black;
            text-align: center;

            &:hover, &.active {
                color: var(--calendar-accent-color);
            }

            icon {
                height: 25px;
                width: 30px;
            }

            span {
                display: block;
                font-size: 10px;
            }
        }

        .add-event {
            display: flex;
            justify-content: center;

            div {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 40px;
                height: 40px;
                border-radius: 100%;
                background: var(--calendar-accent-color);
            }

            icon {
                width: 20px;
                height: 20px;
                color: white;
            }
        }

        .search {
            g, circle {
                fill: transparent;
            }
        }
    }

    icon-plus {
        button {
            background: var(--calendar-accent-color);
        }
    }
}
