manager-overview-table {
    display: flex;
    overflow: hidden;
    flex: 1;
    flex-direction: column;

    .scroll-container {
        overflow-y: auto;
        scrollbar-width: thin;
        height: 100%;
        -webkit-overflow-scrolling: touch;
    }

    header {
        display: flex;
        overflow: hidden;
        padding: 10px;
        box-sizing: border-box;
        border-bottom: ~'1px solid rgba(var(--general-text-color-rgb), .1)';

        div {
            display: flex;
            font-weight: bold;

            .title {
                &.mobile {
                    @media @tablet-up {
                        display: none;
                    }
                }

                &.desktop {
                    overflow: hidden;
                    text-overflow: ellipsis;

                    @media @mobile-max {
                        display: none;
                    }
                }
            }

            .sort {
                position: relative;
                top: 2px;
                padding-left: 5px;
                color: @clr-borders;

                &.active {
                    color: @clr-bodytext;
                }

                &.reverse {
                    transform: scale(1, -1);
                }
            }
        }
    }

    .cell {
        display: flex;
        width: 30%;
        align-items: center;
        overflow: hidden;
        text-align: left;

        .expand-button {
            min-width: 19px;
            width: 19px;

            &.empty {
                .fal:before {
                    content: "";
                }
            }
        }

        &.DepartmentName {
            width: 70%;
            z-index: 1;
        }

        &.Progress {
            align-items: flex-start;
            justify-content: center;
            font-size: 14px;

            percentage-circle {
                .c100.small {
                    font-size: 19px;
                    margin-left: 5px;

                    &:after {
                        background-color: var(--general-background-color);
                    }
                }
            }
        }

        &:not(:last-of-type) {
            padding-right: 10px;
        }
    }

    .table-content {
        display: flex;
        flex: 1;
        overflow-y: auto;
        flex-direction: column;
    }

    .department {
        flex-direction: column;
        border-bottom: 1px solid @clr-borders;
        position: relative;

        &.opened {
            &:before {
                content: '';
                display: block;
                position: absolute;
                z-index: 0;
                top: 23px;
                left: 22px;
                bottom: 23px;
                width: 1px;
                background: @clr-bodytext;
            }

            > .inner-row {
                .name {
                    font-weight: bold;
                }
            }
        }

        &:not(.opened) {
            .departments {
                display: none;
            }
        }

        .department {
            border-bottom: none;

            &.last:before {
                content: '';
                display: block;
                position: absolute;
                top: 50%;
                left: 23px;
                height: 1px;
                width: 7px;
                background: #3c4858;
            }
        }
    }

    .inner-row {
        display: flex;
        width: 100%;
        padding: 15px 10px 15px 15px;
        box-sizing: border-box;
        background: transparent;

        .fal {
            line-height: 13px;
            background: white;
        }
    }
}
