task {
    display: block;
    padding: 10px 15px;
    box-sizing: border-box;
    cursor: pointer;
    background: var(--background-highlight-color);
    border-radius: var(--general-border-radius);
    margin-bottom: var(--m-spacing);

    @media @desktop-up {
        flex-basis: 49%;
        min-height: 100px;
    }

    .task-icons-container {
        display: inline;
        position: absolute;
        right: 0;
    }

    .task {
        height: 100%;
        position: relative;
        z-index: 2;
        display: flex;
        flex-direction: row;
        justify-content: center;
        box-sizing: border-box;
        background-size: cover;

        .label {
            position: relative;
            z-index: 2;
            align-self: flex-start;
            padding: 5px;
            font-size: 1.06667rem;
            font-weight: 700;
        }

        &.completed, &.overdue {
            .content {
                flex-direction: row;
                justify-content: flex-start;
                align-items: flex-start;
            }
        }

        .completed-icon {
            rl-icon {
                opacity: 0;
            }
        }

        &.completed {
            .content {
                h2 {
                    color: ~'rgba(var(--general-text-color-rgb), .5)';
                }

                .task-title {
                    text-decoration: line-through;
                    opacity: .5;
                }
            }

            .status-icon {
                background: var(--confirmation-color);
            }

            .completed-icon {
                display: flex;
                background: @clr-confirmation;
            }

            .overdue-icon {
                display: none;
            }

            h2 {
                color: @clr-confirmation;
            }

            .task-title {
                text-decoration: line-through;
            }

            .text {
                p {
                    font-weight: 500;
                    color: var(--confirmation-color);
                }

                .rl-subheadline {
                    color: var(--general-text-color);
                }
            }
        }

        &.overdue {
            .completed-icon {
                display: none;
            }

            .status-icon {
                background: var(--warning-color);
            }
        }

        &.due-soon {
            .status-icon {
                background: var(--alert-color);
            }

            .text {
                p {
                    font-weight: 500;
                    color: var(--alert-color);
                }
            }
        }

        &.empty {
            .status-icon {
                display: none;
            }

            .text rl-icon {
                display: none;
            }
        }

        .completed-icon, .overdue-icon {
            display: none;
            margin-right: 15px;
            min-width: 40px;
            height: 40px;
            align-items: center;
            justify-content: center;
            border-radius: 100%;

            .icon {
                color: white;
                font-size: 20px;
            }
        }

        .content {
            flex: 1;
            z-index: 2;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: flex-start;
            overflow: hidden;
        }

        .text {
            overflow: hidden;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;

            .top {
                display: flex;
                justify-content: space-between;

                .label {
                    box-sizing: border-box;
                    overflow: hidden;
                    white-space: nowrap;
                    max-width: 120px;
                    text-overflow: ellipsis;
                    padding: 5px;
                    font-size: 13px;
                }

                .task-title {
                    font-weight: 700;
                    font-size: 1.06667rem;
                    word-break: break-word;
                }
            }

            h2 {
                margin: 3px 0 2px;
                padding: 0;
                font-size: 15px;
                font-weight: 600;

                rl-icon {
                    font-size: 12px;
                    opacity: .4;
                    color: var(--general-text-color);
                }
            }

            p {
                font-size: 13px;
                padding: 0;
                margin: 0;
                font-weight: 500;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
            
            .deadline {
                font-size: 0.86667rem;
                font-weight: 400;
                white-space: normal;
            }

            .complitions {
                font-weight: 400;
                font-size: 13px;
                color: var(--general-text-color);
            }
        }

        .categories {
            height: 100%;
            display: flex;
            flex-wrap: wrap;
            align-items: center;

            .category {
                width: 30px;
                height: 5px;
                margin-top: 10px;
                margin-right: 5px;
                border-radius: 5px;
            }
        }

        .footer,
        .bottom {
            display: flex;
            justify-content: space-between;

            .assignees {
                display: flex;
                margin-left: auto;

                span {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 25px;
                    height: 25px;
                    border-radius: 50%;
                    color: var(--text-on-white-color);
                    background: var(--white-color);
                    box-sizing: border-box;
                    box-shadow: 0 6px 7px 0 ~'rgba(var(--general-text-color-rgb), .1)';
                    border: 1px solid var(--white-color);
                    background-position: center center;
                    background-size: cover;
                    background-repeat: no-repeat;

                    &:not(:last-of-type) {
                        margin-right: -10px;
                    }
                }
            }
        }
        
        .footer {
            align-items: flex-end;
            margin-top: auto;
        }
    }
}
