@import "../../Import.less";

.gauge-chart{
    .gauge-container{
        height: 200px;
        width: 100%;
        margin-top: -10px;
    }
    .highcharts-axis-title{
        font-size: 16px;
    }
    .gauge-center{
        text-align: center;
        .gauge-value{
            font-size: 25px;
            font-weight: bold;
            color: #000;
        }
        .gauge-label{
            font-size: 12px;
            color: rgba(0,0,0,0.5);
        }
    }
}