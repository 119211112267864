@font-face {
    font-family: 'Avenir Next LT Pro';
    src: local('Avenir Next LT Pro Regular'), local('AvenirNextLTPro-Regular'),
        url('/Content/Fonts/AvenirPro/AvenirNextLTPro-Regular.woff2') format('woff2'),
        url('/Content/Fonts/AvenirPro/AvenirNextLTPro-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir Next LT Pro';
    src: local('Avenir Next LT Pro Medium'), local('AvenirNextLTPro-Medium'),
        url('/Content/Fonts/AvenirPro/AvenirNextLTPro-Medium.woff2') format('woff2'),
        url('/Content/Fonts/AvenirPro/AvenirNextLTPro-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir Next LT Pro';
    src: local('Avenir Next LT Pro Demi'), local('AvenirNextLTPro-Demi'),
    url('/Content/Fonts/AvenirPro/AvenirNextLTPro-Demi.woff2') format('woff2'),
    url('/Content/Fonts/AvenirPro/AvenirNextLTPro-Demi.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}

