show-more-less {
    // fix extra space between text & button
    font-size: 0;

    .sml-text {
        display: inline;
        word-break: break-word;
        word-break: auto-phrase;
    }

    .sml-expand {
        display: inline;
        font-size: 13px;
        opacity: 0.7;
        padding: 0 0 0 5px;
    }
}