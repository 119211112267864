.base .base-view-serviceFormOverview {
    background: var(--background-highlight-color);

    &.base-view {
        height: auto;
    }
}

forms-overview {
    display: block;
    padding: var(--spacing) var(--spacing) calc(60px + env(safe-area-inset-bottom, 0px));

    article {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        padding: var(--m-spacing);

        background: var(--general-background-color);
        border-radius: var(--general-border-radius);

        &:not(:last-of-type) {
            margin-bottom: var(--spacing);
        }
    }

    .image {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        width: 53px;
        height: 53px;
        margin-right: var(--spacing);

        border-radius: var(--general-border-radius);

        rl-icon {
            font-size: 25px;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: var(--general-border-radius);
        }

        rl-badge {
            position: absolute;
            top: -10px;
            right: -10px;
        }

        &:not(.placeholder-shimmer) {
            background: var(--general-border-color);
        }
    }

    .right {
        display: flex;
        flex: 1;
        flex-direction: column;
        align-self: center;

        h2 {
            font-size: 0.95rem;
            margin: 0 0 5px 0;
            font-weight: 600;
            word-break: break-all;
        }

        p {
            margin: 0;
            font-size: 0.8rem;
            word-break: break-all;
            color: var(--general-text-inactive-color);
        }
    }

    .empty-overview {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        gap: var(--spacing);
        max-width: 246px;
        margin: 155px auto;

        .label {
            font-size: 0.9rem;
            font-weight: 700;
            line-height: 130%;
            text-align: center;
            margin: 0;
            padding: 0 var(--s-spacing);
        }

        svg {
            fill: var(--general-text-inactive-color);
            width: 110px;
            height: 110px;
        }
    }

}