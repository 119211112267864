search-panel {
    display: flex;
    padding: 15px 0;

    rl-search {
        flex: 1;
    }

    .filter-button {
        position: relative;

        rl-badge {
            position: absolute;
            top: 0;
            right: 0;
        }
    }
}