bookmarks-dropdown {

    .app-header-dropdown {
        width: 400px;

        .app-header-dropdown-header {

            app-header-dropdown-header {
                gap: var(--s-spacing);
                border-bottom: none;
            }

            h2 {
                text-transform: none;
            }

            .count {
                margin-right: auto;
                font-weight: 400;
                color: var(--general-text-inactive-color);
            }
        }

        .app-header-dropdown-body {
            min-height: 282px;
            max-height: calc(100vh - 165px);
        }

        .app-header-dropdown-footer {
            app-header-dropdown-footer {
                border: none;

                &> button:only-child:hover {
                    background-color: transparent;
                    color: rgba(var(--highlight-color-rgb), 0.85);
                }
            }

            .see-more {
                text-transform: none;
            }
        }
    }

    .no-content {
        margin-top: -70px;
    }

    .filter-match-no-bookmarks {
        .no-content {
            position: static;
            margin: 62px auto 82px;
            height: unset;
        }
    } 
}