lesson-image-question {
    .title {
        h2 {
            margin: 0 0 var(--spacing) 0;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 130%;
            color: var(--general-text-color);
        }

        p {
            margin: 0;
            font-size: 13px;
            color: var(--general-text-color);
        }

        media {
            overflow: hidden;
            margin-bottom: var(--spacing);
            border-radius: var(--general-border-radius);
        }
    }
    
    .answers {
        position: relative;
        margin-bottom: var(--spacing);
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .answer {
        position: relative;
        width: 47%;
        margin-bottom: var(--spacing);

        img {
            object-fit: cover;
            border: 1px solid transparent;
            border-radius: var(--general-border-radius);
            width: 47cqw;
            height: 47cqw;
        }

        &.selected {
            img {
                border-color: var(--highlight-color);
            }

            .answer-icon {
                width: 30px;
                height: 30px;
                background-color: var(--white-color);
                border-radius: 50%;
                color: var(--highlight-color);
                
                &.multiple {
                    width: 26px;
                    height: 26px;
                    font-size: 32px;
                }
            }
        }

        &.answered.selected {
            rl-icon {
                --fa-primary-color: var(--white-color);
                --fa-secondary-opacity: 1;
            }

            &.correct {
                img {
                    border-color: var(--confirmation-color);
                }

                rl-icon {
                    --fa-secondary-color: var(--confirmation-color);
                }
            }

            &.wrong {
                img {
                    border-color: var(--warning-color);
                }

                rl-icon {
                    --fa-secondary-color: var(--warning-color);
                }
            }
        }

        &.disabled {
            img {
                background-color: var(--background-highlight-color);
            }
        }
    }

    .answer-icon {
        position: absolute;
        top: 10px;
        left: 10px;
        font-size: 30px;
        color: var(--general-background-color);

        span {
            width: 30px;
            height: 30px;
        }
        
        &.multiple {
            width: 26px;
            height: 26px;
        }
    }

    .answer-title {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: block;
    }
}