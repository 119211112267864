.colorize {
    &.negative {
        background: var(--warning-color);
    }

    &.neutral {
        background: var(--alert-color);
    }

    &.positive {
        background: var(--confirmation-color);
    }
}

question-statistic-popup {
    display: block;
    padding: var(--spacing);

    .question-statistic-wrapper {
        display: flex;
        flex-direction: column;
        gap: var(--spacing);
        padding: var(--spacing);

        background: var(--general-background-color);
        border-radius: var(--general-border-radius);
        box-shadow: 0 7px 15px 0 var(--shadow-color);
    }

    h3 {
        margin: 0;
        font-size: 1.06667rem;
        font-weight: 700;
    }

    .text {
        margin: 0;
        font-size: 1.06667rem;
    }

    .groups-list-header {
        display: flex;
        justify-content: space-between;
        padding-bottom: var(--m-spacing);
        margin-bottom: var(--m-spacing);

        border-bottom: 1px solid var(--general-border-color);

        span {
            font-size: 0.86667rem;
            font-weight: 700;
        }
    }

    .groups-list {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .group-item {
            display: flex;
            justify-content: space-between;
            gap: var(--m-spacing);
            align-items: center;

            .group {
                overflow: hidden;
                text-overflow: ellipsis;

                white-space: nowrap;
                font-size: 0.86667rem;
            }

            .score {
                flex-shrink: 0;
                width: 50px;
                height: 24px;
                border-radius: 3px;

                text-align: center;
                line-height: 24px;
                font-size: 1.06667rem;
                font-weight: 700;

                .colorize();
            }
        }
    }
}