.user-management-confirm-popup.confirmation-popup {
    .content {
        padding: var(--s-spacing) var(--spacing);
        margin: var(--s-spacing) 0;
    }

    h1 {
        color: var(--elevated-text-color);
        font-size: 1rem;
        padding-bottom: var(--s-spacing);
    }

    p {
        font-size: 0.9rem;
        color: var(--elevated-text-color);
    }

    footer {
        border: none;
        padding: 0 var(--spacing) var(--spacing);
        gap: var(--spacing);

        rl-button {
            border-radius: var(--general-border-radius);
            min-width: unset;
            flex: 1 0 47%;

            button {
                min-width: unset;
                width: 100%;
                text-transform: uppercase;

                span {
                    display: -webkit-box;
                    max-width: 100%;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }

        rl-button.no {
            button {
                opacity: 1;
                color: var(--elevated-text-inactive-color);
                background-color: var(--elevated-border-color);
            }
        }

        rl-button.yes {
            button {
                background-color: var(--warning-color);
                color: var(--white-color);
            }

            &:only-child {
                button {
                    background-color: var(--button-background-color);
                    color: var(--button-text-color);
                }
            }
        }
    }
}