// Page
@import "../Import.less";

@chart-list-bar-height: 30px;
.charts {
    background-color: lighten(@split-color, 10%);

    .chart-list {
        display: block;
        width: 100%;
        list-style: none;
        padding: (@chart-list-bar-height / 2) 0;
        margin: 0;

        li {
            display: block;
            list-style: none;
            padding: 0 0 @chart-list-bar-height 0;
            margin: 0;

            > .chart-group-item {
                padding: 10px 15px 5px 15px;
                border-top: 1px solid @split-color;
                border-bottom: 1px solid @split-color;

                &:after {
                    display: table;
                    clear: both;
                    content: '';
                }

                .chart-list-item {
                    display: block;
                    float: left;
                    width: 100%;
                    -moz-box-sizing: border-box;
                    -webkit-box-sizing: border-box;
                    box-sizing: border-box;
                    margin-bottom: 5px;

                    &.size-2{
                        width: 50%;
                    }
                    &.size-1{
                        width: 33.333333%;
                    }
                }
            }
        }
    }
}

// Charts
chart{
    display: block;
}

// Bar chart
.bar-chart {
    .legend {
        font-size:0.7em;
        
        ul {
            padding:0;
            margin:0;
            list-style-type:none;
            display:block;
            text-align:center;
            
            li {
                padding:2px;
                margin:0 5px;
                display:inline-block;

                span {
                    line-height:19px;
                    vertical-align:middle;

                    &.colorbox {
                        display:inline-block;
                        height:15px;
                        width:15px;
                        border:2px solid #fff;
                        margin-right:5px;
                    }
                }
            }
        }
    }
}