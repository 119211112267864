@import "../../Import.less";

.form-module-question-textfield{
    textarea{
        border: none;
        display: block;
        width: 100%;
        box-sizing: border-box;
        background: rgba(0,0,0,0.06);
        border-radius: 3px;
        font-size: 1rem;
        padding: 8px;
        margin-bottom: 30px;
    }

    input[type=text]{
        border: none;
        display: block;
        width: 100%;
        box-sizing: border-box;
        background: rgba(0,0,0,0.06);
        border-radius: 3px;
        font-size: 1rem;
        padding: 8px;
        margin-bottom: 30px;
    }
}