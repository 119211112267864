user-management-user-list {
    .overflow-mixin() {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        word-break: break-all;
        display: inline-block;
    }

    .user {
        margin-bottom: 15px;

        &.pending {
            margin: 15px 0 0 0;
        }

        .divider {
            padding: 10px 0 15px 10px;
            color: var(--general-text-color);
            text-transform: uppercase;
            font-size: 11px;
            line-height: 14px;
        }

        .item {
            display: flex;

            &:not(:last-of-type) {
                margin-bottom: 15px;
            }

            .img-wrapper {
                position: relative;
                margin-right: 10px;

                &.no-activity,
                &.disabled {
                    profile-image {
                        opacity: 0.5;
                    }
                }

                profile-image {
                    padding-right: 0;

                    .profile-image {
                        width: 36px;
                        height: 36px;
                    }
                }

                activity-indicator {
                    position: absolute;
                    bottom: 0;
                    right: 0;
                }
            }

            .right {
                display: flex;
                flex-direction: column;
                justify-content: center;
                flex-basis: 100%;
                max-width: min(510px, 100%);
                container-type: inline-size;

                .name {
                    font-weight: bold;
                    .overflow-mixin();
                    max-width: min(510px, 100%);
                }

                .title-wrapper {
                    width: 100%;
                    max-width: min(510px, 100%);
                    font-size: 0;
                    display: flex;
                    
                    .department {
                        flex: 1;
                        display: block;
                        .overflow-mixin();
                    }

                    > span:nth-child(2):before {
                        content: '·';
                        font-weight: bold;
                        padding: 0 5px;
                    }

                    span {
                        color: rgba(var(--general-text-color-rgb), 0.4);
                        font-size: 0.8rem;
                    }
                }

                &.disabled {
                    color: var(--general-text-inactive-color);
                }
            }
        }
    }
}