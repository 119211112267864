simple-language-selector {
    button {
        display: flex;
        align-items: center;
        padding: 0;

        .language-image {
            width: 24px;
            height: 24px;
            filter: drop-shadow(0px 6px 15px rgba(0, 0, 0, 0.15));
        }

        rl-icon {
            font-size: 16px;
            margin-left: 3px;
            color: ~'rgba(var(--general-text-color-rgb), 0.5)';
        }
    }
}