catalog-search {
    module-search-filters {
        padding: 10px 0 0;
    }
    
    .search-header {
        &.sticky {
            z-index: 10;
            position: sticky;
            top: 55px;

            background: var(--background-highlight-color);

            @media @desktop-up {
                top: 60px;
            }
        }
    }

    .search {
        display: flex;
        padding-top: 15px;

        rl-search {
            flex: 1;
        }
    }

    .results-wrapper {
        opacity: 1;
        
        &.hidden {
            opacity: 0;
            transition: opacity 0.2s ease-in-out;
        }
    }
}
