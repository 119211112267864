.base-view.base-view-search {
    background-color: var(--background-highlight-color);
    height: auto;
}

search-page {
    display: block;
    position: relative;

    .sticky-wrapper {
        z-index: 5;
        position: sticky;
        top: 55px;
        background-color: var(--background-highlight-color);

        @media @desktop-up {
            top: 60px;
        }

        module-search-filters {
            padding: 7px 15px 10px 15px;
        }

        &:after {
            display: block;
            content: '';
            height: 1px;
            margin-top: auto;
            width: 100%;
            background-color: var(--background-highlight-color);
        }
    }

    .search-results {
        display: block;
        padding-bottom: 15px;

        .results-wrapper {
            opacity: 1;
            transition: opacity 0.3s ease-in-out;

            &.hidden {
                opacity: 0;
            }
        }
    }

    .search {
        padding: 7px 15px 0;
    }


    .results-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 15px 10px;

        h2 {
            margin: 0;
            font-size: 13px;
            font-weight: 700;

            @media @desktop-up {
                font-size: 15px;
            }
        }
    }

    .help-text {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 90px auto 0;
        max-width: 250px;
        text-align: center;

        rl-icon {
            position: relative;
            font-size: 56px;
            margin-bottom: 20px;
            color: var(--general-text-inactive-color);
        }

        h4 {
            margin: 0;
            font-size: 15px;
            font-weight: 700;
        }

        p {
            margin: 0;
            font-size: 13px;
        }

        @media @desktop-up {
            max-width: 320px;

            h4 {
                margin-bottom: 10px;
            }

            rl-icon {
                margin-bottom: 25px;
            }
        }
    }

    .spinner {
        position: fixed;
        width: 50px;
        height: 50px;
        border-color: var(--general-text-color);
        top: 50%;
        left: 50%;
        margin-top: -25px;
        margin-left: -25px;

        opacity: 0.4;

        @media @desktop-up {
            left: calc(50% + 140px);
        }

        @media @desktop-big-up {
            left: calc(50% + 180px);
        }
    }

    .no-content {
        position: relative;
        margin: 90px auto 0;
    }

}

@import "./FilterByModule/FilterByModuleComponent";