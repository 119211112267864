policy-view, 
policy-overview {
    .loading-container {
        height: 80vh;
        width: 100%;
        top: 0;
        left: 0;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .spinner {
            height: 40px;
            width: 40px;
        }
    }
}

.accept-date() {
    color: var(--confirmation-color);
    display: flex;
    gap: 5px;

    i {
        font-size: 1rem;
    }

    span {
        font-weight: 600;
        font-size: 0.85rem;
    }
};

@import './PolicyOverview/PolicyOverview.less';
@import './PolicyView/PolicyView.less';