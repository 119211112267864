points-log {
    filter-button {
        justify-content: flex-end;
    }

    .profile-point-log {
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        overflow-x: hidden;

        header {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            min-height: 100px;
            background: @clr-background;
            border-bottom: 1px solid @clr-borders;

            h2 {
                margin: 0;
                text-transform: uppercase;
                letter-spacing: 1px;
                font-size: 22px;
                color: @clr-bodytext;
            }

            p {
                margin: 0;
                font-size: 15px;
                color: @clr-bodytext;
            }
        }

        article {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px 15px;
            border-bottom: 1px solid @clr-borders;

            .left {
                display: flex;
            }

            rl-icon {
                box-sizing: border-box;
                margin-right: 10px;
                padding: 6px;
                width: 38px;
                min-width: 38px;
                height: 38px;
                font-size: 20px;
                border-radius: 50%;
                background: @clr-borders;
            }

            .description {
                h3 {
                    margin: 0;
                    padding-bottom: 5px;
                    font-weight: 600;
                    font-size: 15px;
                    color: @clr-bodytext;
                }

                span {
                    font-size: 13px;
                    color: @clr-subheadline-onbg;
                }
            }


            .points {
                font-weight: 600;
                font-size: 15px;
                min-width: 40px;
                text-align: right;
                padding: 0 5px;
            }
        }
    }

    .modal-loader {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;

        .spinner {
            width: 50px;
            height: 50px;
        }
    }

    .empty-list {
        .mdi {
            display: block;
            text-align: center;
            font-size: 70px;
        }

        p {
            display: block;
            margin: 10px 0 0 0;
            padding: 0 20px;
            text-align: center;
            font-size: 18px;
        }

        display: block;
        padding: 20px 0;
    }
}