notifications-dropdown {
    no-content {
        display: block;

        .no-content{
            display: block;
            height: 200px;
            margin: 0;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            i {
                font-size: 40px;
                margin: 0 0 20px 0;
            }
            p {
                font-size: 13px;
            }
        }
    }
}