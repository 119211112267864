@import "../Import.less";

.member-card {
    padding: 10px;

    .tc {
        .btn {
            width:100%;
            margin-bottom:10px;
            cursor:default;
        }
    }

    .cardcontainer {
        position:relative;

        .cardimage {
            position:relative;    
            img {
                width:100%;
            }
        }
        .cardtext {
            position:absolute;
            color:#fff;
            text-align:center;
            width:100%;
            top:0px;
            left:0px;

            .text-xl {
                font-size:2.5em;
            }
            .text-sm {
                font-size:0.7em;
            }

            .margin-lg {
                margin-bottom:20px;
            }

            .margin-md {
                margin-bottom:10px;
            }

            .margin-sm {
                margin-bottom:5px;
            }

            barcode {
                display: flex;
                justify-content: center;
                margin: 10px 0;

                img {
                    max-width: 100%;
                }
            }
        }
    }

    .cardinfo {
        .showTC {
            margin-top:10px;
            text-align:center;
            display: block;
            color: gray
        }
    }

}