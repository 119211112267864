create-pin-biometric-popup {
    .popup-content-inner {
        position: relative;
    }

    pin-code-hint {
        .open {
            position: absolute;
            z-index: 10;
            top: 15px;
            right: 15px;
        }
    }

    pin-code {
        height: auto !important;
        flex: 1;
    }
}