social-post {
    display: block;
    position: relative;

    social-poll {
        margin-bottom: 30px;
    }
}

.post-hashtag {
    cursor: pointer;
}

.post-tag {
    cursor: pointer;
}

.tag-label {
    cursor: unset;
}

.post-tag-user-token {
    visibility: hidden;
    display: none;
}

.post-tag-department-token {
    visibility: hidden;
    display: none;
}

.post-tag-user-group-token {
    visibility: hidden;
    display: none;
}

.social-post-item-encoding-missing {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 2;
    background: ~'rgba(var(--color-white-rgb), 0.95)';
    .flex-center-center();
    box-sizing: border-box;
    padding: 20px;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    max-width: 500px;
    margin: 0 auto;

    @media @desktop-up {
        max-width: 60vw;
    }
}

.social-post-item {
    position: relative;
    display: block;
    width: 100%;
    box-sizing: border-box;

    background: var(--general-background-color);
    border-radius: var(--general-border-radius);

    &.is-hashtag {
        padding-bottom: 15px;
    }

    @media @desktop-up {
        max-width: 60vw;
    }

    .app-link-url {
        position: absolute;
        overflow: hidden;
        clip: rect(0 0 0 0);
        height: 1px;
        width: 1px;
        margin: -1px;
        padding: 0;
        border: 0;
        user-select: none;
    }

    .post-inner {
        padding-top: 15px;

        & > * {
            padding-right: 15px;
            padding-left: 15px;
        }

        .post-footer {
            padding-right: 10px;
            padding-left: 10px;
        }

    }

    .open-profile {
        cursor: pointer;
    }

    translation-button {
        margin: 15px 0;
    }

    .stamp {
        width: 80px;
        height: 80px;
        margin-left: auto;
        margin-bottom: 10px;

        background-position: center center !important;
        background-size: contain !important;
        background-repeat: no-repeat;
    }

    rl-rich-preview {
        position: relative;
        margin: 15px 0 10px 0;

        .read-more {
            margin-bottom: 0;
        }
    }

    .post-content-translation {
        margin: 15px 0;
        box-sizing: border-box;
        border-left: 2px solid rgba(0, 0, 0, 0.25);

        .post-content {
            margin-left: 15px;
        }

        .post-translation-toggle-container {
            margin-bottom: 0;
            margin-left: 15px;
        }
    }

    .post-translation-toggle-container {
        margin: 15px 0;

        .post-translation-toggle {
            display: block;
            cursor: pointer;
            color: @color-blue;
            padding: 0;
        }
    }

    .post-image {
        margin: 5px -15px 5px -15px;
        position: relative;

        media-preview {
            min-height: 200px;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        .stamp {
            position: absolute;
            top: 10px;
            right: 10px;
            z-index: 10;

            margin: 0;
        }

        .embed-video {
            width: 100%;
            max-width: 100%;

            > .embed-video-wrapper {
                position: relative;
                padding-bottom: 46%;
                padding-top: 30px;
                height: 0;
                overflow: hidden;

                iframe, object, embed, video {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }
            }
        }

        @media @desktop-up {
            .media {
                .image {
                    img {
                        max-height: 600px;
                        object-fit: contain;
                    }
                }
            }
        }
    }

    .post-info {
        height: 1.2rem;
        line-height: 1.2rem;
        font-size: .8em;
        text-transform: uppercase;
        text-align: center;
        color: rgba(var(--general-text-color-rgb), 0.4);
        padding: var(--s-spacing) 0 var(--m-spacing);
        border-bottom: 1px solid var(--general-border-color);

        span {
            display: inline-block;
            vertical-align: top;
            margin-right: 11px;
            position: relative;
            font-weight: 600;
        }

        .reaction-like {
            margin: 0;
            display: flex;
            font-weight: 600;
        }

        .default-reaction-label {
            margin: 0;
        }
    }

    .post-bottom {
        height: 50px;
        line-height: 50px;
        text-align: center;
        position: relative;
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-around;

        .reaction-message {
            position: absolute;
            top: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            bottom: 0;
            left: 0;
            right: 0;
            background: var(--general-background-color);
            z-index: 7;
            user-select: none;

            @media @desktop-up {
                display: none;
            }
        }

        rl-icon {
            font-size: 17px;
        }

        reaction-icon {
            rl-icon {
                display: flex;
            }
        }

        reaction-button {
            flex: 0 0 33.33%;

            .btn-reaction {
                min-width: 90px;
                @media @tablet-max {
                    position: static;
                }
            }

            @media @tablet-max {
                .reactions-list {
                    left: 50%;
                    transform: translateX(-50%);
                }
            }
        }

        button {
            word-break: break-word;
            flex: 0 0 33.33%;
            position: relative;
            display: inline-block;
            margin: 0;
            padding: 0;
            vertical-align: top;
            font-size: 13px;
            line-height: 21px;
            text-transform: uppercase;
            color: ~'rgba(var(--general-text-color-rgb), 0.4)';
            background: transparent;
            border: none;
            cursor: pointer;
            -webkit-user-select: none;
            -moz-user-select: none;
            user-select: none;

            reaction-icon {
                line-height: 18px;
                margin-right: 2px;

                span {
                    margin: 0;
                }
            }

            > span {
                margin-right: 7px;
            }

            &.liked {
                color: var(--highlight-color);
            }
        }
    }

    .bookmark-message {
        width: 100%;
        font-size: 12px;
        line-height: 40px;
        padding: 0 15px;
        box-sizing: border-box;
        color: transparent;
        background: rgba(0, 0, 0, .7);
        height: 0;
        visibility: hidden;
        transition: all .3s;
        position: relative;

        &.show {
            color: white;
            height: 40px;
            visibility: visible;
        }

        .icon {
            position: absolute;
            right: 5px;
            top: 13px;
            font-size: 14px;
        }
    }

    .post-comments-loading {
        display: block;
        height: 40px;
        line-height: 40px;
        text-align: center;

        .spinner {
            border-color: var(--general-text-color);
        }
    }
}

.social-post-item .post-top {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    min-height: 40px;

    .left, .right {
        display: flex;
        align-items: center;
    }

    .right {
        position: relative;
        right: -2px;
    }

    .post-options {
        padding: 0;
        text-align: center;
        color: var(--general-text-color);
        background: transparent;
        border: none;

        rl-icon {
            font-size: 20px;
        }

        @media @desktop-up {
            rl-icon {
                font-size: 25px;
            }
        }
    }

    .pin-wrapper {
        position: relative;
        padding-right: 10px;

        rl-icon {
            color: var(--highlight-color);
            font-size: 13px;
        }
    }

    tip .tip-content {
        right: -7px;
        top: 30px;
        text-align: left;
        z-index: 10;

        h3 {
            text-transform: uppercase;
            padding-bottom: 4px;

            &:after {
                content: ':';
            }
        }

        &:after {
            right: 35px;
            left: auto;
        }
    }

    profile-image {
        padding-right: 10px;
        
        .leave-icon {
            right: 7px;
        }

        .profile-image {
            height: 34px;
            width: 34px;
            border-radius: 50%;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;
        }

    }

    .profile-info {
        display: block;
        box-sizing: border-box;

        h3 {
            margin: 0;
            font-size: 15px;
            display: block;
            color: var(--general-text-color);
        }

        .divider {
            padding: 0 2px;
        }

        .department {
            span:not(:last-of-type)::after {
                content: ',';
            }
        }
    }
}