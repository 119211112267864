@import "../Import";

.popup-wrapper.lazy-data-list-popup {
    z-index: 205;

    .loading-indicator {
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .spinner {
            width: 50px;
            height: 50px;
        }
    }

    .search-empty {
        display: flex;
        height: 100%;
        padding-top: 100px;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;

        [ng-transclude="create"] {
            width: 100%;
            padding: 0 25px;
            box-sizing: border-box;
        }
    }

    .create-data-list-item {
        box-sizing: border-box;
        height: 46px;
        font-weight: bold;
        font-size: 15px;
        width: 100%;
        background: var(--highlight-color);
        color: var(--text-on-highlight-color);
    }

    .popup-footer {
        margin: 0;

        button {
            width: 50%;

            &.submit {
            }

            &.reset {
                background: #b7b7b7;
                color: #000;

                &:hover {
                    background: #a5a2a2
                }
            }
        }
    }

    .search-block {
        display: flex;
        width: 100%;

        input[type=text] {
            display: block;
            width: 100%;
            max-width: 100%;
            margin: 0;
            padding: 8px;
            border-radius: 0;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            border: 1px solid #dedede;
            background: #eeeeee;
            font-size: 15px;
            height: 50px;
        }

        barcode-reader {
            .btn {
                opacity: 0.8;
                height: 50px;
                width: 50px;
                line-height: 50px;
            }
        }

        .search-wrapper {
            flex: 1;
        }
    }

    .search-wrapper {
        position: relative;

        input {
            &.ng-valid.ng-dirty {
                border: none;
            }

            padding: 10px 35px 10px 10px;
            font-size: 15px;
        }

        .clear-search {
            position: absolute;
            top: 50%;
            right: 11px;
            margin: -10px 0 0 0;
            padding: 0;
            border: none;
            background: none;
        }
    }

    ul {
        flex: 1;
        margin: 0;
        padding: 0;
        list-style: none;
        overflow-y: auto;
    }

    li {
        cursor: pointer;
        padding: 10px 10px;
        position: relative;
        margin: 0;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);

        &.disabled {
            opacity: 0.4;
        }

        p {
            margin: 0;
        }

        .subtitle {
            font-size: 12px;
            color: #303030;
        }

        .prefix {
            padding-right: 5px;
            font-size: 18px;
        }

        &.selected {
            background: rgba(0, 0, 0, 0.1);
        }


        &.in-use {
            p {
                color: var(--general-text-inactive-color);
            }
        }

        .in-use-label {
            position: absolute;
            top: var(--spacing);
            right: 10px;
        }
    }
}

lazy-datalist {
    position: relative;

    &.ng-invalid:not(.ng-pristine) .lazy-datalist-btn {
        border: 1px solid var(--warning-color) !important;
    }

    &.ng-valid.ng-dirty .lazy-datalist-btn {
        border: 1px solid var(--confirmation-color) !important;
    }

    .lazy-datalist-selector {
        position: relative;
        width: 100%;

        .lazy-datalist-btn {
            display: flex;
            flex-direction: row;
            align-items: center;
            position: relative;

            width: 100%;
            min-height: 45px;
            box-sizing: border-box;

            padding: 10px;

            color: var(--general-text-color);
            background: var(--background-highlight-color);
            border-radius: var(--general-border-radius);
            border: 1px solid transparent;

            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            text-align: left;
            cursor: pointer;

            &:hover {
                border: 1px solid var(--general-border-color);
            }

            &:focus {
                border: 1px solid var(--general-text-inactive-color);
            }

            > span, > p {
                &.btn-label {
                    width: 200px;
                    max-width: 45%;
                    font-weight: 600;
                    color: var(--general-text-color);
                    flex: 1 0 auto;
                    font-size: 14px;
                    padding-right: 8px;
                }

                display: block;
                flex: 1 1 auto;
                padding: 0 30px 0 8px;
                word-wrap: break-word;
                position: relative;
                min-width: 0;
                white-space: normal;
                color: var(--general-text-inactive-color);
                font-size: 13px;
                margin: 0;

                > span {
                    display: block;
                    width: 100%;
                }

                .subtitle {
                    font-size: 12px;
                    color: inherit;
                }
            }
        }

        &.has-data .lazy-datalist-btn {
            > span, > p {
                color: #333;
                font-weight: 600;

                > span:not(:first-child) {
                    font-weight: normal;
                    font-size: 12px;
                    margin-top: 1px;
                }
            }
        }
    }

    .inline-search {
        position: relative;

        .inline-search-wrapper {
            width: 100%;
            display: flex;
        }

        .input-wrapper {
            position: relative;
            flex: 1;

            .open-menu {
                position: absolute;
                right: 0;
                top: 0;
                font-size: 20px;
                padding: 8px;
            }
        }

        .info {
            padding-top: 10px;
            display: flex;
            align-items: center;

            .label {
                padding: 0 10px;
                color: @clr-warning;
            }

            .icon {
                color: @clr-warning;
            }

            &.selected {
                .label {
                    color: @clr-confirmation;
                }

                .icon {
                    color: @clr-confirmation;
                }
            }

            button {
                height: auto;
                line-height: 1.5;
            }
        }

        .predictions {
            border: 1px solid ~'rgba(var(--general-text-color-rgb), .1)';
            position: absolute;
            z-index: 100;
            top: 40px;
            left: 0;
            width: 100%;
            max-height: 120px;
            overflow-y: auto;
            background: white;

            ul {
                list-style: none;
                margin: 0;
                padding: 0;

                li {
                    padding: 10px;
                    border-bottom: 1px solid ~'rgba(var(--general-text-color-rgb), .1)';


                    &:last-of-type {
                        border-bottom: none;
                    }
                }

                p {
                    margin: 0;
                }
            }
        }
    }
}

create-data-list-item {
    .popup-wrapper {
        z-index: 205;
    }
}
