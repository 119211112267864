timer {
    display: flex;
    justify-content: center;
    padding: var(--spacing) 0 var(--s-spacing);

    .timer-container {
        display: flex;
        align-items: center;
        gap: calc(var(--m-spacing) / 2);

        padding: var(--m-spacing);

        border: 1px solid var(--general-border-color);
        border-radius: var(--general-border-radius);

        color: var(--warning-color);
        background-color: var(--general-background-color);

        .time-icon {
            padding: 0 3px;
            font-size: 14px;
        }

        .time-value {
            min-width: 60px;
            font-size: 13px;
            font-weight: 700;
            line-height: 130%;
        }
    }

    .high-alert {
        color: var(--text-on-highlight-color);
        background-color: var(--warning-color);
    }
}