@import "../Import.less";

.ios11-upload-warning-modal {

    .close {
        position: absolute;
        top: 0;
        right: 0;
        padding: 5px 10px;
        font-size: 26px;
        color: white;
    }

    .modal .modal-content {
        left: 10px;
        right: 10px;
        border-radius: 0;

        .modal-top {
            display: none;
        }

        .modal-body {
            padding: 0;

            h2 {
                font-size: 20px;
                margin: 0;
            }
        }
    }

    .ios11-upload-warning-modal-image {
        img {
            max-width: 100%;
        }
    }
    .ios11-upload-warning-modal-content {
        padding: 25px 20px;
    }
}
