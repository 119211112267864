@import "../Import.less";

.skillstown-module {
    background-color: #D7F6F3;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    .spinner{
        height: 40px;
        width: 40px;
    }

    .skillstown-module-inner {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        width: 100%;
        position: relative;
        z-index: 2;
        min-height: 200px;
        box-sizing: border-box;
        padding: 0 15px;
        text-align: center;

        svg {
            margin-bottom: 25px;
        }
    }

    &:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 1;
        opacity: .1;
    }
}
