@import '../Import.less';

@media @tablet-max {
    .desktop-show, .desktop-show-inline-block, .desktop-show-flex {
        display: none!important;
    }
}

@media @desktop-up {
    .desktop-hide {
        display: none!important;
    }
    .desktop-show {
        display: block!important;
    }
    .desktop-show-inline-block {
        display: inline-block!important;
    }
    .desktop-show-flex {
        display: flex!important;
    }
}