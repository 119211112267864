fillings-overview {
    .popup-content-inner.popup-content-inner {
        justify-content: space-between;
        padding: 0;
    }
    .overview-wrapper {
        text-align: center;
    }

    .create {
        box-sizing: border-box;
        height: 46px;
        font-weight: bold;
        font-size: 15px;
        width: 90%;
        margin-top: 20px;
    }
    .footer {
        text-align: center;
        .finish {
            width: 50%;
            height: 46px;

            @media @mobile-max {
                width: 100%;
            }
            @media @tablet-up {
                margin-bottom: 10px;
            }
        }
    }
}

fillings-overview-content {
    display: block;
    overflow-x: auto;
    
    table {
        border-collapse: collapse;
        border-bottom: 1px solid @clr-borders;
        margin-top: 10px;

        thead {
            border-bottom: 1px solid @clr-borders;
        }
        th {
            padding: 5px 4px;
            white-space: nowrap;
            position: relative;
            overflow: hidden;
            text-overflow: ellipsis;
            text-align: left;
            font-size: 13px;
            font-weight: bold;

            &.title {
                padding: 10px 0;
                font-size: 15px;
                font-weight: 600;
            }

            &:first-child {
                padding-left: 8px;
            }
        }
        tbody {
            tr:nth-child(even) {
                background: @clr-background;
            }
            td {
                text-align: left;
                padding: 10px 4px;
                font-size: 14px;
                &:first-child {
                    padding-left: 8px;
                }
                .field-tags div {
                    vertical-align: top;
                    margin: 0 5px 5px 0;
                    box-sizing: border-box;
                    font-size: 13px;
                }

                p {
                    margin: 0;
                }
            }
        }
        .delete {
            color: @clr-warning;
            font-size: 13px;
        }
    }
}
