task-details {
    display: flex;
    min-height: 100%;
    flex-direction: column;
    background: var(--general-background-color);

    > header {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        padding: 15px;
        min-height: 220px !important;
        box-sizing: border-box;
        background: ~'rgba(var(--general-text-color-rgb), .06)';

        .status {
            opacity: 0.5;
        }

        .status-icon {
            display: none;
            min-width: 22px;
            min-height: 22px;
            line-height: 22px;
            box-sizing: border-box;
            border-radius: 50%;
            border: 1px solid ~'rgba(var(--general-text-color-rgb), .1)';
            margin-right: 10px;
            justify-content: center;
            align-items: center;
            color: white;

            rl-icon {
                font-size: 14px;
            }
        }

        &.in-progress {
            .status-icon {
                display: none;
            }
        }

        &.completed {
            background: ~'rgba(var(--confirmation-color-rgb), .2)';

            .status {
                opacity: 1;
            }

            h1 {
                span {
                    opacity: 0.5;
                }

                .title {
                    text-decoration: line-through;
                }
            }


            .status {
                color: var(--confirmation-color);

                .status-icon {
                    display: inline-flex;
                    background: var(--confirmation-color);

                    rl-icon:not([icon="save"]) {
                        display: none;
                    }
                }
            }
        }

        &.overdue {
            background: ~'rgba(var(--warning-color-rgb), .2)';

            .status {
                color: var(--warning-color);

                .status-icon {
                    display: inline-flex;
                    background: var(--warning-color);

                    rl-icon:not([icon="close"]) {
                        display: none;
                    }
                }
            }
        }

        &.due-soon {
            background: ~'rgba(var(--alert-color-rgb), .2)';

            .status {
                color: var(--alert-color);

                .status-icon {
                    display: inline-flex;
                    background: var(--alert-color);

                    rl-icon:not([icon="exclamation"]) {
                        display: none;
                    }
                }
            }
        }

        .top-buttons {
            display: flex;
            justify-content: space-between;
            width: 100%;
        }

        .info {
            width: 100%;

            .status {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                font-size: 13px;
                font-weight: bold;
            }

            .status-placeholder {
                display: inline-block;
                height: 22px;
                width: 140px;
                border-radius: 4px;
            }

            .bottom {
                display: flex;
                align-items: flex-end;
                justify-content: space-between;
            }

            .title-placeholder {
                display: inline-block;
                height: 24px;
                width: 170px;
                border-radius: 4px;
            }

            .assignees {
                display: flex;

                &:disabled {
                    opacity: .5;
                }

                span {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    color: var(--text-on-white-color);
                    background: var(--white-color);
                    box-sizing: border-box;
                    box-shadow: 0 6px 7px 0 ~'rgba(var(--general-text-color-rgb), .1)';
                    border: 1px solid var(--white-color);
                    background-position: center center;
                    background-size: cover;
                    background-repeat: no-repeat;

                    &:not(:last-of-type) {
                        margin-right: -10px;
                    }

                    rl-icon {
                        color: var(--text-on-white-color);
                        font-size: 19px;
                        margin: 0;
                    }
                }
            }
        }

        button {
            padding: 0;
            width: auto;
            color: var(--general-text-color);

            rl-icon {
                font-size: 26px;
                margin-left: -5px;
            }
        }

        h1 {
            margin: 0;
            padding: 10px 10px 0 0;
            width: 100%;
            font-size: initial;
            word-break: break-word;

            span {
                font-size: 20px;
            }

            rl-icon {
                opacity: 0.5;
                margin-left: 10px;
            }
        }
        
        .header-details {
            margin: 10px 0;
            color: var(--general-text-color);
        }
        
        .details-dot-separator {
            vertical-align: middle;
            font-size: 2px;
            padding: 0 5px;
        }
    }

    .statistics {
        padding: 15px;

        .statistics-columns {
            padding: 15px;
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: space-between;
            border: 1px solid ~'rgba(var(--general-text-color-rgb), 0.1)';
            border-radius: 10px;
            cursor: pointer;

            .statistics-column {
                width: 50%;
                text-align: center;
            }

            h4 {
                margin: 0;
            }

            .rl-headline-placeholder {
                width: 40px;
                position: relative;
                top: 3px;
            }

            .status-text {
                margin-bottom: 5px;

                .status-text-placeholder {
                    height: 16px;
                    width: 80px;
                    display: inline-block;
                    border-radius: 4px;
                    vertical-align: middle;
                }
            }

            .status-icon {
                display: inline-flex;
                background: var(--confirmation-color);
                width: 22px;
                min-width: 22px;
                height: 22px;
                min-height: 22px;
                line-height: 22px;
                box-sizing: border-box;
                border-radius: 50%;
                border: 1px solid ~'rgba(var(--general-text-color-rgb), 0.1)';
                justify-content: center;
                align-items: center;
                color: white;

                &.overdue {
                    background: var(--warning-color);
                }

                &.in-progress {
                    background: none;

                    .c100 {
                        font-size: 22px;

                        &:after {
                            background-color: white;
                        }
                    }
                }

                rl-icon {
                    font-size: 14px;
                }
            }

            .status-icon-placeholder {
                height: 22px;
                width: 22px;
                min-height: 22px;
                line-height: 22px;
                border-radius: 100%;
                display: inline-flex;
                justify-content: center;
                align-items: center;
            }
        }
    }

    .details {
        padding: 20px 15px 10px 15px;
        flex: 1;
        display: flex;
        flex-direction: column;
        background: var(--general-background-color);

        .details-row-placeholder {
            display: block;
            height: 30px;
            min-width: 10px;
            border-radius: 4px;
            margin-bottom: 10px;
        }

        rl-rich-preview {
            position: relative;

            .content.loading {
                width: 100%;
            }
        }

        translation-button {
            margin-bottom: 30px;
        }
    }

    .title-expandable {
        display: flex;
        align-items: center;

        .title-content {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .empty-comments {
                opacity: .5;
            }

            rl-icon {
                color: var(--general-text-color);
                opacity: .5;
                font-size: inherit;
            }
        }
    }

    .detail-item {
        display: flex;
        padding: 10px 0;
        align-items: center;
        width: 100%;

        > rl-icon {
            margin-right: 15px;
        }
    }
    
    .lock-by-text {
        > span {
            padding-right: 15px;
        }
    }

    .categories {
        rl-icon {
            margin-top: 2.5px;
            margin-bottom: auto;
        }

        ul {
            display: flex;
            flex-wrap: wrap;
            margin: -10px 0 0 0;
            padding: 0;

            li {
                padding: 0 10px;
                height: 24px;
                line-height: 24px;
                list-style: none;
                margin-top: 10px;
                font-size: 13px;
                font-weight: 500;
                border-radius: var(--general-border-radius);

                &:not(:last-of-type) {
                    margin-right: 10px;
                }
            }
        }
    }

    .add-subtask {
        margin: 10px 0;
        height: 50px;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        cursor: pointer;
        border: 1px solid rgba(var(--general-text-color-rgb), 0.1);
        border-radius: 10px;

        &[disabled] {
            cursor: not-allowed;
            opacity: 0.5;
        }

        rl-icon {
            margin: 0 10px;
            color: var(--general-text-color);

            &.info-icon {
                margin-left: auto;
            }
        }
    }

    .sorting-actions {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin: 10px 0 15px;

        .save-sorting {
            flex: 1;
            button {
                width: 100%;
                background-color: var(--highlight-color);
                color: var(--button-text-color);
                text-transform: uppercase;

                &.transparent.secondary {
                    color: var(--button-text-color);
                }
            }
        }

        .cancel-sorting {
            flex: 1;
            button {
                width: 100%;
                color: var(--warning-color);
                text-transform: uppercase;
            }
        }
    }

    .cant-complete {
        justify-content: center;
    }

    .swipe {
        span {
            text-transform: uppercase;
        }
    }

    .details-bottom {
        margin-top: auto;
    }

    .download-event-wrapper {
        text-align: center;

        .download-event {
            text-decoration: none;
        }

        .fa {
            padding-right: 10px;
        }
    }
}

completions-popup {
    .user ng-transclude rl-icon {
        color: ~'rgba(var(--general-text-color-rgb), 0.6)';
        font-size: 25px;

        &:active {
            color: ~'rgba(var(--general-text-color-rgb), 1)';
        }
    }
}

