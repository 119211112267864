reactions-list {
    display: flex;
    box-sizing: border-box;
    padding: 5px 5px 2px;

    max-height: 46px;
    line-height: 22px;
    font-size: 22px;
    border-radius: 25px;
    text-align: center;

    background: rgba(255, 255, 255, .9);
    box-shadow: 0 5px 10px 0 rgba(60, 72, 88, 0.1);

    .reaction-icon-wrapper {
        position: relative;
        width: 36px;
        height: 36px;
        margin: 0 5px;

        reaction-icon {
            transition: transform .2s ease;
            transform-origin: center bottom;
        }

        &.active, &:hover {
            z-index: 10;

            .reaction-label {
                visibility: visible;
                opacity: 1;
            }

            reaction-icon {
                transform: scale(1.4);
            }
        }

        .reaction-label {
            position: absolute;
            visibility: hidden;
            bottom: 100%;
            left: 50%;

            transform: translateX(-50%);
            transition: all .1s;

            box-sizing: border-box;
            width: auto;
            margin-bottom: 23px;
            padding: 0 5px;

            font-size: 12px;
            border-radius: 15px;

            background: rgba(0, 0, 0, 0.6);
            opacity: 0;
            color: white;
            text-align: center;
            white-space: nowrap;
        }
    }

    reaction-icon {
        position: absolute;
        bottom: 0;
        left: 0;

        transition: width .2s, height .2s;
        margin: 0;
        width: 100%;
        height: 100%;

        border-radius: 0;
        border: none;
        cursor: pointer;

        .reaction-like {
            font-size: 18px;
            padding-bottom: 6px;
        }
    }

    span {
        margin: 0;
        padding: 3px;
        cursor: pointer;
    }
}
