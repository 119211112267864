users-list {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: auto;
    gap: var(--spacing);

    .selected-users-wrapper {
        margin: 0;
        overflow: hidden;
        flex: initial;
    }

    rl-search {
        margin: var(--spacing) var(--spacing) 0;
    }
    
    .show-history-toggle {
        display: flex;
        align-items: center;
        gap: var(--m-spacing);
        margin: 0 var(--spacing);
        color: var(--general-text-color);

        &:hover,
        label:hover {
            cursor: pointer;
        }
    }

    .checkbox {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 0 0 auto;
        width: 1.15rem;
        height: 1.15rem;

        border: 1.5px solid var(--general-text-inactive-color);
        border-radius: 2px;
        box-sizing: border-box;
    
        rl-icon {
            display: none;
            color: var(--white-color);
        }
    
        &.selected {
            background: var(--highlight-color);
            border-color: var(--highlight-color);
    
            rl-icon {
                display: inline-flex;
                font-size: 0.8rem;
            }
        }
    }

    .selected-users {
        display: flex;
        flex-wrap: nowrap;
        gap: 2px;

        margin: 0;
        padding: var(--s-spacing) var(--spacing);
        list-style: none;

        overflow-x: auto;
        -webkit-overflow-scrolling: touch;

        .remove-user {
            position: absolute;
            top: 0;
            right: 0;
            width: 1.35rem;
            height: 1.35rem;
            padding: 0;
            z-index: 1;

            color: var(--text-on-white-color);
            background: var(--general-background-color);
            border-radius: 50%;
            box-shadow: @shadow-box;

            display: grid;
            place-content: center;

            rl-icon {
                font-size: 1rem;
            }
        }

        li {
            width: 86px;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: var(--s-spacing);
            flex: 0 0 auto;
        }

        .profile-and-remove-btn-wrapper {
            position: relative;
            
            profile-image {
                .leave-icon {
                    width: 1.35rem;
                    height: 1.35rem;
                    font-size: 1.35rem;
                    bottom: 0;
                    right: 0;
                }
            }
        }

        profile-image {
            padding-right: 0;

            .profile-image {
                width: 3.5rem;
                height: 3.5rem;
                vertical-align: top;
            }
        }

        .name {
            max-width: 86px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-size: 0.7rem;
            color: var(--general-text-color);
        }
    }

    .user-list-wrapper {
        display: flex;
        flex: 1;
        flex-direction: column;
        overflow-y: auto;
        background: var(--general-background-color);
        padding: 0 var(--spacing);
    }

    .divider {
        margin: 0 var(--m-spacing) 5px;
        font-size: 0.75rem;
        text-transform: uppercase;
    }

    .user-with-menu-container{
        display: flex;
        margin-bottom: 5px;
    }

    .menu-icon {
        transform: rotate(90deg);
        &:hover {
            cursor: pointer;
        }
    }

    .user {
        flex: 1 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 44px;
    }

    .multiple {
        .user {
            &:hover {
                cursor: pointer;
            }
        }
    }

    .user-info {
        display: flex;
        align-items: flex-start;

        .profile-image-wrapper {
            position: relative;
            margin-right: var(--s-spacing);

            .status {
                position: absolute;
                bottom: -3px;
                right: 8px;

                width: 18px;
                height: 18px;
                border-radius: 50%;
                color: var(--white-color);
                box-shadow: 0 2px 4px 0 ~'rgba(var(--general-text-color-rgb), 0.1)';
                font-size: 10px;

                &.completed {
                    background: var(--confirmation-color);
                }
            }
        }   
        
        .blocked-icon {
            width: 0.9rem;
            height: 0.9rem;
            position: absolute;
            right: 0;
            bottom: 0;
            background-color: var(--warning-color);
            border-radius: 50%;
            color: var(--white-color);
            display: grid;
            place-content: center;
            
            [icon="ban"] {
                font-size: 0.5rem;
            }
        }

        profile-image {
            display: block;
            background-color: var(--general-background-color);
            border-radius: 50%;
            padding-right: 0;

            .leave-icon {
                bottom: 0;
                right: 0;
            }

            .profile-image {
                width: 2.25rem;
                height: 2.25rem;
                vertical-align: top;
            }

            &.blocked {
                .profile-image {
                    opacity: 0.5;
                }
            }
        }

        .profile-info {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            .name {
                display: flex;
                align-items: center;
                font-size: 1rem;
                font-weight: 700;
                color: var(--general-text-color);

                & span {
                    display: inline-block;
                    word-break: break-all;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: calc(100vw - 160px);
                }

                [icon="crown"] {
                    margin-left: var(--s-spacing);                        
                    font-size: 0.65rem;
                    color: var(--alert-color);
                }

                &.blocked {
                    color: var(--general-text-inactive-color);

                    [icon="crown"] {
                        color: var(--general-text-inactive-color);
                    }
                }
            }

            .department, .date-created {
                font-size: 0.8rem;
                color: var(--general-text-inactive-color);
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: calc(100vw - 160px);
                word-break: break-all;
                display: inline-block;
            }

            .blocked-label {
                font-size: 0.8rem;
                font-weight: bold;
                color: var(--warning-color);
            }
        }
    }

    .spinner-wrapper {
        display: flex;
        flex: 1;
        justify-content: center;
        align-items: center;

        .spinner {
            width: 50px;
            height: 50px;
        }
    }

    .no-users-found {
        display: flex;
        flex-direction: column;
        flex: 1;
        align-items: center;
        justify-content: center;

        icon {
            width: 75px;
            height: 75px;
        }

        h2 {
            margin: var(--s-spacing) 0;

            font-size: 1.15rem;
            font-weight: bold;
            color: @clr-bodytext;
        }
    }
}