scroll-shadow {
    flex: 1;
    position: relative;
    
    .gradient {
        content: "";
        position: absolute;
        box-sizing: border-box;
        z-index: 99;
        pointer-events: none;
    }

    &[direction="vertical"] {
        .gradient {
            height: 40px;
            width: 100%;
            left: 0;

            &.first {
                top: 0;
                background-image: linear-gradient(to top, rgba(255, 255, 255, 0), var(--general-background-color));
            }

            &.last {
                bottom: 0;
                background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), var(--general-background-color));
            }
        }
    }

    &[direction="horizontal"] {
        .gradient {
            height: 100%;
            width: 40px;
            top: 0;

            &.first {
                left: 0;
                background-image: linear-gradient(to left, rgba(255, 255, 255, 0), var(--general-background-color));
            }

            &.last {
                right: 0;
                background-image: linear-gradient(to right, rgba(255, 255, 255, 0), var(--general-background-color));
            }
        }
    }
}

