@import "../../Import.less";
@import "_WalkthroughVariables.less";

.walkthrough-slide-nativedownload {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    overflow-y: auto;
    overflow-x: hidden;

    .slide-full-width {
        width: 100%;
    }

    .slide-title,
    .slide-body,
    .slide-media {
        margin: 15px 0 0 0;

        &:first-child {
            margin-top: 0;
        }
    }

    .slide-title,
    .slide-body {
        padding-left: 15px;
        padding-right: 15px;
        text-align: left;

        &:first-child {
            padding-top: 15px;
        }
    }

    .slide-title {
        font-size: 22px;
    }

    .slide-body {
        font-size: 17px;
    }

    .slide-media {
        width: 100%;
    }

    .native-download-buttons {
        padding: 25px 15px 25px 15px;
        display: block;
        box-sizing: border-box;

        .btn,
        a {
            display: block;
            cursor: pointer;
            border: 1px solid var(--button-background-color);
            height: 40px;
            line-height: 40px;
            box-sizing: border-box;
            border-radius: 0;
            text-align: center;
            text-transform: uppercase;
            width: 100%;

            &:hover {
                opacity: .8;
            }
        }

        .btn {
            background: var(--button-background-color);
            color: var(--button-text-color);

            i {
                font-size: 20px;
                margin-right: 10px;
                position: relative;
                top: 1px;
            }
        }

        a {
            background: none;
            color: var(--button-background-color);
        }

        .or-divider {
            display: block;
            text-transform: uppercase;
            text-align: center;
            line-height: 40px;
            width: 100%;
        }
    }
}

.walkthrough-slide-nativedownload-background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    overflow: hidden;
    background-position: center center;
    background-size: cover;
}

