overview-table {
    .table-content {
        display: flex;
        flex: 1;
        overflow-y: auto;
        flex-direction: column;
    }

    .course {
        flex-direction: column;
        border-bottom: 1px solid @clr-borders;
        position: relative;

        &.opened {
            &:before {
                content: '';
                display: block;
                position: absolute;
                z-index: -1;
                top: 23px;
                left: 22px;
                bottom: 23px;
                width: 1px;
                background: @clr-bodytext;
            }

            > .inner-row {
                .name {
                    font-weight: bold;
                }
            }
        }

        &:not(.opened) {
            .modules {
                display: none;
            }
        }

        .name {
            display: flex;
            align-items: flex-start;

            .redo {
                background: var(--general-border-color);
                padding: 3px 5px;
                margin-left: 5px;

                rl-icon {
                    font-size: 15px;
                }
            }
        }

    }

    .module {
        &:not(.opened) {
            .lessons {
                display: none;
            }
        }
    }

    .lesson {
        position: relative;

        &:before {
            content: '';
            display: block;
            position: absolute;
            top: 50%;
            left: 23px;
            height: 1px;
            width: 7px;
            background: @clr-bodytext;
        }

        .name {
            padding-left: 19px;
        }
    }

    .inner-row {
        display: flex;
        width: 100%;
        padding: 15px 10px 15px 15px;
        box-sizing: border-box;
        background: transparent;

        .fal {
            line-height: 13px;
            background: white;
            padding-left: 1px;
            padding-right: 5px;
        }
    }
}
