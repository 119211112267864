@import "../Import.less";

@card-footer-actions-height: 46px;
@card-footer-actions-padding: 7.5px;

.card-footer {
    padding: 0 10px;

    @media @desktop-up {
        padding: 0 15px;
    }

    .card-footer-actions {
        display: flex;
        flex-direction: column;

        .card-footer-actions-icons,
        .card-footer-actions-texts {
            min-height: @card-footer-actions-height;
            max-height: @card-footer-actions-height;
            width: 100%;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;

            button {
                min-height: @card-footer-actions-height;
                padding: 0 7.5px;
                box-sizing: border-box;
                color: var(--general-text-color);
            }

            content-post-total-swipes, content-total-views {
                button {
                    color: var(--general-text-color);
                }
            }
        }

        .card-footer-actions-icons {
            justify-content: space-around;
            text-align: left;
            border-top: 1px solid #EBECEE;
            position: relative;

            .reaction-message {
                position: absolute;
                top: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                bottom: 0;
                left: 0;
                right: 0;
                background: var(--general-background-color);
                z-index: 7;
                user-select: none;
                
                @media @desktop-up {
                    display: none;
                }
            }

            button {
                line-height: 21px;
                width: 33.33%;
                box-sizing: border-box;
                padding: 0;
                margin: 0;
                font-size: 13px;
                text-transform: uppercase;

                @media @desktop-up {
                    width: auto;
                    padding: 0 15px;
                }
            }

            rl-icon {
                font-size: 17px;
                display: inline-flex;
            }

            reaction-button {
                width: 33.33%;
                text-align: center;

                @media @tablet-max {
                    .btn-reaction {
                        position: static;
                    }

                    .reactions-list {
                        left: 50%;
                        transform: translateX(-50%);
                    }
                }

                @media @desktop-up {
                    width: auto;
                }

                button {
                    width: 100%;
                    color: var(--general-text-color);
                    user-select: none;
                }
            }
        }

        .card-footer-actions-texts {
            justify-content: space-around;
            text-align: right;
            align-items: normal;

            > button {
                display: flex;
                align-items: center;
                gap: 5px;
                font-size: 13px;
            }

            content-post-total-swipes button, content-total-views button {
                display: flex;
                align-items: center;
                gap: 5px;
                height: 100%;
                width: 100%;
                font-size: 13px;
            }

            rl-icon {
                font-size: 17px;
            }
        }

    }
}