@import "Groups/Groups";
@import "LiveTile/SocialLiveTileComponent";
@import "SocialWall/SocialWall";
@import "SocialWall/Create/SocialPostCreate";
@import "SocialWall/Hashtags/SocialHashtags";
@import "SocialWall/Post/SocialPostPage";
@import "SocialWall/Post/SocialPost";
@import "SocialWall/Post/Poll/SocialPoll";
@import "SocialWall/Post/SocialCommentsPopup/SocialCommentsPopup";
@import "SocialWall/HastagSuggestions/HastagSuggestions";

.base-view.base-view-social {
    background: var(--background-highlight-color);
    height: auto;
}

.menu-opened {
    social .search-wrapper.sticky {
        top: auto !important;
    }
}

social {
    display: block;

    .search-wrapper {
        position: relative;

        &.sticky {
            z-index: 10;
            position: sticky;
            top: 55px;
            background: var(--background-highlight-color);

            @media @desktop-up {
                top: 60px;
            }
        }

        module-search-filters {
            @media @tablet-max {
                padding-left: var(--spacing);
                padding-right: var(--spacing);
            }
        }
    }

    .social-content {
        padding-top: var(--m-spacing);

        &.unset-padding {
            padding-top: 0;
        }

        @media @tablet-max {
            padding-left: var(--spacing);
            padding-right: var(--spacing);
        }

        @media @desktop-up {
            padding-bottom: var(--spacing);
        }

        users-list {
            margin-top: 10px;
            height: calc(100vh - 216px);

            @media @desktop-up {
                height: calc(100vh - 242px);
            }

            .user-list-wrapper {
                background: transparent;
            }

            .divider {
                padding: 15px 0 0 10px;

                font-weight: 400;
                font-size: 11px;
                line-height: 130%;
                color: var(--general-text-inactive-color);
            }

            .user {
                padding: var(--s-spacing) 0;
            }

            .spinner-wrapper {
                opacity: .4;
            }
        }

        > .spinner {
            z-index: 100;
            position: fixed;
            top: 50%;
            left: 50%;
            width: 50px;
            height: 50px;
            margin-left: -25px;
            margin-top: -25px;
            border-color: var(--general-text-color);

            @media @desktop-up {
                left: calc(50% + 140px);
            }

            @media @desktop-big-up {
                left: calc(50% + 180px);
            }
        }
    }
}
