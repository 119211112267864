survey-filters-popup {
    .popup-footer popup-footer {
        display: flex;
        justify-content: space-between;

        gap: var(--spacing);
        padding: var(--spacing);

        rl-button {
            flex: 1;
        }
    }
}