content-library {
    .search-wrapper {
        position: relative;

        &.sticky {
            z-index: 30;
            position: sticky;
            top: 55px;
            background: var(--background-highlight-color);

            @media @desktop-up {
                top: 60px;
            }
        }

        module-search-filters {
            @media @tablet-max {
                padding-left: var(--m-spacing);
                padding-right: var(--m-spacing);
            }
        }
    }
    
    .search-filters-wrapper {
        action-sheet-custom .actionsheet {
            z-index: 11;
        }
    }

    .content-wrapper {
        padding: var(--m-spacing);

        &.unset-padding {
            padding-top: 0;
        }
    }

    .spinner {
        position: absolute;
        top: 50%;
        left: 50%;
    }

    .content-item-placeholder {
        display: block;
        margin: var(--spacing) 0;
        background: var(--general-background-color);
        border-radius: var(--general-border-radius);

        rl-loading-placeholder {
            display: block;
            box-sizing: border-box;
            padding: 15px;

            .placeholder-shimmer {
                height: 19px;
            }
        }
    }
}

// Content list and item common styles
content-library-list {
    .app-link-url {
        position: absolute;
        overflow: hidden;
        clip: rect(0 0 0 0);
        height: 1px;
        width: 1px;
        margin: -1px;
        padding: 0;
        border: 0;
        user-select: none;
    }

    .head {
        position: relative;

        &:last-of-type {
            margin-bottom: 0;
        }

        &:first-of-type {
            margin-top: 0;
        }

        .left, .right {
            display: flex;
            align-items: center;
            padding: 15px 15px 0 15px;
        }

        .right {
            justify-content: flex-end;
        }

        .content-library-options {
            padding: 0;
            text-align: center;
            color: var(--white-color);
            background: transparent;
            border: none;
            z-index: 1;

            rl-icon {
                font-size: 20px;
            }
        }
    }
    
    .no-content {
        left: unset;
        margin-top: 90px;
        position: relative;
        right: unset;
        top: unset;
    }
}

content-library-list,
content-library-item {
    .separator {
        padding: 0 3px;
    }

    .card-item-progress {
        height: 4px;
        position: relative;
        background: rgba(255, 255, 255, 0.4);
        backdrop-filter: blur(24px);

        .fill-progress {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 0;
            background: var(--highlight-color);
            content: " ";
            height: 4px;
        }
    }

    .head {
        height: 230px;
        z-index: 10;

        margin: calc(var(--spacing) * 2) 0;
        overflow: hidden;
        background-color: rgba(0, 0, 0, 0.6);
        color: var(--white-color);

        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;


        &.fixed-top {
            height: auto;
            background-color: var(--header-background-color);
            color: var(--header-text-color);

            .caption {
                display: none;
            }

            .card-content-fixed {
                display: block
            }
        }

        &:not(.fixed-top) {
            .caption {
                display: block;
            }

            .card-content-fixed {
                display: none;
            }
        }


        @media @desktop-up {
            height: 330px;
        }

        .caption {
            position: absolute;
            width: 100%;
            right: 0;
            left: 0;
            bottom: 0;
        }

        .card-head-top,
        .card-head-bottom {
            padding: 3vw;
        }

        .card-head-top {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;

            h3 {
                margin-bottom: 0;
                text-transform: uppercase;
                text-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
            }
        }

        .card-content-fixed {
            padding: 3vw;
            background: black;

            h3 {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                margin: 0 25px 5px 0;
            }

            .content-description {
                margin: 8px 0 2px;
                white-space: nowrap;
                font-size: 14px;
                text-overflow: ellipsis;
                overflow: hidden;
                display: flex;
                align-items: baseline;

                .content-title-link {
                    max-width: calc(~'100% - 80px');
                    overflow: hidden;
                    text-overflow: ellipsis;
                    padding-right: 5px;
                }

                .page-icon {
                    font-size: 16px;
                }

                span {
                    padding: 0 3px;
                }
            }
        }

        .card-item-breadcrumb {
            margin-top: 10px;
            font-size: 12px;
            align-items: baseline;
            text-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);

            > div {
                display: inline-block;
                vertical-align: middle;

                &:last-of-type {
                    .separator {
                        display: none;
                    }
                }
            }
        }

        .card-head-bottom {
            background: rgba(0, 0, 0, .6);
            position: relative;
            overflow: hidden;

            .card-head-actions {
                display: flex;
                justify-content: space-between;

                button {
                    padding: 0 5px;

                    span {
                        font-size: 17px;
                    }

                    &.completed {
                        span {
                            font-size: 12px;
                            padding-right: 2px;
                        }
                    }
                }
            }

            .card-footer-button {
                font-size: 10px;
                text-align: center;

                button {
                    background-color: var(--highlight-color);
                    border-radius: 10px;
                    padding: 5px 16px;
                    color: var(--text-on-highlight-color);
                    font-size: 12px;
                    text-transform: uppercase;
                    margin: 0 3px 5px;
                    font-weight: 600;
                }

                .read-progress {
                    color: var(--white-color);
                }
            }

            .content-item-comments-label {
                font-size: 12px;
                margin-top: 5px;
                margin-left: 5px;
            }

            button {
                color: var(--white-color);
            }
        }

    }
}

// Content item styles
content-library-item {
    display: block;
    padding-bottom: 50px;
    background-color: white;
    -webkit-text-size-adjust: 100%;

    @media @desktop-up {
        max-width: 60vw;
        margin: 15px auto;
        padding-bottom: 0;

    }
    
    translation-button {
        padding-top: var(--spacing);
        padding-left: 3vw;
    }

    .go-to-feed {
        display: block;
        padding: 10px;

        @media @desktop-up {
            padding: 10px 0;
        }
    }

    .head {
        position: sticky;
        top: 55px;
        left: 0;
        width: 100%;
        margin: 0;
        z-index: 10;

        @media @desktop-up {
            top: 60px;
        }

        @media @tablet-max {
            min-height: 230px;
            height: auto;
        }


        transition: min-height 0.2s linear;

        &.fixed-top {
            min-height: 50px;

            @media @desktop-up {
                height: auto;
            }

            .caption {
                display: none;
            }
        }

        &:not(.fixed-top) {
            .small-caption {
                display: none;
            }
        }

        .btn-close {
            position: absolute;
            right: 10px;
            top: 10px;
            margin: 0;
            padding: 0;
            border-radius: 50%;
            background: rgba(var(--header-text-color-rgb), 0.15);
            color: var(--header-text-color);
            width: 23px;
            height: 23px;
            text-align: center;
            z-index: 15;
            font-size: 16px;
            line-height: 16px;

            rl-icon {
                font-size: 15px;
            }
        }

        .small-caption {
            padding: 2vw;

            h3 {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                margin: 0 25px 5px 0;
            }

            .content-description {
                margin: 8px 0 2px;
                white-space: nowrap;
                font-size: 14px;
                text-overflow: ellipsis;
                overflow: hidden;
                display: flex;
                align-items: baseline;

                .content-title-link {
                    max-width: calc(~'100% - 80px');
                    overflow: hidden;
                    text-overflow: ellipsis;
                    padding-right: 5px;
                }

                .page-icon {
                    font-size: 16px;
                }

                > span {
                    padding: 0 3px;
                }
            }

            .table-of-contents {
                margin: 0;
                font-size: 14px;

                &.gradient {
                    .content-list ul {
                        margin-bottom: 20px;
                    }

                    &:after {
                        background: linear-gradient(to bottom, rgba(0, 0, 0, .0), var(--header-background-color) 100%);
                        content: "";
                        position: absolute;
                        bottom: 5px;
                        width: 100%;
                        height: 40px;
                        left: 0;
                        opacity: 1;
                        z-index: 90;
                        pointer-events: none;
                    }
                }

                .content-list {
                    position: relative;

                    max-height: 50vh;
                    overflow: auto;
                    @media @tablet-up {
                        max-height: 65vh;
                    }

                    .toc-progress {
                        position: absolute;
                        top: 13px;
                        left: 7px;
                        width: 2px;
                        overflow: hidden;
                        background-color: rgba(var(--header-text-color-rgb), 0.5);
                        backdrop-filter: blur(4px);

                        .fill-progress {
                            position: absolute;
                            top: 0;
                            bottom: 0;
                            height: 0;
                            left: 0;
                            width: 4px;
                            background-color: var(--highlight-color);
                        }
                    }

                    ul {
                        padding: 0;
                        margin: 0;
                        list-style: none;
                        position: relative;

                        li {
                            margin: 0;
                            text-transform: uppercase;
                            font-size: 14px;
                            padding: 12px 2px 12px 25px;
                            position: relative;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            color: rgba(var(--header-text-color-rgb), 0.7);


                            &:after {
                                position: absolute;
                                left: 2px;
                                top: 50%;
                                margin-top: -6px;
                                content: "";
                                width: 12px;
                                height: 12px;
                                background-color: rgba(var(--header-text-color-rgb), 0.5);
                                backdrop-filter: blur(4px);
                                border-radius: 50%;
                                z-index: 10;
                            }

                            &.completed {
                                color: var(--header-text-color-rgb);

                                &:after {
                                    font-family: "Font Awesome\ 6 Pro";
                                    text-align: center;
                                    content: "\f00c"; // fa-check
                                    background-color: var(--highlight-color);
                                    color: var(--text-on-highlight-color);
                                    width: 17px;
                                    height: 17px;
                                    left: 0;
                                    margin-top: -8px;
                                    font-size: 10px;
                                    line-height: 16px;
                                }
                            }

                            span {
                                padding: 1px;
                            }
                        }
                    }
                }
            }
        }
    }

    .toc-overlay {
        display: block;
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        content: " ";
        background: rgba(0, 0, 0, .7);
        z-index: -1;
        opacity: .2;
    }

    .card-item-progress {
        position: absolute;
        width: 100%;
        bottom: 0;
        left: 0;

        .fill-progress {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 0;
            background: var(--highlight-color);
            content: " ";
            height: 4px;
        }
    }

    .card-sticky-bottom {
        display: flex;
        flex-direction: column;

        .top, .bottom {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .top {
            border-bottom: 1px solid ~'rgba(var(--general-text-color-rgb), 0.1)';

            button {
                rl-icon {
                    font-size: 15px;
                }
            }
        }

        .bottom {
            button {
                rl-icon {
                    vertical-align: bottom;
                }

                > span {
                    text-transform: uppercase;
                    font-size: 13px;
                }
            }
        }

        button {
            padding: 0 10px;
            height: 50px;

            rl-icon {
                margin-right: 6px;
            }
        }
    }

    .card-item-body {
        padding-top: 20px;

        article {
            position: relative;
            margin-top: 40px;
            border-top: 3px solid var(--general-text-inactive-color);

            &:first-of-type {
                margin-top: 10px;
            }

            &.single {
                margin-top: 20px;
                border-top: none;
            }

            &:last-of-type {
                margin-bottom: 40px;
            }

            &.completed {
                border-top: 3px solid var(--highlight-color);

                .index {
                    background: var(--highlight-color);
                    color: var(--text-on-highlight-color);
                }

                h1 {
                    background: rgba(var(--highlight-color-rgb), 0.2);
                }
            }

            a {
                color: var(--highlight-color);
            }
        }

        .index {
            position: absolute;
            top: -28px;

            left: 50%;
            margin-left: -25px;

            width: 50px;
            height: 50px;

            border-radius: 50%;
            color: var(--general-background-color);
            background: var(--general-text-inactive-color);

            text-align: center;
            line-height: 50px;
            font-size: 17px;
        }

        rl-rich-preview.article-content {
            padding: 0 3vw;

            h1 {
                margin: 0 -3vw 20px;
                padding: 45px 3vw 30px;
                font-size: 30px;
                font-weight: bold;

                text-align: center;
                color: var(--general-text-color);
                background: var(--background-highlight-color);
                word-break: break-word;
            }
        }

        img {
            width: auto;
            height: auto;
            max-width: 100%;
            margin-left: auto;
            margin-right: auto;
            display: block;
        }

        .video-wrapper {
            position: relative;
            padding-bottom: 56.25%; /* 16:9 */
            height: 0;

            iframe, video {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }

        video {
            background: grey;
        }
    }

    footer {
        padding: 20px 3vw;
        text-align: center;
        background: var(--background-highlight-color);

        &.with-margin {
            margin-bottom: 53px;
        }

        .card-item-breadcrumb {
            align-items: center;
            justify-content: center;
            font-size: 12px;

            > div {
                display: inline-block;

                &:last-of-type {
                    .separator {
                        display: none;
                    }
                }
            }
        }
    }

    .next-content-link {
        background: var(--highlight-color);
        color: var(--white-color);
        padding: 3vw;

        h3 {
            font-weight: bold;
            text-transform: uppercase;
            margin: 0;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        h4 {
            margin: 0 0 5px;
        }
    }
}
