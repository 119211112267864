content-social-sharing {
    .open-sharing {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        width: 50px;
        height: 50px;
        background: var(--highlight-color);
        color: var(--white-color);
        box-shadow: 0 5px 7px 0 var(--general-border-color);
    }

    .sharing-dialog {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 201;
        display: flex;
        justify-content: center;
        align-items: center;

        .bg {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;

            animation-duration: 0.3s;
            background: var(--popup-overlay-bg);
        }

        .sharing-dialog-popup {
            z-index: 2;
            position: relative;
            box-sizing: border-box;

            display: flex;
            flex-direction: column;
            justify-content: space-between;

            width: 90%;
            max-width: 360px;

            padding: 20px 15px 15px 15px;
            text-align: center;

            background: var(--white-color);
            animation-duration: 0.4s;

            > span {
                color: var(--text-on-white-color);
            }

            p {
                margin: 0;
                padding-bottom: 10px;

                text-align: left;
                font-size: 13px;
                color: var(--text-on-white-color);
            }

            .platforms {
                display: flex;
                flex-direction: column;
                justify-content: space-around;

                padding: 20px 0 7px 0;

                > button {
                    position: relative;
                    padding: 0;
                    height: 50px;

                    text-align: center;

                    &:first-of-type {
                        margin-bottom: 10px;
                    }

                    &.linkedin {
                        background: #0073b1;
                    }

                    &.facebook {
                        background: #4267B2;
                    }

                    &[disabled] {
                        opacity: 0.6;
                    }

                    rl-icon {
                        position: absolute;
                        top: 0;
                        left: 0;
                        padding: 10px;

                        border-radius: 5px;
                        font-size: 30px;
                        color: var(--white-color);
                    }

                    > span {
                        flex: 1;
                        font-weight: 500;
                        color: var(--white-color);
                    }
                }
            }

            rl-button {
                button {
                    color: var(--text-on-white-color);
                    opacity: 1;
                }
            }
        }
    }

    .linkedin-popup {
        .popup-content-inner {
            padding: 15px;
            background: var(--background-highlight-color);

            .content {
                padding: 10px;
                margin-bottom: 20px;
                box-shadow: 0 6px 7px 0 var(--general-border-color);
                background: var(--general-background-color);

                .profile {
                    display: flex;
                    margin-bottom: 10px;

                    profile-image {
                        padding-right: 10px;
                    }

                    .profile-image {
                        width: 35px;
                        height: 35px;
                    }

                    h2 {
                        margin: 0;
                        padding: 0;
                        font-weight: 500;
                        text-transform: none;
                    }

                    p {
                        margin: 0;
                    }
                }

                textarea {
                    min-height: 100px;
                    width: 100%;
                    padding: 0;

                    background: transparent;
                    border: none;

                    &::placeholder {
                        color: ~'rgba(var(--general-text-color-rgb), 0.6)';
                    }
                }
            }

            .share-content {
                border: 1px dashed var(--general-border-color);
                background: var(--background-highlight-color);

                padding-bottom: 20px;

                h2 {
                    margin: 0;
                    padding: 15px 10px 7px 10px;
                    text-transform: none;
                    word-wrap: break-word;
                }

                p {
                    margin: 0;
                    padding: 0 10px;

                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;

                    font-size: 13px;
                }

                .image {
                    height: 170px;
                    background-position: center;
                    background-size: cover;
                }
            }

            .share-linkedin {
                margin-top: auto;
                position: sticky;
                bottom: 0;
            }
        }
    }

    .modal-loader {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
    }
}