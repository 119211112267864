department-hierarchy {
    button {
        justify-content: center;
        align-items: center;
        padding: 0;
        width: 20px;
        height: 20px;
        text-align: center;
        background: rgba(var(--general-text-color-rgb), 0.1);
        color: rgba(var(--general-text-color-rgb), 0.5);
        border-radius: 4px;

        rl-icon {
            margin-top: -10px;
            font-size: 11px;
        }
    }

    .department-hierarchy-component {
        margin-top: 10px;
    }

    .department {
        display: inline-flex;
        width: 100%;
        box-sizing: border-box;
        
        height: 41px;
        padding: 2px 0;
        cursor: pointer;

        &.no-alias-height {
            height: 28px;
        }
    }

    .left {
        margin-right: 10px;

        &.no-children {
            margin-right: 15px;

            rl-icon {
                font-size: 8px;
                margin-left: 5px;
                color: rgba(var(--general-text-color-rgb), 0.1);
            }
        }
    }

    .right {
        display: grid;
        width: 100%;
        align-items: center;

        span {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }

        &.no-alias {
            align-items: unset;
        }
    }

    .open {
        background: var(--highlight-color);
        color: var(--text-on-highlight-color);

        rl-icon {
            transform: rotate(90deg);
        }
    }

    .rl-subheadline {
        font-size: 12px;
    }
}
