.validity-badge-background {
    position: relative;
    width: auto;
    padding: var(--s-spacing);
    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0.2;
        z-index: -1;
        border-radius: var(--general-border-radius);
    }

    &.bg-not-obtained::before {
        background-color: rgba(var(--general-text-color-rgb), 0.8);
    }

    &.bg-obtained::before {
        background: rgba(var(--confirmation-color-rgb), 0.8);
    }

    &.bg-expired::before {
        background: rgba(var(--warning-color-rgb), 0.8);
    }

    &.bg-valid-until::before {
        background: rgba(var(--confirmation-color-rgb), 0.8);
    }
    
    &.bg-deactivated::before {
        background: rgba(var(--alert-color-rgb), 0.8);
    }

    .validity-icon-and-text {
        display: flex;
        gap: calc(var(--m-spacing) * 0.5);
        justify-content: center;
        align-items: center;
        
        .validity-badge-icon-bg-obtained {
            color: var(--confirmation-color);
        }
        
        .validity-badge-icon-bg-expired {
            color: var(--warning-color);
        }

        .validity-badge-icon-bg-valid-until {
            color: var(--confirmation-color);
        }
        
        .validity-badge-icon-bg-not-obtained {
            color: var(--general-text-color);
        }

        .validity-badge-icon-bg-deactivated {
            color: var(--alert-color);
        }
    }
}

.validity-badge-text {
    opacity: 1;
}

.validity-badge-text p {
    color: var( --elevated-text-color);
    margin: 0;
    padding: 0;
    font-size: 0.8125rem;
    font-style: normal;
    font-weight: 700;
    line-height: 135%;
}