@import "../../Import.less";
@import "_WalkthroughVariables.less";

.walkthrough-slide-policy {
    .policy {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        z-index: 2;
        .flex-container();
        -webkit-transition: opacity ease-in-out 0.3s;
        transition: opacity ease-in-out 0.3s;

        &.ng-enter,
        &.ng-leave.ng-leave-active {
            opacity: 0;
        }

        &.ng-leave,
        &.ng-enter.ng-enter-active {
            opacity: 1;
        }
    }

    policy-view {
        overflow: auto;
        scrollbar-width: thin;
        scrollbar-color: rgba(0, 0, 0, 0.15) transparent;

        &.extra-bottom-margin {
            .policy-view {
                margin-bottom: 70px;
            }
        }

        .policy-view-container {
            max-height: unset;
        }
    }

    .policy-view-bottom {
        position: absolute;
        bottom: 0;
        right: 0;
        padding: var(--spacing) 0;
        margin: 0 var(--spacing);
        display: flex;
        gap: var(--spacing);
        width: calc(100% - (2 * var(--spacing)));
        box-sizing: border-box;
        background-color: var(--general-background-color);
        z-index: 5;

        .shadowed-btn {
            flex-grow: 1;
            box-shadow: 0 -20px 15px 5px var(--general-background-color);
        }

        button {
            font-size: 1rem;
            line-height: 1rem;
            text-transform: uppercase;
            min-width: unset;
            width: 100%;
            word-break: break-word;
        }

        .agree-btn {
            button {
                background-color: var(--confirmation-color);
                color: var(--white-color);
            }
        }

        .disagree-btn {
            button {
                background-color: var(--warning-color);
                color: var(--white-color);
            }
        }
    }

    tip { 
        z-index: 100;

        .tip-content {
            bottom: 45px;
            max-width: calc(50% - var(--spacing));
            width: unset;
            padding: var(--m-spacing);
            border-radius: var(--general-border-radius);
            box-shadow: 0px 7px 15px 0px var(--shadow-color);
            background-color: var(--general-text-color);
            color: var(--general-background-color);

            &:after {
                bottom: -6px;
                border-color: var(--general-text-color) transparent transparent;
                border-width: 7px 7.5px 0 7.5px;
            }
        }
    }
}