reaction-button {
    .btn-reaction {
        display: inline-block;
        position: relative;

        .reaction-icon-container {
            display: flex;
            align-items: center;
            line-height: 18px;

            reaction-icon {
                border: 0;
                margin-right: 4px;
                display: flex;
                align-items: center;
                margin-bottom: 4px;

                rl-icon {
                    .reaction-like {
                        font-size: 12px;
                        padding-bottom: 1px;
                    }
                }
            }
        }

        button {
            min-height: 46px;
        }
    }

    .reactions-list {
        position: absolute;
        z-index: 8;
        bottom: 100%;
        left: 0;
        margin-bottom: -3px;

        opacity: 0;
        transition: visibility 0s .3s, opacity .3s;
        visibility: hidden;


        @media @desktop-up {
            left: 50%;
            right: auto;
            margin-left: -120px;

            &:after {
                content: '';
                position: absolute;
                z-index: -1;
                top: 0;
                right: -20px;
                bottom: -20px;
                left: -20px;
            }
        }

        &.active {
            visibility: visible;
            opacity: 1;
            transition: visibility 0s .0s, opacity .3s;

            .reactions-overlay {
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
                position: fixed;

                @media @desktop-up {
                    display: none;
                }
            }
        }
    }

    @keyframes slideUp {
        0% {
            transform: translateY(70px);
        }

        100% {
            transform: translateY(0);
        }
    }
}
