channels-list {
    position: relative;
    display: block;

    .tab-header {
        padding: 10px 15px;
    }

    .channel-wrapper {
        position: relative;
        overflow: hidden;

        &:first-of-type {
            .channel {
                border-top: 1px solid var(--general-border-color);
            }
        }

        .channel-options {
            position: absolute;
            z-index: 10;
            top: 0;
            right: 0;
            bottom: 0;
            left: 100%;
            width: 100%;
            background: var(--general-background-color);

            display: flex;
            align-items: center;
            justify-content: flex-start;

            box-shadow: -3px 0 8px 0 rgba(60, 72, 88, 0.1);
            border-top: 1px solid var(--general-border-color);
            border-bottom: 1px solid var(--general-border-color);

            transition: transform 0.3s;

            button {
                height: 100%;
                padding: 15px;

                color: var(--general-text-color);
                font-weight: 600;

                rl-icon {
                    font-size: 17px;
                }
            }

            .delete {
                color: var(--warning-color);
            }
        }
    }

    .channel {
        display: flex;
        flex-direction: row;
        align-items: center;
        position: relative;
        padding: 15px;
        overflow: hidden;
        transition: transform 0.3s;

        &.has-new-messages {
            background: var(--background-highlight-color);
        }

        rl-icon {
            height: 45px;
            width: 20px;
            margin-left: 15px;
            opacity: 0.5;
        }

        rl-badge {
            height: 7px;
            width: 7px;
            min-width: 7px;
            position: absolute;
            top: 34px;
            left: 4px;
            z-index: 10;
        }
        
        profile-image {
            .leave-icon {
                width: 16px;
                height: 16px;
                bottom: 0;
                right: 0;
                margin-left: unset;
                opacity: 1;
            }
        }
    }

    .channel-content {
        display: flex;
        flex: 1;
        flex-direction: column;
        margin-left: 10px;
        word-break: break-all;

        &:hover {
            cursor: pointer;
        }

        .top {
            margin-bottom: 5px;
            display: flex;
            justify-content: space-between;
            align-items: baseline;

            .title {
                margin: 0;
                font-size: 15px;
                font-weight: bold;
                word-break: break-word;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 70vw;
            }

            .time {
                margin: 0;
                opacity: 0.5;
                font-size: 12px;
                word-break: break-word;
            }
        }

        .bottom {
            display: flex;
            align-items: baseline;

            .author {
                font-weight: bold;
                font-size: 12px;
                flex-shrink: 0;
                color: var(--general-text-inactive-color);
            }
            
            .message {
                margin: 0;
                opacity: 0.5;
                padding-left: 5px;
                font-size: 12px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 70vw;

                @media @desktop-up {
                    max-width: calc(~'100vw - 600px');
                }

                &:first-child {
                    padding-left: 0;
                }
            }
            
            .mute-icon {
                font-size: 10px;
                max-width: 10px;
                height: 10px;
                display: flex;
                margin-left: auto;
            }
        }
    }

    .icon-remove {
        margin: 0 10px;
        color: red;
    }

    .empty-channels-list {
        top: 30vh;
    }
}
