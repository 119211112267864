file-preview {
    display: block;

    .background {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;

        background-position: center;
        background-size: cover;

        &.no-preview {
            background: var(--background-highlight-color);
        }

        &:not(.image):after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: rgba(0, 0, 0, 0.1);
        }
    }

    &.small {
        .file-icon {
            font-size: 35px;
            
            &[icon="play"] {
                width: 50px;
                height: 50px;
                font-size: 25px;
            }
        }

        .download-icon {
            right: 5px;

            width: 16px;
            height: 16px;
            font-size: 10px;
        }

        .text {
            margin-top: 5px;
            font-size: 10px;
        }
    }

    .file {
        z-index: 2;
        position: relative;

        icon {
            position: absolute;
            top: 65%;
            left: 50%;
            transform: translate(-50%, -50%);

            color: var(--background-highlight-color);
            filter: brightness(90%);

            ng-include {
                display: block;
                font-size: 0;
            }
        }
    }

    .file-icon {
        font-size: 78px;
        color: var(--white-color);

        &[icon="play"] {
            width: 70px;
            height: 70px;
            border-radius: 50%;

            font-size: 39px;
            color: var(--general-text-color);
            background: rgba(255, 255, 255, 0.6);
            backdrop-filter: blur(24px);

            span {
                margin-left: 5px;
                opacity: 0.7;
            }
        }
    }

    .download-icon {
        position: absolute;
        right: 15px;
        bottom: -3px;

        font-size: 17px;
        background: var(--highlight-color);
        color: var(--text-on-highlight-color);

        filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.25))
    }

    .text {
        z-index: 2;
        position: relative;
        margin-top: 10px;

        display: block;
        max-width: 90%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        padding: 4px 7px;
        border-radius: 100px;

        color: var(--white-color);
        background: rgba(0, 0, 0, 0.5);
        backdrop-filter: blur(10px);

        &.count {
            text-transform: lowercase;
        }
    }
}