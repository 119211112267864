multiple-department-view-component {
    display: block;
    background: var(--general-background-color);

    overflow: hidden;
    border-radius: var(--general-border-radius);
    box-shadow: 0 7px 15px 0 var(--shadow-color);


    header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        padding: var(--m-spacing);
        border-bottom: 1px solid var(--general-border-color);

        span {
            font-size: 0.73333rem;
            font-weight: 700;
            line-height: 130%;
        }

        .sorting {
            cursor: pointer;

            rl-icon {
                color: var(--general-text-inactive-color);
            }

            .descending {
                transform: rotate(180deg);
            }
        }
    }

    .body {
        display: flex;
        flex-direction: column;
    }

    department-row {
        &:not(:last-of-type) {
            border-bottom: 1px solid var(--general-border-color);
        }

        .tree-icon {
            color: var(--general-text-inactive-color);
            margin-right: calc(var(--spacing) / 2);
        }
        
        .right {
            margin-left: auto;
            display: flex;
            
            rl-icon {
                flex-shrink: 0;
                width: 24px;
                height: 24px;
                padding: 0 var(--s-spacing);
                margin-right: calc(var(--spacing) / 2);

                font-size: 14px;
                cursor: pointer;
                border-radius: 2px;
                background: var(--general-border-color);
            }
        }

        .score {
            width: 50px;
            box-sizing: border-box;

            line-height: 24px;
            text-align: center;
            border-radius: 1.5px;
            font-size: 0.86667rem;
            font-weight: 700;

            &.negative {
                background: var(--warning-color);
                color: var(--general-background-color);
            }

            &.neutral {
                background: var(--alert-color);
                color: var(--general-text-color);
            }

            &.positive {
                background: var(--confirmation-color);
                color: var(--general-background-color);
            }
        }

        &:nth-child(even) > .row-content {
            background: var(--background-highlight-color);
        }
    }

    .row-content {
        display: flex;
        align-items: center;

        padding: var(--m-spacing);
        padding-left: calc(var(--m-spacing) + var(--s-spacing) * 2 * var(--level));
        
        &.clickable {
            cursor: pointer;
        }
    }

    .multiple-department-loader-container {
        .body {
            .row-content {
                &:nth-child(even) {
                    background: var(--background-highlight-color);

                    .placeholder-shimmer {
                        background-image: linear-gradient(to right, var(--general-background-color) 8%, var(--background-highlight-color) 18%, var(--general-background-color) 33%);
                    }
                }

                &:not(:last-of-type) {
                    border-bottom: 1px solid var(--general-border-color);
                }
            }
        }
    }
}