@import "../../Variables";

.landing-page-social {

    .title-wrapper {
        display: flex;

        rl-badge {
            margin-left: 10px;
        }
    }

    .no-posts {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        justify-content: center;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
        -webkit-box-align: center;
        align-items: center;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        text-align: center;

        h3 {
            font-size: 16px;
            font-weight: normal;
            max-width: 200px;
            text-align: center;
            margin: 0 0 15px;
        }

        button {
            text-transform: uppercase;
            border: 2px solid var(--button-background-color);
            padding: 8px 10px;
            margin-top: 15px;
        }
    }

    .social-list {
        display: -webkit-box;
        display: flex;
        -webkit-box-pack: center;
        justify-content: center;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
        position: absolute;
        left: 10px;
        top: 10px;
        right: 10px;
        bottom: 10px;

        &.list-view {
            justify-content: flex-start;

            .head {
                display: flex;
                padding: 5px 0;
                flex-shrink: 0;
                justify-content: space-between;

                .social-slide-read-more {
                    cursor: pointer;
                }

                h4 {
                    margin: 10px 0;
                    font-size: 22px;
                }
            }

            .social-list-items {
                overflow-y: auto;
            }

            .social-list-item-wrapper {
                cursor: pointer;
                padding: 10px;
                margin-bottom: 10px;
            }

            .social-list-item {
                max-height: 130px;
                overflow: hidden;

                h4 {
                    margin: 0 0 10px;
                }
            }

            .social-slide-head {
                display: flex;
            }

            .social-slide-content {
                padding: 10px;
                margin-top: 5px;
                background-color: @color-background-light;
                border-radius: 10px;
                position: relative;

                &:after {
                    content: " ";
                    display: block;
                    width: 0;
                    border-style: solid;
                    position: absolute;
                    top: -12px;
                    left: 25px;
                    bottom: auto;
                    border-width: 13px 0 0 13px;
                    border-color: transparent @color-background-light;
                }
            }

            .social-slide-info {
                margin-left: 5px;

                h4 {
                    margin: 0;
                }

                p {
                    margin: 0;
                    font-size: 12px;
                }
            }

            .profile-image {
                min-width: 34px;
                min-height: 34px;
            }
        }

        .headline {
            display: block;
            font-size: 16px;
            margin: 0 0 10px 0;
            padding: 0;
            -webkit-box-flex: 0;
            flex: 0 1 auto;
            text-transform: uppercase;
        }

        ks-swiper-container {
            width: 100%;
            -webkit-box-flex: 1;
            flex: 1 auto;
            position: relative;

            .swiper-container {
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
            }

            .swiper-button-prev,
            .swiper-button-next {
                opacity: .2;

                &.swiper-button-disabled {
                    display: none;
                }
            }

            .swiper-button-next {
                right: 0;
            }

            .swiper-button-prev {
                left: 0;
            }
        }

        .social-slide {
            .social-slide-top {
                display: flex;
                align-items: center;

                profile-image {
                    padding-right: 10px;

                    .profile-image {
                        height: 30px;
                        width: 30px;
                        vertical-align: top;
                    }
                }

                .social-slide-info {
                    h4 {
                        margin: 0;
                        padding: 0;
                        font-size: 14px;
                    }

                    p {
                        font-size: 12px;
                        opacity: .7;
                        margin: 0;
                        padding: 0;
                    }
                }
            }

            .social-slide-content {
                position: absolute;
                top: 40px;
                bottom: 30px;
                left: 0;
                right: 15px;
                overflow: hidden;
                font-size: 13px;
            }

            .social-slide-read-more {
                position: absolute;
                bottom: 0;
                left: 0;
                opacity: .8;

                a {
                    cursor: pointer;
                    text-transform: uppercase;
                    font-size: 13px;
                    height: 20px;
                    line-height: 20px;
                    border-bottom: 1px solid #000;
                }
            }
        }
    }
}
