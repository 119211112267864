.ec-shopgun-catalog-view {
    .shopgun-catalog-container{
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        z-index: 1;
        opacity: 0;

        &.is-viewing{
            opacity: 1;
        }
    }
}
