@import "../Import.less";

.form {
    .form-item {
        display: block;
        border-bottom: 1px solid var(--general-border-color);
        padding: 10px 0 7px 0;
        position: relative;

        &:last-child {
            border: none;
        }

        &:after {
            display: table;
            clear: both;
            content: '';
        }

        > label {
            display: block;
            color: #8e8e92;
            font-weight: 300;
            text-transform: uppercase;
            font-size: 13px;
            position: relative;
            min-height: 26px;
            line-height: normal;
            padding: 5.5px 0;
            -moz-box-sizing: border-box;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            padding-right: 70px;
        }

        .small-input {
            display: block;
            width: 70px;
            text-align: right;
            position: absolute;
            top: 10px;
            right: 0;
        }

        .input-upload {
            display: inline-block;
            height: 26px;
            line-height: 26px;
            padding: 0;
            margin: -26px 0 0 0;
            font-size: 22px;
            float: right;
            color: @split-color;
        }

        .form-item-placeholder {
            display: block;
            height: 30px;
            width: 100%;
            border-radius: var(--general-border-radius);
        }
    }

    .checkbox-list {
        margin: 10px 0 0 0;

        .checkbox-item {
            margin: 6px 0;
            position: relative;
            height: 26px;

            > label {
                line-height: 26px;
                width: 50%;
                float: left;
            }

            > .small-input {
                top: 0;
            }

            &:after {
                display: table;
                clear: both;
                content: '';
            }
        }
    }

    input {
        background: none;
        height: 26px;
        line-height: 26px;
        display: block;
        border: none;
        width: 100%;
        color: #111;
        font-size: 15px;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;

        &[disabled] {
            background: rgba(0, 0, 0, 0.4);
            opacity: .5;
        }

        &.with-upload {
            padding-right: 40px;
        }
    }

    select {
        background: none;
        //border: 1px solid rgba(0, 0, 0, 0.12);
        border: none;
        background: rgba(0, 0, 0, 0.06);
        line-height: 34px;
        height: 34px;
        color: #000;
        width: 100%;
        font-size: 13px;
        font-weight: 600;
        padding: 0 6px;
        box-sizing: border-box;

        option {
            padding-left: 0;
        }

        &[disabled] {
            background: rgba(0, 0, 0, 0.4);
            opacity: .5;
        }
    }

    textarea {
        border: none;
        width: 100%;
        font-size: 15px;
        color: #111;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
    }

    .form-item .phone-input {
        position: relative;
        clear: both;

        .phone-input-country {
            display: block;
            position: relative;
            height: 32px;
            line-height: 32px;
            width: 50px;
            z-index: 2;
            box-sizing: border-box;
            //border: 1px solid rgba(0, 0, 0, 0.12);
            background: rgba(0, 0, 0, 0.06);
            text-align: center;
            overflow: hidden;

            &.disabled {
                color: rgba(0, 0, 0, 0.4);
                background: transparent;
            }
        }

        > input[type=text] {
            position: absolute;
            width: 100%;
            top: 0;
            left: 0;
            right: 0;
            padding-left: 60px;
            -moz-box-sizing: border-box;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            z-index: 1;
            height: 32px;
            line-height: 32px;
        }
    }
}