reminders {
    display: block;
    padding-top: 15px;

    .day {
        h3 {
            margin: 0 15px;
        }

        padding-bottom: 20px;
    }

    .reminder-wrapper {
        display: flex;
        padding: 15px 0;
        @media @desktop-up {
            align-items: center;
        }
    }

    .reminder {
        display: flex;
        flex: 1;
        align-items: center;
    }

    .reminder-image-container {
        position: relative;
        height: 56px;
        width: 56px;
        margin: 0 15px;

        .avatar {
            svg {
                stroke-width: 0;
                border-radius: 50%;
            }

            path {
                color: #8893a3;
            }
        }

        .media-thumbnail, .image {
            width: 100%;
            height: 100%;
            border-radius: 50%;
        }

        rl-icon {
            position: absolute;
            right: -2px;
            bottom: -6px;

            width: 27px;
            height: 27px;
            font-size: 14px;
            color: var(--white-color);

            &[icon='content'] {
                background: var(--highlight-color);
            }
        }
    }

    .reminder-data {
        display: flex;
        flex: 1;
        flex-direction: column;

        p {
            margin: 0;
            word-break: break-word;

            &:first-of-type {
                margin-bottom: 5px;
            }
        }
    }

    .options {
        padding: 0 15px;

        rl-icon {
            color: var(--general-text-color);
        }
    }
}