@service-form-filling-text-color: #3b3c40;

.popup-wrapper {
    position: fixed;
    z-index: 201;
    display: none;

    &.open {
        display: block;

        .popup-content {
            animation: showPopup 0.3s ease;
        }
    }

    &.template-half {
        display: block;

        &.ng-hide-add,
        &.ng-hide-remove {
            transition: all 0.3s;
        }

        &.ng-hide-add {
            .modal-loader {
                display: none;
            }
        }

        &.ng-hide-add.ng-hide-add-active {
            opacity: .4;

            .popup-content {
                animation: hidePopup 0.3s ease;
            }

            .modal-loader {
                display: none;
            }
        }

        &.ng-hide-remove.ng-hide-remove-active {
            .popup-content {
                animation: showPopup 0.3s ease;
            }
        }

        header {
            min-height: auto;
        }
    }

    .popup-wrapper-bg {
        position: fixed;
        z-index: 10;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.5);
    }

    @keyframes showPopup {
        0% {
            transform: translateY(100%);
        }

        100% {
            transform: translateY(0%);
        }
    }

    @keyframes hidePopup {
        0% {
            transform: translateY(0%);
        }

        100% {
            transform: translateY(100%);
        }
    }

    .popup-content {
        position: fixed;
        z-index: 15;
        top: 0;
        left: 0;

        display: flex;
        justify-content: space-between;
        flex-direction: column;

        width: 100%;
        max-width: 768px;
        height: 100%;
        padding: 0;
        transform: translate(0, 0); //create parent layer for inside fixed popups

        background: var(--general-background-color);


        @media @tablet-up {
            left: 50%;
            margin-left: calc(-768px / 2);
        }


        @media @tablet-max {
            height: calc(100% - var(--popup-height-shift));
        }


        @media @desktop-up {
            height: auto;
            top: 5%;
            bottom: 5%;

            left: calc((var(--sidemenu-width) / 2) + 50%);

            .popup-content {
                top: 0;
                bottom: 0;
            }
        }


        .modal-loader {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;

            .spinner {
                width: 50px;
                height: 50px;

                &.ng-animate {
                    display: none;
                }
            }
        }

        .shimmer-container {
            display: flex;
            flex-direction: column;
            gap: var(--spacing);
            padding: var(--spacing);
            flex: 1 1 100%;
            overflow: hidden;
        }

        .lazy-placeholder {
            background: var(--general-background-color);
            border-radius: var(--general-border-radius);
    
            rl-loading-placeholder {
                display: block;
                box-sizing: border-box;
                padding: 15px;
    
                .placeholder-shimmer {
                    height: 19px;
                }
            }
        }

        .close {
            border: none;
            background: transparent;
            padding: 0;
            margin: 0;
            color: inherit;
            cursor: pointer;

            i {
                font-size: 26px;
            }
        }
    }

    .scroll-container {
        scrollbar-width: thin;
        scrollbar-color: rgba(0, 0, 0, 0.15) transparent;
        overscroll-behavior: none;
        -webkit-overflow-scrolling: touch;
    }

    .popup-content.clean {
        &:not(.flex) {
            display: block;
        }

        &:not(.flex) .popup-content-inner {
            display: block;
            flex: initial;
            height: 100%;
        }

        .popup-content-inner {
            padding: 0;
        }
    }

    .popup-content.half {
        width: 100%;
        max-height: 50vh;
        bottom: 0;
        top: auto;

        @media @desktop-up {
            top: 25%;
            bottom: 25%;
        }

        .divider {
            width: 42px;
            display: block;
            border-radius: 7px;
            background: #ebecee;
            margin: 0 auto;
            position: relative;
            top: -10px;
            height: 5px;

            @media @desktop-up {
                display: none;
            }
        }
    }

    .popup-content.clean.flex .popup-content-inner, .popup-content-inner {
        display: flex;
        flex: 1;
        overflow-x: hidden;
        flex-direction: column;

        &:not(.scroll-disabled) {
            overflow-y: auto;
        }
    }

    .popup-content-inner {
        padding: 10px 15px;
    }

    .popup-footer {
        flex: 0 1 auto;
        min-height: 40px;
        box-sizing: border-box;
        text-align: right;
        font-size: 0;

        button {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            position: relative;
            text-align: center;
            text-transform: uppercase;
            padding: 0 15px;
            cursor: pointer;

            &[disabled] {
                color: #a7a7a7;
                opacity: 0.8;
                cursor: not-allowed;
            }
        }
    }

}
