comments-popup {
    .content {
        display: flex;
        flex-direction: column;
        height: calc(~'100% - 55px');

        section {
            flex: 1 1 auto;
            max-height: calc(~'100% - 40px');
            overflow-y: auto;

            .empty-list {
                padding: 30px 0;
                text-align: center;

                rl-icon {
                    font-size: 60px;
                    margin-bottom: 20px;
                }

                .empty-text {
                    font-size: 16px;
                }
            }

            .comments-list {
                ul {
                    display: block;
                    position: relative;
                    padding: 0;
                    margin: 0;
                    list-style: none;

                    li {
                        position: relative;
                        padding: 10px 15px;
                        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                        display: flex;
                        align-items: flex-start;
                        justify-content: space-between;

                        .profile-image {
                            min-width: 40px;
                            min-height: 40px;
                        }

                        .comment-content {
                            width: 100%;
                            padding: 0 10px;
                            color: var(--general-text-color);

                            h3, p {
                                padding: 0;
                                margin: 0;
                            }

                            p {
                                margin-top: 2px;
                                word-break: break-word;
                            }

                            .subtitle {
                                font-size: 13px;
                                opacity: .5;
                                margin-top: 3px;
                            }
                        }

                        .comment-delete {
                            padding: 0;
                        }
                    }
                }
            }
        }

        footer {
            min-height: 40px;
            display: block;
            width: 100%;
            flex: 0 0 auto;

            .form-inline {
                display: flex;
                padding: 5px 15px;

                textarea {
                    flex: 1 1 auto;
                    min-width: 70%;
                    height: 30px;
                    box-sizing: border-box;
                    padding: 5px;
                    line-height: initial;
                    border: 1px solid ~'rgba(var(--general-text-color-rgb), .1)';
                    border-radius: 4px;
                    margin: 0;
                }

                button {
                    flex: 0 0 auto;
                    color: var(--general-text-color);
                    padding: 0;
                    margin-left: 15px;
                }
            }

            .comment-posting {
                text-align: center;

                .spinner {
                    width: 20px;
                    height: 20px;
                    top: 8px;
                }
            }
        }
    }
}

