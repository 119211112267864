@import "../Import.less";

.popup-overlay > .popup {
    height: 350px;
    width: 90%;
    position: absolute;
    top: 50%;
    left: 5%;
    right: 5%;
    margin-top: -175px;
    background: #fff;
    border-radius: 5px;

    .popup-content-wrapper {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        display: -ms-flexbox;
        display: -webkit-box;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;

        .popup-content {
            display: block;
            text-align: center;
            vertical-align: middle;
            width: 100%;

            i {
                font-size: 100px;
                margin-bottom: 5px;
            }

            h3 {
                font-size: 18px;
                margin: 0;
                padding: 0;
            }

            p {
                display: block;
                margin: 0 auto;
                max-width: 180px;
                width: 100%;
                padding: 0;
            }

            &.primary-color {
                i {
                    color: @color-primary;
                }
            }
        }
    }

    .popup-close-btn {
        position: absolute;
        left: 5px;
        top: 5px;
        padding: 0;
        height: 40px;
        width: 40px;
        line-height: 40px;
        text-align: center;
        background: transparent;
        border: none;

        &:before {
            display: inline-block;
            font-family: @icon-mdi;
            content: "\F156";
            font-size: 38px;
        }
    }
}