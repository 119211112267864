reactions-viewer {

    .popup-wrapper {
        .popup-content-inner {
            padding: 0;
        }

        .reaction-viewer-content {
            display: flex;
            flex-direction: column;
            height: 100%;
        }

        header {
            flex: 0 0 auto;
            width: 100%;
            box-sizing: border-box;
            z-index: 99;
            background: white;

            ul {
                width: 100%;
                margin: 0;
                list-style: none;
                display: flex;
                align-items: center;
                padding: 20px 25px 0 15px;
                box-sizing: border-box;
                border-bottom: 1px solid ~'rgba(var(--text-on-white-color-rgb), .1)';

                li {
                    margin: 0 15px 0 0;
                    padding: 0 0 10px;
                    cursor: pointer;
                    font-size: 14px;
                    border-bottom: 2px solid transparent;
                    color: var(--text-on-white-color);

                    &.active {
                        border-bottom: 2px solid var(--text-on-white-color);

                        &[data-reaction-type="1"],
                        &[data-reaction-type="5"] {
                            color: var(--highlight-color);
                            border-color: var(--highlight-color);
                        }

                        &[data-reaction-type="2"],
                        &[data-reaction-type="6"] {
                            color: var(--alert-color);
                            border-color: var(--alert-color);
                        }

                        &[data-reaction-type="3"],
                        &[data-reaction-type="4"] {
                            color: var(--warning-color);
                            border-color: var(--warning-color);
                        }
                    }

                    reaction-icon {
                        width: 19px;
                        height: 19px;
                        vertical-align: middle;
                    }

                    span {
                        vertical-align: middle;
                        line-height: 20px;
                    }
                }
            }

            .btn-close {
                display: none;

                @media @desktop-up {
                    display: block;
                    position: absolute;
                    right: 0;
                    top: 0;
                    padding: 15px;
                    font-size: 20px;
                    cursor: pointer;
                }
            }
        }
    }

    section {
        flex: 1 1 auto;
        padding: 15px;
        overflow-y: auto;
    }

    .reactions-list {
        margin: 0;
        list-style: none;
        padding: 0;

        li {
            display: flex;
            align-items: center;
            justify-content: space-between;

            margin-top: 10px;

            .left {
                display: flex;
                align-items: center;
            }

            .image-wrapper {
                position: relative;
            }

            .profile-image {
                width: 42px;
                height: 42px;
                cursor: pointer;
            }
            
            .blocked-name {
                font-weight: 600;
                margin-left: 10px;
            }

            reaction-icon {
                position: absolute;
                z-index: 11;
                bottom: 0;
                width: 20px;
                height: 20px;
                right: 0;
            }

            .title {
                margin-left: 5px;

                color: var(--text-on-white-color);

                p {
                    margin: 0;
                }

                .name {
                    font-size: 15px;
                    font-weight: 600;
                }

                .department {
                    opacity: .5;
                    font-size: 13px;
                }
            }

            button {
                min-width: auto;
                opacity: 1 !important;
            }
        }
    }

    .popup-content {
        border-radius: var(--general-border-radius);

        .spinner-wrapper {
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            color: var(--general-text-color);
        }
    }
}