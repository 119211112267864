.colorize {
    &.negative {
        background: var(--warning-color);
        color: var(--general-background-color);
    }

    &.neutral {
        background: var(--alert-color);
        color: var(--general-text-color);
    }

    &.positive {
        background: var(--confirmation-color);
        color: var(--general-background-color);
    }
}

store-manager-vew {
    header-slot {
        display: flex;
        gap: var(--m-spacing);
        align-items: center;

        .img {
            width: 34px;
            height: 34px;
            filter: drop-shadow(0 7px 5px var(--shadow-color));
        }

        h4 {
            margin: 0;
            font-size: 1.06667rem;
            font-weight: 400;
        }

        .score {
            margin-left: auto;
            margin-right: var(--m-spacing);
            padding: calc(var(--m-spacing) / 3) var(--s-spacing);

            box-shadow: 0 7px 15px 0 var(--shadow-color);
            font-size: 0.86667rem;
            font-weight: 700;

            .colorize();
        }
    }
    
    .questions-without-drivers {
        padding: var(--m-spacing) var(--spacing);
        border-radius: var(--general-border-radius);
        box-shadow: 0 7px 15px 0 var(--shadow-color);
        background: var(--general-background-color);
    }
    
    .questions-header {
        display: flex;
        justify-content: space-between;
        padding-bottom: var(--m-spacing);
        margin-bottom: var(--m-spacing);

        border-bottom: 1px solid var(--general-border-color);

        span {
            font-size: 0.86667rem;
            font-weight: 700;
        }
    }
    .questions-list {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .question-item {
            display: flex;
            gap: var(--m-spacing);
            justify-content: space-between;
            align-items: center;

            .question {
                overflow: hidden;
                text-overflow: ellipsis;

                white-space: nowrap;
                font-size: 0.86667rem;
            }

            .right {
                display: flex;
                gap: var(--m-spacing);
            }

            .score {
                flex-shrink: 0;
                width: 50px;
                height: 24px;
                border-radius: 3px;

                text-align: center;
                line-height: 24px;
                font-size: 1.06667rem;
                font-weight: 700;

                .colorize();
            }

            rl-icon {
                flex-shrink: 0;
                width: 24px;
                height: 24px;
                padding: 0 var(--s-spacing);

                font-size: 14px;
                cursor: pointer;
                border-radius: 2px;
                background: var(--general-border-color);
            }
        }
    }
}