calendar-view {
    height: 100%;
    display: flex;
    flex-direction: column;

    @media @desktop-up {
        height: calc(100% - 75px);
    }

    months-view {
        height: 310px;
    }

    .events-scrolled-wrapper {
        padding: 15px;
        
        @media @desktop-up {
            padding: 15px 0;
        }
    }
}