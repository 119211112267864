learning-path-item {
    display: block;
    overflow: hidden;
    padding: var(--m-spacing);
    border-radius: var(--training-border-radius);
    background-color: var(--background-highlight-color);
    
    .learning-path-item {
        &.expired {
            .top h3 {
                opacity: 0.4;
            }

            .image-inner {
                opacity: 0.4;
            }

            rl-progress .bar .inner {
                background: rgba(var(--general-text-color-rgb), 0.4);
            }
        }
    }


    &:not(.locked) {
        cursor: pointer;

    }

    .top {
        .icon-wrapper {
            position: relative;
            margin-right: 10px;

            .icon {
                display: flex;
                align-items: center;
                justify-content: center;

                position: relative;
                width: 80px;
                height: 80px;

                border-radius: 50%;

                background-color: var(--general-text-color);

                [icon="circle"] {
                    font-size: 34px;
                    color: rgba(var(--general-background-color-rgb), 0.4);
                }
            }

            state-icon {
                display: flex;
                position: absolute;
                bottom: 0;
                right: 0;

                rl-icon {
                    &.new {
                        span {
                            top: 0;
                        }
                    }
                }
            }
        }

        h3 {
            margin: 0;
        }
    }

    .top {
        display: flex;
        align-items: center;
        margin-bottom: 15px;

        .icon-wrapper {
            position: relative;
            margin-right: 10px;

            .icon {
                display: flex;
                align-items: center;
                justify-content: center;

                position: relative;
                width: 80px;
                height: 80px;

                border-radius: 50%;

                background-color: var(--general-text-color);

                [icon="folder-tree"] {
                    font-size: 34px;
                    color: rgba(var(--general-background-color-rgb), 0.4);
                }
            }

            state-icon {
                display: flex;
                position: absolute;
                bottom: 0;
                right: 0;

                rl-icon {
                    &.new {
                        span {
                            top: 0;
                        }
                    }
                }
            }
        }

        h3 {
            margin: 0 0 6px 0;
            font-size: 16px;
            font-weight: 600;
        }

        .title-and-duration {
            .duration {
                font-size: 13px;

                span {
                    rl-icon {
                        font-size: 13px;
                    }
                }
            }
        }
    }

    rl-progress {
        margin-bottom: 10px;
    }

    .locked, .expired {
        .info h3 {
            opacity: 0.4;
        }

        .image-inner {
            opacity: 0.4;
        }

        rl-progress .bar .inner {
            background: rgba(var(--general-text-color-rgb), 0.4);
        }
    }

    .deadline {
        .info {
            background-image: linear-gradient(to bottom, rgba(var(--general-text-color-rgb), 0) 39%, rgba(var(--warning-color-rgb), 0.1));
        }

        rl-progress .bar .inner {
            background: var(--warning-color);
        }
    }

    .new {
        .info {
            background-image: linear-gradient(to bottom, rgba(var(--general-text-color-rgb), 0) 39%, rgba(var(--highlight-color-rgb), 0.1));

            h3 {
                color: var(--highlight-color);
            }
        }

        .status {
            text-transform: uppercase;
        }
    }

    .passed {
        .info {
            background-image: linear-gradient(to bottom, rgba(var(--general-text-color-rgb), 0) 39%, rgba(var(--confirmation-color-rgb), 0.1));
        }

        rl-progress .bar .inner {
            background: var(--confirmation-color);
        }
    }

    .not-passed {
        .info {
            background-image: linear-gradient(to bottom, rgba(var(--general-text-color-rgb), 0) 39%, rgba(var(--alert-color-rgb), 0.1));
        }

        rl-progress .bar .inner {
            background: var(--alert-color);
        }
    }

    .bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;

        p {
            margin: 0;
            font-size: 13px;

            &.status {
                font-weight: 600;
            }

            &.expired, &.deadline {
                color: var(--general-text-inactive-color);
            }
        }
    }
}