filling-content {
    .filling-content:not(.show-feedback-only) > div:not(:first-of-type) {
        margin-top: 65px;
    }

    .filling-content.show-feedback-only {
        .service-form-filling-group {
            &:not(:last-of-type) {
                margin-bottom: 15px;
            }
        }
    }

    .service-form-filling-group {
        > h2 {
            font-size: 21px;
            font-weight: bold;
            padding-bottom: 10px;
            margin-top: 0;
            margin-bottom: 15px;

            border-bottom: 1px solid var(--general-border-color);

            p {
                margin: 0;
            }
        }
    }

    .service-form-filling-field {
        word-break: break-word;
        word-break: auto-phrase;
        padding: 10px;

        border-radius: var(--general-border-radius);
        background: var(--background-highlight-color);

        &:not(:last-of-type) {
            margin-bottom: 15px;
        }

        media-preview {
            overflow: hidden;
            border-radius: var(--general-border-radius);
        }

        .multiline {
            word-break: break-word;
        }

        > .title {
            display: flex;
            justify-content: space-between;
            align-items: baseline;
            min-height: 20px;
            padding-bottom: 10px;
            box-sizing: border-box;

            &.bottom-space {
                margin-bottom: 10px;
            }

            h3 {
                font-weight: bold;
                font-size: 15px;
                line-height: 1.3;
                margin: 0 10px 0 0;
            }

            .right {
                display: flex;
                align-items: center;

                .comments {
                    white-space: nowrap;
                }

                > *:not(:last-child) {
                    margin-right: 10px;
                }

            }

            rl-button {
                &[icon=edit] {
                    rl-icon {
                        font-size: 15px;

                    }
                }

                button {
                    min-width: 30px;
                    width: 30px;
                    height: 30px;
                    background: var(--highlight-color);
                    filter: drop-shadow(0px 6px 15px rgba(0, 0, 0, 0.15));

                    rl-icon {
                        font-size: 15px;
                        color: var(--text-on-highlight-color);
                    }
                }
            }
        }

        .field-help-text {
            display: block;
            position: relative;
            z-index: 2;
            font-size: 12px;
            opacity: .5;
            color: var(--general-text-color);
            line-height: 12px;
            margin: 5px 0 10px 0;
        }

        p {
            margin: 0;

            font-size: 15px;
            line-height: 1.3;
            color: var(--general-text-inactive-color);
        }

        .field-tags {
            position: relative;
            z-index: 2;
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            gap: 7px;

            div {
                display: inline-block;
                vertical-align: top;
                padding: 5px 7px;

                box-sizing: border-box;
                align-items: center;

                font-weight: bold;
                border-radius: var(--general-border-radius);
                font-size: 13px;
                background: var(--general-background-color);
                color: var(--general-text-color);
            }
        }
    }

    .feedback-buttons-wrapper {
        rl-button {
            display: block;
            margin-top: 15px;

            & + rl-button {
                button {
                    background-color: var(--general-background-color);
                    border: 1px solid var(--highlight-color);    
                    color: var(--highlight-color);
                }
            }
        }
    }


    footer {
        position: relative;
        display: flex;
        flex-direction: column;

        margin-top: 40px;
        padding: 25px 15px 15px;

        background: var(--background-highlight-color);
        border: 1px solid var(--general-border-color);
        border-radius: var(--general-border-radius);

        [icon="info"] {
            position: absolute;
            top: -13px;
            left: 13px;
            width: 26px;
            border-radius: 50%;

            font-size: 26px;
            background: var(--white-color);
            color: var(--general-text-inactive-color);
        }

        p {
            margin: 0;
            padding: 0;
            display: inline-block;
            color: var(--general-text-inactive-color);

            &:not(:last-of-type) {
                padding-bottom: 5px;
            }

            span {
                margin-left: 3px;
            }
        }


        rl-icon {
            font-size: 13px;
        }

        .customer {
            button {
                padding: 0;
            }
        }
    }
}
