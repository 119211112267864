@import "../Import.less";

:root {
    --startpage-background-image: '';
}

.show-intro {
    .start-page {
        transform: translate3d(-100%, 0, 0);
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
    }
}

.start-page {
    background-color: var(--header-background-color);
    background-image: var(--startpage-background-image);
    background-position: center center;
    background-size: cover;
    color: var(--header-text-color);
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    font-size: 16px;
    height: 100%;
    min-height: 100%;
    box-sizing: border-box;
    padding: 35px 25px;
    transform: translate3d(0, 0, 0);
    z-index: 2;
    transition: transform ease-in-out .2s;

    .start-page-logo {
        text-align: center;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        padding: 5vh 25px 0 25px;
        box-sizing: border-box;

        img {
            width: 100%;
            max-width: 80vw;
            display: inline-block;
        }
    }

    .start-page-footer {
        width: 100%;

        .start-page-texts {
            text-align: center;
            margin-bottom: 15px;

            h2 {
                font-size: 15px;
                font-weight: bold;
                width: 100%;
            }

            p {
                font-size: 15px;
                margin: 0;
                padding: 0;
            }
        }

        .start-page-buttons {
            text-align: center;
        }

        .start-page-button-seperator {
            font-size: 13px;
            padding: 15px 0;
            display: block;
            text-transform: uppercase;
        }

        .start-page-button {
            display: inline-block;
            width: 100%;
            max-width: 80vw;
            box-sizing: border-box;
            height: 46px;
            line-height: 46px;
            color: var(--button-text-color);
            background: var(--button-background-color);
            border: 0;
            box-shadow: none;
            text-transform: uppercase;
            font-weight: 600;
            border-radius: 5px;
            font-size: 15px;
            text-decoration: none;
            cursor: pointer;
            padding: 0 15px;

            span:not(.spinner) {
                display: inline-block;
                white-space: nowrap;
                overflow: hidden;
                max-width: 100%;
                text-overflow: ellipsis;
            }

            .spinner {
                border-color: var(--button-text-color);
                position: relative;
                top: -2px;
            }

            &.start-page-button-outlined {
                background: none;
                border: 1px solid var(--header-text-color);
                color: var(--header-text-color);

                .spinner {
                    border-color: var(--header-text-color);
                }
            }
        }
    }
}