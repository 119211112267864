user-portraits {
    display: flex;

    div {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        width: 30px;
        height: 30px;

        border-radius: 50%;
        color: var(--text-on-white-color);
        background: var(--white-color);
        box-sizing: border-box;
        box-shadow: 0 6px 7px 0 ~'rgba(var(--general-text-color-rgb), .1)';
        border: 1px solid var(--white-color);

        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;

        &:not(:last-of-type) {
            margin-right: -10px;
        }
    }
}