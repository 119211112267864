stars-rating {
    position: relative;
    display: inline-block;


    rl-icon {
        font-size: 40px;
    }

    .empty {
        white-space: nowrap;
        
        rl-icon {
            color: ~'rgba(var(--general-text-color-rgb), 0.2)';
        }
    }

    .full {
        width: 0;
        overflow: hidden;
        position: absolute;
        top: 0;
        left: 0;
        white-space: nowrap;

        rl-icon {
            color: var(--alert-color);
        }
    }
}