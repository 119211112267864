training-voluntary-page, training-voluntary-category {
    .training-grid {
        @media @desktop-up {
            margin: 0;
        }
    }

    .training-categories-grid {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        padding: 0 5px;
        box-sizing: border-box;

        training-voluntary-category-item {
            margin: 0 5px 10px 5px;
            width: ~'calc(50% - 10px)';

            @media @tablet-up {
                width: ~'calc(33.333% - 10px)';
            }
            @media @desktop-up {
                width: ~'calc(25% - 10px)';
            }
        }
    }
}

training-voluntary-page {
    display: flex;
    flex-direction: column;

    height: calc(100vh - 55px - 50px);

    @media @desktop-up {
        height: calc(100vh - 60px);
    }

    rl-search {
        margin: 10px;
    }

    ul {
        display: flex;
        margin: 15px 10px 25px 10px;
        padding: 0;
    }

    li {
        list-style: none;
        opacity: 0.4;

        &:first-of-type {
            margin-right: 30px;
        }

        &.active {
            position: relative;
            opacity: 1;
            font-weight: 600;

            &:after {
                content: '';
                position: absolute;
                left: 0;
                bottom: -5px;
                width: 100%;
                height: 3px;
                background: var(--general-text-color);
            }
        }
    }

    > section {
        position: relative;
        flex: 1;

        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        overscroll-behavior: none;
    }

    .training-categories-grid {
        position: absolute;
        min-height: 100%;
        width: 100%;
        align-content: flex-start;
    }

    .lesson {
        padding: 0 5px 10px 5px;
    }

    .training-modules-lessons {
        header {
            display: flex;
            align-items: center;
            justify-content: space-between;

            padding: 10px;

            h3 {
                font-size: 15px;
                margin: 0;
            }

            rl-button {
                button {
                    font-size: 13px;
                    font-weight: normal;
                    min-width: auto;
                    height: auto;
                }
            }
        }
    }
}
