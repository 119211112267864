.shadow-depth-1{
    .shadow-depth-1();
}
.shadow-depth-2{
    .shadow-depth-2();
}
.shadow-depth-3{
    .shadow-depth-3();
}
.shadow-depth-4{
    .shadow-depth-4();
}
.shadow-depth-5{
    .shadow-depth-5();
}