media {
    display: block;

    &.pdf-viewer {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
    }
}

.media {
    position: relative;

    .image {
        font-size: 0;
        display: block;
        text-align: center;
        position: relative;
        
        &.loading {
            display: block;
        }
        
        &.image-gradient {
            &:after {
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                display: block;
                content: '';
                height: 66px;
                background: url('/Content/Graphics/Gradient.png') repeat-x;
            }
        }

        img {
            width: auto;
            max-width: 100%;
            height: auto;
            margin: 0 auto;
            display: inline-block;
            max-height: 100%;
        }
    }

    .image-spinner {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: none;

        .spinner {
            margin: 28px 0;
            opacity: .25;
        }
    }

    .image[afkl-lazy-image-loaded] + .image-spinner {
        display: none;
    }

    .video {
        width: 100%;
        max-width: 100%;

        > .video-wrapper {
            position: relative;
            padding-bottom: 46%;
            padding-top: 30px;
            height: 0;
            overflow: hidden;

            iframe, object, embed, video {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }

        > .video-play-thumb {
            position: relative;
            overflow: hidden;
            padding-top: 30px;
            padding-bottom: 46%;
            background-size: cover;
            background-position: center center;


            > rl-icon {
                position: absolute;
                z-index: 2;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);

                height: 80px;
                width: 80px;
                border-radius: 50%;

                font-size: 40px;
                color: var(--general-text-color);
                background: rgba(255, 255, 255, 0.6);
                backdrop-filter: blur(24px);

                span {
                    margin-left: 5px;
                }
            }

            &:before {
                content: '';
                display: block;
                position: absolute;
                z-index: 1;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background: rgba(0, 0, 0, 0.4);
            }
        }

        .video-newwindow {
            padding: 5px 10px;
            text-align: right;
            color: gray;

            a {
                color: gray;
            }
        }
    }

    .file-link > a {
        display: block;
        padding: 0 10px;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        line-height: 50px;
        height: 50px;
        color: #000;
        text-decoration: none;
        position: relative;
        border-top: 1px solid #000;
        border-bottom: 1px solid #000;
        background: lighten(@split-color, 10%);

        i {
            font-size: 40px;
            height: 48px;
            line-height: 48px;
        }

        span {
            display: block;
            position: absolute;
            left: 50px;
            right: 50px;
            top: 4px;
            bottom: 0;
            text-align: center;
            height: 30px;
            line-height: 30px;
            overflow: hidden;

            &:after {
                display: block;
            }

            &.small {
                top: 19px;
                font-size: .8em;
                opacity: .6;
            }
        }
    }

    .audio {
        width: 100%;
        height: 150px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        span {
            margin-bottom: 5px;
            font-size: .8em;
            position: relative;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 100%;
            max-width: 100%;
            text-align: center;
        }
    }
}