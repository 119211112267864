@import "../../Import.less";

.table-chart{
    font-size:0.9em;
    th {
        border-bottom:1px solid #999;
        font-weight:500;
        padding:3px 10px 3px 0px;
        text-align:center;
    }
    th:not(:first-child) {
        border-left:1px solid #eee;
    }
    td {
        
        padding-top:3px;
        padding-bottom:3px;
        border-top:1px solid #eee ;
    }
    td:not(:first-child) {
        padding-right:10px;
        border-left:1px solid #eee;
    }
}