@import "../../Import.less";

.line-chart{
    position: relative;
    > canvas{
        width: 100%;
        height: 160px;
    }
    .line-chart-label{
        display: block;
        font-size: .85em;
        margin-top: -7px;
    }

    .line-chart-loader{
        position: absolute;
        top: 50%;
        left: 0; 
        right: 0;
        margin-top: -11px;
        text-align: center;
    }
    .line-chart-missing{
        position: absolute;
        top: 50%;
        left: 0; 
        right: 0;
        margin-top: -11px;
        text-align: center;
        opacity: .5;
        i{
            font-size: 26px;
            display: block;
            margin-bottom: 5px;
        }
        span{
            display: block;
        }
    }
}