@import "../Import.less";

@module-christmas-calendar-background: #cc3333;

.christmas-calendar {
    background: @module-christmas-calendar-background;
    padding-bottom: 0;
    overflow: hidden;

    &.advent {
        .tile-container {
            .tile {
                width: 43%;

                .tile-content {
                    .tile-body {
                        .number {
                            font-size: 40px;
                        }

                        &.no-icon {
                            .icon-container {
                                display: none;
                            }
                        }
                    }

                    .tile-open-body {
                        font-size: 40px;
                    }
                }
            }
        }
    }

    &.normal-25-days {
        .tile-container {
            .tile:last-child {
                width: 91%;

                .tile-content {
                    padding-bottom: 20.88%;
                }
            }
        }
    }

    .short-intro {
        background-color: #fff;
        padding: 6px;
        text-align: center;
        color: #000;
        position: relative;
        z-index: 3;
    }

    .tile-container {
        padding-top: 10px;
        padding-left: 2.5%;
        position: relative;
        z-index: 3;

        .tile {
            display: inline-block;
            width: 19%;
            margin: 2% 2.5%;
            vertical-align: top;

            .tile-content {
                width: 100%;
                padding-bottom: 100%;
                position: relative;
                perspective: 100px;
                border: 2px solid #fff;
                border-radius: 4px;
                color: #fff;

                .tile-body {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    z-index: 2;
                    transition: transform .5s;
                    transform-style: preserve-3d;
                    transform-origin: left center;

                    &.open {
                        background: @module-christmas-calendar-background;
                        transform: rotateY(85deg);
                    }

                    &.no-icon {
                        .number {
                            .flex-center-center();
                            top: 0;
                            left: 0;
                            right: 0;
                            bottom: 0;
                            font-size: 24px;
                        }

                        .icon-container {
                            display: none;
                        }
                    }

                    .number {
                        font-size: 13px;
                        font-weight: 600;
                        position: absolute;
                        top: 3px;
                        left: 5px;
                        z-index: 2;
                    }

                    .icon-container {
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        z-index: 1;
                        .flex-center-center();

                        .icon {
                            width: 55%;
                            height: 55%;
                            .flex-center-center();

                            > img {
                                display: block;
                                max-height: 100%;
                                max-width: 100%;
                            }
                        }
                    }
                }

                .tile-open-body {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    z-index: 1;
                    .flex-center-center();
                    color: #000;
                    font-size: 20px;
                    font-weight: 600;
                    background: #fff;
                }

                .inactive-tile {
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    background: rgba(255, 255, 255, 0.3);
                    z-index: 3;
                }
            }
        }
    }

    .backgrounds {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;

        > div {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 1;
            opacity: 0;
            background-position: center center;
            background-size: cover;
            transition: opacity ease-in-out 1s;

            &.active {
                z-index: 2;
                opacity: 1;
            }
        }
    }

    .background-overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 2;
        background: rgba(0,0,0,0.3);
    }
}

.christmas-calendar-day{
    color: #fff;

    .christmas-calendar-day-container{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 3;
        overflow: hidden;

        .day-tile{
            position: relative;
            width: 100%;
            height: 50%;
            .flex-center-center();
            box-sizing: border-box;
            border-bottom-width: 2px;
            border-bottom-style: solid;
            border-bottom-color: #fff;
            overflow: hidden;
            padding: 20px;

            h2{
                margin: 0 0 5px 0;
                padding: 0;
                font-size: 26px;
            }
            p{
                font-size: 18px;
                margin: 0;
                padding: 0;
                text-align: center;
            }

            &:last-child{
                border-bottom: none;
            }
        }
    }
}