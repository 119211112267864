icon-plus {
    button {
        position: fixed;
        display: flex;
        justify-content: center;
        align-items: center;

        padding: 0;
        width: 50px;
        height: 50px;
        text-align: center;
        background: var(--highlight-color);
        color: var(--white-color);
        box-shadow: 0 5px 7px 0 var(--general-border-color);
        border-radius: 50%;
        z-index: 99;

        &.right-bottom {
            right: 10px;
            bottom: 60px;
            bottom: calc(60px + env(safe-area-inset-bottom, 0px));
        }
    }
}