catalog-item-details {
    display: flex;
    flex-direction: column;

    @media @mobile-max {
        padding: 0 10px;
    }
    @media @tablet-max {
        padding: 0 20px;
        min-height: calc(100vh - 110px);
    }

    .breadcrumbs {
        margin-bottom: 20px;
    }

    .gallery {
        margin-top: 10px;

        .wrap {
            border: 1px solid rgba(60, 72, 88, 0.1);
            margin-bottom: 10px;
        }

        .img {
            position: relative;
            width: 100%;
            height: 200px;
            @media @tablet-up {
                height: 300px;
            }
            @media @desktop-up {
                height: 500px;
            }

            img, .video-play-thumb, .media-preview-file {
                height: 200px;
                padding: 0;

                @media @tablet-up {
                    height: 300px;
                }
                @media @desktop-up {
                    height: 500px;
                }
            }

            img {
                max-height: initial;
            }

            &.animate {
                animation: imageChange 0.5s ease;
            }

            .image-spinner {
                position: absolute;
                top: 50%;
                left: 50%;

                margin-top: -12px;
                margin-left: -12px;

                .spinner {
                    margin: 0;
                }
            }
        }

        .previews {
            display: flex;
            flex-wrap: wrap;
            padding: 0;
            margin: 0 0 10px 0;

            li {
                display: flex;
                justify-content: center;
                align-items: center;

                box-sizing: border-box;

                width: 40px;
                height: 40px;

                margin-bottom: 10px;


                @media @tablet-up {
                    width: 80px;
                    height: 80px;
                }
                @media @desktop-up {
                    width: 100px;
                    height: 100px;
                }

                border: 1px solid rgba(60, 72, 88, 0.1);

                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
                list-style: none;

                &.active {
                    border: 1px solid var(--highlight-color);
                }

                &:not(:last-of-type) {
                    margin-right: 10px;
                }

                &:not(.active) {
                    opacity: 0.5;
                }

                file-preview {
                    position: relative;
                    width: 100%;
                    height: 100%;

                    rl-icon {
                        font-size: 40px;

                        &[icon="play"] {
                            width: 40px;
                            height: 40px;
                            font-size: 20px;
                        }
                    }
                }
            }
        }
    }

    .variants {
        h2 {
            margin: 0 0 10px 0;
            font-size: 16px;
        }

        ul {
            display: flex;
            flex-wrap: wrap;
            gap: 10px;
            margin: 0 0 10px 0;
            padding: 0;

            li {
                display: flex;
                align-items: center;

                box-sizing: border-box;
                padding: 7px 10px;

                background: #f8f8f8;
                list-style: none;
                cursor: pointer;

                &.media {
                    padding: 0;

                    p {
                        width: 40px;
                        height: 40px;
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: contain;
                    }
                }

                &.selected {
                    border: 1px solid var(--highlight-color);
                    cursor: default;
                }

                &.disabled {
                    opacity: 0.5;
                    cursor: default;
                }

                p {
                    margin: 0;
                }

                &:first-child {
                    margin-left: 0;
                }

                &:last-child {
                    margin-right: 0;
                }

                .color {
                    width: 25px;
                    height: 25px;
                }
            }
        }
    }

    .content {
        margin-top: 35px;

        header {
            margin-bottom: 13px;
        }

        .description {
            img {
                width: auto;
                height: auto;
                max-width: 100%;
                margin-left: auto;
                margin-right: auto;
                display: block;
            }

            .video-wrapper {
                position: relative;
                padding-bottom: 56.25%; /* 16:9 */
                height: 0;

                iframe, video {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }
            }

            video {
                background: grey;
            }
        }

        .title {
            margin: 0;
            font-size: 20px;
            color: #494949;
        }

        .subtitle {
            margin: 0;
            padding-top: 5px;

            font-size: 13px;
            line-height: 16px;
        }
    }

    .categories {
        margin-bottom: 13px;

        h2 {
            font-size: 16px;
            margin: 0 0 10px 0;
        }

        .categoryLink {
            cursor: pointer;
            display: inline-block;
            padding: 0 10px 0 0;
            margin-bottom: 5px;

            font-weight: 600;
            color: @clr-highlight;
            text-decoration: underline;

            &:last-of-type {
                padding-right: 0;
            }
        }
    }

    .descripttion {
        font-size: 15px;
        line-height: 16px;

        p {
            margin: 10px 0;
        }
    }

    barcode img {
        display: block;
        margin: 0 auto;
    }

    .custom-fields {
        margin-top: 35px;

        h2 {
            margin: 0 0 10px 0;
            font-size: 15px;
        }

        table {
            border-collapse: collapse;

            tr:nth-child(even) {
                background: #f8f8f8;
            }

            td {
                padding: 6px 8px;
                border: 1px solid #ececec;

                .number {
                    text-align: right;
                }

                .color {
                    width: 50px;
                    height: 50px;
                }
            }

            p {
                margin: 0;
            }
        }
    }

    .buttons-wrapper {
        display: flex;
        justify-content: flex-end;
        position: sticky;

        margin-top: auto;
        padding-top: 40px;
        margin-bottom: 20px;
        bottom: calc(70px + env(safe-area-inset-bottom, 0px));

        @media @desktop-up {
            bottom: 40px;
            margin-top: 40px;
            margin-bottom: 40px;
            padding-top: 0;
        }

        .buttons {
            display: flex;
            background: var(--white-color);
            filter: drop-shadow(0 6px 15px rgba(0, 0, 0, 0.15));
            border-radius: var(--general-border-radius);

            button {
                display: flex;
                justify-content: center;
                align-items: center;

                width: 46px;
                height: 46px;
                padding: 0;

                color: var(--general-text-color);
                background: var(--background-highlight-color);
                border-radius: var(--general-border-radius);
                border: 1px solid var(--white-color);

                &:not([disabled]) {
                    cursor: pointer;
                }

                &[disabled] {
                    color: var(--general-text-inactive-color);
                }

                &.prev {
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                }

                &.next {
                    position: relative;
                    left: -1px;

                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                }
            }
        }
    }
}