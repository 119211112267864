@import "../../Import.less";

.form-module-question-rating{
    text-align: center;
    margin-top: 30px;
    padding-bottom: 20px;
    overflow: hidden;
    margin-bottom: 30px;

    button{
        display: inline-block;
        border: none;
        height: 50px;
        width: 50px;
        line-height: 50px;
        vertical-align: top;
        background-color: transparent;
        padding: 0;
        position: relative;
        overflow: visible;

        i{
            font-size: 50px;
            color: rgba(0,0,0,0.12);
        }

        > span{
            opacity: 0;
        }

        &.activated{
            i{
                color: #fec61a;
            }
        }

        &.picked{
            > span{
                opacity: 1;
                line-height: 20px;
                width: 100px;
                position: absolute;
                top: 100%;
                left: -25px;
                display: block;
                text-align: center;
                font-weight: @font-weight-headline;
            }
        }
    }
}