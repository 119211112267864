filling-item,
filling-loading-item {
    box-sizing: border-box;

    .media-preview :not(.multiple) .media .image {
        height: 130px;
        justify-content: center;
        display: flex;
        align-items: center;
        background: rgba(0, 0, 0, 0.6);
    }

    @media @mobile-max {
        .media-preview .multiple .media-preview-list .media-preview-list-col {
            height: 130px;

            .media-preview-list-item {
                height: 130px;
                line-height: 130px;

                &.small {
                    height: 65px;
                    line-height: 65px;
                }
            }
        }
    }

    @media @tablet-up {
        .media-preview :not(.multiple) .media .image {
            height: 200px;
        }
    }

    .filling-item-container {
        position: relative;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow: hidden;

        border-radius: var(--general-border-radius);
        background: var(--general-background-color);

        &.draft {
            .field-media {
                &:before {
                    content: '';
                    display: block;
                    position: absolute;
                    background: rgba(0, 0, 0, 0.1);
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                }
            }
        }
    }

    .content {
        flex: 1 1 auto;
        box-sizing: border-box;
        padding: 0 7px 10px 7px;
        cursor: pointer;
        word-break: break-word;

        > div:first-of-type h2 {
            margin-top: 5px;
        }
    }
    
    .icons-group {
        z-index: 1;
        display: flex;
        gap: 10px;
    }
    
    .badges-container {
        display: flex;
        position: absolute;
        right: 5px;
        top: 5px;
        flex-direction: column;
        align-items: flex-end;
        
        &.left {
            display: flex;
            gap: var(--s-spacing); 
            flex-direction: row;
            align-items: center;
            right: unset;
            top: unset;
            left: 5px;
            bottom: 5px;
        }
        
        
        .comments-icon {
            background-color: var(--warning-color);
            color: var(--text-on-highlight-color);
            padding: 5px;
            border-radius: 7px;
            font-size: 13px;
        }

        .label {
            z-index: 2;
            padding: 6px 8px;
            margin-bottom: 4px;

            border-radius: var(--general-border-radius);
            backdrop-filter: blur(24px);

            font-size: 13px;
            font-weight: bold;
            color: var(--white-color);

            &.draft {
                background: rgba(0, 0, 0, 0.6);
            }

            &.new {
                color: var(--white-color);
            }
        }
    }

    footer {
        display: flex;
        flex-direction: column;
        cursor: pointer;

        .basic-data {
            display: flex;
            flex-direction: column;
            padding: 10px;

            p {
                margin: 0 0 5px 0;
                padding: 0;
                display: block;
                font-size: 13px;
                color: var(--general-text-inactive-color);

                @media @tablet-up {
                    display: inline-block;
                }

                span {
                    margin-left: 3px;
                }
            }

            rl-icon {
                font-size: 13px;
                color: var(--general-text-inactive-color);
            }
            
            rl-loading-placeholder {
                .placeholder-shimmer {
                    height: 15px;
                }
            }
        }

        .buttons {
            display: flex;
            flex-direction: row;

            border-top: 1px solid var(--general-border-color);

            reaction {
                display: flex;
                flex: 1;
            }

            button {
                display: flex;
                flex: 1;
                align-items: center;
                justify-content: center;

                padding: 0;
                height: 40px;
                box-sizing: border-box;

                border: none;
                background: none;
                cursor: pointer;

                rl-icon {
                    font-size: 14px;
                }

                > span {
                    font-size: 13px;
                    margin-left: 6px;
                }
            }
        }
    }

    .service-form-filling-field {
        word-break: break-word;
        word-break: auto-phrase;

        &:not(:last-of-type) {
            margin-bottom: 8px;

        }

        h2 {
            font-size: 14px;
            padding: 0;
            margin: 0 0 4px 0;
        }

        p {
            font-size: 12px;
            margin: 0;

            &.italic {
                font-style: italic;
            }
        }

        .field-tags {
            display: flex;
            overflow-y: auto;
            white-space: nowrap;
            gap: 7px;

            div {
                display: inline-block;
                vertical-align: top;
                padding: 5px 7px;

                box-sizing: border-box;
                align-items: center;

                font-weight: bold;
                border-radius: var(--general-border-radius);
                font-size: 13px;
                background: var(--background-highlight-color);
                color: var(--general-text-color);
            }
        }


        .field-media {
            position: relative;
            margin-left: -7px;
            margin-right: -7px;
            margin-bottom: 5px;

            .img-placeholder {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 150px;
                background: rgba(0, 0, 0, 0.1);

                rl-icon {
                    color: var(--general-text-inactive-color);
                    font-size: 63px;
                }
            }

            media-preview {
                display: block;
                --media-preview-item-height: 150px;

                img {
                    height: 150px;
                    object-fit: cover;
                }

                &:before {
                    position: absolute;
                    content: '';
                    left: 0;
                    right: 0;
                    bottom: 0;
                    top: 0;
                    z-index: 5;
                }
            }

            .performance-range-badge {
                padding: 6px 8px;
                min-width: 20px;
                border-radius: var(--general-border-radius);
                background-color: var(--warning-color);
                color: var(--text-on-highlight-color);

                text-align: center;
                font-size: 13px;
                font-weight: bold;
            }

            .sf-feedback-rating {
                margin-bottom: 4px;
            }
        }
    }
}
