score-report {

    > .header {
        padding: 10px;
        box-shadow: 0 2px 7px 0 ~'rgba(var(--general-text-color-rgb), 0.1)';

        h2 {
            margin: 0;
        }

        rl-progress {
            .percentage {
                font-size: 20px;
            }
        }

        .top {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 30px;
        }

        .bottom {
            rl-progress {
                margin-bottom: 30px;
            }

            .performance-badge {
                margin-top: 13px;
            }
        }

        .performance-badge {
            display: inline-block;
            box-sizing: border-box;
            height: 30px;
            min-width: 30px;
            line-height: 30px;
            padding: 0 10px;

            border-radius: 50px;

            text-align: center;
            text-transform: uppercase;
            color: var(--white-color);
        }


        .performance-range {
            margin-top: 30px;

            > h2 {
                margin: 0 0 10px 0;
            }
        }
    }

    rl-button {
        text-transform: uppercase;
        display: block;

        button {
            display: block;
            margin: 0 auto;
            color: var(--general-text-color);
        }
    }

    .categories {
        margin-top: 25px;

        .count {
            display: inline-block;
            margin-bottom: 10px;
        }
    }

    .category {
        &:not(:last-of-type) {
            margin-bottom: 15px;
        }

        padding: 10px;
        box-shadow: 0 2px 7px 0 ~'rgba(var(--general-text-color-rgb), 0.1)';

        h3 {
            margin: 0 0 15px 0;
        }
    }
}
