@import "../Import.less";

.accordion {
    display: block;
    list-style: none;
    padding: 0;
    margin: 0;

    > li {
        display: block;
        padding: 0;
        margin: 0;
        width: 100%;


        &:first-child {
            > .accordion-header {
                border-top: none;
            }
        }

        &:last-child {
            border-bottom-width: 1px;
        }

        > .accordion-header {
            display: block;
            height: 42px;
            position: relative;
            border-top: 1px solid #000;
            border-color: #e0e0e0;
            box-sizing: border-box;
            padding: 0;
            position: relative;
            overflow: hidden;

            .accordion-title {
                font-weight: 600;
                width: 75%;
                text-align: left;
                padding-left: 13px;
                white-space: nowrap;
                text-overflow: ellipsis;
            }

            .accordion-toggle {
                width: 25%;
                text-align: right;
                padding-right: 13px;

                &:before {
                    display: inline-block;
                    font-family: @icon-fa;
                    content: '\f078'; // fa-chevron-down
                    font-size: 14px;
                    line-height: 42px;
                    position: relative;
                    transition: transform ease-in-out .2s;
                }
            }

            span {
                display: block;
                line-height: 42px;
                height: 42px;
                margin: 0;
                float: left;
                box-sizing: border-box;
                position: relative;
                overflow: hidden;
            }

            &:after {
                display: table;
                clear: both;
                content: '';
            }
            /*&:after {
                display: block;
                position: absolute;
                right: 10px;
                font-family: @icon-fa;
                content: "\f055"; // fa-circle-plus
                z-index: 1;
                top: 0;
                height: 35px;
                line-height: 35px;
                opacity: 0.3;
                font-size: 17px;
                -moz-transition: all .3s ease-in-out;
                -o-transition: all .3s ease-in-out;
                -webkit-transition: all .3s ease-in-out;
                transition: all .3s ease-in-out;
            }*/
        }

        > .accordion-content {
            display: block;
            max-height: 0;
            overflow: hidden;
            width: 100%;
            opacity: 0;
            transition: opacity ease-in-out .2s;

            > .accordion-inner {
                padding: 5px 10px;
            }
        }

        &.active {
            > .accordion-header {
                border-bottom-width: 1px;
                border-bottom-style: solid;

                .accordion-toggle:before {
                    transform: rotate(180deg);
                }
            }

            > .accordion-content {
                max-height: none;
                opacity: 1;
            }
        }
    }
}