filter-popup {
    .content {
        padding: 15px;
        flex: 1;
    }

    .input-wrapper {
        position: relative;

        > rl-icon {
            position: absolute;
            z-index: 1;
            left: 10px;
            top: 50%;
            transform: translateY(-50%);
        }

        input {
            padding: 10px 10px 10px 40px;

            &[type=date] {
                display: block;
                text-align: left;
                -webkit-appearance: textfield;
                -moz-appearance: textfield;

                &::-webkit-date-and-time-value {
                    text-align: left;
                }
            }
        }
    }

    select-category {
        .click-area {
            position: relative;
            width: 100%;
            min-height: 45px;
            box-sizing: border-box;

            padding: 10px 40px 10px 40px;
            border-radius: var(--general-border-radius);
            border: 1px solid transparent;

            font-size: 15px;
            line-height: 1.3;

            color: var(--general-text-color);
            background: var(--background-highlight-color);
            background-clip: padding-box;

            > span {
                color: var(--general-text-color);
                opacity: .4;
            }

            &:hover {
                border: 1px solid var(--general-border-color);
            }

            &:after {
                content: '';
                display: block;
                position: absolute;
                right: 15px;
                top: 50%;
                transform: translateY(-50%);

                width: 0;
                height: 0;
                border-style: solid;
                border-width: 6px 5px 0 5px;
                border-color: var(--general-text-inactive-color) transparent transparent transparent;
            }
        }

        .category-selector {
            top: 0 !important;
            left: 0 !important;
            width: 100%;
            height: 100%;
        }
    }

    .other {
        .rl-field-wrapper label {
            display: flex;
            align-items: center;

            rl-icon {
                margin-right: 10px;
            }
        }
    }

    footer {
        margin-top: auto;
        padding: 15px;
        display: flex;

        rl-button {
            flex: 1;

            &:first-of-type {
                margin-right: 15px;
            }

            &[icon=sliders ] {
                button {
                    display: flex;
                    flex-direction: row-reverse;
                    align-items: center;
                    justify-content: center;

                    rl-icon {
                        margin-right: 10px;
                    }
                }
            }
        }
    }
}