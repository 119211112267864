@social-post-create-divider-color: #ddd;
@social-post-create-option-height: 40px;

social-post-create {
    .social-post-create-inner {
        padding-bottom: 75px;
    }
    
    .scroll-container {
        position: relative;
    }

    .social-post-create-options {
        display: block;
        padding: 0;
        margin: 0;
        list-style: none;
        flex: 0 0 auto;

        > li {
            display: block;
            width: 100%;
            border-bottom: 1px solid var(--general-border-color);

            &.disabled {
                > .social-group-select {
                    background: rgba(0, 0, 0, 0.1);

                    .icon,
                    span {
                        opacity: .5;
                    }

                    .ripple-effect {
                        display: none;
                    }
                }
            }

            > .upload-btn,
            > .social-group-select {
                display: block;
                height: @social-post-create-option-height;
                line-height: @social-post-create-option-height;
                position: relative;
                overflow: hidden;

                .icon {
                    height: @social-post-create-option-height;
                    line-height: @social-post-create-option-height;
                    width: 50px;
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    font-size: 20px;
                    display: block;
                    text-align: center;

                    rl-icon {
                        height: @social-post-create-option-height;
                    }

                    &.green {
                        color: @color-green
                    }
                }

                .label {
                    position: absolute;
                    box-sizing: border-box;
                    padding: 0 10px;
                    height: @social-post-create-option-height;
                    line-height: @social-post-create-option-height;
                    display: block;
                    left: 50px;
                    top: 0;
                    bottom: 0;
                    right: 0;

                    > b {
                        font-weight: 600;
                    }

                    > i {
                        font-size: 13px;
                        margin-left: 5px;
                    }
                }
            }
        }

        .language-selector-wrapper {
            display: flex;
            justify-content: space-between;
            background-color: var(--general-background-color);

            language-selector {
                flex: 1;
                overflow: hidden;
            }
        }
    }

    .social-post-create {
        overflow-y: auto;
        height: ~'calc(100% - 55px)';
        background: white;

        .save button {
            width: 100%;
            color: var(--white-color);
            background: var(--button-background-color);
            text-transform: uppercase;
        }

        .social-post-create-inner {
            position: static;
            display: flex;
            flex-direction: column;
            height: 100%;
        }

        .social-post-create-area {
            flex: 1;
        }

        rl-rich-textarea {
            border-top: none;
        }

        social-poll {
            margin: 10px;
        }

        .social-post-create-media {
            display: flex;
            flex-wrap: wrap;
            flex: 0 0 auto;
            width: 100%;
            min-height: 10px;
            max-height: 40%;
            padding: 10px 0 0 0;
            overflow-y: auto;
            -webkit-overflow-scrolling: touch;

            border-bottom: 1px solid @social-post-create-divider-color;
            box-sizing: border-box;
            font-size: 0;

            .preview-container {
                width: 46%;
                height: 80px;
                margin: 0 2% 10px 2%;
                display: inline-block;
                position: relative;

                .media-thumbnail .image {
                    height: 80px;
                }

                .preview-container-close {
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    top: 0;
                    background: rgba(0, 0, 0, 0.3);
                    z-index: 2;
                    font-size: 19px;
                    text-align: right;
                    color: #fff;
                    box-sizing: border-box;
                    padding: 4px 5px 0 0;
                }
            }
        }

        .attachments-wrapper {
            padding-top: 10px;
            margin-top: auto;

            attachment-preview {
                box-sizing: border-box;
                display: block;
                padding: 10px;
            }
        }

        .attachments-label {
            padding: 0 10px;
            color: var(--general-text-color);
        }

        .social-post-create-loader {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            z-index: 16;
            background: rgba(0, 0, 0, 0.5);
            .flex-center-center();

            .spinner {
                border-color: #fff;
                height: 40px;
                width: 40px;
            }

            &.ng-enter,
            &.ng-leave.ng-leave-active {
                opacity: 0;
            }

            &.ng-leave,
            &.ng-enter.ng-enter-active {
                opacity: 1;
            }
        }
    }
}
