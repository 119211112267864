.subtaskitemsyles {
    display: flex;
    box-sizing: border-box;
    justify-content: flex-start;
    align-items: center;

    min-height: 50px;
    padding: 10px 10px;

    background: var(--background-highlight-color);
    border-radius: var(--general-border-radius);
    line-height: 16px;
    font-weight: 400;
}
create-subtask-only-title {
    .add-subtask {
        padding: 15px;
        flex: 1;
        overflow-y: auto;

        .add-subtask-field {
            display: flex;
            flex-direction: row;
            align-items: center;
            position: relative;

            .remove-subtask-btn {
                position: absolute;
                right: 10px;
                color: var(--warning-color);
            }
        }

        .list-of-subtasks {
            margin: 0;
            padding: 10px 0;
            list-style-type: none;
        }

        .new-subtask-item {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            margin-top: 10px;

            &.sortable-chosen {
                pointer-events: none;
            }
        
            .subtask-item-content {
                .subtaskitemsyles();

                min-width: calc(100% - 40px);
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;

                .title {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                .handle {
                    cursor: move;
                }
            }

            .remove-subtask-from-list {
                cursor: pointer;
                color: var(--warning-color);
            }
        }

        .input-subtask {
            background: var(--background-highlight-color);
            border: 1px solid rgba(var(--general-text-color-rgb), 0.1);
            border-radius: 10px;
            padding-right: 45px;
        }
    }

    .info {
        padding: 6px 10px;
    }
}