@import "../Import.less";

profile-image {
    display: inline-block;
    padding-right: 5px;
    position: relative;

    .leave-icon {
        border-radius: 50%;
        width: 16px;
        height: 16px;
        position: absolute;
        font-size: 16px;
        bottom: 3px;
        right: 3px;
        z-index: 1;
        backdrop-filter: blur(13px);
        color: var(--general-text-inactive-color);
    }
}

.profile-image {
    display: inline-block;
    min-height: 10px;
    min-width: 10px;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 50%;
    
    &.inactive {
        opacity: 0.6;
        position: relative;
    }
}

.highscore-levels-enabled .profile-image.has-highscore-level {
    box-sizing: border-box;
    border-width: 2px;
    border-style: solid;
}

.profile-image.blocked::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.5);
    pointer-events: none;
    z-index: 0;
}

.profile-image.blocked {
    .blocked-icon {
        width: 0.7rem;
        height: 0.7rem;
        position: absolute;
        bottom: 0;
        right: 0;
        background-color: var(--warning-color);
        border-radius: 50%;
        color: var(--white-color);
        display: grid;
        place-content: center;
        z-index: 1;
        font-size: 0.4rem;
    }

}
