@import "../Import.less";

.learningbank-module {
    display: flex;
    height: 100%;
    background-color: #fff;

    .spinner{
        margin-top: var(--spacing);
        height: 40px;
        width: 40px;
    }

    .learningbank-module-inner {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-align: center;
        font-size: 1rem;
        line-height: 130%;
        margin: 0 auto;
        color: #323251;
        min-width: 100%;

        svg {
            height: 40px;
        }

        .error-msg {
            max-width: 310px;
        }
    }

    .learningbank-iframe-container {
        display: flex;
        flex: 1;

        @media @desktop-up {
            max-height: unset;
        }

        iframe {
            width: 100%;
        }
    }

    .module-error {
        transition-delay: 0.5s;
    }
}

.base .base-view-learningbank {
    background: var(--background-highlight-color);

    &.base-view {
        height: auto;
    }
}   

.learning-bank-overview {
    display: block;
    padding: var(--spacing) var(--spacing) calc(60px + env(safe-area-inset-bottom, 0px));

    .learningbank-list-item {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        padding: var(--m-spacing);

        background: var(--general-background-color);
        border-radius: var(--general-border-radius);

        &:not(:last-of-type) {
            margin-bottom: var(--spacing);
        }

        &:hover {
            cursor: pointer;
            box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
        }
    }

    .image {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        width: 53px;
        height: 53px;
        margin-right: var(--spacing);

        border-radius: var(--general-border-radius);

        rl-icon {
            font-size: 25px;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: var(--general-border-radius);
        }

        &:not(.placeholder-shimmer) {
            background: var(--general-border-color);
        }
    }

    .right {
        display: flex;
        flex: 1;
        flex-direction: column;
        align-self: center;

        h2 {
            font-size: 0.95rem;
            margin: 0 0 5px 0;
            font-weight: 600;
            word-break: break-word;
        }
    }
}
